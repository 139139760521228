/* Brandon Hernandez Rocha 11/2022 */
import React from "react";
import CustomSeparatorDinamic from "../Tools/BreadCrumbs/RecorridoDinamico";
import "../../css/estilo.css";
import { Grid } from "@mui/material";
import ButtonVino from "../Tools/Bottones/ButtonVino";

//nextcloud example 
import { pdf } from '@react-pdf/renderer';
import { getFiles, downloadBlob, downloadBlobsAsZip, createFolderFolio, uploadFilesToNextcloud, getURLDocument, getAuthorize, getURLToken } from "../../nextcloud/functions";
//Ejemplo formulario tramite 
import { FormReclamacionesInd } from "./Elements/FormularioTramite/formReclamaciones";
//Boton upload
import BTUpload from "../Tools/Bottones/BTUpload";
//PDF
import { PDFViewer, BlobProvider, Document, Text, Page } from "@react-pdf/renderer";
import { AcuseRecepcion, CartaRechazo } from "./Elements/Cartas/CartasDictamen";
//idioma
import { FormattedMessage } from 'react-intl';
import TXTField from "../Tools/TextField/TextField";
import { getCurrencyExchangeBanxico } from "../../api/dataToken";
import DatePicker2 from "../Tools/DatePicker/DatePicker2";
import { format } from "date-fns";
const CompDictamenPruebas = () => {

    const arrayBreadcrumbs = [
        { 'key': 1, 'name': <FormattedMessage id="glob.inicio" defaultMessage="Inicio" />, 'to': '/menu' },
    ]
    //Estados para crear folder
    const [folderFolio, setFolderFolio] = React.useState("")
    //Estados para descargar archivos del folder
    const [folio, setFolio] = React.useState("")
    const [btnDescarga, setBtnDescarga] = React.useState({
        estatus: false,
        label: 'Descarga de archivos'
    })
    //Estados para ver archivos del folder
    const [verFolderFolio, setVerFolderFolio] = React.useState("")
    const [btnVerFolder, setBtnVerFolder] = React.useState({
        estatus: false,
        label: 'Ver archivos'
    })
    const [images, setImages] = React.useState([]);
    const [pdfs, setPdfs] = React.useState([]);
    //Esatdos para cargar documentos a folder
    const [selectDocs, setSelectDocs] = React.useState([])
    const [cargarFolio, setCargarFolio] = React.useState("")
    const [nombreArchivo, setNombreArchivo] = React.useState("")
    const [startDate, setStartDate] = React.useState("")
    const [endDate, setEndDate] = React.useState("")
    let today = new Date();

    React.useEffect(() => {
        if (selectDocs.length > 0) console.log(selectDocs)
    }, [selectDocs])

    const getUrl = async () => {
        let url = await getURLDocument(cargarFolio, nombreArchivo);
        try {
            console.log("URL ARCHIVO:", url)
        } catch (e) {
            console.log(e);
        }
    }
    const getAuth = async () => {
        // let url = await getAuthorize();
        let url = await getURLToken();
        try {
            console.log("Response:", url)
        } catch (e) {
            console.log(e);
        }
    }

    // EJemplo de nextcloud 

    // Esta función descargará el archivo, llamando a la función getFiles(folio) quien se asegurará de hacer la programación para realizar la petición fetch
    // const descargarArchivos = async () => {
    //     setBtnDescarga({ ...btnDescarga, label: "Descargando...", estatus: true });

    //     var response = await getFiles(folio)
    //     if (!response) {//Si la respuesta es falsa es porque no existe una carpeta con ese folio
    //         alert("No existe carpeta con ese folio")
    //         setBtnDescarga({ ...btnDescarga, label: "Descargar archivos", estatus: false });
    //     } else {//En caso contrario, revisará que la carpeta tenga archivos para descargarlos, si no, mandar alerta, por ejemplo.
    //         console.log(response);
    //         if (response.length > 0) {
    //             response.map((e) => {
    //                 downloadBlob(e.fileBlob, e.fileName)
    //             })
    //             setBtnDescarga({ ...btnDescarga, label: "Descargar archivos", estatus: false });
    //         } else {
    //             alert("La carpeta está vacía.")
    //             setBtnDescarga({ ...btnDescarga, label: "Descargar archivos", estatus: false });
    //         }
    //     }
    // }
    const descargarArchivos = async () => {
        setBtnDescarga({ ...btnDescarga, label: "Descargando...", estatus: true });

        var response = await getFiles(folio)
        if (!response) {//Si la respuesta es falsa es porque no existe una carpeta con ese folio
            alert("No existe carpeta con ese folio")
            setBtnDescarga({ ...btnDescarga, label: "Descargar archivos", estatus: false });
        } else {//En caso contrario, revisará que la carpeta tenga archivos para descargarlos, si no, mandar alerta, por ejemplo.
            console.log(response);
            if (response.length > 0) {
                let arrayArchivos = []
                response.map((e) => {
                    arrayArchivos.push({ blob: e.fileBlob, filename: e.fileName });
                })
                downloadBlobsAsZip(arrayArchivos, folio)
                setBtnDescarga({ ...btnDescarga, label: "Descargar archivos", estatus: false });
            } else {
                alert("La carpeta está vacía.")
                setBtnDescarga({ ...btnDescarga, label: "Descargar archivos", estatus: false });
            }
        }
    }
    // Esta función descargará el archivo, llamando a la función getFiles(folio) quien se asegurará de hacer la programación para realizar la petición fetch
    const verArchivos = async () => {
        setBtnVerFolder({ ...btnVerFolder, label: "Buscando información...", estatus: true });
        var response = await getFiles(verFolderFolio)
        if (!response) {//Si la respuesta es falsa es porque no existe una carpeta con ese folio
            alert("No existe carpeta con ese folio")
            setBtnVerFolder({ ...btnVerFolder, label: "Ver archivos", estatus: false });

        } else {//En caso contrario, revisará que la carpeta tenga archivos para descargarlos, si no, mandar alerta, por ejemplo.
            if (response.length > 0) {
                setBtnVerFolder({ ...btnVerFolder, label: "Ver archivos", estatus: false });
                var imagenes = response.filter(file => file.fileExt === "jpg");//Filtrar por jpg
                var pdfs = response.filter(file => file.fileExt === "pdf");//Filtrar por pdf

                if (imagenes.length > 0) {
                    const images = imagenes.map(e => URL.createObjectURL(e.fileBlob));
                    setImages(images)
                }
                if (pdfs.length > 0) {
                    const docs = pdfs.map(e => e.fileBlob);
                    console.log("Docs:", docs)
                    setPdfs(docs)
                }

                //response.map((e) => {
                //    downloadBlob(e.fileBlob, e.fileName)
                //})
            } else {
                alert("La carpeta está vacía.")
                setBtnVerFolder({ ...btnVerFolder, label: "Ver archivos", estatus: false });
            }
        }
    }
    React.useEffect(() => {

        return () => {
            // Liberar la memoria utilizada por cada URL de objeto creada
            images.forEach(url => URL.revokeObjectURL(url));
        }
    }, [images])


    //Esta función permite crear una carpeta dentro de nextcloud
    const crearCarpetaFolio = async () => {
        if (folderFolio !== "") {
            var response = await createFolderFolio(folderFolio)//Se llama a la función createFolderFolio(folderFolio).
            if (response) {//Si la respuesta es true, manda mensaje se exito
                alert('Carpeta ' + folderFolio + ' creada exitosamente');
            } else {//Si no, manda mensaje informando el estado de la operación. Por ejemplo
                alert('Ocurrió un error al crear la carpeta');
                throw new Error('Ocurrió un error al crear la carpeta', response.status);
            }
        } else {
            alert("Ingresa un folio válido.")
        }
    }

    //Esta funcion permite cargar archivos a nextcloud
    const cargarArchivos = async () => {
        if (cargarFolio !== "" && selectDocs.length > 0) {
            var response = await uploadFilesToNextcloud(cargarFolio, selectDocs) //Se llama a la funcion uploadFilesToNextCloud(folio,docs)
            if (response) {//Si la respuesta es true, mandar mensaje de exito. 
                //alert('Archivo cargado  exitosamente');
            } else {//Si no, manda mensaje informando el estado de la operación. Por ejemplo
                alert('Ocurrió un error al archivo');
                throw new Error('Ocurrió un error al archivo');
            }
        } else {
            alert("Ingresa un folio válido o selecciona archivos.")
        }
    }

    //Funciones y estados para VER PDF
    const [documents, setDocuments] = React.useState([]);
    const [currentPDF, setCurrentPDF] = React.useState(0);


    //Este use Effect se activará cuando escuche cambios en el pdfs
    React.useEffect(() => {
        if (pdfs.length > 0) {//Verificamos si hay archivos PDFs
            setDocuments([]);//Si los hay actualizamos en estado documentos, seteando un arreglo vacío
            pdfs.forEach((blob) => {//Recorremos el arreglo de pdfs
                var pdfUrl = URL.createObjectURL(blob);// Usar el objectUrl para mostrar el PDF en pantalla...
                setDocuments(prevState => [...prevState, //Obtenemos los valores sin sobreescribirlos,
                <iframe src={pdfUrl} style={{ width: "600px", height: "300px" }} />//Los presentamos en un iframe, por ejemplo
                ]);
                URL.revokeObjectURL(blob);// Luego, al ya no necesitarlo, revocar el objectUrl. Esto ayuda a liberar recursos en la memoria del navegador..
            });
        }
    }, [pdfs])

    let data = {
        'arrayDocs': [{ causas: "No hay INE" }],
        'destinatarios': [{ nombre: "Brandon", apePat: "Hernandez", apeMat: "Rocha", docName: "CR_BHR" }], //Aqui cada destinatario debera tener un nombre de archivo diferente
        'polizas': ["POL23123423"]
    };

    async function guardarCartaNextcloud(data) {
        await uploadFilesToNextcloud("FOLIO12", CartaRechazo(data), data.destinatarios[0].docName)
        ////Por si se quiere imprimir la carta
        var docCarta = CartaRechazo(data)
        docCarta.map(async (item) => {
            var blob = await pdf(item).toBlob();
            const pdfUrl = window.URL.createObjectURL(blob);
            var printWindow = window.open(pdfUrl, '_blank');
            printWindow.print();
            URL.revokeObjectURL(pdfUrl);
        })

    }
    async function verAcuse() {
        let acuse = AcuseRecepcion(1);

        var blob = await pdf(acuse).toBlob();
        const pdfUrl = window.URL.createObjectURL(blob);
        var printWindow = window.open(pdfUrl, '_blank');
        printWindow.print();
        URL.revokeObjectURL(pdfUrl);

    }
    async function verCambio() {
        const response = await fetch("https://www.banxico.org.mx/SieAPIRest/service/v1/SF43718/datos/oportuno?token=2b8a9e49039dab195a70685abdff2eed00d05ad195b549136db622d10a74fb0b");
        let data = await response.json();
        console.log(data);
    }
    async function verCambioBanxico() {
        if (startDate !== "" && endDate !== "") {
            getCurrencyExchangeBanxico(startDate, endDate);
        } else {
            getCurrencyExchangeBanxico();
        }
    }
    async function handleChangeDate(e) {
        let today = new Date();
        let today2 = new Date(e.$d);
        const formatedDate = format(today2, 'yyyy-MM-dd');
        const formatedHour = format(today, 'HH:mm:ss');
        let date = `${formatedDate}T${formatedHour}`;
        let startDate = new Date(new Date(date).getTime() - 24 * 60 * 60 * 1000);
        startDate = startDate.toISOString().slice(0, 10);
        let endDate = formatedDate;
        setEndDate(endDate)
        setStartDate(startDate)

        console.log("startdate", startDate)
        console.log("endDate", endDate)
    }




    return (

        <div className='div-parent'>
            <div className='div-breadcrumbs'>
                <CustomSeparatorDinamic breadcrumbs={arrayBreadcrumbs} name={`Pruebas`} />
            </div>

            <div className='div-container'>

                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start">
                    <FormReclamacionesInd />
                </Grid>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: '100px', textAlign: 'center' }} spacing={3}>
                    <Grid item xs={12}>NEXTCLOUD</Grid>
                    <Grid item  ><ButtonVino onClick={() => guardarCartaNextcloud(data)} label={"Generar carta rechazo y guardar"} /></Grid>
                    <Grid item  ><TXTField label="Número de folio" onChange={(e) => setFolio(e.target.value)} /><ButtonVino onClick={() => descargarArchivos()} label={btnDescarga.label} estatus={btnDescarga.estatus} /></Grid>
                    <Grid item  ><TXTField label="Número de folio" onChange={(e) => setFolderFolio(e.target.value)} /><ButtonVino onClick={() => crearCarpetaFolio()} label="Crear carpeta" /></Grid>
                    <Grid item xs={4} >
                        <TXTField label="Número de folio" onChange={(e) => setCargarFolio(e.target.value)} />

                        <BTUpload style={{ marginRight: '10px' }} accept='.pdf, .jpg, .MP4, .MOV, .AVI' id="bt-upload1" multiple={true} onChange={(e) => {
                            var files = e.target.files
                            for (let i = 0; i < files.length; i++) {
                                let file = files[i];
                                // Realiza la validación del archivo aquí
                                if (file.size > 2 * 1024 * 1024) {
                                    console.log("El tamaño máximo permitido es de 2 MB.");
                                    // Elimina el archivo del arreglo si no pasa la validación
                                    files = [].slice.call(files, 0, i).concat([].slice.call(files, i + 1));
                                    i--;
                                }
                            }
                            // Actualiza el arreglo de archivos sin agregar el archivo que sobrepasa el límite 
                            setSelectDocs(files)
                        }} />
                        <ButtonVino onClick={() => cargarArchivos()} label="Subir archivos" />
                    </Grid>
                    <Grid item  ><TXTField label="Número de folio" onChange={(e) => setVerFolderFolio(e.target.value)} /><ButtonVino onClick={() => verArchivos()} label={btnVerFolder.label} estatus={btnVerFolder.estatus} /></Grid>
                    <Grid item >
                        {images.length > 0 ? (
                            images.map((image, index) => (
                                <div key={index}>
                                    <img src={image} alt={`Imagen ${index}`} style={{ width: '30%' }} />
                                </div>
                            ))
                        ) : (
                            <p>No hay imágenes disponibles</p>
                        )}
                    </Grid>
                    <Grid item >
                        {pdfs.length > 0 ? (
                            <div>
                                {documents[currentPDF]}
                                <div>
                                    <button onClick={() => setCurrentPDF(currentPDF - 1)} disabled={currentPDF === 0}>Anterior PDF</button>
                                    <button onClick={() => setCurrentPDF(currentPDF + 1)} disabled={currentPDF === pdfs.length - 1}>Siguiente PDF</button>
                                </div>
                            </div>
                        ) : (
                            <p>No hay PDF's disponibles</p>
                        )}
                    </Grid>
                    <Grid item xs={4} >
                        <TXTField label="Número de folio" onChange={(e) => setCargarFolio(e.target.value)} />
                        <TXTField label="Nombre archivo" onChange={(e) => setNombreArchivo(e.target.value)} />

                        <ButtonVino onClick={() => getUrl()} label="Obtener url" />
                    </Grid>
                    <Grid item xs={4} >
                        <ButtonVino onClick={() => getAuth()} label="Obtener token" />
                    </Grid>
                    <Grid item xs={4} >
                        <ButtonVino onClick={() => verAcuse()} label="Ver acuse" />
                    </Grid>
                    <Grid item xs={4} >
                        <ButtonVino onClick={() => verCambio()} label="Ver tipo cambio" />
                    </Grid>

                    <Grid item xs={4} >
                        {/* <TXTField label="Fecha inicial" onChange={(e) => setStartDate(e.target.value)} /> */}
                        {/* <TXTField label="Fecha final" onChange={(e) => setEndDate(e.target.value)} /> */}
                        <DatePicker2 maxDate={today} onDataChange={(e) => handleChangeDate(e)} />

                        <ButtonVino onClick={() => verCambioBanxico()} label="Ver tipo cambio Banxico" />
                    </Grid>
                </Grid>
            </div>
        </div>

    );
}
export default CompDictamenPruebas