import React from "react";
import { InfoComunicacion } from "../../../components/Communication/InfoComunicacion";
import MyComponent from "../../../components/Tools/ButtonLoading/ButtonLoading";


export function Info() {
    return (
        <div>

            <MyComponent componentToRender={InfoComunicacion} />

        </div>
    );
}