import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import { Grid } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogCascadeTwo(props) {
  //PARENT DIALOG
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //CHILDREN DIALOG
  const [open2, setOpen2] = React.useState(false);
  const handleClickOpen2 = () => {
    setOpen2(true);
    props.addColumn(true);
  };
  const handleClose2 = () => {
    setOpen2(false); // Close the children dialog
    handleClose(); // Close the parent dialog
  };

  return (
    <div>
      {/* PARENT DIALOG */}
      <div>
        {/*BOTON QUE HACE EL LLAMADO AL DIALOGO PADRE*/}
        <ButtonVino
          onClick={handleClickOpen}
          label={props.mainButton}
          estatus={props.disabledbtn}
          type={props.type}
        />
        {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
        <BootstrapDialog
          // onClose={handleClose}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
          aria-labelledby="dialog"
          open={open}
        >
          {/*CUERPO DEL DIALOGO*/}
          {/*TITILO*/}
          <BootstrapDialogTitle
            id="dialog"
            onClose={handleClose}
            style={{ color: "#761f2a" }}
          >
            <h1-dialog-title>{props.title}</h1-dialog-title>
          </BootstrapDialogTitle>
          {/*CUERPO DEL DIALOGO*/}
          {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
          <DialogContent dividers style={{ minWidth: 500 }}>
            <h2-dialog-description>{props.description}</h2-dialog-description>
            <h2-dialog-description>{props.description2}</h2-dialog-description>
            <h2-dialog-description>{props.description3}</h2-dialog-description>
          </DialogContent>
          {/*es la tercer sección que engloba los botones */}
          <DialogActions>
            {/*En esta parte se llena el segundo mensaje que debe de aparecer*/}
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item xs={"auto"}>
                <ButtonVino
                  onClick={handleClickOpen2}
                  label={props.childrenButton}
                  estatus={props.disabledBtn}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <ButtonVino
                  onClick={handleClose}
                  onSubmit={props.onSubmit}
                  label={props.secondChildrenButton}
                  estatus={props.disabledBtn}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </BootstrapDialog>
      </div>
      {/* CHILDREN DIALOG */}
      <div>
        {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
        <BootstrapDialog
          onClose={handleClose2}
          aria-labelledby="dialog"
          open={open2}
          onClick={props.onClick}
          href={props.enlace}
        >
          {/*CUERPO DEL DIALOGO*/}
          {/*TITULO*/}
          <BootstrapDialogTitle
            id="dialog"
            onClose={handleClose2}
            style={{ color: "#761f2a" }}
            onClick={props.onClick}
          >
            <h1-dialog-title>{props.childrenTitle}</h1-dialog-title>
          </BootstrapDialogTitle>
          {/*CUERPO DEL DIALOGO*/}
          {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
          <DialogContent dividers style={{ minWidth: 500 }}>
            {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
              se necesita colocar entre un typogreaphy las veces que des salto de linea 
              o con un br*/}
            <h2-dialog-description>
              {props.childrenDescription}
            </h2-dialog-description>
          </DialogContent>
          {/*es la tercer sección que engloba los botones */}
          <DialogActions>
            <Grid container justifyContent="center">
              <ButtonVino
                onClick={handleClose2}
                label={props.firstChildrenButton}
                enlace={props.enlace}
              />
            </Grid>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </div>
  );
}
