/* Brandon Hernandez Rocha 11/2022 */
import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from "react-router-dom";
import Stack from '@mui/material/Stack';

const CustomSeparatorDinamic=(props)=>{

  const arrayBreadcrumbs = props.breadcrumbs

  return (
    
    <Stack spacing={2}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {arrayBreadcrumbs.map(element => {
            return(
            <Link style={{textDecoration  : 'none'}} underline="hover" key={element.key} href={element.href} to={element.to} state={element.state}>{/* element.key es para la key del enlace traído desde el array */} 
                <h2-breadcrumbs-primary>{element.name}</h2-breadcrumbs-primary>{/* element.name es el nombre del enlace traído desde el array */}
            </Link>
            )
        })}
        <h3-breadcrumbs-secundary key="2">{props.name}</h3-breadcrumbs-secundary> {/* props.name es la página actual */}
      </Breadcrumbs>
    </Stack>
  );
}

export default CustomSeparatorDinamic;