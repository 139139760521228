/* 
David Ruiz
Proyecto SINAY 
05/09/2022
*/


import React from "react";
import { useAuth } from "../../hooks/useAuth";
import { Login } from "../../pages/Login/ModLogin/Login";


export function AdminLayaout(props){

    const { children } = props;
    const { auth } = useAuth();

     if (!auth) return <Login />;
    
    return(
        <div>
          
            {children}
            
        </div>
    );
}