import { MenuPrincipal } from "../../pages/Client/ModMenuPrincipal/MenuPrincipal";
import { ClientLayaout } from "../../layouts/Client/ClientLayaout";

const routesClient = [

    {
        path: "/",
        layout: ClientLayaout,
        component: MenuPrincipal,
        exact: true,
    },
];

export default routesClient;