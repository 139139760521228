/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { FormattedMessage } from "react-intl";
import { useState } from "react";
//Select
import { Select1 } from "../../../Tools/Selectss/Select1";
//Textfleild
import TXTField from "../../../Tools/TextField/TextField";

//importacion de las imagenes para seleccionar tipo siniestro
import fallecimientoOn from "../../../../img/Recepcion/fallecimientoOn.svg";
import fallecimientoOff from "../../../../img/Recepcion/fallecimientoOff.svg";
import beneficioOn from "../../../../img/Recepcion/beneficioOn.svg";
import beneficioOff from "../../../../img/Recepcion/beneficioOff.svg";
import retiro_on from "../../../../img/Recepcion/retiro_on.png";
import retiro_off from "../../../../img/Recepcion/retiro_off.png";
import rescates_on from "../../../../img/Recepcion/rescates_on.svg";
import rescates_off from "../../../../img/Recepcion/rescates_off.svg";
import vencimiento_off from "../../../../img/Recepcion/vencimiento_off.svg";
import vencimiento_on from "../../../../img/Recepcion/vencimiento_on.svg";

export function FormReclamacionesInd(props) {
  //Estados para los íconos
  const [imgFalle, setImgFalle] = useState(false);
  const [imgBene, setImgBene] = useState(false);
  const [imgVenci, setImgVenci] = useState(false);
  const [imgRetir, setImgRetir] = useState(false);
  const [imgResc, setImgResc] = useState(false);

  /***************** APARTADO PARA COBERTURA POR FALLECIMIENTO *********************/

  //Array coberturas fallecimiento
  const arrayFalleTipo = [
    { identificador: 1, texto: "Muerte violenta" },
    { identificador: 2, texto: "Enfermedad" },
  ];
  const arrayFalleSubtipo = [
    { identificador: 1, texto: "Homicidio" },
    { identificador: 2, texto: "Accidente" },
    { identificador: 3, texto: "Suicidio" },
  ];
  const arrayFalleSubSubtipo = [
    { identificador: 1, texto: "Crimen pasional" },
    { identificador: 2, texto: "Asalto vía pública" },
    { identificador: 3, texto: "Asalto residencia" },
    { identificador: 4, texto: "Ejecución" },
    { identificador: 5, texto: "Secuestro previo" },
    { identificador: 6, texto: "Riña" },
    { identificador: 7, texto: "Otro" },
  ];
  const arrayFalleSubSubtipo2 = [
    { identificador: 1, texto: "Transporte terrestre privado" },
    { identificador: 2, texto: "Transporte terrestre público" },
    { identificador: 3, texto: "Transporte aéreo privado" },
    { identificador: 4, texto: "Transporte aéreo público" },
    { identificador: 5, texto: "Secuestro previo" },
    { identificador: 6, texto: "Moto" },
    { identificador: 7, texto: "Bicicleta" },
    { identificador: 8, texto: "Atropellamiento vía pública" },
    { identificador: 9, texto: "En casa" },
    { identificador: 10, texto: "Otro" },
  ];

  //Estados de selects Fallecimiento
  const [selectFalleTipo, setSelectFalleTipo] = useState("");
  const [selectFalleSubtipo, setSelectFalleSubtipo] = useState("");
  const [selectFalleSubsubtipo, setSelectFalleSubsubtipo] = useState("");

  //Eventos Fallecimiento

  const handleChangeFalletipo = (e) => {
    setSelectFalleTipo(e.target.value);
    setSelectFalleSubtipo("");
    setSelectFalleSubsubtipo("");
  };
  const handleChangeFalleSubtipo = (e) => {
    setSelectFalleSubtipo(e.target.value);
    setSelectFalleSubsubtipo("");
  };
  const handleChangeFalleSubsubtipo = (e) => {
    setSelectFalleSubsubtipo(e.target.value);
  };

  /***************** APARTADO PARA COBERTURA POR BENEFICIOS EN VIDA *********************/

  //Array coberturas fallecimiento
  const arrayBeneTipo = [
    { identificador: 1, texto: "Invalidez" },
    { identificador: 2, texto: "Enfermedad terminal" },
    { identificador: 3, texto: "Exención por desempleo" },
  ];
  const arrayBeneSubtipo = [
    { identificador: 1, texto: "Accidente" },
    { identificador: 2, texto: "Enfermedad" },
  ];

  //Estados de selects Fallecimiento
  const [selectBeneTipo, setSelectBeneTipo] = useState("");
  const [selectBeneSubtipo, setSelectBeneSubtipo] = useState("");

  //Eventos Fallecimiento

  const handleChangeBenetipo = (e) => {
    setSelectBeneTipo(e.target.value);
    setSelectBeneSubtipo("");
  };
  const handleChangeBeneSubtipo = (e) => {
    setSelectFalleSubtipo(e.target.value);
  };

  return (
    <>
      {/* GRID ÍCONO FALLECIMIENTO */}
      <Grid item xs={2}>
        {!imgFalle ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                src={fallecimientoOff}
                alt=""
                style={{ cursor: "pointer" }}
                className="img-iconos-comp"
                onClick={() =>
                  imgFalle
                    ? setImgFalle(false)
                    : (setImgBene(false),
                      setImgFalle(true),
                      setImgVenci(false),
                      setImgRetir(false),
                      setImgResc(false),
                      //reseteamos los valores de los selects
                      setSelectFalleTipo(""),
                      setSelectFalleSubtipo(""),
                      setSelectFalleSubsubtipo(""))
                }
              />
            </Grid>
            <Grid item>
              <h3-contable>
                <FormattedMessage
                  id="globFallec"
                  defaultMessage="Fallecimiento"
                />
              </h3-contable>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                src={fallecimientoOn}
                alt=""
                className="img-iconos-comp"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  imgFalle
                    ? setImgFalle(false)
                    : (setImgBene(false),
                      setImgFalle(true),
                      setImgVenci(false),
                      setImgRetir(false),
                      setImgResc(false),
                      //reseteamos los valores de los selects
                      setSelectFalleTipo(""),
                      setSelectFalleSubtipo(""))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <h3-contable style={{ color: "#771f2a" }}>
                <FormattedMessage
                  id="globFallec"
                  defaultMessage="Fallecimiento"
                />
              </h3-contable>
            </Grid>
            <Grid item xs={12} style={{ width: "100%" }}>
              <Select1
                array={arrayFalleTipo}
                label="Tipo"
                onChange={handleChangeFalletipo}
              />
            </Grid>
            <Grid item xs={12} style={{ width: "100%" }}>
              {selectFalleTipo === "" ? null : selectFalleTipo === 1 ? (
                <Select1
                  array={arrayFalleSubtipo}
                  label="Subtipo"
                  onChange={handleChangeFalleSubtipo}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} style={{ width: "100%" }}>
              {selectFalleSubtipo === "" ? null : selectFalleSubtipo === 1 ? (
                <Select1
                  array={arrayFalleSubSubtipo}
                  label="Subsubtipo"
                  id="subtipo1"
                  onChange={handleChangeFalleSubsubtipo}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} style={{ width: "100%" }}>
              {selectFalleSubtipo === "" ? null : selectFalleSubtipo === 2 ? (
                <Select1
                  array={arrayFalleSubSubtipo2}
                  label="Subsubtipo"
                  id="subtipo2"
                  onChange={handleChangeFalleSubsubtipo}
                />
              ) : null}
            </Grid>
            {/* Grid por si seleccionan OTRO */}
            <Grid item xs={12} style={{ width: "100%" }}>
              {selectFalleSubsubtipo === "" ? null : selectFalleSubsubtipo ===
                  7 && selectFalleSubtipo === 1 ? (
                <TXTField label="Otro" required />
              ) : null}
            </Grid>
            <Grid item xs={12} style={{ width: "100%" }}>
              {selectFalleSubsubtipo === "" ? null : selectFalleSubsubtipo ===
                  10 && selectFalleSubtipo === 2 ? (
                <TXTField label="Otro" required />
              ) : null}
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* GRID ÍCONO BENEFICIOS EN VIDA */}
      <Grid item xs={2}>
        {!imgBene ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                style={{ cursor: "pointer" }}
                onClick={() =>
                  imgBene
                    ? setImgBene(false)
                    : (setImgBene(true),
                      setImgFalle(false),
                      setImgVenci(false),
                      setImgRetir(false),
                      setImgResc(false))
                }
                src={beneficioOff}
                alt=""
                className="img-iconos-comp"
              />
            </Grid>
            <Grid item>
              <h3-contable>
                <FormattedMessage
                  id="recepBenVi"
                  defaultMessage="Beneficios en vida"
                />
              </h3-contable>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                style={{ cursor: "pointer" }}
                onClick={() =>
                  imgBene
                    ? setImgBene(false)
                    : (setImgBene(true),
                      setImgFalle(false),
                      setImgVenci(false),
                      setImgRetir(false),
                      setImgResc(false))
                }
                src={beneficioOn}
                alt=""
                className="img-iconos-comp"
              />
            </Grid>
            <Grid item>
              <h3-contable style={{ color: "#771f2a" }}>
                <FormattedMessage
                  id="recepBenVi"
                  defaultMessage="Beneficios en vida"
                />
              </h3-contable>
            </Grid>
            <Grid item xs={12} style={{ width: "100%" }}>
              <Select1
                array={arrayBeneTipo}
                label="Tipo"
                onChange={handleChangeBenetipo}
              />
            </Grid>
            <Grid item xs={12} style={{ width: "100%" }}>
              {selectBeneTipo === "" ? null : selectBeneTipo === 1 ? (
                <Select1 array={arrayBeneSubtipo} label="Subtipo" />
              ) : null}
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* GRID ÍCONO VENCIMIENTO */}
      <Grid item xs={2}>
        {!imgVenci ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                style={{ cursor: "pointer" }}
                onClick={() =>
                  imgVenci
                    ? setImgVenci(false)
                    : (setImgBene(false),
                      setImgFalle(false),
                      setImgVenci(true),
                      setImgRetir(false),
                      setImgResc(false))
                }
                src={vencimiento_off}
                alt=""
                className="img-iconos-comp"
              />
            </Grid>
            <Grid item>
              <h3-contable>
                <FormattedMessage
                  id="recepVenci"
                  defaultMessage="Vencimiento"
                />
              </h3-contable>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                style={{ cursor: "pointer" }}
                onClick={() =>
                  imgVenci
                    ? setImgVenci(false)
                    : (setImgBene(false),
                      setImgFalle(false),
                      setImgVenci(true),
                      setImgRetir(false),
                      setImgResc(false))
                }
                src={vencimiento_on}
                alt=""
                className="img-iconos-comp"
              />
            </Grid>
            <Grid item>
              <h3-contable style={{ color: "#771f2a" }}>
                <FormattedMessage
                  id="recepVenci"
                  defaultMessage="Vencimiento"
                />
              </h3-contable>
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* GRID ÍCONO RETIROS */}
      <Grid item xs={2}>
        {!imgRetir ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                style={{ cursor: "pointer" }}
                onClick={() =>
                  imgRetir
                    ? setImgRetir(false)
                    : (setImgBene(false),
                      setImgFalle(false),
                      setImgVenci(false),
                      setImgRetir(true),
                      setImgResc(false))
                }
                src={retiro_off}
                alt=""
                className="img-iconos-comp"
              />
            </Grid>
            <Grid item>
              <h3-contable>
                <FormattedMessage id="recepRetrs" defaultMessage="Retiros" />
              </h3-contable>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                style={{ cursor: "pointer" }}
                onClick={() =>
                  imgRetir
                    ? setImgRetir(false)
                    : (setImgBene(false),
                      setImgFalle(false),
                      setImgVenci(false),
                      setImgRetir(true),
                      setImgResc(false))
                }
                src={retiro_on}
                alt=""
                className="img-iconos-comp"
              />
            </Grid>
            <Grid item>
              <h3-contable style={{ color: "#771f2a" }}>
                <FormattedMessage id="recepRetrs" defaultMessage="Retiros" />
              </h3-contable>
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* GRID ÍCONO RESCATES */}
      <Grid item xs={2}>
        {!imgResc ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                style={{ cursor: "pointer" }}
                onClick={() =>
                  imgResc
                    ? setImgResc(false)
                    : (setImgBene(false),
                      setImgFalle(false),
                      setImgVenci(false),
                      setImgRetir(false),
                      setImgResc(true))
                }
                src={rescates_off}
                alt=""
                className="img-iconos-comp"
              />
            </Grid>
            <Grid item>
              <h3-contable>
                <FormattedMessage id="recepResct" defaultMessage="Rescates" />
              </h3-contable>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                style={{ cursor: "pointer" }}
                onClick={() =>
                  imgResc
                    ? setImgResc(false)
                    : (setImgBene(false),
                      setImgFalle(false),
                      setImgVenci(false),
                      setImgRetir(false),
                      setImgResc(true))
                }
                src={rescates_on}
                alt=""
                className="img-iconos-comp"
              />
            </Grid>
            <Grid item>
              <h3-contable style={{ color: "#771f2a" }}>
                <FormattedMessage id="recepResct" defaultMessage="Rescates" />
              </h3-contable>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
