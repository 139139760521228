//  David Ruiz 10/2022

import React from 'react';
import IconButton from '@mui/material/IconButton';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const Btverreport=(props)=>{
    return(
        <IconButton aria-label=" ManageSearchIcon" color="icons" 
        //aqui va el enlace
        href={props.enlace}
        //ojo aqui es disabled
        //true para desabilitar y false para habilitar
        disabled={props.estatus}
        onClick={props.onClick}
        onChange={props.onChange}
        id={props.id}
        >
        < ManageSearchIcon />
        </IconButton>
        );
    }
export default Btverreport;