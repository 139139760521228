import React from "react";
import { Box, Grid, Container, IconButton } from "@mui/material";
import DialogButton from "../Tools/Dialog/DialogButton";
import PasswordTXTField from "../Tools/TextField/TextFieldPass";
import { useLocation } from "react-router-dom";
import familia from "../../img/familia.png";
import "./Login.css";
import { getUsers, putDataLogin } from "./apiLogin/getUsers";
import { BASE_API } from "../../utils/constans";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { postDataToken } from "../../api/dataToken";
import { isPassword } from "../../utils/functions";

// Idioma
import { FormattedMessage } from "react-intl";
import { useAuth } from "../../../src/hooks/useAuth";

export function CompActualizarContra() {
  const { auth, logout } = useAuth();

  const [values, setValues] = React.useState({
    passwordOld: "",
    passwordNew: "",
    passwordConfirm: "",
  });

  const [errorsPasswordNew, setErrorsPasswordNew] = React.useState({});
  const [errorsPasswordConfirm, setErrorsPasswordConfirm] = React.useState({});
  const [emailRecovery, setEmailRecovery] = React.useState({});
  const [today, setToday] = React.useState(new Date());
  const [link, setLink] = React.useState({});
  const [confirm, setConfirm] = React.useState({});
  const [formattedDate, setformattedDate] = React.useState({});
  const [valid, setvalid] = React.useState(true);
  const [validemail, setvalidemail] = React.useState(true);

  const location = useLocation();
  const email = location.state;

  // Traer la fecha del día de hoy
  React.useEffect(() => {
    const dd = today.getDate();
    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();

    const Date = yyyy + "-" + mm + "-" + dd;
    setformattedDate(Date);
    console.log("Today is: " + formattedDate);
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  // Validamos en la api si la contraseña antigua es la misma a la que escribe
  React.useEffect(() => {
    getUsers(`${BASE_API}login/get_correo/${email}/`)
      .then((data) => {
        setEmailRecovery(email);
      })
      .catch((error) => {
        console.error("There was an error:", error);
      });
  }, []);

  console.log(emailRecovery);

  // Validamos si la contraseña es correcta

  async function postUser(aux) {
    try {
      var result = await postDataToken(`login/validatepassword/`, aux);
      console.log("Registro exitoso", result);
      if (result.valid) {
        console.log("La contraseña es válida");
        setConfirm(true);
      } else {
        console.log("La contraseña es inválida");
        setConfirm(false);
      }
    } catch (error) {
      console.log(error);
      setvalid(false);
      console.log(valid);
      throw error;
    }
  }

  let aux = {
    SNYTLOG_email: emailRecovery,
    password: values.passwordOld,
  };

  postUser(aux);

  //PUT para actualizar contraseña

  async function updatePassword(aux) {
    try {
      var result = await putDataLogin(`login/put/${emailRecovery}/`, aux);
      console.log("Actualizado correctamente", result);
    } catch (error) {
      console.log(error);
      setvalidemail(false);
      console.log(validemail);
      throw error;
    }
  }

  const validateUpdatePassword = (e) => {
    e.preventDefault();

    const errorsPasswordNew = {};
    const errorsPasswordConfirm = {};
    const link = {};

    errorsPasswordNew.password = (
      <FormattedMessage id="fueServ" defaultMessage="Fuera de servicio" />
    );
    errorsPasswordConfirm.password = (
      <FormattedMessage
        id="peIntTar"
        defaultMessage="No es posible realizar su petición en este momento, intente más tarde."
      />
    );

    if (!isPassword(values.passwordOld) || !isPassword(values.passwordNew)) {
      errorsPasswordNew.password = (
        <FormattedMessage
          id="globErrorEnContrasen"
          defaultMessage="Error en contraseñas"
        />
      );
      errorsPasswordConfirm.password = (
        <FormattedMessage
          id="globLasCon"
          defaultMessage="Las contraseñas no cumplen con el formato."
        />
      );
    } else if (confirm === false) {
      errorsPasswordNew.password = (
        <FormattedMessage
          id="globErrorEnContrasen"
          defaultMessage="Error en contraseñas"
        />
      );
      errorsPasswordConfirm.password = (
        <FormattedMessage
          id="contAntMsg"
          defaultMessage="La contraseña antigua no coincide, por favor verifica e intenta de nuevo."
        />
      );
    } else if (valid === false) {
      errorsPasswordNew.password = (
        <FormattedMessage id="fueServ" defaultMessage="Fuera de servicio" />
      );
      errorsPasswordConfirm.password = (
        <FormattedMessage
          id="peIntTar"
          defaultMessage="No es posible realizar su petición en este momento, intente más tarde."
        />
      );
    } else if (
      confirm === true &&
      values.passwordNew === values.passwordConfirm
    ) {
      let aux = {
        SNYTLOG_email: emailRecovery,
        password: values.passwordConfirm,
        SNYTLOG_actualizofecha: today,
      };
      updatePassword(aux);
      if (validemail === false) {
        errorsPasswordNew.password = (
          <FormattedMessage id="fueServ" defaultMessage="Fuera de servicio" />
        );
        errorsPasswordConfirm.password = (
          <FormattedMessage
            id="peIntTar"
            defaultMessage="No es posible realizar su petición en este momento, intente más tarde."
          />
        );
      } else {
        logout();
        errorsPasswordNew.password = (
          <FormattedMessage
            id="globActualizacionExi"
            defaultMessage="Actualización exitosa"
          />
        );
        errorsPasswordConfirm.password = (
          <>
            <FormattedMessage
              id="globSeActu"
              defaultMessage="Se actualizó correctamente la contraseña."
            />
            <br />
            <FormattedMessage
              id="globIniciaSesionPara"
              defaultMessage="Inicia sesión para continuar."
            />
          </>
        );
        values.passwordOld = "";
        values.passwordNew = "";
        values.passwordConfirm = "";
        link.password = "/";
      }
    } else {
      errorsPasswordNew.password = (
        <FormattedMessage
          id="globErrorAlActualiza"
          defaultMessage="Error al actualizar contraseña"
        />
      );
      errorsPasswordConfirm.password = (
        <FormattedMessage
          id="globLasContraseNasNo"
          defaultMessage="Las contraseñas no coinciden por favor verifica e intenta de nuevo."
        />
      );
    }

    setErrorsPasswordNew(errorsPasswordNew);
    setErrorsPasswordConfirm(errorsPasswordConfirm);
    setLink(link);
  };

  return (
    <form onSubmit={validateUpdatePassword}>
      <div className="div-parent">
        <Box px={{ xs: 4, sm: 10 }} py={{ xs: 1, sm: 5 }}>
          <Container maxWidth="lg">
            <Grid container spacing={5}>
              <Grid item xs={12} sm={5}>
                <Box>
                  <IconButton
                    id="iconRegresar"
                    size="large"
                    color="primary"
                    href="/"
                  >
                    <ArrowBackIcon fontSize="inherit" />
                  </IconButton>
                  <br />
                  <br />
                  <h2-login-title>
                    <FormattedMessage
                      id="loginActCo"
                      defaultMessage="Actualizar contraseña"
                    />
                  </h2-login-title>
                  <hr class="hr-line" align="left" />
                  <p align="justify">
                    <h3-login-subtitle>
                      <FormattedMessage
                        id="loginIngreUnaConRecu"
                        defaultMessage="Ingresa una nueva contraseña, recuerda que debe tener mínimo 8 caracteres con una letra mayúscula, minúscula, un número y un carácter."
                      />
                    </h3-login-subtitle>
                  </p>
                  <PasswordTXTField
                    id="lblConAnti"
                    label={
                      <FormattedMessage
                        id="globCoAnta"
                        defaultMessage="Contraseña antigua"
                      />
                    }
                    type="password"
                    size="small"
                    required={true}
                    onChange={handleChange("passwordOld")}
                    value={values.passwordOld}
                  />
                  <PasswordTXTField
                    id="lblConNue"
                    label={
                      <FormattedMessage
                        id="loginActNu"
                        defaultMessage="Contraseña nueva"
                      />
                    }
                    type="password"
                    size="small"
                    required={true}
                    onChange={handleChange("passwordNew")}
                    value={values.passwordNew}
                  />
                  <PasswordTXTField
                    id="lblConfContra"
                    label={
                      <FormattedMessage
                        id="loginCofCo"
                        defaultMessage="Confirmar contraseña"
                      />
                    }
                    type="password"
                    size="small"
                    required={true}
                    onChange={handleChange("passwordConfirm")}
                    value={values.passwordConfirm}
                  />
                  <br></br>
                  <br></br>
                  <br></br>
                  <Grid container spacing={12}>
                    <Grid item xs={1} sm={5}>
                      <Box></Box>
                    </Grid>
                    <Grid item xs={1} sm={2}>
                      <Box></Box>
                    </Grid>
                    <Grid item xs={1} sm={4}>
                      <Box>
                        <DialogButton
                          id1="btnAceptar"
                          id2="btnActAcep"
                          mainButton={
                            <FormattedMessage
                              id="globAceptr"
                              defaultMessage="Aceptar"
                            />
                          }
                          title={errorsPasswordNew.password}
                          description={errorsPasswordConfirm.password}
                          button={
                            <FormattedMessage
                              id="globAceptr"
                              defaultMessage="Aceptar"
                            />
                          }
                          type="submit"
                          to={link.password}
                          estatus={
                            values.passwordOld.length > 0 &&
                            values.passwordNew.length > 0 &&
                            values.passwordConfirm.length > 0
                              ? false
                              : true
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Box>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <img
                    src={familia}
                    alt="Generando confianza en tus aplicaciones"
                    width="150%"
                    height="150%"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box></Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
    </form>
  );
}
