import React from "react";
import { CompMenuPrincipal } from "../../../components/MenuPrincipal/CompMenuPrincipal";


export function MenuPrincipal() {
  return (
    <div>
   
      <CompMenuPrincipal />
    
    </div>
  );
}
