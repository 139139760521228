import * as React from "react";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TXTField from "../../../Tools/TextField/TextField";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { SelectDictamen } from "../../../Tools/Selectss/SelectDictamen";
import { getDataToken, putDataToken } from "../../../../api/dataToken";
import { LimitarTexto } from "../../../Recepcion/Elements/Functions/Validaciones";
import { FormattedMessage } from "react-intl";
import getDate from "../../../Registro/Function/obtenerFecha";
import moment from "moment";
import getHours from "../../../Registro/Function/obtenerHora";
import { formatofecha } from "../../../../api/getUmbrales";
import DialogAviso from "../../../Registro/Elements/Dialog/DialogAviso";

const CardDatosReclamacionRetiro = (props) => {
  const idFolio = props.idFolio;
  const polizas = props.polizas;
  console.log(polizas);

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");
  console.log(moment(fechaEmpAn).format(formatofecha));
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();
  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;
  console.log("fechaActSIN" + fechaActSIN);

  const [Data, setData] = React.useState([]);
  const [idcomplejidad, setidcomplejidad] = React.useState("");
  //Estados para guardar los datos de clasificación
  const [clasificacion, setClasificacion] = useState([]);
  const [idclasificacion, setIdclasificacion] = useState("");
  //Obtener usuario.
  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");
  const getData = async () => {
    try {
      var result = await getDataToken(`siniestros/get_folio/${idFolio}/`);
      setData(result);
      setidcomplejidad(
        result[0]?.SINC_idcomplejidadsiniestro?.SINC_idcomplejidadsiniestro
      );
      setIdclasificacion(
        result[0]?.SINC_idcomplejidadsiniestro?.SINC_idcomplejidadsiniestro
      );
      var resultAnalista = await getDataToken(`login/get_correo/${email}/`);
      setnombreAnalista(
        resultAnalista.SNYTLOG_username +
          " " +
          resultAnalista.SNYTLOG_surnamedad +
          " " +
          resultAnalista.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  console.log(Data);
  console.log(idcomplejidad);

  // Traemos los datos de clasificación para llenar el combo
  const getComplejidad = async (idcomplejidad) => {
    try {
      var result = await getDataToken(`complejidadsiniestros/get_listado/`);
      // Utilizar un conjunto para evitar duplicados
      const idComplejidadSet = new Set();

      // Filtrar la oficina deseada y el resto
      const filteredComplejidad = result.reduce((filteredArray, element) => {
        const idComplejidad = element?.SINC_idcomplejidadsiniestro;

        if (!idComplejidadSet.has(idComplejidad)) {
          idComplejidadSet.add(idComplejidad);

          if (idComplejidad === idcomplejidad) {
            // Colocar la oficina deseada en la posición 0
            filteredArray.unshift({
              ...element,
              identificador: 0,
              texto: element?.SINC_complejidad,
            });
          } else {
            filteredArray.push({
              ...element,
              identificador: idComplejidad,
              texto: element?.SINC_complejidad,
            });
          }
        }

        return filteredArray;
      }, []);

      setClasificacion(filteredComplejidad);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getComplejidad(idcomplejidad);
  }, [idcomplejidad]);

  //Estado donde se guardara el dato
  const [plataforma, setPlataforma] = useState("");

  const handleChangeclasi = (e) => {
    const dataClasificacion = parseInt(e.target.value);

    // Verificar si el usuario seleccionó la posición 0
    if (dataClasificacion === 0) {
      setIdclasificacion(idcomplejidad);
      return; // Sale de la función porque no hay necesidad de continuar
    }

    clasificacion.forEach((element) => {
      if (dataClasificacion === element.SINC_idcomplejidadsiniestro) {
        setIdclasificacion(element.SINC_idcomplejidadsiniestro);
        setPlataforma(element.SINC_complejidad.toLowerCase());
      }
    });
  };

  console.log("Id clasificación " + idclasificacion);
  console.log("Plataforma " + plataforma);
  console.log(clasificacion);

  ///////////////////////////////////////////////
  const [values, setValues] = React.useState({
    SIN_causadereclamacion: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  React.useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      SIN_causadereclamacion: Data[0]?.SIN_causadereclamacion,
    }));
  }, [Data]);

  //contantes para editar información
  const [editCampos, setEditCampos] = useState(true);
  const editarCampos = (e) => {
    setEditCampos(false);
  };

  //Alerta de confirmacion
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    // Actualizar el estado utilizando el hook useState
    setVisible(false);
  };

  return (
    <Grid container>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          Data.forEach((siniestros) => {
            let siniestro = {
              SIN_causadereclamacion: values.SIN_causadereclamacion,
              SINC_idcomplejidadsiniestro: idclasificacion,
              RECT_idfolio: idFolio,
              SIN_actualizofecha: fechaActSIN,
              SIN_actualizousuario: nombreAnalista,
            };
            putDataToken(
              `siniestros/get_id/${siniestros.SIN_idsiniestro}/`,
              siniestro
            ).then((data) => {
              console.log(
                `Registro siniestro ${siniestros.SIN_idsiniestro} actualizado`
              );
            });
          });
          setVisible(true);
        }}
      >
        <div>
          {visible && (
            <DialogAviso
              title="Registro actualizado"
              firstButton={
                <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
              }
              description={"Reclamación actualizada correctamente"}
              onClick1={handleClick}
            />
          )}
          <div>
            <Grid container>
              <Grid item xs={12}>
                <h3-title-card>
                  <FormattedMessage
                    id="regis.datosdelsiniestro.reclamacion"
                    defaultMessage="Causa de la reclamación"
                  />
                  :
                </h3-title-card>
                <br />
                <h3-subtitle-card>
                  Motivo por el que el asegurado o beneficiario están
                  presentando la reclamación
                </h3-subtitle-card>
              </Grid>
              <Grid item xs={9} style={{ paddingTop: "9px" }}>
                <TXTField
                  id="inputEjemplo"
                  required
                  disabled={editCampos}
                  onChange={handleChange("SIN_causadereclamacion")}
                  value={values?.SIN_causadereclamacion}
                  onInput={(e) => LimitarTexto(e.target, 50)}
                />
              </Grid>
              <Grid item xs={0.5} style={{ paddingTop: "9px" }}></Grid>
              <Grid item xs={2.5} style={{ paddingTop: "9px" }}>
                <SelectDictamen
                  required
                  disabled={editCampos}
                  sx={{
                    minWidth: 210,
                    maxWidth: 210,
                    height: 55,
                    background: "white",
                  }}
                  label={
                    <FormattedMessage
                      id="globColoni"
                      defaultMessage={`Clasificación *`}
                    />
                  }
                  array={clasificacion}
                  onChange={handleChangeclasi}
                />
              </Grid>
            </Grid>
            <br />
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              style={{ paddingRight: "10px" }}
            >
              <Grid item>
                <ButtonVino
                  label="Editar"
                  onClick={(e) => editarCampos(e)}
                  color="secondary"
                />
              </Grid>
              <Grid item>
                <ButtonVino label="Guardar" type="submit" />
              </Grid>
            </Grid>
          </div>
        </div>
      </form>
    </Grid>
  );
};
export default CardDatosReclamacionRetiro;
