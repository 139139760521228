/* 
Pajita
Proyecto SINAY
2023
*/
import React from "react";
//breadcrumb
import Recorrido3 from "./Elements/Bread/Recorrido3";
//Tabla
import TableDataInsured from "./Elements/TableDataInsured";
import { Grid } from "@mui/material";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
//Estilos
import "../../css/estilo.css";
//Idioma
import { FormattedMessage } from "react-intl";
//Libreria para poder pasar información
import { useLocation } from "react-router-dom";

export function CompRegistroInicio() {
  //Variables para poder pasar información
  const location = useLocation();
  const infoRow = location.state.InfoDataRow;

  console.log("Estoy en compRegistro", infoRow);

  return (
    <div className="div-parent">
      <div className="div-breadcrumbs">
        {/*navegador*/}
        <Recorrido3
          name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
          enlace="/menu"
          name2={<FormattedMessage id="globRegist" defaultMessage="Registro" />}
          enlace2="/registro/inicio"
          name3={
            <FormattedMessage
              id="submenuIni"
              defaultMessage="Iniciar registro"
            />
          }
        />{" "}
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <ReturnPage href="/registro/inicio" />
        </Grid>
      </div>
      <div className="div-container">
        <Grid container>
          <Grid xs={12}>
            <br />
            {/*Contenido
                  Dentro de esta tabla tenemos el contenido como tal para poder hacer el cambio de vista para el cliente 
                  Estamos pasando la información que enviamos desde la primer tabla mediante la variable de infoRow
                  este puede entrar por medio de las props
                */}
            <TableDataInsured infoRow={infoRow} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
