import React from "react";
import CompPerfilCliente from "../../../components/PerfilCliente/CompPerfilCliente";



export function PerfilCliente() {
    return (
        <div>
           
            <CompPerfilCliente />
       
        </div>
    );
}