import { PerfilCliente } from '../../pages/Client/ModPerfilCliente/PerfilCliente';
import { ClientLayaout } from '../../layouts/Client/ClientLayaout';


const routesClient = [

    {
        path: "/perfil",
        layout: ClientLayaout,
        component: PerfilCliente,
        exact: true,
    },
];

export default routesClient;