/* Brandon Hernandez Rocha 11/2022 */
import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import { FormattedMessage } from "react-intl";
import "../dictamen.css"
import ButtonVino from '../../Tools/Bottones/ButtonVino'
import { Link } from 'react-router-dom';
import { CardDetalleFolio } from './Cards/CardDetalleFolio';

export function DetalleFolio(props) {
    const infoDataRow = props.infoRow
    const row = infoDataRow
    const folio = row[0]?.RECT_idfolio
    return (
        <div>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start" style={{marginBottom:'20px'}}>
                <Link style={{textDecoration  : 'none'}} to="/dictamen/detalle_folio/folio" state= {{row, folio}}><ButtonVino color="secondary" label={<FormattedMessage id="menu.dictamen" defaultMessage="Dictamen"/>}/></Link>
            </Grid>
            <CardDetalleFolio infoRow={row}/>
        </div>
    )
}