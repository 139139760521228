import * as React from "react";
import { useState, useEffect } from "react";
//* Table;
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { FormattedMessage } from "react-intl";
//* Card
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

//*CSV
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

import { useCSVReader } from "react-papaparse";
import { lightenDarkenColor, formatFileSize } from "react-papaparse";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
//* Stepper
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { getToken } from "../../../../api/token";
import { BASE_API } from "../../../../utils/constans";

//*Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

const steps = [
  {
    label: (
      <FormattedMessage
        id="conGDescarCSV"
        defaultMessage="Descarga el formato CSV"
      />
    ),
    description: (
      <FormattedMessage
        id="conGFavorCSV"
        defaultMessage="Favor de descargar el formato de archivo CSV, con el cual podrá 
                  hacer la carga de datos de su Policy Master a nuestra Base de 
                  Datos SINAY."
      />
    ),
  },
  {
    label: (
      <FormattedMessage id="conGLLeForm" defaultMessage="Llenado de formato" />
    ),
    description: (
      <FormattedMessage
        id="conGFaFormat"
        defaultMessage="Favor de llenar el formato que se ha descargado (Sin modificar el formato) 
                  con sus campos respectivos para la carga a la Base de Datos."
      />
    ),
  },
  {
    label: (
      <FormattedMessage
        id="conGCargaCSV"
        defaultMessage="Cargar de archivo CSV"
      />
    ),
    description: (
      <FormattedMessage
        id="conGSubCSV"
        defaultMessage="Sube el documento CSV con extensión (.csv) para previsualizar los 
                  los datos y posteriormente si se desea realizar la carga a la 
                  Base de Datos SINAY."
      />
    ),
  },
];

const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const styles2 = {
  zone: {
    alignItems: "center",
    border: `2px dashed #ccc`,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 20,
    position: "sticky",
  },
  file: {
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 120,
    width: 120,
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: "0.5em",
    justifyContent: "center",
    display: "flex",
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: "0.5em",
  },
  progressBar: {
    bottom: 14,
    position: "absolute",
    width: "85%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: "#686868",
  },
  default: {
    borderColor: "#ccc",
  },
  remove: {
    height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23,
  },
};

export default function TableCSV() {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );
  //TODO: Table
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  //TODO: pagination Table
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //TODO: STEPPER
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //TODO: ALERT
  // const [open, setOpen] = React.useState(false);
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  //TODO: DOWNLOAD
  const handleDownload = () => {
    // Crea un nuevo libro de Excel
    const workbook = XLSX.utils.book_new();

    // // Crea una hoja de trabajo dentro del libro
    const worksheet = XLSX.utils.json_to_sheet([
      {
        "Número de poliza": "",
        "Poliza padre": "",
        "Poliza pagada hasta (YYYY-MM-DD)": "",
        "Poliza ex prima": "",
        "Poliza fondo inversión": "",
        "Poliza préstamo": "",
        "Poliza re matemática": "",
        "Poliza fallecimiento": "",
        "Poliza tipo reaseguro": "",
        "Poliza reasegurador": "",
        "Poliza reaseguro": "",
        // "Poliza por reaseguro": "",
        "Poliza prima": "",
        "Poliza fin vigencia (YYYY-MM-DD)": "",
        "Poliza fecha de emisión (YYYY-MM-DD)": "",
      },
    ]);

    // Agrega la hoja de trabajo al libro
    XLSX.utils.book_append_sheet(workbook, worksheet, "SINAY");

    // Genera el archivo Excel
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Convierte el buffer a un objeto Blob
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Descarga el archivo Blob usando FileSaver.js
    saveAs(blob, "CargaDatosSINAY.xlsx");
  };

  const handleReset = () => {
    setActiveStep(0);
    setHeaders([]);
    setRows([]);
    setPage(0);
  };

  async function cargarDatosSNY() {
    const headersFetch = new Headers();
    headersFetch.append("Content-Type", "application/json");
    headersFetch.append("Accept", "application/json");
    headersFetch.append("Authorization", `Bearer ${getToken()}`);

    // console.log("Here all:", rows);
    // console.log("Here specific row:", rows[0]);
    // console.log("Here specific cell:", rows[0][0]);
    let aux;

    const token = getToken();
    rows.map((row, index) => {
      try {
        fetch(`${BASE_API}polizas/get_listado/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(
            (aux = {
              POL_numpoliza: row[0],
              POL_polizapadre: row[1],
              POL_polizapahasta: row[2],
              POL_polizaexprima: "",
              POL_polizafoinversión: row[4],
              POL_polizapréstamo: row[5],
              POL_polizarematemática: row[6],
              POL_polizafallecimiento: row[7],
              POL_polizareasegurador: row[9],
              POL_polizareaseguro: row[10],
              POL_polizaporreaseguro: row[11],
              POL_polizaprima: row[12],
              POL_polizafinvigencia: row[13],
              POL_polizafecemisión: row[14],

              POL_polizafecpago: null,
              POL_polizatireaseguro: true,
              POL_polizagrupo: false,
              POL_activo: true,
              POL_actualizofecha: "2023-03-21",
              POL_actualizousuario: localStorage.getItem("SNYTLOG_email"),

              PRA_idpoliza: 1,
              MON_idmonedas: 1,
              EST_idestatuspoliza: 4,
              CHN_idcanales: 17,
              OF_idoficinas: 1,
              FPAG_idformasdepago: 1,
              PRO_idproductos: 2,
            })
          ),
        });
        setActiveStep(0);
        setHeaders([]);
        setRows([]);
        setPage(0);

        //   const response = await fetch(`${BASE_API}polizas/get_listado/`, {
        //     method: "POST",
        //     headers: headersFetch,
        //     body: JSON.stringify(aux),
        //   });

        //   if (response.ok) {
        //     console.log("Actualizado satisfactoriamente");
        //   }
      } catch (error) {
        console.log(error.message);
        throw error;
      }
    });
  }

  return (
    <div className="div-container">
      <Box>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === 2 ? (
                    <Typography variant="caption">
                      <FormattedMessage
                        id="conGCargaCSV"
                        defaultMessage="Último paso"
                      />
                    </Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                {index === 2 ? (
                  <div className="div-container">
                    <Card>
                      <CardHeader
                        title="Carga de datos CSV"
                        subheader="Selecciona el archivo formato CSV separado por comas para carga de los mismos."
                      />
                      <CardContent>
                        <CSVReader
                          onUploadAccepted={(resultsCSV) => {
                            setZoneHover(false);
                            resultsCSV.data.forEach(() => {
                              const newHeaders = resultsCSV.data[0];
                              const newRows = resultsCSV.data.slice(1);

                              setHeaders(newHeaders);
                              setRows(newRows);
                            });
                          }}
                          onDragOver={(event) => {
                            event.preventDefault();
                            setZoneHover(true);
                          }}
                          onDragLeave={(event) => {
                            event.preventDefault();
                            setZoneHover(false);
                          }}
                        >
                          {({
                            getRootProps,
                            acceptedFile,
                            ProgressBar,
                            getRemoveFileProps,
                            Remove,
                          }) => (
                            <>
                              <div
                                {...getRootProps()}
                                style={{
                                  ...styles2.zone,
                                  ...(zoneHover && styles2.zoneHover),
                                }}
                              >
                                {acceptedFile ? (
                                  <>
                                    <div style={styles2.file}>
                                      <div style={styles2.info}>
                                        <span style={styles2.size}>
                                          {formatFileSize(acceptedFile.size)}
                                        </span>
                                        <span style={styles2.name}>
                                          {acceptedFile.name}
                                        </span>
                                      </div>
                                      <div style={styles2.progressBar}>
                                        <ProgressBar />
                                      </div>
                                      <div
                                        {...getRemoveFileProps()}
                                        style={styles2.remove}
                                        onMouseOver={(event) => {
                                          event.preventDefault();
                                          setRemoveHoverColor(
                                            REMOVE_HOVER_COLOR_LIGHT
                                          );
                                        }}
                                        onMouseOut={(event) => {
                                          event.preventDefault();
                                          setRemoveHoverColor(
                                            DEFAULT_REMOVE_HOVER_COLOR
                                          );
                                        }}
                                      >
                                        <Remove color={removeHoverColor} />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <FormattedMessage
                                    id="conGArrCSV"
                                    defaultMessage="Arrastra el archivo CSV aqui o click para cargar"
                                  />
                                )}
                              </div>
                            </>
                          )}
                        </CSVReader>
                      </CardContent>
                    </Card>
                    <br />

                    <TableContainer>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead style={{ position: "sticky" }}>
                          <TableRow>
                            {headers.map((header) => (
                              <StyledTableCell key={headers.length}>
                                {header}
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => (
                              <TableRow key={row.length}>
                                {row.map((cell) => (
                                  <StyledTableCell>{cell}</StyledTableCell>
                                ))}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                      {rows.length > 0 ? (
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={rows.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      ) : (
                        <></>
                      )}
                      {rows.length > 0 ? (
                        <>
                          <div style={{ display: "flex" }}>
                            <ButtonVino
                              variant="contained"
                              color="primary"
                              size="small"
                              label={
                                <FormattedMessage
                                  id="conGLimDa"
                                  defaultMessage="Limpiar los datos"
                                />
                              }
                              type="submit"
                              onClick={() => {
                                setHeaders([]);
                                setRows([]);
                                setPage(0);
                              }}
                            />
                            <Typography> &nbsp;&nbsp;&nbsp;&nbsp; </Typography>
                            <ButtonVino
                              variant="contained"
                              color="primary"
                              size="small"
                              label={
                                <FormattedMessage
                                  id="conGCarSin"
                                  defaultMessage="Cargar a SINAY"
                                />
                              }
                              type="submit"
                              onClick={() => {
                                cargarDatosSNY();
                              }}
                            />
                          </div>
                          <br />
                          <br />
                        </>
                      ) : (
                        <></>
                      )}
                    </TableContainer>
                  </div>
                ) : (
                  <></>
                )}
                <Box sx={{ mb: 2 }}>
                  <div>
                    {index === 0 && (
                      <Button
                        variant="contained"
                        onClick={handleDownload}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Descargar
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {index === steps.length - 1 ? "Finalizar" : "Continue"}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      ATRÁS
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>
              Todos los pasos han sido finalizados - haz terminado.
            </Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reiniciar pasos
            </Button>
          </Paper>
        )}
      </Box>

      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¡Actualizado satisfactoriamente!"}
        </DialogTitle>
        <DialogActions>
          <ButtonVino
            variant="contained"
            color="primary"
            size="medium"
            label="Aceptar"
            onClick={handleClose}
            autoFocus
          />
        </DialogActions>
      </Dialog> */}
    </div>
  );
}
