import React, { useState, useEffect } from 'react'
import moment from 'moment';
import "./stilachos.css"
import "../../../../css/estilo.css"
//botones
//RETURN
import { Grid } from "@mui/material";
import ReturnPage from "../../../Tools/Bottones/ButtonReturn";
import { putDataToken } from '../../../../api/dataToken';
//idioma
import {FormattedMessage} from 'react-intl';
import {Select1} from "../../../Tools/Selectss/Select1";
import {formatofecha} from "../../../../api/getUmbrales";
import  DialogButton  from "../../../Tools/Dialog/DialogButton"
//Array select 
const arrayFormato = [{ "identificador": 1, "texto": moment().format('DD/MM/YYYY') }, { "identificador": 2, "texto":  moment().format('DD/MM/YY') }, { "identificador": 3, "texto":  moment().format('DD/MMM/YY') }, { "identificador": 4, "texto":  moment().format('DD/MMM/YYYY') },{ "identificador": 5, "texto": moment().format("Do MMM  YY") },{ "identificador": 6, "texto": moment().format(" Do MMMM YYYY") }]



export default function Dateformat() {
const [formato, setFormato] = useState("")
let formatofechaNUEVO;

function condicion(){
if(formato == 1){formatofechaNUEVO="DD/MM/YYYY"}
else if (formato == 2){formatofechaNUEVO='DD/MM/YY'}
else if (formato == 3){formatofechaNUEVO='DD/MMM/YY'}
else if (formato == 4){formatofechaNUEVO='DD/MMM/YYYY'}
else if (formato == 5){formatofechaNUEVO=' Do MMM  YY'}
else if (formato == 6){formatofechaNUEVO=' Do MMMM YYYY'}
else{formatofechaNUEVO="DD/MMM/YY"}
}
condicion();

async function putPersonas(aux) {
  try {
    var result = await putDataToken(
      `configuraciongeneral/get_id/1/`,
      aux
    );
    console.log("Registro exitoso", result);
    // alert("Datos guardados");
  } catch (error) {
    console.log(error);
    // alert("Error al guardar los datos");
    throw error;
  }
}

const validateForm = (e) => {



  e.preventDefault();

  let formato = {
    SCG_FormatoFecha: formatofechaNUEVO,
  };

  console.log(formatofechaNUEVO)
  console.log(formato)
  putPersonas(formato);
};



  return (
    <div>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
        <ReturnPage href='/confadmin'/>
    </Grid>

    <div className='parentformat'>
    
    <div className='dateformat1'>
    <h3-title-card><FormattedMessage id="Hora" defaultMessage="HORA y FECHA ACTUAL DE SU PC"/></h3-title-card>
        <h3-subtitle-card>
        {moment().format('LLLL')}
        </h3-subtitle-card>
    </div>
    <div className='dateformat2'>
    <Select1 sx={{ maxWidth: 350 , minWidth:310 }} array={arrayFormato}  label="Formato Fecha" onChange={e => setFormato(e.target.value)} />
    </div>
    <div className='dateformat3'>
    <h3-title-card><FormattedMessage id="Hora" defaultMessage="FORMATO DE FECHA ACTUAL"/></h3-title-card>
        <h3-subtitle-card>
        {moment().format(formatofecha)}
        </h3-subtitle-card>
    </div>
    <div className='dateformat4'>
    <form form onClick={validateForm}>
      <DialogButton
       title="Guardar"
       enlace="/confadmin"
       button="Aceptar"
      //  onSubmit={validateForm}
       description="Formato Guardado es "
       description2= {moment().format(formatofechaNUEVO)}
       mainButton="Guardar"
      
      />
         </form>
    
    </div>
   </div>
   </div>
   
  );
}
