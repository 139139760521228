export default function getHours() {
  //Obtener la hora del sistema.
  const ahora = new Date();
  const hora = ahora.getHours();
  const minutos = ahora.getMinutes();
  const segundos = ahora.getSeconds();
  const horaActual = `${hora}:${minutos}:${segundos}`;

  return horaActual;
}
