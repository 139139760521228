import React from "react";
import { Negociogeneral } from '../../../../components/GeneralConf/NegocioConf/Negociogeneral';


export function nego1() {
    return (
        <div>
    
            < Negociogeneral />
           
        </div>
    );
}