import React from "react";
import { Box, Grid, Container, Link } from "@mui/material";
import TXTField from "../Tools/TextField/TextField";
import PasswordTXTField from "../Tools/TextField/TextFieldPass";
import familia from "../../img/familia.png";
import DialogButtonLogin from "./Elements/DialogButtonLogin";
import "./Login.css";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { loginApi } from "../../api/user";
import { useAuth } from "../../hooks/useAuth";
import { getUsers, putDataLogin } from "./apiLogin/getUsers";
import { BASE_API, DOMINIOEMPRESARIAL } from "../../utils/constans";
import validateEmail from "./Elements/validateEmail";
import {
  ADMINVIEW,
  ADMINEDIT,
  ADMINADD,
  CONFGENERALADD,
  CONFGENERALVIEW,
  CONFGENERALCHAN,
  CONFEMAILVIEW,
  CONFEMAILADD,
  CONFEMAILCHAN,
  ADMINISTRATCIONDEUSUARIOSVIEW,
  ADMINIESTRACIONDEUSUARIOSADD,
  ADMINISTRACIONDEUSUARIOSCHANGE,
} from "../../utils/constanstoken";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { LimitarTexto2 } from "../Recepcion/Elements/Functions/Validaciones";

export function CompLogin() {
  const [msgTitle, setMsgTitle] = React.useState("SINAY");
  const [msgSubtitle, setMsgSubtitle] = React.useState(
    <FormattedMessage id="loginDeseos" defaultMessage="Administrando deseos." />
  );
  const [msgSubsubtitle, setMsgSubsubtitle] = React.useState("");
  const [estatus, setEstatus] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [emailRecuperado, setEmailRecuperado] = React.useState("");
  const [data, setdata] = React.useState([]);
  const [intentos, setintentos] = React.useState("");
  const [Successful, setSuccessful] = React.useState(true);
  let title = {};
  let subtitle = {};
  let subsubtitle = {};
  console.log(intentos);

  function handleInput(evento) {
    setEmail(evento.target.value);
    LimitarTexto2(evento.target, 50);
  }

  const getUser = async (email) => {
    try {
      const response = await getUsers(`${BASE_API}login/get_correo/${email}/`);
      console.log(response);
      setEstatus(response.SNYTLOG_estatus);
      setintentos(response.SNYTLOG_intentos);
      setdata(response);
      setSuccessful(true);
      setEmailRecuperado(response.SNYTLOG_email);
    } catch (error) {
      setSuccessful(false);
      console.error("There was an error:", error);
    }
  };
  console.log(Successful);
  console.log(emailRecuperado);
  console.log(estatus);

  // Llamada a la API cuando el componente se monta
  React.useEffect(() => {
    if (email) {
      getUser(email);
    }
  }, [email]); // Se ejecutará solo cuando el valor de 'email' cambie

  const funcionActualizar = () => {
    console.log("entro");
    getUser(emailRecuperado);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevenir el comportamiento predeterminado del formulario

    await funcionActualizar(); // Asegurar que funcionActualizar se ejecute y complete

    formik.handleSubmit(); // Luego, llamar al submit de Formik
  };

  // Validaciones login

  const { login } = useAuth();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: intialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formValue) => {
      // Verificamos que el correo tenga el dominio correcto
      var esValido = validateEmail(email, DOMINIOEMPRESARIAL);
      try {
        title = (
          <FormattedMessage id="loginCarga" defaultMessage="Cargando........" />
        );
        subtitle = (
          <FormattedMessage
            id="loginRed"
            defaultMessage="Se está redirigiendo al apartado de inicio."
          />
        );
        subsubtitle = "";
        if (estatus && esValido && Successful) {
          let aux = {
            SNYTLOG_email: email,
            SNYTLOG_intentos: 1,
          };
          putDataLogin(`login/putall/${email}/`, aux).then((data) => {
            console.log(`Registro actualizado correctamente`, data);
          });
          const response = await loginApi(formValue);
          const { access } = response;
          login(access);
          title = (
            <FormattedMessage
              id="loginCarga"
              defaultMessage="Cargando........"
            />
          );
          subtitle = (
            <FormattedMessage
              id="loginRed"
              defaultMessage="Se está redirigiendo al apartado de inicio."
            />
          );
          subsubtitle = "";
          if (data.SNYTLOG_gestordecompetencias === true) {
            navigate("/menu");
            localStorage.setItem("SNYTLOG_email", formValue.SNYTLOG_email);
          } else if (
            data.groups.includes(ADMINVIEW) ||
            data.groups.includes(ADMINEDIT) ||
            data.groups.includes(ADMINADD) ||
            data.groups.includes(CONFGENERALADD) ||
            data.groups.includes(CONFGENERALVIEW) ||
            data.groups.includes(CONFGENERALCHAN) ||
            data.groups.includes(CONFEMAILVIEW) ||
            data.groups.includes(CONFEMAILADD) ||
            data.groups.includes(CONFEMAILCHAN) ||
            data.groups.includes(ADMINISTRATCIONDEUSUARIOSVIEW) ||
            data.groups.includes(ADMINIESTRACIONDEUSUARIOSADD) ||
            data.groups.includes(ADMINISTRACIONDEUSUARIOSCHANGE)
          ) {
            navigate("/menuadmin");
            localStorage.setItem("SNYTLOG_email", formValue.SNYTLOG_email);
          } else {
            navigate("/");
            localStorage.setItem("SNYTLOG_email", formValue.SNYTLOG_email);
          }
        } else if (!esValido) {
          title = (
            <FormattedMessage
              id="globCorreo"
              defaultMessage="Correo inválido"
            />
          );
          subtitle = (
            <FormattedMessage
              id="globElCorr"
              defaultMessage="El correo no cumple con el formato empresarial."
            />
          );
          subsubtitle = "";
        } else if (!Successful & esValido & !estatus) {
          title = (
            <FormattedMessage
              id="globCorreo"
              defaultMessage="Correo inválido"
            />
          );
          subtitle = (
            <FormattedMessage
              id="globCoNoReg"
              defaultMessage="Tu cuenta no esta registrada"
            />
          );
          subsubtitle = "";
        } else if (!estatus && esValido) {
          title = (
            <FormattedMessage
              id="globErrordeAutentifi"
              defaultMessage="Error de autentificación"
            />
          );
          subtitle = (
            <FormattedMessage
              id="logPri"
              defaultMessage="Tu cuenta esta bloqueada o no tienes privilegios."
            />
          );
          subsubtitle = (
            <FormattedMessage
              id="logCom"
              defaultMessage="Comunicate con el responsable de TI"
            />
          );
        } else {
          title = (
            <FormattedMessage id="fueServ" defaultMessage="Fuera de servicio" />
          );
          subtitle = (
            <FormattedMessage
              id="peIntTar"
              defaultMessage="No es posible realizar su petición en este momento, intente más tarde."
            />
          );
        }
      } catch (error) {
        toast.error(error.message);
        if (intentos === 1) {
          let aux = {
            SNYTLOG_email: email,
            SNYTLOG_intentos: 2,
          };
          putDataLogin(`login/putall/${email}/`, aux).then((data) => {
            console.log(`Registro actualizado correctamente`, data);
          });
          title = (
            <FormattedMessage
              id="globErrordeAutentifi"
              defaultMessage="Error de autentificación"
            />
          );
          subtitle = (
            <FormattedMessage
              id="globPorFavorVerifica"
              defaultMessage="Por favor verifica que el usuario y contraseña sean correctos."
            />
          );
          subsubtitle = (
            <FormattedMessage
              id="globPorTuSeguridadQu"
              defaultMessage="Por tu seguridad la cuenta se bloqueará después de tres intentos fallidos."
            />
          );
        } else if (intentos === 2) {
          let aux = {
            SNYTLOG_email: email,
            SNYTLOG_intentos: 3,
          };
          putDataLogin(`login/putall/${email}/`, aux).then((data) => {
            console.log(`Registro actualizado correctamente`, data);
          });
          title = (
            <FormattedMessage
              id="globErrordeAutentifi"
              defaultMessage="Error de autentificación"
            />
          );
          subtitle = (
            <FormattedMessage
              id="globPorFavorVerifica"
              defaultMessage="Por favor verifica que el usuario y contraseña sean correctos."
            />
          );
          subsubtitle = (
            <FormattedMessage
              id="bloInt"
              defaultMessage="Por tu seguridad la cuenta se bloqueará después de tres intentos fallidos. Intento 1."
            />
          );
        } else if (intentos === 3) {
          let aux = {
            SNYTLOG_email: email,
            SNYTLOG_intentos: 4,
          };
          putDataLogin(`login/putall/${email}/`, aux).then((data) => {
            console.log(`Registro actualizado correctamente`, data);
          });
          title = (
            <FormattedMessage
              id="globErrordeAutentifi"
              defaultMessage="Error de autentificación"
            />
          );
          subtitle = (
            <FormattedMessage
              id="globPorFavorVerifica"
              defaultMessage="Por favor verifica que el usuario y contraseña sean correctos."
            />
          );
          subsubtitle = (
            <FormattedMessage
              id="bloqInt"
              defaultMessage="Intento 2. Si ingresas una vez la contraseña incorrecta tu usuario y contraseña se bloqueará."
            />
          );
        } else if (intentos === 4) {
          let aux = {
            SNYTLOG_email: email,
            SNYTLOG_intentos: 5,
            SNYTLOG_estatus: false,
          };
          putDataLogin(`login/putall/${email}/`, aux).then((data) => {
            console.log(`Registro actualizado correctamente`, data);
          });
          title = (
            <FormattedMessage
              id="globCuenta"
              defaultMessage="Cuenta bloqueada"
            />
          );
          subtitle = (
            <FormattedMessage
              id="suInt"
              defaultMessage="Intento 3, superaste el número de intentos permitidos."
            />
          );
          subsubtitle = (
            <FormattedMessage
              id="notSup"
              defaultMessage="Notifica a tu supervisor para solicitar el desbloqueo de tu cuenta."
            />
          );
        } else if (intentos >= 5) {
          let aux = {
            SNYTLOG_email: email,
            SNYTLOG_estatus: false,
          };
          putDataLogin(`login/putall/${email}/`, aux).then((data) => {
            console.log(`Registro actualizado correctamente`, data);
          });
          title = (
            <FormattedMessage
              id="globErrordeAutentifi"
              defaultMessage="Error de autentificación"
            />
          );
          subtitle = (
            <FormattedMessage
              id="logPri"
              defaultMessage="Tu cuenta esta bloqueada o no tienes privilegios."
            />
          );
          subsubtitle = (
            <FormattedMessage
              id="logCom"
              defaultMessage="Comunicate con el responsable de TI"
            />
          );
        }
      }
      setMsgTitle(title);
      setMsgSubtitle(subtitle);
      setMsgSubsubtitle(subsubtitle);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <div className="div-parent">
        <Box px={{ xs: 4, sm: 10 }} py={{ xs: 1, sm: 10 }}>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12} sm={12}></Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  <h2-login-title>
                    <FormattedMessage
                      id="loginIngre"
                      defaultMessage="Ingresar"
                    />
                  </h2-login-title>
                  <hr class="hr-line" align="left" />
                  <br />
                  <TXTField
                    value={formik.values.SNYTLOG_email}
                    onChange={formik.handleChange}
                    error={formik.errors.SNYTLOG_email}
                    label={
                      <FormattedMessage
                        id="loginCorEm"
                        defaultMessage="Correo electrónico empresarial"
                      />
                    }
                    size="small"
                    id="SNYTLOG_email"
                    required={true}
                    onInput={handleInput}
                  />
                  <PasswordTXTField
                    id="password"
                    label={
                      <FormattedMessage
                        id="globContra"
                        defaultMessage="Contraseña"
                      />
                    }
                    size="small"
                    required={true}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.errors.password}
                  />
                  <br />
                  <br />
                  <br />
                </Box>
                <Grid container>
                  <Grid item xs={1}>
                    <Box>
                      <DialogButtonLogin
                        id1="btnAceptar"
                        id2="btnActAcep"
                        mainButton={
                          <FormattedMessage
                            id="loginEntra"
                            defaultMessage="Entrar"
                          />
                        }
                        button={
                          <FormattedMessage
                            id="globAceptr"
                            defaultMessage="Aceptar"
                          />
                        }
                        type="submit"
                        title={msgTitle}
                        description={msgSubtitle}
                        description2={msgSubsubtitle}
                        onClick1={funcionActualizar}
                        onClick2={funcionActualizar}
                        estatus={
                          formik.values.SNYTLOG_email.length > 0 &&
                          formik.values.password.length > 0
                            ? false
                            : true
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box />
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <link-forgot-password>
                        <Link
                          id="btnOlvContra"
                          color="inherit"
                          underline="none"
                          href="/Recuperar-Contra"
                        >
                          <FormattedMessage
                            id="loginOlvCo"
                            defaultMessage="Olvidé mi contraseña"
                          />
                        </Link>
                      </link-forgot-password>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box>
                  <h3-login-subtitle>
                    <Link
                      id="btnCrearCu"
                      color="inherit"
                      underline="none"
                      href="/Registro"
                    >
                      <FormattedMessage
                        id="loginCreCu"
                        defaultMessage="Crear cuenta"
                      />
                    </Link>
                  </h3-login-subtitle>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  <img
                    src={familia}
                    alt="Generando confianza en tus aplicaciones"
                    width="150%"
                    height="150%"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
    </form>
  );
}

function intialValues() {
  return {
    SNYTLOG_email: "",
    password: "",
  };
}

function validationSchema() {
  return {
    SNYTLOG_email: Yup.string().email(true).required(true),
    password: Yup.string().required(true),
  };
}
