import * as React from "react";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { BASE_API_SINSLSH } from "../../../utils/constans";
import "../../../css/Sty.css";
import { getDataTokenconstante } from '../../../api/dataToken';
// import { getToken } from "../../../api/token";
import { TOKENLOGIN } from "../../../utils/constanstoken";
// import { getUsers } from "../../Login/apiLogin/getUsers";
// import { BASE_API } from "../../../utils/constans";
// const token = TOKENLOGIN;


//! /confadmin


export default function NavAppBar() {

  // const [datos, setDatos] = useState([]);

  // getUsers(`${BASE_API}configuraciongeneral/get_id/1/`)
  // .then((data) => {
  //     setDatos(data);
  // })
  // .catch((error) => {
  //   console.error("There was an error:", error);
  // });


  const [datos, setDatos] = useState([]);

  const getDataPermisos = async () => {

    //Resultados de la api investigacion
    var resultPermisos = await getDataTokenconstante("configuraciongeneral/get_id/1/");    
    setDatos(resultPermisos);
    
  }
  useEffect(() => {
    getDataPermisos()  
  }, [])
 
  return (
    //imagen
    <div className="header">
      <Grid container style={{ height: 125 }}>
        <Grid item xs={1}>
          <img
            src={`${BASE_API_SINSLSH}${datos.SCG_imgPathSing}`}
            style={{ paddingLeft: 40 }}
            className="logo-qait"
            alt="logo"
            width="125px"
          ></img>
        </Grid>
        <Grid item xs={2}> 
          {/* AQUI SE PUEDE AGREGAR UNA VARIABLE QUE RECIBA EL COLOR QUE SE DESEE, POR EL MOMENTO ES ESTATICO */}
          <div
            className="div-trapeze1"
            style={{ backgroundColor: datos.SCG_primaryColor }}
            // style={{ backgroundColor: "#771f2a" }}
          ></div>
        </Grid>
        <Grid item xs={1}>
          {/* EL COLOR DEL TRAPECIO2 Y LOS RECTANGULOS DEBEN SER LOS MISMOS */}
          <div
            className="2"
            style={{ backgroundColor: datos.SCG_secundaryColor }}
            // style={{ backgroundColor: "#00203b" }}
          ></div>
        </Grid>
        <Grid item xs={1}>
          <div
            className="div-rectangle1"
            style={{ backgroundColor: datos.SCG_secundaryColor }}
            // style={{ backgroundColor: "#00203b" }}
          ></div>
        </Grid>
        <Grid item xs={5}>
          <div
            className="div-rectangle2"
            // style={{ backgroundColor: "#00203b" }}
            style={{ backgroundColor: datos.SCG_secundaryColor }}
          >
            <p></p>
            <br></br>
            <br></br>
            <p></p>
            <h1-header>SINAY</h1-header>
          </div>
        </Grid>
        <Grid item xs={2}>
          {/* <div className="div-panel" style={{ backgroundColor: "#00203b" }}> */}
          <div className="div-panel" style={{ backgroundColor: datos.SCG_secundaryColor }}>
            {/* AQUI EL PANEL CERRAR SESION, AJUSTES, ETC. */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
