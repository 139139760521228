import React from "react";
import CompDictamenPolOri from "../../../components/Dictamen/CompPolOrigen";



export function DictamenPolOrigen() {
    return (
        <div>
            
            <CompDictamenPolOri />
          
        </div>
    );
}