import React from "react";
import { CompRegistro } from "../../../components/Login/CompRegistro";


export function Registro() {
  return (
    <div>
    
      <CompRegistro />
  
    </div>
  );
}
