/* Brandon Hernandez Rocha 03/2023 */
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import CustomSeparatorDinamic from "../Tools/BreadCrumbs/RecorridoDinamico";
import "../../css/estilo.css";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import { Grid } from "@mui/material";
import { CardDatosReserva } from "./Elements/Cards/CardDatosReserva";
import { Link } from "react-router-dom";
//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "../Tools/Table/StyledTableCell";
import Paper from "@mui/material/Paper";

//Input table
import EditableCell from "../Tools/TextField/EditableCell";
//Botones
import ButtonVino from "../Tools/Bottones/ButtonVino";

//idioma
import { FormattedMessage } from "react-intl";
const CompReservaAjusteAcc = () => {
  const location = useLocation();
  const dataFolio = location.state.infoRow;
  const infoFolio = dataFolio;
  //Esta parte de código esta pendiente, pues se va a cambiar a dinámico
  //const balance = location.state.row.balanceMXP;
  //const moneda = location.state.row.monedaSA;

  const arrayBreadcrumbs = [
    {
      key: 1,
      name: <FormattedMessage id="globInicio" defaultMessage="Inicio" />,
      to: "/menu",
    },
    {
      key: 2,
      name: <FormattedMessage id="menuDictam" defaultMessage="Dictamen" />,
      to: "/dictamen",
    },
    {
      key: 3,
      name: (
        <FormattedMessage id="dictaDetalleF" defaultMessage="Detalle folio" />
      ),
      to: "/dictamen/detalle_folio/",
      state: { row: infoFolio },
    },
    {
      key: 3,
      name: <FormattedMessage id="dictamRese" defaultMessage="Reservas" />,
      to: "/dictamen/detalle_folio/reservas/",
      state: { row : infoFolio },
    },
  ];
  //Estados para importes a favor
  const [valuesImporteFavor, setValuesImporteFavor] = React.useState({
    iValueFondoInv: 0,
    iValueDotalCP: 0,
    iValuePrimaPD: 0,
    iValueInteresesND: 0,
    iValueDividendos: 0,
  });
  //Estados para importes a favor
  const [valuesImporteContra, setValuesImporteContra] = React.useState({
    iPrestamos: 0,
    iPrimasPC: 0,
    iPagosOtrosB: 0,
    iClausulaUP: 0,
  });
  //Estados totales
  var sumaFavor = 0;
  for (let propiedad in valuesImporteFavor) {
    sumaFavor += valuesImporteFavor[propiedad];
  }
  const [totales, setTotales] = React.useState({
    totalFavor: sumaFavor,
    totalContra: 0,
  });
  const [totalNeto, setTotalNeto] = React.useState(0);
  const [balanceTotal, setBalanceTotal] = React.useState(0);
  /**
   *
   * @param {String} event Recibe un string que proviene de una entrada de texto. Esta función sirve para
   * validar la entrada de datos y mostrar un número con formato. Ejemplo: si se ingresa 100000.55, el campo
   * de texto devuelve 100,000.55
   */
  const handleChangeImporte = (event, tipoImporte) => {
    // Obtener referencia al input utilizando su ID
    var input = document.getElementById(event.target.id);

    // reemplazar comas
    let valor = event.target.value.replace(/[^0-9.]|(?<=\..*)\./g, "");

    // Expresión regular para validar la cadena de texto tenga punto decimal y una o dos cifras a la derecha del punto
    let dosDecimales = /^\d+\.\d{1,2}$/;

    // Expresión regular para validar si la cadena de texto tiene mas de dos cifras a la derecha del punto
    let masDosDecimales = /^\d+(\.\d{3,})$/;

    // Verifica si el valor ingresado es un número, un punto o un guion medio
    if (!isNaN(parseFloat(valor)) || valor === ".") {
      if (parseFloat(valor) === NaN || valor === "") return false;
      if (valor === ".") {
        // Si el valor ingresado es e}un punto agrega un 0 antes del punto decimal.
        input.value = "0" + valor; // Cambiar el valor del input
        if (tipoImporte === 1)
          setValuesImporteFavor({
            ...valuesImporteFavor,
            [event.target.id]: parseFloat(input.value),
          });
        else if (tipoImporte == 2)
          setValuesImporteContra({
            ...valuesImporteContra,
            [event.target.id]: parseFloat(input.value),
          });
        return false;
      }
      if (valor.match(/\.(.*)/) && dosDecimales.test(valor)) {
        // valida si la cadena de texto es un numero con uno o dos decimales
        input.value = formatNumber(valor);
        if (tipoImporte === 1)
          setValuesImporteFavor({
            ...valuesImporteFavor,
            [event.target.id]: parseFloat(valor),
          });
        else if (tipoImporte === 2)
          setValuesImporteContra({
            ...valuesImporteContra,
            [event.target.id]: parseFloat(valor),
          });
      } else if (valor.match(/\.(.*)/) && masDosDecimales.test(valor)) {
        // Si la cadena de texto es un nummero con decimal y mas de dos decimales, se sustituye el penultimo caracter por el ultimo
        // Obtenemos la subcadena desde el inicio hasta el antepenúltimo carácter
        let subcadena1 = valor.slice(0, -2);
        // Obtenemos el último carácter
        let ultimoCaracter = valor.slice(-1);
        // Concatenamos la subcadena y el último carácter
        valor = subcadena1 + ultimoCaracter;
        input.value = formatNumber(valor);

        if (tipoImporte === 1)
          setValuesImporteFavor({
            ...valuesImporteFavor,
            [event.target.id]: parseFloat(valor),
          });
        else if (tipoImporte === 2)
          setValuesImporteContra({
            ...valuesImporteContra,
            [event.target.id]: parseFloat(valor),
          });

        return false;
      }

      //Si no ocurre ninguna de los anteriores validaciones, es porque se ha ingresado un número válido y positivo
      input.value = formatNumber(valor);
      //inputTotal.value = inputTotal.value && input.value != "" ? parseFloat(inputTotal.value)+parseFloat(valor) : valor;//Actualizamos el valor del total a favor
      if (tipoImporte === 1)
        setValuesImporteFavor({
          ...valuesImporteFavor,
          [event.target.id]: parseFloat(valor),
        });
      else if (tipoImporte === 2)
        setValuesImporteContra({
          ...valuesImporteContra,
          [event.target.id]: parseFloat(valor),
        });
    } else {
      input.value = "";
      if (tipoImporte === 1)
        setValuesImporteFavor({ ...valuesImporteFavor, [event.target.id]: 0 });
      else if (tipoImporte === 2)
        setValuesImporteContra({
          ...valuesImporteContra,
          [event.target.id]: 0,
        });
    }
  };
  //Use effect que detecta cambios en la tabla de importes a favor
  useEffect(() => {
    var inputTotal = document.getElementById("iValueTotalFavor");
    let totalFavor = 0;
    for (let propiedad in valuesImporteFavor) {
      totalFavor += valuesImporteFavor[propiedad];
    }
    inputTotal.value = formatNumber(totalFavor.toFixed(2));
    //Actualizamos la tabla de totales

    setTotales({ ...totales, ["totalFavor"]: totalFavor });
  }, [valuesImporteFavor]);

  //Use effect que detecta cambios en la tabla de importes a descontar
  useEffect(() => {
    var inputTotal = document.getElementById("iValueTotalContra");
    let totalContra = 0;
    for (let propiedad in valuesImporteContra) {
      totalContra += valuesImporteContra[propiedad];
    }
    inputTotal.value = formatNumber(totalContra.toFixed(2));
    setTotales({ ...totales, ["totalContra"]: totalContra });
  }, [valuesImporteContra]);

  //Use effect que detecta cambios en la tabla de totales
  useEffect(() => {
    setTotalNeto(
      parseFloat((totales.totalFavor - totales.totalContra).toFixed(2))
    );
  }, [totales]);

  //useEffect(() => {
  //    setBalanceTotal((parseFloat(balance) + totalNeto).toFixed(2))
  //}, [totalNeto])

  const formularioRef = useRef(null);

  /**
   *
   * @param {String||Number} num Recibe un número en cadena de texto o tipo númerico y
   * le coloca el formato de numero separador de miles por comas, ademas de que valida si es decimal
   * @returns
   */
  function formatNumber(num) {
    const str = num.toString(); // Convertir el número a cadena de texto
    const decimalIndex = str.indexOf("."); // Obtener el índice del separador decimal

    if (decimalIndex !== -1) {
      // Si el número tiene decimales
      const decimalPart = str.slice(decimalIndex + 1); // Obtener la parte decimal
      const integerPart = str.slice(0, decimalIndex); // Obtener la parte entera
      const integerFormatted = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      ); // Agregar coma cada tres cifras en la parte entera

      return integerFormatted + "." + decimalPart; // Unir la parte entera y la parte decimal
    } else {
      // Si el número no tiene decimales
      return str.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Agregar coma cada tres cifras en la parte entera
    }
  }
  return (
    <div className="div-parent">
      <div className="div-breadcrumbs">
        <CustomSeparatorDinamic
          breadcrumbs={arrayBreadcrumbs}
          name={`Ajuste`}
        />
      </div>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Link
          style={{ textDecoration: "none" }}
          to="/dictamen/detalle_folio/reservas"
          state={{ row: infoFolio }}
        >
          <ReturnPage />
        </Link>
      </Grid>
      <div className="div-container">
        <CardDatosReserva />
        <hr />
        <form ref={formularioRef}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={4}
          >
            {/* Tabla Importes a favor */}
            <Grid item sx={4}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <h1-dialog-title>
                    <FormattedMessage
                      id="dictamImportesatFavo"
                      defaultMessage="Importes a favor"
                    />
                  </h1-dialog-title>
                </Grid>
                <Grid item>
                  <Paper style={{ width: "100%" }}>
                    <TableContainer
                      sx={{ showEmptyDataSourceMessage: true }}
                      style={{ position: "sticky" }}
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          "& .MuiTableRow-root:hover": {
                            backgroundColor: "#ededed",
                          },
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>
                              <FormattedMessage
                                id="dictamRese"
                                defaultMessage="Reserva"
                              />
                            </StyledTableCell>

                            {/* Encabezado de la tabla importes a favor */}
                            <StyledTableCell align="center">
                              <FormattedMessage
                                id="globImport"
                                defaultMessage="Importe"
                              />
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              Fondo de inversión (+)
                            </StyledTableCell>
                            <StyledTableCell>
                              {" "}
                              ${" "}
                              <EditableCell
                                name="iValueFondoInv"
                                id="iValueFondoInv"
                                defaultValue={valuesImporteFavor.iValueFondoInv}
                                onValueChange={(e) => handleChangeImporte(e, 1)}
                              />
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              Dotal a corto plazo (+)
                            </StyledTableCell>
                            <StyledTableCell>
                              {" "}
                              ${" "}
                              <EditableCell
                                name="iValueDotalCP"
                                id="iValueDotalCP"
                                defaultValue={valuesImporteFavor.iValueDotalCP}
                                onValueChange={(e) => handleChangeImporte(e, 1)}
                              />
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              Prima por devolver (+)
                            </StyledTableCell>
                            <StyledTableCell>
                              {" "}
                              ${" "}
                              <EditableCell
                                name="iValuePrimaPD"
                                id="iValuePrimaPD"
                                defaultValue={valuesImporteFavor.iValuePrimaPD}
                                onValueChange={(e) => handleChangeImporte(e, 1)}
                              />
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              Intereses no devengados (+)
                            </StyledTableCell>
                            <StyledTableCell>
                              {" "}
                              ${" "}
                              <EditableCell
                                name="iValueInteresesND"
                                id="iValueInteresesND"
                                defaultValue={
                                  valuesImporteFavor.iValueInteresesND
                                }
                                onValueChange={(e) => handleChangeImporte(e, 1)}
                              />
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              Dividendos (+)
                            </StyledTableCell>
                            <StyledTableCell>
                              {" "}
                              ${" "}
                              <EditableCell
                                name="iValueDividendos"
                                id="iValueDividendos"
                                defaultValue={
                                  valuesImporteFavor.iValueDividendos
                                }
                                onValueChange={(e) => handleChangeImporte(e, 1)}
                              />
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              Total a favor (+)
                            </StyledTableCell>
                            <StyledTableCell>
                              {" "}
                              ${" "}
                              <EditableCell
                                name="iValueTotalFavor"
                                defaultValue={totales.totalFavor}
                                id="iValueTotalFavor"
                                onValueChange={(e) => {}}
                                disabled={true}
                              />
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            {/* Tabla Importes a descontar */}
            <Grid item sx={4}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <h1-dialog-title>
                    <FormattedMessage
                      id="dictImDesc"
                      defaultMessage="Importes a descontar"
                    />
                  </h1-dialog-title>
                </Grid>
                <Grid item>
                  <Paper style={{ width: "100%" }}>
                    <TableContainer
                      sx={{ showEmptyDataSourceMessage: true }}
                      style={{ position: "sticky" }}
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          "& .MuiTableRow-root:hover": {
                            backgroundColor: "#ededed",
                          },
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>
                              <FormattedMessage
                                id="globReserv"
                                defaultMessage="Reserva"
                              />
                            </StyledTableCell>

                            {/* Encabezado de la tabla importes a favor */}
                            <StyledTableCell align="center">
                              <FormattedMessage
                                id="globImport"
                                defaultMessage="Importe"
                              />
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              Préstamos
                            </StyledTableCell>
                            <StyledTableCell>
                              {" "}
                              ${" "}
                              <EditableCell
                                id="iPrestamos"
                                defaultValue={valuesImporteContra.iPrestamos}
                                onValueChange={(e) => handleChangeImporte(e, 2)}
                              />
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              Primas por cobrar
                            </StyledTableCell>
                            <StyledTableCell>
                              {" "}
                              ${" "}
                              <EditableCell
                                id="iPrimasPC"
                                defaultValue={valuesImporteContra.iPrimasPC}
                                onValueChange={(e) => handleChangeImporte(e, 2)}
                              />
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              {" "}
                              Pagos de otros beneficios o días IDH
                            </StyledTableCell>
                            <StyledTableCell>
                              {" "}
                              ${" "}
                              <EditableCell
                                id="iPagosOtrosB"
                                defaultValue={valuesImporteContra.iPagosOtrosB}
                                onValueChange={(e) => handleChangeImporte(e, 2)}
                              />
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              Cláusula de últimos gastos (-)
                            </StyledTableCell>
                            <StyledTableCell>
                              {" "}
                              ${" "}
                              <EditableCell
                                id="iClausulaUP"
                                defaultValue={valuesImporteContra.iClausulaUP}
                                onValueChange={(e) => handleChangeImporte(e, 2)}
                              />
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              Total a descontar (-)
                            </StyledTableCell>
                            <StyledTableCell>
                              {" "}
                              ${" "}
                              <EditableCell
                                id="iValueTotalContra"
                                onValueChange={(e) => {}}
                                disabled={true}
                              />
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            {/* Tabla de totales */}
            <Grid item sx={4}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <h1-dialog-title>Totales</h1-dialog-title>
                </Grid>
                <Grid item>
                  <Paper style={{ width: "100%" }}>
                    <TableContainer
                      sx={{ showEmptyDataSourceMessage: true }}
                      style={{ position: "sticky" }}
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          "& .MuiTableRow-root:hover": {
                            backgroundColor: "#ededed",
                          },
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>
                              <FormattedMessage
                                id="globReserv"
                                defaultMessage="Reserva"
                              />
                            </StyledTableCell>

                            {/* Encabezado de la tabla importes a favor */}
                            <StyledTableCell
                              align="center"
                              style={{ minWidth: "200px" }}
                            >
                              <FormattedMessage
                                id="globImport"
                                defaultMessage="Importe"
                              />
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                              style={{
                                paddingTop: "20px",
                                paddingBottom: "20px",
                              }}
                            >
                              <FormattedMessage
                                id="globTolAFa"
                                defaultMessage="Total a favor"
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              {" "}
                              $ {formatNumber(totales.totalFavor.toFixed(2))}
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                              style={{
                                paddingTop: "20px",
                                paddingBottom: "20px",
                              }}
                            >
                              <FormattedMessage
                                id="dictamTotalaDesconta"
                                defaultMessage="Total a descontar"
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              {" "}
                              $ {formatNumber(totales.totalContra.toFixed(2))}
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                              style={{
                                paddingTop: "20px",
                                paddingBottom: "20px",
                              }}
                            >
                              {" "}
                              <FormattedMessage
                                id="dictamTotalaDesconta"
                                defaultMessage="Total neto"
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              {" "}
                              {totalNeto < 0 ? "-" : null} ${" "}
                              {formatNumber(
                                totalNeto < 0
                                  ? (totalNeto * -1).toFixed(2)
                                  : totalNeto.toFixed(2)
                              )}
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
                {/* <Grid item style={{ marginTop: '50px' }}>
                                    <Grid container>
                                        <Grid item >
                                            <TableBody>
                                                <TableRow >
                                                    <StyledTableCell component="th" scope="row" align="left" >Balance de reserva {moneda === 1 ? 'MXP' : 'USD'}: </StyledTableCell>
                                                    <StyledTableCell align="right"> $ {formatNumber(balance.toFixed(2))} </StyledTableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <StyledTableCell component="th" scope="row" align="left" >Total neto: </StyledTableCell>
                                                    <StyledTableCell align="right"> {totalNeto < 0 ? '-' : null} $ {formatNumber(totalNeto < 0 ? (totalNeto * -1).toFixed(2) : totalNeto.toFixed(2))} </StyledTableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <StyledTableCell component="th" scope="row" align="left" >Balance total de reserva {moneda === 1 ? 'MXP' : 'USD'} : </StyledTableCell>
                                                    <StyledTableCell align="right"> {balanceTotal < 0 ? `- $ ${formatNumber((balanceTotal * -1).toFixed(2))}` : ` $ ${formatNumber(balanceTotal)}`} </StyledTableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Grid>
                                    </Grid>
                                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item>
              <ButtonVino
                label={
                  <FormattedMessage id="dictamBorr" defaultMessage="Borrar" />
                }
                type="button"
              />
            </Grid>
            <Grid item>
              <ButtonVino
                label={
                  <FormattedMessage id="globEditar" defaultMessage="Editar" />
                }
              />
            </Grid>
            <Grid item>
              <ButtonVino
                label={
                  <FormattedMessage id="globGuarda" defaultMessage="Guardar" />
                }
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};
export default CompReservaAjusteAcc;
