import React from "react";
import CompDictamen from "../../../components/Dictamen/CompDictamen";



export function Dictamen() {
    return (
        <div>
      
            <CompDictamen />
        
        </div>
    );
}