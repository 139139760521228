/* Brandon Hernandez Rocha 12/2022 */
import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { Grid } from "@mui/material";
import IconEliminar from "../../../Tools/Icons/IconEliminar";
//idioma
import { FormattedMessage } from "react-intl";
import { putDataToken } from "../../../../api/dataToken";
import DialogFeedback from "../../../Tools/Dialog/DialogFeedback";
import { useState } from "react";
//Aparecera en la segunda alerta dos botones
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogEliminarBene(props) {
  //PARENT DIALOG
  const [open, setOpen] = React.useState(false);
  const [btnConfirmar, setBtnConfirmar] = React.useState(false);
  const beneficiario = props.infoDataRow;

  //Props beneficiario /poliza
  const idBenePxP = props.idBenePxP;
  const idBene = props.idBene;
  const idPoliza = props.idPoliza;

  console.log(idBenePxP);
  console.log(idBene);
  console.log(idPoliza);
  /** Estados y funcion para dialogo feedback al guardar */
  const [tituloFeedback, setTituloFeedback] = useState("");
  const [descripFeedback, setDescripFeedback] = useState("");
  const [mostrarFeedback, setMostrarFeedback] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //Eliminado lógico del beneficiario
  const eliminarDatosBeneficiario = async () => {
    try {
      let jsonBene = {
        PER_activo: false,
      };
      let jsonBenePxP = {
        PXP_activo: false,
        POL_idpoliza: idPoliza,
        PER_idpersona: idBene,
      };
      let result = await putDataToken(
        `personasxpoliza/get_id/${idBenePxP}/`,
        jsonBenePxP
      );
      if (result) {
        let result2 = await putDataToken(
          `personas/get_id/${idBene}/`,
          jsonBene
        );
        if (result2) {
          setTituloFeedback("Datos eliminados correctamente");
          setDescripFeedback(
            `Los datos del beneficiario ${beneficiario?.PER_idpersona?.PER_nombre} ${beneficiario?.PER_idpersona?.PER_apellidopaterno} ${beneficiario?.PER_idpersona?.PER_apellidomaterno} fueron eliminados correctamente.`
          );
          setMostrarFeedback(true);
        } else {
          setTituloFeedback("Falló al eliminar");
          setDescripFeedback(
            "Algo ha salido mal, comuníquese con el encargado de TI."
          );
          setMostrarFeedback(true);
        }
      } else {
        setTituloFeedback("Falló al eliminar");
        setDescripFeedback(
          "Algo ha salido mal, comuníquese con el encargado de TI."
        );
        setMostrarFeedback(true);
      }
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  };
  return (
    <div>
      {/* PARENT DIALOG */}
      <div>
        {/*BOTON QUE HACE EL LLAMADO AL DIALOGO PADRE*/}
        <IconEliminar
          onClick={handleClickOpen}
          tooltipTitle={
            <FormattedMessage id="estconElim" defaultMessage="Eliminar" />
          }
        />
        {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="dialog"
          open={open}
        >
          {/*CUERPO DEL DIALOGO*/}
          {/*TITILO*/}
          <BootstrapDialogTitle
            id="dialog"
            onClose={handleClose}
            style={{ color: "#761f2a" }}
          >
            <h1-dialog-title>Eliminar beneficiario</h1-dialog-title>
          </BootstrapDialogTitle>
          {/*CUERPO DEL DIALOGO*/}
          {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
          <DialogContent dividers style={{ minWidth: 500 }}>
            {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
            se necesita colocar entre un typogreaphy las veces que des salto de linea 
            o con un br*/}
            <h2-dialog-description>
              ¿Estás seguro de eliminar a{" "}
              {beneficiario?.PER_idpersona?.PER_nombre}{" "}
              {beneficiario?.PER_idpersona?.PER_apellidopaterno}{" "}
              {beneficiario?.PER_idpersona?.PER_apellidomaterno} de la lista de
              beneficiarios?
            </h2-dialog-description>
          </DialogContent>
          {/*es la tercer sección que engloba los botones */}
          <form
            onSubmit={async (e) => {
              try {
                e.preventDefault();
                eliminarDatosBeneficiario();
                setBtnConfirmar(true);
              } catch (e) {
                console.error("Error:", e.message);
              }
            }}
          >
            <DialogActions>
              {/*En esta parte se llena el segundo mensaje que debe de aparecer*/}
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item xs={"auto"}>
                  <ButtonVino
                    type="submit"
                    label={"Confirmar"}
                    estatus={btnConfirmar}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <ButtonVino onClick={handleClose} label={"Cancelar"} />
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </BootstrapDialog>
        {!mostrarFeedback ? null : (
          <DialogFeedback
            open={mostrarFeedback}
            clickButton={(e) => {
              props.response(idPoliza);
              setMostrarFeedback(false);
              handleClose();
            }}
            title={tituloFeedback}
            description={descripFeedback}
            button={"Aceptar"}
          />
        )}
      </div>
    </div>
  );
}
