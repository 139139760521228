/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import Grid from "@material-ui/core/Grid";
//idioma
import { FormattedMessage } from "react-intl";
import { getDataToken } from "../../../../api/dataToken";
import { PERR_ASEGURADO, PERR_BENEFICIARIO, PERR_CONTRATANTE, TIPO_FINIQUITO, TRAM_BENEFICIOSEV, TRAM_FALLECIMIENTO, TRAM_RESCATES, TRAM_RETIROS, TRAM_VENCIMIENTO } from "../../../../utils/constanstoken";
import { useEffect } from "react";
import { diasMaxTramite, dias_dictamen_compleja, dias_dictamen_especial, dias_dictamen_estandarizado, umbralATiempo, umbralCancelado, umbralEnProceso } from "../../../../api/getUmbrales";
import moment from "moment/moment";
import { formatNumberDecimalComa, formatedNumberAndDecimalAndComa } from "../../../../utils/functions";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import IconImprimir from "../../../Tools/Icons/IconImprimir";
import IconNoteAdd from "../../../Tools/Icons/IconNoteAdd";
import DialogConfirm from "../../../Tools/Dialog/DialogConfirm";
import { AcuseFiniquito } from "../Cartas/CartasDictamen";
import { pdf } from "@react-pdf/renderer";
import { filter } from "jszip";

export default function TableFiniquito(props) {



  /* CONSTANTES DE ESTADOS*/

  let tipoTabla = props.tipoTabla;
  let coberPagadas = props.coberPagadas;
  let dataApiPolxFolioxSin = props.dataApiPolxFolioxSin;

  console.log("dataApiPolxFolioxSin", dataApiPolxFolioxSin);

  console.log("Tipo tabla", tipoTabla);
  console.log("coberturas pagadas", coberPagadas);

  const [dialogConfirm, setDialogConfirm] = useState(false);
  const [dataReserva, setDataReserva] = useState(false);
  const [jsonPost, setJsonPost] = useState(false);
  const [dialogProp, setDialogProp] = useState({
    title: "",
    firstButton: "",
    secondButton: "",
    description: "",
  })
  const getDataPolCoberProd = async () => {
    try {
      let arrAux = [];
      let data = await getDataToken("polizascoberturasporproductos/get_pol/" + dataApiPolxFolioxSin.POL_idpoliza.POL_idpoliza + "/");
      if (data && data.length > 0) {
        for (let pcp of data) {
          let rpc = await getDataToken("reservasporpolizaporcoberturas/get_IDPCP/" + pcp.id + "/").then(
            (data) => {
              if (data.length > 1) {
                alert("Esta poliza tiene más de un registro con la misma cobertura. Comuniquese con encargado de TI");
              } else {
                arrAux.push(data[0]);
                console.log("dataaaaaaaa:", data)
              }
            }
          );
        }
        setDataReserva(arrAux);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  useEffect(() => {
    getDataPolCoberProd();
  }, [])

  const postFiniquito = async (cobPagadas, type, typeFiniquito, beneIndividual) => {

    try {
      //Obtener los pagos totales por coberturas 

      let coberPagTotales = [] // [ {COB_idcobertura: [], total: 10000}] example
      //1: Mapear las coberturas pagadas
      for (let cobertura of cobPagadas) {
        if (coberPagTotales.length > 0 && !coberPagTotales.find(obj => obj.COB_idcobertura.COB_idcobertura.COB_idcobertura == cobertura.COB_idcobertura.COB_idcobertura)) {
          let filterCob = cobPagadas.filter(obj => obj.COB_idcobertura.COB_idcobertura == cobertura.COB_idcobertura.COB_idcobertura);
          if (filterCob.length > 0) {
            let coberTotal = 0.0;
            for (let cober of filterCob) {
              if (cober.PAG_importetipocambio) {
                coberTotal += cober.PAG_importetipocambio
              } else if (cober.PAG_importe) {
                coberTotal += cober.PAG_importe
              }
            }
            coberPagTotales.push({ COB_idcobertura: cobertura, total: coberTotal });
          }
        } else if (coberPagTotales.length <= 0) {
          let filterCob = cobPagadas.filter(obj => obj.COB_idcobertura.COB_idcobertura == cobertura.COB_idcobertura.COB_idcobertura);
          if (filterCob.length > 0) {
            let coberTotal = 0.0;
            for (let cober of filterCob) {
              if (cober.PAG_importetipocambio) {
                coberTotal += cober.PAG_importetipocambio
              } else if (cober.PAG_importe) {
                coberTotal += cober.PAG_importe
              }
            }
            coberPagTotales.push({ COB_idcobertura: cobertura, total: coberTotal });
          }
        }
      }
      console.log("Total", coberPagTotales);


      //Obtener los ajustes de la cobertura 
      let reservaAjustes = [];

      //Filtrar las reservas que ya fueron pagadas 
      let reservas = [];
      console.log("reservas", dataReserva);
      for (let cobPagada of cobPagadas) {
        let filterCob = dataReserva.filter(obj => obj.PCP_idpolizacoberturaporproducto.COB_idcobertura.COB_idcobertura == cobPagada.COB_idcobertura.COB_idcobertura);
        console.log("filtercob", filterCob)
        console.log(reservas.find(obj => obj.PCP_idpolizacoberturaporproducto.COB_idcobertura.COB_idcobertura == cobPagada.COB_idcobertura.COB_idcobertura))
        if (reservas.find(obj => obj.PCP_idpolizacoberturaporproducto.COB_idcobertura.COB_idcobertura == cobPagada.COB_idcobertura.COB_idcobertura)) {
          // Agregar

        } else {
          if (filterCob.length > 0) {
            console.log(":)")
            reservas.push(filterCob[0]);
            let dataReserAjus = await getDataToken("reservaajustes/get_idreserva/" + filterCob[0].id + "/"); //cambiar por un filtro por folio en id PCP
            reservaAjustes.push(dataReserAjus);
          }
        }

      }
      console.log("Reserva ajustes", reservaAjustes);
      let nombreCoberturas = "";
      for (let index = 0; index < coberPagadas.length; index++) {
        const element = coberPagadas[index];
        if (type == PERR_BENEFICIARIO.id) {
          nombreCoberturas = element.COB_idcobertura.COB_cobertura;
          break;
        }
        if (index === coberPagadas.length - 1) {
          nombreCoberturas += element.COB_idcobertura.COB_cobertura + "";
        } else {
          nombreCoberturas = element.COB_idcobertura.COB_cobertura + ", ";
        }
      }
      if (type == PERR_ASEGURADO.id) {
        //Json post finiquito a asegurado
        let jsonPost = {
          tipoPersona: type,
          coberPagTotales : coberPagTotales,
          nombreAseguradora: "",
          nombreAsegurado: `${dataApiPolxFolioxSin.PER_idpersona?.PER_nombre} ${dataApiPolxFolioxSin.PER_idpersona?.PER_apellidopaterno} ${dataApiPolxFolioxSin.PER_idpersona?.PER_apellidomaterno}`,
          numFolio: dataApiPolxFolioxSin.RECT_idfolio.RECT_numerofolio,
          numSiniestro: dataApiPolxFolioxSin.SIN_idsiniestro.SIN_numerosiniestro,
          numPoliza: dataApiPolxFolioxSin.POL_idpoliza.POL_numpoliza,
          coberturasPagadas: cobPagadas,
          dataReserva: reservas,
          importesConceptosFavor: [],
          importesConceptosContra: [],
          leyendaPago: "",
          leyendaLegal: "",
        }
        console.log("jsonPost:", jsonPost)
        setDialogConfirm(true)
        setDialogProp((prevData) => ({
          ...prevData,
          title: `Finiquito generado con exito`,
          description: `Se ha generado con exito el finiquito a las coberturas (${nombreCoberturas}) de la póliza ${dataApiPolxFolioxSin.POL_idpoliza.POL_numpoliza}`,
          firstButton: "Imprimir",
          secondButton: "Aceptar",
        }));
        setJsonPost(jsonPost)
      } else if (type == PERR_BENEFICIARIO.id) {
        if (typeFiniquito == TIPO_FINIQUITO.FINIQUITO_GENERAL.id) {
          let nuevoArr = cobPagadas.map(obj => {
            return { ...obj, nombreCompleto: `${obj.PER_idpersona?.PER_nombre} ${obj.PER_idpersona?.PER_apellidopaterno} ${obj.PER_idpersona?.PER_apellidomaterno}` }
          })
          //Json post finiquito beneficiarios 
          let jsonPostBene = {
            tipoPersona: type,
            nombreAseguradora: "",
            coberPagTotales : coberPagTotales,
            nombreAsegurado: `${dataApiPolxFolioxSin.PER_idpersona?.PER_nombre} ${dataApiPolxFolioxSin.PER_idpersona?.PER_apellidopaterno} ${dataApiPolxFolioxSin.PER_idpersona?.PER_apellidomaterno}`,
            numFolio: dataApiPolxFolioxSin.RECT_idfolio.RECT_numerofolio,
            numSiniestro: dataApiPolxFolioxSin.SIN_idsiniestro.SIN_numerosiniestro,
            numPoliza: dataApiPolxFolioxSin.POL_idpoliza.POL_numpoliza,
            coberturasPagadas: nuevoArr,
            dataReserva: reservas,
            importesConceptosFavor: reservaAjustes[0].filter(obj => obj.CREVAJ0_idconceptoreservaajuste.CREVAJ0_tipoimporte == 1),
            importesConceptosContra: reservaAjustes[0].filter(obj => obj.CREVAJ0_idconceptoreservaajuste.CREVAJ0_tipoimporte == -1),
            // importesConceptosFavor: reservaAjustes[0].filter(obj => obj.CREVAJ0_idconceptoreservaajuste.CREVAJ0_tipoimporte == 1 && parseFloat(obj.REAJ0_importe) > 0),
            // importesConceptosContra: reservaAjustes[0].filter(obj => obj.CREVAJ0_idconceptoreservaajuste.CREVAJ0_tipoimporte == -1 && parseFloat(obj.REAJ0_importe) > 0),
            leyendaPago: "",
            leyendaLegal: "",
          }
          console.log("jsonPost Bene:", jsonPostBene)
          setJsonPost(jsonPostBene)
          setDialogConfirm(true)


        } else if (typeFiniquito == TIPO_FINIQUITO.FINIQUITO_INDIVIDUAL.id) {
          //Sustituir el nombre de los otros beneficiarios 
          let beneInd = beneIndividual;
          let nuevoArr = cobPagadas.map(obj => {
            if (obj.PER_idpersona.PER_idpersona == beneInd.PER_idpersona.PER_idpersona) {
              return { ...obj, nombreCompleto: `${obj.PER_idpersona?.PER_nombre} ${obj.PER_idpersona?.PER_apellidopaterno} ${obj.PER_idpersona?.PER_apellidomaterno}` }
            } else {
              return { ...obj, nombreCompleto: "IMPORTE A FAVOR DE OTRO BENEFICIARIO" }
            }
          })
          //Json post finiquito beneficiarios 
          let jsonPostBene = {
            tipoPersona: type,
            nombreAseguradora: "",
            coberPagTotales : coberPagTotales,
            nombreAsegurado: `${dataApiPolxFolioxSin.PER_idpersona?.PER_nombre} ${dataApiPolxFolioxSin.PER_idpersona?.PER_apellidopaterno} ${dataApiPolxFolioxSin.PER_idpersona?.PER_apellidomaterno}`,
            numFolio: dataApiPolxFolioxSin.RECT_idfolio.RECT_numerofolio,
            numSiniestro: dataApiPolxFolioxSin.SIN_idsiniestro.SIN_numerosiniestro,
            numPoliza: dataApiPolxFolioxSin.POL_idpoliza.POL_numpoliza,
            coberturasPagadas: nuevoArr,
            dataReserva: reservas,
            // importesConceptosFavor: reservaAjustes[0].filter(obj => obj.CREVAJ0_idconceptoreservaajuste.CREVAJ0_tipoimporte == 1 && parseFloat(obj.REAJ0_importe) > 0),
            // importesConceptosContra: reservaAjustes[0].filter(obj => obj.CREVAJ0_idconceptoreservaajuste.CREVAJ0_tipoimporte == -1 && parseFloat(obj.REAJ0_importe) > 0),
            importesConceptosFavor: reservaAjustes[0].filter(obj => obj.CREVAJ0_idconceptoreservaajuste.CREVAJ0_tipoimporte == 1),
            importesConceptosContra: reservaAjustes[0].filter(obj => obj.CREVAJ0_idconceptoreservaajuste.CREVAJ0_tipoimporte == -1),
            leyendaPago: "",
            leyendaLegal: "",
          }
          console.log("jsonPost Individual:", jsonPostBene)
          setJsonPost(jsonPostBene)
          setDialogConfirm(true)

        }
        setDialogConfirm(true)
        setDialogProp((prevData) => ({
          ...prevData,
          title: `Finiquito generado con exito`,
          description: `Se ha generado con exito el finiquito a la cobertura (${nombreCoberturas}) de la póliza ${dataApiPolxFolioxSin.POL_idpoliza.POL_numpoliza}`,
          firstButton: "Imprimir",
          secondButton: "Aceptar",
        }));
      }
    } catch (e) {
      console.log(e)
    }

  }

  const printFiniquito = async (json) => {
    let acuse = AcuseFiniquito(json);
    var blob = await pdf(acuse).toBlob();
    const pdfUrl = window.URL.createObjectURL(blob);
    var printWindow = window.open(pdfUrl, '_blank');
    printWindow.print();
    URL.revokeObjectURL(pdfUrl);
  }

  return (
    <div>
      <Grid
        style={{ paddingBottom: "20px" }}
        container
        justifyContent="flex-end"
        alignItems="flex-start"
      >

      </Grid>
      <Paper style={{ width: "100%" }}>
        <TableContainer
          sx={{ maxHeight: 440, showEmptyDataSourceMessage: true }}
          style={{ position: "sticky" }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              "& .MuiTableRow-root:hover": {
                backgroundColor: "#ededed",
              },
            }}
          >
            <TableHead  >
              <TableRow >
                {!coberPagadas.find(obj => obj.PER_idpersona.PERR_idrol.PERR_idrol == PERR_BENEFICIARIO.id) ? null : <StyledTableCell padding={"0px"}> Nombre </StyledTableCell>}
                <StyledTableCell padding={"0px"}> Cobertura</StyledTableCell>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                <StyledTableCell padding={"0px"} align="center" >
                  <FormattedMessage
                    id="globNoDeFo"
                    defaultMessage="Importe"
                  />
                </StyledTableCell>
                <StyledTableCell padding={"0px"} align="center" >
                  <FormattedMessage
                    id="globTipoCambio"
                    defaultMessage="Tipo cambio"
                  />
                </StyledTableCell >
                <StyledTableCell padding={"0px"} align="center" >
                  <FormattedMessage
                    id="errorTiRec"
                    defaultMessage="Importe M.N."
                  />
                </StyledTableCell>
                {!coberPagadas.find(obj => obj.PER_idpersona.PERR_idrol.PERR_idrol == PERR_BENEFICIARIO.id) ? null :
                  <StyledTableCell padding={"0px"} align="center" >
                    <FormattedMessage
                      id="globImprimir"
                      defaultMessage="Finiquito individual"
                    />
                  </StyledTableCell>}

              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {coberPagadas
                .map((row) => {


                  return (
                    <TableRow>
                      {row.PER_idpersona.PERR_idrol.PERR_idrol == PERR_ASEGURADO.id ? null
                        :
                        <StyledTableCell align="center" style={{ padding: '0px' }}>{`${row?.PER_idpersona?.PER_nombre} ${row?.PER_idpersona?.PER_apellidopaterno} ${row?.PER_idpersona?.PER_apellidomaterno}`}</StyledTableCell>
                      }
                      <StyledTableCell align="center" style={{ padding: '0px', paddingTop: '5px' }}>{row.COB_idcobertura.COB_cobertura}</StyledTableCell>
                      <StyledTableCell align="center"></StyledTableCell>
                      <StyledTableCell align="center"></StyledTableCell>
                      <StyledTableCell align="center">{!row?.PAG_importe ? "" : formatNumberDecimalComa(row?.PAG_importe)}</StyledTableCell>
                      {row.PER_idpersona.PERR_idrol.PERR_idrol == PERR_ASEGURADO.id ? null
                        :
                        <StyledTableCell align="center"><IconNoteAdd tooltipTitle="Genear finiquito individual" onClick={() => postFiniquito(coberPagadas, tipoTabla, TIPO_FINIQUITO.FINIQUITO_INDIVIDUAL.id, row)} /></StyledTableCell>}

                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

      </Paper>
      <Grid container
        justifyContent="flex-end"
        alignItems="flex-start"
        spacing={3}
        style={{ marginTop: '15px' }}>
        {/* {tipoTabla != 0 && coberPagadas.length > 0 && coberPagadas.find(obj => obj.PER_idpersona.PERR_idrol.PERR_idrol == PERR_ASEGURADO.id) ? <Grid item><ButtonVino label="Generar Finiquito" /></Grid> : null} */}
        <Grid item><ButtonVino label="Finiquito general" onClick={() => postFiniquito(coberPagadas, tipoTabla, TIPO_FINIQUITO.FINIQUITO_GENERAL.id)} /></Grid>
      </Grid>
      {/* Diálogos */}
      {!dialogConfirm ? null :
        <DialogConfirm
          open={dialogConfirm}
          title={dialogProp.title}
          description={dialogProp.description}
          firstButton={dialogProp.firstButton}
          secondButton={dialogProp.secondButton}
          onClickAccept2={() => {
            setDialogConfirm(false)
            printFiniquito(jsonPost)
          }
          }
          onClickAccept={() => {
            setDialogConfirm(false)
          }}
        />}
    </div >
  );
}
