import React, { useMemo, useRef } from "react";
//herramientas que utilizaremos para la creacion de nuestra grafica trayendolas de chart.js para el tipo de grafica que usaremos
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

//importacion del componente Bar que servira para llamarlo desde react-chartjs-2, debemos importarlo para usarlo con npm
import { Bar } from "react-chartjs-2";

//Herramientas para poder imprimir la grafica
import { useReactToPrint } from "react-to-print";

//importacion del estilo y componentes estandarizados
import "../../../css/estilo.css";
import { Grid } from "@mui/material";
import ButtonVino from "../../Tools/Bottones/ButtonVino";

//multilenguaje
import { FormattedMessage } from "react-intl";
import { useState, useEffect } from "react";
import { URLAPIIMG } from "../../../utils/constans";
import { getDataToken } from "../../../api/dataToken";
import { generatePDFWithFetch } from "../../../api/reportes"
import html2canvas from "html2canvas";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const options = {
  fill: true,
  scales: {
    x: {
      display: true,
      title: {
        display: true,
        text: 'Módulos',
      },
    },
    y: {
      display: true,
      title: {
        display: true,
        text: 'Tiempo en días',
      },
    },
  },
}

export default function GraficaTiempo({ folios }) {

  // const componentRef = useRef();
  // const [currentDate, setCurrentDate] = useState("");

  // useEffect(() => {
  //   const formattedDate = moment().format("LL"); // Formato "Ciudad de México a D [de] MMMM [de] YYYY"
  //   setCurrentDate(formattedDate);
  // }, []);

  // const [datosConf, setdatosConf] = useState([]);
  // // Get para el diseño de las graficas
  // const getUsers = async () => {
  //   try {

  //     var resultConf = await getDataToken(`configuraciongeneral/get_id/1/`);
  //     setdatosConf(resultConf);
  //   } catch (error) {
  //     console.log(error);
  //     throw error;
  //   }
  // };

  // useEffect(() => {
  //   getUsers();
  // }, []);


  // const url = `templatesCartas/generate-reporteWF/`;
  // const rutalogo = `${URLAPIIMG}${datosConf?.SCG_imgPathSing}`;
  // const rutaimg = `${URLAPIIMG}${datosConf?.SCG_imgPathSubLogo}`;
  // const param_pie = `${URLAPIIMG}${datosConf?.SCG_imgPathPiePagina}`;
  // const grafica = "https://dev.apis.sinay.com.mx/media/images/logo_qait.png ";
  // const p = "Reporte wf";
  // const p1 = `${datosConf?.SCG_estadoCarta} a ${currentDate}`;
  // const p3 = `${datosConf?.SCG_direccionAseguradora}`;

  // const generatePDF = async () => {
  //   const chartImage = await html2canvas(componentRef.current).then(canvas => canvas.toDataURL("image/png"));
  //   let json = {
  //     param_logo: rutalogo,
  //     param_imagen: rutaimg,
  //     param_pie: param_pie,
  //     param_grafica: chartImage,
  //     param: p,
  //     param1: p1,
  //     param2: "Grafica De Dias Trascurridos Por Modulo",
  //     param3: p3,
  //   };

  //   // Llama a la función para generar el PDF
  //   generatePDFWithFetch(url, json);
  // };
  // //Fin de area de impresiom



  const [inFolio, setFolios] = useState(folios);
  const [modulesData, setModulesData] = useState([]);
  useEffect(() => {
    setFolios(folios);
  }, [folios]);


  useEffect(() => {
    // Obtener datos únicos de módulos y fechas
    const uniqueModules = Array.from(new Set(inFolio.map(item => item.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_descripcion)));

    const modulesData = uniqueModules.map(module => {
      const moduleData = inFolio.find(item => item.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_descripcion === module);
      const creationDate = moment(moduleData.WKT_fechawktramite);
      const daysSinceCreation = moment().diff(creationDate, 'days');
      return { module, daysSinceCreation };
    });

    // console.log('Modules Data:', modulesData); 

    setModulesData(modulesData);
  }, [inFolio]);



  // Construir etiquetas y datos para la gráfica
  const labels = modulesData.map(item => item.module);
  const scores = modulesData.map(item => item.daysSinceCreation);



  //Funcion para declarar los campos de la grafica
  const data = useMemo(function () {
    return {
      datasets: [
        {
          label: "GRAFICA DE DIAS TRASCURRIDOS",
          tension: 0.3,
          data: scores,
          backgroundColor: [
            "#3e95cd",
            "#8e5ea2",
            "#3cba9f",
            "#e8c3b9",
            "#c45850",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(255, 205, 86, 1)",
          ],
        },
      ],
      labels,
    };
  }, [modulesData]);


  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ height: '45vh' }}>
      <Bar data={data} options={options} />
    </Grid>
  );
}

