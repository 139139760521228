//  David Ruiz 10/2022

import React from 'react';
import IconButton from '@mui/material/IconButton';
import Fingerprint from '@mui/icons-material/Fingerprint';

const Finger=(props)=>{
    return(
        <IconButton aria-label="fingerprint" color="icons"
        href={props.enlace}
      //ojo aqui es disabled
      //true para desabilitar y false para habilitar
      disabled={props.estatus}
      onClick={props.onClick}
      onChange={props.onChange}
      id={props.id}
        >
        <Fingerprint />
      </IconButton>
        );
    }
export default Finger;