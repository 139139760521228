import * as React from "react";
import PropTypes from "prop-types";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@material-ui/core/Grid";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { formatofecha } from "../../../../api/getUmbrales";
import moment from "moment";
//Tabla

//Solo aparesera un alerta con un solo boton
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function DialogDatosGuardados(props) {
    const [open, setOpen] = React.useState(props.open ? props.open : false);


    const handleClose = () => {
        setOpen(false);
        props.aceptar(true)
    };
    var today = new Date();

    return (
        <div>

            {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
            <BootstrapDialog

                fullWidth={true}
                onClose={handleClose}
                aria-labelledby="dialog"
                open={open}
            >
                {/*CUERPO DEL DIALOGO*/}
                {/*TITILO*/}
                <BootstrapDialogTitle
                    id="dialog"
                    onClose={handleClose}
                    style={{ color: "#761f2a" }}
                >
                    <h1-dialog-title>Documentos guardados con éxito</h1-dialog-title>
                </BootstrapDialogTitle>
                {/*CUERPO DEL DIALOGO*/}
                {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
                <DialogContent style={{ minWidth: 500, textAlign: 'center' }}>
                    {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
          se necesita colocar entre un typogreaphy las veces que des salto de linea 
          o con un br*/}
                    <div style={{ paddingLeft: 100 }}>
                        <h2-dialog-description style={{ textAlign: 'left' }}>Folio: {props.folio}</h2-dialog-description>
                        <h2-dialog-description style={{ textAlign: 'left' }}>Nombre del asegurado: {props.nombreAse}</h2-dialog-description>
                        <h2-dialog-description style={{ textAlign: 'left' }}>Fecha de creación: {moment(today).format(formatofecha)}</h2-dialog-description>
                        <h2-dialog-description style={{ textAlign: 'left' }}>Atendido por: {props.user}</h2-dialog-description>
                    </div>

                    <Grid 
                    style={{ paddingTop: 20 }}
                    container direction="row"
                        justifyContent="center"
                        alignItems="flex-start">
                        <Grid item>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            //checked={emailChecked}
                                            onChange={(event) =>
                                                console.log("email")
                                            }
                                        />
                                    }
                                    label="Enviar por correo"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            //checked={printChecked}
                                            onChange={(event) =>
                                                console.log("Print")
                                            }
                                        />
                                    }
                                    label="Imprimir"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </DialogContent>
                {/*es la tercer sección que engloba los botones */}
                <DialogActions>
                    <Grid container justifyContent="center" >
                        <ButtonVino
                            onClick={handleClose}
                            label={'Aceptar'}
                        />
                    </Grid>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
