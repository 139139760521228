import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { FormattedMessage } from "react-intl";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Diasurgen } from './daysurgente';
import { Diasestandar } from './dayestandar';
import { Dayspecial } from './dayspecial';
import { Daycompleja } from './daycompleja';
import {diasestandarizados,
  diasespecial,
  diascomplejo,
  diascug} from '../../../../api/getUmbrales';


export default function ControlledAccordions() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };



  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <h3-subtitle-card >
          Plataforma Estandarizada - Dias Seleccionados {diasestandarizados}
          </h3-subtitle-card>
        </AccordionSummary>
        <AccordionDetails>
       <Diasestandar/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <h3-subtitle-card >Plataforma Compleja  - Dias Seleccionados {diascomplejo}</h3-subtitle-card>
        </AccordionSummary>
        <AccordionDetails>
          <Daycompleja/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <h3-subtitle-card >
          Plataforma Especial - Dias Seleccionados {diasespecial}
          </h3-subtitle-card>
        </AccordionSummary>
        <AccordionDetails>
          <Dayspecial/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <h3-subtitle-card >Plataforma Urgente - Dias Seleccionados {diascug}</h3-subtitle-card>
        </AccordionSummary>
        <AccordionDetails>
        <Diasurgen/>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}