/* 
David Ruiz
Proyecto SINAY 
*/
import * as React from 'react';

//Grid
import { Grid } from "@mui/material";

//return
import ReturnPage from '../../Tools/Bottones/ButtonReturn';

//tabla
import Tabla1 from './Elements/Tabla1'

//breadcrumbs
import Recorrido from '../../Tools/BreadCrumbs/Recorrido'

//idioma
import {FormattedMessage} from 'react-intl';

export default function Users() {

return (

  <div className="div-parent">
  <div className="div-breadcrumbs"> {/* Navegacion*/}
  <Recorrido name1={<FormattedMessage id="globInicio" defaultMessage="Inicio"/>}   
  enlace="/confadmin" name2={<FormattedMessage id="admin" defaultMessage="Administración de usuarios  "/>} />  
  </div>
  <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
        <ReturnPage href='/menuadmin'/>
  </Grid>
  <div className="div-container">{/* tabla */}
  <Tabla1/>
</div>
</div>
    
  );
}
