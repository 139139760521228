/* 
David Ruiz
Proyecto SINAY 
05/09/2022
*/


import React from "react";

import { CompAdmin } from '../../components/Admin/CompAdmin';
import  NavMatrizRolesNotifi  from '../../components/Tools/Header/NavMatrizRolNotifi';
import  Footer  from "../../components/Tools/Footer/Footer";


export function Admin() {
    return (
        <div>
            <NavMatrizRolesNotifi />
            <CompAdmin/>
            <Footer />
        </div>
    );
}