import * as React from "react";
import "../../../css/Typography.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import Box from "@mui/material/Box";
import { BASE_API } from "../../../utils/constans";
import { getToken } from "../../../api/token";
import differenceInDays from "date-fns/differenceInDays";
//idioma
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

/* ESTILOS DE LA TABLA */
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00203b",

    padding: 0,
    paddingLeft: "5px",
    height: "65px",
    color: theme.palette.common.white,
    fontSize: "20px",
    borderColor: "#e2e2e2",
    border: "1px solid #e2e2e2",
    fontFamily: "Avenir-Light",
  },
  [`&.${tableCellClasses.body}`]: {
    height: "20px",
    padding: 0,
    fontSize: "16px",
    borderColor: "#e2e2e2",
    border: "1px solid #e2e2e2",
    fontFamily: "Avenir-Light",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    //backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    //border: 0,
  },
}));

export default function TableDetalleStatus() {

  const locationtable = useLocation();
  const tableinfo = locationtable.state.folios;
  // console.log("apidecirculos", tableinfo);


  const [detalles, setDetalles] = useState([]);
  const token = getToken();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_API}wktramite/get_listado/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Error al obtener datos');
        }
  
        const data = await response.json();
        // console.log("Dataaaa", data)
        // console.log('Datos desde la API:', data[0].WKT_fechawktramite);
  
        // Verifica el valor de POL_idpoliza
        const idFolio = tableinfo.POL_idpoliza?.POL_idpoliza;
      //  console.log('ID Folio a filtrar:', tableinfo.POL_idpoliza?.POL_idpoliza);
  
  
        // Filtra los datos basados en POL_idpoliza
        const datosFiltrados = data.filter((dato) => dato.POL_idpoliza?.POL_idpoliza === idFolio);
        // console.log('Datos filtrados:', datosFiltrados);
      
        // Establecer datos filtrados en el estado
        setDetalles(datosFiltrados);
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    fetchData();
  }, [token, tableinfo.POL_idpoliza]);
// Utilizar un conjunto para rastrear IDs de módulos únicos
const uniqueModuleIds = new Set();

// Filtrar y obtener datos únicos basados en el ID del módulo
const uniqueRows = detalles.filter((row) => {
  const moduleId = row.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_descripcion;
  const fechaid = row.WKT_fechawktramite;

  if (!uniqueModuleIds.has(moduleId,fechaid)) {
    uniqueModuleIds.add(moduleId,fechaid);
    return true;
  }

  return false;
});
// Obtener las fechas del array uniqueRows
const fechas = uniqueRows.map((row) => Date.parse(row.WKT_fechawktramite));

// Encontrar la primera y última fecha
const primeraFecha = new Date(Math.min(...fechas));
const ultimaFecha = new Date(Math.max(...fechas));

// Formatear las fechas según tus necesidades
const formattedPrimeraFecha = primeraFecha.toLocaleDateString();
const formattedUltimaFecha = ultimaFecha.toLocaleDateString();

// Imprimir en la consola las fechas encontradas
console.log("Primera fecha registrada:", formattedPrimeraFecha);
console.log("Última fecha registrada:", formattedUltimaFecha);

function createData(detalles){
  return { detalles };
}

  
  /* CONSTANTES DE ESTADOS*/
  const [folios, setFolios] = useState(false); // esta constante guarda un arreglo en donde se almacenará los datos de la API
  const [order, setOrder] = useState("ASC"); // esta constante tiene como propósico guardar el estado del orden de la tabla
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla
  const [InfoDataRow, setInfoDataRow] = useState(""); //Aqui es donde almacenará los datos de la fila seleccionadad
  const [btnDownloadInfo, setBtnDownloadInfo] = useState(true);

  const rows = [createData(detalles)];
  // Función para calcular días transcurridos desde la fecha hasta hoy
  const calcularDiasTranscurridos = (fecha) => {
    const fechaActual = new Date();
    const fechaInicio = new Date(fecha);
    return differenceInDays(fechaActual, fechaInicio);
  };

  return (
    <div>
      <Grid container>
        <h3-header-card>
          {
            <FormattedMessage
              id="wfDetaEsta"
              defaultMessage="Detalles del estatus"
            />
          }
        </h3-header-card>
      </Grid>

      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 300 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="wfDetaEsta"
                    defaultMessage="Modulos"
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  {
                    <FormattedMessage
                      id="estconFeIn"
                      defaultMessage="Fecha inicial"
                    />
                  }
                </StyledTableCell>
                <StyledTableCell align="center">
                  {
                    <FormattedMessage
                      id="estFechFin"
                      defaultMessage="Fecha fin"
                    />
                  }
                </StyledTableCell>
                <StyledTableCell align="center">
                  {
                    <FormattedMessage
                      id="wtDiasTras"
                      defaultMessage="Días trascurridos"
                    />
                  }
                </StyledTableCell>
                <StyledTableCell align="center">
                  {
                    <FormattedMessage
                      id="wtDiasTotalesTrascur"
                      defaultMessage="Días totales trascurridos"
                    />
                  }
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {uniqueRows
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  // } else if (
                  //   /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                  //   /*val.PER_idpersona?.PER_RFC.toLowerCase()
                  //   .includes(searchTerm.toLowerCase()) ||*/
                  //   val.PER_idpersona?.PER_nombre
                  //     .toLowerCase()
                  //     .includes(searchTerm.toLowerCase())
                  // ) {
                    // return val;
                  }
                })
                
                .map((row) => (
                  <StyledTableRow>
                    <StyledTableCell align="center">
                      {row.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_descripcion}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {formattedPrimeraFecha}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {formattedUltimaFecha}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {calcularDiasTranscurridos(row.WKT_fechawktramite)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {/* {row.PER_idpersona?.PERT_idtipopersona?.PERT_actualizousuario} */}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box sx={{ m: 1, minWidth: 25 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid xs={3}>
            <br />
          </Grid>
          <br />
          <Grid xs={3}>
            <br />
          </Grid>
          <Grid xs={2}>
            <br />
            {/* <ButtonVino
              variant="contained"
              color="primary"
              size="small"
              label={
                <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
              }
            /> */}
          </Grid>
          <Grid xs={4}>
            <br />
          </Grid>
        </Grid>
      </Box>
      <br></br>
    </div>
  );
}
