//David Ruiz 10/2022

import React from 'react';
import IconButton from '@mui/material/IconButton';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

const BtAnalista=(props)=>{
    return(
        <IconButton aria-label=" TravelExploreIcon" color="icons" 
        //aqui va el enlace
        href={props.enlace}
        onClick={props.onClick}
        onChange={props.onChange}
        id={props.id}
         //ojo aqui es disabled
         disabled={props.estatus} //true para desabilitar y false para habilitar
        >
            
        < TravelExploreIcon />
        </IconButton>
        );
    }

export default BtAnalista;