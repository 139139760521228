/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { FormattedMessage } from "react-intl";
import "../dictamen.css";
import { useState } from "react";
//table

import TabPanel from "./PanelDictamenFolioX";
import { getDataToken } from "../../../api/dataToken";
import { useEffect } from "react";
import { PERR_ASEGURADO } from "../../../utils/constanstoken";

export function DetalleFolioX(props) {
  const row = props.infoRow;
  const folio = props.folio;
  console.log(row);

  /* CONSTANTES DE ESTADOS*/
  const [data, setData] = useState([]); // esta constante guarda un arreglo en donde se almacenará los datos de la API
  const [asegurado, setAsegurado] = useState(null); // esta constante guarda un arreglo en donde se almacenará los datos de la API

  /* CONSULTAR DATOS ASEGURADO SEGÚN LA PÓLIZA DEL FOLIO  */

  const getDataAsegurado = async () => {
    try {
      let response = await getDataToken(`personasxpoliza/get_poliza/${row[0]?.POL_idpoliza?.POL_idpoliza}/`)
      if (response) {
        let asegurado = response.find(objeto => objeto?.PER_idpersona?.PERR_idrol?.PERR_idrol == PERR_ASEGURADO.id);
        if (asegurado) setAsegurado(asegurado)

      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  useEffect(() => {
    getDataAsegurado();
  }, []);

  return (
    <div>
      <div className="div-cards">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid item xs={2}>
            <h3-title-card>
              <FormattedMessage
                id="dictamDictamenDelFol"
                defaultMessage="Dictamen del folio"
              />
              :
            </h3-title-card>
          </Grid>
          <Grid item xs={2}>
            <h3-subtitle-card>{folio?.RECT_numerofolio}</h3-subtitle-card>
          </Grid>
          <Grid item xs={2}>
            <h3-title-card>
              <FormattedMessage
                id="globNomAse"
                defaultMessage="Nombre del asegurado"
              />
              :
            </h3-title-card>
          </Grid>
          <Grid item xs={2}>
            <h3-subtitle-card>{!asegurado ? null : `${asegurado?.PER_idpersona?.PER_nombre} ${asegurado?.PER_idpersona?.PER_apellidopaterno} ${asegurado?.PER_idpersona?.PER_apellidomaterno}`}</h3-subtitle-card>
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <TabPanel polizas={row} idFolio={folio?.RECT_idfolio}/>
        </Grid>
      </Grid>
    </div>
  );
}
