//  David Ruiz 10/2022

import React from "react";
import Button from "@mui/material/Button";

const ButtonGris=(props)=>{
    return (
      <Button
        variant="contained"
        color="three"
        size="small"
        href={props.enlace}
        //ojo aqui es disabled
        //true para desabilitar y false para habilitar
        disabled={props.estatus}
        onClick={props.onClick}
        onChange={props.onChange}
        id={
props.id
}
      >
      <button-gray-small> {props.label}</button-gray-small>  {/*texto dentro del boton */}
      </Button>
    );
  }


export default ButtonGris; 