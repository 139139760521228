import React, { useState } from "react";
//BreadCrumbs
import { FormattedMessage } from "react-intl";
import Recorrido from "../../Tools/BreadCrumbs/Recorrido";
//Diseño
import { Grid } from "@mui/material";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
} from "@mui/material";
//Boton de regreso
import ReturnPage from "../../Tools/Bottones/ButtonReturn";
//Get api's

import Canales from "./Elements/Catalogos/Canales";
import Parentesco from "./Elements/Catalogos/Parentesco";
import Cuentasmayor from "./Elements/Catalogos/Cuentasmayor";
import TablaReaseguradora from "./Elements/Catalogos/Reaseguradoras";
import TablaCobertura from "./Elements/Catalogos/Coberturas";
import { SelectsCobe } from "./Elements/Select";
import { set } from "react-hook-form";
import TablaFormapago from "./Elements/Catalogos/FormPago";
import TablaOficina from "./Elements/Catalogos/Oficinas";
import Plataformas from "./Elements/Catalogos/Plataformas";
import TiposdeEnfermedad from "./Elements/Catalogos/TiposdeEnfermedad";
import Tablagene from "./Elements/Catalogos/Confgene";
import Catalogoii from "./Elements/Catalogos/CatalogoOII";
import Tiposdeaccidentes from "./Elements/Catalogos/Tiposdeaccidentes";
import Tiposproducto from "./Elements/Catalogos/TiposProducto";
import Tabladocumentos from "./Elements/Catalogos/Documentos";
import Tablaprueba from "./Elements/Catalogos/Confgene";
import TablaMonedas from "./Elements/Catalogos/TablaMonedas";
import TiposdeHomicidio from "./Elements/Catalogos/TiposdeHomicidio";
import TipospersonasSAt from "./Elements/Catalogos/TipopersonaSAT";
import TablaRoles from "./Elements/Catalogos/RolPersona";
import TablaComplejidad from "./Elements/Catalogos/ComplejidadSiniestro";
import TablaCausas from "./Elements/Catalogos/Causas";
import TablaTipoCarta from "./Elements/Catalogos/TipoCarta";
import BancosTabla from "./Elements/Catalogos/Bancos";
import TarifaInv from "./Elements/Catalogos/TarifaInvestigador";

export function Cobertura() {
  //Estado para las tablas a mostrar
  //Tipo de Carta
  const [tipocarta, setTipocarta] = useState(false);
  const handleClickTipocarta = () => {
    setTipocarta(true);
    setCausass(false);
    setComplejidads(false);
    setRolesp(false);
    setEnfer(false);
    settipoSAT(false);
    setcatoii(false);
    settiposaccudente(false);
    settiposhom(false);
    setParent(false);
    setCanales(false);
    settipprod(false);
    setPlata(false);
    setCuentamay(false);
    setMone(false);
    setRea(false);
    setCobe(false);
    setForpa(false);
    setOfici(false);
    setDocu(false);
    setPru(false);
    setBanco(false);
    setTarifasInv(false);
  };
  //Causas
  const [causas, setCausass] = useState(false);
  const handleClickCausass = () => {
    setCausass(true);
    setComplejidads(false);
    setRolesp(false);
    setEnfer(false);
    settipoSAT(false);
    setcatoii(false);
    settiposaccudente(false);
    settiposhom(false);
    setParent(false);
    setCanales(false);
    settipprod(false);
    setPlata(false);
    setCuentamay(false);
    setMone(false);
    setRea(false);
    setCobe(false);
    setForpa(false);
    setOfici(false);
    setDocu(false);
    setPru(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };
  //Complejidad de Siniestros
  const [complejidad, setComplejidads] = useState(false);
  const handleClickComplejidads = () => {
    setComplejidads(true);
    setRolesp(false);
    setEnfer(false);
    settipoSAT(false);
    setcatoii(false);
    settiposaccudente(false);
    settiposhom(false);
    setParent(false);
    setCanales(false);
    settipprod(false);
    setPlata(false);
    setCuentamay(false);
    setMone(false);
    setRea(false);
    setCobe(false);
    setForpa(false);
    setOfici(false);
    setDocu(false);
    setPru(false);
    setCausass(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };
  //Roles Personas
  const [rol, setRolesp] = useState(false);
  const handleClickRolesp = () => {
    setRolesp(true);
    setEnfer(false);
    settipoSAT(false);
    setcatoii(false);
    settiposaccudente(false);
    settiposhom(false);
    setParent(false);
    setCanales(false);
    settipprod(false);
    setPlata(false);
    setCuentamay(false);
    setMone(false);
    setRea(false);
    setCobe(false);
    setForpa(false);
    setOfici(false);
    setDocu(false);
    setPru(false);
    setComplejidads(false);
    setCausass(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };
  //Catalogoii
  const [oii, setcatoii] = useState(false);
  const handleClickcatoii = () => {
    setEnfer(false);
    settipoSAT(false);
    setcatoii(true);
    settiposaccudente(false);
    settiposhom(false);
    setParent(false);
    setCanales(false);
    settipprod(false);
    setPlata(false);
    setCuentamay(false);
    setMone(false);
    setRea(false);
    setCobe(false);
    setForpa(false);
    setOfici(false);
    setDocu(false);
    setPru(false);
    setRolesp(false);
    setComplejidads(false);
    setCausass(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };
  //Tipos persona SAT
  const [tipoSAT, settipoSAT] = useState(false);
  const handleClicktiposSAT = () => {
    setEnfer(false);
    settipoSAT(true);
    settiposaccudente(false);
    settiposhom(false);
    setcatoii(false);
    setParent(false);
    setCanales(false);
    settipprod(false);
    setPlata(false);
    setCuentamay(false);
    setMone(false);
    setRea(false);
    setCobe(false);
    setForpa(false);
    setOfici(false);
    setDocu(false);
    setPru(false);
    setRolesp(false);
    setComplejidads(false);
    setCausass(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };
  //Tipos de enfermdad
  const [tiposaccidente, settiposaccudente] = useState(false);
  const handleClicktiposaccidente = () => {
    setEnfer(false);
    settiposaccudente(true);
    settiposhom(false);
    setParent(false);
    settipoSAT(false);
    setCanales(false);
    settipprod(false);
    setcatoii(false);
    setPlata(false);
    setCuentamay(false);
    setMone(false);
    setRea(false);
    setCobe(false);
    setForpa(false);
    setOfici(false);
    setDocu(false);
    setPru(false);
    setRolesp(false);
    setComplejidads(false);
    setCausass(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };

  //Tipos de enfermdad
  const [tiposhom, settiposhom] = useState(false);
  const handleClicktiposhom = () => {
    setEnfer(false);
    settiposhom(true);
    setParent(false);
    setCanales(false);
    settipprod(false);
    settiposaccudente(false);
    setPlata(false);
    setcatoii(false);
    setCuentamay(false);
    setMone(false);
    setRea(false);
    setCobe(false);
    setForpa(false);
    setOfici(false);
    setDocu(false);
    setPru(false);
    setRolesp(false);
    setComplejidads(false);
    setCausass(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };

  //Tipos de enfermdad
  const [enfer, setEnfer] = useState(false);
  const handleClickenferm = () => {
    setEnfer(true);
    settipoSAT(false);
    setcatoii(false);
    settiposaccudente(false);
    settiposhom(false);
    setParent(false);
    setCanales(false);
    settipprod(false);
    setPlata(false);
    setCuentamay(false);
    setMone(false);
    setRea(false);
    setCobe(false);
    setForpa(false);
    setOfici(false);
    setDocu(false);
    setPru(false);
    setRolesp(false);
    setComplejidads(false);
    setCausass(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };
  //Parentesco
  const [paren, setParent] = useState(false);
  const handleClickparents = () => {
    setParent(true);
    setCanales(false);
    settiposhom(false);
    settipprod(false);
    settiposaccudente(false);
    setPlata(false);
    setCuentamay(false);
    setMone(false);
    setcatoii(false);
    setRea(false);
    setCobe(false);
    setForpa(false);
    setOfici(false);
    setEnfer(false);
    settiposhom(false);
    setDocu(false);
    setPru(false);
    setRolesp(false);
    setComplejidads(false);
    setCausass(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };
  //Productos
  const [tipprod, settipprod] = useState(false);
  const handleClicktipprodc = () => {
    setCanales(false);
    settiposaccudente(false);
    setParent(false);
    setcatoii(false);
    settipprod(true);
    setPlata(false);
    setEnfer(false);
    setCuentamay(false);
    setMone(false);
    setRea(false);
    setCobe(false);
    setForpa(false);
    setOfici(false);
    setDocu(false);
    setPru(false);
    settiposhom(false);
    setRolesp(false);
    setComplejidads(false);
    setCausass(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };
  //PLALTAFORMAS
  const [plata, setPlata] = useState(false);
  const handleClickplata = () => {
    setCanales(false);
    setPlata(true);
    setEnfer(false);
    setParent(false);
    setParent(false);
    setcatoii(false);
    settiposhom(false);
    settipprod(false);
    setCuentamay(false);
    setMone(false);
    settiposaccudente(false);
    setRea(false);
    setCobe(false);
    setForpa(false);
    setOfici(false);
    setDocu(false);
    setPru(false);
    setRolesp(false);
    setComplejidads(false);
    setCausass(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };
  //canales
  const [canales, setCanales] = useState(false);
  const handleClickcanales = () => {
    setCanales(true);
    setPlata(false);
    setEnfer(false);
    setcatoii(false);
    settiposhom(false);
    setCuentamay(false);
    setMone(false);
    setParent(false);
    setRea(false);
    setCobe(false);
    setForpa(false);
    settiposaccudente(false);
    setOfici(false);
    setDocu(false);
    settipprod(false);
    setPru(false);
    setRolesp(false);
    setComplejidads(false);
    setCausass(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };
  //cuentasmayor
  const [cuentamay, setCuentamay] = useState(false);
  const handleClickcuentamayor = () => {
    setCuentamay(true);
    setMone(false);
    settiposhom(false);
    setRea(false);
    setCanales(false);
    settipprod(false);
    setcatoii(false);
    setCobe(false);
    setParent(false);
    settiposaccudente(false);
    setForpa(false);
    setOfici(false);
    setDocu(false);
    setEnfer(false);
    setPlata(false);
    setPru(false);
    setRolesp(false);
    setComplejidads(false);
    setCausass(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };
  //Tabla Monedas
  const [mone, setMone] = useState(false);
  const handleClickMone = () => {
    setMone(true);
    setRea(false);
    setCuentamay(false);
    settipprod(false);
    setCobe(false);
    setPlata(false);
    settiposhom(false);
    setcatoii(false);
    setEnfer(false);
    setParent(false);
    setForpa(false);
    setOfici(false);
    setDocu(false);
    setPru(false);
    settiposaccudente(false);
    setCanales(false);
    setRolesp(false);
    setComplejidads(false);
    setCausass(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };
  // Tabla Reaseguradoras
  const [rea, setRea] = useState(false);
  const handleClickRea = () => {
    setRea(true);
    setMone(false);
    setCobe(false);
    setcatoii(false);
    setForpa(false);
    setOfici(false);
    setEnfer(false);
    setCanales(false);
    setPlata(false);
    settiposhom(false);
    settiposaccudente(false);
    setParent(false);
    setDocu(false);
    setPru(false);
    settipprod(false);
    setCuentamay(false);
    setRolesp(false);
    setComplejidads(false);
    setCausass(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };
  // Tabla Coberturas
  const [cobe, setCobe] = useState(false);
  const handleClickCobe = () => {
    setCobe(true);
    setcatoii(false);
    settipprod(false);
    setEnfer(false);
    setRea(false);
    setCanales(false);
    setMone(false);
    settiposhom(false);
    setParent(false);
    settiposaccudente(false);
    setForpa(false);
    setOfici(false);
    setPlata(false);
    setDocu(false);
    setCuentamay(false);
    setPru(false);
    setRolesp(false);
    setComplejidads(false);
    setCausass(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };
  // Tabla de Formas de Pago
  const [forpa, setForpa] = useState(false);
  const handleClickForpa = () => {
    setForpa(true);
    setCobe(false);
    setcatoii(false);
    setPlata(false);
    setCanales(false);
    setRea(false);
    setParent(false);
    setMone(false);
    setOfici(false);
    setEnfer(false);
    setCuentamay(false);
    settiposhom(false);
    settipprod(false);
    settiposaccudente(false);
    setDocu(false);
    setPru(false);
    setRolesp(false);
    setComplejidads(false);
    setCausass(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };
  // Tabla de Oficina
  const [ofici, setOfici] = useState(false);
  const handleClickOfici = () => {
    setOfici(true);
    setForpa(false);
    setEnfer(false);
    setCanales(false);
    setPlata(false);
    setcatoii(false);
    settiposaccudente(false);
    setParent(false);
    setCobe(false);
    setRea(false);
    setCuentamay(false);
    setMone(false);
    setDocu(false);
    setPru(false);
    settiposhom(false);
    settipprod(false);
    settiposaccudente(false);
    setRolesp(false);
    setComplejidads(false);
    setCausass(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };
  // Tabla documentos
  const [docu, setDocu] = useState(false);
  const handleClickDocu = () => {
    setDocu(true);
    setEnfer(false);
    setcatoii(false);
    setOfici(false);
    settipprod(false);
    setForpa(false);
    setCuentamay(false);
    setCobe(false);
    settiposhom(false);
    setRea(false);
    setPlata(false);
    setParent(false);
    setcatoii(false);
    settiposaccudente(false);
    setCanales(false);
    setMone(false);
    setPru(false);
    setRolesp(false);
    setComplejidads(false);
    setCausass(false);
    setTipocarta(false);
    setBanco(false);
    setTarifasInv(false);
  };

  // Tabla de Prueba
  const [pru, setPru] = useState(false);
  const handleClickprueba = () => {
    setPru(true);
    setcatoii(false);
    setParent(false);
    setDocu(false);
    setCanales(false);
    setOfici(false);
    setEnfer(false);
    setPlata(false);
    setCuentamay(false);
    settipprod(false);
    setForpa(false);
    setCobe(false);
    setcatoii(false);
    settiposaccudente(false);
    settiposhom(false);
    setRea(false);
    setMone(false);
    setRolesp(false);
    setComplejidads(false);
    setCausass(false);
    setBanco(false);
    setTarifasInv(false);
  };

   //Tabla Bancos prueba
   const [banco, setBanco] = useState(false);
   const handleClickBanco = () => {
     setBanco(true);
     setRea(false);
     setCuentamay(false);
     settipprod(false);
     setCobe(false);
     setPlata(false);
     settiposhom(false);
     setcatoii(false);
     setEnfer(false);
     setParent(false);
     setForpa(false);
     setOfici(false);
     setDocu(false);
     setPru(false);
     settiposaccudente(false);
     setCanales(false);
     setRolesp(false);
     setComplejidads(false);
     setCausass(false);
     setTipocarta(false);
     setMone(false);
     setTarifasInv(false);
   };

   //Tabla Tarifas investigador
   const [tarifasInv, setTarifasInv] = useState(false);
   const handleClickTarifasInv = () => {
     setTarifasInv(true);
     setBanco(false);
     setRea(false);
     setCuentamay(false);
     settipprod(false);
     setCobe(false);
     setPlata(false);
     settiposhom(false);
     setcatoii(false);
     setEnfer(false);
     setParent(false);
     setForpa(false);
     setOfici(false);
     setDocu(false);
     setPru(false);
     settiposaccudente(false);
     setCanales(false);
     setRolesp(false);
     setComplejidads(false);
     setCausass(false);
     setTipocarta(false);
     setMone(false);
   };
  return (
    <div className="div-parent">
      <div className="div-breadcrumbs">
        <Recorrido
          name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
          enlace="/confadmin"
          name2={<FormattedMessage id="globCata" defaultMessage="Catálogo" />}
        />
      </div>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <ReturnPage href="/menuadmin" />
      </Grid>
      <div className="div-container">
        <Grid container spacing={2}>
          <Grid>
            <Card>
              <SelectsCobe
                onClick1={handleClickMone}
                onClick2={handleClickRea}
                onClick3={handleClickCobe}
                onClick4={handleClickForpa}
                onClick5={handleClickOfici}
                onClick6={handleClickprueba}
                onClick7={handleClickDocu}
                onClick8={handleClickcuentamayor}
                onClick9={handleClickcanales}
                onClick10={handleClickplata}
                onClick11={handleClicktipprodc}
                onClick12={handleClickparents}
                onClick13={handleClickenferm}
                onClick14={handleClicktiposhom}
                onClick15={handleClicktiposaccidente}
                onClick16={handleClicktiposSAT}
                onClick17={handleClickcatoii}
                onClick18={handleClickRolesp}
                onClick19={handleClickComplejidads}
                onClick20={handleClickCausass}
                onClick21={handleClickTipocarta}
                onClick22={handleClickBanco}
                onClick23={handleClickTarifasInv}
                
              />
            </Card>
          </Grid>
          <br />
          <Grid xs={12}>
            <br />
            <Card>
              {cuentamay ? <Cuentasmayor /> : null}
              {mone ? <TablaMonedas /> : null}
              {rea ? <TablaReaseguradora /> : null}
              {cobe ? <TablaCobertura /> : null}
              {forpa ? <TablaFormapago /> : null}
              {ofici ? <TablaOficina /> : null}
              {docu ? <Tabladocumentos /> : null}
              {pru ? <Tablaprueba /> : null}
              {canales ? <Canales /> : null}
              {plata ? <Plataformas /> : null}
              {tipprod ? <Tiposproducto /> : null}
              {paren ? <Parentesco /> : null}
              {enfer ? <TiposdeEnfermedad /> : null}
              {tiposhom ? <TiposdeHomicidio /> : null}
              {tiposaccidente ? <Tiposdeaccidentes /> : null}
              {tipoSAT ? <TipospersonasSAt /> : null}
              {oii ? <Catalogoii /> : null}
              {rol ? <TablaRoles /> : null}
              {complejidad ? <TablaComplejidad /> : null}
              {causas ? <TablaCausas /> : null}
              {tipocarta ? <TablaTipoCarta /> : null}
              {banco ? <BancosTabla /> : null}
              {tarifasInv ? <TarifaInv /> : null}
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
