import React from 'react';

export default function Page() {
  return (
    <div>
    <h1 >Texto h1</h1>
    <br/>
    <h2>  Texto h2</h2>
    <br/>
    <h3> Texto h3</h3>
    <br/>
    <h4> Texto h4</h4>
    <br/>
    <h5> Texto h5</h5>
    <br/>
    <h6> Texto h6</h6>
    <br/>
    <h7> Texto h7</h7>
    <br/>
    <label> Label </label>
    </div>
    )
}
