import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";

const CustomSeparator4 = (props) => {
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      href={props.enlace}
      to={props.to}
      state={props.state}
      style={{ textDecoration: "none" }}
    >
      <h2-breadcrumbs-primary>{props.name1}</h2-breadcrumbs-primary>
    </Link>,
    <Link
      underline="hover"
      key="2"
      href={props.enlace2}
      to={props.to2}
      state={props.state2}
      style={{ textDecoration: "none" }}
    >
      <h2-breadcrumbs-primary>{props.name2}</h2-breadcrumbs-primary>
    </Link>,
    <Link
      underline="hover"
      key="3"
      href={props.enlace3}
      to={props.to3}
      state={props.state3}
      style={{ textDecoration: "none" }}
    >
      <h2-breadcrumbs-primary>{props.name3}</h2-breadcrumbs-primary>
    </Link>,
    <Link
      underline="hover"
      key="4"
      href={props.enlace4}
      to={props.to4}
      state={props.state4}
      style={{ textDecoration: "none" }}
    >
      <h2-breadcrumbs-primary>{props.name4}</h2-breadcrumbs-primary>
    </Link>,
    <Typography key="5" color="secondary">
      <h3-breadcrumbs-secundary>{props.name5}</h3-breadcrumbs-secundary>
    </Typography>,
  ];

  return (
    <Stack spacing={4}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};

export default CustomSeparator4;
