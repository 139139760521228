import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DialogButton from '../../Tools/Dialog/DialogButton';
import validateEmail from '../../Login/Elements/validateEmail';
import RadioDinamico from '../../Tools/RadioButton/RadioDinamico';
import Check2 from '../../Tools/CheckBox/Check2';
import TextField from '../../Tools/TextField/TextField';
import Box from '@mui/material/Box';
import { FormattedMessage } from 'react-intl';
import { getToken } from '../../../api/token';
import { BASE_API, DOMINIOEMPRESARIAL } from '../../../utils/constans';
import { secundario } from '../../../api/getUmbrales';
import { getDataToken } from '../../../api/dataToken';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PasswordTXTField from '../../Tools/TextField/TextFieldPass';
import { getUsers } from "../../Login/apiLogin/getUsers";


//MODAL
const arrayEstatus = [
  {'id': '1', 'value': 'true' , 'label':'Activo'},
  {'id': '2', 'value': 'false', 'label':'Inactivo'},
]

const ModalUser = ({ open, onClose, selectdata, setselectdata, setTableData, isEditing, setIsEditing }) => {
  //console.log("Valor de open en ModalUser:", open);
  const [searchTerm, setSearchTerm] = useState("");
  const [Successful, setSuccessful] = React.useState({});
  const [emailExits, setEmailExits] = React.useState({});
  const [link, setLink] = React.useState({});

  function refreshPage() {
    //window.location.reload(false);
    setSearchTerm("") // si es "" mostrará todos los datos de la tabla
    setbtnDisbale(true) 
    setselectdata("")
    setTableData([])
    resetState()
    getPermisos()   
  }

  const getPermisos = async () => {
    try {
      const resultID = await getDataToken("login/get_listado_permiso/");
      setTableData(resultID);
    } catch (error) {
      console.error("Error al obtener los datos de permisos:", error);
    }
  };

  const [fullWidth, setFullWidth] = React.useState(true);
 
  const handleClose = () => {
    onClose(); // Llama a la función onClose pasada como prop
  };

  const handleCancelEdit = () => {
    setIsEditing(false); 
    resetState();
    onClose();
  };

  const isPassword = (password) =>
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/.test(password);

  const token = getToken();
  

/* CONSTANTES DE ESTADOS*/
  const [btnDisbale, setbtnDisbale] = useState(true)//estado del boton

  const resetState = () => {
    setValues({email:"",password:"",username:"",lastName:"",maternalsurname:"",estatusss:""});
    setvalordef({
      ismedview: false,
      ismedadd: false,
      ismedchan: false,
      isviewreg: false,
      isaddreg: false,
      ischangereg: false,
      isaddrec: false,
      isviewrec: false,
      ischangerec: false,
      isviewdict: false,
      isadddict: false,
      ischangedict: false,
      isviewinve: false,
      isaddinve: false,
      ischangeinve: false,
      isviewcomcli: false,
      isaddcomcli: false,
      ischangecomcli: false,
      isviewestcon: false,
      isaddestcon: false,
      ischangeestcon: false,
      isviewwork: false,
      isaddwork: false,
      ischangework: false,
      isviewadmin: false,
      isaddadmin: false,
      ischangeadmin: false,
      isviewsuper: false,
      isaddsuper: false,
      ischangesuper: false,
    });
    //console.log(values.email)
  };

  

  const [values, setValues] = React.useState({
    email: "",
    password: "",
    username: "",
    lastName: "",
    maternalsurname: "",
    estatusss:"",
    inicial:""
  });

  React.useEffect(() => {
    setValues(prevValues => ({
      ...prevValues,
      email: selectdata.SNYTLOG_email || "",
      username: selectdata.SNYTLOG_username || "",
      lastName: selectdata.SNYTLOG_surnamedad || "",
      maternalsurname: selectdata.SNYTLOG_surnamemom || "",
      estatusss: selectdata.SNYTLOG_estatus || ""
    }));
  }, [selectdata.SNYTLOG_email, selectdata.SNYTLOG_username, selectdata.SNYTLOG_surnamedad, selectdata.SNYTLOG_surnamemom, selectdata.SNYTLOG_estatus]);


  const handleChange = (prop) => (event) => {
    const newValue = event.target.value;
    setValues({ ...values, [prop]: newValue });
    //console.log(`New value for ${prop}:`, newValue);

  };

  React.useEffect(() => {
    // Validamos si el servidor esta jalando.
    getUsers(`${BASE_API}login/get_listado/`)
      .then((json) => {
        setSuccessful(true);
      })
      .catch((error) => {
        console.error("There was an error:", error);
        setSuccessful(false);
      });
  }, []);
  // console.log(Successful);
  // Validamos en la api si existe un asuario con  el correo

  getUsers(`${BASE_API}login/get_correo/${values.email}/`)
    .then((json) => {
      console.log(json);
      setEmailExits(json.SNYTLOG_email);
    })
    .catch((error) => {
      console.error("There was an error:", error);
    });

  const [valordef, setvalordef  ] = React.useState({
    ismedview: false,
    ismedadd: false,
    ismedchan: false,

    isviewreg: false,
    isaddreg: false,
    ischangereg :false,

    isaddrec: false,
    isviewrec: false,
    ischangerec: false,

    isviewdict: false,
    isadddict: false,
    ischangedict: false,

    isviewinve: false,
    isaddinve: false,
    ischangeinve: false,

    isviewcomcli:false,
    isaddcomcli: false,
    ischangecomcli: false,

    isviewestcon: false,
    isaddestcon: false,
    ischangeestcon: false,

    isviewwork: false,
    isaddwork: false,
    ischangework: false,


    isviewadmin: false,
    isaddadmin: false,
    ischangeadmin: false,

    isviewsuper: false,
    isaddsuper: false,
    ischangesuper: false,

    isviewgestor: false,
    isaddgestor: false,
    ischangegestor: false,


  });

  // eventosdeloscheck

  var viewmed,addmed,chanmed,viewrec,addrec,chanrec,viewreg,addreg,chanreg,viewinv,addinv,chaninv,viewcomclie,addcomcli,chancomcli,viewadmin,addadmin,chanadmin,viewsuper,addsuper,chansuper,viewgestor, addgestor, changegestor = 0
  //viewdic,adddic,chandic,viewconta,addconta,chanconta,viewwork,addwork,chanwork 

 /* const handleviewMedico = (event) => {
   (valordef.ismedview) = (event.target.checked);;
 
  };*/
  const handleviewMedico = (event) => {
    setvalordef(prevState => ({
        ...prevState,
        ismedview: event.target.checked
    }));
};


  const handleaddMedico = (event) => {
     (valordef.ismedadd)=(event.target.checked);
  };

  const handlechangeMedico = (event) => {
      (valordef.ismedchan)=(event.target.checked);
  };

//recepcion checked
  const handleviewRecepcion = (event) => {
      (valordef.isviewrec)=(event.target.checked);
  };

  const handleaddRecepcion = (event) => {
      (valordef.isaddrec)=(event.target.checked);
  };

  const handlechangeRecepcion = (event) => {
      (valordef.ischangerec)=(event.target.checked);
  };

  //registro checked
  const handleviewRegistro = (event) => {
      (valordef.isviewreg)=(event.target.checked);
  };

  const handleaddRegistro = (event) => {
      (valordef.isaddreg)=(event.target.checked);
  };

  const handlechangeRegistro = (event) => {
      (valordef.ischangereg)=(event.target.checked);
  };

//dictamen
  const handleviewDictamen = (event) => {
      (valordef.isviewdict)=(event.target.checked);
  };

  const handleaddDictamen = (event) => {
      (valordef.isadddict)=(event.target.checked);
  };

  const handlechangeDictamen = (event) => {
      (valordef.ischangedict)=(event.target.checked);
  };

//investi
  const handleviewInves = (event) => {
      (valordef.isviewinve)=(event.target.checked);
  };

  const handleaddInves = (event) => {
      (valordef.isaddinve)=(event.target.checked);
  };

  const handlechangeInves= (event) => {
      (valordef.ischangeinve)=(event.target.checked);
  };


  const handleviewComcli = (event) => {
      (valordef.isviewcomcli)=(event.target.checked);
  };

  const handleaddComcli = (event) => {
      (valordef.isaddcomcli)=(event.target.checked);
  };

  const handlechangeComcli = (event) => {
      (valordef.ischangecomcli)=(event.target.checked);
  };

//contable
  const handleviewEstCon = (event) => {
      (valordef.isviewestcon)=(event.target.checked);
  };

  const handleaddEstCon = (event) => {
      (valordef.isaddestcon)=(event.target.checked);
  };

  const handlechangeEstCon = (event) => {
      (valordef.ischangeestcon)=(event.target.checked);
  };
//workflow
  const handleviewWork = (event) => {
    (valordef.isviewwork)=(event.target.checked);
  };

  const handleaddWork = (event) => {
    (valordef.isaddwork)=(event.target.checked);
  };

  const handlechangeWork = (event) => {
    (valordef.ischangework)=(event.target.checked);
  };
//admin
  const handleviewAdmin = (event) => {
    (valordef.isviewadmin)=(event.target.checked);
  };

  const handleaddAdmin = (event) => {
    (valordef.isaddadmin)=(event.target.checked);
  };

  const handlechangeAdmin = (event) => {
    (valordef.ischangeadmin)=(event.target.checked);
  };

//superusuario
const handleviewSuper = (event) => {
  (valordef.isviewsuper)=(event.target.checked);
};

const handleaddSuper = (event) => {
  (valordef.isaddsuper)=(event.target.checked);
};

const handlechangeSuper = (event) => {
  (valordef.ischangesuper)=(event.target.checked);
};


const handleViewGestor = (event) => {
  setvalordef(prevState => ({
      ...prevState,
      isviewgestor: event.target.checked
  }));
};


const handleAddGestor = (event) => {
  (valordef.isaddgestor)=(event.target.checked);
};

const handleChangeGestor = (event) => {
  (valordef.ischangegestor)=(event.target.checked);
};


    //para cambiar el estado de los switch y compar con el numero de grupo
    useEffect(() => {
      if(selectdata !== ""){

    //medico   
      valordef.ismedchan=(selectdata.groups.includes(4))
      valordef.ismedview=(selectdata.groups.includes(2))
      valordef.ismedadd=(selectdata.groups.includes(3))
    
    //recepcion
      valordef.isviewrec=(selectdata.groups.includes(5))
      valordef.isaddrec=(selectdata.groups.includes(6))
      valordef.ischangerec=(selectdata.groups.includes(7))

    //registro
      valordef.isviewreg=(selectdata.groups.includes(8))
      valordef.isaddreg=(selectdata.groups.includes(9))
      valordef.ischangereg=(selectdata.groups.includes(10))

    //investigador
      valordef.isviewinve=(selectdata.groups.includes(11))
      valordef.isaddinve=(selectdata.groups.includes(12))
      valordef.ischangeinve=(selectdata.groups.includes(13))

    //comcliente
      valordef.isviewcomcli=(selectdata.groups.includes(14))
      valordef.isaddcomcli=(selectdata.groups.includes(15))
      valordef.ischangecomcli=(selectdata.groups.includes(16))

    //workflow
      
      // valordef.isviewwork=(selectdata.groups.includes(14))
      // valordef.isaddwork=(selectdata.groups.includes(15))
      // valordef.ischangework=(selectdata.groups.includes(16))

   // admin
      
      valordef.isviewadmin=(selectdata.groups.includes(17))
      valordef.isaddadmin=(selectdata.groups.includes(18))
      valordef.ischangeadmin=(selectdata.groups.includes(19))

    //superusu

    valordef.isviewsuper=(selectdata.groups.includes(30))
    valordef.isaddsuper=(selectdata.groups.includes(31))
    valordef.ischangesuper=(selectdata.groups.includes(32))

    valordef.isviewgestor=(selectdata.groups.includes(33))
    valordef.isaddgestor=(selectdata.groups.includes(34))
    valordef.ischangegestor=(selectdata.groups.includes(35))

    }

    },[selectdata])


    const [errorsExits, setErrorsExits] = React.useState({});
    const [errorsTitle, setErrorsTitle] = React.useState({});
   

//INGRESAR LOS datos compara y asigna valores si cumple ademas de hacer el put

  const validateAndSubmitForm = (e) => {
    e.preventDefault();

    const errorsExits = {};
    const errorsTitle = {};
   

  
    //medico
    if(valordef.ismedview=== true){viewmed = 2}else{viewmed =1 }
    if(valordef.ismedadd === true){addmed = 4}else{addmed = 1}
    if(valordef.ismedchan === true){chanmed = 3}else{chanmed = 1}
    //recepcion
    if(valordef.isviewrec === true){viewrec = 5}else{viewrec = 1 }
    if(valordef.isaddrec === true){addrec = 6}else{addrec = 1}
    if(valordef.ischangerec === true){chanrec = 7}else{chanrec = 1}
    //registro
    if(valordef.isviewreg === true){viewreg = 8}else{viewreg = 1 }
    if(valordef.isaddreg === true){addreg = 9}else{addreg = 1}
    if(valordef.ischangereg === true){chanreg = 10}else{chanreg = 1}
    //dictamen
    // if(valordef.isviewdict === true){viewdic = 2}else{viewdic = 1 }
    // if(valordef.isadddict === true){adddic = 4}else{adddic = 1}
    // if(valordef.ischangedict === true){chandic = 3}else{chandic = 1}
    //investigador
    if(valordef.isviewinve === true){viewinv = 11}else{viewinv = 1 }
    if(valordef.isaddinve === true){addinv = 12}else{addinv = 1}
    if(valordef.ischangeinve === true){chaninv = 13}else{chaninv = 1}
    //comcliente
    if(valordef.isviewcomcli === true){viewcomclie = 14}else{viewcomclie = 1 }
    if(valordef.isaddcomcli === true){addcomcli = 15}else{addcomcli = 1}
    if(valordef.ischangecomcli === true){chancomcli = 16}else{chancomcli = 1}
    //estaconta
    // if(valordef.isviewestcon === true){viewconta = 2}else{viewconta = 1 }
    // if(valordef.isaddestcon === true){addconta = 4}else{addconta = 1}
    // if(valordef.ischangeestcon === true){chanconta = 3}else{chanconta = 1} 
    //admin
     if(valordef.isviewadmin === true){viewadmin = 17}else{viewadmin = 1 }
     if(valordef.isaddadmin === true){addadmin = 18}else{addadmin = 1}
     if(valordef.ischangeadmin === true){chanadmin = 19}else{chanadmin = 1}
     
     //supervisor
     //admin
     if(valordef.isviewsuper === true){viewsuper = 30}else{viewsuper = 1 }
     if(valordef.isaddsuper === true){addsuper = 31}else{addsuper = 1}
     if(valordef.ischangesuper === true){chansuper = 32}else{chansuper = 1}

     if(valordef.isviewgestor === true){viewsuper = 33}else{viewgestor = 1 }
     if(valordef.isaddgestor === true){addsuper = 34}else{addgestor = 1}
     if(valordef.ischangegestor === true){chansuper = 35}else{changegestor = 1}
  //console.log(valordef.isviewgestor)
  //   console.log(viewmed)

 /* console.log("emailExits:", emailExits);
  console.log("values.email:", values.email);
  console.log("isEditing:", isEditing);
  console.log("Successful:", Successful);*/

  if (values.email && !validateEmail(values.email, DOMINIOEMPRESARIAL)) {
    errorsTitle.email = (
      <FormattedMessage
        id="globCorreo"
        defaultMessage="Correo inválido"
      />
    );
    errorsExits.email = (
      <FormattedMessage
        id="globElCorr"
        defaultMessage="El correo no cumple con el formato empresarial."
      />
    );
  } else if (values.password && !isPassword(values.password)) {
    errorsTitle.email = (
      <FormattedMessage
        id="globContrasenaInorre"
        defaultMessage="Contraseña incorrecta"
      />
    );
    errorsExits.email = (
      <FormattedMessage
        id="globLaContrasenaNoCu"
        defaultMessage="La contraseña no cumple con el formato."
      />
    );
  } else if (emailExits === values.email && !isEditing) {
    errorsTitle.email = (
      <FormattedMessage
        id="globUsuarioexistente"
        defaultMessage="Usuario existente"
      />
    );
    errorsExits.email = (
      <FormattedMessage
        id="globYaExisteunaCuent"
        defaultMessage="Ya existe una cuenta con los datos ingresados."
      />
    );
  } else if (emailExits !== values.email && !isEditing && Successful === true) {
    // Manejar el caso en que el correo electrónico es válido y no existe en emailExits
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        SNYTLOG_username: values.username,
        SNYTLOG_email: values.email,
        password: values.password,
        SNYTLOG_surnamedad: values.lastName,
        SNYTLOG_surnamemom: values.maternalsurname,
        SNYTLOG_admin: valordef.isviewadmin,
        SNYTLOG_estatus: values.estatusss,
        is_staff: values.estatusss,
      }),
    };
    //console.log("requestOptions:", requestOptions);
    //Si la respuesta de la solicitud POST es exitosa, se construye la solicitud PUT con la información de los roles y permisos del usuari
    fetch(`${BASE_API}login/register/`, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());
        //console.log("Response:", response);
        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        } else {
          return fetch(`${BASE_API}login/putall/` + values.email + "/", {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              SNYTLOG_email: values.email,
              SNYTLOG_medico: valordef.ismedview,
              SNYTLOG_REGISTRO: valordef.isviewreg,
              SNYTLOG_recepcion: valordef.isviewrec,
              SNYTLOG_dictamen: valordef.isviewdict,
              SNYTLOG_investigacion: valordef.isviewinve,
              SNYTLOG_comunicacionconelcliente: valordef.isviewcomcli,
              SNYTLOG_estadistincontable: valordef.isaddestcon,
              SNYTLOG_gestordecompetencias: valordef.isviewgestor,
              groups: [
                viewmed,
                addmed,
                chanmed,
                viewrec,
                addrec,
                chanrec,
                viewreg,
                addreg,
                chanreg,
                viewinv,
                addinv,
                chaninv,
                viewcomclie,
                addcomcli,
                chancomcli,
                viewadmin,
                addadmin,
                chanadmin,
                viewsuper,
                addsuper,
                chansuper,
                viewgestor,
                addgestor,
                changegestor,
                
              ],
            }),
          });
        }
      })
  
      .catch((error) => {
        console.error("There was an error!", error);
      });
  
    errorsTitle.email = (
      <FormattedMessage
        id="globUsuarioCreadoExi"
        defaultMessage="Usuario creado exitosamente"
      />
    );
    errorsExits.email = (
      <>
        <FormattedMessage
          id="globSeRegi"
          defaultMessage="Se registraron los datos correctamente."
        />
        <br />
        <FormattedMessage
          id="globIniciaSesionPara"
          defaultMessage="Inicia sesión para continuar."
        />
      </>
    );
    fetch(`${BASE_API}login/putall/` + values.email + "/", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ 
          SNYTLOG_email: values.email,
          SNYTLOG_medico: valordef.ismedview,
          SNYTLOG_REGISTRO: valordef.isviewreg,
          SNYTLOG_recepcion: valordef.isviewrec,
          SNYTLOG_dictamen: valordef.isviewdict,
          SNYTLOG_investigacion: valordef.isviewinve,
          SNYTLOG_comunicacionconelcliente: valordef.isviewcomcli,
          SNYTLOG_estadistincontable: valordef.isaddestcon,
          SNYTLOG_gestordecompetencias: valordef.isviewgestor,
          groups: [viewmed, addmed, chanmed, viewrec, addrec, chanrec, viewreg, addreg, chanreg, viewinv, addinv, chaninv, viewcomclie, addcomcli, chancomcli, viewadmin, addadmin, chanadmin, viewsuper, addsuper, chansuper, viewgestor, addgestor, changegestor]
        })
      
    });
    // Refrescar la página después de un breve retraso
    setTimeout(() => {
      handleClose();
      refreshPage();
    }, 2000);
  } else if (emailExits) {
    // Manejar el caso en que el correo electrónico ya existe en emailExits
    const requestOptions = {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        SNYTLOG_username: values.username,
        SNYTLOG_surnamedad: values.lastName,
        SNYTLOG_surnamemom: values.maternalsurname,
        password: values.password,
        SNYTLOG_email: values.email,
        SNYTLOG_estatus: values.estatusss,
        SNYTLOG_medico: valordef.ismedview,
        SNYTLOG_REGISTRO: valordef.isviewreg,
        SNYTLOG_recepcion: valordef.isviewrec,
        SNYTLOG_dictamen: valordef.isviewdict,
        SNYTLOG_investigacion: valordef.isviewinve,
        SNYTLOG_comunicacionconelcliente: valordef.isviewcomcli,
        SNYTLOG_estadistincontable: valordef.isaddestcon,
        SNYTLOG_gestordecompetencias: valordef.isviewgestor,
        groups: [viewmed, addmed, chanmed, viewrec, addrec, chanrec, viewreg, addreg, chanreg, viewinv, addinv, chaninv, viewcomclie, addcomcli, chancomcli, viewadmin, addadmin, chanadmin, viewsuper, addsuper, chansuper, viewgestor, addgestor, changegestor]
      })
    };
    console.log("requestOptions:", requestOptions);
    fetch(`${BASE_API}login/putall/` + selectdata.SNYTLOG_email + "/", requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());
  
        console.log("Response:", response);
  
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    errorsTitle.email = (
      <FormattedMessage
        id="globUsuarioCreadoEdssdxi"
        defaultMessage="Operación exitosa"
      />
    );
    errorsExits.email = (
      <>
        <FormattedMessage
          id="globIniciaSesionPara"
          defaultMessage="Los cambios se han guardado exitosamente."
        />
      </>
    );
    // Refrescar la página después de un breve retraso
    setTimeout(() => {
      handleClose();
      refreshPage();
    }, 2000);
  }
  
  setErrorsExits(errorsExits);
  setErrorsTitle(errorsTitle);
}

  return (   
    <Dialog
      fullWidth={fullWidth}
      maxWidth="md" 
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        {isEditing ? (
          <FormattedMessage id="adminEditr" defaultMessage="Editar Usuario" />
        ) : (
          <FormattedMessage id="adminEditr" defaultMessage="Agregar Usuario" />
        )}
      </DialogTitle>
      <DialogContent>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '35ch' },
        }}
        noValidate
        autoComplete="off"
      >
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px', margin: '20px' }}>
        {/* Primera columna */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <TextField
            fullWidth
            label={<FormattedMessage id="datosNombs" defaultMessage="Nombre(s)" />}
            InputLabelProps={{ shrink: true }}
            onChange={handleChange("username")}
            value={isEditing ? (values.username === "" ? selectdata.SNYTLOG_username : values.username) : values.username}
            //placeholder={selectdata.SNYTLOG_username}
            size="small"
            variant="standard"
          />
          <TextField
            fullWidth
            label={<FormattedMessage id="datosApPat" defaultMessage="Apellido paterno" />}
            InputLabelProps={{ shrink: true }}
            onChange={handleChange("lastName")}
            //placeholder={selectdata.SNYTLOG_surnamedad}
            value={isEditing ? (values.lastName === "" ? selectdata.SNYTLOG_surnamedad : values.lastName) : values.lastName}
            size="small"
            variant="standard"
          />
          <TextField
            fullWidth
            label={<FormattedMessage id="datosApMasst" defaultMessage="Apellido Materno" />}
            InputLabelProps={{ shrink: true }}
            onChange={handleChange("maternalsurname")}
            //placeholder={selectdata.SNYTLOG_surnamemom}
            value={isEditing ? (values.maternalsurname === "" ? selectdata.SNYTLOG_surnamemom : values.maternalsurname): values.maternalsurname}
            size="small"
            variant="standard"
          />
        </Box>
        {/* Segunda columna */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        <PasswordTXTField
            size="small"
            label={<FormattedMessage id="globCurp" defaultMessage="Contraseña" />}
            onChange={handleChange("password")}
            value={values.password}
            variant="standard"
          /> 
          <TextField
            onChange={handleChange("email")}
            label={<FormattedMessage id="comuCorreo" defaultMessage="Correo electrónico" />}
            value={isEditing ? (values.email === "" ? selectdata.SNYTLOG_email : values.email): values.email}
            required={true}
            InputLabelProps={{ shrink: true }}
            size="small"
            variant="standard"
            //placeholder={selectdata.SNYTLOG_email}
          />
        </Box>
        {/* Tercera columna */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <div class="vents7">
            {/*radio*/}
            <RadioDinamico
              id='rb-estatus'
              row={true}
              array={arrayEstatus}
              formLabel={<FormattedMessage id="globEstatu" defaultMessage="Estatus" />}
              onChange={handleChange("estatusss")}
              defaultValue={values.estatusss}
            />
          </div>
        </Box>
      </Box>
    </Box>
    <DialogTitle>
      <FormattedMessage id="adminEditr" defaultMessage="Roles y permisos" />        
    </DialogTitle>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead style={{color:'white',backgroundColor:secundario}}>
          <TableRow>
            <TableCell style={{color:'white'}} align="center">Grupos</TableCell>
            <TableCell style={{color:'white'}} align="center"><FormattedMessage id="globRecepc" defaultMessage="Recepción"/></TableCell>
            <TableCell style={{color:'white'}} align="center"><FormattedMessage id="globRegist" defaultMessage="Registro"/></TableCell>
            <TableCell style={{color:'white'}} align="center"><FormattedMessage id="menuDictam" defaultMessage="Dictamen"/></TableCell>
            <TableCell style={{color:'white'}} align="center"><FormattedMessage id="globMedico" defaultMessage="Médico"/> </TableCell>
            <TableCell style={{color:'white'}} align="center"><FormattedMessage id="menuInvest" defaultMessage="Investigación"/></TableCell>
            <TableCell style={{color:'white'}} align="center"><FormattedMessage id="globCoClie" defaultMessage="Comunicación con el Cliente   "/>  </TableCell>
            <TableCell style={{color:'white'}} align="center"><FormattedMessage id="menuEstCot" defaultMessage="Estadístico Contables    "/> </TableCell>
            <TableCell style={{color:'white'}} align="center"><FormattedMessage id="ds" defaultMessage="WorkFlow"/> </TableCell>
            <TableCell style={{color:'white'}} align="center"><FormattedMessage id="adminAdmin" defaultMessage="Administrador"/></TableCell>
            <TableCell style={{color:'white'}} align="center"><FormattedMessage id="adsamin" defaultMessage="Supervisor"/></TableCell>
            <TableCell style={{color:'white'}} align="center"><FormattedMessage id="gestionCom" defaultMessage="Gestión de competencias"/></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
          <TableCell  ><FormattedMessage id="globConsul" defaultMessage="Consultar"/> <br/></TableCell>
          {/* viewrecepcion */}
          <TableCell align="center"> 
            <Check2
              defaultChecked={valordef.isviewrec}
              onChange={(e) =>handleviewRecepcion(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* view registro */}
          <TableCell align="center">
            <Check2
              defaultChecked={valordef.isviewreg}
              onChange={(e) =>handleviewRegistro(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* view dictamen */}
          <TableCell align="center">
            <Check2
              defaultChecked={valordef.isviewdict}
              onChange={(e) =>handleviewDictamen(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* view medico */}
          <TableCell align="center">
            <Check2
              //checked={valordef.medview}
              defaultChecked={valordef.ismedview}
              onChange={(e) => handleviewMedico(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* viewinvest */}
          <TableCell  align="center"> 
            <Check2
             //checked={isviewinve}
              defaultChecked={valordef.isviewinve}
              onChange={(e) => handleviewInves(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* viewcomcliente */}
          <TableCell align="center"> 
            <Check2
              defaultChecked={valordef.isviewcomcli}
              onChange={(e) => handleviewComcli(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* viewestadiscontable */}
          <TableCell align="center">
            <Check2
              defaultChecked={valordef.isviewestcon}
              onChange={(e) => handleviewEstCon(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* view workflow*/}
          <TableCell  align="center">
            <Check2
              defaultChecked={valordef.isviewwork}
              onChange={(e) => handleviewWork(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* view administrador*/}
          <TableCell align="center">
            <Check2
              defaultChecked={valordef.isviewadmin}
              onChange={(e) => handleviewAdmin(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          <TableCell align="center">
            <Check2
              defaultChecked={valordef.isviewsuper}
              onChange={(e) => handleviewSuper(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          <TableCell align="center">
            <Check2
              defaultChecked={valordef.isviewgestor}
              onChange={(e) => handleViewGestor(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          </TableRow>
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
          <TableCell  ><FormattedMessage id="aGRE" defaultMessage="Agregar"/></TableCell>
          {/* agregarrececpcion */}
          <TableCell align="center"> 
            <Check2
              defaultChecked={valordef.isaddrec}
              onChange={(e) => handleaddRecepcion(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* agregarregistro */}
          <TableCell align="center"> 
            <Check2
              defaultChecked={valordef.isaddreg}
              onChange={(e) => handleaddRegistro(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* agregardictamen */}
          <TableCell align="center"> 
            <Check2
              defaultChecked={valordef.isadddict}
              onChange={(e) => handleaddDictamen(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
            {/* medicoagregar */}
          <TableCell align="center"> 
            <Check2
              //checked={isaddmed}
              defaultChecked={valordef.ismedadd}
              onChange={(e) => handleaddMedico(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* invesagregar */}
          <TableCell align="center"> 
            <Check2
              defaultChecked={valordef.isaddinve}
              onChange={(e) => handleaddInves(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* comclienteagregar */}
          <TableCell align="center"> 
            <Check2
              defaultChecked={valordef.isaddcomcli}
              onChange={(e) =>handleaddComcli(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* agregarestacontable  */}
          <TableCell align="center">
            <Check2
              defaultChecked={valordef.isaddestcon}
              onChange={(e) =>handleaddEstCon(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* agregareswork  */}
          <TableCell align="center">
            <Check2
              defaultChecked={valordef.isaddwork}
              onChange={(e) =>handleaddWork(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* agregaresadmin  */}
          <TableCell align="center">
            <Check2
              defaultChecked={valordef.isaddadmin}
              onChange={(e) =>handleaddAdmin(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* agregaresupervisor  */}
          <TableCell align="center">
            <Check2
              defaultChecked={valordef.isaddsuper}
              onChange={(e) =>handleaddSuper(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          <TableCell align="center">
            <Check2
              defaultChecked={valordef.isaddgestor}
              onChange={(e) =>handleAddGestor(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          </TableRow>
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
          <TableCell><FormattedMessage id="adminEdici" defaultMessage="Edición"/></TableCell>
           {/* edicionrecepcion */}
          <TableCell align="center"> 
            <Check2
              defaultChecked={valordef.ischangerec}
              onChange={(e) =>handlechangeRecepcion(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* edicionregistro */}
          <TableCell align="center"> 
            <Check2
              defaultChecked={valordef.ischangereg}
              onChange={(e) =>handlechangeRegistro(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* ediciondictamen */}
          <TableCell align="center"> 
            <Check2
              defaultChecked={valordef.ischangedict}
              onChange={(e) =>handlechangeDictamen(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
           {/* editarmedico */}
          <TableCell align="center"> 
            <Check2
              defaultChecked={valordef.ismedchan}
              onChange={(e) =>handlechangeMedico(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* edicioninvesti */}
          <TableCell align="center"> 
            <Check2
              defaultChecked={valordef.ischangeinve}
              onChange={(e) =>handlechangeInves(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* edicioncomcliente */}
          <TableCell align="center">  
            <Check2
              defaultChecked={valordef.ischangecomcli}
              onChange={(e) =>handlechangeComcli(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* edicionestacontab */}
          <TableCell align="center"> 
            <Check2
              //checked={ischangeestcon}
              defaultChecked={valordef.ischangeestcon}
              onChange={(e) =>handlechangeEstCon(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* ediconwork  */}
          <TableCell align="center">
            <Check2
              defaultChecked={valordef.ischangework}
              onChange={(e) =>handlechangeWork(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* edicionadmin  */}
          <TableCell align="center">
            <Check2
              defaultChecked={valordef.ischangeadmin}
              onChange={(e) =>handlechangeAdmin(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          {/* edicionsupervisor  */}
          <TableCell align="center">
            <Check2
              defaultChecked={valordef.ischangesuper}
              onChange={(e) =>handlechangeSuper(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          <TableCell align="center">
            <Check2
              defaultChecked={valordef.ischangegestor}
              onChange={(e) =>handleChangeGestor(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          </TableRow>
          <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
          <TableCell><FormattedMessage id="adminCarga" defaultMessage="Carga / Descarga de documentos"/></TableCell>
          <TableCell align="center"> 
            <Check2
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          <TableCell align="center"> 
            <Check2
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          <TableCell align="center"> 
            <Check2
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          <TableCell align="center"> 
            <Check2
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          <TableCell align="center"> 
            <Check2
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          <TableCell align="center"> 
            <Check2
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          <TableCell align="center"> 
            <Check2
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          <TableCell align="center"> 
            <Check2
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          <TableCell align="center"> 
            <Check2
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          <TableCell align="center"> 
            <Check2
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          <TableCell align="center"> 
            <Check2
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
   
      </DialogContent>
    <DialogActions>
      <Button onClick={handleCancelEdit}>
        <FormattedMessage id="globCance" defaultMessage="Cancelar" />
      </Button>
      <form onSubmit={validateAndSubmitForm}>
        <DialogButton
          mainButton={
            <FormattedMessage id="le" defaultMessage="Modificar" />
          }
          title={errorsTitle.email}
          description={errorsExits.email}
          button={
          <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          onClick={() => {
            // Lógica para modificar los datos
            // Cerrar el diálogo
            handleClose();
            
            
          }}
          type="submit"
          to={link.email}
          estatus={
            values.email.length > 0 &&
            //values.password.length > 0 ||
            values.username.length > 0 &&
            values.lastName.length > 0 &&
            values.maternalsurname.length > 0
              ? false
              : true
          }
          
        />
      </form>
    </DialogActions>
    </Dialog>
  
  );
};

export default ModalUser;