import moment from "moment";

//Esta función es para validar si una poliza es disputable o no
export default function obtenerDias(
  fechaRecepcion,
  fechaActual,
  diasplataforma
) {
  // Calcular la diferencia en días
  const diffDays = moment(fechaActual).diff(fechaRecepcion, "days");

  const diastramitefaltante = diasplataforma - diffDays;

  return diastramitefaltante;
}
