import React from "react";
import imgheadersinay from "../../../../img/header_sinay.png";
import imglogo from "../../../../img/logo_sinay.png";

//Modal
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
//Transition
import Slide from "@mui/material/Slide";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
// Create styles
const styles = StyleSheet.create({
  page: {
    // backgroundColor: "#d11fb6",
    // color: "white",
  },
  sectionheader: {
    // margin: 10,
    padding: 5,
    display: "flex",
  },
  sectionbody: {
    margin: 15,
    padding: 15,
  },
  viewer: {
    width: window.innerWidth - 760,
    height: window.innerHeight,
  },
  imagelogo: { width: 70, height: 70, float: "left" },
  title: { fontSize: 14, fontWeight: "extrabold" },
  subtitle: { fontSize: 13 },
  parrafo: {
    fontWeight: "normal",
    fontSize: 12,
    textAlign: "justify",
    padding: 15,
  },
});

//TODO: Transition of dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function FallePDF() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
   <div>
    <Button onClick={handleClickOpen("paper")}> Generar PDF</Button>
    <Dialog
    open={open}
    // onClose={handleClose}
    TransitionComponent={Transition}
    scroll={scroll}
    aria-labelledby="scroll-dialog-title"
    aria-describedby="scroll-dialog-description"
    maxWidth="lg"
  >
    <DialogTitle id="scroll-dialog-title">PDF</DialogTitle>
    <DialogContent dividers={scroll === "paper"}>
      <DialogContentText
        id="scroll-dialog-description"
        ref={descriptionElementRef}
        tabIndex={-1}
      >
        <PDFViewer style={styles.viewer}>
          <Document>
            <Page size="A4" style={styles.page}>
              <View
                style={{
                  display: "flex",
                  padding: 15,
                  flexDirection: "row",
                }}
              >
                <Image style={{ flex: 1 }} src={imglogo} />
                <Image style={{ flex: 7 }} src={imgheadersinay} />
              </View>
              <View style={styles.sectionbody}>
                <Text></Text>
                <Text style={styles.title}>
                  Póliza original. En caso de invalidez, con una copia de
                  la póliza es suficiente.
                </Text>
                <Text>&nbsp;</Text>
                <View style={styles.parrafo}>
                  <textoparaPDF />

                  <Text id="textoaPDF"></Text>
                  <Text>
                    Acta de defunción (Original o copia certificada)
                  </Text>
                  <Text>Certificado de defunción (Copia)</Text>
                  <Text>
                    Identificación oficial del asegurado (Original y
                    copia){" "}
                  </Text>
                  <Text>
                    Historial Clínico, completo que incluya diagnóstico y
                    tratamiento al que fue sometido el Asegurado
                    Identificación del beneficiario (Original y copia)
                  </Text>
                  <Text>Formato Reclamación</Text>
                  <Text>
                    Formato declaración de fallecimiento no. 1.
                    Debidamente llenado y firmado por los Beneficiarios.
                    Original
                  </Text>
                  <Text>
                    Carpeta de Investigación (Solo en caso de existir)
                  </Text>
                  <Text>
                    Copia Certificada por el M.P incluyendo los resultados
                    de los estudios toxicológicos y de alcoholemia Datos
                    bancario de los beneficiarios copia del estado de
                    cuenta y Formato de información para pago. Debidamente
                    llenado y firmado por el asegurado o beneficiario(s).{" "}
                  </Text>

                  <Text>
                    Formato de reclamación en original formato declaración
                    de fallecimiento no. 2. Debidamente llenado y firmado
                    por el médico tratante del asegurado.
                  </Text>
                  <Text>
                    Original de comprobante de domicilio, no mayor de 3
                    meses (recibo de luz, teléfono, predial, agua, cuenta
                    bancaria, suministro de gas natural).**
                  </Text>
                  <Text>
                    Copia certificada de acta de nacimiento (Solo en caso
                    de que el beneficiario sea menor de edad){" "}
                  </Text>
                  <Text>
                    Copia certificada de acta de defunción (Solo en caso
                    de que alguno de los beneficiarios haya fallecido).
                  </Text>
                  {/* </Text> */}
                  <Text>&nbsp;</Text>
                </View>

                <Text style={styles.subtitle}>
                  Si el beneficiario es una Persona Moral algunos de los
                  documentos qué deberá de presentar son los siguientes:
                </Text>
                <Text>&nbsp;</Text>

                <View style={styles.parrafo}>
                  <Text>
                    Testimonio o copia legible del acta constitutiva
                    inscrita en el registro público de comercio que
                    acredite fehacientemente su legal existencia (Copia
                    notariada){" "}
                  </Text>
                  <Text>
                    Original de la cédula de identificación fiscal (RFC)
                    expendida por la secretaría de hacienda y crédito
                    público.{" "}
                  </Text>
                  <Text>
                    Comprobante de domicilio no mayor a 3 meses (Deberá
                    ser original de recibo de luz, teléfono, predial,
                    agua, cuenta bancaria, suministro de gas natural){" "}
                  </Text>
                  <Text>
                    Testimonio o copia legible del instrumento que
                    contenga los poderes del representante legal expedido
                    por fedatario público cuando no estén contenidos en la
                    escritura constitutiva.{" "}
                  </Text>
                  <Text>
                    Original de la identificación oficial vigente del
                    representante legal con firma y fotografía (INE,
                    pasaporte, cartilla SMN, cédula profesional).{" "}
                  </Text>
                  <Text>
                    En caso de ser extranjeros deberá de presentar copia
                    debidamente legalizada o apostillada del documento que
                    compruebe fehacientemente su legal existencia. Así
                    como del que acredite como su representante a la
                    persona física que ostente como tal y en caso de ser
                    este también extranjera deberá presentar los
                    documentos señaladospara personas físicas.
                  </Text>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Cancelar</Button>
    </DialogActions>
  </Dialog>
  </div> 
  );
}