import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Date from "../../Tools/DatePicker/DatePicker";
import { Select1 } from "../../Tools/Selectss/Select1";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../../Tools/Table/StyledTableCell";
//paginación de la tabla
import TablePagination from "@mui/material/TablePagination";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
//Dialogo
import DialogColumna from "../../Contable/Dialogs/DialogColumna";
import DialogButtons from "../../Tools/Dialog/DialogButtons";
import GraficoCuenta from "../Graficos/GraficoCuenta";
//CheckBox
import CheckGroup from "../../Tools/CheckBox/CheckGroup";
//Dialogo
import DialogButton from "../../Tools/Dialog/DialogButton";
//exportExcel
import { downloadExcel } from "react-export-table-to-excel";
import TXTField from "../../Tools/TextField/TextField";
//Idioma
import { FormattedMessage } from "react-intl";
//funciones de validaciones 
import { LimitarTexto } from "../../Recepcion/Elements/Functions/Validaciones"; 
import { LimitarDigitos} from "../../Recepcion/Elements/Functions/Validaciones"; 
//Datos JSON
import data from "../data.json";
import DatePicker from "../../Tools/DatePicker/DatePicker"
import moment from "moment-timezone";


export default function AccountingReportsTable() {

  const [reporteCuenta, setReporteCuenta] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = require("../data.json");
        console.log("Datos obtenidos:", response);
        setReporteCuenta(response);
      } catch (error) {
        console.error("Error al obtener el archivo JSON:", error);
      }      
    };

    fetchData();
  }, []); 

  //creacion del encabezado del archivo excel
  const header = [
    "NÚMERO DE FOLIO",
    "NUMERO DE SINIESTRO",
    "NÚMERO DE PÓLIZA",
    "FECHA DE AVISO",
    "FECHA DE OCURRENCIA",
    "NOMBRE DEL ASEGURADO",
    "CLAVE OII",
    "DESCRIPCION CLAVE OII ",
    "ESTATUS DEL SINIESTRO",
    "SUMA ASEGURADA BÁSICO",
    "MONEDA SUMA ASEGURADA BÁSICA",
    "SUMA ASEGURADA BENEFICIO MUERTE ACCIDENTAL",
    "MONEDA SUMA ASEGURADA BENEFICIO MUERTE ACCIDENTAL",
    "TIPO DE SINIESTRO",
    "TIPO DE SINIESTRO 2",
    "TIPO DE SINIESTRO 4",
    "TIPO DE SINIESTRO 5",
    "CAUSA DEL SINIESTRO",
    "TIPO DE FALLECIMIENTO (TIPO DE SINIESTRO 3)",
    "CIUDAD DE OCURRENCIA",
    "PAIS DE OCURRENCIA",
    "TIPO DE HOMICIDIO",
    "SEXO DEL ASEGURADO",
    "EDAD DEL ASEGURADO",
    "NOMBRE DEL INVESTIGADOR",
    "ESTATUS DE LA INVESTIGACION",
    "MONTO DE PAGO DE LA INVESTIGACIÓN",
    "FECHA DE PAGO DE LA INVESTIGACIÓN",
    "FECHA DE PAGO",
    "MONTO DE PAGO",
    "MONEDA DEL PAGO",
    "MEDIO DE PAGO",
    "FECHA DE VENCIMIENTO DE LA PÓLIZA",
    "NÚMERO DE CUENTA CONTABLE",
    "FECHA DE REGISTRO CONTABLE",
    "IMPORTE DEL REGISTRO CONTABLE",
    "NATURALEZA DEL MOVIMIENTO",
    "ID DE INTEGRACION",
    "ESTATUS DE LA PÓLIZA VERSION 1",
    "NOMBRE DE BENEFICIARIOS",
    "BENEFICIO ADICIONAL",
    "MONTO DE SA DE BENEFICIO ADICIONAL",
    "MONEDA DEL BENEFICIO ADICIONAL",
    "BENEFICIARIO DEL PAGO",
    "CUENTA BANCARIA DEL BENEFICIARIO",
    "CUENTA DE CARGO ORIGEN DEL PAGO",
    "BANCO DE LA CUENTA BANCARIA",
    "FECHA DE EMISIÓN DE LA PÓLIZA",
    "CANAL ( DE DISTRIBUCIÓN)",
    "CLAVE DE AGENTE",
    "DA AGENCIA, PROMOTORÍA",
    "DISPUTABLE (SI) (NO)",
  ];

   //Constante para habilitar el boton de buscar al seleccionar una opción
   const [btnDisableBuscar, setBtnDisableBuscar] = useState(true);

  //Constante de boton deshabilitado 
  const [btnDisable, setBtnDisable] = useState(true);

  /* CONSTANTES DE ESTADOS*/
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla
  
  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

   //CREACIÓN DE CONSTANTES DE VARIABLES PARA PODER ELIMINAR O AGREGAR COLUMNAS
  //estas constantes funcionan con los check, al comenzar aparecen en false para que 
  //no sean vistas en la tabla al dar click en el check cambiaran sus estados a true y serán vitas(funciona cuando agregas columnas)
  //y si las tenemos en true y le das click su estado cambiara a false(esto servira cuando quieran eliminar la columna)
  const[showclaveOil, setShowclaveOil] = useState(false)
  const[showdescripcionOil, setShowdescripcionOil] = useState(false)
  const[showestatusSiniestro, setShowestatusSiniestro] = useState(false)
  const[showsumaAseguradaBasica, setShowsumaAseguradaBasica] = useState(false)
  const[showmonedaSumaAseguradaBasica, setShowmonedaSumaAseguradaBasica] = useState(false)
  const[showsumaAseguradaBeneficioMuerteAccidental, setShowsumaAseguradaBeneficioMuerteAccidental] = useState(false)
  const[showmonedaSumaAseguradaBeneficioMuerteAccidental, setShowmonedaSumaAseguradaBeneficioMuerteAccidental] = useState(false)
  const[showTipodeReclamacion, setShowTipodeReclamacion] = useState(false)
  const[showtipoSiniestro2, setShowtipoSiniestro2] = useState(false)
  const[showtipoSiniestro4, setShowtipoSiniestro4] = useState(false)
  const[showtipoSiniestro5, setShowtipoSiniestro5] = useState(false)
  const[showcausaSiniestro, setShowcausaSiniestro] = useState(false)
  const[showtipoDeFallecimiento, setShowtipoDeFallecimiento] = useState(false)
  const[showciudadOcurrencia, setShowciudadOcurrencia] = useState(false)
  const[showpaisOcurrencia, setShowpaisOcurrencia] = useState(false)
  const[showtipoHomicidio, setShowtipoHomicidio] = useState(false)
  const[showsexoAsegurado, setShowsexoAsegurado] = useState(false)
  const[showedadAsegurado, setShowedadAsegurado] = useState(false)
  const[shownombreInvestigador, setShownombreInvestigador] = useState(false)
  const[showestatusInvestigacion, setShowestatusInvestigacion] = useState(false)
  const[showmontoDePagoDeLaInvestigacion, setShowmontoDePagoDeLaInvestigacion] = useState(false)
  const[showfechaDePagoDeLaInvestigación, setShowfechaDePagoDeLaInvestigación] = useState(false)
  const[showfechaPago, setShowfechaPago] = useState(false)
  const[showmontoPago, setShowmontoPago] = useState(false)
  const[showmonedaPago, setShowmonedaPago] = useState(false)
  const[showmedioPago, setShowmedioPago] = useState(false)
  const[showfechaDeVencimientoDeLaPoliza, setShowfechaDeVencimientoDeLaPoliza] = useState(false)
  const[showestatusPoliza1, setShowestatusPoliza1] = useState(false)
  const[shownombreBeneficiarios, setShownombreBeneficiarios] = useState(false)
  const[showbeneficioAdicional, setShowbeneficioAdicional] = useState(false)
  const[showmontoSumaAseguradaBeneficioAdicional, setShowmontoSumaAseguradaBeneficioAdicional] = useState(false)
  const[showmonedaAdicional, setShowmonedaAdicional] = useState(false)
  const[showbeneficiarioPago, setShowbeneficiarioPago] = useState(false)
  const[showcuentaBeneficiario, setShowcuentaBeneficiario] = useState(false)
  const[showcuentaDeCargoOrigenDelPago, setShowcuentaDeCargoOrigenDelPago] = useState(false)
  const[showbancoCuentaBancaria, setShowbancoCuentaBancaria] = useState(false)
  const[showfechaDeEmisiónDeLaPoliza, setShowfechaDeEmisiónDeLaPoliza] = useState(false)
  const[showcanalDistribucion, setShowcanalDistribucion] = useState(false)
  const[showclaveAgente, setShowclaveAgente] = useState(false)
  const[showagenciaPromotoria, setShowagenciaPromotoria] = useState(false)
  const[showdisputable, setShowdisputable] = useState(false)

 //Activar el boton de Buscar
 const handleClickBuscar = () => {
  setBtnDisableBuscar(false);
};

    //Funcion para Descargar excel
    function handleDownloadExcel() {
      downloadExcel({
        fileName: "react-export-table-to-excel -> downloadExcel method",
        sheet: "react-export-table-to-excel",
        tablePayload: {
          header,//estos header pertenecen al encabezado que nosotros les dimos 
          body: data//en body se asigna lo que se va a imprimir, en este caso asinamos a row ya que es el arreglo que 
          //contiene toda la información que va en la tabla
        },
      });
    }
  
    //Valida que el formulario este lleno para poder enviar la informacion
  const [formValid, setFormValid] = useState(false);
  const [cuenta, setCuenta] = useState('');

  //Constantes para variables de la fecha 
  const [inicioVencimiento, setInicioVencimiento] = useState("");
  const [finVencimiento, setFinVencimiento] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  //Creación de variables para que pueda funcionar el Date 
  let fechaInicioVencimiento = null;
  let fechaFinVencimiento = null;

  const validateInputs = () => {
    const isValid = cuenta.length === 6; // Ejemplo: validación de longitud de cuenta
    setFormValid(isValid);
  }; 

  const handleChangeCuenta = (event) => {
    const newCuenta = event.target.value;
    setCuenta(newCuenta);
    validateInputs(); // Llama a la validación cada vez que cambie el valor
  };

  const handleDataChanges = (newData) => {
    const fechaVencimiento = newData.toISOString();
    const mexicoCityTime = moment(fechaVencimiento).tz("America/Mexico_City");
    const fechasini = mexicoCityTime.format("YYYY-MM-DD");
    setInicioVencimiento(fechasini);
  }
  const handleDataChangesDos = (newData) => {
    const fechaVencimiento = newData.toISOString();
    const mexicoCityTime = moment(fechaVencimiento).tz("America/Mexico_City");
    const fechasini = mexicoCityTime.format("YYYY-MM-DD");
    setFinVencimiento(fechasini);
  }

  const handleClickDescargar = () => {
    // Lógica para manejar el clic en el botón
    console.log(cuenta)

    setInicioVencimiento(fechaInicioVencimiento);
    setFinVencimiento(fechaFinVencimiento);
    console.log(inicioVencimiento);
    console.log(finVencimiento);


    if(inicioVencimiento && finVencimiento){
 
      //filtaremos los datos en las fechas seleccionadas 
      const filteredResults = reporteCuenta.filter((item) => {

        const itemDate = (item.fechaAviso); // Asume que los datos tienen una propiedad 'date' con un valor de fecha
        const numCuenta = (item.numeroContable)//Constante que guardara solo los el Numero de cuenta
        console.log(itemDate);
        const cuentaIngresada = (cuenta);// el tipo de fallecimiento que mostrará
        return itemDate >= inicioVencimiento && itemDate <= finVencimiento && numCuenta == cuentaIngresada;
      });
      setFilteredData(filteredResults);
      console.log(filteredResults);
      setBtnDisable(false)
    } else {
      console.log("Por favor, selecciona una fecha inicial y una fecha final.");
    }
  };

  return (
    <div>
   <form onSubmit={(e) => { e.preventDefault(); validateInputs(); }}>
    <div>
    <Grid container justifyContent="center" >
          <Grid item>
            <h3-header-card>
              <FormattedMessage id="RepSin" defaultMessage="REPORTE POR CUENTA"/>
            </h3-header-card>
          </Grid>
          </Grid>
          <br/>
       {/* Columna de texto */}
  <Grid container spacing={5}>
          <Grid item xs={3}>
            <h3-subtitle-card>
              <FormattedMessage id="estFechIn" defaultMessage="Fecha de inicio"/>
            </h3-subtitle-card>
          </Grid>
          <Grid item xs={3}>
            <h3-subtitle-card>
              <FormattedMessage id="estFechFin" defaultMessage="Fecha final"/>
            </h3-subtitle-card>
          </Grid>
          <Grid item xs={3}>
            <h3-subtitle-card>
              <FormattedMessage id="Sts" defaultMessage="Número de cuenta"/>
            </h3-subtitle-card>
          </Grid>
          </Grid>
      <br/>

      {/* Columna de los DataPicker */}
      <Grid container spacing={5}>
          <Grid item xs={3}>
          <DatePicker
              fecha={fechaInicioVencimiento}
              onDataChange={handleDataChanges}
            />
          </Grid>

          <Grid item xs={3} spacing={5}>
          <DatePicker
              fecha={fechaFinVencimiento}
              onDataChange={handleDataChangesDos}
            />
          </Grid>

          <Grid item xs={3} spacing={5} style={{paddingTop: '4px'}}>
            <TXTField 
              onInput={(e) => LimitarDigitos(e.target , 6)} 
              onChange={handleChangeCuenta}
              id="cuenta" 
              name="cuenta" 
              value={cuenta}
              error={!formValid}
              // helperText={formValid ? '' : 'Número de cuenta inválido'}
            />          
          </Grid>

           <Grid style={{paddingTop: '40px'}}
         item xs={3}>
              <ButtonVino 
            label="Buscar"
            //estatus={!formValid}
            onClick={handleClickDescargar}/>
          </Grid>  
       </Grid>
    </div>
   </form>

   {/*CREACIÓN DE LA TABLA*/}
   <br />
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 540 }} style={{ position: "sticky" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
              <StyledTableCell> Número de Folio </StyledTableCell>
                <StyledTableCell> Número de siniestro </StyledTableCell>
                <StyledTableCell> Número de póliza </StyledTableCell>
                <StyledTableCell> Fecha de aviso </StyledTableCell>
                <StyledTableCell> Fecha de ocurrencia del siniestro </StyledTableCell>
                <StyledTableCell> Nombre del asegurado </StyledTableCell>
                <StyledTableCell> Número de cuenta contable </StyledTableCell>
                <StyledTableCell> Fecha de registro contable </StyledTableCell>
                <StyledTableCell> Importe del registro contable  </StyledTableCell>
                <StyledTableCell> Naturaleza del movimiento </StyledTableCell> 
                <StyledTableCell> ID de integración </StyledTableCell>  
                {/* Ponemos en condicion los chechk ya que dependiendo el estado en el que se esncuentren
                  se podrán ver las columnas o se ocultarán */}                
                {!showclaveOil ? null:<StyledTableCell> Clave Oil </StyledTableCell>}
                {!showdescripcionOil ? null:<StyledTableCell> Descripción Clave Oil </StyledTableCell>}
                {!showestatusSiniestro ? null:<StyledTableCell> Estatus del Siniestro </StyledTableCell>}
                {!showsumaAseguradaBasica ? null:<StyledTableCell> Suma Asegurada Básico </StyledTableCell>}            
                {!showmonedaSumaAseguradaBasica ? null:<StyledTableCell> Moneda Suma Asegurada Básica </StyledTableCell>}            
                {!showsumaAseguradaBeneficioMuerteAccidental ? null:<StyledTableCell> Suma Asegurada Beneficio Muerte Accidental </StyledTableCell>}            
                {!showmonedaSumaAseguradaBeneficioMuerteAccidental ? null:<StyledTableCell> Moneda Suma Asegurada Beneficio Muerte Accidental </StyledTableCell>}            
                {!showTipodeReclamacion ? null:<StyledTableCell> Tipo de Reclamación </StyledTableCell>}            
                {!showtipoSiniestro2 ? null:<StyledTableCell> Tipo de Siniestro 2 </StyledTableCell>}            
                {!showtipoSiniestro4 ? null:<StyledTableCell> Tipo de Siniestro 4 </StyledTableCell>}            
                {!showtipoSiniestro5 ? null:<StyledTableCell> Tipo de Siniestro 5 </StyledTableCell>}            
                {!showcausaSiniestro ? null:<StyledTableCell> Causa de Siniestro </StyledTableCell>}            
                {!showtipoDeFallecimiento ? null:<StyledTableCell> Tipo de Fallecimiento </StyledTableCell>}            
                {!showciudadOcurrencia ? null:<StyledTableCell> Ciudad de Ocurrencia </StyledTableCell>}            
                {!showpaisOcurrencia ? null:<StyledTableCell> País de Ocurrencia </StyledTableCell>}            
                {!showtipoHomicidio ? null:<StyledTableCell> Tipo de Homicidio </StyledTableCell>}            
                {!showsexoAsegurado ? null:<StyledTableCell> Sexo del Asegurado </StyledTableCell>}            
                {!showedadAsegurado ? null:<StyledTableCell> Edad del Asegurado </StyledTableCell>}            
                {!shownombreInvestigador ? null:<StyledTableCell> Nombre del Investigador </StyledTableCell>}            
                {!showestatusInvestigacion ? null:<StyledTableCell> Estatus de la Investigación  </StyledTableCell>}            
                {!showmontoDePagoDeLaInvestigacion ? null:<StyledTableCell> Monto de Pago de la Investigación </StyledTableCell>}            
                {!showfechaDePagoDeLaInvestigación ? null:<StyledTableCell> Fecha de Pago de la Investigación </StyledTableCell>}            
                {!showfechaPago ? null:<StyledTableCell> Fecha de Pago </StyledTableCell>}            
                {!showmontoPago ? null:<StyledTableCell> Monto de Pago </StyledTableCell>}            
                {!showmonedaPago ? null:<StyledTableCell> Moneda del Pago </StyledTableCell>}            
                {!showmedioPago ? null:<StyledTableCell> Medio de Pago </StyledTableCell>}            
                {!showfechaDeVencimientoDeLaPoliza ? null:<StyledTableCell> Fecha de Vencimiento de la Póliza </StyledTableCell>}            
                {!showestatusPoliza1 ? null:<StyledTableCell> Estatus de la Póliza Verisión 1 </StyledTableCell>}            
                {!shownombreBeneficiarios ? null:<StyledTableCell> Nombre de Beneficiario </StyledTableCell>}            
                {!showbeneficioAdicional ? null:<StyledTableCell> Benedicio Adicional </StyledTableCell>}            
                {!showmontoSumaAseguradaBeneficioAdicional ? null:<StyledTableCell> Monto de Suma Asegurada de Beneficio Adicional </StyledTableCell>}            
                {!showmonedaAdicional ? null:<StyledTableCell> Moneda del Beneficio Adicional </StyledTableCell>}            
                {!showbeneficiarioPago ? null:<StyledTableCell> Beneficiario del Pago </StyledTableCell>}            
                {!showcuentaBeneficiario ? null:<StyledTableCell> Cuenta Bancaria del Beneficiario </StyledTableCell>}            
                {!showcuentaDeCargoOrigenDelPago ? null:<StyledTableCell> Cuenta de Cargo Origen del Pago </StyledTableCell>}            
                {!showbancoCuentaBancaria ? null:<StyledTableCell> Banco de la Cuenta Bancaria </StyledTableCell>}            
                {!showfechaDeEmisiónDeLaPoliza ? null:<StyledTableCell> Fecha de Emisión de la Póliza </StyledTableCell>}            
                {!showcanalDistribucion ? null:<StyledTableCell> Canal de Distribución </StyledTableCell>}            
                {!showclaveAgente ? null:<StyledTableCell> Clave de Agente </StyledTableCell>}            
                {!showagenciaPromotoria ? null:<StyledTableCell> Agencia Promotoría  </StyledTableCell>}            
                {!showdisputable ? null:<StyledTableCell> Disputable (Si) (No) </StyledTableCell>}            
              </TableRow>
            </TableHead>
            {filteredData.length > 0 && (
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {filteredData
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                    val.numFolio.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <TableRow>
                    <StyledTableCell align="center">{item.numFolio}</StyledTableCell>
                    <StyledTableCell align="center">{item.numSiniestro}</StyledTableCell>
                    <StyledTableCell align="center">{item.NumPoliza}</StyledTableCell>
                    <StyledTableCell align="center">{item.fechaAviso}</StyledTableCell>
                    <StyledTableCell align="center">{item.fechaOcurrencia}</StyledTableCell>
                    <StyledTableCell align="center">{item.nombreAsegurado}</StyledTableCell>
                    <StyledTableCell align="center">{item.numeroContable}</StyledTableCell>
                    <StyledTableCell align="center">{item.fechaContable}</StyledTableCell>
                    <StyledTableCell align="center">{item.importeContable}</StyledTableCell>
                    <StyledTableCell align="center">{item.naturalezaDelMovimiento}</StyledTableCell>
                    <StyledTableCell align="center">{item.idIntegracion}</StyledTableCell>
                    {/* Ponemos en condicion los chechk ya que dependiendo el estado en el que se esncuentren
                      se podrán ver las columnas o se ocultarán */}                     
                    {!showclaveOil ? null:<StyledTableCell align="center">{item.claveOil}</StyledTableCell>}
                    {!showdescripcionOil ? null:<StyledTableCell align="center">{item.descripcionOil}</StyledTableCell>}
                    {!showestatusSiniestro ? null:<StyledTableCell align="center"> {item.estatusSiniestro} </StyledTableCell>}
                    {!showsumaAseguradaBasica ? null:<StyledTableCell align="center"> {item.sumaAseguradaBasica} </StyledTableCell>}            
                    {!showmonedaSumaAseguradaBasica ? null:<StyledTableCell align="center"> {item.monedaSumaAseguradaBasica} </StyledTableCell>}            
                    {!showsumaAseguradaBeneficioMuerteAccidental ? null:<StyledTableCell align="center"> {item.sumaAseguradaBeneficioMuerteAccidental} </StyledTableCell>}            
                    {!showmonedaSumaAseguradaBeneficioMuerteAccidental ? null:<StyledTableCell align="center"> {item.monedaSumaAseguradaBeneficioMuerteAccidental} </StyledTableCell>}            
                    {!showTipodeReclamacion ? null:<StyledTableCell align="center"> {item.tipoSiniestro} </StyledTableCell>}            
                    {!showtipoSiniestro2 ? null:<StyledTableCell align="center"> {item.tipoSiniestro2} </StyledTableCell>}            
                    {!showtipoSiniestro4 ? null:<StyledTableCell align="center"> {item.tipoSiniestro4} </StyledTableCell>}            
                    {!showtipoSiniestro5 ? null:<StyledTableCell align="center"> {item.tipoSiniestro5} </StyledTableCell>}            
                    {!showcausaSiniestro ? null:<StyledTableCell align="center"> {item.causaSiniestro} </StyledTableCell>}            
                    {!showtipoDeFallecimiento ? null:<StyledTableCell align="center"> {item.tipoDeFallecimiento} </StyledTableCell>}            
                    {!showciudadOcurrencia ? null:<StyledTableCell align="center"> {item.ciudadOcurrencia} </StyledTableCell>}            
                    {!showpaisOcurrencia ? null:<StyledTableCell align="center"> {item.paisOcurrencia} </StyledTableCell>}            
                    {!showtipoHomicidio ? null:<StyledTableCell align="center"> {item.tipoHomicidio} </StyledTableCell>}            
                    {!showsexoAsegurado ? null:<StyledTableCell align="center"> {item.sexoAsegurado} </StyledTableCell>}            
                    {!showedadAsegurado ? null:<StyledTableCell align="center"> {item.edadAsegurado} </StyledTableCell>}            
                    {!shownombreInvestigador ? null:<StyledTableCell align="center"> {item.nombreInvestigador} </StyledTableCell>}            
                    {!showestatusInvestigacion ? null:<StyledTableCell align="center"> {item.estatusInvestigacion} </StyledTableCell>}            
                    {!showmontoDePagoDeLaInvestigacion ? null:<StyledTableCell align="center"> {item.montoDePagoDeLaInvestigacion} </StyledTableCell>}            
                    {!showfechaDePagoDeLaInvestigación ? null:<StyledTableCell align="center"> {item.fechaDePagoDeLaInvestigación} </StyledTableCell>}            
                    {!showfechaPago ? null:<StyledTableCell align="center"> {item.fechaPago} </StyledTableCell>}            
                    {!showmontoPago ? null:<StyledTableCell align="center"> {item.montoPago} </StyledTableCell>}  
                    {!showmonedaPago ? null:<StyledTableCell align="center"> {item.monedaPago} </StyledTableCell>}          
                    {!showmedioPago ? null:<StyledTableCell align="center"> {item.medioPago} </StyledTableCell>}            
                    {!showfechaDeVencimientoDeLaPoliza ? null:<StyledTableCell align="center"> {item.fechaDeVencimientoDeLaPoliza} </StyledTableCell>}            
                    {!showestatusPoliza1 ? null:<StyledTableCell align="center"> {item.estatusPoliza1} </StyledTableCell>}            
                    {!shownombreBeneficiarios ? null:<StyledTableCell align="center"> {item.nombreBeneficiarios} </StyledTableCell>}            
                    {!showbeneficioAdicional ? null:<StyledTableCell align="center"> {item.beneficioAdicional} </StyledTableCell>}            
                    {!showmontoSumaAseguradaBeneficioAdicional ? null:<StyledTableCell align="center"> {item.montoSumaAseguradaBeneficioAdicional} </StyledTableCell>}            
                    {!showmonedaAdicional ? null:<StyledTableCell align="center"> {item.monedaAdicional} </StyledTableCell>}            
                    {!showbeneficiarioPago ? null:<StyledTableCell align="center"> {item.beneficiarioPago} </StyledTableCell>}            
                    {!showcuentaBeneficiario ? null:<StyledTableCell align="center"> {item.cuentaBeneficiario} </StyledTableCell>}            
                    {!showcuentaDeCargoOrigenDelPago ? null:<StyledTableCell align="center"> {item.cuentaDeCargoOrigenDelPago} </StyledTableCell>}            
                    {!showbancoCuentaBancaria ? null:<StyledTableCell align="center"> {item.bancoCuentaBancaria} </StyledTableCell>}            
                    {!showfechaDeEmisiónDeLaPoliza ? null:<StyledTableCell align="center"> {item.fechaDeEmisiónDeLaPoliza} </StyledTableCell>}            
                    {!showcanalDistribucion ? null:<StyledTableCell align="center"> {item.canalDistribucion} </StyledTableCell>}            
                    {!showclaveAgente ? null:<StyledTableCell align="center"> {item.claveAgente} </StyledTableCell>}            
                    {!showagenciaPromotoria ? null:<StyledTableCell align="center"> {item.agenciaPromotoria}  </StyledTableCell>}            
                    {!showdisputable ? null:<StyledTableCell align="center"> {item.disputable} </StyledTableCell>}
                  </TableRow>
                ))}
            </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelRowsPerPage="Filas por página"
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
{/* Separamos los botones del contenido ya que el contenedor de los botones debe de llevar diferente espacio para estos */}
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <br />
          {/* Con el metodo handleDownloadExce al dar click en este botón se activara esta Función
          y se descarga la tabla en excel */}
          <ButtonVino label="Descargar" 
          onClick={handleDownloadExcel} 
          estatus={btnDisable}/>
        </Grid>

        <Grid item>
          <br />
          <DialogButtons
            mainButton="Estadística"//Nombremos el botón del dialogo
            title="Estadísticas de Cuentas "//asignamos el nombre del dialogo
            description={<GraficoCuenta />}//Pasamos el archivo en donde tenemos la grafica para esta tabla mediante una etiqueta
            firstButton="Aceptar"
            secondButton="Cancelar"
            estatus={btnDisable}
          />
        </Grid>
        
        <Grid item>
          <br />
          <DialogColumna
             mainButton="Agregar o Quitar campos"
             title="Agregar o Quitar columnas"
             description="Marca o Desmarca las columnas que desea mostrar"
            description2={<CheckGroup checked={showclaveOil} onChange={(e)=> setShowclaveOil(e.target.checked) } label="Clave Oil" />}
            description3={<CheckGroup checked={showdescripcionOil} onChange={(e)=> setShowdescripcionOil(e.target.checked) } label="Descripción Clave Oil" />}
            description4={<CheckGroup checked={showestatusSiniestro} onChange={(e)=> setShowestatusSiniestro(e.target.checked) } label="Estatus del Siniestro" />}
            description5={<CheckGroup checked={showsumaAseguradaBasica} onChange={(e)=> setShowsumaAseguradaBasica(e.target.checked) } label="Suma Asegurada Básico" />}
            description6={<CheckGroup checked={showmonedaSumaAseguradaBasica} onChange={(e)=> setShowmonedaSumaAseguradaBasica(e.target.checked) } label="Moneda Suma Asegurada Básica" />}
            description7={<CheckGroup checked={showsumaAseguradaBeneficioMuerteAccidental} onChange={(e)=> setShowsumaAseguradaBeneficioMuerteAccidental(e.target.checked) } label=" Suma Asegurada Beneficio Muerte Accidental " />}
            description8={<CheckGroup checked={showmonedaSumaAseguradaBeneficioMuerteAccidental} onChange={(e)=> setShowmonedaSumaAseguradaBeneficioMuerteAccidental(e.target.checked) } label=" Moneda Suma Asegurada Beneficio Muerte Accidental " />}
            description9={<CheckGroup checked={showTipodeReclamacion} onChange={(e)=> setShowTipodeReclamacion(e.target.checked) } label=" Tipo de Reclamación " />}
            description10={<CheckGroup checked={showtipoSiniestro2} onChange={(e)=> setShowtipoSiniestro2(e.target.checked) } label=" Tipo de Siniestro 2 " />}
            description11={<CheckGroup checked={showtipoSiniestro4} onChange={(e)=> setShowtipoSiniestro4(e.target.checked) } label=" Tipo de Siniestro 4 " />}
            description12={<CheckGroup checked={showtipoSiniestro5} onChange={(e)=> setShowtipoSiniestro5(e.target.checked) } label=" Tipo de Siniestro 5 " />}
            description13={<CheckGroup checked={showcausaSiniestro} onChange={(e)=> setShowcausaSiniestro(e.target.checked) } label=" Causa de Siniestro " />}
            description14={<CheckGroup checked={showtipoDeFallecimiento} onChange={(e)=> setShowtipoDeFallecimiento(e.target.checked) } label=" Tipo de Fallecimiento" />}
            description15={<CheckGroup checked={showciudadOcurrencia} onChange={(e)=> setShowciudadOcurrencia(e.target.checked) } label=" Ciudad de Ocurriendia " />}
            description16={<CheckGroup checked={showpaisOcurrencia} onChange={(e)=> setShowpaisOcurrencia(e.target.checked) } label=" País de Ocurrencia " />}
            description17={<CheckGroup checked={showtipoHomicidio} onChange={(e)=> setShowtipoHomicidio(e.target.checked) } label=" Tipo de Homicidio" />}
            description18={<CheckGroup checked={showsexoAsegurado} onChange={(e)=> setShowsexoAsegurado(e.target.checked) } label=" Sexo del Asegurado " />}
            description19={<CheckGroup checked={showedadAsegurado} onChange={(e)=> setShowedadAsegurado(e.target.checked) } label=" Edad del asegurado " />}
            description20={<CheckGroup checked={shownombreInvestigador} onChange={(e)=> setShownombreInvestigador(e.target.checked) } label=" Nombre del investigador " />}
            description21={<CheckGroup checked={showestatusInvestigacion} onChange={(e)=> setShowestatusInvestigacion(e.target.checked) } label=" Estatus de la Investigación " />}
            description22={<CheckGroup checked={showmontoDePagoDeLaInvestigacion} onChange={(e)=> setShowmontoDePagoDeLaInvestigacion(e.target.checked) } label=" Monto de pago de la investigación " />}
            description23={<CheckGroup checked={showfechaDePagoDeLaInvestigación} onChange={(e)=> setShowfechaDePagoDeLaInvestigación(e.target.checked) } label=" Fecha de Pago de la Investigación " />}
            description24={<CheckGroup checked={showfechaPago} onChange={(e)=> setShowfechaPago(e.target.checked) } label=" Fecha de Pago " />}
            description25={<CheckGroup checked={showmontoPago} onChange={(e)=> setShowmontoPago(e.target.checked) } label=" Monto de Pago " />}
            description26={<CheckGroup checked={showmonedaPago} onChange={(e)=> setShowmonedaPago(e.target.checked) } label=" Moneda del Pago " />}
            description27={<CheckGroup checked={showmedioPago} onChange={(e)=> setShowmedioPago(e.target.checked) } label=" Medio de Pago " />}
            description28={<CheckGroup checked={showfechaDeVencimientoDeLaPoliza} onChange={(e)=> setShowfechaDeVencimientoDeLaPoliza(e.target.checked) } label=" Fecha de Vencimiento de la Póliza " />}
            description29={<CheckGroup checked={showestatusPoliza1} onChange={(e)=> setShowestatusPoliza1(e.target.checked) } label=" Estatus de la Póliza Versión 1 " />}
            description30={<CheckGroup checked={shownombreBeneficiarios} onChange={(e)=> setShownombreBeneficiarios(e.target.checked) } label=" Nombre de Beneficiarios " />}
            description31={<CheckGroup checked={showbeneficioAdicional} onChange={(e)=> setShowbeneficioAdicional(e.target.checked) } label=" Beneficio Adicional " />}
            description32={<CheckGroup checked={showmontoSumaAseguradaBeneficioAdicional} onChange={(e)=> setShowmontoSumaAseguradaBeneficioAdicional(e.target.checked) } label=" Monto de Suma Asegurada de Beneficio Adicional " />}
            description33={<CheckGroup checked={showmonedaAdicional} onChange={(e)=> setShowmonedaAdicional(e.target.checked) } label=" Moneda del Beneficio Adicional  " />}
            description34={<CheckGroup checked={showbeneficiarioPago} onChange={(e)=> setShowbeneficiarioPago(e.target.checked) } label=" Beneficiario del Pago " />}
            description35={<CheckGroup checked={showcuentaBeneficiario} onChange={(e)=> setShowcuentaBeneficiario(e.target.checked) } label=" Cuenta Bancaria del Beneficiario " />}
            description36={<CheckGroup checked={showcuentaDeCargoOrigenDelPago} onChange={(e)=> setShowcuentaDeCargoOrigenDelPago(e.target.checked) } label=" Cuenta de Cargo Origen del Pago " />}
            description37={<CheckGroup checked={showbancoCuentaBancaria} onChange={(e)=> setShowbancoCuentaBancaria(e.target.checked) } label=" Banco de la Cuenta Bancaria " />}
            description38={<CheckGroup checked={showfechaDeEmisiónDeLaPoliza} onChange={(e)=> setShowfechaDeEmisiónDeLaPoliza(e.target.checked) } label=" Fecha de Emisión de la Póliza " />}
            description39={<CheckGroup checked={showcanalDistribucion} onChange={(e)=> setShowcanalDistribucion(e.target.checked) } label=" Canal (de distribución) " />}
            description40={<CheckGroup checked={showclaveAgente} onChange={(e)=> setShowclaveAgente(e.target.checked) } label=" Clave de Agente " />}
            description41={<CheckGroup checked={showagenciaPromotoria} onChange={(e)=> setShowagenciaPromotoria(e.target.checked) } label="Agencia, Promotoría" />}
            description42={<CheckGroup checked={showdisputable} onChange={(e)=> setShowdisputable(e.target.checked) } label=" Disputable (si) (no) " />}
            button="Aceptar"
          />
        </Grid>
      </Grid>
      <br/>
    </div>
  );
}