import React from "react";
import { CompComunicacionConsul } from '../../../components/Communication/CompComunicacionConsul';
import MyComponent from "../../../components/Tools/ButtonLoading/ButtonLoading";


export function comunicacioncon() {
    return (
        <div>
           
           <MyComponent componentToRender={CompComunicacionConsul} />
       
        </div>
    );
}