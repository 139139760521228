/* Brandon Hernandez Rocha 11/2022 */
import React from "react";
import CustomSeparatorDinamic from "../Tools/BreadCrumbs/RecorridoDinamico";
import "../../css/estilo.css";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

//Tabla
import TablaReservaRegistro from "./Elements/Tables/TablaReservaRegistro";

//idioma
import { FormattedMessage } from "react-intl";
const CompDictamenReserRegis = () => {
  const location = useLocation();
  const dataFolio = location.state.infoRow;
  const infoFolio = dataFolio;

  const arrayBreadcrumbs = [
    {
      key: 1,
      name: <FormattedMessage id="globInicio" defaultMessage="Inicio" />,
      to: "/menu",
    },
    {
      key: 2,
      name: <FormattedMessage id="menuDictam" defaultMessage="Dictamen" />,
      to: "/dictamen",
    },
    {
      key: 3,
      name: (
        <FormattedMessage id="dictaDetalleF" defaultMessage="Detalle folio" />
      ),
      to: "/dictamen/detalle_folio/",
      state: { row : infoFolio },
    },
    {
      key: 4,
      name: (
        <FormattedMessage id="dictaDetalleReservas" defaultMessage="Reservas" />
      ),
      to: "/dictamen/detalle_folio/reservas",
      state: { row : infoFolio },
    },
  ];

  return (
    <div className="div-parent">
      <div className="div-breadcrumbs">
        <CustomSeparatorDinamic
          breadcrumbs={arrayBreadcrumbs}
          name={`Registro`}
        />
      </div>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Link
          style={{ textDecoration: "none" }}
          to="/dictamen/detalle_folio/reservas"
          state={{ row : infoFolio }}
        >
          <ReturnPage />
        </Link>
      </Grid>
      <div className="div-container">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <TablaReservaRegistro />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default CompDictamenReserRegis;
