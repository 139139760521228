import * as React from "react";

//tablas
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//componentes de mui
import TXTField from "../../../Tools/TextField/TextField";
import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import Box from "@mui/material/Box";
import DialogRegistro from "../Dialog/DialogRegistro";
import { Link } from "react-router-dom";
import moment from "moment";


//idioma
import { FormattedMessage } from "react-intl";
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import TablePagination from "@mui/material/TablePagination";
//useEffect
import { useEffect } from "react";

//importacion de los token para acceder al proyecto
import { getDataToken } from "../../../../api/dataToken";
import { MONEDA_NACIONAL, PERR_AGENTE, PERR_ASEGURADO, PERR_BENEFICIARIO, PERR_CONTRATANTE, PER_FISICA, TRAM_MANUAL, TRAM_PM } from "../../../../utils/constanstoken";
import DialogNotFoundSinayPm from "../Dialog/DialogNotFoundSinayPm";
import DialogFoundNumPol from "../Dialog/DialogFoundNumPol";
import RadioDinamico from "../../../Tools/RadioButton/RadioDinamico";
import DialogFoundAse from "../Dialog/DialogFoundAse";
import Switch from "@mui/material/Switch";
import DialogFoundPolPM from "../Dialog/DialogFoundPolPM";
import DialogConfirmAviso from "../Dialog/DialogConfirmarAviso";
import { AGENTE, ASEGURADO, BENE_1, BENE_1_FECHA_NAC, BENE_1_NOMBRE_COMPLETO, BENE_2, BENE_2_FECHA_NAC, BENE_2_NOMBRE_COMPLETO, BENE_3, BENE_3_FECHA_NAC, BENE_3_NOMBRE_COMPLETO, BENE_4, BENE_4_FECHA_NAC, BENE_4_NOMBRE_COMPLETO, BENE_5, BENE_5_FECHA_NAC, BENE_5_NOMBRE_COMPLETO, CONTRATANTE, DATA_PM, POLIZA_PM } from "../../../../utils/constansPM";
import { extractNameSurname } from "../../../../utils/functions";

export default function TableRecepcion() {

  const [anidado, setAnidado] = useState([]);
  const [datosApiSinay, setDatosAPiSinay] = useState([]);
  const [datosApiJoinRec, setDatosApiJoinRec] = useState([]);

  const CONEXIONPM = true;

  //TODO: GET
  const getDataJoinRec = async (numPoliza) => {
    try {
      let resp = getDataToken("polizaportramites/get_numpoliza/" + numPoliza + "/")
      if (resp) {
        setDatosApiJoinRec(resp)
      }
      return resp;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };





  /* CONSTANTES DE ESTADOS*/

  const [BtnSiguiente, setBtnSiguiente] = useState(true); //Este estado validara el boton siguiente
  //Por este estado manejaremos el paso de variables que se reciben en la tabla de recepcion para su envio a nuevo tramite
  const [pasoVariables, setPasoVariables] = useState("");
  const [habilitaLink, setHabilitaLink] = useState(false); //activacion y desactivacion del link

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const [numPoliza, setNumPoliza] = useState("");
  const [dialogSinayPm, setDialogSinayPm] = useState(false);
  const [dialogFoundPol, setDialogFoundPol] = useState(false);
  const [dialogFoundPolPM, setDialogFoundPolPM] = useState(false);
  const [dialogFoundAse, setDialogFoundAse] = useState(false);
  const [numFolios, setNumFolios] = useState("");
  const [reclamacion, setReclamacion] = useState("");
  const [datosAse, setDatosAse] = useState({
    nombre: "",
    apePat: "",
    apeMat: ""
  })

  async function buscarPoliza(numPoliza) {
    if (numPoliza !== "") {

      /**********   FRAGMENTO NUEVO   ********** */

      //BUSCAR LA PÓLIZA EN LA API JOIN 
      // Filtrar la estructura para obtener el objeto completo con sus objetos hijos
      let resultados = await getDataJoinRec(numPoliza);
      console.log("Data join", datosApiJoinRec);
      // Utilizar la función find para buscar el objeto

      console.log("Resultados api join", resultados)
      if (resultados.length === 0) {
        // Si NO hay coincidencias en sinay que busque en el PM (en caso de que se tenga la conexión). 
        if (CONEXIONPM) {
          // Aqui se debe buscar el   numero de folio al PM (otro if o else, validando si se encontró el número de folio) 
          let result = await getDataToken(`conexionpm/poliza_apiPM/?poliza=${numPoliza}`)
          if (result.length > 0) {
            console.log("Entre a conexionpm", result);
            let object = result[0]
            let estatusPoliza = await getDataToken("estatuspoliza/get_id/" + object[DATA_PM.ID_ESTATUS_POLIZA] + "/")
            console.log("estatus poliza", estatusPoliza)

            let jsonPM = [
              {//CONTRATANTE
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": object[DATA_PM.NOMBRE_CONTRATANTE],
                "PER_apellidopaterno": object[DATA_PM.APELLIDO_PATERNO_CONT],
                "PER_apellidomaterno": object[DATA_PM.APELLIDO_MATERNO_CONT],
                "PER_RFC": object[DATA_PM.RFC_ASEGURADO],
                "PER_CURP": object[DATA_PM.CURP_ASEGURADO],
                "PERR_idrol": PERR_CONTRATANTE.id,
                "PERT_idtipopersona": PER_FISICA, // constante
                "PCP_coberturasumaasegurada": null,
              },
              {//ASEGURADO y POLIZA
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "POL_polizapadre": object[DATA_PM.POLIZA_PADRE],
                "MON_idmonedas": MONEDA_NACIONAL.id,// Pendiente, cambiar valor del PM por uno en cátalogo
                "POL_polizapahasta": object[DATA_PM.PAGADO_HASTA],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "EST_idestatuspoliza": estatusPoliza, // Pendiente, cambiar valor del PM por uno en cátalogo
                "POL_aniosduracionpoliza": object[DATA_PM.DURACION_POLIZA],
                "POL_polizafoinversión": object[DATA_PM.FONDO_INVERSION],
                "POL_polizapréstamo": object[DATA_PM.PRESTAMO],
                "CHN_idcanales": object[DATA_PM.ID_CANAL], // Pendiente, cambiar valor del PM por uno en cátalogo
                "OF_idoficinas": object[DATA_PM.ID_OFICINA], // Pendiente, cambiar valor del PM por uno en cátalogo
                "ESTA_idestado": object[DATA_PM.ID_ESTADO], // Pendiente, cambiar valor del PM por uno en cátalogo
                "POL_polizarematemática": object[DATA_PM.RESERVA_MATEMATICA],
                "POL_polizatireaseguro": true,// Pendiente, cambiar valor del PM por valor booleano
                "POL_polizareasegurador": object[DATA_PM.REASEGURO],
                "POL_polizaprima": object[DATA_PM.PRIMA],
                "FPAG_idformasdepago": object[DATA_PM.ID_FORMA_PAGO],// Pendiente, cambiar valor del PM por uno en cátalogo
                "POL_polizafinvigencia": object[DATA_PM.FECHA_FIN_VEGENCIA],
                "POL_polizafecemisión": object[DATA_PM.FECHA_EMISION_POLIZA],
                "PRO_idproductos": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, cambiar valor del PM por uno en cátalogo

                //PolizaCoberturaXProductos                
                "PCP_coberturasumaasegurada": null, //DUDA, esta SA se usa para Coberturaxprod

                // "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, cambiar valor del PM por uno en cátalogo
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": estatusPoliza?.EST_descripcion,

                "PER_nombre": object[DATA_PM.NOMBRE_CONTRATANTE],
                "PER_apellidopaterno": object[DATA_PM.APELLIDO_PATERNO_CONT],
                "PER_apellidomaterno": object[DATA_PM.APELLIDO_MATERNO_CONT],
                "PER_RFC": object[DATA_PM.RFC_ASEGURADO],
                "PER_CURP": object[DATA_PM.CURP_ASEGURADO],
                "PERR_idrol": PERR_ASEGURADO.id,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC_BENEFICIARIO_1], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.FECHA_NAC_BENEFICIARIO_1], "DD/MM/YYYY").format("YYYY-MM-DD") : null,

              },
              {//AGENTE
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                // "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_AGENTE], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_AGENTE], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_AGENTE], 3),
                "PERR_idrol": object[DATA_PM.NOMBRE_AGENTE] ? PERR_AGENTE.id : null,
                "PER_clave": object[DATA_PM.CLAVE_AGENTE],
                "PER_direcciondeagencia": object[DATA_PM.DA_AGENTE],
                "PERT_idtipopersona": PER_FISICA, //crear constante
              },
              {//BENEFICIARIO 1 
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                // "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_1], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_1], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_1], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_1], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_1] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_1],
              },
              {//BENEFICIARIO 2 
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_2], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_2], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_2], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_2], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_2] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_2],
              },
              {//BENEFICIARIO 3
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_3], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_3], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_3], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_3], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_3] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_3],
              },
              {//BENEFICIARIO 4
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_4], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_4], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_4], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_4], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_4] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_4],
              },
              {//BENEFICIARIO 5 
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_5], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_5], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_5], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_5], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_5] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_5],
              },
              {//BENEFICIARIO 6 
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_6], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_6], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_6], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_6], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_6] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_6],
              },
              {//BENEFICIARIO 7 
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_7], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_7], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_7], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_7], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_7] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_7],
              },
              {//BENEFICIARIO 8
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_8], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_8], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_8], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_8], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_8] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_8],
              },
              {//BENEFICIARIO 9 
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_9], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_9], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_9], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_9], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_9] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_9],
              },
              {//BENEFICIARIO 10 
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_10], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_10], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_10], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_10], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_10] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_10],
              },
              //COBERTURAS 
              {//COBERTURA 1
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_1],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_1],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_1],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_1]
              },
              {//COBERTURA 2
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_2],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_2],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_2],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_2]
              },
              {//COBERTURA 3
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_3],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_3],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_3],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_3]
              },
              {//COBERTURA 4
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_4],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_4],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_4],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_4]
              },
              {//COBERTURA 5
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_5],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_5],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_5],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_5]
              },
              {//COBERTURA 6
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_6],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_6],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_6],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_6]
              },
              {//COBERTURA 7
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_7],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_7],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_7],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_7]
              },
              {//COBERTURA 8
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_8],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_8],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_8],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_8]
              },
              {//COBERTURA 9
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_9],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_9],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_9],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_9]
              },
              {//COBERTURA 10
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_10],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_10],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_10],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_10]
              },

            ];
            // Busco si hay coincidencias en el arreglo "arrPolizas"
            // Utilizar la función find para buscar el objeto
            var objetoEncontrado = arrPolizas.flat().find(function (obj) {
              return obj["POL_numpoliza"] === numPoliza;
            });

            if (objetoEncontrado) {
              console.log("Objeto encontrado en la tabla:", objetoEncontrado);
              setDialogFoundPolPM(true)
            } else {
              console.log("Objeto no encontrado en la tabla.");
              setArrPolizas([...arrPolizas, jsonPM])
              setDialogFoundPolPM(true)
            }
          } else {
            // Si no encontró resultado entonces mostrar diálogo.
            setDialogSinayPm(true)
          }
        }
      } else {

        let folios = [];
        let itemsArrPol = []
        // Iterar el objeto dataApiJoinRecep
        for (let data of resultados) {
          console.log("Objeto sinay:", resultados);
          let asegurado = {};
          let contratante = {};
          let agente = {};
          let beneficiarios = {};

          //Recuperamos el numero de folio y tipo de reclamación de la api recepción 
          // let objectRecep = await getDataToken(`recepcion/get_id/${resultados[0][0]?.RECT_idfolio}/`);
          let objectRecep = await getDataToken(`recepcion/get_id/${data.RECT_idfolio.RECT_idfolio}/`);
          folios.push(objectRecep?.RECT_numerofolio)
          setReclamacion(objectRecep?.SINC_idcomplejidadsiniestro?.TRA_destipotramitesiniestro)

          let personasxPoliza = await getDataToken("personasxpoliza/get_poliza/" + data.POL_idpoliza.POL_idpoliza + "/")
          let cobPolTram = await getDataToken("polizascoberturasporproductos/get_pol/" + data.POL_idpoliza.POL_idpoliza + "/")
          console.log("COBPOLTRAAAAAAAAAAAM", cobPolTram)
          console.log("personasxPoliza", personasxPoliza)
          for (let poliza of personasxPoliza) {
            if (poliza.PER_idpersona.PERR_idrol.PERR_idrol == PERR_ASEGURADO.id) {
              asegurado = poliza
              console.log("Encontre al asegurado!!!", poliza)
            } else if (poliza.PER_idpersona.PERR_idrol.PERR_idrol == PERR_CONTRATANTE.id) {
              contratante = poliza
              console.log("Encontre al contratante!!!", poliza)
            } else if (poliza.PER_idpersona.PERR_idrol.PERR_idrol == PERR_AGENTE.id) {
              agente = poliza
              console.log("Encontre al agente!!!", poliza)
            }
            else if (poliza.PER_idpersona.PERR_idrol.PERR_idrol == PERR_BENEFICIARIO.id) {
              beneficiarios.push(poliza)
              console.log("Encontre al beneficiario!!!", poliza)
            }
          }
          if (Object.keys(asegurado).length === 0) {
            console.log("El objeto asegurado está vacío.");
            return;
          }
          let jsonPM2 = [
            {//CONTRATANTE
              "tipoTramite": TRAM_MANUAL,
              "POL_numpoliza": contratante?.POL_idpoliza?.POL_numpoliza,
              "PRO_cveproducto": contratante?.POL_idpoliza?.PRO_idproductos?.PRO_cveproducto, //Pendiente, no hay dato similar en el PM (crear constante?)
              "PRO_descripcion": contratante?.POL_idpoliza?.PRO_idproductos?.PRO_descripcion,
              "EST_descripcion": contratante?.POL_idpoliza?.EST_idestatuspoliza?.EST_descripcion,
              "PER_nombre": contratante?.PER_idpersona?.PER_nombre,
              "PER_apellidopaterno": contratante?.PER_idpersona?.PER_apellidopaterno,
              "PER_apellidomaterno": contratante?.PER_idpersona?.PER_apellidomaterno,
              "PER_RFC": contratante?.PER_idpersona?.PER_RFC,
              "PER_CURP": contratante?.PER_idpersona?.PER_CURP,
              "PERR_idrol": PERR_CONTRATANTE.id,
              "PERT_idtipopersona": PER_FISICA, //crear constante
              "PCP_coberturasumaasegurada": null,
              "POL_idpoliza": contratante?.POL_idpoliza?.POL_idpoliza,
              "PER_idpersona": contratante?.PER_idpersona?.PER_idpersona,
              "RECT_numerofolio": data.RECT_idfolio.RECT_numerofolio,
            },
            {//ASEGURADO y POLIZA
              "tipoTramite": TRAM_MANUAL,
              "POL_idpoliza" : asegurado?.POL_idpoliza?.POL_idpoliza,
              "POL_numpoliza": asegurado?.POL_idpoliza?.POL_numpoliza,
              "POL_polizapadre": asegurado?.POL_idpoliza?.POL_polizapadre,
              "MON_idmonedas": MONEDA_NACIONAL.id,// Pendiente, cambiar valor del PM por uno en cátalogo
              "POL_polizapahasta": asegurado?.POL_idpoliza?.POL_polizapahasta,
              "EST_idestatuspoliza": asegurado?.POL_idpoliza?.EST_idestatuspoliza?.EST_idestatuspoliza, // Pendiente, cambiar valor del PM por uno en cátalogo
              "POL_aniosduracionpoliza": asegurado?.POL_idpoliza?.POL_aniosduracionpoliza,
              "POL_polizafoinversión": asegurado?.POL_idpoliza?.POL_polizafoinversión,
              "POL_polizapréstamo": asegurado?.POL_idpoliza?.POL_polizapréstamo,
              "CHN_idcanales": asegurado?.POL_idpoliza?.CHN_idcanales?.CHN_idcanal, // Pendiente, cambiar valor del PM por uno en cátalogo
              "OF_idoficinas": asegurado?.POL_idpoliza?.OF_idoficinas?.OF_idoficina, // Pendiente, cambiar valor del PM por uno en cátalogo
              "ESTA_idestado": asegurado?.POL_idpoliza?.ESTA_idestado?.ESTA_idestado, // Pendiente, cambiar valor del PM por uno en cátalogo
              "POL_polizarematemática": asegurado?.POL_idpoliza?.POL_polizarematemática,
              "POL_polizatireaseguro": asegurado?.POL_idpoliza?.POL_polizatireaseguro,// Pendiente, cambiar valor del PM por valor booleano
              "POL_polizareasegurador": asegurado?.POL_idpoliza?.POL_polizareasegurador,
              "POL_polizaprima": asegurado?.POL_idpoliza?.POL_polizaprima,
              "FPAG_idformasdepago": asegurado?.POL_idpoliza?.FPAG_idformapago,// Pendiente, cambiar valor del PM por uno en cátalogo
              "POL_polizafinvigencia": asegurado?.POL_idpoliza?.POL_polizafinvigencia,
              "POL_polizafecemisión": asegurado?.POL_idpoliza?.POL_polizafecemisión,
              "PRO_idproductos": asegurado?.POL_idpoliza?.PRO_idproductos?.PRO_idproducto, //Pendiente, cambiar valor del PM por uno en cátalogo

              //PolizaCoberturaXProductos                
              "PCP_coberturasumaasegurada": null, //DUDA, esta SA se usa para Coberturaxprod

              // "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, cambiar valor del PM por uno en cátalogo
              "PRO_descripcion": asegurado?.POL_idpoliza?.PRO_idproductos?.PRO_descripcion,
              "EST_descripcion": asegurado?.POL_idpoliza?.EST_idestatuspoliza?.EST_descripcion,

              "PER_nombre": asegurado?.PER_idpersona?.PER_nombre,
              "PER_apellidopaterno": asegurado?.PER_idpersona?.PER_apellidopaterno,
              "PER_apellidomaterno": asegurado?.PER_idpersona?.PER_apellidomaterno,
              "PER_RFC": asegurado?.PER_idpersona?.PER_RFC,
              "PER_CURP": asegurado?.PER_idpersona?.PER_CURP,
              "PERR_idrol": PERR_ASEGURADO.id,
              "PERT_idtipopersona": PER_FISICA, //crear constante
              "PER_fechanacimiento": asegurado?.PER_idpersona?.PER_fechanacimiento,
              "RECT_numerofolio": data.RECT_idfolio.RECT_numerofolio,
            },
            {//AGENTE
              "tipoTramite": TRAM_MANUAL,
              "POL_numpoliza": agente?.POL_idpoliza?.POL_numpoliza,
              // "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
              "PRO_descripcion": agente?.POL_idpoliza?.PRO_idproductos?.PRO_descripcion,
              "EST_descripcion": agente?.POL_idpoliza?.EST_idestatuspoliza?.EST_descripcion,
              "PER_nombre": agente?.PER_idpersona?.PER_nombre,
              "PER_apellidopaterno": agente?.PER_idpersona?.PER_apellidopaterno,
              "PER_apellidomaterno": agente?.PER_idpersona?.PER_apellidomaterno,
              "PERR_idrol": PERR_AGENTE.id,
              "PER_clave": agente?.PER_idpersona?.PER_clave,
              "PER_direcciondeagencia": agente?.PER_idpersona?.PER_direcciondeagencia,
              "PERT_idtipopersona": PER_FISICA, //crear constante
            },
            {//BENEFICIARIO 1 
              "tipoTramite": TRAM_MANUAL,
              "POL_numpoliza": beneficiarios[0]?.POL_idpoliza?.POL_numpoliza,
              // "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
              "PRO_descripcion": beneficiarios[0]?.POL_idpoliza?.PRO_idproductos?.PRO_descripcion,
              "EST_descripcion": beneficiarios[0]?.POL_idpoliza?.EST_idestatuspoliza?.EST_descripcion,
              "PER_nombre": beneficiarios[0]?.PER_idpersona?.PER_nombre,
              "PER_apellidopaterno": beneficiarios[0]?.PER_idpersona?.PER_apellidopaterno,
              "PER_apellidomaterno": beneficiarios[0]?.PER_idpersona?.PER_apellidomaterno,
              "PERR_idrol": PERR_AGENTE.id,
              "PER_clave": beneficiarios[0]?.PER_idpersona?.PER_clave,
              "PER_direcciondeagencia": beneficiarios[0]?.PER_idpersona?.PER_direcciondeagencia,
              "PERT_idtipopersona": PER_FISICA, //crear constante
            },
            {//BENEFICIARIO 2 
              "tipoTramite": TRAM_MANUAL,
              "POL_numpoliza": beneficiarios[1]?.POL_idpoliza?.POL_numpoliza,
              // "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
              "PRO_descripcion": beneficiarios[1]?.POL_idpoliza?.PRO_idproductos?.PRO_descripcion,
              "EST_descripcion": beneficiarios[1]?.POL_idpoliza?.EST_idestatuspoliza?.EST_descripcion,
              "PER_nombre": beneficiarios[1]?.PER_idpersona?.PER_nombre,
              "PER_apellidopaterno": beneficiarios[1]?.PER_idpersona?.PER_apellidopaterno,
              "PER_apellidomaterno": beneficiarios[1]?.PER_idpersona?.PER_apellidomaterno,
              "PERR_idrol": PERR_AGENTE.id,
              "PER_clave": beneficiarios[1]?.PER_idpersona?.PER_clave,
              "PER_direcciondeagencia": beneficiarios[1]?.PER_idpersona?.PER_direcciondeagencia,
              "PERT_idtipopersona": PER_FISICA, //crear constante
            },
            {//BENEFICIARIO 3
              "tipoTramite": TRAM_MANUAL,
              "POL_numpoliza": beneficiarios[2]?.POL_idpoliza?.POL_numpoliza,
              // "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
              "PRO_descripcion": beneficiarios[2]?.POL_idpoliza?.PRO_idproductos?.PRO_descripcion,
              "EST_descripcion": beneficiarios[2]?.POL_idpoliza?.EST_idestatuspoliza?.EST_descripcion,
              "PER_nombre": beneficiarios[2]?.PER_idpersona?.PER_nombre,
              "PER_apellidopaterno": beneficiarios[2]?.PER_idpersona?.PER_apellidopaterno,
              "PER_apellidomaterno": beneficiarios[2]?.PER_idpersona?.PER_apellidomaterno,
              "PERR_idrol": PERR_AGENTE.id,
              "PER_clave": beneficiarios[2]?.PER_idpersona?.PER_clave,
              "PER_direcciondeagencia": beneficiarios[2]?.PER_idpersona?.PER_direcciondeagencia,
              "PERT_idtipopersona": PER_FISICA, //crear constante
            },
            {//BENEFICIARIO 4
              "tipoTramite": TRAM_MANUAL,
              "POL_numpoliza": beneficiarios[3]?.POL_idpoliza?.POL_numpoliza,
              // "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
              "PRO_descripcion": beneficiarios[3]?.POL_idpoliza?.PRO_idproductos?.PRO_descripcion,
              "EST_descripcion": beneficiarios[3]?.POL_idpoliza?.EST_idestatuspoliza?.EST_descripcion,
              "PER_nombre": beneficiarios[3]?.PER_idpersona?.PER_nombre,
              "PER_apellidopaterno": beneficiarios[3]?.PER_idpersona?.PER_apellidopaterno,
              "PER_apellidomaterno": beneficiarios[3]?.PER_idpersona?.PER_apellidomaterno,
              "PERR_idrol": PERR_AGENTE.id,
              "PER_clave": beneficiarios[3]?.PER_idpersona?.PER_clave,
              "PER_direcciondeagencia": beneficiarios[3]?.PER_idpersona?.PER_direcciondeagencia,
              "PERT_idtipopersona": PER_FISICA, //crear constante
            },
            {//BENEFICIARIO 5 
              "tipoTramite": TRAM_MANUAL,
              "POL_numpoliza": beneficiarios[4]?.POL_idpoliza?.POL_numpoliza,
              // "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
              "PRO_descripcion": beneficiarios[4]?.POL_idpoliza?.PRO_idproductos?.PRO_descripcion,
              "EST_descripcion": beneficiarios[4]?.POL_idpoliza?.EST_idestatuspoliza?.EST_descripcion,
              "PER_nombre": beneficiarios[4]?.PER_idpersona?.PER_nombre,
              "PER_apellidopaterno": beneficiarios[4]?.PER_idpersona?.PER_apellidopaterno,
              "PER_apellidomaterno": beneficiarios[4]?.PER_idpersona?.PER_apellidomaterno,
              "PERR_idrol": PERR_AGENTE.id,
              "PER_clave": beneficiarios[4]?.PER_idpersona?.PER_clave,
              "PER_direcciondeagencia": beneficiarios[4]?.PER_idpersona?.PER_direcciondeagencia,
              "PERT_idtipopersona": PER_FISICA, //crear constante
            },
            {//BENEFICIARIO 6 
              "tipoTramite": TRAM_MANUAL,
              "POL_numpoliza": beneficiarios[5]?.POL_idpoliza?.POL_numpoliza,
              // "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
              "PRO_descripcion": beneficiarios[5]?.POL_idpoliza?.PRO_idproductos?.PRO_descripcion,
              "EST_descripcion": beneficiarios[5]?.POL_idpoliza?.EST_idestatuspoliza?.EST_descripcion,
              "PER_nombre": beneficiarios[5]?.PER_idpersona?.PER_nombre,
              "PER_apellidopaterno": beneficiarios[5]?.PER_idpersona?.PER_apellidopaterno,
              "PER_apellidomaterno": beneficiarios[5]?.PER_idpersona?.PER_apellidomaterno,
              "PERR_idrol": PERR_AGENTE.id,
              "PER_clave": beneficiarios[5]?.PER_idpersona?.PER_clave,
              "PER_direcciondeagencia": beneficiarios[5]?.PER_idpersona?.PER_direcciondeagencia,
              "PERT_idtipopersona": PER_FISICA, //crear constante
            },
            {//BENEFICIARIO 7 
              "tipoTramite": TRAM_MANUAL,
              "POL_numpoliza": beneficiarios[6]?.POL_idpoliza?.POL_numpoliza,
              // "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
              "PRO_descripcion": beneficiarios[6]?.POL_idpoliza?.PRO_idproductos?.PRO_descripcion,
              "EST_descripcion": beneficiarios[6]?.POL_idpoliza?.EST_idestatuspoliza?.EST_descripcion,
              "PER_nombre": beneficiarios[6]?.PER_idpersona?.PER_nombre,
              "PER_apellidopaterno": beneficiarios[6]?.PER_idpersona?.PER_apellidopaterno,
              "PER_apellidomaterno": beneficiarios[6]?.PER_idpersona?.PER_apellidomaterno,
              "PERR_idrol": PERR_AGENTE.id,
              "PER_clave": beneficiarios[6]?.PER_idpersona?.PER_clave,
              "PER_direcciondeagencia": beneficiarios[6]?.PER_idpersona?.PER_direcciondeagencia,
              "PERT_idtipopersona": PER_FISICA, //crear constante
            },
            {//BENEFICIARIO 8
              "tipoTramite": TRAM_MANUAL,
              "POL_numpoliza": beneficiarios[7]?.POL_idpoliza?.POL_numpoliza,
              // "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
              "PRO_descripcion": beneficiarios[7]?.POL_idpoliza?.PRO_idproductos?.PRO_descripcion,
              "EST_descripcion": beneficiarios[7]?.POL_idpoliza?.EST_idestatuspoliza?.EST_descripcion,
              "PER_nombre": beneficiarios[7]?.PER_idpersona?.PER_nombre,
              "PER_apellidopaterno": beneficiarios[7]?.PER_idpersona?.PER_apellidopaterno,
              "PER_apellidomaterno": beneficiarios[7]?.PER_idpersona?.PER_apellidomaterno,
              "PERR_idrol": PERR_AGENTE.id,
              "PER_clave": beneficiarios[7]?.PER_idpersona?.PER_clave,
              "PER_direcciondeagencia": beneficiarios[7]?.PER_idpersona?.PER_direcciondeagencia,
              "PERT_idtipopersona": PER_FISICA, //crear constante
            },
            {//BENEFICIARIO 9 
              "tipoTramite": TRAM_MANUAL,
              "POL_numpoliza": beneficiarios[8]?.POL_idpoliza?.POL_numpoliza,
              // "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
              "PRO_descripcion": beneficiarios[8]?.POL_idpoliza?.PRO_idproductos?.PRO_descripcion,
              "EST_descripcion": beneficiarios[8]?.POL_idpoliza?.EST_idestatuspoliza?.EST_descripcion,
              "PER_nombre": beneficiarios[8]?.PER_idpersona?.PER_nombre,
              "PER_apellidopaterno": beneficiarios[8]?.PER_idpersona?.PER_apellidopaterno,
              "PER_apellidomaterno": beneficiarios[8]?.PER_idpersona?.PER_apellidomaterno,
              "PERR_idrol": PERR_AGENTE.id,
              "PER_clave": beneficiarios[8]?.PER_idpersona?.PER_clave,
              "PER_direcciondeagencia": beneficiarios[8]?.PER_idpersona?.PER_direcciondeagencia,
              "PERT_idtipopersona": PER_FISICA, //crear constante
            },
            {//BENEFICIARIO 10 
              "tipoTramite": TRAM_MANUAL,
              "POL_numpoliza": beneficiarios[9]?.POL_idpoliza?.POL_numpoliza,
              // "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
              "PRO_descripcion": beneficiarios[9]?.POL_idpoliza?.PRO_idproductos?.PRO_descripcion,
              "EST_descripcion": beneficiarios[9]?.POL_idpoliza?.EST_idestatuspoliza?.EST_descripcion,
              "PER_nombre": beneficiarios[9]?.PER_idpersona?.PER_nombre,
              "PER_apellidopaterno": beneficiarios[9]?.PER_idpersona?.PER_apellidopaterno,
              "PER_apellidomaterno": beneficiarios[9]?.PER_idpersona?.PER_apellidomaterno,
              "PERR_idrol": PERR_AGENTE.id,
              "PER_clave": beneficiarios[9]?.PER_idpersona?.PER_clave,
              "PER_direcciondeagencia": beneficiarios[9]?.PER_idpersona?.PER_direcciondeagencia,
              "PERT_idtipopersona": PER_FISICA, //crear constante
            },
            //COBERTURAS 
            {//COBERTURA 1
              "id" : 1, 
              "tipoTramite": TRAM_MANUAL,
              "idCober": cobPolTram[0]?.COB_idcobertura?.COB_idcobertura,
              "nomCober": cobPolTram[0]?.COB_idcobertura?.COB_cobertura,
              "SA": cobPolTram[0]?.PCP_coberturasumaasegurada,
              "idMoneda": cobPolTram[0]?.MON_idmonedacobertura?.MON_idmoneda,
              "nomMoneda" : cobPolTram[0]?.MON_idmonedacobertura?.MON_abreviatura,
            },
            {//COBERTURA 2
              "id": 2,
              "tipoTramite": TRAM_MANUAL,
              "idCober": cobPolTram[1]?.COB_idcobertura?.COB_idcobertura,
              "nomCober": cobPolTram[1]?.COB_idcobertura?.COB_cobertura,
              "SA": cobPolTram[1]?.PCP_coberturasumaasegurada,
              "idMoneda": cobPolTram[1]?.MON_idmonedacobertura?.MON_idmoneda,
              "nomMoneda" : cobPolTram[1]?.MON_idmonedacobertura?.MON_abreviatura,
            },
            {//COBERTURA 3
              "id": 3,
              "tipoTramite": TRAM_MANUAL,
              "idCober": cobPolTram[2]?.COB_idcobertura?.COB_idcobertura,
              "nomCober": cobPolTram[2]?.COB_idcobertura?.COB_cobertura,
              "SA": cobPolTram[2]?.PCP_coberturasumaasegurada,
              "idMoneda": cobPolTram[2]?.MON_idmonedacobertura?.MON_idmoneda,
              "nomMoneda" : cobPolTram[2]?.MON_idmonedacobertura?.MON_abreviatura,
            },
            {//COBERTURA 4
              "id": 4,
              "tipoTramite": TRAM_MANUAL,
              "idCober": cobPolTram[3]?.COB_idcobertura?.COB_idcobertura,
              "nomCober": cobPolTram[3]?.COB_idcobertura?.COB_cobertura,
              "SA": cobPolTram[3]?.PCP_coberturasumaasegurada,
              "idMoneda": cobPolTram[3]?.MON_idmonedacobertura?.MON_idmoneda,
              "nomMoneda" : cobPolTram[3]?.MON_idmonedacobertura?.MON_abreviatura,
            },
            {//COBERTURA 5
              "id": 5,
              "tipoTramite": TRAM_MANUAL,
              "idCober": cobPolTram[4]?.COB_idcobertura?.COB_idcobertura,
              "nomCober": cobPolTram[4]?.COB_idcobertura?.COB_cobertura,
              "SA": cobPolTram[4]?.PCP_coberturasumaasegurada,
              "idMoneda": cobPolTram[4]?.MON_idmonedacobertura?.MON_idmoneda,
              "nomMoneda" : cobPolTram[4]?.MON_idmonedacobertura?.MON_abreviatura,
            },
            {//COBERTURA 6
              "id": 6,
              "tipoTramite": TRAM_MANUAL,
              "idCober": cobPolTram[5]?.COB_idcobertura?.COB_idcobertura,
              "nomCober": cobPolTram[5]?.COB_idcobertura?.COB_cobertura,
              "SA": cobPolTram[5]?.PCP_coberturasumaasegurada,
              "idMoneda": cobPolTram[5]?.MON_idmonedacobertura?.MON_idmoneda,
              "nomMoneda" : cobPolTram[5]?.MON_idmonedacobertura?.MON_abreviatura,
            },
            {//COBERTURA 7
              "id": 7,
              "tipoTramite": TRAM_MANUAL,
              "idCober": cobPolTram[6]?.COB_idcobertura?.COB_idcobertura,
              "nomCober": cobPolTram[6]?.COB_idcobertura?.COB_cobertura,
              "SA": cobPolTram[6]?.PCP_coberturasumaasegurada,
              "idMoneda": cobPolTram[6]?.MON_idmonedacobertura?.MON_idmoneda,
              "nomMoneda" : cobPolTram[6]?.MON_idmonedacobertura?.MON_abreviatura,
            },
            {//COBERTURA 8
              "id": 8,
              "tipoTramite": TRAM_MANUAL,
              "idCober": cobPolTram[7]?.COB_idcobertura?.COB_idcobertura,
              "nomCober": cobPolTram[7]?.COB_idcobertura?.COB_cobertura,
              "SA": cobPolTram[7]?.PCP_coberturasumaasegurada,
              "idMoneda": cobPolTram[7]?.MON_idmonedacobertura?.MON_idmoneda,
              "nomMoneda" : cobPolTram[7]?.MON_idmonedacobertura?.MON_abreviatura,
            },
            {//COBERTURA 9
              "id": 9,
              "tipoTramite": TRAM_MANUAL,
              "idCober": cobPolTram[8]?.COB_idcobertura?.COB_idcobertura,
              "nomCober": cobPolTram[8]?.COB_idcobertura?.COB_cobertura,
              "SA": cobPolTram[8]?.PCP_coberturasumaasegurada,
              "idMoneda": cobPolTram[8]?.MON_idmonedacobertura?.MON_idmoneda,
              "nomMoneda" : cobPolTram[8]?.MON_idmonedacobertura?.MON_abreviatura,
            },
            {//COBERTURA 10
              "id": 10,
              "tipoTramite": TRAM_MANUAL,
              "idCober": cobPolTram[9]?.COB_idcobertura?.COB_idcobertura,
              "nomCober": cobPolTram[9]?.COB_idcobertura?.COB_cobertura,
              "SA": cobPolTram[9]?.PCP_coberturasumaasegurada,
              "idMoneda": cobPolTram[9]?.MON_idmonedacobertura?.MON_idmoneda,
              "nomMoneda" : cobPolTram[9]?.MON_idmonedacobertura?.MON_abreviatura,
            }

          ];
          // Busco si hay coincidencias en el arreglo "arrPolizas"
          // Utilizar la función find para buscar el objeto
          let objetoEncontrado = arrPolizas.flat().find(function (obj) {
            return obj["POL_numpoliza"] === numPoliza && obj["RECT_numerofolio"] === data.RECT_idfolio.RECT_numerofolio;
          });

          if (objetoEncontrado) {
            console.log("Objeto encontrado en la tabla:", objetoEncontrado);
            setDialogFoundPol(true)
          } else {
            console.log("Objeto no encontrado en la tabla.");
            itemsArrPol.push(jsonPM2);
            setNumFolios(prevArrFolio => [...prevArrFolio, jsonPM2[0].RECT_numerofolio])
            setReclamacion(prevArrSin => [...prevArrSin, objectRecep?.SINC_idcomplejidadsiniestro?.TRA_destipotramitesiniestro])

          }
        }
        if (itemsArrPol.length > 0) {
          console.log("items arr pol", itemsArrPol)

          for (let json of itemsArrPol) {
            setArrPolizas(prevArrPolizas => [...prevArrPolizas, json]);
            setDialogFoundPol(true);
          }
        }
      }
    } else { // Si no se ingresó ningún carácter poner foco al texfield. 
      document.getElementById("txtNumPoliza").focus();
    }
  }
  async function buscarPorNombreAse(nombre, apePat, apeMat) {
    if (nombre !== "") {
      //BUSCAR LA PÓLIZA EN LA API JOIN 
      // Filtrar la estructura para obtener el objeto completo con sus objetos hijos
      let resultados = [];
      // Utilizar la función find para buscar el objeto
      console.log("obj: ", datosApiJoinRec)
      if (!resultados)
        for (const objetosHijos of datosApiJoinRec) {
          let contieneValorBuscado = objetosHijos.some(function (obj) {
            return obj["PER_nombre"].toLowerCase() === nombre.trim().toLowerCase()
              && obj["PER_apellidopaterno"].toLowerCase() === apePat.trim().toLowerCase()
              && obj["PER_apellidomaterno"].toLowerCase() === apeMat.trim().toLowerCase()
              ;
          });

          if (contieneValorBuscado) {
            resultados.push(objetosHijos);
          }
        };
      console.log("Resultados api join", resultados)
      if (resultados.length === 0 || !resultados) {
        // Si NO hay coincidencias en sinay que busque en el PM (en caso de que se tenga la conexión). 
        if (CONEXIONPM) {
          // Aqui se debe buscar el   numero de folio al PM (otro if o else, validando si se encontró el número de folio) 
          let result = await getDataToken(`conexionpm/nombre_apiPM/?Nombre_asegurado_sistema=${nombre}&Apellido_Paterno=${apePat}&Apellido_Materno=${apeMat}`);
          if (result.length > 0) {
            console.log("Entre a conexionpm", result);
            let object = result[0]
            let estatusPoliza = await getDataToken("estatuspoliza/get_id/" + object[DATA_PM.ID_ESTATUS_POLIZA] + "/")
            console.log("estatus poliza", estatusPoliza)

            let jsonPM = [
              {//CONTRATANTE
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": object[DATA_PM.NOMBRE_CONTRATANTE],
                "PER_apellidopaterno": object[DATA_PM.APELLIDO_PATERNO_CONT],
                "PER_apellidomaterno": object[DATA_PM.APELLIDO_MATERNO_CONT],
                "PER_RFC": object[DATA_PM.RFC_ASEGURADO],
                "PER_CURP": object[DATA_PM.CURP_ASEGURADO],
                "PERR_idrol": PERR_CONTRATANTE.id,
                "PERT_idtipopersona": PER_FISICA, // constante
                "PCP_coberturasumaasegurada": null,
              },
              {//ASEGURADO y POLIZA
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "POL_polizapadre": object[DATA_PM.POLIZA_PADRE],
                "MON_idmonedas": MONEDA_NACIONAL.id,// Pendiente, cambiar valor del PM por uno en cátalogo
                "POL_polizapahasta": object[DATA_PM.PAGADO_HASTA],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "EST_idestatuspoliza": estatusPoliza, // Pendiente, cambiar valor del PM por uno en cátalogo
                "POL_aniosduracionpoliza": object[DATA_PM.DURACION_POLIZA],
                "POL_polizafoinversión": object[DATA_PM.FONDO_INVERSION],
                "POL_polizapréstamo": object[DATA_PM.PRESTAMO],
                "CHN_idcanales": object[DATA_PM.ID_CANAL], // Pendiente, cambiar valor del PM por uno en cátalogo
                "OF_idoficinas": object[DATA_PM.ID_OFICINA], // Pendiente, cambiar valor del PM por uno en cátalogo
                "ESTA_idestado": object[DATA_PM.ID_ESTADO], // Pendiente, cambiar valor del PM por uno en cátalogo
                "POL_polizarematemática": object[DATA_PM.RESERVA_MATEMATICA],
                "POL_polizatireaseguro": true,// Pendiente, cambiar valor del PM por valor booleano
                "POL_polizareasegurador": object[DATA_PM.REASEGURO],
                "POL_polizaprima": object[DATA_PM.PRIMA],
                "FPAG_idformasdepago": object[DATA_PM.ID_FORMA_PAGO],// Pendiente, cambiar valor del PM por uno en cátalogo
                "POL_polizafinvigencia": object[DATA_PM.FECHA_FIN_VEGENCIA],
                "POL_polizafecemisión": object[DATA_PM.FECHA_EMISION_POLIZA],
                "PRO_idproductos": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, cambiar valor del PM por uno en cátalogo

                //PolizaCoberturaXProductos                
                "PCP_coberturasumaasegurada": null, //DUDA, esta SA se usa para Coberturaxprod

                // "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, cambiar valor del PM por uno en cátalogo
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": estatusPoliza?.EST_descripcion,

                "PER_nombre": object[DATA_PM.NOMBRE_CONTRATANTE],
                "PER_apellidopaterno": object[DATA_PM.APELLIDO_PATERNO_CONT],
                "PER_apellidomaterno": object[DATA_PM.APELLIDO_MATERNO_CONT],
                "PER_RFC": object[DATA_PM.RFC_ASEGURADO],
                "PER_CURP": object[DATA_PM.CURP_ASEGURADO],
                "PERR_idrol": PERR_ASEGURADO.id,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC_BENEFICIARIO_1], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.FECHA_NAC_BENEFICIARIO_1], "DD/MM/YYYY").format("YYYY-MM-DD") : null,

              },
              {//AGENTE
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                // "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_AGENTE], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_AGENTE], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_AGENTE], 3),
                "PERR_idrol": object[DATA_PM.NOMBRE_AGENTE] ? PERR_AGENTE.id : null,
                "PER_clave": object[DATA_PM.CLAVE_AGENTE],
                "PER_direcciondeagencia": object[DATA_PM.DA_AGENTE],
                "PERT_idtipopersona": PER_FISICA, //crear constante
              },
              {//BENEFICIARIO 1 
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                // "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_1], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_1], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_1], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_1], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_1] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_1],
              },
              {//BENEFICIARIO 2 
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_2], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_2], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_2], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_2], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_2] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_2],
              },
              {//BENEFICIARIO 3
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_3], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_3], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_3], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_3], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_3] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_3],
              },
              {//BENEFICIARIO 4
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_4], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_4], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_4], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_4], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_4] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_4],
              },
              {//BENEFICIARIO 5 
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_5], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_5], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_5], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_5], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_5] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_5],
              },
              {//BENEFICIARIO 6 
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_6], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_6], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_6], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_6], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_6] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_6],
              },
              {//BENEFICIARIO 7 
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_7], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_7], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_7], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_7], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_7] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_7],
              },
              {//BENEFICIARIO 8
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_8], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_8], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_8], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_8], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_8] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_8],
              },
              {//BENEFICIARIO 9 
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_9], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_9], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_9], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_9], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_9] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_9],
              },
              {//BENEFICIARIO 10 
                "tipoTramite": TRAM_PM,
                "POL_numpoliza": object[DATA_PM.POLIZA],
                "PRO_cveproducto": object[DATA_PM.ID_TIPO_PRODUCTO], //Pendiente, no hay dato similar en el PM (crear constante?)
                "PRO_descripcion": object[DATA_PM.PRODUCTO_COMERCIAL],
                "EST_descripcion": object[DATA_PM.ID_ESTATUS_POLIZA],
                "PER_nombre": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_10], 1),
                "PER_apellidopaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_10], 2),
                "PER_apellidomaterno": extractNameSurname(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_10], 3),
                "PER_fechanacimiento": moment(object[DATA_PM.FECHA_NAC], "DD/MM/YYYY").isValid() ?
                  moment(object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_10], "DD/MM/YYYY").format("YYYY-MM-DD") : null,
                "PERR_idrol": object[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_10] ? PERR_BENEFICIARIO.id : null,
                "PERT_idtipopersona": PER_FISICA, //crear constante
                "PXP_porcentajebeneficiario": object[DATA_PM.PORCENTAJE_BENEFICIARIO_10],
              },
              //COBERTURAS 
              {//COBERTURA 1
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_1],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_1],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_1],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_1]
              },
              {//COBERTURA 2
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_2],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_2],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_2],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_2]
              },
              {//COBERTURA 3
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_3],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_3],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_3],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_3]
              },
              {//COBERTURA 4
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_4],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_4],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_4],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_4]
              },
              {//COBERTURA 5
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_5],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_5],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_5],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_5]
              },
              {//COBERTURA 6
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_6],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_6],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_6],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_6]
              },
              {//COBERTURA 7
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_7],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_7],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_7],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_7]
              },
              {//COBERTURA 8
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_8],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_8],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_8],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_8]
              },
              {//COBERTURA 9
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_9],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_9],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_9],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_9]
              },
              {//COBERTURA 10
                "tipoTramite": TRAM_PM,
                "COB_idcobertura": object[DATA_PM.ID_COBERTURA_10],
                "COB_descripcion": object[DATA_PM.COB_DESCRIPCION_10],
                "COB_sumaasegurada": object[DATA_PM.SA_COBERTURA_10],
                "COB_idmoneda": object[DATA_PM.MONEDA_COBERTURA_10]
              },

            ];
            // Busco si hay coincidencias en el arreglo "arrPolizas"
            // Utilizar la función find para buscar el objeto
            var objetoEncontrado = arrPolizas.flat().find(function (obj) {
              return obj["POL_numpoliza"] === numPoliza;
            });

            if (objetoEncontrado) {
              console.log("Objeto encontrado en la tabla:", objetoEncontrado);
              setDialogFoundPolPM(true)
            } else {
              console.log("Objeto no encontrado en la tabla.");
              setArrPolizas([...arrPolizas, jsonPM])
              setDialogFoundPolPM(true)
            }
          } else {
            // Si no encontró resultado entonces mostrar diálogo.
            setDialogSinayPm(true)
          }
        }
      } else {

        console.log("Objeto sinay:", resultados);
        let asegurado = {};
        let contratante = {};
        //Recuperamos el numero de folio y tipo de reclamación de la api recepción 
        let objectRecep = await getDataToken(`recepcion/get_id/${resultados[0][0]?.RECT_idfolio}/`);
        // setNumFolios(objectRecep?.RECT_numerofolio)
        setReclamacion(objectRecep?.SINC_idcomplejidadsiniestro?.TRA_destipotramitesiniestro)
        resultados[0].forEach(poliza => {
          if (poliza?.PERR_idrol == PERR_ASEGURADO.id) {
            asegurado = poliza
          } else if (poliza?.PERR_idrol == PERR_CONTRATANTE.id) {
            contratante = poliza
          }
        })
        if (Object.keys(asegurado).length === 0) {
          console.log("El objeto asegurado está vacío.");
          return;
        }
        let jsonPM = [
          {//CONTRATANTE
            "tipoTramite": TRAM_PM,
            "POL_numpoliza": contratante?.POL_numpoliza,
            "PRO_cveproducto": contratante?.PRO_cveproducto, //Pendiente, no hay dato similar en el PM (crear constante?)
            "PRO_descripcion": contratante?.PRO_descripcion,
            "EST_descripcion": contratante?.EST_descripcion,
            "PER_nombre": contratante?.PER_nombre,
            "PER_apellidopaterno": contratante?.PER_apellidopaterno,
            "PER_apellidomaterno": contratante?.PER_apellidomaterno,
            "PER_RFC": contratante?.PER_RFC,
            "PER_CURP": contratante?.PER_CURP,
            "PERR_idrol": PERR_CONTRATANTE.id,
            "PERT_idtipopersona": 1, //crear constante
            "PCP_coberturasumaasegurada": contratante?.PCP_coberturasumaasegurada ? contratante?.PCP_coberturasumaasegurada : 0,
            "POL_idpoliza": contratante?.POL_idpoliza,
            "POL_idpoliza": contratante?.POL_idpoliza,
            "PER_idpersona": contratante?.PER_idpersona,

          },
          {//ASEGURADO
            "tipoTramite": TRAM_PM,
            "POL_numpoliza": asegurado?.POL_numpoliza,
            "PRO_cveproducto": asegurado?.PRO_cveproducto, //Pendiente, no hay dato similar en el PM (crear constante?)
            "PRO_descripcion": asegurado?.PRO_descripcion,
            "EST_descripcion": asegurado?.EST_descripcion,
            "EST_idestatuspoliza": 1, //Crear constantes de estatus poliza, ya que no está especificado en el PM
            "POL_polizafecemisión": asegurado?.POL_polizafecemisión,
            "POL_polizafinvigencia": asegurado?.POL_polizafinvigencia,
            "PRO_idproductos": "9", //Crear constantes de productos, ya que no está especificado en el PM
            "PER_nombre": asegurado?.PER_nombre,
            "PER_apellidopaterno": asegurado?.PER_apellidopaterno,
            "PER_apellidomaterno": asegurado?.PER_apellidomaterno,
            "PER_RFC": asegurado?.PER_RFC,
            "PER_CURP": asegurado?.PER_CURP,
            "PERR_idrol": PERR_ASEGURADO.id,
            "PERT_idtipopersona": 1, //crear constante
            "PER_fechanacimiento": asegurado?.PER_fechanacimiento,
            "PCP_coberturasumaasegurada": asegurado?.PCP_coberturasumaasegurada ? asegurado?.PCP_coberturasumaasegurada : 0,
            "RECT_numerofolio": asegurado?.RECT_numerofolio,

          },
        ];
        // Busco si hay coincidencias en el arreglo "arrPolizas"
        // Utilizar la función find para buscar el objeto
        var objetoEncontrado = arrPolizas.flat().find(function (obj) {
          return obj["POL_numpoliza"] === numPoliza;
        });

        if (objetoEncontrado) {
          console.log("Objeto encontrado en la tabla:", objetoEncontrado);
          setDialogFoundPol(true)
        } else {
          console.log("Objeto no encontrado en la tabla.");
          setArrPolizas([...arrPolizas, jsonPM])
          setDialogFoundPol(true)
        }
      }
    } else { // Si no se ingresó ningún carácter poner foco al texfield. 
      document.getElementById("txtNombre").focus();
    }
  }
  //Funciones para apartado "Buscar por:"
  const [buscarPor, setBuscarPor] = useState(1)

  //funcion para seleccionar polizas
  const [listaPol, setListaPol] = useState([]);
  const [dialogAviso, setDialogAviso] = useState(false);
  const [estadoSwitch, setEstadoSwitch] = useState("");

  const updatePolToList = (e, row, pol) => {
    if (e.target.checked) {
      // Validar si el objeto cumple con ciertas condiciones
      if (listaPol.length > 0) {
        if (pol?.PER_RFC !== listaPol[0][1].PER_RFC) {
          // Se seleccionó un asegurado diferente, mostrar aviso.    
          setDialogAviso(true);
        } else {
          setEstadoSwitch(pol?.PER_RFC);
          setListaPol((prevState) => [...prevState, row]);
        }
      } else {
        setEstadoSwitch(pol?.PER_RFC);
        setListaPol((prevState) => [...prevState, row]);
      }
    } else if (!e.target.checked && listaPol.length <= 0) {
      setEstadoSwitch("");
      //Remover el objeto de la lista de polizas seleccionadas
      setListaPol((current) => current.filter((e) => e[0].POL_numpoliza != pol?.POL_numpoliza));
    } else {
      //Remover el objeto de la lista de polizas seleccionadas
      setListaPol((current) => current.filter((e) => e[0].POL_numpoliza != pol?.POL_numpoliza));
    }
  };


  useEffect(() => {
    if (listaPol.length > 0) {
      console.log("Polizas seleccionadas:", listaPol);
      setBtnSiguiente(false);
      setHabilitaLink(true);
      // //concatenamos los datos que se van a enviar
      setPasoVariables(listaPol);
    } else {
      setBtnSiguiente(true);
      setHabilitaLink(false);
    }
  }, [listaPol])


  const [arrPolizas, setArrPolizas] = useState([])
  const agregarPoliza = async (arrPolizasNuevas) => {
    const nuevoArrPolizas = [...arrPolizas];// Crear una copia del arreglo original
    arrPolizasNuevas.forEach(polizaNueva => {
      // Verificar si el nombre de la poliza nueva ya existe en el nuevo arreglo
      const existe = nuevoArrPolizas.some(polizaExistente => polizaExistente[0].POL_numpoliza === polizaNueva[0].POL_numpoliza);

      if (existe) {
        // Si el objeto ya existe, mostrar una mesaje
        console.log(`La póliza "${polizaNueva[0].POL_numpoliza}" ya existe en la tabla.`);
      } else {
        // Si el objeto no existe, agregarlo al nuevo arreglo
        nuevoArrPolizas.push(polizaNueva);
        console.log("Poliza(s) agregada(s):", polizaNueva)
      }
    });
    //Actualizar el estado original por el nuevo.
    setArrPolizas(nuevoArrPolizas);
  }

  useEffect(() => {
    console.log("arr pol useefect", arrPolizas)
  }, [arrPolizas]);

  return (
    <div>
      <Grid
        style={{ paddingBottom: "20px" }}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <h4-search>
            <FormattedMessage id="globBuscar" defaultMessage="Buscar por" />:
          </h4-search>
        </Grid>
        <Grid item>
          <RadioDinamico defaultValue={1} id="rbOptions" array={[{ "id": 1, "value": 1, "label": "Número de póliza" }, { "id": 2, "value": 2, "label": "Nombre del asegurado" }]} onChange={(e) => {
            setBuscarPor(parseInt(e.target.value))
            setDatosAse({
              nombre: "",
              apePat: "",
              apeMat: ""
            })
            setNumPoliza("")
          }} />
        </Grid>
        {buscarPor === 1 ?
          <Grid item>
            <TXTField
              label="Número de póliza"
              id="txtNumPoliza"
              showSearchIcon={true}
              iconOnClick={() => buscarPoliza(numPoliza)}
              onChange={(e) => {
                setNumPoliza(e.target.value)
              }}
            />
          </Grid> :
          <Grid item>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <TXTField
                  label="Nombre"
                  id="txtNombre"
                  onChange={(e) => { setDatosAse(prevState => ({ ...prevState, nombre: e.target.value })) }}
                />
              </Grid>
              <Grid item>
                <TXTField
                  label="Apellido paterno"
                  id="txtApePat"
                  onChange={(e) => { setDatosAse(prevState => ({ ...prevState, apePat: e.target.value })) }}
                /></Grid>
              <Grid item>
                <TXTField
                  label="Apellido Materno"
                  id="txtApeMat"
                  onChange={(e) => { setDatosAse(prevState => ({ ...prevState, apeMat: e.target.value })) }}
                />
              </Grid>
              <Grid item>
                <ButtonVino label="Buscar" onClick={() => buscarPorNombreAse(datosAse.nombre, datosAse.apePat, datosAse.apeMat)} />
              </Grid>
            </Grid>
          </Grid>
        }


      </Grid>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead style={{ position: "sticky" }}>
              <TableRow>
                <StyledTableCell>Seleccionar</StyledTableCell>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                <StyledTableCell>
                  <FormattedMessage
                    id="globNoPoli"
                    defaultMessage="No. de póliza"
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="folio" defaultMessage="Número folio" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="globProdut" defaultMessage="Producto" />
                </StyledTableCell>
                <StyledTableCell align="center">Estatus</StyledTableCell>
                <StyledTableCell>
                  <FormattedMessage
                    id="globNomAse"
                    defaultMessage="Nombre del asegurado"
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="recepContr"
                    defaultMessage="Contratante"
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="globRfcp" defaultMessage="RFC" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="globCurp" defaultMessage="CURP" />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody id="tbbody">
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {arrPolizas
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  console.log("rooow", row)
                  let asegurado;
                  let contratante;
                  row.forEach(poliza => {
                    if (poliza?.PERR_idrol === PERR_ASEGURADO.id) {
                      asegurado = poliza
                    } else if (poliza?.PERR_idrol === PERR_CONTRATANTE.id) {
                      contratante = poliza
                    }
                  })
                  console.log(asegurado)
                  return (

                    <TableRow >
                      <StyledTableCell component="th" scope="row" align="center">
                        <Switch onChange={(e) => updatePolToList(e, row, asegurado)}
                          disabled={listaPol.length > 0 && asegurado?.PER_RFC !== (listaPol[0] && listaPol[0][1] && listaPol[0][1].PER_RFC) ? true : (asegurado.POL_numpoliza === (listaPol[0] && listaPol[0][1] && listaPol[0][1].POL_numpoliza) && asegurado?.POL_idpoliza !== (listaPol[0] && listaPol[0][1] && listaPol[0][1].POL_idpoliza)) ? true : false }
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {asegurado?.POL_numpoliza}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {asegurado?.RECT_numerofolio}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {asegurado?.PRO_descripcion}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {asegurado?.EST_descripcion}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {`${asegurado?.PER_nombre} ${asegurado?.PER_apellidopaterno} ${asegurado?.PER_apellidomaterno}`}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {`${contratante?.PER_nombre} ${contratante?.PER_apellidopaterno} ${contratante?.PER_apellidomaterno}`}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {`${asegurado?.PER_RFC}`}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {`${asegurado?.PER_CURP}`}
                      </StyledTableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={arrPolizas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Registros por página:"
        />
      </Paper>
      <br />
      <Box sx={{ minWidth: 25 }}>
        <Grid container>
          <Grid xs={2}>
            <Link
              to="/NuevoTramite"
              state={{ pasoVariables }}
              style={{ textDecoration: "none" }}
              onClick={(e) => !habilitaLink && e.preventDefault()}
            >
              <ButtonVino
                variant="contained"
                color="primary"
                size="small"
                estatus={BtnSiguiente}
                label={
                  <FormattedMessage
                    id="glob.siguiente"
                    defaultMessage="Siguiente"
                  />
                }
              />
            </Link>
            <br />
          </Grid>
          <br />
          <Grid xs={3}>
            <DialogRegistro dataPolizas={(e) => agregarPoliza(e)} />
            <br />
          </Grid>
        </Grid>
      </Box>
      <br></br>
      {/* Este dialogo es cuando una póliza no se encuentra ni en sinay ni en el PM */}
      {!dialogSinayPm ? null : <DialogNotFoundSinayPm open={true} aceptar={() => setDialogSinayPm(false)} />}
      {/* Este dialogo es cuando una póliza no se encuentra en sinay pero si en el PM */}
      {!dialogFoundPolPM ? null :
        <DialogFoundPolPM numPoliza={numPoliza} open={true} cerrar={() => {
          setDialogFoundPolPM(false)
        }}
          aceptar={() => {
            //guardarPersonaXPoliza()
            setDialogFoundPolPM(false)
            console.log("Aqui la programacion para guardar en sinay")
          }} />}
      {/* Este dialogo es cuando se encontró póliza dentro de sinay */}
      {!dialogFoundPol ? null : <DialogFoundNumPol open={true} aceptar={() => setDialogFoundPol(false)}
        numPoliza={numPoliza}
        numeroFolio={numFolios}
        reclamacion={reclamacion} />}
      {/* Este dialogo es cuando se encontró al aseguado dentro de sinay */}
      {!dialogFoundAse ? null : <DialogFoundAse open={true} aceptar={() => setDialogFoundAse(false)} />}
      {/* Este dialogo es cuando se encontró al aseguado dentro de sinay */}
      {!dialogAviso ? null : <DialogConfirmAviso open={true} aceptar={() => setDialogAviso(false)} />}
    </div>
  );
}
