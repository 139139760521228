import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { TabDatosPoliza } from "./TabDatosPoliza";
import { TabDatosAsegurado } from "./TabDatosAsegurado";
import { TabDatosBeneficiarios } from "./TabDatosBeneficiario";
import { TabDatosAgente } from "./TabDatosAgente";
import { TabDatosCoberturas } from "./TabDatosCoberturas";
import { TabDatosReservas } from "./TabDatosReservas";
import { getDataToken, putDataToken } from "../../../../api/dataToken";
import { FormattedMessage } from "react-intl";
import { formatofecha } from "../../../../api/getUmbrales";
import moment from "moment";
import DialogValidaciones from "../Dialog/DialogValidaciones";
import Dialogtwoboton from "../Dialog/Dialogtwoboton";
import getDate from "../../Function/obtenerFecha";
import getHours from "../../Function/obtenerHora";
import {
  PERR_ASEGURADO,
  PERR_CONTRATANTE,
  PERR_BENEFICIARIO,
  PERR_AGENTE,
} from "../../../../utils/constanstoken";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ display: value === index ? "block" : "none", flexGrow: 1 }}
    >
      {value === index && (
        <Box sx={{ p: 3, width: "100%" }}>
          {" "}
          {/* Establecer el ancho al 100% */}
          <Typography style={{ width: "100%" }}>{children}</Typography>{" "}
          {/* Establecer el ancho al 100% */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function TabPMSinay(props) {
  ////CONFIGURACIÓN DEL COMPONENTE////
  const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const idPoliza = props.idPoliza;
  console.log(idPoliza);

  const numPoliza = props.numPoliza;
  console.log(numPoliza);

  ///Obtener los datos del PM
  //Obtenemos la póliza que deseamos consultar.
  const [dataPoliza, setdataPoliza] = useState([]);
  const [dataCobertura, setdataCobertura] = useState([]);
  const [dataAsegurados, setdataAsegurados] = useState([]);
  const [dataContratante, setdataContratante] = useState([]);
  const [dataAgentes, setdataAgentes] = useState([]);
  const [dataBeneficiarios, setdatabeneficiarios] = useState([]);
  const [dataReseguradoras, setdataReaseguradoras] = useState([]);

  const getDatosPoliza = async () => {
    try {
      // Realizas las solicitudes para obtener los datos
      const result = await getDataToken(`polizas/get_id/${idPoliza}/`);
      setdataPoliza(result);
      const resultCoberturas = await getDataToken(
        `polizascoberturasporproductos/get_pol/${idPoliza}/`
      );
      setdataCobertura(resultCoberturas);
      const resultPersonas = await getDataToken(
        `personasxpoliza/get_poliza/${idPoliza}/`
      );
      const resultReaseguradoras = await getDataToken(
        `polizasreaseguro/get_pol/${idPoliza}/`
      );
      setdataReaseguradoras(resultReaseguradoras);
      const dataAsegurados = resultPersonas.find(
        (item) =>
          item?.PER_idpersona?.PERR_idrol?.PERR_idrol === PERR_ASEGURADO.id
      );
      const dataContratante = resultPersonas.find(
        (item) =>
          item?.PER_idpersona?.PERR_idrol?.PERR_idrol === PERR_CONTRATANTE.id
      );
      const dataAgentes = resultPersonas.find(
        (item) => item?.PER_idpersona?.PERR_idrol?.PERR_idrol === PERR_AGENTE.id
      );
      const dataBeneficiarios = resultPersonas.filter(
        (item) =>
          item?.PER_idpersona?.PERR_idrol?.PERR_idrol === PERR_BENEFICIARIO.id
      );

      // Estableces los datos filtrados
      if (dataAsegurados) setdataAsegurados(dataAsegurados);
      if (dataContratante) setdataContratante(dataContratante);
      if (dataAgentes) setdataAgentes(dataAgentes);
      if (dataBeneficiarios) setdatabeneficiarios(dataBeneficiarios);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getDatosPoliza();
  }, [idPoliza, numPoliza]);

  console.log("Num de póliza" + numPoliza);
  console.log("Datos póliza", dataPoliza);
  console.log(dataCobertura);
  console.log(dataAsegurados);
  console.log(dataContratante);
  console.log(dataAgentes);
  console.log(dataBeneficiarios);
  console.log(dataReseguradoras);

  ////Datos Analista, hora y fecha//////
  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");

  //Obtener la hora
  const horaActual = getHours();

  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;

  console.log("fechaActSIN" + fechaActSIN);

  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");

  //Obtener usuario.
  const getUsers = async () => {
    try {
      var result = await getDataToken(`login/get_correo/${email}/`);
      setnombreAnalista(
        result.SNYTLOG_username +
          " " +
          result.SNYTLOG_surnamedad +
          " " +
          result.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  console.log(nombreAnalista);

  ///Obtener la fecha de poliza rehabilitada
  const [polizaRehabilitada, setpolizaRehabilitada] = useState("");
  console.log(polizaRehabilitada);

  /////////////////////////Mensajes///////////////////////
  const [mostrarAlerta, setmostrarAlerta] = useState(false);
  const [mostrarConfirmacion, setmostrarConfirmacion] = useState(false);
  const [banderaEjecutado, setbanderaEjecutado] = useState(false);
  const [banderaFinal, setbanderaFinal] = useState(false);

  const mostrarAlert = () => {
    if (banderaFinal) {
      setbanderaEjecutado(true);
    } else {
      setmostrarAlerta(true);
    }
  };

  const mostrarConfirm = async () => {
    let poliza = {
      POL_polizarehabilitada: polizaRehabilitada,
      POL_actualizofecha: fechaActSIN,
      POL_actualizousuario: nombreAnalista,
    };
    const data = await putDataToken(
      `polizas/get_id/${dataPoliza?.POL_idpoliza}/`,
      poliza
    );
    console.log(`Actualización exitosa de poliza`, data);
    setmostrarConfirmacion(true);
    setmostrarAlerta(false);
  };

  const cerrarAlert = () => {
    setmostrarAlerta(false);
  };

  const msjFinal = () => {
    setbanderaFinal(true);
    handleExpandClick();
  };

  const ultimoAlert = () => {
    setbanderaEjecutado(false);
    handleExpandClick();
  };

  console.log(banderaFinal);

  return (
    <>
      {banderaEjecutado && (
        <DialogValidaciones
          title={
            <FormattedMessage
              id="regisMsgInf"
              defaultMessage="Mensaje informativo"
            />
          }
          secondButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description={
            <FormattedMessage
              id="regisReali"
              defaultMessage="El registro ya fue realizado."
            />
          }
          onClick={ultimoAlert}
        />
      )}
      {mostrarConfirmacion && (
        <DialogValidaciones
          title={
            <FormattedMessage
              id="regisGenVrs"
              defaultMessage="GENERACIÓN DE VERSIÓN 1 DE PÓLIZA"
            />
          }
          secondButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description={
            <FormattedMessage
              id="recepFeCre"
              defaultMessage="Fecha de creación: {fecha}"
              values={{ fecha: moment(fechaEmpAn).format(formatofecha) }}
            />
          }
          description2={
            <FormattedMessage
              id="regisHoraCrea"
              defaultMessage="Hora de creación: {hora}"
              values={{ hora: horaActual }}
            />
          }
          description3={
            <FormattedMessage
              id="regisAnalista"
              defaultMessage="Analista: {analista}"
              values={{ analista: nombreAnalista }}
            />
          }
          onClick={msjFinal}
        />
      )}
      {mostrarAlerta && (
        <Dialogtwoboton
          title={
            <FormattedMessage
              id="regisMensajeConf"
              defaultMessage="Mensaje de confirmación"
            />
          }
          description={
            <FormattedMessage
              id="regisMensajeOpc"
              defaultMessage="¿Esta seguro de realizar esta operación?"
            />
          }
          firstButton={<FormattedMessage id="globSi" defaultMessage="Si" />}
          secondButton={<FormattedMessage id="globNo" defaultMessage="No" />}
          onClick={mostrarConfirm}
          onClick2={cerrarAlert}
        />
      )}
      <div>
        <Card sx={{ width: "100%" }}>
          <CardHeader
            onClick={handleExpandClick}
            action={
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                style={{ color: "#fff" }}
              >
                <ExpandMoreIcon />
              </ExpandMore>
            }
            style={{
              backgroundColor: "#00203b",
              color: "#fff",
              cursor: "pointer",
            }}
            title={
              <h1-header-card-polizas>
                <FormattedMessage
                  id="globNumPol"
                  defaultMessage="Número de póliza"
                />{" "}
                - {numPoliza}
              </h1-header-card-polizas>
            }
          />
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <h2-dialog-description>
                <strong>
                  {" "}
                  <FormattedMessage
                    id="regExtPo"
                    defaultMessage="Extracción de datos de la póliza del PM."
                  />
                </strong>
              </h2-dialog-description>
              <br />
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                }}
              >
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: "divider" }}
                >
                  <Tab
                    label={
                      <FormattedMessage
                        id="regDataPo"
                        defaultMessage="Datos de la póliza"
                      />
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="regDataCo"
                        defaultMessage="Coberturas de la póliza"
                      />
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="regDataRe"
                        defaultMessage="Reaseguradoras de la póliza"
                      />
                    }
                    {...a11yProps(2)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="regDataAseg"
                        defaultMessage="Datos del asegurado y contratante"
                      />
                    }
                    {...a11yProps(3)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="regDataBene"
                        defaultMessage="Datos de los beneficiarios"
                      />
                    }
                    {...a11yProps(4)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="regDataAgen"
                        defaultMessage="Datos del agente"
                      />
                    }
                    {...a11yProps(5)}
                  />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <TabDatosPoliza
                    dataPoliza={dataPoliza}
                    polizaRehabilitada={(e) => setpolizaRehabilitada(e)}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <TabDatosCoberturas dataPoliza={dataCobertura} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <TabDatosReservas dataPoliza={dataReseguradoras} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <TabDatosAsegurado
                    dataPoliza={dataAsegurados}
                    dataPoliza2={dataContratante}
                  />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <TabDatosBeneficiarios dataPoliza={dataBeneficiarios} />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <TabDatosAgente dataPoliza={dataAgentes} />
                </TabPanel>
              </Box>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item xs={"auto"}>
                  <ButtonVino
                    label={
                      <FormattedMessage
                        id="globGuarda"
                        defaultMessage="Guardar"
                      />
                    }
                    onClick={mostrarAlert}
                  ></ButtonVino>
                </Grid>
              </Grid>
              <br />
              <br />
            </CardContent>
          </Collapse>
        </Card>
      </div>
    </>
  );
}
