import React from "react";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getDataToken } from "../../../../api/dataToken";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { searchFilesByName } from "../../../../nextcloud/functions";
import { EXP } from "../../../../utils/constanstoken";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CardExpedienteReclamacion(props) {
  const poliza = props.poliza;
  const numFolio = poliza?.RECT_idfolio?.RECT_numerofolio;
  const nombreBusqueda = EXP + "_" + poliza?.POL_idpoliza?.POL_numpoliza;
  console.log(poliza);
  console.log(numFolio);
  console.log(nombreBusqueda);

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  ///Obtener los datos del PM
  const [data, setdata] = React.useState("");

  React.useEffect(() => {
    getDataToken(
      `conexionpm/poliza_apiPM/?poliza=${poliza?.POL_idpoliza?.POL_numpoliza}`
    )
      .then((result) => {
        setdata(result[0]);
      })
      .catch((error) => console.error(error));
  }, [poliza]);

  console.log(data);

  const [pdfs, setPdfs] = React.useState([]);
  const [documents, setDocuments] = React.useState([]);
  const [currentPDF, setCurrentPDF] = React.useState(0);
  const [loadingPDF, setLoadingPDF] = React.useState(false);

  React.useEffect(() => {
    const verArchivos = async () => {
      setLoadingPDF(true);
      var response = await searchFilesByName(numFolio, nombreBusqueda);
      if (!response) {
        alert("No existe carpeta con ese folio");
      } else {
        if (response.length > 0) {
          var pdfs = response.filter((file) => file.fileExt === "pdf");
          if (pdfs.length > 0) {
            const docs = pdfs.map((e) => e.fileBlob);
            console.log("Docs:", docs);
            setPdfs(docs);
          } else {
            setLoadingPDF(false);
          }
        } else {
          alert("La carpeta está vacía.");
        }
      }
    };

    verArchivos();
  }, [numFolio, nombreBusqueda]);

  //Este use Effect se activará cuando escuche cambios en el pdfs
  React.useEffect(() => {
    if (pdfs.length > 0) {
      setLoadingPDF(false);
      pdfs.forEach((blob) => {
        var pdfUrl = URL.createObjectURL(blob);
        console.log(pdfUrl);
        setDocuments((prevState) => [
          ...prevState,
          <iframe
            src={pdfUrl}
            style={{
              width: "900px",
              height: "1000px",
              display: "block",
              margin: "auto",
            }}
          />,
        ]);
        URL.revokeObjectURL(blob);
      });
    }
  }, [pdfs]);

  return (
    <div>
      <Card sx={{ width: "100%" }}>
        <CardHeader
          onClick={handleExpandClick}
          action={
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              style={{ color: "#fff" }}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }
          style={{
            backgroundColor: "#00203b",
            color: "#fff",
            cursor: "pointer",
          }}
          title={
            <h1-header-card-polizas>
              Expediente con número de póliza{" "}
              {poliza?.POL_idpoliza?.POL_numpoliza}
            </h1-header-card-polizas>
          }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <div>
              {data === null || data === " " || data === undefined ? (
                <div>
                  {loadingPDF ? (
                    <p>Cargando PDF...</p>
                  ) : pdfs.length > 0 ? (
                    <div>
                      {documents[currentPDF]}
                      <div
                        style={{
                          display: "block",
                          margin: "auto",
                          textAlign: "center",
                        }}
                      >
                        <br />
                        <ButtonVino
                          onClick={() => setCurrentPDF(currentPDF - 1)}
                          disabled={currentPDF === 0}
                          label="Anterior PDF"
                        />
                        <ButtonVino
                          onClick={() => setCurrentPDF(currentPDF + 1)}
                          disabled={currentPDF === pdfs.length - 1}
                          label="Siguiente PDF"
                        />
                      </div>
                    </div>
                  ) : (
                    <p>El folio {numFolio}, no cuenta con un expediente.</p>
                  )}
                </div>
              ) : (
                <div>
                  <iframe
                    src="https://www.scribd.com/embeds/696012358/content?start_page=1&view_mode=scroll&access_key=key-hOpBX2U6dZqam7jXVpFV"
                    style={{
                      width: "900px",
                      height: "1000px",
                      display: "block",
                      margin: "auto",
                    }}
                  />
                </div>
              )}
            </div>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
}
