import * as React from "react";
import Grid from "@mui/material/Grid";
import { DATA_PM } from "../../../../utils/constansPM";
import { FormattedMessage } from "react-intl";

export function TabDatosAgente(props) {
  const dataPoliza = props.dataPoliza;
  console.log(dataPoliza);
  return (
    <div style={{ padding: "5px", width: "800px" }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div style={{ textAlign: "center" }}>
            <strong>
              <FormattedMessage
                id="recepNomAg"
                defaultMessage="Nombre agente"
              />
            </strong>
            <br />
            <br />
            {dataPoliza?.POL_idpoliza?.POL_idpoliza
              ? dataPoliza?.PER_idpersona?.PER_nombre +
                " " +
                dataPoliza?.PER_idpersona?.PER_apellidopaterno +
                " " +
                dataPoliza?.PER_idpersona?.PER_apellidomaterno
              : dataPoliza === "" || dataPoliza === undefined
              ? ""
              : dataPoliza[DATA_PM.NOMBRE_AGENTE]}
            <br />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ textAlign: "center" }}>
            <strong>
              <FormattedMessage id="recepCveAg" defaultMessage="Clave agente" />
            </strong>
            <br />
            <br />
            {dataPoliza?.POL_idpoliza?.POL_idpoliza
              ? dataPoliza?.PER_idpersona?.PER_clave
              : dataPoliza === "" || dataPoliza === undefined
              ? ""
              : dataPoliza[DATA_PM.CLAVE_AGENTE]}
            <br />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ textAlign: "center" }}>
            <strong>
              <FormattedMessage id="recepDAAg" defaultMessage="DA agente" />
            </strong>
            <br />
            <br />
            {dataPoliza?.POL_idpoliza?.POL_idpoliza
              ? dataPoliza?.PER_idpersona?.OFXAGEN_idoficinaxagencia
                  ?.AGEN_idagencia?.AGEN_direccion
              : dataPoliza === "" || dataPoliza === undefined
              ? ""
              : dataPoliza[DATA_PM.DA_AGENTE]}
            <br />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
