import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';


const R3=(props)=>{
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={props.valordefault} 
        name="radio-buttons-group"
        color='primary'
        row
      >
        <FormControlLabel value={props.valor1} control={<Radio />} label={props.nombre1} />
        <FormControlLabel value={props.valor2}  control={<Radio />} label={props.nombre2} />
        <FormControlLabel value={props.valor3}  control={<Radio />} label={props.nombre3} />
        <FormControlLabel value={props.valor4}  control={<Radio />} label={props.nombre4} />
     
      </RadioGroup>
    </FormControl>
  );
}

export default R3;