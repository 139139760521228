import routesComponents from "./routes.Components";
import routesClient from "./routes.Client";
import rtmedico from "./Medico/rtmedico";
import rtrecepcion from "./Recepcion/rtrecepcion";
import routesAdmin from "./routes.admin";
import rtinicio from "./Inicio/rtInicio";
import rtregistro from "./Registro/rtregistro";
import rtcomunicacion from "./Comunicacion/rtcomunicacion";
import rtinvestigador from "./Investigador/rtinvestigador";
import rtDictamen from "./Dictamen/rtDictamen";
import rtGestion from "./GestorComp/rtGestorComp";
import rtcontable from "./Contable/rtcontable";
import rtworkflow from "./Workflow/rtworkflow";
import rtConf from "./ConfGeneral/rtConf";
import rtDocumentations from "./Documentations/rtDocumentations";
import rtMenuPrincipal from "./MenuPrincipal/rtMenuPrincipal";
import rtPerfilCliente from "./PerfilCliente/rtPerfilCliente";
import { Error404 } from "../pages/Error404";
import { BasicLayout } from "../layouts/BasicLayout";

const routes = [
  ...routesAdmin,
  ...routesClient,
  ...routesComponents,
  ...rtmedico,
  ...rtrecepcion,
  ...routesAdmin,
  ...rtinicio,
  ...rtinvestigador,
  ...rtcomunicacion,
  ...rtregistro,
  ...rtDictamen,
  ...rtcontable,
  ...rtworkflow,
  ...rtConf,
  ...rtDocumentations,
  ...rtMenuPrincipal,
  ...rtPerfilCliente,
  ...rtGestion,

  {
    path: "*",
    layout: BasicLayout,
    component: Error404,
  },
];

export default routes;
