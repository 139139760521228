/* 
Pajita
Proyecto SINAY
2023
*/
import React from "react";
//impor para el boton
import Box from "@mui/material/Box";
//import para la tabla
import TableConstRegistro from "./Elements/TableConstRegistro";
import { Grid } from "@mui/material";
//Breadcrumbs
import Recorrido3 from "./Elements/Bread/Recorrido3";
//Estilos
import "../../css/estilo.css";
//Idioma
import { FormattedMessage } from "react-intl";

//En este componene traemos la charola de los registros que tenemos para su consulta.

export function CompConstRegistro() {
  return (
    <div className="div-parent">
      <div className="div-breadcrumbs">
        {/*navegador*/}
        <Recorrido3
          name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
          enlace="/menu"
          name2={<FormattedMessage id="globRegist" defaultMessage="Registro" />}
          enlace2="/registro/inicio"
          name3={
            <FormattedMessage
              id="submenuCon"
              defaultMessage="Consultar registro"
            />
          }
        />
      </div>
      <div className="div-container">
        <Box sx={{ width: "100%" }}>
          {/*Contenido*/}
          <Grid container>
            <Grid xs={12}>
              <TableConstRegistro />
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
