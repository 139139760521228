/* 
Pajita
Proyecto SINAY
2023
*/
import React from "react";
//import para la tabla
import TableRecord from "./Elements/TableRecord";
//Breadcrumbso
import Recorrido from "./Elements/Bread/Recorrido";
//Estilos
import "../../css/estilo.css";
//Idioma
import { FormattedMessage } from "react-intl";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import { Grid } from "@mui/material";

//Este es el componente principal, donde se colocan todos los componentes.

export function CompRegistro() {
  return (
    <div className="div-parent">
      <div className="div-breadcrumbs">
        {/*navegador*/}
        <Recorrido
          name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
          enlace="/menu"
          name2={<FormattedMessage id="globRegist" defaultMessage="Registro" />}
        />
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <ReturnPage href="/menu" />
        </Grid>
      </div>
      <div className="div-container">
        {/*LLamado de la tabla */}
        <TableRecord />
      </div>
    </div>
  );
}
