import * as React from "react";
//Diseño
// import "./Elements/Diseño.css";
import "../../../css/estilo.css";
//Breadcrums
import Recorrido from "../../Tools/BreadCrumbs/Recorrido3";
//Letra
import Typography from "@mui/material/Typography";

//* Alerts
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
//* Card
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
//* Textfield
import TextField from "@mui/material/TextField";
// import { Password } from "@mui/icons-material";
//* Grid
import Grid from "@material-ui/core/Grid";
import { Divider } from "@mui/material";
//* Button Vino
import ButtonVino from "../../Tools/Bottones/ButtonVino";
//* Table
import { useState } from "react";
import StyledTableCell from "../../Tools/Table/StyledTableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FormattedMessage } from "react-intl";
import { styled } from "@mui/material/styles";

//* Input
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
//* Icons
import KeyIcon from "@mui/icons-material/Key";

export function ConfSecurity() {
  //TODO: Variable password
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla

  //TODO: StyledTableRow
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    //* hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  //TODO: Función para validar datos tabla.
  function createData(Tipo, DireccionIP, Cliente) {
    return { Tipo, DireccionIP, Cliente };
  }
  const rows = [
    createData("Inicio de sesión con credenciales el 10:40 2023/01/04", "localhost:3000", "Chrome, Windows 11 Pro 22h2"),
    createData("Inicio de sesión con credenciales el 16:05 2023/01/04", "localhost:3000", "Chrome, Windows 11 Pro 22h2"),
    createData("Inicio de sesión con credenciales el 16:05 2023/01/04", "localhost:3000", "Chrome, Windows 11 Pro 22h2"),
    createData("Inicio de sesión con credenciales el 16:05 2023/01/04", "localhost:3000", "Chrome, Windows 11 Pro 22h2"),
  ];
  //TODO:variable para table
  const [data] = useState(rows);

  return (
    <div className="div-parent">
      <Alert severity="warning">
        <AlertTitle>Warning</AlertTitle> <strong>Módulo en construcción</strong>
      </Alert>
      {/* //TODO: BreadCrumbs */}
      <div className="div-breadcrumbs">
        <Recorrido name1="Inicio" enlace="/confadmin" name2="Seguridad" />
      </div>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Card style={{ width: "100%" }}>
                <CardHeader
                  title="Generar SSH Key"
                  subheader={
                    <label>
                      Generar SSH Key para combprobación del sistema
                    </label>
                  }
                />
                <CardContent>
                  <Typography>Nombre<label style={{color:"red"}}>*</label></Typography>
                  <TextField fullWidth placeholder="Ej. NombreKey"></TextField>
                  <br/>
                  <Typography>Correo<label style={{color:"red"}}>*</label></Typography>
                  <TextField fullWidth placeholder="Ej. example@example.com"></TextField>
                  <br/>
                  <Typography>Frase de contraseña (Opcional)</Typography>
                  <TextField fullWidth placeholder="Vacio sin frase de contraseña"></TextField>
                  <br/>
                  
                  {/* <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">
                      Contraseña actual
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      startAdornment={
                        <InputAdornment position="start">
                          <KeyIcon />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Contraseña actual"
                    />
                  </FormControl> */}
                  {/* <br />
                  <br />
                  <Divider textAlign="left">
                    <Typography>Nuevo</Typography>
                  </Divider>
                  <br /> */}
                  {/* <Typography>Nueva contraseña</Typography> */}
                  {/* <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">
                      Nueva contraseña
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword2 ? "text" : "password"}
                      startAdornment={
                        <InputAdornment position="start">
                          <KeyIcon />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword2}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Nueva contraseña"
                    />
                  </FormControl>
                  <br />
                  <br /> */}
                  {/* <Typography>Confirmar contraseña</Typography> */}
                  {/* <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">
                      Confirmar contraseña
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type="password"
                      startAdornment={
                        <InputAdornment position="start">
                          <KeyIcon />
                        </InputAdornment>
                      }
                      label="Confirmar contraseña"
                    />
                  </FormControl> */}
                  <br />
                  <br />
                  <div
                    style={{
                      justifyContent: "right",
                      alignItems: "right",
                      display: "flex",
                    }}
                  >
                    <ButtonVino
                      variant="contained"
                      color="primary"
                      size="large"
                      label="Generar SSH Key"
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card style={{ width: "100%" }}>
                <CardHeader
                  title="Historial de inicios de sesión"
                  subheader={
                    <label>Su actividad de inicio de sesión reciente.</label>
                  }
                />
                <Paper sx={{ width: "100%" }}>
                  <TableContainer sx={{ maxHeight: 500 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">
                            <FormattedMessage
                              // id="glob.folio"
                              id="secu.tipo"
                              defaultMessage="Tipo de inicio de sesión"
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormattedMessage
                              id="secu.direccion"
                              defaultMessage="Dirección IP"
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            // onClick={() => sorting("nombreAsegurado")}
                          >
                            <FormattedMessage
                              id="secu.cliente"
                              defaultMessage="Cliente"
                            />
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data
                          .filter((val) => {
                            if (searchTerm === "") {
                              return val;
                            } else if (
                              /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                              val.numPoliza
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                              val.nombreAsegurado
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            ) {
                              return val;
                            }
                          })
                          .slice
                          // page * rowsPerPage,
                          // page * rowsPerPage + rowsPerPage
                          ()
                          .map((row) => (
                            // <StyledTableRow key={row.numPoliza}>
                            <StyledTableRow>
                              <StyledTableCell align="center">
                                {row.Tipo}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.DireccionIP}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.Cliente}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <br />
    </div>
  );
}
