import * as React from "react";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const Check = (props) => {
  return (
    <FormControl component="fieldset">
      <FormGroup aria-label="position" row>
        <FormControlLabel
          control={<Switch color="primary" disabled={props.estatus} />}
          label={props.label}
          labelPlacement={props.position}
          onChange={props.onChange}
        />
      </FormGroup>
    </FormControl>
  );
};

export default Check;
