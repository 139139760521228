import * as React from "react";
import PropTypes from "prop-types";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from '@material-ui/core/Grid'
import IconEditar from "../../../Tools/Icons/IconEditar";
import TXTField from "../../../Tools/TextField/TextField";
import moment from "moment/moment";
//idioma
import { FormattedMessage } from "react-intl";
import DatePicker2 from "../../../Tools/DatePicker/DatePicker2";
import { LimitarDigitos,  calculateAge } from "../../../../utils/functions";
import {  putDataToken } from "../../../../api/dataToken";
import { useState } from "react";
import DialogFeedback from "../../../Tools/Dialog/DialogFeedback";


//Solo aparesera un alerta con un solo boton
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

//FECHA ACTUAL
let today = new Date();
//EDAD ACTUAL 
function calcularEdad(fecha) {
  var anios = today.getFullYear() - fecha.getFullYear()
  //si no ha cumplido años le restamos el año por cumplir 
  if (today.getMonth() < fecha.getMonth() || today.getDate() < fecha.getDate()) {
    anios--
  }
  return anios
}



export default function DialogEditarAse(props) {
  const asegurado = props.asegurado
  const [open, setOpen] = React.useState(false);
  const formularioRef = React.useRef(null);
  const [btnGuardar, setBtnGuardar] = React.useState(false);
console.log("Asegurado", asegurado)


  //FECHA DE NACIEMIENTO DEL BENEFICIARIO CON FORMATO DD-MM-YYYY
  const [fechaNac, setFechaNac] = React.useState(asegurado?.PER_idpersona?.PER_fechanacimiento)
  //validacion de edad actual
  const [txtEdadActValue, setTxtEdadActValue] = React.useState("")

  const handleDataChange = (newData) => {
    // setTxtEdadActValue(newData)
    let edadActual = calcularEdad(newData.$d);
    setTxtEdadActValue(edadActual);
    setFechaNac(moment(newData.$d, "YYYY-MM-DD").format("YYYY-MM-DD"));
    validarEdadTramite(edadActual, 2);
  }

  // validacion de edad al trámite
  const [edadTraError, setEdadTraError] = React.useState(false)
  const [helperEdadTra, setHelperEdadTra] = React.useState(false)

  function validarEdadTramite(e, caso) {

    if (caso === 1) {
      let edad = e.target.value;
      if (edad > txtEdadActValue) {
        setEdadTraError(true)
        setHelperEdadTra('La edad ingresada es mayor a la edad actual.')
      } else {
        setEdadTraError(false)
        setHelperEdadTra('')
      }
    } else {
      if (txtEdadActValue > e) {
        setEdadTraError(true)
        setHelperEdadTra('La edad al trámite es mayor a la edad actual.')
      } else {
        setEdadTraError(false)
        setHelperEdadTra('')
      }
    }

  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    formularioRef.current.reset();
  };

  //GUARDAR DATOS ASEGURADO
  const gardarDatosAsegurado = async (jsonAse) => {
    try {
      let result = await putDataToken(`personas/get_id/${asegurado.PER_idpersona.PER_idpersona}/`, jsonAse);
      if (result ) {
        props.response(true);
        setTituloFeedback("Datos actualizados correctamente")
        setDescripFeedback("Los datos del asegurado fueron actualizados correctamente.")
        setMostrarFeedback(true);
      }else{
        props.response(true);
        setTituloFeedback("Falló al actualizar")
        setDescripFeedback("Algo ha salido mal, comuníquese con el encargado de TI.")
        setMostrarFeedback(true);
      }
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }

  /** Estados y funcion para dialogo feedback al guardar */
  const [tituloFeedback, setTituloFeedback] = useState("")
  const [descripFeedback, setDescripFeedback] = useState("")
  const [mostrarFeedback, setMostrarFeedback] = useState(false)


  return (
    <div>
      {/*BOTON QUE HACE EL LLAMADO AL DIALOGO*/}
      {/*Este boton aparecera el js en donde se necesite de la alerta*/}
      <IconEditar onClick={handleClickOpen} tooltipTitle={<FormattedMessage id="globEditar" defaultMessage="Editar" />} />

      {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
      <BootstrapDialog

        onClose={handleClose}
        aria-labelledby="dialog"
        open={open}
      >
        {/*CUERPO DEL DIALOGO*/}
        {/*TITILO*/}
        <BootstrapDialogTitle
          id="dialog"
          onClose={handleClose}
          style={{ color: "primary" }}
        >
          <h1-dialog-title>Editar asegurado</h1-dialog-title>
        </BootstrapDialogTitle>
        {/*CUERPO DEL DIALOGO*/}
        {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
        <form ref={formularioRef} onSubmit={async (e) => {
          try {
            e.preventDefault();
            
            const {
              //Datos del asegurado 
              txtNombre,
              txtApePat,
              txtApeMat,
              txtRFC,
              txtBanco,
              txtCuentaBanc,
              txtClabe,
              txtRazonSocial,
              txtEdadTramite
            } = e.target.elements;

            let jsonAse = {
              PER_nombre: txtNombre.value,
              PER_apellidopaterno: txtApePat.value,
              PER_apellidomaterno: txtApeMat.value,
              PER_RFC: txtRFC.value,
              PER_fechanacimiento: fechaNac,
              PER_edadtramite : txtEdadTramite.value !== "" ? txtEdadTramite.value : 0,
            }


            gardarDatosAsegurado(jsonAse);
          } catch (e) {
            console.error("Error:", e.message)
          }

        }}>
          <DialogContent dividers style={{ minWidth: 500 }}>
            {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
          se necesita colocar entre un typogreaphy las veces que des salto de linea 
          o con un br*/}

            <Grid container direction="row" justifyContent="flex-end" alignItems="center" >
              <Grid item xs={12}><TXTField defaultValue={asegurado?.PER_idpersona?.PER_nombre} label={'Nombre'} name="txtNombre" id="txtNombre" /></Grid>
              <Grid item xs={12}><TXTField defaultValue={asegurado?.PER_idpersona?.PER_apellidopaterno} label={'Apellido paterno'} name="txtApePat" id="txtApePat" /></Grid>
              <Grid item xs={12}><TXTField defaultValue={asegurado?.PER_idpersona?.PER_apellidomaterno} label={'Apellido materno'} name="txtApeMat" id="txtApeMat" /></Grid>
              <Grid item xs={12}><TXTField defaultValue={asegurado?.PER_idpersona?.PER_RFC} label={'RFC'} name="txtRFC" id="txtRFC"/></Grid>
              <Grid item xs={12} style={{ paddingTop: '10px' }}>
                <DatePicker2 id="txtFechaNac" name="txtFechaNac" onDataChange={handleDataChange} fecha={fechaNac} maxDate={today} label={'Fecha de nacimiento'} /></Grid>
              <Grid item xs={12}><TXTField defaultValue={calculateAge(today.toISOString(), asegurado?.PER_idpersona?.PER_fechanacimiento)} label={'Edad actual'}
                value={txtEdadActValue}
              /></Grid>
              <Grid item xs={12}><TXTField defaultValue={asegurado?.PER_edadtramite}
                onChange={(e) => validarEdadTramite(e, 1)}
                onInput={(e) => LimitarDigitos(e.target, 3)}
                error={edadTraError}
                helperText={helperEdadTra}
                label={'Edad al trámite'}
                name="txtEdadTramite"
                id="txtEdadTramite"
              /></Grid>
              <Grid item xs={12}><TXTField defaultValue={asegurado.razonSoc} label={'Razón social'} name="txtRazonSocial" id="txtRazonSocial" /></Grid>
              <Grid item xs={12}><TXTField defaultValue={asegurado?.PER_idpersona?.PER_clabe} label={'Clabe interbancaria'} name="txtClabe" id="txtClabe" onInput={(e) => LimitarDigitos(e.target, 20)}/></Grid>
              <Grid item xs={12}><TXTField defaultValue={asegurado?.PER_idpersona?.PER_numerocuentabancaria} label={'Cuenta bancaria'} name="txtCuentaBanc" id="txtCuentaBanc" onInput={(e) => LimitarDigitos(e.target, 20)}/></Grid>
              <Grid item xs={12}><TXTField defaultValue={asegurado?.PER_idpersona?.banco} label={'Banco'} name="txtBanco" id="txtBanco" /></Grid>
            </Grid>
          </DialogContent>
          {/*es la tercer sección que engloba los botones */}
          <DialogActions>
            <Grid container justifyContent="center" alignItems="center" spacing={2} marginTop={1}>
              <Grid item >
                <ButtonVino estatus={btnGuardar} label='Guardar' type="submit" />
                {!mostrarFeedback ? null : <DialogFeedback
                  open={mostrarFeedback}
                  clickButton={(e) => { 
                    setMostrarFeedback(false)
                    handleClose() 
                  }}
                  title={tituloFeedback}
                  description={descripFeedback}
                  button={"Aceptar"}
                />}
              </Grid>
              <Grid item >
                <ButtonVino
                  variant="contained"
                  label='Cancelar'
                  onClick1={props.cons}
                  onClick={handleClose}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}
