/* 
Pajita
2023
*/
import React, { useState, useEffect } from "react";
//navegador
import Recorrido from "../Tools/BreadCrumbs/Recorrido";
import InputAdornment from "@mui/material/InputAdornment";
//return
import ReturnPage from "../Tools/Bottones/ButtonReturn";
//tiempo
import moment from "moment";
import { formatofecha } from "../../api/getUmbrales";
import DatePicker from "../Registro/Elements/DatePicker/DatepickerSini";
//componentes
import TXTFIELD from "../Tools/TextField/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
//iconos
import Infoinvest from "./Elements/Dialog/Infoinves";
import Docsiniestros from "./Elements/Dialog/Docsiniestros";
import IconMedic from "./Elements/Dialog/IconMedic";
//clave oii
import ButtonVino from "../Tools/Bottones/ButtonVino";

//css
import "../../css/estilo.css";
//idioma
import { FormattedMessage } from "react-intl";
//Grid
import { Grid, IconButton } from "@mui/material";
import { getDataToken, putDataToken, postDataToken } from "../../api/dataToken";
//pdf
import { getFiles, searchFilesByName } from "../../nextcloud/functions";
import { useLocation } from "react-router-dom";
import {
  INVSQL,
  IDMODMEDICO,
  IDALTOII,
  IDDICMED,
  IDDESDOC,
  IDMODDICTAMEN,
} from "../../utils/constanstoken";
import getDate from "../Registro/Function/obtenerFecha";
import getHours from "../Registro/Function/obtenerHora";
import Dialogtwobutton from "./Elements/Dialog/Dialogtwobutton";
import DialogInv from "../Registro/Elements/Dialog/DialogAviso";
import GetTareas from "../../api/getTareas";
import { LimitarTexto } from "../Recepcion/Elements/Functions/Validaciones";
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export function InfoMedic() {
  //Paso de variables de la charola
  const location = useLocation();
  const datoscharola = location.state.selectdatamedic;
  console.log("Estoy en compMédico", datoscharola);
  const [verFolderFolio, setVerFolderFolio] = React.useState(
    datoscharola?.RECT_idfolio?.RECT_numerofolio
  );
  const idFolio = datoscharola?.RECT_idfolio?.RECT_idfolio;
  console.log(idFolio);
  const idPoliza = datoscharola?.POL_idpoliza?.POL_idpoliza;
  console.log(idPoliza);

  //Obtenemos las tareas para nuestro módulo
  const objetosCoincidentes = GetTareas(IDMODMEDICO);
  console.log(objetosCoincidentes);

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");

  console.log(moment(fechaEmpAn).format(formatofecha));
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();

  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;

  //Obtener usuario.
  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");
  const [idTrabajador, setidTrabajador] = React.useState("");
  const getUsers = async () => {
    try {
      var result = await getDataToken(`login/get_correo/${email}/`);
      setidTrabajador(result.id);
      setnombreAnalista(
        result.SNYTLOG_username +
          " " +
          result.SNYTLOG_surnamedad +
          " " +
          result.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  //Traemos todos los registros que coincidan con el folio y póliza
  const [arregloRespuesta, setarregloRespuesta] = useState([]);

  async function getdictamenpolizafolio() {
    var result = await getDataToken(`dictamenpoliza/get_listado/`);
    setarregloRespuesta(
      result.filter(
        (folio) =>
          folio.RECT_idfolio?.RECT_idfolio === idFolio &&
          folio.POL_idpoliza?.POL_idpoliza === idPoliza
      )
    );
  }

  useEffect(() => {
    getdictamenpolizafolio();
  }, [idFolio, idPoliza]);

  console.log(arregloRespuesta);

  //CAMBIAR FORMATO FECHA NACIMIENTO Y SINIESTRO  QUE SE MUESTRAN
  //Aqui obtengo del sus apis respectivas y lo asigno a una variable
  var inpufecha = datoscharola.PER_idpersona?.PER_fechanacimiento;
  //esta misma variable la meto a otra pero la meto con el moment y el format lo obtengo con los umbrales
  //es importante para este punto tener la biblioteca de moment importada y el valor de formato fecha
  var fechanac = moment(inpufecha).format(formatofecha);
  var inputDate = datoscharola.SIN_idsiniestro?.SIN_fechasiniestro;
  var fechasiniestro = moment(inputDate).format(formatofecha);

  // console.log(fechasinies)

  //Diferencia para sacar la edad del cumplañero dinamicamente
  var nacimiento = moment(datoscharola.PER_idpersona?.PER_fechanacimiento);
  var hoy = moment();
  var anios = hoy.diff(nacimiento, "years");

  //Estado para guardar los datos de la poliza
  const [polcob, setpolcob] = useState([]);

  //Get para traer los datos de guardados del dictamen
  async function terceraFuncion() {
    var resu = await getDataToken(
      `coberturaspolizasportramite/get_pol/${idPoliza}/`
    );
    setpolcob(resu);
  }
  //EJECUTAMOSPOLIZACOBERTURAS
  useEffect(() => {
    terceraFuncion();
  }, [idPoliza]);

  console.log(polcob);

  const idCobertura =
    polcob?.length > 0 ? polcob[0]?.COB_idcobertura?.COB_idcobertura : null;

  //Estados para los selects de la clave OII
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedClave, setSelectedClave] = useState(null);
  const [tableData, setTableData] = useState([]);

  //Get para obtener el listado de la clave OII
  const getOII = async () => {
    try {
      const resultID = await getDataToken("catalogoOII/get_listado/");
      setTableData(resultID);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getOII();
  }, []);

  //Para que en el listado de muestre el loading
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions([...tableData]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  // Actualiza el valor del primer Autocomplete cuando se selecciona un valor en el segundo Autocomplete
  useEffect(() => {
    if (selectedClave) {
      const selectedOption = options.find(
        (option) => option.OII_claveoii === selectedClave.OII_claveoii
      );
      if (selectedOption) {
        setSelectedValue(selectedOption);
        const indice = objetosCoincidentes.findIndex(
          (objeto) => objeto.TARE_idtarea.TARE_idtarea === IDALTOII
        );
        //Actualizamos a true
        objetosCoincidentes[indice].estado = true;
      }
    }
  }, [selectedClave, options]);

  // Actualiza el valor del segundo Autocomplete cuando se selecciona un valor en el primer Autocomplete
  useEffect(() => {
    if (selectedValue) {
      const selectedOption = options.find(
        (option) => option.OII_idoii === selectedValue.OII_idoii
      );
      if (selectedOption) {
        setSelectedClave(selectedOption);
        const indice = objetosCoincidentes.findIndex(
          (objeto) => objeto.TARE_idtarea.TARE_idtarea === IDALTOII
        );
        //Actualizamos a true
        objetosCoincidentes[indice].estado = true;
      }
    }
  }, [selectedValue, options]);

  console.log(selectedValue);

  //Funcion para traer los documentos del siniestro
  //Estados para ver archivos del folder
  const [pdfs, setPdfs] = React.useState([]);
  const [btnVerFolder, setBtnVerFolder] = React.useState({
    estatus: false,
    label: "Aceptar",
  });
  const [documents, setDocuments] = React.useState([]);
  const [currentPDF, setCurrentPDF] = React.useState(0);
  const [loadingPDF, setLoadingPDF] = useState(false);

  //Función para traer los documentos
  const verArchivos = async () => {
    const indice = objetosCoincidentes.findIndex(
      (objeto) => objeto.TARE_idtarea.TARE_idtarea === IDDESDOC
    );
    //Actualizamos a true
    objetosCoincidentes[indice].estado = true;
    setBtnVerFolder({
      ...btnVerFolder,
      label: "Buscando información...",
      estatus: true,
    });
    setLoadingPDF(true);
    var response = await getFiles(verFolderFolio);
    if (!response) {
      //Si la respuesta es falsa es porque no existe una carpeta con ese folio
      // alert("No existe carpeta con ese folio");
      setBtnVerFolder({ ...btnVerFolder, label: "Aceptar", estatus: false });
    } else {
      //En caso contrario, revisará que la carpeta tenga archivos para descargarlos, si no, mandar alerta, por ejemplo.
      if (response.length > 0) {
        setBtnVerFolder({ ...btnVerFolder, label: "Aceptar", estatus: false });
        var pdfs = response.filter((file) => file.fileExt === "pdf"); //Filtrar por pdf

        if (pdfs.length > 0) {
          const docs = pdfs.map((e) => e.fileBlob);
          console.log("Docs:", docs);
          setPdfs(docs);
        } else {
          setLoadingPDF(false);
        }
      } else {
        // alert("La carpeta está vacía.");
        setBtnVerFolder({ ...btnVerFolder, label: "Aceptar", estatus: false });
        setLoadingPDF(false);
      }
    }
  };

  //Este use Effect se activará cuando escuche cambios en el pdfs
  React.useEffect(() => {
    if (pdfs.length > 0) {
      setLoadingPDF(false);
      //Verificamos si hay archivos PDFs
      setDocuments([]); //Si los hay actualizamos en estado documentos, seteando un arreglo vacío
      pdfs.forEach((blob) => {
        //Recorremos el arreglo de pdfs
        var pdfUrl = URL.createObjectURL(blob); // Usar el objectUrl para mostrar el PDF en pantalla...
        setDocuments((prevState) => [
          ...prevState, //Obtenemos los valores sin sobreescribirlos,
          <iframe
            src={pdfUrl}
            style={{
              width: "1100px",
              height: "1100px",
              display: "block",
              margin: "auto",
            }}
          />, //Los presentamos en un iframe, por ejemplo
        ]);
        URL.revokeObjectURL(blob); // Luego, al ya no necesitarlo, revocar el objectUrl. Esto ayuda a liberar recursos en la memoria del navegador..
      });
    }
  }, [pdfs]);

  //Ver la información del investigador

  const [pdfsINV, setPdfsINV] = React.useState([]);
  const [btnVerFolderINV, setBtnVerFolderINV] = React.useState({
    estatus: false,
    label: "Aceptar",
  });

  const [documentsINV, setDocumentsINV] = React.useState([]);
  const [currentPDFINV, setCurrentPDFINV] = React.useState(0);
  const [loadingPDFINV, setLoadingPDFINV] = useState(false);

  const verArchivosINV = async () => {
    setBtnVerFolderINV({
      ...btnVerFolderINV,
      label: "Buscando información...",
      estatus: true,
    });
    setLoadingPDFINV(true);
    var response = await searchFilesByName(verFolderFolio, INVSQL);
    if (!response) {
      // alert("No existe carpeta con ese folio");
      setBtnVerFolderINV({
        ...btnVerFolderINV,
        label: "Aceptar",
        estatus: false,
      });
    } else {
      if (response.length > 0) {
        setBtnVerFolderINV({
          ...btnVerFolderINV,
          label: "Aceptar",
          estatus: false,
        });
        var pdfs = response.filter((file) => file.fileExt === "pdf");
        if (pdfs.length > 0) {
          const docs = pdfs.map((e) => e.fileBlob);
          console.log("Docs:", docs);
          setPdfsINV(docs);
        } else {
          setLoadingPDFINV(false);
        }
      } else {
        // alert("La carpeta está vacía.");
        setBtnVerFolderINV({
          ...btnVerFolderINV,
          label: "Aceptar",
          estatus: false,
        });
        setLoadingPDFINV(false);
      }
    }
  };

  //Este use Effect se activará cuando escuche cambios en el pdfs
  React.useEffect(() => {
    if (pdfsINV.length > 0) {
      setLoadingPDFINV(false);
      pdfsINV.forEach((blob) => {
        var pdfUrl = URL.createObjectURL(blob);
        console.log(pdfUrl);
        setDocumentsINV((prevState) => [
          ...prevState,
          <iframe
            src={pdfUrl}
            style={{
              width: "1100px",
              height: "1100px",
              display: "block",
              margin: "auto",
            }}
          />,
        ]);
        URL.revokeObjectURL(blob);
      });
    }
  }, [pdfsINV]);

  //Estados para el get y colocar los datos si ya los traemos
  const [oiis, setoiis] = useState([]);

  const [DICTM_fechainiciosintomas, setDICTMFechaInicioSintomas] =
    useState(null);
  const [DICTM_fechadiagnostico, setDICTM_fechadiagnostico] = useState(null);
  const [guardadoMSG, setguardadoMSG] = useState([]);
  const [guardarDatos, setguardarDatos] = useState([]);
  const [indicaciones, setindicaciones] = useState([]);

  //GET para obtener los datos guardados anteriormente
  const getguardado = async () => {
    try {
      //Resultados de la api investigacion
      var resultID = await getDataToken(
        `dictamenmedicoxpolizacoberturaproducto/get_po/${idPoliza}/`
      );
      setoiis(resultID);

      if (resultID && resultID.length > 0) {
        const fechaInicioSintomas = moment(
          resultID[0]?.DICTM_fechainiciosintomas
        ).format("YYYY-MM-DD");
        setDICTMFechaInicioSintomas(fechaInicioSintomas);
        const fechaDiagnostico = moment(
          resultID[0]?.DICTM_fechadiagnostico
        ).format("YYYY-MM-DD");
        setDICTM_fechadiagnostico(fechaDiagnostico);
      }

      var result = await getDataToken(
        `mensajes/historical_filter/?folio=${idFolio}&poliza=${idPoliza}&modulo=${IDMODMEDICO}`
      );
      setguardadoMSG(result);

      var resultInd = await getDataToken(
        `mensajes/historical_filter/?folio=${idFolio}&poliza=${idPoliza}&modulo=${IDMODDICTAMEN}`
      );
      setindicaciones(resultInd);
    } catch (error) {
      console.error("There was an error:", error);
    }
  };
  useEffect(() => {
    getguardado();
  }, [idPoliza, idFolio]);

  const resultado = guardadoMSG?.reduce((maxFecha, item) => {
    const fecha = new Date(item.MSG_actualizofecha);

    if (!maxFecha || fecha > maxFecha.fecha) {
      return { fecha, elemento: item };
    }

    return maxFecha;
  }, null);

  const elemento = resultado?.elemento;

  console.log(elemento);

  const resultadoind = indicaciones?.reduce((maxFecha, item) => {
    const fecha = new Date(item.MSG_actualizofecha);

    if (!maxFecha || fecha > maxFecha.fecha) {
      return { fecha, elemento: item };
    }

    return maxFecha;
  }, null);

  const elementoind = resultadoind?.elemento;

  console.log(elementoind);

  ///Constantes para el body del json de las apis
  const [values, setValues] = React.useState({
    POL_idpoliza: "",
    PCP_idcobertura: "",
    PCP_idproducto: "",
    OII_idoii: "",
    DICTM_fechainiciosintomas: "",
    DICTM_fechadiagnostico: "",
    DICTM_exclusiones: "",
    DICTM_dictamenmedico: "",
    DICTM_causadelaenfermedad: "",
    DICTM_actualizofecha: "",
    DICTM_fechadictamendico: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  React.useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      DICTM_causadelaenfermedad:
        oiis?.length > 0 ? oiis[0]?.DICTM_causadelaenfermedad : "",
      DICTM_exclusiones: oiis?.length > 0 ? oiis[0]?.DICTM_exclusiones : "",
      DICTM_dictamenmedico: elemento?.MSG_mensaje,
    }));
  }, [oiis, elemento]);

  //Creo variables fuera de la constante para que se puedan utilizar
  const [fechasintomas, setfechasintomas] = useState("");
  const [fechadiagnostico, setfechadiagnostico] = useState("");

  //FUNCION PARA DETECTAR CAMBIOS EN EL DATEPICKER
  const handleDataChange = (newData) => {
    const fecha = newData.toISOString();
    const mexicoCityTime = moment(fecha).tz("America/Mexico_City");
    setfechasintomas(mexicoCityTime.format("YYYY-MM-DD"));
  };
  //FUNCION PARA DETECTAR CAMBIOS EN EL DATEPICKER
  const handleDataChanges = (newData) => {
    const fecha = newData.toISOString();
    const mexicoCityTime = moment(fecha).tz("America/Mexico_City");
    setfechadiagnostico(mexicoCityTime.format("YYYY-MM-DD"));
  };

  const claveOII = oiis?.length > 0 ? oiis[0]?.OII_idoii?.OII_idoii : null;
  const OII_claveoii =
    oiis?.length > 0 ? oiis[0]?.OII_idoii?.OII_claveoii : null;
  const OII_descripcion =
    oiis?.length > 0 ? oiis[0]?.OII_idoii?.OII_descripcion : null;
  const ultimaFecha = elemento?.MSG_actualizofecha;

  console.log(ultimaFecha); // La última fecha en formato Date

  console.log(claveOII);
  console.log(OII_claveoii);
  console.log(OII_descripcion);
  console.log(DICTM_fechainiciosintomas);

  // Asigna el valor predeterminado a selectedValue y selectedClave
  useEffect(() => {
    setSelectedValue(OII_descripcion);
    setSelectedClave(OII_claveoii);
  }, [OII_claveoii, OII_descripcion]);

  //Datos dictamen médico
  console.log("clave oii" + selectedValue?.OII_idoii);
  console.log("inicio sintomas " + fechasintomas);
  console.log("diagnostico " + fechadiagnostico);
  console.log("Causa enfermedad " + values?.DICTM_causadelaenfermedad);
  console.log("exclusiones " + values?.DICTM_exclusiones);
  console.log("Dictamen médico " + values?.DICTM_dictamenmedico);

  async function postMSG(aux) {
    try {
      var result = await postDataToken(`mensajes/get_listado/`, aux);
      console.log("Registro exitoso msg", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  //POST DICTAMEN MÉDICO X POLIZA
  async function postDictamenPoliza(aux) {
    try {
      var result = await postDataToken(
        `dictamenmedicoxpolizacoberturaproducto/get_listado/`,
        aux
      );
      console.log("Registro exitoso", result);
      setguardarDatos((prevElementos) => [...prevElementos, result]);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const [desactive, setdesactive] = useState(false);
  const [guardarRegistros, setguardarRegistros] = useState(false);
  const [activeBTN, setactiveBTN] = useState(false);
  const [guardarcheck, setguardarcheck] = useState(false);
  const [guardarduplica, setguardarduplica] = useState(false);

  //POST y PUT para el botton guardar
  const validateAndSubmitForm = (e) => {
    e.preventDefault();

    const indice = objetosCoincidentes.findIndex(
      (objeto) => objeto.TARE_idtarea.TARE_idtarea === IDDICMED
    );
    //Actualizamos a true
    objetosCoincidentes[indice].estado = true;

    // Filtrar el arreglo para obtener solo los registros con el campo 'estado'.
    const registrosConEstado = objetosCoincidentes.filter(
      (registro) => registro.estado
    );
    console.log(registrosConEstado);

    if (claveOII === null) {
      if (!guardarcheck) {
        const guardarmsg = {
          RECT_idfolio: idFolio,
          POL_idpoliza: idPoliza,
          IDTRABAJADOR: idTrabajador,
          WFM_idwfmodulo: IDMODMEDICO,
          MSG_mensaje: values.DICTM_dictamenmedico,
          MSG_actualizofecha: fechaActSIN,
          MSG_actualizousuario: nombreAnalista,
        };
        console.log(guardarmsg);
        postMSG(guardarmsg);
        for (const itemTra of registrosConEstado) {
          for (const item of polcob) {
            let guardar = {
              RECT_idfolio: item?.RECT_idfolio?.RECT_idfolio,
              PCP_idcobertura: item?.COB_idcobertura?.COB_idcobertura,
              PER_idpersona: datoscharola?.PER_idpersona?.PER_idpersona,
              PCP_idproducto:
                item?.POL_idpoliza?.PRO_idproductos?.PRO_idproducto,
              POL_idpoliza: item?.POL_idpoliza?.POL_idpoliza,
              OII_idoii: selectedValue?.OII_idoii,
              DICTM_fechainiciosintomas: fechasintomas,
              DICTM_fechadiagnostico: fechadiagnostico,
              DICTM_causadelaenfermedad: values.DICTM_causadelaenfermedad,
              DICTM_exclusiones: values.DICTM_exclusiones,
              DICTM_dictamenmedico: values.DICTM_dictamenmedico,
              DICTM_fechadictamendico: fechaActSIN,
              DICTM_actualizofecha: fechaActSIN,
              DICTM_actualizousuario: nombreAnalista,
            };
            postDictamenPoliza(guardar);
            let registro = {
              RECT_idfolio: item?.RECT_idfolio?.RECT_idfolio,
              POL_idpoliza: item?.POL_idpoliza?.POL_idpoliza,
              WTM_idwftareasxmodulos: itemTra.WTM_idwftareasxmodulos,
              PER_idpersona: datoscharola?.PER_idpersona?.PER_idpersona,
              SIN_idsiniestro: datoscharola?.SIN_idsiniestro?.SIN_idsiniestro,
              WKT_fechawktramite: fechaActSIN,
              WKT_seejecuto: itemTra.estado,
              WKT_actualizofecha: fechaActSIN,
              WKT_actualizousuario: nombreAnalista,
            };
            postDataToken(`wktramite/get_listado/`, registro).then((data) => {
              console.log(`Registro exitoso wktramite`, data);
            });
          }
        }
        setguardarRegistros(true);
        setactiveBTN(true);
        setguardarcheck(true);
      } else {
        setguardarduplica(true);
      }
    } else if (claveOII !== null) {
      if (values.DICTM_dictamenmedico !== "") {
        console.log("entro");
        const guardarmsg = {
          RECT_idfolio: idFolio,
          POL_idpoliza: idPoliza,
          IDTRABAJADOR: idTrabajador,
          WFM_idwfmodulo: IDMODMEDICO,
          MSG_mensaje: values.DICTM_dictamenmedico,
          MSG_actualizofecha: fechaActSIN,
          MSG_actualizousuario: nombreAnalista,
        };
        console.log(guardarmsg);
        putDataToken(
          `mensajes/get_id/${guardadoMSG[0]?.MSG_idmensaje}/`,
          guardarmsg
        ).then((data) => {
          console.log(`Registro ${guardadoMSG[0].MSG_idmensaje} actualizado`);
          console.log(data);
        });
      }
      oiis.forEach((datos) => {
        let actualizar = {
          RECT_idfolio: datos?.RECT_idfolio?.RECT_idfolio,
          PCP_idcobertura: datos?.PCP_idcobertura?.COB_idcobertura,
          PER_idpersona: datos?.PER_idpersona?.PER_idpersona,
          PCP_idproducto: datos?.POL_idpoliza?.PRO_idproductos?.PRO_idproducto,
          POL_idpoliza: datos?.POL_idpoliza?.POL_idpoliza,
          OII_idoii:
            selectedValue?.OII_idoii === undefined
              ? claveOII
              : selectedValue?.OII_idoii,
          DICTM_fechainiciosintomas:
            fechasintomas === ""
              ? datos?.DICTM_fechainiciosintomas
              : fechasintomas,
          DICTM_fechadiagnostico:
            fechadiagnostico === ""
              ? datos?.DICTM_fechadiagnostico
              : fechadiagnostico,
          DICTM_causadelaenfermedad:
            values?.DICTM_causadelaenfermedad === ""
              ? datos?.DICTM_causadelaenfermedad
              : values.DICTM_causadelaenfermedad,
          DICTM_exclusiones:
            values.DICTM_exclusiones === ""
              ? datos?.DICTM_exclusiones
              : values.DICTM_exclusiones,
          DICTM_dictamenmedico:
            values.DICTM_dictamenmedico === ""
              ? datos?.DICTM_dictamenmedico
              : values.DICTM_dictamenmedico,
          DICTM_fechadictamendico: fechaActSIN,
          DICTM_actualizofecha: fechaActSIN,
          DICTM_actualizousuario: nombreAnalista,
        };
        putDataToken(
          `dictamenmedicoxpolizacoberturaproducto/get_id/${datos.DICTM_iddictamenmedico}/`,
          actualizar
        ).then((data) => {
          console.log(`Registro ${datos.DICTM_iddictamenmedico} actualizado`);
          console.log(data);
          setguardarDatos((prevElementos) => [...prevElementos, data]);
        });
      });
      setdesactive(true);
      setactiveBTN(true);
    }
  };

  const [activeDictamen, setactiveDictamen] = useState(false);
  const [enviadoyaDictamen, setenviadoyaDictamen] = useState(false);
  const [duenviadoDictamen, setduenviadoDictamen] = useState(false);

  //Enviar a dictamen
  const validateDictamen = (e) => {
    e.preventDefault();
    if (!enviadoyaDictamen) {
      setactiveDictamen(true);
      setenviadoyaDictamen(true);
      //Mandar a dictamen
      guardarDatos.forEach((datos) => {
        let actualizar = {
          OII_idoii: datos?.OII_idoii,
          DICTM_enviodictamen: true,
          DICTM_actualizofecha: fechaActSIN,
          DICTM_actualizousuario: nombreAnalista,
        };
        putDataToken(
          `dictamenmedicoxpolizacoberturaproducto/get_id/${datos.DICTM_iddictamenmedico}/`,
          actualizar
        ).then((data) => {
          console.log(`Registro ${datos.DICTM_iddictamenmedico} actualizado`);
        });
      });
      //Quitar el registro de la charola
      let actualizarcharola = {
        RECT_idfolio: datoscharola?.RECT_idfolio?.RECT_idfolio,
        PER_idpersona: datoscharola?.PER_idpersona?.PER_idpersona,
        SIN_idsiniestro: datoscharola?.SIN_idsiniestro?.SIN_idsiniestro,
        CHMED_entregado: true,
        CHMED_actualizofecha: fechaActSIN,
        CHMED_actualizousuario: nombreAnalista,
      };
      putDataToken(
        `charolamedico/get_id/${datoscharola?.CHMED_idcharolamed}/`,
        actualizarcharola
      ).then((data) => {
        console.log(`Registro ${datoscharola?.CHMED_idcharolamed} actualizado`);
      });
    } else {
      setduenviadoDictamen(true);
    }
  };

  console.log(guardarDatos);

  // Obtiene la longitud combinada de los tres valores
  const combinedLength =
    (datoscharola.PER_idpersona?.PER_nombre?.length || 0) +
    (datoscharola.PER_idpersona?.PER_apellidopaterno?.length || 0) +
    (datoscharola.PER_idpersona?.PER_apellidomaterno?.length || 0);

  // Decide si ocultar algo basado en la longitud combinada
  const shouldHide = combinedLength > 20;

  return (
    <div className="div-parent">
      <div className="div-breadcrumbs">
        {" "}
        {/*navegador*/}
        <Recorrido
          name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
          enlace="/menu"
          name2={<FormattedMessage id="globMedico" defaultMessage="Médico" />}
        />
      </div>
      {guardarRegistros && (
        <DialogInv
          title="Registro exitoso"
          description="La información se ha guardado de manera exitosa"
          firstButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          onClick1={() => setguardarRegistros(false)}
        />
      )}
      {desactive && (
        <DialogInv
          title="Actualización exitosa"
          description="La información se ha actualizado de manera exitosa"
          firstButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          onClick1={() => setdesactive(false)}
        />
      )}
      {guardarduplica && (
        <DialogInv
          title="Error al guardar datos"
          description="La información ya se ha guardado exitosamente."
          firstButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          onClick1={() => setguardarduplica(false)}
        />
      )}
      {activeDictamen && (
        <DialogInv
          title="Envio de información exitosa"
          description="La información se ha enviado a dictamen exitosamente."
          firstButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          onClick1={() => setactiveDictamen(false)}
          href="/medico"
        />
      )}
      {duenviadoDictamen && (
        <DialogInv
          title="Error en envio de información"
          description="La información ya se ha enviado a dictamen exitosamente."
          firstButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          onClick1={() => setduenviadoDictamen(false)}
          href="/medico"
        />
      )}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <ReturnPage href="/medico" />
      </Grid>

      <div className="div-container">
        <h3-header-card>
          <FormattedMessage
            id="medicInAse"
            defaultMessage="Información del asegurado"
          />
        </h3-header-card>
        <br />
        <br />
        <br />
        <Grid container spacing={2}>
          <Grid item xs={2.1}>
            <h3-title-card>
              <FormattedMessage
                id="globNomCom"
                defaultMessage="Nombre Completo"
              />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {datoscharola.PER_idpersona?.PER_nombre}{" "}
              {datoscharola.PER_idpersona?.PER_apellidopaterno}{" "}
              {datoscharola.PER_idpersona?.PER_apellidomaterno}
            </h3-subtitle-card>
            {shouldHide ? (
              <>
                <br />
                <br />
              </>
            ) : (
              <>
                <br />
                <br />
                <br />
              </>
            )}

            <h3-title-card>
              <FormattedMessage id="globRcp" defaultMessage="R.F.C" />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {datoscharola.PER_idpersona?.PER_RFC}
            </h3-subtitle-card>
          </Grid>
          <Grid item xs={2.1}>
            <h3-title-card>
              <FormattedMessage
                id="globFechNa"
                defaultMessage="Fecha de nacimiento"
              />
            </h3-title-card>
            <br />
            <h3-subtitle-card>{fechanac}</h3-subtitle-card>
            <br />
            <br />
            <br />
            <h3-title-card>
              <FormattedMessage id="globCurp" defaultMessage="CURP" />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {datoscharola.PER_idpersona?.PER_CURP}
            </h3-subtitle-card>
          </Grid>
          <Grid item xs={2.1}>
            <h3-title-card>
              <FormattedMessage id="globEdad" defaultMessage="Edad" />
            </h3-title-card>
            <br />
            <h3-subtitle-card>{anios}</h3-subtitle-card>
            <br />
            <br />
            <br />
            <h3-title-card>
              <FormattedMessage
                id="dictFeDeSi"
                defaultMessage="Fecha de Siniestro"
              />
            </h3-title-card>
            <br />
            <h3-subtitle-card>{fechasiniestro}</h3-subtitle-card>
          </Grid>
          <Grid item xs={1.3}>
            <h3-title-card>
              <FormattedMessage id="globSexo" defaultMessage="Sexo" />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {datoscharola.PER_idpersona?.PER_sexo}
            </h3-subtitle-card>
            <br />
            <br />
            <br />
            <h3-title-card>
              <FormattedMessage id="globFolio" defaultMessage="Folio" />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {datoscharola.RECT_idfolio?.RECT_numerofolio}
            </h3-subtitle-card>
          </Grid>
          <Grid item xs={2.2}>
            <br />
            <br />
            <nav>
              <IconButton onClick={() => verArchivosINV()}>
                <Infoinvest
                  button={
                    <FormattedMessage
                      id="globAceptr"
                      defaultMessage="Aceptar"
                    />
                  }
                  title="Investigador"
                  label={btnVerFolderINV.label}
                  description={
                    <div>
                      {loadingPDFINV ? (
                        <p>&nbsp;&nbsp;Cargando PDF...</p>
                      ) : pdfsINV.length > 0 ? (
                        <div>
                          {documentsINV[currentPDFINV]}
                          <div
                            style={{
                              display: "block",
                              margin: "auto",
                              textAlign: "center",
                            }}
                          >
                            <br />
                            <ButtonVino
                              onClick={() =>
                                setCurrentPDFINV(currentPDFINV - 1)
                              }
                              disabled={currentPDFINV === 0}
                              label="Anterior PDF"
                            ></ButtonVino>
                            <ButtonVino
                              onClick={() =>
                                setCurrentPDFINV(currentPDFINV + 1)
                              }
                              disabled={currentPDFINV === pdfsINV.length - 1}
                              label="Siguiente PDF"
                            ></ButtonVino>
                          </div>
                        </div>
                      ) : (
                        <p>&nbsp;&nbsp;No hay PDF's disponibles</p>
                      )}
                    </div>
                  }
                ></Infoinvest>
              </IconButton>
            </nav>
          </Grid>
          <Grid item xs={2.2}>
            <br />
            <br />
            <nav>
              <IconButton onClick={() => verArchivos()}>
                <Docsiniestros
                  button={
                    <FormattedMessage
                      id="globAceptr"
                      defaultMessage="Aceptar"
                    />
                  }
                  title="Siniestro"
                  label={btnVerFolder.label}
                  description={
                    <div>
                      {loadingPDF ? (
                        <p>Cargando PDF...</p>
                      ) : pdfs.length > 0 ? (
                        <div>
                          {documents[currentPDF]}
                          <div
                            style={{
                              display: "block",
                              margin: "auto",
                              textAlign: "center",
                            }}
                          >
                            <br />
                            <ButtonVino
                              onClick={() => setCurrentPDF(currentPDF - 1)}
                              disabled={currentPDF === 0}
                              label="Anterior PDF"
                            />
                            <ButtonVino
                              onClick={() => setCurrentPDF(currentPDF + 1)}
                              disabled={currentPDF === pdfs.length - 1}
                              label="Siguiente PDF"
                            />
                          </div>
                        </div>
                      ) : (
                        <p>No hay PDF's disponibles</p>
                      )}
                    </div>
                  }
                ></Docsiniestros>
              </IconButton>
            </nav>
          </Grid>
        </Grid>
        <br />
        <br />
        <hr />
        <br />
        <h3-header-card>
          <FormattedMessage id="medicDiMed" defaultMessage="Dictamen Médico" />
        </h3-header-card>
        <Grid container spacing={2}>
          <Grid item xs={4.2}>
            <br />
            <br />
            <Autocomplete
              value={selectedValue}
              onChange={(event, value) => setSelectedValue(value)}
              id="asynchronous-demo"
              sx={{ maxWidth: 475 }}
              style={{ position: "sticky" }}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              getOptionLabel={(option) =>
                option.OII_descripcion || selectedValue
              }
              options={options}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <FormattedMessage
                      id="medicDeCla"
                      defaultMessage={
                        claveOII === null
                          ? "Descripción de la clave OII *"
                          : "Descripción de la clave OII"
                      }
                      // defaultMessage="Descripción de la clave OII"
                    />
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <React.Fragment>
                            <CircularProgress color="inherit" size={20} />
                          </React.Fragment>
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            <br />
            <br />
            <Grid container>
              <Grid item xs={5.5}>
                {DICTM_fechainiciosintomas && (
                  <DatePicker
                    fechaSiniestro={DICTM_fechainiciosintomas}
                    titulo={
                      <FormattedMessage
                        id="medicInSin"
                        defaultMessage={
                          claveOII === null
                            ? "Fecha de Inicio de Sintomas *"
                            : "Fecha de Inicio de Sintomas"
                        }
                      />
                    }
                    onDataChange={handleDataChange}
                  />
                )}
                {!DICTM_fechainiciosintomas && (
                  <DatePicker
                    titulo={
                      <FormattedMessage
                        id="medicInSin"
                        defaultMessage={
                          claveOII === null
                            ? "Fecha de Inicio de Sintomas *"
                            : "Fecha de Inicio de Sintomas"
                        }
                      />
                    }
                    onDataChange={handleDataChange}
                  />
                )}
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5.5}>
                {DICTM_fechadiagnostico && (
                  <DatePicker
                    fechaSiniestro={DICTM_fechadiagnostico}
                    titulo={
                      <FormattedMessage
                        id="medicFeDiag"
                        defaultMessage={
                          claveOII === null
                            ? "Fecha de diagnóstico *"
                            : "Fecha de diagnóstico"
                        }
                      />
                    }
                    onDataChange={handleDataChanges}
                  />
                )}
                {!DICTM_fechadiagnostico && (
                  <DatePicker
                    titulo={
                      <FormattedMessage
                        id="medicFeDiag"
                        defaultMessage={
                          claveOII === null
                            ? "Fecha de diagnóstico *"
                            : "Fecha de diagnóstico"
                        }
                      />
                    }
                    onDataChange={handleDataChanges}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2.3}>
            <br />
            <br />
            <Autocomplete
              value={selectedClave} // Establece el valor seleccionado en el segundo Autocomplete
              onChange={(event, value) => setSelectedClave(value)}
              id="asynchronous-demsao"
              sx={{ maxWidth: 235 }}
              style={{ position: "sticky" }}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              getOptionLabel={(option) => option.OII_claveoii || selectedClave}
              options={options}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <FormattedMessage
                      id="SA"
                      defaultMessage={
                        claveOII === null ? "Clave OII *" : "Clave OII"
                      }
                    />
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <React.Fragment>
                            <CircularProgress color="inherit" size={20} />
                          </React.Fragment>
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            <div style={{ marginTop: "20px" }}>
              <TXTFIELD
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Ultima fecha de actualizacion"
                required={false}
                value={
                  oiis?.length > 0
                    ? moment(ultimaFecha).format(formatofecha)
                    : values?.DICTM_actualizofecha
                }
                disabled={true}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ marginTop: "21px" }}>
              <TXTFIELD
                type="text"
                label={
                  <FormattedMessage
                    id="medCausaEnf"
                    defaultMessage={
                      claveOII === null
                        ? "Causa de enfermedad *"
                        : "Causa de enfermedad"
                    }
                  />
                }
                // helperText={<FormattedMessage id="medCausaEnf" defaultMessage="Causa de enfermedad"/>}
                value={values?.DICTM_causadelaenfermedad}
                onChange={
                  handleChange("DICTM_causadelaenfermedad")
                  // setValues(event.target.value)
                }
                InputLabelProps={{ shrink: true }}
                onInput={(e) => LimitarTexto(e.target, 255)}
              />
            </div>
            <div style={{ marginTop: "11px" }}>
              <TXTFIELD
                type="text"
                label={
                  <FormattedMessage
                    id="medicExclu"
                    defaultMessage={
                      claveOII === null ? "Exclusiones *" : "Exclusiones"
                    }
                  />
                }
                //helperText={<FormattedMessage id="medicExclu" defaultMessage="Exclusiones"/>}
                startAdornment={
                  <InputAdornment position="start">.</InputAdornment>
                }
                onChange={handleChange("DICTM_exclusiones")}
                InputLabelProps={{ shrink: true }}
                value={values?.DICTM_exclusiones}
                onInput={(e) => LimitarTexto(e.target, 255)}
              />
            </div>
          </Grid>
          <Grid item xs={2.5}>
            <br />
            <br />
            <br />
            <nav>
              <IconMedic
                mainButton
                button={
                  <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
                }
                description={
                  <TXTFIELD
                    type="text"
                    disabled
                    multiline={true}
                    rows={5}
                    value={
                      elementoind
                        ? elementoind.MSG_mensaje
                        : "Ninguna indicación se ha registrado."
                    }
                  />
                }
                title={
                  <FormattedMessage
                    id="medicInDic"
                    defaultMessage="Indicaciones de dictamen"
                  />
                }
              ></IconMedic>
            </nav>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <div style={{ marginLeft: "17px" }}>
                <TXTFIELD
                  type="text"
                  label={
                    <FormattedMessage
                      id="menuDictam"
                      defaultMessage={
                        claveOII === null ? "Dictamen *" : "Dictamen"
                      }
                    />
                  }
                  //helperText={<FormattedMessage id="menuDictam" defaultMessage="Dictamen"/>}
                  multiline={true}
                  rows={4}
                  maxWidth={100}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange("DICTM_dictamenmedico")}
                  value={values?.DICTM_dictamenmedico}
                  onInput={(e) => LimitarTexto(e.target, 255)}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={"auto"}>
                {/* BT Guardar */}
                {claveOII === null ? (
                  <Dialogtwobutton
                    mainButton="Guardar"
                    title="Confirmar clave OII"
                    description="¿Deseas confirmar la clave Oll?"
                    description1={
                      selectedValue?.OII_claveoii === undefined
                        ? OII_claveoii
                        : selectedValue?.OII_claveoii
                    }
                    firstButton={
                      <FormattedMessage
                        id="globAceptr"
                        defaultMessage="Aceptar"
                      />
                    }
                    secondButton={
                      <FormattedMessage
                        id="globCance"
                        defaultMessage="Cancelar"
                      />
                    }
                    onClick1={validateAndSubmitForm}
                    estatus={
                      selectedValue?.OII_idoii > 0 &&
                      values?.DICTM_causadelaenfermedad != "" &&
                      fechadiagnostico.length > 0 &&
                      fechasintomas.length > 0 &&
                      values?.DICTM_exclusiones != "" &&
                      values?.DICTM_dictamenmedico != ""
                        ? false
                        : true
                    }
                  />
                ) : (
                  <Dialogtwobutton
                    mainButton="Modificar"
                    title="Confirmar clave OII"
                    description="¿Deseas confirmar la clave Oll?"
                    description1={
                      selectedValue?.OII_claveoii === undefined
                        ? OII_claveoii
                        : selectedValue?.OII_claveoii
                    }
                    firstButton={
                      <FormattedMessage
                        id="globAceptr"
                        defaultMessage="Aceptar"
                      />
                    }
                    secondButton={
                      <FormattedMessage
                        id="globCance"
                        defaultMessage="Cancelar"
                      />
                    }
                    onClick1={validateAndSubmitForm}
                    estatus={
                      selectedValue?.OII_idoii > 0 ||
                      values?.DICTM_causadelaenfermedad != "" ||
                      fechadiagnostico.length > 0 ||
                      fechasintomas.length > 0 ||
                      values?.DICTM_exclusiones != "" ||
                      values?.DICTM_dictamenmedico != ""
                        ? false
                        : true
                    }
                  />
                )}
              </Grid>
              <Grid item xs={"auto"}>
                {/* BT enviar */}
                <Dialogtwobutton
                  mainButton="Enviar a dictamen"
                  title="Observaciones"
                  description="¿Deseas confirmar esta operación?"
                  firstButton={
                    <FormattedMessage
                      id="globAceptr"
                      defaultMessage="Aceptar"
                    />
                  }
                  secondButton={
                    <FormattedMessage
                      id="globCance"
                      defaultMessage="Cancelar"
                    />
                  }
                  onClick1={validateDictamen}
                  estatus={activeBTN ? false : true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={"auto"}>
                {/* BT Cancelar*/}
                <ButtonVino
                  label={
                    <FormattedMessage
                      id="globCance"
                      defaultMessage="Cancelar"
                    />
                  }
                  enlace="/medico"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
