import { Botones } from "../pages/components/Botonespage";
import  {CompLayaout} from "../layouts/components/CompLayaout";
import { Barras } from "../pages/components/Barraspage";
import { DateBrea } from "../pages/components/DateBreaRa"
import { Typo } from '../pages/components/Typograf';



const routesComponents = [
    {
        path:"/comp/botones",
        layout:CompLayaout,
        component:Botones,
        exact:true,
    },
    {
        path:"/comp/barras",
        layout:CompLayaout,
        component:Barras,
        exact:true,
    },
    {
        path:"/comp/text",
        layout:CompLayaout,
        component:Barras,
        exact:true,
    },
    {
        path:"/comp/nav",
        layout:CompLayaout,
        component:DateBrea,
        exact:true,
    },
    {
        path:"/comp/typo",
        layout:CompLayaout,
        component:Typo,
        exact:true,
    },
];

export default routesComponents;