import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Select1 } from "../../Tools/Selectss/Select1";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../../Tools/Table/StyledTableCell";
//paginación de la tabla
import TablePagination from "@mui/material/TablePagination";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
//CheckBox
import CheckGroup from "../../Tools/CheckBox/CheckGroup";
//Dialogo
import DialogButton from "../../Tools/Dialog/DialogButton";
import DialogColumna from "../../Contable/Dialogs/DialogColumna";
import DialogButtons from "../../Tools/Dialog/DialogButtons";
//exportExcel
import { downloadExcel } from "react-export-table-to-excel";
//importaciones de estadisticas
import GraficoEstatus from "../Graficos/GraficoEstatus";
//Idioma
import { FormattedMessage } from "react-intl";
//Datos JSON
import data from "../data.json";
import DatePicker from "../../Tools/DatePicker/DatePicker"
import moment from "moment-timezone";


export default function StatusTable() {

  const [status, setStatus] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = require("../data.json");
        console.log("Datos obtenidos:", response);
        setStatus(response);
        console.log(status)
      } catch (error) {
        console.error("Error al obtener el archivo JSON:", error);
      }      
    };

    fetchData();
  }, []); 
 
  //creación del encabezado para el botón de descargas 
  const header = [
    "ID",
    "NÚMERO DE FOLIO",
    "NUMERO DE SINIESTRO",
    "NÚMERO DE PÓLIZA",
    "FECHA DE AVISO",
    "FECHA DE OCURRENCIA",
    "NOMBRE DEL ASEGURADO",
    "CLAVE OII",
    "DESCRIPCION CLAVE OII ",
    "ESTATUS DEL SINIESTRO",
    "SUMA ASEGURADA BÁSICO",
    "MONEDA SUMA ASEGURADA BÁSICA",
    "SUMA ASEGURADA BENEFICIO MUERTE ACCIDENTAL",
    "MONEDA SUMA ASEGURADA BENEFICIO MUERTE ACCIDENTAL",
    "TIPO DE SINIESTRO",
    "TIPO DE SINIESTRO 2",
    "TIPO DE SINIESTRO 4",
    "TIPO DE SINIESTRO 5",
    "CAUSA DEL SINIESTRO",
    "TIPO DE FALLECIMIENTO (TIPO DE SINIESTRO 3)",
    "CIUDAD DE OCURRENCIA",
    "PAIS DE OCURRENCIA",
    "TIPO DE HOMICIDIO",
    "SEXO DEL ASEGURADO",
    "EDAD DEL ASEGURADO",
    "NOMBRE DEL INVESTIGADOR",
    "ESTATUS DE LA INVESTIGACION",
    "MONTO DE PAGO DE LA INVESTIGACIÓN",
    "FECHA DE PAGO DE LA INVESTIGACIÓN",
    "FECHA DE PAGO",
    "MONTO DE PAGO",
    "MONEDA DEL PAGO",
    "MEDIO DE PAGO",
    "FECHA DE VENCIMIENTO DE LA PÓLIZA",
    "NÚMERO DE CUENTA CONTABLE",
    "FECHA DE REGISTRO CONTABLE",
    "IMPORTE DEL REGISTRO CONTABLE",
    "NATURALEZA DEL MOVIMIENTO ( 1 o 2 )",
    "ID DE INTEGRACION",
    "ESTATUS DE LA PÓLIZA VERSION 1",
    "NOMBRE DE BENEFICIARIOS",
    "BENEFICIO ADICIONAL",
    "MONTO DE SA DE BENEFICIO ADICIONAL",
    "MONEDA DEL BENEFICIO ADICIONAL",
    "BENEFICIARIO DEL PAGO",
    "CUENTA BANCARIA DEL BENEFICIARIO",
    "CUENTA DE CARGO ORIGEN DEL PAGO",
    "BANCO DE LA CUENTA BANCARIA",
    "FECHA DE EMISIÓN DE LA PÓLIZA",
    "CANAL ( DE DISTRIBUCIÓN)",
    "CLAVE DE AGENTE",
    "DA AGENCIA, PROMOTORÍA",
    "DISPUTABLE (SI) (NO)"
  ];

  const[showdescripcionOil, setShowdescripcionOil] = useState(false)
  const[showsumaAseguradaBeneficioMuerteAccidental, setShowsumaAseguradaBeneficioMuerteAccidental] = useState(false)
  const[showtipoSiniestro, setShowtipoSiniestro] = useState(false)
  const[showtipoSiniestro2, setShowtipoSiniestro2] = useState(false)
  const[showtipoSiniestro4, setShowtipoSiniestro4] = useState(false)
  const[showtipoSiniestro5, setShowtipoSiniestro5] = useState(false)
  const[showcausaSiniestro, setShowcausaSiniestro] = useState(false)
  const[showtipoDeFallecimiento, setShowtipoDeFallecimiento] = useState(false)
  const[showciudadOcurrencia, setShowciudadOcurrencia] = useState(false)
  const[showpaisOcurrencia, setShowpaisOcurrencia] = useState(false)
  const[showtipoHomicidio, setShowtipoHomicidio] = useState(false)
  const[showsexoAsegurado, setShowsexoAsegurado] = useState(false)
  const[showedadAsegurado, setShowedadAsegurado] = useState(false)
  const[shownombreInvestigador, setShownombreInvestigador] = useState(false)
  const[showestatusInvestigacion, setShowestatusInvestigacion] = useState(false)
  const[showmontoDePagoDeLaInvestigacion, setShowmontoDePagoDeLaInvestigacion] = useState(false)
  const[showfechaDePagoDeLaInvestigación, setShowfechaDePagoDeLaInvestigación] = useState(false)
  const[showfechaPago, setShowfechaPago] = useState(false)
  const[showmontoPago, setShowmontoPago] = useState(false)
  const[showmonedaPago, setShowmonedaPago] = useState(false)
  const[showmedioPago, setShowmedioPago] = useState(false)
  const[showfechaDeVencimientoDeLaPoliza, setShowfechaDeVencimientoDeLaPoliza] = useState(false)
  const[shownumeroContable, setShownumeroContable] = useState(false)
  const[showfechaContable, setShowfechaContable] = useState(false)
  const[showimporteContable, setShowimporteContable] = useState(false)
  const[shownaturalezaDelMovimiento, setShownaturalezaDelMovimiento] = useState(false)
  const[showidIntegracion, setShowidIntegracion] = useState(false)
  const[showestatusPoliza1, setShowestatusPoliza1] = useState(false)
  const[shownombreBeneficiarios, setShownombreBeneficiarios] = useState(false)
  const[showbeneficioAdicional, setShowbeneficioAdicional] = useState(false)
  const[showmontoSumaAseguradaBeneficioAdicional, setShowmontoSumaAseguradaBeneficioAdicional] = useState(false)
  const[showmonedaAdicional, setShowmonedaAdicional] = useState(false)
  const[showbeneficiarioPago, setShowbeneficiarioPago] = useState(false)
  const[showcuentaBeneficiario, setShowcuentaBeneficiario] = useState(false)
  const[showcuentaDeCargoOrigenDelPago, setShowcuentaDeCargoOrigenDelPago] = useState(false)
  const[showbancoCuentaBancaria, setShowbancoCuentaBancaria] = useState(false)
  const[showfechaDeEmisiónDeLaPoliza, setShowfechaDeEmisiónDeLaPoliza] = useState(false)
  const[showcanalDistribucion, setShowcanalDistribucion] = useState(false)
  const[showclaveAgente, setShowclaveAgente] = useState(false)
  const[showagenciaPromotoria, setShowagenciaPromotoria] = useState(false)
  const[showdisputable, setShowdisputable] = useState(false)

  //Constante para habilitar el boton de buscar al seleccionar una opción
  const [btnDisableBuscar, setBtnDisableBuscar] = useState(true);
  //Constante de boton deshabilitado de Descarga y Estadistica
  const [btnDisable, setBtnDisable] = useState(true);

  /* CONSTANTES DE ESTADOS*/
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10
  //ejemplo
  const [selectedEstatus, setSelectedEstatus] = useState(""); // Default value is "0".

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

 //Activar el boton de Buscar
 const handleClickBuscar = () => {
  setBtnDisableBuscar(false);
};

  //Funcion para Descargar excel
  function handleDownloadExcel() {
    downloadExcel({
      fileName: "react-export-table-to-excel -> downloadExcel method",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,//estos header pertenecen al encabezado que nosotros les dimos
        body: data //en body se asigna lo que se va a imprimir, en este caso asinamos a row ya que es el arreglo que //contiene toda la información que va en la tabla
      },
    });
  }

  //Arreglo para el estatus
  const estatus = [
    { identificador: "Pagado total", texto: "Pagado total" },
    { identificador: "Pagado parcial", texto: "Pagado parcial" },
    { identificador: "Proceso", texto: "Proceso" },
    { identificador: "Rechazo", texto: "Rechazo" },
    { identificador: "Rescisión", texto: "Rescisión" },
    { identificador: "Cancelada", texto: "Cancelada" },
    { identificador: "Activado", texto: "Activado" },
    { identificador: "Todos", texto: "Todos" },
  ];


    //Funcion para habilitar el boton al seleccionar una opción
    const handleOptionBtnBuscar = (e) => {
      setSelectedEstatus(e);
      console.log(selectedEstatus);
    }

//Constantes para variables de la fecha 
const [inicioVencimiento, setInicioVencimiento] = useState("");
const [finVencimiento, setFinVencimiento] = useState("");
const [filteredData, setFilteredData] = useState([]);
//Creación de variables para que pueda funcionar el Date 
 let fechaInicioVencimiento = null;
 let fechaFinVencimiento = null;

  const handleDataChanges = (newData) => {
    const fechaVencimiento = newData.toISOString();
    const mexicoCityTime = moment(fechaVencimiento).tz("America/Mexico_City");
    const fechasini = mexicoCityTime.format("YYYY-MM-DD");
    setInicioVencimiento(fechasini);
  }
  const handleDataChangesDos = (newData) => {
    const fechaVencimiento = newData.toISOString();
    const mexicoCityTime = moment(fechaVencimiento).tz("America/Mexico_City");
    const fechasini = mexicoCityTime.format("YYYY-MM-DD");
    setFinVencimiento(fechasini);
  }

  const handleClickDescargar = () => {
    setInicioVencimiento(fechaInicioVencimiento);
    setFinVencimiento(fechaFinVencimiento);
    console.log(inicioVencimiento);
    console.log(finVencimiento);
    console.log(estatus)
   
    if(inicioVencimiento && finVencimiento){
      const primerFecha = new Date(inicioVencimiento)
      const segundaFecha = new Date(finVencimiento)
      //filtaremos los datos en las fechas seleccionadas 
      const filteredResults = status.filter((item) => {
        const itemDate = new Date(item.fechaAviso); // Asume que los datos tienen una propiedad 'date' con un valor de fecha
        const arreglo = (item.estatusSiniestro)
        console.log(arreglo)
        console.log(selectedEstatus)
        return (
          itemDate >= primerFecha &&
          itemDate <= segundaFecha &&
          arreglo == selectedEstatus          
        );
      });
      setFilteredData(filteredResults);
      console.log(filteredResults);
      setBtnDisable(false)
    } else {
      console.log("Por favor, selecciona una fecha inicial y una fecha final.");
    }
      
  };

  return (
    <div>
  <Grid container justifyContent="center">
          <Grid item>
            <h3-header-card>
              <FormattedMessage id="estatus" defaultMessage="REPORTE DE ESTATUS"/>
            </h3-header-card>
          </Grid>
          </Grid>
      <br/>         
      
        {/* Columna de texto */}
          <Grid container spacing={5}>
          <Grid item xs={3}>
            <h3-subtitle-card>
              <FormattedMessage id="estFechIn" defaultMessage="Fecha de inicio"/>
            </h3-subtitle-card>
          </Grid>
          <Grid item xs={3}>
            <h3-subtitle-card>
              <FormattedMessage id="estFechFin" defaultMessage="Fecha final"/>
            </h3-subtitle-card>
          </Grid>

          <Grid item xs={3}>
            <h3-subtitle-card>
              <FormattedMessage id="Sts" defaultMessage="Estatus"/>
            </h3-subtitle-card>
          </Grid>
          <Grid item xs={3}>
          </Grid>
          </Grid>

        {/* Columna de los DataPicker */}
        <Grid container spacing={5}>
          <Grid item xs={3}>
            <DatePicker
              fecha={fechaInicioVencimiento}
              onDataChange={handleDataChanges}
            />
          </Grid>

          <Grid item xs={3}>          
            <DatePicker
              fecha={fechaFinVencimiento}
              onDataChange={handleDataChangesDos}
            />
        </Grid>
      
          <Grid item xs={3} spacing={5} style={{paddingTop: '11px'}}>
          <Select1 
          array={estatus} 
          style ={{marginTop:'0px'}}          
          onChange={(e)=> handleOptionBtnBuscar(e.target.value)}
           />          

          </Grid>   

        <Grid style={{paddingTop: '40px'}}
         item xs={3}>
        <ButtonVino 
            label="Buscar"
            onClick={handleClickDescargar}/>
          </Grid>
       </Grid>
    <br></br>
      <br />
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 540 }} style={{ position: "sticky" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              {/*Encabezado dinamico*/} 
              <TableRow>
                  <StyledTableCell> Número de folio </StyledTableCell>
                  <StyledTableCell> Número de siniestro  </StyledTableCell>
                  <StyledTableCell> Número de póliza </StyledTableCell>
                  <StyledTableCell> Fecha de aviso </StyledTableCell>
                  <StyledTableCell> Fecha de ocurrencia </StyledTableCell>
                  <StyledTableCell> Nombre asegurado </StyledTableCell>
                  <StyledTableCell> Clave OIl </StyledTableCell>              
                  <StyledTableCell> Estatus del siniestro</StyledTableCell>
                  <StyledTableCell> Suma asegurada Básica</StyledTableCell>     
                  <StyledTableCell> Moneda suma asegurada básica</StyledTableCell> 
                  <StyledTableCell> Moneda suma asegurada básica beneficio muerte accidental</StyledTableCell>           
              {/* Ponemos en condicion los chechk ya que dependiendo el estado en el que se esncuentren
                  se podrán ver las columnas o se ocultarán */} 
                  {!showdescripcionOil ? null: <StyledTableCell> Descripcion clave oil  </StyledTableCell>}
                  {!showsumaAseguradaBeneficioMuerteAccidental ? null: <StyledTableCell> Suma asegurada beneficio muerte accidental </StyledTableCell>}
                  {!showtipoSiniestro ? null: <StyledTableCell> Tipo de siniestro </StyledTableCell>}
                  {!showtipoSiniestro2 ? null: <StyledTableCell> Tipo de siniestro 2 </StyledTableCell>}
                  {!showtipoSiniestro4 ? null: <StyledTableCell> Tipo de siniestro 4 </StyledTableCell>}
                  {!showtipoSiniestro5 ? null: <StyledTableCell> Tipo de siniestro 5 </StyledTableCell>}
                  {!showcausaSiniestro ? null: <StyledTableCell> Causa del siniestro </StyledTableCell>}
                  {!showtipoDeFallecimiento ? null: <StyledTableCell> Tipo de fallecimiento (tipo de siniestro 3) </StyledTableCell>}
                  {!showciudadOcurrencia ? null: <StyledTableCell> Ciudad de ocurrencia </StyledTableCell>}
                  {!showpaisOcurrencia ? null: <StyledTableCell> Pais de ocurrencia </StyledTableCell>}
                  {!showtipoHomicidio ? null: <StyledTableCell> Tipo de homicidio </StyledTableCell>}
                  {!showsexoAsegurado ? null: <StyledTableCell> Sexo del asegurado </StyledTableCell>}
                  {!showedadAsegurado ? null: <StyledTableCell> Edad del asegurado </StyledTableCell>}
                  {!shownombreInvestigador ? null: <StyledTableCell> Nombre del investigador </StyledTableCell>}
                  {!showestatusInvestigacion ? null: <StyledTableCell> Estatus de la investigacion </StyledTableCell>}
                  {!showmontoDePagoDeLaInvestigacion ? null: <StyledTableCell> Monto de pago de la investigación </StyledTableCell>}
                  {!showfechaDePagoDeLaInvestigación ? null: <StyledTableCell> Fecha de pago de la investigación </StyledTableCell>}
                  {!showfechaPago ? null: <StyledTableCell> Fecha de pago </StyledTableCell>}
                  {!showmontoPago ? null: <StyledTableCell> Monto de pago </StyledTableCell>}
                  {!showmonedaPago ? null: <StyledTableCell> Moneda del pago </StyledTableCell>}
                  {!showmedioPago ? null: <StyledTableCell> Medio de pago </StyledTableCell>}
                  {!showfechaDeVencimientoDeLaPoliza ? null: <StyledTableCell> Fecha de vencimiento de la póliza </StyledTableCell>}
                  {!shownumeroContable ? null: <StyledTableCell> Número de cuenta contable </StyledTableCell>}
                  {!showfechaContable ? null: <StyledTableCell> Fecha de registro contable </StyledTableCell>}
                  {!showimporteContable ? null: <StyledTableCell> Importe del registro contable </StyledTableCell>}
                  {!shownaturalezaDelMovimiento ? null: <StyledTableCell> Naturaleza del movimiento ( 1 o 2 ) </StyledTableCell>}
                  {!showidIntegracion ? null: <StyledTableCell> Id de integracion </StyledTableCell>}
                  {!showestatusPoliza1 ? null: <StyledTableCell> Estatus de la póliza version 1 </StyledTableCell>}
                  {!shownombreBeneficiarios ? null: <StyledTableCell> Nombre de beneficiarios </StyledTableCell>}
                  {!showbeneficioAdicional ? null: <StyledTableCell> Beneficio adicional </StyledTableCell>}
                  {!showmontoSumaAseguradaBeneficioAdicional ? null: <StyledTableCell> Monto de sa de beneficio adicional </StyledTableCell>}
                  {!showmonedaAdicional ? null: <StyledTableCell> Moneda del beneficio adicional </StyledTableCell>}
                  {!showbeneficiarioPago ? null: <StyledTableCell> Beneficiario del pago </StyledTableCell>}
                  {!showcuentaBeneficiario ? null: <StyledTableCell> Cuenta bancaria del beneficiario </StyledTableCell>}
                  {!showcuentaDeCargoOrigenDelPago ? null: <StyledTableCell> Cuenta de cargo origen del pago </StyledTableCell>}
                  {!showbancoCuentaBancaria ? null: <StyledTableCell> Banco de la cuenta bancaria </StyledTableCell>}
                  {!showfechaDeEmisiónDeLaPoliza ? null: <StyledTableCell> Fecha de emisión de la póliza </StyledTableCell>}
                  {!showcanalDistribucion ? null: <StyledTableCell> Canal ( de distribución) </StyledTableCell>}
                  {!showclaveAgente ? null: <StyledTableCell> Clave de agente </StyledTableCell>}
                  {!showagenciaPromotoria ? null: <StyledTableCell> Da agencia, promotoría </StyledTableCell>}
                  {!showdisputable ? null: <StyledTableCell> Disputable (si) (no) </StyledTableCell>}
              </TableRow>
            </TableHead>

            {filteredData.length > 0 && (
           <TableBody>
          {filteredData.filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                    val.estatuSiniestro.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          
          .map(item => (
            <TableRow key={item.fechaAviso}>
              <StyledTableCell align="center"> {item.numFolio} </StyledTableCell>
                      <StyledTableCell align="center"> {item.numSiniestro} </StyledTableCell>
                      <StyledTableCell align="center"> {item.NumPoliza} </StyledTableCell>
                      <StyledTableCell align="center"> {item.fechaAviso} </StyledTableCell>
                      <StyledTableCell align="center"> {item.fechaOcurrencia} </StyledTableCell>
                      <StyledTableCell align="center"> {item.nombreAsegurado} </StyledTableCell>
                      <StyledTableCell align="center"> {item.claveOil} </StyledTableCell>
                      <StyledTableCell align="center"> {item.estatusSiniestro} </StyledTableCell>                   
                      <StyledTableCell align="center"> {item.sumaAseguradaBasica} </StyledTableCell>  
                      <StyledTableCell align="center"> {item.monedaSumaAseguradaBasica} </StyledTableCell>
                      <StyledTableCell align="center"> {item.monedaSumaAseguradaBeneficioMuerteAccidental} </StyledTableCell>
                      {!showdescripcionOil ? null:<StyledTableCell align="center"> {item.descripcionOil} </StyledTableCell>}
                      {!showsumaAseguradaBeneficioMuerteAccidental ? null:<StyledTableCell align="center"> {item.sumaAseguradaBeneficioMuerteAccidental} </StyledTableCell>}
                      {!showtipoSiniestro ? null:<StyledTableCell align="center"> {item.tipoSiniestro} </StyledTableCell>}
                      {!showtipoSiniestro2 ? null:<StyledTableCell align="center"> {item.tipoSiniestro2} </StyledTableCell>}
                      {!showtipoSiniestro4 ? null:<StyledTableCell align="center"> {item.tipoSiniestro4} </StyledTableCell>}
                      {!showtipoSiniestro5 ? null:<StyledTableCell align="center"> {item.tipoSiniestro5} </StyledTableCell>}
                      {!showcausaSiniestro ? null:<StyledTableCell align="center"> {item.causaSiniestro} </StyledTableCell>}
                      {!showtipoDeFallecimiento ? null:<StyledTableCell align="center"> {item.tipoDeFallecimiento} </StyledTableCell>}
                      {!showciudadOcurrencia ? null:<StyledTableCell align="center"> {item.ciudadOcurrencia} </StyledTableCell>}
                      {!showpaisOcurrencia ? null:<StyledTableCell align="center"> {item.paisOcurrencia} </StyledTableCell>}
                      {!showtipoHomicidio ? null:<StyledTableCell align="center"> {item.tipoHomicidio} </StyledTableCell>}
                      {!showsexoAsegurado ? null:<StyledTableCell align="center"> {item.sexoAsegurado} </StyledTableCell>}
                      {!showedadAsegurado ? null:<StyledTableCell align="center"> {item.edadAsegurado} </StyledTableCell>}
                      {!shownombreInvestigador ? null:<StyledTableCell align="center"> {item.nombreInvestigador} </StyledTableCell>}
                      {!showestatusInvestigacion ? null:<StyledTableCell align="center"> {item.estatusInvestigacion} </StyledTableCell>}
                      {!showmontoDePagoDeLaInvestigacion ? null:<StyledTableCell align="center"> {item.montoDePagoDeLaInvestigacion} </StyledTableCell>}
                      {!showfechaDePagoDeLaInvestigación ? null:<StyledTableCell align="center"> {item.fechaDePagoDeLaInvestigación} </StyledTableCell>}
                      {!showfechaPago ? null:<StyledTableCell align="center"> {item.fechaPago} </StyledTableCell>}
                      {!showmontoPago ? null:<StyledTableCell align="center"> {item.montoPago} </StyledTableCell>}
                      {!showmonedaPago ? null:<StyledTableCell align="center"> {item.monedaPago} </StyledTableCell>}
                      {!showmedioPago ? null:<StyledTableCell align="center"> {item.medioPago} </StyledTableCell>}
                      {!showfechaDeVencimientoDeLaPoliza ? null:<StyledTableCell align="center"> {item.fechaDeVencimientoDeLaPoliza} </StyledTableCell>}
                      {!shownumeroContable ? null:<StyledTableCell align="center"> {item.numeroContable} </StyledTableCell>}
                      {!showfechaContable ? null:<StyledTableCell align="center"> {item.fechaContable} </StyledTableCell>}
                      {!showimporteContable ? null:<StyledTableCell align="center"> {item.importeContable} </StyledTableCell>}
                      {!shownaturalezaDelMovimiento ? null:<StyledTableCell align="center"> {item.naturalezaDelMovimiento} </StyledTableCell>}
                      {!showidIntegracion ? null:<StyledTableCell align="center"> {item.idIntegracion} </StyledTableCell>}
                      {!showestatusPoliza1 ? null:<StyledTableCell align="center"> {item.estatusPoliza1} </StyledTableCell>}
                      {!shownombreBeneficiarios ? null:<StyledTableCell align="center"> {item.nombreBeneficiarios} </StyledTableCell>}
                      {!showbeneficioAdicional ? null:<StyledTableCell align="center"> {item.beneficioAdicional} </StyledTableCell>}
                      {!showmontoSumaAseguradaBeneficioAdicional ? null:<StyledTableCell align="center"> {item.montoSumaAseguradaBeneficioAdicional} </StyledTableCell>}
                      {!showmonedaAdicional ? null:<StyledTableCell align="center"> {item.monedaAdicional} </StyledTableCell>}
                      {!showbeneficiarioPago ? null:<StyledTableCell align="center"> {item.beneficiarioPago} </StyledTableCell>}
                      {!showcuentaBeneficiario ? null:<StyledTableCell align="center"> {item.cuentaBeneficiario} </StyledTableCell>}
                      {!showcuentaDeCargoOrigenDelPago ? null:<StyledTableCell align="center"> {item.cuentaDeCargoOrigenDelPago} </StyledTableCell>}
                      {!showbancoCuentaBancaria ? null:<StyledTableCell align="center"> {item.bancoCuentaBancaria} </StyledTableCell>}
                      {!showfechaDeEmisiónDeLaPoliza ? null:<StyledTableCell align="center"> {item.fechaDeEmisiónDeLaPoliza} </StyledTableCell>}
                      {!showcanalDistribucion ? null:<StyledTableCell align="center"> {item.canalDistribucion} </StyledTableCell>}
                      {!showclaveAgente ? null:<StyledTableCell align="center"> {item.claveAgente} </StyledTableCell>}
                      {!showagenciaPromotoria ? null:<StyledTableCell align="center"> {item.agenciaPromotoria} </StyledTableCell>}
                      {!showdisputable ? null:<StyledTableCell align="center"> {item.disputable} </StyledTableCell>}
            </TableRow>
          ))}
        </TableBody>
      )}
          </Table>
        </TableContainer>
        <TablePagination
          count={data.length}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelRowsPerPage="Filas por página"
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* Separamos los botones del contenido ya que el contenedor de los botones debe de llevar diferente espacio para estos */}
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <br />
          {/* Con el metodo handleDownloadExce al dar click en este botón se activara esta Función
              y se descarga la tabla en excel */}
          <ButtonVino
          label="Descargar"
          onClick={handleDownloadExcel}
          estatus={btnDisable} />
        </Grid>
        <Grid item>
          <br />
          <DialogButtons
            mainButton="Estadística"//Nombremos el botón del dialogo
            title="Estadísticas por estatus"//asignamos el nombre del dialogo
            description={<GraficoEstatus/>}//Pasamos el archivo en donde tenemos la grafica para esta tabla mediante una etiqueta
            firstButton="Aceptar"
            secondButton="Cancelar"
            estatus={btnDisable}
          />
        </Grid>
        <Grid item>
          <br />
          <DialogColumna
            mainButton="Agregar o Quitar campos"
            title="Agregar o Quitar columnas"
            description="Marca o Desmarca las columnas que desea mostrar."
            description9={<CheckGroup checked={showdescripcionOil} onChange={ (e)=> setShowdescripcionOil(e.target.checked)} label = "Descripción Clave Oil "/>}
            description13={<CheckGroup checked={showsumaAseguradaBeneficioMuerteAccidental} onChange={ (e)=> setShowsumaAseguradaBeneficioMuerteAccidental(e.target.checked)} label = "Suma Asegurada Beneficio Muerte Accidental"/>}
            description15={<CheckGroup checked={showtipoSiniestro} onChange={ (e)=> setShowtipoSiniestro(e.target.checked)} label = "Tipo De Siniestro"/>}
            description16={<CheckGroup checked={showtipoSiniestro2} onChange={ (e)=> setShowtipoSiniestro2(e.target.checked)} label = "Tipo De Siniestro 2"/>}
            description17={<CheckGroup checked={showtipoSiniestro4} onChange={ (e)=> setShowtipoSiniestro4(e.target.checked)} label = "Tipo De Siniestro 4"/>}
            description18={<CheckGroup checked={showtipoSiniestro5} onChange={ (e)=> setShowtipoSiniestro5(e.target.checked)} label = "Tipo De Siniestro 5"/>}
            description19={<CheckGroup checked={showcausaSiniestro} onChange={ (e)=> setShowcausaSiniestro(e.target.checked)} label = "Causa Del Siniestro"/>}
            description20={<CheckGroup checked={showtipoDeFallecimiento} onChange={ (e)=> setShowtipoDeFallecimiento(e.target.checked)} label = "Tipo De Fallecimiento (Tipo De Siniestro 3)"/>}
            description21={<CheckGroup checked={showciudadOcurrencia} onChange={ (e)=> setShowciudadOcurrencia(e.target.checked)} label = "Ciudad De Ocurrencia"/>}
            description22={<CheckGroup checked={showpaisOcurrencia} onChange={ (e)=> setShowpaisOcurrencia(e.target.checked)} label = "Pais De Ocurrencia"/>}
            description23={<CheckGroup checked={showtipoHomicidio} onChange={ (e)=> setShowtipoHomicidio(e.target.checked)} label = "Tipo De Homicidio"/>}
            description24={<CheckGroup checked={showsexoAsegurado} onChange={ (e)=> setShowsexoAsegurado(e.target.checked)} label = "Sexo Del Asegurado"/>}
            description25={<CheckGroup checked={showedadAsegurado} onChange={ (e)=> setShowedadAsegurado(e.target.checked)} label = "Edad Del Asegurado"/>}
            description26={<CheckGroup checked={shownombreInvestigador} onChange={ (e)=> setShownombreInvestigador(e.target.checked)} label = "Nombre Del Investigador"/>}
            description27={<CheckGroup checked={showestatusInvestigacion} onChange={ (e)=> setShowestatusInvestigacion(e.target.checked)} label = "Estatus De La Investigacion"/>}
            description28={<CheckGroup checked={showmontoDePagoDeLaInvestigacion} onChange={ (e)=> setShowmontoDePagoDeLaInvestigacion(e.target.checked)} label = "Monto De Pago De La Investigación"/>}
            description29={<CheckGroup checked={showfechaDePagoDeLaInvestigación} onChange={ (e)=> setShowfechaDePagoDeLaInvestigación(e.target.checked)} label = "Fecha De Pago De La Investigación"/>}
            description30={<CheckGroup checked={showfechaPago} onChange={ (e)=> setShowfechaPago(e.target.checked)} label = "Fecha De Pago"/>}
            description31={<CheckGroup checked={showmontoPago} onChange={ (e)=> setShowmontoPago(e.target.checked)} label = "Monto De Pago"/>}
            description32={<CheckGroup checked={showmonedaPago} onChange={ (e)=> setShowmonedaPago(e.target.checked)} label = "Moneda Del Pago"/>}
            description33={<CheckGroup checked={showmedioPago} onChange={ (e)=> setShowmedioPago(e.target.checked)} label = "Medio De Pago"/>}
            description34={<CheckGroup checked={showfechaDeVencimientoDeLaPoliza} onChange={ (e)=> setShowfechaDeVencimientoDeLaPoliza(e.target.checked)} label = "Fecha De Vencimiento De La Póliza"/>}
            description35={<CheckGroup checked={shownumeroContable} onChange={ (e)=> setShownumeroContable(e.target.checked)} label = "Número De Cuenta Contable"/>}
            description36={<CheckGroup checked={showfechaContable} onChange={ (e)=> setShowfechaContable(e.target.checked)} label = "Fecha De Registro Contable"/>}
            description37={<CheckGroup checked={showimporteContable} onChange={ (e)=> setShowimporteContable(e.target.checked)} label = "Importe Del Registro Contable"/>}
            description38={<CheckGroup checked={shownaturalezaDelMovimiento} onChange={ (e)=> setShownaturalezaDelMovimiento(e.target.checked)} label = "Naturaleza Del Movimiento ( 1 O 2 )"/>}
            description39={<CheckGroup checked={showidIntegracion} onChange={ (e)=> setShowidIntegracion(e.target.checked)} label = "Id De Integracion"/>}
            description40={<CheckGroup checked={showestatusPoliza1} onChange={ (e)=> setShowestatusPoliza1(e.target.checked)} label = "Estatus De La Póliza Version 1"/>}
            description41={<CheckGroup checked={shownombreBeneficiarios} onChange={ (e)=> setShownombreBeneficiarios(e.target.checked)} label = "Nombre De Beneficiarios"/>}
            description42={<CheckGroup checked={showbeneficioAdicional} onChange={ (e)=> setShowbeneficioAdicional(e.target.checked)} label = "Beneficio Adicional"/>}
            description43={<CheckGroup checked={showmontoSumaAseguradaBeneficioAdicional} onChange={ (e)=> setShowmontoSumaAseguradaBeneficioAdicional(e.target.checked)} label = "Monto De Sa De Beneficio Adicional"/>}
            description44={<CheckGroup checked={showmonedaAdicional} onChange={ (e)=> setShowmonedaAdicional(e.target.checked)} label = "Moneda Del Beneficio Adicional"/>}
            description45={<CheckGroup checked={showbeneficiarioPago} onChange={ (e)=> setShowbeneficiarioPago(e.target.checked)} label = "Beneficiario Del Pago"/>}
            description46={<CheckGroup checked={showcuentaBeneficiario} onChange={ (e)=> setShowcuentaBeneficiario(e.target.checked)} label = "Cuenta Bancaria Del Beneficiario"/>}
            description47={<CheckGroup checked={showcuentaDeCargoOrigenDelPago} onChange={ (e)=> setShowcuentaDeCargoOrigenDelPago(e.target.checked)} label = "Cuenta De Cargo Origen Del Pago"/>}
            description48={<CheckGroup checked={showbancoCuentaBancaria} onChange={ (e)=> setShowbancoCuentaBancaria(e.target.checked)} label = "Banco De La Cuenta Bancaria"/>}
            description49={<CheckGroup checked={showfechaDeEmisiónDeLaPoliza} onChange={ (e)=> setShowfechaDeEmisiónDeLaPoliza(e.target.checked)} label = "Fecha De Emisión De La Póliza"/>}
            description50={<CheckGroup checked={showcanalDistribucion} onChange={ (e)=> setShowcanalDistribucion(e.target.checked)} label = "Canal ( De Distribución)"/>}
            description51={<CheckGroup checked={showclaveAgente} onChange={ (e)=> setShowclaveAgente(e.target.checked)} label = "Clave De Agente"/>}
            description52={<CheckGroup checked={showagenciaPromotoria} onChange={ (e)=> setShowagenciaPromotoria(e.target.checked)} label = "Da Agencia, Promotoría"/>}
            description53={<CheckGroup checked={showdisputable} onChange={ (e)=> setShowdisputable(e.target.checked)} label = "Disputable (Si) (No)"/>}
            button="Aceptar"
          />
        </Grid>
      </Grid>
    <br/>
      </div>
  );
}