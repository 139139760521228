import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@material-ui/core/IconButton";
import TXTField from "../../../../Tools/TextField/TextField";
import Grid from "@material-ui/core/Grid";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { FormattedMessage } from "react-intl";
import TablePagination from "@mui/material/TablePagination";
import ButtonVino from "../../../../Tools/Bottones/ButtonVino";
import ArrowBackIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button } from "@mui/material";
import { CardContent } from "@mui/material";
import { TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import SearchTXTField from "../../../../Tools/TextField/SearchTextField";

// funciones para el uso de apis
import { useEffect, useState } from "react";
import { getToken } from "../../../../../api/token";
import { BASE_API } from "../../../../../utils/constans";
import { secundario } from '../../../../../api/getUmbrales';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: secundario,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function TablaCompejidad() {
  /* Función para validar datos tabla,  */
  function createData(Complejidad) {
    return { Complejidad };
  }
  const rows = [createData("Aqui estan apuntados los datos de la api")];

  /* CONSTANTES DE ESTADOS*/
  const [data, setData] = useState(rows); // esta constante guarda un arreglo en donde se almacenará los datos de la API
  const [order, setOrder] = useState("ASC"); // esta constante tiene como propósico guardar el estado del orden de la tabla
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla

  // Funcion para la api
  // Token
  const [users, setUsers] = useState([]);
  const token = getToken();

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", `Bearer ${token}`);

  // Metodo GET
  const fetchGet = () => {
    fetch(`${BASE_API}complejidadsiniestros/get_listado/`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data);
        console.log(data);
      });
  };

  useEffect(() => {
    fetchGet();
  }, []);
  // Aqui termina la funcion para los datos de la api

  // Metodo para eliminar los datos de la tabla
  function eliminar(id) {
    let response = fetch(
      `${BASE_API}complejidadsiniestros/get_id/` + id + "/",
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify({ SINC_activo: false }),
      }
    );
    response.then((response) => {
      console.log(response);
    });

    console.log(id);
  }
  // Aqui termina la Funcion para eliminar

  //Comienzo de la funcionalidad de Editar
  const [editar, setEditar] = React.useState("");
  function editarPrueba() {
    alert("Entro a editar");
  }
  const [valuee, setValuee] = useState({
    txtnombreedit: "",
  });
  const edittClick = (prop) => (event) => {
    setValue({ ...valuee, [prop]: event.target.value });
    console.log(valuee);
  };

  async function PUTDataToken(aux) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${token}`);

    console.log(idselect);

    try {
      const response = fetch(
        `${BASE_API}complejidadsiniestros/get_id/` + idselect + "/",
        {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(aux),
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong. Status: " + response.status);
      }
      const responseJSON = await response.json();
      return responseJSON;
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }
  //Funcion para el boton de editar y abrir ventana modal
  const [open, setOpen] = React.useState(false);
  const [idselect, setidselect] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const handleClickOpen = (scrollType, row) => () => {
    setScroll(scrollType);
    setEditar(row);
    setidselect(row.SINC_idcomplejidadsiniestro);
  };

  useEffect(() => {
    if (editar != "") setOpen(true);
  }, [editar]);

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  //Constante para los datos
  const [value, setValue] = useState({
    txtnombre: "",
  });
  // Aqui termina la funcionalidad de Editar

  //Funcion para agregar nuevos datos de la api
  const anadirClick = (prop) => (event) => {
    setValue({ ...value, [prop]: event.target.value });
    console.log(value);
  };
  async function postDataToken(aux) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${token}`);

    try {
      const response = await fetch(
        `${BASE_API}complejidadsiniestros/get_listado/`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(aux),
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong. Status: " + response.status);
      }
      const responseJSON = await response.json();
      return responseJSON;
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }

  // Funcion para abril modal para agregar datoo
  const [openn, setOpenn] = React.useState(false);
  const [scrolll, setScrolll] = React.useState("paper");
  const handleClickOpen1 = (scrollType) => () => {
    setOpenn(true);
    setScrolll(scrollType);
  };

  const handleClose1 = () => {
    setOpenn(false);
  };

  const descriptionElementRef1 = React.useRef(null);
  React.useEffect(() => {
    if (openn) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openn]);

  // Aqui termina la funcionlidad de agregar

  /* FUNCIÓN PARA ORDENAR LA TABLA ASC/DSC (Ascendent/ Descendant) */
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setOrder("DSC");
      setData(sorted);
    } else if (order === "DSC") {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? -1 : 1
      );
      setOrder("ASC");
      setData(sorted);
    }
  };

  /* FUNCIÓN CLICK EN LOS RADIO BUTTON, ARROJA INFORMACION DE LA FILA SELECCIONADA */
  // const rbClick = (e) => {
  //   const inputfolio = (e.target.value);
  //   data.map(row=> {
  //     if(row.folio===inputfolio){
  //       console.log(row)
  //       setbtnDisbale(false)
  //     }
  //   })
  // }
  /* FUNCIÓN PARA DIRECCIONAR A OTRA VENTANA' */

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(5); //El tamaño inicial de las filas será de 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <Grid container alignItems="center" spacing={5}>
        <Grid item xs={3}>
          <SearchTXTField
            label={<FormattedMessage id="globBusqued" defaultMessage="Búsqueda" />}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained"  onClick={handleClickOpen1("paper")}>Agregar nuevo dato</Button>
        </Grid>   
      </Grid>
      <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}

                <StyledTableCell align="right" onClick={() => sorting("id")}>
                  Id
                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    {order === "DSC" ? <UnfoldMoreIcon /> : <UnfoldMoreIcon />}
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  onClick={() => sorting("Nombre")}
                >
                  <FormattedMessage
                    id="confGComplej"
                    defaultMessage="Complejidad"
                  />
                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    {order === "DSC" ? <UnfoldMoreIcon /> : <UnfoldMoreIcon />}
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  onClick={() => sorting("Estatus")}
                >
                  <FormattedMessage id="globEstatu" defaultMessage="Estatus" />
                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    {order === "DSC" ? <UnfoldMoreIcon /> : <UnfoldMoreIcon />}
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  onClick={() => sorting("Editar")}
                >
                  <FormattedMessage id="globEditar" defaultMessage="Editar" />
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  onClick={() => sorting("Eliminar")}
                >
                  <FormattedMessage id="estconElim" defaultMessage="Eliminar" />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {data
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                  /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                    // val.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    val.SINC_complejidad.toLowerCase().includes(
                      searchTerm.toLowerCase()
                    )
                  ) {
                    return val;
                  }
                })
                .map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell align="right">
                      {row.SINC_idcomplejidadsiniestro}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.SINC_complejidad}
                    </StyledTableCell>
                    {row.SINC_activo ? (
                      <StyledTableCell align="right">
                        <FormattedMessage
                          id="globActivo"
                          defaultMessage="Activo"
                        />
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell align="right">
                        {" "}
                        <FormattedMessage
                          id="globInacti"
                          defaultMessage="Inativo"
                        />
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="right">
                      <Button onClick={handleClickOpen("paper", row)}>
                        <ArrowBackIcon />
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Button
                        onClick={() =>
                          eliminar(row.SINC_idcomplejidadsiniestro)
                        }
                      >
                        <DeleteForeverIcon />
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        
      
      {/* Modal para editar dato */}
      <Dialog open={open}>
        <form
          onSubmit={(e) => {
            const { txtnombreedit } = e.target.elements;
            e.preventDefault();
            let aux = {
              SINC_complejidad: txtnombreedit.value,
            };
            PUTDataToken(aux);
          }}
        >
          <DialogTitle>
            <FormattedMessage id="confGEditP" defaultMessage="Editar plan" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box>
                {" "}
                <CardContent>
                  <CardContent>
                    <Typography style={{ fontSize: 14, color: "grey" }}>
                      <FormattedMessage
                        id="confGComplej"
                        defaultMessage="Complejidad"
                      />
                    </Typography>
                    <TextField
                      name="txtnombreedit"
                      label=""
                      fullWixdth
                      defaultValue={editar.SINC_complejidad}
                      onChange={edittClick("txtnombreedit")}
                    />
                    <br />
                  </CardContent>
                </CardContent>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonVino
              variant="contained"
              label={
                <FormattedMessage id="globCance" defaultMessage="Cancelar" />
              }
              onClick={handleClose}
            />
            <ButtonVino
              variant="contained"
              color="primary"
              label={
                <FormattedMessage id="globGuarda" defaultMessage="Guardar" />
              }
              onClick={handleClose}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
      <Dialog open={openn}>
        <form
          onSubmit={(e) => {
            const { txtnombre, txtasignado } = e.target.elements;
            e.preventDefault();
            let aux = {
              SINC_complejidad: txtnombre.value,

              // MON_actualizofecha: txtfecha.value,
              SINC_actualizousuario: txtasignado.value,
            };
            postDataToken(aux);
          }}
        >
          {/* Modal para agregar dato */}
          <DialogTitle>
            <FormattedMessage id="globAgrega" defaultMessage="Agregar" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box>
                {" "}
                <CardContent>
                  <CardContent>
                    <Typography style={{ fontSize: 14, color: "grey" }}>
                      <FormattedMessage id="globRol" defaultMessage="Rol" />
                    </Typography>
                    <TextField
                      name="txtnombre"
                      label=""
                      fullWidth
                      onChange={anadirClick("txtnombre")}
                    />
                    <br />
                    <Typography style={{ fontSize: 14, color: "grey" }}>
                      <FormattedMessage
                        id="globAsigna"
                        defaultMessage="Asignado"
                      />
                    </Typography>
                    <TextField
                      name="txtasignado"
                      label=""
                      fullWidth
                      onChange={anadirClick("txtasignado")}
                    />
                    <br />
                  </CardContent>
                </CardContent>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonVino
              variant="contained"
              label={
                <FormattedMessage id="globCance" defaultMessage="Cancelar" />
              }
              onClick={handleClose1}
            />
            <ButtonVino
              variant="contained"
              color="primary"
              label={
                <FormattedMessage id="globGuarda" defaultMessage="Guardar" />
              }
              onClick={handleClose1}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
