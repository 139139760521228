import { TOKENLOGIN } from "../../../utils/constans";
import { getURL } from "../../../api/getURL";

const token = TOKENLOGIN;
var myHeaders = new Headers();

myHeaders.append("Content-Type", "application/json");
myHeaders.append("Accept", "application/json");
myHeaders.append("Authorization", `Bearer ${token}`);

export async function getUsers(url) {
  try {
    const params = {
      method: "GET",
      headers: myHeaders,
    };
    const response = await fetch(url, params);

    if (!response.ok) {
      throw new Error("Something went wrong");
    }
    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    throw error;
  }
}

/**
 *
 * @param {string} url Recibe como parámetro una URL de la API
 * @param {json} JsonBody Recibe un JSON '{}' según lo requerido
 * @returns {Any} Retorna el resultado de la API
 */
export async function postDataLogin(url, JsonBody) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  try {
    const response = await fetch(`${await getURL()}${url}/`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(JsonBody),
    });

    if (!response.ok) {
      throw new Error(
        "Something went wrong. Status: " + response.status + getURL() + url
      );
    }
    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
}

/**
 *
 * @param {string} url Recibe como parámetro una URL de la API
 * @param {json} JsonBody Recibe un JSON '{}' según lo requerido
 * @returns {Any} Retorna el resultado de la API
 */
export async function putDataLogin(url, JsonBody) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  try {
    const response = await fetch(`${await getURL()}${url}`, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(JsonBody),
    });

    if (!response.ok) {
      throw new Error(
        "Something went wrong. Status: " + response.status + getURL() + url
      );
    }
    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
}
