import React from "react";
import img_recepcion from "../../img/menu/Recepcion.svg";
import img_recepcion_on from "../../img/menu/Recepcion_on.svg";
import img_registro from "../../img/menu/Registro.svg";
import img_registro_on from "../../img/menu/Registro_on.svg";
import img_dictamen from "../../img/menu/Dictamen.svg";
import img_dictamen_on from "../../img/menu/Dictamen_on.svg";
import img_medico from "../../img/menu/Medico.svg";
import img_medico_on from "../../img/menu/Medico_on.svg";
import img_investigador from "../../img/menu/Investigador.svg";
import img_investigador_on from "../../img/menu/Investigador_on.svg";
import img_comunicacion from "../../img/menu/ComunicacionCliente.svg";
import img_comunicacion_on from "../../img/menu/ComunicacionCliente_on.svg";
import img_estadistico from "../../img/menu/EstadisticoContable.svg";
import img_estadistico_on from "../../img/menu/EstadisticoContable_on.svg";
import img_wf from "../../img/menu/Workflow.svg";
import img_wf_on from "../../img/menu/Workflow_on.svg";
import "./Inicio.css";
import {  useEffect } from 'react'
import { getDataToken } from "../../api/dataToken";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import Grid from "@material-ui/core/Grid";
import DialogButton from "./Elements/dialogInvest";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
//idioma
import { FormattedMessage } from "react-intl";

export function CompInicio() {
  //href para medico
  const clickMedico = () => {
    window.location = "/medico";
  };

  //Href para recepcion
  const clickRecepcion = () => {
    window.location = "/recepcion";
  };

  //Href para recepcion agregar documentos
  const clickAgregarDocumentoR = () => {
    window.location = "/agregarDocumento";
  };
  //href para registro
  const clickRegistro = () => {
    window.location = "/registro/inicio";
  };
  const clickConsultarRegistro = () => {
    window.location = "/consulta/registro";
  };
  //href para Comunicacion
  const clickcomunicacioncon = () => {
    window.location = "/comunicacionconsul";
  };
  const clickcomunicacion = () => {
    window.location = "/comunicacion";
  };
  //href para Dictamen
  const clickDictamen = () => {
    window.location = "/dictamen";
  };
  //href para Estadistico Contable
  const clickContable = () => {
    window.location = "/contable";
  };

  //href para Workflow
  const clickWorkflow = () => {
    window.location = "/workflow";
  };

  //href para consultas workflow
  const clickConsultasWF = () => {
    window.location = "/consultasWF";
  };

  //Efecto dropdown al dar click en ciertos módulos
  const [showWF, setShowWF] = useState(true);
  const [showRegistro, setShowRegistro] = useState(true);
  const [showRecepcion, setShowRecepcion] = useState(true);
  const [showComClien, setShowComClien] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  //Cambiar imagenes on a off y viceversa
  const [imgWF, setImgWF] = useState(false);
  const [imgRegistro, setImgRegistro] = useState(false);
  const [imgRecepcion, setImgRecepcion] = useState(false);
  const [imgComClien, setImgComClien] = useState(false);
  const [imgDictamen, setImgDictamen] = useState(false);
  const [imgMedico, setImgMedico] = useState(false);
  const [imgInvest, setImgInvest] = useState(false);
  const [imgEstaCont, setImgEstaCont] = useState(false);
  const [enabled, setEnabled] = useState(true);

  
  const [dataX, setDataX] = React.useState([])
  const getDataPermisos = async () => {
    //Resultados de la api investigacion
    var resultPermisos = await getDataToken("login/get_correo/"+  localStorage.getItem("SNYTLOG_email") + "/");    
    setDataX(resultPermisos);
    
  }

  useEffect(() => {
    getDataPermisos()  
  }, [])


  //Alerta al dar click en ícono investigador
  const clickInvestigador = () => {
     if(dataX.groups.includes(11) == true){
      setOpenDialog(true);
      window.location = "/investigador";
    };
      if(dataX.groups.includes(30) == true){
      setOpenDialog(true);
      window.location = "/supervisor";
    };
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
        style={{
          paddingRight: "50px",
          paddingTop: "30px",
          position: "absolute",
        }}
      >
        <ReturnPage href="/" />
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        id="div-parent"
      >
        {/* DIV MÓDULO  RECEPCIÓN */}
        <Grid
          item
          xs={3}
          className="div-children-menu"
          onMouseEnter={() => {
            setShowRecepcion((s) => !s);
            setImgRecepcion(true);
          }}
          onMouseLeave={() => {
            setShowRecepcion((s) => !s);
            setImgRecepcion(false);
          }}
          style={{ pointerEvents: enabled === true ? "auto" : "none" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <center>
                <Grid xs={12}>
                  {!imgRecepcion ? (
                    <img
                      className="img-iconos"
                      src={img_recepcion}
                      alt="Recepción"
                    />
                  ) : (
                    <img
                      className="img-iconos"
                      src={img_recepcion_on}
                      alt="Recepción"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label>
                    <FormattedMessage
                      id="globRecepc"
                      defaultMessage="Recepción"
                    />
                  </label>
                </Grid>
              </center>
            </Grid>
            <Grid item xs={12}>
              <a
                className="lnk-style"
                onClick={clickRecepcion}
                href="#"
                style={{ display: showRecepcion ? "none" : "block" }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <ControlPointIcon />
                  </Grid>
                  <Grid item>
                    <FormattedMessage
                      id="submenuNue"
                      defaultMessage="Nuevo trámite"
                    />
                  </Grid>
                </Grid>
              </a>
              <a
                className="lnk-style"
                onClick={clickAgregarDocumentoR}
                href="#"
                style={{ display: showRecepcion ? "none" : "block" }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <CreateOutlinedIcon />
                  </Grid>
                  <Grid item>
                    <FormattedMessage
                      id="submenuAgr"
                      defaultMessage="Agregar documentos"
                    />
                  </Grid>
                </Grid>
              </a>
            </Grid>
          </Grid>
        </Grid>
        {/* DIV MÓDULO  REGISTRO */}
        <Grid
          item
          xs={3}
          className="div-children-menu"
          onMouseEnter={() => {
            setShowRegistro((s) => !s);
            setImgRegistro(true);
          }}
          onMouseLeave={() => {
            setShowRegistro((s) => !s);
            setImgRegistro(false);
          }}
          style={{ pointerEvents: enabled === true ? "auto" : "none" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <center>
                <Grid xs={12}>
                  {!imgRegistro ? (
                    <img
                      className="img-iconos"
                      src={img_registro}
                      alt="Registro"
                    />
                  ) : (
                    <img
                      className="img-iconos"
                      src={img_registro_on}
                      alt="Registro"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label>
                    <FormattedMessage
                      id="globRegist"
                      defaultMessage="Registro"
                    />
                  </label>
                </Grid>
              </center>
            </Grid>
            <Grid item xs={12}>
              <a
                onClick={clickRegistro}
                className="lnk-style"
                href="#"
                style={{ display: showRegistro ? "none" : "block" }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <ControlPointIcon />
                  </Grid>
                  <Grid item>
                    <FormattedMessage
                      id="globRegist"
                      defaultMessage="Iniciar registro"
                    />
                  </Grid>
                </Grid>
              </a>
              <a
                className="lnk-style"
                onClick={clickConsultarRegistro}
                href="#"
                style={{ display: showRegistro ? "none" : "block" }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <label className="label-menu">
                    <SearchIcon style={{ paddingRight: 7 }} />
                    <FormattedMessage
                      id="submenuCon"
                      defaultMessage="Consulta registro"
                    />
                  </label>
                </Grid>
              </a>
            </Grid>
          </Grid>
        </Grid>
        {/* DIV MÓDULO  DICTÁMEN*/}
        <Grid
          item
          xs={3}
          className="div-children-menu"
          onClick={clickDictamen}
          onMouseEnter={() => {
            setImgDictamen(true);
          }}
          onMouseLeave={() => {
            setImgDictamen(false);
          }}
          style={{ pointerEvents: enabled === true ? "auto" : "none" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <center>
                <Grid xs={12}>
                  {!imgDictamen ? (
                    <img
                      className="img-iconos"
                      src={img_dictamen}
                      alt="Dictamen"
                    />
                  ) : (
                    <img
                      className="img-iconos"
                      src={img_dictamen_on}
                      alt="Dictamen"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label>
                    <FormattedMessage
                      id="menuDictam"
                      defaultMessage="Dictamen"
                    />
                  </label>
                </Grid>
              </center>
            </Grid>
          </Grid>
        </Grid>
        {/* DIV MÓDULO MÉDICO */}
        <Grid
          item
          xs={3}
          className="div-children-menu"
          onClick={clickMedico}
          onMouseEnter={() => {
            setImgMedico(true);
          }}
          onMouseLeave={() => {
            setImgMedico(false);
          }}
          style={{ pointerEvents: enabled === true ? "auto" : "none" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <center>
                <Grid xs={12}>
                  {!imgMedico ? (
                    <img className="img-iconos" src={img_medico} alt="Medico" />
                  ) : (
                    <img
                      className="img-iconos"
                      src={img_medico_on}
                      alt="Medico"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label>
                    <FormattedMessage id="globMedico" defaultMessage="Médico" />
                  </label>
                </Grid>
              </center>
            </Grid>
          </Grid>
        </Grid>

  {/* -------------------------------------------------------------------------------------------------------------------
  -----------------------------------------------------------------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------- */}
        {/* DIV MÓDULO INVESTIGADOR */}
        <Grid
          item
          xs={3}
          className="div-children-menu"
          onClick={clickInvestigador}
          onMouseEnter={() => {
            setImgInvest(true);
          }}
          onMouseLeave={() => {
            setImgInvest(false);
          }}
          style={{ pointerEvents: enabled === true ? "auto" : "none" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <center>
                <Grid xs={12}>
                  {!imgInvest ? (
                    <img className="img-iconos" src={img_investigador} alt="Investigador"/>
                  ) : (
                    <img className="img-iconos" src={img_investigador_on} alt="Investigador"/>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label>
                     <FormattedMessage id="globInvest" defaultMessage="Investigador"/>
                  </label>
                </Grid>
              </center>
            </Grid>
          </Grid>
        </Grid>
  {/* -------------------------------------------------------------------------------------------------------------------
  -----------------------------------------------------------------------------------------------------------------------
  ----------------------------------------------------------------------------------------------------------------------- */}

        
        {/* DIV MÓDULO COMUNICACIÓN CON EL CLIENTE*/}
        <Grid
          item
          xs={3}
          className="div-children-menu"
          onMouseEnter={() => {
            setShowComClien((s) => !s);
            setImgComClien(true);
          }}
          onMouseLeave={() => {
            setShowComClien((s) => !s);
            setImgComClien(false);
          }}
          style={{ pointerEvents: enabled === true ? "auto" : "none" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <center>
                <Grid xs={12}>
                  {!imgComClien ? (
                    <img
                      className="img-iconos"
                      src={img_comunicacion}
                      alt="Comunicación"
                    />
                  ) : (
                    <img
                      className="img-iconos"
                      src={img_comunicacion_on}
                      alt="Comunicación"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label>
                    <FormattedMessage
                      id="globCoClie"
                      defaultMessage="Comunicación con el Cliente"
                    />
                  </label>
                </Grid>
              </center>
            </Grid>
            <Grid item xs={12}>
              <a
                onClick={clickcomunicacion}
                className="lnk-style"
                href="#"
                style={{ display: showComClien ? "none" : "block" }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <ControlPointIcon />
                  </Grid>
                  <Grid item>
                    <FormattedMessage id="globInicio" defaultMessage="Inicio" />
                  </Grid>
                </Grid>
              </a>
              <a
                onClick={clickcomunicacioncon}
                className="lnk-style"
                href="#"
                style={{ display: showComClien ? "none" : "block" }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <SearchIcon />
                  </Grid>
                  <Grid item>
                    <FormattedMessage
                      id="globConsu"
                      defaultMessage="Consulta"
                    />
                  </Grid>
                </Grid>
              </a>
            </Grid>
          </Grid>
        </Grid>
        {/* DIV MÓDULO  ESTADÍSTICO CONTABLE */}
        <Grid
          item
          xs={3}
          className="div-children-menu"
          onClick={clickContable}
          onMouseEnter={() => {
            setImgEstaCont(true);
          }}
          onMouseLeave={() => {
            setImgEstaCont(false);
          }}
          style={{ pointerEvents: enabled === true ? "auto" : "none" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <center>
                <Grid xs={12}>
                  {!imgEstaCont ? (
                    <img
                      className="img-iconos"
                      src={img_estadistico}
                      alt="Estadístico contable"
                    />
                  ) : (
                    <img
                      className="img-iconos"
                      src={img_estadistico_on}
                      alt="Estadístico contable"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label>
                    <FormattedMessage
                      id="menuEstCot"
                      defaultMessage="Estadístico Contable"
                    />
                  </label>
                </Grid>
              </center>
            </Grid>
          </Grid>
        </Grid>
        {/* DIV MÓDULO  WORKFLOW*/}
        <Grid
          item
          xs={3}
          className="div-children-menu"
          onMouseEnter={() => {
            setShowWF((s) => !s);
            setImgWF(true);
          }}
          onMouseLeave={() => {
            setShowWF((s) => !s);
            setImgWF(false);
          }}
          style={{ pointerEvents: enabled === true ? "auto" : "none" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <center>
                <Grid xs={12}>
                  {!imgWF ? (
                    <img className="img-iconos" src={img_wf} alt="WorkfLow" />
                  ) : (
                    <img
                      className="img-iconos"
                      src={img_wf_on}
                      alt="WorkfLow"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label>Workflow</label>
                </Grid>
              </center>
            </Grid>
            <Grid item xs={12}>
              <a
                onClick={clickWorkflow}
                className="lnk-style"
                href="#"
                style={{ display: showWF ? "none" : "block" }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <DescriptionOutlinedIcon />
                  </Grid>
                  <Grid item>
                    <FormattedMessage
                      id="submenuFol"
                      defaultMessage="Folios en proceso"
                    />
                  </Grid>
                </Grid>
              </a>
              <a
                onClick={clickConsultasWF}
                className="lnk-style"
                href="#"
                style={{ display: showWF ? "none" : "block" }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <SearchIcon />
                  </Grid>
                  <Grid item>
                    <FormattedMessage
                      id="globConsu"
                      defaultMessage="Consulta"
                    />
                  </Grid>
                </Grid>
              </a>
            </Grid>
          </Grid>
        </Grid>

        {/* FIN DE DIV MÓDULOS */}
      </Grid>
    </>
  );
}
