import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState,useEffect } from "react";
import { Grid } from "@mui/material";
import RadioInput from "../../Tools/RadioButton/RadioInput";
import TXTField from "../../Tools/TextField/TextField";
//paginación de la tabla
import TablePagination from "@mui/material/TablePagination";
//estilos de la tabla
import StyledTableCell from "../../Tools/Table/StyledTableCell";
import { FormattedMessage } from "react-intl";
//Datos JSON
import data from "../data.json";

export default function Policy() {

  const [poliza, setPoliza] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = require("../data.json");
        console.log("Datos obtenidos:", response);
        setPoliza(response);
      } catch (error) {
        console.error("Error al obtener el archivo JSON:", error);
      }      
    };

    fetchData();
  }, []); 

  /* CONSTANTES DE ESTADOS*/
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla
  const [btnDisable, setBtnDisable] = useState(true);

  /* FUNCIÓN CLICK EN LOS RADIO BUTTON, ARROJA INFORMACION DE LA FILA SELECCIONADA */
  // const rbClick = (e) => {
  //   const inputFolio = e.target.value;
  //   data.map((row) => {
  //     if (row.numFolio === inputFolio) {
  //       console.log(row);
  //       setInfoDataRow(row);
  //       setBtnDownloadInfo(false);
  //       setBtnDisable(false);
  //     }
  //   });
  // };

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //Constantes para variables de la fecha 
  const [filteredData, setFilteredData] = useState([]);
 
  function buscarPoliza() {
    // Lógica para manejar el clic en el botón
    console.log('Buscar:', searchTerm);

    if(searchTerm != ""){
 
      //filtaremos los datos en las fechas seleccionadas 
      const filteredResults = poliza.filter((item) => {
       const numeroPoliza = (item.NumPoliza)//Constante que guardara solo los el Numero de cuenta
       console.log(numeroPoliza)
       
        return (searchTerm == numeroPoliza);
      });
      setFilteredData(filteredResults);
      console.log(filteredResults);
      
    } else {
      console.log("Por favor, ingresa una poliza.");
    }
  };
  console.log(filteredData)
  return (
    <div>
      <Grid
        style={{ paddingBottom: "20px" }}
        container
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid xs={12}>
          <br />
          <Grid container justifyContent="center">
          <Grid item>
            <h3-header-card>
              <FormattedMessage id="estatus" defaultMessage="POR NÚMERO DE PÓLIZA"/>
            </h3-header-card>
          </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}> 
          <h4-search>Buscar:</h4-search>
          <TXTField
            showSearchIcon={true}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            iconOnClick={() => buscarPoliza()}
          />
        </Grid>
      </Grid>

      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* <StyledTableCell align="center"></StyledTableCell> */}
                <StyledTableCell> Número de folio </StyledTableCell>
                <StyledTableCell> Número de siniestro </StyledTableCell>
                <StyledTableCell> Fecha de aviso </StyledTableCell>
                <StyledTableCell> Fecha de ocurrencia del siniestro </StyledTableCell>
                <StyledTableCell> Nombre del asegurado </StyledTableCell>
                <StyledTableCell> Clave oil </StyledTableCell>
                <StyledTableCell> Descripción clave oil </StyledTableCell>
                <StyledTableCell> Estatus del siniestro </StyledTableCell>
                <StyledTableCell> Suma asegurada básico </StyledTableCell>
                <StyledTableCell> Moneda suma asegurada básica </StyledTableCell>
                <StyledTableCell> Moneda suma asegurada beneficio muerte accidental </StyledTableCell>
                <StyledTableCell> Tipo de reclamación </StyledTableCell>
                <StyledTableCell> Causa de siniestro </StyledTableCell>
                <StyledTableCell> ID de integración  </StyledTableCell>
                <StyledTableCell> Estatus de póliza versión 1 </StyledTableCell>
              </TableRow>
            </TableHead>
            {/* {filteredData.length > 0 && ( */}
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <TableRow key={item.id}>
                    {/* <StyledTableCell component="th" scope="row" align="center">
                      <RadioInput
                        name="rb"
                        value={row.numFolio}
                        onChange={rbClick}
                      />
                    </StyledTableCell> */}
                    <StyledTableCell align="center"> {item.numFolio} </StyledTableCell>
                    <StyledTableCell align="center"> {item.numSiniestro} </StyledTableCell>
                    <StyledTableCell align="center"> {item.fechaAviso} </StyledTableCell>
                    <StyledTableCell align="center"> {item.fechaOcurrencia} </StyledTableCell>
                    <StyledTableCell align="center"> {item.nombreAsegurado} </StyledTableCell>
                    <StyledTableCell align="center"> {item.claveOil} </StyledTableCell>
                    <StyledTableCell align="center"> {item.descripcionOil} </StyledTableCell>
                    <StyledTableCell align="center"> {item.estatusSiniestro} </StyledTableCell>
                    <StyledTableCell align="center"> {item.sumaAseguradaBasica} </StyledTableCell>
                    <StyledTableCell align="center"> {item.monedaSumaAseguradaBasica} </StyledTableCell>
                    <StyledTableCell align="center"> {item.sumaAseguradaBeneficioMuerteAccidental} </StyledTableCell>
                    <StyledTableCell align="center"> {item.tipoReclamacion} </StyledTableCell>
                    <StyledTableCell align="center"> {item.causaSiniestro} </StyledTableCell>
                    <StyledTableCell align="center"> {item.idIntegracion} </StyledTableCell>
                    <StyledTableCell align="center"> {item.estatusPoliza1} </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
            {/* )} */}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelRowsPerPage="Filas por página"
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
