import { getUsers } from "../../src/components/Login/apiLogin/getUsers";
import { BASE_API } from "../utils/constans";

export async function getApiURL() {
  var result = await getUsers(`${BASE_API}configuraciongeneral/get_id/1/`);
  var url = result.SCG_URL;
  var port = result.SCG_Puerto;
  var urlComplete = port === null ? url + "/" : url + ":" + port + "/";

  return urlComplete;
}
