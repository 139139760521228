//  David Ruiz 10/2022

import React from 'react';
import IconButton from '@mui/material/IconButton';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

const Btres=(props)=>{
    return(
        <IconButton aria-label=" RequestQuoteIcon" color="icons" 
        //aqui va el enlace
        href={props.enlace}
        onClick={props.onClick}
        onChange={props.onChange}
        id={props.id}
        //ojo aqui es disabled
        //true para desabilitar y false para habilitar
        disabled={props.estatus}>
        < RequestQuoteIcon />
      </IconButton>
        );
    }

export default Btres;