import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import PersonIcon from "@mui/icons-material/Person";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import LogoSINAY from "../../../img/LogoSINAY.jpeg";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  // Override media queries injected by theme.mixins.toolbar
  "@media all": {
    minHeight: 110,
  },
}));

export default function NavMatrizRoles() {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={2}>
        <AppBar position="static" color="inherit">
          <StyledToolbar variant="dense">
            <Container maxWidth="lg">
              <Grid>
                <Grid item xs={12} sm={12}>
                  <Box>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <img src={LogoSINAY} alt="logo" width="70%"></img>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </StyledToolbar>
        </AppBar>
      </Grid>
      <Grid item xs={12} sm={2}>
        <AppBar position="static" color="primary">
          <StyledToolbar variant="dense">
            <Container maxWidth="lg">
              <Grid>
                <Grid item xs={12} sm={12}>
                  <Box borderLeft={0}></Box>
                </Grid>
              </Grid>
            </Container>
          </StyledToolbar>
        </AppBar>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Box borderBottom={1}>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color="secondary">
              <StyledToolbar variant="dense">
                <Container maxWidth="lg">
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={3}>
                      <Box>
                        <br></br>
                        <br></br>
                        <h1-header>SINAY</h1-header>
                        <h1-header-card-polizas>
                          Sistema Integral Administrador de Siniestros Vida
                        </h1-header-card-polizas>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box></Box>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Box>
                        <br></br>
                        <Badge>
                          <PersonIcon />
                          &nbsp;&nbsp;&nbsp; Pajita Aguirre
                        </Badge>
                        <br></br>
                        <br></br>
                        <Stack spacing={2} direction="row">
                          <Link color="inherit" underline="none" href="#">
                            Matriz de Roles
                          </Link>
                          <Divider
                            color="white"
                            orientation="vertical"
                            variant="middle"
                            flexItem
                          />
                          <Link color="inherit" underline="none" href="/">
                            Cerrar sesión
                          </Link>
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </StyledToolbar>
            </AppBar>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
