/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import Paper from "@mui/material/Paper";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { useState, useEffect } from "react";
//idioma
import { FormattedMessage } from "react-intl";
import { Grid } from "@mui/material";
import DialogMovReservaOPC from "../Dialogs/DialogMovReservaOPC";
import { Link } from "react-router-dom";

//Ejemplo formulario tramite
import { FormReclamacionesInd } from "../FormularioTramite/formReclamaciones";

export default function TablaRegistroContable(props) {
  const poliza = props.poliza;
  const infoRow = props.infoRow;
  const row = {
    id: 1,
    cobertura: "Fallecimiento",
    sumaAsegurada: 1000000,
    monedaSA: "MXN",
    importe: 1000000,
    balanceMXP: 1000000,
    balanceUSD: 0,
  };

  /* Función para validar datos tabla,  */
  function createData(
    id,
    cobertura,
    sumaAsegurada,
    monedaSA,
    importe,
    balanceMXP,
    balanceUSD
  ) {
    return {
      id,
      cobertura,
      sumaAsegurada,
      monedaSA,
      importe,
      balanceMXP,
      balanceUSD,
    };
  }
  const coberturas = [
    createData(1, "Fallecimiento", 2000000, 1, "", "", ""),
    createData(2, "Muerte accidental", 1000000, 1, "", "", ""),
    createData(3, "Invalidez", 5000000, 1, "", "", ""),
    createData(4, "Enfermedades graves", 500000, 1, "", "", ""),
    createData(5, "Extensión de pago de primas", 700000, 1, "", "", ""),
  ];

  /* CONSTANTES DE ESTADOS*/
  const [data, setData] = useState(coberturas); // esta constante guarda un arreglo en donde se almacenará los datos de la API
  const [dataAux, setDataAux] = useState(data); // esta constante guarda un arreglo en donde se almacenará los datos de la API
  const [importeMoneda, setImporteMoneda] = useState("");
  const [abierto, setAbierto] = useState(false);

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item>
          <Paper style={{ width: "100%" }}>
            <TableContainer
              sx={{ maxHeight: 440, showEmptyDataSourceMessage: true }}
              style={{ position: "sticky" }}
            >
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  "& .MuiTableRow-root:hover": {
                    backgroundColor: "#ededed",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="globCobert"
                        defaultMessage="Coberturas"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage id="dictaSA" defaultMessage="S.A." />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="globMoneda"
                        defaultMessage="Moneda"
                      />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
                  {dataAux
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <StyledTableCell>{row.cobertura}</StyledTableCell>
                          <StyledTableCell>{row.sumaAsegurada}</StyledTableCell>
                          <StyledTableCell>{row.monedaSA}</StyledTableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
      {/* Escenario 2 */}
      <Grid
        style={{ marginTop: "20px" }}
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item>
          <DialogMovReservaOPC infoRow={infoRow} poliza={poliza}/>
        </Grid>
        <Grid item>
          <Link
            to="/dictamen/detalle_folio/reservas/ajuste_acc"
            state={{ infoRow, row }}
          >
            <ButtonVino
              label={
                <FormattedMessage
                  id="dictaReservaAjus"
                  defaultMessage="Reserva de ajuste (Accesorios)"
                />
              }
            />
          </Link>
        </Grid>
        <Grid item>
          <Link
            to="/dictamen/detalle_folio/reservas/consulta_cont"
            state={{ infoRow, row }}
          >
            <ButtonVino
              label={
                <FormattedMessage
                  id="dictamConsultacontab"
                  defaultMessage="Consulta contable"
                />
              }
            />
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
