import * as React from "react";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import IconReservas from "../../../Tools/Icons/IconReservas";
import "../../dictamen.css";
import { Grid } from "@material-ui/core";
import DialogAvisoFallecimiento from "../Dialogs/DialogAvisoFallecimiento";
import DialogAvisoSiniestro from "../Dialogs/DialogAvisoSiniestro";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export default function PopperReservas(props) {
  const [open, setOpen] = React.useState(false);
  const [openDialog1, setOpenDialog1] = React.useState(false);
  const [openDialog2, setOpenDialog2] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let infoFolio = props.detalleFolio;
  let infoRow = props.detalleFolio;
  const row = props.row;
  infoFolio = { ...infoFolio, poliza: props.poliza, ...row };

  //Escenario 1 “REGISTRO DEL IMPORTE DE RESERVA (sin contabilización en SINAY)”
  //Escenario 2 SINAY GENERA EL REGISTRO CONTABLE Y LA POLIZA DE DIARIO CONTABLE DE LAS RESERVAS
  //Cuando es 1 corresponde al escenario 1, 2 para el escenario 2
  const tipoEscenario = 1;

  const showPopper = (event) => {
    setAnchorEl(event.currentTarget);
    !open ? setOpen(true) : setOpen(false);
  };
  const hidePopper = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(false);
  };
  const openDialogFallecimiento = () => {
    return (
      <React.Fragment>
        <DialogAvisoFallecimiento
          poliza={props.poliza}
          closeDialog={(e) => setOpenDialog1(e)}
        />
      </React.Fragment>
    );
  };
  const openDialogSiniestro = () => {
    return (
      <React.Fragment>
        <DialogAvisoSiniestro
          poliza={props.poliza}
          closeDialog={(e) => setOpenDialog2(e)}
        />
      </React.Fragment>
    );
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  return (
    <div>
      <IconReservas tooltipTitle={props.tooltipTitle} onClick={showPopper} />
      <Popper id={id} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Box
              sx={{ border: 1, p: 1, bgcolor: "background.paper" }}
              className="ul-dictamen-reservas"
              onMouseLeave={hidePopper}
            >
              <ul>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  className="dictamen-reservas-items"
                >
                  {/* {tipoEscenario === 1 ? (
                    <Grid item xs={12}>
                      <li>
                        <h3-subtitle-card>
                          <Link
                            style={{ cursor: "pointer" }}
                            to="/dictamen/detalle_folio/reservas"
                            state={infoFolio}
                          >
                            <FormattedMessage
                              id="dictRegRes"
                              defaultMessage="Registro de reservas"
                            />
                          </Link>
                        </h3-subtitle-card>
                      </li>
                    </Grid>
                  ) : null} */}
                  <Grid item xs={12}>
                      <li>
                        <h3-subtitle-card>
                          <Link
                            style={{ cursor: "pointer" }}
                            to="/dictamen/detalle_folio/reservas"
                            state={{row: infoRow, poliza: row}}
                          >
                            <FormattedMessage
                              id="dictRegRes"
                              defaultMessage="Registro de reservas"
                            />
                          </Link>
                        </h3-subtitle-card>
                      </li>
                    </Grid>
                  <Grid item xs={12}>
                    <li>
                      <h3-subtitle-card>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenDialog1(true)}
                        >
                          <FormattedMessage
                            id="dictAviFall"
                            defaultMessage="Aviso de fallecimiento"
                          />
                        </a>
                      </h3-subtitle-card>
                    </li>
                  </Grid>
                  <Grid item xs={12}>
                    <li>
                      <h3-subtitle-card>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenDialog2(true)}
                        >
                          <FormattedMessage
                            id="dictAviSin"
                            defaultMessage="Aviso de Siniestro"
                          />
                        </a>
                      </h3-subtitle-card>
                    </li>
                  </Grid>
                </Grid>
              </ul>
            </Box>
          </Fade>
        )}
      </Popper>
      {!openDialog1 ? null : openDialogFallecimiento()}
      {!openDialog2 ? null : openDialogSiniestro()}
    </div>
  );
}
