/* Brandon Hernandez Rocha 11/2022 */
import React, { useEffect } from "react";
import CustomSeparatorDinamic from "../Tools/BreadCrumbs/RecorridoDinamico";
import "../../css/estilo.css";


//idioma
import { FormattedMessage } from "react-intl";

const CompReactivar = () => {

    const arrayBreadcrumbs = [
        {
            key: 1,
            name: <FormattedMessage id="globInicio" defaultMessage="Inicio" />,
            to: "/menu",
        },
        {
            key: 2,
            name: <FormattedMessage id="menuDictam" defaultMessage="Dictamen" />,
            to: "/dictamen",
        },
    ];



    return (
        <div className="div-parent">
            <div className="div-breadcrumbs">
                <CustomSeparatorDinamic
                    breadcrumbs={arrayBreadcrumbs}
                    name={`Reactivar folio`}
                />
            </div>
            <div className="div-container">
              
            </div>
        </div>
    );
};
export default CompReactivar;
