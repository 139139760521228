import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { Grid } from "@mui/material";
import RadioInput from "../../Tools/RadioButton/RadioInput";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import TXTField from "../../Tools/TextField/TextField";
//Idioma
import { FormattedMessage } from "react-intl";
//paginación de la tabla
import TablePagination from "@mui/material/TablePagination";
//estilos de la tabla
import StyledTableCell from "../../Tools/Table/StyledTableCell";
//importamos la libreria para el get
import { getDataToken } from "../../../api/dataToken";
import { formatofecha } from "../../../api/getUmbrales";
import moment from "moment";

export default function TableConstRegistro() {
  //Creción de constantes  para poder usar las apis
  const [registro, setRegistros] = useState([]); //Guarda toda la información que trae la api
  //cambio de Formulario
  const [showForm, setShowForm] = useState(true);
  //Método get para traer los datos de la charola registro
  const getDatos = async () => {
    try {
      var resultCharola = await getDataToken(
        "charolaregistro/get_listadoEntregados/"
      );
      // Extraer los folios de los registros obtenidos
      const folios = resultCharola.map(
        (registro) => registro.RECT_idfolio?.RECT_idfolio
      );
      var resultPolizas = await getDataToken("polizaportramites/get_listado/");
      // Filtrar los registros que tengan los folios obtenidos
      setRegistros(
        resultPolizas.filter((registro) =>
          folios.includes(registro.RECT_idfolio?.RECT_idfolio)
        )
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getDatos();
  }, []);

  console.log(registro);

  /* CONSTANTES DE ESTADOS*/
  //Constantes de estados para la tabla
  const [order, setOrder] = useState("ASC"); // esta constante tiene como propósiyo guardar el estado del orden de la tabla
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla
  const [btnDisable, setBtnDisable] = useState(true); //Boton de inicio registro el cual podra ser habilitado o deshabilitado
  const [IDCharola, setIDCharola] = useState([]); //se almacenaran nuestros datos para poder enviarlos
  const [datos, setDatos] = useState(); //se almacenaran nuestros datos para poder enviarlos

  const getDatosHeader = async (ID) => {
    try {
      var resultCharola = await getDataToken(
        "charolaregistro/get_listadoEntregados/"
      );
      // Extraer los datos de los registros obtenidos
      setDatos(
        resultCharola.filter(
          (registro) => registro.RECT_idfolio?.RECT_idfolio === ID
        )
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  console.log(datos);
  //Con esta funciónn manejamos el timepo de respuesta de acuerdo a cada color

  /* FUNCIÓN PARA ORDENAR LA TABLA ASC/DSC (Ascendent/ Descendant) */
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...registro].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setOrder("DSC");
      setRegistros(sorted);
    } else if (order === "DSC") {
      const sorted = [...registro].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? -1 : 1
      );
      setOrder("ASC");
      setRegistros(sorted);
    }
  };

  /* FUNCIÓN CLICK EN LOS RADIO BUTTON, ARROJA INFORMACION DE LA FILA SELECCIONADA */
  const rbClick = (e) => {
    const inputFolio = parseInt(e.target.value);
    registro.map((row) => {
      if (row.RECT_idfolio?.RECT_idfolio == inputFolio) {
        console.log(row);
        setBtnDisable(false);
        setIDCharola(row);
      }
    });
  };

  useEffect(() => {
    if (Object.keys(IDCharola).length > 0) {
      getDatosHeader(IDCharola.RECT_idfolio?.RECT_idfolio);
      console.log("entro");
    }
  }, [IDCharola]);
  console.log("primer tabla que va a pasar ", IDCharola);

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const filteredData = registro.slice(startIndex, endIndex);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  if (showForm) {
    return (
      <div>
        <Grid
          style={{ paddingBottom: "20px" }}
          container
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <Grid item xs={4}>
            <h4-search>
              <FormattedMessage id="globBuscar" defaultMessage="Buscar" />:
            </h4-search>
            <TXTField
              showSearchIcon={true}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
            {/* <ButtonVino label="Buscar" onClick={(e)=>fetchRegistro(searchTerm) }  /> */}
          </Grid>
        </Grid>

        <Paper sx={{ width: "100%" }}>
          <TableContainer
            sx={{ maxHeight: 440 }}
            style={{ position: "sticky" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center"></StyledTableCell>
                  {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                  <StyledTableCell
                    align="center"
                    onClick={() =>
                      sorting("registro.RECT_idfolio.RECT_numerofolio")
                    }
                  >
                    <FormattedMessage
                      id="globNoDeFo"
                      defaultMessage="No. de folio"
                    />
                    <IconButton
                      className="icon-button"
                      style={{ color: "white" }}
                    >
                      <UnfoldMoreIcon />
                    </IconButton>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <FormattedMessage
                      id="globNoPoli"
                      defaultMessage="No. de póliza"
                    />
                    <IconButton
                      className="icon-button"
                      style={{ color: "white" }}
                    >
                      <UnfoldMoreIcon />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <FormattedMessage
                      id="globFechEm"
                      defaultMessage="Fecha de emisión"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <FormattedMessage
                      id="globPagaHa"
                      defaultMessage="Pagado hasta"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <FormattedMessage
                      id="globFechPa"
                      defaultMessage="Fecha de pago"
                    />
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .filter((val) => {
                    if (searchTerm === "") {
                      return val;
                    } else if (
                      /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                      val.RECT_idfolio?.RECT_numerofolio?.toLowerCase().includes(
                        searchTerm.toLowerCase()
                      ) ||
                      val.POL_idpoliza?.POL_numpoliza.toLowerCase().includes(
                        searchTerm.toLowerCase()
                      )
                    ) {
                      console.log(val);
                      return val;
                    }
                  })
                  .map((row) => (
                    <TableRow key={row.RECT_idfolio.RECT_idfolio}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        <RadioInput
                          name="rb"
                          value={row.RECT_idfolio.RECT_idfolio}
                          onChange={rbClick}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {" "}
                        {row.RECT_idfolio?.RECT_numerofolio}{" "}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {" "}
                        {row.POL_idpoliza?.POL_numpoliza}{" "}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {" "}
                        {moment(row.POL_idpoliza?.POL_polizafecemisión).format(
                          formatofecha
                        )}{" "}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {" "}
                        {moment(row.POL_idpoliza?.POL_polizapahasta).format(
                          formatofecha
                        )}{" "}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {" "}
                        {moment(row.POL_idpoliza?.POL_polizafecpago).format(
                          formatofecha
                        )}{" "}
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={registro.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={
              <FormattedMessage
                id="globRegPa"
                defaultMessage="Registros por página:"
              />
            }
          />
        </Paper>
        <br />
        <Grid container spacing={5}>
          <Grid item>
            {/* El uso de Link es para poder pasar valores a el link necesario por to
          El valor que estamos pasando es por medio de la varibale de Row */}

            <ButtonVino
              variant="contained"
              color="primary"
              size="small"
              estatus={btnDisable}
              label={
                <FormattedMessage id="globConsul" defaultMessage="Consultar" />
              }
              onClick={() => setShowForm(false)}
            />

            <br />
          </Grid>
          <br />
        </Grid>
        <br />
        <br />
      </div>
    );
  }
  if (!showForm) {
    return (
      <>
        <Grid container>
          <Grid xs={12}>
            <h3-header-card>
              <FormattedMessage
                id="datosDaAse"
                defaultMessage="Datos del asegurado"
              />
            </h3-header-card>
          </Grid>
          <Grid xs={12}></Grid>
          <br />
          <br />
          <Grid xs={3}>
            <h3-title-card>
              <FormattedMessage id="datosNombs" defaultMessage="Nombre(s)" />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {datos ? datos[0]?.PER_idpersona?.PER_nombre : ""}
            </h3-subtitle-card>
          </Grid>
          <Grid xs={3}>
            <h3-title-card>
              <FormattedMessage
                id="datosApPat"
                defaultMessage="Apellido Paterno"
              />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {datos ? datos[0]?.PER_idpersona?.PER_apellidopaterno : ""}
            </h3-subtitle-card>
          </Grid>
          <Grid xs={3}>
            <h3-title-card>
              <FormattedMessage
                id="datosApMat"
                defaultMessage="Apellido Materno"
              />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {datos ? datos[0]?.PER_idpersona?.PER_apellidomaterno : ""}
            </h3-subtitle-card>
          </Grid>
          <Grid xs={3}>
            <h3-title-card>
              <FormattedMessage
                id="globFechNa"
                defaultMessage="Fecha de nacimiento"
              />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {datos
                ? moment(datos[0]?.PER_idpersona?.PER_fechanacimiento).format(
                    formatofecha
                  )
                : ""}
            </h3-subtitle-card>
          </Grid>

          <Grid xs={12}></Grid>
          <br />
          <br />

          <Grid xs={3}>
            <h3-title-card>
              <FormattedMessage id="globRfcp" defaultMessage="R.F.C" />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {datos ? datos[0]?.PER_idpersona?.PER_RFC : ""}
            </h3-subtitle-card>
          </Grid>
          <Grid xs={3}>
            <h3-title-card>
              <FormattedMessage id="globCurp" defaultMessage="CURP" />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {datos ? datos[0]?.PER_idpersona?.PER_CURP : ""}
            </h3-subtitle-card>
          </Grid>
          <Grid xs={3}>
            <h3-title-card>
              <FormattedMessage
                id="regisFeRec"
                defaultMessage="Fecha de recepción"
              />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {datos
                ? moment(datos[0].RECT_idfolio?.RECT_fecharecepcion).format(
                    formatofecha
                  )
                : ""}
            </h3-subtitle-card>
          </Grid>
        </Grid>
        <br></br>
        <hr></hr>
        <br></br>
        <Grid container>
          <Grid xs={12}>
            <h3-header-card>
              <FormattedMessage
                id="recepDatSi"
                defaultMessage="Datos del siniestro"
              />
            </h3-header-card>
          </Grid>
          <Grid xs={12}></Grid>
          <br />
          <br />
          <Grid xs={3}>
            <h3-title-card>
              <FormattedMessage
                id="globTiDeSi"
                defaultMessage="Tipo de siniestro"
              />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {datos
                ? datos[0]?.RECT_idfolio?.SINC_idcomplejidadsiniestro
                    ?.TRA_destipotramitesiniestro
                : ""}
            </h3-subtitle-card>
          </Grid>
          <Grid xs={3}>
            <h3-title-card>
              <FormattedMessage
                id="regisRegPo"
                defaultMessage="Póliza siniestrada"
              />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {IDCharola?.POL_idpoliza?.POL_numpoliza}
            </h3-subtitle-card>
          </Grid>

          <Grid xs={3}>
            <h3-title-card>
              <FormattedMessage id="globEstatu" defaultMessage="Estatus" />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {datos
                ? datos[0]?.RECT_idfolio?.TEST_idestatustramite
                    ?.TEST_estatustramite
                : ""}
            </h3-subtitle-card>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end">
          <ButtonVino
            label={
              <FormattedMessage id="globRegres" defaultMessage="Regresar" />
            }
            onClick={() => setShowForm(true)}
            estatus={false}
          />
        </Grid>
      </>
    );
  }
}
