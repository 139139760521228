import React, { useState } from "react";
import "../../css/estilo.css";
import Recorrido from "../Tools/BreadCrumbs/Recorrido";
import TableAgregarDocumento from "./Elements/Tables/TableAgregarDocumento";
import { Grid } from "@mui/material";
import ReturnPage from "../Tools/Bottones/ButtonReturn";

//Idioma
import { FormattedMessage } from "react-intl";
import { langContext } from "../../Context/langContext";
import { useContext } from "react";
import CustomSeparatorDinamic from "../Tools/BreadCrumbs/RecorridoDinamico";

export function CompAgregarDocumentos() {
  //importación para el idioma
  const idioma = useContext(langContext);
  const arrayBreadcrumbs = [
    {
      key: 1,
      name: <FormattedMessage id="globInicio" defaultMessage="Inicio" />,
      to: "/menu",
    },
    {
      key: 2,
      name: <FormattedMessage id="globRecepc" defaultMessage="Recepción" />,
      to:"/menu"
    },
  ];
  return (
    <div className="div-parent">
      {/*Enlaces de inicio */}
      <div className="div-breadcrumbs">
        {/*navegador*/}
        <CustomSeparatorDinamic
          breadcrumbs={arrayBreadcrumbs}
          name={`Agregar documentos`}
        />
      </div>
      {/**Boton regresar */}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <ReturnPage href="/menu" />
      </Grid>
      <div className="div-container">
        {/*Tabla recepcion */}
        <TableAgregarDocumento />
      </div>
    </div>
  );
}
