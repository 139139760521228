import React from "react";
import { CompConstRegistro } from "../../../components/Registro/CompConstRegistro";
import MyComponent from "../../../components/Tools/ButtonLoading/ButtonLoading";

export function ConstRegistro() {
  return (
    <div>
      <MyComponent componentToRender={CompConstRegistro} />
    </div>
  );
}
