import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState, useEffect} from "react";
import { Grid } from "@mui/material";
import RadioInput from "../../Tools/RadioButton/RadioInput";
import TXTField from "../../Tools/TextField/TextField";
//Boton debajo de la tabla
import DialogPdNacional from "../Dialogs/DialogPdNacional"
//estilos de la tabla
import StyledTableCell from "../../Tools/Table/StyledTableCell";
import TablePagination from "@mui/material/TablePagination";
//Idioma
import { FormattedMessage } from "react-intl";
import {getDataToken} from "../../../api/dataToken";
import { BASE_API } from "../../../utils/constans";
import { getToken } from '../../../api/token';

export default function TableAccounts2() { 
  const token = getToken();

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);
  //Creación de metodo get
  const [cuentas, setCuentas] = useState([]);    
  const getData = async () => {
    try {
      var result = await getDataToken("cuentas/get_listado/");
      console.log("ojala salga", result);
      setCuentas(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  /* CONSTANTES DE ESTADOS*/
  //const [data, setData] = useState(cuentas); // esta constante guarda un arreglo en donde se almacenará los datos de la API
 const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla
  const [InfoDataRow, setInfoDataRow] = useState(""); //Aqui es donde almacenará los datos de la fila seleccionadad
  const [btnDownloadInfo, setBtnDownloadInfo] = useState(true);
  const [btnDisable, setBtnDisable] = useState(true);
  const [deletedRows, setDeletedRows] = useState([]);//Estado para eliminar un registro

  /* FUNCIÓN CLICK EN LOS RADIO BUTTON, ARROJA INFORMACION DE LA FILA SELECCIONADA */
  const rbClick = (e) => {
    const inputCuenta = e.target.value;
    console.log(inputCuenta);
    cuentas.map((row) => {
      if (row.CTA_numcuenta == inputCuenta) {
        console.log(inputCuenta);
        console.log(row.CTA_numcuenta)
        console.log(row);
        setInfoDataRow(row);
        setBtnDownloadInfo(false);
        setBtnDisable(false);
        // Se agrega la fila seleccionada a deletedRows
        setDeletedRows((prevState) => [...prevState, row]);
      }
    });
  };


   //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
   const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
   const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10
 
   const handleChangePage = (event, newPage) => {
     setPage(newPage);
   };
   const handleChangeRowsPerPage = (event) => {
     setRowsPerPage(+event.target.value);
     setPage(0);
   };


    // Metodo para eliminar los datos de la tabla
    function putEliminar(id) {
      console.log("Entre al put")
      let response = fetch(`${BASE_API}cuentas/get_id/` + id + '/', {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({ CTA_activo: false }),
      });
      response.then((response) => {
        console.log(response);
        getData(); // Actualizar la tabla después de eliminar
      });
  
      console.log(id);
    }
    // Aqui termina la Funcion para eliminar
  
    useEffect(() => {
      getData();
      const interval = setInterval(getData, 90000);
  
      return () => {
        clearInterval(interval);
      };
    }, []);

      //FUNCION PARA ELIMINAR UN REGISTRO
  const handleDeleteRows = () => {
    console.log("delete rows")
    const newData = cuentas.filter((row) => !deletedRows.includes(row));
    setCuentas(newData);
    setDeletedRows([]);
    
  };

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item>
          <br/>
          <h3-header-card><FormattedMessage id="estconBaCu" defaultMessage="Baja de cuenta"/></h3-header-card>
        </Grid>
      </Grid>
      <Grid
        style={{ paddingBottom: "20px" }}
        container
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={4}>
          <h4-search>
          <FormattedMessage id="globBuscar" defaultMessage="Buscar"/>
          </h4-search>
          <TXTField
            showSearchIcon={true}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                <StyledTableCell align="center"><FormattedMessage id="estCueMayo" defaultMessage="Cuenta del mayor"/></StyledTableCell>
                <StyledTableCell align="center"><FormattedMessage id="estcoSubCu" defaultMessage="Subcuenta"/></StyledTableCell>
                <StyledTableCell align="center">Subsubcuenta </StyledTableCell>
                <StyledTableCell align="center"><FormattedMessage id="loginCuent" defaultMessage="Cuenta"/></StyledTableCell>
                <StyledTableCell align="center">Nombre</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {cuentas
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                    (val.CTAM_idcuentamayor?.CTAM_numcuenta).toString().includes(searchTerm) ||
                    (val.CTA_numcuenta).toString().includes(searchTerm) ||
                    val.CTA_nombre.toLowerCase().includes(searchTerm.toLowerCase())
                  
                  ) {
                    return val;
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.CTA_numcuenta?.CTA_numcuenta}>
                    <StyledTableCell align="center">
                      <RadioInput
                        name="rb"
                        value={row.CTA_numcuenta}
                        onChange={rbClick}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center"> {row.CTAM_idcuentamayor?.CTAM_numcuenta} </StyledTableCell>
                    <StyledTableCell align="center"> {row.SCTA_idsubcuenta?.SCTA_numsubcuenta} </StyledTableCell>
                    <StyledTableCell align="center"> {row.SSCTA_idsubsubcuenta?.SSCTA_numsubsubcuenta} </StyledTableCell>
                    <StyledTableCell align="center"> {row.CTA_numcuenta} </StyledTableCell>
                    <StyledTableCell>{row.CTA_nombre}</StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={cuentas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelRowsPerPage="Filas por página"
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <br />
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" >
          <Grid item>

          <DialogPdNacional
            mainButton={<FormattedMessage id="estconDaBa" defaultMessage="Dar de baja"/>}
            estatus={btnDisable}
            title={<FormattedMessage id="GlobEstCon" defaultMessage="Confirmación"/>}
            //La variable que estamos imprimiento se pasa mediante una variable que almacena todos los 
            //Datos seleccionados y solo coloca el que necesitamos o pedimos
            description={`Está a punto de dar de baja la cuenta ${InfoDataRow.CTA_numcuenta} ¿está seguro de realizar los cambios?`}
            firstButton={<FormattedMessage id="globAceptr" defaultMessage="Aceptar"/>}
            onClick={() => putEliminar(InfoDataRow.id)}
            secondButton={<FormattedMessage id="globCance" defaultMessage="Cancelar"/>}
          /> 

          </Grid>
        </Grid>


    </div>
  );
}