import React, { useState, useEffect } from "react";
import "../../css/estilo.css";
import Recorrido from "../Tools/BreadCrumbs/Recorrido3";
import { Grid } from "@mui/material";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import DatePicker from "../Tools/DatePicker/DatePicker";
import ButtonVino from "../Tools/Bottones/ButtonVino";
import TableTotalFolios from "../Workflow/Tables/TableTotalFolios";
import { FormattedMessage } from "react-intl"; //Multilenguaje
import { BASE_API } from "../../utils/constans";
import { getToken } from "../../api/token";
import dayjs from "dayjs";

export default function CompConsultasWF() {
  const token = getToken();
  const [fechaInicio, setFechaInicio] = useState(dayjs());
  const [fechaFin, setFechaFin] = useState(dayjs());
  const [folios, setFolios] = useState([]);
  const [buscarFolios, setBuscarFolios] = useState(false);

  const handleClickBuscarFolios = () => {
    setBuscarFolios(true);
  };

  useEffect(() => {
    // Fetch folios based on fechaInicio and fechaFin
    if (buscarFolios) {
      console.log("Realizando búsqueda...");
      // Convierte las fechas a un formato adecuado para enviar como parámetros
      const formattedFechaInicio = fechaInicio.format("YYYY-MM-DD");
      const formattedFechaFin = fechaFin.format("YYYY-MM-DD");

      fetch(
        `${BASE_API}wktramite/get_listado/?fechaInicio=${formattedFechaInicio}&fechaFin=${formattedFechaFin}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((data) => data.json())
        .then((data) => {
          // Filtra los datos que coinciden con el rango de fechas
          const filteredData = data.filter((item) => {
            const fechaActualizo = dayjs(item.WKT_actualizofecha);

            return (
              fechaActualizo.isAfter(fechaInicio) &&
              fechaActualizo.isBefore(fechaFin.endOf("day"))
            );
          });
          setFolios(filteredData);
          console.log("Resultados de la búsqueda:", filteredData);
        })
        .catch((error) => {
          console.error("Error al realizar la búsqueda:", error);
        })
        .finally(() => {
          // Restablecer el estado buscarFolios después de completar la búsqueda
          // setBuscarFolios(false);
        });
    }
  }, [buscarFolios, fechaInicio, fechaFin, token]);

  // console.log("Inicio", fechaInicio);
  // console.log("Fin", fechaFin);
  return (
    <div className="div-parent">
      <div className="div-breadcrumbs">
        <Recorrido
          name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
          enlace="/menu"
          name2="Workflow"
          enlace2="/workflow"
          name3={
            <FormattedMessage id="wfConsulta" defaultMessage="Consultas" />
          }
          enlace3=""
        />
        {/**Insercion boton regresar  */}
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
          <ReturnPage href="/menu" />
        </Grid>
        {/**termino de boton regresar */}
      </div>
      <div className="div-container">
        <Grid container>
          {/*Datepicker de fechas de inicio y fin */}
          <Grid xs={3}>
            <h3-subtitle-card>
              {<FormattedMessage id="estconFeIn" defaultMessage="Fecha inicio" />}
            </h3-subtitle-card>
            <br />
            <DatePicker
              fecha={fechaInicio}
              onDataChange={(newValue) => setFechaInicio(newValue)}
              id="fechaInicio"
            />
          </Grid>
          <Grid xs={1}></Grid>
          <Grid xs={3}>
            <h3-subtitle-card>
              {<FormattedMessage id="estFechFin" defaultMessage="Fecha fin" />}
            </h3-subtitle-card>
            <br />
            <DatePicker
              fecha={fechaFin}
              onDataChange={(newValue) => setFechaFin(newValue)}
              id="fechaFin"
            />
          </Grid>
          {/**Boton buscar cuando se realiza una busqueda entre fechas mostrara los folios registrados en una tabla */}
          <Grid xs={0.2}></Grid>
          <Grid xs={2}>
            <br />
            <br />
            <br />
            <ButtonVino
              label={
                <FormattedMessage id="globBuscar" defaultMessage="Buscar" />
              }
              onClick={handleClickBuscarFolios}
            />
          </Grid>
          {/**Tabla de folios total */}
          <Grid xs={12}>
            <br />
            {buscarFolios ? <TableTotalFolios folios={folios} /> : null}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
