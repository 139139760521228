import React from "react";
import {
  Box,
  Grid,
  Container,
  Alert,
  AlertTitle,
  IconButton,
} from "@mui/material";
import PasswordTXTField from "../Tools/TextField/TextFieldPass";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DialogButton from "../Tools/Dialog/DialogButton";
import familia from "../../img/familia.png";
import { getUsers, putDataLogin } from "./apiLogin/getUsers";
import { BASE_API } from "../../utils/constans";
import "./Login.css";
import "../../css/estilo.css";
import { isPassword } from "../../utils/functions";

//idioma
import { FormattedMessage } from "react-intl";

export function CompRestablecerContra(props) {
  const [values, setValues] = React.useState({
    passwordNew: "",
    passwordConfirm: "",
  });

  const [errorsPasswordNew, setErrorsPasswordNew] = React.useState({});
  const [errorsPasswordConfirm, setErrorsPasswordConfirm] = React.useState({});
  const [Successful, setSuccessful] = React.useState();
  const [valid, setvalid] = React.useState(true);
  const [link, setLink] = React.useState({});
  const [today, setToday] = React.useState(new Date());
  const [user, setUser] = React.useState(null);
  const [visible, setVisible] = React.useState(true);
  const [tokenURL, setTokenURL] = React.useState("");
  const [formattedDate, setformattedDate] = React.useState({});
  const [Succes, setSuccess] = React.useState();
  const [state, setState] = React.useState(true);

  // Validamos el tiempo, en cuanto pase 10 minutos colocamos el valor a false.

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, props.time);

    return () => clearTimeout(timer);
  }, [props.time]);

  // Traer la fecha del día de hoy
  React.useEffect(() => {
    const dd = today.getDate();
    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();

    const Date = yyyy + "-" + mm + "-" + dd;
    setformattedDate(Date);
  }, []);

  console.log("Today is: " + formattedDate);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  // obtener el token de la URL

  const tokenurl = new URLSearchParams(window.location.search).get("token");

  // Verificamos que el token pertenezca a un usuario
  React.useEffect(() => {
    getUsers(`${BASE_API}login/tokenuser/${tokenurl}/`)
      .then((data) => {
        setUser(data.SNYTLOG_email);
        setTokenURL(data.SNYTLOG_tokenurl);
        setSuccess(true);
        // // Decodificar el token para obtener su contenido
        const tokenContent = JSON.parse(atob(tokenurl));

        // // Obtener la fecha de expiración del token (suponiendo que el campo se llama 'exp')
        const expirationTimestamp = tokenContent.exp;
        console.log(expirationTimestamp);

        // // Convertir el timestamp a Date
        const expirationDate = new Date(expirationTimestamp * 1000);
        console.log(expirationDate);

        // // Verificar si la fecha de expiración es posterior a la fecha y hora actual
        if (tokenurl === "") {
          window.location.href = "/";
        } else if (expirationDate > new Date()) {
          console.log("El token aún es válido.");
        } else {
          console.log("El token ha expirado.");
          setVisible(false);
          setTimeout(() => {
            let aux = {
              SNYTLOG_email: user,
              SNYTLOG_tokenurl: "",
            };
            try {
              var result = putDataLogin(`login/putall/${user}/`, aux);
              console.log("Actualizado correctamente", result);
            } catch (error) {
              setVisible(false);
              console.log(error);
              throw error;
            }
          }, 5000);
        }
      })
      .catch((error) => {
        setState(false);
        setSuccessful(false);
        console.log(Successful);
        console.error("There was an error:", error);
        if (Successful === false) {
          window.location.href = "/";
        } else if (tokenURL === "") {
          window.location.href = "/";
        }
      });
  }, []);

  console.log(user);
  console.log(tokenURL);
  console.log(Succes);
  console.log(state);

  //PUT Restablecer contra

  async function updatePassword(aux) {
    try {
      var result = await putDataLogin(`login/put/${user}/`, aux);
      console.log("Actualizado correctamente", result);
    } catch (error) {
      console.log(error);
      setvalid(false);
      console.log(valid);
      throw error;
    }
  }

  //PUT ALL Restablecer contra

  async function updatePasswordAll(aux) {
    try {
      var result = await putDataLogin(`login/putall/${user}/`, aux);
      console.log("Actualizado correctamente", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const validateRestorePassword = (e) => {
    e.preventDefault();

    const errorsPasswordNew = {};
    const errorsPasswordConfirm = {};
    const link = {};

    errorsPasswordNew.password = (
      <FormattedMessage id="fueServ" defaultMessage="Fuera de servicio" />
    );
    errorsPasswordConfirm.password = (
      <FormattedMessage
        id="peIntTar"
        defaultMessage="No es posible realizar su petición en este momento, intente más tarde."
      />
    );

    if (!isPassword(values.passwordNew)) {
      errorsPasswordNew.password = (
        <FormattedMessage
          id="globErrorEnContrasen"
          defaultMessage="Error en contraseñas"
        />
      );
      errorsPasswordConfirm.password = (
        <FormattedMessage
          id="globLasCon"
          defaultMessage="Las contraseñas no cumplen con el formato."
        />
      );
    } else if (
      values.passwordNew === values.passwordConfirm &&
      visible === true &&
      Succes === true
    ) {
      let aux = {
        SNYTLOG_email: user,
        password: values.passwordConfirm,
        SNYTLOG_actualizofecha: today,
        SNYTLOG_tokenurl: "",
      };
      updatePassword(aux);
      if (valid === false) {
        errorsPasswordNew.password = (
          <FormattedMessage id="fueServ" defaultMessage="Fuera de servicio" />
        );
        errorsPasswordConfirm.password = (
          <FormattedMessage
            id="peIntTar"
            defaultMessage="No es posible realizar su petición en este momento, intente más tarde."
          />
        );
      } else {
        errorsPasswordNew.password = (
          <FormattedMessage id="loginActNu" defaultMessage="Contraseña nueva" />
        );
        errorsPasswordConfirm.password = (
          <>
            <FormattedMessage
              id="globSeGene"
              defaultMessage="Se generó una contraseña nueva exitosamente."
            />
            <br />
            <FormattedMessage
              id="globIniciaSesionPara"
              defaultMessage="Inicia sesión para continuar."
            />
          </>
        );
        values.passwordNew = "";
        values.passwordConfirm = "";
        link.password = "/";
      }
    } else if (values.passwordNew != values.passwordConfirm) {
      errorsPasswordNew.password = (
        <FormattedMessage
          id="globErrorAlActualiza"
          defaultMessage="Error al actualizar contraseña"
        />
      );
      errorsPasswordConfirm.password = (
        <FormattedMessage
          id="globLasContraseNasNo"
          defaultMessage="Las contraseñas no coinciden por favor verifica e intenta de nuevo."
        />
      );
    } else if (state === false) {
      errorsPasswordNew.password = (
        <FormattedMessage id="fueServ" defaultMessage="Fuera de servicio" />
      );
      errorsPasswordConfirm.password = (
        <FormattedMessage
          id="peIntTar"
          defaultMessage="No es posible realizar su petición en este momento, intente más tarde."
        />
      );
    }

    setErrorsPasswordNew(errorsPasswordNew);
    setErrorsPasswordConfirm(errorsPasswordConfirm);
    setLink(link);
  };

  // si visible es false, nos quitara el token

  if (visible === false) {
    let aux = {
      SNYTLOG_email: user,
      SNYTLOG_tokenurl: "",
    };
    updatePasswordAll(aux);
  }

  return (
    <form onSubmit={validateRestorePassword}>
      {visible && (
        <div className="div-parent">
          <Box px={{ xs: 4, sm: 10 }} py={{ xs: 1, sm: 10 }}>
            <Container maxWidth="lg">
              <Grid container spacing={5}>
                <Grid item xs={12} sm={5}>
                  <Box>
                    <IconButton
                      id="iconRegresar"
                      size="large"
                      color="primary"
                      href="/"
                    >
                      <ArrowBackIcon fontSize="inherit" />
                    </IconButton>
                    <br />
                    <br />
                    <h2-login-title>
                      <FormattedMessage
                        id="loginResCo"
                        defaultMessage="Restablecer contraseña"
                      />
                    </h2-login-title>
                    <hr class="hr-line" align="left" />
                    <p align="justify">
                      <h3-login-subtitle>
                        <FormattedMessage
                          id="loginIngreUnaConRecu"
                          defaultMessage="Ingresa una nueva contraseña, recuerda que debe tener mínimo 8 caracteres con una letra mayúscula, minúscula, un número y un carácter."
                        />
                      </h3-login-subtitle>
                    </p>
                    <PasswordTXTField
                      id="lblConNue"
                      label={
                        <FormattedMessage
                          id="loginActNu"
                          defaultMessage="Contraseña nueva"
                        />
                      }
                      type="password"
                      size="small"
                      required={true}
                      onChange={handleChange("passwordNew")}
                      value={values.passwordNew}
                    />
                    <PasswordTXTField
                      id="lblConfContra"
                      label={
                        <FormattedMessage
                          id="loginCofCo"
                          defaultMessage="Confirmar contraseña"
                        />
                      }
                      type="password"
                      size="small"
                      required={true}
                      onChange={handleChange("passwordConfirm")}
                      value={values.passwordConfirm}
                    />
                    <br />
                    <br />
                    <br />
                    <Grid container spacing={12}>
                      <Grid item xs={1} sm={5}>
                        <Box></Box>
                      </Grid>
                      <Grid item xs={1} sm={2}>
                        <Box></Box>
                      </Grid>
                      <Grid item xs={1} sm={4}>
                        <Box>
                          <DialogButton
                            id1="btnAceptar"
                            id2="btnActAcep"
                            mainButton={
                              <FormattedMessage
                                id="globAceptr"
                                defaultMessage="Aceptar"
                              />
                            }
                            title={errorsPasswordNew.password}
                            description={errorsPasswordConfirm.password}
                            button={
                              <FormattedMessage
                                id="globAceptr"
                                defaultMessage="Aceptar"
                              />
                            }
                            type="submit"
                            to={link.password}
                            estatus={
                              values.passwordNew.length > 0 &&
                              values.passwordConfirm.length > 0
                                ? false
                                : true
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Box>
                    <br />
                    <img
                      src={familia}
                      alt="Generando confianza en tus aplicaciones"
                      width="150%"
                      height="150%"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box></Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </div>
      )}
      {!visible && (
        <>
          <br />
          <br />
          <div className="div-parent">
            <Alert severity="error">
              <AlertTitle>
                <strong>
                  <FormattedMessage
                    id="tiemAgo"
                    defaultMessage="¡Tu tiempo se ha agotado, suerte para la próxima!"
                  />
                </strong>
              </AlertTitle>
            </Alert>
          </div>
        </>
      )}
    </form>
  );
}
