import { Dictamen } from "../../pages/Client/ModDictamen/Dictamen";
import { DetalleFolio } from "../../pages/Client/ModDictamen/DetalleFolio";
import { DictamenFolioX } from "../../pages/Client/ModDictamen/DetalleFolioX";
import { ClientLayaout } from '../../layouts/Client/ClientLayaout';
import { DictamenTecnico } from "../../pages/Client/ModDictamen/DictamenTecnico";
import { DictamenJuridico } from "../../pages/Client/ModDictamen/DictamenJuridico";
import { DictamenPolOrigen } from "../../pages/Client/ModDictamen/DictamenPolOrigen";
import { DictamenGenCarta } from "../../pages/Client/ModDictamen/dictamenGenCarta";
import { DictamenReservas } from "../../pages/Client/ModDictamen/DictamenReservas";
import { DictamenReserAjustes } from "../../pages/Client/ModDictamen/DictamenReserAjustes";
import { DictamenReserRegis } from "../../pages/Client/ModDictamen/DictamenReserRegis";
import { DictamenReserAjusAcc } from "../../pages/Client/ModDictamen/DictamenReserAjusAcc";
import { DictamenPruebas } from "../../pages/Client/ModDictamen/DictamenPruebas";
import CompDictamenReserConsul from "../../components/Dictamen/CompConsultaContable";
import CompRealizarPagos from "../../components/Dictamen/CompRealizarPagos";
import CompDictamenInvestigacion from "../../components/Dictamen/CompInvestigacion";
import CompDictamenTramAut from "../../components/Dictamen/DictamenTramAut";
import CompFiniquito from "../../components/Dictamen/CompFiniquito";
import CompReactivar from "../../components/Dictamen/CompReactivar";




const routesClient = [
    {
        path: "/dictamen",
        layout: ClientLayaout,
        component: Dictamen,
        exact: true,
    },
    {
        path: "/dictamen/detalle_folio",
        layout: ClientLayaout,
        component: DetalleFolio,
        exact: true,
    },
    {
        path: "/dictamen/detalle_folio/folio",
        layout: ClientLayaout,
        component: DictamenFolioX,
        exact: true,
    },
    {
        path: "/dictamen/detalle_folio/tecnico",
        layout: ClientLayaout,
        component: DictamenTecnico,
        exact: true,
    },
    {
        path: "/dictamen/detalle_folio/juridico",
        layout: ClientLayaout,
        component: DictamenJuridico,
        exact: true,
    },
    {
        path: "/dictamen/detalle_folio/poliza_origen",
        layout: ClientLayaout,
        component: DictamenPolOrigen,
        exact: true,
    },
    {
        path: "/dictamen/detalle_folio/generar_carta",
        layout: ClientLayaout,
        component: DictamenGenCarta,
        exact: true,
    },
    {
        path: "/dictamen/detalle_folio/reservas",
        layout: ClientLayaout,
        component: DictamenReservas,
        exact: true,
    },
    {
        path: "/dictamen/detalle_folio/reservas/ajustes",
        layout: ClientLayaout,
        component: DictamenReserAjustes,
        exact: true,
    },
    {
        path: "/dictamen/detalle_folio/reservas/registro",
        layout: ClientLayaout,
        component: DictamenReserRegis,
        exact: true,
    },
    {
        path: "/dictamen/detalle_folio/reservas/ajuste_acc",
        layout: ClientLayaout,
        component: DictamenReserAjusAcc,
        exact: true,
    },
    {
        path: "/dictamen/pruebas",
        layout: ClientLayaout,
        component: DictamenPruebas,
        exact: true,
    },
    {
        path: "/dictamen/detalle_folio/reservas/consulta_cont",
        layout: ClientLayaout,
        component: CompDictamenReserConsul,
        exact: true,
    },  
    {
        path: "/dictamen/detalle_folio/realizar_pagos",
        layout: ClientLayaout,
        component: CompRealizarPagos,
        exact: true,
    },  
    {
        path: "/dictamen/investigacion",
        layout: ClientLayaout,
        component: CompDictamenInvestigacion,
        exact: true,
    },  
    {
        path: "/dictamen/tramites_autorizados",
        layout: ClientLayaout,
        component: CompDictamenTramAut,
        exact: true,
    }, 
    {
        path: "/dictamen/detalle_folio/finiquito",
        layout: ClientLayaout,
        component: CompFiniquito,
        exact: true,
    }, 
    {
        path: "/dictamen/reactivar",
        layout: ClientLayaout,
        component: CompReactivar,
        exact: true,
    }, 
];

export default routesClient;