/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import Paper from "@mui/material/Paper";
import { Grid, Radio, RadioGroup, FormControlLabel } from "@mui/material";
//icons
import IconEliminar from "../../../Tools/Icons/IconEliminar";
//Dialogs
//idioma
import { FormattedMessage } from "react-intl";
import TXTField from "../../../Tools/TextField/TextField";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { Select1 } from "../../../Tools/Selectss/Select1";
import { getDataToken } from "../../../../api/dataToken";
import { LimitarTexto } from "../../../Recepcion/Elements/Functions/Validaciones";
import { IDCARTASOL } from "../../../../utils/constanstoken";
import TablePagination from "@mui/material/TablePagination";

export default function TableCartaSolicitudDoc(props) {
  const listaPolizas = props.listaPolizas;
  console.log(listaPolizas);
  //Funcion checkbox para seleccionar beneficiarios
  const [listaDocs, setListaDocs] = React.useState([]);
  //funcion para añadir destinatario a la lista

  React.useEffect(() => {
    //console.log('documentos seleccionados', listaDocs)
    props.onDataChange(listaDocs.length === 0);
    props.cartas(listaDocs);
    props.tipoCarta(IDCARTASOL);
  }, [listaDocs]);

  //////////////////////////////////
  const [datos, setDatos] = React.useState([
    {
      id: 1,
      check1: false,
      descripcion: "",
      tipo: "Original",
      texto: "",
    },
  ]);

  const agregarFila = () => {
    const primeraFila = datos[0]; // Obtener la primera fila

    // Crear una nueva fila con los mismos datos que la primera fila y valores por defecto
    const nuevaFila = {
      id: datos.length + 1,
      check1: false,
      descripcion: "",
      tipo: "Original",
      texto: "",
    };

    setDatos([...datos, nuevaFila]); // Agregar la nueva fila al estado de datos
  };

  const eliminarFila = (id) => {
    const filaEliminada = datos.find((fila) => fila.id === id);

    // Eliminar la fila correspondiente de datos
    const nuevasFilas = datos.filter((fila) => fila.id !== id);
    setDatos(nuevasFilas);

    // Eliminar la fila correspondiente de listaDocs si existe
    if (filaEliminada) {
      setListaDocs((prevState) =>
        prevState.filter((item) => item.id !== filaEliminada.id)
      );
    }
  };

  /////DATOS SELECT///////////
  //Estados para guardar los datos de api
  const [arrdocumentoapi, setarrdocumentoapi] = React.useState([]);

  // Traemos los datos de clasificación para llenar el combo
  const getDocumentosAPI = async () => {
    try {
      var result = await getDataToken(
        `documentosxtiposiniestrostramite/get_idTramite/${listaPolizas[0].tramite}/`
      );
      let array = result.map((element) => {
        return {
          ...element,
          identificador: element?.DOC_iddocumento?.DOC_iddocumento,
          texto: element?.DOC_iddocumento?.DOC_documento,
        };
      });
      setarrdocumentoapi(array);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getDocumentosAPI();
  }, []);

  console.log(arrdocumentoapi);

  const handleChange = (e, rowIndex) => {
    const selectedDocumentId = parseInt(e.target.value);

    const updatedData = datos.map((row, index) => {
      if (index === rowIndex) {
        const selectedDocument = arrdocumentoapi.find(
          (element) =>
            element.DOC_iddocumento.DOC_iddocumento === selectedDocumentId
        );

        const updatedRow = {
          ...row,
          descripcion: selectedDocument.DOC_iddocumento.DOC_documento,
        };

        handleUpdateList(updatedRow); // Agregar/eliminar de listaDocs
        return updatedRow;
      }
      return row;
    });

    setDatos(updatedData);
  };

  const handleRadioChange = (selectedValue, rowIndex, fieldName) => {
    const updatedData = datos.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          [fieldName]: selectedValue,
        };
      }
      return row;
    });

    setDatos(updatedData);

    // Extract document and check for updating listaDocs
    const updatedRow = updatedData[rowIndex];
    handleUpdateList(updatedRow); // Agregar/eliminar de listaDocs
  };

  const handleTextChange = (value, rowIndex) => {
    const updatedData = datos.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          texto: value,
        };
      }
      return row;
    });

    setDatos(updatedData);

    // Actualizar listadocs con el nuevo valor de texto en la misma posición
    const updatedRow = updatedData[rowIndex];
    handleUpdateList(updatedRow);
  };

  const handleUpdateList = (updatedRow) => {
    const { id, descripcion, tipo, texto } = updatedRow;

    // Encuentra el índice del elemento en listadocs
    const indexInListDocs = listaDocs.findIndex((item) => item.id === id);

    if (indexInListDocs !== -1) {
      // Si el elemento existe, actualiza su texto
      const updatedListDocs = [...listaDocs];
      updatedListDocs[indexInListDocs].tipo = descripcion;
      updatedListDocs[indexInListDocs].tipo = tipo;
      updatedListDocs[indexInListDocs].texto = texto;
      setListaDocs(updatedListDocs);
    } else {
      // Si no existe, crea un nuevo elemento en listadocs
      setListaDocs((prevState) => [
        ...prevState,
        { id, descripcion, tipo, texto },
      ]);
    }
  };

  console.log(datos);

  console.log(listaDocs);

  const [page, setPage] = React.useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = React.useState(5); //El tamaño inicial de las filas será de 10
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const filteredData = datos.slice(startIndex, endIndex);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 540 }} style={{ position: "sticky" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                <StyledTableCell>
                  <FormattedMessage
                    id="globDocume"
                    defaultMessage="Documento"
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <FormattedMessage
                    id="globOrigin"
                    defaultMessage="Tipo documento"
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <FormattedMessage id="globNotas" defaultMessage="Notas" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="estconElim" defaultMessage="Eliminar" />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {filteredData.map((row, index) => {
                return (
                  <TableRow key={row.id}>
                    <StyledTableCell>
                      <Select1
                        sx={{
                          background: "white",
                        }}
                        array={arrdocumentoapi}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <RadioGroup
                        value={row.radioField}
                        onChange={(e) =>
                          handleRadioChange(e.target.value, index, "tipo")
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FormControlLabel
                            value="Original"
                            control={<Radio />}
                            label="Original"
                          />
                          <FormControlLabel
                            value="Copia"
                            control={<Radio />}
                            label="Copia"
                          />
                        </div>
                        {/* Agrega más opciones según sea necesario */}
                      </RadioGroup>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <TXTField
                        value={row.texto}
                        onChange={(e) =>
                          handleTextChange(e.target.value, index)
                        }
                        onInput={(e) => LimitarTexto(e.target, 100)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IconEliminar onClick={() => eliminarFila(row.id)} />
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          component="div"
          count={datos.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Registros por página:"
        />
      </Paper>
      <br />
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={"auto"}>
          <ButtonVino
            variant="contained"
            color="primary"
            size="small"
            onClick={agregarFila}
            label={
              <FormattedMessage
                id="recepNuDoc"
                defaultMessage="Agregar documento"
              />
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
