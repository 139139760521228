/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import Paper from "@mui/material/Paper";
import { Grid, Modal } from "@mui/material";
import CheckGroup from "../../../Tools/CheckBox/CheckGroup";
//Dialogs
import DialogCartaEliminarDest from "../Dialogs/DialogCartaEliminarDest";
import DialogCartaAgregarOtros from "../Dialogs/DialogCartAgregarOtros";
import DialogCartaEditarOtros from "../Dialogs/DialogCartaEditarOtros";
//idioma
import { FormattedMessage } from "react-intl";
import { PERR_OTRO } from "../../../../utils/constanstoken";
//Document
import { useState } from "react";
import { useEffect } from "react";
import { getDataToken } from "../../../../api/dataToken";
import TablePagination from "@mui/material/TablePagination";

export default function TableCartaOtros(props) {
  // CONSTANTES / ESTADOS / VARIABLES
  const idPoliza = props.idPoliza;
  const informacion = props.informacion;

  //Funcion checkbox para seleccionar beneficiarios

  const [listaDest, setListaDest] = React.useState([]);
  //funcion para añadir destinatario a la lista
  const updateDestToList = (e, dest) => {
    if (e.target.checked) {
      setListaDest((prevState) => [...prevState, dest]);
    } else {
      //remove from array
      setListaDest((current) =>
        current.filter(
          (e) => e.PXP_idpersonaxpoliza !== dest.PXP_idpersonaxpoliza
        )
      );
    }
  };
  React.useEffect(() => {
    //console.log('destinatarios seleccionados', listaDest)
    props.onDataChange(listaDest.length === 0);
    props.destinatarios(listaDest);
  }, [listaDest]);

  console.log(listaDest);

  /** Funcionalidad y estados para asegurados segun la póliza ***/
  const [otro, setOtro] = useState([]);
  const getDataPersonasXPoliza = async () => {
    try {
      // Supongamos que idPoliza es un arreglo de IDs de pólizas
      const promises = idPoliza.map(async (id) => {
        const response = await getDataToken(
          `personasxpoliza/get_poliza/${id.idPoliza}/`
        );
        if (response) {
          return response.filter(
            (persona) =>
              persona?.PER_idpersona?.PERR_idrol?.PERR_idrol == PERR_OTRO.id
          );
        }
        return [];
      });

      const otrosArrays = await Promise.all(promises);
      const otrosConcatenados = otrosArrays.flat(); // Concatena los arreglos de asegurados

      console.log(otrosConcatenados);
      setOtro(otrosConcatenados);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  useEffect(() => {
    getDataPersonasXPoliza();
  }, []);

  const funcionActualizar = () => {
    getDataPersonasXPoliza();
  };

  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(5); //El tamaño inicial de las filas será de 10
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const filteredData = otro.slice(startIndex, endIndex);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Paper style={{ width: "100%" }}>
            <TableContainer style={{ position: "sticky" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                    <StyledTableCell>
                      <FormattedMessage
                        id="globSelecc"
                        defaultMessage="Seleccionar"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage
                        id="globNombre"
                        defaultMessage="Póliza"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage
                        id="globNombre"
                        defaultMessage="Nombre"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage id="globRfcp" defaultMessage="RFC" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage id="globCalle" defaultMessage="Calle" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage
                        id="globaNoExt"
                        defaultMessage="No. Ext."
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage
                        id="globaNoInt"
                        defaultMessage="No. Int."
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage
                        id="globColoni"
                        defaultMessage="Colonia"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage
                        id="dictamAlca"
                        defaultMessage="Alcaldía"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage id="globCP" defaultMessage="C.P." />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage
                        id="globCorEle"
                        defaultMessage="Correo electrónico"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="globEditar"
                        defaultMessage="Editar"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="estconElim"
                        defaultMessage="Eliminar"
                      />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
                  {filteredData.map((row) => {
                    return (
                      <TableRow key={row.PXP_idpersonaxpoliza}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <CheckGroup
                            checked={listaDest.some(
                              (item) =>
                                item.PXP_idpersonaxpoliza ===
                                row.PXP_idpersonaxpoliza
                            )}
                            onChange={(e) => updateDestToList(e, row)}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          {`${row?.POL_idpoliza?.POL_numpoliza}`}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                        >{`${row?.PER_idpersona?.PER_nombre} ${row?.PER_idpersona?.PER_apellidopaterno} ${row?.PER_idpersona?.PER_apellidomaterno}`}</StyledTableCell>
                        <StyledTableCell>
                          {row?.PER_idpersona?.PER_RFC}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.PER_idpersona?.PER_calle}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.PER_idpersona?.PER_numeroexterior}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.PER_idpersona?.PER_numerointerior}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.PER_idpersona?.SEP_idsepomex?.SEP_asenta}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.PER_idpersona?.SEP_idsepomex?.SEP_municipio}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.PER_idpersona?.SEP_idsepomex?.SEP_codpostal}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.PER_idpersona?.PER_correo}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <DialogCartaEditarOtros
                            rows={otro}
                            idOtros={row?.PXP_idpersonaxpoliza}
                            ejecutarConsulta={funcionActualizar}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <DialogCartaEliminarDest
                            title={
                              <FormattedMessage
                                id="dictaElimiAgen"
                                defaultMessage="Eliminar agente"
                              />
                            }
                            infoDataRow={row}
                            ejecutarConsulta={funcionActualizar}
                          />
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20, 25]}
              component="div"
              count={otro.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Registros por página:"
            />
          </Paper>
        </Grid>
        <Grid item>
          <br />
          <DialogCartaAgregarOtros
            informacion={informacion}
            polizas={idPoliza}
            ejecutarConsulta={funcionActualizar}
          />
        </Grid>
      </Grid>
    </>
  );
}
