import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ButtonVino from '../../Tools/Bottones/ButtonVino';
import { Grid } from "@mui/material";
import RadioDinamico from "../../Tools/RadioButton/RadioDinamico";
import { Link } from "react-router-dom";
import {CONSTITUCION_INTERESES_MORATORIOS} from "../../../utils/constanstoken";




//Aparecera en la segunda alerta dos botones
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function DialogConstitucion(props) {
//PARENT DIALOG
const [open, setOpen] = React.useState(false)

const handleClickOpen = () => { setOpen(true) }
const handleClose = () => {
    setOpen(false);
    setMovimiento1("");
}
//CHILDREN DIALOG
const [open2, setOpen2] = React.useState(false)
const handleClickOpen2 = () => { setOpen2(true) }
const handleClose2 = () => {
    setOpen2(false) // Close the children dialog
    //handleClose() // Close the parent dialog
}

//Lista del menu
const array = [
    { "id": "1", "value": "1", "label": "Siniestros por fallecimiento" },
    { "id": "2", "value": "2", "label": "Muerte accidental" },
    { "id": "3", "value": "3", "label": "Invalidez" },
    { "id": "4", "value": "4", "label": "Exención de pago de primas por desempleo" },
    { "id": "5", "value": "5", "label": "Enfermedad en fase terminal" },
    { "id": "6", "value": "6", "label": "Exención de pago de primas por invalidez" },
    { "id": "7", "value": "7", "label": "Exención de pago de primas por fallecimiento" },
    { "id": "8", "value": "8", "label": "Pérdida de miembros" },
    { "id": "9", "value": "9", "label": "Gastos funerarios" },
]
const [movimiento1, setMovimiento1] = React.useState("")
const handleRbChange = (e) => {
    console.log(e.target.value)
    setMovimiento1(e.target.value)
}

const [opcionRB, setOpcionRB] = React.useState({
    listaUno: { movimiento: "", descripcion: "" }
})
React.useEffect(() => {
    if (movimiento1 !== "") {
        let descripcion1 = "";
        switch (movimiento1) {//Este swit permitirá  crear un objeto segun lo que se seleccione en los radios inputs
            case "1": {
                descripcion1 = "Siniestros por fallecimiento"
            };
                break;
            case "2": {
                descripcion1 = "Muerte accidental"
            };
                break;
            case "3": {
                descripcion1 = "Invalidez"
            }; 
            break;
            case "4": {
                descripcion1 = "Exención de pago de primas por desempleo"
            };
            break;
            case "5": {
                descripcion1 = "Enfermedad en fase terminal"
            };
            break;
            case "6": {
                descripcion1 = "Exención de pago de primas por invalidez"
            };
            break;
            case "7": {
                descripcion1 = "Exención de pago de primas por fallecimiento"
            };
            break;
            case "8": {
                descripcion1 = "Pérdida de miembros"
            };
            break;
            case "9": {
                descripcion1 = "Gastos funerarios"
            };
                break;
            default: descripcion1 = "No hay descripcion a esa opción";
        }
        
        setOpcionRB({ ...opcionRB, 
            listaUno: { movimiento: movimiento1, descripcion: descripcion1}
        });
    }
}, [movimiento1]);

const handleClick = (event) => {
    setOpen(false); 
    props.onClick(event) //  servirá para revisar el cambio del valor del select desde el componente padre
};

     return (
         <div>
             {/* PARENT DIALOG */}
             <div>
                 {/*BOTON QUE HACE EL LLAMADO AL DIALOGO PADRE*/}
                 <ButtonVino onClick={handleClickOpen} label={CONSTITUCION_INTERESES_MORATORIOS.descripcion} estatus={props.disabledbtn} />
                 {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
                 <BootstrapDialog
                     fullWidth={true}
                     onClose={handleClose}
                     aria-labelledby="dialog"
                     open={open}
                 >
                     {/*CUERPO DEL DIALOGO*/}
                     {/*TITILO*/}
                     <BootstrapDialogTitle id="dialog" onClose={handleClose} style={{ color: '#761f2a' }}>
                         <h1-dialog-title>{CONSTITUCION_INTERESES_MORATORIOS.descripcion}</h1-dialog-title>
                     </BootstrapDialogTitle>
                     {/*CUERPO DEL DIALOGO*/}
                     {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
                     <DialogContent dividers style={{ minHeight: '300px' }}>
                         {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
             se necesita colocar entre un typogreaphy las veces que des salto de linea 
             o con un br*/}
                         <h2-dialog-description>Seleccione una opcion: </h2-dialog-description>
                         <RadioDinamico array={array} onChange={handleRbChange} />
                     </DialogContent>
                     {/*es la tercer sección que engloba los botones */}
                     <DialogActions>
 
                         {/*En esta parte se llena el segundo mensaje que debe de aparecer*/}
                         <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                             {/*<Grid item xs={'auto'}>
               <ButtonVino onClick={handleClickOpen2} label={props.firstButton} estatus={props.disabledBtn}/>
               </Grid>*/}
                             <Grid item xs={'auto'}>
                                 <ButtonVino onClick={handleClick} label={"Aceptar"} estatus={movimiento1 === "" ? true : false} />
                             </Grid>
                             <Grid item xs={'auto'}>
                                 <ButtonVino onClick={handleClose} label={"Cancelar"} estatus={props.disabledBtn} />
                             </Grid>
                         </Grid>
 
                     </DialogActions>
                 </BootstrapDialog>
             </div>
            
             
         </div>
     );

}