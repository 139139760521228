import React from "react";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import DialogEnviarMedico from "../Dialogs/DialogEnviarAMed";
import { getDataToken } from "../../../../api/dataToken";
import {
  IDMODMEDICO,
  IDMODDICTAMEN,
  TRAM_FALLECIMIENTO,
  TRAM_BENEFICIOSEV,
} from "../../../../utils/constanstoken";
import moment from "moment";
import { formatofecha } from "../../../../api/getUmbrales";
import GetTareas from "../../../../api/getTareas";

export default function CardConsultaMedic(props) {
  const idFolio = props.idFolio;
  const polizas = props.polizas;
  const tipoTramite =
    polizas[0]?.RECT_idfolio?.SINC_idcomplejidadsiniestro
      ?.TRA_idtipotramitesiniestro;
  console.log(idFolio);
  console.log(polizas);
  console.log(tipoTramite);
  const [Data, setData] = React.useState([]);
  const [guardadoMSG, setguardadoMSG] = useState([]);
  const getData = async () => {
    try {
      var result = await getDataToken(
        `dictamenmedicoxpolizacoberturaproducto/get_folio/${idFolio}/`
      );
      setData(result);
      var resultmsg = await getDataToken(
        `mensajes/historical_filter/?folio=${idFolio}&poliza=${polizas[0]?.POL_idpoliza?.POL_idpoliza}&modulo=${IDMODMEDICO}`
      );
      setguardadoMSG(resultmsg);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  console.log(Data);
  console.log(guardadoMSG);

  const resultado = guardadoMSG?.reduce((maxFecha, item) => {
    const fecha = new Date(item.MSG_actualizofecha);
    if (!maxFecha || fecha > maxFecha.fecha) {
      return { fecha, elemento: item };
    }
    return maxFecha;
  }, null);

  const elemento = resultado?.elemento;
  console.log(elemento);

  const objetosCoincidentes = GetTareas(IDMODDICTAMEN);
  console.log(objetosCoincidentes);

  return (
    <div>
      {tipoTramite === TRAM_FALLECIMIENTO.id ||
      tipoTramite === TRAM_BENEFICIOSEV.id ? (
        <Grid container>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={2}>
              <Grid>
                <h3-title-card>Clave OII:</h3-title-card>
              </Grid>
              <br />
              <Grid>
                <h3-subtitle-card>
                  {Data ? Data[0]?.OII_idoii?.OII_claveoii : ""}
                </h3-subtitle-card>
              </Grid>
              <br />
            </Grid>
            <Grid item xs={4}>
              <Grid>
                <h3-title-card>Descripción de la clave OII:</h3-title-card>
              </Grid>
              <br />
              <Grid>
                <h3-subtitle-card>
                  {Data ? Data[0]?.OII_idoii?.OII_descripcion : ""}
                </h3-subtitle-card>
              </Grid>
              <br />
            </Grid>
            <Grid item xs={3}>
              <Grid>
                <h3-title-card>Tipo de enfermedad:</h3-title-card>
              </Grid>
              <br />
              <Grid>
                <h3-subtitle-card>
                  {Data ? Data[0]?.DICTM_causadelaenfermedad : ""}
                </h3-subtitle-card>
              </Grid>
              <br />
            </Grid>
            <Grid item xs={3}>
              <Grid>
                <h3-title-card>
                  Fecha inicio de síntomas preexistentes:
                </h3-title-card>
              </Grid>
              <br />
              <Grid>
                <h3-subtitle-card>
                  {Data
                    ? moment(Data[0]?.DICTM_fechainiciosintomas).format(
                        formatofecha
                      )
                    : ""}
                </h3-subtitle-card>
              </Grid>
              <br />
            </Grid>
            <Grid item xs={6}>
              <Grid>
                <h3-title-card>Enfermedad excluida:</h3-title-card>
              </Grid>
              <br />
              <Grid>
                <h3-subtitle-card>
                  {Data ? Data[0]?.DICTM_exclusiones : ""}
                </h3-subtitle-card>
              </Grid>
              <br />
            </Grid>
            <Grid item xs={6}>
              <Grid>
                <h3-title-card>Dictamen médico:</h3-title-card>
              </Grid>
              <br />
              <Grid>
                <h3-subtitle-card>{elemento?.MSG_mensaje}</h3-subtitle-card>
              </Grid>
              <br />
            </Grid>
            <Grid
              container
              justifyContent="right"
              alignItems="right"
              spacing={2}
              marginTop={1}
            >
              <Grid item>
                <DialogEnviarMedico
                  mainButton="Enviar a médico"
                  title="Enviar a médico"
                  polizas={polizas}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div>
          <h3-title-card>
            Este tipo de trámite no quiere valoración médica.
          </h3-title-card>
        </div>
      )}
    </div>
  );
}
