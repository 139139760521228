import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import IconDocumentacionRegistro from "../../../Tools/Icons/IconDocumentacionRegistro";

export default function ScrollDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <a onClick={props.onClick1}>
        <IconDocumentacionRegistro
          onClick={handleClickOpen("paper")}
          label="scroll=paper"
        />
      </a>
      <Dialog
        open={open}
        // onClose={handleClose}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="scroll-dialog-title">
          <h1-dialog-title>{props.title} </h1-dialog-title>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {props.descriptionimg}
            {props.descriptionpdf}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonVino onClick={handleClose} label={props.label} />
        </DialogActions>
      </Dialog>
    </div>
  );
}
