import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import Check2 from "../../Tools/CheckBox/Check2";
//Botones
import ScrollDialog from "./Dialog/Documents";
import DatePicker from "../Elements/DatePicker/DatepickerSini";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import { getFiles } from "../../../nextcloud/functions";
import DialogFecha from "./Dialog/DialogFecha";
import { Link } from "react-router-dom";
import {
  IDESTATUSFALTINF,
  TRAM_FALLECIMIENTO,
  IDMODREGISTRO,
  IDEXTGRBPO,
  IDCAPFESINI,
  IDTAVALIDACION,
} from "../../../utils/constanstoken";
import { DATA_PM } from "../../../utils/constansPM";
//Encabezado
import Header from "./Header/Header";
//Idioma
import { FormattedMessage } from "react-intl";
//estilos de la tabla
import StyledTableCell from "../../Tools/Table/StyledTableCell";
//Icono de PDF
//importamos la libreria para el get
import {
  getDataToken,
  putDataToken,
  postDataToken,
} from "../../../api/dataToken";
import { formatofecha } from "../../../api/getUmbrales";
import moment from "moment-timezone";
import getDate from "../Function/obtenerFecha";
import getHours from "../Function/obtenerHora";
import DialogValidaciones from "./Dialog/DialogValidaciones";
import DialogBoton from "./Dialog/DialogBoton";
import GetTareas from "../../../api/getTareas";
import { TabPM } from "./Tabs/TabPM";
import { TabPMSinay } from "./Tabs/TabPMSinay";

export default function TableDataInsured(props) {
  //Constantes para poder obtener la información
  const infoDataRow = props.infoRow;
  const paso = infoDataRow; //le asignamos la información que traemos desde la primer tabla a una variable para poder manejarla
  console.log("Estoy pasando el valor ", paso);

  //Obtenemos las tareas para nuestro módulo
  const objetosCoincidentes = GetTareas(IDMODREGISTRO);
  console.log(objetosCoincidentes);

  //Creción de constantes  para poder usar las apis
  const [registro, setRegistros] = useState([]); //Guarda toda la información que trae la api
  const [folio, setFolio] = useState(paso?.RECT_idfolio?.RECT_idfolio); //Guarda toda la información que trae la api
  const [verFolderFolio, setVerFolderFolio] = React.useState(
    paso?.RECT_idfolio?.RECT_numerofolio
  );
  const [idTramite, setidTramite] = React.useState(
    paso?.RECT_idfolio?.SINC_idcomplejidadsiniestro?.TRA_idtipotramitesiniestro
  );
  const [btnValidar, setbtnValidar] = useState(true); //Botón para validar fecha
  const [btnValidar2, setbtnValidar2] = useState(false); //Botón para validar fecha

  //Obtener fecha
  const fecha = getDate();
  const fechaEmpAn = moment(fecha, "DD/MM/YYYY").format("YYYY-MM-DD");

  //Obtener la hora
  const hora = getHours();
  const fechaActSIN = `${fechaEmpAn}T${hora}`;
  console.log("fechaActSIN" + fechaActSIN);

  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");
  //Buscar siniestro en las apis
  const [siniestros, setsiniestros] = React.useState([]);

  let complejidad = null,
    idSiniestro = [],
    fechaAPISiniestro = null;

  const getData = async () => {
    try {
      var resultPolizas = await getDataToken(
        `polizaportramites/get_folio/${folio}/`
      );
      console.log("Resultado de pólizas por trámite =>", resultPolizas);
      setRegistros(resultPolizas);
      var resultSiniestros = await getDataToken(`siniestros/get_listado/`);
      setsiniestros(resultSiniestros);
      var result = await getDataToken(`login/get_correo/${email}/`);
      setnombreAnalista(
        result.SNYTLOG_username +
          " " +
          result.SNYTLOG_surnamedad +
          " " +
          result.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  let idEstatusTramite = IDESTATUSFALTINF;

  console.log("Estatus trámite: " + idEstatusTramite);
  console.log("Folio" + folio);
  console.log("numFolio" + verFolderFolio);
  console.log("idTramite" + idTramite);

  //Para actualizar el estatus del trámite
  async function putRecepcion(aux) {
    try {
      var result = await putDataToken(`recepcion/get_id/${folio}/`, aux);
      console.log("Registro exitoso", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  let registroEncontradoTra =
    registro[0]?.RECT_idfolio?.SINC_idcomplejidadsiniestro
      ?.TRA_idtipotramitesiniestro;

  console.log("Tramite siniestro" + registroEncontradoTra);

  //Este guarda todos los elementos que se encontraron en siniestros puede ser 1 ó más.
  const elementoSiniestros = siniestros?.filter(
    (item) => item.RECT_idfolio?.RECT_idfolio === folio
  );
  console.log(elementoSiniestros);

  complejidad =
    elementoSiniestros[0]?.SINC_idcomplejidadsiniestro
      ?.SINC_idcomplejidadsiniestro;

  fechaAPISiniestro = moment(elementoSiniestros[0]?.SIN_fechasiniestro).format(
    "YYYY-MM-DD"
  );

  const fechaAPISiniestroFormat = moment(
    elementoSiniestros[0]?.SIN_fechasiniestro
  ).format("YYYY/MM/DD");

  idSiniestro = elementoSiniestros.map((elemento) => ({
    SIN_idsiniestro: elemento.SIN_idsiniestro,
  }));

  console.log(JSON.stringify(idSiniestro));
  console.log("Complejidad " + complejidad);
  console.log("Fecha siniestro" + fechaAPISiniestro);
  console.log("Fecha siniestro format" + fechaAPISiniestroFormat);

  //Contsante para cambio de Formulario
  const [showForm, setShowForm] = useState(true);
  /* CONSTANTES DE ESTADOS*/
  const [InfoDataRow, setInfoDataRow] = useState([]); //Aqui es donde almacenará los datos de la fila seleccionadas
  const [btnDatos, setBtnDatos] = useState(true); //Botón de ver datos
  const [row, setRow] = useState(""); //se almacenaran nuestros datos para poder enviarlos

  //Condiciones para validar la fecha de siniestro en bdsinay
  const [btnFecha, setBtnFecha] = useState(true); //Botón para validar fech
  const [fechasiniestroapi, setfechasiniestroapi] = useState("");

  // Declarar datosActualizados como un estado usando useState
  const [datosActualizados, setDatosActualizados] = useState([]);
  const [datosActualizadosSinay, setDatosActualizadosSinay] = useState([]);
  const [visiblePoliza, setVisiblePoliza] = React.useState(false);

  /* FUNCIÓN CLICK EN LOS RADIO BUTTON, ARROJA INFORMACION DE LA FILA SELECCIONADA */
  const rbClick = (e) => {
    const inputPoliza = parseInt(e.target.value);

    //Esta condicion se necesita para el paso de props
    registro.map((row) => {
      if (row.POL_idpoliza?.POL_idpoliza == inputPoliza) {
        setRow(row);
      }
    });

    const switchSeleccionado = registro.find(
      (row) => row.POL_idpoliza?.POL_idpoliza === inputPoliza
    );
    console.log(switchSeleccionado);

    if (switchSeleccionado) {
      const isSelected = InfoDataRow.some(
        (item) => item.POL_idpoliza?.POL_idpoliza === inputPoliza
      );
      const polizaVacia = !switchSeleccionado.POL_idpoliza?.POL_numpoliza;

      if (polizaVacia) {
        // Realizar la acción correspondiente cuando el campo "poliza" está vacío
        setVisiblePoliza(true);
        let dataRecepcion = {
          SINC_idcomplejidadsiniestro: idTramite,
          TEST_idestatustramite: idEstatusTramite,
          RECT_actualizofecha: fechaActSIN,
          RECT_actualizousuario: nombreAnalista,
        };
        putRecepcion(dataRecepcion);
      }
      if (isSelected) {
        setInfoDataRow((prevData) =>
          prevData.filter(
            (item) => item.POL_idpoliza?.POL_idpoliza !== inputPoliza
          )
        );

        // Eliminar el elemento correspondiente del estado datosActualizados
        setDatosActualizados((prevDatos) =>
          prevDatos.filter(
            (dato) =>
              dato.poliza !== switchSeleccionado.POL_idpoliza?.POL_numpoliza
          )
        );
        // Eliminar el elemento correspondiente del estado datosActualizados
        setDatosActualizadosSinay((prevDatos) =>
          prevDatos.filter(
            (dato) =>
              dato.poliza !== switchSeleccionado.POL_idpoliza?.POL_numpoliza
          )
        );
      } else {
        setInfoDataRow((prevData) => [...prevData, switchSeleccionado]);
        setBtnDatos(false);
        setfechasiniestroapi(fechaAPISiniestro);

        // Hacemos la consulta al PM
        const valor = switchSeleccionado.POL_idpoliza?.POL_numpoliza;
        getDataToken(`conexionpm/poliza_apiPM/?poliza=${valor}`).then(
          (result) => {
            const promiseResult = result[0];
            console.log(promiseResult);

            // Guarda los datos en una variable en lugar de actualizar el estado 'datos'
            const nuevosDatos = {
              poliza:
                promiseResult.length > 0 ? promiseResult[DATA_PM.POLIZA] : null,
              attribute:
                promiseResult.length > 0
                  ? promiseResult[DATA_PM.ID_ESTATUS_POLIZA]
                  : null,
              fechaEmision:
                promiseResult.length > 0
                  ? promiseResult[DATA_PM.FECHA_EMISION_POLIZA]
                  : null,
            };

            // Actualiza el estado datosActualizados agregando los nuevos datos
            setDatosActualizados((prevDatos) => [...prevDatos, nuevosDatos]);

            // Realiza cualquier acción adicional con los nuevos datos, como guardarlos en una variable
            console.log(datosActualizados);
          }
        );

        //Hacemos la segunda consulta a Sinay
        const fetchData = async () => {
          const result = await getDataToken("polizas/get_listado/");
          for (let i = 0; i < result.length; i++) {
            if (result[i].POL_numpoliza === valor) {
              const nuevosDatosSinay = {
                idPoliza: result.length > 0 ? result[i].POL_idpoliza : null,
                poliza: result.length > 0 ? result[i].POL_numpoliza : null,
                attribute:
                  result.length > 0
                    ? result[i].EST_idestatuspoliza?.EST_descripcion
                    : null,
                fechaEmision:
                  result.length > 0 ? result[i].POL_polizafecemisión : null,
              };

              // Actualiza el estado datosActualizadosSinay agregando los nuevos datos
              setDatosActualizadosSinay((prevDatos) => [
                ...prevDatos,
                nuevosDatosSinay,
              ]);

              break; // si encuentras el registro, detienes el bucle
            }
          }
        };

        fetchData();
      }

      console.log(isSelected);
    }
  };
  console.log(row);
  console.log(InfoDataRow);
  console.log(datosActualizados);
  console.log(datosActualizadosSinay);
  console.log(fechasiniestroapi);

  //Cerrar dialog
  const handleClose = () => {
    setVisiblePoliza(false);
  };

  /* CONSTANTES DE MSG*/
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [subsubtitle, setSubsubTitle] = useState("");
  const [visibleSiniestro, setVisibleSiniestro] = React.useState(false);
  const [valid, setvalid] = useState(false);
  const [habilitaLink, setHabilitaLink] = useState(false); //activacion y desactivacion del link
  const [fechasi, setfechasi] = useState("");

  let msgTitle, msgsubtitle, msgsubsubtitle;

  //Obtenemos el valor actual que el usuario seleccione
  let fechasini;
  const handleDataChanges = (newData) => {
    setvalid(false);
    if (fechasiniestroapi === "") {
      setfechasiniestroapi(`${fechaAPISiniestro}T${hora}`);
    } else {
      const fechasiniestro = newData.toISOString();
      const mexicoCityTime = moment(fechasiniestro).tz("America/Mexico_City");
      fechasini = mexicoCityTime.format("YYYY-MM-DD");
      console.log(fechasini);
      setfechasi(mexicoCityTime.format("YYYY/MM/DD"));
      setfechasiniestroapi(`${fechasini}T${hora}`);
    }
  };

  console.log(fechasiniestroapi);

  //Formateamos las fechas a AAAA/MM/DD que tenemos del PM
  const fechasEmisionFormateadas = datosActualizados.map((elemento) => {
    const fechaEmision = elemento.fechaEmision;
    const fechaFormateada = moment(fechaEmision, "DD/MM/YYYY").format(
      "YYYY/MM/DD"
    );
    return fechaFormateada;
  });

  console.log(fechasEmisionFormateadas);

  //Formateamos las fechas a AAAA/MM/DD que tenemos de Sinay
  const fechasEmisionFormateadasSinay = datosActualizadosSinay.map(
    (elemento) => {
      const fechaEmision = elemento.fechaEmision;
      const fechaFormateada = moment(fechaEmision, "YYYY-MM-DD").format(
        "YYYY/MM/DD"
      );
      return fechaFormateada;
    }
  );

  console.log(fechasEmisionFormateadasSinay);

  //Hacemos todas las validaciones correspondientes al RF8
  const [openDialog1, setOpenDialog1] = useState("false");

  function validarFecha() {
    //Validamos si la fecha ingresada es anterior a la emisión de la póliza
    const esAnteriorSinay = fechasEmisionFormateadasSinay.find((fecha) =>
      moment(fechasi, "YYYY/MM/DD").isBefore(moment(fecha, "YYYY/MM/DD"))
    );
    console.log(esAnteriorSinay);

    const esAnteriorPM = fechasEmisionFormateadas.find((fecha) =>
      moment(fechasi, "YYYY/MM/DD").isBefore(moment(fecha, "YYYY/MM/DD"))
    );
    console.log(esAnteriorPM);

    const esDespuesSinay = fechasEmisionFormateadasSinay.find((fecha) =>
      moment(fechasi, "YYYY/MM/DD").isAfter(moment(fecha, "YYYY/MM/DD"))
    );
    console.log(esDespuesSinay);

    const esDespuesPM = fechasEmisionFormateadas.find((fecha) =>
      moment(fechasi, "YYYY/MM/DD").isAfter(moment(fecha, "YYYY/MM/DD"))
    );
    console.log(esDespuesPM);

    const esAnteriorSinayapi = fechasEmisionFormateadasSinay.find((fecha) =>
      moment(fechaAPISiniestroFormat, "YYYY/MM/DD").isBefore(
        moment(fecha, "YYYY/MM/DD")
      )
    );
    console.log(esAnteriorSinayapi);

    const esAnteriorPMapi = fechasEmisionFormateadas.find((fecha) =>
      moment(fechaAPISiniestroFormat, "YYYY/MM/DD").isBefore(
        moment(fecha, "YYYY/MM/DD")
      )
    );
    console.log(esAnteriorPMapi);

    const esDespuesSinayapi = fechasEmisionFormateadasSinay.find((fecha) =>
      moment(fechaAPISiniestroFormat, "YYYY/MM/DD").isAfter(
        moment(fecha, "YYYY/MM/DD")
      )
    );
    console.log(esDespuesSinayapi);

    const esDespuesPMapi = fechasEmisionFormateadas.find((fecha) =>
      moment(fechaAPISiniestroFormat, "YYYY/MM/DD").isAfter(
        moment(fecha, "YYYY/MM/DD")
      )
    );
    console.log(esDespuesPMapi);

    //Validamos si uno de esos resultados se encuentra en el arreglo
    const objetoEncontrado = datosActualizados.find((objeto) => {
      return (
        objeto.fechaEmision ===
        moment(esAnteriorPM, "YYYY-MM-DD").format("YYYY-MM-DD")
      );
    });

    const objetoEncontradoSinay = datosActualizadosSinay.find((objeto) => {
      return (
        objeto.fechaEmision ===
        moment(esAnteriorSinay, "YYYY-MM-DD").format("YYYY-MM-DD")
      );
    });

    const objetoEncontradoapi = datosActualizados.find((objeto) => {
      return (
        objeto.fechaEmision ===
        moment(esAnteriorPMapi, "YYYY-MM-DD").format("YYYY-MM-DD")
      );
    });

    const objetoEncontradoSinayapi = datosActualizadosSinay.find((objeto) => {
      return (
        objeto.fechaEmision ===
        moment(esAnteriorSinayapi, "YYYY-MM-DD").format("YYYY-MM-DD")
      );
    });

    let polizaEncontrada;
    if (objetoEncontrado) {
      polizaEncontrada = objetoEncontrado.poliza;
    } else if (objetoEncontradoSinay) {
      polizaEncontrada = objetoEncontradoSinay.poliza;
    } else if (objetoEncontradoapi) {
      polizaEncontrada = objetoEncontradoapi.poliza;
    } else if (objetoEncontradoSinayapi) {
      polizaEncontrada = objetoEncontradoSinayapi.poliza;
    } else {
      console.log("No se encontró ninguna coincidencia en el arreglo.");
    }
    console.log(polizaEncontrada);

    if (
      esAnteriorSinay ||
      esAnteriorPM ||
      esAnteriorSinayapi ||
      esAnteriorPMapi
    ) {
      setVisibleSiniestro(true);
      setvalid(true);
      msgTitle = (
        <FormattedMessage id="RegFueVig" defaultMessage="Fuera de Vigencia" />
      );
      msgsubtitle = (
        <div>
          <FormattedMessage
            id="siniestroMsg"
            defaultMessage="El siniestro debe de haber ocurrido dentro de la vigencia de la póliza."
          />
          <br />
          <FormattedMessage
            id="polizaMsg"
            defaultMessage="La póliza con fuera de vigencia es: {poliza}"
            values={{ poliza: <strong>{polizaEncontrada}</strong> }}
          />
        </div>
      );
      // Itera sobre los registros a actualizar
      idSiniestro.forEach((registro) => {
        // Actualiza el registro con el nuevo valor
        let datasiniestro = {
          RECT_idfolio: folio,
          SINC_idcomplejidadsiniestro: complejidad,
          SIN_fechasiniestro: fechasiniestroapi,
          SIN_actualizofecha: fechaActSIN,
          SIN_actualizousuario: nombreAnalista,
        };
        // Realiza una solicitud PUT o PATCH para actualizar el registro
        putDataToken(
          `siniestros/get_id/${registro.SIN_idsiniestro}/`,
          datasiniestro
        ).then((data) => {
          console.log(`Registro ${registro.SIN_idsiniestro} actualizado`);
        });
      });
    } else if (
      (fechasiniestroapi !== "" &&
        esDespuesPM &&
        esDespuesPM != undefined &&
        esAnteriorPM == undefined) ||
      (fechasiniestroapi !== "" &&
        esDespuesSinay &&
        esDespuesSinay != undefined &&
        esAnteriorSinay == undefined) ||
      (fechasiniestroapi !== "" &&
        esDespuesPMapi &&
        esDespuesPMapi != undefined &&
        esAnteriorPMapi == undefined) ||
      (fechasiniestroapi !== "" &&
        esDespuesSinayapi &&
        esDespuesSinayapi != undefined &&
        esAnteriorSinayapi == undefined)
    ) {
      // Itera sobre los registros a actualizar
      idSiniestro.forEach((registro) => {
        // Actualiza el registro con el nuevo valor
        let datasiniestro = {
          RECT_idfolio: folio,
          SINC_idcomplejidadsiniestro: complejidad,
          SIN_fechasiniestro: fechasiniestroapi,
          SIN_actualizofecha: fechaActSIN,
          SIN_actualizousuario: nombreAnalista,
        };
        // Realiza una solicitud PUT o PATCH para actualizar el registro
        putDataToken(
          `siniestros/get_id/${registro.SIN_idsiniestro}/`,
          datasiniestro
        ).then((data) => {
          console.log(`Registro ${registro.SIN_idsiniestro} actualizado`);
        });
      });
      msgTitle = (
        <FormattedMessage
          id="globValDeF"
          defaultMessage="Validación de Fecha exitosa"
        />
      );
      msgsubtitle = (
        <FormattedMessage
          id="globValFchIng"
          defaultMessage="La fecha ingresada de Siniestro se validó correctamente, continua con el trámite."
        />
      );
      setVisibleSiniestro(true);
    }
    // Usando findIndex para buscar la palabra y obtener el índice del objeto
    const indices = objetosCoincidentes.findIndex(
      (objeto) => objeto.TARE_idtarea.TARE_idtarea === IDCAPFESINI
    );
    //Actualizamos a true
    objetosCoincidentes[indices].estado = true;
    // Usando findIndex para buscar la palabra y obtener el índice del objeto
    const indice = objetosCoincidentes.findIndex(
      (objeto) => objeto.TARE_idtarea.TARE_idtarea === IDTAVALIDACION
    );
    //Actualizamos a true
    objetosCoincidentes[indice].estado = true;
    setTitle(msgTitle);
    setSubtitle(msgsubtitle);
    setSubsubTitle(msgsubsubtitle);
  }

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const filteredData = registro.slice(startIndex, endIndex);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //Bandera switch
  const [bandera, setbandera] = React.useState(false);

  //metodo para el boton de guardar y que nos pueda mostrar el boton de
  const handleClicVista = () => {
    setbandera(true);
    setShowForm(true);
    setBtnFecha(false);
    setBtnDatos(true);
    setHabilitaLink(true);
    // Usando findIndex para buscar la palabra y obtener el índice del objeto
    const indice = objetosCoincidentes.findIndex(
      (objeto) => objeto.TARE_idtarea.TARE_idtarea === IDEXTGRBPO
    );
    //Actualizamos a true
    objetosCoincidentes[indice].estado = true;
  };

  const handleGuardarWF = () => {
    // Filtrar el arreglo para obtener solo los registros con el campo 'estado'.
    const registrosConEstado = objetosCoincidentes.filter(
      (registro) => registro.estado
    );
    console.log(registrosConEstado);

    for (const itempo of registro) {
      for (const item of registrosConEstado) {
        for (const siniestro of elementoSiniestros) {
          let registro = {
            RECT_idfolio: folio,
            POL_idpoliza: itempo.POL_idpoliza.POL_idpoliza,
            WTM_idwftareasxmodulos: item.WTM_idwftareasxmodulos,
            PER_idpersona: paso?.PER_idpersona?.PER_idpersona,
            SIN_idsiniestro: siniestro?.SIN_idsiniestro,
            WKT_fechawktramite: fechaActSIN,
            WKT_seejecuto: item.estado,
            WKT_actualizofecha: fechaActSIN,
            WKT_actualizousuario: nombreAnalista,
          };
          postDataToken(`wktramite/get_listado/`, registro).then((data) => {
            console.log(`Registro exitoso wktramite`, data);
          });
        }
      }
    }
  };

  //Estados para ver archivos del folder

  const [images, setImages] = React.useState([]);
  const [btnVerFolder, setBtnVerFolder] = React.useState({
    estatus: false,
    label: <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />,
  });
  const [pdfs, setPdfs] = React.useState([]);
  const [loadingIMG, setLoadingIMG] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const verArchivos = async () => {
    setBtnVerFolder({
      ...btnVerFolder,
      label: (
        <FormattedMessage
          id="globBuscaInf"
          defaultMessage="Buscando información..."
        />
      ),
      estatus: true,
    });
    setLoadingIMG(true);
    setLoadingPDF(true);
    var response = await getFiles(verFolderFolio);
    if (!response) {
      //Si la respuesta es falsa es porque no existe una carpeta con ese folio
      // alert("No existe carpeta con ese folio");
      setBtnVerFolder({
        ...btnVerFolder,
        label: <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />,
        estatus: false,
      });
    } else {
      //En caso contrario, revisará que la carpeta tenga archivos para descargarlos, si no, mandar alerta, por ejemplo.
      if (response.length > 0) {
        setBtnVerFolder({
          ...btnVerFolder,
          label: <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />,
          estatus: false,
        });
        var imagenes = response.filter((file) => file.fileExt === "jpg"); //Filtrar por jpg
        var pdfs = response.filter((file) => file.fileExt === "pdf"); //Filtrar por pdf

        if (imagenes.length > 0) {
          const images = imagenes.map((e) => URL.createObjectURL(e.fileBlob));
          setImages(images);
        } else {
          setLoadingIMG(false);
        }
        if (pdfs.length > 0) {
          const docs = pdfs.map((e) => e.fileBlob);
          console.log("Docs:", docs);
          setPdfs(docs);
        } else {
          setLoadingPDF(false);
        }

        //response.map((e) => {
        //    downloadBlob(e.fileBlob, e.fileName)
        //})
      } else {
        // alert("La carpeta está vacía xd.");
        setBtnVerFolder({
          ...btnVerFolder,
          label: <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />,
          estatus: false,
        });
        setLoadingIMG(false);
        setLoadingPDF(false);
        console.log(response);
      }
    }
  };
  React.useEffect(() => {
    if (images.length > 0) {
      setLoadingIMG(false);
      return () => {
        // Liberar la memoria utilizada por cada URL de objeto creada
        images.forEach((url) => URL.revokeObjectURL(url));
      };
    }
  }, [images]);
  //Funciones y estados para VER PDF
  const [documents, setDocuments] = React.useState([]);
  const [currentPDF, setCurrentPDF] = React.useState(0);

  //Este use Effect se activará cuando escuche cambios en el pdfs
  React.useEffect(() => {
    if (pdfs.length > 0) {
      setLoadingPDF(false);
      //Verificamos si hay archivos PDFs
      setDocuments([]); //Si los hay actualizamos en estado documentos, seteando un arreglo vacío
      pdfs.forEach((blob) => {
        //Recorremos el arreglo de pdfs
        var pdfUrl = URL.createObjectURL(blob); // Usar el objectUrl para mostrar el PDF en pantalla...
        setDocuments((prevState) => [
          ...prevState, //Obtenemos los valores sin sobreescribirlos,
          <iframe
            src={pdfUrl}
            style={{
              width: "900px",
              height: "700px",
              display: "block",
              margin: "auto",
            }}
          />, //Los presentamos en un iframe, por ejemplo
        ]);
        URL.revokeObjectURL(blob); // Luego, al ya no necesitarlo, revocar el objectUrl. Esto ayuda a liberar recursos en la memoria del navegador..
      });
    }
  }, [pdfs]);

  //Este es nuestra primer vista
  //Esta vista es mostrada porque nuestro showForm lo tenemos en true
  if (showForm) {
    return (
      <div>
        {visiblePoliza && (
          <DialogValidaciones
            title={
              <FormattedMessage
                id="regisMsgInf"
                defaultMessage="Mensaje informativo"
              />
            }
            secondButton={
              <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
            }
            description={
              <div>
                <p>
                  <FormattedMessage
                    id="noPoliza"
                    defaultMessage="El registro seleccionado no cuenta con un número de póliza."
                  />
                  <br />
                  <FormattedMessage
                    id="turnarDictamen"
                    defaultMessage="El caso se turnara a dictamen como plataforma especial."
                  />
                </p>
              </div>
            }
            onClick={handleClose}
          />
        )}

        {visibleSiniestro && (
          <DialogBoton
            title={title}
            secondButton={
              <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
            }
            description={subtitle}
            to="/cobertura" //Esta url es porque aquí se pasaran los datos que lleva la varibale row
            state={{ paso }} //Row es la variable que lleva la información a otra vista
            onClick={handleGuardarWF}
          />
        )}
        <Grid xs={12}>
          {/* Encabezado  
            Se esta pasando la información de la primer tabla al encabezado por medio de la varibale paso
          */}
          <Header infoRow={paso} />
          <br />
        </Grid>
        <Paper sx={{ width: "100%" }}>
          <TableContainer
            sx={{ maxHeight: 540 }}
            style={{ position: "sticky" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {!bandera && <StyledTableCell></StyledTableCell>}
                  {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                  <StyledTableCell align="center">
                    <FormattedMessage
                      id="globNoDeFo"
                      defaultMessage="No. de folio"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <FormattedMessage
                      id="globNoPoli"
                      defaultMessage="No. de póliza"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <FormattedMessage
                      id="globFechEm"
                      defaultMessage="Fecha de emisión"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <FormattedMessage
                      id="globPagaHa"
                      defaultMessage="Pagado hasta"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <FormattedMessage
                      id="globForPag"
                      defaultMessage="Forma de pago"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <FormattedMessage
                      id="regisVigen"
                      defaultMessage="Vigencia"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <FormattedMessage
                      id="globProdut"
                      defaultMessage="Producto"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <FormattedMessage
                      id="globDocumt"
                      defaultMessage="Documentación"
                    />
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
                {filteredData.map((row) => {
                  return (
                    <TableRow key={row.POL_idpoliza.POL_idpoliza}>
                      {!bandera && (
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <Check2
                            name="rb"
                            value={row.POL_idpoliza.POL_idpoliza}
                            onChange={rbClick}
                          />
                        </StyledTableCell>
                      )}
                      <StyledTableCell align="center">
                        {" "}
                        {row.RECT_idfolio?.RECT_numerofolio}{" "}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {" "}
                        {row.POL_idpoliza?.POL_numpoliza}{" "}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(row.POL_idpoliza?.POL_polizafecemisión).format(
                          formatofecha
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(row.POL_idpoliza?.POL_polizapahasta).format(
                          formatofecha
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {" "}
                        {
                          row.POL_idpoliza?.FPAG_idformasdepago
                            ?.FPAG_formadepago
                        }{" "}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(row.POL_idpoliza?.POL_polizafinvigencia).format(
                          formatofecha
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {" "}
                        {
                          row.POL_idpoliza?.PRO_idproductos?.PRO_cveproducto
                        }{" "}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <ScrollDialog
                          onClick1={() => verArchivos()}
                          title={
                            <FormattedMessage
                              id="globDocumt"
                              defaultMessage="Documentación"
                            />
                          }
                          label={btnVerFolder.label}
                          descriptionimg={
                            <div>
                              {loadingIMG ? (
                                <p>Cargando imágenes...</p>
                              ) : images.length > 0 ? (
                                images.map((image, index) => (
                                  <div key={index}>
                                    <img
                                      src={image}
                                      alt={`Imagen ${index}`}
                                      style={{
                                        width: "80%",
                                        display: "block",
                                        margin: "auto",
                                      }}
                                      onLoad={() => setLoadingIMG(false)}
                                      onError={() => setLoadingIMG(false)}
                                    />
                                  </div>
                                ))
                              ) : (
                                <p>
                                  <FormattedMessage
                                    id="globDocumtSin"
                                    defaultMessage="No hay imágenes disponibles"
                                  />
                                </p>
                              )}
                            </div>
                          }
                          descriptionpdf={
                            <div>
                              {loadingPDF ? (
                                <p>
                                  <FormattedMessage
                                    id="globLoadingPDF"
                                    defaultMessage="Cargando PDF..."
                                  />
                                </p>
                              ) : documents.length > 0 ? (
                                <div>
                                  {documents[currentPDF]}
                                  <div
                                    style={{
                                      display: "block",
                                      margin: "auto",
                                      textAlign: "center",
                                    }}
                                  >
                                    <br />
                                    <ButtonVino
                                      label={
                                        <FormattedMessage
                                          id="globAntPDF"
                                          defaultMessage="Anterior PDF"
                                        />
                                      }
                                      onClick={() =>
                                        setCurrentPDF(currentPDF - 1)
                                      }
                                      estatus={currentPDF === 0}
                                    />
                                    <ButtonVino
                                      label={
                                        <FormattedMessage
                                          id="globSigPDF"
                                          defaultMessage="Siguiente PDF"
                                        />
                                      }
                                      onClick={() =>
                                        setCurrentPDF(currentPDF + 1)
                                      }
                                      estatus={
                                        currentPDF === documents.length - 1
                                      }
                                    />
                                  </div>
                                </div>
                              ) : (
                                <p>
                                  <FormattedMessage
                                    id="globNoPDF"
                                    defaultMessage="No hay PDF's disponibles"
                                  />
                                </p>
                              )}
                            </div>
                          }
                        />
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={registro.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={
              <FormattedMessage
                id="globRegPa"
                defaultMessage="Registros por página:"
              />
            }
          />
        </Paper>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={4}>
            <br />
            <ButtonVino
              onClick={() => setShowForm(false)}
              estatus={btnDatos} //Dependiendo si selecciona alguna información en la tabla
              //por este estado se encendera el botón en la vista o permanecera inactivo
              label={
                <FormattedMessage id="regisVerDa" defaultMessage="Ver Datos" />
              }
            />
          </Grid>
          <Grid item>
            <br />
            {registroEncontradoTra === TRAM_FALLECIMIENTO.id ? (
              <DialogFecha
                mainButton={
                  <FormattedMessage
                    id="regisReSin"
                    defaultMessage="Validar Fecha Siniestro"
                  />
                }
                estatus={btnFecha}
                title={
                  <FormattedMessage
                    id="regisReSin"
                    defaultMessage="Fecha de ocurrencia del Siniestro"
                  />
                }
                description={
                  <DatePicker
                    fechaSiniestro={fechaAPISiniestro}
                    onDataChange={handleDataChanges}
                  />
                }
                button={
                  <FormattedMessage id="globValidr" defaultMessage="Validar" />
                }
                onClick={validarFecha}
                visible={openDialog1}
                estatus2={
                  fechaAPISiniestro === "Fecha inválida" || idSiniestro === null
                    ? btnValidar
                    : btnValidar2
                }
              />
            ) : (
              <Link
                to="/cobertura"
                state={{ paso }}
                style={{ textDecoration: "none" }}
                onClick={(e) => !habilitaLink && e.preventDefault()}
              >
                <ButtonVino
                  estatus={btnFecha}
                  label={
                    <FormattedMessage
                      id="globSiguie"
                      defaultMessage="Siguiente"
                    />
                  }
                  onClick={handleGuardarWF}
                />
              </Link>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
  //Este es nuestra segunda vista
  //Esta vista es mostrada porque nuestro showForm lo tenemos en false
  if (!showForm) {
    return (
      <>
        {datosActualizados.length > 0
          ? datosActualizados.map((poliza, index) => (
              <>
                <TabPM key={index} numPoliza={poliza.poliza} infoData={paso} />
                <br />
              </>
            ))
          : datosActualizadosSinay.map((poliza, index) => (
              <>
                <TabPMSinay
                  key={index}
                  idPoliza={poliza.idPoliza}
                  numPoliza={poliza.poliza}
                />
                <br />
              </>
            ))}
        <br />
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <Grid item xs={"auto"}>
            <ButtonVino
              label={
                <FormattedMessage id="globSiguie" defaultMessage="Siguiente" />
              }
              onClick={handleClicVista}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}
