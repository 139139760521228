import React from "react";
import { useState, useEffect } from "react";
// import { getToken } from "../../../api/token";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import "../../../../src/css/estilo.css";
//Idioma
import { FormattedMessage } from "react-intl";
import { getDataTokenconstante } from "../../../api/dataToken";
// import { BASE_API } from "../../../utils/constans";
// import { getUsers } from "../../Login/apiLogin/getUsers";





const Footer = () => {
  // const [datos, setDatos] = useState([]);

  // getUsers(`${BASE_API}configuraciongeneral/get_id/1/`)
  // .then((data) => {
  //     setDatos(data);
  // })
  // .catch((error) => {
  //   console.error("There was an error:", error);
  // });

  const [datos, setDatos] = useState([]);

  const getDataPermisos = async () => {

    //Resultados de la api investigacion
    var resultPermisos = await getDataTokenconstante("configuraciongeneral/get_id/1/");    
    setDatos(resultPermisos);
    
  }
  useEffect(() => {
    getDataPermisos()  
  }, [])
  
  return (
    <footer>
      <Grid container>
        <Grid item xs={12} sm={8}>
          <Box borderBottom={1}>
            <Box sx={{ flexGrow: 1 }}>
              <AppBar
                position="static"
                style={{ backgroundColor: datos.SCG_secundaryColor }}
              >
                <Toolbar variant="dense">
                  <Container maxWidth="lg">
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={1}>
                        <Box borderBottom={0}></Box>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <Box borderBottom={0}>
                          <Link color="inherit" underline="none" href="#">
                            <h5-footer-information>
                              <FormattedMessage
                                id="global.mapa"
                                defaultMessage="Mapa de sitio"
                              />
                            </h5-footer-information>
                          </Link>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <Box borderBottom={0}>
                          <Link color="inherit" underline="none" href="#">
                            <h5-footer-information>
                              <FormattedMessage
                                id="global.aviso"
                                defaultMessage="Aviso de privacidad"
                              />
                            </h5-footer-information>
                          </Link>
                        </Box>
                      </Grid>
                    </Grid>
                  </Container>
                </Toolbar>
              </AppBar>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <AppBar
            position="static"
            style={{ backgroundColor: datos.SCG_primaryColor }}
          >
            <Toolbar variant="dense">
              <Container maxWidth="lg">
                <Grid>
                  <Grid item xs={12} sm={12}>
                    <Box borderLeft={0}>
                      <Link
                        color="inherit"
                        underline="none"
                        href="https://www.qait.com.mx/"
                      >
                        <h6-footer-copyright>
                          Copyright &reg; {new Date().getFullYear()} SINAY
                        </h6-footer-copyright>
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Toolbar>
          </AppBar>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
