//David Ruiz 10/2022

import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const BtBorrar=(props)=>{
    
    return(
        <IconButton aria-label="DeleteIcon" color="icons" 
        //aqui va el enlace
        href={props.enlace}
        onClick={props.onClick}
        onChange={props.onChange}
        id={props.id}
         //ojo aqui es disabled
         disabled={props.estatus} //true para desabilitar y false para habilitar
        >
        < DeleteIcon/>
        </IconButton>
        );
    
}
export default BtBorrar;