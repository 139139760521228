//  David Ruiz 10/2022

import React from 'react';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';

const Btver=(props)=>{
    return(
        <IconButton aria-label=" VisibilityIcon" color="icons" 
        //aqui va el enlace
        href={props.enlace}
        //ojo aqui es disabled
        //true para desabilitar y false para habilitar
        disabled={props.estatus}
        onClick={props.onClick}
        onChange={props.onChange}
        id={props.id}
        >
        < VisibilityIcon />
        </IconButton>
        );
    }

export default Btver;