import React from "react";
import { CompInicio } from "../../../components/Login/CompInicio";


export function Inicio() {
  return (
    <div>
 
      <CompInicio />
  
    </div>
  );
}
