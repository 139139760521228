import React from "react";
import MailExit  from '../../../../components/GeneralConf/Mail/MailExit';


export function exitmail() {
    return (
        <div>
         
            <MailExit/>
           
        </div>
    );
}