import * as React from "react";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TXTField from "../../../Tools/TextField/TextField";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import Check2 from "../../../Tools/CheckBox/Check2";
import { getDataToken, putDataToken } from "../../../../api/dataToken";
import { SelectDictamen } from "../../../Tools/Selectss/SelectDictamen";
import { LimitarTexto } from "../../../Recepcion/Elements/Functions/Validaciones";
import { LimitarDigitos } from "../../../Recepcion/Elements/Functions/Validaciones";
import { FormattedMessage } from "react-intl";
import { IDROLMEDICO } from "../../../../utils/constanstoken";
import getDate from "../../../Registro/Function/obtenerFecha";
import moment from "moment";
import getHours from "../../../Registro/Function/obtenerHora";
import { formatofecha } from "../../../../api/getUmbrales";
import DialogAviso from "../../../Registro/Elements/Dialog/DialogAviso";
import { IDPAISMEXICO } from "../../../../utils/constanstoken";
import {
  LimitarTextoSinCondiciones,
  LimitarTextoCodigo,
} from "../../../Registro/Function/limitarTexto";

const CardDatosReclamacionFallecimiento = (props) => {
  const idFolio = props.idFolio;
  const polizas = props.polizas;
  console.log(polizas);

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");
  console.log(moment(fechaEmpAn).format(formatofecha));
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();
  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;
  console.log("fechaActSIN" + fechaActSIN);

  const [Data, setData] = React.useState([]);
  const [DataMedico, setDataMedico] = React.useState([]);
  const [asentamiento, setAsentamiento] = React.useState([]);
  const [asentamientos, setAsentamientos] = React.useState([]);
  const [idcomplejidad, setidcomplejidad] = React.useState("");
  //Estados para guardar los datos de clasificación
  const [clasificacion, setClasificacion] = useState([]);
  const [idclasificacion, setIdclasificacion] = useState("");
  //Estados para guardar los datos de los paises
  const [paises, setpaises] = useState([]);
  const [idpais, setidpais] = useState("");
  const [idpaisR, setidpaisR] = useState("");

  //Obtener usuario.
  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");
  const getData = async () => {
    try {
      var result = await getDataToken(`siniestros/get_folio/${idFolio}/`);
      setData(result);
      let nuevoElemento = {
        identificador: result[0]?.SEP_codpostal?.id,
        texto: result[0]?.SEP_codpostal?.SEP_asenta,
      };
      console.log(nuevoElemento);
      setAsentamiento([...asentamiento, nuevoElemento]);
      setidpais(result[0]?.PAI_idpais?.PAI_idpais);
      setidpaisR(result[0]?.PAI_idpais?.PAI_idpais);
      setidcomplejidad(
        result[0]?.SINC_idcomplejidadsiniestro?.SINC_idcomplejidadsiniestro
      );
      setIdclasificacion(
        result[0]?.SINC_idcomplejidadsiniestro?.SINC_idcomplejidadsiniestro
      );
      var resultPER = await getDataToken(
        `personasxpoliza/get_poliza/${polizas[0]?.POL_idpoliza?.POL_idpoliza}/`
      );
      let personasFiltradas = resultPER.filter(
        (persona) =>
          persona.PER_idpersona?.PERR_idrol?.PERR_idrol === IDROLMEDICO
      );
      setDataMedico(personasFiltradas);
      let nuevoElementoMed = {
        identificador: personasFiltradas[0]?.PER_idpersona?.SEP_idsepomex?.id,
        texto: personasFiltradas[0]?.PER_idpersona?.SEP_idsepomex?.SEP_asenta,
      };
      setAsentamientos([...asentamientos, nuevoElementoMed]);
      var resultAnalista = await getDataToken(`login/get_correo/${email}/`);
      setnombreAnalista(
        resultAnalista.SNYTLOG_username +
          " " +
          resultAnalista.SNYTLOG_surnamedad +
          " " +
          resultAnalista.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  console.log(Data);
  console.log(DataMedico);
  console.log(idcomplejidad);
  console.log(asentamientos);
  console.log(asentamiento);

  // Traemos los datos de clasificación para llenar el combo
  const getComplejidad = async (idcomplejidad, idpais) => {
    try {
      const [result, resultPaises] = await Promise.all([
        getDataToken(`complejidadsiniestros/get_listado/`),
        getDataToken(`paises/get_listado/`),
      ]);

      // Utilizar un conjunto para evitar duplicados
      const idComplejidadSet = new Set();
      const idPaisSet = new Set();

      // Filtrar la complejidad deseada y el resto
      const filteredComplejidad = result?.reduce((filteredArray, element) => {
        const idComplejidad = element?.SINC_idcomplejidadsiniestro;

        if (!idComplejidadSet.has(idComplejidad)) {
          idComplejidadSet.add(idComplejidad);

          if (idComplejidad === idcomplejidad) {
            // Colocar la complejidad deseada en la posición 0
            filteredArray.unshift({
              ...element,
              identificador: 0,
              texto: element?.SINC_complejidad,
            });
          } else {
            filteredArray.push({
              ...element,
              identificador: idComplejidad,
              texto: element?.SINC_complejidad,
            });
          }
        }

        return filteredArray;
      }, []);

      // Filtrar el país deseado y el resto
      const filteredpaises = resultPaises?.reduce(
        (filteredArrayPais, element) => {
          const idPais = element?.PAI_idpais;

          if (!idPaisSet.has(idPais)) {
            idPaisSet.add(idPais);

            if (idPais === idpais) {
              // Colocar el país deseado en la posición 0
              filteredArrayPais.unshift({
                ...element,
                identificador: 0,
                texto: element?.PAI_pais,
              });
            } else {
              filteredArrayPais.push({
                ...element,
                identificador: idPais,
                texto: element?.PAI_pais,
              });
            }
          }

          return filteredArrayPais;
        },
        []
      );

      setClasificacion(filteredComplejidad);
      setpaises(filteredpaises);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getComplejidad(idcomplejidad, idpais);
  }, [idcomplejidad, idpais]);

  //Estado donde se guardara el dato
  const [plataforma, setPlataforma] = useState("");

  const handleChangeclasi = (e) => {
    const dataClasificacion = parseInt(e.target.value);

    // Verificar si el usuario seleccionó la posición 0
    if (dataClasificacion === 0) {
      setIdclasificacion(idcomplejidad);
      return; // Sale de la función porque no hay necesidad de continuar
    }

    clasificacion.forEach((element) => {
      if (dataClasificacion === element.SINC_idcomplejidadsiniestro) {
        setIdclasificacion(element.SINC_idcomplejidadsiniestro);
        setPlataforma(element.SINC_complejidad.toLowerCase());
      }
    });
  };

  console.log("Id clasificación " + idclasificacion);
  console.log("Plataforma " + plataforma);
  console.log(clasificacion);

  //Estado donde se guardara el dato
  const [pais, setpais] = useState("");

  const handleChangePais = (e) => {
    const dataPais = parseInt(e.target.value);

    // Verificar si el usuario seleccionó la posición 0
    if (dataPais === 0) {
      setidpaisR(idpais);
      return; // Sale de la función porque no hay necesidad de continuar
    }

    paises.forEach((element) => {
      if (dataPais === element.PAI_idpais) {
        setidpaisR(element.PAI_idpais);
        setpais(element.PAI_pais.toLowerCase());
      }
    });
  };

  console.log("Id pais " + idpaisR);
  console.log("Pais" + pais);

  //Validación solo acepta números
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (/[^0-9]/.test(keyValue)) {
      event.preventDefault();
    }
  };

  //validación para que acepte solo letras.
  function handleKeyPressL(event) {
    const regex = /^[a-zA-Z]*$/;
    const isValidInput = regex.test(event.key);
    if (!isValidInput) {
      event.preventDefault();
    }
  }

  //Estados utilizados para sepomex
  const [SEP_Id, setSEP_Id] = useState("");
  const [SEP_asenta, setSEP_asenta] = useState("");
  const [SEP_municipio, setSEP_municipio] = useState("");
  const [SEP_estado, setSEP_estado] = useState("");
  const [SEP_ciudad, setSEP_ciudad] = useState("");
  const [SEPT_Id, setSEPT_Id] = useState("");
  const [SEPT_asenta, setSEPT_asenta] = useState("");
  const [SEPT_municipio, setSEPT_municipio] = useState("");
  const [SEPT_estado, setSEPT_estado] = useState("");
  const [SEPT_ciudad, setSEPT_ciudad] = useState("");
  const [codpostal, setCodPostal] = useState("");
  const [codigopostal, setCodigoPostal] = useState("");
  const [colonias, setColonias] = useState([]);
  const [colonia, setColonia] = useState([]);
  const [dataSelectColonias, setDataSelectColonias] = useState([]);
  const [dataSelectColonia, setDataSelectColonia] = useState([]);

  //Traer los datos de CP para los dos formatos de cp
  const getCP = async (cp) => {
    try {
      var result = await getDataToken(`sepomex/get_listado_cp/${cp}`);
      setColonia(result);
      if (result <= 0) {
        setDataSelectColonia([]);
        setSEPT_estado("");
        setSEPT_municipio("");
        setSEPT_asenta("");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getCodP = async (cp) => {
    try {
      var result = await getDataToken(`sepomex/get_listado_cp/${cp}`);
      setColonias(result);
      if (result <= 0) {
        setDataSelectColonias([]);
        setSEP_estado("");
        setSEP_municipio("");
        setSEP_asenta("");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  //Primer formulario CP

  const handleSubmitone = () => {
    if (codigopostal === "") {
      document.getElementById("txtCP").focus();
    } else {
      getCP(codigopostal);
      setEditColonia(false);
    }
  };

  const getInfoasentamiento = (e) => {
    const dataColonia = parseInt(e.target.value);
    colonia.forEach((element) => {
      if (dataColonia === element.id) {
        setSEPT_Id(element.id);
        setSEPT_municipio(element.SEP_municipio);
        setSEPT_estado(element.SEP_estado);
        setSEPT_ciudad(element.SEP_ciudad);
        setSEPT_asenta(element.SEP_asenta);
      }
    });
  };

  //Esta función useEffect se activará cuando haya cambios en el estado 'colonias', activando a su ves otro estado
  useEffect(() => {
    if (colonia.length > 0) {
      let dataColonia = colonia;
      dataColonia.map(
        (cp) => (
          (cp.texto = cp.SEP_asenta), // cp.texto es una propiedad que no existe dentro del JSON, por tanto, se crea
          (cp.identificador = cp.id)
        )
      ); //Se actualiza el valor del dataSelectColonias
      setDataSelectColonia(dataColonia);
    }
  }, [colonia]);

  console.log("Id sepomex primero" + SEPT_Id);

  //Segundo formulario CP

  const handleSubmit = () => {
    if (codpostal === "") {
      document.getElementById("txtCP2").focus();
    } else {
      getCodP(codpostal);
      setEditColonias(false);
    }
  };

  const getInfoasenta = (e) => {
    const dataColonia = parseInt(e.target.value);
    colonias.forEach((element) => {
      if (dataColonia === element.id) {
        setSEP_Id(element.id);
        setSEP_municipio(element.SEP_municipio);
        setSEP_estado(element.SEP_estado);
        setSEP_ciudad(element.SEP_ciudad);
        setSEP_asenta(element.SEP_asenta);
      }
    });
  };

  //Esta función useEffect se activará cuando haya cambios en el estado 'colonias', activando a su ves otro estado
  useEffect(() => {
    if (colonias.length > 0) {
      let dataColonias = colonias;
      dataColonias.map(
        (cp) => (
          (cp.texto = cp.SEP_asenta), // cp.texto es una propiedad que no existe dentro del JSON, por tanto, se crea
          (cp.identificador = cp.id)
        )
      ); //Se actualiza el valor del dataSelectColonias
      setDataSelectColonias(dataColonias);
    }
  }, [colonias]);

  console.log("Id sepomex " + SEP_Id);

  //Estados para conocer el valor de los switch
  const [isChecked, setIsChecked] = useState();
  const [isOn, setIsOn] = useState();

  // Valor del check 1
  const handleSwitchChange = (event) => {
    setIsOn(event.target.checked);
  };

  console.log("checked 1" + isOn);

  // Valor del check 2
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  console.log("checked 2" + isChecked);

  ///////////////////////////////////////////////
  const [values, setValues] = React.useState({
    SIN_causadereclamacion: "",
    SIN_calle: "",
    SIN_fuecremado: "",
    SIN_fueinhumado: "",
    SIN_edadconcentracion: "",
    SIN_edadsiniestro: "",
    SIN_ciudadsiniestro: "",
    SIN_estadosiniestro: "",
    SIN_cpsiniestro: "",
    PER_nombre: "",
    PER_apellidopaterno: "",
    PER_apellidomaterno: "",
    PER_cedulaprofesional: "",
    PER_calle: "",
    PER_numeroexterior: "",
    PER_numerointerior: "",
    PER_ciudadmedico: "",
    PER_estadomedico: "",
    PER_cpmedico: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  React.useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      SIN_causadereclamacion: Data[0]?.SIN_causadereclamacion,
      SIN_calle: Data[0]?.SIN_calle,
      SIN_edadconcentracion: Data[0]?.SIN_edadconcentracion,
      SIN_edadsiniestro: Data[0]?.SIN_edadsiniestro,
      SIN_ciudadsiniestro: Data[0]?.SIN_ciudadsiniestro,
      SIN_estadosiniestro: Data[0]?.SIN_estadosiniestro,
      SIN_cpsiniestro: Data[0]?.SIN_cpsiniestro,
      PER_nombre: DataMedico[0]?.PER_idpersona?.PER_nombre,
      PER_apellidopaterno: DataMedico[0]?.PER_idpersona?.PER_apellidopaterno,
      PER_apellidomaterno: DataMedico[0]?.PER_idpersona?.PER_apellidomaterno,
      PER_cedulaprofesional:
        DataMedico[0]?.PER_idpersona?.PER_cedulaprofesional,
      PER_calle: DataMedico[0]?.PER_idpersona?.PER_calle,
      PER_numeroexterior: DataMedico[0]?.PER_idpersona?.PER_numeroexterior,
      PER_numerointerior: DataMedico[0]?.PER_idpersona?.PER_numerointerior,
      PER_ciudadmedico: DataMedico[0]?.PER_idpersona?.PER_ciudadmedico,
      PER_estadomedico: DataMedico[0]?.PER_idpersona?.PER_estadomedico,
      PER_cpmedico: DataMedico[0]?.PER_idpersona?.PER_cpmedico,
    }));
    setCodigoPostal(Data[0]?.SEP_codpostal?.SEP_codpostal);
    setSEPT_municipio(Data[0]?.SEP_codpostal?.SEP_municipio);
    setSEPT_Id(Data[0]?.SEP_codpostal?.id);
    setSEPT_estado(Data[0]?.SEP_codpostal?.SEP_estado);
    setSEPT_ciudad(Data[0]?.SEP_codpostal?.SEP_ciudad);
    setSEPT_asenta(Data[0]?.SEP_codpostal?.SEP_asenta);
    setIsChecked(Data[0]?.SIN_fuecremado);
    setIsOn(Data[0]?.SIN_fueinhumado);
    setCodPostal(DataMedico[0]?.PER_idpersona?.SEP_idsepomex?.SEP_codpostal);
    setSEP_municipio(
      DataMedico[0]?.PER_idpersona?.SEP_idsepomex?.SEP_municipio
    );
    setSEP_Id(DataMedico[0]?.PER_idpersona?.SEP_idsepomex?.id);
    setSEP_estado(DataMedico[0]?.PER_idpersona?.SEP_idsepomex?.SEP_estado);
    setSEP_ciudad(DataMedico[0]?.PER_idpersona?.SEP_idsepomex?.SEP_ciudad);
    setSEP_asenta(DataMedico[0]?.PER_idpersona?.SEP_idsepomex?.SEP_asenta);
  }, [Data, DataMedico]);

  //contantes para editar información
  const [editCampos, setEditCampos] = useState(true);
  const editarCampos = (e) => {
    setEditCampos(false);
  };

  const [editColonia, setEditColonia] = useState(true);
  const [editColonias, setEditColonias] = useState(true);

  //Alerta de confirmacion
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    // Actualizar el estado utilizando el hook useState
    setVisible(false);
  };

  return (
    <Grid container>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          Data.forEach((siniestros) => {
            let siniestro = {
              SIN_causadereclamacion: values.SIN_causadereclamacion,
              SIN_calle: values.SIN_calle,
              SEP_codpostal: SEPT_Id,
              SIN_fuecremado: isChecked,
              SIN_fueinhumado: isOn,
              SIN_edadconcentracion: values.SIN_edadconcentracion,
              SIN_edadsiniestro: values.SIN_edadsiniestro,
              SINC_idcomplejidadsiniestro: idclasificacion,
              RECT_idfolio: idFolio,
              PAI_idpais: idpaisR,
              SIN_ciudadsiniestro: values.SIN_ciudadsiniestro,
              SIN_estadosiniestro: values.SIN_estadosiniestro,
              SIN_cpsiniestro: values.SIN_cpsiniestro,
              SIN_actualizofecha: fechaActSIN,
              SIN_actualizousuario: nombreAnalista,
            };
            putDataToken(
              `siniestros/get_id/${siniestros.SIN_idsiniestro}/`,
              siniestro
            ).then((data) => {
              console.log(
                `Registro siniestro ${siniestros.SIN_idsiniestro} actualizado`,
                data
              );
            });
          });

          DataMedico.forEach((medico) => {
            let personas = {
              PER_nombre: values.PER_nombre,
              PER_apellidopaterno: values.PER_apellidopaterno,
              PER_apellidomaterno: values.PER_apellidomaterno,
              PER_cedulaprofesional: values.PER_cedulaprofesional,
              PER_calle: values.PER_calle,
              PER_numeroexterior:
                idpaisR === IDPAISMEXICO ? values.PER_numeroexterior : null,
              PER_numerointerior:
                idpaisR === IDPAISMEXICO ? values.PER_numerointerior : null,
              SEP_idsepomex: SEP_Id,
              PERR_idrol: medico?.PER_idpersona?.PERR_idrol?.PERR_idrol,
              PAI_idpais: idpaisR,
              PER_ciudadmedico: values.PER_ciudadmedico,
              PER_estadomedico: values.PER_estadomedico,
              PER_cpmedico: values.PER_cpmedico,
              PER_actualizofecha: fechaActSIN,
              PER_actualizousuario: nombreAnalista,
            };
            putDataToken(
              `personas/get_id/${medico.PER_idpersona?.PER_idpersona}/`,
              personas
            ).then((data) => {
              console.log(
                `Registro persona ${medico.PER_idpersona?.PER_idpersona} actualizado`,
                data
              );
            });
          });
          setVisible(true);
        }}
      >
        <div>
          {visible && (
            <DialogAviso
              title="Registro actualizado"
              firstButton={
                <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
              }
              description={"Reclamación actualizada correctamente"}
              onClick1={handleClick}
            />
          )}
          <div>
            <Grid container>
              <Grid item xs={12}>
                <h3-title-card>
                  <FormattedMessage
                    id="regis.datosdelsiniestro.reclamacion"
                    defaultMessage="Causa de la reclamación"
                  />
                  :
                </h3-title-card>
                <br />
                <h3-subtitle-card>
                  Motivo por el que el asegurado o beneficiario están
                  presentando la reclamación
                </h3-subtitle-card>
              </Grid>
              <TXTField
                id="inputEjemplo"
                required
                disabled={editCampos}
                onChange={handleChange("SIN_causadereclamacion")}
                value={values?.SIN_causadereclamacion}
                onInput={(e) => LimitarTexto(e.target, 50)}
              />
            </Grid>
            <hr />
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <br />
                <h3-title-card>
                  <FormattedMessage
                    id="regis.datosdelsiniestro.defuncion"
                    defaultMessage="Lugar de defunción"
                  />
                  :
                </h3-title-card>
                <br />
              </Grid>
            </Grid>
            {idpaisR === IDPAISMEXICO ? (
              <Grid container spacing={3}>
                <Grid item xs={3} style={{ paddingTop: "0px" }}>
                  <SelectDictamen
                    disabled={editCampos}
                    sx={{
                      minWidth: 210,
                      maxWidth: 210,
                      height: 40,
                      background: "white",
                    }}
                    label={
                      <FormattedMessage
                        id="globColoni"
                        defaultMessage={`Pais *`}
                      />
                    }
                    array={paises}
                    onChange={handleChangePais}
                  />
                </Grid>
                <Grid item xs={3} style={{ paddingTop: "0px" }}>
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globCodPos"
                        defaultMessage="Código postal"
                      />
                    }
                    size="small"
                    required
                    disabled={editCampos}
                    value={codigopostal}
                    name="txtCP"
                    id="txtCP"
                    onChange={(event) => setCodigoPostal(event.target.value)}
                    onKeyPress={handleKeyPress}
                    onPaste={(e) => e.preventDefault()}
                    onInput={(e) => LimitarDigitos(e.target, 6)}
                    showSearchIcon={true}
                    iconOnClick={handleSubmitone}
                    disabledicon={editCampos}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={3} style={{ paddingTop: "0px" }}>
                  <SelectDictamen
                    required
                    disabled={editColonia}
                    sx={{
                      minWidth: 210,
                      maxWidth: 210,
                      height: 40,
                      background: "white",
                    }}
                    label={
                      <FormattedMessage
                        id="globColoni"
                        defaultMessage={`Colonia *`}
                      />
                    }
                    array={
                      dataSelectColonia.length > 0
                        ? dataSelectColonia
                        : asentamiento
                    }
                    onChange={getInfoasentamiento}
                  />
                </Grid>
                <Grid item xs={3} style={{ paddingTop: "0px" }}>
                  <TXTField
                    label={
                      <FormattedMessage
                        id="regisAsent"
                        defaultMessage="Asentamiento"
                      />
                    }
                    size="small"
                    required
                    disabled={true}
                    value={SEPT_asenta}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={3} style={{ paddingTop: "0px" }}>
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globmunicipioAlcadia"
                        defaultMessage="Municipio o Alcaldia"
                      />
                    }
                    size="small"
                    required
                    disabled={true}
                    value={SEPT_municipio}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={3} style={{ paddingTop: "0px" }}>
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globestado"
                        defaultMessage="Estado"
                      />
                    }
                    size="small"
                    required
                    disabled={true}
                    value={SEPT_estado}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={3} style={{ paddingTop: "0px" }}>
                  <TXTField
                    label={
                      <FormattedMessage
                        id="globciudad"
                        defaultMessage="ciudad"
                      />
                    }
                    size="small"
                    required
                    disabled={true}
                    value={SEPT_ciudad}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={3} style={{ paddingTop: "0px" }}>
                  <TXTField
                    label={
                      <FormattedMessage
                        id="recep.nuevotram.calle"
                        defaultMessage="Calle"
                      />
                    }
                    size="small"
                    required
                    disabled={editCampos}
                    value={values.SIN_calle}
                    onChange={handleChange("SIN_calle")}
                    onInput={(e) => LimitarTexto(e.target, 50)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            ) : (
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <SelectDictamen
                      disabled={editCampos}
                      sx={{
                        minWidth: 210,
                        maxWidth: 210,
                        height: 40,
                        background: "white",
                      }}
                      label={
                        <FormattedMessage
                          id="globColoni"
                          defaultMessage={`Pais *`}
                        />
                      }
                      array={paises}
                      onChange={handleChangePais}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <TXTField
                      label={
                        <FormattedMessage
                          id="recep.nuevotram.calle"
                          defaultMessage="Dirección"
                        />
                      }
                      size="small"
                      disabled={editCampos}
                      value={values.SIN_calle}
                      onChange={handleChange("SIN_calle")}
                      onInput={(e) => LimitarTextoSinCondiciones(e.target, 50)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <TXTField
                      label={
                        <FormattedMessage
                          id="recep.nuevotram.calle"
                          defaultMessage="Ciudad"
                        />
                      }
                      size="small"
                      disabled={editCampos}
                      value={values.SIN_ciudadsiniestro}
                      onChange={handleChange("SIN_ciudadsiniestro")}
                      onInput={(e) => LimitarTexto(e.target, 50)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <TXTField
                      label={
                        <FormattedMessage
                          id="recep.nuevotram.calle"
                          defaultMessage="Estado"
                        />
                      }
                      size="small"
                      disabled={editCampos}
                      value={values.SIN_estadosiniestro}
                      onChange={handleChange("SIN_estadosiniestro")}
                      onInput={(e) => LimitarTexto(e.target, 50)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <TXTField
                      label={
                        <FormattedMessage
                          id="recep.nuevotram.calle"
                          defaultMessage="Código"
                        />
                      }
                      size="small"
                      disabled={editCampos}
                      value={values.SIN_cpsiniestro}
                      onChange={handleChange("SIN_cpsiniestro")}
                      onInput={(e) => LimitarTextoCodigo(e.target, 50)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            <hr />
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <br />
                <h3-title-card>
                  <FormattedMessage
                    id="regis.datosdelsiniestro.adicsiniestro"
                    defaultMessage="Informacion adicional del siniestro"
                  />
                  :
                </h3-title-card>
              </Grid>
              <Grid item xs={3} style={{ paddingBottom: "0px" }}>
                <h3-subtitle-card>
                  <FormattedMessage
                    id="regis.datosdelsiniestro.cremado"
                    defaultMessage="¿El cuerpo fue cremado?"
                  />
                </h3-subtitle-card>
              </Grid>
              <Grid item xs={3} style={{ paddingBottom: "0px" }}>
                <Check2
                  disabled={editCampos}
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  option1={<FormattedMessage id="globNo" defaultMessage="No" />}
                  option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
                />
              </Grid>
              <Grid item xs={3} style={{ paddingBottom: "0px" }}>
                <h3-subtitle-card>
                  <FormattedMessage
                    id="regis.datosdelsiniestro.inhumado"
                    defaultMessage="¿El cuerpo fue inhumado?"
                  />
                </h3-subtitle-card>
              </Grid>
              <Grid item xs={3} style={{ paddingBottom: "0px" }}>
                <Check2
                  disabled={editCampos}
                  checked={isOn}
                  onChange={handleSwitchChange}
                  option1={<FormattedMessage id="globNo" defaultMessage="No" />}
                  option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
                />
              </Grid>
              <Grid item xs={3} style={{ paddingTop: "10px" }}>
                <TXTField
                  label={
                    <FormattedMessage
                      id="regis.datosdelsiniestro.edadcontratacio"
                      defaultMessage="Edad de contratación"
                    />
                  }
                  size="small"
                  required
                  disabled={editCampos}
                  value={values.SIN_edadconcentracion}
                  onChange={handleChange("SIN_edadconcentracion")}
                  onInput={(e) => LimitarDigitos(e.target, 2)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3} style={{ paddingTop: "10px" }}>
                <TXTField
                  label={
                    <FormattedMessage
                      id="regis.datosdelsiniestro.edadsiniestro"
                      defaultMessage="Edad de siniestro"
                    />
                  }
                  size="small"
                  required
                  disabled={editCampos}
                  value={values.SIN_edadsiniestro}
                  onChange={handleChange("SIN_edadsiniestro")}
                  onInput={(e) => LimitarDigitos(e.target, 2)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            <hr />
            {/* DATOS DEL MÉDICO QUE CERTIFICA */}
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <br />
                <h3-title-card>
                  <FormattedMessage
                    id="regis.datosdelsiniestro.datmedicocertifica"
                    defaultMessage="Datos del médico que certifica"
                  />
                  :
                </h3-title-card>
                <br />
              </Grid>
              <Grid item xs={3} style={{ paddingTop: "0px" }}>
                <TXTField
                  label={
                    <FormattedMessage
                      id="datos.nom"
                      defaultMessage="Nombre(s)"
                    />
                  }
                  size="small"
                  disabled={editCampos}
                  required
                  value={values.PER_nombre}
                  onChange={handleChange("PER_nombre")}
                  onKeyPress={handleKeyPressL}
                  onPaste={(e) => e.preventDefault()}
                  onInput={(e) => LimitarTexto(e.target, 50)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3} style={{ paddingTop: "0px" }}>
                <TXTField
                  label={
                    <FormattedMessage
                      id="datos.apellidopate"
                      defaultMessage="Apellido paterno"
                    />
                  }
                  size="small"
                  required
                  disabled={editCampos}
                  value={values.PER_apellidopaterno}
                  onChange={handleChange("PER_apellidopaterno")}
                  onKeyPress={handleKeyPressL}
                  onPaste={(e) => e.preventDefault()}
                  onInput={(e) => LimitarTexto(e.target, 50)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3} style={{ paddingTop: "0px" }}>
                <TXTField
                  label={
                    <FormattedMessage
                      id="datos.apellidomate"
                      defaultMessage="Apellido materno"
                    />
                  }
                  size="small"
                  required
                  disabled={editCampos}
                  value={values.PER_apellidomaterno}
                  onChange={handleChange("PER_apellidomaterno")}
                  onKeyPress={handleKeyPressL}
                  onPaste={(e) => e.preventDefault()}
                  onInput={(e) => LimitarTexto(e.target, 50)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3} style={{ paddingTop: "0px" }}>
                <TXTField
                  label={
                    <FormattedMessage
                      id="regis.datossinies.cedprof"
                      defaultMessage="Cédula Profesional"
                    />
                  }
                  size="small"
                  required
                  disabled={editCampos}
                  value={values.PER_cedulaprofesional}
                  onChange={handleChange("PER_cedulaprofesional")}
                  onKeyPress={handleKeyPress}
                  onPaste={(e) => e.preventDefault()}
                  onInput={(e) => LimitarDigitos(e.target, 15)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            {idpaisR === IDPAISMEXICO ? (
              <div>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <br />
                    <TXTField
                      label={
                        <FormattedMessage
                          id="globCodPos"
                          defaultMessage="Código postal"
                        />
                      }
                      size="small"
                      required
                      disabled={editCampos}
                      value={codpostal}
                      name="txtCP"
                      id="txtCP"
                      onChange={(event) => setCodPostal(event.target.value)}
                      onKeyPress={handleKeyPress}
                      onPaste={(e) => e.preventDefault()}
                      onInput={(e) => LimitarDigitos(e.target, 6)}
                      showSearchIcon={true}
                      iconOnClick={handleSubmit}
                      disabledicon={editCampos}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <br />
                    <SelectDictamen
                      required
                      disabled={editColonias}
                      sx={{
                        minWidth: 210,
                        maxWidth: 210,
                        height: 40,
                        background: "white",
                      }}
                      label={
                        <FormattedMessage
                          id="globColoni"
                          defaultMessage={`Colonia *`}
                        />
                      }
                      array={
                        dataSelectColonias.length > 0
                          ? dataSelectColonias
                          : asentamientos
                      }
                      onChange={getInfoasenta}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <br />
                    <TXTField
                      label={
                        <FormattedMessage
                          id="regisAsent"
                          defaultMessage="Asentamiento"
                        />
                      }
                      size="small"
                      required
                      disabled={true}
                      value={SEP_asenta}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <br />
                    <TXTField
                      label={
                        <FormattedMessage
                          id="globmunicipioAlcadia"
                          defaultMessage="Municipio o Alcaldia"
                        />
                      }
                      size="small"
                      required
                      disabled={true}
                      value={SEP_municipio}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <TXTField
                      label={
                        <FormattedMessage
                          id="globestado"
                          defaultMessage="Estado"
                        />
                      }
                      size="small"
                      required
                      disabled={true}
                      value={SEP_estado}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <TXTField
                      label={
                        <FormattedMessage
                          id="globciudad"
                          defaultMessage="ciudad"
                        />
                      }
                      size="small"
                      required
                      disabled={true}
                      value={SEP_ciudad}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <TXTField
                      label={
                        <FormattedMessage
                          id="recep.nuevotram.calle"
                          defaultMessage="Calle"
                        />
                      }
                      size="small"
                      required
                      disabled={editCampos}
                      value={values.PER_calle}
                      onChange={handleChange("PER_calle")}
                      onInput={(e) => LimitarTexto(e.target, 100)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <TXTField
                      label={
                        <FormattedMessage
                          id="regis.datosdelsiniestro.noexterno"
                          defaultMessage="No. Ext"
                        />
                      }
                      size="small"
                      required
                      disabled={editCampos}
                      value={values.PER_numeroexterior}
                      onChange={handleChange("PER_numeroexterior")}
                      onKeyPress={handleKeyPress}
                      onPaste={(e) => e.preventDefault()}
                      onInput={(e) => LimitarDigitos(e.target, 5)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <TXTField
                      label={
                        <FormattedMessage
                          id="regis.datosdelsiniestro.nointerno"
                          defaultMessage="No. Int"
                        />
                      }
                      size="small"
                      required
                      disabled={editCampos}
                      value={values.PER_numerointerior}
                      onChange={handleChange("PER_numerointerior")}
                      onKeyPress={handleKeyPress}
                      onPaste={(e) => e.preventDefault()}
                      onInput={(e) => LimitarDigitos(e.target, 5)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <SelectDictamen
                      required
                      disabled={editCampos}
                      sx={{
                        minWidth: 210,
                        maxWidth: 210,
                        height: 40,
                        background: "white",
                      }}
                      label={
                        <FormattedMessage
                          id="globColoni"
                          defaultMessage={`Clasificación *`}
                        />
                      }
                      array={clasificacion}
                      onChange={handleChangeclasi}
                    />
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <br />
                    <TXTField
                      label={
                        <FormattedMessage
                          id="recep.nuevotram.calle"
                          defaultMessage="Dirección"
                        />
                      }
                      size="small"
                      disabled={editCampos}
                      value={values.PER_calle}
                      onChange={handleChange("PER_calle")}
                      onInput={(e) => LimitarTextoSinCondiciones(e.target, 50)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <br />
                    <TXTField
                      label={
                        <FormattedMessage
                          id="recep.nuevotram.calle"
                          defaultMessage="Ciudad"
                        />
                      }
                      size="small"
                      disabled={editCampos}
                      value={values.PER_ciudadmedico}
                      onChange={handleChange("PER_ciudadmedico")}
                      onInput={(e) => LimitarTexto(e.target, 50)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <br />
                    <TXTField
                      label={
                        <FormattedMessage
                          id="recep.nuevotram.calle"
                          defaultMessage="Estado"
                        />
                      }
                      size="small"
                      disabled={editCampos}
                      value={values.PER_estadomedico}
                      onChange={handleChange("PER_estadomedico")}
                      onInput={(e) => LimitarTexto(e.target, 50)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <br />
                    <TXTField
                      label={
                        <FormattedMessage
                          id="recep.nuevotram.calle"
                          defaultMessage="Código"
                        />
                      }
                      size="small"
                      disabled={editCampos}
                      value={values.PER_cpmedico}
                      onChange={handleChange("PER_cpmedico")}
                      onInput={(e) => LimitarTextoCodigo(e.target, 50)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <SelectDictamen
                      required
                      disabled={editCampos}
                      sx={{
                        minWidth: 210,
                        maxWidth: 210,
                        height: 40,
                        background: "white",
                      }}
                      label={
                        <FormattedMessage
                          id="globColoni"
                          defaultMessage={`Clasificación *`}
                        />
                      }
                      array={clasificacion}
                      onChange={handleChangeclasi}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              style={{ paddingRight: "10px" }}
            >
              <Grid item>
                <ButtonVino
                  label="Editar"
                  onClick={(e) => editarCampos(e)}
                  color="secondary"
                />
              </Grid>
              <Grid item>
                <ButtonVino label="Guardar" type="submit" />
              </Grid>
            </Grid>
          </div>
        </div>
      </form>
    </Grid>
  );
};
export default CardDatosReclamacionFallecimiento;
