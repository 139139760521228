/* Brandon Hernandez Rocha 04/2023 */
import React from "react";
import CustomSeparatorDinamic from "../Tools/BreadCrumbs/RecorridoDinamico";
import "../../css/estilo.css";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

//table
import TablaRegistroConsulCont from "./Elements/Tables/TablaRegistroConsulCont";

//Tabla

//idioma
import { FormattedMessage } from "react-intl";
const CompDictamenReserConsul = () => {
  const location = useLocation();
  const dataFolio = location.state.infoRow;
  const infoFolio = dataFolio;

  const arrayBreadcrumbs = [
    {
      key: 1,
      name: <FormattedMessage id="globInicio" defaultMessage="Inicio" />,
      to: "/menu",
    },
    {
      key: 2,
      name: <FormattedMessage id="menuDictam" defaultMessage="Dictamen" />,
      to: "/dictamen",
    },
    {
      key: 3,
      name: (
        <FormattedMessage id="dictaDetalleF" defaultMessage="Detalle folio" />
      ),
      to: "/dictamen/detalle_folio/",
      state: { row: infoFolio },
    },
    {
      key: 3,
      name: <FormattedMessage id="dictamRese" defaultMessage="Reservas" />,
      to: "/dictamen/detalle_folio/reservas/",
      state: { row : infoFolio },
    },
  ];

  return (
    <div className="div-parent">
      <div className="div-breadcrumbs">
        <CustomSeparatorDinamic
          breadcrumbs={arrayBreadcrumbs}
          name={`Consulta contable`}
        />
      </div>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Link
          style={{ textDecoration: "none" }}
          to="/dictamen/detalle_folio/reservas"
          state={{ row : infoFolio }}
        >
          <ReturnPage />
        </Link>
      </Grid>
      <div className="div-container">
        <Grid container direction="row" justifyContent="flex-start">
          <TablaRegistroConsulCont />
        </Grid>
      </div>
    </div>
  );
};
export default CompDictamenReserConsul;
