import React from "react";
import TextField from "@mui/material/TextField";


function EditableCell(props) {
    const handleChange = (event) => {
        props.onValueChange(event);
    };

    return (

        <TextField
            fullwidth
            style={{
                cursor: 'pointer',
                border: 'none',
                width: 'auto',
                backgroundColor: 'transparent',
                outline: 'none',
                position: 'sticky',
                marginTop: '16px',
                marginBottom: '16px',
                paddingTop: '8px', paddingBottom: '8px'
            }}
            size={props.size ? props.size : "small"}
            error={props.error}
            helperText={props.errorMessage}
            type="text"
            value={props.value}
            defaultValue={props.defaultValue}
            onChange={handleChange}
            pattern={props.pattern}
            disabled={props.disabled}
            name={props.name}
            id={props.id}
            ref={props.inputRef}
            autoComplete="off"
        />
    );
}

export default EditableCell;