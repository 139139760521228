import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import IconButton from "@material-ui/core/IconButton";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@material-ui/core/Grid";
import { FormattedMessage } from "react-intl";
import TXTField from "../../../../Tools/TextField/TextField";
import Paper from "@mui/material/Paper";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import TablePagination from "@mui/material/TablePagination";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button } from "@mui/material";
import ButtonVino from "../../../../Tools/Bottones/ButtonVino";
import { CardActionArea, Typography } from "@mui/material";
import { CardContent } from "@mui/material";
import { TextField } from "@mui/material";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SearchTXTField from "../../../../Tools/TextField/SearchTextField";
import { getToken } from "../../../../../api/token";
import { getDataToken } from "../../../../../api/dataToken";
import { BASE_API } from "../../../../../utils/constans";
import { secundario } from '../../../../../api/getUmbrales';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: secundario,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Catalogoii() {
  function refreshPage() {
    window.location.reload(false);
  }

  //Token
  const [tableData, setTableData] = useState([]);
  const token = getToken();

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  //Jalar los datos de las Api's
  const fetchData = async () => {
    //Resultados de la api investigacion
    var resultID = await getDataToken("catalogoOII/get_listado/");
    setTableData(resultID);
  };

  //fecha actual
  var today = new Date();

  const rows = [createData(tableData)];

  function createData(tableData) {
    return { tableData };
  }

  useEffect(() => {
    fetchData();
  }, []);
  //Eliminar
  function eliminar(id) {
    let response = fetch(`${BASE_API}catalogoOII/get_id/` + id + "/", {
      method: "PUT",
      headers: headers,
      body: JSON.stringify({
        OII_activo: false,
        OII_actualizousuario: localStorage.getItem("SNYTLOG_email"),
      }),
    });
    response.then((response) => {
      console.log(response);
    });

    refreshPage();
  }

  //editar
  const [editar, setEditar] = React.useState("");
  function editarPrueba() {

    alert(
      <FormattedMessage id="ConfGEntrEd" defaultMessage="Entro a editar" />
    );

  }
  const [valuee, setValuee] = useState({
    OII_descripcionedit: "",
  });
  const edittClick = (prop) => (event) => {
    setValue({ ...valuee, [prop]: event.target.value });
    console.log(valuee);
  };

  async function PUTDataToken(aux) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${token}`);

    try {
      const response = fetch(
        `${BASE_API}catalogoOII/get_id/` + idselect + "/",
        {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(aux),
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong. Status: " + response.status);
        refreshPage();
      }
      const responseJSON = await response.json();
      return responseJSON;
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }

  //Funcion para el boton de editar y abrir ventana modal
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla
  const [idselect, setidselect] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const handleClickOpen = (scrollType, row) => () => {
    setScroll(scrollType);
    setEditar(row);
    setidselect(row.OII_idoii);
  };

  useEffect(() => {
    if (editar != "") setOpen(true);
  }, [editar]);

  const handleClose = () => {
    setOpen(false);
    refreshPage();
  };

  const handleClose3 = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  //Constante para los datos
  const [value, setValue] = useState({
    OII_descripcion: "",
  });
  //Funcion para agregar nuevos datos de la api
  const anadirClick = (prop) => (event) => {
    setValue({ ...value, [prop]: event.target.value });
    console.log(value);
  };
  async function postDataToken(aux) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${token}`);

    try {
      const response = await fetch(`${BASE_API}catalogoOII/get_listado/`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(aux),
      });

      if (!response.ok) {
        throw new Error("Something went wrong. Status: " + response.status);
        refreshPage();
      }
      const responseJSON = await response.json();
      return responseJSON;
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }

  // Funcion para abril modal para agregar datoo
  const [openn, setOpenn] = React.useState(false);
  const [scrolll, setScrolll] = React.useState("paper");
  const handleClickOpen1 = (scrollType) => () => {
    setOpenn(true);
    setScrolll(scrollType);
  };

  const handleClose1 = () => {
    setOpenn(false);
  };

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(5); //El tamaño inicial de las filas será de 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    if (openn) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openn]);

  /* CONSTANTES DE ESTADOS*/


  const [order, setOrder] = useState("ASC"); // esta constante tiene como propósico guardar el estado del orden de la tabla


  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...tableData].sort((a, b) =>
        a[col].toString() > b[col].toString() ? 1 : -1
      );
      setOrder("DSC");
      setTableData(sorted);
    } else if (order === "DSC") {
      const sorted = [...tableData].sort((a, b) =>
        a[col].toString() > b[col].toString() ? -1 : 1
      );
      setOrder("ASC");
      setTableData(sorted);
    }
  };

  return (
    <div>
      <Grid container alignItems="center" spacing={5}>
        <Grid item xs={3}>
          <SearchTXTField
            label={<FormattedMessage id="globBusqued" defaultMessage="Búsqueda" />}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
      <Grid item xs={3}>
        <Button variant="contained" onClick={handleClickOpen1("paper")}>Agregar nuevo dato</Button>
      </Grid> 
    </Grid>
    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="right">ID </StyledTableCell>
                <StyledTableCell
                  align="right"
                  onClick={() => sorting("OII_descripcion")}
                >

                  <FormattedMessage
                    id="globDescri"
                    defaultMessage="Descripción"
                  />

                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    {order === "DSC" ? <UnfoldMoreIcon /> : <UnfoldMoreIcon />}
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <FormattedMessage id="globEstatu" defaultMessage="Estatus" />
                </StyledTableCell>
                <StyledTableCell align="right">

                  <FormattedMessage id="globFecha" defaultMessage="Fecha" />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <FormattedMessage id="globAsigna" defaultMessage="Asignado" />

                </StyledTableCell>
                <StyledTableCell align="right">
                  <FormattedMessage id="globEditar" defaultMessage="Editar" />
                </StyledTableCell>
                <StyledTableCell align="right">

                  <FormattedMessage id="estconElim" defaultMessage="Eliminar" />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData
                //  .filter(val =>{
                //   if(searchTerm===""){
                //     return val;
                //   }
                //   /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                //   else if(
                //     val.OII_descripcion.toString().includes(searchTerm.toString())||
                //     val.OII_claveoii.toString().includes(searchTerm.toString())
                //   )

                //   {
                //     return val;
                //   }
                // })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow
                    key={row.OII_idoii}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell component="th" scope="row">
                      {row.OII_claveoii}
                    </StyledTableCell>

                    <StyledTableCell align="right">{row.OII_descripcion}</StyledTableCell>
                    {row.OII_activo ? (
                      <StyledTableCell align="right">


                        <FormattedMessage
                          id="globActivo"
                          defaultMessage="Activo"
                        />
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell align="right">

                        <FormattedMessage
                          id="globInacti"
                          defaultMessage="Inativo"

                        />
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="right">
                      {row.OII_actualizofecha}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.OII_actualizousuario}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Button onClick={handleClickOpen("paper", row)}>
                        <ArrowBackIcon />
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Button onClick={() => eliminar(row.OII_idoii)}>
                        <DeleteForeverIcon />
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

      <div>
        {/* Modal para editar dato */}
        <Dialog open={open}>
          <form
            onSubmit={(e) => {
              const { OII_descripcionedit, OII_claveoiiedit } =
                e.target.elements;
              e.preventDefault();
              let aux = {
                OII_descripcion: OII_descripcionedit.value,
                OII_claveoii: OII_claveoiiedit.value,
                OII_actualizofecha: today,
                OII_actualizousuario: localStorage.getItem("SNYTLOG_email"),
              };
              PUTDataToken(aux);
            }}
          >
            <DialogTitle>
              <FormattedMessage id="confGEditP" defaultMessage="Editar plan" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Box>
                  {" "}
                  <CardContent>
                    <CardContent>
                      <Typography style={{ fontSize: 14, color: "grey" }}>
                        <FormattedMessage
                          id="globDescri"
                          defaultMessage="Descripción"
                        />
                      </Typography>
                      <TextField
                        name="OII_descripcionedit"
                        label=""
                        fullWixdth
                        defaultValue={editar.OII_descripcion}
                        onChange={edittClick("OII_descripcionedit")}
                      />
                      <br />
                      <Typography style={{ fontSize: 14, color: "grey" }}>
                        <FormattedMessage
                          id="globaClave"
                          defaultMessage="Clave OII"
                        />
                      </Typography>
                      <TextField
                        name="OII_claveoiiedit"
                        label=""
                        fullWidth
                        defaultValue={editar.OII_claveoii?.OII_claveoii}
                        onChange={edittClick("OII_claveoiiedit")}
                      />
                    </CardContent>
                  </CardContent>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ButtonVino
                variant="contained"
                label={
                  <FormattedMessage id="globCance" defaultMessage="Cancelar" />
                }
                onClick={handleClose3}
              />
              <ButtonVino
                variant="contained"
                color="primary"
                label={
                  <FormattedMessage id="globGuarda" defaultMessage="Guardar" />
                }
                onClick={handleClose}
                type="submit"
              />
            </DialogActions>
          </form>
        </Dialog>
        {/* Modal para agregar dato */}
        <Dialog open={openn}>
          <form
            onSubmit={(e) => {
              const { OII_descripcion } = e.target.elements;
              e.preventDefault();
              let aux = {
                OII_descripcion: OII_descripcion.value,
              };
              postDataToken(aux);
            }}
          >
            <DialogTitle>
              <FormattedMessage id="confGEditP" defaultMessage="Editar plan" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Box>
                  {" "}
                  <CardContent>
                    <CardContent>
                      <Typography style={{ fontSize: 14, color: "grey" }}>
                        <FormattedMessage
                          id="globDescri"
                          defaultMessage="Descripción"
                        />
                      </Typography>
                      <TextField
                        name="OII_descripcion"
                        label=""
                        fullWidth
                        onChange={anadirClick("OII_descripcion")}
                      />
                    </CardContent>
                  </CardContent>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ButtonVino
                variant="contained"
                label={
                  <FormattedMessage id="globCance" defaultMessage="Cancelar" />
                }
                onClick={handleClose1}
              />
              <ButtonVino
                variant="contained"
                color="primary"
                label={
                  <FormattedMessage id="globGuarda" defaultMessage="Guardar" />
                }
                onClick={handleClose1}
                type="submit"
              />
            </DialogActions>
          </form>
        </Dialog>
      </div>
    </div>
  );
}
