import React, { useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import image from "../../../../img/Conf/bd.png"
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { BASE_API } from '../../../../utils/constans';
//import { getDataToken } from '../../../../api/dataToken';
import { getToken } from "../../../../api/token";
import { getURL } from "../../../../api/getURL";
import { TOKENLOGIN } from "../../../../utils/constans";

export async function getDataToken(url) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("authorization", "Bearer " + getToken());
  
    try {
      const response = await fetch(`${await getURL()}${url}`, {
        method: "GET",
        headers: headers,
      });
  
      return response; // Devuelve directamente el objeto de respuesta
  
    } catch (error) {
      console.log(error.message);
      return null;
    }
}
  
export default function BdConfig() {
    const [selectedDb, setSelectedDb] = useState('mysql');
    const [ip, setIp] = useState('');
    const [puerto, setPuerto] = useState('');
    const [nombreBD, setNombreBD] = useState('');
    const [usuario, setUsuario] = useState('');
    const [password, setPassword] = useState('');
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = useState('');

    const [showPassword, setShowPassword] = useState(false);

    //Define un estado dbId para almacenar el ID de la base de datos existente, si es que existe.
    const [dbId, setDbId] = useState(null);
    const authorizationHeader = `Bearer ${TOKENLOGIN}`;

    useEffect(() => {
        //console.log('Entrando en useEffect');
        const fetchData = async () => {
            try {
                const response = await getDataToken("conexionpm/database-connection-listado/");
                console.log('Respuesta completa:', response); 
                
                if (response.ok) {
                    const data = await response.json();
                    console.log('Data obtenida:', data); // Verificar la estructura de los datos
                    if (data.length > 0) {
                        const id = parseInt(data[0].id); // Convertir el ID a número
                        setDbId(id);
                        console.log('Valor de dbId:', id); // Mostrar el ID obtenido
                    }
                } else if(response.status === 403) {
                    window.location.href = "/unauthorized";    
                } else if(response.status === 401) {
                    window.location.href = "/InvalidToken";    
                }
      
            } catch (error) {
                console.error('Error fetching database connection:', error);
            }
        };
      
        fetchData();
      }, []);

    const handleClose = async () => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", authorizationHeader);

        const tipoBD = selectedDb === 'mysql' ? 1 : 2;
        const body = {
            host: ip,
            user: usuario,
            password: password,
            database: nombreBD,
            id_tipobd: tipoBD
          };

        const url = dbId ? `${BASE_API}conexionpm/get_id/${dbId}/` : `${BASE_API}conexionpm/database-connection-listado/`;
        const method = dbId ? 'PUT' : 'POST';

        try {
            const response = await fetch(url, {
                method: method,
                headers: headers,
                body: JSON.stringify(body),
              });
            //console.log('Respuesta:', response);
            

            if (response.ok) {
                const data = await response.json();
                setMessage(data.message);
            } else {
                setMessage('Error al conectar a la base de datos');
            }
        } catch (error) {
            //console.error('Error:', error);
            setMessage('Error al conectar a la base de datos');
        }
    
        setOpen(false);
    };
    
    
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleChange = (event) => {
        setSelectedDb(event.target.value);
    };

    const handleIpChange = (event) => {
        setIp(event.target.value);
    };

    const handlePuertoChange = (event) => {
        setPuerto(event.target.value);
    };

    const handleNombreBDChange = (event) => {
        setNombreBD(event.target.value);
    };

    const handleUsuarioChange = (event) => {
        setUsuario(event.target.value);
    };

    

    return (
        <Card>
            <CardContent style={{ textAlign: 'center' }}>
                <img src={image} alt="Descripción de la imagen" style={{ margin: 'auto', width: '200px' }} />
                <Box sx={{ display: 'flex', gap: '16px', margin: '30px' }}>
                    <FormControl fullWidth>
                        <InputLabel id="db-select-label">Selecciona la base de datos:</InputLabel>
                        <Select
                            labelId="db-select-label"
                            id="db-select"
                            value={selectedDb}
                            onChange={handleChange}
                            label="Selecciona la base de datos"
                        >
                        <MenuItem value="mysql">MySQL</MenuItem>
                        <MenuItem value="postgres">PostgreSQL</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Dirección IP"
                        placeholder="Dirección IP"
                        type="text"
                        value={ip}
                        onChange={handleIpChange}
                    />
                    <TextField
                        fullWidth
                        label="Puerto"
                        placeholder="Puerto"
                        type="text"
                        value={puerto}
                        onChange={handlePuertoChange}
                    />
                </Box>
                <Box sx={{ display: 'flex', gap: '16px', margin: '30px' }}>
                    <TextField
                        fullWidth
                        label="Nombre de la BD"
                        placeholder="Nombre de la base de datos"
                        type="text"
                        value={nombreBD}
                        onChange={handleNombreBDChange}
                    />
                    <TextField
                        fullWidth
                        label="Nombre de usuario"
                        placeholder="Nombre de usuario"
                        type="text"
                        value={usuario}
                        onChange={handleUsuarioChange}
                    />
                    <TextField
                        fullWidth
                        label="Contraseña"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={handlePasswordChange}
                        InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                            >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        </InputAdornment>
                    ),
                }}
                />
                </Box>
                <Stack spacing={10} direction="row" alignItems="center" justifyContent="flex-end" margin="30px">
                    <Button variant="contained" style={{ height: '55px' }} onClick={handleClickOpen}>Guardar</Button>
                    <Dialog
                        open={open}
                        onClose={() => setOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Confirmación de Conexión a la Base de Datos"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Antes de iniciar la conexión a la base de datos, por favor, asegúrate de tener
                                los permisos necesarios y de estar autorizado para acceder a esta información.
                                La conexión a la base de datos es una operación sensible y debe realizarse con responsabilidad.
                                ¿Estás seguro de continuar?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpen(false)}>Cancelar</Button>
                            <Button onClick={handleClose} autoFocus>
                                Conectarse
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Stack>
                {message && <p>{message}</p>}
            </CardContent>
        </Card>
    );
}
