import { Recepcion } from "../../pages/Client/ModRecepcion/Recepcion";
import { ClientLayaout } from "../../layouts/Client/ClientLayaout";
import { AgregarDocumento } from "../../pages/Client/ModRecepcion/AgregarDocumento";
import { EditarTramite } from "../../pages/Client/ModRecepcion/EditarTramite";
import { NuevoTramite } from "../../pages/Client/ModRecepcion/NuevoTramite";
import { FinalizarTramite } from "../../pages/Client/ModRecepcion/FinalizarTramite";
import { LoginLayaout } from "../../layouts/Login/LoginLayout";

const routesClient = [
  {
    path: "/recepcion",
    layout: ClientLayaout,
    component: Recepcion,
    exact: true,
  },
  {
    path: "/agregarDocumento",
    layout: ClientLayaout,
    component: AgregarDocumento,
    exact: true,
  },
  {
    path: "/editarTramite",
    layout: ClientLayaout,
    component: EditarTramite,
    exact: true,
  },
  {
    path: "/nuevoTramite",
    layout: ClientLayaout,
    component: NuevoTramite,
    exact: true,
  },
  {
    path: "/finalizarTramite",
    layout: ClientLayaout,
    component: FinalizarTramite,
    exact: true,
  },
];

export default routesClient;
