import React from "react";
import { Box, Grid, Container, IconButton } from "@mui/material";
import PasswordTXTField from "../Tools/TextField/TextFieldPass";
import TXTField from "../Tools/TextField/TextField";
import DialogButton from "../Tools/Dialog/DialogButton";
import familia from "../../img/familia.png";
import "./Login.css";
import { BASE_API, DOMINIOEMPRESARIAL } from "../../utils/constans";
import { getUsers, postDataLogin } from "./apiLogin/getUsers";
import { isPassword } from "../../utils/functions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
//idioma
import { FormattedMessage } from "react-intl";
import validateEmail from "./Elements/validateEmail";
import validarRFC from "./Elements/validateRFC";
import {
  LimitarTexto2,
  LimitarTexto,
} from "../Recepcion/Elements/Functions/Validaciones";

export function CompRegistro() {
  //Creación de estados para almacenar las variables

  const [values, setValues] = React.useState({
    email: "",
    password: "",
    userName: "",
    lastName: "",
    maternalSurname: "",
    rfc: "",
  });

  const [emailExits, setEmailExits] = React.useState({});
  const [errorsExits, setErrorsExits] = React.useState({});
  const [errorsTitle, setErrorsTitle] = React.useState({});
  const [Successful, setSuccessful] = React.useState();
  const [link, setLink] = React.useState({});

  // Se activa un evento onChange cuando se ingresan valores en la entrada.
  //Esto activa una función handleChange() , que se usa para establecer un nuevo estado para la entrada.

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleKeyStop = (event) => {
    const maxLength = 13;
    const value = event.target.value;
    if (value.length >= maxLength) {
      event.preventDefault();
    }
  };

  React.useEffect(() => {
    // Validamos si el servidor esta jalando.
    getUsers(`${BASE_API}login/get_listado/`)
      .then((json) => {
        setSuccessful(true);
      })
      .catch((error) => {
        console.error("There was an error:", error);
        setSuccessful(false);
      });
  }, []);
  // console.log(Successful);
  // Validamos en la api si existe un asuario con  el correo

  getUsers(`${BASE_API}login/get_correo/${values.email}/`)
    .then((json) => {
      console.log(json);
      setEmailExits(json.SNYTLOG_email);
    })
    .catch((error) => {
      console.error("There was an error:", error);
    });

  //validar rfc
  const isValidRFC = validarRFC(values.rfc);
  console.log("RFC válido:", isValidRFC);

  // Validar dominio

  var esValido = validateEmail(values.email, DOMINIOEMPRESARIAL);
  // console.log(esValido);

  // POST Para agregar un nuevo usuario

  async function postUser(aux) {
    try {
      var result = await postDataLogin(`login/register`, aux);
      console.log("Registro exitoso", result);
    } catch (error) {
      console.log(error);
    }
  }

  //validación para que acepte solo letras.
  function handleKeyPress(event) {
    const regex = /^[a-zA-Z]*$/;
    const isValidInput = regex.test(event.key);
    if (!isValidInput) {
      event.preventDefault();
    }
  }

  //Validaciones de acuerdo al TCH100

  const validateAndSubmitForm = (e) => {
    e.preventDefault();

    const errorsExits = {};
    const errorsTitle = {};
    const link = {};

    errorsTitle.email = (
      <FormattedMessage id="fueServ" defaultMessage="Fuera de servicio" />
    );
    errorsExits.email = (
      <FormattedMessage
        id="peIntTar"
        defaultMessage="No es posible realizar su petición en este momento, intente más tarde."
      />
    );

    if (esValido === false) {
      errorsTitle.email = (
        <FormattedMessage id="globCorreo" defaultMessage="Correo inválido" />
      );
      errorsExits.email = (
        <FormattedMessage
          id="globElCorr"
          defaultMessage="El correo no cumple con el formato empresarial."
        />
      );
    } else if (!isPassword(values.password)) {
      errorsTitle.email = (
        <FormattedMessage
          id="globContrasenaInorre"
          defaultMessage="Contraseña incorrecta"
        />
      );
      errorsExits.email = (
        <FormattedMessage
          id="globLaContrasenaNoCu"
          defaultMessage="La contraseña no cumple con el formato."
        />
      );
    } else if (emailExits === values.email) {
      errorsTitle.email = (
        <FormattedMessage
          id="globUsuarioexistente"
          defaultMessage="Usuario existente"
        />
      );
      errorsExits.email = (
        <FormattedMessage
          id="globYaExisteunaCuent"
          defaultMessage="Ya existe una cuenta con los datos ingresados."
        />
      );
    } else if (isValidRFC === false) {
      errorsTitle.email = (
        <FormattedMessage id="loginRFC" defaultMessage="RFC inválido" />
      );
      errorsExits.email = (
        <FormattedMessage
          id="loginRFCF"
          defaultMessage="El RFC no cumple con el formato correcto."
        />
      );
    } else if (emailExits !== values.email && Successful === true) {
      // POST request using fetch with error handling
      let aux = {
        SNYTLOG_username: values.userName,
        SNYTLOG_email: values.email,
        password: values.password,
        SNYTLOG_surnamedad: values.lastName,
        SNYTLOG_surnamemom: values.maternalSurname,
        SNYTLOG_RFC: values.rfc,
        SNYTLOG_intentos: 1,
      };
      postUser(aux);
      errorsTitle.email = (
        <FormattedMessage
          id="globUsuarioCreadoExi"
          defaultMessage="Usuario creado exitosamente"
        />
      );
      errorsExits.email = (
        <>
          <FormattedMessage
            id="globSeRegi"
            defaultMessage="Se registraron los datos correctamente."
          />
          <br />
          <FormattedMessage
            id="globIniciaSesionPara"
            defaultMessage="Inicia sesión para continuar."
          />
        </>
      );
      values.email = "";
      values.password = "";
      values.userName = "";
      values.lastName = "";
      values.maternalSurname = "";
      values.rfc = "";

      link.email = "/";
    }
    setErrorsExits(errorsExits);
    setErrorsTitle(errorsTitle);
    setLink(link);
  };
  return (
    <form onSubmit={validateAndSubmitForm}>
      <div className="div-parent">
        <Box>
          <Container maxWidth="lg">
            <Grid container spacing={5}>
              <Grid item xs={12} sm={5}>
                <Box>
                  <IconButton
                    id="iconRegresar"
                    size="large"
                    color="primary"
                    href="/"
                  >
                    <ArrowBackIcon fontSize="inherit" />
                  </IconButton>
                  <br />
                  <br />
                  <h2-login-title>
                    <FormattedMessage
                      id="loginRegte"
                      defaultMessage="Registrarte"
                    />
                  </h2-login-title>
                  <hr class="hr-line" align="left" />
                  <br />
                  <TXTField
                    label={
                      <FormattedMessage
                        id="loginCorEm"
                        defaultMessage="Correo electrónico empresarial"
                      />
                    }
                    size="small"
                    id="lblCorreo"
                    value={values.email}
                    required={true}
                    onInput={(e) => LimitarTexto2(e.target, 50)}
                    onChange={handleChange("email")}
                  />
                  <PasswordTXTField
                    id="lblContrasena"
                    label={
                      <FormattedMessage
                        id="globContra"
                        defaultMessage="Contraseña"
                      />
                    }
                    type="password"
                    size="small"
                    value={values.password}
                    helperText={
                      <FormattedMessage
                        id="loginMiCar"
                        defaultMessage="Mínimo 8 caracteres con una letra mayúscula, minúscula, un número y un carácter."
                      />
                    }
                    required={true}
                    onChange={handleChange("password")}
                  />
                  <TXTField
                    id="lblNombre"
                    label={
                      <FormattedMessage
                        id="datosNombs"
                        defaultMessage="Nombre(s)"
                      />
                    }
                    type="text"
                    size="small"
                    value={values.userName}
                    required={true}
                    onChange={handleChange("userName")}
                    onKeyPress={handleKeyPress}
                    onPaste={(e) => e.preventDefault()}
                    onInput={(e) => LimitarTexto(e.target, 15)}
                  />
                  <TXTField
                    id="lblApellidoPa"
                    label={
                      <FormattedMessage
                        id="datosApPat"
                        defaultMessage="Apellido Paterno"
                      />
                    }
                    type="text"
                    size="small"
                    value={values.lastName}
                    required={true}
                    onChange={handleChange("lastName")}
                    onKeyPress={handleKeyPress}
                    onPaste={(e) => e.preventDefault()}
                    onInput={(e) => LimitarTexto(e.target, 20)}
                  />
                  <TXTField
                    id="lblApellidoMa"
                    label={
                      <FormattedMessage
                        id="datosApMat"
                        defaultMessage="Apellido Materno"
                      />
                    }
                    type="text"
                    value={values.maternalSurname}
                    size="small"
                    onChange={handleChange("maternalSurname")}
                    onKeyPress={handleKeyPress}
                    onPaste={(e) => e.preventDefault()}
                    onInput={(e) => LimitarTexto(e.target, 20)}
                  />
                  <TXTField
                    id="lblRFC"
                    label={
                      <FormattedMessage id="loginRFCE" defaultMessage="RFC" />
                    }
                    type="text"
                    size="small"
                    value={values.rfc}
                    required={true}
                    onKeyPress={handleKeyStop}
                    onChange={handleChange("rfc")}
                    onPaste={(e) => e.preventDefault()}
                  />
                  <br />
                  <br />
                  <Grid container spacing={12}>
                    <Grid item xs={1} sm={5}>
                      <Box>
                        <br />
                        <h4-label>
                          <FormattedMessage
                            id="loginOblig"
                            defaultMessage="Campos obligatorios*"
                          />
                        </h4-label>
                      </Box>
                    </Grid>
                    <Grid item xs={1} sm={2}>
                      <Box></Box>
                    </Grid>
                    <Grid item xs={1} sm={4}>
                      <Box>
                        <DialogButton
                          id1="btnAceptar"
                          id2="btnActAcep"
                          mainButton={
                            <FormattedMessage
                              id="loginRegt"
                              defaultMessage="Registrate"
                            />
                          }
                          title={errorsTitle.email}
                          description={errorsExits.email}
                          button={
                            <FormattedMessage
                              id="globAceptr"
                              defaultMessage="Aceptar"
                            />
                          }
                          type="submit"
                          to={link.email}
                          estatus={
                            values.email.length > 0 &&
                            values.password.length > 0 &&
                            values.userName.length > 0 &&
                            values.lastName.length > 0 &&
                            values.rfc.length > 0
                              ? false
                              : true
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Box>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <img
                    src={familia}
                    alt="Generando confianza en tus aplicaciones"
                    width="150%"
                    height="150%"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box></Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
    </form>
  );
}
