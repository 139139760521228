import * as React from "react";
import PropTypes from "prop-types";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from '@material-ui/core/Grid'
import Btverreport from "../../../Tools/Bottones/BTAgregarDocumento";
import TXTField from "../../../Tools/TextField/TextField";
//idioma
import { FormattedMessage } from "react-intl";


//Solo aparesera un alerta con un solo boton
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function DialogCartaAgregarBene(props) {
    const rows = props.rows
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState("")


    //FUNCION PARA RESETEAR EL FORMULARIO AL CANCELAR O SALIR DEL DIÁLOGO
    // Obtenemos una referencia al formulario
    const formRef = React.useRef(null);

    // Creamos una función para resetear el formulario
    const resetForm = () => {
        formRef.current.reset();
    }



    const handleClickOpen = () => {
        setOpen(true);
        agregarBene()
    };
    const handleClose = () => {
        setOpen(false);
        resetForm()
    };

    const agregarBene = () => {
        setTitle("Agregar beneficiario")
    }



    return (
        <div>
            {/*BOTON QUE HACE EL LLAMADO AL DIALOGO*/}
            {/*Este boton aparecera el js en donde se necesite de la alerta*/}
             <Btverreport onClick={handleClickOpen} label={<FormattedMessage id="glob.agregarBeneficiarios" defaultMessage="Agregar beneficiarios" />}/>

            {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
            <BootstrapDialog

                onClose={handleClose}
                aria-labelledby="dialog"
                open={open}
            >
                {/*CUERPO DEL DIALOGO*/}
                {/*TITILO*/}
                <BootstrapDialogTitle
                    id="dialog"
                    onClose={handleClose}
                    style={{ color: "primary" }}
                >
                    <h1-dialog-title>{title}</h1-dialog-title>
                </BootstrapDialogTitle>
                {/*CUERPO DEL DIALOGO*/}
                {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
                <form ref={formRef} onSubmit={
                    (e) => {
                        const {
                            txtNombre,
                            txtApePat,
                            txtApeMat,
                            txtCorreoElec,
                            txtDireccion

                        } = e.target.elements
                        console.log(e.target.elements)
                        e.preventDefault();
                        alert('Ha guardado los siguientes datos: \n' +
                            'Nombre: ' + txtNombre.value + '\n' +
                            'Apellido parterno: ' + txtApePat.value + '\n' +
                            'Apellido materno: ' + txtApeMat.value + '\n' +
                            'Correo electrónico: ' + txtCorreoElec.value + '\n' +
                            'Dirección: ' + txtDireccion.value 
                            )
                    }
                }>
                    <DialogContent dividers style={{ minWidth: 500 }}>
                            <Grid container direction="row" justifyContent="flex-end" alignItems="center" >
                                <Grid item xs={12}><TXTField id="txtNombre" name="txtNombre" label={'Nombre'} /></Grid>
                                <Grid item xs={12}><TXTField id="txtApePat" name="txtApePat" label={'Apellido paterno'} /></Grid>
                                <Grid item xs={12}><TXTField id="txtApeMat" name="txtApeMat" label={'Apellido materno'} /></Grid>
                                <Grid item xs={12}><TXTField id="txtCorreoElec" name="txtCorreoElec" label={'Correo electrónico'} /></Grid>
                                <Grid item xs={12}><TXTField id="txtDireccion" name="txtDireccion" label={'Dirección'} /></Grid>
                            </Grid>
                 
                    </DialogContent>
                    {/*es la tercer sección que engloba los botones */}
                    <DialogActions>
                        <Grid container justifyContent="center" alignItems="center" spacing={2} marginTop={1}>
                            <Grid item >
                                <ButtonVino
                                    variant="contained"
                                    label='Guardar'
                                    type="submit"
                                />
                            </Grid>
                            <Grid item >
                                <ButtonVino
                                    variant="contained"
                                    label='Cancelar'
                                    onClick={handleClose}
                                />
                            </Grid>
                        </Grid>

                    </DialogActions>
                </form>
            </BootstrapDialog>
        </div>
    );
}
