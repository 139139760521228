import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import { Select1 } from "../../Tools/Selectss/Select1";
//import de tabla
import TXTField from "../../Tools/TextField/TextField";
//useLocation
import { useLocation } from "react-router-dom";
//apis
import { getDataToken, postDataToken } from "../../../api/dataToken";
import { MOV_RESERVA_OPC } from "../../../utils/constanstoken";

export default function GenerarMov() {
  //El arreglo de moneda es el tipo de moneda que se va a realizar el pago puede cambiar a api
  const moneda = [
    { id: 1, identificador: "nacional", texto: "MXN" },
    { id: 2, identificador: "dolar", texto: "USD" },
    { id: 3, identificador: "canada", texto: "CAD" },
  ];
  const [concepto1, setConcepto1] = useState("");
  //alert(MOV_RESERVA_OPC.descripcion)
  //Creamos una varibale para almacenar el folio que se ingresara

  const handleChangeBuscarFolio = (event) => {
    console.log("Entre al metodo buscar" + searchTerm);

    // Lamado del get de polizafolio
    getPolizaFolio(searchTerm);
  };

  //Get de polizafolio y siniestro
  const [datos, setDatos] = useState([]);

  //Obtener datos del asegurado y su id
  const getPolizaFolio = async () => {
    try {
      var result = await getDataToken(`polizasfolioxsiniestro/get_listado/`);
      for (let i = 0; i < result.length; i++) {
        if (result[i].RECT_idfolio?.RECT_numerofolio === searchTerm) {
          console.log("entre a get folio");
          setDatos(result[i]);
          setConcepto1("Honorarios por investigation");
          console.log(datos);
          break; // si encuentras el registro, det ienes el bucle
        }
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getPolizaFolio();
  }, []);

  /* CONSTANTES DE ESTADOS*/
  const [searchTerm, setSearchTerm] = useState("");
  const [noFolio, setNoFolio] = useState("");
  const [noPoliza, setNoPoliza] = useState("");
  const [noSiniestro, setNoSiniestro] = useState("");
  const [concepto, setConcepto] = useState("");
  const [tipoSiniestro, setTipoSiniestro] = useState("");
  const [nombreAsegurado, setNombreAsegurado] = useState("");
  const [ importe, setImporte] = useState("");
  const [ selectedMonedaId, setSelectedMonedaId] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setNoFolio(e.target.noFolio.value);
    setNoPoliza(e.target.noPoliza.value);
    setNoSiniestro(e.target.noSiniestro.value);
    setConcepto(e.target.concepto.value);
    setTipoSiniestro(e.target.tipoSiniestro.value);
    setNombreAsegurado(e.target.nombreAsegurado.value);
    setImporte(e.target.importe.value);

    // Obtenemos el ID de la moneda
    const selectedIdentificador = e.target.moneda.value;

    // Encuentra el objeto de moneda correspondiente al identificador seleccionado
    const selectedMoneda = moneda.find(option => option.identificador === selectedIdentificador);
    setSelectedMonedaId(selectedMoneda.id)

    console.log(
      noFolio,
      noPoliza,
      noSiniestro,
      concepto,
      tipoSiniestro,
      nombreAsegurado,
      importe,
      selectedMonedaId
    );

    // Consultamos ID
    let rectIdfolio = '';
    var selectedIdNo = await getDataToken('polizasfolioxsiniestro/get_listado/')
    for (let i = 0; i < selectedIdNo.length; i++) {
       rectIdfolio = selectedIdNo[i]?.RECT_idfolio;
      if (rectIdfolio && rectIdfolio.RECT_idusuario === noFolio) {
        console.log("Se encontró el REACT_idfolio:", rectIdfolio.RECT_idfolio);
        // Aquí puedes hacer lo que necesites con el REACT_idfolio encontrado
        break; // si encuentras el registro, detienes el bucle
      }
    }

    let poliza = '';
     let polizaNumero = '';
     for (let i = 0; i < selectedIdNo.length; i++) {
        poliza = selectedIdNo[i]?.POL_idpoliza;
        polizaNumero = selectedIdNo[i]?.POL_idpoliza?.POL_numpoliza;
       if (poliza && polizaNumero === noPoliza) {
         console.log("Se encontró la POL_idpoliza:", poliza);
         // Aquí puedes hacer lo que necesites con la POL_idpoliza encontrada
         break; // si encuentras el registro, detienes el bucle
       }
    }

    let siniestro = '';
    let numeroSiniestro = ''
    for (let i = 0; i < selectedIdNo.length; i++) {
       siniestro = selectedIdNo[i]?.SIN_idsiniestro;
       numeroSiniestro = selectedIdNo[i]?.SIN_idsiniestro?.SIN_numerosiniestro;
      if (siniestro && numeroSiniestro === noSiniestro) {
        console.log("Se encontró el SIN_idsiniestro:", siniestro);
        // Aquí puedes hacer lo que necesites con el SIN_idsiniestro encontrado
        break; // si encuentras el registro, detienes el bucle
      }
    }

    let tipoTramite = '';
    let destipoTramite = ''
    for (let i = 0; i < selectedIdNo.length; i++) {     
       tipoTramite = selectedIdNo[i]?.RECT_idfolio?.SINC_idcomplejidadsiniestro;
       destipoTramite = tipoTramite?.TRA_destipotramitesiniestro;
      if (tipoTramite && destipoTramite === tipoSiniestro) {

        console.log("Se encontró el TRA_idtipotramitesiniestro:", tipoTramite.TRA_idtipotramitesiniestro);
        // Aquí puedes hacer lo que necesites con el TRA_idtipotramitesiniestro encontrado
        break; // si encuentras el registro, detienes el bucle
      }
    }

    let personaIdNo = '';
    for (let i = 0; i < selectedIdNo.length; i++) {
        personaIdNo = selectedIdNo[i]?.PER_idpersona?.PER_idpersona;
        const [nombrePersona, apellidoPaterno, apellidoMaterno] = nombreAsegurado.split(' ');

        const nombrePersonaValidar = selectedIdNo[i]?.PER_idpersona?.PER_nombre;
        const apellidoPaternoPersona = selectedIdNo[i]?.PER_idpersona?.PER_apellidopaterno;
        const apellidoMaternoPersona = selectedIdNo[i]?.PER_idpersona?.PER_apellidomaterno;
       if (personaIdNo && nombrePersona === nombrePersonaValidar && apellidoPaternoPersona === apellidoPaterno && apellidoMaternoPersona === apellidoMaterno) {
        console.log("Se encontró el PER_idpersona:", personaIdNo);
        // Hacer lo que necesites con el PER_idpersona encontrado
        break; // Detener el bucle si se encuentra el registro
      }
    }

    
    // ************************************************************************************************
    let siniestroIdNo ='';
    for (let i = 0; i < selectedIdNo.length; i++) {
      siniestroIdNo = selectedIdNo[i]?.SIN_idsiniestro?.SIN_idsiniestro;
      const numeroSiniestroActual = selectedIdNo[i]?.SIN_idsiniestro?.SIN_numerosiniestro;
     if (siniestroIdNo && numeroSiniestroActual === noSiniestro) {
      console.log("Se encontró el PER_idpersona:", siniestroIdNo);
      // Hacer lo que necesites con el PER_idpersona encontrado
      break; // Detener el bucle si se encuentra el registro
    }
  }


    console.log(rectIdfolio.RECT_idfolio);
    console.log(poliza.POL_idpoliza);
    console.log(siniestro.SIN_idsiniestro)
    console.log(tipoTramite.TRA_idtipotramitesiniestro)
    console.log(personaIdNo)
    console.log(siniestroIdNo)
  

    const JsonBody = {
        RECT_idfolio: rectIdfolio.RECT_idfolio,
        POL_idpoliza: poliza.POL_idpoliza,
        SIN_idsiniestro: siniestroIdNo,
        CON4_idconceptonivelcuatro: 1,
        setTipoSiniestro : tipoTramite.TRA_idtipotramitesiniestro,
        PER_idpersona: personaIdNo,
        GMVC_importe : importe, 
        MON_idmoneda : selectedMonedaId
      };

      console.log(JsonBody)
      console.log('Enviando datos....')
      var resultData = await postDataToken("generarmovcontable/get_listado/", JsonBody);
      if(resultData) return console.log(resultData, 'se envio el post') ;

        console.log('No se envio el POST')
  }

  // Constante de boton para metodo put
  const [value, setValue] = useState ({});
  const anadirClickHandler = (prop) => (event) => {
    setValue({ ...value, [prop]: event.target.value});
    console.log("PUT ECHO", value);
  };
  
    return(
        <>
        <form onSubmit={handleSubmit} >
            <Grid container>
                <Grid item xs={12}>
                    <h3>Generar Movimiento Contable </h3>
                </Grid>
                <Grid item xs={4}>
                    <div class="espacio">
                        <TXTField label="No. Folio" 
                        id="noFolio"
                        name="noFolio"
                        showSearchIcon={true}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                          }}
                        iconOnClick={() => handleChangeBuscarFolio()}/>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div class="espacio">
                        <TXTField label="No. Póliza" 
                        id="noPoliza"
                        name="noPoliza"
                         value={datos.POL_idpoliza?.POL_numpoliza}
                         InputLabelProps={{ shrink: true }}
                        />  
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div class="espacio">
                        <TXTField label="No. Siniestro" 
                        id="noSiniestro"
                        name="noSiniestro"
                         value={datos.SIN_idsiniestro?.SIN_numerosiniestro}
                         InputLabelProps={{ shrink: true }}
                        />
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div class="espacio">
                        <TXTField label="Concepto"
                        id="concepto"
                        name="concepto"
                        value={concepto1} />
                    </div>
                </Grid>  
                <Grid item xs={4}>
                    <div class="espacio">
                        <TXTField label="Tipo de siniestro"
                        id="tipoSiniestro"
                        name="tipoSiniestro"
                         value={datos.RECT_idfolio?.SINC_idcomplejidadsiniestro?.TRA_destipotramitesiniestro}
                         InputLabelProps={{ shrink: true }}
                        />
                    </div>
                </Grid> 
                <Grid item xs={4}>
                    <div class="espacio">
                        <TXTField label="Nombre del asegurado"
                        id="nombreAsegurado"
                        name="nombreAsegurado"
                         value={!datos.PER_idpersona?'': datos.PER_idpersona?.PER_nombre + ' ' + datos.PER_idpersona?.PER_apellidopaterno + ' ' + datos.PER_idpersona?.PER_apellidomaterno}
                         InputLabelProps={{ shrink: true }}
                        />
                    </div>
                </Grid>    
                <Grid item xs={4}>
                    <div class="espacio">
                      <TXTField label="Importe"
                        id="importe"
                        name="importe"
                        //value={importe}
                      />
                    </div>
                </Grid>  
                <Grid item xs={4}>
                    <div class="espacio">
                        <Select1 name="moneda" array={moneda} key={moneda.id}/>
                        {/* <TXTField label="Moneda"
                         value={datos.PRO_idproductos?.MON_idmonedas?.MON_abreviatura}
                         InputLabelProps={{ shrink: true }}
                        /> */}
            </div>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={2}>
            <ButtonVino label="Guardar" type="submit" />
          </Grid>
          <Grid item xs={5}></Grid>
        </Grid>
      </form>
    </>
  );
}
