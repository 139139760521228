import React from "react";
import { CompActualizarContra } from "../../../components/Login/CompActualizarContra";


export function ActualizarContra() {
  return (
    <div>

      <CompActualizarContra />
 
    </div>
  );
}
