/* Brandon Hernandez Rocha 11/2022 */
import React from "react";
import CustomSeparatorDinamic from "../Tools/BreadCrumbs/RecorridoDinamico";
import "../../css/estilo.css";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CardDetalleFolio } from "./Elements/Cards/CardDetalleFolio";
import DatePicker2 from "../Tools/DatePicker/DatePicker2";
import TXTField from "../Tools/TextField/TextField";
import ButtonVino from "../Tools/Bottones/ButtonVino";

//idioma
import { FormattedMessage } from "react-intl";
import { CardDetallePago } from "./Elements/Cards/CardDetallePago";
import RadioDinamico from "../Tools/RadioButton/RadioDinamico";
import { COBERTURA_FALLE_DEFAULT, PERR_ASEGURADO, PERR_BENEFICIARIO } from "../../utils/constanstoken";
import { transcode } from "buffer";
import TablaRealizarPagos from "./Elements/Tables/TablaRealizarPagos";
import { getDataToken } from "../../api/dataToken";
const CompRealizarPagos = () => {
    const location = useLocation();
    const infoFolio = location.state.infoFolio;
    const idPoliza = location.state.idPoliza;
    console.log("idPoliza: " , idPoliza)
    const row = location.state.row;
    const arrayBreadcrumbs = [
        {
            key: 1,
            name: <FormattedMessage id="globInicio" defaultMessage="Inicio" />,
            to: "/menu",
        },
        {
            key: 2,
            name: <FormattedMessage id="menuDictam" defaultMessage="Dictamen" />,
            to: "/dictamen",
        },
        {
            key: 3,
            name: (
                <FormattedMessage id="dictaDetalleF" defaultMessage="Detalle folio" />
            ),
            to: "/dictamen/detalle_folio",
            state: { row: infoFolio },
        },
    ];
    /* CONSTANTES PARA CAMBIAR TABLA ASEGURADO / BENEFICIARIOS */
    const [showTabla, setShowTabla] = React.useState(0)
    const handleRb = (value) => {
        setShowTabla(parseInt(value.target.value));
    }
    //
    const [dataApiPolxFolioxSin, setDataApiPolxFolioxSin] = React.useState("")
    const [dataCob, setDataCob] = React.useState("")
    const [listRB, setListRB] = React.useState([
        { id: PERR_ASEGURADO.id, value: PERR_ASEGURADO.id, label: PERR_ASEGURADO.descripcion },
        { id: PERR_BENEFICIARIO.id, value: PERR_BENEFICIARIO.id, label: PERR_BENEFICIARIO.descripcion }
    ])

    const getDataApiPolizaxFolioxSin = async () => {
        try {
            let result = await getDataToken("polizasfolioxsiniestro/get_idpoliza/" + idPoliza + "/");
            if (result) {
                console.log("result", result);
                setDataApiPolxFolioxSin(result[0]);
                console.log(result);
                let response2 = await getDataToken("coberturaspolizasportramite/get_pol/" + result[0]?.POL_idpoliza?.POL_idpoliza + "/")
                if (response2) {
                    console.log("response 2", response2)
                    let findCoberBasica = response2.find(obj => obj.COB_idcobertura.COB_idcobertura == COBERTURA_FALLE_DEFAULT.id);
                    if (findCoberBasica) {
                        setListRB([
                            { id: PERR_BENEFICIARIO.id, value: PERR_BENEFICIARIO.id, label: PERR_BENEFICIARIO.descripcion },
                        ])
                    }
                }
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    React.useEffect(() => {
        getDataApiPolizaxFolioxSin();
    }, [])

    return (
        <div className="div-parent">
            <div className="div-breadcrumbs">
                <CustomSeparatorDinamic
                    breadcrumbs={arrayBreadcrumbs}
                    name={`Realizar pagos`}
                />
            </div>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
            >
                <Link
                    style={{ textDecoration: "none" }}
                    to="/dictamen/detalle_folio"
                    state={{ row: infoFolio }}
                >
                    <ReturnPage />
                </Link>
            </Grid>
            <div className="div-container">
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        {dataApiPolxFolioxSin === "" ? null : <CardDetallePago infoRow={infoFolio} polFolSin={dataApiPolxFolioxSin} row={row} dataCob={dataCob} />}
                    </Grid>
                    {dataApiPolxFolioxSin === "" ? null :
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item >
                                <h3-subtitle-card>Pago a </h3-subtitle-card>
                            </Grid>
                            <Grid item >
                                <RadioDinamico array={listRB}
                                    row
                                    onChange={handleRb}
                                />
                            </Grid>
                            {dataApiPolxFolioxSin === "" ? null : <TablaRealizarPagos infoFolio={infoFolio} tipoTabla={showTabla} polFolSin={dataApiPolxFolioxSin} dataCob={(e) => setDataCob(e)} />}
                        </Grid>}

                </Grid>
            </div>
        </div>
    );
};
export default CompRealizarPagos;
