import React from "react";
//Betdrooms
import Recorrido from "../Tools/BreadCrumbs/Recorrido";
//Estilos
import "./Docu.css";
import "../../css/estilo.css";
import { Grid } from "@mui/material";
import { Info, WhatsApp } from "@mui/icons-material";
import { Email } from "@mui/icons-material";
//Son tablas
import TablaDocu from "./Elements/TablaDocu";
import TablaDocuFalle from "./Elements/TablaDocuFalle";
import TablaDocuVenci from "./Elements/TablaDocuVenci";
import TablaDocuBenefi from "./Elements/TablaDocuBenefi";



//Funciones
import { SelectsDoc } from "./Elements/Select";
import Check from "../Tools/CheckBox/Check";
import DialogButton from '../Tools/Dialog/DialogButton';
import { useState } from "react";
import Check2 from "../Tools/CheckBox/Check2";
import TextField from '@mui/material/TextField';
import Mydocument from "../Tools/Pdfdocu/BtPDF";
import { VenciPDF } from "./Elements/Vencimiento/Vencipdf";
import { FallePDF } from "./Elements/Fallecimiento/Fallepdf";
import { BenePDF } from "./Elements/Beneficio de vida/Benepdf";

import { getToken } from "../../api/token";
import { BASE_API } from "../../utils/constans";




export function CompDocumentation() {
  const [correoElectronico, setCorreoElectronico] = useState('');

  const handleCorreoElectronicoChange = (event) => {
    setCorreoElectronico(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Llamar a la función postEmail con el valor de correoElectronico
    await postEmail(correoElectronico);
  }; 


  const postEmail = async () => {

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("authorization", "Bearer " + getToken());
    try {
      await fetch(`${BASE_API}notificador/mail/`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          EMLNT_email: correoElectronico,
          EMLNT_saludo:"Socio asegurado",
          EMLNT_presentacion:"Estimado asegurad@",
          EMLNT_asunto: "",
          EMLNT_texto: "El siguinte documento contiene nuestras documentacion en cuestion de nuestra area de seguros",
          EMLNT_texto2: "",
          EMLNT_texto3: "",
          EMLNT_texto4: "",
          EMLNT_despedida:"Sin mas por el momento me despido",
          EMLNT_firma:"Qait",
          EMLNT_PORT: 587,
          EMLNT_TLS: true,
          EMLNT_HOST: "mail.qait.com.mx",
          EMLNT_HOST_USER: "sinay.admin@qait.com.mx",
          EMLNT_HOST_PASSWORD: "6xxQKSn{e~gl",
        }),
      });
    console.log("Envio de correo exitoso");
  } catch (error) {
    // handleOpen(true);
    console.log(error.message);
    throw error;
  }
};

// Inicio de Notificador de Whats
const [values, setValues] = React.useState({
  EMLNT_numerocli:"", 
  EMLNT_texto2:"",
  EMLNT_texto:"", 
  EMLNT_titulo:"",
  EMLNT_ladaineternacional:""
});

const handleChange = (prop) => (event) => {
setValues({ ...values, [prop]: event.target.value })

}
const token = getToken();

const  validateAndSubmitForm = (e) => {
  // e.preventDefault();

  fetch(`${BASE_API}notificador/sms/`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
          EMLNT_titulo:"EAALKJcl6jkkBAMT58IrOhFmhAcsDKGYa4Ruv3qeLRlwkxyWXP7DiLdO8qV3V3TZCesXJBUCnPy4caVFpBDtfqyN1y9IJYmlcYzHOe6LZBwb1U2vYKaWkAq9xQ7l6RohyLkwcZAWhJYla8cA9c2woZCi8Ix5sHoxWT68fSsvUXy9LMwugFZBDwVAfiSf9wVl5SkeXGocXsjmx1tXNo19j0",
          EMLNT_numerocli:"+52"+ values.EMLNT_numerocli, 
          EMLNT_texto2: correoElectronico,
          EMLNT_texto:"QAIT", 
        })
        
    })

}



// Inicio de switch
const toogleSwitch2 = (e) => {
  if (e.target.checked) {
    setCorreoSi(true);
    setbtnDisbale(false);
  } else {
    setCorreoSi(false);
    setbtnDisbale(true);
  }
};
const [correoSi, setCorreoSi] = useState(false);
const handleClickCorreoSi = () => {
  setCorreoSi(true);
  setbtnDisbale(false);
};

//PDF
const toogleSwitc = (e) => {
  if (e.target.checked) {
    setpdfSi(true);
    setbtnDisbale(false);
  } else {
    setpdfSi(false);
    setbtnDisbale(true);
  }
};
const [pdfSi, setpdfSi] = useState(false);
const handleClickpdfSi = () => {
  setpdfSi(true);
  setbtnDisbale(false);
};

//Funcion de poner numero para whatsApp
const toogleSwitch = (e) => {
  if (e.target.checked) {
    setWhatSi(true);
    setbtnDisbale(false);
  } else {
    setWhatSi(false);
    setbtnDisbale(true);
  }
};
const [whatSi, setWhatSi] = useState(false);
const handleClickWahtSi = () => {
  setWhatSi(true);
  setbtnDisbale(false);
};


//Esta es para TableFallecimiento
const [Falle, setFalle] = useState(false);
const handleClickFalle = () => {
  setFalle(true);
  setBene(false);
  setVen(false);
  setVa(false);
  setRyR(false)
};
//Esta es para TableVencimiento
const [Ven, setVen] = useState(false);
const handleClickVen = () => {
  setVen(true);
  setFalle(false);
  setBene(false);
  setVa(false);
  setRyR(false)
};
//Esta es para TableBeneficio
const [Bene, setBene] = useState(false);
const handleClickBene = () => {
  setBene(true);
  setVen(false);
  setFalle(false);
  setVa(false);
  setRyR(false)
};
//Esta es para Retiros y Rescates
const [RyR, setRyR] = useState(false);
const handleClickRyR = () => {
  setRyR(true)
  setBene(false);
  setVen(false);
  setFalle(false);
  setVa(false);
};
//Tabla vacia
const [Va, setVa] = useState(true);

//Estado del boton
const [btnDisbale, setbtnDisbale] = useState(true)

return (
  <div className="div-parent">
    <div className='div-breadcrumbs'>
      <Recorrido name1="Inicio" enlace="/menu" name2="Documentacion" />
    </div>
    <div className='div-container'>
      <Grid container>
        {/*Informacion*/}
        <Grid xs={12}>
          <h3-subtitle-card>
            Tipo de Siniestro
          </h3-subtitle-card>
          <br />
        </Grid>
        {/*Select*/}
        <Grid xs={3}>
          <SelectsDoc onClick1={handleClickFalle} onClick2={handleClickVen} onClick3={handleClickBene} />
          <br />
        </Grid>
        <Grid container>
          <br />
          {/*Tabla*/}
          <Grid xs={12}>
            {
              Va ? (<TablaDocu />) : null
            }
            {
              Falle ? (<TablaDocuFalle />) : null //Tabla fallecimiento
            }
            {
              Bene ? (<TablaDocuVenci />) : null // Tabla vencimiento
            }
            {
              Ven ? (<TablaDocuBenefi />) : null //Tabla beneficios
            }
            {
              RyR ? (<TablaDocuVenci />) : null //Tabla retiros y rescates
            }
            <br />
            <br />
          </Grid>
          {/*Check correo*/}
          <Grid xs={3}>
            {
              Va ? ("") : null
            }

            {
              Falle ? (<Check2
                option1={<h3-subtitle-card>Enviar por correo</h3-subtitle-card>}
                option2=""
                onChange={toogleSwitch2}
              />) : null
            }

            {
              Bene ? (<Check2
                option1={<h3-subtitle-card>Enviar por correo</h3-subtitle-card>}
                option2=""
                onChange={toogleSwitch2}
              />) : null
            }

            {
              Ven ? (<Check2
                option1={<h3-subtitle-card>Enviar por correo</h3-subtitle-card>}
                option2=""
                onChange={toogleSwitch2}
              />) : null
            }
            {
              RyR ? (<Check2
                option1={<h3-subtitle-card>Enviar por correo</h3-subtitle-card>}
                option2=""
                onChange={toogleSwitch2}
              />) : null
            }

          </Grid>
          {/*Check imprimir*/}
          <Grid xs={3}>
            {
              Va ? ("") : null
            }

            {
              Falle ? (<FallePDF
              />) : null
            }

            {
              Bene ? (<VenciPDF
              />) : null
            }

            {
              Ven ? (<BenePDF
              />) : null
            }
            {
              RyR ? (<BenePDF
              />) : null
            }

          </Grid>

          {/*Check WhatsApp*/}
          <Grid xs={3}>
            {
              Va ? ("") : null
            }

            {
              Falle ? (<Check2
                option1={<h3-subtitle-card>Enviar por WhatsApp</h3-subtitle-card>}
                option2=""
                onChange={toogleSwitch}
              />) : null
            }

            {
              Bene ? (<Check2
                option1={<h3-subtitle-card>Enviar por WhatsApp</h3-subtitle-card>}
                option2=""
                onChange={toogleSwitch}
              />) : null
            }

            {
              Ven ? (<Check2
                option1={<h3-subtitle-card>Enviar por WhatsApp</h3-subtitle-card>}
                option2=""
                onChange={toogleSwitch}
              />) : null
            }
            {
              RyR ? (<Check2
                option1={<h3-subtitle-card>Enviar por WhatsApp</h3-subtitle-card>}
                option2=""
                onChange={toogleSwitch2}
              />) : null
            }

          </Grid>
          {/*Funcion de enviar por correos*/}

          <Grid xs={6}>
            
            {correoSi ?
              <TextField
              id="txtCorreo"
              name="txtCorreo"
              variant="outlined"
              label={<Email />}
              style={{ width: 350 }}
              minRows={1}
              value={correoElectronico}
              onChange={handleCorreoElectronicoChange}
            />  : null}
              
          </Grid>

          {/*Funcion de enviar por Whats*/}
          <Grid xs={5}>
            
            {whatSi ?
              <TextField 
              id="txtwhats" 
              name="txtwhats" 
              variant="outlined" 
              label={<WhatsApp />} 
              style={{ width: 180 }} 
              minRows={1} 
              value={values.EMLNT_numerocli}
              onChange={
                handleChange("EMLNT_numerocli")
                // setValues(event.target.value)
              }
              InputLabelProps={{ shrink: true }} 
              /> : null}  
          </Grid>
          {/*Boton Continuar*/}
          <Grid xs={1}>

            <br />
            <br />
            <br />
            {
              Va ? ("") : null
            }

            {
              Falle ? (<DialogButton mainButton="Continuar"
                title="Envio exitoso"
                description="Se envió de manera correcta la documentación."
                button="Aceptar"
                link="/menu"
                estatus={btnDisbale}
                onClick={() => {
                  postEmail();
                  validateAndSubmitForm();
                }}
              >
              </DialogButton>) : null
            }

            {
              Bene ? (<DialogButton mainButton="Continuar"
                title="Envio exitoso"
                description="Se envió de manera correcta la documentación."
                button="Aceptar"
                link="/menu"
                estatus={btnDisbale}
                onClick={() => {
                  postEmail();
                  validateAndSubmitForm();
                }}
              >
              </DialogButton>) : null
            }

            {
              Ven ? (<DialogButton mainButton="Continuar"
                title="Envio exitoso"
                description="Se envió de manera correcta la documentación."
                button="Aceptar"
                link="/menu"
                estatus={btnDisbale}
                onClick={() => {
                  postEmail();
                  validateAndSubmitForm();
                }}
              >
              </DialogButton>) : null
            }


          </Grid>
        </Grid>
      </Grid>
    </div>
  </div>
);
}

