import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper"
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import RadioInput from "../../Tools/RadioButton/RadioInput";
import TXTField from "../../Tools/TextField/TextField";
import DialogButtons from "../../Tools/Dialog/DialogButtons";
//estilos de la tabla
import StyledTableCell from "../../Tools/Table/StyledTableCell";
import TablePagination from "@mui/material/TablePagination";
//Idioma
import { FormattedMessage } from "react-intl";
//Dialogo
import DialogPdNacional from "../Dialogs/DialogPdNacional"
//impportacion apis
import {getDataToken} from "../../../api/dataToken"

export default function TableDeregistrationAccountingEntries() {
  //Creación de metodo get
  const [cuentas, setCuentas] = useState([]); 
  const getData = async () => {
    try {
      var result = await getDataToken("cuentasporasientoscontables/get_listado/");
      console.log("ojala salga", result);
      setCuentas(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getData();
  }, []); 
  
const rows = []; // ESTO ES UN ARREGLO DE EJEMPLO
  /* CONSTANTES DE ESTADOS*/ 
  const [data, setData] = useState(rows); // esta constante guarda un arreglo en donde se almacenará los datos de la API
  const [order, setOrder] = useState("ASC"); // esta constante tiene como propósico guardar el estado del orden de la tabla
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla
  const [InfoDataRow, setInfoDataRow] = useState(""); //Aqui es donde almacenará los datos de la fila seleccionadad
  const [btnDownloadInfo, setBtnDownloadInfo] = useState(true);
  const [btnDisable, setBtnDisable] = useState(true);
  const [deletedRows, setDeletedRows] = useState([]);//Estado para eliminar un registro

  /* FUNCIÓN CLICK EN LOS RADIO BUTTON, ARROJA INFORMACION DE LA FILA SELECCIONADA */
  const rbClick = (e) => {
    const inputCuenta = e.target.value;
    data.map((row) => {
      if (row.cuenta === inputCuenta) {
        console.log(row);
        setInfoDataRow(row);
        setBtnDownloadInfo(false);
        setBtnDisable(false);
        // Se agrega la fila seleccionada a deletedRows
        setDeletedRows((prevState) => [...prevState, row]);
      }
    });
  };

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //FUNCION PARA ELIMINAR UN REGISTRO
  const handleDeleteRows = () => {
    const newData = data.filter((row) => !deletedRows.includes(row));
    setData(newData);
    setDeletedRows([]);
  };
  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item>
          <h3-header-card>            
            <FormattedMessage id="." defaultMessage="Baja de asiento contable"/> 
          </h3-header-card>
        </Grid>
      </Grid>
      <Grid
        style={{ paddingBottom: "20px" }}
        container
        justifyContent="flex-end"
        alignItems="flex-start">
        <Grid item xs={4}>
          <h4-search>
            <FormattedMessage id="globBuscar" defaultMessage="Buscar"/>
          </h4-search>
          <TXTField
            showSearchIcon={true}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}/>
        </Grid>
      </Grid>
      <br />
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell align="left">
                  <FormattedMessage id="estconCont" defaultMessage="Concepto"/>
                </StyledTableCell>
                <StyledTableCell align="left"><FormattedMessage id="estCueMayo" defaultMessage="Cuenta del mayor"/></StyledTableCell>
                <StyledTableCell align="center"><FormattedMessage id="estcoSubCu" defaultMessage="Subcuenta"/></StyledTableCell>
                <StyledTableCell align="center">Subsubcuenta</StyledTableCell>
                <StyledTableCell align="center"><FormattedMessage id="loginCuent" defaultMessage="Cuenta"/></StyledTableCell>                
                <StyledTableCell align="center"><FormattedMessage id="estconNatu" defaultMessage="Naturaleza"/></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {cuentas
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                    val.concepto
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    val.cuenta.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((row, index, array) => (
                  // Se colocan dos veces cada variable a ocupar ya que esta tabla puede tener
                  //  más de dos registros dentro de otro registro
                  <>
                    { index === 0 || row.ASTCONT_idasientocontable?.ASTCONT_asientocontable !== array[index-1].ASTCONT_idasientocontable?.ASTCONT_asientocontable ? 
                    (
                      <TableRow key={row.cuenta}>

                        <StyledTableCell component="th" scope="row" align="center" rowSpan={array.filter( (item) => item.ASTCONT_idasientocontable?.ASTCONT_asientocontable === row.ASTCONT_idasientocontable?.ASTCONT_asientocontable).length}>
                          <RadioInput
                            name="rb"
                            value={row.ASTCONT_idasientocontable?.ASTCONT_asientocontable}
                            onChange={rbClick}
                          />
                        </StyledTableCell>

                        <StyledTableCell rowSpan={array.filter( (item) => item.ASTCONT_idasientocontable?.ASTCONT_asientocontable ===           row.ASTCONT_idasientocontable?.ASTCONT_asientocontable).length}>
                          {row.ASTCONT_idasientocontable?.ASTCONT_asientocontable}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                            <Grid item xs={6}>
                              {row.CTA_idcuentas?.CTAM_idcuentamayor?.CTAM_numcuenta}
                            </Grid>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Grid item xs={6}>
                            {row.CTA_idcuentas?.SCTA_idsubcuenta?.SCTA_numsubcuenta}
                          </Grid>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Grid item xs={6}>
                            {row.CTA_idcuentas?.SSCTA_idsubsubcuenta?.SSCTA_numsubsubcuenta}
                          </Grid>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Grid item xs={6}>
                            {row.CTA_idcuentas?.CTA_numcuenta}
                          </Grid>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Grid item xs={6}>
                          {row.CTAASC_naturaleza === 1 ? 'Crédito' : 'Cargo'}
                          </Grid> 
                        </StyledTableCell>

                      </TableRow>
                    ) : (
                        
                        <TableRow>
                          <StyledTableCell align="center">
                            <Grid item xs={6}>
                              {row.CTA_idcuentas?.CTAM_idcuentamayor?.CTAM_numcuenta}
                            </Grid>
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            <Grid item xs={6}>
                              {row.CTA_idcuentas?.SCTA_idsubcuenta?.SCTA_numsubcuenta}
                            </Grid>
                          </StyledTableCell>

                          <StyledTableCell align="center">
                          <Grid item xs={6}>
                            {row.CTA_idcuentas?.SSCTA_idsubsubcuenta?.SSCTA_numsubsubcuenta}
                          </Grid>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Grid item xs={6}>
                            {row.CTA_idcuentas?.CTA_numcuenta}
                          </Grid>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Grid item xs={6}>
                          {row.CTAASC_naturaleza === 2 ? 'Cargo' : 'Crédito'}
                          </Grid>                           
                        </StyledTableCell>

                        </TableRow>
                    ) }
                  </>
                  
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelRowsPerPage="Filas por página"
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <br />
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={3}
      >
        {/* <ButtonVino variant="outlined" onClick={handleDeleteRows}
          label={<FormattedMessage id="estconBaCuDarBaja" defaultMessage="Dar de baja" />}>            
          </ButtonVino> */}
        <Grid item>
          <DialogPdNacional
            mainButton={<FormattedMessage id="estconDaBa" defaultMessage="Dar de baja"/>}
            estatus={btnDisable}
            title={<FormattedMessage id="GlobEstCon" defaultMessage="Confirmación"/>}
            description="Está a punto de dar de baja la cuenta, ¿está seguro de realizar los cambios?"
            firstButton={<FormattedMessage id="globAceptr" defaultMessage="Aceptar"/>}
            onClick1={handleDeleteRows}
            secondButton={<FormattedMessage id="globCance" defaultMessage="Cancelar"/>}
          /> 
        </Grid>
      </Grid>
    </div>
  );
}
