import React from "react";
import Page from '../../components/Tools/Typogr/Page';


export function Typo(){
    return(
        <div>
            <Page />
        </div>
    );
}