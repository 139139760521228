import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import { BASE_API } from "../../../utils/constans";
import { getToken } from "../../../api/token";
import { useLocation } from "react-router-dom";

//importaciones del idioma al componente
import { FormattedMessage } from "react-intl";


export default function StatusRegistro() {

  const locationtable = useLocation();
  const tableregistro = locationtable.state.folios;
  console.log("apideregistro", tableregistro);

  const [detalles, setDetalles] = useState([]);
  const token = getToken();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_API}wktramite/get_listado/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Error al obtener datos');
        }
        const data = await response.json();
        // console.log('Datos desde la API:', data.POL_idpoliza?.POL_idpoliza);

        // Verifica el valor de POL_idpoliza
        const idFolio = tableregistro.POL_idpoliza?.POL_idpoliza;
        //  console.log('ID Folio a filtrar:', tableinfo.POL_idpoliza?.POL_idpoliza);


        // Filtra los datos basados en POL_idpoliza
        const datosFiltrados = data.filter((dato) => (
          dato.POL_idpoliza?.POL_idpoliza === idFolio &&
          dato.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_idwfmodulo === 2
        ));
        console.log('Datos filtrados:', datosFiltrados);

        // Establecer datos filtrados en el estado
        setDetalles(datosFiltrados);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [token, tableregistro.POL_idpoliza]);

  function createData(detalles) {
    return { detalles };
  }


  return (
    <Paper sx={{ width: "100%", mb: 2 }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow style={{ backgroundColor: "#00203B", color: "white" }}>
              <TableCell
                style={{
                  backgroundColor: "#00203B",
                  color: "white",
                  width: 20,
                }}
              ></TableCell>
              <TableCell style={{ backgroundColor: "#00203B", color: "white" }}>
                <FormattedMessage id="globEstatu" defaultMessage="Estatus" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {detalles.map((row) => (
              <TableRow
                key={row.detalles}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.WTM_idwftareasxmodulos?.TARE_idtarea?.TARE_activo ? (
                    <CircleRoundedIcon style={{ color: "green" }} />
                  ) : (
                    <CircleRoundedIcon style={{ color: "red" }} />
                  )}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.WTM_idwftareasxmodulos?.TARE_idtarea?.TARE_tarea}
                  {/* {row.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_descripcion} */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
