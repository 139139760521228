import * as React from "react";
import PropTypes from "prop-types";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@material-ui/core/Grid";
import IconEditar from "../../../Tools/Icons/IconEditar";
import TXTField from "../../../Tools/TextField/TextField";
//idioma
import { FormattedMessage } from "react-intl";
import {
  LimitarDigitos,
  LimitarTexto,
  LimitarTextoYNumero,
} from "../../../Recepcion/Elements/Functions/Validaciones";
import { SelectDictamen } from "../../../Tools/Selectss/SelectDictamen";
import { putDataToken, getDataToken } from "../../../../api/dataToken";
import getDate from "../../../Registro/Function/obtenerFecha";
import getHours from "../../../Registro/Function/obtenerHora";
import moment from "moment";
import { formatofecha } from "../../../../api/getUmbrales";
import DialogValidaciones from "../../../Registro/Elements/Dialog/DialogValidaciones";

//Solo aparesera un alerta con un solo boton
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogCartaEditarBene(props) {
  const rows = props.rows;
  const idBene = props.idBene;
  console.log(rows);
  console.log(idBene);

  const [open, setOpen] = React.useState(false);
  const [beneficiario, setBeneficiario] = React.useState("");
  const [asentamiento, setAsentamiento] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
    editarBene();
  };
  const handleClose = () => {
    setOpen(false);
    setvisible(false);
    setAsentamiento([]);
    setColonias([]);
    setDataSelectColonias([]);
  };

  const editarBene = () => {
    rows.forEach((beneficiario) => {
      //Validamos que el beneficiario sea diferente al seleccionado, para no sumar su porcentaje ya asignado
      if (beneficiario.PXP_idpersonaxpoliza == idBene) {
        getDataToken(`personasxpoliza/get_id/${idBene}/`).then((data) => {
          setBeneficiario(data);
          let nuevoElemento = {
            identificador: data?.PER_idpersona?.SEP_idsepomex?.id,
            texto: data?.PER_idpersona?.SEP_idsepomex?.SEP_asenta,
          };
          setAsentamiento([...asentamiento, nuevoElemento]);
        });
      }
    });
  };

  console.log(beneficiario);
  console.log(asentamiento);

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");

  console.log(moment(fechaEmpAn).format(formatofecha));
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();

  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;

  //Obtener usuario.
  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");
  const getUsers = async () => {
    try {
      var result = await getDataToken(`login/get_correo/${email}/`);
      setnombreAnalista(
        result.SNYTLOG_username +
          " " +
          result.SNYTLOG_surnamedad +
          " " +
          result.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getUsers();
  }, []);

  //////////////////////////////
  //   Funciones para cod postal
  ////////////////////////////

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (/[^0-9]/.test(keyValue)) {
      event.preventDefault();
    }
  };

  //Estados utilizados para sepomex
  const [SEP_Id, setSEP_Id] = React.useState("");
  const [SEP_municipio, setSEP_municipio] = React.useState("");
  const [SEP_colonia, setSEP_colonia] = React.useState("");
  const [codpostal, setCodPostal] = React.useState("");
  const [colonias, setColonias] = React.useState([]);
  const [dataSelectColonias, setDataSelectColonias] = React.useState([]);

  //Traer los datos de CP para los dos formatos de cp

  const getCodP = async (cp) => {
    try {
      var result = await getDataToken(`sepomex/get_listado_cp/${cp}`);
      setColonias(result);
      if (result <= 0) {
        setDataSelectColonias([]);
        setSEP_municipio("");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  //Funcionalidad del formulario CP

  const handleSubmit = () => {
    if (codpostal === "") {
      document.getElementById("txtCP2").focus();
    } else {
      getCodP(codpostal);
    }
  };

  const getInfoasenta = (e) => {
    const dataColonia = parseInt(e.target.value);
    colonias.forEach((element) => {
      if (dataColonia === element.id) {
        setSEP_Id(element.id);
        setSEP_municipio(element.SEP_municipio);
        setSEP_colonia(element.SEP_asenta);
      }
    });
  };

  //Esta función useEffect se activará cuando haya cambios en el estado 'colonias', activando a su ves otro estado
  React.useEffect(() => {
    if (colonias.length > 0) {
      let dataColonias = colonias;
      dataColonias.map(
        (cp) => (
          (cp.texto = cp.SEP_asenta), // cp.texto es una propiedad que no existe dentro del JSON, por tanto, se crea
          (cp.identificador = cp.id)
        )
      ); //Se actualiza el valor del dataSelectColonias
      setDataSelectColonias(dataColonias);
    }
  }, [colonias]);

  console.log("Id sepomex " + SEP_Id);
  console.log(dataSelectColonias);

  ///////////////////////////////////////////////
  const [values, setValues] = React.useState({
    PER_calle: "",
    PER_numeroexterior: "",
    PER_numerointerior: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  React.useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      PER_calle: beneficiario?.PER_idpersona?.PER_calle,
      PER_numeroexterior: beneficiario?.PER_idpersona?.PER_numeroexterior,
      PER_numerointerior: beneficiario?.PER_idpersona?.PER_numerointerior,
    }));
    setCodPostal(beneficiario?.PER_idpersona?.SEP_idsepomex?.SEP_codpostal);
    setSEP_municipio(beneficiario?.PER_idpersona?.SEP_idsepomex?.SEP_municipio);
    setSEP_Id(beneficiario?.PER_idpersona?.SEP_idsepomex?.id);
  }, [beneficiario]);

  const validateUpdate = () => {
    setAsentamiento([]);
    setColonias([]);
    setDataSelectColonias([]);
    let actualizar = {
      PER_calle: values.PER_calle,
      PER_numeroexterior: values.PER_numeroexterior,
      PER_numerointerior: values.PER_numerointerior,
      SEP_idsepomex: SEP_Id,
      PER_actualizofecha: fechaActSIN,
      PER_actualizousuario: nombreAnalista,
    };
    putDataToken(
      `personas/get_id/${beneficiario?.PER_idpersona?.PER_idpersona}/`,
      actualizar
    ).then((data) => {
      props.ejecutarConsulta();
      console.log(
        `Registro ${beneficiario?.PER_idpersona?.PER_idpersona} actualizado`
      );
    });
  };

  const [visible, setvisible] = React.useState(false);

  const onclickVisible = () => {
    setvisible(true);
    validateUpdate();
  };

  return (
    <div>
      {visible && (
        <DialogValidaciones
          title="Actualización correcta"
          secondButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description="Su registro se actualizo correctamente."
          onClick={handleClose}
        />
      )}
      {/*BOTON QUE HACE EL LLAMADO AL DIALOGO*/}
      {/*Este boton aparecera el js en donde se necesite de la alerta*/}
      <IconEditar
        onClick={handleClickOpen}
        tooltipTitle={
          <FormattedMessage id="globEditar" defaultMessage="Editar" />
        }
      />

      {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
      <BootstrapDialog
        // onClose={handleClose}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="dialog"
        open={open}
      >
        {/*CUERPO DEL DIALOGO*/}
        {/*TITILO*/}
        <BootstrapDialogTitle
          id="dialog"
          onClose={handleClose}
          style={{ color: "primary" }}
        >
          <h1-dialog-title>Editar beneficiario</h1-dialog-title>
        </BootstrapDialogTitle>
        {/*CUERPO DEL DIALOGO*/}
        {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
        <form>
          <DialogContent dividers style={{ minWidth: 500 }}>
            {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
          se necesita colocar entre un typogreaphy las veces que des salto de linea 
          o con un br*/}

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item xs={12}>
                <TXTField
                  value={values?.PER_calle}
                  label={"Calle"}
                  onChange={handleChange("PER_calle")}
                  InputLabelProps={{ shrink: true }}
                  onInput={(e) => LimitarTextoYNumero(e.target, 100)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  value={values?.PER_numeroexterior}
                  label={"No. Ext."}
                  onChange={handleChange("PER_numeroexterior")}
                  InputLabelProps={{ shrink: true }}
                  onInput={(e) => LimitarDigitos(e.target, 5)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  value={values?.PER_numerointerior}
                  label={"No. Int."}
                  onChange={handleChange("PER_numerointerior")}
                  InputLabelProps={{ shrink: true }}
                  onInput={(e) => LimitarDigitos(e.target, 5)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  label={
                    <FormattedMessage
                      id="globCodPos"
                      defaultMessage="Código postal"
                    />
                  }
                  value={codpostal}
                  name="txtCP2"
                  id="txtCP2"
                  onChange={(event) => setCodPostal(event.target.value)}
                  onKeyPress={handleKeyPress}
                  onPaste={(e) => e.preventDefault()}
                  onInput={(e) => LimitarDigitos(e.target, 6)}
                  showSearchIcon={true}
                  iconOnClick={handleSubmit}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectDictamen
                  sx={{
                    minWidth: 550,
                    maxWidth: 550,
                    height: 50,
                    background: "white",
                  }}
                  label={
                    <FormattedMessage
                      id="globColoni"
                      defaultMessage={`Colonia`}
                    />
                  }
                  array={
                    dataSelectColonias.length > 0
                      ? dataSelectColonias
                      : asentamiento
                  }
                  onChange={getInfoasenta}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  label={
                    <FormattedMessage
                      id="globmunicipioAlcadia"
                      defaultMessage="municipio o Alcaldia"
                    />
                  }
                  value={SEP_municipio}
                  onChange={handleChange("SEP_municipio")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          {/*es la tercer sección que engloba los botones */}
          <DialogActions>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
              marginTop={1}
            >
              <Grid item>
                <ButtonVino
                  variant="contained"
                  label="Actualizar"
                  onClick={onclickVisible}
                />
              </Grid>
              <Grid item>
                <ButtonVino
                  variant="contained"
                  label="Cancelar"
                  onClick={handleClose}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}
