/* Brandon Hernandez Rocha 11/2022 */
import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import StyledTableCell from '../../../Tools/Table/StyledTableCell'
import '../../dictamen.css'
//TXTfield
import TXTField from '../../../Tools/TextField/TextField'
//Button 
import ButtonVino from '../../../Tools/Bottones/ButtonVino'
//Select
import { Select2 } from '../../../Tools/Selectss/Select2'

//idioma
import { FormattedMessage } from 'react-intl';

//useLocation
import { useLocation } from 'react-router-dom';
import { getDataToken, postDataToken, putDataToken } from '../../../../api/dataToken'
import { formatNumber, formatNumberComa, formatNumberDecimalComa, formatedNumberAndDecimalAndComa } from '../../../../utils/functions'
import DialogGeneral from '../../../Tools/Dialog/DialogGeneral'
import DialogFeedback from '../../../Tools/Dialog/DialogFeedback'
import DialogConfirm from '../../../Tools/Dialog/DialogConfirm'
import { ESTATUS_GESTION_COMP } from '../../../../utils/constanstoken'

export default function TablaReservaRegistro(props) {
  //Datos useLocation 
  const location = useLocation();
  const data = location.state;
  // const dataListaUno = data.data.opcionRB.listaUno
  // const dataListaDos = data.data.opcionRB.listaDos
  const dataPoliza = data.data.dataPoliza;
  const dataConcepto = data.data.dataConcepto;
  console.log("Data concepto", dataConcepto);


  console.log("Datos reserva registro: ", data)

  const initialDialogResponse = {
    title: '',
    description: '',
    button: '',
    firstButton: '',
    secondButton: '',
  }
  const initialDataDialog = {
    infoFila: "", dataReservaAux: "", importe: ""
  }


  //Constantes / estados 
  const [valueImporte, setValueImporte] = React.useState("");
  const [valueImporte2, setValueImporte2] = React.useState("");
  const [importeMoneda, setImporteMoneda] = useState("")
  const [activarCeldas, setActivarCeldas] = useState(false)
  const [coberturaSelected, setCoberturaSelected] = useState(null);
  const [dataReserva, setDataReserva] = useState([]);



  const [dataDialog, setDataDialog] = useState(initialDataDialog);
  const [dialogResponse, setDialogResponse] = useState(initialDialogResponse);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogFeedb, setOpenDialogFeedb] = useState(false);
  const [dialogType, setDialogType] = useState(false);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);

/****** Código Usuario que actualizó *******/
const [user, setUser] = useState("");
//GET A API DOCUMENTACION 
const getDataUser = async () => {
  //Resultados de la api documentacion documentosxtiposiniestrostramite
  var result = await getDataToken("login/get_correo/" + localStorage.getItem("SNYTLOG_email") + "/");
  setUser(result?.SNYTLOG_username + " " + result?.SNYTLOG_surnamedad + " " + result?.SNYTLOG_surnamemom);
};
useEffect(() => {
  getDataUser();
}, []);
  //  Monedas
  const [monedas, setMonedas] = useState([])
  let getCobertura = async () => {
    try {
      //obtener resultados de gestion de competencias 
      let resultGestion = await getDataToken("gestiondecompetencias/get_listado/");
      let filterGestion = [];
      if (resultGestion) {
        filterGestion = resultGestion;
      }
      let arrAux = [];
      //Obtener  coberturas poliza por trámite
      let listCPT = await getDataToken(`coberturaspolizasportramite/get_folio/${dataPoliza.RECT_idfolio.RECT_idfolio}/`);
      if (listCPT) {
        //Hay registros, buscar la reserva de la cobertura

        for (let cpt of listCPT) {
          let reserva = await getDataToken("reservasporpolizaporcoberturas/get_IDPCP/" + cpt.CPT_idcoberturapolizatramite + "/");
          if (reserva && reserva.length > 1) {
            alert("Esta poliza tiene más de un registro con la misma cobertura. Comuniquese con encargado de TI");
          } else if (reserva) {
            let gestion = filterGestion.find(gestor => gestor.PXFS_idfoliopolizaxsiniestro.PXFS_idfoliopolizaxsiniestro == dataPoliza.PXFS_idfoliopolizaxsiniestro
              && gestor.RPC_idreservapolizaporcobertura.CPT_idcoberturapolizatramite.COB_idcobertura == reserva[0].CPT_idcoberturapolizatramite.COB_idcobertura.COB_idcobertura);
            reserva[0].gestion = !gestion ? null : gestion;
            reserva[0].identificador = reserva[0].CPT_idcoberturapolizatramite.CPT_idcoberturapolizatramite;
            reserva[0].texto = reserva[0].CPT_idcoberturapolizatramite.COB_idcobertura.COB_cobertura;
            arrAux.push(reserva[0]);
          }
        }
        setDataReserva(arrAux)
      }
      let listMonedas = await getDataToken("monedas/get_listado/");
      if (monedas) {
        let newArray = listMonedas.map(obj => {
          obj.identificador = obj.MON_idmoneda;
          obj.texto = obj.MON_abreviatura
          return obj
        });
        setMonedas(newArray);
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    getCobertura();
  }, [])



  /**
* Esta función hace el proceso de reservar al dar clic en el botón "Reservar"
* @param {Object} infoFila 
* @returns 
*/
  async function reservar() {

    let importe = formatNumber(valueImporte);
    if (!coberturaSelected || coberturaSelected == 0) {
      document.getElementById("slcCobertura").focus();
      return false;
    }
    if (parseFloat(importe) <= 0) {
      setDialogResponse({ ...dialogResponse, description: "No se puede reservar la cantidad  igual o menor que 0", button: 'Aceptar' });
      setOpenDialogFeedb(true);
      return false;
    }
    if (importeMoneda === "") {
      setDialogResponse({ ...dialogResponse, description: "Elija tipo de moneda.", button: 'Aceptar' });
      setOpenDialogFeedb(true);
      return false;
    }
    if (!valueImporte) {
      document.getElementById("txtImporte").focus();
      return false;
    }
    if (importeMoneda != coberturaSelected.CPT_idcoberturapolizatramite.MON_idmoneda.MON_idmoneda) {
      setDialogResponse({ ...dialogResponse, description: "¡ATENCIÓN! LA MONEDA ES DIFERENTE A LA MONEDA DE LA S.A.  REQUIERE AUTORIZACIÓN DE SUPERVISOR", button: 'Aceptar' });
      setDataDialog({ ...dataDialog, importe })
      setOpenDialogConfirm(true);
      handlerPostGestionComp()
      return false;
    }
    if (importe > parseFloat(coberturaSelected.CPT_idcoberturapolizatramite.CPT_sumaasegurada)) {
      setDialogResponse({ ...dialogResponse, description: "¡ATENCIÓN! EL IMPORTE ES MAYOR A LA S.A. REQUIERE AUTORIZACIÓN DE SUPERVISOR", button: 'Aceptar' });
      setDataDialog({ ...dataDialog, importe })
      setOpenDialogConfirm(true);
      handlerPostGestionComp()

      return false;
    }
    console.log("cobertura seleccionada:", coberturaSelected)
    console.log("importe: ", importe)

    //Aqui el codigo put para actualizar el campo, por mientras solo se hará estático
    // if (confirm("Está a punto de constituir una reserva por fallecimiento para la póliza N1Y1007890 por un importe $" + valueImporte + " en moneda MXP.¿Confirma la operación?")) {
    //     // el usuario hizo clic en "Aceptar"
    //     setValueImporte2(valueImporte)
    //     setActivarCeldas(true)
    // }

    //Aqui el codigo put para modificar la reserva
    let jsonReservasPolCober = {


    }
    console.log(jsonReservasPolCober);
    // if (dataReserva) {
    //   let resp = await putDataToken("reservasporpolizaporcoberturas/get_id/" + dataReservaAux.id + "/", jsonReservasPolCober);
    //   if (resp) {
    //     getDataPolCoberProd();
    //   } else {
    //     setDialogResponse({ ...resp, description: "" });
    //     setOpenDialog(true);
    //     setDialogType(false);

    //   }
    // }
    return true
  }
  //** Apartado Gestion competencias */
  const handlerPostGestionComp = async () => {
    /* TODO: BUSCAR ID DEL TRABAJADOR DISPONIBLE */
    setOpenDialogConfirm(false);
    //Buscar el gestor al azar

    let result = await getDataToken("login/get_listado/");
    let personasFiltradas = result.filter(
      (persona) => persona.SNYTLOG_gestordecompetencias === true && parseFloat(persona?.SNYTLOG_SALimite) >= parseFloat(coberturaSelected.CPT_idcoberturapolizatramite.CPT_sumaasegurada)
        && parseFloat(persona?.SNYTLOG_SALimite) >= parseFloat(formatNumber(valueImporte))
    );
    if (personasFiltradas.length <= 0) {
      //No hay usuarios con que puedan autorizar esa suma asegurada, mandar alerta. 
      setDialogResponse({ ...dialogResponse, description: "No hay usuarios que puedan autorizar esa cantidad." });
      setOpenDialog(true);
      setDialogType(false);
      return false;
    }

    // Elegir una persona aleatoria
    const indiceAleatorio = Math.floor(
      Math.random() * personasFiltradas.length
    );
    const personaAleatoria = personasFiltradas[indiceAleatorio];


    const jsonGestion = {
      PXFS_idfoliopolizaxsiniestro: dataPoliza.PXFS_idfoliopolizaxsiniestro,
      MON_idmoneda: importeMoneda,
      IDTRABAJADOR: personaAleatoria.id,
      RPC_idreservapolizaporcobertura: coberturaSelected.id,
      ESTG_idestatusgestion: ESTATUS_GESTION_COMP.PENDIENTE_ID,
      GC_sumaasegurada: parseFloat(coberturaSelected.CPT_idcoberturapolizatramite.CPT_sumaasegurada),
      GC_importereservado: formatNumber(valueImporte),
      GC_actualizousuario: user,
    }
    console.log("json competencia:", jsonGestion)
    //FILTRAR POR RPC_idreservapolizaporcobertura
    // let listado = await getDataToken("gestiondecompetencias/get_listado/");

    // if (listado) {
    //   let findReserva = listado.find(obj => obj.RPC_idreservapolizaporcobertura.id == dataReservaAux.id);
    //   //Si encuentra, utilizar ese registro y actualizar
    //   if (findReserva) {
    //     //PUT
    //     let resp = await putDataToken(`gestiondecompetencias/get_id/${findReserva.GC_idgestiondecompentencia}/`, jsonGestion);
    //     if (resp) {
    //       setDialogResponse({ ...resp, description: "" });
    //       setOpenDialog(true);
    //       setDialogType(true);
    //       // getDataPolCoberProd();
    //     } else {
    //       setDialogResponse({ ...resp, description: "" });
    //       setOpenDialog(true);
    //       setDialogType(false);
    //     }
    //   } else {
    //     //POST
    //     let resp = await postDataToken(`gestiondecompetencias/get_listado/`, jsonGestion);
    //     if (resp) {
    //       setDialogResponse({ ...resp, description: "" });
    //       setOpenDialog(true);
    //       setDialogType(true);
    //       // getDataPolCoberProd();
    //     } else {
    //       setDialogResponse({ ...resp, description: "" });
    //       setOpenDialog(true);
    //       setDialogType(false);
    //     }
    //   }
    // }
  }
  return (
    <div>
      <Grid container direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={6}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <h1-dialog-title style={{ paddingBottom: '10px' }}>Registro de asiento contable</h1-dialog-title>
          <h2-login-title style={{ paddingBottom: '10px' }}>{`${dataConcepto.CON2_idconceptoniveldos.CON2_conceptoniveldos} - ${dataConcepto.CON3_conceptoniveltres}`}</h2-login-title >
          <Paper style={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: 440, showEmptyDataSourceMessage: true }} style={{ position: 'sticky' }}>
              <Table stickyHeader aria-label="sticky table"
                sx={{
                  "& .MuiTableRow-root:hover": {
                    backgroundColor: "#ededed"
                  }
                }}
              >
                <TableHead >
                  <TableRow>
                    <StyledTableCell component="th" scope="row" align="left" >Número de folio </StyledTableCell>
                    <StyledTableCell align="center"> {dataPoliza?.RECT_idfolio?.RECT_numerofolio}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow >
                    <StyledTableCell component="th" scope="row" align="left" >Número de siniestro  </StyledTableCell>
                    <StyledTableCell align="center"> {dataPoliza?.SIN_idsiniestro?.SIN_numerosiniestro} </StyledTableCell>
                  </TableRow>
                  <TableRow >
                    <StyledTableCell component="th" scope="row" align="left" >Número de póliza</StyledTableCell>
                    <StyledTableCell align="center"> {dataPoliza?.POL_idpoliza?.POL_numpoliza}</StyledTableCell>
                  </TableRow>
                  <TableRow >
                    <StyledTableCell component="th" scope="row" align="left" >Suma Asegurada</StyledTableCell>
                    <StyledTableCell align="center">{!coberturaSelected ? "" : formatNumberDecimalComa(coberturaSelected.CPT_idcoberturapolizatramite.CPT_sumaasegurada)}</StyledTableCell>
                  </TableRow>
                  <TableRow >
                    <StyledTableCell component="th" scope="row" align="left" >Tipo de Siniestro 2  </StyledTableCell>
                    <StyledTableCell align="center">Muerte violenta</StyledTableCell>
                  </TableRow>
                  <TableRow >
                    <StyledTableCell component="th" scope="row" align="left" >Nombre  del asegurado  </StyledTableCell>
                    <StyledTableCell align="center">{`${dataPoliza.PER_idpersona.PER_nombre} ${dataPoliza.PER_idpersona.PER_apellidopaterno} ${dataPoliza.PER_idpersona.PER_apellidomaterno}`}</StyledTableCell>
                  </TableRow>
                  {!activarCeldas ? null :
                    <>
                      <TableRow >
                        <StyledTableCell component="th" scope="row" align="left" >Importe</StyledTableCell>
                        <StyledTableCell align="center"> ${valueImporte2} </StyledTableCell>
                      </TableRow>
                      <TableRow >
                        <StyledTableCell component="th" scope="row" align="left" >Moneda</StyledTableCell>
                        <StyledTableCell align="center"> MXN </StyledTableCell>
                      </TableRow>
                    </>
                  }

                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={4} style={{ width: "100%" }}><Select2 id="slcCobertura" array={dataReserva} label="Cobertura" onChange={e => setCoberturaSelected(dataReserva.find(obj => obj.CPT_idcoberturapolizatramite.CPT_idcoberturapolizatramite == e.target.value))} /></Grid>
        <Grid item xs={4} ><TXTField readOnly={true} label="Suma asegurada" textAlign="right" value={!coberturaSelected ? "" : formatNumberComa(coberturaSelected.CPT_idcoberturapolizatramite.CPT_sumaasegurada)} /></Grid>
        <Grid item xs={4} ><TXTField readOnly={true} label="Moneda cobertura" value={!coberturaSelected ? "" : coberturaSelected.CPT_idcoberturapolizatramite.MON_idmoneda.MON_abreviatura} /></Grid>
        <Grid item xs={4} style={{ width: "100%" }}><TXTField id="txtImporte" value={valueImporte} label="Importe" onChange={(e) => setValueImporte(formatedNumberAndDecimalAndComa(e))} /></Grid>
        <Grid item xs={4} style={{ width: "100%" }}><Select2 array={monedas} label="Moneda" onChange={e => setImporteMoneda(e.target.value)} /></Grid>
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ButtonVino label="Guardar" onClick={() => {
          reservar()
        }} /></Grid>
        {!openDialog ? null : <DialogGeneral open={openDialog} type={dialogType} title={dialogResponse.title} description={dialogResponse.description} onClickAccept={() => setOpenDialog(false)} />}
        {!openDialogFeedb ? null : <DialogFeedback open={openDialog} title={dialogResponse.title} description={dialogResponse.description} clickButton={() => setOpenDialog(false)} button={dialogResponse.button} />}
        {!openDialogConfirm ? null : <DialogConfirm open={openDialogConfirm} description={dialogResponse.description} firstButton={"Aceptar"} secondButton={"Cancelar"} onClickAccept={() => setOpenDialogConfirm(false)} onClickAccept2={() => {
          handlerPostGestionComp()
        }} />}
      </Grid>
    </div>
  );
}