import React from "react";

export function CompLayaout(props){

    const {children} = props;
    
    return(
        <div>
            {children}
        </div>
    );
}