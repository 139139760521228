/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import BTInv from "../../Tools/Bottones/BTInvestigador";
import { Select1 } from "../../Tools/Selectss/Select1";
import { Grid } from "@mui/material";
import DialogButton from "../../Tools/Dialog/DialogButton";
//idioma
import { FormattedMessage } from "react-intl";
//api
import { getDataToken } from "../../../api/dataToken";
import { putDataToken } from "../../../api/dataToken";
import getDate from "../../Registro/Function/obtenerFecha";
import getHours from "../../Registro/Function/obtenerHora";
import moment from "moment";
import { formatofecha } from "../../../api/getUmbrales";

//Solo aparecerá la alerta con dos botones
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogAsignar(props) {
  const [open, setOpen] = React.useState(false);
  const [RFC, setRFC] = React.useState("HERB990218HHH"); //Ejemplo de un RFC, este campo debería compararse con el del usuario en sesión
  const [RFCErr, setRFCErr] = React.useState(false);
  const [investigadores, setInvestigadores] = React.useState("");
  //Obtener usuario.
  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");
  console.log(moment(fechaEmpAn).format(formatofecha));
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();
  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;
  console.log("fechaActSIN" + fechaActSIN);

  const getInvestigadores = async () => {
    try {
      //Resultados de la api personas
      var resultInves = await getDataToken("login/get_listado/");
      var filtro = resultInves.filter(
        (persona) => persona.SNYTLOG_investigacion === true
      );

      var aux = filtro.map((e) => {
        return {
          ...e,
          identificador: e.id,
          texto: `${e.SNYTLOG_username} ${e.SNYTLOG_surnamedad} ${e.SNYTLOG_surnamemom}`,
        };
      });
      setInvestigadores(aux);
      var resultAnalista = await getDataToken(`login/get_correo/${email}/`);
      setnombreAnalista(
        resultAnalista.SNYTLOG_username +
          " " +
          resultAnalista.SNYTLOG_surnamedad +
          " " +
          resultAnalista.SNYTLOG_surnamemom
      );
    } catch (e) {}
  };
  React.useEffect(() => {
    getInvestigadores();
  }, []);

  const [asignarInvestigador, setAsignarInvestigador] = React.useState("");
  const rowFolio = props.rowFolio;
  const idInvestigacion = props.idInvestigacion;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.ejecutarConsulta();
  };

  // Las variables de acontinuación tienen ccomo objetivo mostrar la fecha actual
  var day = new Date().getDate();
  var month = new Date().getMonth() + 1;
  var year = new Date().getFullYear();
  var formatDate = day + "/" + month + "/" + year; //format: dd-mm-yyyy;
  //La siguiente constante ayudará a validar RFC con el formato del SAT
  const validRFC = new RegExp("/^[ña-z]{3,4}[0-9]{6}[0-9a-z]{3}$/i");
  const validateRFC = () => {
    if (!validRFC.test(RFC)) {
      setRFCErr(true);
    }
  };

  const arrayInvestigadores = [
    { identificador: "1", texto: "Germán Castillo Rueda" },
    { identificador: "2", texto: "Brandon Hernandez Rocha" },
  ];

  const changeInvest = (investId) => {
    investigadores.forEach((data) => {
      if (data.identificador == investId) {
        setAsignarInvestigador(data.texto);
        putNombreInvestigador(data.identificador);
        console.log(asignarInvestigador);
        return 0;
      }
    });
  };

  async function putNombreInvestigador(aux) {
    try {
      let idInvestigador = {
        IDTRABAJADOR: aux,
        INV_fechaasignacion: fechaEmpAn,
        INV_actualizofecha: fechaActSIN,
        INV_actualizousuario: nombreAnalista,
      };
      var result = await putDataToken(
        `investigacion/get_id/` + idInvestigacion + "/",
        idInvestigador
      );
      console.log("Registro actualizado", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  return (
    <div>
      {/*BOTON QUE HACE EL LLAMADO AL DIALOGO*/}
      {/*Este boton aparecera el js en donde se necesite de la alerta*/}
      <BTInv
        tooltipTitle={
          <FormattedMessage
            id="investAsIn"
            defaultMessage="Asignar investigador"
          />
        }
        onClick={handleClickOpen}
      />
      {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
      <BootstrapDialog
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        open={open}
      >
        {/*CUERPO DEL DIALOGO*/}
        {/*TITILO*/}
        <BootstrapDialogTitle
          id="dialog"
          onClose={handleClose}
          style={{ color: "#761f2a" }}
        >
          <h1-dialog-title>{props.title} </h1-dialog-title>
        </BootstrapDialogTitle>
        {/*CUERPO DEL DIALOGO*/}
        {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
        <DialogContent dividers style={{ minWidth: 500 }}>
          {" "}
          {/* <--------------------------------  */}
          {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
          se necesita colocar entre un typogreaphy las veces que des salto de linea 
          o con un br*/}
          <h2-dialog-description>{props.description}</h2-dialog-description>
          <label>
            <Select1
              label2={
                <FormattedMessage
                  id="globNomInv"
                  defaultMessage="Nombre del investigador"
                />
              }
              array={investigadores}
              onChange={(e) => changeInvest(e.target.value)}
            />
          </label>
          {/*<TXTField ></TXTField>*/}
          {/*RFCErr ? null: <p style={{textAlign: "center"}}><label>Error, verifica R.F.C.</label></p>*/}
        </DialogContent>
        {/*es la tercer sección que engloba los botones */}
        <DialogActions>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item xs={"auto"}>
              <DialogButton
                mainButton={props.mainButton}
                title={"Asignación exitosa"}
                description={`Se asignó el investigador ${asignarInvestigador} al folio ${rowFolio}`}
                onClick3={handleClose}
                button={
                  <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
                }
              />
            </Grid>
            <Grid item xs={"auto"}>
              <ButtonVino label={props.secondButton} onClick={handleClose} />
            </Grid>
          </Grid>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
