/* Brandon Hernandez Rocha 11/2022 */
import React from "react";
import CompDictamenDetalleFolio from "../../../components/Dictamen/CompDetalleFolio";



export function DetalleFolio() {
    return (
        <div>
            
            <CompDictamenDetalleFolio />
            
        </div>
    );
}