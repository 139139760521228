import * as React from "react";
import PropTypes from "prop-types";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@material-ui/core/Grid";
import TXTField from "../../../Tools/TextField/TextField";
import DatePicker2 from "../../../Tools/DatePicker/DatePicker2";
import { FormattedMessage } from "react-intl";

//Solo aparesera un alerta con un solo boton
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogAvisoSiniestro(props) {
  const [open, setOpen] = React.useState(true);
  var today = new Date();

  const handleClose = () => {
    props.closeDialog(false);
    setOpen(false);
  };

  return (
    <div>
      {/*BOTON QUE HACE EL LLAMADO AL DIALOGO*/}

      {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
      <BootstrapDialog
        maxWidth={"md"}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="dialog"
        open={open}
      >
        {/*CUERPO DEL DIALOGO*/}
        {/*TITILO*/}
        <BootstrapDialogTitle
          id="dialog"
          onClose={handleClose}
          style={{ color: "#761f2a" }}
        >
          <h1-dialog-title>
            <FormattedMessage
              id="dictAviSin"
              defaultMessage="Aviso de siniestro"
            />
          </h1-dialog-title>
        </BootstrapDialogTitle>
        {/*CUERPO DEL DIALOGO*/}
        {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
        <DialogContent dividers style={{ minWidth: 500 }}>
          {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
          se necesita colocar entre un typogreaphy las veces que des salto de linea 
          o con un br*/}
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item>
              <h3-title-card>
                <FormattedMessage
                  id="globNoPoli"
                  defaultMessage="No. de póliza"
                />
                :
              </h3-title-card>
            </Grid>
            <Grid item>
              <h3-subtitle-card>{props.poliza}</h3-subtitle-card>
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={4}>
              <TXTField
                label={
                  <FormattedMessage
                    id="globDestina"
                    defaultMessage="Destinatario"
                  />
                }
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TXTField
                label={
                  <FormattedMessage
                    id="globCorEle"
                    defaultMessage="Correo electrónico"
                  />
                }
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TXTField
                label={
                  <FormattedMessage
                    id="globNomAse"
                    defaultMessage="Nombre del asegurado"
                  />
                }
                required
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker2
                label={
                  <FormattedMessage
                    id="globFechNa"
                    defaultMessage="Fecha de nacimiento"
                  />
                }
                maxDate={today}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker2
                label={
                  <FormattedMessage
                    id="dictFeDeSi"
                    defaultMessage="Fecha de siniestro"
                  />
                }
                maxDate={today}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TXTField
                label={
                  <FormattedMessage id="globMensaj" defaultMessage="Mensaje" />
                }
                multiline={true}
                required
              />
            </Grid>
          </Grid>
          <br />
          <br />
        </DialogContent>
        {/*es la tercer sección que engloba los botones */}
        <DialogActions>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <ButtonVino
                onClick={handleClose}
                variant="contained"
                label={
                  <FormattedMessage id="globCance" defaultMessage="Cancelar" />
                }
              />
            </Grid>
            <Grid item>
              <ButtonVino
                onClick={handleClose}
                variant="contained"
                label={
                  <FormattedMessage id="globEnviar" defaultMessage="Enviar" />
                }
              />
            </Grid>
          </Grid>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
