import { Medico } from "../../pages/Client/ModMedico/Medico";
import { Info } from "../../pages/Client/ModMedico/Info";
import { ClientLayaout } from '../../layouts/Client/ClientLayaout';

const routesClient = [

    {
        path: "/medico",
        layout: ClientLayaout,
        component: Medico,
        exact: true,
    },
    {
        path: "/medico/infoasegurado",
        layout: ClientLayaout,
        component: Info,
        exact: true,
    },
];

export default routesClient;