import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { uploadFilesToNextcloud2 } from "../../../../nextcloud/functions";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { Select1 } from "../../../Tools/Selectss/Select1";
import BTUpload from "../../../Tools/Bottones/BTUpload";
import DialogConfirm from "../Dialog/DialogConfirm";
import { FormattedMessage } from "react-intl";

import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import IconVisualizar from "../../../Tools/Icons/IconVisualizar";
import TXTField from "../../../Tools/TextField/TextField";
import { Radio } from "@mui/material";
import { TAM_ARCHIVOS_MB } from "../../../../utils/constanstoken";
export default function TableDocumentacion(props) {


  const arrayDocs = props.arrayDocs

  //declaracion de la variable ID
  var initialState = [{ id: 1, tipoDoc: "", file: "", original: true, copia: false, notas: "" }];

  //funcion que recibira la variable initialState previamente declarada
  const [dataRow, setDataRow] = useState(initialState);
  React.useEffect(() => {
    if (props.limpiarTabla) {
      // Lógica para limpiar la tabla
      setDataRow([])
    }
  }, [props.limpiarTabla]);

  //esta funcion agrega un contador que al hacer click aumentara un registro en nuestra tabla
  const agregarFila = () => {
    let identificador;
    if (dataRow.length <= 0) {
      identificador = 1
    } else {
      // Obtener el último elemento del array
      const ultimoElemento = dataRow[dataRow.length - 1];
      // Obtener el último ID del elemento
      const ultimoId = ultimoElemento.id;
      // Asignar el nuevo ID al último elemento
      identificador = ultimoId + 1;
    }
    setDataRow((current) => [...current, { id: identificador, tipoDoc: "", file: "", original: true, copia: false, notas: "" }]);
  };
  //esta funcion elimina un elemento de la tabla
  const DeleteItems = (event, indexItem) => {
    if (event) {
      setDataRow((prevState) =>
        prevState.filter((todo, index) => index !== indexItem)
      );
    }
  };

  //Detectar cambios en el select y actualizar dataRow
  const updateDocList = (e, id) => {
    const item = e.target.value;
    const newData = [...dataRow]; // Crear una copia nueva del array de objetos
    newData.forEach((c, i) => {
      if (c.id == id) {
        c.tipoDoc = item;
      }
    });
    setDataRow(newData);
  };
  //Detectar cambios en el botón de carga de archivos
  const updateDocUrlList = (e, id) => {
    const item = e;
    const newData = [...dataRow]; // Crear una copia nueva del array de objetos
    newData.forEach((c, i) => {
      if (c.id === id) {
        c.file = item;
      }
    });
    setDataRow(newData);
  };
  //Detectar cambios en original y copia 
  const updateDocCopia = (e, id) => {
    const item = e.target.checked;
    const newData = [...dataRow]; // Crear una copia nueva del array de objetos
    newData.forEach((c, i) => {
      if (c.id === id) {
        c.copia = item;
        c.original = !item;
      }
    });
    setDataRow(newData);
  };
  const updateDocOriginal = (e, id) => {
    const item = e.target.checked;
    const newData = [...dataRow]; // Crear una copia nueva del array de objetos
    newData.forEach((c, i) => {
      if (c.id === id) {
        c.original = item;
        c.copia = !item;
      }
    });
    setDataRow(newData);
  };
  //Detectar cambios en notas 
  const updateDocNotas = (e, id) => {
    const item = e.target.value;
    const newData = [...dataRow]; // Crear una copia nueva del array de objetos
    newData.forEach((c, i) => {
      if (c.id === id) {
        c.notas = item;
      }
    });
    setDataRow(newData);
  };


  //Este useEfect permitirá actualizar el estado de los documentos seleccionados 
  React.useEffect(() => {
    if (dataRow.length > 0) {
      console.log(dataRow)
      let arrayDocs = []
      for (let index = 0; index < dataRow.length; index++) {
        const element = dataRow[index];
        arrayDocs.push({doc: element.file, docName: element.file.name  });
      }
      props.documentos(arrayDocs)
      props.documentosExpTram(dataRow)
    }else{
      props.documentosExpTram(dataRow)
      props.limpiarTablaCompleto(true);
    }
  }, [dataRow])

  //VISUALIZAR DOCUMENTO : Esta función recibe un id que servirá para filtrar el documento que se quiere visualizar
  function openDoc(id) {
    if (id === "") {
      alert("No ha seleccionado documento para visualizar");
      return false;
    }

    const filteredArray = dataRow.filter((item) => item.id === id);
    const element = filteredArray.find((item) => item.id === id);
    let urlFile
    //La primera validación será para saber si element es diferente de undefined, en caso contrario mandar un alert de no se encontraron archivos
    try {
      if (element && element.file != "") {
        //esta validación servirá para visualizar los documentos; los recién agregados tienen un prefijo blob en la url
        urlFile = URL.createObjectURL(element.file)
        window.open(urlFile);
      } else alert("No se encontraron archivos");
      window.onload = function () {
        URL.revokeObjectURL(urlFile);
      };
    } catch (e) {
      alert("No ha seleccionado documento para visualizar");
    }
  }




  return (
    <div>
      <h3-subtitle-card>
        <FormattedMessage
          id="msg.recep.formatodearchivos"
          defaultMessage={`El formato de los archivos debe ser .jpg o .pdf con un peso máximo de ${TAM_ARCHIVOS_MB}MB.`}
        />
      </h3-subtitle-card>
      <br />
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 500 }} style={{ position: "sticky" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                <StyledTableCell align="center" style={{ width: "100px" }}>
                  <FormattedMessage
                    id="globDocume"
                    defaultMessage="Documento"
                  />
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "250px" }}>
                  <FormattedMessage id="recepArcvo" defaultMessage="Archivo" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="globOrigin" defaultMessage="Original" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="globCopia" defaultMessage="Copia" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="globNotas" defaultMessage="Notas" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="recepVer" defaultMessage="Ver" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="estconElim" defaultMessage="Eliminar" />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {dataRow.map((row, index) => {
                return (
                  <TableRow>
                    <StyledTableCell align="center">
                      <Select1 id={row.id} array={arrayDocs} label={"Documentos"} onChange={(e) => { updateDocList(e, row.id) }} />
                      {/* <SelectDocumentos
                        tipoDoc={(e) => updateDocList(e, index)}
                      /> */}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <BTUpload
                        style={{ marginRight: "10px", cursor: row.tipoDoc === "" ? "default" : "pointer" }}
                        disabled={row.tipoDoc === "" ? true : false}
                        accept=".pdf, .jpg, .MP4, .MOV, .AVI"
                        id="bt-upload1"
                        onChange={(e) => {
                          let files = e.target.files;
                          let validFiles = [];

                          for (let i = 0; i < files.length; i++) {
                            let file = files[i];
                            if (file.size > TAM_ARCHIVOS_MB * 1024 * 1024) {
                              console.log(
                                `El tamaño máximo permitido es de ${TAM_ARCHIVOS_MB}MB.`
                              );
                              updateDocUrlList("", row.id)
                              alert(`El tamaño máximo permitido es de ${TAM_ARCHIVOS_MB}MB.`)
                              throw new Error(`El tamaño máximo permitido es de ${TAM_ARCHIVOS_MB}MB.`)
                            } else {
                              validFiles.push(file);
                              updateDocUrlList(file, row.id)
                            }
                          }
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Radio disabled={/*row.tipoDoc === "" ||*/ row.file == "" ? true : false} checked={row.original} onChange={e => updateDocOriginal(e, row.id)} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Radio disabled={/*row.tipoDoc === "" ||*/ row.file == "" ? true : false} checked={row.copia} onChange={e => updateDocCopia(e, row.id)} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <TXTField label={"Notas"} disabled={/*row.tipoDoc === "" ||*/ row.file === "" ? true : false} onChange={e => updateDocNotas(e, row.id)} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IconVisualizar tooltipTitle={"Vista previa"} disabled={row.file === "" ? true : false} onClick={() => openDoc(row.id)} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <DialogConfirm title={"Eliminar fila"} description={"¿Estás seguro de eliminar el elemento de la tabla?"} onClickAccept={(e) => DeleteItems(e, index)} />
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <br></br>
      <ButtonVino
        variant="contained"
        color="primary"
        size="small"
        onClick={agregarFila}
        label={
          <FormattedMessage id="recepNuDoc" defaultMessage="Nuevo documento" />
        }
      />
    </div>
  );
}
