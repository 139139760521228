import React, { useMemo, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import html2canvas from "html2canvas";

//importacion del componente Bar que servira para llamarlo desde react-chartjs-2, debemos importarlo para usarlo con npm
import { Bar } from "react-chartjs-2";

//Herramientas para poder imprimir la grafica
import { useReactToPrint } from "react-to-print";

//importacion del estilo y componentes estandarizados
import "../../../css/estilo.css";
import { Grid } from "@mui/material";
import ButtonVino from "../../Tools/Bottones/ButtonVino";

//importaciones del idioma al componente
import { FormattedMessage } from "react-intl";
import { useState, useEffect } from "react";
import { URLAPIIMG } from "../../../utils/constans";
import { getDataToken } from "../../../api/dataToken";
import { generatePDFWithFetch } from "../../../api/reportes"
import moment from "moment";

//registramos las herramientas que usaremos BarElement hace referencia a que usaremos un grafico de barras
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const options = {
  fill: true,
  scales: {
    x: {
      display: true,
      title: {
        display: true,
        text: 'Modulos',
      },
    },
    y: {
      display: true,
      title: {
        display: true,
        text: 'Folios Asignados',
      },
    },
  },
}

export default function Grafica({ folios }) {

  // Funcion para obtener
var fechaInicioInput = document.getElementById('fechaInicio');
var fechaFinInput = document.getElementById('fechaFin');
// Obtiene el valor del atributo 'value'
var valorFechaInicio = fechaInicioInput.value;
var valorFechaFin = fechaFinInput.value;
// Imprime el valor en la consola
// console.log("Inicio", valorFechaInicio);
// console.log("Fin", valorFechaFin);

  //Area de la impresion
  const componentRef = useRef();

  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const formattedDate = moment().format("LL"); // Formato "Ciudad de México a D [de] MMMM [de] YYYY"
    setCurrentDate(formattedDate);
  }, []); 

const [datosConf, setdatosConf] = useState([]);
  // Get para el diseño de las graficas
  const getUsers = async () => {
    try {
   
      var resultConf = await getDataToken(`configuraciongeneral/get_id/1/`);
      setdatosConf(resultConf);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getUsers();
  }, []); 


    const url = `templatesCartas/generate-reporteWF/`;
    const rutalogo = `${URLAPIIMG}${datosConf?.SCG_imgPathSing}`;
    const rutaimg = `${URLAPIIMG}${datosConf?.SCG_imgPathSubLogo}`;
    const param_pie = `${URLAPIIMG}${datosConf?.SCG_imgPathPiePagina}`; 
    const grafica = "https://dev.apis.sinay.com.mx/media/images/logo_qait.png ";
    const p = "REPORTE GLOBLAL POR MODULOS";
    const p1 = `${datosConf?.SCG_estadoCarta} a ${currentDate}`;
    const p3 = `${datosConf?.SCG_direccionAseguradora}`;
    // console.log("Datosconf", datosConf);
    // useEffect(() => {
    //   // Función para generar y obtener el PDF
    //   let json = {
    //     param_logo: rutalogo,
    //     param_imagen: rutaimg,
    //     param_pie: param_pie,
    //     param_grafica: grafica,
    //     param: p,
    //     param1: p1,
    //     param2: "A CONTINUACIÓN SE MUESTRA",
    //     param3: p3,
    //   };
    //   // Llama a la función para generar el PDF
    //   generatePDFWithFetch(url, json);
    // }, []); 
    const generatePDF = async () => {
      const scale = 8;
      const chartImage = await html2canvas(componentRef.current, {
        scale: scale,
      }).then(canvas => canvas.toDataURL("image/png"));
      let json = {
        param_logo: rutalogo,
        param_imagen: rutaimg,
        param_pie: param_pie,
        param_grafica: chartImage,
        param: p,
        param1: p1,
        param2: "Reporte de las fechas" + "   "  + valorFechaInicio + '|' + valorFechaFin,
        param3: p3,
      };
  
      // Llama a la función para generar el PDF
      generatePDFWithFetch(url, json);
    };
  //Fin de area de impresiom

  const [inFolio, setFolios] = useState(folios);

  useEffect(() => {
    setFolios(folios);
  }, [folios]);
  const contarPolizas = () => {
    // Utiliza un objeto para almacenar las pólizas únicas por analista
    const polizasUnicas = {};
  
    // Itera sobre los elementos de inFolio y agrega las pólizas únicas por analista al objeto
    inFolio.forEach(poliza => {
      const analista = poliza.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_descripcion;
      const numPoliza = poliza.POL_idpoliza?.POL_numpoliza;
      if (analista && numPoliza !== undefined) {
        if (!polizasUnicas[analista]) {
          polizasUnicas[analista] = new Set();
        }
        polizasUnicas[analista].add(numPoliza);
      }
    });
  
    // Convierte el objeto a otro objeto con el conteo de pólizas únicas por analista
    const polizasCount = {};
    Object.keys(polizasUnicas).forEach(analista => {
      polizasCount[analista] = polizasUnicas[analista].size;
    });
  
    return polizasCount;
  };

  

  const data = useMemo(() => {
    const uniqueAnalistas = Object.keys(contarPolizas());
  
    const scores = uniqueAnalistas.map((analista) =>
      contarPolizas()[analista] || 0
    );
      
  
      return {
        datasets: [
          {
            label: "GRAFICA DE FOLIOS POR MODULO ",
            tension: 0.5,
            data: scores,
            backgroundColor: [
              "#3e95cd",
              "#8e5ea2",
              "#3cba9f",
              "#e8c3b9",
              "#c45850",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(255, 159, 64, 1)",
              "rgba(255, 205, 86, 1)",
            ],
          },
        ],
        labels: uniqueAnalistas,
      };
    }, [folios]);

  return (
    <>
    <br/>
        <div
          ref={componentRef}
          className="app"
          style={{ width: "800px", height: "500px" }}
        >
          <Bar data={data} options={options} />
        </div>
      
      <Grid container justifyContent="center" alignItems="center">
          {/**Asignamos el evento al botono para poder imprimir nuestro documento */}
          <ButtonVino
            label={
              <FormattedMessage id="globImprim" defaultMessage="Imprimir" />
            }
            onClick={generatePDF}
          />

      </Grid>
    </>
  );
}
