import React from "react";

//importacion del estilo
import "../../css/estilo.css";

//importacion de componentes estandarizados y herramientas de mui
import Recorrido from "../Tools/BreadCrumbs/Recorrido";
import TableRecepcion from "./Elements/Tables/TableRecepcion";
import { Grid } from "@mui/material";
import ReturnPage from "../Tools/Bottones/ButtonReturn";

//importaciones del multilenguaje

import { FormattedMessage } from "react-intl";

export function CompRecepcion() {
  return (
    <div className="div-parent">
      {/*Enlaces de inicio */}
      <div className="div-breadcrumbs">
        {" "}
        {/*navegador*/}
        <Recorrido
          name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
          enlace="/menu"
          name2={
            <FormattedMessage id="globRecepc" defaultMessage="Recepción" />
          }
        />
      </div>
      {/**Insercion boton regresar  */}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <ReturnPage href="/menu" />
      </Grid>
      <div className="div-container">
        {/*Tabla recepcion */}
        <TableRecepcion />
      </div>
    </div>
  );
}
