//David Ruiz 11/2022

import * as React from "react";
import {  useEffect } from 'react'
//imagenes
import negocio from "../../../img/Conf/negocio.svg"; //Reglas de negocio
import conta from "../../../img/Conf/conta.svg"; //Contabilidad
import admin from "../../../img/Conf/admin.svg"; //Administrador de usuarios
import conf from "../../../img/Conf/conf.svg"; //Configuracion General
import catalogo from "../../../img/Conf/catalogo.svg"; //Catalogo
import seguridad from "../../../img/Conf/seguridad.svg"; //Seguridad
import correo from "../../../img/Conf/correo.svg"; //Correo

import { getDataToken } from "../../../api/dataToken";

import ReturnPage from "../../Tools/Bottones/ButtonReturn";

import { FormattedMessage } from 'react-intl';
import Grid from "@material-ui/core/Grid";
//import { langContext } from '../../../Context/langContext';



import "./Elements/Css/Inicio.css";
import { useState } from "react";

export function Confmenu() {


  const [dataX, setDataX] = React.useState([])

  const getDataPermisos = async () => {

    //Resultados de la api investigacion
    var resultPermisos = await getDataToken("login/get_correo/"+  localStorage.getItem("SNYTLOG_email") + "/");    
    setDataX(resultPermisos);
    
  }

  useEffect(() => {
    getDataPermisos()  
  }, [])
  

 
  //Efecto dropdown al dar click en ciertos módulos

  const [showConta, setShowConta] = useState(true);

  const [showNegocio, setShowNegocio] = useState(true);

  //Href para recepcion
  const clickconfadmin = () => {
    //if(dataX.groups.includes(21)){
      window.location = "/confgeneral";
    //};
    
  };

  const clickSecurity = () => {
    window.location = "/confSecurity";
  };

  const clickMail = () => {
    //if(dataX.groups.includes(23)){
      window.location = "/mail-output";
    //};
    
  };


  //Href para recepcion
  const clickconfrol = () => {
   //if(dataX.groups.includes(26)){
    window.location = "/rolespermisos";
  //}
    
  };

  const clickCataPlan = () => {
    window.location = "/CataCobertura";
  };

  // Constante para contabilidad
  const clickconta = () => {
    window.location = "/Conta";
  };
  

  return (
  
    <Grid>
   <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
        style={{
          paddingRight: "50px",
          paddingTop: "30px",
          position: "absolute",
        }}
      >
        <ReturnPage href="/" />
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        id="div-parent"
      ></Grid>
      {/* DIV MÓDULO  ADMIN USERS */}

      <div id="div-parent">
      <div className="div-children" id="div-mod-recepcion">
        {/*<Selects/>*/}
        <div
          className="div-grandchild"
          onClick={clickconfrol}
        >
          <center>
            <img className="img-iconos" src={admin} alt="Recepción" />
            <p>
              <FormattedMessage
                id="Mod-ConfGen-Title1"
                defaultMessage="Administración de usuarios"
              />
            </p>
          </center>
        </div>
      </div>
      {/* DIV MÓDULO CONF GENERAL */}
      <div className="div-children" id="div-mod-registro">
        <div className="div-grandchild" onClick={clickconfadmin}>
          <center>
            <img className="img-iconos" src={conf} alt="Registro" />
            <p>
             
              <FormattedMessage
                id="Mod-ConfGen-Title2"
                defaultMessage="Configuración general"
              />
            </p>
          </center>
        </div>
      </div>
      {/* DIV MÓDULO CONTADOR */}
      <div className="div-children" id="div-mod-CONTADOR">
        <div className="div-grandchild" onClick={() => setShowConta((s) => !s)}>
          <center>
            <img className="img-iconos" src={conta} alt="Cont" />
            <p>
              <FormattedMessage
                id="Mod-ConfGen-Title3"
                defaultMessage="Contabilidad"
              />
            </p>
          </center>
        </div>
        <a
          className="lnk-style"
          href="/#"
          style={{ display: showConta ? "none" : "block" }}
        >
          <FormattedMessage
            id="Mod-ConfGen-Title3-Subtitle1"
            defaultMessage="Mandar mensaje"
          />
        </a>
        <a
          className="lnk-style"
          href="/Conta"
          style={{ display: showConta ? "none" : "block" }}
        >
          <FormattedMessage
            id="Mod-ConfGen-Title3-Subtitle2"
            defaultMessage="Contabilidad SINAY"
          />{" "}
        </a>
      </div>
      {/* DIV MÓDULO CATALOGO */}
      <div className="div-children" id="div-mod-registro">
        <div
          className="div-grandchild"
          // onClick={() => setShowCatalogo((s) => !s)}
          onClick={clickCataPlan}
        >
          <center>
            <img className="img-iconos" src={catalogo} alt="Catalogo" />
            <p>
              <FormattedMessage
                id="Mod-ConfGen-Title4"
                defaultMessage="Catálogo"
              />
            </p>
          </center>
        </div>

        {/* <a
          className="lnk-style"
          href="/#"
          style={{ display: showCatalogo ? "none" : "block" }}
        >
          <FormattedMessage
            id="Mod-ConfGen-Title4-Subtitle1"
            defaultMessage="Planes"
          />
        </a>
        <a
          className="lnk-style"
          href="/#"
          style={{ display: showCatalogo ? "none" : "block" }}
        >
          <FormattedMessage
            id="Mod-ConfGen-Title4-Subtitle2"
            defaultMessage="Cobertura"
          />{" "}
        </a> */}
      </div>
      {/* DIV MÓDULO SEGURIDAD */}
      <div className="div-children" id="div-mod-registro">
        <div
          className="div-grandchild"
        //   onClick={() => setShowSeguridad((s) => !s)}
        onClick={clickSecurity}
        >
          <center>
            <img className="img-iconos" src={seguridad} alt="Seguridad" />
            <p>
              <FormattedMessage
                id="Mod-ConfGen-Title5"
                defaultMessage="Seguridad"
              />
            </p>
          </center>
        </div>
        {/* <a
          className="lnk-style"
          href="/#"
          style={{ display: showSeguridad ? "none" : "block" }}
        >
          SSH
        </a>
        <a
          className="lnk-style"
          href="/#"
          style={{ display: showSeguridad ? "none" : "block" }}
        >
          .PUB{" "}
        </a> */}
      </div>
      {/* DIV MÓDULO CORREO */}

      <div className="div-children" id="div-mod-registro">
        <div
          className="div-grandchild"
        //   onClick={() => setShowSeguridad((s) => !s)}
        onClick={clickMail}
        >
          <center>
          <img className="img-iconos" src={correo} alt="Correo" />
            <p>
            <FormattedMessage id="glob.correosaelec" defaultMessage="Comunicación" />
            </p>
          </center>
        </div>



      

      </div>
      {/* DIV Negocio */}
      <div className="div-children" id="div-mod-registro">
        <div
          className="div-grandchild"
          onClick={() => setShowNegocio((s) => !s)}
        >
          <center>
            <img className="img-iconos" src={negocio} alt="Negocio" />
            <p>
              <FormattedMessage
                id="Mod-ConfGen-Title7"
                defaultMessage="Negocio"
              />
            </p>
          </center>
        </div>
        <a
          className="lnk-style"
          href="/ConfNegocio"
          style={{ display: showNegocio ? "none" : "block" }}
        >
          <FormattedMessage
            id="Mod-ConfGen-Title7-Subtitle1"
            defaultMessage="Dias de servicio"
          />
        </a>
        <a
          className="lnk-style"
          href="/negocioconfi"
          style={{ display: showNegocio ? "none" : "block" }}
        >
          <FormattedMessage
            id="Mod-ConfGen-Title7-Subtitle3"
            defaultMessage="Configuración de Negocio"
          />{" "}
        </a>
      </div>
      {/* FIN DE DIV MÓDULOS */}
      </div>
    </Grid>
  );
}
