import { SA } from "../../../utils/constanstoken";

//Esta función la utilizamos para validar si la suma aasegurada es mayor a la que la aseguradora
//considera como limite para mandar a investigar.

export default function SumaAsegurada(SumaAse) {
  if (SumaAse >= SA) {
    return true;
  } else {
    return false;
  }
}
