import React from "react";
import { CompAgregarDocumentos } from "../../../components/Recepcion/CompAgregarDocumentos";


//import  { BotonesLayout } from '../../components/Bottones/BotonesLayout';

export function AgregarDocumento() {
  return (
    <div>
     
      <CompAgregarDocumentos />
     
    </div>
  );
}
