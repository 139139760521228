import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'

/* ESTILOS DE LA TABLA */
  const StyledTableCell = styled(TableCell)(({ theme, paddingRight, paddingLeft, minWidth, padding }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#00203B',
      padding: !padding ? '5px' : padding,
    
      paddingLeft: '10px',
      paddingRight: '10px',
      textAlign: 'center', 
      height:'65px', 
      color: theme.palette.common.white,
      fontSize: '20px',
      borderColor: '#e2e2e2',
      border: "1px solid #e2e2e2",
      fontFamily: 'Avenir-Light',
      minWidth : minWidth ? minWidth : 'auto',
    },
    [`&.${tableCellClasses.body}`]: {
      height: '20px',
      padding: 0,
      paddingRight: paddingRight ? paddingRight : '30px',
      paddingLeft: paddingLeft ? paddingLeft : '30px',
      fontSize: '16px',
      borderColor: '#e2e2e2',
      border: "1px solid #e2e2e2",
      fontFamily: 'Avenir-Light',
    },
  }));

  export default StyledTableCell