import { useContext } from "react";
import * as React from "react";
//Diseño
// import "./Elements/Diseño.css";
import "../../../css/estilo.css";
//Breadcrums
import Recorrido from "../../Tools/BreadCrumbs/Recorrido3";

//!Danny's imports
//Alert
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
//Card
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
// import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import ShareIcon from "@mui/icons-material/Share";
//grid
import Grid from "@material-ui/core/Grid";
import { FormattedMessage } from "react-intl";
import TXTField from "../../Tools/TextField/TextField";
import { useState } from "react";
//table
import StyledTableCell from "../../Tools/Table/StyledTableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VisibilityIcon from "@mui/icons-material/Visibility";
//Modal
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import { Divider } from "@mui/material";
//input
import Input from "@mui/material/Input";
//styledTableRow
import { styled } from "@mui/material/styles";
//Transition
import Slide from "@mui/material/Slide";
//Tooltip
import Tooltip from "@mui/material/Tooltip";
//*Form
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
//!Danny's imports

//TODO: StyledTableRow
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  //* hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

//TODO: Transition of dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export function ConfGene() {
  //TODO:Variables
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla
  //TODO: variables de modal
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  //TODO: Función para validar datos tabla.
  function createData(
    folio,
    poliza,
    fechaemision,
    pagadohasta,
    fechapago,
    acciones
  ) {
    return { folio, poliza, fechaemision, pagadohasta, fechapago, acciones };
  }
  const rows = [
    createData(
      "0002550",
      "8800001589694",
      "01/01/2019",
      "01/01/2020",
      "01/01/2019"
    ),
    createData(
      "0002550",
      "8800001589694",
      "05/08/2019",
      "05/08/2020",
      "05/08/2019"
    ),
    createData(
      "0002550",
      "8800001589694",
      "05/08/2019",
      "05/08/2020",
      "05/08/2019"
    ),
  ];
  //TODO:variable para table
  const [data] = useState(rows);

  return (
    //TODO: div general
    <div className="div-parent">
      {/* //TODO: warning  */}
      <Alert severity="warning">
        <AlertTitle>Warning</AlertTitle> <strong>Módulo en construcción</strong>
      </Alert>
      {/* //TODO: breadcrumbs */}
      <div className="div-breadcrumbs">
        <Recorrido
          name1="Inicio"
          enlace="/confadmin"
          name2="Negocio Configurable"
        />
      </div>
      <br />
      <br />
      <Card style={{ padding: 20 }}>
        <CardContent>
          <div
            style={{
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
            }}
          >
            {/* //TODO: Primer Grid  */}
            <Grid item xs={4}>
              {/* //TODO: Textfield buscar */}
              <h4-search>
                <FormattedMessage
                  id="glob.buscar"
                  defaultMessage="Ingresar número de folio"
                />
                :
              </h4-search>
              <TXTField
                showSearchIcon={true}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
              <br />
              <br />
              {/* //TODO: Carde de textos */}
              <Card>
                <CardHeader
                  title="Datos principales"
                  subheader={<FormattedMessage
                    id="glob.folio"
                    defaultMessage="Folio:"
                  />}
                />
                <CardContent>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableBody>
                        <TableRow>
                          <StyledTableCell>
                            <Typography variant="body2" color="text.secondary">
                              Nombre(s):
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Input disabled value={"Gerardo Daniel"} />
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>
                            <Typography variant="body2" color="text.secondary">
                              Apellido paterno:
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Input disabled value={"Velasco"} />
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>
                            <Typography variant="body2" color="text.secondary">
                              Apellido materno
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Input disabled value={"Pizaña"} />
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>
                            <Typography variant="body2" color="text.secondary">
                              Fecha de nacimiento:
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Input disabled value={"14/02/1994"} />
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>
                            <Typography variant="body2" color="text.secondary">
                              RFC:
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Input disabled value={"VEPG940214"} />
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>
                            <Typography variant="body2" color="text.secondary">
                              CURP:
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Input disabled value={"VEPG940214HMCLZR09"} />
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
            {/* //TODO: Grid de separacion */}
            <Grid>&nbsp;&nbsp;&nbsp;&nbsp;</Grid>
            {/* //TODO: Segundo Grid */}
            <Grid item xs={8}>
              <Card
                style={{
                  alignContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CardHeader
                  alignItems="center"
                  alignContent="center"
                  title="Registros"
                />
                <Paper sx={{ width: "100%" }}>
                  <TableContainer sx={{ maxHeight: 500 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">
                            <FormattedMessage
                              id="glob.folio"
                              defaultMessage="Folio"
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormattedMessage
                              id="glob.poliza"
                              defaultMessage="Póliza"
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            // onClick={() => sorting("nombreAsegurado")}
                          >
                            <FormattedMessage
                              id="glob.nomasegurado"
                              defaultMessage="Fecha de emisión"
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormattedMessage
                              id="recep.contratante"
                              defaultMessage="Pagada hasta"
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormattedMessage
                              id="recep.contratante"
                              defaultMessage="Fecha de pago"
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Acciones
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data
                          .filter((val) => {
                            if (searchTerm === "") {
                              return val;
                            } else if (
                              /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                              val.numPoliza
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                              val.nombreAsegurado
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            ) {
                              return val;
                            }
                          })
                          .slice
                          // page * rowsPerPage,
                          // page * rowsPerPage + rowsPerPage
                          ()
                          .map((row) => (
                            <StyledTableRow key={row.numPoliza}>
                              <StyledTableCell align="center">
                                {row.folio}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.poliza}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.fechaemision}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.pagadohasta}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.fechapago}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Tooltip
                                  describeChild
                                  title="Ver tabla de datos póliza V1"
                                >
                                  <IconButton
                                    variant="outlined"
                                    onClick={handleClickOpen("paper")}
                                  >
                                    <VisibilityIcon />{" "}
                                    <Typography>&nbsp;Ver datos</Typography>
                                  </IconButton>
                                </Tooltip>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Card>
            </Grid>
          </div>
        </CardContent>
      </Card>

      {/* //TODO: Dialog */}
      <div>
        <Dialog
          open={open}
          // onClose={handleClose}
          TransitionComponent={Transition}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            Tabla datos de la póliza V1.
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              {/* {[...new Array(500)].map(() => "Test table").join("\n")} */}
              {/* //TODO: TABLE V1  */}
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <div>
                  <TextField
                    id="outlined-helperText"
                    label="Número poliza"
                    helperText="Numero de poliza"
                    placeholder="TEST"
                  />
                  <TextField
                    id="outlined-helperText"
                    label="Número poliza"
                    helperText="Numero de poliza"
                    placeholder="TEST"
                  />
                  <TextField
                    id="outlined-helperText"
                    label="Número poliza"
                    helperText="Numero de poliza"
                    placeholder="TEST"
                  />
                  <TextField
                    id="outlined-helperText"
                    label="Número poliza"
                    helperText="Numero de poliza"
                    placeholder="TEST"
                  />
                  <TextField
                    id="outlined-helperText"
                    label="Número poliza"
                    helperText="Numero de poliza"
                    placeholder="TEST"
                  />
                  <TextField
                    id="outlined-helperText"
                    label="Número poliza"
                    helperText="Numero de poliza"
                    placeholder="TEST"
                  />
                  <TextField
                    id="outlined-helperText"
                    label="Número poliza"
                    helperText="Numero de poliza"
                    placeholder="TEST"
                  />
                  <TextField
                    id="outlined-helperText"
                    label="Número poliza"
                    helperText="Numero de poliza"
                    placeholder="TEST"
                  />
                  <TextField
                    id="outlined-helperText"
                    label="Número poliza"
                    helperText="Numero de poliza"
                    placeholder="TEST"
                  />
                  <TextField
                    id="outlined-helperText"
                    label="Número poliza"
                    helperText="Numero de poliza"
                    placeholder="TEST"
                  />
                  <TextField
                    id="outlined-helperText"
                    label="Número poliza"
                    helperText="Numero de poliza"
                    placeholder="TEST"
                  />
                  <TextField
                    id="outlined-helperText"
                    label="Número poliza"
                    helperText="Numero de poliza"
                    placeholder="TEST"
                  />
                  <TextField
                    id="outlined-helperText"
                    label="Número poliza"
                    helperText="Numero de poliza"
                    placeholder="TEST"
                  />
                  <TextField
                    id="outlined-helperText"
                    label="Número poliza"
                    helperText="Numero de poliza"
                    placeholder="TEST"
                  />
                </div>
              </Box>
              <CardContent>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableBody>
                      <TableRow>
                        <StyledTableCell>
                          <Typography variant="body2" color="text.secondary">
                            Numero poliza:
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            // required
                            // id="standard-required"
                            // label="Número Poliza"
                            // defaultValue="Hello World"
                            variant="standard"
                          />
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell>
                          <Typography variant="body2" color="text.secondary">
                            Apellido paterno:
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Input disabled value={"Velasco"} />
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell>
                          <Typography variant="body2" color="text.secondary">
                            Apellido materno
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Input disabled value={"Pizaña"} />
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell>
                          <Typography variant="body2" color="text.secondary">
                            Fecha de nacimiento:
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Input disabled value={"14/02/1994"} />
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell>
                          <Typography variant="body2" color="text.secondary">
                            RFC:
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Input disabled value={"VEPG940214"} />
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell>
                          <Typography variant="body2" color="text.secondary">
                            CURP:
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Input disabled value={"VEPG940214HMCLZR09"} />
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
              {/* //TODO: TABLE V1  */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Tooltip
              describeChild
              title="No se guardara ninguno de sus cambios"
            >
              <Button onClick={handleClose}>Cancelar</Button>
            </Tooltip>
            <Button onClick={handleClose}>Guardar</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
