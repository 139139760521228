//  David Ruiz 10/2022

import React from "react";
//icono
import IconButton from '@mui/material/IconButton';
import DifferenceIcon from '@mui/icons-material/Difference';
import {FormattedMessage} from 'react-intl';


function App(props) {
  return (
    <div>
      <nav>
     <IconButton aria-label="DifferenceIcon" color="icons" 
        //aqui va el enlace
        href={props.enlace}
        onClick={props.onClick}
        onChange={props.onChange}
        id={props.id}
        //ojo aqui es disabled
        disabled={props.estatus} //true para desabilitar y false para habilitar
        >
        < DifferenceIcon />
        <h3-medic-icons><FormattedMessage id="medicDoSin" defaultMessage="Documentación del Siniestro"/></h3-medic-icons>  
      </IconButton>
    </nav>
  
    </div>
  );
}

export default App;