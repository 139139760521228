import React from "react";
import { CompRegistroInicio } from "../../../components/Registro/CompRegistroInicio";
import MyComponent from "../../../components/Tools/ButtonLoading/ButtonLoading";

export function RegistroAsegurado() {
  return (
    <div>
      <MyComponent componentToRender={CompRegistroInicio} />
    </div>
  );
}
