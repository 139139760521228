import React from "react";
import { ConfGene } from '../../../../components/GeneralConf/NegocioConf/NegocioConfi';


export function nego() {
    return (
        <div>
    
            < ConfGene />
           
        </div>
    );
}