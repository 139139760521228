//  David Ruiz 10/2022

import React from 'react';
import IconButton from '@mui/material/IconButton';
import SsidChartIcon from '@mui/icons-material/SsidChart';

const BTReporteyet=(props)=>{
    return(
        <IconButton aria-label="SsidChartIcon" color="icons" 
        //aqui va el enlace
        href={props.enlace}
        onClick={props.onClick}
        onChange={props.onChange}
        id={props.id}
        //ojo aqui es disabled
        //true para desabilitar y false para habilitar
        disabled={props.estatus}>
        <SsidChartIcon/>
        </IconButton>
        );
    }
export default BTReporteyet;