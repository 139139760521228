import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "../dictamen.css";
import Grid from "@material-ui/core/Grid";
import TableDictamenPolizas from "../Elements/Tables/TableDictamenPolizas";
import TableExpedienteReclamacion from "./Tables/TableExpedienteReclamacion";
import CardDatosReclamacionFallecimiento from "./Cards/CardDatosReclamacionFallecimiento";
import CardDatosReclamacionBV from "./Cards/CardDatosReclamacionBV";
import CardConsultaMedic from "./Cards/CardConsultaMed";
import { getDataToken } from "../../../api/dataToken";
import {
  TRAM_BENEFICIOSEV,
  TRAM_FALLECIMIENTO,
} from "../../../utils/constanstoken";
//idioma
import { FormattedMessage } from "react-intl";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import CardDatosReclamacionRetiro from "./Cards/CardDatosReclamacionRetiro";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; //cards

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs(props) {
  const [value, setValue] = React.useState(0);
  const polizas = props.polizas;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let idFolio = props.idFolio;

  const [Data, setData] = React.useState([]);
  const getData = async () => {
    try {
      var result = await getDataToken(`recepcion/get_id/${idFolio}/`);
      setData(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  console.log(Data);

  const tipoTramite =
    Data?.SINC_idcomplejidadsiniestro?.TRA_idtipotramitesiniestro;

  const handleLoadError = (error) => {
    console.error(error);
  };
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <div className="div-dictamen-folioX">
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={2} style={{ position: "sticky", top: 0 }}>
          <div className="div-tabs-dictamen">
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              <Tab
                label={
                  <h3-subtitle-card>
                    <FormattedMessage
                      id="dictamDaDelaReclamac"
                      defaultMessage="Datos de la reclamación"
                    />
                  </h3-subtitle-card>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <h3-subtitle-card>
                    <FormattedMessage
                      id="dictamDictaDeLasPoli"
                      defaultMessage="Dictamen de las pólizas"
                    />
                  </h3-subtitle-card>
                }
                {...a11yProps(1)}
              />
              <Tab
                label={
                  <h3-subtitle-card>
                    <FormattedMessage
                      id="globConMed"
                      defaultMessage="Consulta de dictamen médico"
                    />
                  </h3-subtitle-card>
                }
                {...a11yProps(2)}
              />
              <Tab
                label={
                  <h3-subtitle-card>
                    <FormattedMessage
                      id="dictamExpedDelaRecla"
                      defaultMessage="Expediente de la reclamación"
                    />
                  </h3-subtitle-card>
                }
                {...a11yProps(3)}
              />
            </Tabs>
          </div>
        </Grid>

        <Grid item xs={10}>
          {/* Panel 1: DATOS DE LA RECLAMACIÓN */}
          <TabPanel value={value} index={0}>
            {tipoTramite === TRAM_FALLECIMIENTO.id ? (
              <CardDatosReclamacionFallecimiento
                polizas={polizas}
                idFolio={idFolio}
              />
            ) : tipoTramite === TRAM_BENEFICIOSEV.id ? (
              <CardDatosReclamacionBV polizas={polizas} idFolio={idFolio} />
            ) : (
              <CardDatosReclamacionRetiro polizas={polizas} idFolio={idFolio} />
            )}
          </TabPanel>
          {/* Panel 2: DICTAMEN DE LAS PÓLIZAS */}
          <TabPanel value={value} index={1}>
            <TableDictamenPolizas polizas={polizas} idFolio={idFolio} />
          </TabPanel>
          {/* Panel 3: CONSULTA DICTAMEN MÉDICO */}
          <TabPanel value={value} index={2}>
            <CardConsultaMedic polizas={polizas} idFolio={idFolio} />
          </TabPanel>
          {/* Panel 3: EXPEDIENTE DE LA RECLAMACIÓN*/}
          <TabPanel value={value} index={3} align="center">
            <TableExpedienteReclamacion polizas={polizas} idFolio={idFolio} />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
}
