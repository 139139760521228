import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import RadioInput from "../../Tools/RadioButton/RadioInput";
import TXTField from "../../Tools/TextField/TextField";
//paginación de la tabla
import TablePagination from "@mui/material/TablePagination";
//estilos de la tabla
import StyledTableCell from "../../Tools/Table/StyledTableCell";
import { FormattedMessage } from "react-intl";

export default function PolicyReg() {

  const [siniestro, setSiniestro] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = require("../data.json");
        console.log("Datos obtenidos:", response);
        setSiniestro(response);
      } catch (error) {
        console.error("Error al obtener el archivo JSON:", error);
      }      
    };

    fetchData();
  }, []); 
  

  /* CONSTANTES DE ESTADOS*/
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla
  const [InfoDataRow, setInfoDataRow] = useState(""); //Aqui es donde almacenará los datos de la fila seleccionadad
  const [btnDownloadInfo, setBtnDownloadInfo] = useState(true);
  const [btnDisable, setBtnDisable] = useState(true);

  /* FUNCIÓN CLICK EN LOS RADIO BUTTON, ARROJA INFORMACION DE LA FILA SELECCIONADA */
  // const rbClick = (e) => {
  //   const inputFolio = e.target.value;
  //   data.map((row) => {
  //     if (row.numFolio === inputFolio) {
  //       console.log(row);
  //       setInfoDataRow(row);
  //       setBtnDownloadInfo(false);
  //       setBtnDisable(false);
  //     }
  //   });
  // };

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

    //Constantes para variables de la fecha 
    const [filteredData, setFilteredData] = useState([]);
 
    function buscarSiniestro() {
      // Lógica para manejar el clic en el botón
      console.log('Buscar:', searchTerm);
  
      if(searchTerm != ""){
   
        //filtaremos los datos en las fechas seleccionadas 
        const filteredResults = siniestro.filter((item) => {
         const numSiniestro = (item.numSiniestro)//Constante que guardara solo los el Numero de cuenta
         console.log(numSiniestro)
         
          return searchTerm== numSiniestro;
        });
        setFilteredData(filteredResults);
        console.log(filteredResults);
        setBtnDisable(false)
      } else {
        console.log("Por favor, selecciona una fecha inicial y una fecha final.");
      }
    };
  return (
    <div>
      <Grid
        style={{ paddingBottom: "20px" }}
        container
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid xs={12}>
          <br />
          <Grid container justifyContent="center">
          <Grid item>
            <h3-header-card>
              <FormattedMessage id="estatus" defaultMessage="POR NÚMERO DE SINIESTRO "/>
            </h3-header-card>
          </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <h4-search>Buscar:</h4-search>
          <TXTField
            showSearchIcon={true}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            iconOnClick={() => buscarSiniestro()}
          />
        </Grid>
      </Grid>

      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* <StyledTableCell align="center"></StyledTableCell> */}
                <StyledTableCell> Número de folio </StyledTableCell>
                <StyledTableCell> Número de póliza </StyledTableCell>
                <StyledTableCell> Fecha de aviso  </StyledTableCell>
                <StyledTableCell> Fecha de ocurrencia </StyledTableCell>
                <StyledTableCell> Nombre del asegurado </StyledTableCell>
                <StyledTableCell> Clave oil </StyledTableCell>
                <StyledTableCell> Descripción de clave oil </StyledTableCell>
                <StyledTableCell> Estatus del siniestro </StyledTableCell>
                <StyledTableCell> Suma asegurada básica </StyledTableCell>
                <StyledTableCell> Moneda suma asegurada básica </StyledTableCell>
                <StyledTableCell> Moneda suma asegurada benedifio muerte accidental </StyledTableCell>
                <StyledTableCell> Tipo de reclamación </StyledTableCell>
                <StyledTableCell> Causa de siniestro </StyledTableCell>
                <StyledTableCell> Estatus de la póliza versión 1 </StyledTableCell>
                <StyledTableCell> Nombre de beneficiarios </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {filteredData                
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <TableRow key={item.numFolio}>
                    {/* <StyledTableCell component="th" scope="row" align="center">
                      <RadioInput
                        name="rb"
                        value={row.numFolio}
                        onChange={rbClick}
                      />
                    </StyledTableCell> */}
                    <StyledTableCell align="center"> {item.numFolio} </StyledTableCell>
                    <StyledTableCell align="center"> {item.NumPoliza} </StyledTableCell>
                    <StyledTableCell align="center"> {item.fechaAviso} </StyledTableCell>
                    <StyledTableCell align="center"> {item.fechaOcurrencia} </StyledTableCell>
                    <StyledTableCell align="center"> {item.nombreAsegurado} </StyledTableCell>
                    <StyledTableCell align="center"> {item.claveOil} </StyledTableCell>
                    <StyledTableCell align="center"> {item.descripcionOil} </StyledTableCell>
                    <StyledTableCell align="center"> {item.estatusSiniestro} </StyledTableCell>
                    <StyledTableCell align="center"> {item.sumaAseguradaBasica} </StyledTableCell>
                    <StyledTableCell align="center"> {item.monedaSumaAseguradaBasica} </StyledTableCell>
                    <StyledTableCell align="center"> {item.monedaSumaAseguradaBeneficioMuerteAccidental} </StyledTableCell>
                    <StyledTableCell align="center"> {item.tipoReclamacion} </StyledTableCell>
                    <StyledTableCell align="center"> {item.causaSiniestro} </StyledTableCell>
                    <StyledTableCell align="center"> {item.estatusPoliza1} </StyledTableCell>
                    <StyledTableCell align="center"> {item.nombreBeneficiarios} </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelRowsPerPage="Filas por página"
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
