import React from "react";
import { ConfSecurity } from '../../../../components/GeneralConf/Security/Security';
import NavUsuario from "../../../../components/Tools/Header/NavUsuario";
import  Footer  from "../../../../components/Tools/Footer/Footer";

export function secu() {
    return (
        <div>
           
            < ConfSecurity />
           
        </div>
    );
}