import React from "react";
import img_recepcion from "../../img/menu/Recepcion.svg";
import img_recepcion_on from "../../img/menu/Recepcion_on.svg";
import img_registro from "../../img/menu/Registro.svg";
import img_registro_on from "../../img/menu/Registro_on.svg";
import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { getUsers, putDataLogin } from "../Login/apiLogin/getUsers";
import { BASE_API } from "../../utils/constans";
import DialogUpdate from "./Elements/DialogUpdate";
//Alerta
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
//idioma
import { FormattedMessage } from "react-intl";
// import { Height } from "@mui/icons-material";
import { useAuth } from "../../../src/hooks/useAuth";

export function CompMenuPrincipal() {
  //Alerta function
  // const [showAlert, setShowAlert] = useState(false);
  const [visible, setVisible] = React.useState(false);
  const vertical = "top";
  const horizontal = "right";
  const [open, setOpen] = React.useState(false);

  const { auth, logout } = useAuth();

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  //href para medico
  const clickVidaInd = () => {
    window.location = "/menu";
  };
  //href para Estadistico Contable
  const clickVidaGru = () => {
    setOpen(true);
  };

  //Cambiar imagenes on a off y veceversa
  const [imgVidaInd, setImgVidaInd] = useState(false);
  const [imgVidaGru, setImgVidaGru] = useState(false);

  //Conteo de meses para actualizar contraseña

  const [user, setUser] = React.useState();
  const [title, setTitle] = React.useState("");
  const [subtitle, setSubtitle] = React.useState("");
  const [subsubtitle, setSubSubtitle] = React.useState("");
  const [today, setToday] = React.useState(new Date());
  const [lastDate, setLastDate] = React.useState(null);
  const [link, setLink] = React.useState();
  const [onclink, setonclick] = React.useState();
  const [valid, setvalid] = React.useState(true);
  let url = {};
  let click = {};
  let msgtitle = {};
  let msgsubtitle = {};
  let msgsubsubtitle = {};

  var storageUser = localStorage.getItem("SNYTLOG_email");
  console.log(storageUser);

  React.useEffect(() => {
    // Obtener la última fecha de la base de datos
    getUsers(`${BASE_API}login/get_correo/${storageUser}/`)
      .then((json) => {
        setLastDate(new Date(json.SNYTLOG_actualizofecha));
        setUser(json.SNYTLOG_email);
        console.log(setLastDate);
        //console.log(setUser);
        //console.log(json.SNYTLOG_actualizofecha);
      })
      .catch((error) => {
        console.error("There was an error:", error);
      });
  }, []);

  //PUT Cuenta bloqueada
  async function updateStaff(aux) {
    try {
      var result = await putDataLogin(`login/putall/${user}/`, aux);
      console.log("Actualizado correctamente", result);
    } catch (error) {
      console.log(error);
      setvalid(false);
      console.log(valid);
      throw error;
    }
  }

  React.useEffect(() => {
    if (lastDate) {
      // Calcula el tiempo transcurrido desde la última fecha
      const diffTime = Math.abs(today - lastDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      // console.log(diffTime);
      console.log(diffDays);
      // console.log(diffMonths);

      if (diffDays === 75) {
        //alert("Faltan 15 días");
        setVisible(true);
        msgtitle = (
          <FormattedMessage
            id="loginActCo"
            defaultMessage="Actualizar contraseña"
          />
        );
        msgsubtitle = (
          <FormattedMessage
            id="accQn"
            defaultMessage="Tu acceso al sistema expira en 15 días."
          />
        );
        msgsubsubtitle = (
          <FormattedMessage
            id="logActAh"
            defaultMessage="¿Deseas actualizar tu contraseña ahora?"
          />
        );
        url = "/Actualizar-Contra";
        setLink(url);
      } else if (diffDays === 80) {
        //alert("Faltan 10 días");
        setVisible(true);
        msgtitle = (
          <FormattedMessage
            id="loginActCo"
            defaultMessage="Actualizar contraseña"
          />
        );
        msgsubtitle = (
          <FormattedMessage
            id="accDz"
            defaultMessage="Tu acceso al sistema expira en 10 días."
          />
        );
        msgsubsubtitle = (
          <FormattedMessage
            id="logActAh"
            defaultMessage="¿Deseas actualizar tu contraseña ahora?"
          />
        );
        url = "/Actualizar-Contra";
        setLink(url);
      } else if (diffDays === 85) {
        //alert("Faltan 5 días");
        setVisible(true);
        msgtitle = (
          <FormattedMessage
            id="loginActCo"
            defaultMessage="Actualizar contraseña"
          />
        );
        msgsubtitle = (
          <FormattedMessage
            id="accCnc"
            defaultMessage="Tu acceso al sistema expira en 5 días."
          />
        );
        msgsubsubtitle = (
          <FormattedMessage
            id="logActAh"
            defaultMessage="¿Deseas actualizar tu contraseña ahora?"
          />
        );
        url = "/Actualizar-Contra";
        setLink(url);
      } else if (diffDays === 89) {
        //alert("Mañana es tu ultimo dia para actualizar la contraseña");
        setVisible(true);
        msgtitle = (
          <FormattedMessage
            id="loginActCo"
            defaultMessage="Actualizar contraseña"
          />
        );
        msgsubtitle = (
          <FormattedMessage
            id="accUlt"
            defaultMessage="Mañana es tu último día para actualizar la contraseña."
          />
        );
        msgsubsubtitle = (
          <FormattedMessage
            id="logActAh"
            defaultMessage="¿Deseas actualizar tu contraseña ahora?"
          />
        );
        url = "/Actualizar-Contra";
        setLink(url);
      } else if (diffDays >= 91) {
        setVisible(true);
        click = logout;
        setonclick(click);
        console.log("Cuenta bloqueada");
        let aux = {
          SNYTLOG_email: user,
          is_staff: false,
          SNYTLOG_estatus: false,
        };
        updateStaff(aux);
        if (valid === false) {
          msgtitle = (
            <FormattedMessage id="fueServ" defaultMessage="Fuera de servicio" />
          );
          msgsubtitle = (
            <FormattedMessage
              id="pentTar"
              defaultMessage="No es posible realizar su petición en este momento, intente más tarde."
            />
          );
          msgsubsubtitle = "";
        } else {
          msgtitle = (
            <FormattedMessage
              id="globCuenta"
              defaultMessage="Cuenta bloqueada"
            />
          );
          msgsubtitle = (
            <FormattedMessage
              id="notSup"
              defaultMessage="Notifica a tu supervisor para solicitar el desbloqueo de tu cuenta."
            />
          );
          msgsubsubtitle = "";
        }
      }
      setTitle(msgtitle);
      setSubtitle(msgsubtitle);
      setSubSubtitle(msgsubsubtitle);
    }
  }, [today, lastDate]);

  return (
    <>
      {visible && (
        <DialogUpdate
          title={title}
          firstButton={
            <FormattedMessage id="globCance" defaultMessage="Cancelar" />
          }
          secondButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description={subtitle}
          description2={subsubtitle}
          state={user}
          to={link}
          onClick={onclink}
          onclick1={onclink}
        />
      )}
      <Stack spacing={2}>
        <Snackbar
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleClose} severity="warning">
            Módulo en construcción
          </Alert>
        </Snackbar>
      </Stack>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        id="div-parent"
      >
        {/* MENÚ VIDA INDIVIDUAL */}
        <Grid
          item
          xs={3}
          className="div-children-menu"
          onClick={clickVidaInd}
          onMouseEnter={() => {
            setImgVidaInd(true);
          }}
          onMouseLeave={() => {
            setImgVidaInd(false);
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <center>
                <Grid xs={12}>
                  {!imgVidaInd ? (
                    <img
                      className="img-iconos"
                      src={img_recepcion}
                      alt="Vida Individual"
                    />
                  ) : (
                    <img
                      className="img-iconos"
                      src={img_recepcion_on}
                      alt="Vida Individual"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label>
                    <FormattedMessage
                      id="gMenuVidaInd"
                      defaultMessage="Vida Individual"
                    />
                  </label>
                </Grid>
              </center>
            </Grid>
          </Grid>
        </Grid>
        {/* MENU VIDA GRUPO */}
        <Grid
          item
          xs={3}
          className="div-children-menu"
          onClick={clickVidaGru}
          onMouseEnter={() => {
            setImgVidaGru(true);
          }}
          onMouseLeave={() => {
            setImgVidaGru(false);
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <center>
                <Grid xs={12}>
                  {!imgVidaGru ? (
                    <img
                      className="img-iconos"
                      src={img_registro}
                      alt="Vida Grupo"
                    />
                  ) : (
                    <img
                      className="img-iconos"
                      src={img_registro_on}
                      alt="Vida Grupo"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label>
                    <FormattedMessage
                      id="gMenuVidaGru"
                      defaultMessage="Vida Grupo"
                    />
                  </label>
                </Grid>
              </center>
            </Grid>
          </Grid>
        </Grid>
        {/* FIN DE MENUS */}
      </Grid>
    </>
  );
}
