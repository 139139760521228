export default function RadioInput (props) {

    return (
        // Este input puede utilizarse en casos en los que no se requiera un RadioGruop
        // recibiendo como props el name, value, un evento
        <input 
            type="radio" 
            name={props.name} 
            value={props.value} 
            onChange={props.onChange} 
            style={{ accentColor:'#761F2A', width:'20px' , height:'20px' }}
        />
    )
}