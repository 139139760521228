/* 
David Ruiz
Proyecto SINAY 
06/12/2022
*/

//Grid
import { Grid } from "@mui/material";
import DialogCascadeTwo from "../../Tools/Dialog/DialogCascadeTwo";

import React, { useState, useEffect } from "react";

//navegador
import Recorrido from "../../Tools/BreadCrumbs/Recorrido";

//RETURN
import ReturnPage from "../../Tools/Bottones/ButtonReturn";

//button
import ButtonVino from "../../Tools/Bottones/ButtonVino";

//select
// import { Select1 } from '../../Tools/Selectss/Select1';

// token

import { getToken } from "../../../api/token";
import { BASE_API } from "../../../utils/constans";

//css
import "../../../css/estilo.css";
import "./mail.css";

//idioma
import { FormattedMessage } from "react-intl";

//radio
import Check2 from "../../Tools/CheckBox/Check2";

//texfield
import TextField from "../../Tools/TextField/TextField";

// elements

//import Prn1 from './Elements/Prn1';

export default function MailEnt() {
  const toogleSwitch = (e) => {
    if (e.target.checked) {
      setIniciosesionSi(true);
    } else {
      setIniciosesionSi(false);
    }
  };
  
  //Funcion que muestra formulario si el iniciosesion realiza el tramite
  const [iniciosesionSi, setIniciosesionSi] = useState(false);

  ///VALORES
  const [values, setValues] = React.useState({
    EMLNT_saludo: "",
    EMLNT_presentacion: "",
    EMLNT_despedida: "",
    EMLNT_firma: "",
    EMLNT_imagen5: "",

    EMLNT_email: "",
    EMLNT_PORT: "",
    EMLNT_TLS: "",
    EMLNT_HOST: "",
    EMLNT_HOST_USER: "",
    EMLNT_HOST_PASSWORD: "",
    EMLNT_USE_SSL: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const token = getToken();

  const handleFetchData = async () => {
    const response = await fetch(`${BASE_API}notificador/conf-mail/1/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    // console.log(data)
    setValues(data);
  };

  useEffect(() => {
    handleFetchData();
    console.log(values);
  }, []);

  //    para cambiar el estado de los switch y compar con el numero de grupo
  // useEffect(() => {
  //   if(selectdata !== ""){

  // //correo
  // valordef.isviewemail=(selectdata.groups.includes(23))
  // valordef.isaddemail=(selectdata.groups.includes(24))
  // valordef.ischangeemail=(selectdata.groups.includes(25))
  // //confgeneral
  // valordef.isconfgenview =(selectdata.groups.includes(21))
  // valordef.isconfgenadd=(selectdata.groups.includes(20))
  // valordef.isconfgenchan=(selectdata.groups.includes(22))

  // }
  // },[selectdata])

  const validateAndSubmitForm = (e) => {
    e.preventDefault();

    fetch(`${BASE_API}notificador/mail/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        EMLNT_email: values.EMLNT_email,
        EMLNT_asunto: "Asunto",
        EMLNT_texto: "texto(prueba)",
        EMLNT_texto2: "texto2(prueba)",
        EMLNT_texto3: "texto3(prueba)",
        EMLNT_texto4: "texto4(prueba)",
        EMLNT_titulo: "titulo del mensaje (prueba)",

        EMLNT_saludo: values.EMLNT_saludo,
        EMLNT_presentacion: values.EMLNT_presentacion,
        EMLNT_despedida: values.EMLNT_despedida,
        EMLNT_firma: values.EMLNT_firma,

        EMLNT_PORT: values.EMLNT_PORT,
        EMLNT_TLS: values.EMLNT_TLS,
        EMLNT_HOST: values.EMLNT_HOST,
        EMLNT_HOST_USER: values.EMLNT_HOST_USER,
        EMLNT_HOST_PASSWORD: values.EMLNT_HOST_PASSWORD,
      }),
    });
  };

  const handleviewTLS = (event) => {
    values.EMLNT_TLS = event.target.checked;
  };

  function refreshPage() {
    window.location.reload(false);
  }

  const posttablaapis = (e) => {
    e.preventDefault();

    if (values.EMLNT_HOST == undefined) {
      console.log("entron al post de las apis");
      fetch(`${BASE_API}notificador/conf-mail/get_listado/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          EMLNT_PORT: values.EMLNT_PORT,
          EMLNT_TLS: values.EMLNT_TLS,
          EMLNT_HOST: values.EMLNT_HOST,
          EMLNT_HOST_USER: values.EMLNT_HOST_USER,
          EMLNT_HOST_PASSWORD: values.EMLNT_HOST_PASSWORD,
          EMLNT_USE_SSL: values.EMLNT_USE_SSL,
        }),
      });
    } else {
      console.log("entron al PUT apis conectadas");
      fetch(`${BASE_API}notificador/conf-mail/1/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          EMLNT_PORT: values.EMLNT_PORT,
          EMLNT_TLS: values.EMLNT_TLS,
          EMLNT_HOST: values.EMLNT_HOST,
          EMLNT_HOST_USER: values.EMLNT_HOST_USER,
          EMLNT_HOST_PASSWORD: values.EMLNT_HOST_PASSWORD,
          EMLNT_USE_SSL: values.EMLNT_USE_SSL,
        }),
      });
      refreshPage();
    }
  };

  return (
    <div className="div-parent">
      {/* <div className='div-breadcrumbs'>
      <Recorrido name1={<FormattedMessage id="globInicio" defaultMessage="Inicio"/>}
       enlace="/confadmin" name2="Configuración Correo Salida" />
    </div> */}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <ReturnPage href="/confadmin" />
      </Grid>
      <div className="div-container">
        <div className="par">
          <div className="di1">
            <h3-header-card>
              <FormattedMessage
                id="conGSerConf"
                defaultMessage="Servidor Configuración"
              />
            </h3-header-card>
          </div>
          <div className="di2">
            <TextField
              type="text"
              label="Host Server"
              size="small"
              required={true}
              value={values.EMLNT_HOST}
              onChange={
                handleChange("EMLNT_HOST")
                // setValues(event.target.value)
              }
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="di3">
            <TextField
              type="text"
              label="Port"
              size="small"
              required={true}
              value={values.EMLNT_PORT}
              onChange={
                handleChange("EMLNT_PORT")
                // setValues(event.target.value)
              }
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="di4">
            <TextField
              type="text"
              label="Host User"
              size="small"
              required={true}
              value={values.EMLNT_HOST_USER}
              onChange={
                handleChange("EMLNT_HOST_USER")
                // setValues(event.target.value)
              }
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="di5">
            <TextField
              type="text"
              label="Host Password"
              size="small"
              required={true}
              value={values.EMLNT_HOST_PASSWORD}
              onChange={
                handleChange("EMLNT_HOST_PASSWORD")
                // setValues(event.target.value)
              }
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="di6">
            <br />
            <h3-subtitle-card>TLS</h3-subtitle-card>
            <Check2
              defaultChecked={values.EMLNT_TLS}
              onChange={(e) => handleviewTLS(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
          </div>
          <div className="di7">
            <ButtonVino
              label={
                <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
              }
              enlace="/confadmin"
              onClick={posttablaapis}
              estatus={
                values.EMLNT_HOST.length > 0 &&
                values.EMLNT_HOST_PASSWORD.length > 0 &&
                values.EMLNT_HOST_USER.length > 0
                  ? false
                  : true
              }
            />
          </div>
          <div className="di8">
            <ButtonVino
              label={
                <FormattedMessage id="globCance" defaultMessage="Cancelar" />
              }
              enlace="/confadmin"
            />
          </div>
          <div className="di9">
            <DialogCascadeTwo
              onClick={validateAndSubmitForm}
              secondButton={
                <FormattedMessage id="globCance" defaultMessage="Cancelar" />
              }
              childrenTitle={
                <FormattedMessage id="globEnviado" defaultMessage="Enviado" />
              }
              childrenDescription={
                <FormattedMessage
                  id="conGEnvSat"
                  defaultMessage="Enviado Satisfactoriamente"
                />
              }
              title={
                <FormattedMessage
                  id="conGPeCorre"
                  defaultMessage="Proporcione el Correo para mandar la prueba"
                />
              }
              description={
                <TextField
                  type="text"
                  label="Email"
                  size="small"
                  required={true}
                  value={values.EMLNT_email}
                  onChange={handleChange("EMLNT_email")}
                />
              }
              mainButton={
                <FormattedMessage id="globProba" defaultMessage="Probar" />
              }
              firstChildrenButton={
                <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
              }
              childrenButton={
                <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
              }
              secondChildrenButton={
                <FormattedMessage id="globCance" defaultMessage="Cancelar" />
              }
              /*
            disabledbtn={
                values?.EMLNT_HOST?.length > 0 &&
                values.EMLNT_HOST_PASSWORD.length > 0 &&
                values.EMLNT_HOST_USER.length > 0 &&
                values.EMLNT_despedida.length > 0 &&
                values.EMLNT_firma.length > 0 &&
                values.EMLNT_saludo.length > 0 &&
                values.EMLNT_presentacion.length > 0
                  ? false
                  : true
              }            
            */
            />
          </div>
        </div>
      </div>
    </div>
  );
}
