import * as React from "react";
import PropTypes from "prop-types";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@material-ui/core/Grid";
import Btverreport from "../../../Tools/Bottones/BTAgregarDocumento";
import TXTField from "../../../Tools/TextField/TextField";
//idioma
import { FormattedMessage } from "react-intl";
import { getDataToken, postDataToken } from "../../../../api/dataToken";
import {
  LimitarDigitos,
  LimitarTexto,
  LimitarTexto2,
} from "../../../Recepcion/Elements/Functions/Validaciones";
import getDate from "../../../Registro/Function/obtenerFecha";
import getHours from "../../../Registro/Function/obtenerHora";
import moment from "moment";
import { formatofecha } from "../../../../api/getUmbrales";
import DialogValidaciones from "../../../Registro/Elements/Dialog/DialogValidaciones";
import { Select1 } from "../../../Tools/Selectss/Select1";
import { PERR_OTRO } from "../../../../utils/constanstoken";

//Solo aparesera un alerta con un solo boton
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null} */}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogCartaAgregarOtros(props) {
  const informacion = props.informacion;
  const polizas = props.polizas;
  console.log(informacion);
  console.log(polizas);
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [asentamiento, setAsentamiento] = React.useState([]);

  //FUNCION PARA RESETEAR EL FORMULARIO AL CANCELAR O SALIR DEL DIÁLOGO
  // Obtenemos una referencia al formulario
  const formRef = React.useRef(null);

  // Creamos una función para resetear el formulario
  const resetForm = () => {
    formRef.current.reset();
  };

  const handleClickOpen = () => {
    setOpen(true);
    agregarAgente();
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen(false);
    resetForm();
    setvisible(false);
    props.ejecutarConsulta();
  };

  const agregarAgente = () => {
    setTitle("Agregar");
  };

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");

  console.log(moment(fechaEmpAn).format(formatofecha));
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();

  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;

  //Obtener usuario.
  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");
  const getUsers = async () => {
    try {
      var result = await getDataToken(`login/get_correo/${email}/`);
      setnombreAnalista(
        result.SNYTLOG_username +
          " " +
          result.SNYTLOG_surnamedad +
          " " +
          result.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getUsers();
  }, []);

  //////////////////////////////
  //   Funciones para cod postal
  ////////////////////////////

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (/[^0-9]/.test(keyValue)) {
      event.preventDefault();
    }
  };

  //Estados utilizados para sepomex
  const [SEP_Id, setSEP_Id] = React.useState("");
  const [SEP_municipio, setSEP_municipio] = React.useState("");
  const [SEP_colonia, setSEP_colonia] = React.useState("");
  const [codpostal, setCodPostal] = React.useState("");
  const [colonias, setColonias] = React.useState([]);
  const [dataSelectColonias, setDataSelectColonias] = React.useState([]);

  //Traer los datos de CP para los dos formatos de cp

  const getCodP = async (cp) => {
    try {
      var result = await getDataToken(`sepomex/get_listado_cp/${cp}`);
      setColonias(result);
      if (result <= 0) {
        setDataSelectColonias([]);
        setSEP_municipio("");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  //Funcionalidad del formulario CP

  const handleSubmit = () => {
    if (codpostal === "") {
      document.getElementById("txtCP2").focus();
    } else {
      getCodP(codpostal);
    }
  };

  const getInfoasenta = (e) => {
    const dataColonia = parseInt(e.target.value);
    colonias.forEach((element) => {
      if (dataColonia === element.id) {
        setSEP_Id(element.id);
        setSEP_municipio(element.SEP_municipio);
        setSEP_colonia(element.SEP_asenta);
      }
    });
  };

  //Esta función useEffect se activará cuando haya cambios en el estado 'colonias', activando a su ves otro estado
  React.useEffect(() => {
    if (colonias.length > 0) {
      let dataColonias = colonias;
      dataColonias.map(
        (cp) => (
          (cp.texto = cp.SEP_asenta), // cp.texto es una propiedad que no existe dentro del JSON, por tanto, se crea
          (cp.identificador = cp.id)
        )
      ); //Se actualiza el valor del dataSelectColonias
      setDataSelectColonias(dataColonias);
    }
  }, [colonias]);

  console.log("Id sepomex " + SEP_Id);
  console.log(dataSelectColonias);

  ///////////////////////////////////////////////
  const [values, setValues] = React.useState({
    PER_nombre: "",
    PER_apellidopaterno: "",
    PER_apellidomaterno: "",
    PER_RFC: "",
    PER_correo: "",
    PER_calle: "",
    PER_numeroexterior: "",
    PER_numerointerior: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const validateAndSubmitForm = async (e) => {
    e.preventDefault();
    let idPersona;
    let infoCartas = [];
    setvisible(true);

    let json = {
      PER_nombre: values.PER_nombre,
      PER_apellidopaterno: values.PER_apellidopaterno,
      PER_apellidomaterno: values.PER_apellidomaterno,
      PER_RFC: values.PER_RFC,
      PER_correo: values.PER_correo,
      PER_calle: values.PER_calle,
      PER_numeroexterior: values.PER_numeroexterior,
      PER_numerointerior: values.PER_numerointerior,
      SEP_idsepomex: SEP_Id,
      PERR_idrol: PERR_OTRO.id,
      PER_actualizofecha: fechaActSIN,
      PER_actualizousuario: nombreAnalista,
    };

    try {
      const data = await postDataToken(`personas/get_listado/`, json);
      console.log(`Registro exitoso personas`, data);
      idPersona = data.PER_idpersona;

      if (idPersona) {
        for (const personasxpoliza of polizas) {
          const json2 = {
            PER_idpersona: idPersona,
            POL_idpoliza: personasxpoliza.idPoliza,
            PXP_actualizofecha: fechaActSIN,
            PXP_actualizousuario: nombreAnalista,
          };

          const data2 = await postDataToken(
            `personasxpoliza/get_listado/`,
            json2
          );
          console.log(`Registro exitoso personasxpolizas`, data2);

          const json3 = {
            RECT_idfolio: informacion[0]?.RECT_idfolio?.RECT_idfolio,
            POL_idpoliza: personasxpoliza.idPoliza,
            LTXPOL_actualizofecha: fechaActSIN,
            LTXPOL_actualizousuario: nombreAnalista,
          };

          const data3 = await postDataToken(
            `cartapolizatramite/get_listado/`,
            json3
          );
          console.log(`Registro exitoso cartapolizatramite`, data3);
          infoCartas.push(data3);
        }
      }

      if (infoCartas.length === polizas.length) {
        for (const destinatarios of infoCartas) {
          const json4 = {
            PER_idpersona: idPersona,
            LTXPOL_idcartaxpoliza: destinatarios.LTXPOL_idcartaxpoliza,
            DESXLTR0_actualizofecha: fechaActSIN,
            DESXLTR0_actualizousuario: nombreAnalista,
          };

          const data4 = await postDataToken(
            `destinatarioxcarta/get_listado/`,
            json4
          );
          console.log(`Registro exitoso destinatarioxcarta`, data4);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }

    values.PER_nombre = "";
    values.PER_apellidopaterno = "";
    values.PER_apellidomaterno = "";
    values.PER_RFC = "";
    values.PER_correo = "";
    values.PER_calle = "";
    values.PER_numeroexterior = "";
    values.PER_numerointerior = "";
    setSEP_municipio("");
  };

  const [visible, setvisible] = React.useState(false);

  return (
    <div>
      {visible && (
        <DialogValidaciones
          title="Registro exitoso"
          secondButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description="Su registro se agrego correctamente."
          onClick={handleClose2}
        />
      )}
      {/*BOTON QUE HACE EL LLAMADO AL DIALOGO*/}
      {/*Este boton aparecera el js en donde se necesite de la alerta*/}
      <IconButton onClick={handleClickOpen}>
        <h2-dialog-description>
          {" "}
          <Btverreport />
          {
            <FormattedMessage
              id="glob.agregarBeneficiarios"
              defaultMessage="Agregar otros"
            />
          }
        </h2-dialog-description>
      </IconButton>

      {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
      <BootstrapDialog
        // onClose={handleClose}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="dialog"
        open={open}
      >
        {/*CUERPO DEL DIALOGO*/}
        {/*TITILO*/}
        <BootstrapDialogTitle
          id="dialog"
          onClose={handleClose}
          style={{ color: "primary" }}
        >
          <h1-dialog-title>{title}</h1-dialog-title>
        </BootstrapDialogTitle>
        {/*CUERPO DEL DIALOGO*/}
        {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
        <form ref={formRef} onSubmit={validateAndSubmitForm}>
          <DialogContent dividers style={{ minWidth: 500 }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item xs={12}>
                <TXTField
                  value={values?.PER_nombre}
                  label={"Nombre"}
                  onChange={handleChange("PER_nombre")}
                  required
                  onInput={(e) => LimitarTexto(e.target, 50)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  label={"Apellido paterno"}
                  onChange={handleChange("PER_apellidopaterno")}
                  value={values?.PER_apellidopaterno}
                  required
                  onInput={(e) => LimitarTexto(e.target, 50)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  label={"Apellido materno"}
                  onChange={handleChange("PER_apellidomaterno")}
                  value={values?.PER_apellidomaterno}
                  required
                  onInput={(e) => LimitarTexto(e.target, 50)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  label={"RFC"}
                  onChange={handleChange("PER_RFC")}
                  value={values?.PER_RFC}
                  onInput={(e) => LimitarDigitos(e.target, 13)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  value={values?.PER_calle}
                  label={"Calle"}
                  onChange={handleChange("PER_calle")}
                  required
                  onInput={(e) => LimitarTexto(e.target, 100)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  value={values?.PER_numeroexterior}
                  label={"No. Ext."}
                  onChange={handleChange("PER_numeroexterior")}
                  required
                  onInput={(e) => LimitarDigitos(e.target, 5)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  value={values?.PER_numerointerior}
                  label={"No. Int."}
                  onChange={handleChange("PER_numerointerior")}
                  required
                  onInput={(e) => LimitarDigitos(e.target, 5)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  label={
                    <FormattedMessage
                      id="globCodPos"
                      defaultMessage="Código postal"
                    />
                  }
                  value={codpostal}
                  name="txtCP2"
                  id="txtCP2"
                  onChange={(event) => setCodPostal(event.target.value)}
                  onKeyPress={handleKeyPress}
                  onPaste={(e) => e.preventDefault()}
                  onInput={(e) => LimitarDigitos(e.target, 6)}
                  showSearchIcon={true}
                  iconOnClick={handleSubmit}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Select1
                  sx={{
                    minWidth: 550,
                    maxWidth: 550,
                    height: 50,
                    background: "white",
                  }}
                  label={
                    <FormattedMessage
                      id="globColoni"
                      defaultMessage={`Colonia`}
                    />
                  }
                  array={
                    dataSelectColonias.length > 0
                      ? dataSelectColonias
                      : asentamiento
                  }
                  onChange={getInfoasenta}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  label={
                    <FormattedMessage
                      id="globmunicipioAlcadia"
                      defaultMessage="municipio o Alcaldia"
                    />
                  }
                  value={SEP_municipio}
                  onChange={handleChange("SEP_municipio")}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  label={"Correo Electrónico"}
                  onChange={handleChange("PER_correo")}
                  value={values?.PER_correo}
                  required
                  onInput={(e) => LimitarTexto2(e.target, 100)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          {/*es la tercer sección que engloba los botones */}
          <DialogActions>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
              marginTop={1}
            >
              <Grid item>
                <ButtonVino
                  variant="contained"
                  label="Guardar"
                  type="submit"
                  estatus={
                    values.PER_nombre.length > 0 &&
                    values.PER_apellidopaterno.length > 0 &&
                    values.PER_apellidomaterno.length > 0 &&
                    values.PER_calle.length > 0 &&
                    values.PER_numeroexterior.length > 0 &&
                    values.PER_numerointerior.length > 0 &&
                    codpostal.length > 0 &&
                    values.PER_correo.length > 0
                      ? false
                      : true
                  }
                />
              </Grid>
              <Grid item>
                <ButtonVino
                  variant="contained"
                  label="Cancelar"
                  onClick={handleClose}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}
