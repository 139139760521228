import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import {
  getDataToken,
  postDataToken,
  putDataToken,
} from "../../../api/dataToken";
import { generatePDFWithFetch } from "../../../api/reportes";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "../../Tools/Table/StyledTableCell";
import { FormattedMessage } from "react-intl";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TablePerdida from "./TablePerdida";
import DialogPerdida from "./Dialog/DialogPedida";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import TableContainer from "@mui/material/TableContainer";
import IconButton from "@mui/material/IconButton";
import Check2 from "../../Tools/CheckBox/Check2";
import SaveIcon from "@mui/icons-material/Save";
import { Tooltip } from "@mui/material";
import getDate from "../Function/obtenerFecha";
import getHours from "../Function/obtenerHora";
import moment from "moment-timezone";
import DialogValidaciones from "./Dialog/DialogValidaciones";
import CircularProgress from "@mui/material/CircularProgress";
import {
  COBERPERORG,
  PERR_BENEFICIARIO,
  TRAM_FALLECIMIENTO,
  IDCRXTCNCB,
  PERR_AGENTE,
  IDESTXPRE,
  IDENVCARTA,
  IDVALCOB,
  IDMODREGISTRO,
} from "../../../utils/constanstoken";
import { URLAPIIMG } from "../../../utils/constans";
import { uploadCartasToNextcloud } from "../../../nextcloud/functions";
//Dialogos
import DialogRechazo from "./Dialog/DialogRechazo";
import TXTField from "../../Tools/TextField/TextField";
import GetTareas from "../../../api/getTareas";

export default function TableDataCoverage(props) {
  //Variables para traer los datos
  const idpoliza = props?.id;
  console.log("paso idpoliza" + idpoliza);

  const numpoliza = props?.poliza;
  console.log("paso numpoliza" + numpoliza);

  const NoFolio = props?.info?.RECT_idfolio?.RECT_numerofolio;
  console.log("paso NoFolio" + NoFolio);

  const tramite =
    props?.info?.RECT_idfolio?.SINC_idcomplejidadsiniestro
      ?.TRA_destipotramitesiniestro;
  console.log("paso tramite" + tramite);

  const [infopersonal, setinfopersonal] = useState(props?.info);
  console.log(infopersonal);

  const [idFolioprops, setidFolioprops] = useState(
    props?.info?.RECT_idfolio?.RECT_idfolio
  );
  console.log(idFolioprops);

  //Obtenemos las tareas para nuestro módulo
  const objetosCoincidentes = GetTareas(IDMODREGISTRO);
  console.log(objetosCoincidentes);

  //Cerrar Dialog a tráves de variables props
  const [close, setclose] = useState("false");

  //Obtener props del componente de las coberturas
  const obtenerValorPerdida = (valor) => {
    // Aquí puedes usar el valor recibido del componente hijo como desees
    let respuestaprops = valor;
    console.log("Valor recibido ventana:", valor);
    if (!respuestaprops) {
      setclose("true");
    }
  };

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");

  console.log(fechaFormateada);
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();

  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;

  console.log("fechaActSIN" + fechaActSIN);

  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");
  const [idAnalista, setidAnalista] = React.useState("");
  const [datosConf, setdatosConf] = React.useState([]);
  const [siniestros, setsiniestros] = React.useState([]);

  //Obtener usuario.
  const getUsers = async () => {
    try {
      var result = await getDataToken(`login/get_correo/${email}/`);
      setidAnalista(result.id);
      setnombreAnalista(
        result.SNYTLOG_username +
          " " +
          result.SNYTLOG_surnamedad +
          " " +
          result.SNYTLOG_surnamemom
      );
      var resultConf = await getDataToken(`configuraciongeneral/get_id/1/`);
      setdatosConf(resultConf);
      var resultSiniestros = await getDataToken(`siniestros/get_listado/`);
      const elementoSiniestros = resultSiniestros?.filter(
        (item) => item.RECT_idfolio?.RECT_idfolio === idFolioprops
      );
      setsiniestros(elementoSiniestros);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getUsers();
  }, [idFolioprops]);

  console.log(nombreAnalista);
  console.log(siniestros);

  //Creción de constantes  para poder usar las apis
  const [cobertura, setCobertura] = useState([]); //Guarda toda la información que trae la api

  const getCoberturas = async () => {
    try {
      var result = await getDataToken(
        `polizascoberturasporproductos/get_pol/${idpoliza}/`
      );
      setCobertura(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getCoberturas();
  }, []);

  console.log(cobertura);

  //POST DE LAS COBERTURAS
  async function postCoberturasxTramite(aux) {
    try {
      var result = await postDataToken(
        `coberturaspolizasportramite/get_listado/`,
        aux
      );
      console.log("Registro exitoso", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  /* CONSTANTES DE ESTADOS*/

  const [InfoDataRow, setInfoDataRow] = useState([]); //Aqui es donde almacenará los datos de la fila seleccionadad
  const [btnDisable, setBtnDisable] = useState(true); //Boton de inicio registro el cual podra ser habilitado o deshabilitado

  useEffect(() => {
    if (InfoDataRow.length === 0) {
      props.botonlink(false);
      props.botondisable(true);
    }
  }, [InfoDataRow]);

  /* FUNCIÓN CLICK EN LOS RADIO BUTTON, ARROJA INFORMACION DE LA FILA SELECCIONADA */
  const rbClick = (e) => {
    const inputId = e.target.value;

    const switchSeleccionado = cobertura.find(
      (row) => row.COB_idcobertura.COB_cobertura === inputId
    );

    if (switchSeleccionado) {
      const isSelected = InfoDataRow.some(
        (item) => item.COB_idcobertura.COB_cobertura === inputId
      );

      if (isSelected) {
        setInfoDataRow((prevData) =>
          prevData.filter(
            (item) => item.COB_idcobertura.COB_cobertura !== inputId
          )
        );
      } else {
        setInfoDataRow((prevData) => [...prevData, switchSeleccionado]);
        setBtnDisable(false);
        props.botonlink(true);
        props.botondisable(false);
      }

      console.log(isSelected);
    }
  };

  console.log(InfoDataRow);

  const coberturaExiste = InfoDataRow.some((objeto) =>
    objeto.COB_idcobertura.COB_cobertura.toLowerCase().includes(COBERPERORG)
  );
  console.log(coberturaExiste);

  //Estado para saber si se dio clic en el botón
  const [clicboton, setclicboton] = useState(false);
  const [registro, setregistro] = useState(false);
  const [disable, setdisable] = useState(false);
  const [perdida, setperdida] = useState(false);

  const post = () => {
    if (coberturaExiste) {
      setperdida(true);
    } else if (btnDisable) {
      setdisable(true);
    } else {
      if (!clicboton && !btnDisable) {
        //Actualizamos a true
        const indices = objetosCoincidentes.findIndex(
          (objeto) => objeto.TARE_idtarea.TARE_idtarea === IDVALCOB
        );
        //Actualizamos a true
        objetosCoincidentes[indices].estado = true;
        let data;
        for (const item of InfoDataRow) {
          data = {
            RECT_idfolio: idFolioprops,
            POL_idpoliza: item.POL_idpoliza.POL_idpoliza,
            COB_idcobertura: item.COB_idcobertura.COB_idcobertura,
            MON_idmoneda: item.MON_idmonedacobertura.MON_idmoneda,
            TRA_idtipotramitesiniestro:
              item.RECT_idfolio.SINC_idcomplejidadsiniestro
                .TRA_idtipotramitesiniestro,
            CPT_sumaasegurada: item.PCP_coberturasumaasegurada,
            CPT_actualizofecha: fechaActSIN,
            CPT_actualizousuario: nombreAnalista,
          };
          postCoberturasxTramite(data);
        }
        // Filtrar el arreglo para obtener solo los registros con el campo 'estado'.
        const registrosConEstado = objetosCoincidentes.filter(
          (registro) => registro.estado
        );
        console.log(registrosConEstado);

        let bandera = false;

        for (const itempo of InfoDataRow) {
          for (const itemTra of registrosConEstado) {
            for (const siniestro of siniestros) {
              let registro = {
                RECT_idfolio: idFolioprops,
                POL_idpoliza: itempo.POL_idpoliza.POL_idpoliza,
                WTM_idwftareasxmodulos: itemTra.WTM_idwftareasxmodulos,
                PER_idpersona: infopersonal?.PER_idpersona?.PER_idpersona,
                SIN_idsiniestro: siniestro?.SIN_idsiniestro,
                WKT_fechawktramite: fechaActSIN,
                WKT_seejecuto: itemTra.estado,
                WKT_actualizofecha: fechaActSIN,
                WKT_actualizousuario: nombreAnalista,
              };
              postDataToken(`wktramite/get_listado/`, registro).then((data) => {
                console.log(`Registro exitoso wktramite`, data);
              });
              bandera = true;
            }
          }
        }
        if (bandera) {
          registrosConEstado.forEach((objeto) => {
            delete objeto.estado;
          });
        }

        setclicboton(true);
      } else if (btnDisable) {
        setdisable(true);
      } else {
        setregistro(true);
      }
    }
  };

  //Colocar el estado en falso
  const onclickdisable = () => {
    setdisable(false);
  };

  //Colocar el estado en falso
  const onclickregistro = () => {
    setregistro(false);
  };

  //Colocar el estado en falso
  const onclickperdida = () => {
    setperdida(false);
  };

  //Generación de cartas
  const [causaRechazo, setcausaRechazo] = useState("");
  console.log(causaRechazo);

  //Creción de constantes  para poder usar las apis
  const [databeneficiarios, setdatabeneficiarios] = useState([]); //Guarda toda la información que trae la api

  const getBeneficiarios = async () => {
    try {
      var result = await getDataToken(
        `personasxpoliza/get_poliza/${idpoliza}/`
      );
      setdatabeneficiarios(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  React.useEffect(() => {
    getBeneficiarios();
  }, []);
  console.log(databeneficiarios);

  //Obtenemos los beneficiarios

  const beneficiarios = databeneficiarios?.filter(
    (registro) =>
      registro?.PER_idpersona?.PERR_idrol?.PERR_idrol === PERR_BENEFICIARIO.id
  );

  console.log(beneficiarios);

  const agentes = databeneficiarios?.filter(
    (registro) =>
      registro.PER_idpersona?.PERR_idrol?.PERR_idrol === PERR_AGENTE.id
  );

  console.log(agentes);

  //ESTADOS GENERALES PARA GENERAR UNA CARTA

  let docs = [];

  let destinatarios = [];

  let polizas = [];

  var info = [];
  var infoasegurado = [];

  if (tramite === TRAM_FALLECIMIENTO.id && beneficiarios.length > 0) {
    info = beneficiarios.map((persona) => ({
      id: persona.PER_idpersona?.PER_idpersona,
      nombre: persona.PER_idpersona?.PER_nombre,
      apePat: persona.PER_idpersona?.PER_apellidopaterno,
      apeMat: persona.PER_idpersona?.PER_apellidomaterno,
      calleynum: `${persona.PER_idpersona?.PER_calle} No. Ext. ${persona.PER_idpersona?.PER_numeroexterior} No. Int. ${persona.PER_idpersona?.PER_numerointerior}`,
      colonia: `Col. ${persona.PER_idpersona?.SEP_idsepomex?.SEP_asenta}`,
      cp: `CP. ${persona.PER_idpersona?.SEP_idsepomex?.SEP_codpostal}`,
      alcaldia: `Alcaldía ${persona.PER_idpersona?.SEP_idsepomex?.SEP_municipio}`,
      agente: `${agentes[0]?.PER_idpersona?.PER_nombre} ${agentes[0]?.PER_idpersona?.PER_apellidopaterno} ${agentes[0]?.PER_idpersona?.PER_apellidomaterno}`,
    }));
  } else if (beneficiarios?.length === 0 && tramite === TRAM_FALLECIMIENTO.id) {
    info = agentes.map((persona) => ({
      id: persona.PER_idpersona?.PER_idpersona,
      nombre: persona.PER_idpersona?.PER_nombre,
      apePat: persona.PER_idpersona?.PER_apellidopaterno,
      apeMat: persona.PER_idpersona?.PER_apellidomaterno,
      calleynum: `${persona.PER_idpersona?.PER_calle} No. Ext. ${persona.PER_idpersona?.PER_numeroexterior} No. Int. ${persona.PER_idpersona?.PER_numerointerior}`,
      colonia: `Col. ${persona.PER_idpersona?.SEP_idsepomex?.SEP_asenta}`,
      cp: `CP. ${persona.PER_idpersona?.SEP_idsepomex?.SEP_codpostal}`,
      alcaldia: `Alcaldía ${persona.PER_idpersona?.SEP_idsepomex?.SEP_municipio}`,
      agente: persona?.PER_idpersona?.PER_direcciondeagencia,
    }));
  } else {
    infoasegurado = {
      id: infopersonal?.PER_idpersona?.PER_idpersona,
      nombre: infopersonal?.PER_idpersona?.PER_nombre,
      apePat: infopersonal?.PER_idpersona?.PER_apellidopaterno,
      apeMat: infopersonal?.PER_idpersona?.PER_apellidomaterno,
      calleynum: `${infopersonal?.PER_idpersona?.PER_calle} No. Ext. ${infopersonal?.PER_idpersona?.PER_numeroexterior} No. Int. ${infopersonal?.PER_idpersona?.PER_numerointerior}`,
      colonia: `Col. ${infopersonal?.PER_idpersona?.SEP_idsepomex?.SEP_asenta}`,
      cp: `CP. ${infopersonal?.PER_idpersona?.SEP_idsepomex?.SEP_codpostal}`,
      alcaldia: `Alcaldía ${infopersonal?.PER_idpersona?.SEP_idsepomex?.SEP_municipio}`,
      agente: `${agentes[0]?.PER_idpersona?.PER_nombre} ${agentes[0]?.PER_idpersona?.PER_apellidopaterno} ${agentes[0]?.PER_idpersona?.PER_apellidomaterno}`,
    };
    info.push(infoasegurado);
  }

  docs.push({ id: 1, causa: causaRechazo });

  destinatarios = info;
  console.log(destinatarios);

  polizas.push({ id: 1, numPoliza: numpoliza });

  var dataCarta = {
    arrayDocs: docs,
    destinatarios: destinatarios,
    polizas: polizas,
  };

  console.log(dataCarta);

  //ruta de la carta rechazo
  const url = `templatesCartas/generate-cartarechazo/`;
  const [currentDate, setCurrentDate] = useState("");

  //Obtener fecha
  useEffect(() => {
    const formattedDate = moment().format("LL"); // Formato "Ciudad de México a D [de] MMMM [de] YYYY"
    setCurrentDate(formattedDate);
  }, []);

  // Guardar los datos en una variable
  const rutalogo = `${URLAPIIMG}${datosConf?.SCG_imgPathSing}`;
  const rutaimg = `${URLAPIIMG}${datosConf?.SCG_imgPathSubLogo}`;
  const param_pie = `${URLAPIIMG}${datosConf?.SCG_imgPathPiePagina}`;
  const param_firma = `${URLAPIIMG}${datosConf?.SCG_imgFirma}`;
  const p = `${datosConf?.SCG_tituloPDF}`;
  const p1 = `${datosConf?.SCG_estadoCarta} a ${currentDate}`;
  const p6 = `Ref. Asegurada/asegurado ${infopersonal?.PER_idpersona?.PER_nombre} ${infopersonal?.PER_idpersona?.PER_apellidopaterno} ${infopersonal?.PER_idpersona?.PER_apellidomaterno}`;
  const p7 = JSON.stringify(polizas);
  const p8 = `No. Folio ${NoFolio}`;
  const p10 = `${datosConf?.SCG_acronimoAseguradora}`;
  const p11 = `${cobertura[0]?.COB_idcobertura?.COB_cobertura} `;
  const p12 = `${datosConf?.SCG_nombreFirma}`;
  const p13 = `${datosConf?.SCG_Puesto}`;
  const p14 = `${datosConf?.SCG_nombreLargoAseg}`;
  const p16 = `${datosConf?.SCG_direccionAseguradora}`;
  const p17 = JSON.stringify(docs);

  //Creción de constantes  para poder usar las apis
  const [Cartacausa, setCartacausa] = useState([]); //Guarda toda la información que trae la api

  //Obtener el ID de la cartaxcausa
  const getCartacausa = async () => {
    try {
      var result = await getDataToken(`cartascausa/get_id/${IDCRXTCNCB}/`);
      setCartacausa(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getCartacausa();
  }, []);

  console.log(Cartacausa);

  //Método post para el turnado de la carta a comunicacion con el cliente
  async function postCartasPoliza(aux, aux2) {
    try {
      var result = await postDataToken(`cartapolizatramite/get_listado/`, aux);
      console.log("Registro exitoso", result);
      const IDTipoCarta = result.LTXPOL_idcartaxpoliza;
      return AgregarRegistrosCartas(IDTipoCarta, aux2);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  //Agregar los registros a las tablas de comunicación con el cliente

  function AgregarRegistrosCartas(idTipoCarta, arreglo) {
    let data;
    // Agreamos los datos a la charola comunicación con el cliente
    let charola = {
      RECT_idfolio: idFolioprops,
      PER_idpersona: infopersonal?.PER_idpersona?.PER_idpersona,
      POL_idpoliza: idpoliza,
      Usuario_Login: idAnalista,
      CHCOM_entregado: false,
      CHCOM_actualizofecha: fechaActSIN,
      CHCOM_actualizousuario: nombreAnalista,
    };

    console.log(charola);
    // Realiza una solicitud POST para AGREGAR el registro
    postDataToken(`charolacomunicacion/get_listado/`, charola).then((data) => {
      console.log(`Registro exitoso charola comunicacion`);
    });

    // Itera sobre los registros que se guardaran
    for (const item of arreglo) {
      let destinatarioscarta = {
        PER_idpersona: item.id,
        DESXLTR0_pathcarta: item.url.length > 0 ? item.url : "https://file",
        DESXLTR0_fechaentregadocarta: fechaActSIN,
        LTXPOL_idcartaxpoliza: idTipoCarta,
        DESXLTR0_actualizofecha: fechaActSIN,
        DESXLTR0_actualizousuario: nombreAnalista,
      };
      console.log(destinatarioscarta[0]);
      postDataToken(`destinatarioxcarta/get_listado/`, destinatarioscarta).then(
        (data) => {
          console.log(
            `Registro exitoso destinatariosxpoliza`,
            destinatarioscarta
          );
        }
      );
    }
    setvisibleSiniestro(true);
    setIsLoading(false);
  }

  //Datos que se agregaran
  let cartaspolizas = {
    RECT_idfolio: idFolioprops,
    POL_idpoliza: idpoliza,
    CXC_idcausa: Cartacausa[0]?.CXC_idcausa,
    LTXPOL_actualizofecha: fechaActSIN,
    LTXPOL_actualizousuario: nombreAnalista,
  };

  console.log(cartaspolizas);

  const [visibleSiniestro, setvisibleSiniestro] = useState(false);
  const [openDialog1, setOpenDialog1] = useState("false");
  console.log(visibleSiniestro);

  //Esta funcion permite cargar archivos a nextcloud
  async function guardarCartaNextcloud() {
    let pdfArray = [];
    try {
      // Primer paso: crear las cartas. Esta función retornará un arreglo de cartas, el cual dependerá del número de destinatarios.
      for (const destinatario of destinatarios) {
        let json = {
          param_logo: rutalogo,
          param_imagen: rutaimg,
          param_pie: param_pie,
          param_firma: param_firma,
          param: p,
          param1: p1,
          param2: destinatario.calleynum,
          param3: destinatario.colonia,
          param4: destinatario.cp,
          param5: destinatario.alcaldia,
          param6: p6,
          param7: p7,
          param8: p8,
          param9:
            destinatario.nombre +
            " " +
            destinatario.apePat +
            " " +
            destinatario.apeMat,
          param10: p10,
          param11: p11,
          param12: p12,
          param13: p13,
          param14: p14,
          param15: destinatario.agente,
          param16: p16,
          param17: p17,
        };
        const resultadoCartas = await generatePDFWithFetch(url, json);
        pdfArray.push(resultadoCartas);
        console.log(pdfArray); // Accede a la URL y otros datos
      }
      if (pdfArray.length > 0) {
        // Declara un arreglo vacío para almacenar los documentos
        let docs = [];

        // Utiliza forEach para iterar sobre docCarta y agregar elementos a docs
        pdfArray.forEach((element, index) => {
          console.log(element);

          // Agrega cada elemento al arreglo "docs"
          docs.push({ doc: element, docName: `Carta_rechazo_` });
        });

        console.log(docs);
        // Una vez terminado el for, llamamos a la función para guardarlo en nextcloud, usando la estructura de los parámetros.
        const resultURLS = await uploadCartasToNextcloud(NoFolio, docs);
        console.log(resultURLS.url);

        if (resultURLS.length > 0) {
          // Combinar los objetos en la misma posición de ambos arreglos
          const objetosCombinados = destinatarios.map((destinatario, index) => {
            const resultURL = resultURLS[index];
            return {
              ...destinatario,
              ...resultURL,
            };
          });

          console.log("Objetos combinados:", objetosCombinados);

          if (objetosCombinados.length > 0) {
            postCartasPoliza(cartaspolizas, objetosCombinados);
          }
        }
      }
    } catch (error) {
      console.error("Error al generar el PDF o cargarlo en Nextcloud:", error);
    }
  }

  const [polizaxtramite, setpolizaxtramite] = useState([]);
  const [idrechazo, setidrechazo] = useState([]);

  const getPolizas = async () => {
    try {
      var result = await getDataToken(
        `polizaportramites/get_poliza/${idpoliza}/`
      );
      console.log(result);
      setpolizaxtramite(
        result?.filter(
          (pxt) => pxt?.RECT_idfolio?.RECT_idfolio === idFolioprops
        )
      );
      var resultado = await getDataToken(
        `estatuspolizatramite/get_id/${IDESTXPRE}/`
      );
      setidrechazo(resultado);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  React.useEffect(() => {
    getPolizas();
  }, [idFolioprops, idpoliza]);

  console.log(idrechazo);
  console.log(polizaxtramite);

  async function putPolizaTramite(aux) {
    try {
      var result = await putDataToken(
        `polizaportramites/get_id/${polizaxtramite[0]?.id}/`,
        aux
      );
      console.log("Registro exitoso", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  //Validar si se selecciono la póliza
  const [visiblepoliza, setvisiblepoliza] = useState(false);
  const [habilitado, sethabilitado] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //Colocar el false el estado
  const onclickabierto = () => {
    if (habilitado) {
      setvisiblepoliza(true);
    }
  };

  const handleImprimir = () => {
    if (!habilitado) {
      const indice = objetosCoincidentes.findIndex(
        (objeto) => objeto.TARE_idtarea.TARE_idtarea === IDENVCARTA
      );
      //Actualizamos a true
      objetosCoincidentes[indice].estado = true;
      const indices = objetosCoincidentes.findIndex(
        (objeto) => objeto.TARE_idtarea.TARE_idtarea === IDVALCOB
      );
      //Actualizamos a true
      objetosCoincidentes[indices].estado = true;
      setIsLoading(true);
      sethabilitado(true);
      setOpenDialog1("true");
      console.log(visibleSiniestro);
      guardarCartaNextcloud();
      let update = {
        EST_idestatuspolizatramite: idrechazo[0]?.EST_idestatuspolizatramite,
        PXT_actualizofecha: fechaActSIN,
        PXT_actualizousuario: nombreAnalista,
      };
      putPolizaTramite(update);
      // Filtrar el arreglo para obtener solo los registros con el campo 'estado'.
      const registrosConEstado = objetosCoincidentes.filter(
        (registro) => registro.estado
      );
      console.log(registrosConEstado);

      let bandera = false;

      for (const itemTra of registrosConEstado) {
        for (const siniestro of siniestros) {
          let registro = {
            RECT_idfolio: idFolioprops,
            POL_idpoliza: idpoliza,
            WTM_idwftareasxmodulos: itemTra.WTM_idwftareasxmodulos,
            PER_idpersona: infopersonal?.PER_idpersona?.PER_idpersona,
            SIN_idsiniestro: siniestro?.SIN_idsiniestro,
            WKT_fechawktramite: fechaActSIN,
            WKT_seejecuto: itemTra.estado,
            WKT_actualizofecha: fechaActSIN,
            WKT_actualizousuario: nombreAnalista,
          };
          postDataToken(`wktramite/get_listado/`, registro).then((data) => {
            console.log(`Registro exitoso wktramite`, data);
          });
          bandera = true;
        }
      }
      if (bandera) {
        registrosConEstado.forEach((objeto) => {
          delete objeto.estado;
        });
      }
    }
  };

  //Colocar el false el estado
  const onclickrechazo = () => {
    setvisiblepoliza(false);
  };

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(5); //El tamaño inicial de las filas será de 10
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const filteredData = cobertura.slice(startIndex, endIndex);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(255, 255, 255, 0.9)", // Fondo semitransparente
            zIndex: 9999, // Mayor z-index para estar encima de todo
          }}
        >
          <CircularProgress style={{ marginTop: "130px" }} />
          <br />
          <div>
            <h1-dialog-title>
              <FormattedMessage
                id="loginCarga"
                defaultMessage="Cargando........"
              />
            </h1-dialog-title>
            <h2-dialog-description>
              {" "}
              <FormattedMessage
                id="espMom"
                defaultMessage="Espere un momento."
              />
            </h2-dialog-description>
          </div>
        </div>
      )}
      {visiblepoliza && (
        <DialogValidaciones
          title={
            <FormattedMessage
              id="regisMsgInf"
              defaultMessage="Mensaje informaivo"
            />
          }
          secondButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description={
            <FormattedMessage
              id="regisMsgEnvio"
              defaultMessage="Ya se envió la carta rechazo."
            />
          }
          onClick={onclickrechazo}
        />
      )}
      {disable && (
        <DialogValidaciones
          title={
            <FormattedMessage
              id="regisMsgInf"
              defaultMessage="Mensaje informaivo"
            />
          }
          secondButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description={
            <FormattedMessage
              id="regisMsgCoberturas"
              defaultMessage="Seleccione las coberturas a reclamar."
            />
          }
          onClick={onclickdisable}
        />
      )}
      {clicboton && (
        <DialogValidaciones
          title={
            <FormattedMessage
              id="regisExitoso"
              defaultMessage="Registro exitoso"
            />
          }
          secondButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description={
            <FormattedMessage
              id="regisCobRecl"
              defaultMessage="Las coberturas a reclamar se guardaron correctamente."
            />
          }
        />
      )}
      {registro && (
        <DialogValidaciones
          title={
            <FormattedMessage
              id="regisMsgInf"
              defaultMessage="Mensaje informaivo"
            />
          }
          secondButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description={
            <FormattedMessage
              id="regisGuarCor"
              defaultMessage="El registro ya fue guardado."
            />
          }
          onClick={onclickregistro}
        />
      )}
      {perdida && (
        <DialogValidaciones
          title={
            <FormattedMessage
              id="regisMsgInf"
              defaultMessage="Mensaje informaivo"
            />
          }
          secondButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description={
            <FormattedMessage
              id="regisCobPer"
              defaultMessage="La cobertura se guarda cuando se asigna el monto de acuerdo a la pérdida. Si usted ya obtuvo este valor puede seguir con su trámite."
            />
          }
          onClick={onclickperdida}
        />
      )}
      {visibleSiniestro && (
        <DialogValidaciones
          title={
            <FormattedMessage
              id="regTraRecTramiteRech"
              defaultMessage="Trámite Rechazado"
            />
          }
          secondButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description={
            <FormattedMessage
              id="regReCorrect"
              defaultMessage="Se ingresó de manera correcta el rechazo."
            />
          }
        />
      )}
      <Grid container>
        <Grid xs={12}>
          <h3-header-card-cobertura>
            <FormattedMessage
              id="globNumPol"
              defaultMessage="Número de póliza "
            />
            {numpoliza}
          </h3-header-card-cobertura>
          <br />
          <h2-dialog-description>
            <FormattedMessage
              id="globSelectCob"
              defaultMessage="Seleccione las coberturas que estan por reclamarse."
            />
          </h2-dialog-description>
          <br />
        </Grid>
      </Grid>
      <Paper>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center">
                  <Grid>
                    <FormattedMessage
                      id="globCober"
                      defaultMessage="Cobertura"
                    />
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Grid>
                    <FormattedMessage
                      id="globSumAse"
                      defaultMessage="Suma asegurada"
                    />
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Grid>
                    <FormattedMessage id="globMoneda" defaultMessage="Moneda" />
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {filteredData.map((row) => {
                //Creacion de saber si es perdida organica nos mostrará un mensaje
                var showCobertura = true;
                if (
                  row.COB_idcobertura?.COB_cobertura.normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase() === COBERPERORG
                ) {
                  showCobertura = true;
                }
                if (
                  row.COB_idcobertura?.COB_cobertura.normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase() != COBERPERORG
                ) {
                  showCobertura = false;
                }
                console.log(showCobertura);
                return (
                  <TableRow key={row.COB_idcobertura?.COB_cobertura}>
                    <StyledTableCell component="th" scope="row">
                      <Check2
                        onChange={rbClick}
                        name="rb"
                        value={row.COB_idcobertura?.COB_cobertura}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.COB_idcobertura?.COB_cobertura}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.PCP_coberturasumaasegurada?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.MON_idmonedacobertura?.MON_abreviatura}
                    </StyledTableCell>
                    <StyledTableCell>
                      {showCobertura ? (
                        <IconButton style={{ padding: 0 }}>
                          <DialogPerdida
                            mainButton={<DriveFileRenameOutlineIcon />}
                            description={
                              <TablePerdida
                                infopersonal={infopersonal}
                                InfoDataRow={InfoDataRow}
                                closewin={obtenerValorPerdida}
                              />
                            }
                            title={
                              <FormattedMessage
                                id="regSelecLaSeleccnaLa"
                                defaultMessage="Selecciona las perdidas orgánicas"
                              />
                            }
                            firstButton={
                              <FormattedMessage
                                id="globRegres"
                                defaultMessage="Regresar"
                              />
                            }
                            visible={close}
                          />
                        </IconButton>
                      ) : null}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <StyledTableCell colSpan={5}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    {" "}
                    <Grid item xs={"auto"}>
                      <DialogRechazo
                        onClick={onclickabierto}
                        title={
                          <FormattedMessage
                            id="globRechaz"
                            defaultMessage="Trámite Rechazado"
                          />
                        }
                        description={
                          <FormattedMessage
                            id="globPolizaRechazada"
                            defaultMessage="El número de póliza rechazada es: {numpoliza}"
                            values={{ numpoliza }}
                          />
                        }
                        description1={
                          <FormattedMessage
                            id="regisMotRe"
                            defaultMessage="Ingrese el motivo de rechazo:"
                          />
                        }
                        description2={
                          <TXTField
                            id="polizaRechazada"
                            name="polizaRechazada"
                            multiline={true}
                            onChange={(event) =>
                              setcausaRechazo(event.target.value)
                            }
                          />
                        }
                        firstButton={
                          <FormattedMessage
                            id="globAceptr"
                            defaultMessage="Aceptar"
                          />
                        }
                        onClick1={handleImprimir}
                        secondButton={
                          <FormattedMessage
                            id="globCance"
                            defaultMessage="Cancelar"
                          />
                        }
                        visible={openDialog1}
                      />
                    </Grid>
                    <Grid item xs={"auto"}>
                      {" "}
                      <Tooltip
                        title={
                          <FormattedMessage
                            id="regisGuCober"
                            defaultMessage="Guardar coberturas"
                          />
                        }
                        placement="top"
                      >
                        <IconButton
                          color="secondary"
                          size="large"
                          onClick={post}
                        >
                          <SaveIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          component="div"
          count={cobertura.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={
            <FormattedMessage
              id="globRegPa"
              defaultMessage="Registros por página:"
            />
          }
        />
      </Paper>
      <br />
      <br />
    </div>
  );
}
