import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {Info} from "./Fallecimiento/InfoFallecimiento"
import {Infovenci} from "./Vencimiento/InfoVenci"
import {Infobene} from "./Beneficio de vida/InfoBeneficio"


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#00203b',
    color: theme.palette.common.white,
    //fontFamily: 'Avenir Light',
    fontSize: 15,
    padding: 0,
    paddingLeft: '10px', 
    height:'50px', 
   
    fontSize: '20px',
    borderColor: '#e2e2e2',
    border: "1px solid #e2e2e2",
    fontFamily: 'Avenir-Light',
  },
  [`&.${tableCellClasses.body}`]: {
    //fontFamily: 'Avenir Light',
    fontSize: 13,
    height: '10px',
    padding: 0,
    fontSize: '16px',
    borderColor: '#e2e2e2',
    border: "1px solid #e2e2e2",
    fontFamily: 'Avenir-Light',
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
export default function TablaDocuFalle() {
function createData(Documentacion)  {
  return { Documentacion};
}

const rows = [
  createData(<Info/>),
 
  
  

];




  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 50 }} aria-label="simple table">
        <TableHead>
          <TableRow>   
            <StyledTableCell align="left">Documentacion</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="left">
                {row.Documentacion}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


