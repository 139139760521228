import React, { useState, useEffect } from "react";
import "../../css/estilo.css";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import { Grid, IconButton } from "@mui/material";
import { searchFilesByName } from "../../nextcloud/functions";

//idioma
import { FormattedMessage } from "react-intl";
import CustomSeparatorDinamic from "../Tools/BreadCrumbs/RecorridoDinamico";
import TXTField from "../Tools/TextField/TextField";
import ButtonVino from "../Tools/Bottones/ButtonVino";
import DialogInvestigacion from "./Elements/Dialogs/DialogInvestigacion";
import { useLocation } from "react-router-dom";
import {
  INVSQL,
  IDMODDICTAMEN,
  APARTADO,
  IDMODINVESTIGADOR,
} from "../../utils/constanstoken";
import Infoinvest from "./Elements/Dialogs/DialogInfoinves";
import { getDataToken, postDataToken, putDataToken } from "../../api/dataToken";
import { LimitarTexto } from "../Recepcion/Elements/Functions/Validaciones";
import DialogEnviarAInvestigador from "./Elements/Dialogs/DialogEnviarAInvestigador";
import DialogObtenerAInvestigador from "./Elements/Dialogs/DialogObtenerAInvestigador";
import getDate from "../Registro/Function/obtenerFecha";
import getHours from "../Registro/Function/obtenerHora";
import { formatofecha } from "../../api/getUmbrales";
import moment from "moment";
import DialogAviso from "../Registro/Elements/Dialog/DialogAviso";

const CompDictamenInvestigacion = () => {
  const arrayBreadcrumbs = [
    {
      key: 1,
      name: <FormattedMessage id="glob.inicio" defaultMessage="Inicio" />,
      to: "/menu",
    },
    {
      key: 2,
      name: <FormattedMessage id="menu.dictamen" defaultMessage="Dictamen" />,
      to: "/dictamen",
    },
  ];

  const location = useLocation();
  const folio = location.state.folio;
  console.log(folio);

  const [verFolderFolio, setVerFolderFolio] = React.useState(
    folio?.RECT_idfolio?.RECT_numerofolio
  );

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");

  console.log(moment(fechaEmpAn).format(formatofecha));
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();

  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;

  //Ver la información del investigador

  const [pdfsINV, setPdfsINV] = React.useState([]);
  const [btnVerFolderINV, setBtnVerFolderINV] = React.useState({
    estatus: false,
    label: "Aceptar",
  });

  const [documentsINV, setDocumentsINV] = React.useState([]);
  const [currentPDFINV, setCurrentPDFINV] = React.useState(0);
  const [loadingPDFINV, setLoadingPDFINV] = useState(false);

  const verArchivosINV = async () => {
    setBtnVerFolderINV({
      ...btnVerFolderINV,
      label: "Buscando información...",
      estatus: true,
    });
    setLoadingPDFINV(true);
    var response = await searchFilesByName(verFolderFolio, INVSQL);
    if (!response) {
      // alert("No existe carpeta con ese folio");
      setBtnVerFolderINV({
        ...btnVerFolderINV,
        label: "Aceptar",
        estatus: false,
      });
    } else {
      if (response.length > 0) {
        setBtnVerFolderINV({
          ...btnVerFolderINV,
          label: "Aceptar",
          estatus: false,
        });
        var pdfs = response.filter((file) => file.fileExt === "pdf");
        if (pdfs.length > 0) {
          const docs = pdfs.map((e) => e.fileBlob);
          console.log("Docs:", docs);
          setPdfsINV(docs);
        } else {
          setLoadingPDFINV(false);
        }
      } else {
        // alert("La carpeta está vacía.");
        setBtnVerFolderINV({
          ...btnVerFolderINV,
          label: "Aceptar",
          estatus: false,
        });
        setLoadingPDFINV(false);
      }
    }
  };

  //Este use Effect se activará cuando escuche cambios en el pdfs
  React.useEffect(() => {
    if (pdfsINV.length > 0) {
      setLoadingPDFINV(false);
      pdfsINV.forEach((blob) => {
        var pdfUrl = URL.createObjectURL(blob);
        console.log(pdfUrl);
        setDocumentsINV((prevState) => [
          ...prevState,
          <iframe
            src={pdfUrl}
            style={{
              width: "1100px",
              height: "1100px",
              display: "block",
              margin: "auto",
            }}
          />,
        ]);
        URL.revokeObjectURL(blob);
      });
    }
  }, [pdfsINV]);

  const [guardarDatos, setguardarDatos] = useState([]);
  const [indicaciones, setindicaciones] = useState([]);
  //Obtener usuario.
  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");
  const [idTrabajador, setidTrabajador] = React.useState("");

  //GET para obtener los datos guardados anteriormente
  const getguardado = async () => {
    try {
      const results = [],
        indicaciones = [];
      for (const resultados of folio) {
        var result = await getDataToken(
          `mensajes/historical_filter_dictamen/?folio=${resultados?.RECT_idfolio?.RECT_idfolio}&poliza=${resultados?.POL_idpoliza?.POL_idpoliza}&modulo=${IDMODDICTAMEN}`
        );
        results.push(result);
      }
      setguardarDatos(results);
      for (const resultados of folio) {
        var resultInd = await getDataToken(
          `mensajes/historical_filter_investigador/?folio=${resultados?.RECT_idfolio?.RECT_idfolio}&poliza=${resultados?.POL_idpoliza?.POL_idpoliza}&modulo=${IDMODINVESTIGADOR}`
        );
        indicaciones.push(resultInd);
      }
      setindicaciones(indicaciones);
      var resultLogin = await getDataToken(`login/get_correo/${email}/`);
      setidTrabajador(resultLogin.id);
      setnombreAnalista(
        resultLogin.SNYTLOG_username +
          " " +
          resultLogin.SNYTLOG_surnamedad +
          " " +
          resultLogin.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.error("There was an error:", error);
    }
  };
  useEffect(() => {
    getguardado();
  }, [folio]);

  console.log(guardarDatos);
  console.log(indicaciones);

  const resultado = guardarDatos[0]?.reduce((maxFecha, item) => {
    const fecha = new Date(item.MSG_actualizofecha);

    if (!maxFecha || fecha > maxFecha.fecha) {
      return { fecha, elemento: item };
    }

    return maxFecha;
  }, null);

  const elemento = resultado?.elemento;

  console.log(elemento);

  /////////

  const mensajesConDatos = indicaciones.filter(
    (item) =>
      item && Array.isArray(item) && item.some((msg) => msg && msg.MSG_mensaje)
  );

  console.log(mensajesConDatos);

  const resultadoInd = mensajesConDatos[0]?.reduce((maxFecha, item) => {
    const fecha = new Date(item.MSG_actualizofecha);

    if (!maxFecha || fecha > maxFecha.fecha) {
      return { fecha, elemento: item };
    }

    return maxFecha;
  }, null);

  const elementoInd = resultadoInd?.elemento;

  console.log(elementoInd);

  const [values, setValues] = React.useState({
    observaciones: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  React.useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      observaciones: elemento ? elemento?.MSG_mensaje : "",
    }));
  }, [elemento]);

  async function postMSG(aux) {
    try {
      var result = await postDataToken(`mensajes/get_listado/`, aux);
      console.log("Registro exitoso msg", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  const [visible, setVisible] = useState(false);

  const validateInvestigador = (e) => {
    e.preventDefault();
    if (elemento) {
      guardarDatos.forEach((datos) => {
        const guardarmsg = {
          RECT_idfolio: datos[0]?.RECT_idfolio?.RECT_idfolio,
          POL_idpoliza: datos[0]?.POL_idpoliza?.POL_idpoliza,
          IDTRABAJADOR: idTrabajador,
          WFM_idwfmodulo: IDMODDICTAMEN,
          MSG_mensaje: values?.observaciones,
          MSG_apartado: APARTADO.DICTAMEN,
          MSG_actualizofecha: fechaActSIN,
          MSG_actualizousuario: nombreAnalista,
        };
        putDataToken(
          `mensajes/get_id/${datos[0].MSG_idmensaje}/`,
          guardarmsg
        ).then((data) => {
          console.log(`Registro ${datos[0].MSG_idmensaje} actualizado`);
          console.log(data);
        });
      });
      setVisible(true);
    } else {
      for (const item of folio) {
        const guardarmsg = {
          RECT_idfolio: item?.RECT_idfolio?.RECT_idfolio,
          POL_idpoliza: item?.POL_idpoliza?.POL_idpoliza,
          IDTRABAJADOR: idTrabajador,
          WFM_idwfmodulo: IDMODDICTAMEN,
          MSG_mensaje: values?.observaciones,
          MSG_apartado: APARTADO.DICTAMEN,
          MSG_actualizofecha: fechaActSIN,
          MSG_actualizousuario: nombreAnalista,
        };
        postMSG(guardarmsg);
      }
      setVisible(true);
    }
  };

  const handleClick = () => {
    // Actualizar el estado utilizando el hook useState
    setVisible(false);
    getguardado();
  };

  return (
    <div className="div-parent">
      {visible && (
        <DialogAviso
          title="Envio exitoso"
          firstButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description={"Envio realizado exitosamente."}
          onClick1={handleClick}
        />
      )}
      <div className="div-breadcrumbs">
        <CustomSeparatorDinamic
          breadcrumbs={arrayBreadcrumbs}
          name={`Investigación`}
        />
      </div>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <ReturnPage href="/dictamen" />
      </Grid>
      <div className="div-container">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ marginBottom: "20px" }}
        >
          <Grid item>
            <h11-title-modal-admin>Investigador</h11-title-modal-admin>
          </Grid>
          <Grid item>
            <IconButton onClick={() => verArchivosINV()}>
              <Infoinvest
                button={
                  <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
                }
                title="Investigador"
                label={btnVerFolderINV.label}
                description={
                  <div>
                    {loadingPDFINV ? (
                      <p>&nbsp;&nbsp;Cargando PDF...</p>
                    ) : pdfsINV.length > 0 ? (
                      <div>
                        {documentsINV[currentPDFINV]}
                        <div
                          style={{
                            display: "block",
                            margin: "auto",
                            textAlign: "center",
                          }}
                        >
                          <br />
                          <ButtonVino
                            onClick={() => setCurrentPDFINV(currentPDFINV - 1)}
                            disabled={currentPDFINV === 0}
                            label="Anterior PDF"
                          ></ButtonVino>
                          <ButtonVino
                            onClick={() => setCurrentPDFINV(currentPDFINV + 1)}
                            disabled={currentPDFINV === pdfsINV.length - 1}
                            label="Siguiente PDF"
                          ></ButtonVino>
                        </div>
                      </div>
                    ) : (
                      <p>&nbsp;&nbsp;No hay PDF's disponibles</p>
                    )}
                  </div>
                }
              ></Infoinvest>
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={4.5}>
            <DialogEnviarAInvestigador
              mainButton={"Enviar a investigador"}
              button={
                <FormattedMessage id="globAceptr" defaultMessage="Enviar" />
              }
              description={
                <div>
                  <TXTField
                    multiline={true}
                    rows={2}
                    onChange={handleChange("observaciones")}
                    value={values?.observaciones}
                    onInput={(e) => LimitarTexto(e.target, 255)}
                  />
                </div>
              }
              title="Observaciones a investigador"
              estatus={values.observaciones == "" ? true : false}
              onClick2={validateInvestigador}
              button1="Cancelar"
            ></DialogEnviarAInvestigador>
          </Grid>
          <Grid item xs={4}>
            <DialogObtenerAInvestigador
              mainButton={"Observaciones de investigador"}
              button={
                <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
              }
              description={
                <div>
                  <TXTField
                    disabled={true}
                    multiline={true}
                    rows={2}
                    value={
                      elementoInd
                        ? elementoInd.MSG_mensaje
                        : "No cuenta con ninguna observación."
                    }
                  />
                </div>
              }
              title="Observaciones de investigador"
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item></Grid>
        </Grid>
      </div>
    </div>
  );
};
export default CompDictamenInvestigacion;
