import React from "react";
import { CompDocumentation } from "../../../components/Documentations/CompDocumentation";
import MyComponent from "../../../components/Tools/ButtonLoading/ButtonLoading";

export function Documentation() {
  return (
    <div>
   
   <MyComponent componentToRender={CompDocumentation } />
   
    </div>
  );
}
