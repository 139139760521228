import React from "react";
import { Cobertura } from '../../../../components/GeneralConf/Catalogo/Cobertura';


export function cobe() {
    return (
        <div>
         
            <Cobertura />
       
        </div>
    );
}