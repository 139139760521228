import React from "react";
import { ConfGeneral } from '../../../../components/GeneralConf/General/ConfGeneral';

export function logo() {
    return (
        <div>
         
            <ConfGeneral />
          
        </div>
    );
}