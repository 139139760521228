import { ClientLayaout } from '../../layouts/Client/ClientLayaout';
// links de las pages Modinvestigador
import { Investigador } from "../../pages/Client/ModInvestigador/Investigador";
import { Supervisor } from "../../pages/Client/ModInvestigador/Supervisor";

const routesClient = [

    {
        path: "/investigador",
        layout: ClientLayaout,
        component: Investigador,
        exact: true,
    },
    {
        path: "/supervisor",
        layout: ClientLayaout,
        component: Supervisor,
        exact: true,
    },
];

export default routesClient;