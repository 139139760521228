import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

const CustomSeparator2 = (props) => {
  const breadcrumbs = [
    <Link underline="hover" key="1" href={props.enlace}>
      <h2-breadcrumbs-primary>{props.name1}</h2-breadcrumbs-primary>
    </Link>,
    <Link underline="hover" key="2" href={props.enlace2}>
      <h2-breadcrumbs-primary>{props.name2}</h2-breadcrumbs-primary>
    </Link>,
    <h3-breadcrumbs-secundary>{props.name3}</h3-breadcrumbs-secundary>,
  ];

  return (
    <Stack spacing={2}>
      <Breadcrumbs separator="›">{breadcrumbs}</Breadcrumbs>
    </Stack>
  );
};

export default CustomSeparator2;
