/* Brandon Hernandez Rocha 11/2022 */
import * as React from 'react'
import "../../dictamen.css"
import { useState } from 'react';
import { Grid } from "@mui/material";
import CardPoliza from '../Cards/CardPoliza';


export default function TableDictamenPolizas(props) {
  const polizas = props.polizas;  
  return (
    <div>
      {polizas.map((poliza) => (
        <Grid container spacing={3} key={poliza.PXFS_idfoliopolizaxsiniestropoliza}>
          <Grid item xs={12}>
            <CardPoliza poliza={poliza} idFolio={props.idFolio}/>
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
