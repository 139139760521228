import * as React from "react";
import { useState, useEffect, useRef } from "react";
//herramientas de mui y componentes estandarizados
import Grid from "@material-ui/core/Grid";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TXTField from "../../Tools/TextField/TextField";
import DatePicker2 from "../../Tools/DatePicker/DatePicker2";
import { Select2 } from "../../Tools/Selectss/Select2";
import { Select1 } from "../../Tools/Selectss/Select1";
import { getDataToken, postDataToken } from "../../../api/dataToken";
import { BASE_API } from "../../../utils/constans";
import { getToken } from "../../../api/token";
import DialogGuardarRegSinPol from "./Dialog/DialogGuardarRegSinPol";
import { LimitarDigitos, OnlyNumber, formatNumber, formatNumberString, formatedNumberAndDecimalAndComa, validateCURP, validateNumberAndDecimal, validateRFC } from "../../../utils/functions";
import moment from "moment";
import Check2 from "../../Tools/CheckBox/Check2";
import { FormattedMessage } from "react-intl";
import TablaDatosReclamacion from "./Tables/TablaDatosReclamacion";
import { COBERTURAS_BASICAS, COBERTURA_FALLE_DEFAULT, COBERTURA_RESCA_DEFAULT, COBERTURA_RETIRO_DEFAULT, MONEDA_NACIONAL, PERR_AGENTE, PERR_ASEGURADO, PERR_CONTRATANTE, TRAM_BENEFICIOSEV, TRAM_FALLECIMIENTO, TRAM_MANUAL, TRAM_RESCATES, TRAM_RETIROS, TRAM_VENCIMIENTO } from "../../../utils/constanstoken";
import { LimitarTexto, LimitarTextoYNumero } from "./Functions/Validaciones";
import RadioDinamico from "../../Tools/RadioButton/RadioDinamico";
import Btverreport from "../../Tools/Bottones/BTAgregarDocumento";
import IconEliminar from "../../Tools/Icons/IconEliminar";
import DialogConfirm from "./Dialog/DialogConfirm";
import DialogIconDeleteConfirm from "../../Tools/Dialog/DialogIconDeleteConfirm";
import DialogFeedback from "../../Tools/Dialog/DialogFeedback";
import { set } from "date-fns";
export default function TablaPolizaSinRegistro(props) {


  const formularioRef = useRef(null);
  //Envio de variables para ventana Nuevo tramite
  const [dataForm, setDataForm] = useState(""); //Envio variables a pantalla nuevo tramite
  const CONEXIONPM = true;

  //Funcion que valida el campo rfc
  const [errorRFC, setErrorRFC] = React.useState(false)
  const [errorMessageRFC, setErrorMessageRFC] = React.useState("")
  //Funcion que valida el campo número de póliza
  const [errorNumPol, setErrorNumPol] = React.useState(false)
  const [errorMessageNumPol, setErrorMessageNumPol] = React.useState("")
  const [numPoliza, setNumPoliza] = React.useState("")
  //Funcion que valida el campo CURP
  const [errorCURP, setErrorCURP] = React.useState(false)
  const [errorMessageCURP, setErrorMessageCURP] = React.useState("")

  //Valida fechas emison 
  const [errorFecEmis, setErroFecEmis] = React.useState(false)
  const [errorMessageFecEmis, setErrorMessageFecEmis] = React.useState("")

  //Boton agregar 
  const [btnAgregar, setBtnAgregar] = useState(false)
  //Funcion que valida el campo sexo
  const [errorSexo, setErrorSexo] = React.useState(false)
  //Funcion que valida el campo producto
  const [errorProd, setErrorProd] = React.useState(false)

  //Estados para dialogos de confirmación y error
  const [showDialog, setShowDialog] = React.useState(false)
  const [showDialogError, setShowDialogError] = React.useState(false)
  //FECHA ACTUAL
  var today = new Date();

  // validar campos.
  const [nombre, setNombre] = useState("");
  const [apePat, setApePat] = useState("");
  const [apeMat, setApeMat] = useState("");
  const [nombreCont, setNombreCont] = useState("");
  const [apePatCont, setApePatCont] = useState("");
  const [apeMatCont, setApeMatCont] = useState("");
  const [asegurado, setAsegurado] = useState({
    nombre: "",
    apePat: "",
    apeMat: "",
    fechaNac: today.toISOString(today).slice(0, 10),
    curp: "",
    rfc: "",
    sexo: "",
  });
  const [polizasGuardadas, setPolizasGuardadas] = useState([]);

  // select sexo 
  let arraySexo = [
    { "identificador": 1, "texto": "Hombre" },
    { "identificador": 2, "texto": "Mujer" }
  ]


  //metodo get para lista productos
  const [tipoProductos, setTipoProductos] = useState([]);
  const [listMonedas, setListMonedas] = useState([]);
  const [listAgencias, setListAgencias] = useState([]);
  const [tipoCveProducto, setCveProducto] = useState(0);
  const [diragenciaId, setDirAgenciaId] = useState(0);


  const getDataTipoProductosMoneda = async () => {
    //Resultados de la api documentacion documentosxtiposiniestrostramite
    let results = await getDataToken("productos/get_listado/");
    if (results.length > 0) {
      let copiaApi = results.map(objeto => {
        return { ...objeto, identificador: objeto.PRO_idproducto, texto: objeto.PRO_descripcion };
      });
      setTipoProductos(copiaApi)
    }
    let resultMoneda = await getDataToken("monedas/get_listado/");
    if (resultMoneda.length > 0) {
      let copiaApi = resultMoneda.map(objeto => {
        return { ...objeto, identificador: objeto.MON_idmoneda, texto: objeto.MON_abreviatura };
      });
      setListMonedas(copiaApi)
    }
    let resultAgencias = await getDataToken("agencias/get_listado/");
    if (resultAgencias.length > 0) {
      let copiaApi = resultAgencias.map(objeto => {
        return { ...objeto, identificador: objeto.AGEN_idagencia, texto: objeto.AGEN_claveagencia };
      });
      setListAgencias(copiaApi)
    }
  };
  useEffect(() => {
    getDataTipoProductosMoneda();
  }, []);

  /****** Código Usuario que actualizó *******/
  const [showFeedback, setShowFeedback] = useState(false);
  const [dataFeedBack, setDataFeedBack] = useState({
    title: "",
    description: "",
    button: "Aceptar",
  })



  useEffect(() => {
    if (numPoliza === "") {
      setCveProducto(0);
      setFechaVen(null);
      setFechaEmi(null);
      setSumaAse("");
      formularioRef.current.reset();
    }
  }, [numPoliza]);


  function showDialogConfirm(flag) {
    return flag ? <React.Fragment>
      <DialogGuardarRegSinPol
        dataForm={dataForm}
        open={true}
        mainButton="Guardar"
        title="Formulario completado con éxito"
        description="De clic en Aceptar para continuar con el proceso."
        button="Aceptar"
        type="submit"
      />
    </React.Fragment> : <React.Fragment><DialogGuardarRegSinPol
      open={true}
      mainButton="Guardar"
      title="Algo falló."
      description="Comuniquese con el administrador de TI."
      button="Aceptar"
      type="submit"
    /></React.Fragment>
  }

  useEffect(() => {
    if (dataForm !== "") {
      setShowDialog(true)
    }
  }, [dataForm])


  const [fechaNac, setFechaNac] = useState(today.toISOString(today).slice(0, 10))
  const [fechaEmi, setFechaEmi] = useState("")
  const [fechaVen, setFechaVen] = useState("")
  const [sumaAse, setSumaAse] = useState("")

  //Cambiar el estado de persona quien realiza el trámite
  const [aseguradoSi, setAseguradoSi] = useState(false)
  const toogleSwitch = (e) => {
    if (e.target.checked) {
      setAseguradoSi(true);
    } else {
      setAseguradoSi(false);
    }

  };
  //Cambio de producto y moneda
  const [producto, setProducto] = useState("")
  const handleChangeProduct = (value) => {
    setCveProducto(value)
    for (let index = 0; index < tipoProductos.length; index++) {
      const element = tipoProductos[index];
      if (element.identificador == value) {
        setProducto(element.texto);
        return;
      }
    }
  }

  //esta funcion elimina un elemento de la tabla
  const DeleteItems = (event, indexItem) => {
    if (event) {
      setDatosReclamacion((prevState) =>
        prevState.filter((todo, index) => index !== indexItem)
      );
    }
  };

  //Agregar a tabla datos reclamación
  const [datosReclamacion, setDatosReclamacion] = useState([]);
  const agregarFila = (data) => {
    let copiaCober = [...coberAReclamar]
    setDatosReclamacion((current) => [...current,
    {
      tipoTramite: data.tipoTramite,
      POL_numpoliza: data.POL_numpoliza,
      POL_polizafecemisión: data.POL_polizafecemisión,
      POL_polizafinvigencia: data.POL_polizafinvigencia,
      PRO_idproductos: data.PRO_idproductos,
      PRO_descripcion: data.PRO_descripcion,
      PCP_coberturasumaasegurada: data.PCP_coberturasumaasegurada,

      PER_nombre_cont: data.PER_nombre_cont,
      PER_apePat_cont: data.PER_apePat_cont,
      PER_apeMat_cont: data.PER_apeMat_cont,

      PER_nombre_ase: data.PER_nombre_ase,
      PER_apePat_ase: data.PER_apePat_ase,
      PER_apeMat_ase: data.PER_apeMat_ase,
      PER_CURP_ase: data.PER_CURP_ase,
      PER_RFC_ase: data.PER_RFC_ase,
      PER_fechanac_ase: data.PER_fechanac_ase,
      PER_sexo_ase: data.PER_sexo_ase,

      PER_nombre_agente: data.PER_nombre_agente,
      PER_apeMat_agente: data.PER_apeMat_agente,
      PER_apePat_agente: data.PER_apePat_agente,
      PER_clave_agente: data.PER_clave_agente,
      PER_DA_agente: data.PER_DA_agente,
      PER_correo_agente: data.PER_correo_agente,
      coberturas: copiaCober

    }]);

    if (tipoTram == TRAM_FALLECIMIENTO.id) {
      setCoberAReclamar([
        { id: 1, idCober: COBERTURA_FALLE_DEFAULT.id, nomCober: COBERTURA_FALLE_DEFAULT.description, SA: "", idMoneda: null, nomMoneda: null, tipoTram: tipoTram }])
    } else {
      setCoberAReclamar([
        { id: 1, idCober: null, nomCober: "", SA: "", idMoneda: null, nomMoneda: null, tipoTram: tipoTram }])
    }
    setCveProducto(0);
    setDirAgenciaId(0)
    setNumPoliza("");
    setFechaVen(null);
    setFechaEmi(null);
  }
  //Guardar personaxPoliza 
  async function saveData() {
    console.log("Datos de la reclamación:", datosReclamacion);

    let dataPolizas = [];
    for (let index = 0; index < datosReclamacion.length; index++) {
      const element = datosReclamacion[index];

      let dataPoliza = [
        {
          "tipoTramite": TRAM_MANUAL,
          "POL_idpoliza": "",
          "POL_numpoliza": element.POL_numpoliza,
          "POL_polizafecemisión": element.POL_polizafecemisión,
          "POL_polizafinvigencia": element.POL_polizafinvigencia,
          "EST_idestatuspoliza": 1,
          "RECT_numerofolio": "",
          "PRO_idproductos": element.PRO_idproductos,
          "PRO_descripcion": producto,
          "EST_descripcion": "VIGENTE",
          "PER_nombre": element.PER_nombre_cont,
          "PER_apellidopaterno": element.PER_apePat_cont,
          "PER_apellidomaterno": element.PER_apeMat_cont,
          "PER_RFC": "",
          "PER_CURP": "",
          "PERR_idrol": PERR_CONTRATANTE.id,
          "PER_idpersona": "",
          "PERT_idtipopersona": 1,
          "PCP_coberturasumaasegurada": 0,

        },
        {
          "tipoTramite": TRAM_MANUAL,
          "POL_idpoliza": "",
          "POL_numpoliza": element.POL_numpoliza,
          "POL_polizafecemisión": element.POL_polizafecemisión,
          "POL_polizafinvigencia": element.POL_polizafinvigencia,
          "EST_idestatuspoliza": 1,
          "RECT_numerofolio": "",
          "PRO_idproductos": element.PRO_idproductos,
          "PRO_descripcion": producto,
          "EST_descripcion": "VIGENTE",
          "PER_nombre": element.PER_nombre_ase,
          "PER_apellidopaterno": element.PER_apePat_ase,
          "PER_apellidomaterno": element.PER_apeMat_ase,
          "PER_RFC": element.PER_RFC_ase,
          "PER_CURP": element.PER_CURP_ase,
          "PER_fechanacimiento": element.PER_fechanac_ase,
          "PER_sexo": element.PER_sexo_ase,
          // "PER_correo": element.PER_correo_ase, 
          "PERR_idrol": PERR_ASEGURADO.id,
          "PER_idpersona": "",
          "PERT_idtipopersona": 1,
          "PCP_coberturasumaasegurada": 0,
        },
        {
          "tipoTramite": TRAM_MANUAL,
          "POL_idpoliza": "",
          "POL_numpoliza": element.POL_numpoliza,
          "POL_polizafecemisión": element.POL_polizafecemisión,
          "POL_polizafinvigencia": element.POL_polizafinvigencia,
          "EST_idestatuspoliza": 1,
          "RECT_numerofolio": "",
          "PRO_idproductos": element.PRO_idproductos,
          "PRO_descripcion": producto,
          "EST_descripcion": "VIGENTE",
          "PER_nombre": element.PER_nombre_agente,
          "PER_apellidopaterno": element.PER_apePat_agente,
          "PER_apellidomaterno": element.PER_apeMat_agente,
          "PER_clave": element.PER_clave_agente,
          "PER_direcciondeagencia": element.PER_DA_agente,
          "PER_correo": element.PER_correo_agente,
          "PERR_idrol": PERR_AGENTE.id,
          "PER_idpersona": "",
          "PERT_idtipopersona": 1,
          "PCP_coberturasumaasegurada": 0,
        }, element.coberturas,
      ]
      dataPolizas.push(dataPoliza)

    }
    props.dataPolizas(dataPolizas);
  }

  useEffect(() => {
    props.polizasGuardadas(polizasGuardadas);
  }, [polizasGuardadas])

  // *** FUNCIONALIDAD PARA AGREGAR COBERTURAS ****** // 
  const [tipoTram, setTipoTram] = useState(0);
  const [arrCoberturas, setArrCoberturas] = useState([]);
  const [monedaCob, setMonedaCob] = useState(false);
  const [coberAReclamar, setCoberAReclamar] = useState([
    { id: 1, idCober: 0, nomCober: null, SA: null, idMoneda: 0, nomMoneda: null, tipoTram: tipoTram, obligatoria: false }]);

  const handleChangeTram = async (value) => {
    try {
      setCoberAReclamar([])
      setTipoTram(value);
      setMonedaCob(false)

      let coberturas = await getDataToken("coberturas/get_listado/");
      console.log(coberturas);
      if (coberturas) {
        // let copiaApi = coberturas.map(obj => {
        //   return { ...obj, identificador: obj.PXC_idcobertura.COB_idcobertura, texto: obj.PXC_idcobertura.COB_cobertura };
        // })
        let dataApi = coberturas.filter(obj => obj.TRA_idtipotramitesiniestro.TRA_idtipotramitesiniestro == value)
        console.log("copia api", dataApi)
        let copiaApi = [];
        if (dataApi.length > 0) {
          copiaApi = dataApi.map(obj => {
            return { ...obj, identificador: obj.COB_idcobertura, texto: obj.COB_cobertura };
          })
        }
        setArrCoberturas(copiaApi)
      } else {
        setArrCoberturas([])
      }

    } catch (e) {

    }
  }
  useEffect(() => {
    if (tipoTram == TRAM_FALLECIMIENTO.id) {
      setCoberAReclamar([
        { id: 1, idCober: COBERTURA_FALLE_DEFAULT.id, nomCober: COBERTURA_FALLE_DEFAULT.description, SA: "", idMoneda: 0, nomMoneda: "", tipoTram: tipoTram, obligatoria: true }])
    } else if (tipoTram == TRAM_BENEFICIOSEV.id) {
      setCoberAReclamar([
        { id: 1, idCober: 0, nomCober: "", SA: "", idMoneda: 0, nomMoneda: "", tipoTram: tipoTram, obligatoria: false }])
    } else if (tipoTram == TRAM_RESCATES.id) {
      setCoberAReclamar([
        { id: 1, idCober: COBERTURA_RESCA_DEFAULT.id, nomCober: COBERTURA_RESCA_DEFAULT.description, SA: "0.0", idMoneda: MONEDA_NACIONAL.id, nomMoneda: MONEDA_NACIONAL.abreviatura, tipoTram: tipoTram, obligatoria: false }])
    } else if (tipoTram == TRAM_RETIROS.id) {
      setCoberAReclamar([
        { id: 1, idCober: COBERTURA_RETIRO_DEFAULT.id, nomCober: COBERTURA_RETIRO_DEFAULT.description, SA: "0.0", idMoneda: MONEDA_NACIONAL.id, nomMoneda: MONEDA_NACIONAL.abreviatura, tipoTram: tipoTram, obligatoria: false }])
    }
    else if (tipoTram == TRAM_VENCIMIENTO.id) {
      setCoberAReclamar([
        { id: 1, idCober: null, nomCober: "", SA: "", idMoneda: 0, nomMoneda: "", tipoTram: tipoTram, obligatoria: false }])
    }
  }, [tipoTram])

  // Controlar el arreglo de coberturas a reclamar - (Agregar)
  const handleClickAddCober = () => {

    let flag = true;
    if (coberAReclamar.length > 0) {
      for (let cobertura of coberAReclamar) {
        if (!cobertura.idCober || cobertura.idCober == 0 || !cobertura.SA || cobertura.SA === "" || !cobertura.idMoneda || cobertura.idMoneda === 0) {
          flag = false;
          document.getElementById("sCobertura_" + cobertura.id).focus();
        }
      }
    }
    if (!flag) {
      return false;
    }

    let identificador;
    if (coberAReclamar.length <= 0) {
      identificador = 1
    } else {
      // Obtener el último elemento del array
      const ultimoElemento = coberAReclamar[coberAReclamar.length - 1];
      // Obtener el último ID del elemento
      const ultimoId = ultimoElemento.id;
      // Asignar el nuevo ID al último elemento
      identificador = ultimoId + 1;
    }
    setCoberAReclamar((current) => [...current, { id: identificador, idCober: null, nomCober: null, SA: null, idMoneda: null, nomMoneda: null, tipoTram: tipoTram, obligatoria: false }]);
  }
  /* Controlar el arreglo de coberturas a reclamar - (Modificar) - Types: 1=> para el id cobertura,
  2=> para la suma asegurada y 3=> para el id moneda */
  const updateCoberAReclamar = (e, id, type) => {
    const item = e.target.value;
    //Buscar el nombre de la cobertura y moneda en el arreglo de coberturas
    let findCober = arrCoberturas.find((obj) => obj.identificador == item);
    let findMoneda = listMonedas.find((obj) => obj.identificador == item);

    const newData = [...coberAReclamar]; // Crear una copia nueva del array de objetos
    for (let obj of newData) {
      if (obj.id == id && type == 1) {
        obj.idCober = item;
        obj.nomCober = findCober.texto;
      } else if (obj.id == id && type == 2) {
        obj.SA = formatedNumberAndDecimalAndComa(e, 1);
      } else if (obj.id == id && type == 3) {
        obj.idMoneda = item;
        obj.nomMoneda = findMoneda.texto;
      }
    }
    setCoberAReclamar(newData);
    setMonedaCob(true)

  };
  //esta funcion elimina un elemento de la tabla
  const deleteCoberAReclamar = (event, id) => {
    if (event) {
      setCoberAReclamar((prevState) =>
        prevState.filter((obj, index) => obj.id != id)
      );
    }
  };

  useEffect(() => {
    console.log("Coberturas a reclamar: ", coberAReclamar);
  }, [coberAReclamar]);





  return (
    <form
      ref={formularioRef}
      onSubmit={async (e) => {
        try {
          setBtnAgregar(true);
          const {
            txtPoliza,
            txtFechaEmis,
            txtFechaVenc,
            txtNombreCont,
            txtApePatCont,
            txtApeMatCont,
            txtNombreAse,
            txtApePatAse,
            txtApeMatAse,
            txtCurp,
            txtRfc,
            // txtCorreoAse,
            selectSexo,
            selectProducto,
            txtNombreAgente,
            txtApePatAgente,
            txtApeMatAgente,
            txtClaveAgente,
            txtCorreoAgente,
            selectDAAgente,
          } = e.target.elements;
          e.preventDefault();
          let aux = {
            tipoTramite: tipoTram,
            POL_numpoliza: txtPoliza.value,
            POL_polizafecemisión: fechaEmi,
            POL_polizafinvigencia: fechaVen,
            PRO_idproductos: tipoCveProducto,
            PRO_descripcion: producto,

            PER_nombre_cont: txtNombreCont ? txtNombreCont.value : asegurado.nombre,
            PER_apePat_cont: txtApePatCont ? txtApePatCont.value : asegurado.apePat,
            PER_apeMat_cont: txtApeMatCont ? txtApeMatCont.value : asegurado.apeMat,

            PER_nombre_ase: txtNombreAse ? txtNombreAse.value : asegurado.nombre,
            PER_apePat_ase: txtApePatAse ? txtApePatAse.value : asegurado.apePat,
            PER_apeMat_ase: txtApeMatAse ? txtApeMatAse.value : asegurado.apeMat,
            PER_CURP_ase: txtCurp ? txtCurp.value : asegurado.curp,
            PER_RFC_ase: txtRfc ? txtRfc.value : asegurado.rfc,
            // PER_correo_ase: txtCorreoAse.value,
            PER_fechanac_ase: fechaNac,
            PER_sexo_ase: !selectSexo ? "Hombre" : asegurado.sexo,

            PER_nombre_agente: txtNombreAgente.value,
            PER_apePat_agente: txtApePatAgente.value,
            PER_apeMat_agente: txtApeMatAgente.value,
            PER_clave_agente: txtClaveAgente.value,
            PER_DA_agente: selectDAAgente.value,
            PER_correo_agente: txtCorreoAgente.value,
          };

          const fecha1 = moment(txtFechaEmis.value, "DD/MM/YYYY").toDate();
          const fecha2 = moment(txtFechaVenc.value, "DD/MM/YYYY").toDate();
          console.log(fechaNac)
          console.log(today.toISOString(today).slice(0, 10))

          //Verificar si ya existe un registro con ese numero de póliza, si si, rechazar registro
          //const result = await getDataToken('personasxpoliza/get_listado/');

          // if (result.length > 0) {
          //   let foundObject = false//result.find(obj => obj.POL_idpoliza?.POL_numpoliza === txtPoliza.value);
          //   if (foundObject) {
          //     setErrorNumPol(true)
          //     setErrorMessageNumPol("Ya existe un registro con ese número de póliza.");
          //     document.getElementById('txtPoliza').focus();
          //     setBtnAgregar(false);
          //     throw new Error("Ya existe un registro con ese número de póliza.");
          //   } else {
          //     if (!CONEXIONPM) {
          //       let result = await getDataToken(`configuraciongeneral/poliza_apiPM/?poliza=${txtPoliza.value}`)
          //       if (result.length === 0) {
          //         setErrorNumPol(false)
          //         setErrorMessageNumPol("");
          //       } else {
          //         setErrorNumPol(true)
          //         setErrorMessageNumPol("Ya existe un registro con ese número de póliza.");
          //         document.getElementById('txtPoliza').focus();
          //         setBtnAgregar(false);
          //         throw new Error("Ya existe un registro con ese número de póliza");
          //       }
          //     } else {
          //       setErrorNumPol(false)
          //       setErrorMessageNumPol("");
          //     }
          //   }
          // }}
          
          if (fecha1.getTime() === fecha2.getTime()) {
            console.log("Las fechas son iguales.");
            setErroFecEmis(true)
            setErrorMessageFecEmis("La fecha de emisión es la misma que la fecha de vencimiento.");
            document.getElementById('txtFechaEmis').focus();
            setBtnAgregar(false);
            throw new Error("Fecha inválida");
          } else if (fecha1.getTime() > fecha2.getTime()) {
            setErroFecEmis(true)
            setErrorMessageFecEmis("La fecha de emisión es mayor a la fecha de vencimiento.");
            document.getElementById('txtFechaEmis').focus();
            setBtnAgregar(false);
            throw new Error("Fecha inválida");
          } else {
            setErroFecEmis(false)
            setErrorMessageFecEmis("");
          }
          if(fechaNac== today.toISOString(today).slice(0, 10)){
            document.getElementById('txtFechaNac').focus();
            setBtnAgregar(false);
            throw new Error("Fecha inválida");

          }
          // if (validateCURP(txtCurp.value)) {
          //   setErrorMessageCURP("");
          //   setErrorCURP(false)
          // } else {
          //   setErrorCURP(true)
          //   setErrorMessageCURP("CURP inválido");
          //   throw new Error("CURP inválido");
          // }
          // if (validateRFC(txtRfc.value)) {
          //   setErrorMessageRFC("");
          //   setErrorRFC(false)
          // } else {
          //   setErrorRFC(true)
          //   setErrorMessageRFC("RFC inválido");
          //   throw new Error("RFC inválido");
          // }
          if (numPoliza !== "") {
            if (selectProducto.value == '0') {
              setErrorProd(true)
              throw new Error("Producto no seleccionado");
            } else {
              setErrorProd(false)
            }
            if (selectDAAgente.value == '0') {
              setErrorProd(true)
              throw new Error("Dirección de agencia no seleccionada");
            } else {
              setErrorProd(false)
            }
          }

          if (coberAReclamar.length > 0) {
            for (let cobertura of coberAReclamar) {
              if (!cobertura.idCober || !cobertura.SA || !cobertura.idMoneda) {
                document.getElementById("sCobertura_" + cobertura.id)?.focus();
                setBtnAgregar(false);
                throw new Error("Falta informacion en coberturas");
              }
            }
            const idSet = new Set();

            let validateArr = coberAReclamar.some(objeto => {
              if (idSet.has(parseInt(objeto.idCober))) {
                return true; // Hay un ID repetido
              }
              idSet.add(parseInt(objeto.idCober));
              return false;
            })
            if (validateArr) {
              setDataFeedBack(prevState => ({ ...prevState, title: "Duplicación de coberturas", description: "Asegúrate de no reclamar la misma cobertura más de una vez." }));
              setShowFeedback(true);
              setBtnAgregar(false);
              throw new Error("Hay un ID repetido en el arreglo.");
            } else {
              setDataFeedBack(prevState => ({ ...prevState, title: "", description: "" }));
            }
          } else {
            document.getElementById("inputCoberturas").focus();
            throw new Error("Falta informacion en coberturas");
          }
          if (selectSexo) {
            if (selectSexo.value == '0') {
              setErrorSexo(true)
              setBtnAgregar(false);
              throw new Error("Sexo no seleccionado");
            } else {
              setErrorSexo(false)
            }
          }
          //Validar que el tipo de trámite sea el mismo en las pólizas a reclamar 
          if (datosReclamacion.length > 0) {
            let validateArr = datosReclamacion.every(objeto => {
              if (objeto.tipoTramite == aux.tipoTramite) {
                return true; // Es el mismo trámite
              }
              return false;
            });
            if (validateArr) {
              setDataFeedBack(prevState => ({ ...prevState, title: "", description: "" }));
            } else {
              setDataFeedBack(prevState => ({ ...prevState, title: "Tipo de trámite diferente", description: "Asegúrate de que el tipo de trámite sea el mismo para las pólizas agregadas." }));
              setShowFeedback(true);
              setBtnAgregar(false);
              throw new Error("El trámite de reclamación es distinto a una póliza ya registrada.");
            }
          }

          ////console.log("Fecha del datepicker", txtFechaNac.value);
          //setDataForm(aux);
          setBtnAgregar(false);
          agregarFila(aux)
        } catch (e) {
          console.log(e)
        }

      }}
    >
      <DialogContent dividers style={{ minWidth: 600 }} >
        {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
          se necesita colocar entre un typogreaphy las veces que des salto de linea 
          o con un br*/}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
        >
          {/* Datos de la póliza */}

          <Grid item xs={12}>
            <h3-title-card style={{ textAlign: "center" }}>Datos de la póliza</h3-title-card>
          </Grid>
          <Grid item xs={3}>
            <TXTField
              id="txtPoliza"
              name="txtPoliza"
              label={"Número de póliza"}
              error={errorNumPol} helperText={errorMessageNumPol}
              onChange={(e) => setNumPoliza(e.target.value)}
            />
          </Grid>

          <Grid item xs={3} >
            <DatePicker2 label={"Fecha de emisión"} fecha={fechaEmi} error={errorFecEmis}
              helperText={errorMessageFecEmis} id="txtFechaEmis" name="txtFechaEmis"
              maxDate={today} onDataChange={(e) => setFechaEmi(e.toISOString(e).slice(0, 10))}
              required={numPoliza !== "" ? true : false}
              readOnly={numPoliza === "" ? true : false}
            />
          </Grid>
          <Grid item xs={3} >
            <DatePicker2 label={"Fecha de vencimiento"} fecha={fechaVen} id="txtFechaVenc" name="txtFechaVenc" onDataChange={(e) => {
              setFechaVen(e.toISOString(e).slice(0, 10))
            }}
              required={numPoliza !== "" ? true : false}
              readOnly={numPoliza === "" ? true : false}
            />
          </Grid>
          <Grid item xs={3} >
            <Select2 disabled={numPoliza === "" ? true : false} id="selectProducto" name="selectProducto" error={errorProd} value={tipoCveProducto} defaultValue={tipoCveProducto} helperOnClick={() => console.log(":)")} FormHelperText="Agregar otro" label="Nombre del producto" array={tipoProductos} onChange={(e) => handleChangeProduct(e.target.value)} />
          </Grid>
          {/* <Grid item xs={3}>
            <TXTField
              id="txtSumaAse"
              name="txtSumaAse"
              label={"Suma asegurada de la cobertura"}
              //onInput={(e) => OnlyNumber(e.target, 4)}
              onChange={(e) => setSumaAse(formatedNumberAndDecimalAndComa(e, 1))}
              required={numPoliza !== "" ? true : false}
              readOnly={numPoliza === "" ? true : false}
              value={sumaAse}
            />
          </Grid>
          <Grid item xs={3}>
            <Select1 id="selectMonedaSuma" name="selectMonedaSuma" value={moneda} label="Moneda de la cobertura" array={listMonedas} onChange={(e) => setMoneda(e.target.value)} />
          </Grid> */}
          {/* Datos del agente */}
          <Grid item xs={3}>
            <TXTField
              id="txtNombreAgente"
              name="txtNombreAgente"
              label={"Nombre del agente"}
              required={numPoliza !== "" ? true : false}
              readOnly={numPoliza === "" ? true : false}
            />
          </Grid>
          <Grid item xs={3}>
            <TXTField
              id="txtApePatAgente"
              name="txtApePatAgente"
              label={"Apellido paterno del agente"}
              required={numPoliza !== "" ? true : false}
              readOnly={numPoliza === "" ? true : false}
            />
          </Grid>
          <Grid item xs={3}>
            <TXTField
              id="txtApeMatAgente"
              name="txtApeMatAgente"
              label={"Apellido materno del agente"}
              required={numPoliza !== "" ? true : false}
              readOnly={numPoliza === "" ? true : false}
            />
          </Grid>
          <Grid item xs={3}>
            <TXTField
              id="txtClaveAgente"
              name="txtClaveAgente"
              label={"Clave del agente"}
              required={numPoliza !== "" ? true : false}
              readOnly={numPoliza === "" ? true : false}
              onInput={(e) => LimitarDigitos(e.target, 8)}
            />
          </Grid>
          <Grid item xs={3}>
            {/* <TXTField
              id="txtDAAgente"
              name="txtDAAgente"
              label={"Dirección de agencia del agente"}
              required={numPoliza !== "" ? true : false}
              readOnly={numPoliza === "" ? true : false}
            /> */}
            <Select2
              value={diragenciaId}
              defaultValue={diragenciaId}
              id="selectDAAgente"
              name="selectDAAgente"
              disabled={numPoliza == "" ? true : false}
              label="Dirección de agencia del agente"
              array={listAgencias} 
              onChange={(e)=>setDirAgenciaId(e.target.value)}
              />

          </Grid>
          <Grid item xs={3}>
            <TXTField
              id="txtCorreoAgente"
              name="txtCorreoAgente"
              label={"Correo electrónico del agente"}
              required={numPoliza !== "" ? true : false}
              readOnly={numPoliza === "" ? true : false}
              type="mail"
            />
          </Grid>
          {/* Datos de la cobertura */}
          <Grid item xs={12}>
            <h3-title-card style={{ textAlign: "center" }}>Tipo de trámite</h3-title-card>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          style={{ marginTop: '10px', marginBottom: '20px' }}
        >
          <Grid item xs={"auto"}>
            <RadioDinamico row={true}
              array={[
                { "id": TRAM_BENEFICIOSEV.id, "value": TRAM_BENEFICIOSEV.id, "label": TRAM_BENEFICIOSEV.descripcion },
                { "id": TRAM_FALLECIMIENTO.id, "value": TRAM_FALLECIMIENTO.id, "label": TRAM_FALLECIMIENTO.descripcion },
                { "id": TRAM_VENCIMIENTO.id, "value": TRAM_VENCIMIENTO.id, "label": TRAM_VENCIMIENTO.descripcion },
                { "id": TRAM_RETIROS.id, "value": TRAM_RETIROS.id, "label": TRAM_RETIROS.descripcion },
                { "id": TRAM_RESCATES.id, "value": TRAM_RESCATES.id, "label": TRAM_RESCATES.descripcion },
              ]}
              onChange={(event) => handleChangeTram(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <input //Input auxiliar para hacer focus 
              type="text"
              id="inputCoberturas"
              style={{ position: 'absolute', left: '-9999px', opacity: '0' }}
            />
          </Grid>
          {/* <Grid item xs={4}>
            <Select1 label="Cobertura" array={arrCoberturas} onChange={(e) => console.log(e.target.value)} />
          </Grid>
          <Grid item xs={4}>
            <TXTField
              id="txtSumaAse"
              name="txtSumaAse"
              label={"Suma asegurada de la cobertura"}
              //onInput={(e) => OnlyNumber(e.target, 4)}
              onChange={(e) => setSumaAse(formatedNumberAndDecimalAndComa(e, 1))}
              required={numPoliza !== "" ? true : false}
              readOnly={numPoliza === "" ? true : false}
              value={sumaAse}
            />
          </Grid>
          <Grid item xs={2}>
            <Select1 id="selectMonedaSuma" name="selectMonedaSuma" value={moneda} label="Moneda" array={listMonedas} onChange={(e) => setMoneda(e.target.value)} />
          </Grid> */}
          {/* {tipoTram === 0 || tipoTram == TRAM_RESCATES.id || tipoTram == TRAM_RETIROS.id ? null : coberAReclamar.map((obj, index) => { */}
          {tipoTram == TRAM_FALLECIMIENTO.id || tipoTram == TRAM_BENEFICIOSEV.id || tipoTram == TRAM_VENCIMIENTO.id ? coberAReclamar.map((obj, index) => {
            console.log("cobertura object", obj)
            return <>
              <Grid item xs={4}>
                <Select2 label={tipoTram == TRAM_FALLECIMIENTO.id && index === 0 ? "Cobertura básica" : "Cobertura"} disabled={tipoTram == TRAM_FALLECIMIENTO.id && index === 0 ? true : false} defaultValue={tipoTram == TRAM_FALLECIMIENTO.id && index === 0 ? COBERTURA_FALLE_DEFAULT.id : 0} value={tipoTram == TRAM_FALLECIMIENTO.id && index === 0 ? COBERTURA_FALLE_DEFAULT.id : obj.idCober} id={`sCobertura_${obj.id}`} name={obj.nomCober} array={arrCoberturas} onChange={(e) => updateCoberAReclamar(e, obj.id, 1)} />
              </Grid>
              <Grid item xs={4}>
                <TXTField
                  label={"Suma asegurada de la cobertura"}
                  autoComplete="off"
                  onChange={(e) => updateCoberAReclamar(e, obj.id, 2)}
                  value={obj.SA}
                />
              </Grid>
              <Grid item xs={2}>
                {!monedaCob ? null :
                  <Select2 defaultValue={obj.idMoneda} value={obj.idMoneda} label="Moneda" array={listMonedas}
                    onChange={(e) => {
                      setMonedaCob(false);
                      updateCoberAReclamar(e, obj.id, 3)
                    }} />}

              </Grid>
              {index === 0 ? <Grid item xs={1}>
              </Grid> :
                <Grid item xs={1}>
                  <DialogIconDeleteConfirm title={"Eliminar cobertura"} tooltipTitle={"Eliminar cobertura"} description={`¿Estás seguro de eliminar la cobertura`}
                    onClickAccept={(e) => deleteCoberAReclamar(e, obj.id)} />
                </Grid>}
            </>
          }) : null}
        </Grid>
        {tipoTram === 0 || tipoTram == TRAM_RESCATES.id || tipoTram == TRAM_RETIROS.id ? null :
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={3}
          >
            <Grid item xs={3}>
              <Btverreport
                onClick={handleClickAddCober}
                label={
                  <FormattedMessage
                    id="dictAgreOtra"
                    defaultMessage="Agregar otra cobertura"
                  />
                }
              />
            </Grid>
          </Grid>}
        {!showFeedback ? null : <DialogFeedback open={true} button={dataFeedBack.button} title={dataFeedBack.title} description={dataFeedBack.description} clickButton={() => setShowFeedback(false)} />}


        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
        >
          {/* Datos del asegurado */}
          <Grid item xs={12}>
            <h3-title-card style={{ textAlign: "center", marginTop: "20px" }}>Datos del asegurado</h3-title-card>
          </Grid>
          <Grid item xs={4}>
            <TXTField
              onChange={(e) => {
                setAsegurado(prevState => ({ ...prevState, nombre: formatNumberString(e.target.value, 50, true, 3) }));
              }}
              id="txtNombreAse"
              name="txtNombreAse"
              label={"Nombre"}
              maxlength="25"
              required
              value={asegurado.nombre}
            />
          </Grid>
          <Grid item xs={4}>
            <TXTField
              onChange={(e) => {
                setAsegurado(prevState => ({ ...prevState, apePat: formatNumberString(e.target.value, 50, true, 3) }));
              }}
              id="txtApePatAse"
              name="txtApePatAse"
              label={"Apellido paterno"}
              required
              value={asegurado.apePat}
            />
          </Grid>
          <Grid item xs={4}>
            <TXTField
              onChange={(e) => {
                setAsegurado(prevState => ({
                  ...prevState, apeMat: formatNumberString(e.target.value, 50, true, 3) }));
              }}
              id="txtApeMatAse"
              name="txtApeMatAse"
              label={"Apellido materno"}
              required
              style={{ position: "sticky" }}
              value={asegurado.apeMat}
            />
          </Grid>
          <Grid item xs={4} >
            <DatePicker2 label={"Fecha de nacimiento"} id="txtFechaNac" name="txtFechaNac" maxDate={today}
              onDataChange={(e) => {
                setFechaNac(e.toISOString(e).slice(0, 10))
                setAsegurado(prevState => ({ ...prevState, fechaNac: e.toISOString(e).slice(0, 10) }))
              }}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TXTField
              error={errorCURP} helperText={errorMessageCURP}
              id="txtCurp"
              name="txtCurp"
              label={"CURP"}
              value={asegurado.curp}
              onChange={(e) => {
                setAsegurado(prevState => ({ ...prevState, curp: formatNumberString(e.target.value, 18, true, 4) }));
              }}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TXTField
              error={errorRFC} helperText={errorMessageRFC}
              id="txtRfc"
              name="txtRfc"
              label={"RFC"}
              onChange={(e) => {
                setAsegurado(prevState => ({ ...prevState, rfc: formatNumberString(e.target.value, 13, true, 4) }));
              }}
              value={asegurado.rfc}
              required
            />
          </Grid>
          {/* <Grid item xs={4}>
            <TXTField
              id="txtCorreoAse"
              name="txtCorreoAse"
              label={"Correo electrónico"}
              required
            />
          </Grid> */}
          <Grid item xs={4}>
            <Select1 error={errorSexo} id="selectSexo" name="selectSexo" label="Seleccione su sexo" array={arraySexo}
              onChange={(e) => { setAsegurado(prevState => ({ ...prevState, sexo: e.target.value == 1 ? "Hombre" : "Mujer" })) }}
              required></Select1>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={"auto"} >
              <h3-subtitle-card>
                <FormattedMessage
                  id="dudaPreTra"
                  defaultMessage="¿El asegurado es también el contratante?"
                />
              </h3-subtitle-card>
            </Grid>
            <Grid item xs={"auto"} style={{ marginLeft: '20px' }}>
              <Check2
                option1={<FormattedMessage id="globNo" defaultMessage="No" />}
                option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
                //checked={aseguradoSi}
                //disabled={tipoTramite === TRAM_FALLECIMIENTO ? true : false}
                onChange={toogleSwitch}
              />
            </Grid>
          </Grid>
          {/* Datos del contratante */}
          {aseguradoSi ? null :
            <>
              <Grid item xs={12}>
                <h3-title-card style={{ textAlign: "center", marginTop: '20px' }}>Datos del contratante</h3-title-card>
              </Grid>
              <Grid item xs={4}>
                <TXTField
                  onChange={(e) => {
                    setNombreCont(formatNumberString(e.target.value, 50, true, 3));
                  }}
                  id="txtNombreCont"
                  name="txtNombreCont"
                  label={"Nombre"}
                  maxlength="25"
                  required
                  value={nombreCont}
                />
              </Grid>
              <Grid item xs={4}>
                <TXTField
                  onChange={(e) => {
                    setApePatCont(formatNumberString(e.target.value, 50, true, 3));
                  }}
                  id="txtApePatCont"
                  name="txtApePatCont"
                  label={"Apellido paterno"}
                  required
                  value={apePatCont}
                />
              </Grid>
              <Grid item xs={4}>
                <TXTField
                  onChange={(e) => {
                    setApeMatCont(formatNumberString(e.target.value, 50, true, 3));
                  }}
                  id="txtApeMatCont"
                  name="txtApeMatCont"
                  label={"Apellido materno"}
                  required
                  style={{ position: "sticky" }}
                  value={apeMatCont}
                />

              </Grid>
            </>
          }
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item>
              <ButtonVino
                label={!btnAgregar ? "Agregar" : "Agregando..."}
                type="submit"
                estatus={btnAgregar}
              />
            </Grid>

          </Grid>

        </Grid>
        <Grid item xs={12}>
          <TablaDatosReclamacion data={datosReclamacion} iconOnClick={DeleteItems} />
        </Grid>
      </DialogContent>
      {/*es la tercer sección que engloba los botones */}
      <DialogActions>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          marginTop={1}
        ><Grid item>
            <ButtonVino
              label="Cancelar"
              onClick={() => props.handleClose(true)}
            />
          </Grid>
          <Grid item>
            <ButtonVino
              label="Guardar"
              estatus={datosReclamacion.length <= 0}
              onClick={() => saveData()}
            />
            {showDialog ? showDialogConfirm(true) : null}
            {showDialogError ? showDialogConfirm(false) : null}
          </Grid>


          {/* <Grid item >
            <Link
              to={!btnSiguiente ? "/NuevoTramite" : '#'}
              state={{ prueba }}
            >
              <ButtonVino
                id="enviar"
                variant="contained"
                label="Siguiente"
                estatus={btnSiguiente}
              />
            </Link>
          </Grid> */}
        </Grid>
      </DialogActions>
    </form>
  );
}
