import * as React from "react";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import IconPagos from "../../../Tools/Icons/IconPagos";
import "../../dictamen.css";

//idioma
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export default function PopperPagos(props) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const infoFolio = props.detalleFolio;
  const row = props.row;
  const poliza  = props.poliza;
  const idPoliza  = props.idPoliza;

  const showPopper = (event) => {
    setAnchorEl(event.currentTarget);
    !open ? setOpen(true) : setOpen(false);
  };
  const hidePopper = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(false);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  return (
    <div>
      <IconPagos tooltipTitle={props.tooltipTitle} onClick={showPopper} />
      <Popper id={id} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              sx={{ border: 1, p: 1, bgcolor: "background.paper" }}
              onMouseLeave={hidePopper}
            >
              <ul className="ul-dictamen-reservas">
                <li>
                  <h3-subtitle-card>
                    <a href="">
                      <FormattedMessage
                        id="dictConsPag"
                        defaultMessage="Consulta de pagos"
                      />
                    </a>
                  </h3-subtitle-card>
                </li>
                <li>
                  <h3-subtitle-card>
                    <Link style={{ cursor: "pointer" }}
                      to="/dictamen/detalle_folio/realizar_pagos"
                      state={{infoFolio,  row , idPoliza}}>
                      <FormattedMessage
                        id="dictConsPag"
                        defaultMessage="Realizar un pago"
                      />
                    </Link>
                  </h3-subtitle-card>
                </li>
                <li>
                <h3-subtitle-card>
                    <Link style={{ cursor: "pointer" }}
                      to="/dictamen/detalle_folio/finiquito"
                      state={{infoFolio,  row , idPoliza}}>
                      <FormattedMessage
                        id="dictGenFin"
                        defaultMessage="Generar finiquito"
                      />
                    </Link>
                  </h3-subtitle-card>
                </li>
              </ul>
            </Box>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
