import React from "react";
import { ConsulComunicacion } from "../../../components/Communication/ConsulComunicacion";
import MyComponent from "../../../components/Tools/ButtonLoading/ButtonLoading";


export function consul() {
    return (
        <div>
          
          <MyComponent componentToRender={ConsulComunicacion} />
        
        </div>
    );
}