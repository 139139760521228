import * as React from "react";

import Recorrido from "./Recorrido";
import Recorrido3 from "./Recorrido3";
import DatePicker from "../DatePicker/DatePicker";
import Radio2 from "../RadioButton/Radio2";
import Radio3 from "../RadioButton/Radio3";
import Radio4 from "../RadioButton/Radio4";
import TextField from "../TextField/TextField";
import RadioInput from "../RadioButton/RadioInput";
import BTUpload from "../Bottones/BTUpload";
import { Select1 } from "../Selectss/Select1";
import Check from "../CheckBox/Check";

const array = [
  {
    id: 39999,
    SEP_CodPostal: "34377",
    SEP_Asenta: "Rodriguez Puebla",
    SEP_Tipo_Asenta: "Ejido",
    SEP_Municipio: "Durango",
    SEP_Estado: "Durango",
    SEP_Ciudad: "",
    SEP_Centro_Codigo: "34271",
    SEP_Cod_Estado: "10",
    SEP_Cod_ofi_central: "34271",
    SEP_Clv_Municipio: "5",
    SEP_Clv_Ciudad: "",
    SEP_fechaModificacion: "2022-09-30T00:00:00-05:00",
    SEP_nombreModifica: "Alfredo Aguirre",
    texto: "Rodriguez Puebla",
  },
  {
    id: 40000,
    SEP_CodPostal: "34377",
    SEP_Asenta: "Rancho el Manzano",
    SEP_Tipo_Asenta: "Rancheria",
    SEP_Municipio: "Durango",
    SEP_Estado: "Durango",
    SEP_Ciudad: "",
    SEP_Centro_Codigo: "34271",
    SEP_Cod_Estado: "10",
    SEP_Cod_ofi_central: "34271",
    SEP_Clv_Municipio: "5",
    SEP_Clv_Ciudad: "",
    SEP_fechaModificacion: "2022-09-30T00:00:00-05:00",
    SEP_nombreModifica: "Alfredo Aguirre",
    texto: "Rancho el Manzano",
  },
  {
    id: 40001,
    SEP_CodPostal: "34377",
    SEP_Asenta: "Rancho la Vibora",
    SEP_Tipo_Asenta: "Rancheria",
    SEP_Municipio: "Durango",
    SEP_Estado: "Durango",
    SEP_Ciudad: "",
    SEP_Centro_Codigo: "34271",
    SEP_Cod_Estado: "10",
    SEP_Cod_ofi_central: "34271",
    SEP_Clv_Municipio: "5",
    SEP_Clv_Ciudad: "",
    SEP_fechaModificacion: "2022-09-30T00:00:00-05:00",
    SEP_nombreModifica: "Alfredo Aguirre",
    texto: "Rancho la Vibora",
  },
];

export default function PagReco() {
  return (
    <div>
      <Recorrido name1="Name1" enlace="/" name2="name2" />
      <br />
      <Recorrido3
        name1="Name1"
        enlace="/"
        name2="name2"
        enlace2="/"
        name3="name3"
      />
      <br />
      <DatePicker titulo="titulo" fecha="11/11/1997" />
      <br />
      <Radio2
        valordefault="False"
        valor1="False"
        nombre1="Falso"
        valor2="verdadero"
        nombre2="verdadero"
      />
      <br />
      <Radio3
        valordefault="abcd"
        valor1="ab"
        nombre1="ab"
        valor2="abc"
        nombre2="abc"
        valor3="abcd"
        nombre3="abcd"
      />
      <br />
      <Radio4
        valordefault="0"
        valor1="0"
        nombre1="0"
        valor2="1"
        nombre2="1"
        valor3="2"
        nombre3="2"
        nombre4="3"
        valor4="3"
      />
      <br />
      <RadioInput />
      <br />
      <TextField
        id="prueba1"
        type="text"
        label="Text"
        helperText="outlined"
        size="small"
        required={true}
      />
      <TextField
        id="prueba1"
        type="password"
        label="Password"
        helperText="outlined"
        size="small"
        required={true}
      />
      <TextField
        id="prueba1"
        type="email"
        label="Email"
        helperText="outlined"
        size="small"
        required={true}
      />
      <BTUpload accept=".jpg, .jpeg, .png, .pdf" />
      <TextField
        id="prueba1"
        type="text"
        label="Multiline"
        helperText="outlined"
        size="small"
        required={true}
        multiline={true}
      />
      <TextField
        id="prueba1"
        type="text"
        label="Multiline with rows"
        helperText="outlined"
        size="small"
        required={true}
        multiline={true}
        rows={4}
      />
      <br />
      <Select1 array={array} />
      <br />
      {/*checked true=habilitado false=deshabilitado
      //start Izquierda //end derecha //top Arriba //bottom Abajo
      MINUSCULAS
      estatus=disabled
      Para la opcion de checked con un valor definido checar este url https://mui.com/material-ui/react-switch/
      */}
      <Check estatus={false} position="start" label="Inicio" />
      <br />
      <Check estatus={true} position="end" label="Final" />
    </div>
  );
}
