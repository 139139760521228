//  David Ruiz 10/2022

import React from 'react';
import IconButton from '@mui/material/IconButton';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';


const Btconsregistro=(props)=>{
    return(
        <IconButton aria-label=" ContentPasteSearchIcon" color="icons"
        //aqui va el enlace
        href={props.enlace}
        onClick={props.onClick}
        onChange={props.onChange}
        id={props.id}
        //ojo aqui es disabled
        disabled={props.estatus} //true para desabilitar y false para habilitar
        >
        < ContentPasteSearchIcon />
        </IconButton>
        );
    
}
export default Btconsregistro;