import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../../Tools/Table/StyledTableCell";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { formatofecha } from "../../../api/getUmbrales";

export default function TablaInfo(props) {
  const datos = props.data;
  console.log(datos);

  return (
    <div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }} style={{ position: "sticky" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">
                  {
                    <FormattedMessage
                      id="globNoPoli"
                      defaultMessage="No. de Póliza"
                    />
                  }
                </StyledTableCell>
                <StyledTableCell align="left">
                  {
                    <FormattedMessage
                      id="globFechEm"
                      defaultMessage="Fecha de emisión"
                    />
                  }
                </StyledTableCell>
                <StyledTableCell align="left">
                  {
                    <FormattedMessage
                      id="globPagaHa"
                      defaultMessage="Pagado hasta"
                    />
                  }
                </StyledTableCell>
                <StyledTableCell align="left">
                  {
                    <FormattedMessage
                      id="globFechPa"
                      defaultMessage="Fecha de pago"
                    />
                  }
                </StyledTableCell>
                {/*  */}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={datos?.POL_idpoliza?.POL_idpoliza}>
                <StyledTableCell align="center">
                  {datos?.POL_idpoliza?.POL_numpoliza}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {moment(datos?.POL_idpoliza?.POL_polizafecemisión).format(
                    formatofecha
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {moment(datos?.POL_idpoliza?.POL_polizapahasta).format(
                    formatofecha
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {moment(datos?.POL_idpoliza?.POL_polizafecpago).format(
                    formatofecha
                  )}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <br />
      <br />
    </div>
  );
}
