import { TOKENLOGIN } from "../utils/constans";
import { getURL } from "./getURL";

export async function generatePDFWithFetch(url, json) {
  try {
    const response = await fetch(`${await getURL()}${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer` + token, // Ensure this matches your Django view's expected content type
      },
      body: JSON.stringify(json),
    });

    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.statusText}`);
    }

    const pdfBlob = await response.blob(); // Wait for the response in blob format (PDF)
    const pdfUrl = URL.createObjectURL(pdfBlob);

    const newTab = window.open();
    newTab.document.write(
      `<iframe width="100%" height="100%" src="${pdfUrl}"></iframe>`
    );

    return pdfBlob; // Devuelve el blob del PDF
  } catch (error) {
    console.error("Error al enviar datos y obtener el PDF:", error);
    return null; // En caso de error, devuelve null o maneja el error según sea necesario
  }
}

export async function generatePDFWithFetchSin(url, json) {
  try {
    const response = await fetch(`${await getURL()}${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer` + token, // Ensure this matches your Django view's expected content type
      },
      body: JSON.stringify(json),
    });

    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.statusText}`);
    }

    const pdfBlob = await response.blob(); // Wait for the response in blob format (PDF)

    return pdfBlob; // Devuelve el blob del PDF
  } catch (error) {
    console.error("Error al enviar datos y obtener el PDF:", error);
    return null; // En caso de error, devuelve null o maneja el error según sea necesario
  }
}
