import React from "react";
import { CompFinalizarTramite } from "../../../components/Recepcion/CompFinalizarTramite";


//import  { BotonesLayout } from '../../components/Bottones/BotonesLayout';

export function FinalizarTramite() {
  return (
    <div>
     
      <CompFinalizarTramite />

    </div>
  );
}
