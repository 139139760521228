import React, { useState } from "react";
import "./Contable.css";
import "../../css/estilo.css";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Recorrido from "../Tools/BreadCrumbs/Recorrido";
import { Grid } from "@mui/material";
//imagenes del menu
import catalogoCuentas_on from "../../img/catalogoCuentas_on.svg";
import consultasLinea_on from "../../img/consultasLinea_on.svg";
import integradorContabilidad_on from "../../img/integradorContabilidad_on.svg";
import reportePrescripcion_on from "../../img/reportePrescripcion_on.svg";
import reportesEstadisticas_on from "../../img/reportesEstadisticas_on.svg";
import catalogoCuentas_off from "../../img/catalogoCuentas_off.svg";
import consultasLinea_off from "../../img/consultasLinea_off.svg";
import integradorContabilidad_off from "../../img/integradorContabilidad_off.svg";
import reportePrescripcion_off from "../../img/reportePrescripcion_off.svg";
import reportesEstadisticas_off from "../../img/reportesEstadisticas_off.svg";
//importaciones de componentes donde se realizan las funciones
import CompCuentaAsiento from "./Menu/CompCuentaAsiento";
import FormPrescriptionReport from "./Elements/FormPrescriptionReport";
import CompIntegrador from "./Menu/CompIntegrador";
import CompEstadisticas from "./Menu/CompEstadisticas";
import CompConsultaLinea from "./Menu/CompConsultaLinea";
//Boton de regresar 
import ReturnPage from "../Tools/Bottones/ButtonReturn";


const CompContable = () => {
  //Creacion de las variables para el cambio de las imagenes del menu
  const [catalogoCuentas, setCatalogoCuentas] = useState(false);
  const [consultaLinea, setConsultaLinea] = useState(false);
  const [integrador, setIntegrador] = useState(false);
  const [prescripcion, setPrescripcion] = useState(false);
  const [estadisticas, setEstadisticas] = useState(false);
  
  //Metodo que tiene el menu de estadistico contable
  const seleccionarMenu = () => {
    
    return (
      <React.Fragment>
        {/* ÍCONOS */}
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          className="grid-container-menu-all"
        >
          {/* Ícono de Cuentas y asientos contables */}
          <Grid
            xs={2.4}
            className="grid-item-menu"
            //En onClick ponemos lo que se va a mostrar en true
            onClick={() => {
              setCatalogoCuentas(true);
              setConsultaLinea(false);
              setIntegrador(false);
              setPrescripcion(false);
              setEstadisticas(false);
            }}
          >
            {/*Es el cambio de imagenes */}
            {!catalogoCuentas ? (
              <img
                className="img-iconos-comp"
                src={catalogoCuentas_off}
                alt="cuentasAsientos"
              />
            ) : (
              <img
                className="img-iconos-comp"
                src={catalogoCuentas_on}
                alt="CuentasAsientos"
              />
            )}
            {!catalogoCuentas ? (
              /*Nombre que aparecera en el menu */
              <h3-contable>
                Cátalogo de cuentas y asientos contables
              </h3-contable>
            ) : (
              /*Nombre que aparecera en el menu en color rojo */
              <h3-contable-red>
                Cátalogo de cuentas y asientos contables
              </h3-contable-red>
            )}
          </Grid>
          {/* Ícono de integrador de contabilidad*/}
          <Grid
            xs={2.4}
            className="grid-item-menu"
            //En onClick ponemos lo que se va a mostrar en true
            onClick={() => {
              setCatalogoCuentas(false);
              setConsultaLinea(false);
              setIntegrador(true);
              setPrescripcion(false);
              setEstadisticas(false);
            }}
          >
            {/*Es el cambio de imagenes */}
            {!integrador ? (
              <img
                className="img-iconos-comp"
                src={integradorContabilidad_off}
                alt="Integrador"
              />
            ) : (
              <img
                className="img-iconos-comp"
                src={integradorContabilidad_on}
                alt="Integrador"
              />
            )}
            {!integrador ? (
              /*Nombre que aparecera en el menu */
              <h3-contable>Integrador de contabilidad</h3-contable>
            ) : (
              /*Nombre que aparecera en el menu en color rojo */
              <h3-contable-red>Integrador de contabilidad</h3-contable-red>
            )}
          </Grid>
          {/* Ícono de reportes y estadísitcas */}
          <Grid
            xs={2.4}
            className="grid-item-menu"
            //En onClick ponemos lo que se va a mostrar en true 
            onClick={() => {
              setCatalogoCuentas(false);
              setConsultaLinea(false);
              setIntegrador(false);
              setPrescripcion(false);
              setEstadisticas(true);
            }}
          >
            {/*Es el cambio de imagenes */}
            {!estadisticas ? (
              <img
                className="img-iconos-comp"
                src={reportesEstadisticas_off}
                alt="Integrador"
              />
            ) : (
              <img
                className="img-iconos-comp"
                src={reportesEstadisticas_on}
                alt="Integrador"
              />
            )}
            {!estadisticas ? (
              /*Nombre que aparecera en el menu*/
              <h3-contable>Reportes y estadísticas</h3-contable>
            ) : (
              /*Nombre que aparecera en el menu en color rojo */
              <h3-contable-red>Reportes y estadísticas</h3-contable-red>
            )}
          </Grid>
          {/* Ícono de consulta en línea */}
          <Grid
            xs={2.4}
            className="grid-item-menu"
            //En onClick ponemos lo que se va a mostrar en true
            onClick={() => {
              setCatalogoCuentas(false);
              setConsultaLinea(true);
              setIntegrador(false);
              setPrescripcion(false);
              setEstadisticas(false);
            }}
          >
            {/*Es el cambio de imagenes */}
            {!consultaLinea ? (
              <img
                className="img-iconos-comp"
                src={consultasLinea_off}
                alt="Integrador"
              />
            ) : (
              <img
                className="img-iconos-comp"
                src={consultasLinea_on}
                alt="Integrador"
              />
            )}
            {!consultaLinea ? (
              /*Nombre que aparecera en el menu */
              <h3-contable>Consultas en línea</h3-contable>
            ) : (
              /*Nombre que aparecera en el menu en color rojo */
              <h3-contable-red>Consultas en línea</h3-contable-red>
            )}
          </Grid>
          {/* Ícono de reporde de prescripción */}
          <Grid
            xs={2.4}
            className="grid-item-menu"
            //En onClick ponemos lo que se va a mostrar en true
            onClick={() => {
              setCatalogoCuentas(false);
              setConsultaLinea(false);
              setIntegrador(false);
              setPrescripcion(true);
              setEstadisticas(false);
            }}
          >
            {/*Es el cambio de imagenes */}
            {!prescripcion ? (
              <img
                className="img-iconos-comp"
                src={reportePrescripcion_off}
                alt="Integrador"
              />
            ) : (
              <img
                className="img-iconos-comp"
                src={reportePrescripcion_on}
                alt="Integrador"
              />
            )}
            {!prescripcion ? (
              /*Nombre que aparecera en el menu*/
              <h3-contable>Reporte de prescripción</h3-contable>
            ) : (
              /*Nombre que aparecera en el menu en color rojo */
              <h3-contable-red>Reporte de prescripción</h3-contable-red>
            )}
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center">
          {/*En esta parte asignamos lo que se ocupara en el menu y es donde se mandan a llamar los componentes*/}
          {!catalogoCuentas ? null : (
            <Grid item xs={12}>
              <CompCuentaAsiento />
            </Grid>
          )}          
          {!integrador ? null : (
            <Grid item xs={12}>
              <CompIntegrador />
            </Grid>
          )}
          {!estadisticas ? null : (
            <Grid item xs={12}>
              <CompEstadisticas />
            </Grid>
          )}
          {!consultaLinea ? null : (
            <Grid item xs={12}>
              <CompConsultaLinea />
            </Grid>
          )}
          {!prescripcion ? null : (
            <Grid item xs={12}>
              <FormPrescriptionReport />
            </Grid>
          )}
        </Grid>
      </React.Fragment> 
    );
  };


  {
    /*Este return es el que maneja las vista en  SINAY*/
  }
  return (
    <div className="div-parent">
      <Alert severity="warning">
        <AlertTitle>
          <strong>Módulo en construcción</strong>
        </AlertTitle>
      </Alert>
      <div className="div-breadcrumbs">
        {/*navegador*/}
        <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems="center">
          <Grid item xs={4}>
          <Recorrido name1="Inicio" enlace="/menu" name2="Estadístico Contable" />
          </Grid>
          <Grid item xs={1.3}>
          <ReturnPage href="/menu" />
          </Grid>      
        </Grid>        
      </div>
      <div className="div-container">
        {/*Contenido*/}
        {/*Llamamos nuestro metodo que vamos a ocupar para mostrar nuestros menus*/}
        {seleccionarMenu()}
      </div>
    </div>
  );
};
export default CompContable;
