//Este componente no esta definido en las maquetas y en tch solo se menciona

import React, { useState } from "react";
import "../../css/estilo.css";
import { Grid, TextField } from "@mui/material";
import SelectTipoMuerte from "./Elements/selectsRecepcion/SelectTipoMuerte";

import { isValidCURP } from "./Elements/Functions/CURP";

export function CompFinalizarTramite() {
  const [curp, setCurp] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    setCurp(event.target.value);
    setError(!isValidCURP(event.target.value));
  };

  return (
    <div className="div-parent">
      <div className="div-breadcrumbs"></div>
      <TextField
        label="CURP"
        value={curp}
        onChange={handleChange}
        error={error}
        helperText={error && "El CURP es inválido."}
      />
      <div className="div-container"></div>
    </div>
  );
}
