//  David Ruiz 10/2022

import React from 'react';
import IconButton from '@mui/material/IconButton';
import SourceIcon from '@mui/icons-material/Source';

const BtExpsinn=(props)=>{
    return(
        <IconButton aria-label="SourceIcon" color="icons"   
        //aqui va el enlace
        href={props.enlace}
        onClick={props.onClick}
        onChange={props.onChange}
        id={props.id}
        //ojo aqui es disabled
        //true para desabilitar y false para habilitar
        disabled={props.estatus}>
        < SourceIcon />
      </IconButton>
        );
    }

export default BtExpsinn;