import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TramiteBeneficiario from "../Forms/FormTramiteBeneficiario";
import TramiteAgente from "../Forms/FormTramiteAgente";
import TramiteOtro from "../Forms/FormTramiteOtro";
import TramiteInterno from "../Forms/FormTramiteInterno";
import TramiteContratante from "../Forms/FormTramiteContratante";
import {
  PERR_AGENTE,
  PERR_BENEFICIARIO,
  PERR_CONTRATANTE,
  PERR_OTRO,
  PERR_INTERNO
} from "../../../../utils/constanstoken";
import { Select1 } from "../../../Tools/Selectss/Select1";
//idioma


export default function SelectorPersona(props) {

  
  const [values, setValues]= useState(
    {
      beneficiario : false,
      contratante: false, 
      agente: false,
      otro: false, 
      interno: false
    }
  )
  const [idRolPer, setIdRolPer] = useState(0);


const handleChange = (event) => {
    var inputValue = parseInt(event.target.value)
    setIdRolPer(inputValue)
    if (inputValue === PERR_AGENTE.id) { 
      setValues({
        beneficiario : false,
        contratante: false, 
        agente: true,
        otro: false, 
        interno: false
      });
    } else if (inputValue === PERR_BENEFICIARIO.id) {
      setValues({
        beneficiario : true,
        contratante: false, 
        agente: false,
        otro: false, 
        interno: false
      });
    } else if (inputValue === PERR_CONTRATANTE.id) {
      setValues({
        beneficiario : false,
        contratante: true, 
        agente: false,
        otro: false, 
        interno: false
      });
    } else if (inputValue === PERR_OTRO.id) {
      setValues({
        beneficiario : false,
        contratante: false, 
        agente: false,
        otro: true, 
        interno: false
      });
    } else if (inputValue === PERR_INTERNO.id) {
      setValues({
        beneficiario : false,
        contratante: false, 
        agente: false,
        otro: false, 
        interno: true
      });
    }
};
useEffect(() =>{
  props.rolPersona(idRolPer)
},[values])

  const arrayPersonas = [
    { identificador: PERR_AGENTE.id, texto: PERR_AGENTE.descripcion },
    { identificador: PERR_BENEFICIARIO.id, texto: PERR_BENEFICIARIO.descripcion },
    { identificador: PERR_CONTRATANTE.id, texto:  PERR_CONTRATANTE.descripcion },
    { identificador: PERR_INTERNO.id, texto: PERR_INTERNO.descripcion },
    { identificador: PERR_OTRO.id, texto: PERR_OTRO.descripcion },
    
  ];



  return (
    <Grid container>
      {/* */}

      <Grid item xs={3}>
        <Select1 name={props.name} id={props.id} array={arrayPersonas} onChange={handleChange} error={props.error}/>
      </Grid>
        {/* cuando se selecciona una opcion del select se mostrara el formulario correspondiente */}
      
      <Grid xs={12}>
          <div>{values.beneficiario ? <TramiteBeneficiario /> : null}</div>
          <div>{values.contratante ? <TramiteContratante /> : null}</div>
          <div>{values.agente ? <TramiteAgente /> : null}</div>
          <div>{values.otro ? <TramiteOtro /> : null}</div>
          <div>{values.interno ? <TramiteInterno /> : null}</div>
        </Grid>
      </Grid>
  );
}
