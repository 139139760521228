/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ButtonVino from '../../../Tools/Bottones/ButtonVino';
import { Grid } from "@mui/material";
import RadioDinamico from "../../../Tools/RadioButton/RadioDinamico";
import { Link } from "react-router-dom";
import { getDataToken } from "../../../../api/dataToken";
import { MOV_RESERVA_OPC } from "../../../../utils/constanstoken";
import { useEffect } from "react";

//Aparecera en la segunda alerta dos botones
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


export default function DialogMovReservaOPC(props) {

    const infoRow = props.infoRow;
    const poliza = props.poliza;

    //PARENT DIALOG
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => { setOpen(true) }
    const handleClose = () => {
        setOpen(false);
        setMovimiento1("");
    }
    //CHILDREN DIALOG
    const [open2, setOpen2] = React.useState(false)
    const handleClickOpen2 = () => { setOpen2(true) }
    const handleClose2 = () => {
        setOpen2(false) // Close the children dialog
        setMovimiento2("");
        //handleClose() // Close the parent dialog
    }

    useEffect(() => {
        getCopceptos();
    }, [])

    //Estados
    const [listConceptosN2, setListConceptosN2] = React.useState([])
    const [listConceptosN3, setListConceptosN3] = React.useState([])
    const [listConceptosN4, setListConceptosN4] = React.useState([])

    const getCopceptos = async () => {
        try {
            let listConcepN2 = await getDataToken(`conceptosniveldos/get_idconcepto1/${MOV_RESERVA_OPC.id}/`);
            let listConcepN3 = await getDataToken(`conceptosniveltres/get_idconcepto1/${MOV_RESERVA_OPC.id}/`);

            if (listConcepN2) {
                let newArray = listConcepN2.map((obj) => {
                    obj.id = obj.CON2_idconceptoniveldos;
                    obj.value = obj.CON2_idconceptoniveldos;
                    obj.label = obj.CON2_conceptoniveldos;
                    return obj;
                });
                setListConceptosN2(newArray);
                console.log("conceptos nivel 2:", newArray); 
            }
            if (listConcepN3) {
                let newArray = listConcepN3.map((obj) => {
                    obj.id = obj.CON3_idconceptoniveltres;
                    obj.value = obj.CON3_idconceptoniveltres;
                    obj.label = obj.CON3_conceptoniveltres;
                    return obj;
                });
                setListConceptosN3(newArray);
                console.log("conceptos nivel 3:",newArray)
            }

        } catch (e) {
            console.error(e)
        }
    }


    //Array 1: Primera lista
    // const array = [
    //     { "id": "1", "value": "1", "label": "Constitución de la reserva OPC" },
    //     { "id": "2", "value": "2", "label": "Cancelación de la reserva OPC" },
    //     { "id": "3", "value": "3", "label": "Incremento a la reserva OPC" },
    //     { "id": "4", "value": "4", "label": "Disminución de la reserva OPC" },
    // ]
    const [movimiento1, setMovimiento1] = React.useState("")
    const handleRbChange = (e) => {
        let value = e.target.value;
        let filterConceptos = listConceptosN3.filter(obj => obj.CON2_idconceptoniveldos.CON2_idconceptoniveldos == value);
        setListConceptosN4(filterConceptos);
        //setMovimiento1(e.target.value)
    }


    //Arrays 2: Segunda lista se tienen 1 por cada opción elegida

    //array CONSTITUCION DE LA RESRERVA OPC : opcion 1 
    const arrayConstitucion = [
        { "id": "1", "value": "1", "label": "Siniestro por fallecimiento" },
        { "id": "2", "value": "2", "label": "Muerte accidental" },
        { "id": "3", "value": "3", "label": "Beneficio adicional de invalidez" },
        { "id": "4", "value": "4", "label": "Beneficio adicional de desempleo" },
        { "id": "5", "value": "5", "label": "Enfermedad terminal" },
        { "id": "6", "value": "6", "label": "Exención de pago de primas por invalidez" },
        { "id": "7", "value": "7", "label": "Exención de pago de primas por fallecimiento" },
        { "id": "8", "value": "8", "label": "Exención de pago de primas por desempleo" },
        { "id": "9", "value": "9", "label": "Pérdida de miembros" },
        { "id": "10", "value": "10", "label": "Gastos funerarios" },
        { "id": "11", "value": "11", "label": "Reserva matemática por suicidio" },
    ];
    //array CANCELACION DE  RESERVA OPC : opcion 2
    const arrayCancelacion = [
        { "id": "1", "value": "1", "label": "Fallecimiento" },
        { "id": "2", "value": "2", "label": "Beneficio adicional de invalidez" },
        { "id": "3", "value": "3", "label": "Beneficio adicional de muerte accidental" },
        { "id": "4", "value": "4", "label": "Beneficio adicional de desempleo" },
        { "id": "5", "value": "5", "label": "Pérdida de miembros no procedentes" },
        { "id": "6", "value": "6", "label": "Gastos funerarios" },
        { "id": "7", "value": "7", "label": "Exención de pago de primas por fallecimiento" },
        { "id": "8", "value": "8", "label": "Exención de pago de primas por invalidez" },
        { "id": "9", "value": "9", "label": "Exención de pago de primas por desempleo" },
    ];
    //array INCREMENTO A LA RESERVA : opcion 3 
    const arrayIncremento = [
        { "id": "1", "value": "1", "label": "Fallecimiento" },
        { "id": "2", "value": "2", "label": "Muerte accidental" },
        { "id": "3", "value": "3", "label": "Invalidez" },
        { "id": "4", "value": "4", "label": "Desempleo" },
        { "id": "5", "value": "5", "label": "Gastos funerarios" },
        { "id": "6", "value": "6", "label": "Pérdida de miembros" },
        { "id": "7", "value": "7", "label": "Exención de pago de primas por fallecimiento" },
        { "id": "8", "value": "8", "label": "Exención de pago de primas por invalidez" },
        { "id": "9", "value": "9", "label": "Exención de pago de primas por desempleo" },
    ];
    //array DISMINUCIÓN A LA RESERVA : opcion 4
    const arrayDisminucion = [
        { "id": "1", "value": "1", "label": "Fallecimiento" },
        { "id": "2", "value": "2", "label": "Muerte accidental" },
        { "id": "3", "value": "3", "label": "Invalidez" },
        { "id": "4", "value": "4", "label": "Desempleo" },
        { "id": "5", "value": "5", "label": "Gastos funerarios" },
        { "id": "6", "value": "6", "label": "Pérdida de miembros" },
        { "id": "7", "value": "7", "label": "Exención de pago de primas por fallecimiento" },
        { "id": "8", "value": "8", "label": "Exención de pago de primas por invalidez" },
        { "id": "9", "value": "9", "label": "Exención de pago de primas por desempleo" },
    ];


    const [movimiento2, setMovimiento2] = React.useState("")
    const [conceptoN4Selected, setCopteptoN4Selected] = React.useState(null)
    const handleRbChange2 = (e) => {
        let findConcepto = listConceptosN4.find(obj => obj.CON3_idconceptoniveltres == e.target.value)
        console.log("findConcepto:", findConcepto)
        if(findConcepto)
            setCopteptoN4Selected(findConcepto)
        setMovimiento2(e.target.value)
    }


    return (
        <div>
            {/* PARENT DIALOG */}
            <div>
                {/*BOTON QUE HACE EL LLAMADO AL DIALOGO PADRE*/}
                <ButtonVino onClick={handleClickOpen} label="Movimientos a la Reserva OPC" estatus={props.disabledbtn} />
                {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
                <BootstrapDialog
                    fullWidth={true}
                    onClose={handleClose}
                    aria-labelledby="dialog"
                    open={open}
                >
                    {/*CUERPO DEL DIALOGO*/}
                    {/*TITILO*/}
                    <BootstrapDialogTitle id="dialog" onClose={handleClose} style={{ color: '#761f2a' }}>
                        <h1-dialog-title>MOVIMIENTOS A LA RESERVA OPC</h1-dialog-title>
                    </BootstrapDialogTitle>
                    {/*CUERPO DEL DIALOGO*/}
                    {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
                    <DialogContent dividers style={{ minHeight: '300px' }}>
                        {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
            se necesita colocar entre un typogreaphy las veces que des salto de linea 
            o con un br*/}
                        <h2-dialog-description>Seleccione una opcion: </h2-dialog-description>
                        <RadioDinamico array={listConceptosN2} onChange={handleRbChange} />
                    </DialogContent>
                    {/*es la tercer sección que engloba los botones */}
                    <DialogActions>

                        {/*En esta parte se llena el segundo mensaje que debe de aparecer*/}
                        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                            {/*<Grid item xs={'auto'}>
              <ButtonVino onClick={handleClickOpen2} label={props.firstButton} estatus={props.disabledBtn}/>
              </Grid>*/}
                            <Grid item xs={'auto'}>
                                <ButtonVino onClick={handleClickOpen2} label={"Siguiente"} estatus={listConceptosN4.length > 0 ? false : true} />
                            </Grid>
                            <Grid item xs={'auto'}>
                                <ButtonVino onClick={handleClose} label={"Cancelar"} estatus={props.disabledBtn} />
                            </Grid>
                        </Grid>

                    </DialogActions>
                </BootstrapDialog>
            </div>
            {/* CHILDREN DIALOG */}
            <div>
                {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
                <BootstrapDialog
                    fullWidth={true}
                    onClose={handleClose2}
                    aria-labelledby="dialog"
                    open={open2}
                >
                    {/*CUERPO DEL DIALOGO*/}
                    {/*TITULO*/}
                    <BootstrapDialogTitle id="dialog" onClose={handleClose2} style={{ color: '#761f2a' }}>
                        <h1-dialog-title>MOVIMIENTOS A LA RESERVA OPC</h1-dialog-title>
                    </BootstrapDialogTitle>
                    {/*CUERPO DEL DIALOGO*/}
                    {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
                    <DialogContent dividers style={{ minHeight: '300px' }}>
                        {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
              se necesita colocar entre un typogreaphy las veces que des salto de linea 
              o con un br*/}
                        <RadioDinamico array={
                            listConceptosN4
                        } onChange={handleRbChange2}
                        />
                    </DialogContent>
                    {/*es la tercer sección que engloba los botones */}
                    <DialogActions>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item><Link to="/dictamen/detalle_folio/reservas/registro" state={{ infoRow, data: { dataPoliza: poliza, dataConcepto: conceptoN4Selected }, poliza: poliza }}><ButtonVino estatus={movimiento2 === "" ? true : false} label={"Siguiente"} /></Link></Grid>
                            <Grid item><ButtonVino onClick={handleClose2} label={"Cancelar"} /></Grid>
                        </Grid>
                    </DialogActions>
                </BootstrapDialog>
            </div>
        </div>
    );
}
