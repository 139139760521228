import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormattedMessage } from "react-intl";

export function SelectsCobe(props) {
  const [op, setop] = React.useState("");

  const handleChange = (event) => {
    setop(event.target.value);
  };
  return (
    <div>
      <FormControl
        variant="standard"
        sx={{ m: 1, minWidth: 250 }}
        size="medium"
      >
        <InputLabel id="demo-simple-select-standard-label">
          <FormattedMessage id="globCatal" defaultMessage="Catálogo" />
        </InputLabel>
        <Select
          //style={{ position: "sticky" }}
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={op}
          label={
            <FormattedMessage
              id="globSeleOp"
              defaultMessage="Seleccion una opción"
            />
          }
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={1} onClick={props.onClick1}>
            <FormattedMessage id="globMoneda" defaultMessage="Moneda" />
          </MenuItem>
          <MenuItem value={2} onClick={props.onClick2}>
            <FormattedMessage
              id="confGReases"
              defaultMessage="Reaseguradoras"
            />
          </MenuItem>
          <MenuItem value={3} onClick={props.onClick3}>
            <FormattedMessage id="globCobert" defaultMessage="Coberturas" />
          </MenuItem>
          <MenuItem value={4} onClick={props.onClick4}>
            {" "}
            <FormattedMessage id="globForPag" defaultMessage="Forma de Pago" />
          </MenuItem>
          <MenuItem value={5} onClick={props.onClick5}>
            <FormattedMessage id="confGOfics" defaultMessage="Oficinas" />
          </MenuItem>
          <MenuItem value={6} onClick={props.onClick6}>
            <FormattedMessage id="confGPrueba" defaultMessage="Prueba" />
          </MenuItem>
          <MenuItem value={7} onClick={props.onClick7}>
            <FormattedMessage id="regisDocum" defaultMessage="Documentos" />
          </MenuItem>
          {/* <MenuItem value={8} onClick={props.onClick8}><FormattedMessage id="globMoneda" defaultMessage="CUENTAS MAYOR</MenuItem> */}
          <MenuItem value={9} onClick={props.onClick9}>
            <FormattedMessage id="confGCana" defaultMessage="Canales" />
          </MenuItem>
          <MenuItem value={10} onClick={props.onClick10}>
            <FormattedMessage id="globPlats" defaultMessage="Plataformas" />
          </MenuItem>
          <MenuItem value={11} onClick={props.onClick11}>
            <FormattedMessage
              id="confGTprodu"
              defaultMessage="Tipos de producto"
            />
          </MenuItem>
          <MenuItem value={12} onClick={props.onClick12}>
            <FormattedMessage id="globParent" defaultMessage="Parentesco" />
          </MenuItem>
          <MenuItem value={13} onClick={props.onClick13}>
            <FormattedMessage
              id="confGTEnfer"
              defaultMessage="Tipos de enfermedad"
            />
          </MenuItem>
          <MenuItem value={14} onClick={props.onClick14}>
            <FormattedMessage
              id="confGTHomi"
              defaultMessage="Tipos de homicidio"
            />
          </MenuItem>
          <MenuItem value={15} onClick={props.onClick15}>
            <FormattedMessage
              id="confgTAccide"
              defaultMessage="Tipos de accidente"
            />
          </MenuItem>
          <MenuItem value={16} onClick={props.onClick16}>
            <FormattedMessage
              id="confGTPSAT"
              defaultMessage="Tipo personas SAT"
            />
          </MenuItem>
          <MenuItem value={17} onClick={props.onClick17}>
            <FormattedMessage id="confGCATOIL" defaultMessage="CATÁLOGO OII" />
          </MenuItem>
          <MenuItem value={18} onClick={props.onClick18}>
            <FormattedMessage id="confGRolP" defaultMessage="Rol persona" />
          </MenuItem>
          <MenuItem value={19} onClick={props.onClick19}>
            <FormattedMessage
              id="confGCompSin"
              defaultMessage="Complejidad de siniestro"
            />
          </MenuItem>
          <MenuItem value={20} onClick={props.onClick20}>
            <FormattedMessage id="dictaCausas" defaultMessage="Causas" />
          </MenuItem>
          <MenuItem value={21} onClick={props.onClick21}>
            <FormattedMessage id="dictaTipoCart" defaultMessage="Tipo carta" />
          </MenuItem>
          <MenuItem value={22} onClick={props.onClick22}>
            <FormattedMessage id="bancos" defaultMessage="Bancos" />
          </MenuItem>
          <MenuItem value={23} onClick={props.onClick23}>
            <FormattedMessage id="tarifaInv" defaultMessage="Tarifa investigador" />
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
