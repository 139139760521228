import * as React from "react";
import Grid from "@mui/material/Grid";
import { DATA_PM } from "../../../../utils/constansPM";
import DatePicker from "../../../Tools/DatePicker/DatePicker";
import moment from "moment";
import { FormattedMessage } from "react-intl";

export function TabDatosPoliza(props) {
  const dataPoliza = props.dataPoliza;
  console.log(dataPoliza);

  const handleDataChanges = (newData) => {
    const dateObject = new Date(newData);
    const fechasiniestro = moment(dateObject)
      .tz("America/Mexico_City")
      .format("YYYY-MM-DD");
    console.log(fechasiniestro);
    props.polizaRehabilitada(fechasiniestro);
  };

  return (
    <div style={{ padding: "5px", width: "900px" }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage id="regisEnLe" defaultMessage="Entidad legal" />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.POL_entidadlegal
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.ENTIDAD_LEGAL]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage id="regisTiSeg" defaultMessage="Tipo de seguro" />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.POL_tipodeseguro
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.TIPO_SEGURO]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage id="globPoliza" defaultMessage="Póliza" />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.POL_numpoliza
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.POLIZA]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage
              id="globNoPolizaPadre"
              defaultMessage="No. de póliza padre"
            />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.POL_polizapadre
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.POLIZA_PADRE]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage
              id="regisMoPo"
              defaultMessage="ID Moneda póliza"
            />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.MON_idmonedas?.MON_idmoneda
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.ID_MONEDA]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage id="globPagaHa" defaultMessage="Pagado hasta" />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.POL_polizapahasta
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.PAGADO_HASTA]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage
              id="regisIDEstPo"
              defaultMessage="ID Estatus póliza"
            />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.EST_idestatuspoliza?.EST_idestatuspoliza
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.ID_ESTATUS_POLIZA]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage id="regisDuPo" defaultMessage="Duración póliza" />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.POL_aniosduracionpoliza
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.DURACION_POLIZA]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage
              id="regisPathPoOr"
              defaultMessage="Path Póliza Origen"
            />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.POL_pathpolizaorigen.length > 20
              ? `${dataPoliza.POL_pathpolizaorigen.slice(0, 20)}...`
              : dataPoliza.POL_pathpolizaorigen
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.PATH_POLIZA].length > 20
            ? `${dataPoliza[DATA_PM.PATH_POLIZA].slice(0, 20)}...`
            : dataPoliza[DATA_PM.PATH_POLIZA]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage
              id="dicFoDeInv"
              defaultMessage="Fondo de inversión"
            />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.POL_polizafoinversión
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.FONDO_INVERSION]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage id="dictamPresta" defaultMessage="Prestamo" />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.POL_polizapréstamo
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.PRESTAMO]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage id="regisIDCa" defaultMessage="ID Canal" />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.CHN_idcanales?.CHN_idcanal
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.ID_CANAL]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage id="regisIDOf" defaultMessage="ID Oficina" />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.OF_idoficinas?.OF_idoficina
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.ID_OFICINA]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage id="regisIDEst" defaultMessage="ID Estado" />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.ESTA_idestado?.ESTA_idestado
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.ID_ESTADO]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage
              id="regisReMat"
              defaultMessage="Reserva matemática"
            />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.POL_polizarematemática
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.RESERVA_MATEMATICA]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage id="dictamReas" defaultMessage="Reaseguro" />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.POL_polizareaseguro
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.REASEGURO]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage id="estPrima" defaultMessage="Prima" />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.POL_polizaprima
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.PRIMA]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage
              id="regisIDForPag"
              defaultMessage="ID Forma de pago"
            />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.FPAG_idformasdepago?.FPAG_idformapago
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.ID_FORMA_PAGO]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage
              id="regisFinVg"
              defaultMessage="Fin de vigencia"
            />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.POL_polizafinvigencia
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.FECHA_FIN_VEGENCIA]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage
              id="regisFchEmiPo"
              defaultMessage="Fecha emisión póliza"
            />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.POL_polizafecemisión
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.FECHA_EMISION_POLIZA]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage
              id="regisProdCo"
              defaultMessage="Producto Comercial"
            />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.PRO_idproductos?.PRO_descripcion
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.PRODUCTO_COMERCIAL]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage
              id="regisIDTiProd"
              defaultMessage="ID Tipo producto"
            />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza.PRO_idproductos?.PROT_idtiposproducto
                ?.PROT_idtipoproducto
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.ID_TIPO_PRODUCTO]}
          <br />
        </Grid>
        <Grid item xs={3}>
          <strong>
            <FormattedMessage id="regisIDPlat" defaultMessage="ID Plataforma" />
          </strong>
          <br />
          <br />
          {dataPoliza.POL_idpoliza
            ? dataPoliza?.PRO_idproductos?.PROT_idtiposproducto
                ?.PLA_idplataforma?.PLA_idplataforma
            : dataPoliza === "" || dataPoliza === undefined
            ? ""
            : dataPoliza[DATA_PM.ID_PLATAFORMA]}
          <br />
        </Grid>
        <Grid item xs={6}>
          <br />
          <h2-dialog-description>
            <strong>
              <FormattedMessage
                id="regisCondPo"
                defaultMessage="Sí la póliza esta rehabilitada, ingrese la fecha."
              />
            </strong>
          </h2-dialog-description>
          <br />
          <div style={{ width: "320px" }}>
            <DatePicker onDataChange={handleDataChanges} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
