import React from "react";
//importacion del tema de estilos a tablas
import "../../css/estilo.css";

//componentes estandarizados y herramientas de mui
import Recorrido from "../Tools/BreadCrumbs/Recorrido3";
import { Grid } from "@mui/material";
import TableFolios from "../Workflow/Tables/TableFolios";
import ReturnPage from "../Tools/Bottones/ButtonReturn";

//importacion del multilenguaje
import { FormattedMessage } from "react-intl";

export function CompWorkflow() {
  return (
    <div className="div-parent">
      {/*Enlaces de inicio */}

      <div className="div-breadcrumbs">
        {" "}
        {/*navegador*/}
        <Recorrido
          name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
          enlace="/menu"
          name2="Workflow"
          enlace2="/workflow"
          name3={
            <FormattedMessage
              id="submenuFol"
              defaultMessage="Folios en proceso"
            />
          }
        />
      </div>
      {/**Boton regresar */}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <ReturnPage href="/menu" />
      </Grid>

      <div className="div-container">
        <Grid container>
          {/*contenido de la pantalla se manda a traer el componente tabla de folios*/}
          <Grid xs={12}>
            <TableFolios />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
