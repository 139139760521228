//Brandon Hernandez 12/2022
import React, {useState} from 'react'
import './Elements/Investigador.css'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import InvestigadorTable from './Elements/TablaInvestigador'
import Grid from '@material-ui/core/Grid';
import CustomSeparator from '../Tools/BreadCrumbs/Recorrido'
import "../../css/estilo.css";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
//idioma
import {FormattedMessage} from 'react-intl';

const CompInvestigador = () =>{
    return(
        
        <div className='div-parent'>
            <div className='div-breadcrumbs'>
                <CustomSeparator  name1={<FormattedMessage id="globInicio" defaultMessage="Inicio"/>} 
                enlace="/menu"
                name2={<FormattedMessage id="menuInvest" defaultMessage="Investigación"/>} 
                />
            </div>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                <ReturnPage href='/menu'/>
            </Grid>
            <div className='div-container'>
                <Grid container >
                    {/* <Grid item xs={3}><section className='sec-carpetas'><FolderOutlinedIcon style={{color: '#12b3ef'}}/><folder-titles><FormattedMessage id="investNuCa" defaultMessage="Nuevos casos"/></folder-titles><folder-titles-cases  id="pNuevosCasos">2</folder-titles-cases></section></Grid> */}
                </Grid>

                <InvestigadorTable/>
            </div>
        </div>
        
    );
}
export default CompInvestigador;