import { Workflow } from "../../pages/Client/ModWorkflow/Workflow";
import { InformacionWF } from "../../pages/Client/ModWorkflow/InformacionWF";
import { ConsultasWF } from "../../pages/Client/ModWorkflow/ConsultasWF";
import { ClientLayaout } from "../../layouts/Client/ClientLayaout";
//import { LoginLayaout } from "../../layouts/Login/LoginLayout";

const routesClient = [
  {
    path: "/workflow",
    layout: ClientLayaout,
    component: Workflow,
    exact: true,
  },
  {
    path: "/informacionWF",
    layout: ClientLayaout,
    component: InformacionWF,
    exact: true,
  },
  {
    path: "/consultasWF",
    layout: ClientLayaout,
    component: ConsultasWF,
    exact: true,
  },
];

export default routesClient;
