/* Brandon Hernandez Rocha 02/2024 */
import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import { CircularProgress } from '@mui/material';
import { removeToken } from './token';

export function Unauthorized() {
    // Función para redireccionar después de 3 segundos
    setTimeout(function () {
        window.location.href = "/";
        removeToken();

    }, 4000);
    return (
        <div className="div-parent">
            <div className="div-breadcrumbs"></div>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                style={{ marginTop: '50px' }}
            >
                <Grid item xs={12}><h1-invalid-token>{`Privilegios insuficientes, contacta al encargado de TI.`}</h1-invalid-token> </Grid>
                <Grid item><h1-invalid-token>{`Redireccionando  `}</h1-invalid-token><CircularProgress /></Grid>
            </Grid>

        </div>
    )
}