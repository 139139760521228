import React, { useEffect, useState } from 'react'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid } from "@mui/material";

import { FormattedMessage } from "react-intl";
import Paper from "@mui/material/Paper";
import { getDataToken, putDataToken } from '../../api/dataToken';
import StyledTableCell from '../Tools/Table/StyledTableCell';
import { Select2 } from '../Tools/Selectss/Select2';
import { ESTATUS_GCRP, ESTATUS_GESTION_COMP } from '../../utils/constanstoken';
import DialogFeedback from '../Tools/Dialog/DialogFeedback';
import DialogConfirm from "../Tools/Dialog/DialogConfirm";
import { formatNumberDecimalComa } from '../../utils/functions';
import DialogGeneral from '../Tools/Dialog/DialogGeneral';

export const CompGestion = () => {
    //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
    const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
    const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10
    /****** Código Usuario que actualizó *******/
    const [user, setUser] = useState("");

    //Estado para mostrar diálogos de confirmación 
    const initialDialogResponse = {
        title: '',
        description: '',
        button: '',
        firstButton: '',
        secondButton: '',
    }
    const [openDialogFeedb, setOpenDialogFeedb] = useState(false);
    const [dialogType, setDialogType] = useState(false);
    const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
    const [dialogResponse, setDialogResponse] = useState(initialDialogResponse);
    const [openDialog, setOpenDialog] = useState(false);
    const [dataPostUpdate, setDataPostUpdate] = useState(null);
    const [dataAction, setDataAction] = useState(0);
    //
    const [dataEstatusGesion, setDataEstatusGestion] = useState([]);
    //GET A API 
    const getDataUser = async () => {
        //Resultados de la api documentacion documentosxtiposiniestrostramite
        let result = await getDataToken("login/get_correo/" + localStorage.getItem("SNYTLOG_email") + "/");
        let result2 = await getDataToken("estatusgestion/get_listado/");
        setUser(result);
        if (result2) {
            console.log("Estatus", result2);
            let filterData = result2.filter(obj => {
                if (obj.ESTG_idestatusgestion != ESTATUS_GESTION_COMP.FINALIZADO_ID && obj.ESTG_idestatusgestion != ESTATUS_GESTION_COMP.PENDIENTE_ID) {
                    obj.identificador = obj.ESTG_idestatusgestion;
                    obj.texto = obj.ESTG_descripcion;
                    return obj;
                }
            })
            console.log("filter", filterData)
            setDataEstatusGestion(filterData)
        }
        console.log("user: ", result);
        getDataGestion();

    };
    const getDataGestion = async () => {
        //Resultados de la api documentacion documentosxtiposiniestrostramite
        let result = await getDataToken("gestiondecompetencias/get_listado/");
        //Filtrar ppor id trabajador
        // setData(result.filter(obj => obj.IDTRABAJADOR.id === user.id));

        //Ver coberturas 

        let coberturas = await getDataToken("coberturas/get_listado/");
        console.log("coberturas: ", coberturas);
        let newArr = result.map(obj => {
            let cober = coberturas.find(cob => cob.COB_idcobertura == obj.RPC_idreservapolizaporcobertura.CPT_idcoberturapolizatramite.COB_idcobertura)
            if (cober) obj.COB_cobertura = cober || null
            return obj
        })
        let filter = newArr.filter(obj => obj.ESTG_idestatusgestion.ESTG_idestatusgestion != ESTATUS_GESTION_COMP.APROBADO_ID && obj.ESTG_idestatusgestion.ESTG_idestatusgestion != ESTATUS_GESTION_COMP.DENEGADO_ID && obj.ESTG_idestatusgestion.ESTG_idestatusgestion != ESTATUS_GESTION_COMP.FINALIZADO_ID)
        setData(filter);
        console.log("result: ", filter);


    };
    useEffect(() => {
        getDataUser();
    }, []);
    //Constantes data 
    const [data, setData] = useState([]);



    const handlerChangeStatus = async (e, row) => {
        let result = await getDataToken("estatusgestion/get_listado/");

        let action = result.find(obj => obj.ESTG_idestatusgestion == e);
        setDialogResponse({ ...dialogResponse, description: `Está apunto de cambiar el estatus a "${action.ESTG_descripcion}". ¿Desea continuar?`, firstButton: 'Aceptar', secondButton: "Cancelar" });
        setDataPostUpdate(row)
        setDataAction(e);
        setOpenDialogConfirm(true)
    }
    const handlerConfirmAlert = async () => {
        let json = {

            "ESTG_idestatusgestion": dataAction
        }
        //Iniciar proceso para actualizar. Primero saber que tipo de movimiento es, si reserva o pago. 
        if (dataPostUpdate.GC_movpago) { // es pago

        } else if (dataPostUpdate.GC_movreserva) { // es reserva
            let result = await putDataToken(`gestiondecompetencias/get_id/${dataPostUpdate.GC_idgestiondecompentencia}/`, json)
            if (result) {
                setDialogResponse({ ...dialogResponse, description: ``, firstButton: 'Aceptar', secondButton: "Cancelar" });
                setOpenDialog(true);
                setDialogType(true);
                getDataGestion();
            }
        }


    }



    return (
        <div className='div-parent'>
            <div className='div-container'>
                <Paper style={{ width: "100%" }}>
                    <TableContainer
                        sx={{ maxHeight: 440, showEmptyDataSourceMessage: true }}
                        style={{ position: "sticky" }}
                    >
                        <Table
                            stickyHeader
                            aria-label="sticky table"
                            sx={{
                                "& .MuiTableRow-root:hover": {
                                    backgroundColor: "#ededed",
                                },
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                                    <StyledTableCell>
                                        <Grid container alignItems="center">
                                            <Grid item xs={9}>
                                                <FormattedMessage
                                                    id="globNoDeFo"
                                                    defaultMessage="No. de folio"
                                                />
                                            </Grid>
                                        </Grid>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Grid container alignItems="center">
                                            <Grid item xs={9}>
                                                <FormattedMessage
                                                    id="globPol"
                                                    defaultMessage="Póliza"
                                                />
                                            </Grid>
                                        </Grid>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Grid container alignItems="center">
                                            <Grid item xs={10}>
                                                <FormattedMessage
                                                    id="globCob"
                                                    defaultMessage="Cobertura"
                                                />
                                            </Grid>

                                        </Grid>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Grid container alignItems="center">
                                            <Grid item xs={10}>
                                                <FormattedMessage
                                                    id="globMoneCob"
                                                    defaultMessage="Moneda cobertura"
                                                />
                                            </Grid>

                                        </Grid>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <FormattedMessage id="globSumaAsegurada" defaultMessage="Suma asegurada" />
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <FormattedMessage id="dictamReserPago" defaultMessage="Reserva / Pago" />
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <FormattedMessage id="globImporte" defaultMessage="Importe" />
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <FormattedMessage
                                            id="globMoneda"
                                            defaultMessage="Moneda"
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell > <FormattedMessage
                                        id="globAcciones"
                                        defaultMessage="Acciones"
                                    /></StyledTableCell>


                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
                                {data
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        console.log("row", row);
                                        //Tipo de movimiento 
                                        let tipoMov = ESTATUS_GCRP.find(obj => obj.GC_movreserva.value === row.GC_movreserva || obj.GC_movreserva.value === row.GC_movreserva)
                                        if (!tipoMov) {
                                            tipoMov = null;
                                        }
                                        return (
                                            <TableRow key={row.GC_idgestiondecompentencia}>
                                                <StyledTableCell align="center">{row?.PXFS_idfoliopolizaxsiniestro.RECT_idfolio.RECT_numerofolio}</StyledTableCell>
                                                <StyledTableCell align="center">{row?.PXFS_idfoliopolizaxsiniestro.POL_idpoliza.POL_numpoliza}</StyledTableCell>
                                                <StyledTableCell align="center">{row?.COB_cobertura.COB_cobertura}</StyledTableCell>
                                                <StyledTableCell align="center">{row?.RPC_idreservapolizaporcobertura.MON_idmoneda.MON_abreviatura}</StyledTableCell>
                                                <StyledTableCell align="center">{formatNumberDecimalComa(row?.GC_sumaasegurada)}</StyledTableCell>
                                                <StyledTableCell align="center">{tipoMov.GC_movreserva ? tipoMov.GC_movreserva.descripcion : tipoMov.GC_movpago.descripcion}</StyledTableCell>
                                                <StyledTableCell align="center">{formatNumberDecimalComa(row?.GC_importereservado)}</StyledTableCell>
                                                <StyledTableCell align="center">{row?.MON_idmoneda.MON_abreviatura}</StyledTableCell>
                                                <StyledTableCell align="center"><Select2 value={row.ESTG_idestatusgestion.ESTG_idestatusgestion} defaultValueDisabled={row.ESTG_idestatusgestion.ESTG_idestatusgestion} defaultOptionDisabled={row.ESTG_idestatusgestion.ESTG_descripcion} array={dataEstatusGesion} onChange={(e) => handlerChangeStatus(e.target.value, row)} /></StyledTableCell>
                                            </TableRow>
                                        );
                                    })}

                            </TableBody>
                            
                        </Table>
                        {data.length > 0 ? null :
                                <div align="center" style={{padding:"10px"}}><h2-dialog-description>Sin pendientes</h2-dialog-description></div>

                        }
                    </TableContainer>
                </Paper>
                {!openDialog ? null : <DialogGeneral open={openDialog} type={dialogType} title={dialogResponse.title} description={dialogResponse.description} onClickAccept={() => setOpenDialog(false)} />}
                {/* {!openDialogFeedb ? null : <DialogFeedback open={openDialog} title={dialogResponse.title} description={dialogResponse.description} clickButton={() => setOpenDialog(false)} button={dialogResponse.button} />} */}
                {!openDialogConfirm ? null : <DialogConfirm open={openDialogConfirm} description={dialogResponse.description} firstButton={dialogResponse.firstButton} secondButton={dialogResponse.secondButton} onClickAccept={() => setOpenDialogConfirm(false)} onClickAccept2={() => {
                    handlerConfirmAlert()
                    console.log("Diste click en aceptar");
                    setOpenDialogConfirm(false)
                }} />}
            </div>
        </div>
    )
}
