//David Ruiz 2022

import React from 'react';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


const Btverreport = (props) => {
    return (
        <IconButton aria-label=" AddCircleOutlineIcon" color="icons"
            //aqui va el enlace
            href={props.enlace}
            onClick={props.onClick}
            onChange={props.onChange}
            id={props.id}
            //ojo aqui es disabled
            disabled={props.estatus} //true para desabilitar y false para habilitar
        >
            < AddCircleOutlineIcon />
            <h2-dialog-description style={{ paddingLeft: '5px' }}>{props.label}</h2-dialog-description>
        </IconButton>
    );
}
export default Btverreport;