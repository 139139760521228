import React, {useState} from 'react';
import MensajesIngles from '../Lang/en-US.json';
import MensajesEspañol from '../Lang/es-MX.json';
import MensajesPortugues from '../Lang/po-BR.json';
import MensajesItaliano from '../Lang/es-IT.json';
import MensajesFrances from '../Lang/es-FR.json';


import {IntlProvider} from 'react-intl';

const langContext = React.createContext();

const LangProvider = ({children}) => {
	let localePorDefecto;
	let mensajesPorDefecto;
	const lang = localStorage.getItem('lang');

	if(lang){
		localePorDefecto = lang

		if(lang === 'es-MX'){
			mensajesPorDefecto = MensajesEspañol;
		}else if(lang === 'po-BR'){
			mensajesPorDefecto = MensajesPortugues
		}else  if(lang === 'es-IT'){
			mensajesPorDefecto = MensajesItaliano
		}else if(lang === 'es-FR'){
			mensajesPorDefecto = MensajesFrances
		} else if(lang === 'po-BR'){
			mensajesPorDefecto = MensajesPortugues
		}
		else {
			localePorDefecto = 'en-US'
			mensajesPorDefecto = MensajesIngles
		}
	}
//estados para los cambios de idiomas
	const [mensajes, establecerMensajes] = useState(mensajesPorDefecto);
	const [locale, establecerLocale] = useState(localePorDefecto);
 

//cuando se seleccione en español o ingles los mensajes se cambiaran por el switch
//se le puso de manera default el ingles
	const establecerLenguaje = (lenguaje) => {
		switch (lenguaje){
			case 'es-MX':
				establecerMensajes(MensajesEspañol);
				establecerLocale('es-MX');
				localStorage.setItem('lang', 'es-MX');
				break;
			case 'en-US':
				establecerMensajes(MensajesIngles);
				establecerLocale('en-US');
				localStorage.setItem('lang', 'en-US');
				break;
			case 'po-BR':
				establecerMensajes(MensajesPortugues);
				establecerLocale('po-BR');
				localStorage.setItem('lang', 'po-BR');
				break;
			case 'es-IT':
				establecerMensajes(MensajesItaliano);
				establecerLocale('es-IT');
				localStorage.setItem('lang', 'es-IT');				
				break;
			case 'es-FR':
				establecerMensajes(MensajesFrances);
				establecerLocale('es-FR');
				localStorage.setItem('lang', 'es-FR');
				break;
			default:
				establecerMensajes(MensajesEspañol);
				establecerLocale('es-MX');
				localStorage.setItem('lang', 'en-MX');
		}
	}

	return (

		/*Prover el contexto de todo lo que es encuentra dentro */
		<langContext.Provider value={{establecerLenguaje: establecerLenguaje}}>
			{/*Para que funcione intl necesita encerrar en esta funcion para que sirva*/}
			<IntlProvider locale={locale} messages={mensajes}>
				{children}
			</IntlProvider>
		</langContext.Provider>
	);
}
 
export {LangProvider, langContext};