import * as React from "react";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";

const Check2 = (props) => {
  return (
    //Ejemplo de uso => <Check2 option1="Si" option2="No" onChange={(e)=>{ console.log(e.target.checked)}}/>
    <Stack direction="row" spacing={1} alignItems="center">
      <h4-label>{props.option1}</h4-label>{" "}
      {/* Esta prop mostrará el texto a la izquierda del switch (la opcion false) */}
      <Switch
        checked={props.checked}
        disabled={props.disabled} //Esta prop sirve para deshabilitar el switch
        position={props.position} //Esta prop sirve para indicar la posicion
        defaultChecked={props.defaultChecked} //Esta prop sirve para indicar el estaddo inicial del switch (false por defecto)
        onChange={props.onChange} // Esta prop sirve para agregar un evento al código que necesite un switch
        value={props.value}
        id={props.id}
      />
      <h4-label>{props.option2}</h4-label>{" "}
      {/* Esta prop mostrará el texto a la derecha del switch (la opcion true) */}
    </Stack>
  );
};

export default Check2;
