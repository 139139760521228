import { CompMedic } from "../../../components/Medic/CompMedic";
import React from "react";
import MyComponent from "../../../components/Tools/ButtonLoading/ButtonLoading";

export function Medico() {
  return (
    <div>
      <MyComponent componentToRender={CompMedic} />
    </div>
  );
}
