import React from "react";
import error404  from "../img/error404.gif"
import Typography  from "@mui/material/Typography";

export function Error404() {
    return (
      <div >
        <center>
        <Typography>ERROR </Typography>
        <Typography>Lo sentimos no pudimos encontrar la pagina </Typography>
        <img  className='img-iconos' src={error404} alt='error404' />
        </center>
        
      </div>
  
    );
}
