import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, TextField } from "@mui/material";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import TXTField from "../../Tools/TextField/TextField";
//Idioma
import { FormattedMessage } from "react-intl";
//paginación de la tabla
import TablePagination from "@mui/material/TablePagination";
//estilos de la tabla
import StyledTableCell from "../../Tools/Table/StyledTableCell";
//datapicker
import DatePicker from "../../Tools/DatePicker/DatePicker";
//exportExcel
import { downloadExcel } from "react-export-table-to-excel";
//funciones de validaciones 
import { LimitarDigitos } from "../../Recepcion/Elements/Functions/Validaciones";
import moment from "moment-timezone";
import data from "../data.json";

export default function FechaVencimiento() {

  const [fechaVencimiento, setFechaVencimiento] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = require("../data.json");
        console.log("Datos obtenidos:", response);
        setFechaVencimiento(response);
      } catch (error) {
        console.error("Error al obtener el archivo JSON:", error);
      }      
    };

    fetchData();
  }, []); 

  /* CONSTANTES DE ESTADOS*/
  const [btnDisable, setBtnDisable] = useState(true); //Funcion para habilitar o desabilitar el boton

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Encabezado para el excel a descargar
  const header = [
    "id",
    "Número de cuenta",
    "Fecha de ocurrencia",
    "Fecha constitución de reserva",
    "Fecha emisión de póliza",
    "Número cuenta de reserva",
    "Importe reserva",
    "Número póliza",
    "Nombre asegurado",
    "Estatus siniestro",
  ];

  //Funcion para Descargar excel
  function handleDownloadExcel() {
    downloadExcel({
      fileName: "Vencimientos",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,
        body: data,
      },
    });
  }

     //Valida que el formulario este lleno para poder enviar la informacion
     const [formValid, setFormValid] = useState(false);
     const [cuenta, setCuenta] = useState('');
     
     const validateInputs = () => {
       // Realiza tu lógica de validación aquí
       const isValid = cuenta.length === 6; // Ejemplo: validación de longitud de cuenta
       setFormValid(isValid);
     }; 
  
   const handleChangeCuenta = (event) => {
     const newCuenta = event.target.value;
     setCuenta(newCuenta);
     validateInputs(); // Llama a la validación cada vez que cambie el valor
   };
  
  //Constantes para variables de la fecha 
  const [inicioVencimiento, setInicioVencimiento] = useState("");
  const [finVencimiento, setFinVencimiento] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  //Creación de variables para que pueda funcionar el Date 
  let fechaInicioVencimiento = null;
  let fechaFinVencimiento = null;
  
   const handleDataChanges = (newData) => {
     const fechaVencimiento = newData.toISOString();
     const mexicoCityTime = moment(fechaVencimiento).tz("America/Mexico_City");
     const fechasini = mexicoCityTime.format("YYYY-MM-DD");
     setInicioVencimiento(fechasini);
   }
   const handleDataChangesDos = (newData) => {
     const fechaVencimiento = newData.toISOString();
     const mexicoCityTime = moment(fechaVencimiento).tz("America/Mexico_City");
     const fechasini = mexicoCityTime.format("YYYY-MM-DD");
     setFinVencimiento(fechasini);
   }
  
   const handleClickDescargar = () => {
     // Lógica para manejar el clic en el botón
     console.log(cuenta)
  
     setInicioVencimiento(fechaInicioVencimiento);
     setFinVencimiento(fechaFinVencimiento);
     console.log(inicioVencimiento);
     console.log(finVencimiento);
  
  
     if(inicioVencimiento && finVencimiento){
  
       //filtaremos los datos en las fechas seleccionadas 
       const filteredResults = fechaVencimiento.filter((item) => {
  
         const itemDate = (item.fechaOcurrencia); // Asume que los datos tienen una propiedad 'date' con un valor de fecha
         const numCuenta = (item.numeroContable)//Constante que guardara solo los el Numero de cuenta
         console.log(itemDate);
         const cuentaIngresada = (cuenta);// el tipo de fallecimiento que mostrará
         return itemDate >= inicioVencimiento && itemDate <= finVencimiento && numCuenta == cuentaIngresada;
       });
       setFilteredData(filteredResults);
       console.log(filteredResults);
       setBtnDisable(false)
     } else {
       console.log("Por favor, selecciona una fecha inicial y una fecha final.");
     }
   };


  return (
<div>
      <form onSubmit={(e) => { e.preventDefault(); validateInputs(); }}>
      <div>
            {/* Grid solo del texto reporte libro de sinisestros ocurridos */}
            {/* Columna de texto */}
            <br />
            <Grid container justifyContent="center">
              <Grid item>
                <h3-header-card>
                  <FormattedMessage id="estatus" defaultMessage="REPORTE POR FECHA DE VENCIMIENTO" />
                </h3-header-card>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={5}>
              <Grid item xs={3}>
                <h3-subtitle-card>
                  <FormattedMessage id="estconFeIn" defaultMessage="Fecha de inicio" />
                </h3-subtitle-card>
              </Grid>
              <Grid item xs={3}>
                <h3-subtitle-card>
                  <FormattedMessage id="estFechFin" defaultMessage="Fecha final" />
                </h3-subtitle-card>
              </Grid>

              <Grid item xs={3}>
                <h3-subtitle-card>
                  <FormattedMessage id="estFechBus" defaultMessage="Cuentas a consultar" />
                </h3-subtitle-card>
              </Grid>
            </Grid>


            {/* Columna del boton */}
            <Grid container spacing={5}>
              <Grid item xs={3}>
              <DatePicker
                fecha={fechaInicioVencimiento}
                onDataChange={handleDataChanges}
              />
              </Grid>

              <Grid item xs={3}>
                <DatePicker
                fecha={fechaFinVencimiento}
                onDataChange={handleDataChangesDos}
              />
              </Grid>        
       
              <Grid container xs={3}
                style={{ paddingTop: '22px' }}
                justifyContent={'flex-end'}
              >
                <Grid item xs={11}>
                  <TXTField
                    onInput={(e) => LimitarDigitos(e.target , 6)}
                    onChange={handleChangeCuenta}
                    label="Número de cuenta"
                    id="cuenta" name="cuenta"
                    value={cuenta}
                  />                    
                </Grid>                
           </Grid>
           <Grid item xs={1}>
              <ButtonVino 
            label="Buscar"
            onClick={handleClickDescargar}/>
          </Grid>
          </Grid>
            <br />
            <br />
        </div>
      </form>    


      <Paper sx={{ width: "100%" }}>
              <TableContainer sx={{ maxHeight: 540 }} style={{ position: "sticky" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                    
                      <StyledTableCell > Número de cuenta </StyledTableCell>
                      <StyledTableCell> Fecha de ocurrencia </StyledTableCell>
                      <StyledTableCell> Fecha de constitución de reserva </StyledTableCell>
                      <StyledTableCell> Fecha de emisión de póliza </StyledTableCell>
                      <StyledTableCell> Número cuenta de reserva </StyledTableCell>
                      <StyledTableCell> Importe reserva </StyledTableCell>
                      <StyledTableCell> Número de póliza </StyledTableCell>
                      <StyledTableCell> Nombre del asegurado </StyledTableCell>
                      <StyledTableCell> Estatus del siniestro </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  
                    {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                    retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                    que coincidan con las letras ingresadas, */}
                    {filteredData.length > 0 && (
                      <TableBody>
                      {filteredData
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(item => (
                        <TableRow key={item.id}>                          
                          <StyledTableCell align="center">{item.numeroContable} </StyledTableCell>
                          <StyledTableCell align="center">{item.fechaOcurrencia}</StyledTableCell>
                          <StyledTableCell align="center">{item.reserva} </StyledTableCell>
                          <StyledTableCell align="center">{item.fechaDeVencimientoDeLaPoliza}</StyledTableCell>
                          <StyledTableCell align="center">{item.numeroContable}</StyledTableCell>
                          <StyledTableCell align="center">{item.importeContable}</StyledTableCell>
                          <StyledTableCell align="center">{item.NumPoliza}</StyledTableCell>
                          <StyledTableCell align="center">{item.nombreAsegurado}</StyledTableCell>
                          <StyledTableCell align="center">{item.estatusSiniestro}</StyledTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                    )}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                labelRowsPerPage="Filas por página"
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <br />
            <ButtonVino
              estatus={btnDisable}
              variant="contained"
              color="primary"
              size="small"
              label="Descargar"
              onClick={handleDownloadExcel}
            />
            <br />  

    </div>
  )
}