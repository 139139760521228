import React, { useState, useEffect } from "react";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import moment from 'moment';
import Calendar from "react-calendar";
import "./stilachos.css";
import { Grid } from "@mui/material";
import ReturnPage from "../../../Tools/Bottones/ButtonReturn";
import 'react-calendar/dist/Calendar.css';
import { getToken } from "../../../../api/token";
import { BASE_API } from "../../../../utils/constans";
// aa

export function Planes() {
  const [dias, setDias] = useState([]); // Estado para almacenar los días obtenidos de la API
  const [selectedDate, setSelectedDate] = useState(new Date()); // Estado para almacenar la fecha seleccionada en el calendario
  const [noLaborableDates, setNoLaborableDates] = useState([]); // Estado para almacenar los días no laborables

  const token = getToken();

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", `Bearer ${token}`);

  // Comienza el consumo de la api con el metodo GET
  const diadatas = () => {
    fetch(`${BASE_API}configuraciongeneral/get_id/1/`, {
      method: "GET",
      headers: headers
    })
      .then(response => response.json())
      .then(data => {
        setNoLaborableDates(data.SCG_dias.split(',')); // Obtiene los días no laborables de la API y los almacena en el estado
      })
      .catch(error => {
        console.error(error);
      });
  };

  useEffect(() => {
    diadatas(); // Obtiene los datos de los días no laborables cuando el componente se monta
  }, []);
  // Finaliza el consumo de la api 

  // Comienza con la actualizacion de los datos para que sea subidos directamente al campo que se le indica a la api
  const updateDias = (newDias) => {
    fetch(`${BASE_API}configuraciongeneral/get_id/1/`, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify({ SCG_dias: newDias }), // Envía los días actualizados a la API en el cuerpo de la solicitud
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setNoLaborableDates(newDias.split(',')); // Actualiza el estado con los días laborables actualizados devueltos por la API
      })
      .catch(error => {
        console.error(error);
      });
  };
  // Finaliza el metodo de actualizacion de los datos

  const año = moment().format('YYYY'); // Obtiene el año actual
  const primerDia = moment(año).startOf('year').format('YYYY-MM-DD'); // Obtiene el primer día del año actual
  const ultimoDia = moment(año).endOf('year').format('YYYY-MM-DD'); // Obtiene el último día del año actual

  const handleDateChange = (date) => {
    setSelectedDate(date); // Actualiza la fecha seleccionada en el estado al cambiar el calendario
  };

  const handleAddNoLaborableDate = () => {
    const newDates = [...noLaborableDates, moment(selectedDate).format('YYYY-MM-DD')]; // Agrega la fecha seleccionada al estado de días no laborables
    setNoLaborableDates(newDates);
    updateDias(newDates.join(',')); // Actualiza los días laborables en la API
  };

  const handleRemoveNoLaborableDate = () => {
    const updatedDates = noLaborableDates.filter(
      (date) => moment(date).format('YYYY-MM-DD') !== moment(selectedDate).format('YYYY-MM-DD') // Filtra los días no laborables para eliminar el día seleccionado
    );
    setNoLaborableDates(updatedDates);
    updateDias(updatedDates.join(',')); // Actualiza los días laborables en la API
  };

  return (
    <div>
      <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
        <ReturnPage href='/confadmin' /> 
      </Grid>
      <div className="parent">
        <div className="div1">
          <style>
            {`
             .react-calendar__tile.no-laborable {
                background-color: red !important;
                color: white !important;
              }
            `}
          </style>
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            maxDate={new Date(ultimoDia)} // Establece la fecha máxima permitida en el calendario
            minDate={new Date(primerDia)} // Establece la fecha mínima permitida en el calendario
            calendarType="US"
            tileClassName={({ date, view }) =>
              view === 'month' && noLaborableDates.some((d) => moment(d).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))
                ? 'no-laborable' // Agrega la clase "no-laborable" a los días no laborables para estilizarlos en el calendario
                : null
            }
          />
        </div>
        <div className="div2">
          <h3-breadcrumbs-secundary>Calendario</h3-breadcrumbs-secundary>
        </div>
        <div className="div3">
          <h3-breadcrumbs-secundary>Días no laborables:</h3-breadcrumbs-secundary>
          <ul>
            {noLaborableDates.map((date, index) => (
              <li key={index}>{moment(date).format('LL')}</li> // Muestra los días no laborables en formato legible
            ))}
          </ul>
        </div>
        <div className="div4">
{/* // Botón para agregar un día no laborable */}
          <ButtonVino label="Agregar día no laborable" onClick={handleAddNoLaborableDate} /> 
          <br />
          <br />
          {/* // Botón para eliminar el día seleccionado */}
          <ButtonVino label="Eliminar día seleccionado" onClick={handleRemoveNoLaborableDate} /> 
        </div>
      </div>
    </div>
  );
}
