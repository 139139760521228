import { differenceInYears } from "date-fns";
import parseISO from "date-fns/parseISO";

// patron del RFC, persona moral
const _rfc_pattern_pm =
  "^(([A-ZÑ&]{3})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
  "(([A-ZÑ&]{3})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
  "(([A-ZÑ&]{3})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
  "(([A-ZÑ&]{3})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
// patron del RFC, persona fisica
const _rfc_pattern_pf =
  "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
  "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
  "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
  "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
// Patrón validar el CURP, segun la estructura definida por el Registro Nacional de Población (RENAPO)
// Consultar : https://vidadigital.com.mx/es/expresiones/regulares/curp/
const regexCURP =
  "[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}" +
  "(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])" +
  "[HM]{1}" +
  "(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)" +
  "[B-DF-HJ-NP-TV-Z]{3}" +
  "[0-9A-Z]{1}[0-9]{1}$";

export function validateRFC(rfc) {
  let flag = true;
  if (rfc.match(_rfc_pattern_pm) || rfc.match(_rfc_pattern_pf)) {
    flag = true;
  } else {
    flag = false;
  }
  return flag;
}
export function validateCURP(curp) {
  let flag = true;
  if (curp.match(regexCURP)) {
    flag = true;
  } else {
    flag = false;
  }
  return flag;
}

//Validacion de solo numeros
export function LimitarDigitos(input, limite) {
  // Expresión regular que solo permite números
  const regex = /[^0-9]/g;
  input.value = input.value.replace(regex, "");
  if (input.value.length > limite) {
    input.value = input.value.substring(0, limite);
  }
}
/**
 * 
 * @param {*} value Recibe el valor de la cadena de texto ingresada
 * @param {*} limite Recibe la longitud límite de la cadena
 * @param {*} allMayus si el valor es tru, cambia a mayúsculas
 * @param {*} type null|undefined => Cualquier símbolo, 1=>Solo números, 2=> Solo letras, 3=> Solo letras y espacios en blanco, 4=> Letras y números
 * @returns 
 */
export function formatNumberString(value, limite, allMayus, type) {
  // Expresión regular que elimina caracteres no permitidos (no alfabéticos ni numéricos)
  let regex = "";
  if (type) {
    if (type === 1) {
      regex = /[^0-9]/g;
    } else if (type === 2) {
      regex = /[^a-zA-Z]/g;
    } else if (type === 3) {
      regex = /[^a-zA-Z\s]/g;
    } else if (type === 4) {
      regex = /[^a-zA-Z0-9]/g;
    }
    value = value.replace(regex, "");
  }
  if (allMayus) {
    value = value.substring(0, limite).toUpperCase();
  }
  if (value.length > limite) {
    value = value.substring(0, limite);
  }
  return value;
}

//Validacion de solo numeros
export function OnlyNumber(input) {
  // Expresión regular que solo permite números
  const regex = /[^0-9]/g;
  input.value = input.value.replace(regex, "");
}

// Función para extraer los nombres, apellido paterno y apellido materno
/**
 *
 * @param {*} nombreCompleto Recibe un string de un nombre completo, ejemplo: ABEL IGLESIAS CARRASCO,
 * con formato nombre(s) apellido paterno apellido materno
 * @param {*} clave Recibe un número clave que retornará lo siguiente: 1 si se requiere solo nombre(s),
 * 2 si se requiere solo apellido paterno, 3 si se requiere solo apellido materno, y otro cualquiera si
 * se requiere el objeto del nombre completo separado por cada elemento.
 *
 * @returns
 */
export function extractNameSurname(nombreCompleto, clave) {
  //Validamos si nombre es diferente de null
  if (typeof nombreCompleto !== "string") {
    return null; // Devuelve null
  }
  // Dividir el nombre completo en palabras usando el espacio como separador
  var palabras = nombreCompleto.split(" ");

  // Extraer el apellido materno (el último elemento del array)
  var apellidoMaterno = palabras.pop();

  // Extraer el apellido paterno (el penúltimo elemento del array)
  var apellidoPaterno = palabras.pop();

  // Unir las palabras restantes para obtener los nombres
  var nombres = palabras.join(" ");

  // Devolver un objeto con los nombres, apellido paterno y apellido materno segun la clave
  switch (clave) {
    case 1:
      return nombres;
    case 2:
      return apellidoPaterno;
    case 3:
      return apellidoMaterno;
    default:
      return {
        nombres: nombres,
        apellidoPaterno: apellidoPaterno,
        apellidoMaterno: apellidoMaterno,
      };
  }
}

/**
 *
 * @param {String} date1 Recibe una fecha válida en formato ISO string. (Minuendo).
 * @param {String} date2 Recibe una fecha válida en formato ISO string. (Sustraendo)
 * @returns {Number} Retorna la diferencia entre las fechas
 */
export function calculateAge(date1, date2) {
  try {
    let date1Format = parseISO(date1);
    let date2Format = parseISO(date2);
    const yearsDiff = differenceInYears(date1Format, date2Format);
    return yearsDiff ? yearsDiff : null;
  } catch (e) {
    console.error("Date invalid", e);
  }
}
/**
 *
 * @param {*} sumaAsegurada Recibe un número válido (string o númerico) del total a sacar el porcentaje
 * @param {*} importe Recibe un número válido (string o númerico) de la parte del total
 * @returns Retorna el porcentaje
 */
export function calcularPorcentajeBeneficiario(sumaAsegurada, importe) {
  // Convierte los valores a números de punto flotante y verifica su validez
  const sumaAseguradaFloat = parseFloat(sumaAsegurada);
  const importeFloat = parseFloat(importe);

  if (isNaN(sumaAseguradaFloat) || isNaN(importeFloat)) {
    console.log(sumaAsegurada)
    console.log(importe)
    console.log("Los valores proporcionados no son números válidos.");
    return null;
  }

  // Calcula el porcentaje y lo redondea a dos decimales antes de retornarlo
  return ((importeFloat / sumaAseguradaFloat) * 100).toFixed(2);
}

/**
 *
 * @param {*} sumaAsegurada Recibe un número válido (string o númerico) del total a sacar el importe
 * @param {*} porcentaje Recibe un número válido (string o númerico) del porcentaje de la parte total
 * @returns Retorna el importe
 */
export function calcularImporteBeneficiario(sumaAsegurada, porcentaje) {
  // Convierte y verifica que los valores sean números válidos
  const sumaAseguradaFloat = parseFloat(sumaAsegurada);
  const porcentajeFloat = parseFloat(porcentaje);

  if (isNaN(sumaAseguradaFloat) || isNaN(porcentajeFloat)) {
    console.error(
      "Los valores proporcionados no son números válidos.",
      sumaAsegurada,
      porcentaje
    );
    return null;
  }

  // Calcula el importe, lo redondea a dos decimales y lo retorna como cadena
  return ((porcentajeFloat / 100) * sumaAseguradaFloat).toFixed(2);
}

/**
 *
 * @param {*} event Recibe el event del textfield
 * @returns Retorna el número transformado (con comas en miles y dos decimales)
 */
export const formatedNumberAndDecimalAndComa = (event, type) => {

  let valor = "";
  if (type && type == 1) {
    valor = event.target.value.replace(/[^0-9.]|\-(?=\d)/g, "");

  } else {
    // reemplazar comas
    valor = event.target.value.replace(/[^0-9.-]|(?<=\..*)\./g, "");
  }
  // let valor = event.target.value.replace(/[^0-9.\-]|(?<=\..*)\./g, "");

  // Expresión regular para validar la cadena de texto tenga punto decimal y una o dos cifras a la derecha del punto
  let dosDecimales = /^\d+\.\d{1,2}$/;

  // Expresión regular para validar si la cadena de texto tiene mas de dos cifras a la derecha del punto, ademas de permitir numeros negativos
  let masDosDecimales = /^-?\d+(\.\d{3,})$/;



  // Verifica si el valor ingresado es un número, un punto o un guion medio
  if (!isNaN(parseFloat(valor)) || valor === "." || valor === "-") {


    if (parseFloat(valor) === NaN || valor === "") return "";
    if (valor === ".") {
      // Si el valor ingresado es e}un punto agrega un 0 antes del punto decimal.
      return `0${valor}`;
    }
    if (valor.length === 2 && valor.charAt(0) == 0 && !isNaN(valor.charAt(1))) {
      // valida si se ingresa un numero cero, lo sustituya opor la segunda cifra.
      return valor.charAt(1);
    }
    if (valor.match(/\.(.*)/) && dosDecimales.test(valor)) {
      // valida si la cadena de texto es un numero con uno o dos decimales
      return formatNumberComa(valor);
    }
    if (valor.match(/\.(.*)/) && masDosDecimales.test(valor)) {
      // Si la cadena de texto es un nummero con decimal y mas de dos decimales, se sustituye el penultimo caracter por el ultimo
      // Obtenemos la subcadena desde el inicio hasta el antepenúltimo carácter
      let subcadena1 = valor.slice(0, -2);
      // Obtenemos el último carácter
      let ultimoCaracter = valor.slice(-1);
      // Concatenamos la subcadena y el último carácter
      valor = subcadena1 + ultimoCaracter;
      return formatNumberComa(valor);
    } else if (valor.charAt(0) === "-" && valor.length >= 2 && valor.charAt(valor.length - 1) === "-") {
      return formatNumberComa(valor.slice(0, -1));
    } else if (valor.charAt(0) !== "-" && valor.length >= 2 && valor.charAt(valor.length - 1) === "-") {
      return formatNumberComa(valor.slice(0, -1));
    }

    //Si no ocurre ninguna de los anteriores validaciones, es porque se ha ingresado un número válido y positivo
    return formatNumberComa(valor);
  } else {
    return "";
  }
};

/**
 *
 * @param {String||Number} num Recibe un número en cadena de texto o tipo númerico y
 * le coloca el formato de numero separador de miles por comas, ademas de que valida si es decimal
 * @returns
 */
export function formatNumberComa(num) {
  try {
    const str = num.toString(); // Convertir el número a cadena de texto
    const decimalIndex = str.indexOf("."); // Obtener el índice del separador decimal

    if (decimalIndex !== -1) {
      // Si el número tiene decimales
      const decimalPart = str.slice(decimalIndex + 1); // Obtener la parte decimal
      const integerPart = str.slice(0, decimalIndex); // Obtener la parte entera
      const integerFormatted = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      ); // Agregar coma cada tres cifras en la parte entera

      return integerFormatted + "." + decimalPart; // Unir la parte entera y la parte decimal
    } else {
      // Si el número no tiene decimales
      return str.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Agregar coma cada tres cifras en la parte entera
    }
  } catch (e) {
    console.error(e);
  }
}
/**
 *
 * @param {String||Number} num Recibe un número en cadena de texto o tipo númerico y
 * le coloca el formato de numero separador de miles por comas, ademas de que valida si es decimal
 * @returns
 */
export function formatNumberDecimalComa(num) {
  try {
    let str = num;
    if (typeof str !== 'string') {
      str = num.toString(); // Convertir el número a cadena de texto
    }
    const decimalIndex = str.indexOf("."); // Obtener el índice del separador decimal

    if (decimalIndex !== -1) {
      // Si el número tiene decimales
      const decimalPart = str.slice(decimalIndex + 1); // Obtener la parte decimal
      const integerPart = str.slice(0, decimalIndex); // Obtener la parte entera
      const integerFormatted = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      ); // Agregar coma cada tres cifras en la parte entera

      return integerFormatted + "." + decimalPart; // Unir la parte entera y la parte decimal
    } else {
      // Si el número no tiene decimales
      return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00"; // Agregar coma cada tres cifras en la parte entera
    }
  } catch (e) {
    console.error(e);
    return null;
  }
}

// Exporta la función como un módulo
export function validateNumberAndDecimal(numero, limite) {
  let valor = numero;

  // Expresión regular para validar la cadena de texto tenga punto decimal y una o dos cifras a la derecha del punto
  let dosDecimales = /^\d+\.\d{1,2}$/;

  // Expresión regular para validar si la cadena de texto tiene mas de dos cifras a la derecha del punto
  let masDosDecimales = /^\d+(\.\d{3,})$/;

  // Verifica si el valor ingresado es un número, un punto o un guion medio
  if (!isNaN(parseFloat(valor)) || valor === ".") {
    if (valor.length > 2) {
      valor.replace(/,/g, '')
    }
    if (valor === ".") {
      // Si el valor ingresado es un punto agrega un 0 antes del punto decimal.
      return `0${valor}`;
    } else if (limite && valor.length > limite) {
      let patron = new RegExp(`^(\\d{1,${limite}}(\\.\\d{0,2})?)?$`);

      if (patron.test(valor)) {
        return valor;
      } else {
        if (valor.match(/\.(.*)/) && masDosDecimales.test(valor)) {
          // Si la cadena de texto es un nummero con decimal y mas de dos decimales, se sustituye el penultimo caracter por el ultimo
          // Obtenemos la subcadena desde el inicio hasta el antepenúltimo carácter
          let subcadena1 = valor.slice(0, -2);
          // Obtenemos el último carácter
          let ultimoCaracter = valor.slice(-1);
          // Concatenamos la subcadena y el último carácter
          valor = subcadena1 + ultimoCaracter;
          return formatNumber(valor);
        } else return formatNumber(valor.substring(0, valor.length - 1));
      }
    }

    if (valor.length > 0 && valor.includes(".")) {
      let patron = /\..*\./;
      let unPunto = patron.test(valor);
      if (unPunto) return valor.substring(0, valor.length - 1);
    }
    if (parseFloat(valor) === NaN || valor === "") return false;

    if (valor.length > 0 && valor.match(/\.(.*)/) && dosDecimales.test(valor)) {
      // valida si la cadena de texto es un numero con uno o dos decimales
      return formatNumber(valor);
    }

    //Si no ocurre ninguna de los anteriores validaciones, es porque se ha ingresado un número válido y positivo
    return formatNumber(valor);
  } else {
    return "";
  }
}

export function formatNumber(num) {
  const valor = (num.toString()).replace(/,/g, '');//Reemplazar comas
  const str = valor.toString(); // Convertir el número a cadena de texto
  const decimalIndex = str.indexOf("."); // Obtener el índice del separador decimal

  if (decimalIndex !== -1) {
    // Si el número tiene decimales
    const decimalPart = str.slice(decimalIndex + 1); // Obtener la parte decimal
    const integerPart = str.slice(0, decimalIndex); // Obtener la parte entera
    return integerPart + "." + decimalPart; // Unir la parte entera y la parte decimal
  } else {
    // Si el número no tiene decimales
    return parseFloat(str); // retornar el numero
  }
}

export function isPassword(password) {
  const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
  return regex.test(password);
}
