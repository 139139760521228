import React, { useState, useEffect } from 'react'
import "./stilachos.css"
import Card from "@mui/material/Card";
import "../../../../css/estilo.css"
//RETURN
import { Grid } from "@mui/material";
import ReturnPage from "../../../Tools/Bottones/ButtonReturn";
//idioma
import {FormattedMessage} from 'react-intl';
import TextField from '@mui/material/TextField';
import Check2 from '../../../Tools/CheckBox/Check2';
import  DialogButton  from "../../../Tools/Dialog/DialogButton"
import {diasestandarizados,
  tipo_estandarizados,     
  diasespecial,
  tipo_especial,
  diascomplejo,
  tipo_complejo,
  diascug,
  tipo_cug} from '../../../../api/getUmbrales';
import { postDataToken,putDataToken} from '../../../../api/dataToken';
import { bool } from 'yup';



export default function Dayservice() {
   
  const [value, setValue] = useState();
  const [especial, setEspecial] = useState();
  const [compleja, setCompleja] = useState();
  const [urgente, setUrgente] = useState();

//  const esta,espe,comp,cugg = useState();

  const [valorbool,setbool  ] = React.useState({
    estandarizados: false,
    especial: false,
    compleja:false,
    cug:false
  })
  function handler(e) {
    if (Number(e.target.value) > 30) {
      setValue(30);
    }else{
      setValue(e.target.value);
    }
  }

  function handler1(e) {
    if (Number(e.target.value) > 30) {
      setEspecial(30);
    }else{
      setEspecial(e.target.value);
    }
  }

  function handler2(e) {
    if (Number(e.target.value) > 30) {
      setCompleja(30);
    }else{
      setCompleja(e.target.value);
    }
  }

  function handler3(e) {
    if (Number(e.target.value) > 2) {
      setUrgente(2);
    }else{
      setUrgente(e.target.value);
    }
  }



  const handleviewestandar = (event) => {
   (valorbool.estandarizados) = (event.target.checked);
   console.log(valorbool.estandarizados)
  };

  const handleviewespecial = (event) => {
   (valorbool.especial) = (event.target.checked);;
 
  };

  const handleviewcompleja = (event) => {
   (valorbool.compleja) = (event.target.checked);;
 
  };
  
  const handleviewCUG = (event) => {
   (valorbool.cug) = (event.target.checked);;
 
  };

 


  const validateAndSubmitForm = (e) => {
    e.preventDefault();

    let aux = {
      SCG_DiasEstandares : value,//10 dias default
      SCG_DiasEstandares_bool : valorbool.estandarizados, //true = habiles L-V
      SCG_DiasEspeciales: especial,//15 dias default
      SCG_DiasEspecialess_bool:valorbool.especial,//true = habiles L-V
      SCG_DiasComplejos:compleja,//25 dias default
      SCG_DiasComplejos_bool:valorbool.compleja,//true = habiles L-V
      SCG_DiasUrgente:urgente,//2 dias default
      SCG_DiasUrgente_bool:valorbool.cug,//true = habiles L-V
  
    }
    console.log(valorbool.estandarizados)
  if (tipo_complejo.length < 0) {
    try {
      var result = postDataToken(`configuraciongeneral/get_id/1/`, aux);
      console.log("Registro exitoso", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  else{
    try {
      var result = putDataToken(`configuraciongeneral/get_id/1/`, aux);
      console.log("Registro actualizado", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  }
  
  console.log(value)
  
 
  return (
    <div>
    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
    <ReturnPage href='/confadmin'/>
</Grid>
    <div className='parentdias'>
     <Card sx={{ maxWidth: 280 }}> 
    <div className='dias1'>
    <folder-titles>{<FormattedMessage id="medCausGGSDSFaEnf" defaultMessage="Plataforma estandarizada"/>}</folder-titles>
    </div>
    <br/>
    <div className='dias2'>
     <TextField style={{ position: "sticky" }}
      id="Dias1"
      label="Max 30 dias"
      type="number"
      InputProps={{ inputProps: { min: "1", max: "30", step: "1" } }}
      onChange={handler}
      value={value}
      fullWidth
      placeholder={diasestandarizados}
      InputLabelProps={{ shrink: true }}
      margin="normal"
/>
<Check2
      //checked={valordef.medview}
      defaultChecked={tipo_estandarizados}
      onChange={(e) => handleviewestandar(e)}
      option1={<FormattedMessage id="globNo" defaultMessage="Dias Naturales (L-D)" />}
      option2={<FormattedMessage id="globSi" defaultMessage="Dias Habiles (L-V)" />}
    />
    </div>
    </Card>
    <Card sx={{ maxWidth: 280 }}>
    <div className='dias3'>
    <folder-titles>{<FormattedMessage id="medCaussaFDSGaEnf" defaultMessage="Plataforma Especial"/>}</folder-titles>
    </div>
    <br/>
    <div className='dias4'>
     <TextField style={{ position: "sticky" }}
      id="Dias1"
      label="Max 30 dias"
      type="number"
      InputProps={{ inputProps: { min: "1", max: "30", step: "1" } }}
      onChange={handler1}
      value={especial}
      fullWidth
      placeholder={diasespecial} 
      InputLabelProps={{ shrink: true }}
      margin="normal"
/>
<Check2
      //checked={valordef.medview}
      defaultChecked={tipo_especial}
      onChange={(e) => handleviewespecial(e)}
      option1={<FormattedMessage id="globNo" defaultMessage="Dias Naturales (L-D)" />}
      option2={<FormattedMessage id="globSi" defaultMessage="Dias Habiles (L-V)" />}
    />
    </div>
    </Card>
    <Card  Card sx={{ maxWidth: 280 }}>
    <div className='dias5'> 
    <folder-titles>{<FormattedMessage id="medCaFDSusaEnf" defaultMessage="Plataforma Compleja"/>} </folder-titles>
    </div>
    <br/>
    <div className='dias6'>
     <TextField style={{ position: "sticky" }}
      id="Dias1"
      label="Max 30 dias"
      type="number"
      InputProps={{ inputProps: { min: "1", max: "30", step: "1" } }}
      onChange={handler2}
      value={compleja}
      fullWidth
      placeholder={diascomplejo} InputLabelProps={{ shrink: true }}
      margin="normal"
    />
    <Check2
      //checked={valordef.medview}
      defaultChecked={tipo_complejo}
      onChange={(e) => handleviewcompleja(e)}
      option1={<FormattedMessage id="globNo" defaultMessage="Dias Naturales (L-D)" />}
      option2={<FormattedMessage id="globSi" defaultMessage="Dias Habiles (L-V)" />}
    />
    </div>
    </Card>
    <Card  Card sx={{ maxWidth: 280 }}>
    <div className='dias7'>
    <folder-titles>{<FormattedMessage id="medCaFDSusaEnf" defaultMessage="Plataforma urgente(CUG)"/>} </folder-titles>
    </div>
    <br></br>
    <div className='dias8'>
     <TextField style={{ position: "sticky" }}
      id="Dias1"
      label="Max 2 dias"
      type="number"
      InputProps={{ inputProps: { min: "1", max: "2", step: "1" } }}
      onChange={handler3}
      value={urgente}
      fullWidth
      placeholder={diascug}
      InputLabelProps={{ shrink: true }}
      margin="normal"
/>
<Check2
      //checked={valordef.medview}
      defaultChecked={tipo_cug}
      onChange={(e) => handleviewCUG(e)}
      option1={<FormattedMessage id="globNo" defaultMessage="Dias Naturales (L-D)" />}
      option2={<FormattedMessage id="globSi" defaultMessage="Dias Habiles (L-V)" />}
    />
    </div>
    </Card>
    <div className='dias9'>

    <DialogButton
       title="Guardar"
       onClick={validateAndSubmitForm}
       enlace="/confadmin"
       button="Aceptar"
      //  onSubmit={validateForm}
       description="Dias Guardados: Plataforma Estadarizada,Plataforma Especial,Plataforma Compleja,Plataforma Urgente"
       mainButton="Guardar"
      />
      
    </div>  
    </div>
    </div>
  );
}
