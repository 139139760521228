import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RadioInput from "../../Tools/RadioButton/RadioTable";
import IconButton from "@material-ui/core/IconButton";
import TXTField from "../../Tools/TextField/TextField";
//********************* */
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import TablePagination from "@mui/material/TablePagination";

//
import { getDataToken } from "../../../api/dataToken";
//idioma
import { FormattedMessage } from "react-intl";

//Flujo
import { Link } from "react-router-dom";
import { NestCamWiredStandRounded } from "@mui/icons-material";

/* ESTILOS DE LA TABLA */
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00203b",

    padding: 0,
    paddingLeft: "5px",
    height: "65px",
    color: theme.palette.common.white,
    fontSize: "20px",
    borderColor: "#e2e2e2",
    border: "1px solid #e2e2e2",
    fontFamily: "Avenir-Light",
  },
  [`&.${tableCellClasses.body}`]: {
    height: "20px",
    padding: 0,
    fontSize: "16px",
    borderColor: "#e2e2e2",
    border: "1px solid #e2e2e2",
    fontFamily: "Avenir-Light",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    //backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    //border: 0,
  },
}));

export default function TableFolios() {
 
  const [siniestro, setSiniestros] = useState([]);
  const uniquePolizaSet = new Set();


  const getSiniestros = async () => {
    try {
      var result = await getDataToken("wktramite/get_listado/");
      
      console.log("Resultado de la API workflow =>", result);

      setSiniestros(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getSiniestros();
  }, []);



  function createData(siniestro) {
    return { siniestro };
  }
  /* CONSTANTES DE ESTADOS*/
  
  const [order, setOrder] = useState("ASC"); // esta constante tiene como propósico guardar el estado del orden de la tabla
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla
  const [InfoDataRow, setInfoDataRow] = useState(""); //Aqui es donde almacenará los datos de la fila seleccionadad
  const [btnDownloadInfo, setBtnDownloadInfo] = useState(true);
  const [btnContinuar, setBtnContinuar] = useState(true); //Esta funcion validara el boton continuar para ir al siguiente paso
  const [folios, setFolios] = useState(false);
  const [habilitaLink, setHabilitaLink] = useState(false); //activacion y desactivacion del link ]



  const rows = [createData(siniestro)];


  /* FUNCIÓN PARA ORDENAR LA TABLA ASC/DSC (Ascendent/ Descendant) */
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...siniestro].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setOrder("DSC");
      setSiniestros(sorted);
    } else if (order === "DSC") {
      const sorted = [...siniestro].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? -1 : 1
      );
      setOrder("ASC");
      setSiniestros(sorted);
    }
  };

  /* FUNCIÓN CLICK EN LOS RADIO BUTTON, ARROJA INFORMACION DE LA FILA SELECCIONADA */
  const rbClick = (e) => {
    const inputFolio = parseInt(e.target.value);
    siniestro.map((row) => {
      if (row.WKT_idworktramite == inputFolio) {
        console.log(row);
        setInfoDataRow(row);
        setBtnDownloadInfo(false);
        setBtnContinuar(false); //cuando se seleecciones un registro se habilitara continuar
        //Envio pantalla InformacionWF
        setFolios(row);
        setHabilitaLink(true);

      }
    });
  };

  console.log("Prueba de datos a enviar:", folios);
  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <div>
      <Grid
        style={{ paddingBottom: "20px" }}
        container
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={4}>
          <h4-search>
            <FormattedMessage id="glob.buscar" defaultMessage="Buscar" />:
          </h4-search>
          
          <TXTField
            showSearchIcon={true}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="globSelecc"
                    defaultMessage="Seleccionar"
                  />
                </StyledTableCell>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                <StyledTableCell
                  align="center"
                  onClick={() => sorting("row.POL_idpoliza?.POL_idpoliza")}
                >
                  <FormattedMessage
                    id="globNoDeFo"
                    defaultMessage="No. de folio"
                  />
                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    <UnfoldMoreIcon />
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  onClick={() => sorting("row.PER_idpersona?.PER_idpersona")}
                >
                  <FormattedMessage
                    id="globNoPoli"
                    defaultMessage="No. de póliza"
                  />
                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    <UnfoldMoreIcon />
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => sorting("numSiniestros")}
                  align="center"
                >
                  <FormattedMessage
                    id="globNSin"
                    defaultMessage="No. de siniestro"
                  />
                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    <UnfoldMoreIcon />
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="globTiDeSi"
                    defaultMessage="Tipo de siniestro"
                  />
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  onClick={() => sorting("nombreAsegurado")}
                >
                  <FormattedMessage
                    id="globNomAse"
                    defaultMessage="Nombre del Asegurado"
                  />
                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    <UnfoldMoreIcon />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {siniestro
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                    val.POL_idpoliza?.POL_numpoliza.toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                    val.PER_idpersona?.PER_nombre.toLowerCase()
                    .includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  }

                  
                })
                .filter((row) => {
                  const polizaValue = row.POL_idpoliza?.POL_numpoliza;
                  if (!uniquePolizaSet.has(polizaValue)) {
                    uniquePolizaSet.add(polizaValue);
                    return true;
                  }
                  return false;
                })
                .map((row) => (
                  <StyledTableRow key={row.WKT_idworktramite}>
                    <StyledTableCell component="th" scope="row" align="center">
                      <RadioInput
                        name="rb"
                        value={row.WKT_idworktramite}
                        onChange={rbClick}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.RECT_idfolio?.RECT_numerofolio}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.POL_idpoliza?.POL_numpoliza}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.SIN_idsiniestro?.SIN_numerosiniestro}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.SIN_idsiniestro?.SIN_causadereclamacion}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                    {row.PER_idpersona?.PER_nombre}{" "}
                      {row.PER_idpersona?.PER_apellidopaterno}{" "}
                      {row.PER_idpersona?.PER_apellidomaterno}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
         labelRowsPerPage="Registros por página:"
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <br />
      <Grid container>
        <Grid xs={2}>

          <Link
          to = "/informacionWF"
          state = {{folios}}
          style = {{textDecoration: "none"}}
          onClick={(e) => !habilitaLink && e.preventDefault()} 
          >
          
          
          <ButtonVino
            variant="contained"
            color="primary"
            size="small"
            estatus={btnContinuar}
            label={
              <FormattedMessage id="dictamCont" defaultMessage="Continuar" />
            }

          />
          </Link>
          <br />
        </Grid>
        <Grid xs={6}></Grid>
        <Grid xs={2}>
          <br />
        </Grid>
      </Grid>

      <br></br>
    </div>
  );
}