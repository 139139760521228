import { Login } from "../pages/Login/ModLogin/Login";
import { ActualizarContra } from "../pages/Login/ModLogin/ActualizarContra";
import { Registro } from "../pages/Login/ModLogin/Registro";
import { RestablecerContra } from "../pages/Login/ModLogin/RestablecerContra";
import { RecuperarContra } from "../pages/Login/ModLogin/RecuperarContra";
import { Inicio } from "../pages/Login/ModLogin/Inicio";
import { LoginLayaout } from "../layouts/Login/LoginLayout";
import { BasicLayout } from "../layouts/BasicLayout";
import { InvalidToken } from "../api/InvalidToken";
import { Unauthorized } from "../api/unauthorized";


const routesClient = [
  {
    path: "/login",
    layout: BasicLayout,
    component: Login,
    exact: true,
  },
  {
    path: "/Actualizar-Contra",
    layout: LoginLayaout,
    component: ActualizarContra,
    exact: true,
  },
  {
    path: "/Registro",
    layout: LoginLayaout,
    component: Registro,
    exact: true,
  },
  {
    path: "/Restablecer-Contra",
    layout: LoginLayaout,
    component: RestablecerContra,
    exact: true,
  },
  {
    path: "/Recuperar-Contra",
    layout: LoginLayaout,
    component: RecuperarContra,
    exact: true,
  },
  {
    path: "/Inicio",
    layout: LoginLayaout,
    component: Inicio,
    exact: true,
  },
  {
    path: "/InvalidToken",
    layout: LoginLayaout,
    component: InvalidToken,
    exact: true,
  },
  {
    path: "/Unauthorized",
    layout: LoginLayaout,
    component: Unauthorized,
    exact: true,
  },
];

export default routesClient;
