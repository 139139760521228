import React from "react";
import Nav from "../../components/Tools/Header/Nav"
import Footer from "../../components/Tools/Footer/Footer";

export function LoginLayaout(props){
    const { children } = props;
   
    return(
        <div>
            <Nav/>
            {children}
            <Footer/>
        </div>
    );
}