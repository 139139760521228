import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material';
import {Select1} from "../../Tools/Selectss/Select1"
import {Select2} from "../../Tools/Selectss/Select2"
import ButtonVino from '../../Tools/Bottones/ButtonVino';
//importaciones de formularios o tablas que se necesitan
import AccountingMovements from "../Elements/AccountingMovements";//Generar moviento contable
import TableNationalCurrency from "../Elements/TableNationalCurrency";//Moneda nacional
import TableDollar from "../Elements/TableDollar";//Moneda Dolar
import TableConsultation from "../Elements/TableConsultation";//Consulta e integracion pds
//Idioma
import { FormattedMessage } from "react-intl";

const CompIntegrador = ()=>{

  //Variable para que puedan aparecer las tablas y formularios
  const [consultaMovimiento, setConsultaMovimiento] = useState(false);
  const [consultaIntegracion, setConsultaIntegracion] = useState(false);
  const [monedaNacional, setMonedaNacional] = useState(false);
  const [dolar, setDolar] = useState(false);
  //variable para le boton de regreso
  const[showBtnRegresarLista, setShowBtnRegresarLista]= useState (false)
  const[showBtnRegresarTabla, setShowBtnRegresarTabla]= useState (false)
  const [valorSelectUno, setValorSelectUno] = useState(0)
  const [valorSelectDos, setValorSelectDos] = useState(0)
  const [habilitado, setHabilitado] = useState(true);

//********************INTEGRADOR DE CONTABILIDAD****************/
//--Aquí apareceran el primer menú
const [integrador, setIntegrador] = useState([]);
const integradorContable = [
  { identificador: "1", texto: "Generar movimiento contable" },
  { identificador: "2", texto: "Creación y consulta de las PD´S" },
];

useEffect(() => {
  setIntegrador(integradorContable);
}, []);

//subSubMenu estas son las opciones de intefrador contable
const [moviento, setMovimiento] = useState([]); //recibira los el nuevo arreglo
const [creacion, setCreacion] = useState(false);
const moviminetoCreacion = [
  {id: 1, integradorId: 2, identificador: "1", texto: "Generar la PD contable", },
  {id: 2, integradorId: 2, identificador: "2", texto: "Consulta e integración de PD´s",},
];

const handleOption2 = (id) => {
  const dt = moviminetoCreacion.filter((x) => x.integradorId == id);
  console.log(dt);
  setMovimiento(dt);
  
  if(id==1){
    setConsultaMovimiento(true)//Generar movimiento contable
    setCreacion(false)//Segundo select
    setInternacional(false)//tercer select
    setConsultaIntegracion(false)//Tabla de consulta e integracion PD´S
    setMonedaNacional(false)//PD nacional
    setDolar(false)//PD dolar
    setShowBtnRegresarLista(true)
  }else{
    setCreacion(true); //para que aparezca el select cuando llamen este metodo
    setConsultaMovimiento(false)//Generar movimineto contable
    setShowBtnRegresarLista(true) 
  }
 
};

//subSubSubMenu de Integrador de contabilidad
const [nacional, setNacional] = useState([]);
const [internacional, setInternacional] = useState(false);
const nacionalInternacional = [
  { id: 1, movientoId: 1, identificador: "1", texto: "Crear la PD nacional", },
  { id: 2, movientoId: 1, identificador: "2", texto: "Crear PD moneda dólares", },
];

const handleOption3 = (id) => {
  const dt = nacionalInternacional.filter((x) => x.movientoId == id);
  console.log(dt);
  setNacional(dt);
  
  if(id==1){
    setInternacional(true);//tercer select
    setConsultaIntegracion(false)//Tabla de consulta e integracion PD´S
    setMonedaNacional(false)//PD nacional
    setDolar(false)//PD dolar
    setShowBtnRegresarTabla(true)
  }else{
    setConsultaIntegracion(true)
    setInternacional(false)
    setMonedaNacional(false)
    setDolar(false)
    setShowBtnRegresarTabla(true)
  }
  
};
const handleOptionUltimaMovimientos = (ide) => {
  const dt = nacionalInternacional.filter((x) => x.id == ide);
  if(ide==1){
    setMonedaNacional(true)
    setDolar(false)
  }else{
    setDolar(true)
    setMonedaNacional(false)
  }
};

const handleChangeMenu = (e) => {
  handleOption2(e.target.value);
  setValorSelectUno(e.target.value);
  setHabilitado(false); // Habilitar el select2
};

const handleClickRegresarLista = (e) => {
  setCreacion(false);
  setConsultaMovimiento(false)
  setShowBtnRegresarLista(false);
  setValorSelectUno(0) 
  setHabilitado(true); // Habilitar el select2
};
const handleChangeMenuDos = (e) => {
  setShowBtnRegresarLista(false)
  handleOption3(e.target.value);
  setValorSelectDos(e.target.value);
  setHabilitado(false);
};

const handleClickRegresarTabla = (e) => {
  handleOption3(e.target.value);
  setValorSelectDos(e.target.value);
  setShowBtnRegresarLista(true)
  setConsultaIntegracion(false)
  setMonedaNacional(false)
  setDolar(false)
  setValorSelectDos(0)
  setShowBtnRegresarTabla(false)
};


return(
  <>
  <Grid container>
    {/*En este contenido llamaremos a los select necesarios para realizar nuestro menu
      Array=pondremos el arreglo que queremos mostrar 
      onChange= pondremos el handle que deseamos que necesitamos en esa opción      
      */}
      <Grid xs={4}>
         
          <Select2 array={integradorContable} sx={{ minWidth: 310, maxWidth: 300, background: "white", }} h3-contable='Buscar por:'
            id="primerSelect" value={valorSelectUno} onChange={handleChangeMenu}  disabled={!habilitado}/>
      </Grid>
      <Grid xs={4}>
      { creacion?(<Select2 array={moviento}
      sx={{ minWidth: 310, maxWidth: 300, background: "white", }} h3-contable='Buscar por:'
      id="segundoSelect"
      value={valorSelectDos}
      onChange={handleChangeMenuDos} /> ): null }
      
      </Grid>
      <Grid xs={4}>
      { internacional?(<Select1 sx={{ minWidth: 310, maxWidth: 300, background: "white", }} h3-contable='Buscar por' 
      id='subSubIntegradorContabilidad' array={nacional} onChange={(e)=> handleOptionUltimaMovimientos(e.target.value)} />): null }
      </Grid>
      <Grid xs={12}>
     {/*Se colocan todas las opciones de lo que se va a a parecer dependiendo de lo que es seleccionado en el menu */}
          {consultaMovimiento?<AccountingMovements/>:null}
          {consultaIntegracion?<TableConsultation/>:null}
          {monedaNacional?<TableNationalCurrency/>:null}
          {dolar?<TableDollar/>:null}
      </Grid>

      {/* Vista del boton donde limpia los datos del select 2 y solo muestra el 1 */}
      {showBtnRegresarLista? <Grid container justifyContent="flex-end">
      <Grid item>
        <ButtonVino
            onClick = {handleClickRegresarLista}
            label={<FormattedMessage id="globRegres" defaultMessage="Regresar"/>}
          />
        </Grid>
      </Grid>:null}

    {/* Vista del Boton para limpiar las tablas */}
    {showBtnRegresarTabla? <Grid container justifyContent="flex-end">
      <Grid item>
      <ButtonVino
          onClick = {handleClickRegresarTabla}
          label={<FormattedMessage id="globRegres" defaultMessage="Regresar"/>}
        />
      </Grid>
    </Grid>:null}
  </Grid>
</>
  )
}
export default CompIntegrador;