import * as React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { makeStyles } from "@material-ui/core";
import { formatofecha } from "../../../api/getUmbrales";
import dayjs from "dayjs";
import "dayjs/locale/es";


export default function DatePicker2(props) {
  const [value, setValue] = React.useState(props.fecha); //props fecha recibirá la fecha que se quiera mostrar en el cambpo fecha

  dayjs.locale("es");

  React.useEffect(() => {
    setValue(props.fecha);
  }, [props.fecha]);

  const useHelperTextStyles = makeStyles(() => ({
    root: {
      background: "#fafafa",
      margin: "60px 0px 0px 0px !important",
      paddingLeft: "10px",
      paddinTop: "0px",
      position: "absolute",
    },
  }));
  const helperTextStyles = useHelperTextStyles();
  return (

    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} locale="es">
        <Stack
          style={{
            background: "white",
            marginTop: "16px",
            marginBottom: "8px",
          }}
        >
          <DatePicker
            readOnly={props.readOnly}
            disabled={props.disabled}
            views={["day", "month", "year"]}
            inputFormat={formatofecha}
            label={props.label}
            value={value}
            minDate={props.minDate}
            maxDate={props.maxDate}
            onChange={(newValue) => {
              setValue(newValue);
              props.onDataChange(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                FormHelperTextProps={{
                  classes: {
                    root: helperTextStyles.root,
                  },
                }}
                error={props.error}
                helperText={props.helperText}
                id={props.id}
                name={props.name}
                required={props.required}

                // El color tampoco cambia es el definido por la empresa, no se pide valor
                color="gray"
              />
            )}
          />
        </Stack>
      </LocalizationProvider>
    </>
  );
}
