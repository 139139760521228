/* Brandon Hernandez Rocha 11/2022 */
/* ----------------------------------------------------------------------------------------------------------------------------------------------- */
/* IMPORTACIONES DE COMPOMENTES */
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RadioInput from "../../Tools/RadioButton/RadioTable";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import IconButton from "@material-ui/core/IconButton";
import "./Investigador.css";
import Grid from "@material-ui/core/Grid";
import DialogDownloadInfo from "../Elements/DialogDownloadInfo";
import DialogDownloadAviso from "../Elements/DialogDownloadAviso";
import DialogUploadDoc from "../Elements/DialogUploadInfo";
import TablePagination from "@mui/material/TablePagination";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import SearchTXTField from "../../Tools/TextField/SearchTextField";
import StyledTableCell from "../../Tools/Table/StyledTableCell";
import TXTField from "../../Tools/TextField/TextField";
import DialogEnviarAInvestigador from "../../Dictamen/Elements/Dialogs/DialogEnviarAInvestigador";
import DialogObtenerAInvestigador from "../../Dictamen/Elements/Dialogs/DialogObtenerAInvestigador";
//moment
import moment from "moment";
//umbral
import {
  umbralCancelado,
  umbralEnProceso,
  umbralATiempo,
  diasoficiales,
  tipo_estandarizados,
  tipo_especial,
  tipo_complejo,
  tipo_cug,
  dias_investigador_especial,
  dias_investigador_compleja,
  formatofecha,
} from "../../../api/getUmbrales";
//idioma
import { FormattedMessage } from "react-intl";
//api
import {
  getDataToken,
  postDataToken,
  putDataToken,
} from "../../../api/dataToken";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { getFiles } from "../../../nextcloud/functions";
import { LimitarTexto } from "../../Recepcion/Elements/Functions/Validaciones";
import {
  IDMODDICTAMEN,
  IDMODINVESTIGADOR,
  APARTADO,
} from "../../../utils/constanstoken.js";
import getDate from "../../Registro/Function/obtenerFecha";
import getHours from "../../Registro/Function/obtenerHora";
import DialogAviso from "../../Registro/Elements/Dialog/DialogAviso";

/* ----------------------------------------------------------------------------------------------------------------------------------------------- */
/* CONSTANTES PARA EL GUARDADO DE DATOS */
export default function InvestigadorTable() {
  const [datosApi, setDataApi] = useState([]);
  const [idData, setID] = useState([]);

  //GET PARA SABER CON EL CORREO SU ID Y CON ESO PINTAR LA TABLA
  const getPermisos = async () => {
    //Resultados de la api investigacion
    var resultID = await getDataToken(
      "login/get_correo/" + localStorage.getItem("SNYTLOG_email") + "/"
    );
    setID(resultID);
  };
  useEffect(() => {
    getPermisos();
  }, []);

  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  //dias se mapean y sy meten ala libreria especial
  const mapafecha = diasoficiales.map((elemento) => elemento);
  var arreglodias = [];

  var moment = require("moment-business-days");
  moment.updateLocale("us", {
    holidays: mapafecha,
    holidayFormat: "YYYY-MM-DD",
  });

  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  /* CONSULTA A LAS APIS CONSUMIDAS */
  const getDataInvestigacion = async () => {
    var resultInves = await getDataToken(
      "investigacion/get_idtrabajador2/" + idData.id + "/"
    );
    console.log("Resultados de la Api investigación", resultInves);
    setDataApi(resultInves);
  };

  useEffect(() => {
    getDataInvestigacion();
  }, [idData.id]);

  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  /* CONSTANTES DE ESTADOS*/
  const [order, setOrder] = useState("ASC"); // esta constante tiene como propósico guardar el estado del orden de la tabla
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla
  const [infoDataRow, setInfoDataRow] = useState(""); //Aqui es donde almacenará los datos de la fila seleccionadad
  const [btnDownloadInfo, setBtnDownloadInfo] = useState(true); //Boton para descargar la infomacion de la fila seleccionada
  const [btnUploadDoc, setBtnUploadDoc] = useState(true); //Boton para subir la informacion de la fila seleccionada

  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  /* FUNCIÓN PARA ORDENAR LA TABLA ASC/DSC (Ascendent/ Descendant) */
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...datosApi].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setOrder("DSC");
      setDataApi(sorted);
    } else if (order === "DSC") {
      const sorted = [...datosApi].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? -1 : 1
      );
      setOrder("ASC");
      setDataApi(sorted);
    }
  };
  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  /* RADIO BUTTON  */
  /* FUNCIÓN CLICK EN LOS RADIO BUTTON, ARROJA INFORMACION DE LA FILA SELECCIONADA */

  const rbClick = (e) => {
    const inputFolio = parseInt(e.target.value);
    console.log(inputFolio);
    datosApi.forEach((row) => {
      if (row?.pxfs_data[0]?.id_poliza === inputFolio) {
        console.log(row);
        setInfoDataRow(row);
        var existInforme = numInformes.some((e) => {
          return e.numFolio === row.num_folio && e.numFile > 9;
        });
        if (existInforme || row.descargado) {
          setBtnDownloadInfo(true);
          setBtnUploadDoc(true);
        } else {
          setBtnDownloadInfo(false);
          setBtnUploadDoc(false);
        }
      }
    });
  };
  console.log(btnDownloadInfo);
  console.log(btnUploadDoc);
  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  /* CONTANTES CORRESPONDIENTES AL CONTEO DE INFORMES */
  const [numInformes, setNumInformes] = useState([]);
  var datos = [];

  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  /* FUNCION PARA HACER EL CONTEO DE LOS INFORMES EXISTENTES POR FOLIO */
  const contInformesXFolio = async () => {
    const folios = [];
    datosApi?.map((row) => {
      folios.push(row.num_folio);
    });
    console.log("FOLIOS DATOS API", folios);

    for (let index = 0; index < folios.length; index++) {
      var response = await getFiles(folios[index]);
      //console.log(response);
      if (response.length > 0) {
        let arrayArchivos = [];
        response.map((e) => {
          arrayArchivos.push(e.fileName);
        });
        console.log("Array de informes", arrayArchivos);
        let result = 0;
        for (let i = 0; i < arrayArchivos.length; i++) {
          console.log("FOLIO", folios[index]);
          if (
            arrayArchivos[i][0] === "I" &&
            arrayArchivos[i][1] === "N" &&
            arrayArchivos[i][2] === "V"
          ) {
            result++;
          }
          //console.log("Numero de informes", result , "del FOLIO", folios[index]);
        }
        console.log("Numero de informes", result, "del FOLIO", folios[index]);
        datos.push({ numFolio: folios[index], numFile: result });
      }
      setNumInformes(datos);
    }
    //console.log("Arreglo de informesxfolio" , numInformes)
  };

  useEffect(() => {
    contInformesXFolio();
    console.log("Arreglo de informesxfolio", numInformes);
  }, [datosApi]);

  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  //CCONSTANTES DE ESTADOS PARA EL CONTADOR DE LAS CARPETAS DEL ENCABEZADO DE LA TABLA
  const [contador, setContador] = useState({
    urgente: 0,
    atiempo: 0,
    porterminar: 0,
    terminado: 0,
  });

  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  //METODO DONDE SE HACE EL CONTEO DE LOS FOLIO SEGUN SEA EL SEMAFORO
  useEffect(() => {
    if (datosApi?.length > 0) {
      let urgente = 0;
      let porterminar = 0;
      let atiempo = 0;
      let terminado = 0;
      for (let index = 0; index < datosApi.length; index++) {
        const row = datosApi[index];
        //console.log("Datos obtenidos de las APIS:", datosApi)
        //se saca la diferentencia entre los dias de asueto con la fecha actual
        var tiempos = moment().businessDiff(
          moment(row.fch_llegada, "YYYY-MM-DD")
        );
        var existInforme = false;
        //descargarArchivos(row.numFolio);
        numInformes.map((e) => {
          if (e.numFolio === row.num_folio && e.numFile > 0) {
            existInforme = true;
          }
        });

        if (existInforme) {
          terminado = terminado + 1;
        } else if (
          tiempos > dias_investigador_compleja ||
          tiempos > dias_investigador_especial
        ) {
          urgente = urgente + 1;
        } else if (
          tiempos == dias_investigador_compleja ||
          tiempos == dias_investigador_especial
        ) {
          porterminar = porterminar + 1;
        } else if (
          tiempos < dias_investigador_compleja ||
          tiempos < dias_investigador_especial
        ) {
          atiempo = atiempo + 1;
        }
      }
      //prevState es donde guardamos los valores anteriores de nuestro contador u lo modificamos a lo nuevo
      setContador((prevState) => ({
        ...prevState,
        urgente: urgente,
        porterminar: porterminar,
        atiempo: atiempo,
        terminado: terminado,
      }));
    }
  }, [datosApi]);

  //METODO PARA CAMBIO DE NOMBRE...

  const actualizarDatosDespuesDeDescarga = () => {
    getDataInvestigacion();
    const updatedInfoDataRow = { ...infoDataRow, descargado: true };
    setInfoDataRow(updatedInfoDataRow);
  };

  console.log(infoDataRow);

  //////////////////////////////////////////////////////////////
  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");

  console.log(moment(fechaEmpAn).format(formatofecha));
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();

  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;

  const [guardarDatos, setguardarDatos] = useState([]);
  const [indicaciones, setindicaciones] = useState([]);
  //Obtener usuario.
  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");
  const [idTrabajador, setidTrabajador] = React.useState("");

  //GET para obtener los datos guardados anteriormente
  const getguardado = async () => {
    try {
      var result = await getDataToken(
        `mensajes/historical_filter_dictamen/?folio=${infoDataRow?.id_folio}&poliza=${infoDataRow?.pxfs_data[0]?.id_poliza}&modulo=${IDMODDICTAMEN}`
      );
      setindicaciones(result);
      var resultInd = await getDataToken(
        `mensajes/historical_filter_investigador/?folio=${infoDataRow?.id_folio}&poliza=${infoDataRow?.pxfs_data[0]?.id_poliza}&modulo=${IDMODINVESTIGADOR}`
      );
      setguardarDatos(resultInd);
      var resultLogin = await getDataToken(`login/get_correo/${email}/`);
      setidTrabajador(resultLogin.id);
      setnombreAnalista(
        resultLogin.SNYTLOG_username +
          " " +
          resultLogin.SNYTLOG_surnamedad +
          " " +
          resultLogin.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.error("There was an error:", error);
    }
  };
  useEffect(() => {
    getguardado();
  }, [infoDataRow]);

  console.log(guardarDatos);
  console.log(indicaciones);

  const resultado = guardarDatos?.reduce((maxFecha, item) => {
    const fecha = new Date(item.MSG_actualizofecha);

    if (!maxFecha || fecha > maxFecha.fecha) {
      return { fecha, elemento: item };
    }

    return maxFecha;
  }, null);

  const elemento = resultado?.elemento;

  console.log(elemento);

  /////////
  const resultadoInd = indicaciones?.reduce((maxFecha, item) => {
    const fecha = new Date(item.MSG_actualizofecha);

    if (!maxFecha || fecha > maxFecha.fecha) {
      return { fecha, elemento: item };
    }

    return maxFecha;
  }, null);

  const elementoInd = resultadoInd?.elemento;

  console.log(elementoInd);

  const [values, setValues] = React.useState({
    observaciones: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  React.useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      observaciones: elemento ? elemento?.MSG_mensaje : "",
    }));
  }, [elemento]);

  async function postMSG(aux) {
    try {
      var result = await postDataToken(`mensajes/get_listado/`, aux);
      console.log("Registro exitoso msg", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  const [visible, setVisible] = useState(false);

  const validateInvestigador = (e) => {
    e.preventDefault();
    if (elemento) {
      const guardarmsg = {
        RECT_idfolio: infoDataRow?.id_folio,
        POL_idpoliza: infoDataRow?.pxfs_data[0]?.id_poliza,
        IDTRABAJADOR: idTrabajador,
        WFM_idwfmodulo: IDMODINVESTIGADOR,
        MSG_mensaje: values?.observaciones,
        MSG_apartado: APARTADO.INVESTIGADOR,
        MSG_actualizofecha: fechaActSIN,
        MSG_actualizousuario: nombreAnalista,
      };
      putDataToken(
        `mensajes/get_id/${elemento.MSG_idmensaje}/`,
        guardarmsg
      ).then((data) => {
        console.log(`Registro ${elemento.MSG_idmensaje} actualizado`);
        console.log(data);
      });
      setVisible(true);
    } else {
      const guardarmsg = {
        RECT_idfolio: infoDataRow?.id_folio,
        POL_idpoliza: infoDataRow?.pxfs_data[0]?.id_poliza,
        IDTRABAJADOR: idTrabajador,
        WFM_idwfmodulo: IDMODINVESTIGADOR,
        MSG_mensaje: values?.observaciones,
        MSG_apartado: APARTADO.INVESTIGADOR,
        MSG_actualizofecha: fechaActSIN,
        MSG_actualizousuario: nombreAnalista,
      };
      postMSG(guardarmsg);

      setVisible(true);
    }
  };

  const handleClick = () => {
    // Actualizar el estado utilizando el hook useState
    setVisible(false);
    getguardado();
  };

  return (
    <div>
      {visible && (
        <DialogAviso
          title="Envio exitoso"
          firstButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description={"Envio realizado exitosamente."}
          onClick1={handleClick}
        />
      )}
      {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
      {/* SECCION DE CARPETAS IMPLEMENRANDO EL CONTADOR DE CASOS */}
      <Grid container>
        <Grid item xs={4}>
          <section className="sec-carpetas">
            <FolderOutlinedIcon style={{ color: "#F42B00" }} />
            <folder-titles>
              <FormattedMessage
                id="investCaUr"
                defaultMessage="Casos urgentes"
              />
              <folder-titles-cases id="pCasosUrgentes">
                {contador.urgente}
              </folder-titles-cases>
            </folder-titles>
          </section>
        </Grid>
        <Grid item xs={4}>
          <section className="sec-carpetas">
            <FolderOutlinedIcon style={{ color: "#FFE400" }} />
            <folder-titles>
              <FormattedMessage
                id="investCaTe"
                defaultMessage="Casos por terminar"
              />
            </folder-titles>
            <folder-titles-cases id="pCasosPorTerminar">
              {contador.porterminar}
            </folder-titles-cases>
          </section>
        </Grid>
        <Grid item xs={4}>
          <section className="sec-carpetas">
            <FolderOutlinedIcon style={{ color: "#58E308" }} />
            <folder-titles>
              <FormattedMessage
                id="investCaTi"
                defaultMessage="Casos a tiempo"
              />
            </folder-titles>
            <folder-titles-cases id="pCasosATiempo">
              {contador.atiempo}
            </folder-titles-cases>
          </section>
        </Grid>
      </Grid>
      {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
      {/* BARRA DE BUSQUEDA  */}
      <Grid
        style={{ paddingBottom: "20px" }}
        container
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={4}>
          <h4-search>
            <FormattedMessage id="globBuscar" defaultMessage="Buscar" />:
          </h4-search>
          <SearchTXTField
            showSearchIcon={true}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
      {/* COLUMNA TABLA INVESTIGADOR  */}
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }} style={{ position: "sticky" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* COLUMNA TABLA - SELECCIONAR  */}
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="globSelecc"
                    defaultMessage="Seleccionar"
                  />
                </StyledTableCell>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}

                {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* COLUMNA TABLA - NO FOLIO  */}
                <StyledTableCell>
                  <Grid container alignItems="center">
                    <Grid item xs={9}>
                      <FormattedMessage
                        id="globNoFoli"
                        defaultMessage="No. de folio."
                      />{" "}
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton
                        className="icon-button"
                        style={{ color: "white" }}
                        onClick={() => sorting("numFolio")}
                      >
                        <UnfoldMoreIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </StyledTableCell>

                {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* COLUMNA TABLA - NO SINIESTRO  */}
                <StyledTableCell>
                  <Grid container alignItems="center">
                    <Grid item xs={9}>
                      <FormattedMessage
                        id="globNSin"
                        defaultMessage="No. de siniestro."
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton
                        className="icon-button"
                        style={{ color: "white" }}
                        onClick={() => sorting("numSiniestro")}
                      >
                        <UnfoldMoreIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </StyledTableCell>

                {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* COLUMNA TABLA - CASO DEL SINIESTRO  */}
                <StyledTableCell>
                  <FormattedMessage
                    id="investClasf"
                    defaultMessage="Tipo siniestro."
                  />
                </StyledTableCell>

                {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* COLUMNA TABLA - CLASIFICACION DEL SINIESTRO  */}
                <StyledTableCell>
                  <FormattedMessage
                    id="investClasf"
                    defaultMessage="Clasificación."
                  />
                </StyledTableCell>

                {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* COLUMNA TABLA - FECHA DE LLEGADA  */}
                <StyledTableCell>
                  <Grid container alignItems="center">
                    <Grid item xs={9}>
                      <FormattedMessage
                        id="investFeLl"
                        defaultMessage="Fecha de llegada."
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton
                        className="icon-button"
                        style={{ color: "white" }}
                        onClick={() => sorting("INV_actualizofecha")}
                      >
                        <UnfoldMoreIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </StyledTableCell>

                {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* COLUMNA TABLA - ESTATUS  */}
                <StyledTableCell>
                  <FormattedMessage id="globEstatu" defaultMessage="Estatus." />
                </StyledTableCell>
              </TableRow>
            </TableHead>

            {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
            {/* FILTRADO DE BUSQUEDA  */}
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {datosApi
                ?.filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                    val.num_folio
                      .toString()
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    val.pxfs_data[0]?.num_siniestro
                      .toString()
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    val.tipo_tramite
                      .toString()
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    val.pxfs_data[0]?.pxfs_clasificacion
                      .toString()
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
                /* MAPEO TABLA INVESTIGADOR */
                /*Estas validaciones sirven para editar la tabla en columna casos, agregando el icono de color según corresponda*/
                .map((row) => {
                  //Dependiendo el tipo de siniestro es true y cumple con su valor de su tabla sera tomado en dias habiles y si no seran naturales
                  //siempre se descontaran los dias de asueto
                  if (
                    (tipo_estandarizados === true &&
                      row.pxfs_data[0].pxfs_idclasificacion === 1) ||
                    (tipo_complejo === true &&
                      row.pxfs_data[0].pxfs_idclasificacion === 2) ||
                    (tipo_especial === true &&
                      row.pxfs_data[0].pxfs_idclasificacion === 3) ||
                    (tipo_cug === true &&
                      row.pxfs_data[0].pxfs_idclasificacion === 4)
                  ) {
                    arreglodias = [1, 2, 3, 4, 5];
                  } else {
                    arreglodias = [0, 1, 2, 3, 4, 5, 6];
                  }

                  moment.updateLocale("us", {
                    workingWeekdays: arreglodias,
                  });

                  //se saca la diferentencia entre los dias de asueto con la fecha actual
                  var fecha = moment(row.fch_llegada).format("D MMMM YYYY");
                  var tiempos = moment().businessDiff(
                    moment(row.fch_llegada, "YYYY-MM-DD")
                  );
                  var existInforme = false;
                  //descargarArchivos(row.numFolio);
                  numInformes.map((e) => {
                    if (e.numFolio === row.num_folio && e.numFile > 0) {
                      existInforme = true;
                    }
                  });

                  if (existInforme) {
                    tiempos = (
                      <label className="INV_actualizofecha">
                        <CircleRoundedIcon style={{ color: "#761f2a" }} />{" "}
                        Terminado{" "}
                      </label>
                    );
                  } else if (
                    tiempos > dias_investigador_compleja ||
                    tiempos > dias_investigador_especial
                  ) {
                    tiempos = (
                      <label className="INV_actualizofecha">
                        <CircleRoundedIcon style={{ color: umbralCancelado }} />{" "}
                        urgente{" "}
                      </label>
                    );
                  } else if (
                    tiempos === dias_investigador_compleja ||
                    tiempos === dias_investigador_especial
                  ) {
                    tiempos = (
                      <label className="INV_actualizofecha">
                        <CircleRoundedIcon style={{ color: umbralEnProceso }} />{" "}
                        Por terminar{" "}
                      </label>
                    );
                  } else if (
                    tiempos < dias_investigador_compleja ||
                    tiempos < dias_investigador_especial
                  ) {
                    tiempos = (
                      <label className="INV_actualizofecha">
                        <CircleRoundedIcon style={{ color: umbralATiempo }} /> A
                        tiempo{" "}
                      </label>
                    );
                  }

                  return (
                    <TableRow key={row.id}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        <RadioInput
                          name="rb"
                          value={row?.pxfs_data[0]?.id_poliza}
                          onChange={rbClick}
                        />
                      </StyledTableCell>
                      <StyledTableCell>{row.num_folio}</StyledTableCell>
                      <StyledTableCell>
                        {row.pxfs_data[0]?.num_siniestro}
                      </StyledTableCell>
                      <StyledTableCell>{row.tipo_tramite}</StyledTableCell>
                      <StyledTableCell>
                        {row.pxfs_data[0]?.pxfs_clasificacion}
                      </StyledTableCell>
                      <StyledTableCell>
                        {moment(fecha).format(formatofecha)}
                      </StyledTableCell>
                      <StyledTableCell>{tiempos}</StyledTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
        {/* SECCION DEL PAGINACIÓN  */}
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={datosApi?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Registros por página"
        />
      </Paper>
      <br></br>

      {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
      {/* SECCION DEL CODIGO DE LOS BOTONES DE DESCARGA, SUBIR Y ENVIAR INFORMACION  */}
      <Grid container spacing={5}>
        <Grid item>
          {infoDataRow?.descargado === true ? (
            <DialogDownloadAviso
              mainButton={
                <FormattedMessage
                  id="investDeIn"
                  defaultMessage="Descargar información"
                />
              }
              title={
                <FormattedMessage
                  id="msgInvDeIn"
                  defaultMessage="Mensaje informativo"
                />
              }
              description={`La información del folio ${infoDataRow.num_folio} ya fue descargada.`}
              firstButton={
                <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
              }
              infoDataRow={infoDataRow}
              disabledbtn={btnDownloadInfo}
              onClick1={actualizarDatosDespuesDeDescarga}
            />
          ) : (
            <DialogDownloadInfo
              mainButton={
                <FormattedMessage
                  id="investDeIn"
                  defaultMessage="Descargar información"
                />
              }
              title={
                <FormattedMessage
                  id="msgInvDeIn"
                  defaultMessage="Descargar información del Folio"
                />
              }
              firstButton={
                <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
              }
              secondButton={
                <FormattedMessage id="globCance" defaultMessage="Cancelar" />
              }
              infoDataRow={infoDataRow}
              disabledbtn={btnDownloadInfo}
              onClick1={actualizarDatosDespuesDeDescarga}
            />
          )}
        </Grid>
        <Grid item>
          <DialogUploadDoc
            mainButton={
              <FormattedMessage
                id="msgInvSuIn"
                defaultMessage="Subir informe"
              />
            }
            title={
              <FormattedMessage
                id="msgInvSuDo"
                defaultMessage="Subir documentación de investigación"
              />
            }
            description={
              <FormattedMessage
                id="msg.invest.formatoarchivo"
                defaultMessage="El formato de los archivos debe ser .pdf con un peso máximo de 1GB, el nombre del archivo debe ser igual al numero de folio comenzando con las siglas INV_"
              />
            }
            firstButton={
              <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
            }
            secondButton={
              <FormattedMessage id="globCance" defaultMessage="Cancelar" />
            }
            infoDataRow={infoDataRow}
            disabledbtn={btnUploadDoc}
          />
        </Grid>
        <Grid item>
          <DialogEnviarAInvestigador
            mainButton={"Enviar a dictamen"}
            button={
              <FormattedMessage id="globAceptr" defaultMessage="Enviar" />
            }
            description={
              <div>
                <TXTField
                  multiline={true}
                  rows={2}
                  onChange={handleChange("observaciones")}
                  value={values?.observaciones}
                  onInput={(e) => LimitarTexto(e.target, 255)}
                />
              </div>
            }
            title="Observaciones a dictamen"
            estatus={values.observaciones == "" ? true : false}
            onClick2={validateInvestigador}
            disabledbtn={infoDataRow ? false : true}
            button1="Cancelar"
          ></DialogEnviarAInvestigador>
        </Grid>
        <Grid item>
          <DialogObtenerAInvestigador
            mainButton={"Observaciones de dictamen"}
            button={
              <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
            }
            description={
              <div>
                <TXTField
                  disabled={true}
                  multiline={true}
                  rows={2}
                  value={
                    elementoInd
                      ? elementoInd.MSG_mensaje
                      : "No cuenta con ninguna observación."
                  }
                />
              </div>
            }
            title="Observaciones de dictamen"
            disabledbtn={infoDataRow ? false : true}
          />
        </Grid>
      </Grid>
    </div>
  );
}
