import React, { useState } from "react";
import "../../css/estilo.css";
import Recorrido from "../Tools/BreadCrumbs/Recorrido3";
import { Container } from "@mui/system";
import { Grid } from "@mui/material";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import CompSteeper from "../Tools/Progress/CompSteeper";
import ButtonVino from "../Tools/Bottones/ButtonVino";
import { useLocation } from "react-router-dom";
import BarraPro from "./Elements/ProgressBar";
import TableDetalleStatus from "../Workflow/Tables/TableDetalleStatus";

//fecha
import moment from "moment";
import 'moment/locale/es';

//multilenguaje
import { FormattedMessage } from "react-intl";
import CirculosDeProgreso from "./Elements/CirculosDeProgreso";

export function CompInformacionWF() {

  //Variables para recibir informacion de tableFolios
  const location3 = useLocation();
  const infoRow3 = location3.state.folios;
  console.log("prueba de datos", infoRow3); //Inforow3 trae todo el objeto
  //funcion para ocultar la tabla detalles del estatus
  const [tabla, setTabla] = useState(false);

  const handleClickTabla = () => {
    setTabla(true);
  };

  // Convertir la fecha de la API a formato "DD/MM/YY" utilizando Moment.js

  moment.locale('es');
  let fechaTerm = moment(infoRow3.WKT_idworktramite?.WKT_fechawktramite).format('DD [de] MMMM [del] YYYY');
  // Imprimir la fecha formateada
  let fechaInit = moment(infoRow3.PER_idpersona?.PER_actualizofecha).format('DD [de] MMMM [del] YYYY');
console.log("Fecha formateada: " + fechaTerm);
console.log("Fecha formateada: " + fechaInit);

  return (
    <div className="div-parent">
      {/*Enlaces de inicio */}
      <div className="div-breadcrumbs">
        {" "}
        {/*navegador*/}
        <Recorrido
          name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
          enlace="/menu"
          name2="Workflow"
          enlace2="/workflow"
          name3={
            <FormattedMessage
              id="submenuFol"
              defaultMessage="Folios en proceso"
            />
          }
          enlace3=""
        />
      </div>

      {/**Insercion boton regresar  */}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <ReturnPage href="/workflow" />
      </Grid>
      {/**termino de boton regresar */}
      <div className="div-container">
        <Grid container>
          {/*informacion de datos del asegurado */}
          <br></br>
          <br></br>
          <Grid xs={12}>
            <h3-header-card>
              <FormattedMessage
                id="datosDaAse"
                defaultMessage="Datos del asegurado"
              />
            </h3-header-card>
          </Grid>
          <Grid xs={3}>
            <h3-title-card>
              {" "}
              <FormattedMessage
                id="globNomCom"
                defaultMessage="Nombre completo"
              />
            </h3-title-card>
            <br></br>
            <h3-subtitle-card>
            {infoRow3.PER_idpersona?.PER_nombre}{" "}
            {infoRow3.PER_idpersona?.PER_apellidopaterno}{" "}
            {infoRow3.PER_idpersona?.PER_apellidomaterno}
            </h3-subtitle-card>
          </Grid>
          <Grid xs={3}>
            <h3-title-card>
              <FormattedMessage id="globFolio" defaultMessage="Folio" />
            </h3-title-card>
            <br></br>
            <h3-subtitle-card>{infoRow3.RECT_idfolio?.RECT_numerofolio}</h3-subtitle-card>
          </Grid>
          <Grid xs={3}>
            <h3-title-card>
              <FormattedMessage
                id="globNoPoli"
                defaultMessage="No. de póliza"
              />
            </h3-title-card>
            <br></br>
            <h3-subtitle-card>{infoRow3.POL_idpoliza?.POL_numpoliza}</h3-subtitle-card>
          </Grid>
          <Grid xs={3}>
            <h3-title-card>
              <FormattedMessage
                id="globTiDeSi"
                defaultMessage="Tipo de siniestro" 
              />
            </h3-title-card>
            <br></br>
            <h3-subtitle-card>{infoRow3.SIN_idsiniestro?.SIN_causadereclamacion}</h3-subtitle-card>
          </Grid>
          <br></br>
          <br></br>
          <br></br>
          {/*datos asegurado 2 parte */}
          <br></br>
          <br></br>
          <Grid xs={3}>
            <h3-title-card>
              <FormattedMessage
                id="wfFechNaci"
                defaultMessage="Fecha de inicio"
              />
            </h3-title-card>
            <br></br>
            <h3-subtitle-card>{fechaInit}</h3-subtitle-card>
          </Grid>
          <Grid xs={3}>
            <h3-title-card>
              <FormattedMessage
                id="wfFetransc"
                defaultMessage="Fecha transcurrida"
              />
            </h3-title-card>
            <br></br>
            <h3-subtitle-card>{fechaTerm}</h3-subtitle-card>
          </Grid>
          <Grid xs={3}>
            <h3-title-card>
              <FormattedMessage id="wfEstandar" defaultMessage="Estándar" />
            </h3-title-card>
            <br></br>
            <h3-subtitle-card>30 días</h3-subtitle-card>
          </Grid>
          <Grid xs={3}>
            <h3-title-card>
              <FormattedMessage
                id="wfTpoDiaHo"
                defaultMessage="Tiempo al día de hoy"
              />
            </h3-title-card>
            <br></br>
            <h3-subtitle-card>30 días</h3-subtitle-card>
          </Grid>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {/*Encabezado de circulos de progreso */}
          <Grid xs={12}>
            <h3-header-card>
              <FormattedMessage
                id="wfProGener"
                defaultMessage="Progreso general"
              />
            </h3-header-card>
          </Grid>
          <br />
          <br />
          <br />
          {/*Step bar de workflow */}
          <Grid xs={12}>
            <BarraPro/>
            {/* <CompSteeper /> */}
          </Grid>
          <br />
          <br />
          <br />
          <br />
          <br />

          {/**Boton de regresar y tiempo modular */}
          <Grid container>
            <Grid xs={3}>
              <ButtonVino
                variant="contained"
                color="primary"
                size="small"
                label="Regresar"
                enlace="/workflow"
              />
            </Grid>
            <Grid xs={6}></Grid>
            <Grid xs={3} container justifyContent="flex-end">
              <ButtonVino
                variant="contained"
                color="primary"
                size="small"
                label="Tiempo modular"
                onClick={handleClickTabla}
              />
            </Grid>
          </Grid>

          {/*circulos de progreso de modulos */}
          <Grid xs={12}>
            <CirculosDeProgreso />
          </Grid>

          {/*Tabla de detalles */}
          <Grid xs={12}>{tabla ? <TableDetalleStatus /> : null}</Grid>
        </Grid>
      </div>
    </div>
  );
}
