/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import Paper from "@mui/material/Paper";
import DialogActions from "@mui/material/DialogActions";
import { Grid } from "@mui/material";
import IconAgregar from "../../../Tools/Icons/IconAgregar";
import TXTField from "../../../Tools/TextField/TextField";
import RadioDinamico from "../../../Tools/RadioButton/RadioDinamico";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import DialogEliminarRease from "../Dialogs/DialogEliminarRease";
import IconEditar from "../../../Tools/Icons/IconEditar";
import { getDataToken, postDataToken,putDataToken } from "../../../../api/dataToken";
import { useState, useEffect, useRef } from "react";
import { Select1 } from "../../../Tools/Selectss/Select1";
//idioma
import { FormattedMessage } from "react-intl";
import DialogButtons from '../../../Tools/Dialog/DialogButtons'

export default function TableReaseguro(props) {

  const idpoliza = props.idpoliza;
  console.log("IDPOLIZA", idpoliza)

  //Estados
  const [txtPorImp, setTxtPorImp] = React.useState("Porcentaje");
  const [showFormAgre, setShowFormAgre] = React.useState(false);
  const [showFormEdi, setShowFormEdi] = React.useState(false);
  const [showTxtIcon, setShowTxtIcon] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState(false);
  const [numregistros, setNumRegistros] = React.useState(false);
  //Estados para reaseguradora a editar
  const [rowData, setRowData] = React.useState("");
  const [valueEdit, setValueEdit] = React.useState("");
  const [listAseguradoras, setListAseguradoras] = useState([]);
  const [aseguradora, setAseguradora] = useState(0);
  const [porcentaje, setPorcentaje] = useState(0);
  const [importe, setImporte] = useState(0);
  const [datosApi, setDataApi] = useState(0);
  const [dataX, setDataX] = useState([]);
  const [registroApi, setRegistroApi] = useState([]);

 
  const getDataPolizasReaseguro = async () => {
    //Resultados de la api investigacion
    var result = await getDataToken("polizasreaseguro/get_listado/");
    console.log("Resultados de la Api polizasreaseguro", result, result.length);
    for(let i=0; i < result.length; i++){
      const array = result[i]
      if (array?.PRA_activo===true && array?.POL_idpoliza?.POL_idpoliza===idpoliza) setDataX(...dataX, result);
    }
    if (result.length==4){
      alert("!Exceso de registros")
      setNumRegistros(true)
     /* return(
         <DialogButtons
                            //onClickAccept={eliminars}
                            mainButton="Eliminar Usuario"
                            title="Eliminar Usuario"
                            description="El numero de "
                            firstButton="Cancelar"
                            secondButton="Aceptar"
                            estatus={true}
                        />
      );*/
    }
  };

  useEffect(() => {
    getDataPolizasReaseguro();
}, []);


useEffect(() => {
  console.log(dataX)
    for(let index = 0; index < dataX?.length; index++) {
      console.log("********")
      const element = dataX[index];
      console.log("id regis",element?.POL_idpoliza?.POL_idpoliza)
      if(element?.POL_idpoliza === idpoliza){
        console.log("entra al if")
            const iddictamentec = element?.PRA_idpoliza;
            console.log("Existe un registro de dictamen tecnico con id", iddictamentec)
            console.log("Registro en la api", element)
            //setRegistroApi(element)
           

      }else{
        console.log("No es el mismo id poliza con el id dictamenpoliza",element?.PRA_idpoliza)
      }
      }
      console.log("----------------------",registroApi)
  
}, []);

/* Función para validar datos tabla,  */
function createData(id, nombre, tipo, valor) {
  return { id, nombre, tipo, valor };
}
const rows = [
  createData(1, "AXA XL", 1, 23),
  //createData(dataX.PRA_idpoliza, "AXA XL", 1, dataX.PRA_importe),
  //createData(2,'MAPFRE RE, Compañía de reaseguros S.A.',2,  2000000),
];


  //GET A API REASEGURADORAS 
  const getDataAseguradoras = async () => {
    //Resultados de la api documentacion documentosxtiposiniestrostramite
    var results = await getDataToken("reaseguradoras/get_listado/");
    if (results.length > 0) {
      let copiaApi = results.map(objeto => {
        return { ...objeto, identificador: objeto.REA_idreaseguradora, texto: objeto.REA_reaseguradora };
      });
      setListAseguradoras(copiaApi)
    }
  };

  useEffect(() => {
    getDataAseguradoras();
  }, []);

  let json 

  //Radio button
  const array = [
    { id: 1, value: "1", label: "Porcentaje" },
    { id: 2, value: "2", label: "Importe" },
  ];

  //Funciones
  const rbChange = (tipo) => {
    setValue("");
    setValueEdit(""); 
    setErrorMessage(``);
    setError(false);
    if (tipo == 1) {
      setTxtPorImp("Porcentaje");
      setShowTxtIcon(true);
    } else {
      setTxtPorImp("Importe");
      setShowTxtIcon(false);
    }
  };
  const showFormAdd = () => {
    setShowFormAgre(true);
    setShowFormEdi(false);
  };
  const showFormEdit = () => {
    setShowFormEdi(true);
    setShowFormAgre(false);
    setValueEdit(rowData.valor);
  };
  const hideForm = () => {
    setShowFormAgre(false);
    setShowFormEdi(false);
    formRefAdd.current.reset();
    formRefEdit.current.reset();
  };
  // Obtenemos una referencia al formulario
  const formRefAdd = React.useRef(null);
  const formRefEdit = React.useRef(null);
  //Verificar si el texto es porcentaje o importe, si es porcentaje validar que no sea menor que o mayor de 100
  const handleChange = (event) => {
    let valor = event.target.value;
    // Verifica si el valor ingresado es un número, un punto o un guion medio
    if (!isNaN(valor) || valor === "." || valor === "-") {
      setValue(valor);
      setValueEdit(valor);
      setImporte(valor)
    }
    if (valor > 100 && showTxtIcon === true) {
      setErrorMessage(
        <FormattedMessage
          id="dictaCantidad100"
          defaultMessage="La cantidad ingresada es superior al límite (100%)"
        />
      );
      setError(true);
    } else if (valor < 0) {
      setErrorMessage(
        <FormattedMessage
          id="dictaCantidadNeg"
          defaultMessage="No se permiten cantidades negativas."
        />
      );
      setError(true);
    } 
    else if(valor <= 100 && valor >=0){
      setPorcentaje(valor)
    }else {
      setErrorMessage(``);
      setError(false);
      
    }
  };

  //METODO PUT PARA MODIFICAR LOS PORCENTAJES 
  async function EditarPorcentajeImporte() {
    try {
      let jsonMod = {
        PRA_porcentaje: porcentaje,
        PRA_importe: importe,
        //PRA_activo: true,
        REA_idreaseguradora: aseguradora,
        // POL_idpoliza: 10,
        //MON_idmonedagiroimporte: null
      }
      var result = await putDataToken(
        `polizasreaseguro/get_id/` + 1 + "/",
        jsonMod
      );
      console.log("Registro exitoso", result);
      alert("Edicion de Valores exitosamente")
      //setDataApi(datosApi +1)
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  //METODO POST PARA REGISTRAR LOS COMENTARIOS 
  async function RegistrarPorcentajeImporte() {
    let jsonRegis = {
        PRA_porcentaje: parseFloat(porcentaje),
        PRA_importe: parseFloat(importe),
        REA_idreaseguradora: aseguradora,
        POL_idpoliza: idpoliza,
      }

        console.log("ARREGLO", jsonRegis)
    try {
      

      var result = await postDataToken(
        `polizasreaseguro/get_listado/`,
        jsonRegis
      );
      console.log("Registro exitoso", result);
      alert("Registro de Valores exitosamente")
      setDataApi(datosApi +1)
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Paper style={{ width: "100%" }}>
            <TableContainer style={{ position: "sticky" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                    <StyledTableCell>
                      <FormattedMessage
                        id="globNombre"
                        defaultMessage="Nombre"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="dictamPoOI"
                        defaultMessage="Porcentaje o importe"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="globEditar"
                        defaultMessage="Editar"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="estconElim"
                        defaultMessage="Eliminar"
                      />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}

                  {dataX.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <StyledTableCell component="th" scope="row">
                          {row?.REA_idreaseguradora?.REA_reaseguradora}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row?.PRA_porcentaje}
                          {row.tipo == 1 ? "$" : "%"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <IconEditar
                            onClick={() => {
                              showFormEdit();
                              setRowData(row);
                            }}
                            tooltipTitle={
                              <FormattedMessage
                                id="globEditar"
                                defaultMessage="Editar"
                              />
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <DialogEliminarRease
                            handleClose={hideForm}
                            infoDataRow={row}
                          />
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {rows.length > 0 ? null : (
                <h2-dialog-description align="center">
                  No existen registros
                </h2-dialog-description>
              )}
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item>
          <br />
          <IconAgregar
            tooltipTitle={
              <FormattedMessage
                id="dictaAgreRease"
                defaultMessage="Agregar reaseguradora"
                
            />
            
            }
            disabled={numregistros}
            onClick={showFormAdd}
            iconTitle={"Agregar"}
          />
          <br />
          <br />
          <br />
          <br />
        </Grid>
        {!showFormAgre ? null : (
          <form
            ref={formRefAdd}
            onSubmit={(e) => {
              const { txtNombre, rbTipo, txtValor } = e.target.elements;
              console.log("TIPO",rbTipo);
              e.preventDefault();
              console.log({
                txtNombre: txtNombre.value,
                rbTipo: rbTipo.value,
                txtValor: txtValor.value,
              });

              alert(
                "Ha guardado los siguientes datos: \n" +
                  "Nombre de la aseguradora: " +
                  txtNombre.value +
                  "\n" +
                  "Tipo: " +
                  (rbTipo.value == 1 ? "Porcentaje" : "Importe") +
                  "\n" +
                  "Valor: " +
                  txtValor.value
              );
            }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <hr />
              <h3-title-card>
                {/*<FormattedMessage
                  id="dictaAgreRease"
                  defaultMessage="Agregar reaseguradora"
          />*/}
          
              </h3-title-card>
              <Grid item xs={12}>
                {/*Aqi va los datos del select*/}
                <Select1  id="selectReaseguro" name="selectReaseguro" value={aseguradora} key={aseguradora.REA_idreaseguradora}  label="Aseguradora para el reaseguro" array={listAseguradoras} onChange={(e) => setAseguradora(e.target.value)} />
              </Grid>
              <Grid item xs={4}>
                <RadioDinamico
                  id="rbTipo"
                  name="rbTipo"
                  defaultValue={"1"}
                  array={array}
                  row={true}
                  onChange={(e) => rbChange(e.target.value)}
                />
              </Grid>
              <Grid item xs={8}>
                <TXTField
                  id="txtValor"
                  name="txtValor"
                  label={txtPorImp}
                  error={error}
                  helperText={errorMessage}
                  showPorcentaje={showTxtIcon}
                  value={value}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <DialogActions>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    marginTop={1}
                  >
                    <Grid item>
                      <ButtonVino
                        variant="contained"
                        label={
                          <FormattedMessage
                            id="globGuarda"
                            defaultMessage="Guardar"
                          />

                        }
                        type="submit"
                        onClick={RegistrarPorcentajeImporte}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonVino
                        variant="contained"
                        label={
                          <FormattedMessage
                            id="globCance"
                            defaultMessage="Cancelar"
                          />
                        }
                        onClick={hideForm}
                      />
                    </Grid>
                  </Grid>
                </DialogActions>
              </Grid>
            </Grid>
          </form>
        )}
        {!showFormEdi ? null : (
          <form
            ref={formRefEdit}
            onSubmit={(e) => {
              const { txtNombre, rbTipo, txtValor } = e.target.elements;
              console.log(rbTipo);
              e.preventDefault();
              console.log({
                txtNombre: txtNombre.value,
                rbTipo: rbTipo.value,
                txtValor: txtValor.value,
              });

              alert(
                "Ha guardado los siguientes datos: \n" +
                  "Nombre de la aseguradora: " +
                  txtNombre.value +
                  "\n" +
                  "Tipo: " +
                  (rbTipo.value == 1 ? "Porcentaje" : "Importe") +
                  "\n" +
                  "Valor: " +
                  txtValor.value
              );
            }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <hr />
              <h3-title-card>
                <FormattedMessage
                  id="dictaEditaRease"
                  defaultMessage="editar reaseguradora"
                />
              </h3-title-card>
              <Grid item xs={12}>
                {/*Aqi va los datos del select*/}
                <Select1  id="selectReaseguro" name="selectReaseguro" value={aseguradora} key={aseguradora.REA_idreaseguradora}  label="Aseguradora para el reaseguro" array={listAseguradoras} onChange={(e) => setAseguradora(e.target.value)} />
              
                  required
                
              </Grid>
              <Grid item xs={4}>
                <RadioDinamico
                  id="rbTipo"
                  name="rbTipo"
                  defaultValue={rowData.tipo}
                  array={array}
                  row={true}
                  onChange={(e) => rbChange(e.target.value)}
                />
              </Grid>
              <Grid item xs={8}>
                <TXTField
                  id="txtValor"
                  name="txtValor"
                  label={txtPorImp}
                  defaultValue={rowData.valor}
                  error={error}
                  helperText={errorMessage}
                  showPorcentaje={showTxtIcon}
                  value={valueEdit}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <DialogActions>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    marginTop={1}
                  >
                    <Grid item>
                      <ButtonVino
                        variant="contained"
                        label={
                          <FormattedMessage
                            id="globGuarda"
                            defaultMessage="Guardar"
                          />
                        }
                        type="submit"
                        onClick={EditarPorcentajeImporte}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonVino
                        variant="contained"
                        label={
                          <FormattedMessage
                            id="globCance"
                            defaultMessage="Cancelar"
                          />
                        }
                        onClick={hideForm}
                      />
                    </Grid>
                  </Grid>
                </DialogActions>
              </Grid>
            </Grid>
          </form>
        )}
      </Grid>
    </>
  );
}
