/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ButtonVino from '../../../Tools/Bottones/ButtonVino';
import { Grid } from "@mui/material";
import TableBeneficiarios from "../Tables/TablaBeneficiarios";
//idioma
import { FormattedMessage } from 'react-intl';
import { useState } from "react";
import { PERR_ASEGURADO, PERR_BENEFICIARIO } from "../../../../utils/constanstoken";
import { getDataToken } from "../../../../api/dataToken";
import { useEffect } from "react";
import TableAsegurado from "../Tables/TablaAsegurado";
//Aparecera en la segunda alerta dos botones
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


export default function DialogAsegurado(props) {

  //PARENT DIALOG
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => { 
    setOpen(true);
    getDataPersonasXPoliza();
  }
  const handleClose = () => { setOpen(false) }
  //CHILDREN DIALOG
  const [open2, setOpen2] = React.useState(false)
  const handleClickOpen2 = () => { setOpen2(true) }
  const handleClose2 = () => {
    setOpen2(false) // Close the children dialog
    handleClose() // Close the parent dialog

  }
  const idPoliza = props.idPoliza;
  const idFolio = props.idFolio;


  /* AQUI SE TIENE QUE VALIDAR LA RESPUESTA JSON, SI FUE EXITOSO O NO EL ENVIO A DICTAMEN */
  const [dialogTittle, setDialogTittle] = React.useState("")
  const [dialogMessage, setDialogMessage] = React.useState("")


  //AQUI DEBE HACER LA PETICIÓN A LA API PARA DESPUES MOSTRAR LA RESPUESTA EN EL DIALOGO HIJO
  React.useEffect(() => {
    setDialogTittle('Envio éxitoso')
    setDialogMessage(
      <div>
        <h2-dialog-description><label>{<FormattedMessage id="glob.folio" defaultMessage="Folio" />}: </label></h2-dialog-description>
      </div>
    )
  }, [])

  /* CONSULTAR LAS PERSONAS POR PÓLIZA*/
  const [asegurado, setAsegurado] = useState(null)
  const getDataPersonasXPoliza = async () => {
    try {
      let response = await getDataToken(`personasxpoliza/get_poliza/${idPoliza}/`)
      if (response) {
        /** Buscar al beneficiario de la póliza */
        const asegurado = response.filter((persona) => persona?.PER_idpersona?.PERR_idrol?.PERR_idrol == PERR_ASEGURADO.id);

        if (asegurado.length > 0)
          setAsegurado(asegurado)
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  useEffect(() => {
    getDataPersonasXPoliza();
  }, []);

  return (
    <div>
      {/* PARENT DIALOG */}
      <div>
        {/*BOTON QUE HACE EL LLAMADO AL DIALOGO PADRE*/}
        <ButtonVino onClick={handleClickOpen} label={props.mainButton} estatus={props.disabledbtn} />
        {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
        <BootstrapDialog
          maxWidth={false}
          fullWidth={true}
          onClose={handleClose}
          aria-labelledby="dialog"
          open={open}
        >
          {/*CUERPO DEL DIALOGO*/}
          {/*TITILO*/}
          <BootstrapDialogTitle id="dialog" onClose={handleClose} style={{ color: '#761f2a' }}>
            <h1-dialog-title>{props.title}</h1-dialog-title>
          </BootstrapDialogTitle>
          {/*CUERPO DEL DIALOGO*/}
          {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
          <DialogContent dividers style={{ minHeight: '300px' }}>
            {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
            se necesita colocar entre un typogreaphy las veces que des salto de linea 
            o con un br*/}
            <TableAsegurado asegurado={asegurado} idFolio={idFolio} idPoliza={idPoliza} />

          </DialogContent>
          {/*es la tercer sección que engloba los botones */}
          <DialogActions>

            {/*En esta parte se llena el segundo mensaje que debe de aparecer*/}
            <Grid container spacing={1} direction="row" justifyContent="center" alignItems="flex-start">
              {/*<Grid item xs={'auto'}>
              <ButtonVino onClick={handleClickOpen2} label={props.firstButton} estatus={props.disabledBtn}/>
              </Grid>*/}
              <Grid item xs={'auto'}>
                <ButtonVino onClick={handleClose} label={props.secondButton} estatus={props.disabledBtn} />
              </Grid>
            </Grid>

          </DialogActions>
        </BootstrapDialog>
      </div>
      {/* CHILDREN DIALOG */}
      <div>
        {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
        <BootstrapDialog
          onClose={handleClose2}
          aria-labelledby="dialog"
          open={open2}
        >
          {/*CUERPO DEL DIALOGO*/}
          {/*TITULO*/}
          <BootstrapDialogTitle id="dialog" onClose={handleClose2} style={{ color: '#761f2a' }}>
            <h1-dialog-title>{dialogTittle}</h1-dialog-title>
          </BootstrapDialogTitle>
          {/*CUERPO DEL DIALOGO*/}
          {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
          <DialogContent dividers >
            {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
              se necesita colocar entre un typogreaphy las veces que des salto de linea 
              o con un br*/}
            <h2-dialog-description>{dialogMessage}</h2-dialog-description>
          </DialogContent>
          {/*es la tercer sección que engloba los botones */}
          <DialogActions>
            <Grid container justifyContent="center">
              <ButtonVino onClick={handleClose2} label={props.firstChildrenButton} />
            </Grid>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </div>
  );
}
