import React, { useEffect, useState } from "react";
import "../../../css/estilo.css";
//importaciones del idioma al componente
import { FormattedMessage } from "react-intl";
//importacion herramientas MUI y componentes estandarizados
import { Grid } from "@mui/material";
import Grafica from "../Graficas/Grafica";
import DialogButtonWork from "../../Tools/Dialog/DialogButtonWork";
// Imagenes para los modulos 
import ComunicacionCliente from "../../../img/menu/ComunicacionCliente.svg"
import Dictamen from "../../../img/menu/Dictamen.svg"
import EstadisticoContable from "../../../img/menu/EstadisticoContable.svg"
import Investigador from "../../../img/menu/Investigador.svg"
import Medico from "../../../img/menu/Medico.svg"
import Recepcion from "../../../img/menu/Recepcion.svg"
import Registro from "../../../img/menu/Registro.svg"
// Tablas 
import TableTotalRegistro from "../Tables/TablesTotalFolios/TableTotalRegistro";
import TableTotalComunicacion from "../Tables/TablesTotalFolios/TableTotalComunicacion";
import TableTotalDictamen from "../Tables/TablesTotalFolios/TableTotalDictamen";
import TableTotalInvestigador from "../Tables/TablesTotalFolios/TableTotalInvestigador";
import TableTotalRecepcion from "../Tables/TablesTotalFolios/TableTotalRecepcion";
import TableTotalMedico from "../Tables/TablesTotalFolios/TableTotalMedico";
import { getDataToken } from "../../../api/dataToken";
export default function Proceso_Polizas({ folios }) {
  // console.log("PAso de fechax2", folios);
  const [inFolio, setFolios] = useState(folios);

  useEffect(() => {
    setFolios(folios);
  }, [folios]);


  const contarPolizas = () => {
    // Utiliza un conjunto para almacenar las propiedades únicas
    const polizasSet = new Set();
  
    // Itera sobre los elementos de inFolio y agrega las propiedades únicas al conjunto
    inFolio.forEach(poliza => {
      if (poliza?.POL_idpoliza?.POL_numpoliza !== undefined) {
        polizasSet.add(poliza.POL_idpoliza.POL_numpoliza);
      }
    });
  
    // Devuelve la cantidad de elementos únicos
    return polizasSet.size;
  };
  
  useEffect(() => {
    // console.log("Número total de pólizas:", contarPolizas());
  }, [inFolio]);
  

  return (
    <div className="div-parent">
      {/**este div agrega un contenedor aqui colocaremos los componentes y elementos que necesitemos */}
      <div className="div-container">
        <Grid container justifyContent="center" alignItems="center">
          {/*aqui colocaremos los elementos usando grid para guiarnos */}
          <Grid xs={4}>
          <h3-header-card>
              <FormattedMessage
                id="wfPolizaTo"
                defaultMessage="Pólizas en total"
              />
          : &nbsp; {contarPolizas()}
          </h3-header-card>
          </Grid>
          <Grid xs={8}>
          <h3-header-card>
              <FormattedMessage
                id="wfPolizaPr"
                defaultMessage="Pólizas en Proceso"
              />
              : &nbsp; {contarPolizas()}
            </h3-header-card>
          </Grid>
          <br />
          <br />
          <br />
          <br />
          {/*Recepcion*/}
          <Grid xs={2}>
            <DialogButtonWork
              imagen={Recepcion}
              title="Recepcion"
              description={<TableTotalRecepcion folios={folios} />}
              button="Aceptar"
            />
            <br/>
            <h3-header-card>
             Recepcion
            </h3-header-card>
          </Grid>
          {/**progres registro */}
          <Grid xs={2}>
            <DialogButtonWork
              imagen={Registro}
              title="Registro"
              description={<TableTotalRegistro folios={folios} />}
              button="Aceptar"
            /> 
            <br/>
            <h3-header-card>
             Registro
            </h3-header-card>
          </Grid> 
          {/**progreso dictamen */}
          <Grid xs={2}>
            <DialogButtonWork
              imagen={Dictamen}
              title="Dictamen"
              description={<TableTotalDictamen folios={folios} />}
              button="Aceptar" />
              <br/>
            <h3-header-card>
             Dictamen
            </h3-header-card>
          </Grid>
          {/**progreso medico */}
          <Grid xs={2}>
            <DialogButtonWork
              imagen={Medico}
              title="Medico"
              description={<TableTotalMedico folios={folios} />}
              button="Aceptar"
            />
            <br/>
            <h3-header-card>
             Medico
            </h3-header-card>
          </Grid>
          {/**progreso investigacion */}
          <Grid xs={2}>
            <DialogButtonWork
              imagen={Investigador}
              title="Investigador"
              description={<TableTotalInvestigador folios={folios} />}
              button="Aceptar" />
              <br/>
            <h3-header-card>
             Investigacion
            </h3-header-card>
          </Grid>
          {/**progreso comunicacion */}
          <Grid xs={2}>
            <DialogButtonWork
              imagen={ComunicacionCliente}
              title="Comunicacion con el Cliente"
              description={<TableTotalComunicacion folios={folios} />}
              button="Aceptar" />
              <br/>
            <h3-header-card>
             Comunicacion
            </h3-header-card>
          </Grid>
          {/**Gin de los circulos de progreso de modulos */}    
          <Grid >
          <br/>        <br/>   <br/>   <br/> 
          
            <Grafica folios={folios}/>
            
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
