import { getUsers } from "../components/Login/apiLogin/getUsers";
import { URLAPIS } from "../utils/constans";
let umbralTerminado,
  umbralCancelado,
  umbralPorVencer,
  umbralEnProceso,
  umbralATiempo,
  umbralNuevo,
  diasMaxTramite,
  principal,
  formatofecha,
  secundario,
  diasestandarizados,
  tipo_estandarizados,
  diasespecial,
  tipo_especial,
  diascomplejo,
  tipo_complejo,
  tipo_cug,
  diascug,
  diasoficiales,
  dias_medico,
  dias_recepcion,
  dias_registro,
  dias_dictamen,
  dias_investigador,
  dias_comcliente,
  dias_estcontable,
  dias_medico_estandarizado,
  dias_recepcion_estandarizado,
  dias_registro_estandarizado,
  dias_dictamen_estandarizado,
  dias_investigador_estandarizado,
  dias_comcliente_estandarizado,
  dias_estcontable_estandarizado,
  dias_medico_compleja,
  dias_recepcion_compleja,
  dias_registro_compleja,
  dias_dictamen_compleja,
  dias_investigador_compleja,
  dias_comcliente_compleja,
  dias_estcontable_compleja,
  dias_medico_especial,
  dias_recepcion_especial,
  dias_registro_especial,
  dias_dictamen_especial,
  dias_investigador_especial,
  dias_comcliente_especial,
  dias_estcontable_especial,
  dias_medico_urgente,
  dias_recepcion_urgente,
  dias_registro_urgente,
  dias_dictamen_urgente,
  dias_investigador_urgente,
  dias_comcliente_urgente,
  dias_estcontable_urgente;

getUsers(`${URLAPIS}configuraciongeneral/get_id/1/`)
  .then((data) => {
    formatofecha = data.SCG_FormatoFecha; //formatofecha
    principal = data.SCG_primaryColor; //vino sinay
    secundario = data.SCG_secundaryColor; //azul sinay
    umbralTerminado = data.SCG_umbralColor1; //Rojo fuerte
    umbralCancelado = data.SCG_umbralColor2; //Rojo claro
    umbralPorVencer = data.SCG_umbralColor3; //Naranja
    umbralEnProceso = data.SCG_umbralColor4; //Amarillo
    umbralATiempo = data.SCG_umbralColor5; //verde
    umbralNuevo = data.SCG_umbralColor6; //Azul

    diasMaxTramite = data.SCG_diasMaxTramite;

    diasoficiales = data.SCG_dias.split(","); //dias de asueto
    diasestandarizados = data.SCG_DiasEstandares; //10 dias default
    tipo_estandarizados = data.SCG_DiasEstandares_bool; //true = habiles L-V
    diasespecial = data.SCG_DiasEspeciales; //15 dias default
    tipo_especial = data.SCG_DiasEspecialess_bool; //true = habiles L-V
    diascomplejo = data.SCG_DiasComplejos; //25 dias default
    tipo_complejo = data.SCG_DiasComplejos_bool; //true = habiles L-V
    diascug = data.SCG_DiasUrgente; //2 dias default
    tipo_cug = data.SCG_DiasUrgente_bool; //true = habiles L-V

    dias_medico = data.SCG_dias_medico;
    dias_recepcion = data.SCG_dias_recepcion;
    dias_registro = data.SCG_dias_registro;
    dias_dictamen = data.SCG_dias_dictamen;
    dias_investigador = data.SCG_dias_investigador;
    dias_comcliente = data.SCG_dias_comcliente;
    dias_estcontable = data.SCG_dias_estcontable;

    // para los dias por modulo por tipo de siniestro

    dias_medico_estandarizado = data.SCG_dias_medico_estandarizado;
    dias_recepcion_estandarizado = data.SCG_dias_recepcion_estandarizado;
    dias_registro_estandarizado = data.SCG_dias_registro_estandarizado;
    dias_dictamen_estandarizado = data.SCG_dias_dictamen_estandarizado;
    dias_investigador_estandarizado = data.SCG_dias_investigador_estandarizado;
    dias_comcliente_estandarizado = data.SCG_dias_comcliente_estandarizado;
    dias_estcontable_estandarizado = data.SCG_dias_estcontable_estandarizado;

    dias_medico_compleja = data.SCG_dias_medico_compleja;
    dias_recepcion_compleja = data.SCG_dias_recepcion_compleja;
    dias_registro_compleja = data.SCG_dias_registro_compleja;
    dias_dictamen_compleja = data.SCG_dias_dictamen_compleja;
    dias_investigador_compleja = data.SCG_dias_investigador_compleja;
    dias_comcliente_compleja = data.SCG_dias_comcliente_compleja;
    dias_estcontable_compleja = data.SCG_dias_estcontable_compleja;

    dias_medico_especial = data.SCG_dias_medico_especial;
    dias_recepcion_especial = data.SCG_dias_recepcion_especial;
    dias_registro_especial = data.SCG_dias_registro_especial;
    dias_dictamen_especial = data.SCG_dias_dictamen_especial;
    dias_investigador_especial = data.SCG_dias_investigador_especial;
    dias_comcliente_especial = data.SCG_dias_comcliente_especial;
    dias_estcontable_especial = data.SCG_dias_estcontable_especial;

    dias_medico_urgente = data.SCG_dias_medico_urgente;
    dias_recepcion_urgente = data.SCG_dias_recepcion_urgente;
    dias_registro_urgente = data.SCG_dias_registro_urgente;
    dias_dictamen_urgente = data.SCG_dias_dictamen_urgente;
    dias_investigador_urgente = data.SCG_dias_investigador_urgente;
    dias_comcliente_urgente = data.SCG_dias_comcliente_urgente;
    dias_estcontable_urgente = data.SCG_dias_estcontable_urgente;
  })
  .catch((error) => {
    console.error("There was an error:", error);
  });

// fetch(`${BASE_API}configuraciongeneral/get_id/1/`);

export {
  umbralTerminado,
  umbralCancelado,
  umbralPorVencer,
  secundario,
  principal,
  formatofecha,
  umbralEnProceso,
  umbralATiempo,
  umbralNuevo,
  diasMaxTramite,
  diasoficiales,
  diasestandarizados,
  tipo_estandarizados,
  diasespecial,
  tipo_especial,
  diascomplejo,
  tipo_complejo,
  diascug,
  tipo_cug,
  dias_medico,
  dias_recepcion,
  dias_registro,
  dias_dictamen,
  dias_investigador,
  dias_comcliente,
  dias_estcontable,
  //dias siniestro por complejidad
  dias_medico_estandarizado,
  dias_recepcion_estandarizado,
  dias_registro_estandarizado,
  dias_dictamen_estandarizado,
  dias_investigador_estandarizado,
  dias_comcliente_estandarizado,
  dias_estcontable_estandarizado,
  dias_medico_compleja,
  dias_recepcion_compleja,
  dias_registro_compleja,
  dias_dictamen_compleja,
  dias_investigador_compleja,
  dias_comcliente_compleja,
  dias_estcontable_compleja,
  dias_medico_especial,
  dias_recepcion_especial,
  dias_registro_especial,
  dias_dictamen_especial,
  dias_investigador_especial,
  dias_comcliente_especial,
  dias_estcontable_especial,
  dias_medico_urgente,
  dias_recepcion_urgente,
  dias_registro_urgente,
  dias_dictamen_urgente,
  dias_investigador_urgente,
  dias_comcliente_urgente,
  dias_estcontable_urgente,
};