import * as React from "react";
import PropTypes from "prop-types";
import ButtonVino from "../Bottones/ButtonVino";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@material-ui/core/Grid";
import { principal } from "../../../api/getUmbrales";
import { Link } from "react-router-dom";
//Solo aparesera un alerta con un solo boton
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function DialogGeneral(props) {
    const [open, setOpen] = React.useState(props.open ? props.open : false);

    const type = props.type; // Asignar true si se quiere mostrar un mensaje de éxito, y false un estado de error.
    const description = !props.description ? "" : props.description; //Añade descripcion extra al diálogo
    const to = props.to; // Si al confirmar el díalogo se requiere navegar a otra ruta 
    const state = props.state // Enviar datos a otra ruta por medio de state

    const handleClose = () => {
        props.onClickAccept(false)
        setOpen(false);
    };
    const handleClickAccept = () => {
        props.onClickAccept(true)
        setOpen(false);
    }

    return (
        <div>
            {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="dialog"
                open={open}
                onSubmit={props.onSubmit}
            >
                {/*CUERPO DEL DIALOGO*/}
                {/*TITILO*/}
                <BootstrapDialogTitle
                    id="dialog"
                    onClose={handleClose}
                    style={{ color: principal }}
                >
                    <h1-dialog-title>{props.title}</h1-dialog-title>
                </BootstrapDialogTitle>
                {/*CUERPO DEL DIALOGO*/}
                {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
                <DialogContent dividers style={{ minWidth: 500 }}>
                    {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
          se necesita colocar entre un typogreaphy las veces que des salto de linea 
          o con un br*/}
                    <h2-dialog-description>{type  ? `Operación completada con éxito. ${description}` : `Ha ocurrido un error inesperado. Comuníquese con el encargado de TI. ${description}`}</h2-dialog-description>

                </DialogContent>
                {/*es la tercer sección que engloba los botones */}
                <DialogActions>
                    <Grid container justifyContent="center">
                        {to?
                            <Link
                                to={to}
                                state={state}
                                style={{ textDecoration: "none" }}>

                                <ButtonVino
                                    onClick={handleClickAccept}
                                    variant="contained"
                                    label={"Aceptar"}
                                />
                            </Link> :
                            <ButtonVino
                                onClick={handleClickAccept}
                                variant="contained"
                                label={"Aceptar"}
                            />
                        }

                    </Grid>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
