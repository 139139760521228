import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { FormattedMessage } from "react-intl";

export const Loading2 = () => {
  return (

    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "68%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(255, 255, 255, 0.9)", // Fondo semitransparente
        zIndex: 100, // Mayor z-index para estar encima de todo
      }}
    >
      <CircularProgress style={{ marginTop: "130px" }} />
      <br />
      <div>
        <h1-dialog-title>
          <FormattedMessage
            id="loginCarga"
            defaultMessage="Cargando........"
          />
        </h1-dialog-title>
        <h2-dialog-description>
          {" "}
          <FormattedMessage
            id="espMom"
            defaultMessage="Espere un momento."
          />
        </h2-dialog-description>
      </div>
    </div>

  );
}
