import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import RadioInput from '../../../Tools/RadioButton/RadioInput'
import CircleRoundedIcon from '@mui/icons-material/CircleRounded'
import ButtonVino from '../../../Tools/Bottones/ButtonVino'
import moment from 'moment';
import IconButton from "@material-ui/core/IconButton"
import TXTField from '../../../Tools/TextField/TextField'
import PasswordTXTField from '../../../Tools/TextField/TextFieldPass'
import Grid from '@material-ui/core/Grid'
import ModalesAlta from '../Elements/Modal/ModalAlta';//Alta
import DialogButton from '../../../Tools/Dialog/DialogButton'
//radio
import RadioDinamico from '../../../Tools/RadioButton/RadioDinamico';
import Check2 from '../../../Tools/CheckBox/Check2';
import TextField from '../../../Tools/TextField/TextField';
//modal ventana
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
//idioma
import {FormattedMessage} from 'react-intl';
//paginacion
import TablePagination from '@mui/material/TablePagination'
//TOKEN
import {getToken} from '../../../../api/token'
import { BASE_API } from '../../../../utils/constans'
//COLORES
import {umbralTerminado,umbralATiempo} from '../../../../api/getUmbrales'
import DialogButtons from '../../../Tools/Dialog/DialogButtons'


const style = {//modal emergente
  position:'absolute',
  overflow:'scroll',
  overheight:'scroll',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  width:'98%' ,
  height:'90%',

};

//MODAL
const arrayEstatus = [
  {'id': '1', 'value': 'true' , 'label':'Activo'},
  {'id': '2', 'value': 'false', 'label':'Inactivo'},
]


/* ESTILOS DE LA TABLA */

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#00203b',
   
    padding: 0,
    paddingLeft: '5px', 
    height:'65px', 
    color: theme.palette.common.white,
    fontSize: '20px',
    borderColor: '#e2e2e2',
    border: "1px solid #e2e2e2",
    fontFamily: 'Avenir-Light',
  },
  [`&.${tableCellClasses.body}`]: {
    height: '20px',
    padding: 0,
    fontSize: '16px',
    borderColor: '#e2e2e2',
    border: "1px solid #e2e2e2",
    fontFamily: 'Avenir-Light',
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
   //backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    //border: 0,
  },
}));


export default function InvestigadorTable() {


//MODAL
const [open, setOpen] = React.useState(false);
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);

function refreshPage() {
  window.location.reload(false);
}

//APIS

const [tableData, setTableData] = useState([])
const token = getToken();

useEffect(() => {
  fetch(`${BASE_API}login/get_listado_admin/`,
  {
    
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        Authorization: `Bearer ${token}`,   
  },   
  })
    .then((data) => data.json())
    .then((data) => setTableData(data))
  
}, [])



const [values, setValues] = React.useState({
  email: "",
  password: "",
  username: "",
  lastName: "",
  maternalsurname: "",
  estatusss:"",
});


const handleChange = (prop) => (event) => {
  setValues({ ...values, [prop]: event.target.value });
  
}


//POSTyPUT GUARDARselectdata
const eliminars = (e) => {
  e.preventDefault();
 
  fetch(`${BASE_API}login/putall/` + selectdata.SNYTLOG_email+ "/", {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      SNYTLOG_email: selectdata.SNYTLOG_email,
      SNYTLOG_delete:false
    
      })
    
  })
  
refreshPage();
};


  /* CONSTANTES DE ESTADOS*/
const [order, setOrder] = useState("ASC") // esta constante tiene como propósico guardar el estado del orden de la tabla
const [searchTerm, setSearchTerm] = useState("") // si es "" mostrará todos los datos de la tabla
const [btnDisbale, setbtnDisbale] = useState(true)//estado del boton

  /* FUNCIÓN PARA ORDENAR LA TABLA ASC/DSC (Ascendent/ Descendant) */
  const sorting = (col) => {
    if(order === "ASC"){
      const sorted = [...tableData].sort((a,b) => 
      a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setOrder('DSC')
      setTableData(sorted);
    }
     else if(order === "DSC"){
      const sorted = [...tableData].sort((a,b) => 
      a[col].toLowerCase() > b[col].toLowerCase() ? -1 : 1
      );
      setOrder('ASC')
      setTableData(sorted);
    }
  } 


  const [selectdata,setselectdata] = useState("")

  
  /* FUNCIÓN CLICK EN LOS RADIO BUTTON, ARROJA INFORMACION DE LA FILA SELECCIONADA */
  const rbClick = (row) => {
    setselectdata(row)   
  }

  useEffect(() => {
    if(selectdata !== "")
    setbtnDisbale(false)
    console.log(selectdata)
  },[selectdata])



 //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
 const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
 const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10

 const handleChangePage = (event, newPage) => {
   setPage(newPage);
 };
 const handleChangeRowsPerPage = (event) => {
   setRowsPerPage(+event.target.value);
   setPage(0);
 };

 tableData.map(row=> { 
  if(row.SNYTLOG_estatus === false){
    row.SNYTLOG_estatus = false
    // <label className='lbl-estatus-tabla'><CircleRoundedIcon style={{color: '#97000E'}}/>
    // <FormattedMessage id="globInacti" defaultMessage="Inactivo"/>
    // </label>
  }else if(row.SNYTLOG_estatus === true){
    row.SNYTLOG_estatus = true
    // <label className='lbl-estatus-tabla'><CircleRoundedIcon style={{color: '#008000'}}/>
    //   <FormattedMessage id="globActivo" defaultMessage="Activo"/>
    //   </label> 
  }
})
 
 
function refreshPage() {
  window.location.reload(false);
  
}



  //chekeds

const [valordef,setvalordef  ] = React.useState({
  isconfgenview: false,
  isconfgenadd: false,
  isconfgenchan: false,

  isviewadmin: false,
  isaddadmin: false,
  ischangeadmin :false,

  isviewemail: false,
  isaddemail: false,
  ischangeemail:false,

  isviewconta: false,
  isaddconta: false,
  ischangeconta:false,

  isviewcatalogo: false,
  isaddcatalogo: false,
  ischangecatalogo:false,

  isviewsecurity: false,
  isaddsecurity: false,
  ischangesecurity:false,

  isviewnegocio: false,
  isaddnegocio: false,
  ischangenegocio:false,




});



  // eventosdeloscheck

  var viewconf,addconf,chanconf,viewcorreo,addcorreo,chancorreo,viewadmin,addadmin,chanadmin = 0
  
  //admin
    const handleviewadmin = (event) => {
    (valordef.isviewadmin) = (event.target.checked);
    };
    const handleaddadmin = (event) => {
      (valordef.isaddadmin) = (event.target.checked);
    };
    const handlechanadmin = (event) => {
      (valordef.ischangeadmin) = (event.target.checked);
    };
  //confgeneral
  const handleviewconf= (event) => {
    (valordef.isconfgenview) = (event.target.checked);};
  const handleaddconf = (event) => {
    (valordef.isconfgenadd) = (event.target.checked);
  };
  const handlechanconf = (event) => {
    (valordef.isconfgenchan) = (event.target.checked);
  };
  //email
  const handleviewemail= (event) => {
    (valordef.isviewemail) = (event.target.checked);
    console.log(valordef.isviewemail);
   };
   const handleaddemail = (event) => {
     (valordef.isaddemail) = (event.target.checked);
   };
   const handlechanemail = (event) => {
     (valordef.ischangeemail) = (event.target.checked);
   };

console.log(selectdata.groups)

//para cambiar el estado de los switch y compar con el numero de grupo
    useEffect(() => {
      if(selectdata !== ""){

    //correo
    valordef.isviewemail=(selectdata.groups.includes(23))
    valordef.isaddemail=(selectdata.groups.includes(24))
    valordef.ischangeemail=(selectdata.groups.includes(25))
    //confgeneral
    valordef.isconfgenview =(selectdata.groups.includes(21))
    valordef.isconfgenadd=(selectdata.groups.includes(20))
    valordef.isconfgenchan=(selectdata.groups.includes(22))
    //admin
    valordef.isviewadmin =(selectdata.groups.includes(26))
    valordef.isaddadmin=(selectdata.groups.includes(27))
    valordef.ischangeadmin=(selectdata.groups.includes(28))


    }
    },[selectdata])

  



  const validateAndSubmitForm = (e) => {
    e.preventDefault();
  
   //confgeneral
  if(valordef.isconfgenview === true){viewconf = 21}else{viewconf =1 }
  if(valordef.isconfgenadd === true){addconf = 20}else{addconf = 1}
  if(valordef.isconfgenchan === true){chanconf = 22}else{chanconf = 1}
  //email
  if(valordef.isviewemail === true){viewcorreo = 23}else{viewcorreo = 1 }
  if(valordef.isaddemail === true){addcorreo = 24}else{addcorreo = 1}
  if(valordef.ischangeemail === true){chancorreo = 25}else{chancorreo = 1}
  //admin
  if(valordef.isviewadmin === true){viewadmin = 26}else{viewadmin =1 }
  if(valordef.isaddadmin === true){addadmin = 27}else{addadmin = 1}
  if(valordef.ischangeadmin === true){chanadmin = 28}else{chanadmin = 1}
  

   
  

   fetch(`${BASE_API}login/putall/` + selectdata.SNYTLOG_email+ "/", {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      SNYTLOG_username: values.username,
      SNYTLOG_surnamedad: values.lastName,
      SNYTLOG_surnamemom: values.maternalsurname,
      password: values.password,
      SNYTLOG_email: values.email,
      SNYTLOG_estatus:values.estatusss,
      groups:[viewconf,addconf,chanconf,viewcorreo,addcorreo,chancorreo,viewadmin,chanadmin,addadmin]
      })    
  })
  refreshPage();
  };


 
  return (
    <div>
{/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
{/* BARRA DE BUSQUEDA  */}
    <Grid  
     style={{ paddingBottom: "20px" }}container justifyContent="flex-end" alignItems="flex-start">
      <Grid item xs={4}>
      <h4-search><FormattedMessage id="globBuscar" defaultMessage="Buscar"/>:</h4-search>
      <TXTField 
          showSearchIcon={true}
          onChange={(e) => {setSearchTerm(e.target.value)}}
        
      />
      </Grid>
    </Grid>
{/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
{/* TABLA DE ADMINISTRACION DE USUARIOS  */}
    <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ maxHeight: 1050 }}style={{position:'sticky'}}>
            <Table stickyHeader aria-label="sticky table" >
                <TableHead>
                <TableRow>

{/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
{/* COLUMNA TABLA - SELECCIONAR  */}
                    <StyledTableCell>
                      <FormattedMessage id="globSelecc" defaultMessage="Seleccionar"/>
                      </StyledTableCell>
                    {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
{/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
{/* COLUMNA TABLA - FECHA ALTA  */}
                    <StyledTableCell align="center" onClick={()=> sorting("SNYTLOG_register")}>
                    <Grid container alignItems="center">
                        <Grid item xs={9}>
                      <FormattedMessage id="adminFeAlt" defaultMessage="Fecha de alta"/>
                      </Grid>
                      <Grid item xs={3}>
                        <IconButton className='icon-button' style={{color: 'white'}}>{ order==='DSC' ? 
                        <UnfoldMoreIcon/>:<UnfoldMoreIcon/>}
                        </IconButton> 
                        </Grid>
                     </Grid>
                    </StyledTableCell>
{/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
{/* COLUMNA TABLA - ID  */}
                   {/*} <StyledTableCell align="center" onClick={()=> sorting("SNYTLOG_register")}><FormattedMessage id="globCurp" defaultMessage="Id"/>
                        <IconButton className='icon-button' style={{color: 'white'}}>{ order==='DSC' ? <UnfoldMoreIcon/>:<UnfoldMoreIcon/>}</IconButton>
                    </StyledTableCell>*/}
{/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
{/* COLUMNA TABLA - NOMBRE COMPLETO  */}                    
                    <StyledTableCell align="center" onClick={()=> sorting("SNYTLOG_username")}>
                    <Grid container alignItems="center">
                    <Grid item xs={9}>
                      <FormattedMessage id="globNomCom" defaultMessage="Nombre Completo"/>
                      </Grid>
                    <Grid item xs={3}>
                        <IconButton className='icon-button' style={{color: 'white'}}>{ order==='DSC' ? 
                        <UnfoldMoreIcon/>:<UnfoldMoreIcon/>}
                        </IconButton>
                        </Grid>
                   </Grid>
                    </StyledTableCell>
{/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
{/* COLUMNA TABLA - CORREO  */}                   
                    <StyledTableCell align="center">
                      <FormattedMessage id="globCorEle" defaultMessage="Correo electrónico"/>
                      </StyledTableCell>
{/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
{/* COLUMNA TABLA - PERFIL  */} 
                    <StyledTableCell align="center" onClick={()=> sorting("SNYTLOG_email")}>
                    <Grid container alignItems="center">
                    <Grid item xs={9}> 
                      <FormattedMessage id="adminPerfi" defaultMessage="Perfil"/>
                      </Grid>
                    <Grid item xs={3}>
                    <IconButton className='icon-button' style={{color: 'white'}}>{ order==='DSC' ? 
                    <UnfoldMoreIcon/>:<UnfoldMoreIcon/>}
                    </IconButton>
                    </Grid>
                  </Grid>
                    </StyledTableCell>
{/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
{/* COLUMNA TABLA - ESTATUS  */}                   
                    <StyledTableCell align="center">
                      <FormattedMessage id="globEstatu" defaultMessage="Estatus"/>
                      </StyledTableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en color contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
                {
                tableData
                .filter(val =>{
                  if(searchTerm===""){
                    return val;
                  }
                  /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                  else if(
                    (val.SNYTLOG_email.toLowerCase().includes(searchTerm.toLowerCase()))||
                    (val.SNYTLOG_username.toLowerCase().includes(searchTerm.toLowerCase()))

                  ){
                    return val;
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                   /*Estas validaciones sirven para editar la tabla en columna casos, agregando el 
                  icono de color según corresponda*/
                  var color = "";
                  if (row.SNYTLOG_estatus === false) {
                    color = (
                      <label className='lbl-estatus-tabla'><CircleRoundedIcon style={{color:umbralTerminado}}/>
                      <br/>
                      <FormattedMessage id="globInacti" defaultMessage="Inactivo"/>
                      </label>
                    );
                  } else if(row.SNYTLOG_estatus === true){
                    color = (
                    <label className='lbl-estatus-tabla'><CircleRoundedIcon style={{color:umbralATiempo}}/>
                    <br/>
                    <FormattedMessage id="globActivo" defaultMessage="Activo"/>
                    </label> 
                    );
                  }
                 
                  let separar = (row.groups)
                  
                  
                    var  confgeneral = " "
                    var  email= " "
                    var admin=""
            
                  
                  if(separar.includes(20)  || separar.includes(21) || separar.includes(22) 
                  ){
                   confgeneral = (<label key={separar}> <FormattedMessage
                    id="Mod-ConfGen-Title2"
                    defaultMessage="Configuración general"
                  /></label>);
                  }

                  if(separar.includes(23)  || separar.includes(24)  || separar.includes(25) 
                  ){
                   email = (<label key={separar}><FormattedMessage id="glob.correoelec" defaultMessage="Correo" /></label>);
                  }

                  if(separar.includes(26)  || separar.includes(27)  || separar.includes(28) 
                  ){
                   admin = (<label key={separar}><FormattedMessage
                    id="Mod-ConfGen-Title1"
                    defaultMessage="Administración de usuarios"
                  /></label>);
                  }

                  

                  return (
                    <StyledTableRow key={row.SNYTLOG_email}>                              
                      <StyledTableCell align="center" component="th" scope="row">
                      <RadioInput name="rb" value={row.id} onChange={()=>rbClick(row)}/>
                      </StyledTableCell>
                      <StyledTableCell align="center">{moment( row.SNYTLOG_register).format("DD/MMM/YYYY")}</StyledTableCell>
                      {/*<StyledTableCell align="center">{row.id}</StyledTableCell>*/}
                      <StyledTableCell align="center">{row.SNYTLOG_username+" "+row.SNYTLOG_surnamedad+" "+row.SNYTLOG_surnamemom}</StyledTableCell>
                      <StyledTableCell align="center">{row.SNYTLOG_email}</StyledTableCell>
                      <StyledTableCell align="center">{
                        [confgeneral," ",email," ",admin]
                      
                       }</StyledTableCell>
                      <StyledTableCell align="center">{color}</StyledTableCell>
                    </StyledTableRow>
                  )
                })}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
          
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Registros por página"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        
        />
    </Paper>
    <br/>
    <Grid container spacing={5}>
      <Grid item>
      <ModalesAlta 

     disableEnforceFocus= {true}
    >
    </ModalesAlta>
    </Grid>
    <Grid item>
    <ButtonVino
     estatus={btnDisbale} 
     onClick={handleOpen}
     label={<FormattedMessage id="adminEditr" defaultMessage="Editar usuario"/>}
    >
    </ButtonVino>
    
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
        <div class="ventstanasa">
            <div class="vents1">{/*btbreadcrumbs*/}
            <h11-title-modal-admin>
            <FormattedMessage id="adminEditr" defaultMessage="Editar Usuario "/>
              </h11-title-modal-admin></div>
            <div class="vents2"> {/*txtfield*/}
            <TextField   label={<FormattedMessage id="datosNombs" defaultMessage="Nombre(s)"/> }   InputLabelProps={{ shrink: true }}  onChange={handleChange("username")}  value={values.username} placeholder={selectdata.SNYTLOG_username} >   </TextField>
          
            </div>
            <div class="vents3"> {/*txtfield*/}
            <TextField  label={<FormattedMessage id="datosApPat" defaultMessage="Apellido paterno"/>}  InputLabelProps={{ shrink: true }}   onChange={handleChange("lastName")} placeholder={selectdata.SNYTLOG_surnamedad} value={values.lastName} />
            </div>
            <div class="vents4"> {/*txtfield*/}
            <TextField id="mat" label={<FormattedMessage id="datosApMasst" defaultMessage="Apellido Materno"/>}  InputLabelProps={{ shrink: true }}   onChange={handleChange("maternalsurname")} placeholder={selectdata.SNYTLOG_surnamemom} value={values.maternalsurname} />
            </div>
            <div class="vents5">{/*txtfield*/}
              <PasswordTXTField   label={<FormattedMessage id="globCurp" defaultMessage=""/> }   onChange={handleChange("password")} value={values.password} variant="standard"  /> 
              </div>
            <div class="vents6"> {/*txtfield*/}
            <TextField  
            onChange={handleChange("email")}
            label={<FormattedMessage id="comuCorreo" defaultMessage="Correo electrónico"/>}
            value={values.email}
            required={true}
            InputLabelProps={{ shrink: true }}
            variant="standard" 
            placeholder={selectdata.SNYTLOG_email}
            />
            </div>
            <div class="vents7"> {/*radio*/}
            <RadioDinamico id='rb-estatus' 
            row={true} 
            array={arrayEstatus} 
            formLabel={<FormattedMessage id="globEstatu" defaultMessage="Estatus"/>} 
            //defaultValue={selectdata.SNYTLOG_estatus} 
            onChange={handleChange("estatusss")}
            />
            </div>
            <div class="vents8"> {/*titulo*/}
            <hr/>
            <h11-title-modal-admin>
            <FormattedMessage id="adminRoPer" defaultMessage="Roles y Permisos"/>
            </h11-title-modal-admin>
            </div>
            <div class="vents9"> {/*tabla*/}
            <br/>
            <Table aria-label="simple table" >
        <TableHead style={{color:'white',backgroundColor:'#00203B'}} >
          <TableRow >
            <TableCell style={{color:'white'}} ><FormattedMessage id="globConsul" defaultMessage="Consultar"/> <br/></TableCell>
            <TableCell style={{color:'white'}} align="center">
            <FormattedMessage
                id="Mod-ConfGen-Title1"
                defaultMessage="Administración de usuarios"
              /><br/>
            {/* view adminusuarios */}
            <Check2
              defaultChecked={valordef.isviewadmin}
              onChange={(e) =>handleviewadmin(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
            <TableCell style={{color:'white'}} align="center">
            <FormattedMessage
                id="Mod-ConfGen-Title2"
                defaultMessage="Configuración general"
              />
            {/* view confgeneral*/}
            <Check2
              defaultChecked={valordef.isconfgenview}
              onChange={(e) =>handleviewconf(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
            </TableCell>
            <TableCell style={{color:'white'}} align="center">
            <FormattedMessage
                id="Mod-ConfGen-Title4"
                defaultMessage="Catálogo"
              />
            {/* view catalogo */}
            <Check2
              //defaultChecked={valordef.isviewdict}
              //onChange={(e) =>handleviewDictamen(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
            </TableCell>
            {/* view contabilidad */}
            <TableCell style={{color:'white'}} align="center">
            <FormattedMessage
            id="Mod-ConfGen-Title3-Subtitle2"
            defaultMessage="Contabilidad SINAY"
          />
            <Check2
              //checked={valordef.medview}
              //defaultChecked={valordef.isconfgenview}
              //onChange={(e) => handleviewMedico(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
            {/* seguridad */}
            <TableCell style={{color:'white'}} align="center">
            <FormattedMessage
                id="Mod-ConfGen-Title5"
                defaultMessage="Seguridad"
              />
            <Check2
             //checked={isviewinve}
              //defaultChecked={valordef.isviewinve}
              //onChange={(e) => handleviewInves(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
            <TableCell style={{color:'white'}} align="center">
            <FormattedMessage id="glob.correoelec" defaultMessage="Correo" />  
            {/* view correo*/}
            <Check2
              defaultChecked={valordef.isviewemail}
              onChange={(e) => handleviewemail(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
            </TableCell>
            {/* view negocio */}
            <TableCell style={{color:'white'}} align="center">
            <FormattedMessage
                id="Mod-ConfGen-Title7"
                defaultMessage="Negocio"
              />
            <Check2
              //defaultChecked={valordef.isviewestcon}
              //onChange={(e) => handleviewEstCon(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow>
              <TableCell component="th" scope="row">
              <FormattedMessage id="aGRE" defaultMessage="Agregar"/>
              </TableCell>
              <TableCell align="center"> 
              {/* agregar admin */}
              <Check2
              defaultChecked={valordef.isaddadmin}
              onChange={(e) => handleaddadmin(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center"> 
              {/* agregar confgeneral */}
              <Check2
              defaultChecked={valordef.isconfgenadd}
              onChange={(e) => handleaddconf(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center"> 
              {/* agregarcatalogo */}
              <Check2
              //defaultChecked={valordef.isadddict}
              //onChange={(e) => handleaddDictamen(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
            {/* contabilidadagregar */}
              <TableCell align="center"> 
              <Check2
              
              //defaultChecked={valordef.isconfgenadd}
              //onChange={(e) => handleaddMedico(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center"> 
              {/* seguridad */}
              <Check2
              //defaultChecked={valordef.isaddinve}
              //onChange={(e) => handleaddInves(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center"> 
              {/* correo agregar */}
              <Check2
              defaultChecked={valordef.isaddemail}
              onChange={(e) =>handleaddemail(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center">
              {/* negocio*/}
              <Check2
             // defaultChecked={valordef.isaddnegocio}
             // onChange={(e) =>handleadd(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" >
              <FormattedMessage id="adminEdici" defaultMessage="Edición"/>
              </TableCell>
              <TableCell align="center"> 
              {/* edicionadminusuarios */}
              <Check2
              defaultChecked={valordef.ischangeadmin}
              onChange={(e) =>handlechanadmin(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center"> 
              {/* edicionconfgeneral*/}
              <Check2
              defaultChecked={valordef.isconfgenchan}
              onChange={(e) =>handlechanconf(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center"> 
              {/* edicioncatalogo */}
              <Check2
              //defaultChecked={valordef.ischangedict}
              //onChange={(e) =>handlechangeDictamen(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
            {/* editarcontabilidad */}
              <TableCell align="center"> 
              <Check2
              //defaultChecked={valordef.isconfgenchan}
              //onChange={(e) =>handlechangeMedico(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center"> 
              {/* edicionseguridad */}
              <Check2
              //defaultChecked={valordef.ischangeinve}
              //onChange={(e) =>handlechangeInves(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center"> 
              {/* edicioncorreo*/}
              <Check2
              defaultChecked={valordef.ischangeemail}
              onChange={(e) =>handlechanemail(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center"> 
              {/* edicionnegocio */}
              <Check2
              //checked={ischangeestcon}
              //defaultChecked={valordef.ischangeestcon}
              //onChange={(e) =>handlechangeEstCon(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
            </TableRow>
          
          
        </TableBody>
      </Table>       
           
            </div>
            
            <div class="vents10">{/*btcancelar*/}
            <br></br>
            <ButtonVino  onClick={handleClose} label={<FormattedMessage id="globCance" defaultMessage="Cancelar"/>}/>
            </div>
            <div class="vents11">{/*btaceptar*/}
            <br></br>
            <form onClick={validateAndSubmitForm}>
            <DialogButton
                          mainButton={
                            <FormattedMessage
                              id="le"
                              defaultMessage="Modificar"
                            />
                          }
                          title={ <FormattedMessage
                            id="globUsuarioCreadoEdssdxi"
                            defaultMessage="Usuario modificado exitosamente"
                          />}
                          description={  <FormattedMessage
                            id="globSeRdsegi"
                            defaultMessage="Se modifico los datos correctamente."
                          />}
                          button={
                            <FormattedMessage
                              id="globAceptr"
                              defaultMessage="Aceptar"
                            />
                           
                          }
                          onClick={handleClose}
                          type="submit"
                          estatus={
                            values.email.length > 0 &&
                            values.password.length > 0 &&
                            values.username.length > 0 &&
                            values.lastName.length > 0 &&
                            values.estatusss.length > 0 
                              ? false
                              : true
                          }
                        />

            </form>
            </div>
        </div>
        </Box>
      </Modal>
    </Grid>

  
    <Grid item > 
   {/*Eliminar usuario btn */}
   <DialogButtons
                            onClickAccept={eliminars}
                            mainButton="Eliminar Usuario"
                            title="Eliminar Usuario"
                            description="¿Deseas eliminar este usuario?"
                            firstButton="Cancelar"
                            secondButton="Aceptar"
                            estatus={btnDisbale}
                        />

      </Grid>
    </Grid>


    </div>
  );
}