import React from "react";

import ButtonVino from "./ButtonVino";
import ButtonGris from "./ButtonGris";
import Enlace from "./Enlace";
import Finger from "./Finger";
import FoundDoc from "./FoundDoc";
import BTDictamen from "./BTDictamen";
import BTReserva from "./BTReserva";
import BTMedico from "./BTMedico";
import BTInvestigador from "./BTInvestigador";
import BTCarRsion from "./BTCarRsion";
import BTExpsin from "./BTExpsin";
import BTDatSin from "./BTDatSin";
import BTRes from "./BTRes";
import BTDestina from "./BTDestina";
import BTSolDoc from "./BTSolDoc";
import BTVerReporte from "./BTVerReporte";
import BTCatalogoCuentas from "./BTCatalogoCuentas";
import BTIntegradorCont from "./BTIntegradorCont";
import BTReporteyEst from "./BTReporteyEst";
import BTConslinea from "./BTConslinea";
import BTReportPresc from "./BTReportPresc";
import BTDocSiniestro from "./BTDocSiniestro";
import BTIndicacionDictamen from "./BTIndicacionDictamen";
import BTBorrar from "./BTBorrar";
import BTVer from "./BTVer";
import BTDocuments from "./BTDocuments";
import BTGlobal from "./BTGlobal";
import BTAnalista from "./BTAnalista";
import BTTiempoporModulo from "./BTTiempoporModulo";
import BTAgregarDocumento from "./BTAgregarDocumento";
import BTNuevoTramite from "./BTNuevoTramite";
import BTConsultarRegistro from "./BTConsultarRegistro";

import Radio2 from "../RadioButton/Radio2";
import Radio3 from "../RadioButton/Radio3";
import Radio4 from "../RadioButton/Radio4";

import Recorrido from "../BreadCrumbs/Recorrido";
import Recorrido3 from "../BreadCrumbs/Recorrido3";

//import Appos from "../Documents/index";
import DatePicker from "../DatePicker/DatePicker";

export function BotonesLayout() {
  return (
    <div>
      <ButtonVino value="boton primario" />
      <p />
      <ButtonGris value="boton secundario" />
      <p />
      <Enlace text="https://www.google.com/" value="Enlace" />
      <p />
      <BTAgregarDocumento text="https://www.google.com/" />
      <label>Agregar Documento/Iniciar Registro/Inicio</label>
      <p />
      <BTNuevoTramite text="https://www.google.com/" />
      <label>Nuevo Tramite</label>
      <p />
      <BTDictamen text="https://www.google.com/" />
      <label>Dictamen</label>
      <p />
      <BTReserva text="https://www.google.com/" />
      <label>Reserva</label>
      <p />
      <BTMedico text="https://www.google.com/" />
      <label>Medico</label>
      <p />
      <BTInvestigador text="https://www.google.com/" />
      <label>Investigador</label>
      <p />
      <BTCarRsion text="https://www.google.com/" />
      <label>Carta de rechazo / Carta de Rescision</label>
      <p />
      <BTExpsin text="https://www.google.com/" />
      <label>Expediente del Siniestro </label>
      <p />
      <BTDatSin text="https://www.google.com/" />
      <label>Datos del Siniestro </label>
      <p />
      <BTRes text="https://www.google.com/" />
      <label>Reaseguro </label>
      <p />
      <BTDestina text="https://www.google.com/" />
      <label>Destinatario </label>
      <p />
      <BTSolDoc text="https://www.google.com/" />
      <label>Solicitud de Documentos </label>
      <p />
      <BTVerReporte text="https://www.google.com/" />
      <label>Ver Reporte </label>
      <p />
      <BTCatalogoCuentas text="https://www.google.com/" />
      <label>Catalogo de Cuentas </label>
      <p />
      <BTIntegradorCont text="https://www.google.com/" />
      <label>Integrado de Contabilidad </label>
      <p />
      <BTReporteyEst text="https://www.google.com/" />
      <label>Reportes y Estadisticas </label>
      <p />
      <BTReportPresc text="https://www.google.com/" />
      <label>Reporte de prescripcion </label>
      <p />
      <BTConslinea text="https://www.google.com/" />
      <label>Consulta en linea </label>
      <p />
      <FoundDoc text="https://www.google.com/" />
      <label>Informe de Investigador</label>
      <p />
      <BTDocSiniestro text="https://www.google.com/" />
      <label>Documentacion Siniestro</label>
      <p />
      <BTIndicacionDictamen text="https://www.google.com/" />
      <label>Indicaciones de Dictamen</label>
      <p />
      <BTBorrar text="https://www.google.com/" />
      <label>Borrar</label>
      <p />
      <BTVer text="https://www.google.com/" />
      <label>Ver</label>
      <p />
      <BTDocuments text="https://www.google.com/" />
      <label>Documentos</label>
      <p />
      <BTGlobal text="https://www.google.com/" />
      <label>Global</label>
      <p />
      <BTAnalista text="https://www.google.com/" />
      <label>Analista</label>
      <p />
      <BTTiempoporModulo text="https://www.google.com/" />
      <label>Tiempo por Modulo</label>
      <p />
      <BTConsultarRegistro text="https://www.google.com/" />
      <label>Consultar Registro</label>
      <p />
      <Finger />
      <label>Lector de huella</label>
      <p />

      <Radio2 text="https://www.google.com/" value="Enlace" list="sasa" />
      <p />
      <Radio3 />
      <p />
      <Radio4 />

      <p />
      <Recorrido />
      <p />
      <Recorrido3 />
      <p />
      <DatePicker />
      <p />
    </div>
  );
}
