import React, { useState } from "react";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { getToken } from "../../../../api/token";
import { BASE_API } from "../../../../utils/constans";

export default function Contabi() {
  const [cuentaMayor, setCuentaMayor] = useState(false);
  const [subcuenta1, setSubcuenta1] = useState(false);
  const [subcuenta2, setSubcuenta2] = useState(false);
  const [subsubcuenta1, setSubsubcuenta1] = useState(false);
  const [subsubcuenta2, setSubsubcuenta2] = useState(false);
  const [nombreCuenta, setNombreCuenta] = useState('');

  const handleCuentaMayorChange = (event) => {
    const isChecked = event.target.checked;
    setCuentaMayor(isChecked);

    // Habilitar o deshabilitar subcuentas y subsubcuentas
    if (!isChecked) {
      setSubcuenta1(false);
      setSubcuenta2(false);
      setSubsubcuenta1(false);
      setSubsubcuenta2(false);
    }
  };

  const handleNombreCuentaChange = (event) => {
    const nombre = event.target.value;
    setNombreCuenta(nombre);
  };

  const handleGuardar = () => {
    // Aquí puedes hacer algo con los datos seleccionados
    // Por ejemplo, enviarlos a una API o mostrarlos en la consola
    console.log('Cuenta Mayor:', cuentaMayor);
    console.log('Subcuenta 1:', subcuenta1);
    console.log('Subcuenta 2:', subcuenta2);
    console.log('Subsubcuenta 1:', subsubcuenta1);
    console.log('Subsubcuenta 2:', subsubcuenta2);
    console.log('Nombre de la cuenta:', nombreCuenta);
  };

  return (
    <div>
      <h1>Contabilidad</h1>

      <label>
        <input
          type="checkbox"
          checked={cuentaMayor}
          onChange={handleCuentaMayorChange}
        />
        Cuenta Mayor
      </label>

      <div>
        <label>
          <input type="checkbox" checked={subcuenta1} disabled={!cuentaMayor} onChange={() => setSubcuenta1(!subcuenta1)} />
          Subcuenta 1
        </label>
        <label>
          <input type="checkbox" checked={subcuenta2} disabled={!cuentaMayor} onChange={() => setSubcuenta2(!subcuenta2)} />
          Subcuenta 2
        </label>
      </div>

      <div>
        <label>
          <input type="checkbox" checked={subsubcuenta1} disabled={!cuentaMayor || !subcuenta1} onChange={() => setSubsubcuenta1(!subsubcuenta1)} />
          Subsubcuenta 1
        </label>
        <label>
          <input type="checkbox" checked={subsubcuenta2} disabled={!cuentaMayor || !subcuenta2} onChange={() => setSubsubcuenta2(!subsubcuenta2)} />
          Subsubcuenta 2
        </label>
      </div>

      <div>
        <label>
          Nombre de la cuenta:
          <input
            type="text"
            value={nombreCuenta}
            onChange={handleNombreCuentaChange}
          />
        </label>
      </div>

      <button onClick={handleGuardar}>Guardar</button>
    </div>
  );
}