import React from "react";
import imgheadersinay from "../../../../img/header_sinay.png";
import imglogo from "../../../../img/logo_sinay.png";

//Modal
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
//Transition
import Slide from "@mui/material/Slide";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
// Create styles
const styles = StyleSheet.create({
  page: {
    // backgroundColor: "#d11fb6",
    // color: "white",
  },
  sectionheader: {
    // margin: 10,
    padding: 5,
    display: "flex",
  },
  sectionbody: {
    margin: 15,
    padding: 15,
  },
  viewer: {
    width: window.innerWidth - 760,
    height: window.innerHeight,
  },
  imagelogo: { width: 70, height: 70, float: "left" },
  title: { fontSize: 14, fontWeight: "extrabold" },
  subtitle: { fontSize: 13 },
  parrafo: {
    fontWeight: "normal",
    fontSize: 12,
    textAlign: "justify",
    padding: 15,
  },
});

//TODO: Transition of dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function BenePDF() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
   <div>
    <Button onClick={handleClickOpen("paper")}> Generar PDF</Button>
    <Dialog
    open={open}
    // onClose={handleClose}
    TransitionComponent={Transition}
    scroll={scroll}
    aria-labelledby="scroll-dialog-title"
    aria-describedby="scroll-dialog-description"
    maxWidth="lg"
  >
    <DialogTitle id="scroll-dialog-title">PDF</DialogTitle>
    <DialogContent dividers={scroll === "paper"}>
      <DialogContentText
        id="scroll-dialog-description"
        ref={descriptionElementRef}
        tabIndex={-1}
      >
        <PDFViewer style={styles.viewer}>
          <Document>
            <Page size="A4" style={styles.page}>
              <View
                style={{
                  display: "flex",
                  padding: 15,
                  flexDirection: "row",
                }}
              >
                <Image style={{ flex: 1 }} src={imglogo} />
                <Image style={{ flex: 7 }} src={imgheadersinay} />
              </View>
              <View style={styles.sectionbody}>
                <Text></Text>
                <Text style={styles.title}>
                EL BENEFICIO DE INVALIDEZ SÓLO SE PAGA AL ASEGURADO
                </Text>
                <Text>&nbsp;</Text>
                <View style={styles.parrafo}>
                  <textoparaPDF />

                  <Text id="textoaPDF"></Text>
                  <Text>
                  Póliza original. En caso de invalidez, con una copia de la póliza es suficiente.
                  </Text>
                  <Text>Historia clínica completa (Original o copia certificada) Con pronóstico y estado actual de salud del asegurado.</Text>
                  <Text>
                  Formato de invalidez (Original o copia certificada) Formato de declaración para el trámite de reclamación de beneficios adicionales. Debidamente llenado y firmado por el asegurado y el médico tratante.{" "}
                  </Text>
                  <Text>
                  Formato informe médico de beneficios de indemnización por pérdidas orgánicas Debidamente llenado y firmado por el Asegurado y el médico tratante. (Solo si cuenta con cobertura DIBA).
                  </Text>
                  <Text>Identificación Oficial Asegurado</Text>
                  <Text>
                  Datos bancarios estado de cuenta del asegurado no mayor a 3 meses de antigüedad.
                  </Text>
                  <Text>
                  Formato reclamación formato de información para pago. Debidamente llenado y firmado por el asegurado
                  </Text>
                  <Text>
                  Otros{" "}
                  </Text>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Cancelar</Button>
    </DialogActions>
  </Dialog>
  </div> 
  );
}