//Brandon Hernandez 12/2022
import React from 'react'
import Grid from '@material-ui/core/Grid';
import CustomSeparator from '../Tools/BreadCrumbs/Recorrido'
import "../../css/estilo.css";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
//Button
import ButtonVino from '../Tools/Bottones/ButtonVino'
//TextField
import TXTField from "../Tools/TextField/TextField";
//Encrypt library 
import JSEncrypt from "jsencrypt";
//Button 
import BTUpload from "../Tools/Bottones/BTUpload";
//
import DialogButtons from '../Tools/Dialog/DialogButtons'
//Ejemplo de generacion e llave privada y privada
import { createPairKeys } from "../../privateKey.js/privateKey";
//idioma
import { FormattedMessage } from 'react-intl';

import { getDataToken } from "../../api/dataToken";
import {  useEffect } from 'react' 
import { useState } from "react";

const CompPerfilCliente = () => {

    const [priKeyEncrypt, setPriKeyEncrypt] = React.useState("")
    const [privateKey, setPrivateKey] = React.useState("")
    const [publicKey, setPublicKey] = React.useState("")
    const [btnLlaves, setBtnLlaves] = useState(false);
    const [dataX, setDataX] = React.useState([])           
    const [namemed,setnamemed] = React.useState("")
    const [namecomcli,setnamecomcli]= React.useState("")
    const [nameinv,setnameinv] = React.useState("")
    const [namerec,setnamerec] = React.useState("")
    const [namereg,setnamereg] = React.useState("")
    const [nameadmin,setnameadmin] = React.useState("")
    const [namesuper,setnamesuper] = React.useState("")
    const [generarllaves,setgenerarllaves] = React.useState(false)

  const getDataPermisos = async () => {
    //Resultados de la api investigacion
    var resultPermisos = await getDataToken("login/get_correo/"+  localStorage.getItem("SNYTLOG_email") + "/");    
    setDataX(resultPermisos);
    if(resultPermisos.SNYTLOG_generollave === true){
        setBtnLlaves(true)
    }
    getPermisos(resultPermisos)
  }
  
  useEffect(() => {
    getDataPermisos()  
  }, [btnLlaves])

    const handleCreatePrivateKey = () => {
        createPairKeys()
    }
    

    const handleChangePubKey = (e) => {
        console.log(e.target.files[0])
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.addEventListener('load', function () {
            setPublicKey(reader.result);
        });
        reader.readAsText(file)
    }
    const handleChangePrivKey = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.addEventListener('load', function () {
            setPrivateKey(reader.result);
        });
        reader.readAsText(file)
    }
    React.useEffect(() => {
    }, [publicKey, privateKey])
    
    const validateKeys = (e) => {
        if (privateKey === "" || publicKey === "") {
            alert('Selecciona archivos para comparar llaves')
        } else {
            // Encrypt with the public key...
            var encrypt = new JSEncrypt();
            encrypt.setPublicKey(publicKey);
            // Generate a random alphanumerical string of length 11 ( change substring parameter for other length)
            var aux = Math.random().toString(36).substring(2);
            var encrypted = encrypt.encrypt(aux);

            // Decrypt with the private key...
            var decrypt = new JSEncrypt();
            decrypt.setPrivateKey(privateKey);
            var uncrypted = decrypt.decrypt(encrypted);

            // Now a simple check to see if the round-trip worked.
            if (uncrypted == aux) {
                alert('Las llaves corresponden entre sí!!!');
            }
            else {
                alert('Las llaves no corresponden entre sí....');
            }

        }
    }

    const actualizaContraseña = () => {
          window.location = "/Actualizar-Contra";
      };

    const getPermisos = async (data) => {
        console.log("data de grupos", data.groups)
        let separar = (data.groups)
                if(separar.includes(2)  || separar.includes(3) || separar.includes(4) 
                  ){
                console.log(1)
                setnamemed("Médico");
                   
                  }

                  if(separar.includes(5)  || separar.includes(6)  || separar.includes(7) 
                  ){
                    console.log(2)
                   setnamerec("Recepción");
                  }

                  if(separar.includes(8)  || separar.includes(9)   || separar.includes(10)
                    ){
                        console.log(3)
                     setnamereg("Registro");
                  }

                  // if(row.groups ==  || row.groups ==  || row.groups == ){
                  //   namemed = (<label><FormattedMessage id="menuDictam" defaultMessage="Dictamen"/></label>);
                  // }

                  if(separar.includes(11)  || separar.includes(12) || separar.includes(13)
                  ){
                  setnameinv("Investigación");
                  setgenerarllaves(true);
                  }

                  if(separar.includes(14) || separar.includes(15) || separar.includes(16)
                  ){
                  setnamecomcli("Comunicación con el Cliente");
                  }

                  // if(row.groups ==  || row.groups ==  || row.groups == ){
                  //   namemed = (<label><FormattedMessage id="menuEstCot" defaultMessage="Estadístico Contables"/></label>);
                  // }

                  if(separar.includes(17) || separar.includes(18) || separar.includes(19)
                  ){
                  setnameadmin("Administrador");
                  
                  }

                  //supervisor
                  if(separar.includes(30) || separar.includes(31) || separar.includes(32)
                  ){
                  setnamesuper("Supervisor");
                  }
    }


if(generarllaves){
    return (

        <div className='div-parent'>
            <div className='div-breadcrumbs'>
                <CustomSeparator name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
                    enlace="/menu"
                    name2={<FormattedMessage id="gperfil" defaultMessage="Perfil" />}
                />
            </div>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                <ReturnPage href='/menu' />
            </Grid>
            <div className='div-container'>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12}>
                        <h3-header-card>Información personal</h3-header-card>
                        <br />
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
                        <Grid item xs={3}>
                            <h3-title-card>
                            <FormattedMessage
                                id="perfil.nombre"
                                defaultMessage="Nombre:"
                            />
                            </h3-title-card>
                            <h3-subtitle-card>{dataX?.SNYTLOG_username}</h3-subtitle-card>
                        </Grid>

                        <Grid item xs={3}>
                            <h3-title-card>
                            <FormattedMessage
                                id="perfil.apPaterno"
                                defaultMessage="Apellido paterno:"
                            />
                            </h3-title-card>
                            <h3-subtitle-card>{dataX?.SNYTLOG_surnamedad}</h3-subtitle-card>
                        </Grid>
                        <Grid item xs={3}>
                            <h3-title-card>
                            <FormattedMessage
                                id="perfil.apMaterno"
                                defaultMessage="Apellido materno:"
                            />
                            </h3-title-card>
                            <h3-subtitle-card>{dataX?.SNYTLOG_surnamemom}</h3-subtitle-card>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={3}>
                            <h3-title-card>
                            <FormattedMessage
                                id="perfil.correo"
                                defaultMessage="Correo:"
                            />
                            </h3-title-card>
                            <h3-subtitle-card>{dataX?.SNYTLOG_email}</h3-subtitle-card>
                        </Grid>
                    <br />
                    <ButtonVino 
                    onClick={actualizaContraseña}
                    label={<FormattedMessage id="actContraseña" defaultMessage="Recupera Contraseña"/>}
                    >
                    </ButtonVino>

                    <br /><br />
                        <hr></hr>
                    </Grid>
                    <Grid item xs={12}>
                        <h3-header-card>Roles y permisos</h3-header-card>
                        <br />
                        <Grid item xs={3}>
                            <h3-title-card>
                            <FormattedMessage
                                id="perfil.permisos"
                                defaultMessage="Pemisos por modulo:"
                            />
                            </h3-title-card>
                            
                            <h3-subtitle-card>{namereg}</h3-subtitle-card>
                            <h3-subtitle-card>{namerec}</h3-subtitle-card>
                            <h3-subtitle-card>{namemed}</h3-subtitle-card>
                            <h3-subtitle-card>{nameinv}</h3-subtitle-card>
                            <h3-subtitle-card>{namecomcli}</h3-subtitle-card>
                            <h3-subtitle-card>{nameadmin}</h3-subtitle-card>
                            <h3-subtitle-card>{namesuper}</h3-subtitle-card>
                        </Grid>
                        <br /><br />
                        <hr></hr>
                    </Grid>

            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
                
                    <Grid item xs={12}> 
                        <h3-header-card>LLave pública y privada</h3-header-card>
                        <br /><br />
                        
                        <DialogButtons
                            onClickAccept={handleCreatePrivateKey}
                            mainButton="Crear llave privada"
                            title="Generar clave"
                            description="Guarda este archivo de forma segura porque no puedes recuperar la clave generada si la pierdes."
                            firstButton="Cancelar"
                            secondButton="Aceptar"
                            estatus={btnLlaves}
                        />
                        
                    </Grid>
                    <Grid item>
                    </Grid>
                    <hr></hr>
{/*}
                    <Grid item xs={2} alignItems="center">
                        <h2-dialog-description><label>Public key </label></h2-dialog-description>
                        <BTUpload  accept='.txt' onChange={(e) => handleChangePubKey(e)} />
    </Grid>*/}
                    <Grid item xs={2}>
                        <h2-dialog-description><label>Private key </label></h2-dialog-description>
                        <BTUpload  accept='.txt' onChange={(e) => handleChangePrivKey(e)} />
                    </Grid>
                    <Grid item xs={2}>
                        <ButtonVino label="Validar llave" onClick={(e) => validateKeys(e)} />
                    </Grid>
                </Grid>
            </Grid>
            </div>
        </div>

    );}

else{
    return(

    <div className='div-parent'>
    <div className='div-breadcrumbs'>
        <CustomSeparator name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
            enlace="/menu"
            name2={<FormattedMessage id="gperfil" defaultMessage="Perfil" />}
        />
    </div>
    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
        <ReturnPage href='/menu' />
    </Grid>
    <div className='div-container'>
        <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
                <h3-header-card>Información personal</h3-header-card>
                <br />
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
                <Grid item xs={3}>
                    <h3-title-card>
                    <FormattedMessage
                        id="perfil.nombre"
                        defaultMessage="Nombre:"
                    />
                    </h3-title-card>
                    <h3-subtitle-card>{dataX?.SNYTLOG_username}</h3-subtitle-card>
                </Grid>

                <Grid item xs={3}>
                    <h3-title-card>
                    <FormattedMessage
                        id="perfil.apPaterno"
                        defaultMessage="Apellido paterno:"
                    />
                    </h3-title-card>
                    <h3-subtitle-card>{dataX?.SNYTLOG_surnamedad}</h3-subtitle-card>
                </Grid>
                <Grid item xs={3}>
                    <h3-title-card>
                    <FormattedMessage
                        id="perfil.apMaterno"
                        defaultMessage="Apellido materno:"
                    />
                    </h3-title-card>
                    <h3-subtitle-card>{dataX?.SNYTLOG_surnamemom}</h3-subtitle-card>
                </Grid>
            </Grid>
            <br />
            <Grid item xs={3}>
                    <h3-title-card>
                    <FormattedMessage
                        id="perfil.correo"
                        defaultMessage="Correo:"
                    />
                    </h3-title-card>
                    <h3-subtitle-card>{dataX?.SNYTLOG_email}</h3-subtitle-card>
                </Grid>
            <br />
            <ButtonVino 
            onClick={actualizaContraseña}
            label={<FormattedMessage id="actContraseña" defaultMessage="Recupera Contraseña"/>}
            >
            </ButtonVino>

            <br /><br />
                <hr></hr>
            </Grid>
            <Grid item xs={12}>
                <h3-header-card>Roles y permisos</h3-header-card>
                <br />
                <Grid item xs={3}>
                    <h3-title-card>
                    <FormattedMessage
                        id="perfil.permisos"
                        defaultMessage="Pemisos por modulo:"
                    />
                    </h3-title-card>
                    
                    <h3-subtitle-card>{namereg}</h3-subtitle-card>
                    <h3-subtitle-card>{namerec}</h3-subtitle-card>
                    <h3-subtitle-card>{namemed}</h3-subtitle-card>
                    <h3-subtitle-card>{nameinv}</h3-subtitle-card>
                    <h3-subtitle-card>{namecomcli}</h3-subtitle-card>
                    <h3-subtitle-card>{nameadmin}</h3-subtitle-card>
                    <h3-subtitle-card>{namesuper}</h3-subtitle-card>
                </Grid>
                <br /><br />
                <hr></hr>
            </Grid>
            </Grid>
            </div>
        </div>
);}
}
export default CompPerfilCliente;