/* Brandon Hernandez Rocha 11/2022 */
import React from "react";
import "../../css/estilo.css";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import { Grid } from "@mui/material";

//idioma
import { FormattedMessage } from "react-intl";
import CustomSeparatorDinamic from "../Tools/BreadCrumbs/RecorridoDinamico";
import TablaCharolaPendientes from "./Elements/Tables/TablaCharolaPendientes";

const CompDictamenTramAut = () => {
    const arrayBreadcrumbs = [{ 'key': 1, 'name': <FormattedMessage id="glob.inicio" defaultMessage="Inicio" />, 'to': '/menu' }, { 'key': 2, 'name': <FormattedMessage id="menu.dictamen" defaultMessage="Dictamen" />, 'to': '/dictamen' }]



    return (
        <div className="div-parent">
            <div className="div-breadcrumbs">
                <CustomSeparatorDinamic breadcrumbs={arrayBreadcrumbs} name={`Trámites autorizados`} />
            </div>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
            >
                <ReturnPage to="/dictamen" />
            </Grid>
            <div className="div-container">
                <TablaCharolaPendientes />
            </div>
        </div>
    );
};
export default CompDictamenTramAut;
