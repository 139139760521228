import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";

const CustomSeparator3 = (props) => {
  const breadcrumbs = [
    <Link
      style={{ textDecoration: "none" }}
      underline="hover"
      key="1"
      href={props.enlace}
      to={props.to}
      state={props.state}
    >
      <h2-breadcrumbs-primary>{props.name1}</h2-breadcrumbs-primary>
    </Link>,
    <Link
      style={{ textDecoration: "none" }}
      underline="hover"
      key="2"
      href={props.enlace2}
      to={props.to2}
      state={props.state2}
    >
      <h2-breadcrumbs-primary>{props.name2}</h2-breadcrumbs-primary>
    </Link>,
    <Link
      style={{ textDecoration: "none" }}
      underline="hover"
      key="3"
      href={props.enlace3}
      to={props.to3}
      state={props.state3}
    >
      <h2-breadcrumbs-primary>{props.name3}</h2-breadcrumbs-primary>
    </Link>,
    <h3-breadcrumbs-secundary>{props.name4}</h3-breadcrumbs-secundary>,
  ];

  return (
    <Stack spacing={3}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};

export default CustomSeparator3;
