//  David Ruiz 10/2022

import React from 'react';
import IconButton from '@mui/material/IconButton';
import NoteAddIcon from '@mui/icons-material/NoteAdd';


const BTnuevotram=(props)=>{
    return(
        <IconButton aria-label=" NoteAddIcon" color="icons"
        //aqui va el enlace
        href={props.enlace}
        onClick={props.onClick}
        onChange={props.onChange}
        id={props.id}
        //ojo aqui es disabled
        //true para desabilitar y false para habilitar
        disabled={props.estatus}>
        < NoteAddIcon />
        </IconButton>
        );
    }
export default BTnuevotram;