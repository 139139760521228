import * as React from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material"; 
import TXTField from "../../Tools/TextField/TextField";
//estilos de la tabla
import StyledTableCell from "../../Tools/Table/StyledTableCell";
import TablePagination from "@mui/material/TablePagination";
//Idioma
import { FormattedMessage } from "react-intl";
//importacion apis
import {getDataToken} from "../../../api/dataToken"


export default function TableAccounts() {
    //Creación de metodo get
    const [cuentas, setCuentas] = useState([]);    
    const getData = async () => {
      try {
        var result = await getDataToken("cuentas/get_listado/");
        console.log("ojala salga", result);
        if(result)
          setCuentas(result);
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

    useEffect(() => {
      getData();
    }, []); 


  /* CONSTANTES DE ESTADOS*/
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla


  React.useEffect(()=>{
    console.log("Cuentas:", cuentas)
  }, [cuentas])

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item>
          <br />
          <h3-header-card>
            <FormattedMessage id="estCoCoCaC" defaultMessage="Consulta de catálogo de cuentas"/>
          </h3-header-card>
        </Grid>
      </Grid>
      <Grid
        style={{ paddingBottom: "20px" }}
        container
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={4}>
          <h4-search><FormattedMessage id="globBuscar" defaultMessage="Buscar"/></h4-search>
          <TXTField
            showSearchIcon={true}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }} style={{ position: "sticky" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                <StyledTableCell align="center"><FormattedMessage id="estCueMayo" defaultMessage="Cuenta del mayor"/></StyledTableCell>
                <StyledTableCell align="center"><FormattedMessage id="estcoSubCu" defaultMessage="Subcuenta"/></StyledTableCell>
                <StyledTableCell align="center"><FormattedMessage id="notiene" defaultMessage="Subsubcuenta"/></StyledTableCell>
                <StyledTableCell align="center"><FormattedMessage id="loginCuent" defaultMessage="Cuenta"/></StyledTableCell>
                <StyledTableCell align="center">Nombre</StyledTableCell>
              </TableRow>
            </TableHead>
            { cuentas
            .filter((val) => {
              if (searchTerm === "") {
                return true; // Devuelve verdadero para mantener todos los elementos si el filtro está vacío.
              } else {
                /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                return (
                  (val.CTAM_idcuentamayor?.CTAM_numcuenta).toString().includes(searchTerm) ||
                  (val.CTA_numcuenta).toString().includes(searchTerm) ||
                  val.CTA_nombre.toLowerCase().includes(searchTerm.toLowerCase())
                );
              }
            })
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => (
              <TableRow key={row.CTA_numcuenta?.CTA_numcuenta}>
                <StyledTableCell align="center"> {row.CTAM_idcuentamayor?.CTAM_numcuenta} </StyledTableCell>
                <StyledTableCell align="center"> {row.SCTA_idsubcuenta?.SCTA_numsubcuenta} </StyledTableCell>
                <StyledTableCell align="center"> {row.SSCTA_idsubsubcuenta?.SSCTA_numsubsubcuenta} </StyledTableCell>
                <StyledTableCell align="center"> {row.CTA_numcuenta} </StyledTableCell>
                <StyledTableCell>{row.CTA_nombre}</StyledTableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={cuentas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelRowsPerPage="Filas por página"
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* <MyComponent compToRender={TableAccounts} /> */}
    </div>
  );
}
