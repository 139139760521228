export default function validateEmail(correo, dominio) {
  // Extraer el dominio del correo electrónico ingresado por el usuario.
  var partesCorreo = correo.split("@");
  var dominioCorreo = partesCorreo[1];

  // Comparar el dominio extraído con el dominio especificado.
  if (dominioCorreo === dominio) {
    return true;
  } else {
    return false;
  }
}
