import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const R2 = (props) => {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={props.valordefault}
        name="radio-buttons-group"
        color="primary"
        row
      >
        <FormControlLabel
          onClick={props.evento1}
          value={props.valor1}
          control={<Radio />}
          label={props.nombre1}
        />
        <FormControlLabel
          onClick={props.evento2}
          value={props.valor2}
          control={<Radio />}
          label={props.nombre2}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default R2;
