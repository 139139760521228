import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { Select2 } from '../../Tools/Selectss/Select2'
//importaciones de las tablas que se necesitan 
import Policy from "../MenuConsultaLinea/Policy"//Por póliza
import Invoice from "../MenuConsultaLinea/Invoice" //por Número de folio
import NameInsure from "../MenuConsultaLinea/NameInsure"//por nombre asegurado
import PaymentMovements from "../MenuConsultaLinea/PaymentMovements"//por folio movimientos contables
import AccountingMovements from "../MenuConsultaLinea/AccountingMovements"//por polizas contables
import Beneficiary from "../MenuConsultaLinea/Beneficiary"//beneficiario
import FolioMovimiento from "../MenuConsultaLinea/FolioMovimiento" //Folio Movimiento
import ButtonVino from "../../Tools/Bottones/ButtonVino"
import PolicyReg from '../MenuConsultaLinea/PolicyReg'
import { FormattedMessage } from "react-intl"

const CompConsultaLinea = ()=>{
  //Constantes ára las tablas 
  const [folio, setFolio] = useState (false)
  const [poliza, setPoliza] = useState (false)
  const [asegurado, setAsegurado] = useState (false)
  const [beneficiario, setBeneficiario] = useState (false)
  const [movFolio, setMovFolio] = useState (false)
  const [movPoliza, setMovPoliza] = useState (false)
  const [contFolio, setContFolio] = useState (false)
  const [polRegistro, setPolRegistro] = useState (false)
  const [valorSelect, setValorSelect] = useState(0) 

  const[showBtnRegresarTabla, setShowBtnRegresarTabla]= useState (false)

const [linea, setLinea] = useState([]);
const consultaLinea = [
  { identificador: "1", texto: "Por número de folio" },
  { identificador: "2", texto: "Por número de póliza" },
  { identificador: "3", texto: "Por nombre del asegurado" },
  { identificador: "4", texto: "Por nombre del beneficiario" },
  { identificador: "5", texto: "Por póliza registros contables" },
  { identificador: "6", texto: "Por folio registros contables" },
  { identificador: "7", texto: "Por póliza registros contables de pago" },
  { identificador: "8", texto: "Por número de siniestro" },
  
];
useEffect(() => {
   setLinea(consultaLinea);
}, []);


const handleMenu = (id) =>{
  const dt = consultaLinea.filter((x) => x.identificador == id);
  if (id == 1) {
    setFolio(true);
    setPoliza(false);
    setAsegurado(false);
    setBeneficiario(false);
    setMovFolio(false);
    setMovPoliza(false);
    setContFolio(false);
    setPolRegistro(false);
    setShowBtnRegresarTabla(true);
  }if(id==2){
    setFolio(false)
    setPoliza(true)
    setAsegurado(false)
    setBeneficiario(false)
    setMovFolio(false)
    setMovPoliza(false)
    setContFolio(false)
    setPolRegistro(false)
    setShowBtnRegresarTabla(true)
  }if(id==3){
    setFolio(false)
    setPoliza(false)
    setAsegurado(true)
    setBeneficiario(false)
    setMovFolio(false)
    setMovPoliza(false)
    setContFolio(false)
    setPolRegistro(false)
    setShowBtnRegresarTabla(true)
  }if(id==4){
    setFolio(false)
    setPoliza(false)
    setAsegurado(false)
    setBeneficiario(true)
    setMovFolio(false)
    setMovPoliza(false)
    setContFolio(false)
    setPolRegistro(false)
    setShowBtnRegresarTabla(true)
  }if(id==5){
    setFolio(false)
    setPoliza(false)
    setAsegurado(false)
    setBeneficiario(false)
    setMovFolio(true)
    setMovPoliza(false)
    setContFolio(false)
    setPolRegistro(false)
    setShowBtnRegresarTabla(true)
  }if(id==6){
    setFolio(false)
    setPoliza(false)
    setAsegurado(false)
    setBeneficiario(false)
    setMovFolio(false)
    setMovPoliza(true)
    setContFolio(false)
    setPolRegistro(false)
    setShowBtnRegresarTabla(true)
  }if(id==7){
    setFolio(false)
    setPoliza(false)
    setAsegurado(false)
    setBeneficiario(false)
    setMovFolio(false)
    setMovPoliza(false)
    setContFolio(true)
    setPolRegistro(false)
    setShowBtnRegresarTabla(true)
  }if(id==8){
    setFolio(false)
    setPoliza(false)
    setAsegurado(false)
    setBeneficiario(false)
    setMovFolio(false)
    setMovPoliza(false)
    setContFolio(false)
    setPolRegistro(true)
    setShowBtnRegresarTabla(true)
  }
  
}

const handleClickRegresarTabla = () => {
  // Restablecer los estados 
    setFolio(false);
  setPoliza(false);
  setAsegurado(false);
  setBeneficiario(false);
  setMovFolio(false);
  setMovPoliza(false);
  setContFolio(false);
  setPolRegistro(false);
  setShowBtnRegresarTabla(false);
  setValorSelect(0) 
};
  
  const handleChangeMenu = (e) => {
    handleMenu(e.target.value);
    setValorSelect(e.target.value);
  };
    return(
     <>
      <Grid container>
        {/*En este contenido llamaremos a los select necesarios para realizar nuestro menu
        Array=pondremos el arreglo que queremos mostrar 
        onChange= pondremos el handle que deseamos que necesitamos en esa opción      
      */}
        <Grid xs={4}>
          <Select2
          sx={{ minWidth: 310, maxWidth: 300, background: "white", }} h3-contable='Buscar por:'
          id="subConsultaLinea" 
          array={consultaLinea}
          onChange={handleChangeMenu} 
          value={valorSelect}
           />
        </Grid>

        <Grid xs={12}>
          {/*Pondremos todas las opciones porque estas seran seleccionadas dependiendo del menu 
        que desea ver*/} 
          {folio? <Invoice/>:null}
          {poliza? <Policy/>:null}
          {asegurado? <NameInsure/>:null}
          {beneficiario? <Beneficiary/>:null}
          {movFolio? <PaymentMovements/>:null}
          {movPoliza? <AccountingMovements/>:null}
          {contFolio? <FolioMovimiento/>:null}
          {polRegistro? <PolicyReg/>:null}
          <br/>
        </Grid>
       
        {showBtnRegresarTabla? <Grid container justifyContent="flex-end">
        <Grid item>
        <ButtonVino
          onClick={handleClickRegresarTabla}
          label={<FormattedMessage id="globRegres" defaultMessage="Regresar" />}
        />
        </Grid>
      </Grid>:null}
      </Grid>
     </>
    )
}
export default CompConsultaLinea;