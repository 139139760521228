import { RegistroInicio } from "../../pages/Client/ModRegistro/RegistroInicio";
import { RegistroAsegurado } from "../../pages/Client/ModRegistro/RegistroAsegurado";
import { Siniestro } from "../../pages/Client/ModRegistro/Siniestro";
import { Cobertura } from "../../pages/Client/ModRegistro/Cobertura";
import { ConstRegistro } from "../../pages/Client/ModRegistro/ConstRegistro";
import { LoginLayaout } from "../../layouts/Login/LoginLayout";
import { ClientLayaout } from "../../layouts/Client/ClientLayaout";

const routesRegistro = [
  {
    path: "/registro/inicio",
    layout: ClientLayaout,
    component: RegistroInicio,
    exact: true,
  },
  {
    path: "/registroasegurado",
    layout: ClientLayaout,
    component: RegistroAsegurado,
    exact: true,
  },
  {
    path: "/cobertura",
    layout: ClientLayaout,
    component: Cobertura,
    exact: true,
  },
  {
    path: "/datosdelsiniestro",
    layout: ClientLayaout,
    component: Siniestro,
    exact: true,
  },
  {
    path: "/consulta/registro",
    layout: ClientLayaout,
    component: ConstRegistro,
    exact: true,
  },
];

export default routesRegistro;
