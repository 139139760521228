import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ButtonVino from '../../Tools/Bottones/ButtonVino';
import { Grid } from "@mui/material";
import RadioDinamico from "../../Tools/RadioButton/RadioDinamico";
import { Link } from "react-router-dom";
import CheckGroup from "../../Tools/CheckBox/CheckGroup";



//Aparecera en la segunda alerta dos botones
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function DialogSiniestrosOrdenados(props) {
//PARENT DIALOG
const [open, setOpen] = React.useState(false)

const handleClickOpen = () => { setOpen(true) }
const handleClose = () => {
    setOpen(false);
    setMovimiento1("");
}
//CHILDREN DIALOG
const [open2, setOpen2] = React.useState(false)
const handleClickOpen2 = () => { setOpen2(true) }
const handleClose2 = () => {
    setOpen2(false) // Close the children dialog
    //handleClose() // Close the parent dialog
}

//Lista del menu
const array = [
    { "id": "1", "value": "1", "label": "Código" },
    { "id": "2", "value": "2", "label": "Moneda" },
    { "id": "3", "value": "3", "label": "Fecha" },
    { "id": "4", "value": "4", "label": "Cuenta" },
    { "id": "5", "value": "5", "label": "Póliza" },
    { "id": "6", "value": "6", "label": "Asegurado" },
    { "id": "7", "value": "7", "label": "Importe" },
    { "id": "8", "value": "8", "label": "Naturaleza" },
]
const [movimiento1, setMovimiento1] = React.useState("")
const handleRbChange = (e) => {
    console.log(e.target.value)
    setMovimiento1(e.target.value)
}

const [opcionRB, setOpcionRB] = React.useState({
    listaUno: { movimiento: "", descripcion: "" }
})
React.useEffect(() => {
    if (movimiento1 !== "") {
        let descripcion1 = "";
        switch (movimiento1) {//Este swit permitirá  crear un objeto segun lo que se seleccione en los radios inputs
            case "1": {
                descripcion1 = "Siniestros por fallecimiento"
            };
                break;
            case "2": {
                descripcion1 = "Muerte accidental"
            };
                break;
            case "3": {
                descripcion1 = "Beneficio adicional"
            };
                break;
            default: descripcion1 = "No hay descripcion a esa opción";
        }
        
        setOpcionRB({ ...opcionRB, 
            listaUno: { movimiento: movimiento1, descripcion: descripcion1}
        });
    }
}, [movimiento1]);
     return (
        <div>
            {/* PARENT DIALOG */}
            <div>
                {/*BOTON QUE HACE EL LLAMADO AL DIALOGO PADRE*/}
                <ButtonVino onClick={handleClickOpen} label="Creación Pd Nacional" estatus={props.disabledbtn} />
                {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
                <BootstrapDialog
                    fullWidth={true}
                    onClose={handleClose}
                    aria-labelledby="dialog"
                    open={open}
                >
                    {/*CUERPO DEL DIALOGO*/}
                    {/*TITILO*/}
                    <BootstrapDialogTitle id="dialog" onClose={handleClose} style={{ color: '#761f2a' }}>
                        <h1-dialog-title>CREACIÓN DE TABLA DE PD NACIONAL</h1-dialog-title>
                    </BootstrapDialogTitle>
                    {/*CUERPO DEL DIALOGO*/}
                    {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
                    <DialogContent dividers style={{ minHeight: '300px' }}>
                        {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
            se necesita colocar entre un typogreaphy las veces que des salto de linea 
            o con un br*/}
                        <h2-dialog-description>Seleccione las columnas: </h2-dialog-description>
                        <CheckGroup label="Código" />
                        <CheckGroup label="Moneda" />
                        <CheckGroup label="Fecha" />
                        <CheckGroup label="Cuenta" />
                        <CheckGroup label="Póliza" />
                        <CheckGroup label="Asegurado" />
                        <CheckGroup label="Importe" />
                        <CheckGroup label="Naturaleza" />
                    </DialogContent>
                    {/*es la tercer sección que engloba los botones */}
                    <DialogActions>

                        {/*En esta parte se llena el segundo mensaje que debe de aparecer*/}
                        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                            {/*<Grid item xs={'auto'}>
              <ButtonVino onClick={handleClickOpen2} label={props.firstButton} estatus={props.disabledBtn}/>
              </Grid>*/}
                            <Grid item xs={'auto'}>
                                <ButtonVino onClick={handleClickOpen2} label={"Siguiente"} estatus={movimiento1 === "" ? true : false} />
                            </Grid>
                            <Grid item xs={'auto'}>
                                <ButtonVino onClick={handleClose} label={"Cancelar"} estatus={props.disabledBtn} />
                            </Grid>
                        </Grid>

                    </DialogActions>
                </BootstrapDialog>
            </div>            
        </div>
     );

}