import * as React from "react";
import Grid from "@mui/material/Grid";
import { DATA_PM } from "../../../../utils/constansPM";
import { FormattedMessage } from "react-intl";

export function TabDatosCoberturas(props) {
  const dataPoliza = props.dataPoliza;
  console.log(dataPoliza);
  return (
    <div style={{ padding: "5px", width: "900px" }}>
      {dataPoliza[0]?.COB_idcobertura?.COB_idcobertura ? (
        <table>
          <thead>
            <tr>
              <th align="center">
                <strong>
                  <FormattedMessage
                    id="recepIDCob"
                    defaultMessage="ID Cobertura"
                  />
                </strong>
              </th>
              <th align="center">
                <strong>
                  <FormattedMessage
                    id="globDescri"
                    defaultMessage="Descripción"
                  />
                </strong>
              </th>
              <th align="center">
                <strong>
                  <FormattedMessage
                    id="regisSACob"
                    defaultMessage="S.A Cobertura"
                  />
                </strong>
              </th>
              <th align="center">
                <strong>
                  <FormattedMessage
                    id="regisIDMoCob"
                    defaultMessage="ID Moneda Cobertura"
                  />
                </strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {dataPoliza?.map((row) => (
              <tr key={row?.POL_idpoliza?.POL_idpoliza}>
                <td
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    width: "250px",
                  }}
                >
                  {row?.COB_idcobertura?.COB_idcobertura}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    width: "250px",
                  }}
                >
                  {row?.COB_idcobertura?.COB_acronimo}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    width: "250px",
                  }}
                >
                  {row?.PCP_coberturasumaasegurada}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    width: "250px",
                  }}
                >
                  {row?.MON_idmonedacobertura?.MON_idmoneda}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div style={{ textAlign: "center" }}>
              <strong>
                <FormattedMessage
                  id="recepIDCob"
                  defaultMessage="ID Cobertura"
                />
              </strong>
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_COBERTURA_1]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_COBERTURA_2]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_COBERTURA_3]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_COBERTURA_4]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_COBERTURA_5]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_COBERTURA_6]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_COBERTURA_7]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_COBERTURA_8]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_COBERTURA_9]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_COBERTURA_10]}
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ textAlign: "center" }}>
              <strong>
                <FormattedMessage
                  id="globDescri"
                  defaultMessage="Descripción"
                />
              </strong>
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.COB_DESCRIPCION_1]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.COB_DESCRIPCION_2]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.COB_DESCRIPCION_3]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.COB_DESCRIPCION_4]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.COB_DESCRIPCION_5]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.COB_DESCRIPCION_6]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.COB_DESCRIPCION_7]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.COB_DESCRIPCION_8]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.COB_DESCRIPCION_9]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.COB_DESCRIPCION_10]}
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ textAlign: "center" }}>
              <strong>
                <FormattedMessage
                  id="regisSACob"
                  defaultMessage="S.A Cobertura"
                />
              </strong>
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.SA_COBERTURA_1]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.SA_COBERTURA_2]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.SA_COBERTURA_3]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.SA_COBERTURA_4]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.SA_COBERTURA_5]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.SA_COBERTURA_6]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.SA_COBERTURA_7]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.SA_COBERTURA_8]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.SA_COBERTURA_9]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.SA_COBERTURA_10]}
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ textAlign: "center" }}>
              <strong>
                <FormattedMessage
                  id="regisIDMoCob"
                  defaultMessage="ID Moneda Cobertura"
                />
              </strong>
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.MONEDA_COBERTURA_1]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.MONEDA_COBERTURA_2]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.MONEDA_COBERTURA_3]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.MONEDA_COBERTURA_4]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.MONEDA_COBERTURA_5]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.MONEDA_COBERTURA_6]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.MONEDA_COBERTURA_7]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.MONEDA_COBERTURA_8]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.MONEDA_COBERTURA_9]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.MONEDA_COBERTURA_10]}
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
