import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

export function SelectDictamen(props) {
  // La propiedad props.array permitirá llenar el menu item segun su prop.id y prop.texto
  const array = props.array;
  console.log(array);

  const [selectedValue, setSelectedValue] = React.useState(
    props.value ? props.value : 0
  );

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    props.onChange(event); //  servirá para revisar el cambio del valor del select desde el componente padre
  };
  React.useEffect(() => {
    if (array.length === 1) {
      setSelectedValue(array[0].identificador);
    } else {
      setSelectedValue(props.value || 0);
    }
  }, [array]);

  return (
    <div>
      <Box sx={{ minWidth: 120 }}>
        <FormControl
          fullWidth
          style={{
            position: "sticky",
            marginTop: "16px",
            marginBottom: "8px",
          }}
          //Manejo de errores
          error={props.error}
          disabled={props.disabled}
          readOnly={props.readOnly}
          size={props.size}
        >
          {/*La propiedad props.label permitirá darle un nombre al input*/}
          <InputLabel id={props.inputLabelId} style={{ color: "gray" }}>
            {props.label}
          </InputLabel>
          <Select
            labelId={props.labelId}
            label={props.label}
            color="gray"
            id={props.id} //Esta propiedad permitiá identificar el select, permitiendo saber su valor, etc.
            name={props.name}
            defaultValue={0}
            value={selectedValue}
            onChange={handleChange} // props.selecciona será el nombre del evento que recibirá un evento de tipo OnChange
            sx={props.sx}
            required={props.required}
          >
            <MenuItem value={0} disabled>
              Selecciona una opción
            </MenuItem>
            {/* Este array tiene objetos que entre sus propiedades estarán el identificador 
            y el texto que se quiera mostrar, 
            
            ***IMPORTANTE*** estas propiedades (identificador,texto) no estarán en un principio en 
            el JSON traido de la API, por lo que se debe manipular y agregar estas propiedades 
            a cada uno de los objetos para poder usar este componente */}
            {array.map((element) => {
              return (
                <MenuItem
                  value={element.identificador}
                  name={element.texto}
                  key={element.identificador}
                >
                  {element.texto}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText
            onClick={props.helperOnClick}
            className="custom-helpertext"
            style={{
              position: "absolute",
              right: 0,
              marginTop: "60px",
              cursor: "pointer",
            }}
          >
            {props.FormHelperText}
          </FormHelperText>
        </FormControl>
      </Box>
    </div>
  );
}
