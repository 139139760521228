import { createTheme } from "@mui/material/styles";
import { buttonClasses } from "@mui/material/Button";
import { getApiColor } from "./apiColor";
import { principal,secundario } from "../api/getUmbrales";
/**
 * 
 * @returns Retorna el resultado de la API
 */
async function getColores(){
  try {
    var promise = getApiColor();
    return promise.then((result) => {
      return  JSON.parse(result);
    }).catch((err) => {
      let paleta = {
        action: {
          disabledBackground: "", // don't set the disable background color
          disabled: "#fff", // set the disable foreground color
        },
        primary: {
          main: principal,
          dark: "#a94d52",
          contrastText: "#fff",
        },
        secondary: {
          main: secundario,
          dark: "#304766",
          contrastText: "#fff",
        },
        neutral: {
          main: "#fff",
        },
        three: {
          main: "#DFDADC",
          contrastText: secundario,
        },
        gray: {
          main: "#aaaaaa",
          contrastText: "#fff",
        },
        white: {
          main: "#ffffff",
          dark: "#304766",
          contrastText: "#fff",
        },
  
        icons: {
          main: "#304766",
          dark: principal,
          contrastText: "#fdff",
          light: "#ffff",
          disabled: "black",
        },
      };
      return paleta;
    })
  } catch (e) {
    throw Error(e);
  }
}
/**
 * 
 * @returns Retorna la configuración del theme
 */
const theme = async () =>{
  return createTheme({
    //Opacidad de los botones
    components: {
      MuiButtonBase: {
        styleOverrides: {
          root: {
            [`&.${buttonClasses.disabled}`]: {
              opacity: 0.5,
            },
          },
        },
      },
    },
    palette: await getColores(),
  });
}
export default theme; 