import React from "react";
import { CompWorkflow } from "../../../components/Workflow/CompWorkflow";


export function Workflow() {
  return (
    <div>
  
      <CompWorkflow />
    
    </div>
  );
}
