import React from 'react';
import "./Communication.css";
import Recorrido3 from "../Tools/BreadCrumbs/Recorrido3";
import TablaConsu from "./Elements/TablaConsu";
import ReturnPage from "../Tools/Bottones/ButtonReturn"; 
import { Grid } from '@mui/material';

export function CompComunicacionConsul() {

  return (
  <div className='div-parent'>
      <div className='div-breadcrumbs'> 
      <Recorrido3 name1="Inicio" enlace="/menu" name2="Comunicacion Cliente" name3="Consulta" />
      </div>
      <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                <ReturnPage href='/menu'/>
            </Grid>
      <div className='div-container' >  
      <TablaConsu/>
      </div>
    </div>

  );
}