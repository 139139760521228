/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import "../../dictamen.css";
//Icons
import DialogReaseguro from "../Dialogs/DialogReaseguro";
import IconGenerarCarta from "../../../Tools/Icons/IconGenerarCarta";
import IconTecnico from "../../../Tools/Icons/IconTecnico";
import IconJuridico from "../../../Tools/Icons/IconJuridico";
import IconPolizaOrigen from "../../../Tools/Icons/IconPolizaOrigen";
import PopperPagos from "../Poppers/PopperPagos";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import PopperReservas from "../Poppers/PopperReservas";
//idioma
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { dias_dictamen_compleja, dias_dictamen_especial, formatofecha, umbralATiempo, umbralCancelado, umbralEnProceso } from "../../../../api/getUmbrales";
import { getDataToken } from "../../../../api/dataToken";

export default function TablaDetalleFolio(props) {
  const polizas = props.infoPolizas;
  console.log("POLIZAS",polizas)
  /* Función para obtener el numero de siniestro de cada póliza  */
  const [dataPolizas, setDataPolizas] = useState(polizas)
  let momentbd = require('moment-business-days');


  useEffect(() => {
    setDataPolizas(polizas)
  }, [props.infoPolizas]);


  return (
    <div>
      <Paper style={{ width: "100%" }}>
        <TableContainer
          sx={{ maxHeight: 440, showEmptyDataSourceMessage: true }}
          style={{ position: "sticky" }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              "& .MuiTableRow-root:hover": {
                backgroundColor: "#ededed",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="center"
                  style={{ width: "30px" }}
                ></StyledTableCell>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                <StyledTableCell align="center">
                  <FormattedMessage id="globDias" defaultMessage="Días" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Grid container alignItems="center">
                    <Grid item xs={9}>
                      <FormattedMessage
                        id="globPoliza"
                        defaultMessage="Póliza"
                      />
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Grid container alignItems="center">
                    <Grid item xs={10}>
                      <FormattedMessage
                        id="globNumSini"
                        defaultMessage="Número de siniestro"
                      />{" "}
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="globFechEm"
                    defaultMessage="Fecha de emisión"
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="globProdut" defaultMessage="Producto" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="globMoneda" defaultMessage="Moneda" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="globEstatu" defaultMessage="Estatus" />
                </StyledTableCell>
                <StyledTableCell align="center" colSpan={8}>
                  <FormattedMessage
                    id="dictaAcciones"
                    defaultMessage="Acciones"
                  />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {polizas.map((row) => {
                /*Estas validaciones sirven para editar la tabla en columna casos, agregando el 
                  icono de color según corresponda*/
                  console.log(row)
                //Programación de días

                const fechaAviso = moment(row?.RECT_idfolio?.RECT_fechaaviso, 'YYYY-MM-DD'); // Supongamos que tienes una fecha en formato 'YYYY-MM-DD'
                let diasTranscurridos = momentbd().businessDiff(fechaAviso)

                //Color 
                let color = "red";

                if (diasTranscurridos > dias_dictamen_compleja || diasTranscurridos > dias_dictamen_especial){
                  color = umbralCancelado
                } else if (diasTranscurridos === dias_dictamen_compleja || diasTranscurridos === dias_dictamen_especial ){
                    color = umbralEnProceso
                } else if (diasTranscurridos < dias_dictamen_compleja || diasTranscurridos < dias_dictamen_especial ){
                    color = umbralATiempo
                } 
                return (
                  <TableRow key={row.id}>
                    <StyledTableCell align="center">
                      <CircleRoundedIcon style={{ color: color }} />
                    </StyledTableCell>
                    <StyledTableCell align="center">{diasTranscurridos}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.POL_idpoliza?.POL_numpoliza}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.SIN_idsiniestro?.SIN_numerosiniestro}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {moment(row?.POL_idpoliza?.POL_polizafecemisión).format(formatofecha)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.POL_idpoliza?.PRO_idproductos?.PRO_descripcion}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {/* {row?.POL_idpoliza?.MON_idmonedas?.MON_abreviatura} */}
                      {"MXN"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {/* {row?.EST_idestatuspolizatramite?.EST_estatuspolizatramite} */}
                      {"VIGENTE"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ paddingLeft: 4, paddingRight: 4 }}
                    >
                      <IconGenerarCarta
                        tooltipTitle={"Generar cartas"}
                        to="/dictamen/detalle_folio/generar_carta"
                        state={{ row, polizas }}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ paddingLeft: 4, paddingRight: 4 }}
                    >
                      <DialogReaseguro 
                        idpoliza = {row?.POL_idpoliza?.POL_idpoliza}  
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ paddingLeft: 4, paddingRight: 4 }}
                    >
                      <IconTecnico
                        tooltipTitle={"Dictamen Técnico"}
                        to="/dictamen/detalle_folio/tecnico"
                        state={{ row: polizas }}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ paddingLeft: 4, paddingRight: 4 }}
                    >
                      <IconJuridico
                        tooltipTitle={"Dictamen Jurídico"}
                        to="/dictamen/detalle_folio/juridico"
                        state={{ row: polizas }}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ paddingLeft: 4, paddingRight: 4 }}
                    >
                      <IconPolizaOrigen
                        tooltipTitle={"Póliza origen"}
                        to="/dictamen/detalle_folio/poliza_origen"
                        state={{ row }}
                        //disable={true}
                        //poliza={row?.POL_idpoliza?.POL_numpoliza}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ paddingLeft: 4, paddingRight: 4 }}
                    >
                      <PopperReservas
                        tooltipTitle={"Reservas"}
                        poliza={row?.POL_idpoliza?.POL_numpoliza}
                        detalleFolio={polizas}
                        row={row}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ paddingLeft: 4, paddingRight: 4 }}
                    >
                      <PopperPagos
                        tooltipTitle={"Pagos"}
                        poliza={row.poliza}
                        idPoliza={row?.POL_idpoliza.POL_idpoliza}
                        detalleFolio={polizas}
                        row={row}
                      />
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
