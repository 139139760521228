import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


export function SelectsDoc(props) {
  const [op, setop] = React.useState('');

  const handleChange = (event) => {
    setop(event.target.value);
  };
  



  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 300,maxWidth:350 }}>
        <InputLabel id="demo-simple-select-helper-label">Seleccion una opción</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={op}
          label="Seleccion una opción"
          onChange={handleChange}>
          <MenuItem value={1} onClick={props.onClick1} >Fallecimiento</MenuItem>
          <MenuItem value={2} onClick={props.onClick2}>Beneficios en Vida</MenuItem>
          <MenuItem value={3} onClick={props.onClick3}>Vencimientos</MenuItem>
          <MenuItem value={5} onClick={props.onClick3}>Retiro y Rescates</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
