import React, {useEffect,useState} from "react";
import { LinearProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import { getToken } from "../../../api/token";
import { BASE_API } from "../../../utils/constans";
function BarraPro(){
    // Obtencion del id desde el apuntado de la tabla 
    const locationbar = useLocation();
    const barinfo = locationbar.state.folios;
    // console.log("barradatos", barinfo);

// Metodo de optencion del api para mostrar 
const token = getToken();
const [bardatos, setBardatos] = useState([]);

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${BASE_API}wktramite/get_listado/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error al obtener datos');
      }

      const data = await response.json();
      // console.log('Datos desde la API:', data[0].POL_idpoliza?.POL_idpoliza);

      // Verifica el valor de POL_idpoliza
      const idFolio = barinfo.POL_idpoliza?.POL_idpoliza;
    //  console.log('ID Folio a filtrar:', barinfo.POL_idpoliza?.POL_idpoliza);


      // Filtra los datos basados en POL_idpoliza
      const datosFiltrados = data.filter((dato) => dato.POL_idpoliza?.POL_idpoliza === idFolio);
      // console.log('Datos filtrados:', datosFiltrados);
    
      // Establecer datos filtrados en el estado
      setBardatos(datosFiltrados);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  fetchData();
}, [token, barinfo.POL_idpoliza]);

// console.log('Datos finales para barra:', bardatos.map(item => item?.WTM_idwftareasxmodulos?.TARE_idtarea?.TARE_ponderación));

const ponderaciones = bardatos.map(item => item?.WTM_idwftareasxmodulos?.TARE_idtarea?.TARE_ponderación);

// Calcular la suma de las ponderaciones
const sumaPonderaciones = ponderaciones.reduce((total, ponderacion) => total + ponderacion, 0);

// Calcular el progreso como el promedio de las ponderaciones
const nuevoProgreso =
ponderaciones.length > 0 ? (sumaPonderaciones / ponderaciones.length).toFixed(1) : 0;

const [progress, setProgress] = useState(nuevoProgreso);


React.useEffect(() => {
  // Actualizar el estado si initialValue cambia
  setProgress(nuevoProgreso);
}, [nuevoProgreso]);

// Actualizar el estado del progreso


      return (
        <div style={{ width: '80%', margin: '0 auto', textAlign: 'center' }}>
          
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              height: '20px', // Altura de la barra
              borderRadius: '10px', // Bordes redondeados
              background: '#f1f1f1', // Fondo de la barra
            }}
          />
          <div
            style={{
              marginTop: '10px',
              fontSize: '18px', // Tamaño de fuente del porcentaje
              fontWeight: 'bold', // Texto en negrita
              color: '#333', // Color del texto
            }}
          >
            {`${progress}%`}
          </div>
        </div>
      );
    }
export default BarraPro;