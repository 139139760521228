import * as React from "react";
import "../../dictamen.css";
import { Grid } from "@mui/material";
import CardExpedienteReclamacion from "../Cards/CardExpedienteReclamacion";

export default function TableExpedienteReclamacion(props) {
  const polizas = props.polizas;
  return (
    <div>
      {polizas.map((poliza) => (
        <Grid
          container
          spacing={3}
          key={poliza.PXFS_idfoliopolizaxsiniestropoliza}
        >
          <Grid item xs={12}>
            <CardExpedienteReclamacion
              poliza={poliza}
              idFolio={props.idFolio}
            />
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
