import React, { useState } from "react";
import ButtonVino from "../../Tools/Bottones/ButtonVino";

const PDFViewer = ({ pdfArray }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;
      return nextIndex < pdfArray.length ? nextIndex : prevIndex;
    });
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => {
      const previousIndex = prevIndex - 1;
      return previousIndex >= 0 ? previousIndex : prevIndex;
    });
  };

  return (
    <div>
      {pdfArray.map((blob, index) => (
        <div
          key={index}
          style={{ display: index === currentIndex ? "block" : "none" }}
        >
          <object
            data={window.URL.createObjectURL(blob)}
            type="application/pdf"
            width="100%"
            height="500px"
          >
            <p>El visor de PDF no está disponible en tu navegador.</p>
          </object>
        </div>
      ))}
      <div
        style={{
          display: "block",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <ButtonVino
          label={"Anterior"}
          onClick={handlePrevious}
          estatus={currentIndex === 0}
        />
        <ButtonVino
          label={"Siguiente"}
          onClick={handleNext}
          estatus={currentIndex === pdfArray.length - 1}
        />
      </div>
    </div>
  );
};

export default PDFViewer;
