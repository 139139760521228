import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ButtonVino from '../../Tools/Bottones/ButtonVino';
import { Grid } from "@mui/material";
import RadioDinamico from "../../Tools/RadioButton/RadioDinamico";
import { Link } from "react-router-dom";
import {RESERVAS_ACTUARIALES} from "../../../utils/constanstoken"

//Aparecera en la segunda alerta dos botones
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


export default function DialogReservasActuariales(props) {

    const infoRow = props.infoRow


    //PARENT DIALOG
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => { setOpen(true) }
    const handleClose = () => {
        setOpen(false);
        setMovimiento1("");
    }
    //CHILDREN DIALOG
    const [open2, setOpen2] = React.useState(false)
    const handleClickOpen2 = () => { setOpen2(true) }
    const handleClose2 = () => {
        setOpen2(false) // Close the children dialog
        setMovimiento2("");

        //handleClose() // Close the parent dialog
    }

    //Array 1: Primera lista
    const array = [
        { "id": "1", "value": "1", "label": "Ajustes a reserva pólizas exentas por invalidez" },
        { "id": "2", "value": "2", "label": "Ajustes pólizas exentas por fallecimiento" },
        { "id": "3", "value": "3", "label": "Ajustes pólizas exentas por desempleo" },
        { "id": "4", "value": "4", "label": "Reserva de siniestros ocurridos no reportados IBNR" },
    ]
    const [movimiento1, setMovimiento1] = React.useState("")
    const handleRbChange = (e) => {
        console.log(e.target.value)
        setMovimiento1(e.target.value)
    }


    //Arrays 2: Segunda lista se tienen 1 por cada opción elegida

    //array RESERVA POR INVALIDEZ : opcion 1 
    const arrayInvalidez = [
        { "id": "1", "value": "1", "label": "Incremento" },
        { "id": "2", "value": "2", "label": "Decremento" },
    ];
    //array POR FALLECIMIENTO : opcion 2
    const arrayFallecimiento = [
        { "id": "1", "value": "1", "label": "Incremento" },
        { "id": "2", "value": "2", "label": "Decremento" },
    ];
    //array POR DESEMPLEO : opcion 3 
    const arrayDesempleo = [
        { "id": "1", "value": "1", "label": "Incremento" },
        { "id": "2", "value": "2", "label": "Decremento" },
    ];


    const [movimiento2, setMovimiento2] = React.useState("")
    const handleRbChange2 = (e) => {
        setMovimiento2(e.target.value)
    }

    const [opcionRB, setOpcionRB] = React.useState({
        listaUno: { movimiento: "", descripcion: "" },
        listaDos: { movimiento: "", descripcion: "" }
    })
    React.useEffect(() => {
        if (movimiento1 !== "" && movimiento2 !== "") {
            let descripcion1 = "";
            let descripcion2 = "";
            switch (movimiento1) {//Este switch permitirá  crear un objeto segun lo que se seleccione en los radios inputs
                case "1": {
                    descripcion1 = "Ajustes a reserva pólizas exentas por invalidez"
                    descripcion2 = arrayInvalidez.find(e => e.id === movimiento2)?.label
                };
                    break;
                case "2": {
                    descripcion1 = "Ajustes pólizas exentas por fallecimiento"
                    descripcion2 = arrayFallecimiento.find(e => e.id === movimiento2)?.label
                };
                    break;
                case "3": {
                    descripcion1 = "Ajustes pólizas exentas por desempleo"
                    descripcion2 = arrayDesempleo.find(e => e.id === movimiento2)?.label;
                };
                //     break;
                // case "4": {
                //     descripcion1 = "Reserva de siniestros ocurridos no reportados IBNR"
                // };
                    break;
                default: descripcion1 = "No hay descripcion a esa opción";
            }
            
            setOpcionRB({ ...opcionRB, 
                listaUno: { movimiento: movimiento1, descripcion: descripcion1}, 
                listaDos: { movimiento: movimiento2, descripcion: descripcion2} 
            });
        }
    }, [movimiento1,movimiento2]);

    const handleClick = (event) => {
        setOpen(false) // Close the children dialog
        setOpen2(false) // Close the children dialog
        props.onClick(event) //  servirá para revisar el cambio del valor del select desde el componente padre
    };

    return (
        <div>
            {/* PARENT DIALOG */}
            <div>
                {/*BOTON QUE HACE EL LLAMADO AL DIALOGO PADRE*/}
                <ButtonVino onClick={handleClickOpen} label={RESERVAS_ACTUARIALES.descripcion} estatus={props.disabledbtn} />
                {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
                <BootstrapDialog
                    fullWidth={true}
                    onClose={handleClose}
                    aria-labelledby="dialog"
                    open={open}
                >
                    {/*CUERPO DEL DIALOGO*/}
                    {/*TITILO*/}
                    <BootstrapDialogTitle id="dialog" onClose={handleClose} style={{ color: '#761f2a' }}>
                        <h1-dialog-title>{RESERVAS_ACTUARIALES.descripcion}</h1-dialog-title>
                    </BootstrapDialogTitle>
                    {/*CUERPO DEL DIALOGO*/}
                    {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
                    <DialogContent dividers style={{ minHeight: '300px' }}>
                        {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
            se necesita colocar entre un typogreaphy las veces que des salto de linea 
            o con un br*/}
                        <h2-dialog-description>Seleccione una opción: </h2-dialog-description>
                        <RadioDinamico array={array} onChange={handleRbChange} />
                    </DialogContent>
                    {/*es la tercer sección que engloba los botones */}
                    <DialogActions>

                        {/*En esta parte se llena el segundo mensaje que debe de aparecer*/}
                        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                            {/*<Grid item xs={'auto'}>
              <ButtonVino onClick={handleClickOpen2} label={props.firstButton} estatus={props.disabledBtn}/>
              </Grid>*/}
                            <Grid item xs={'auto'}>
                                <ButtonVino onClick={handleClickOpen2} label={"Siguiente"} estatus={movimiento1 === "" ? true : false} />
                            </Grid>
                            <Grid item xs={'auto'}>
                                <ButtonVino onClick={handleClose} label={"Cancelar"} estatus={props.disabledBtn} />
                            </Grid>
                        </Grid>

                    </DialogActions>
                </BootstrapDialog>
            </div>
            {/* CHILDREN DIALOG */}
            <div>
                {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
                <BootstrapDialog
                    fullWidth={true}
                    onClose={handleClose2}
                    aria-labelledby="dialog"
                    open={open2}
                >
                    {/*CUERPO DEL DIALOGO*/}
                    {/*TITULO*/}
                    <BootstrapDialogTitle id="dialog" onClose={handleClose2} style={{ color: '#761f2a' }}>
                        <h1-dialog-title>RESERVAS ACTUARIALES</h1-dialog-title>
                    </BootstrapDialogTitle>
                    {/*CUERPO DEL DIALOGO*/}
                    {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
                    <DialogContent dividers style={{ minHeight: '300px' }}>
                        {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
              se necesita colocar entre un typogreaphy las veces que des salto de linea 
              o con un br*/}
                        <RadioDinamico array={
                            movimiento1 === "1" ? arrayInvalidez : (movimiento1 === "2" ? arrayFallecimiento : arrayDesempleo)
                        } onChange={handleRbChange2}
                        />
                    </DialogContent>
                    {/*es la tercer sección que engloba los botones */}
                    <DialogActions>
                        <Grid container justifyContent="center" spacing={2}>
                            {/* <Grid item><Link to="/dictamen/detalle_folio/reservas/registro" state={{ infoRow, data:{opcionRB} }}><ButtonVino estatus={movimiento2 === "" ? true : false} label={"Siguiente"} /></Link></Grid> */}
                            <Grid item><ButtonVino onClick={handleClick} label={"Aceptar"} /></Grid>
                            <Grid item><ButtonVino onClick={handleClose2} label={"Cancelar"} /></Grid>
                        </Grid>
                    </DialogActions>
                </BootstrapDialog>
            </div>
        </div>
    );
}
