import React from "react";
import CompDictamenDetalleFolioX from "../../../components/Dictamen/CompDetalleFolioX";



export function DictamenFolioX() {
    return (
        <div>
           
            <CompDictamenDetalleFolioX />
            
        </div>
    );
}