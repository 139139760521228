import React from "react";
import { CompInicio } from "../../../components/Inicio/CompInicio";
import NavUsuario from "../../../components/Tools/Header/NavUsuario";
import Footer from "../../../components/Tools/Footer/Footer";

export function Inicio() {
  return (
    <div>
      
      <CompInicio />
   
    </div>
  );
}
