import * as React from "react";
import PropTypes from "prop-types";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from '@material-ui/core/Grid'
import Btverreport from "../../../Tools/Bottones/BTAgregarDocumento";
import TXTField from "../../../Tools/TextField/TextField";
import moment from "moment/moment";
//idioma
import { FormattedMessage } from "react-intl";
import DatePicker2 from "../../../Tools/DatePicker/DatePicker2";
import { calcularImporteBeneficiario, calcularPorcentajeBeneficiario, calculateAge, parseNumberWithTwoDecimals, validarNumero, validateNumberAndDecimal } from "../../../../utils/functions";
import { Select2 } from "../../../Tools/Selectss/Select2";
import { getDataToken, postDataToken, putDataToken } from "../../../../api/dataToken";
import { useEffect, useState } from "react";
import RadioDinamico from "../../../Tools/RadioButton/RadioDinamico";
import DialogFeedback from "../../../Tools/Dialog/DialogFeedback";
import { PERR_BENEFICIARIO } from "../../../../utils/constanstoken";


//Solo aparesera un alerta con un solo boton
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

//FECHA ACTUAL
let today = new Date();
//EDAD ACTUAL 
function calcularEdad(fecha) {
  var anios = today.getFullYear() - fecha.getFullYear()
  //si no ha cumplido años le restamos el año por cumplir 
  if (today.getMonth() < fecha.getMonth() || today.getDate() < fecha.getDate()) {
    anios--
  }
  return anios
}



export default function DialogEditarBene(props) {
  const beneficiarios = props.beneficiarios
  const cobertura = props.cobertura;
  const idBenexpoliza = props.idBene
  const idFolio = props.idFolio;
  const idPoliza = props.idPoliza;
  console.log("cobertura", cobertura?.PCP_coberturasumaasegurada)
  const [open, setOpen] = React.useState(false);
  const [totalPorDisp, setTotalPorDisp] = React.useState(100)
  const [totalImportDisp, setTotalImportDisp] = React.useState(0)
  const [beneficiario, setBeneficiario] = React.useState("")
  const [importe, setImporte] = useState(0);
  const [porcentaje, setPorcentaje] = useState(0);
  const [showMsg, setShowMsg] = React.useState(false);
  const [btnGuardar, setBtnGuardar] = React.useState(false);
  const [title, setTitle] = React.useState("");


  //FECHA DE NACIEMIENTO DEL BENEFICIARIO CON FORMATO DD-MM-YYYY
  const [fechaNac, setFechaNac] = React.useState(null)
  //validacion de edad actual
  const [txtEdadActValue, setTxtEdadActValue] = React.useState(0)

  const handleDataChange = (newData) => {
    // setTxtEdadActValue(newData)
    let edadActual = calcularEdad(newData.$d);
    setTxtEdadActValue(edadActual);
    setFechaNac(moment(newData.$d, "YYYY-MM-DD").format("YYYY-MM-DD"));
    validarEdadTramite(edadActual, 2);
  }

  // validacion de edad al trámite
  const [edadTraError, setEdadTraError] = React.useState(false)
  const [helperEdadTra, setHelperEdadTra] = React.useState(false)

  function validarEdadTramite(e, caso) {

    if (caso === 1) {
      let edad = e.target.value;
      if (edad > txtEdadActValue) {
        setEdadTraError(true)
        setHelperEdadTra('La edad ingresada es mayor a la edad actual.')
      } else {
        setEdadTraError(false)
        setHelperEdadTra('')
      }
    } else {
      if (txtEdadActValue > e) {
        setEdadTraError(true)
        setHelperEdadTra('La edad al trámite es mayor a la edad actual.')
      } else {
        setEdadTraError(false)
        setHelperEdadTra('')
      }
    }

  }

  const handleClickOpen = () => {
    setOpen(true);
    agregarBene();
  };
  const handleClose = () => {
    setOpen(false);
    setTextfieldValue("");
    setTxtPorImp("Porcentaje");
    setValueEdit(1);
    setTextfieldValue(!beneficiario?.PXP_porcentajebeneficiario ? 0 : beneficiario?.PXP_porcentajebeneficiario)
    setErrorMessage(``)
    setError(false)
    setFechaNac(null)
    setTxtEdadActValue(0)
    setPorcentaje(0)
    setImporte(0)
  };



  const [errorMessage, setErrorMessage] = React.useState("")
  const [error, setError] = React.useState(false)

  /*** Funcionalidad para elegir el parentesco y banco ***/
  const [listaParentesco, setListaParentesco] = useState([])
  const [listaBancos, setListaBancos] = useState([])
  const [banco, setBanco] = useState({
    id : 0, 
    descrition: "",
  })

  const getDataParentesco = async () => {
    try {
      let response = await getDataToken(`parentesco/get_listado/`);
      let response2 = await getDataToken(`bancos/get_listado/`);
      if (response.length > 0) {
        let copiaApiDoc = response.map(element => {
          return { ...element, identificador: element.PAR_idparentesco, texto: element.PAR_parentesco };
        });
        setListaParentesco(copiaApiDoc);
      }
      if (response2.length > 0) {
        let copiaApiDoc = response2.map(element => {
          return { ...element, identificador: element.BNC_idbanco, texto: element.BNC_nombrecorto };
        });
        setListaBancos(copiaApiDoc);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  useEffect(() => {
    getDataParentesco();
    agregarBene();
  }, []);

  //*** Funcionalidad para importe o porcentaje ****/
  const [txtPorImp, setTxtPorImp] = React.useState("Porcentaje");
  const [showTxtIcon, setShowTxtIcon] = React.useState(true);
  const [errorMessage2, setErrorMessage2] = React.useState("");
  const [value, setValue] = React.useState("");
  const [error2, setError2] = React.useState(false);
  //Estados para reaseguradora a editar
  const [rowData, setRowData] = React.useState("");
  const [valueEdit, setValueEdit] = React.useState(1);

  //Radio button
  const arrayTipoImporte = [
    { id: 1, value: "1", label: "Porcentaje" },
    { id: 2, value: "2", label: "Importe" },
  ];
  //Funciones
  const editarBene = () => {
    let totalPorDisponible = 100;
    let porcentajeAsignado = 0;

    let totalImportDisponible = cobertura?.PCP_coberturasumaasegurada;
    let importeAsignado = 0;

    if (beneficiarios && beneficiarios.length > 0) {
      beneficiarios.forEach((beneficiario, index) => {
        //Validamos que el beneficiario sea diferente al seleccionado, para no sumar su porcentaje ya asignado
        if (beneficiario?.PXP_idpersonaxpoliza == idBenexpoliza) {
          setBeneficiario(beneficiario)
          // setTxtEdadActValue(beneficiario.edadAct)
          setFechaNac(moment(beneficiario?.PER_idpersona?.PER_fechanacimiento, "YYYY-MM-DD").format("YYYY-MM-DD"))
          setTxtEdadActValue(calculateAge(today.toISOString(), beneficiario?.PER_idpersona?.PER_fechanacimiento));
        } else {
          porcentajeAsignado += (!beneficiario?.PXP_porcentajebeneficiario ? 0 : beneficiario?.PXP_porcentajebeneficiario);
          importeAsignado += (!beneficiario?.PXP_importebeneficiario ? 0 : beneficiario?.PXP_importebeneficiario)
        }
      })
      totalPorDisponible -= porcentajeAsignado
      setTotalPorDisp(totalPorDisponible);
      totalImportDisponible -= importeAsignado
      setTotalImportDisp(totalImportDisponible);
    } else {
      setTotalPorDisp(totalPorDisponible);
      setTotalImportDisp(totalImportDisponible);
    }



  }
  useEffect(() => {
    console.log("Suma asegurada", cobertura?.PCP_coberturasumaasegurada)

    if (valueEdit == 1) {
      //Validamos si hay cifra en porcentaje
      if (beneficiario?.PXP_porcentajebeneficiario) {
        setTextfieldValue(beneficiario.PXP_porcentajebeneficiario)//Se asigna el valor del porcentaje al textfield
      } else { //Si no, validamos si hay cifras en el importe
        if (beneficiario?.PXP_importebeneficiario) { // si las hay, calculamos el porcentaje basado al importe 
          let importe = beneficiario?.PXP_importebeneficiario
          let porcentaje = calcularPorcentajeBeneficiario(cobertura?.PCP_coberturasumaasegurada, importe)
          setTextfieldValue(porcentaje)
        } else {//Si no hay cifras en porcentaje ni en importe, asignamos 0
          setTextfieldValue(0);
        }
      }
    }
    else {
      //Validamos si hay cifra en importe
      if (beneficiario.PXP_importebeneficiario) {
        setTextfieldValue(beneficiario.PXP_importebeneficiario)//Se asigna el valor del importe al textfield
      } else {//Si no, validamos si hay cifras en el porcentaje
        console.log("% bene", beneficiario.PXP_porcentajebeneficiario)
        if (beneficiario.PXP_porcentajebeneficiario) { // si las hay, calculamos el importe basado al porcentaje 
          let porcentaje = beneficiario?.PXP_porcentajebeneficiario
          console.log("porcentaje", porcentaje)
          let importe = calcularImporteBeneficiario(cobertura?.PCP_coberturasumaasegurada, porcentaje)
          setTextfieldValue(importe)
        } else {//Si no hay cifras en porcentaje ni en importe, asignamos 0
          setTextfieldValue(0)
        }
      }
    }

    //Ademas de cambiar los valores del txtfield, lo haremos en los estados importe y porcentaje
    //Validamos si hay cifra en porcentaje
    if (beneficiario?.PXP_porcentajebeneficiario) {
      setPorcentaje(beneficiario.PXP_porcentajebeneficiario)//Se asigna el valor del porcentaje al estado
    } else { //Si no, validamos si hay cifras en el importe
      if (beneficiario?.PXP_importebeneficiario) { // si las hay, calculamos el porcentaje basado al importe 
        let importe = beneficiario?.PXP_importebeneficiario
        let porcentaje = calcularPorcentajeBeneficiario(cobertura?.PCP_coberturasumaasegurada, importe)
        if (porcentaje) {// si la operacion es correcta, asignamos el porcentaje
          setPorcentaje(porcentaje);
        }
      }
    }
    //Validamos si hay cifra en importe
    if (beneficiario?.PXP_importebeneficiario) {
      setImporte(beneficiario.PXP_importebeneficiario)//Se asigna el valor del importe al estado
    } else { //Si no, validamos si hay cifras en el porcentaje
      if (beneficiario?.PXP_porcentajebeneficiario) { // si las hay, calculamos el porcentaje basado al importe 
        let porcentaje = beneficiario?.PXP_porcentajebeneficiario
        let importe = calcularImporteBeneficiario(cobertura?.PCP_coberturasumaasegurada, porcentaje)
        if (importe) {// si la operacion es correcta, asignamos el importe
          setImporte(importe);
        }
      }
    }
  }, [cobertura, valueEdit, beneficiario]);
  //Funcionalidad para agregar el porcentaje máximo restante
  const [textfieldValue, setTextfieldValue] = React.useState("")
  const handleChange = (event, tipo) => {

    setTextfieldValue(validateNumberAndDecimal(event.target.value));
    if (tipo == 1) {
      let porcentajeIngresado = event.target.value
      if (porcentajeIngresado > totalPorDisp) {
        setErrorMessage(`La cantidad ingresada es superior al límite (${totalPorDisp}%)`)
        setError(true)
        setBtnGuardar(true)
      }
      else {
        setErrorMessage(``)
        setError(false)
        setBtnGuardar(false)

        setPorcentaje(porcentajeIngresado)
        //Calculamos el valor del importe nuevo
        let importe = calcularImporteBeneficiario(cobertura.PCP_coberturasumaasegurada, porcentajeIngresado)
        if (importe)
          setImporte(importe)
      }
    } else {
      let importeIngresado = event.target.value
      if (importeIngresado > totalImportDisp) {
        setErrorMessage(`La cantidad ingresada es superior al límite (${totalImportDisp})`)
        setError(true)
      }
      else {
        setErrorMessage(``)
        setError(false)
        setImporte(importeIngresado)
        //Calculamos el valor del porcentaje nuevo
        let porcentaje = calcularPorcentajeBeneficiario(cobertura.PCP_coberturasumaasegurada, importeIngresado)
        if (porcentaje)
          setImporte(porcentaje)
      }
    }

  };
  const asignarMaximo = () => {
    // Modificar el valor del textfield aquí, asignando el máximo segun el tipo (porcentaje o importe)
    if (valueEdit == 1) {
      setTextfieldValue(totalPorDisp);
      setPorcentaje(totalPorDisp)
      setErrorMessage(``)
      setError(false)
    } else {
      setTextfieldValue(totalImportDisp);
      setImporte(totalImportDisp);
      setErrorMessage(``)
      setError(false)
    }
  };
  //Funcion para detectar el cambio en el radio button
  const rbChange = (tipo) => {
    setValue("");
    setTextfieldValue("")
    setErrorMessage(``);
    setError2(false);
    if (tipo == 1) {
      setTxtPorImp("Porcentaje");
      setValueEdit(1);
      setShowTxtIcon(true);
      setTextfieldValue(!beneficiario?.PXP_porcentajebeneficiario ? 0 : beneficiario?.PXP_porcentajebeneficiario);

    } else {
      setTxtPorImp("Importe");
      setValueEdit(2);
      setShowTxtIcon(false);
      setTextfieldValue(!beneficiario?.PXP_importebeneficiario ? 0 : beneficiario?.PXP_importebeneficiario)

    }
  };

  const agregarBene = async () => {
    let porcentajeAsignado = await validarPorcentajeDisponible();

    if (porcentajeAsignado == 100) {
      console.log("Alcanzó el límite de porcentaje asignado");
      setTitle(
        <FormattedMessage
          id="dictLimPorc"
          defaultMessage="Límite de porcentaje alcanzado"
        />
      );
      setShowMsg(true);
    } else {


      console.log("Hay " + porcentajeAsignado + " % asignado");
      setTitle(
        <FormattedMessage
          id="dictAgrBen"
          defaultMessage="Agregar beneficiario"
        />
      );
      editarBene();
      setShowMsg(false);
    }
    console.log("Total de porcentaje disponible " + (porcentajeAsignado - parseFloat(cobertura.PCP_coberturasumaasegurada)) + "%");
  };

  async function validarPorcentajeDisponible() {
    //Primer paso: obtenemos el porcentaje del campo PXP_porcentajebeneficiario y PXP_importebeneficiario, utilizando las funciones para convertir a pocentaje
    let porcentajeAsignado = 0;

    try {
      let response = await getDataToken(`personasxpoliza/get_poliza/${idPoliza}/`)
      if (response) {
        /** Buscar los beneficiarios de la póliza */
        const beneficiarios = response.filter((persona) => persona?.PER_idpersona?.PERR_idrol?.PERR_idrol == PERR_BENEFICIARIO.id);

        if (beneficiarios.length > 0) {
          beneficiarios.forEach((beneficiario) => {

            //Validamos si hay cifra en porcentaje
            if (beneficiario?.PXP_porcentajebeneficiario) {
              porcentajeAsignado += (beneficiario.PXP_porcentajebeneficiario)//Se asigna el valor del porcentaje a la variable acumuladora
            } else { //Si no, validamos si hay cifras en el importe
              if (beneficiario?.PXP_importebeneficiario) { // si las hay, calculamos el porcentaje basado al importe 
                let importe = beneficiario?.PXP_importebeneficiario
                let porcentaje = calcularPorcentajeBeneficiario(cobertura?.PCP_coberturasumaasegurada, importe)
                if (porcentaje) {// si la operacion es correcta, sumamos el porcentaje
                  porcentajeAsignado += porcentaje;
                }
              }
            }
          });
        }
        return porcentajeAsignado;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }



  }

  //GUARDAR DATOS BENEFICIARIO 
  const gardarDatosBeneficiario = async (jsonBene) => {
    try {
      let result = await postDataToken(`personas/get_listado/`, jsonBene);
      if (result) {
        let idBene = result.PER_idpersona;
        let jsonBene = {

          PXP_porcentajebeneficiario: porcentaje,
          PXP_importebeneficiario: importe,
          PER_idpersona: idBene,
          POL_idpoliza: idPoliza,

        }
        let result2 = await postDataToken(`personasxpoliza/get_listado/`, jsonBene);
        if (result2) {
          props.response(true);
          setTituloFeedback("Datos guardados correctamente")
          setDescripFeedback("Los datos del beneficiario fueron registrados correctamente.")
          setMostrarFeedback(true);
        }

      } else {
        props.response(true);
        setTituloFeedback("Falló al actualizar")
        setDescripFeedback("Algo ha salido mal, comuníquese con el encargado de TI.")
        setMostrarFeedback(true);
      }
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }

  /** Estados y funcion para dialogo feedback al guardar */
  const [tituloFeedback, setTituloFeedback] = useState("")
  const [descripFeedback, setDescripFeedback] = useState("")
  const [mostrarFeedback, setMostrarFeedback] = useState(false)

  const onChangeBanco = ({ target }) => {
    let valor = target.value.trim();
    if (valor.length >= 3) {
      let clabe = valor.substring(0, 3);
      let banco = listaBancos.find(banco => banco.BNC_codigo === clabe)
      if (banco) {
        setBanco({
          id: banco.BNC_idbanco,
         descrition: banco.BNC_nombrecorto,
        })
      } else {
        setBanco({
          id : 0, 
          descrition: "",
        })
      }
    }
  }


  return (
    <>
      <div>
        {/*BOTON QUE HACE EL LLAMADO AL DIALOGO*/}
        {/*BOTON QUE HACE EL LLAMADO AL DIALOGO*/}
        {/*Este boton aparecera el js en donde se necesite de la alerta*/}
        <Btverreport
          onClick={handleClickOpen}
          label={
            <FormattedMessage
              id="dictAgreBe"
              defaultMessage="Agregar beneficiarios"
            />
          }
        />
        {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
        <BootstrapDialog

          onClose={handleClose}
          aria-labelledby="dialog"
          open={open}
        >
          {/*CUERPO DEL DIALOGO*/}
          {/*TITILO*/}
          <BootstrapDialogTitle
            id="dialog"
            onClose={handleClose}
            style={{ color: "primary" }}
          >
            <h1-dialog-title>{title}</h1-dialog-title>
          </BootstrapDialogTitle>
          {/*CUERPO DEL DIALOGO*/}
          {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
          <form onSubmit={async (e) => {
            try {
              e.preventDefault();
              const {
                //Datos del beneficiario 
                txtNombre,
                txtApePat,
                txtApeMat,
                txtParentesco,
                txtBanco,
                txtCuentaBanc,
                txtClabe,
                txtRazonSocial,
                txtEdadTramite
              } = e.target.elements;

              let jsonBene = {
                PERR_idrol: PERR_BENEFICIARIO.id,
                PER_nombre: txtNombre.value,
                PER_apellidopaterno: txtApePat.value,
                PER_apellidomaterno: txtApeMat.value,
                PER_fechanacimiento: fechaNac,
                PAR_idparentesco: txtParentesco.value == 0 ? null : txtParentesco.value,
                PER_numerocuentabancaria: txtCuentaBanc.value !== "" ? txtCuentaBanc.value : null,
                PER_edadtramite : txtEdadTramite.value !=="" ? txtEdadTramite.value : null,
                PER_clabe : txtClabe.value !== "" ? txtClabe.value : null,
                PER_nombrerazonsocial: txtRazonSocial.value !== "" ? txtRazonSocial.value : null,

              }
              gardarDatosBeneficiario(jsonBene);
            } catch (e) {
              console.error("Error:", e.message)
            }

          }}>
            <DialogContent dividers style={{ minWidth: 500 }}>
              {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
          se necesita colocar entre un typogreaphy las veces que des salto de linea 
          o con un br*/}
              {showMsg ? (
                <h2-dialog-description>
                  {
                    "Ha alcanzado el límite de porcentaje asignado. Para agregar otro beneficiario es necesario editar o eliminar un beneficiario ya registrado."
                  }
                </h2-dialog-description>
              ) : <>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center" >
                  <Grid item xs={12}><TXTField label={'Nombre'} name="txtNombre" id="txtNombre" /></Grid>
                  <Grid item xs={12}><TXTField label={'Apellido paterno'} name="txtApePat" id="txtApePat" /></Grid>
                  <Grid item xs={12}><TXTField label={'Apellido materno'} name="txtApeMat" id="txtApeMat" /></Grid>
                  <Grid item xs={12}><Select2 onChange={() => { }} array={listaParentesco} value={beneficiario?.PER_idpersona?.PAR_idparentesco?.PAR_idparentesco} name="txtParentesco" id="txtParentesco" /></Grid>
                  <Grid item xs={12}><TXTField label={'RFC'} /></Grid>
                  <Grid item xs={12} style={{ paddingTop: '10px' }}>
                    <DatePicker2 id="txtFechaNac" name="txtFechaNac" onDataChange={handleDataChange} fecha={fechaNac} maxDate={today} label={'Fecha de nacimiento'} required /></Grid>
                  <Grid item xs={12}><TXTField label={'Edad actual'}
                    value={txtEdadActValue}
                  /></Grid>
                  <Grid item xs={12}><TXTField
                    onChange={(e) => validarEdadTramite(e, 1)}
                    error={edadTraError}
                    helperText={helperEdadTra}
                    label={'Edad al trámite'}
                    name="txtEdadTramite"
                    id="txtEdadTramite"
                  /></Grid>
                  <Grid item xs={12}><TXTField label={'Razón social'} name="txtRazonSocial" id="txtRazonSocial" /></Grid>
                  <Grid item xs={12}><TXTField label={'Clabe interbancaria'} name="txtClabe" id="txtClabe" onChange={onChangeBanco} /></Grid>
                  <Grid item xs={12}><TXTField label={'Cuenta bancaria'} name="txtCuentaBanc" id="txtCuentaBanc" /></Grid>
                  <TXTField label="Banco" name="txtBanco" id="txtBanco" value={banco.descrition}/>
                  {/* <Grid item xs={12}><Select2 onChange={() => { }} array={listaBancos} value={banco} name="slcBanco" id="slcBanco" /></Grid> */}

                  {/* <Grid item xs={10}><TXTField error={error} helperText={errorMessage} defaultValue={!beneficiario?.PXP_porcentajebeneficiario ? 0 : beneficiario?.PXP_porcentajebeneficiario} showPorcentaje={true} label={'Porcentaje'}
                value={textfieldValue}
                onChange={handleChange}
              /></Grid>
              <Grid item xs={2} style={{ paddingLeft: '10px' }}><IconButton onClick={handleButtonClick} ><h3-subtitle-card>Máximo</h3-subtitle-card></IconButton></Grid> */}
                </Grid>
                <div style={{ textAlign: 'center' }}>
                  <Grid item xs={12}>
                    <RadioDinamico
                      id="rbTipo"
                      name="rbTipo"
                      defaultValue={1}
                      array={arrayTipoImporte}
                      row={true}
                      onChange={(e) => rbChange(e.target.value)}
                    />
                  </Grid>
                </div>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center" >
                  <Grid item xs={10}>
                    <TXTField
                      id="txtValor"
                      name="txtValor"
                      label={txtPorImp}
                      value={textfieldValue}
                      error={error}
                      helperText={errorMessage}
                      showPorcentaje={showTxtIcon}
                      defaultValue={valueEdit == 1 ?
                        (!beneficiario?.PXP_porcentajebeneficiario ? 0 : beneficiario?.PXP_porcentajebeneficiario) :
                        (!beneficiario?.PXP_importebeneficiario ? 0 : beneficiario?.PXP_importebeneficiario)
                      }
                      onChange={(e) => handleChange(e, valueEdit)}
                      required
                    />
                  </Grid>
                  <Grid item xs={2} style={{ paddingLeft: '10px' }}><IconButton onClick={asignarMaximo} ><h3-subtitle-card>Máximo</h3-subtitle-card></IconButton></Grid>
                </Grid>
              </>}


            </DialogContent>
            {/*es la tercer sección que engloba los botones */}
            <DialogActions>
              <Grid container justifyContent="center" alignItems="center" spacing={2} marginTop={1}>
                <Grid item >
                  {showMsg ? <ButtonVino
                    variant="contained"
                    label={
                      <FormattedMessage
                        id="globAceptar"
                        defaultMessage="Aceptar"
                      />
                    }
                    onClick={handleClose}

                  /> : <ButtonVino estatus={btnGuardar} label='Guardar' type="submit" />}
                  {!mostrarFeedback ? null : <DialogFeedback
                    open={mostrarFeedback}
                    clickButton={(e) => {
                      setMostrarFeedback(false)
                      handleClose()
                    }}
                    title={tituloFeedback}
                    description={descripFeedback}
                    button={"Aceptar"}
                  />}
                </Grid>
                {showMsg ? null : <Grid item >
                  <ButtonVino
                    variant="contained"
                    label='Cancelar'
                    onClick1={props.cons}
                    onClick={handleClose}
                  />
                </Grid>
                }
              </Grid>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </div>
    </>

  );
}


