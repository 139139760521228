/* 
David Ruiz
Proyecto SINAY 
21/04/2023
*/


import React from "react";

import { CompMenuAdmin } from '../../components/MenuPrincipal/CompMenuAdmin'


export function adminMenu() {
    return (
        <div>
            <CompMenuAdmin/>
        </div>
    );
}