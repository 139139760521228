import React from "react";
//Estilos
import "./Communication.css";
import { CARTA } from "../../utils/constanstoken";
//Alertas
import DialogButton from "../Tools/Dialog/DialogButton";
import DialogImg from "../Tools/Dialog/DialogImg";
import DialogButtonComu from "../Tools/Dialog/DialogButtonComu";
//Navegacion
import Recorrido3 from "../Tools/BreadCrumbs/Recorrido3";
import ArrowBackIcon from "@mui/icons-material/DriveFileRenameOutline";
import { TextField } from "@mui/material";
import TXTField from "../Tools/TextField/TextField";
import { Button } from "@mui/material";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import { useState, useEffect } from "react";
//Tabla de poliza
import TablaInfo from "./Elements/TablaInfo";
import TablaCobertura from "./Elements/TablaCobertura";
//idioma
import { FormattedMessage } from "react-intl";
//Grid
import { Grid } from "@mui/material";
import ButtonVino from "../Tools/Bottones/ButtonVino";
// Api's
import { putDataToken } from "../../api/dataToken";
// Paso de Variable de los objetos
import { useLocation } from "react-router-dom";
import { getFiles, searchFilesByName } from "../../nextcloud/functions";
import { getDataToken, postDataToken } from "../../api/dataToken";
import { formatofecha } from "../../api/getUmbrales";
import moment from "moment";
import {
  LimitarTexto2,
  LimitarDigitos,
} from "../Recepcion/Elements/Functions/Validaciones";
import {
  PERR_AGENTE,
  TRAM_FALLECIMIENTO,
  IDMODCOMUNICACION,
  TIPOENTRECOM,
} from "../../utils/constanstoken";
import getDate from "../Registro/Function/obtenerFecha";
import getHours from "../Registro/Function/obtenerHora";
import DialogAviso from "../Registro/Elements/Dialog/DialogAviso";

export function InfoComunicacion() {
  const location = useLocation();
  const datos = location.state.pasocomu;
  console.log("datos", datos);

  const [cobertura, setCobertura] = useState("");
  const [siniestro, setSiniestro] = useState("");
  const [carta, setCarta] = useState("");
  const [agente, setAgente] = useState("");

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");

  console.log(moment(fechaEmpAn).format(formatofecha));
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();

  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;

  //Obtener usuario.
  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");
  const [idTrabajador, setidTrabajador] = React.useState("");

  const [info, setinfo] = React.useState([]);
  const [guardadoMSG, setguardadoMSG] = useState([]);

  // Consulta para traer los datos del encabezado

  const getCharola = async () => {
    try {
      var result = await getDataToken(
        `polizascoberturasporproductos/get_folio_poliza/${datos?.RECT_idfolio?.RECT_idfolio}/${datos?.POL_idpoliza?.POL_idpoliza}/`
      );
      setCobertura(result);
      console.log(result);
      var resultSini = await getDataToken(
        `polizasfolioxsiniestro/get_folio_poliza/${datos?.RECT_idfolio?.RECT_idfolio}/${datos?.POL_idpoliza?.POL_idpoliza}/`
      );
      console.log(resultSini);
      setSiniestro(resultSini);
      var resultCarta = await getDataToken(
        `cartapolizatramite/get_folio_poliza/${datos?.RECT_idfolio?.RECT_idfolio}/${datos?.POL_idpoliza?.POL_idpoliza}/`
      );
      console.log(resultCarta);
      setCarta(resultCarta);
      var resultPersonas = await getDataToken(
        `personasxpoliza/get_poliza/${datos?.POL_idpoliza?.POL_idpoliza}/`
      );
      let agente = resultPersonas.filter(
        (persona) =>
          persona?.PER_idpersona?.PERR_idrol?.PERR_idrol === PERR_AGENTE.id
      );
      console.log(agente);
      setAgente(agente);
      var resultAnalista = await getDataToken(`login/get_correo/${email}/`);
      setidTrabajador(resultAnalista.id);
      setnombreAnalista(
        resultAnalista.SNYTLOG_username +
          " " +
          resultAnalista.SNYTLOG_surnamedad +
          " " +
          resultAnalista.SNYTLOG_surnamemom
      );
      var resultNoti = await getDataToken(`notificador/conf-mail/get_listado/`);
      console.log("Resultado del notificador =>", resultNoti);
      setinfo(resultNoti);
      var resultMSG = await getDataToken(
        `mensajes/historical_filter_peticion/?folio=${datos?.RECT_idfolio?.RECT_idfolio}&poliza=${datos?.POL_idpoliza?.POL_idpoliza}&modulo=${IDMODCOMUNICACION}&peticion=${TIPOENTRECOM}`
      );
      console.log(resultMSG);
      setguardadoMSG(resultMSG);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getCharola();
  }, []);

  const [values, setValues] = React.useState({
    correo: "",
    telefono: "",
    observaciones: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  React.useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      correo:
        datos?.RECT_idfolio?.SINC_idcomplejidadsiniestro
          ?.TRA_idtipotramitesiniestro === TRAM_FALLECIMIENTO.id
          ? agente[0]?.PER_idpersona?.PER_correo
          : datos?.PER_idpersona?.PER_correo,
      telefono:
        datos?.RECT_idfolio?.SINC_idcomplejidadsiniestro
          ?.TRA_idtipotramitesiniestro === TRAM_FALLECIMIENTO.id
          ? agente[0]?.PER_idpersona?.PER_celular
          : datos?.PER_idpersona?.PER_celular,
    }));
  }, [agente]);

  const validateUpdate = () => {
    const id_persona =
      datos?.RECT_idfolio?.SINC_idcomplejidadsiniestro
        ?.TRA_idtipotramitesiniestro === TRAM_FALLECIMIENTO.id
        ? agente[0]?.PER_idpersona?.PER_idpersona
        : datos?.PER_idpersona?.PER_idpersona;
    let actualizar = {
      PER_correo: values.correo,
      PER_celular: values.telefono,
      PER_actualizofecha: fechaActSIN,
      PER_actualizousuario: nombreAnalista,
    };
    putDataToken(`personas/get_id/${id_persona}/`, actualizar).then((data) => {
      console.log(`Registro ${id_persona} actualizado`, data);
    });

    if (guardadoMSG === null) {
      const guardarmsg = {
        RECT_idfolio: datos?.RECT_idfolio?.RECT_idfolio,
        POL_idpoliza: datos?.POL_idpoliza?.POL_idpoliza,
        IDTRABAJADOR: idTrabajador,
        WFM_idwfmodulo: IDMODCOMUNICACION,
        TPET_idtipopeticion: TIPOENTRECOM,
        MSG_mensaje: values.observaciones,
        MSG_actualizofecha: fechaActSIN,
        MSG_actualizousuario: nombreAnalista,
      };
      postDataToken(`mensajes/get_listado/`, guardarmsg).then((data) => {
        console.log(`Registro exitoso mensajes`, data);
      });
    } else {
      const guardarmsg = {
        RECT_idfolio: datos?.RECT_idfolio?.RECT_idfolio,
        POL_idpoliza: datos?.POL_idpoliza?.POL_idpoliza,
        IDTRABAJADOR: idTrabajador,
        WFM_idwfmodulo: IDMODCOMUNICACION,
        TPET_idtipopeticion: TIPOENTRECOM,
        MSG_mensaje: values.observaciones,
        MSG_actualizofecha: fechaActSIN,
        MSG_actualizousuario: nombreAnalista,
      };
      putDataToken(
        `mensajes/get_id/${guardadoMSG[0]?.MSG_idmensaje}/`,
        guardarmsg
      ).then((data) => {
        console.log(`Registro actualizado mensajes`, data);
      });
    }
    setValues((prevValues) => ({
      ...prevValues,
      observaciones: "",
    }));
  };

  const [visible, setvisible] = React.useState(false);

  const onclickVisible = () => {
    setvisible(true);
    validateUpdate();
  };

  const handleClick = () => {
    setvisible(false);
  };

  const urlprovisional = "https://acortar.link/O4Cb4w";

  const validateContacto = () => {
    let auxpost = {
      EMLNT_email: values.correo,
      EMLNT_asunto: "Información sobre su trámite siniestro",
      EMLNT_texto:
        "Estimado usuario, le mandamos la siguiente información sobre su trámite.",
      EMLNT_texto2: values.observaciones,
      EMLNT_archivo_url: urlprovisional,
      EMLNT_PORT: info[0].EMLNT_PORT,
      EMLNT_TLS: info[0].EMLNT_TLS,
      EMLNT_HOST: info[0].EMLNT_HOST,
      EMLNT_HOST_USER: info[0].EMLNT_HOST_USER,
      EMLNT_HOST_PASSWORD: info[0].EMLNT_HOST_PASSWORD,
    };
    postDataToken(`notificador/mail/`, auxpost).then((data) => {
      console.log(auxpost);
      console.log(`Registro exitoso`, data);
    });

    let data = {
      RECT_idfolio: datos?.RECT_idfolio?.RECT_idfolio,
      PER_idpersona: datos?.PER_idpersona?.PER_idpersona,
      POL_idpoliza: datos?.POL_idpoliza?.POL_idpoliza,
      Usuario_Login: idTrabajador,
      CHCOM_entregado: true,
      CHCOM_fechaentregado: fechaActSIN,
      CHCOM_actualizofecha: fechaActSIN,
      CHCOM_actualizousuario: nombreAnalista,
    };
    putDataToken(
      `charolacomunicacion/get_id/${datos?.CHCOM_idcharolacom}/`,
      data
    ).then((data) => {
      console.log(`Registro actualizado charola comunicacion`, data);
    });
  };

  const [visibleEmail, setvisibleEmail] = React.useState(false);

  const onclickEmail = () => {
    setvisibleEmail(true);
    validateContacto();
  };

  const handleEmail = () => {
    setvisibleEmail(false);
  };

  // // Inicio de Notificador de Whats
  // const validateAndSubmitForm = (e) => {
  //   //e.preventDefault();

  //   fetch(`${BASE_API}notificador/sms/`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       accept: "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //     body: JSON.stringify({
  //       EMLNT_titulo:
  //         "EAALKJcl6jkkBAGMOUbgwfJUoQKqhYDxuZC1C2LWcmff0mlYr1KM1jd77SMlIR5jPgtx5ZB2s28p1VkKsMujSp0xgM6cNlsNBoZARzzNJ4nBO4HstHgxg0i8kroV69EM94nBxiY4Md4YTdYXJMRMjRlZAmOJHvGzuEFLJn7zjzXQNExzpZAUqAZAn82R3J5vHqmJfhWUq0gLqNTDQIqLXiR",
  //       EMLNT_numerocli: "+52" + userData.PER_idpersona?.PER_telefono,
  //       EMLNT_texto2: userData.PER_idpersona?.PER_correo,
  //       EMLNT_texto: "QAIT",
  //     }),
  //   });
  // };
  // Area de Nextcloud, buscar cartas
  //Estado para buscar los datos de la carpeta del netxcloud

  // const [pdfsCARTA, setPdfsCARTA] = React.useState([]);

  // const [btnVerFolderCARTA, setBtnVerFolderCARTA] = React.useState({
  //   estatus: false,
  //   label: "Aceptar",
  // });

  // const [documentsCARTA, setDocumentsCARTA] = React.useState([]);
  // const [currentPDFCARTA, setCurrentPDFCARTA] = React.useState(0);

  // const verArchivosCARTA = async () => {
  //   setBtnVerFolderCARTA({
  //     ...btnVerFolderCARTA,
  //     label: "Buscando información...",
  //     estatus: true,
  //   });
  //   var response = await searchFilesByName(verfolderCARTA, CARTA);
  //   if (!response) {
  //     alert("No existe carpeta con ese folio");
  //     setBtnVerFolderCARTA({
  //       ...btnVerFolderCARTA,
  //       label: "Aceptar",
  //       estatus: false,
  //     });
  //   } else {
  //     if (response.length > 0) {
  //       setBtnVerFolderCARTA({
  //         ...btnVerFolderCARTA,
  //         label: "Aceptar",
  //         estatus: false,
  //       });
  //       var pdfs = response.filter((file) => file.fileExt === "pdf");
  //       if (pdfs.length > 0) {
  //         const docs = pdfs.map((e) => e.fileBlob);
  //         console.log("Docs:", docs);
  //         setPdfsCARTA(docs);
  //       }
  //     } else {
  //       alert("La carpeta está vacía.");
  //       setBtnVerFolderCARTA({
  //         ...btnVerFolderCARTA,
  //         label: "Aceptar",
  //         estatus: false,
  //       });
  //     }
  //   }
  // };
  // //Este use Effect se activará cuando escuche cambios en el pdfs
  // React.useEffect(() => {
  //   if (pdfsCARTA.length > 0) {
  //     pdfsCARTA.forEach((blob) => {
  //       var pdfUrl = URL.createObjectURL(blob);
  //       setDocumentsCARTA((prevState) => [
  //         ...prevState,
  //         <iframe
  //           src={pdfUrl}
  //           style={{
  //             width: "1100px",
  //             height: "1100px",
  //             display: "block",
  //             margin: "auto",
  //           }}
  //         />,
  //       ]);
  //       URL.revokeObjectURL(blob);
  //     });
  //   }
  // }, [pdfsCARTA]);

  //contantes para editar información
  const [editCampos, setEditCampos] = useState(true);
  const editarCampos = (e) => {
    setEditCampos(false);
  };

  // Calculo para la edad del asegurado
  const calcularEdad = (fechaNacimiento, fechaSiniestro) => {
    const fechaNac = moment(fechaNacimiento);
    const fechaSin = moment(fechaSiniestro);
    const edad = fechaSin.diff(fechaNac, "years");
    return edad;
  };

  // Uso de la función para obtener la edad del asegurado

  const fechaNacimiento = datos.PER_idpersona?.PER_fechanacimiento;
  const fechaSiniestro = siniestro[0]?.SIN_idsiniestro?.SIN_fechasiniestro;
  const edadAsegurado = calcularEdad(fechaNacimiento, fechaSiniestro);

  return (
    <>
      {visible && (
        <DialogAviso
          title={
            <FormattedMessage
              id="regisMsgInf"
              defaultMessage="Mensaje informativo"
            />
          }
          firstButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description="Los datos se han actualizado con exito."
          onClick1={handleClick}
        />
      )}
      {visibleEmail && (
        <DialogAviso
          title={
            <FormattedMessage
              id="regisMsgInf"
              defaultMessage="Mensaje informativo"
            />
          }
          firstButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description="El correo electrónico se envio exitosamente."
          onClick1={handleEmail}
          link1="/comunicacion"
        />
      )}
      <div class="div-parent">
        <div class="div-breadcrumbs">
          <Recorrido3
            name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
            enlace="/menu"
            name2={
              <FormattedMessage
                id="globCoClie"
                defaultMessage="Comunicación con el cliente"
              />
            }
            enlace2="/comunicacion"
            name3={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
          />
          <br />
        </div>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <ReturnPage href="/menu" />
        </Grid>

        <div className="div-container">
          <Grid container>
            <Grid xs={12}>
              <h3-header-card>
                <FormattedMessage
                  id="medicInAse"
                  defaultMessage="Información del Asegurado"
                />
              </h3-header-card>
              <br />
              <br />
              <br />
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage
                  id="globNomCom"
                  defaultMessage="Nombre completo"
                />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {datos.PER_idpersona?.PER_nombre} {}
                {datos.PER_idpersona?.PER_apellidopaterno} {}
                {datos.PER_idpersona?.PER_apellidomaterno} {}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage
                  id="globFechNa"
                  defaultMessage="Fecha de nacimiento"
                />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {moment(datos.PER_idpersona?.PER_fechanacimiento).format(
                  formatofecha
                )}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage id="globEdad" defaultMessage="Edad" />
              </h3-title-card>
              <br />
              <h3-subtitle-card>{edadAsegurado}</h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage id="globSexo" defaultMessage="Sexo" />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {datos.PER_idpersona?.PER_sexo}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage id="globRfc" defaultMessage="RFC" />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {datos.PER_idpersona?.PER_RFC}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage id="globCurp" defaultMessage="CURP" />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {datos.PER_idpersona?.PER_CURP}
              </h3-subtitle-card>
              <br />
              <br />
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage id="globFolio" defaultMessage="Folio" />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {datos.RECT_idfolio?.RECT_numerofolio}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage
                  id="dictFeDeSi"
                  defaultMessage="Fecha de siniestro"
                />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {moment(fechaSiniestro).format(formatofecha)}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage
                  id="globNumS"
                  defaultMessage="Número siniestro"
                />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {siniestro[0]?.SIN_idsiniestro?.SIN_numerosiniestro}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage
                  id="errorTiRec"
                  defaultMessage="Tipo de reclamación"
                />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {
                  datos.RECT_idfolio?.SINC_idcomplejidadsiniestro
                    ?.TRA_destipotramitesiniestro
                }
              </h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage id="globCober" defaultMessage="Cobertura" />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {cobertura[0]?.COB_idcobertura?.COB_cobertura}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage id="globEstatu" defaultMessage="Estatus" />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {datos.RECT_idfolio?.TEST_idestatustramite.TEST_estatustramite}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={12}>
              <br />
              <hr />
              <br />
            </Grid>
            <Grid xs={12}>
              <TablaInfo data={datos} />
              <br />
            </Grid>
            <Grid xs={1.4}>
              <DialogButtonComu
                mainButton={
                  <FormattedMessage id="globCober" defaultMessage="Cobertura" />
                }
                title={`Número de póliza : ${datos?.POL_idpoliza?.POL_numpoliza}`}
                description2={<TablaCobertura data={cobertura} />}
                button="Aceptar"
              />
            </Grid>
            <Grid xs={10.6}>
              {/* <DialogImg
              mainButton={carta[0]?.CXC_idcausa?.FK_Tipo_Carta?.LTR_descripcion}
              title={carta[0]?.CXC_idcausa?.FK_Tipo_Carta?.LTR_descripcion}
              button={
                <FormattedMessage id="globGuarda" defaultMessage="Guardar" />
              }
              img={
                pdfsCARTA.length > 0 ? (
                  <div>
                    {documentsCARTA[currentPDFCARTA]}
                    <div
                      style={{
                        display: "block",
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      <br />
                      <ButtonVino
                        onClick={() => setCurrentPDFCARTA(currentPDFCARTA - 1)}
                        disabled={currentPDFCARTA === 0}
                        label="Anterior PDF"
                      ></ButtonVino>
                      <ButtonVino
                        onClick={() => setCurrentPDFCARTA(currentPDFCARTA + 1)}
                        disabled={currentPDFCARTA === pdfsCARTA.length - 1}
                        label="Siguiente PDF"
                      ></ButtonVino>
                    </div>
                  </div>
                ) : (
                  <p>Cargando PDF...</p>
                )
              }
              route={() => verArchivosCARTA()}
              onclick1={() => verArchivosCARTA()}
            /> */}
            </Grid>
            <Grid xs={12}>
              <br />
              <hr />
              <br />
            </Grid>
            {/* Empieza area de contacto con el cliente */}
            {/* Formulario para editar el area de correo y telefono */}
            {/* Area de diseño para contacto */}
            <Grid container>
              <Grid xs={12}>
                <h3-header-card>
                  <FormattedMessage id="comuContac" defaultMessage="Contacto" />
                </h3-header-card>
                <br /> <br />
              </Grid>
              <Grid xs={3}>
                <h2-dialog-description>
                  Correo electrónico
                </h2-dialog-description>
                <TXTField
                  size="small"
                  id="correo"
                  value={values.correo}
                  required={true}
                  onInput={(e) => LimitarTexto2(e.target, 50)}
                  onChange={handleChange("correo")}
                  disabled={editCampos}
                />
              </Grid>
              <Grid xs={0.5}></Grid>
              <Grid xs={1.5}>
                <h2-dialog-description>Teléfono</h2-dialog-description>
                <TXTField
                  size="small"
                  id="telefono"
                  value={values.telefono}
                  required={true}
                  onInput={(e) => LimitarDigitos(e.target, 10)}
                  onChange={handleChange("telefono")}
                  disabled={editCampos}
                />
              </Grid>
              <Grid xs={0.5}></Grid>
              <Grid xs={5}>
                <h2-dialog-description>Observaciones</h2-dialog-description>
                <TXTField
                  size="small"
                  id="observaciones"
                  value={values.observaciones}
                  required={true}
                  onInput={(e) => LimitarTexto2(e.target, 50)}
                  onChange={handleChange("observaciones")}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={9}></Grid>
              <Grid xs={0.9}>
                <br />
                <br />
                <ButtonVino
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e) => editarCampos(e)}
                  label="Editar"
                />
              </Grid>
              <Grid xs={1}>
                <br />
                <br />
                <ButtonVino
                  variant="contained"
                  color="primary"
                  size="small"
                  label="Guardar"
                  estatus={
                    values.correo && values.telefono && values.observaciones
                      ? false
                      : true
                  }
                  onClick={onclickVisible}
                />
              </Grid>
              <Grid xs={1}>
                <br />
                <br />
                <ButtonVino
                  variant="contained"
                  color="primary"
                  size="small"
                  label="Enviar"
                  estatus={
                    values.correo && values.telefono && values.observaciones
                      ? false
                      : true
                  }
                  onClick={onclickEmail}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
