/* 
David Ruiz
Proyecto SINAY 
11/10/2022
Pajita
Proyecto SINAY
16/08/2023
*/

import React from "react";
import "./Medic.css";
import "../../css/estilo.css";

//navegador
import Recorrido from "../Tools/BreadCrumbs/Recorrido";

//tabla
import TablaMedico from "./Elements/Table/TablaMedico";

//regreso
import ReturnPage from "../Tools/Bottones/ButtonReturn";

//Grid
import { Grid } from "@mui/material";

//idioma
import { FormattedMessage } from "react-intl";

export function CompMedic() {
  return (
    <div className="div-parent">
      <div className="div-breadcrumbs">
        {" "}
        {/*navegador*/}
        <Recorrido
          name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
          enlace="/menu"
          name2={<FormattedMessage id="globMedico" defaultMessage="Médico" />}
        />
      </div>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <ReturnPage href="/menu" />
      </Grid>
      <div className="div-container">
        {" "}
        {/*tabla/botnes*/}
        <TablaMedico />
      </div>
      <br />
    </div>
  );
}
