/* Brandon Hernandez Rocha 11/2022 */
import React from "react";
import { useLocation } from "react-router-dom";
import CustomSeparatorDinamic from "../Tools/BreadCrumbs/RecorridoDinamico";
import "../../css/estilo.css";
import TablaReservas from "./Elements/Tables/TablaReservas";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import { Grid } from "@mui/material";
import { CardDetalleFolio } from "./Elements/Cards/CardDetalleFolio";
import { Link } from "react-router-dom";
import TablaRegistroContable from "./Elements/Tables/TablaRegistroContable";

//idioma
import { FormattedMessage } from "react-intl";
const CompDictamenReservas = () => {
  const location = useLocation();
  const row = location.state.row;
  const poliza = location.state.poliza;
  //Escenario 1 “REGISTRO DEL IMPORTE DE RESERVA (sin contabilización en SINAY)”
  //Escenario 2 SINAY GENERA EL REGISTRO CONTABLE Y LA POLIZA DE DIARIO CONTABLE DE LAS RESERVAS
  //Cuando es 1 corresponde al escenario 1, 2 para el escenario 2
  const tipoEscenario = 2;
  const arrayBreadcrumbs = [
    {
      key: 1,
      name: <FormattedMessage id="globInicio" defaultMessage="Inicio" />,
      to: "/menu",
    },
    {
      key: 2,
      name: <FormattedMessage id="menuDictam" defaultMessage="Dictamen" />,
      to: "/dictamen",
    },
    {
      key: 3,
      name: (
        <FormattedMessage id="dictaDetalleF" defaultMessage="Detalle folio" />
      ),
      to: "/dictamen/detalle_folio",
      state: { row : row },
    },
  ];
  return (
    <div className="div-parent">
      <div className="div-breadcrumbs">
        <CustomSeparatorDinamic
          breadcrumbs={arrayBreadcrumbs}
          name={<FormattedMessage id="dictamRese" defaultMessage="Reservas" />}
        />
      </div>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Link
          style={{ textDecoration: "none" }}
          to="/dictamen/detalle_folio"
          state={{ row : row }}
        >
          <ReturnPage />
        </Link>
      </Grid>
      <div className="div-container">
        <CardDetalleFolio infoRow={row} />
        {tipoEscenario === 1 ? (
          <TablaReservas poliza={poliza} infoRow={row} />
        ) : <TablaRegistroContable infoRow={row} poliza={poliza}/>}  
      </div>
    </div>
  );
};
export default CompDictamenReservas;
