import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import IconButton from "@material-ui/core/IconButton";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Snackbar from '@mui/material/Snackbar';
import Grid from "@material-ui/core/Grid";
import Paper from "@mui/material/Paper";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import TablePagination from "@mui/material/TablePagination";
import ArrowBackIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";
import { Box } from "@mui/material";
import { getToken } from "../../../../../api/token";
import { getDataToken } from "../../../../../api/dataToken";
import { BASE_API } from "../../../../../utils/constans";
import { secundario } from '../../../../../api/getUmbrales';
import { TOKENLOGIN } from "../../../../../utils/constans";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SearchTXTField from "../../../../Tools/TextField/SearchTextField";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: secundario,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Moneda() {
  

  //Token
  const [tableData, setTableData] = useState([]);
  const token = getToken();
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  //Jalar los datos de las Api's
  const fetchData = async () => {
    //Resultados de la api investigacion
    var resultID = await getDataToken("monedas/get_listado/");
    setTableData(resultID);
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  //Funcion para el boton de editar y abrir ventana modal
  const [open, setOpen] = React.useState(false);
  
  // Funcion para abril modal para agregar datoo
  const [openn, setOpenn] = React.useState(false);
  const authorizationHeader = `Bearer ${TOKENLOGIN}`;
  const [scrolll, setScrolll] = React.useState("paper");
  const handleClickOpen1 = (scrollType) => () => {
    setOpenn(true);
    setScrolll(scrollType);
  };

  const handleClose1 = () => {
    setOpenn(false);
  };

  const [codigo, setCodigo] = useState('');
  const [moneda, setMoneda] = useState('');
  const [openDialogMsj, setOpenDialogMsj] = useState(false);
  const [openNew, setOpenNew] = React.useState(false);

  const handleCloseNew = () => {
      setOpenNew(false);
      //setReloadTable(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    //setEditingId(null);
  };

  const handleCodigoChange = (event) => {
    setCodigo(event.target.value);
  };

  const handleMonedaChange = (event) => {
    setMoneda(event.target.value);
};
    //NUEVO POST
  const handleAddCoin = (newCoin) => {
      const currentDate = new Date().toISOString();
      const body = {
          MON_abreviatura: codigo,
          MON_nombre: moneda,
          MON_activo: true,
          MON_actualizofecha: currentDate, 
          MON_actualizousuario: localStorage.getItem("SNYTLOG_email")
      };

      console.log('Datos a enviar:', body);

      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", authorizationHeader);

      fetch(`${BASE_API}monedas/get_listado/`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
      })
      .then(response => {
          console.log('Respuesta:', response);
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          return response.json();
      })
      .then(data => {
          console.log('New coin added:', data);
          setOpenDialogMsj(true);
      })
      .catch(error => {
          console.error('Error adding new coin:', error);
          // Aquí puedes manejar el error, por ejemplo, mostrando un mensaje al usuario
      });
  };

  //NUEVO ELIMINAR

  const [openEliminar, setOpenEliminar] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleEliminarClick = (id) => {
    setOpenEliminar(true);
    setSelectedId(id);
  };

  const handleEliminarConfirmado = (id) => {
    let response = fetch(`${BASE_API}monedas/get_id/` + selectedId + "/", {
      method: "PUT",
      headers: headers,
      body: JSON.stringify({ MON_activo: false }),
    });
    response.then((response) => {
      console.log(response);
      setOpenEliminar(false); 
      setOpenSnackbar(true);
    });

    console.log(selectedId);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false); // Cierra el Snackbar
  };

  //NUEVO EDITAR

  const [editCoinInfo, setEditCoinInfo] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackbarEditar, setOpenSnackbarEditar] = useState(false);

  //Para cerrar el dialogo que edita
  const handleCloseEdit = () => {
    setOpenDialog(false);
    
  };

  const handleEdit = (id) => {
    console.log('Editar moneda con ID:', id);
    fetch(`${BASE_API}monedas/get_id/${id}/`, {
      method: "GET",
      headers: headers,
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log('Datos de la moneda:', data);
      // Asegúrate de que los campos de texto sean editables
      setEditCoinInfo({ ...data, editable: true });
      setOpenDialog(true); // Abrir el diálogo de edición
    });
  };


  const handleGuardar = () => {
    console.log('Datos del banco a guardar:', editCoinInfo.MON_idmoneda);
    fetch(`${BASE_API}monedas/get_id/${editCoinInfo.MON_idmoneda}/`, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(editCoinInfo),
    })
    .then((response) => {
        setOpenDialog(false); 
        setOpenSnackbarEditar(true);
    });
  };

  //Uso del snackbar para cuando se ha editado correctamente 
  const handleCloseSnackbarEditar = () => {
    setOpenSnackbarEditar(false); // Cierra el Snackbar
  };

  //CONSTANTES MONA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(5); //El tamaño inicial de las filas será de 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
   // Cambiar filas por página
   const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
};

  

  /* CONSTANTES DE ESTADOS*/

  const [order, setOrder] = useState("ASC"); // esta constante tiene como propósico guardar el estado del orden de la tabla

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...tableData].sort((a, b) =>
        a[col].toString() > b[col].toString() ? 1 : -1
      );
      setOrder("DSC");
      setTableData(sorted);
    } else if (order === "DSC") {
      const sorted = [...tableData].sort((a, b) =>
        a[col].toString() > b[col].toString() ? -1 : 1
      );
      setOrder("ASC");
      setTableData(sorted);
    }
  };

  return (
    <div>
      <Grid container alignItems="center" spacing={5}>
                <Grid item xs={3}>
                    <SearchTXTField
                        label={<FormattedMessage id="globBusqued" defaultMessage="Búsqueda" />}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button variant="contained" onClick={handleClickOpen1("paper")}>Agregar nueva moneda</Button>
                </Grid>
                
            </Grid>
      
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="right">ID </StyledTableCell>
                <StyledTableCell
                  align="right"
                  onClick={() => sorting("MON_nombre")}
                >
                  <FormattedMessage id="globMoneda" defaultMessage="Moneda" />
                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    {order === "DSC" ? <UnfoldMoreIcon /> : <UnfoldMoreIcon />}
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <FormattedMessage id="globEstatu" defaultMessage="Estatus" />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <FormattedMessage id="globSimbolo" defaultMessage="Símbolo" />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <FormattedMessage id="dictaFecha" defaultMessage="Fecha" />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <FormattedMessage id="globModif" defaultMessage="Modificó" />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <FormattedMessage id="globEditar" defaultMessage="Editar" />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <FormattedMessage id="estconElim" defaultMessage="Eliminar" />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                    val.MON_nombre.toLowerCase().includes(
                      searchTerm.toLowerCase()
                    )
                  ) {
                    return val;
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                 
                  <StyledTableRow key={row.MON_idmoneda}>
                    <StyledTableCell component="th" scope="row">
                    {row.MON_idmoneda}
                    </StyledTableCell>

                    <StyledTableCell align="right">{row.MON_nombre}</StyledTableCell>
                    {row.MON_activo ? (
                      <StyledTableCell align="right">
                        {" "}
                        <FormattedMessage
                          id="globActivo"
                          defaultMessage="Activo"
                        />
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell align="right">
                        {" "}
                        <FormattedMessage
                          id="globInacti"
                          defaultMessage="Inativo"
                        />
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="right">{row.MON_simbolo}</StyledTableCell>
                    <StyledTableCell align="right">
                    {new Date(row.MON_actualizofecha).toLocaleDateString('es-MX', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                    })}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.MON_actualizousuario}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Button  onClick={() => handleEdit(row.MON_idmoneda)}>
                        <ArrowBackIcon />
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton onClick={() => handleEliminarClick(row.MON_idmoneda)}>
                      <DeleteForeverIcon />
                      </IconButton>
                    </StyledTableCell>
                    <Dialog open={openEliminar} onClose={() => setOpenEliminar(false)}>
                      <DialogTitle>¿Estás seguro que deseas eliminar esta moneda?</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Una vez eliminado no es posible recuperarlo
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setOpenEliminar(false)}>Cancelar</Button>
                        <Button onClick={() => handleEliminarConfirmado(row.MON_idmoneda)} autoFocus>Eliminar</Button>
                      </DialogActions>
                    </Dialog>
                    <Snackbar
                      open={openSnackbar}
                      autoHideDuration={6000}
                      onClose={handleCloseSnackbar}
                      message="Moneda eliminada con éxito"
                      action={
                        <Button color="inherit" onClick={handleCloseSnackbar}>
                          Cerrar
                        </Button>
                      }
                    />
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      

      <div>
        {/* Modal para editar dato */}
        <Dialog 
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Editar Moneda</DialogTitle>
            <DialogContent>
                    <Box  component="form"
                        sx={{
                        '& .MuiTextField-root': { m: 1, margin: '0 auto', mt: 2 },
                        }}
                        noValidate
                        autoComplete="off">
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                            <TextField
                                label="Moneda"
                                value={editCoinInfo?.MON_nombre || ''}
                                onChange={(e) => setEditCoinInfo({ ...editCoinInfo, MON_nombre: e.target.value })}
                                fullWidth
                                disabled={!editCoinInfo?.editable} 
                            />
                            </Grid>
                            <Grid item xs={6}>
                            <TextField
                                id="outlined-basic2"
                                label="Abreviatura"
                                variant="outlined"
                                value={editCoinInfo ? editCoinInfo.MON_abreviatura : ''}
                                fullWidth
                                onChange={(e) => setEditCoinInfo({ ...editCoinInfo,MON_abreviatura: e.target.value })}
                                disabled={!editCoinInfo?.editable} // Asegura que el campo sea editable
                                />
                            </Grid>
                        </Grid>
                    </Box>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseEdit}>Cancelar</Button>
            <Button onClick={handleGuardar}>Guardar</Button>
            </DialogActions>
        </Dialog>
        <Snackbar
          open={openSnackbarEditar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbarEditar}
          message="Moneda actualizada con éxito"
          action={
          <Button color="inherit" onClick={handleCloseSnackbarEditar}>
          Cerrar
          </Button>
          }
        />
        {/* Modal para agregar dato */}
        <Dialog open={openn}>
            
                <DialogTitle id="alert-dialog-title">Agregar moneda</DialogTitle>
                <DialogContent>
                    <Box  component="form"
                        sx={{
                        '& .MuiTextField-root': { m: 1, margin: '0 auto', mt: 2 },
                        }}
                        noValidate
                        autoComplete="off">
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <TextField
                                    id="MON_nombre"
                                    name="MON_nombre"
                                    label="Moneda"
                                    value={moneda}
                                    onChange={handleMonedaChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="MON_codigo"
                                    name="MON_codigo"
                                    label="Codigo"
                                    variant="outlined"
                                    value={codigo}
                                    onChange={handleCodigoChange}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose1}>Cancelar</Button>
                    <Button onClick={handleAddCoin} autoFocus>
                          Aceptar
                    </Button>
                    <Dialog open={openDialogMsj} onClose={handleCloseDialog}>
                      <DialogTitle>Banco Agregado</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                          La moneda se agrego con éxito
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose1}>Cerrar</Button>
                      </DialogActions>
                    </Dialog>
                </DialogActions>
            
        </Dialog>
      </div>
    </div>
  );
}
