import * as React from "react";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import TextField from "@mui/material/TextField";
import usa from "../../../../img/usa.png";
import mex from "../../../../img/mex.png";
import bra from "../../../../img/bra.png";
import francesa from "../../../../img/francesa.png";
import italiana from "../../../../img/italiana.png";
import { FormattedMessage } from "react-intl";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import { useContext } from "react";
import { langContext } from "../../../../Context/langContext";
import { putDataToken } from "../../../../api/dataToken";

export default function General() {
  //TODO: Umbrales

  //TODO: Language
  const [language, setLanguage] = React.useState("");
  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
  };
  const idioma = useContext(langContext);

  //!Validaciones
  //*Dialogo
  const [openDialog, setOpenDialog] = useState(false);
  //*Validaciones
  const [correo, setCorreo] = useState("");
  const [correoValido, setCorreoValido] = useState(false);
  const [url, setURL] = useState("");
  const [urlValido, setURLValido] = useState(false);
  const [port, setPort] = useState("");
  const [maxDias, setMaxDias] = useState("");
  const [direccion, setDireccion] = useState("");
  const [slogan, setSlogan] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const datos = {
      SCG_Dominio: correo,
      SCG_URL: url,
      SCG_Puerto: port,
      SCG_diasMaxTramite: maxDias,
      SCG_Direccion: direccion,
      SCG_slogan: slogan,
    };

    putDataToken("configuraciongeneral/get_id/1/", datos)
      .then((data) => {
        console.log("Respuesta del servidor:", data);
        setOpenDialog(true);
      })
      .catch((error) => {
        console.error("Error al enviar los datos:", error);
      });
  };

  const handleCorreoChange = (event) => {
    setCorreo(event.target.value);
    const regex = /^\@+[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/;
    if (event.target.value === "") {
      setCorreoValido(false);
    } else {
      setCorreoValido(regex.test(event.target.value));
    }
  };

  const handleURLChange = (event) => {
    setURL(event.target.value);
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    if (event.target.value === "") {
      setURLValido(false);
    } else {
      setURLValido(regex.test(event.target.value));
    }
  };
  const handlePortChange = (event) => {
    let value = event.target.value;
    if (parseInt(value) >= 0 && value !== "") {
      setPort(parseInt(value));
    } else if (value === "") {
      setPort(0);
    }
  };

  const handleMaxDiasChange = (event) => {
    let value = event.target.value;
    if (parseInt(value) >= 0 && value !== "") {
      setMaxDias(parseInt(value));
    } else if (value === "") {
      setMaxDias(0);
    }
  };
  const handleDireccionChange = (event) => {
    let value = event.target.value;
    if (value !== "") {
      setDireccion(value);
    } else {
      setDireccion(null);
    }
  };
  const handleSloganChange = (event) => {
    let value = event.target.value;
    if (value !== "") {
      setSlogan(value);
    } else {
      setSlogan(null);
    }
  };

  //TODO: Cerrar Dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  //TODO: Tipo de Cambio - Banco de Mexico
  const [date, setDate] = useState(new Date());
  const [dollarExchangeRate, setDollarExchangeRate] = useState(null);
  useEffect(() => {
    const options = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const locale = "es-MX";
    const formattedDate = new Date().toLocaleDateString(locale, options);
    setDate(formattedDate);
    fetch(
      "https://www.banxico.org.mx/SieAPIRest/service/v1/series/SF43718/datos/oportuno?token=79bd278e1e022a8ffd0649b604c0f6ee03f03f4c366b08302556c36df8f34979"
    )
      .then((response) => response.json())
      .then((data) => setDollarExchangeRate(data.bmx.series[0].datos[0].dato))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="div-parent">
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          <FormattedMessage
            id="conGResServ"
            defaultMessage="Respuesta del servidor"
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage
              id="conGDatAct"
              defaultMessage="Los datos se han actualizado correctamente."
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonVino
            variant="contained"
            color="primary"
            size="medium"
            label={
              <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
            }
            type="submit"
            autoFocus
            onClick={handleCloseDialog}
          />
        </DialogActions>
      </Dialog>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} xs={12}>
          <Grid item xs={4}>
            <Card>
              <CardHeader
                title={
                  <FormattedMessage
                    id="conGFormatF"
                    defaultMessage="Formato de folio"
                  />
                }
                subheader={
                  <FormattedMessage
                    id="conGInForm"
                    defaultMessage="Ingresa el formato deseado para los folios."
                  />
                }
              />
              <CardContent xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      // type={correo}
                      // value={correo}
                      placeholder="Ej. RECF-"
                      // onChange={handleCorreoChange}
                    />
                    {correoValido ? (
                      <p style={{ color: "green", fontSize: 10 }}>
                        El folio es válido.
                      </p>
                    ) : (
                      <p style={{ color: "red", fontSize: 10 }}>
                        El folio no es válido.
                      </p>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card>
              <CardHeader
                title={
                  <FormattedMessage
                    id="conGDomiI"
                    defaultMessage="Dominio institucional"
                  />
                }
                subheader={
                  <FormattedMessage
                    id="conGDomiCo"
                    defaultMessage="Dominio del correo institucional."
                  />
                }
              />
              <CardContent xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      type={correo}
                      value={correo}
                      placeholder="@ejemplo.com.mx"
                      onChange={handleCorreoChange}
                    />
                    {correoValido ? (
                      <p style={{ color: "green", fontSize: 10 }}>
                        <FormattedMessage
                          id="conGDomiVa"
                          defaultMessage="El dominio es válido."
                        />
                      </p>
                    ) : (
                      <p style={{ color: "red", fontSize: 10 }}>
                        <FormattedMessage
                          id="conGDomiNo"
                          defaultMessage="El dominio no es válido."
                        />
                      </p>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card>
              <CardHeader
                title={
                  <FormattedMessage id="conGSlog" defaultMessage="Slogan" />
                }
                subheader={
                  <FormattedMessage
                    id="conGIeSlo"
                    defaultMessage="Ingresa el slogan de tu empresa."
                  />
                }
              />
              <CardContent>
                <TextField
                  name="txtslogan"
                  variant="outlined"
                  placeholder='"Generando confianza en tus aplicaciones". '
                  style={{ maxRows: 1 }}
                  fullWidth
                  onChange={handleSloganChange}
                />
                {slogan ? (
                  <p style={{ color: "green", fontSize: 10 }}>
                    <FormattedMessage
                      id="conGSlog"
                      defaultMessage="Slogan válido"
                    />
                    .
                  </p>
                ) : (
                  <p style={{ color: "red", fontSize: 10 }}>
                    <FormattedMessage
                      id="conSloNo"
                      defaultMessage="El slogan no puede estar vacio"
                    />
                    .
                  </p>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card>
              <CardHeader
                title={
                  <FormattedMessage
                    id="globUrlY"
                    defaultMessage="URL y Puerto"
                  />
                }
                subheader={
                  <FormattedMessage
                    id="globIntroURL"
                    defaultMessage="Introduce la URL y el puerto para conexiónes."
                  />
                }
              />
              <CardContent xs={12}>
                <Grid container spacing={1} xs={12}>
                  <Grid item xs={8}>
                    <TextField
                      type="text"
                      value={url}
                      placeholder="https://www.example.com"
                      fullWidth
                      onChange={handleURLChange}
                    />
                    {urlValido ? (
                      <p style={{ color: "green", fontSize: 10 }}>
                        <FormattedMessage
                          id="globURLVa"
                          defaultMessage="La URL es válida"
                        />
                        .
                      </p>
                    ) : (
                      <p style={{ color: "red", fontSize: 10 }}>
                        <FormattedMessage
                          id="globURLNo"
                          defaultMessage="La URL no es válida"
                        />
                        .
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      placeholder="3000"
                      value={port}
                      type="number"
                      inputProps={{ min: 0, null: false }}
                      onChange={handlePortChange}
                    />
                    {port ? (
                      <p style={{ color: "green", fontSize: 10 }}>
                        <FormattedMessage
                          id="globPuerVa"
                          defaultMessage="Puerto válido"
                        />
                        .
                      </p>
                    ) : (
                      <p style={{ color: "red", fontSize: 10 }}>
                        <FormattedMessage
                          id="globPuertaNo"
                          defaultMessage="Puerto no válido"
                        />{" "}
                        .
                      </p>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card>
              <CardHeader
                title={
                  <FormattedMessage
                    id="globDirecCorp"
                    defaultMessage="Dirección del corporativo"
                  />
                }
                subheader={
                  <FormattedMessage
                    id="globEscCorp"
                    defaultMessage="Escribir la dirección de tu corporativo."
                  />
                }
              />
              <CardContent xs={12}>
                <TextField
                  name="txtdireccion"
                  variant="outlined"
                  fullWidth
                  placeholder="Av.ejemplo No.123, Benito Juarez, C.P. 12345, CDMX."
                  style={{ minWidth: 300, maxRows: 1 }}
                  onChange={handleDireccionChange}
                />
                {direccion ? (
                  <p style={{ color: "green", fontSize: 10 }}>
                    <FormattedMessage
                      id="conGDireVa"
                      defaultMessage="Dirección válida."
                    />
                  </p>
                ) : (
                  <p style={{ color: "red", fontSize: 10 }}>
                    <FormattedMessage
                      id="conGDireNO"
                      defaultMessage="La dirección no puede estar vacia."
                    />
                  </p>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={9}
            container
            spacing={0}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <ButtonVino
              variant="contained"
              color="primary"
              size="medium"
              label={
                <FormattedMessage id="globGuarda" defaultMessage="Guardar" />
              }
              type="submit"
            />
          </Grid>
        </Grid>
      </form>

      <Grid container spacing={1} xs={12}>
        <Grid item xs={5}>
          <Card>
            <CardHeader
              title={
                <FormattedMessage id="conGLengu" defaultMessage="Lenguaje" />
              }
              subheader={
                <FormattedMessage
                  id="conGLengu"
                  defaultMessage="Selecciona el lenguaje."
                />
              }
            />
            <CardContent xs={12}>
              {/*//TODO: Select Language*/}
              <FormControl variant="standard" sx={{ minWidth: 350 }} fullWidth>
                <InputLabel id="demo-simple-select-autowidth-label">
                  <FormattedMessage
                    id="conGSeleLan"
                    defaultMessage="Seleccione el idioma"
                  />
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={language}
                  onChange={handleChangeLanguage}
                  placeholder="Selecciona tu idioma"
                >
                  <MenuItem value="">
                    <em>
                      <FormattedMessage
                        id="conGSeleLan"
                        defaultMessage="Seleccione el idioma"
                      />
                    </em>
                  </MenuItem>
                  <MenuItem
                    onClick={() => idioma.establecerLenguaje("es-MX")}
                    value={"Spanish"}
                  >
                    <img src={mex} alt="MEX" />
                    &nbsp;Español
                  </MenuItem>
                  <MenuItem
                    onClick={() => idioma.establecerLenguaje("en-US")}
                    value={"English"}
                  >
                    <img src={usa} alt="USA" />
                    &nbsp;Inglés
                  </MenuItem>
                  <MenuItem
                    onClick={() => idioma.establecerLenguaje("es-IT")}
                    value={"Italian"}
                  >
                    <img src={italiana} alt="ITA" />
                    &nbsp;Italiano
                  </MenuItem>
                  <MenuItem
                    onClick={() => idioma.establecerLenguaje("es-FR")}
                    value={"French"}
                  >
                    <img src={francesa} alt="FRA" />
                    &nbsp;Francés
                  </MenuItem>
                  <MenuItem
                    onClick={() => idioma.establecerLenguaje("po-BR")}
                    value={"Portuguese"}
                  >
                    <img src={bra} alt="POR" />
                    &nbsp;Portugués
                  </MenuItem>
                </Select>
              </FormControl>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
