/* Brandon Hernandez Rocha 03/2023 */
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import CustomSeparatorDinamic from "../Tools/BreadCrumbs/RecorridoDinamico";
import "../../css/estilo.css";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import { Grid } from "@mui/material";
import { CardDatosReserva } from "./Elements/Cards/CardDatosReserva";
import { Link } from "react-router-dom";
//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "../Tools/Table/StyledTableCell";
import Paper from "@mui/material/Paper";

//Input table
import EditableCell from "../Tools/TextField/EditableCell";
//Botones
import ButtonVino from "../Tools/Bottones/ButtonVino";

//idioma
import { FormattedMessage } from "react-intl";
import TXTField from "../Tools/TextField/TextField";
import { getDataToken, putDataToken } from "../../api/dataToken";
import { formatNumberDecimalComa, formatedNumberAndDecimalAndComa, validateNumberAndDecimal } from "../../utils/functions";
import { TOTAL_CONTRA_ACC_RESERVA, TOTAL_FAVOR_ACC_RESERVA, TOTAL_NETO_ACC_RESERVA } from "../../utils/constanstoken";
import DialogGeneral from "../Tools/Dialog/DialogGeneral";
const CompReservasAjustes = () => {
  const location = useLocation();
  const row = location.state.row;
  const poliza = location.state.poliza;
  console.log("poliza", poliza);
  //Esta parte de código esta pendiente, pues se va a cambiar a dinámico


  const arrayBreadcrumbs = [
    {
      key: 1,
      name: <FormattedMessage id="globInicio" defaultMessage="Inicio" />,
      to: "/menu",
    },
    {
      key: 2,
      name: <FormattedMessage id="menuDictam" defaultMessage="Dictamen" />,
      to: "/dictamen",
    },
    {
      key: 3,
      name: (
        <FormattedMessage id="dictaDetalleF" defaultMessage="Detalle folio" />
      ),
      to: "/dictamen/detalle_folio/reservas",
      state: { poliza, row },
    },
  ];

  /** codigo nuevo */
  //Funcionalidad para modificar los valores preliminares de los accesorios de la reserva (sin asiento contable)
  const [accesorios, setAccesorios] = useState([]); //Arreglo original
  const [txtValuesAccesorios, setTxtValuesAccesorios] = useState([]); // Arreglo de los valores que se modifican
  const [txtValueTotal, setTxtValueTotal] = useState("");
  const [txtValueTotalErr, setTxtValueTotalErr] = useState(false);

  //Validar si ya existen registros de reservas ajustes para este folio y poliza 
  const getDataPolCoberProd = async () => {
    try {
      let dataPCP = await getDataToken("coberturaspolizasportramite/get_folio/" + poliza.RECT_idfolio.RECT_idfolio + "/"); //cambiar por un filtro por folio en api 
      if (dataPCP) {
        if (dataPCP.length > 0) {
          console.log(dataPCP);
          for (const element of dataPCP) {
            //recorrer las polizas del folio 
            if (element.RECT_idfolio.RECT_idfolio === poliza.RECT_idfolio.RECT_idfolio
              && element.POL_idpoliza.POL_idpoliza === poliza.POL_idpoliza.POL_idpoliza) {
                console.log(element)
              //Validar si existe un registro de reserva con esta poliza y cobertura
              let resp = await getDataToken("reservasporpolizaporcoberturas/get_IDPCP/" + element.CPT_idcoberturapolizatramite + "/");
              if (resp.length > 0) { // Existe un registro de reserva
                let dataReserAjus = await getDataToken("reservaajustes/get_idreserva/"+resp[0].id+"/"); //cambiar por un filtro por folio en id PCP
                // let filtroPCP = dataReserAjus.filter((e) => e?.RPC_idreservapolizacob?.id == resp[0].id);

                setAccesorios(dataReserAjus);
                setTxtValuesAccesorios(dataReserAjus)
                let summation = addAccValues(dataReserAjus);
                setTxtValueTotal(summation)
              }
            }
          }
        }
      };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  React.useEffect(() => {
    // getDataAccesorios();
    getDataPolCoberProd();
  }, []);

  //Funcion que detecta los cambios en los accesorios
  const handleInputAccChange = (e, id, obj) => {
    if (e.target.value === "-") {
      return false;
    }
    const formattedValue = formatedNumberAndDecimalAndComa(e);
    // const newDataTxtAcc = [...txtValuesAccesorios]; // Crear una copia nueva del array de objetos
    const newDataTxtAcc = JSON.parse(JSON.stringify(txtValuesAccesorios)); // Crear una copia más profunda del array de objetos
    newDataTxtAcc.forEach((c, i) => {
      if (c.id === id) {
        c.REAJ0_importe = formattedValue;
      }
    });
    setTxtValuesAccesorios(newDataTxtAcc);
  };
  //UseEffect para validar la sumatoria entre los accesorios 
  useEffect(() => {
    let suma = addAccValues(txtValuesAccesorios);
    if (suma < 0) {
      setTxtValueTotalErr(true)
    } else {
      setTxtValueTotalErr(false)
    }
    setTxtValueTotal(formatNumberDecimalComa(suma))
  }, [txtValuesAccesorios])

  // Funcion para sumar los accesorios al total neto
  const addAccValues = (objects) => {
    let suma = 0;
    for (let obj of objects) {
      if (obj.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte == -1 ||
        obj.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte == 1) {
        suma += parseFloat(validateNumberAndDecimal(((obj.REAJ0_importe).replace(/,/g, '') * parseInt(obj.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte))))
      }
    }
    return suma.toFixed(2);
  };
  // Funcion para sumar los totales, si es tipo 1 para total a favor, 2 para total en contra 
  const getAccValues = (objects, type) => {
    let summation = 0;
    for (let obj of objects) {
      if (obj.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte == -1 && type === 2) {
        summation += parseFloat(validateNumberAndDecimal(((obj.REAJ0_importe).replace(/,/g, '') * parseInt(obj.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte))))
      }
      else if (obj.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte == 1 && type === 1) {
        summation += parseFloat(validateNumberAndDecimal(((obj.REAJ0_importe).replace(/,/g, '') * parseInt(obj.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte))))
      }
    }
    return summation.toFixed(2);
  };
  //*****SECCIÓN PARA CONTROLAR EL ESTADO DEL DIÁLOGO GNERAL *****
  const [showDialogGral, setShowdialogGral] = useState(0)

  // ***************************EMPIEZA APARTADO PARA ACTUALIZAR VALORES ACCESORIOS ***************************

  //Funcion para realizar las actualizaciones a las apis
  const saveData = async () => {
    let flag = true; // Auxiliar para cambiar el estado del DialogoGeneral
    //Comparar cambios de los datos originales
    // Recorrer el arreglo de accesorios original y compararlo con el que se hacen cambios 
    for (const accesorio of accesorios) {
      //Buscar el objeto en el arreglo de cambios 
      let findAcc = txtValuesAccesorios.find((obj) => accesorio.id == obj.id);
      if (findAcc && parseFloat(findAcc.REAJ0_importe) != parseFloat(accesorio.REAJ0_importe)) {
        // Realizar los put correspondientes
        //Actualizar importes en contra
        if (findAcc?.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte == -1) {
          //Actualizar importe 
          let jsonImpContra = {
            REAJ0_importe: validateNumberAndDecimal((findAcc.REAJ0_importe).replace(/,/g, ''))
          };
          let dataAcc = await putDataToken(`reservaajustes/get_id/${accesorio.id}/`, jsonImpContra);
          if (!dataAcc) {
            flag = false;
            return false;
          }
        } //Actualizar importes a favor
        else if (findAcc?.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte == 1) {
          //Actualizar importe 
          let jsonImpFavor = {
            REAJ0_importe: validateNumberAndDecimal((findAcc.REAJ0_importe).replace(/,/g, ''))
          };
          let dataAcc = await putDataToken(`reservaajustes/get_id/${accesorio.id}/`, jsonImpFavor);
          if (!dataAcc) {
            flag = false;
            return false;
          }
        }

        //Buscar el objeto que corresponde al valor neto 
        let findTotalNeto = accesorios.find((obj) => obj.CREVAJ0_idconceptoreservaajuste.CREVAJ0_idconceptoreservaajuste == TOTAL_NETO_ACC_RESERVA.id);
        if (findTotalNeto) {
          //Actualizar importe Total neto
          let jsonTotalNeto = {
            REAJ0_importe: addAccValues(txtValuesAccesorios)
          };
          let dataTotalNeto = await putDataToken(`reservaajustes/get_id/${findTotalNeto.id}/`, jsonTotalNeto);
          if (!dataTotalNeto) {
            flag = false;
            return false;
          }
        }
        //Buscar el objeto que corresponde al valor a favor 
        let findTotalFavor = accesorios.find((obj) => obj.CREVAJ0_idconceptoreservaajuste.CREVAJ0_idconceptoreservaajuste == TOTAL_FAVOR_ACC_RESERVA.id);
        if (findTotalFavor) {
          //Actualizar importe a favor
          let jsonTotalNeto = {
            REAJ0_importe: getAccValues(txtValuesAccesorios, 1)
          };
          let dataTotalFavor = await putDataToken(`reservaajustes/get_id/${findTotalFavor.id}/`, jsonTotalNeto);
          if (!dataTotalFavor) {
            flag = false;
            return false;
          }
        }
        //Buscar el objeto que corresponde al valor en contra
        let findTotalContra = accesorios.find((obj) => obj.CREVAJ0_idconceptoreservaajuste.CREVAJ0_idconceptoreservaajuste == TOTAL_CONTRA_ACC_RESERVA.id);
        if (findTotalContra) {
          //Actualizar importe en contra
          let jsonTotalNeto = {
            REAJ0_importe: getAccValues(txtValuesAccesorios, 2)
          };
          let dataTotalContra = await putDataToken(`reservaajustes/get_id/${findTotalContra.id}/`, jsonTotalNeto);
          if (!dataTotalContra) {
            flag = false;
            return false;
          }
        }
        //Modificar el valor de calculo ajustes en reservasporpolizaporcoberturas
        let jsonReserPolCob = {
          RPC_calculoAjustes: addAccValues(txtValuesAccesorios),
          RPC_importeReservaAjustes: addAccValues(txtValuesAccesorios)
        };
        let dataCalculoRes = await putDataToken(`reservasporpolizaporcoberturas/get_id/${accesorio.RPC_idreservapolizacob.id}/`, jsonReserPolCob);
        if (!dataCalculoRes) {
          flag = false;
          return false;
        }
      }
    }
    setShowdialogGral(flag);
  }
  // ***************************TERMINA APARTADO PARA ACTUALIZAR VALORES ACCESORIOS ***************************

  /** codigo nuevo */

  return (
    <div className="div-parent">
      <div className="div-breadcrumbs">
        <CustomSeparatorDinamic
          breadcrumbs={arrayBreadcrumbs}
          name={<FormattedMessage id="globAjus" defaultMessage="Ajuste" />}
        />
      </div>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Link
          style={{ textDecoration: "none" }}
          to="/dictamen/detalle_folio/reservas"
          state={{ poliza, row }}
        >
          <ReturnPage />
        </Link>
      </Grid>
      <div className="div-container">
        <CardDatosReserva />
        <hr />
        <form>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={4}
          >
            {/* Tabla Importes a favor */}
            <Grid item sx={4}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <h1-dialog-title>
                    <FormattedMessage
                      id="dictamImportesatFavo"
                      defaultMessage="Importes a favor"
                    />
                  </h1-dialog-title>
                </Grid>
                <Grid item>
                  <Paper style={{ width: "100%" }}>
                    <TableContainer
                      sx={{ showEmptyDataSourceMessage: true }}
                      style={{ position: "sticky" }}
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          "& .MuiTableRow-root:hover": {
                            backgroundColor: "#ededed",
                          },
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>
                              <FormattedMessage
                                id="dictamRese"
                                defaultMessage="Reservas"
                              />
                            </StyledTableCell>

                            {/* Encabezado de la tabla importes a favor */}
                            <StyledTableCell align="center">
                              <FormattedMessage
                                id="globImport"
                                defaultMessage="Importe"
                              />
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* Aqui los resultados de la api accesorios a favor */}
                          {txtValuesAccesorios.map((element, index) => {
                            if (element?.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte == 1) {
                              return (<TableRow>
                                <StyledTableCell component="th" scope="row" align="left">
                                  {`(+) ${element.CREVAJ0_idconceptoreservaajuste.CREVAJ0_conceptoreservaaj}`}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <TXTField size="small" showStartSimboloPeso={true} textAlign="right" defaultValue={0} value={txtValuesAccesorios.id || element.REAJ0_importe} onChange={(e) => handleInputAccChange(e, element.id, element)} />
                                </StyledTableCell>
                              </TableRow>)
                            }
                          })}
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              <FormattedMessage
                                id="globTolAFa"
                                defaultMessage="Total a favor"
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              {" "}
                              <TXTField size="small" showStartSimboloPeso={true}
                                textAlign="right"
                                defaultValue={""}
                                value={formatNumberDecimalComa(getAccValues(txtValuesAccesorios, 1))} />
                            </StyledTableCell>
                          </TableRow>

                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            {/* Tabla Importes a descontar */}
            <Grid item sx={4}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <h1-dialog-title>
                    <FormattedMessage
                      id="dictImDesc"
                      defaultMessage="Importes a descontar"
                    />
                  </h1-dialog-title>
                </Grid>
                <Grid item>
                  <Paper style={{ width: "100%" }}>
                    <TableContainer
                      sx={{ showEmptyDataSourceMessage: true }}
                      style={{ position: "sticky" }}
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          "& .MuiTableRow-root:hover": {
                            backgroundColor: "#ededed",
                          },
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>
                              <FormattedMessage
                                id="globReserv"
                                defaultMessage="Reserva"
                              />
                            </StyledTableCell>

                            {/* Encabezado de la tabla importes a favor */}
                            <StyledTableCell align="center">
                              <FormattedMessage
                                id="globImport"
                                defaultMessage="Importe"
                              />
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* Aqui los resultados de la api accesorios en contra */}
                          {txtValuesAccesorios.map((element, index) => {
                            if (element?.CREVAJ0_idconceptoreservaajuste?.CREVAJ0_tipoimporte == -1) {
                              return (<TableRow>
                                <StyledTableCell component="th" scope="row" align="left">
                                  {`(-) ${element.CREVAJ0_idconceptoreservaajuste.CREVAJ0_conceptoreservaaj}`}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <TXTField size="small" showStartSimboloPeso={true} textAlign="right" defaultValue={0} value={txtValuesAccesorios.id || element.REAJ0_importe} onChange={(e) => handleInputAccChange(e, element.id, element)} />
                                </StyledTableCell>
                              </TableRow>)
                            }
                          })}
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              <FormattedMessage
                                id="globTolContra"
                                defaultMessage="Total en contra"
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <TXTField size="small" showStartSimboloPeso={true}
                                textAlign="right"
                                defaultValue={""}
                                value={formatNumberDecimalComa(getAccValues(txtValuesAccesorios, 2))} />
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            {/* Tabla de totales */}
            <Grid item sx={4}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <h1-dialog-title>
                    <FormattedMessage
                      id="dictamTota"
                      defaultMessage="Totales"
                    />
                  </h1-dialog-title>
                </Grid>
                <Grid item>
                  <Paper style={{ width: "100%" }}>
                    <TableContainer
                      sx={{ showEmptyDataSourceMessage: true }}
                      style={{ position: "sticky" }}
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                          "& .MuiTableRow-root:hover": {
                            backgroundColor: "#ededed",
                          },
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>
                              <FormattedMessage
                                id="dictamRese"
                                defaultMessage="Reserva"
                              />
                            </StyledTableCell>

                            {/* Encabezado de la tabla importes a favor */}
                            <StyledTableCell
                              align="center"
                              style={{ minWidth: "200px" }}
                            >
                              <FormattedMessage
                                id="globImport"
                                defaultMessage="Importe"
                              />
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                              style={{
                                paddingTop: "20px",
                                paddingBottom: "20px",
                              }}
                            >
                              <FormattedMessage
                                id="globTolAFa"
                                defaultMessage="Total a favor"
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              {formatNumberDecimalComa(getAccValues(txtValuesAccesorios, 1))}
                              {/* $ {formatNumber(totales.totalFavor.toFixed(2))} */}
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                              style={{
                                paddingTop: "20px",
                                paddingBottom: "20px",
                              }}
                            >
                              <FormattedMessage
                                id="dictamTotalaDesconta"
                                defaultMessage="Total a descontar"
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              {formatNumberDecimalComa(getAccValues(txtValuesAccesorios, 2))}
                              {/* $ {formatNumber(totales.totalContra.toFixed(2))} */}
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                              style={{
                                paddingTop: "20px",
                                paddingBottom: "20px",
                              }}
                            >
                              {" "}
                              <FormattedMessage
                                id="dictaTotNe"
                                defaultMessage="Total neto"
                              />{" "}
                              (=)
                            </StyledTableCell>
                            <StyledTableCell>
                              {formatNumberDecimalComa(addAccValues(txtValuesAccesorios))}
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
                <Grid item style={{ marginTop: "50px" }}>
                  <Grid container>
                    <Grid item>
                      <TableBody>
                        <TableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="left"
                          >
                            Total de reserva {poliza.POL_idpoliza.PRO_idproductos.MON_idmonedas.MON_abreviatura}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumberDecimalComa(accesorios[0]?.RPC_idreservapolizacob?.RPC_importeReserva)}
                            {/* $ {formatNumber(balance.toFixed(2))}{" "} */}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="left"
                          >
                            <FormattedMessage
                              id="dictaTotNe"
                              defaultMessage="Total neto"
                            />{" "}
                            (=)
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumberDecimalComa(addAccValues(accesorios))}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="left"
                          >
                            <FormattedMessage
                              id="dictBalToRe"
                              defaultMessage="Balance total de reserva"
                            />
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumberDecimalComa(accesorios[0]?.RPC_idreservapolizacob?.RPC_balance)}
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Botones */}
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item>
              <ButtonVino
                label={
                  <FormattedMessage id="dictamBorr" defaultMessage="Borrar" />
                }
                type="button"
              />
            </Grid>
            <Grid item>
              <ButtonVino
                label={
                  <FormattedMessage id="globEditar" defaultMessage="Editar" />
                }
              />
            </Grid>
            <Grid item>
              {/* <Link
                style={{ textDecoration: "none" }}
                to="/dictamen/detalle_folio/reservas"
                state={{ poliza, row }}
              >
                <ButtonVino
                  label={
                    <FormattedMessage
                      id="globGuarda"
                      defaultMessage="Guardar"
                    />
                  }
                  onClick={()=>saveData()}
                />
              </Link> */}
              <ButtonVino
                label={
                  <FormattedMessage
                    id="globGuarda"
                    defaultMessage="Guardar"
                  />
                }
                onClick={() => saveData()}
              />
            </Grid>
            {/* SECCIÓN PARA MOSTRAR DIÁLOGO GENERAL */}
            {showDialogGral === 0 ? null : (!showDialogGral ? <DialogGeneral type={false} open={true} onClickAccept={() => {
              getDataPolCoberProd()
              setShowdialogGral(0)
            }} /> :
              <DialogGeneral type={true} open={true} onClickAccept={() => {
                getDataPolCoberProd()
                setShowdialogGral(0)
              }} />

            )
            }
          </Grid>
        </form>
      </div>
    </div>
  );
};
export default CompReservasAjustes;
