import React from 'react';
import { Page, Text, Document, View, StyleSheet, Image,  PDFViewer } from '@react-pdf/renderer';
import imgheadersinay from "../../../img/header_sinay.png";
import imglogo from "../../../img/logo_sinay.png";

const styles = StyleSheet.create({
    page: {
      // backgroundColor: "#d11fb6",
      // color: "white",
    },
    sectionheader: {
      // margin: 10,
      padding: 5,
      display: "flex",
    },
    sectionbody: {
      margin: 15,
      padding: 15,
    },
    viewer: {
      width: window.innerWidth - 760,
      height: window.innerHeight,
    },
    imagelogo: { width: 70, height: 70, float: "left" },
    title: { fontSize: 14, fontWeight: "extrabold" },
    subtitle: { fontSize: 13 },
    parrafo: {
      fontWeight: "normal",
      fontSize: 12,
      textAlign: "justify",
      padding: 15,
    },
  });
const Mydocument = (props) => {
    return(
        <PDFViewer style={styles.viewer}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View
              style={{
                display: "flex",
                padding: 15,
                flexDirection: "row",
              }}
            >
              <Image style={{ flex: 1 }} src={imglogo} />
              <Image style={{ flex: 7 }} src={imgheadersinay} />
            </View>
            <View style={styles.sectionbody}>
              <Text>{props.hola}</Text>
              
            </View>
          </Page>
        </Document>
      </PDFViewer>
);
};
export default Mydocument;