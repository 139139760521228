/* 
Pajita
Proyecto SINAY
2023
*/
import React from "react";
//breadcrumb
import Recorrido4 from "./Elements/Bread/Recorrido4";
//Tabla
import TableCoverage from "./Elements/TableCoverage";
//Boton
import { Grid } from "@mui/material";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
//Estilos
import "../../css/estilo.css";
import HeaderCobertura from "./Elements/Header/HeaderCobertura";
//Idioma
import { FormattedMessage } from "react-intl";
//Libreria para poder pasar información
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export function CompCobertura() {
  const location = useLocation();
  const infoRow = location.state.paso;
  const InfoDataRow = location.state.paso;
  const sharedState = infoRow;
  console.log(infoRow);
  console.log("Shared coberturas" + sharedState);

  return (
    <div className="div-parent">
      <div className="div-breadcrumbs">
        {/*navegador*/}
        <Recorrido4
          name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
          to="/menu"
          name2={<FormattedMessage id="globRegist" defaultMessage="Registro" />}
          to2="/registro/inicio"
          name3={
            <FormattedMessage
              id="submenuIni"
              defaultMessage="Iniciar registro"
            />
          }
          name4={
            <FormattedMessage
              id="regisValCo"
              defaultMessage="Validar cobertura"
            />
          }
          to3="/registroasegurado"
          state3={{ InfoDataRow }}
          // enlace3="/registroasegurado"
        />
      </div>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Link
          to="/registroasegurado"
          state={{ InfoDataRow }}
          style={{ textDecoration: "none" }}
        >
          <ReturnPage />
        </Link>
      </Grid>
      <div className="div-container">
        <Grid container>
          {/*Contenido*/}

          <Grid xs={12}>
            <HeaderCobertura infoRow={infoRow} />
          </Grid>
          <div style={{ justifyContent: "center", width: "100%" }}>
            <TableCoverage sharedState={sharedState} />
          </div>
        </Grid>
      </div>
    </div>
  );
}
