import admin from "../../img/Conf/admin.png"
import conf from "../../img/Conf/conf.svg"
import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { getDataToken } from "../../api/dataToken";
import * as React from "react";
import {  useEffect } from 'react'
//idioma
import { FormattedMessage } from "react-intl";


export function CompMenuAdmin() {
  //Alerta function
  // const [showAlert, setShowAlert] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [dataX, setDataX] = React.useState([])

  const getDataPermisos = async () => {

    //Resultados de la api investigacion
    var resultPermisos = await getDataToken("login/get_correo/"+  localStorage.getItem("SNYTLOG_email") + "/");    
    setDataX(resultPermisos);
    
  }

  useEffect(() => {
    getDataPermisos()  
  }, [])





  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  //href para medico
  const clickVidaInd = () => {
    //if(dataX.groups.includes(17) == true){
      window.location = "/admin";
    //};
    
  };
  //href para Estadistico Contable
  const clickVidaGru = () => {
      window.location = "/confadmin";
  };

  //Cambiar imagenes on a off y veceversa
  const [imgVidaInd, setImgVidaInd] = useState(false);
  const [imgVidaGru, setImgVidaGru] = useState(false);


  var storageUser = localStorage.getItem("SNYTLOG_email");



  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        id="div-parent"
      >
        {/* MENÚ VIDA INDIVIDUAL */}
        <Grid
          item
          xs={3}
          className="div-children-menu"
          onClick={clickVidaInd}
          onMouseEnter={() => {
            setImgVidaInd(true);
          }}
          onMouseLeave={() => {
            setImgVidaInd(false);
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <center>
                <Grid xs={12}>
                  {!imgVidaInd ? (
                   <img className="img-iconos" src={admin} alt="administradorsinay" />
                  ) : (
                    <img className="img-iconos" src={admin} alt="administradorsinay" />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label>
                  <FormattedMessage
                id="Mod-ConfGen-Title1"
                defaultMessage="Administración de usuarios de Sinay"
              />
                  </label>
                </Grid>
              </center>
            </Grid>
          </Grid>
        </Grid>
        {/* MENU VIDA GRUPO */}
        <Grid
          item
          xs={3}
          className="div-children-menu"
          onClick={clickVidaGru}
          onMouseEnter={() => {
            setImgVidaGru(true);
          }}
          onMouseLeave={() => {
            setImgVidaGru(false);
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <center>
                <Grid xs={12}>
                  {!imgVidaGru ? (
                    <img className="img-iconos" src={conf} alt="Registro" />
                  ) : (
                    <img className="img-iconos" src={conf} alt="Registro" />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label>
                  <FormattedMessage
                id="Mod-ConfsaGen-Title2"
                defaultMessage="Negocio Configurable"
              />
                  </label>
                </Grid>
              </center>
            </Grid>
          </Grid>
        </Grid>
        {/* FIN DE MENUS */}
      </Grid>
    </>
  );
}
