/* Brandon Hernandez Rocha 11/2022 */
import React, { useState, useEffect } from "react";
import CustomSeparatorDinamic from "../Tools/BreadCrumbs/RecorridoDinamico";
import "../../css/estilo.css";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CardDetalleFolio } from "./Elements/Cards/CardDetalleFolio";
import DatePicker from "../Registro/Elements/DatePicker/DatepickerSini";
import TXTField from "../Tools/TextField/TextField";
import ButtonVino from "../Tools/Bottones/ButtonVino";
import { putDataToken, getDataToken, postDataToken } from "../../api/dataToken";
import getDate from "../Registro/Function/obtenerFecha";
import getHours from "../Registro/Function/obtenerHora";
import moment from "moment-timezone";
import { LimitarTexto } from "../Recepcion/Elements/Functions/Validaciones";
import DialogAviso from "../Registro/Elements/Dialog/DialogAviso";

//idioma
import { FormattedMessage } from "react-intl";

const CompDictamenTec = () => {
  const location = useLocation();
  const infoRow = location.state.row;
  console.log(infoRow);

  const arrayBreadcrumbs = [
    {
      key: 1,
      name: <FormattedMessage id="globInicio" defaultMessage="Inicio" />,
      to: "/menu",
    },
    {
      key: 2,
      name: <FormattedMessage id="menuDictam" defaultMessage="Dictamen" />,
      to: "/dictamen",
    },
    {
      key: 3,
      name: (
        <FormattedMessage id="dictaDetalleF" defaultMessage="Detalle folio" />
      ),
      to: "/dictamen/detalle_folio",
      state: { row: infoRow },
    },
  ];

  //Obtener fecha
  const fecha = getDate();
  const fechaEmpAn = moment(fecha, "DD/MM/YYYY").format("YYYY-MM-DD");

  //Obtener la hora
  const hora = getHours();
  const fechaActSIN = `${fechaEmpAn}T${hora}`;

  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");

  const [datos, setDatos] = useState([]);
  const [fechaTecnicoApi, setFechaTecnicoApi] = useState(null);
  const getData = async () => {
    try {
      var resultDictamenTecnico = await getDataToken(
        `dictamentecnicoxpolizacoberturaproducto/get_filtro/?POL_idpoliza=${infoRow[0]?.POL_idpoliza?.POL_idpoliza}&PER_idpersona=${infoRow[0]?.PER_idpersona?.PER_idpersona}&RECT_idfolio=${infoRow[0]?.RECT_idfolio?.RECT_idfolio}&SIN_idsiniestro=${infoRow[0]?.SIN_idsiniestro?.SIN_idsiniestro}`
      );
      console.log(resultDictamenTecnico);
      setDatos(resultDictamenTecnico);
      const fechaFormateada = moment(
        resultDictamenTecnico[0]?.DICTT_fechadictamentecnico
      ).format("YYYY-MM-DD");
      setFechaTecnicoApi(fechaFormateada);
      var result = await getDataToken(`login/get_correo/${email}/`);
      setnombreAnalista(
        result.SNYTLOG_username +
          " " +
          result.SNYTLOG_surnamedad +
          " " +
          result.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getData();
  }, [infoRow]);

  console.log(fechaTecnicoApi);

  const [fechaTecnico, setFechaTecnico] = useState("");

  const handleDataChanges = (newData) => {
    const fecha = newData.toISOString();
    const mexicoCityTime = moment(fecha).tz("America/Mexico_City");
    setFechaTecnico(mexicoCityTime.format("YYYY-MM-DD"));
  };
  console.log(fechaTecnico);

  const [values, setValues] = React.useState({
    DICTT_dictamentecnico: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  React.useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      DICTT_dictamentecnico: datos[0]?.DICTT_dictamentecnico,
    }));
  }, [datos]);

  async function postDictamenTecnico(aux) {
    try {
      var result = await postDataToken(
        `dictamentecnicoxpolizacoberturaproducto/get_listado/`,
        aux
      );
      console.log("Registro exitoso", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const [visiblePost, setVisiblePost] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);

  const validateAndSubmitForm = (e) => {
    e.preventDefault();
    if (datos.length == 0) {
      let json = {
        POL_idpoliza: infoRow[0]?.POL_idpoliza?.POL_idpoliza,
        PER_idpersona: infoRow[0]?.PER_idpersona.PER_idpersona,
        RECT_idfolio: infoRow[0]?.RECT_idfolio?.RECT_idfolio,
        SIN_idsiniestro: infoRow[0]?.SIN_idsiniestro.SIN_idsiniestro,
        DICTT_fechadictamentecnico: fechaTecnico
          ? fechaTecnico
          : fechaTecnicoApi,
        DICTT_dictamentecnico: values.DICTT_dictamentecnico,
        DICTT_actualizofecha: fechaActSIN,
        DICTT_actualizousuario: nombreAnalista,
      };
      console.log(json);
      postDictamenTecnico(json);
      setVisiblePost(true);
    } else {
      let actualizar = {
        DICTT_fechadictamentecnico:
          fechaTecnico === ""
            ? datos[0]?.DICTT_fechadictamentecnico
            : fechaTecnico,
        DICTT_dictamentecnico:
          values?.DICTT_dictamentecnico === ""
            ? datos[0]?.DICTT_dictamentecnico
            : values.DICTT_dictamentecnico,
        DICTT_actualizofecha: fechaActSIN,
        DICTT_actualizousuario: nombreAnalista,
      };
      console.log(actualizar);
      putDataToken(
        `dictamentecnicoxpolizacoberturaproducto/get_id/${datos[0].DICTT_iddictamenproducto}/`,
        actualizar
      ).then((data) => {
        console.log(
          `Registro ${datos[0].DICTT_iddictamenproducto} actualizado`
        );
        console.log(data);
      });
      setVisibleUpdate(true);
    }
  };

  const [editCampos, setEditCampos] = useState(true);
  useEffect(() => {
    setEditCampos(datos.length === 0 ? false : true);
  }, [datos]);
  console.log(editCampos);
  const editarCampos = (e) => {
    setEditCampos(false);
  };

  return (
    <div>
      {visibleUpdate && (
        <Link
          to="/dictamen/detalle_folio"
          style={{ textDecoration: "none" }}
          state={{ row: infoRow }}
        >
          <DialogAviso
            title="Registro actualizado"
            firstButton={
              <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
            }
            description={"Dictamen técnico actualizado correctamente"}
          />
        </Link>
      )}
      {visiblePost && (
        <Link
          to="/dictamen/detalle_folio"
          style={{ textDecoration: "none" }}
          state={{ row: infoRow }}
        >
          <DialogAviso
            title="Registro exitoso"
            firstButton={
              <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
            }
            description={"Dictamen técnico guardado correctamente"}
          />
        </Link>
      )}
      <div className="div-parent">
        <div className="div-breadcrumbs">
          <CustomSeparatorDinamic
            breadcrumbs={arrayBreadcrumbs}
            name={`Técnico`}
          />
        </div>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <Link
            style={{ textDecoration: "none" }}
            to="/dictamen/detalle_folio"
            state={{ row: infoRow }}
          >
            <ReturnPage />
          </Link>
        </Grid>
        <div className="div-container">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12}>
              <h3-header-card>
                <FormattedMessage id="globPoliza" defaultMessage="Póliza" />
                {` ${infoRow?.[0]?.POL_idpoliza?.POL_numpoliza}`}
              </h3-header-card>
            </Grid>
            <Grid item xs={12}>
              <CardDetalleFolio infoRow={infoRow} />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12}>
                  <h3-header-card>
                    <FormattedMessage
                      id="dictamDictamenTecnic"
                      defaultMessage="Dictamen técnico"
                    />
                  </h3-header-card>
                </Grid>
                <Grid item xs={4}>
                  <h3-title-card>
                    <FormattedMessage
                      id="dictamFechaDeCaptura"
                      defaultMessage="Fecha de captura:"
                    />
                  </h3-title-card>
                  <br />
                  {fechaTecnicoApi && (
                    <DatePicker
                      fechaSiniestro={fechaTecnicoApi}
                      onDataChange={handleDataChanges}
                      disabled={editCampos}
                    />
                  )}
                  {!fechaTecnicoApi && (
                    <DatePicker onDataChange={handleDataChanges} />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <h3-title-card>
                    <FormattedMessage
                      id="globObserv"
                      defaultMessage="Observaciones:"
                    />
                  </h3-title-card>
                  <TXTField
                    id="txtDictamTecnico"
                    name="txtDictamTecnico"
                    type="text"
                    size="small"
                    multiline={true}
                    value={values?.DICTT_dictamentecnico}
                    onChange={handleChange("DICTT_dictamentecnico")}
                    onInput={(e) => LimitarTexto(e.target, 255)}
                    required
                    disabled={editCampos}
                    rows={2}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item>
                  {datos.length > 0 && (
                    <ButtonVino
                      label="Editar"
                      onClick={(e) => editarCampos(e)}
                      color="secondary"
                    />
                  )}
                </Grid>
                <Grid item>
                  <ButtonVino
                    onClick={validateAndSubmitForm}
                    label={
                      datos.length > 0 ? (
                        <FormattedMessage
                          id="globGuarda"
                          defaultMessage="Modificar"
                        />
                      ) : (
                        <FormattedMessage
                          id="globGuarda"
                          defaultMessage="Guardar"
                        />
                      )
                    }
                    estatus={
                      fechaTecnico && values?.DICTT_dictamentecnico != ""
                        ? false
                        : true
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default CompDictamenTec;
