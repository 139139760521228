// Brandon Hernandez 11/2022
import * as React from "react";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchTXTField(props) {
  const showSearchIcon = props.showSearchIcon
  //Estilo del helpertext
  const useHelperTextStyles = makeStyles(() => ({
    root: {
      background: '#fafafa',
      margin: '0px !important',
      paddingLeft: '10px'
    }
  }));
  const helperTextStyles = useHelperTextStyles();
  return (
    <TextField
      //Tamaño del textfield
      fullWidth
      // Identificar del texto
      value={props.value}
      // Identificador
      id={props.id}
      // Texto que mostrara el componente
      label={props.label}
      // Tipo de entrada
      type={props.type}
      // Texto que se muestra debajo del componente como indicaciones
      helperText={props.helperText}
      // Tamaño del componente
      size={props.size}
      // Si queremos que el componente sea requerido colocamos: required={true}
      required={props.required}
      // Si queremos que el componente sea multiline colocamos: multiline={true}
      multiline={props.multiline}
      // Si queremos que el componente sea multiline y tenga filas por default colocamos: rows={5}
      rows={props.rows}
      // El evento onchange ocurre cuando el valor de un elemento ha cambiado.
      onChange={props.onChange}
      // El onclickevento ocurre cuando el usuario hace clic en un elemento.
      onClick={props.onClick}
      // Estos valores no cambiaran ya que son los que se definieron para estandarizar, no se pide valor
      variant="outlined"
      // El color tampoco cambia es el definido por la empresa, no se pide valor
      color="primary"
      // Son los margenes que dan en los 4 lados, no se pide valor
      margin="normal"
      // Esta propiedad nos brinda darle diseño al componente, nosotros solo utilizaremos el
      // background para que lo muestre en color blanco, no se pide valor
      sx={{
        background: "white",
      }}
      FormHelperTextProps={{
        classes: {
          root: helperTextStyles.root
        }
      }}
      //Placeholder, texto que ayuda al usuario a saber que colocar en el text field
      placeholder={props.placeholder}
      InputProps={{
        endAdornment: <InputAdornment position="start">{!showSearchIcon?"":<SearchIcon/>}</InputAdornment>
      }}
    />
  );
}
