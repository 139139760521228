import React from "react";
import Users from "../../../../components/GeneralConf/Roles/Users";


export function userss() {
    return (
        <div>
            
            <Users/>
        
        </div>
    );
}