import React from "react";
import MailEnt  from '../../../../components/GeneralConf/Mail/MailEnt';


export function inputmail() {
    return (
        <div>
          
            <MailEnt />
           
        </div>
    );
}