import * as React from "react";
import { useState, useEffect, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import GraficaTiempo from "../Graficas/GraficaTiempo";
import moment from "moment";
import { getDataToken } from "../../../api/dataToken";
import { generatePDFWithFetch } from "../../../api/reportes"
import { URLAPIIMG } from "../../../utils/constans";
import html2canvas from "html2canvas";
import ButtonVino from "../../Tools/Bottones/ButtonVino";

//importaciones del idioma al componente
import { FormattedMessage } from "react-intl";


export default function ConsultaTiempo({ folios }) {

   // Funcion para obtener la fecha
   var fechaInicioInput = document.getElementById('fechaInicio');
   var fechaFinInput = document.getElementById('fechaFin');
   // Obtiene el valor del atributo 'value'
   var valorFechaInicio = fechaInicioInput.value;
   var valorFechaFin = fechaFinInput.value;
   // Imprime el valor en la consola
   // console.log("Inicio", valorFechaInicio);
   // console.log("Fin", valorFechaFin);

  const componentRef = useRef();
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const formattedDate = moment().format("LL"); // Formato "Ciudad de México a D [de] MMMM [de] YYYY"
    setCurrentDate(formattedDate);
  }, []);

  const [datosConf, setdatosConf] = useState([]);
  // Get para el diseño de las graficas
  const getUsers = async () => {
    try {

      var resultConf = await getDataToken(`configuraciongeneral/get_id/1/`);
      setdatosConf(resultConf);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getUsers();
  }, []);


  const url = `templatesCartas/generate-reporteWF/`;
  const rutalogo = `${URLAPIIMG}${datosConf?.SCG_imgPathSing}`;
  const rutaimg = `${URLAPIIMG}${datosConf?.SCG_imgPathSubLogo}`;
  const param_pie = `${URLAPIIMG}${datosConf?.SCG_imgPathPiePagina}`;
  const grafica = "https://dev.apis.sinay.com.mx/media/images/logo_qait.png ";
  const p = "REPORTE GLOBAL DE GESTION DE DIAS";
  const p1 = `${datosConf?.SCG_estadoCarta} a ${currentDate}`;
  const p3 = `${datosConf?.SCG_direccionAseguradora}`;

  const generatePDF = async () => {
    const scale = 8;
    const chartImage = await html2canvas(componentRef.current, {
      scale: scale,
    }).then(canvas => canvas.toDataURL("image/png"));
    let json = {
      param_logo: rutalogo,
      param_imagen: rutaimg,
      param_pie: param_pie,
      param_grafica: chartImage,
      param: p,
      param1: p1,
      param2: "Reporte de las fechas" + "   " + valorFechaInicio + '|' + valorFechaFin,
      param3: p3,
    };

    // Llama a la función para generar el PDF
    generatePDFWithFetch(url, json);
  };
  //Fin de area de impresion

  const [inFolio, setFolios] = useState(folios);
  useEffect(() => {
    setFolios(folios);
  }, [folios]);

  // Obtener datos únicos de módulos y fechas
  const uniqueModules = Array.from(new Set(inFolio.map(item => item.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_descripcion)));

  const modulesData = uniqueModules.map(module => {
    const moduleData = inFolio.find(item => item.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_descripcion === module);
    const creationDate = moment(moduleData.WKT_fechawktramite);
    const daysSinceCreation = moment().diff(creationDate, 'days');
    return { module, daysSinceCreation };
  });

  // console.log('Modules Data:', modulesData);

  const uniqueTiempo = [...new Set(folios.map((row) => row.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_descripcion))];

  return (
    <div>
      <Grid container ref={componentRef} justifyContent="center" alignItems="center">
        <Paper sx={{ width: "40%", mb: 4 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 180 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#00203B", color: "white" }}>
                  <TableCell
                    style={{ backgroundColor: "#00203B", color: "white" }}
                  >
                    <FormattedMessage id="wtModulos" defaultMessage="Modulos" />
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#00203B", color: "white" }}
                  >
                    <FormattedMessage
                      id="wtTiempoPorModuloEnD"
                      defaultMessage="Tiempo por módulo en dias"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {modulesData.map((row) => (
                  <TableRow
                    key={row.module}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.module}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.daysSinceCreation}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <br />
        <Grid xs={12}>
          {" "}
          <GraficaTiempo folios={folios} />
        </Grid>
      </Grid>
      <br/><br/><br/><br/>
      <Grid container justifyContent="center" alignItems="center">
        <ButtonVino
          label={
            <FormattedMessage id="globImprim" defaultMessage="Imprimir" />
          }
          onClick={generatePDF}
        />
      </Grid>
    </div>
  );
}
