/* Brandon Hernandez Rocha 12/2022 */
import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { Grid } from "@mui/material";
import IconEliminar from "../../../Tools/Icons/IconEliminar";
//idioma
import { FormattedMessage } from "react-intl";
import { putDataToken } from "../../../../api/dataToken";
//Aparecera en la segunda alerta dos botones
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogCartaEliminarDest(props) {
  //PARENT DIALOG
  const [open, setOpen] = React.useState(false);
  const arrayInfoDataRow = props.infoDataRow;
  console.log(arrayInfoDataRow);

  const eliminarDatosBeneficiario = async () => {
    try {
      let jsonBene = {
        PER_activo: false,
      };
      let jsonBenePxP = {
        PXP_activo: false,
        POL_idpoliza: arrayInfoDataRow?.POL_idpoliza?.POL_idpoliza,
        PER_idpersona: arrayInfoDataRow?.PER_idpersona?.PER_idpersona,
      };
      let result = await putDataToken(
        `personasxpoliza/get_id/${arrayInfoDataRow?.PXP_idpersonaxpoliza}/`,
        jsonBenePxP
      );
      console.log(result);
      if (result) {
        let result2 = await putDataToken(
          `personas/get_id/${arrayInfoDataRow?.PER_idpersona?.PER_idpersona}/`,
          jsonBene
        );
        console.log(result2);
      }
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //CHILDREN DIALOG
  const [open2, setOpen2] = React.useState(false);
  const handleClickOpen2 = () => {
    eliminarDatosBeneficiario();
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false); // Close the children dialog
    handleClose(); // Close the parent dialog
  };

  const handleClose3 = () => {
    setOpen2(false); // Close the children dialog
    handleClose(); // Close the parent dialog
    props.ejecutarConsulta();
  };

  /* AQUI SE TIENE QUE VALIDAR LA RESPUESTA JSON, SI FUE EXITOSO O NO EL ENVIO A MÉDICO */
  const [dialogTittle, setDialogTittle] = React.useState("");
  const [dialogMessage, setDialogMessage] = React.useState("");

  //AQUI DEBE HACER LA PETICIÓN A LA API PARA DESPUES MOSTRAR LA RESPUESTA EN EL DIALOGO HIJO
  React.useEffect(() => {
    setDialogTittle("Operación exitosa");
    setDialogMessage(
      <div>
        <h2-dialog-description>
          Se eliminó a {arrayInfoDataRow?.PER_idpersona?.PER_nombre} de la
          lista.
        </h2-dialog-description>
      </div>
    );
  }, []);

  return (
    <div>
      {/* PARENT DIALOG */}
      <div>
        {/*BOTON QUE HACE EL LLAMADO AL DIALOGO PADRE*/}
        <IconEliminar
          onClick={handleClickOpen}
          tooltipTitle={
            <FormattedMessage id="estconElim" defaultMessage="Eliminar" />
          }
        />
        {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
        <BootstrapDialog
          // onClose={handleClose}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
          aria-labelledby="dialog"
          open={open}
        >
          {/*CUERPO DEL DIALOGO*/}
          {/*TITILO*/}
          <BootstrapDialogTitle
            id="dialog"
            onClose={handleClose}
            style={{ color: "#761f2a" }}
          >
            <h1-dialog-title>{props.title}</h1-dialog-title>
          </BootstrapDialogTitle>
          {/*CUERPO DEL DIALOGO*/}
          {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
          <DialogContent dividers style={{ minWidth: 500 }}>
            {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
            se necesita colocar entre un typogreaphy las veces que des salto de linea 
            o con un br*/}
            <h2-dialog-description>
              ¿Estás seguro de eliminar a{" "}
              {arrayInfoDataRow.PER_idpersona?.PER_nombre}{" "}
              {arrayInfoDataRow.PER_idpersona?.PER_apellidopaterno}{" "}
              {arrayInfoDataRow.PER_idpersona?.PER_apellidomaterno} de la lista?
            </h2-dialog-description>
          </DialogContent>
          {/*es la tercer sección que engloba los botones */}
          <DialogActions>
            {/*En esta parte se llena el segundo mensaje que debe de aparecer*/}
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item xs={"auto"}>
                <ButtonVino onClick={handleClickOpen2} label={"Confirmar"} />
              </Grid>
              <Grid item xs={"auto"}>
                <ButtonVino onClick={handleClose} label={"Cancelar"} />
              </Grid>
            </Grid>
          </DialogActions>
        </BootstrapDialog>
      </div>
      {/* CHILDREN DIALOG */}
      <div>
        {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
        <BootstrapDialog
          // onClose={handleClose2}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
          aria-labelledby="dialog"
          open={open2}
        >
          {/*CUERPO DEL DIALOGO*/}
          {/*TITULO*/}
          <BootstrapDialogTitle
            id="dialog"
            onClose={handleClose2}
            style={{ color: "#761f2a" }}
          >
            <h1-dialog-title>{dialogTittle}</h1-dialog-title>
          </BootstrapDialogTitle>
          {/*CUERPO DEL DIALOGO*/}
          {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
          <DialogContent dividers style={{ minWidth: 500 }}>
            {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
              se necesita colocar entre un typogreaphy las veces que des salto de linea 
              o con un br*/}
            <h2-dialog-description>{dialogMessage}</h2-dialog-description>
          </DialogContent>
          {/*es la tercer sección que engloba los botones */}
          <DialogActions>
            <Grid container justifyContent="center">
              <ButtonVino onClick={handleClose3} label={"Aceptar"} />
            </Grid>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </div>
  );
}
