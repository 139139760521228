/* Brandon Hernandez Rocha 11/2022 */
import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import { FormattedMessage } from "react-intl";
import "../../dictamen.css"
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { getDataToken } from '../../../../api/dataToken';
import { useEffect } from 'react';
import { PERR_ASEGURADO } from '../../../../utils/constanstoken';
import moment from 'moment';
import { formatofecha } from '../../../../api/getUmbrales';
import { formatNumberDecimalComa } from '../../../../utils/functions';

export function CardDatosReserva(props) {

    const location = useLocation();
    const poliza = location.state.poliza;
    const row = location.state.row;

    /** DATOS DEL ASEGURADO **/
    const [asegurado, setAsegurado] = useState([]);
    const [polCobProd, setPolCobProd] = useState([]);

    const getDataAsegurado = async () => {
        try {
            let asegurado = ""
            let response = await getDataToken(`personasxpoliza/get_poliza/${poliza.POL_idpoliza.POL_idpoliza}/`)
            if (Array.isArray(response)) {
                asegurado = response.find(objeto => objeto?.PER_idpersona?.PERR_idrol?.PERR_idrol == PERR_ASEGURADO.id);
            } else if (typeof response === 'object') {
                // La respuesta es un objeto
                asegurado = response;
            }
            setAsegurado(asegurado)
        } catch (e) {
            console.log(e)
        }
    }
    const getDataPCPP = async () => {
        try {
            let polcobpro = ""
            let response = await getDataToken(`polizascoberturasporproductos/get_folio/${poliza.RECT_idfolio.RECT_idfolio}/`)
            if (Array.isArray(response)) {
                polcobpro = response.find(objeto => objeto?.POL_idpoliza?.POL_idpoliza == poliza.POL_idpoliza.POL_idpoliza);
            } else if (typeof response === 'object') {
                // La respuesta es un objeto
                polcobpro = response;
            }
            setPolCobProd(polcobpro)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        getDataPCPP();
        getDataAsegurado();
    }, [])
    
    return (
        <div className="div-cards">
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">

                <Grid item xs={3}>
                    <h3-title-card>
                        <FormattedMessage
                            id="glob.nodefolio"
                            defaultMessage="Número de Siniestro"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{poliza.SIN_idsiniestro?.SIN_numerosiniestro}</h3-subtitle-card>
                </Grid>
                <Grid item xs={3}>
                    <h3-title-card>
                        <FormattedMessage
                            id="glob.nomasegurado"
                            defaultMessage="Número de póliza"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{`${asegurado?.PER_idpersona?.PER_nombre} ${asegurado?.PER_idpersona?.PER_apellidopaterno} ${asegurado?.PER_idpersona?.PER_apellidomaterno}`}</h3-subtitle-card>
                </Grid>
                <Grid item xs={3}>
                    <h3-title-card>
                        <FormattedMessage
                            id="glob.sexo"
                            defaultMessage="Suma Asegurada"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{formatNumberDecimalComa(polCobProd?.PCP_coberturasumaasegurada)}</h3-subtitle-card>
                </Grid>
                <Grid item xs={3}>
                    <h3-title-card>
                        <FormattedMessage
                            id="glob.edad"
                            defaultMessage="Suma Asegurada Adicional"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{ }</h3-subtitle-card>
                </Grid>
                <Grid item xs={3}>
                    <h3-title-card>
                        <FormattedMessage
                            id="glob.datos.fechanacimiento"
                            defaultMessage="Fecha de emisión"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{moment(poliza?.POL_idpoliza?.POL_polizafecemisión).format(formatofecha)}</h3-subtitle-card>
                </Grid>
                <Grid item xs={3}>
                    <h3-title-card>
                        <FormattedMessage
                            id="glob.fechasinies"
                            defaultMessage="Fecha de siniestro"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{moment(poliza.SIN_idsiniestro.SIN_fechasiniestro).format(formatofecha) }</h3-subtitle-card>
                </Grid>
                {/* <Grid item xs={3}>
                    <h3-title-card>
                        <FormattedMessage
                            id="glob.fechasinies"
                            defaultMessage="Antigüedad"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{"26/May/2022"}</h3-subtitle-card>
                </Grid> */}
                <Grid item xs={3}>
                    <h3-title-card>
                        <FormattedMessage
                            id="recep.edit.tiposinies"
                            defaultMessage="Pagado hasta"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{moment(poliza?.POL_idpoliza?.POL_polizapahasta).format(formatofecha)}</h3-subtitle-card>
                </Grid>
                <Grid item xs={3}>
                    <h3-title-card>
                        <FormattedMessage
                            id="recep.edit.tiposinies"
                            defaultMessage="Fecha de último pago"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{moment(poliza?.POL_idpoliza?.POL_polizafecpago).format(formatofecha)}</h3-subtitle-card>
                </Grid>
                <Grid item xs={3}>
                    <h3-title-card>
                        <FormattedMessage
                            id="recep.edit.tiposinies"
                            defaultMessage="Moneda"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{poliza?.POL_idpoliza?.PRO_idproductos?.MON_idmonedas?.MON_abreviatura}</h3-subtitle-card>
                </Grid>
            </Grid>
        </div>
    )
}