import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { getDataToken } from "../../../../api/dataToken";
import { formatofecha } from "../../../../api/getUmbrales";
//Idioma
import { FormattedMessage } from "react-intl";
import moment from "moment";

export default function HeaderCobertura(props) {
  const infoDataRow = props.infoRow; //Por esta variable entran los valores que se estan enviando
  var nacimiento = moment(infoDataRow?.PER_idpersona?.PER_fechanacimiento);
  var hoy = moment();
  var anios = hoy.diff(nacimiento, "years");

  const [IDFolio, setIDFolio] = React.useState(
    infoDataRow?.RECT_idfolio?.RECT_idfolio
  );
  const [data, setdata] = useState("");

  const getCPXT = async () => {
    try {
      var result = await getDataToken(
        `polizascoberturasporproductos/get_folio/${IDFolio}/`
      );
      console.log("Resultado de polizascoberturasporproductos =>", result);
      setdata(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getCPXT();
  }, []);

  return (
    <Grid container>
      <Grid xs={12}>
        <h3-header-card>
          <FormattedMessage
            id="recepDatSi"
            defaultMessage="Datos del siniestro"
          />
        </h3-header-card>
      </Grid>

      <Grid xs={12}></Grid>
      <br />
      <br />

      <Grid xs={2.4}>
        <h3-title-card>
          <FormattedMessage id="globNomCom" defaultMessage="Nombre completo" />
        </h3-title-card>
        <br />
        {/*Se colocaran los id a cada etiqueta para que esta nos sirva para colocar la infromación que 
          nos regresará la api*/}
        <h3-subtitle-card>
          {infoDataRow?.PER_idpersona?.PER_nombre +
            " " +
            infoDataRow?.PER_idpersona?.PER_apellidopaterno +
            " " +
            infoDataRow?.PER_idpersona?.PER_apellidomaterno}
        </h3-subtitle-card>
      </Grid>
      <Grid xs={2.4}>
        <h3-title-card>
          <FormattedMessage
            id="globFechNa"
            defaultMessage="Fecha de nacimiento"
          />
        </h3-title-card>
        <br />
        <h3-subtitle-card>
          {moment(infoDataRow?.PER_idpersona?.PER_fechanacimiento).format(
            formatofecha
          )}
        </h3-subtitle-card>
      </Grid>
      <Grid xs={2.4}>
        <h3-title-card>
          <FormattedMessage id="globEdad" defaultMessage="Edad" />
        </h3-title-card>
        <br />
        <h3-subtitle-card>{anios}</h3-subtitle-card>
      </Grid>
      <Grid xs={2.4}>
        <h3-title-card>
          <FormattedMessage id="globSexo" defaultMessage="Sexo" />
        </h3-title-card>
        <br />
        <h3-subtitle-card>
          {infoDataRow?.PER_idpersona?.PER_sexo}
        </h3-subtitle-card>
      </Grid>
      <Grid xs={2.4}></Grid>

      <Grid xs={12}></Grid>
      <br />
      <br />

      <Grid xs={2.4}>
        <h3-title-card>
          <FormattedMessage id="loginRFCE" defaultMessage="RFC" />
        </h3-title-card>
        <br />
        <h3-subtitle-card>
          {infoDataRow?.PER_idpersona?.PER_RFC}
        </h3-subtitle-card>
      </Grid>
      <Grid xs={2.4}>
        <h3-title-card>
          <FormattedMessage id="globCurp" defaultMessage="CURP" />
        </h3-title-card>
        <br />
        <h3-subtitle-card>
          {infoDataRow?.PER_idpersona?.PER_CURP}
        </h3-subtitle-card>
      </Grid>
      <Grid xs={2.4}>
        <h3-title-card>
          <FormattedMessage id="globNoFoli" defaultMessage="Número de folio" />
        </h3-title-card>
        <br />
        <h3-subtitle-card>
          {infoDataRow?.RECT_idfolio?.RECT_numerofolio}
        </h3-subtitle-card>
      </Grid>
      <Grid xs={2.4}>
        <h3-title-card>
          <FormattedMessage
            id="errorTiRec"
            defaultMessage="Tipo de reclamación"
          />
        </h3-title-card>
        <br />
        <h3-subtitle-card>
          {
            infoDataRow?.RECT_idfolio?.SINC_idcomplejidadsiniestro
              ?.TRA_destipotramitesiniestro
          }
        </h3-subtitle-card>
      </Grid>
      <Grid xs={2.4}>
        <h3-title-card>
          <FormattedMessage id="globCober" defaultMessage="Cobertura" />
        </h3-title-card>
        <br />
        <h3-subtitle-card>
          {data ? data[0].COB_idcobertura?.COB_cobertura : ""}
        </h3-subtitle-card>
      </Grid>

      <Grid xs={12}></Grid>
      <br />
      <br />
    </Grid>
  );
}
