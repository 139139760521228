//  David Ruiz 10/2022

import React from 'react';
import IconButton from '@mui/material/IconButton';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const BtDatSin=(props)=>{

    return(
        <IconButton aria-label= " ContactMailIcon" color="icons"  
        //aqui va el enlace
        href={props.enlace}
        onClick={props.onClick}
        onChange={props.onChange}
        id={props.id}
        //ojo aqui es disabled
        disabled={props.estatus} //true para desabilitar y false para habilitar
        >
        < ContactMailIcon />
      </IconButton>
        );
    }

export default BtDatSin;