import React, { useState } from 'react';
//BreadCrumbs
import Recorrido from '../../Tools/BreadCrumbs/Recorrido';
//Diseño
import { Grid } from "@mui/material";
//Boton de regreso
import ReturnPage from "../../Tools/Bottones/ButtonReturn";
import Contabi from './Elements/Conta';
import TableCuentas from './Elements/tablacuentas';



export function Contabilidad() {



    return (
        <div className='div-parent'>
            <div className='div-breadcrumbs'>
                <Recorrido name1="Inicio" enlace="/confadmin" name2="Contabiliad" />
            </div>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                <ReturnPage href='/confadmin' />
            </Grid>
            <div className='div-container'>
                <Grid container>
                <Grid xs={5}>
                <Contabi />
                </Grid>
                <Grid xs={5}>
                    <TableCuentas/>
                </Grid>
                </Grid>
            </div>
        </div>
    );
}