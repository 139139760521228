import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableCell } from '@material-ui/core';
import Paper from '@mui/material/Paper';
import StyledTableCell from '../../../Tools/Table/StyledTableCell';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { getToken } from '../../../../api/token';
import { BASE_API } from '../../../../utils/constans';

export default function TableCuentas() {
  function createData(Cuentas) {
    return { Cuentas };
  }

  const rows = [
    createData('Aqui estan apuntados los datos de la api'),
  ];

  const [data, setData] = useState(rows);

  const token = getToken();

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);

  const fetchGet = () => {
    fetch(`${BASE_API}cuentas/get_listado/`, {
      method: 'GET',
      headers: headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data);
        console.log(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  // Metodo para eliminar los datos de la tabla
  function eliminar(id) {
    let response = fetch(`${BASE_API}cuentas/get_id/` + id + '/', {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify({ CTA_activo: false }),
    });
    response.then((response) => {
      console.log(response);
      fetchGet(); // Actualizar la tabla después de eliminar
    });

    console.log(id);
  }
  // Aqui termina la Funcion para eliminar

  useEffect(() => {
    fetchGet();
    const interval = setInterval(fetchGet, 90000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ maxHeight: 440 }} style={{ position: 'sticky' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Numero de Cuenta</StyledTableCell>
                <StyledTableCell align="center">Nombre</StyledTableCell>
                <StyledTableCell align="center">Estado</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <StyledTableCell align="center">{row?.CTA_numcuenta}</StyledTableCell>
                  <StyledTableCell align="center">{row?.CTA_naturaleza}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.CTA_activo ? (
                      <button
                        style={{ backgroundColor: 'green' }}
                        onClick={() => eliminar(row.id)}
                      >
                        Activo
                      </button>
                    ) : (
                      <button
                        style={{ backgroundColor: 'red' }}
                        onClick={() => eliminar(row.id)}
                      >
                        Inactivo
                      </button>
                    )}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <br />
      <br />
    </div>
  );
}
