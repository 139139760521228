/* 
David Ruiz
Proyecto SINAY 
06/12/2022
*/

//Grid
import { Grid } from "@mui/material";


import React, { useState, useEffect } from 'react'

//navegador
// import Recorrido from '../../Tools/BreadCrumbs/Recorrido';

//RETURN
import ReturnPage from "../../../Tools/Bottones/ButtonReturn";

//button
import ButtonVino from '../../../Tools/Bottones/ButtonVino'


//select
// import { Select1 } from '../../Tools/Selectss/Select1';

// token

import { getToken } from "../../../../api/token";
import { BASE_API } from "../../../../utils/constans";

//css
import "../../../../css/estilo.css";
import "../mail.css";

//idioma
import {FormattedMessage} from 'react-intl';

//radio
import Check2 from '../../../Tools/CheckBox/Check2';

//texfield
import TextField from '../../../Tools/TextField/TextField';

// elements

//import Prn1 from './Elements/Prn1';


export default function WhatsApp() {

///VALORES
const [values, setValues] = React.useState({
    EMLNT_numerocli:"", 
    EMLNT_texto2:"",
    EMLNT_texto:"", 
    EMLNT_titulo:"",
    EMLNT_ladaineternacional:""
});

const handleChange = (prop) => (event) => {
  setValues({ ...values, [prop]: event.target.value });
  
}
const token = getToken();


 
//funcion envio de prueba
const validateAndSubmitForm = (e) => {
    e.preventDefault();

    fetch(`${BASE_API}notificador/sms/`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
            EMLNT_titulo:"EAALKJcl6jkkBAE6dTCRrmy8jbppdeLjGA4lYb7Y1zrZADHXqxGn4Gf1k27coz3uXFvA6Sviuy8hQAmjekrlZBe1FTaa2IzyLYZBVTZCm9ZCZBd1Nh4Nx1ICnWgsQ6syxMQL2KIoHvi3eJ93bPsz4v0UwxJeVeWASxoEU9iLs1FhIZAS5swfxd3ErOb1yDnnmjaq0nWf7S53DYHUZCpnfYZAOU",
            EMLNT_numerocli:values.EMLNT_ladaineternacional+values.EMLNT_numerocli, 
            EMLNT_texto2:values.EMLNT_texto2,
            EMLNT_texto:values.EMLNT_texto, 
          })
          
      })

}



 
 return (
    <div className='div-parent'>
    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
        <ReturnPage href='/confadmin'/>
    </Grid>
    <div className='div-container'>
    <div className="parentda">
    <div className="whats1">
    <h3-subtitle-card>Le informamos cordialmente que el presente apartado solo puede funcionar si se ha contratado previamente, debido a que es una herramienta externa a SINAY. Si tiene interés en adquirir este servicio, le sugerimos que se ponga en contacto con su agente de QAIT para obtener más información y realizar la contratación correspondiente.</h3-subtitle-card>
      </div> 
    <div className="whats2">
    <TextField
        type="text"
        label="Nombre de la empresa"
        size="small"
        required={true}
        value={
          values.EMLNT_texto
        }
        onChange={
          handleChange("EMLNT_texto")
          // setValues(event.target.value)
        }
        InputLabelProps={{ shrink: true }}
      /> 
      </div> 
    <div className="whats3">
    <TextField
        type="text"
        label="Correo Cliente"
        size="small"
        required={true}
        value={
          values.EMLNT_texto2
        }
        onChange={
          handleChange("EMLNT_texto2")
          // setValues(event.target.value)
        }
        InputLabelProps={{ shrink: true }}
      /> 
     </div>
    <div className="whats4">
    <TextField
        type="text"
        label="Extensión Internacional"
        size="small"
        required={true}
        value={
          values.EMLNT_ladaineternacional
        }
        onChange={
          handleChange("EMLNT_ladaineternacional")
          // setValues(event.target.value)
        }
        InputLabelProps={{ shrink: true }} 
      /> 
     </div>
     <div className="whats5">
     <ButtonVino label="Prueba" enlace="/confadmin" onClick={validateAndSubmitForm} estatus={
                            values.EMLNT_numerocli.length> 0 &&
                            values.EMLNT_texto.length> 0 &&
                            values.EMLNT_texto2.length>0 
                            ? false
                            : true}/>
   
     </div>
     <div className="whats6">
    <TextField
        type="text"
        label="Número del Cliente"
        size="small"
        required={true}
        value={
          values.EMLNT_numerocli
        }
        onChange={
          handleChange("EMLNT_numerocli")
          // setValues(event.target.value)
        }
        InputLabelProps={{ shrink: true }} 
      /> 
     </div>
    </div>
    </div>
    </div>
    
  )
}
