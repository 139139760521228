import React from "react";
import { CompComunicacion } from '../../../components/Communication/CompComunicacion';
import MyComponent from "../../../components/Tools/ButtonLoading/ButtonLoading";


export function Comunicacion() {
    return (
        <div>
         
         <MyComponent componentToRender={CompComunicacion} />
      
        </div>
    );
}