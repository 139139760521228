import * as React from "react";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TXTField from "../../../Tools/TextField/TextField";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { getDataToken, putDataToken } from "../../../../api/dataToken";
import { SelectDictamen } from "../../../Tools/Selectss/SelectDictamen";
import { LimitarTexto } from "../../../Recepcion/Elements/Functions/Validaciones";
import { LimitarDigitos } from "../../../Recepcion/Elements/Functions/Validaciones";
import { FormattedMessage } from "react-intl";
import { IDROLMEDICODIC } from "../../../../utils/constanstoken";
import getDate from "../../../Registro/Function/obtenerFecha";
import moment from "moment";
import getHours from "../../../Registro/Function/obtenerHora";
import { formatofecha } from "../../../../api/getUmbrales";
import DialogAviso from "../../../Registro/Elements/Dialog/DialogAviso";
import { IDPAISMEXICO } from "../../../../utils/constanstoken";
import {
  LimitarTextoSinCondiciones,
  LimitarTextoCodigo,
} from "../../../Registro/Function/limitarTexto";

const CardDatosReclamacionBV = (props) => {
  const idFolio = props.idFolio;
  const polizas = props.polizas;
  console.log(polizas);

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");
  console.log(moment(fechaEmpAn).format(formatofecha));
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();
  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;
  console.log("fechaActSIN" + fechaActSIN);

  const [Data, setData] = React.useState([]);
  const [DataMedico, setDataMedico] = React.useState([]);
  const [asentamientos, setAsentamientos] = React.useState([]);
  const [idcomplejidad, setidcomplejidad] = React.useState("");
  //Estados para guardar los datos de clasificación
  const [clasificacion, setClasificacion] = useState([]);
  const [idclasificacion, setIdclasificacion] = useState("");
  //Estados para guardar los datos de los paises
  const [paises, setpaises] = useState([]);
  const [idpais, setidpais] = useState("");
  const [idpaisR, setidpaisR] = useState("");

  //Obtener usuario.
  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");
  const getData = async () => {
    try {
      var result = await getDataToken(`siniestros/get_folio/${idFolio}/`);
      setData(result);
      setidpais(result[0]?.PAI_idpais?.PAI_idpais);
      setidpaisR(result[0]?.PAI_idpais?.PAI_idpais);
      setidcomplejidad(
        result[0]?.SINC_idcomplejidadsiniestro?.SINC_idcomplejidadsiniestro
      );
      setIdclasificacion(
        result[0]?.SINC_idcomplejidadsiniestro?.SINC_idcomplejidadsiniestro
      );
      var resultPER = await getDataToken(
        `personasxpoliza/get_poliza/${polizas[0]?.POL_idpoliza?.POL_idpoliza}/`
      );
      let personasFiltradas = resultPER.filter(
        (persona) =>
          persona.PER_idpersona?.PERR_idrol?.PERR_idrol === IDROLMEDICODIC
      );
      setDataMedico(personasFiltradas);
      let nuevoElementoMed = {
        identificador: personasFiltradas[0]?.PER_idpersona?.SEP_idsepomex?.id,
        texto: personasFiltradas[0]?.PER_idpersona?.SEP_idsepomex?.SEP_asenta,
      };
      setAsentamientos([...asentamientos, nuevoElementoMed]);
      var resultAnalista = await getDataToken(`login/get_correo/${email}/`);
      setnombreAnalista(
        resultAnalista.SNYTLOG_username +
          " " +
          resultAnalista.SNYTLOG_surnamedad +
          " " +
          resultAnalista.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  console.log(Data);
  console.log(DataMedico);
  console.log(idcomplejidad);

  // Traemos los datos de clasificación para llenar el combo
  const getComplejidad = async (idcomplejidad, idpais) => {
    try {
      const [result, resultPaises] = await Promise.all([
        getDataToken(`complejidadsiniestros/get_listado/`),
        getDataToken(`paises/get_listado/`),
      ]);

      // Utilizar un conjunto para evitar duplicados
      const idComplejidadSet = new Set();
      const idPaisSet = new Set();

      // Filtrar la complejidad deseada y el resto
      const filteredComplejidad = result?.reduce((filteredArray, element) => {
        const idComplejidad = element?.SINC_idcomplejidadsiniestro;

        if (!idComplejidadSet.has(idComplejidad)) {
          idComplejidadSet.add(idComplejidad);

          if (idComplejidad === idcomplejidad) {
            // Colocar la complejidad deseada en la posición 0
            filteredArray.unshift({
              ...element,
              identificador: 0,
              texto: element?.SINC_complejidad,
            });
          } else {
            filteredArray.push({
              ...element,
              identificador: idComplejidad,
              texto: element?.SINC_complejidad,
            });
          }
        }

        return filteredArray;
      }, []);

      // Filtrar el país deseado y el resto
      const filteredpaises = resultPaises?.reduce(
        (filteredArrayPais, element) => {
          const idPais = element?.PAI_idpais;

          if (!idPaisSet.has(idPais)) {
            idPaisSet.add(idPais);

            if (idPais === idpais) {
              // Colocar el país deseado en la posición 0
              filteredArrayPais.unshift({
                ...element,
                identificador: 0,
                texto: element?.PAI_pais,
              });
            } else {
              filteredArrayPais.push({
                ...element,
                identificador: idPais,
                texto: element?.PAI_pais,
              });
            }
          }

          return filteredArrayPais;
        },
        []
      );

      setClasificacion(filteredComplejidad);
      setpaises(filteredpaises);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getComplejidad(idcomplejidad, idpais);
  }, [idcomplejidad, idpais]);

  //Estado donde se guardara el dato
  const [plataforma, setPlataforma] = useState("");

  const handleChangeclasi = (e) => {
    const dataClasificacion = parseInt(e.target.value);

    // Verificar si el usuario seleccionó la posición 0
    if (dataClasificacion === 0) {
      setIdclasificacion(idcomplejidad);
      return; // Sale de la función porque no hay necesidad de continuar
    }

    clasificacion.forEach((element) => {
      if (dataClasificacion === element.SINC_idcomplejidadsiniestro) {
        setIdclasificacion(element.SINC_idcomplejidadsiniestro);
        setPlataforma(element.SINC_complejidad.toLowerCase());
      }
    });
  };

  console.log("Id clasificación " + idclasificacion);
  console.log("Plataforma " + plataforma);
  console.log(clasificacion);

  //Estado donde se guardara el dato
  const [pais, setpais] = useState("");

  const handleChangePais = (e) => {
    const dataPais = parseInt(e.target.value);

    // Verificar si el usuario seleccionó la posición 0
    if (dataPais === 0) {
      setidpaisR(idpais);
      return; // Sale de la función porque no hay necesidad de continuar
    }

    paises.forEach((element) => {
      if (dataPais === element.PAI_idpais) {
        setidpaisR(element.PAI_idpais);
        setpais(element.PAI_pais.toLowerCase());
      }
    });
  };

  console.log("Id pais " + idpaisR);
  console.log("Pais" + pais);

  //Validación solo acepta números
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (/[^0-9]/.test(keyValue)) {
      event.preventDefault();
    }
  };

  //validación para que acepte solo letras.
  function handleKeyPressL(event) {
    const regex = /^[a-zA-Z]*$/;
    const isValidInput = regex.test(event.key);
    if (!isValidInput) {
      event.preventDefault();
    }
  }

  //Estados utilizados para sepomex
  const [SEP_Id, setSEP_Id] = useState("");
  const [SEP_asenta, setSEP_asenta] = useState("");
  const [SEP_municipio, setSEP_municipio] = useState("");
  const [SEP_estado, setSEP_estado] = useState("");
  const [SEP_ciudad, setSEP_ciudad] = useState("");
  const [codpostal, setCodPostal] = useState("");
  const [colonias, setColonias] = useState([]);
  const [dataSelectColonias, setDataSelectColonias] = useState([]);

  const getCodP = async (cp) => {
    try {
      var result = await getDataToken(`sepomex/get_listado_cp/${cp}`);
      setColonias(result);
      if (result <= 0) {
        setDataSelectColonias([]);
        setSEP_estado("");
        setSEP_municipio("");
        setSEP_asenta("");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleSubmit = () => {
    if (codpostal === "") {
      document.getElementById("txtCP2").focus();
    } else {
      getCodP(codpostal);
    }
  };

  const getInfoasenta = (e) => {
    const dataColonia = parseInt(e.target.value);
    colonias.forEach((element) => {
      if (dataColonia === element.id) {
        setSEP_Id(element.id);
        setSEP_municipio(element.SEP_municipio);
        setSEP_estado(element.SEP_estado);
        setSEP_ciudad(element.SEP_ciudad);
        setSEP_asenta(element.SEP_asenta);
      }
    });
  };

  //Esta función useEffect se activará cuando haya cambios en el estado 'colonias', activando a su ves otro estado
  useEffect(() => {
    if (colonias.length > 0) {
      let dataColonias = colonias;
      dataColonias.map(
        (cp) => (
          (cp.texto = cp.SEP_asenta), // cp.texto es una propiedad que no existe dentro del JSON, por tanto, se crea
          (cp.identificador = cp.id)
        )
      ); //Se actualiza el valor del dataSelectColonias
      setDataSelectColonias(dataColonias);
    }
  }, [colonias]);

  console.log("Id sepomex " + SEP_Id);

  ///////////////////////////////////////////////
  const [values, setValues] = React.useState({
    SIN_causadereclamacion: "",
    SIN_edadconcentracion: "",
    SIN_edadsiniestro: "",
    PER_nombre: "",
    PER_apellidopaterno: "",
    PER_apellidomaterno: "",
    PER_cedulaprofesional: "",
    PER_calle: "",
    PER_numeroexterior: "",
    PER_numerointerior: "",
    PER_ciudadmedico: "",
    PER_estadomedico: "",
    PER_cpmedico: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  React.useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      SIN_causadereclamacion: Data[0]?.SIN_causadereclamacion,
      SIN_edadconcentracion: Data[0]?.SIN_edadconcentracion,
      SIN_edadsiniestro: Data[0]?.SIN_edadsiniestro,
      PER_nombre: DataMedico[0]?.PER_idpersona?.PER_nombre,
      PER_apellidopaterno: DataMedico[0]?.PER_idpersona?.PER_apellidopaterno,
      PER_apellidomaterno: DataMedico[0]?.PER_idpersona?.PER_apellidomaterno,
      PER_cedulaprofesional:
        DataMedico[0]?.PER_idpersona?.PER_cedulaprofesional,
      PER_calle: DataMedico[0]?.PER_idpersona?.PER_calle,
      PER_numeroexterior: DataMedico[0]?.PER_idpersona?.PER_numeroexterior,
      PER_numerointerior: DataMedico[0]?.PER_idpersona?.PER_numerointerior,
      PER_ciudadmedico: DataMedico[0]?.PER_idpersona?.PER_ciudadmedico,
      PER_estadomedico: DataMedico[0]?.PER_idpersona?.PER_estadomedico,
      PER_cpmedico: DataMedico[0]?.PER_idpersona?.PER_cpmedico,
    }));
    setCodPostal(DataMedico[0]?.PER_idpersona?.SEP_idsepomex?.SEP_codpostal);
    setSEP_municipio(
      DataMedico[0]?.PER_idpersona?.SEP_idsepomex?.SEP_municipio
    );
    setSEP_Id(DataMedico[0]?.PER_idpersona?.SEP_idsepomex?.id);
    setSEP_estado(DataMedico[0]?.PER_idpersona?.SEP_idsepomex?.SEP_estado);
    setSEP_ciudad(DataMedico[0]?.PER_idpersona?.SEP_idsepomex?.SEP_ciudad);
    setSEP_asenta(DataMedico[0]?.PER_idpersona?.SEP_idsepomex?.SEP_asenta);
  }, [Data, DataMedico]);

  //contantes para editar información
  const [editCampos, setEditCampos] = useState(true);
  const editarCampos = (e) => {
    setEditCampos(false);
  };

  const [editColonias, setEditColonias] = useState(true);

  //Alerta de confirmacion
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    // Actualizar el estado utilizando el hook useState
    setVisible(false);
  };

  return (
    <Grid container>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          Data.forEach((siniestros) => {
            let siniestro = {
              SIN_causadereclamacion: values.SIN_causadereclamacion,
              SIN_edadconcentracion: values.SIN_edadconcentracion,
              SIN_edadsiniestro: values.SIN_edadsiniestro,
              SINC_idcomplejidadsiniestro: idclasificacion,
              RECT_idfolio: idFolio,
              PAI_idpais: idpaisR,
              SIN_actualizofecha: fechaActSIN,
              SIN_actualizousuario: nombreAnalista,
            };
            putDataToken(
              `siniestros/get_id/${siniestros.SIN_idsiniestro}/`,
              siniestro
            ).then((data) => {
              console.log(
                `Registro siniestro ${siniestros.SIN_idsiniestro} actualizado`
              );
            });
          });

          DataMedico.forEach((medico) => {
            let personas = {
              PER_nombre: values.PER_nombre,
              PER_apellidopaterno: values.PER_apellidopaterno,
              PER_apellidomaterno: values.PER_apellidomaterno,
              PER_cedulaprofesional: values.PER_cedulaprofesional,
              PER_calle: values.PER_calle,
              PER_numeroexterior:
                idpaisR === IDPAISMEXICO ? values.PER_numeroexterior : null,
              PER_numerointerior:
                idpaisR === IDPAISMEXICO ? values.PER_numerointerior : null,
              SEP_idsepomex: SEP_Id,
              PERR_idrol: medico?.PER_idpersona?.PERR_idrol?.PERR_idrol,
              PAI_idpais: idpaisR,
              PER_ciudadmedico: values.PER_ciudadmedico,
              PER_estadomedico: values.PER_estadomedico,
              PER_cpmedico: values.PER_cpmedico,
              PER_actualizofecha: fechaActSIN,
              PER_actualizousuario: nombreAnalista,
            };
            putDataToken(
              `personas/get_id/${medico.PER_idpersona?.PER_idpersona}/`,
              personas
            ).then((data) => {
              console.log(
                `Registro persona ${medico.PER_idpersona?.PER_idpersona} actualizado`
              );
            });
          });
          setVisible(true);
        }}
      >
        <div>
          {visible && (
            <DialogAviso
              title="Registro actualizado"
              firstButton={
                <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
              }
              description={"Reclamación actualizada correctamente"}
              onClick1={handleClick}
            />
          )}
          <div>
            <Grid container>
              <Grid item xs={12}>
                <h3-title-card>
                  <FormattedMessage
                    id="regis.datosdelsiniestro.reclamacion"
                    defaultMessage="Causa de la reclamación"
                  />
                  :
                </h3-title-card>
                <br />
                <h3-subtitle-card>
                  Motivo por el que el asegurado o beneficiario están
                  presentando la reclamación
                </h3-subtitle-card>
              </Grid>

              <TXTField
                id="inputEjemplo"
                required
                disabled={editCampos}
                onChange={handleChange("SIN_causadereclamacion")}
                value={values?.SIN_causadereclamacion}
                onInput={(e) => LimitarTexto(e.target, 50)}
              />
            </Grid>
            <hr />
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <br />
                <h3-title-card>
                  <FormattedMessage
                    id="regis.datosdelsiniestro.adicsiniestro"
                    defaultMessage="Informacion adicional del siniestro"
                  />
                  :
                </h3-title-card>
              </Grid>
              <Grid item xs={3} style={{ paddingTop: "10px" }}>
                <TXTField
                  label={
                    <FormattedMessage
                      id="regis.datosdelsiniestro.edadcontratacio"
                      defaultMessage="Edad de contratación"
                    />
                  }
                  size="small"
                  required
                  disabled={editCampos}
                  value={values.SIN_edadconcentracion}
                  onChange={handleChange("SIN_edadconcentracion")}
                  onInput={(e) => LimitarDigitos(e.target, 2)}
                />
              </Grid>
              <Grid item xs={3} style={{ paddingTop: "10px" }}>
                <TXTField
                  label={
                    <FormattedMessage
                      id="regis.datosdelsiniestro.edadsiniestro"
                      defaultMessage="Edad de siniestro"
                    />
                  }
                  size="small"
                  required
                  disabled={editCampos}
                  value={values.SIN_edadsiniestro}
                  onChange={handleChange("SIN_edadsiniestro")}
                  onInput={(e) => LimitarDigitos(e.target, 2)}
                />
              </Grid>
              <Grid item xs={2.4} style={{ paddingTop: "10px" }}>
                <link-forgot-password>
                  ¿En que país sucedió el siniestro?
                </link-forgot-password>
              </Grid>
              <Grid item xs={3.6} style={{ paddingTop: "10px" }}>
                <SelectDictamen
                  disabled={editCampos}
                  sx={{
                    minWidth: 210,
                    maxWidth: 210,
                    height: 40,
                    background: "white",
                  }}
                  label={
                    <FormattedMessage
                      id="globColoni"
                      defaultMessage={`Pais *`}
                    />
                  }
                  array={paises}
                  onChange={handleChangePais}
                />
              </Grid>
            </Grid>
            <hr />
            {/* DATOS DEL MÉDICO QUE CERTIFICA */}
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <br />
                <h3-title-card>
                  <FormattedMessage
                    id="regis.datosdelsiniestro.datmedicocertifica"
                    defaultMessage="Datos del médico que dictamina"
                  />
                  :
                </h3-title-card>
                <br />
              </Grid>
              <Grid item xs={3} style={{ paddingTop: "0px" }}>
                <TXTField
                  label={
                    <FormattedMessage
                      id="datos.nom"
                      defaultMessage="Nombre(s)"
                    />
                  }
                  size="small"
                  disabled={editCampos}
                  required
                  value={values.PER_nombre}
                  onChange={handleChange("PER_nombre")}
                  onKeyPress={handleKeyPressL}
                  onPaste={(e) => e.preventDefault()}
                  onInput={(e) => LimitarTexto(e.target, 50)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3} style={{ paddingTop: "0px" }}>
                <TXTField
                  label={
                    <FormattedMessage
                      id="datos.apellidopate"
                      defaultMessage="Apellido paterno"
                    />
                  }
                  size="small"
                  required
                  disabled={editCampos}
                  value={values.PER_apellidopaterno}
                  onChange={handleChange("PER_apellidopaterno")}
                  onKeyPress={handleKeyPressL}
                  onPaste={(e) => e.preventDefault()}
                  onInput={(e) => LimitarTexto(e.target, 50)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3} style={{ paddingTop: "0px" }}>
                <TXTField
                  label={
                    <FormattedMessage
                      id="datos.apellidomate"
                      defaultMessage="Apellido materno"
                    />
                  }
                  size="small"
                  required
                  disabled={editCampos}
                  value={values.PER_apellidomaterno}
                  onChange={handleChange("PER_apellidomaterno")}
                  onKeyPress={handleKeyPressL}
                  onPaste={(e) => e.preventDefault()}
                  onInput={(e) => LimitarTexto(e.target, 50)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3} style={{ paddingTop: "0px" }}>
                <TXTField
                  label={
                    <FormattedMessage
                      id="regis.datossinies.cedprof"
                      defaultMessage="Cédula Profesional"
                    />
                  }
                  size="small"
                  required
                  disabled={editCampos}
                  value={values.PER_cedulaprofesional}
                  onChange={handleChange("PER_cedulaprofesional")}
                  onKeyPress={handleKeyPress}
                  onPaste={(e) => e.preventDefault()}
                  onInput={(e) => LimitarDigitos(e.target, 15)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            {idpaisR === IDPAISMEXICO ? (
              <div>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <TXTField
                      label={
                        <FormattedMessage
                          id="globCodPos"
                          defaultMessage="Código postal"
                        />
                      }
                      size="small"
                      required
                      disabled={editCampos}
                      value={codpostal}
                      name="txtCP"
                      id="txtCP"
                      onChange={(event) => setCodPostal(event.target.value)}
                      onKeyPress={handleKeyPress}
                      onPaste={(e) => e.preventDefault()}
                      onInput={(e) => LimitarDigitos(e.target, 6)}
                      showSearchIcon={true}
                      iconOnClick={handleSubmit}
                      disabledicon={editCampos}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <SelectDictamen
                      required
                      disabled={editCampos}
                      sx={{
                        minWidth: 210,
                        maxWidth: 210,
                        height: 40,
                        background: "white",
                      }}
                      label={
                        <FormattedMessage
                          id="globColoni"
                          defaultMessage={`Colonia *`}
                        />
                      }
                      array={
                        dataSelectColonias.length > 0
                          ? dataSelectColonias
                          : asentamientos
                      }
                      onChange={getInfoasenta}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <TXTField
                      label={
                        <FormattedMessage
                          id="regisAsent"
                          defaultMessage="Asentamiento"
                        />
                      }
                      size="small"
                      required
                      disabled={true}
                      value={SEP_asenta}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <TXTField
                      label={
                        <FormattedMessage
                          id="globmunicipioAlcadia"
                          defaultMessage="Municipio o Alcaldia"
                        />
                      }
                      size="small"
                      required
                      disabled={true}
                      value={SEP_municipio}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <TXTField
                      label={
                        <FormattedMessage
                          id="globestado"
                          defaultMessage="Estado"
                        />
                      }
                      size="small"
                      required
                      disabled={true}
                      value={SEP_estado}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <TXTField
                      label={
                        <FormattedMessage
                          id="globciudad"
                          defaultMessage="ciudad"
                        />
                      }
                      size="small"
                      required
                      disabled={true}
                      value={SEP_ciudad}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <TXTField
                      label={
                        <FormattedMessage
                          id="recep.nuevotram.calle"
                          defaultMessage="Calle"
                        />
                      }
                      size="small"
                      required
                      disabled={editCampos}
                      value={values.PER_calle}
                      onChange={handleChange("PER_calle")}
                      onInput={(e) => LimitarTexto(e.target, 100)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <TXTField
                      label={
                        <FormattedMessage
                          id="regis.datosdelsiniestro.noexterno"
                          defaultMessage="No. Ext"
                        />
                      }
                      size="small"
                      required
                      disabled={editCampos}
                      value={values.PER_numeroexterior}
                      onChange={handleChange("PER_numeroexterior")}
                      onKeyPress={handleKeyPress}
                      onPaste={(e) => e.preventDefault()}
                      onInput={(e) => LimitarDigitos(e.target, 5)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <TXTField
                      label={
                        <FormattedMessage
                          id="regis.datosdelsiniestro.nointerno"
                          defaultMessage="No. Int"
                        />
                      }
                      size="small"
                      required
                      disabled={editCampos}
                      value={values.PER_numerointerior}
                      onChange={handleChange("PER_numerointerior")}
                      onKeyPress={handleKeyPress}
                      onPaste={(e) => e.preventDefault()}
                      onInput={(e) => LimitarDigitos(e.target, 5)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "9px" }}>
                    <SelectDictamen
                      required
                      disabled={editCampos}
                      sx={{
                        minWidth: 210,
                        maxWidth: 210,
                        height: 35,
                        background: "white",
                      }}
                      label={
                        <FormattedMessage
                          id="globColoni"
                          defaultMessage={`Clasificación *`}
                        />
                      }
                      array={clasificacion}
                      onChange={handleChangeclasi}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <br />
                    <TXTField
                      label={
                        <FormattedMessage
                          id="recep.nuevotram.calle"
                          defaultMessage="Dirección"
                        />
                      }
                      size="small"
                      disabled={editCampos}
                      value={values.PER_calle}
                      onChange={handleChange("PER_calle")}
                      onInput={(e) => LimitarTextoSinCondiciones(e.target, 50)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <br />
                    <TXTField
                      label={
                        <FormattedMessage
                          id="recep.nuevotram.calle"
                          defaultMessage="Ciudad"
                        />
                      }
                      size="small"
                      disabled={editCampos}
                      value={values.PER_ciudadmedico}
                      onChange={handleChange("PER_ciudadmedico")}
                      onInput={(e) => LimitarTexto(e.target, 50)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <br />
                    <TXTField
                      label={
                        <FormattedMessage
                          id="recep.nuevotram.calle"
                          defaultMessage="Estado"
                        />
                      }
                      size="small"
                      disabled={editCampos}
                      value={values.PER_estadomedico}
                      onChange={handleChange("PER_estadomedico")}
                      onInput={(e) => LimitarTexto(e.target, 50)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <br />
                    <TXTField
                      label={
                        <FormattedMessage
                          id="recep.nuevotram.calle"
                          defaultMessage="Código"
                        />
                      }
                      size="small"
                      disabled={editCampos}
                      value={values.PER_cpmedico}
                      onChange={handleChange("PER_cpmedico")}
                      onInput={(e) => LimitarTextoCodigo(e.target, 50)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: "0px" }}>
                    <SelectDictamen
                      required
                      disabled={editCampos}
                      sx={{
                        minWidth: 210,
                        maxWidth: 210,
                        height: 40,
                        background: "white",
                      }}
                      label={
                        <FormattedMessage
                          id="globColoni"
                          defaultMessage={`Clasificación *`}
                        />
                      }
                      array={clasificacion}
                      onChange={handleChangeclasi}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              style={{ paddingRight: "10px" }}
            >
              <Grid item>
                <ButtonVino
                  label="Editar"
                  onClick={(e) => editarCampos(e)}
                  color="secondary"
                />
              </Grid>
              <Grid item>
                <ButtonVino label="Guardar" type="submit" />
              </Grid>
            </Grid>
          </div>
        </div>
      </form>
    </Grid>
  );
};
export default CardDatosReclamacionBV;
