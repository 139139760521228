import React from "react";

//Estilos
import "./Communication.css";
import DialogButtonComu from "../Tools/Dialog/DialogButtonComu";
//Navegacion
import Recorrido3 from "../Tools/BreadCrumbs/Recorrido3";
import ButtonVino from "../Tools/Bottones/ButtonVino";
import TXTField from "../Tools/TextField/TextField";
import { useState, useEffect } from "react";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import DialogAviso from "../Registro/Elements/Dialog/DialogAviso";

//Tabla de poliza
import TablaInfoConsu from "./Elements/TablaInfoConsulta";

//idioma
import { FormattedMessage } from "react-intl";
//Grid
import { Grid } from "@mui/material";
// Api's
import { getDataToken, postDataToken, putDataToken } from "../../api/dataToken";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { formatofecha } from "../../api/getUmbrales";
import {
  PERR_AGENTE,
  IDMODCOMUNICACION,
  TRAM_FALLECIMIENTO,
  TIPOENVIODICT,
  TIPOENTRECOM,
} from "../../utils/constanstoken";
import getDate from "../Registro/Function/obtenerFecha";
import getHours from "../Registro/Function/obtenerHora";
import {
  LimitarTexto2,
  LimitarDigitos,
} from "../Recepcion/Elements/Functions/Validaciones";
import TablaCobertura from "./Elements/TablaCobertura";
import TablaInfo from "./Elements/TablaInfo";

export function ConsulComunicacion() {
  const location = useLocation();
  const datos = location.state.pasocomu;
  console.log("datos", datos);

  const [cobertura, setCobertura] = useState("");
  const [siniestro, setSiniestro] = useState("");
  const [agente, setAgente] = useState("");

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");

  console.log(moment(fechaEmpAn).format(formatofecha));
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();

  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;

  //Obtener usuario.
  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");
  const [idTrabajador, setidTrabajador] = React.useState("");
  const [guardadoMSG, setguardadoMSG] = useState([]);
  const [guardadoDictamen, setguardadoDictamen] = useState([]);

  // Consulta para traer los datos del encabezado

  const getCharola = async () => {
    try {
      var result = await getDataToken(
        `polizascoberturasporproductos/get_folio_poliza/${datos?.RECT_idfolio?.RECT_idfolio}/${datos?.POL_idpoliza?.POL_idpoliza}/`
      );
      setCobertura(result);
      console.log(result);
      var resultSini = await getDataToken(
        `polizasfolioxsiniestro/get_folio_poliza/${datos?.RECT_idfolio?.RECT_idfolio}/${datos?.POL_idpoliza?.POL_idpoliza}/`
      );
      console.log(resultSini);
      setSiniestro(resultSini);
      var resultPersonas = await getDataToken(
        `personasxpoliza/get_poliza/${datos?.POL_idpoliza?.POL_idpoliza}/`
      );
      let agente = resultPersonas.filter(
        (persona) =>
          persona?.PER_idpersona?.PERR_idrol?.PERR_idrol === PERR_AGENTE.id
      );
      console.log(agente);
      setAgente(agente);
      var resultAnalista = await getDataToken(`login/get_correo/${email}/`);
      setidTrabajador(resultAnalista.id);
      setnombreAnalista(
        resultAnalista.SNYTLOG_username +
          " " +
          resultAnalista.SNYTLOG_surnamedad +
          " " +
          resultAnalista.SNYTLOG_surnamemom
      );
      var resultMSG = await getDataToken(
        `mensajes/historical_filter_peticion/?folio=${datos?.RECT_idfolio?.RECT_idfolio}&poliza=${datos?.POL_idpoliza?.POL_idpoliza}&modulo=${IDMODCOMUNICACION}&peticion=${TIPOENTRECOM}`
      );
      console.log(resultMSG);
      setguardadoMSG(resultMSG);
      var resultDict = await getDataToken(
        `mensajes/historical_filter_peticion/?folio=${datos?.RECT_idfolio?.RECT_idfolio}&poliza=${datos?.POL_idpoliza?.POL_idpoliza}&modulo=${IDMODCOMUNICACION}&peticion=${TIPOENVIODICT}`
      );
      console.log(resultDict);
      setguardadoDictamen(resultDict);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getCharola();
  }, []);

  const resultado = guardadoMSG?.reduce((maxFecha, item) => {
    const fecha = new Date(item.MSG_actualizofecha);

    if (!maxFecha || fecha > maxFecha.fecha) {
      return { fecha, elemento: item };
    }

    return maxFecha;
  }, null);

  const elemento = resultado?.elemento;

  console.log(elemento);

  const [values, setValues] = React.useState({
    correo: "",
    telefono: "",
    observaciones: "",
    observacionesmsg: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  React.useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      correo:
        datos?.RECT_idfolio?.SINC_idcomplejidadsiniestro
          ?.TRA_idtipotramitesiniestro === TRAM_FALLECIMIENTO.id
          ? agente[0]?.PER_idpersona?.PER_correo
          : datos?.PER_idpersona?.PER_correo,
      telefono:
        datos?.RECT_idfolio?.SINC_idcomplejidadsiniestro
          ?.TRA_idtipotramitesiniestro === TRAM_FALLECIMIENTO.id
          ? agente[0]?.PER_idpersona?.PER_celular
          : datos?.PER_idpersona?.PER_celular,
    }));
  }, [agente]);

  const validateUpdate = () => {
    if (guardadoDictamen === null) {
      const guardarmsg = {
        RECT_idfolio: datos?.RECT_idfolio?.RECT_idfolio,
        POL_idpoliza: datos?.POL_idpoliza?.POL_idpoliza,
        IDTRABAJADOR: idTrabajador,
        WFM_idwfmodulo: IDMODCOMUNICACION,
        TPET_idtipopeticion: TIPOENVIODICT,
        MSG_mensaje: values.observacionesmsg,
        MSG_actualizofecha: fechaActSIN,
        MSG_actualizousuario: nombreAnalista,
      };
      postDataToken(`mensajes/get_listado/`, guardarmsg).then((data) => {
        console.log(`Registro exitoso mensajes`, data);
      });
    } else {
      const guardarmsg = {
        RECT_idfolio: datos?.RECT_idfolio?.RECT_idfolio,
        POL_idpoliza: datos?.POL_idpoliza?.POL_idpoliza,
        IDTRABAJADOR: idTrabajador,
        WFM_idwfmodulo: IDMODCOMUNICACION,
        TPET_idtipopeticion: TIPOENVIODICT,
        MSG_mensaje: values.observacionesmsg,
        MSG_actualizofecha: fechaActSIN,
        MSG_actualizousuario: nombreAnalista,
      };
      putDataToken(
        `mensajes/get_id/${guardadoDictamen[0]?.MSG_idmensaje}/`,
        guardarmsg
      ).then((data) => {
        console.log(`Registro actualizado mensajes`, data);
      });
    }
    setValues((prevValues) => ({
      ...prevValues,
      observacionesmsg: "",
    }));
  };

  const [visible, setvisible] = React.useState(false);

  const onclickVisible = () => {
    setvisible(true);
    validateUpdate();
  };

  const handleClick = () => {
    setvisible(false);
    let foliosiniestro = {
      PXFS_entregado: false,
      PXFS_actualizofecha: fechaActSIN,
      PXFS_actualizousuario: nombreAnalista,
    };
    putDataToken(
      `polizasfolioxsiniestro/get_id/${siniestro[0]?.PXFS_idfoliopolizaxsiniestro}/`,
      foliosiniestro
    ).then((data) => {
      console.log(`Registro actualizado dictamen`, data);
    });
  };

  // Calculo para la edad del asegurado
  const calcularEdad = (fechaNacimiento, fechaSiniestro) => {
    const fechaNac = moment(fechaNacimiento);
    const fechaSin = moment(fechaSiniestro);
    const edad = fechaSin.diff(fechaNac, "years");
    return edad;
  };

  // Uso de la función para obtener la edad del asegurado

  const fechaNacimiento = datos.PER_idpersona?.PER_fechanacimiento;
  const fechaSiniestro = siniestro[0]?.SIN_idsiniestro?.SIN_fechasiniestro;
  const edadAsegurado = calcularEdad(fechaNacimiento, fechaSiniestro);

  return (
    <>
      {visible && (
        <DialogAviso
          title={
            <FormattedMessage
              id="regisMsgInf"
              defaultMessage="Mensaje informativo"
            />
          }
          firstButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description="Las observaciones se enviaron al módulo de dictamen."
          onClick1={handleClick}
          link1="/comunicacionconsul"
        />
      )}
      <div class="div-parent">
        <div class="div-breadcrumbs">
          <Recorrido3
            name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
            enlace="/menu"
            name2={
              <FormattedMessage
                id="globCoClie"
                defaultMessage="Comunicación con el cliente"
              />
            }
            enlace2="/comunicacionconsul"
            name3={
              <FormattedMessage id="globConsu" defaultMessage="Consulta" />
            }
          />
          <br />
        </div>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <ReturnPage href="/menu" />
        </Grid>

        <div className="div-container">
          <Grid container>
            <Grid xs={12}>
              <h3-header-card>
                <FormattedMessage
                  id="medicInAse"
                  defaultMessage="Información del Asegurado"
                />
              </h3-header-card>
              <br />
              <br />
              <br />
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage
                  id="globNomCom"
                  defaultMessage="Nombre completo"
                />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {datos.PER_idpersona?.PER_nombre} {}
                {datos.PER_idpersona?.PER_apellidopaterno} {}
                {datos.PER_idpersona?.PER_apellidomaterno} {}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage
                  id="globFechNa"
                  defaultMessage="Fecha de nacimiento"
                />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {moment(datos.PER_idpersona?.PER_fechanacimiento).format(
                  formatofecha
                )}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage id="globEdad" defaultMessage="Edad" />
              </h3-title-card>
              <br />
              <h3-subtitle-card>{edadAsegurado}</h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage id="globSexo" defaultMessage="Sexo" />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {datos.PER_idpersona?.PER_sexo}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage id="globRfc" defaultMessage="RFC" />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {datos.PER_idpersona?.PER_RFC}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage id="globCurp" defaultMessage="CURP" />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {datos.PER_idpersona?.PER_CURP}
              </h3-subtitle-card>
              <br />
              <br />
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage id="globFolio" defaultMessage="Folio" />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {datos.RECT_idfolio?.RECT_numerofolio}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage
                  id="dictFeDeSi"
                  defaultMessage="Fecha de siniestro"
                />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {moment(fechaSiniestro).format(formatofecha)}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage
                  id="globNumS"
                  defaultMessage="Número siniestro"
                />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {siniestro[0]?.SIN_idsiniestro?.SIN_numerosiniestro}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage
                  id="errorTiRec"
                  defaultMessage="Tipo de reclamación"
                />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {
                  datos.RECT_idfolio?.SINC_idcomplejidadsiniestro
                    ?.TRA_destipotramitesiniestro
                }
              </h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage id="globCober" defaultMessage="Cobertura" />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {cobertura[0]?.COB_idcobertura?.COB_cobertura}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={2}>
              <h3-title-card>
                <FormattedMessage id="globEstatu" defaultMessage="Estatus" />
              </h3-title-card>
              <br />
              <h3-subtitle-card>
                {datos.RECT_idfolio?.TEST_idestatustramite.TEST_estatustramite}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={12}>
              <hr />
              <br />
            </Grid>
            <Grid xs={12}>
              <TablaInfo data={datos} />
              <br />
            </Grid>
            <Grid xs={1.4}>
              <DialogButtonComu
                mainButton={
                  <FormattedMessage id="globCober" defaultMessage="Cobertura" />
                }
                title={`Número de póliza : ${datos?.POL_idpoliza?.POL_numpoliza}`}
                description2={<TablaCobertura data={cobertura} />}
                button="Aceptar"
              />
            </Grid>
            <Grid xs={10.6}>
              {siniestro[0]?.PXFS_entregado === true ? (
                <DialogButtonComu
                  mainButton={
                    <FormattedMessage
                      id="comuReacti"
                      defaultMessage="Reactivar"
                    />
                  }
                  title={
                    <FormattedMessage
                      id="comuReacti"
                      defaultMessage="Reactivar"
                    />
                  }
                  description={
                    <FormattedMessage
                      id="globObserv"
                      defaultMessage="Observaciones"
                    />
                  }
                  description2={
                    <TXTField
                      style={{ width: 500 }}
                      multiline={true}
                      rows={3}
                      value={values.observacionesmsg}
                      required={true}
                      onInput={(e) => LimitarTexto2(e.target, 50)}
                      onChange={handleChange("observacionesmsg")}
                    />
                  }
                  button={"Aceptar"}
                  estatus2={values.observacionesmsg ? false : true}
                  onClick1={onclickVisible}
                />
              ) : (
                <DialogButtonComu
                  mainButton={
                    <FormattedMessage
                      id="comuReacti"
                      defaultMessage="Reactivar"
                    />
                  }
                  title={
                    <FormattedMessage
                      id="comuReacti"
                      defaultMessage="Reactivar"
                    />
                  }
                  description2="El trámite ya se esta atendiendo en dictamen."
                  button={"Aceptar"}
                />
              )}
            </Grid>
            <Grid xs={12}>
              <br />
              <hr />
              <br />
            </Grid>
            {/* Empieza area de contacto con el cliente */}
            {/* Formulario para editar el area de correo y telefono */}
            {/* Area de diseño para contacto */}
            <Grid container>
              <Grid xs={12}>
                <h3-header-card>
                  <FormattedMessage id="comuContac" defaultMessage="Contacto" />
                </h3-header-card>
                <br /> <br />
              </Grid>
              <Grid xs={3}>
                <h2-dialog-description>
                  Correo electrónico
                </h2-dialog-description>
                <TXTField
                  size="small"
                  id="correo"
                  value={values.correo}
                  required={true}
                  onInput={(e) => LimitarTexto2(e.target, 50)}
                  onChange={handleChange("correo")}
                  disabled={true}
                />
              </Grid>
              <Grid xs={0.5}></Grid>
              <Grid xs={1.5}>
                <h2-dialog-description>Teléfono</h2-dialog-description>
                <TXTField
                  size="small"
                  id="telefono"
                  value={values.telefono}
                  required={true}
                  onInput={(e) => LimitarDigitos(e.target, 10)}
                  onChange={handleChange("telefono")}
                  disabled={true}
                />
              </Grid>
              <Grid xs={0.5}></Grid>
              <Grid xs={5}>
                <h2-dialog-description>Observaciones</h2-dialog-description>
                <TXTField
                  size="small"
                  id="observaciones"
                  value={elemento?.MSG_mensaje}
                  required={true}
                  onInput={(e) => LimitarTexto2(e.target, 50)}
                  onChange={handleChange("observaciones")}
                  disabled={true}
                  multiline={true}
                  rows={3}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
