import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button } from "@mui/material";
import ButtonVino from "../../../../Tools/Bottones/ButtonVino";
import { TextareaAutosize } from "@mui/material";
import { CardActionArea, Typography } from "@mui/material";
import { CardContent } from "@mui/material";
import { TextField } from "@mui/material";
import Fab from "@mui/material/Fab";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SearchTXTField from "../../../../Tools/TextField/SearchTextField";
import Grid from '@material-ui/core/Grid';
import { type } from "@testing-library/user-event/dist/type";
import { getToken } from "../../../../../api/token";
import { PostAdd } from "@mui/icons-material";
import DatePicker2 from "../../../../Tools/DatePicker/DatePicker2";
import { BASE_API } from "../../../../../utils/constans";
import { FormattedMessage } from "react-intl";
import { secundario } from '../../../../../api/getUmbrales';
import TablePagination from '@mui/material/TablePagination';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: secundario,
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function TablaOficina() {
  function createData(Cobertura) {
    return { Cobertura };
  }

  const rows = [createData("La siguiente tabla mostrara nuestras coverturas")];
  //Token
  const [rowsPerPage, setRowsPerPage] = useState(5); 
  const [data, setData] = useState([]); // Estado para almacenar los datos de los bancos
  const [page, setPage] = useState(0); // Estado para el número de página actual
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const token = getToken();

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Authorization", `Bearer ${token}`);
  //Jalar los datos de las Api's

  // Cambiar página
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

// Cambiar filas por página
  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
  };
  const fetchData = () => {
    fetch(`${BASE_API}oficinas/get_listado/`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setUsers(data);
        console.log(data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  //Eliminar
  function eliminar(id) {
    let response = fetch(`${BASE_API}oficinas/get_id/` + id + "/", {
      method: "PUT",
      headers: headers,
      body: JSON.stringify({ OF_activo: false }),
    });
    response.then((response) => {
      console.log(response);
    });

    console.log(id);
  }

  //editar
  const [editar, setEditar] = React.useState("");
  function editarPrueba() {
    alert("Entro a editar");
  }
  const [valuee, setValuee] = useState({
    txtoficinaedit: "",
    edit: "",
  });
  const edittClick = (prop) => (event) => {
    setValue({ ...valuee, [prop]: event.target.value });
    console.log(valuee);
  };

  async function PUTDataToken(aux) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${token}`);

    try {
      const response = fetch(`${BASE_API}oficinas/get_id/` + idselect + "/", {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(aux),
      });

      if (!response.ok) {
        throw new Error("Something went wrong. Status: " + response.status);
      }
      const responseJSON = await response.json();
      return responseJSON;
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }

  //Funcion para el boton de editar y abrir ventana modal
  const [open, setOpen] = React.useState(false);
  const [idselect, setidselect] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const handleClickOpen = (scrollType, row) => () => {
    setScroll(scrollType);
    setEditar(row);
    setidselect(row.OF_idoficina);
  };

  useEffect(() => {
    if (editar != "") setOpen(true);
  }, [editar]);

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  //Constante para los datos
  const [value, setValue] = useState({
    txtoficina: "",
    // txtfecha: "",
    txtasignado: "",
  });
  //Funcion para agregar nuevos datos de la api
  const anadirClick = (prop) => (event) => {
    setValue({ ...value, [prop]: event.target.value });
    console.log(value);
  };
  async function postDataToken(aux) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${token}`);

    try {
      const response = await fetch(`${BASE_API}oficinas/get_listado/`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(aux),
      });

      if (!response.ok) {
        throw new Error("Something went wrong. Status: " + response.status);
      }
      const responseJSON = await response.json();
      return responseJSON;
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }

  // Funcion para abril modal para agregar datoo
  const [openn, setOpenn] = React.useState(false);
  const [scrolll, setScrolll] = React.useState("paper");
  const handleClickOpen1 = (scrollType) => () => {
    setOpenn(true);
    setScrolll(scrollType);
  };

  const handleClose1 = () => {
    setOpenn(false);
  };

  const descriptionElementRef1 = React.useRef(null);
  React.useEffect(() => {
    if (openn) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openn]);

  return (
    <div>
      <Grid container alignItems="center" spacing={5}>
        <Grid item xs={3}>
          <SearchTXTField
            label={<FormattedMessage id="globBusqued" defaultMessage="Búsqueda" />}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" onClick={handleClickOpen1("paper")}>Agregar nuevo dato</Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="right">id</StyledTableCell>
              <StyledTableCell align="right">
                <FormattedMessage id="globOfici" defaultMessage="Oficina" />
              </StyledTableCell>
              <StyledTableCell align="right">
                <FormattedMessage id="globEstatu" defaultMessage="Estatus" />
              </StyledTableCell>
              <StyledTableCell align="right">
                <FormattedMessage id="dictaFecha" defaultMessage="Fecha" />
              </StyledTableCell>
              <StyledTableCell align="right">
                <FormattedMessage id="globAsig" defaultMessage="Asignado" />
              </StyledTableCell>
              <StyledTableCell align="right">
                <FormattedMessage id="globEditar" defaultMessage="Editar" />
              </StyledTableCell>
              <StyledTableCell align="right">
                <FormattedMessage id="estconElim" defaultMessage="Eliminar" />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row) => (
              <StyledTableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.OF_idoficina}
                </StyledTableCell>

                <StyledTableCell align="right">{row.OF_oficina}</StyledTableCell>
                {/* <TableCell align="right">{row.MON_abreviatura}</TableCell> */}
                {row.OF_activo ? (
                  <StyledTableCell align="right">activo</StyledTableCell>
                ) : (
                  <StyledTableCell align="right">inative</StyledTableCell>
                )}
                <StyledTableCell align="right">{row.OF_actualizofecha}</StyledTableCell>
                <StyledTableCell align="right">{row.OF_actualizousuario}</StyledTableCell>
                <StyledTableCell align="right">
                  <Button onClick={handleClickOpen("paper", row)}>
                    <ArrowBackIcon />
                  </Button>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Button onClick={() => eliminar(row.OF_idoficina)}>
                    <DeleteForeverIcon />
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <div>
        {/* Modal para editar dato */}
        <Dialog open={open}>
          <form
            onSubmit={(e) => {
              const { txtoficinaedit } = e.target.elements;
              e.preventDefault();
              let aux = {
                OF_oficina: txtoficinaedit.value,
                // MON_abreviatura: edit.value,
              };
              PUTDataToken(aux);
            }}
          >
            <DialogTitle>
              <FormattedMessage id="confGEditP" defaultMessage="Editar plan" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Box>
                  {" "}
                  <CardContent>
                    <CardContent>
                      <Typography style={{ fontSize: 14, color: "grey" }}>
                        <FormattedMessage
                          id="globOfici"
                          defaultMessage="Oficina"
                        />
                      </Typography>
                      <TextField
                        name="txtoficinaedit"
                        label=""
                        fullWixdth
                        defaultValue={editar.OF_oficina}
                        onChange={edittClick("txtoficinaedit")}
                      />
                      <br />
                    </CardContent>
                  </CardContent>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ButtonVino
                variant="contained"
                label={
                  <FormattedMessage id="globCance" defaultMessage="Cancelar" />
                }
                onClick={handleClose}
              />
              <ButtonVino
                variant="contained"
                color="primary"
                label={
                  <FormattedMessage id="globGuarda" defaultMessage="Guardar" />
                }
                onClick={handleClose}
                type="submit"
              />
            </DialogActions>
          </form>
        </Dialog>
        {/* Modal para agregar dato */}
        <Dialog open={openn}>
          <form
            onSubmit={(e) => {
              const {
                txtoficina,

                // txtfecha,
                txtasignado,
              } = e.target.elements;
              e.preventDefault();
              let aux = {
                OF_oficina: txtoficina.value,

                // OF_actualizofecha: txtfecha.value,
                OF_actualizousuario: txtasignado.value,
              };
              postDataToken(aux);
            }}
          >
            {/* Aqui se agregan los nuevos datos */}
            <DialogTitle>
              <FormattedMessage id="globAgrega" defaultMessage="Agregar" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Box>
                  {" "}
                  <CardContent>
                    <CardContent>
                      <Typography style={{ fontSize: 14, color: "grey" }}>
                        <FormattedMessage
                          id="globOfici"
                          defaultMessage="Oficina"
                        />
                      </Typography>
                      <TextField
                        name="txtoficina"
                        label=""
                        fullWidth
                        onChange={anadirClick("txtoficin")}
                      />
                      <br />
                      <Typography style={{ fontSize: 14, color: "grey" }}>
                        <FormattedMessage
                          id="globAsigna"
                          defaultMessage="Asignado"
                        />
                      </Typography>
                      <TextField
                        name="txtasignado"
                        label=""
                        fullWidth
                        onChange={anadirClick("txtasignado")}
                      />
                      <br />
                    </CardContent>
                  </CardContent>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ButtonVino
                variant="contained"
                label={
                  <FormattedMessage id="globCance" defaultMessage="Cancelar" />
                }
                onClick={handleClose1}
              />
              <ButtonVino
                variant="contained"
                color="primary"
                label={
                  <FormattedMessage id="globGuarda" defaultMessage="Guardar" />
                }
                onClick={handleClose1}
                type="submit"
              />
            </DialogActions>
          </form>
        </Dialog>
      </div>
    </div>
  );
}
