import * as React from "react";
import TextField from "@mui/material/TextField";
import { IconButton, makeStyles } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import PercentIcon from "@mui/icons-material/Percent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Tooltip } from "@mui/material";

export default function TXTField(props) {
  const showSearchIcon = props.showSearchIcon;
  const showPorcentaje = props.showPorcentaje;
  const showSimboloPeso = props.showSimboloPeso;
  const showStartPorcentaje = props.showStartPorcentaje;
  const showStartSimboloPeso = props.showStartSimboloPeso;

  //Estilo del helpertext
  const useHelperTextStyles = makeStyles(() => ({
    root: {
      background: "#fafafa",
      margin: "0px 0px 0px 0px !important",
      paddingLeft: "10px",
      paddinTop: "0px",
    },
  }));
  const helperTextStyles = useHelperTextStyles();
  return (
    <TextField
      autoComplete={props.autoComplete}
      //Tamaño del textfield
      style={{ position: "sticky" }}
      //Tamaño del textfield
      fullWidth={props.fullWidth ? false : true}
      //Manejo de errores
      InputLabelProps={props.InputLabelProps}
      error={props.error}
      errorMessage={props.errorMessage}
      // Identificar del texto
      value={props.value}
      //valorDefault
      defaultValue={props.defaultValue}
      // Identificador
      id={props.id}
      // Texto que mostrara el componente
      label={props.label}
      // Tipo de entrada
      onBlur={props.onBlur}
      onInput={props.onInput}
      type={props.type}
      // Texto que se muestra debajo del componente como indicaciones
      helperText={props.helperText ? props.helperText : ""}
      // Tamaño del componente
      size={props.size}
      // Si queremos que el componente sea requerido colocamos: required={true}
      required={props.required}
      // Si queremos que el componente sea multiline colocamos: multiline={true}
      multiline={props.multiline}
      // Si queremos que el componente sea multiline y tenga filas por default colocamos: rows={5}
      rows={props.rows}
      // El evento onchange ocurre cuando el valor de un elemento ha cambiado.
      onChange={props.onChange}
      // El onclickevento ocurre cuando el usuario hace clic en un elemento.
      onClick={props.onClick}
      // Para limitar la cantidad de caracteres que un usuario puede ingresar en un campo de entrada de texto.
      maxlength={props.maxlength}
      // Estos valores no cambiaran ya que son los que se definieron para estandarizar, no se pide valor
      variant="outlined"
      // El color tampoco cambia es el definido por la empresa, no se pide valor
      color="gray"
      // Son los margenes que dan en los 4 lados, no se pide valor
      margin="normal"
      // Esta propiedad nos brinda darle diseño al componente, nosotros solo utilizaremos el
      // background para que lo muestre en color blanco, no se pide valor
      className={props.className}
      sx={{
        background: "white",
      }}
      FormHelperTextProps={{
        classes: {
          root: helperTextStyles.root,
        },
      }}
      //Placeholder, texto que ayuda al usuario a saber que colocar en el text field
      placeholder={props.placeholder}
      //deshabilitar input , input inactivo (true por defecto)
      disabled={props.disabled}
      startAdornment={props.startAdornment}
      onKeyPress={props.onKeyPress}
      onCopy={props.onCopy}
      onCut={props.onCut}
      onPaste={props.onPaste}
      //ICONOS
      InputProps={{
        sx: {
          "& input": {
            textAlign: !props.textAlign ? "left" : props.textAlign,
          },
        },

        position: "absolute",
        readOnly: props.readOnly, // solo lectura, input activo (true por defecto)
        endAdornment: (
          <InputAdornment
            position={!props.iconPosition ? "start" : props.iconPosition}
            style={{ position: "relative", zIndex: 1 }}
          >
            {!showSearchIcon ? null : (
              <div>
                <Tooltip title="Buscar">
                  <IconButton disabled={props.disabledicon}>
                    <SearchIcon
                      onClick={props.iconOnClick}
                      type="button"
                      style={{ cursor: "pointer" }}
                      id={props.idIcon}
                      name={props.nameIcon}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            {!showPorcentaje ? null : (
              <PercentIcon style={{ color: "bcbcbc" }} />
            )}
            {!showSimboloPeso ? null : (
              <AttachMoneyIcon style={{ color: "bcbcbc" }} />
            )}
          </InputAdornment>
        ),
        startAdornment: !props.startAdornment ? null : (
          <InputAdornment
            position={!props.iconPosition ? "start" : props.iconPosition}
            style={{ position: "relative", zIndex: 1 }}
          >
            {!showStartSimboloPeso ? null : (
              <AttachMoneyIcon style={{ color: "bcbcbc" }} />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}
