// importaciones por default de react
import * as React from "react";
import { useState, useEffect } from "react";

//importaciones para aplicar estilos a la tabla de folios
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import TablePagination from "@mui/material/TablePagination";

//Consultas de APIS
import { getDataToken } from "../../../api/dataToken";

//importaciones del idioma al componente
import { FormattedMessage } from "react-intl";

//importaciones de MUI y componentes estandarizados
import Grid from "@material-ui/core/Grid";
import TXTField from "../../Tools/TextField/TextField";
import Proceso_Polizas from "../Elements/Proceso_Polizas";
import ConsultaAnalista from "../Elements/ConsultaAnalista";
import ConsultaTiempo from "../Elements/ConsultaTiempo";

//importaciones de las imagenes del menu tipo de busqueda
import global_on from "../../../img/WF/global_on.svg";
import global_off from "../../../img/WF/global_off.svg";
// import analista_off from "../../../img/WF/analista_off.svg";
import analista_on from "../../../img/WF/analista_on.svg";
import tiempo_por_modulo_off from "../../../img/WF/tiempo_por_modulo_off.svg";
import tiempo_por_modulo_on from "../../../img/WF/tiempo_por_modulo_on.svg";

/* ESTILOS DE LA TABLA */
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00203b",

    padding: 0,
    paddingLeft: "5px",
    height: "65px",
    color: theme.palette.common.white,
    fontSize: "20px",
    borderColor: "#e2e2e2",
    border: "1px solid #e2e2e2",
    fontFamily: "Avenir-Light",
  },
  [`&.${tableCellClasses.body}`]: {
    height: "20px",
    padding: 0,
    fontSize: "16px",
    borderColor: "#e2e2e2",
    border: "1px solid #e2e2e2",
    fontFamily: "Avenir-Light",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    //backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    //border: 0,
  },
}));

export default function TableTotalFolios({ folios }) {
  // console.log("PAso de fechas", folios);
  const  [inFolio, setFolios] = useState(folios);
  const [uniquePolizas, setUniquePolizas] = useState(new Set());
  
  // const [data, setData] = useState([]);
  useEffect(() => {
    const uniquePolizasSet = new Set();
    const uniqueData = inFolio.filter((row) => {
      const poliza = row.POL_idpoliza?.POL_numpoliza;
      if (!uniquePolizasSet.has(poliza)) {
        uniquePolizasSet.add(poliza);
        return true;
      }
      return false;
    });
    setUniquePolizas(uniquePolizasSet);
    setData(uniqueData);
  }, [inFolio]);

  useEffect(() => {
    // Este efecto se ejecutará cada vez que las propiedades 'folios' cambien
    setFolios(folios);
  }, [folios]);


  /* Función para validar datos tabla, en este caso */
  function createData(
  
    estatus
  ) {
    if (estatus === 1) {
      estatus = (
        <label className="lbl-casos-tabla">
          <CircleRoundedIcon style={{ color: "#922B21" }} /> cancelado{" "}
        </label>
      );
    } else if (estatus === 2) {
      estatus = (
        <label className="lbl-casos-tabla">
          <CircleRoundedIcon style={{ color: "#ebcc2c" }} /> en proceso{" "}
        </label>
      );
    } else if (estatus === 3) {
      estatus = (
        <label className="lbl-casos-tabla">
          <CircleRoundedIcon style={{ color: "#11a04a" }} /> completado{" "}
        </label>
      );
    }
    return {
      estatus,
    };
  }
  const rows = [
    createData(inFolio
    ),
  ];

  /* CONSTANTES DE ESTADOS*/
  const [data, setData] = useState(rows); // esta constante guarda un arreglo en donde se almacenará los datos de la API
  const [order, setOrder] = useState("ASC"); // esta constante tiene como propósico guardar el estado del orden de la tabla
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla
  const [InfoDataRow, setInfoDataRow] = useState(""); //Aqui es donde almacenará los datos de la fila seleccionadad
  const [btnDownloadInfo, setBtnDownloadInfo] = useState(true);

  //funcion cuando se seleccione busqueda global, analista o tiempo
  const [buscarGlobal, setBuscarGlobal] = useState(false);
  const [buscarAnalista, setBuscarAnalista] = useState(false);
  const [buscarTiempo, setBuscarTiempo] = useState(false);

  const handleClickbuscarGlobal = () => {
    setBuscarGlobal(true);
    setBuscarAnalista(false);
    setBuscarTiempo(false);
  };

  const handleClickbuscarAnalista = () => {
    setBuscarGlobal(false);
    setBuscarAnalista(true);
    setBuscarTiempo(false);
  };

  const handleClickbuscarTiempo = () => {
    setBuscarGlobal(false);
    setBuscarAnalista(false);
    setBuscarTiempo(true);
  };

  /* FUNCIÓN PARA ORDENAR LA TABLA ASC/DSC (Ascendent/ Descendant) */
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...inFolio].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setOrder("DSC");
      setData(sorted);
    } else if (order === "DSC") {
      const sorted = [...inFolio].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? -1 : 1
      );
      setOrder("ASC");
      setData(sorted);
    }
  };

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <Grid
        style={{ paddingBottom: "10px" }}
        container
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={4}>
          <h4-search>
            <FormattedMessage id="glob.buscar" defaultMessage="Buscar" />:
          </h4-search>
          <TXTField
            showSearchIcon={true}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Paper sx={{ width: "auto" }}>
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="globNoDeFo"
                    defaultMessage="No. de folio"
                  />
                </StyledTableCell>
                {/*polizas */}
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="globNoPoli"
                    defaultMessage="No. de póliza"
                  />
                </StyledTableCell>
                {/**Siniestros */}
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="globNSin"
                    defaultMessage="No. de siniestro"
                  />
                </StyledTableCell>

                {/*asegurado */}
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="globNomAse"
                    defaultMessage="Nombre del asegurado"
                  />
                </StyledTableCell>

                {/*donde se encuentra */}
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="wtDondeSeEncuentraEl"
                    defaultMessage="Modulo"
                  />
                </StyledTableCell>

                {/*analista */}
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="wtAnalistaAsignado"
                    defaultMessage="Analista asignado"
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="globEstatu" defaultMessage="Estatus" />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {data
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                      val.PER_idpersona?.PER_nombre
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    val.POL_idpoliza?.POL_numpoliza
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((row) => (
                  <StyledTableRow key={row.WKT_idworktramite}>
                    <StyledTableCell align="center">
                      {row.RECT_idfolio?.RECT_numerofolio} {/*Numero de folio */}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.POL_idpoliza?.POL_numpoliza} {/*Numero de poliza */}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.PER_idpersona?.PER_RFC} {/*Numero de siniestro */}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.PER_idpersona?.PER_nombre}{" "}   {/*Nombre*/}
                      {row.PER_idpersona?.PER_apellidopaterno}{" "}
                      {row.PER_idpersona?.PER_apellidomaterno}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_descripcion}   {/*Modulo*/}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.WKT_actualizousuario}   Analista
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.estatus}  
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
        labelRowsPerPage="Registros por página:"
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <br />
      {/*aqui termina la construccion de la tabla la etiqueta "HR" se usa para crear una linea divisora */}
      <hr></hr>
      {/*Aqui comienza la segunda seccion del componente donde tenemos los tipos de busqueda */}
      <Grid xs={12}>Tipo de consulta</Grid>

      {/*usaremos un grid container para meter las 3 imagenes de los tipos de busqueda
       */}
      <Grid container>
        {/*Agregamos la primer imagen en un grid xs esta es la imagen global
        le asignamos el evento creado anteriormente junto con la etiqueta del filtro */}
        <Grid xs={2}>
          <div onClick={handleClickbuscarGlobal} className="div-children-comp">
            <label className="lbl-cont">
              {!buscarGlobal ? (
                <img className="img-iconos-comp" src={global_off} alt="" />
              ) : (
                <img className="img-iconos-comp" src={global_on} alt="" />
              )}
              <h3-contable>
                <FormattedMessage id="wtGlobal" defaultMessage="Global" />
              </h3-contable>
            </label>
          </div>
        </Grid>
        {/*Agregamos la segunda imagen en un grid xs esta es la imagen analista
        le asignamos el evento creado anteriormente junto con la etiqueta del filtro */}
        <Grid xs={2}>
          <div
            onClick={handleClickbuscarAnalista}
            className="div-children-comp"
          >
            <label className="lbl-cont">
              {!buscarAnalista ? (
                <img className="img-iconos-comp"  src={analista_on} alt="" />
              ) : (
                <img className="img-iconos-comp" src={analista_on} alt="" />
              )}
              <h3-contable>
                <FormattedMessage id="wtAnalista" defaultMessage="Analista" />
              </h3-contable>
            </label>
          </div>
        </Grid>
        {/*Agregamos la tercer imagen en un grid xs esta es la imagen tiempo modular
        le asignamos el evento creado anteriormente junto con la etiqueta del filtro */}
        <Grid xs={3}>
          {/*el div es necesario que este debido a que encierra los estilos para aplicar en las imagenes */}
          <div onClick={handleClickbuscarTiempo} className="div-children-comp">
            <label className="lbl-cont">
              {!buscarTiempo ? (
                <img
                  className="img-iconos-comp"
                  src={tiempo_por_modulo_off}
                  alt=""
                />
              ) : (
                <img
                  className="img-iconos-comp"
                  src={tiempo_por_modulo_on}
                  alt=""
                />
              )}
              <h3-contable>
                <FormattedMessage
                  id="wtTiempoPo"
                  defaultMessage="Tiempo por módulo"
                />
              </h3-contable>
            </label>
          </div>
        </Grid>
      </Grid>
      {/*final del grid container de las imagenes */}

      <Grid xs={12}>
        <div>{buscarGlobal ? <Proceso_Polizas folios={folios}/> : null}</div>
        <div>{buscarAnalista ? <ConsultaAnalista folios={folios} /> : null}</div>
        <div>{buscarTiempo ? <ConsultaTiempo folios={folios}/> : null}</div>
      </Grid>
    </div>
  );
}
