/* Brandon Hernandez Rocha 05/2023 */
import * as React from "react";
//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import TXTField from "../../../Tools/TextField/TextField";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";

//idioma
import { FormattedMessage } from "react-intl";

export default function TablaRegistroConsulCont(props) {
  // CONSTANTES / ESTADOS / VARIABLES

  const rows = [
    {
      id: 1,
      asiento: "78974",
      moneda: "01",
      fecha: "25-ene-21",
      cuenta: "212101011432120",
      numFoli: 2021002501,
      numSiniestro: "CAR010093",
      poliza: "N1S1002015",
      asegurado: "MARIA LOPEZ",
      importe: -2400000,
    },
    {
      id: 2,
      asiento: "78980",
      moneda: "01",
      fecha: "25-abr-21",
      cuenta: "212101011432120",

      numFoli: 2021002501,
      numSiniestro: "CAR010093",
      poliza: "N1S1002015",
      asegurado: "MARIA LOPEZ",
      importe: 400000,
    },
    {
      id: 3,
      asiento: "828129",
      moneda: "01",
      fecha: "25-abr-21",
      cuenta: "212101011432120",
      numFoli: 2021002501,
      numSiniestro: "CAR010093",
      poliza: "N1S1002015",
      asegurado: "MARIA LOPEZ",
      importe: -200000,
    },
  ];

  return (
    <>
      <Grid container direction="row" alignItems="center" spacing={3}>
        <Grid item xs={4}>
          <TXTField label="Número de póliza por movimientos contables:" />
        </Grid>
        <Grid item xs={4}>
          <ButtonVino label="Buscar" />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Paper style={{ width: "100%" }}>
            <TableContainer style={{ position: "sticky" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                    <StyledTableCell>
                      <FormattedMessage
                        id="dictaAsiento"
                        defaultMessage="Asiento"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage
                        id="globMoneda"
                        defaultMessage="Moneda"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage
                        id="dictaFecha"
                        defaultMessage="Fecha"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage
                        id="loginCuent"
                        defaultMessage="Cuenta"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="globNoFoli"
                        defaultMessage="Número de folio"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="globNumSini"
                        defaultMessage="Número de siniestro"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="globPoliza"
                        defaultMessage="Póliza"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="globAsegud"
                        defaultMessage="Asegurado"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="globImport"
                        defaultMessage="Importe"
                      />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
                  {rows.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <StyledTableCell component="th" scope="row">
                          {row.asiento}
                        </StyledTableCell>
                        <StyledTableCell>{row.moneda}</StyledTableCell>
                        <StyledTableCell>{row.fecha}</StyledTableCell>
                        <StyledTableCell>{row.cuenta}</StyledTableCell>
                        <StyledTableCell>{row.numFoli}</StyledTableCell>
                        <StyledTableCell>{row.numSiniestro}</StyledTableCell>
                        <StyledTableCell>{row.poliza}</StyledTableCell>
                        <StyledTableCell>{row.asegurado}</StyledTableCell>
                        <StyledTableCell>{row.importe}</StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
