/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import Paper from "@mui/material/Paper";
//icons
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import IconDetalleDictamen from "../../../Tools/Icons/IconDetalleDictamen";
import IconButton from "@material-ui/core/IconButton";
import IconAsignarInvestigador from "../../../Tools/Icons/IconInvestigador";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";

import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TXTField from "../../../Tools/TextField/TextField";
//idioma
import { FormattedMessage } from "react-intl";
import { getDataToken } from "../../../../api/dataToken";
import {
  PERR_ASEGURADO,
  TRAM_BENEFICIOSEV,
  TRAM_FALLECIMIENTO,
  TRAM_RESCATES,
  TRAM_RETIROS,
  TRAM_VENCIMIENTO,
} from "../../../../utils/constanstoken";
import { useEffect } from "react";
import {
  diasMaxTramite,
  dias_dictamen_compleja,
  dias_dictamen_especial,
  dias_dictamen_estandarizado,
  umbralATiempo,
  umbralCancelado,
  umbralEnProceso,
} from "../../../../api/getUmbrales";
import moment from "moment/moment";

export default function TableCharola() {
  /* CONSTANTES DE ESTADOS*/
  let momentbd = require("moment-business-days");
  const [order, setOrder] = useState("ASC"); // esta constante tiene como propósico guardar el estado del orden de la tabla
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla
  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const labelDisplayedRows = ({ from, to, count }) => {
    return `${from}-${to} de ${count}`;
  };

  /* FUNCIÓN PARA ORDENAR LA TABLA ASC/DSC (Ascendent/ Descendant) */
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...dataApiPolxFolioxSin].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setOrder("DSC");
      setDataApiPolxFolioxSin(sorted);
    } else if (order === "DSC") {
      const sorted = [...dataApiPolxFolioxSin].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? -1 : 1
      );
      setOrder("ASC");
      setDataApiPolxFolioxSin(sorted);
    }
  };
  /****** Código Usuario que actualizó *******/
  const [user, setUser] = useState("");
  //GET A API DOCUMENTACION
  const getDataUser = async () => {
    //Resultados de la api documentacion documentosxtiposiniestrostramite
    let result = await getDataToken(
      "login/get_correo/" + localStorage.getItem("SNYTLOG_email") + "/"
    );
    if (result) {
      console.log("resultado", result);
      setUser(
        result?.SNYTLOG_username +
          " " +
          result?.SNYTLOG_surnamedad +
          " " +
          result?.SNYTLOG_surnamemom
      );
      getDataApiPolizaxFolioxSin(result.id);
    }
  };
  useEffect(() => {
    getDataUser();
  }, []);

  /**** SECCIÓN PARA OBTENER DATOS DE LAS APIS PARA LA CHAROLA***** */
  const [dataApiPolxFolioxSin, setDataApiPolxFolioxSin] = useState([]);
  const getDataApiPolizaxFolioxSin = async (id) => {
    try {
      await getDataToken(`polizasfolioxsiniestro/get_rol/${id}/`).then(
        // await getDataToken(`polizasfolioxsiniestro/get_listado/`).then(
        (data) => {
          //Conservamos un arreglo original de la api
          setDataApiPolxFolioxSin(data);
          console.log(data);
        }
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (
    <div>
      <Grid
        style={{ paddingBottom: "20px" }}
        container
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={3}>
          <h4-search>
            <FormattedMessage id="globBuscar" defaultMessage="Buscar" />:
          </h4-search>
          <TXTField
            //showSearchIcon={true}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Paper style={{ width: "100%" }}>
        <TableContainer
          sx={{ maxHeight: 440, showEmptyDataSourceMessage: true }}
          style={{ position: "sticky" }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              "& .MuiTableRow-root:hover": {
                backgroundColor: "#ededed",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ width: "30px" }}></StyledTableCell>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                <StyledTableCell>
                  <Grid container alignItems="center">
                    <Grid item xs={9}>
                      <FormattedMessage
                        id="globNoDeFo"
                        defaultMessage="No. de folio"
                      />{" "}
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton
                        className="icon-button"
                        style={{ color: "white" }}
                        onClick={() => sorting("RECT_numerofolio")}
                      >
                        <UnfoldMoreIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell>
                  <Grid container alignItems="center">
                    <Grid item xs={9}>
                      <FormattedMessage
                        id="globNomAse"
                        defaultMessage="Nombre del asegurado"
                      />
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell>
                  <Grid container alignItems="center">
                    <Grid item xs={10}>
                      <FormattedMessage
                        id="errorTiRec"
                        defaultMessage="Tipo de reclamación"
                      />{" "}
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="dictamPoli" defaultMessage="Pólizas" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="dictamDeta" defaultMessage="Detalle" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="menuInvest"
                    defaultMessage="Investigación"
                  />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {dataApiPolxFolioxSin.length < 0
                ? null
                : dataApiPolxFolioxSin
                    .filter((val) => {
                      if (searchTerm === "") {
                        return val;
                      } else if (
                        /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                        val.RECT_idfolio.RECT_numerofolio.toLowerCase().includes(
                          searchTerm.toLowerCase()
                        )
                      ) {
                        return val;
                      }
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      //Estas validaciones son para ocultar/mostrar columnas detalle/investigación según el tipo de reclamación
                      let tipoReclamacion =
                        row?.RECT_idfolio?.SINC_idcomplejidadsiniestro
                          ?.TRA_idtipotramitesiniestro;
                      let tipoReclamancionMsg = "";
                      if (tipoReclamacion == TRAM_FALLECIMIENTO.id)
                        tipoReclamancionMsg = (
                          <FormattedMessage
                            id="globFallec"
                            defaultMessage="Fallecimiento"
                          />
                        );
                      else if (tipoReclamacion == TRAM_BENEFICIOSEV.id)
                        tipoReclamancionMsg = (
                          <FormattedMessage
                            id="recepBenVi"
                            defaultMessage="Beneficios en vida"
                          />
                        );
                      else if (tipoReclamacion == TRAM_VENCIMIENTO.id)
                        tipoReclamancionMsg = (
                          <FormattedMessage
                            id="recepVenci"
                            defaultMessage="Vencimiento"
                          />
                        );
                      else if (tipoReclamacion == TRAM_RETIROS.id)
                        tipoReclamancionMsg = (
                          <FormattedMessage
                            id="recepRetrs"
                            defaultMessage="Retiros"
                          />
                        );
                      else if (tipoReclamacion == TRAM_RESCATES.id)
                        tipoReclamancionMsg = (
                          <FormattedMessage
                            id="recepResct"
                            defaultMessage="Rescates"
                          />
                        );

                      //Estas validaciones son para mostrar u ocultar/mostrar columnas detalle/investigación
                      let showInvest = false;
                      let showDetalle = false;
                      if (
                        tipoReclamacion == TRAM_FALLECIMIENTO.id ||
                        tipoReclamacion == TRAM_BENEFICIOSEV.id
                      ) {
                        showInvest = true;
                        showDetalle = true;
                      }

                      /*Estas validaciones sirven para editar la tabla en columna casos, agregando el 
                  icono de color según corresponda*/
                      //Programación de días Días

                      const fechaAviso = moment(
                        row?.RECT_idfolio?.RECT_fechaaviso,
                        "YYYY-MM-DD"
                      ); // Supongamos que tienes una fecha en formato 'YYYY-MM-DD'
                      let diasTranscurridos =
                        momentbd().businessDiff(fechaAviso);

                      //Color
                      let color = "";

                      if (diasTranscurridos > dias_dictamen_estandarizado) {
                        color = umbralCancelado;
                      } else if (
                        diasTranscurridos === dias_dictamen_estandarizado ||
                        diasTranscurridos === dias_dictamen_estandarizado
                      ) {
                        color = umbralEnProceso;
                      } else if (
                        diasTranscurridos < dias_dictamen_estandarizado ||
                        diasTranscurridos < dias_dictamen_estandarizado
                      ) {
                        color = umbralATiempo;
                      }
                      const nombreAsegurado = `${row?.PER_idpersona?.PER_nombre} ${row?.PER_idpersona?.PER_apellidopaterno} ${row?.PER_idpersona?.PER_apellidomaterno}`;

                      // const objetoEncontrado = dataApiJoinRec.find(objeto => objeto?.RECT_idfolio == row.RECT_idfolio && objeto?.PERR_idrol == PERR_ASEGURADO.id);
                      // const nombreAsegurado = `${objetoEncontrado?.PER_nombre} ${objetoEncontrado?.PER_apellidopaterno} ${objetoEncontrado?.PER_apellidomaterno}`;

                      //Ahora, con el uso de la api Polizaportramites, se busca la cantidad de polizas asociadas por folio
                      // Filtrar objetos que cumplan con la condición
                      const polizasPorFolio = dataApiPolxFolioxSin.filter(
                        (objeto) =>
                          objeto?.RECT_idfolio?.RECT_idfolio ==
                          row?.RECT_idfolio?.RECT_idfolio
                      );
                      let cantPol = polizasPorFolio.length;
                      return (
                        <TableRow key={row.RECT_idfolio?.RECT_idfolio}>
                          <StyledTableCell align="center">
                            <CircleRoundedIcon style={{ color: color }} />
                          </StyledTableCell>
                          <StyledTableCell>
                            {row?.RECT_idfolio?.RECT_numerofolio}
                          </StyledTableCell>
                          <StyledTableCell>{nombreAsegurado}</StyledTableCell>
                          <StyledTableCell>
                            {tipoReclamancionMsg}
                          </StyledTableCell>
                          <StyledTableCell>{cantPol}</StyledTableCell>
                          <StyledTableCell align="center">
                            {/* {!showDetalle ? null : ( */}
                            <IconDetalleDictamen
                              to="/dictamen/detalle_folio"
                              state={{ row: polizasPorFolio }}
                              tooltipTitle={
                                <FormattedMessage
                                  id="dictamDeta"
                                  defaultMessage="Detalle"
                                />
                              }
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {/* {!showInvest ? null : */}
                            <IconAsignarInvestigador
                              to="/dictamen/investigacion"
                              state={{ folio: polizasPorFolio }}
                              tooltipTitle={
                                <FormattedMessage
                                  id="dictamInvestigacion"
                                  defaultMessage="Investigación"
                                />
                              }
                            />
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={dataApiPolxFolioxSin.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={
            <FormattedMessage
              id="globFilasPPag"
              defaultMessage="Filas por página"
            />
          }
          labelDisplayedRows={labelDisplayedRows}
        />
      </Paper>
    </div>
  );
}
