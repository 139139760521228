//  David Ruiz 10/2022

import React from "react";
import Button from "@mui/material/Button";
import '../../../css/Typography.css'
import { Tooltip } from '@mui/material';

const ButtonVino = (props) => {

  return (
    <Tooltip title={props.tooltipTitle}>
      <Button
        style={{
          boxShadow: 'none',
          textTransform: 'none',
          fontFamily: 'Avenir-light',
          borderRadius: 0,
          paddingTop: '5px',
          paddingBottom: '5px',
          paddingLeft: props.paddingLeft ? props.paddingLeft : '15px',
          paddingRight: props.paddingRight ? props.paddingRight : '15px',
          display:props.display,
          marginRight: props.marginRight ? props.marginRight : '0px',
          marginLeft: props.marginLeft ? props.marginLeft : '0px',
        }}
        variant="contained"
        //color será la propiedad para cambiar el color según la paleta de colores del tema, el tema por defecto es primary
        color={props.color}
        size={"small"}
        type={props.type}
        href={props.enlace}
        //ojo aqui es disabled
        //true para desabilitar y false para habilitar
        disabled={props.estatus} 
        onClick={props.onClick}
        onSubmit={props.onSubmit}
        onChange={props.onChange}
        id={props.id}
        startIcon={props.startIcon}
      >
        <button-vino-small> {props.label}</button-vino-small>{" "}
        {/*texto dentro del boton */}
      </Button>
    </Tooltip>
    
  );
};

export default ButtonVino;
