import * as React from "react";
import Grid from "@mui/material/Grid";
import { DATA_PM } from "../../../../utils/constansPM";
import { FormattedMessage } from "react-intl";

export function TabDatosBeneficiarios(props) {
  const dataPoliza = props.dataPoliza;
  console.log(dataPoliza);

  return (
    <div style={{ padding: "5px", width: "800px" }}>
      {dataPoliza[0]?.POL_idpoliza?.POL_idpoliza ? (
        <table>
          <thead>
            <tr>
              <th align="center">
                <strong>
                  <FormattedMessage
                    id="datosNomComBene"
                    defaultMessage="Nombre completo beneficiario"
                  />
                </strong>
              </th>
              <th align="center">
                <strong>
                  <FormattedMessage
                    id="FchNacBene"
                    defaultMessage="Fecha nacimiento beneficiario"
                  />
                </strong>
              </th>
              <th align="center">
                <strong>
                  <FormattedMessage
                    id="PorcBene"
                    defaultMessage="Porcentaje beneficiario"
                  />
                </strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {dataPoliza?.map((row) => (
              <tr key={row?.POL_idpoliza?.POL_idpoliza}>
                <td
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    width: "333px",
                  }}
                >
                  {row?.PER_idpersona?.PER_nombre +
                    " " +
                    row?.PER_idpersona?.PER_apellidopaterno +
                    " " +
                    row?.PER_idpersona?.PER_apellidomaterno}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    width: "333px",
                  }}
                >
                  {row?.PER_idpersona?.PER_fechanacimiento}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    width: "333px",
                  }}
                >
                  {row?.PXP_porcentajebeneficiario}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div>
              <strong>
                <FormattedMessage
                  id="datosNomComBene"
                  defaultMessage="Nombre completo beneficiario"
                />
              </strong>
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_1]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_2]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_3]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_4]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_5]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_6]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_7]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_8]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_9]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.NOMBRE_COMPLETO_BENEFICIARIO_10]}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ textAlign: "center" }}>
              <strong>
                <FormattedMessage
                  id="FchNacBene"
                  defaultMessage="Fecha nacimiento beneficiario"
                />
              </strong>
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.FECHA_NAC_BENEFICIARIO_1]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.FECHA_NAC_BENEFICIARIO_2]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.FECHA_NAC_BENEFICIARIO_3]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.FECHA_NAC_BENEFICIARIO_4]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.FECHA_NAC_BENEFICIARIO_5]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.FECHA_NAC_BENEFICIARIO_6]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.FECHA_NAC_BENEFICIARIO_7]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.FECHA_NAC_BENEFICIARIO_8]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.FECHA_NAC_BENEFICIARIO_9]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.FECHA_NAC_BENEFICIARIO_10]}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ textAlign: "center" }}>
              <strong>
                <FormattedMessage
                  id="PorcBene"
                  defaultMessage="Porcentaje beneficiario"
                />
              </strong>
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.PORCENTAJE_BENEFICIARIO_1]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.PORCENTAJE_BENEFICIARIO_2]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.PORCENTAJE_BENEFICIARIO_3]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.PORCENTAJE_BENEFICIARIO_4]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.PORCENTAJE_BENEFICIARIO_5]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.PORCENTAJE_BENEFICIARIO_6]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.PORCENTAJE_BENEFICIARIO_7]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.PORCENTAJE_BENEFICIARIO_8]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.PORCENTAJE_BENEFICIARIO_9]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.PORCENTAJE_BENEFICIARIO_10]}
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
