import * as React from "react";
import Grid from "@mui/material/Grid";
import { DATA_PM } from "../../../../utils/constansPM";
import { FormattedMessage } from "react-intl";

export function TabDatosAsegurado(props) {
  const dataPoliza = props.dataPoliza;
  console.log(dataPoliza);
  const dataPoliza2 = props.dataPoliza2;
  console.log(dataPoliza2);

  return (
    <div style={{ padding: "5px", width: "900px" }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div style={{ textAlign: "center" }}>
            <strong>
              <FormattedMessage
                id="recepNomAse"
                defaultMessage="Nombre asegurado"
              />
            </strong>
            <br />
            <br />
            {dataPoliza?.POL_idpoliza?.POL_idpoliza
              ? dataPoliza?.PER_idpersona?.PER_nombre
              : dataPoliza === "" || dataPoliza === undefined
              ? ""
              : dataPoliza[DATA_PM.NOMBRE_ASEGURADO]}
            <br />
            <br />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ textAlign: "center" }}>
            <strong>
              <FormattedMessage
                id="datosApPat"
                defaultMessage="Apellido Paterno"
              />
            </strong>
            <br />
            <br />
            {dataPoliza?.POL_idpoliza?.POL_idpoliza
              ? dataPoliza?.PER_idpersona?.PER_apellidopaterno
              : dataPoliza === "" || dataPoliza === undefined
              ? ""
              : dataPoliza[DATA_PM.APELLIDO_PATERNO_ASE]}
            <br />
            <br />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ textAlign: "center" }}>
            <strong>
              <FormattedMessage
                id="datosApMat"
                defaultMessage="Apellido Materno"
              />
            </strong>
            <br />
            <br />
            {dataPoliza?.POL_idpoliza?.POL_idpoliza
              ? dataPoliza?.PER_idpersona?.PER_apellidomaterno
              : dataPoliza === "" || dataPoliza === undefined
              ? ""
              : dataPoliza[DATA_PM.APELLIDO_MATERNO_ASE]}
            <br />
            <br />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ textAlign: "center" }}>
            <strong>
              <FormattedMessage id="globGen" defaultMessage="Género" />
            </strong>
            <br />
            <br />
            {dataPoliza?.POL_idpoliza?.POL_idpoliza
              ? dataPoliza?.PER_idpersona?.PER_sexo
              : dataPoliza === "" || dataPoliza === undefined
              ? ""
              : dataPoliza[DATA_PM.GENERO_SEXO]}
            <br />
            <br />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ textAlign: "center" }}>
            <strong>
              <FormattedMessage id="globCurp" defaultMessage="CURP" />
            </strong>
            <br />
            <br />
            {dataPoliza?.POL_idpoliza?.POL_idpoliza
              ? dataPoliza?.PER_idpersona?.PER_CURP
              : dataPoliza === "" || dataPoliza === undefined
              ? ""
              : dataPoliza[DATA_PM.CURP_ASEGURADO]}
            <br />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ textAlign: "center" }}>
            <strong>
              <FormattedMessage id="globRfcp" defaultMessage="RFC" />
            </strong>
            <br />
            <br />
            {dataPoliza?.POL_idpoliza?.POL_idpoliza
              ? dataPoliza?.PER_idpersona?.PER_RFC
              : dataPoliza === "" || dataPoliza === undefined
              ? ""
              : dataPoliza[DATA_PM.RFC_ASEGURADO]}
            <br />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ textAlign: "center" }}>
            <strong>
              <FormattedMessage
                id="globFechNa"
                defaultMessage="Fecha de nacimiento"
              />
            </strong>
            <br />
            <br />
            {dataPoliza?.POL_idpoliza?.POL_idpoliza
              ? dataPoliza?.PER_idpersona?.PER_fechanacimiento
              : dataPoliza === "" || dataPoliza === undefined
              ? ""
              : dataPoliza[DATA_PM.FECHA_NAC_ASEGURADO]}
            <br />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ textAlign: "center" }}>
            <strong>
              <FormattedMessage id="datosEdadE" defaultMessage="Edad emisión" />
            </strong>
            <br />
            <br />
            {dataPoliza?.POL_idpoliza?.POL_idpoliza
              ? dataPoliza?.PER_idpersona?.PER_edadtramite
              : dataPoliza === "" || dataPoliza === undefined
              ? ""
              : dataPoliza[DATA_PM.EDAD_EMISION]}
            <br />
          </div>
        </Grid>
      </Grid>
      <br />
      <hr />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div style={{ textAlign: "center" }}>
            <strong>
              <FormattedMessage
                id="datosNomC"
                defaultMessage="Nombre contratante sistema"
              />
            </strong>
            <br />
            <br />
            {dataPoliza2?.POL_idpoliza?.POL_idpoliza
              ? dataPoliza2?.PER_idpersona?.PER_nombre
              : dataPoliza === "" || dataPoliza === undefined
              ? ""
              : dataPoliza[DATA_PM.NOMBRE_CONTRATANTE]}
            <br />
            <br />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ textAlign: "center" }}>
            <strong>
              <FormattedMessage
                id="datosApPatC"
                defaultMessage="Apellido Paterno Contratante"
              />
            </strong>
            <br />
            <br />
            {dataPoliza2?.POL_idpoliza?.POL_idpoliza
              ? dataPoliza2?.PER_idpersona?.PER_apellidopaterno
              : dataPoliza === "" || dataPoliza === undefined
              ? ""
              : dataPoliza[DATA_PM.APELLIDO_PATERNO_CONT]}
            <br />
            <br />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ textAlign: "center" }}>
            <strong>
              <FormattedMessage
                id="datosApMatC"
                defaultMessage="Apellido Materno Contratante"
              />
            </strong>
            <br />
            <br />
            {dataPoliza2?.POL_idpoliza?.POL_idpoliza
              ? dataPoliza2?.PER_idpersona?.PER_apellidomaterno
              : dataPoliza === "" || dataPoliza === undefined
              ? ""
              : dataPoliza[DATA_PM.APELLIDO_MATERNO_CONT]}
            <br />
            <br />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
