/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import TXTField from "../../Tools/TextField/TextField";
import BTUpload from "../../Tools/Bottones/BTUpload";
import { Grid } from "@mui/material";
import { getFiles, downloadBlobsAsZip } from "../../../nextcloud/functions";
//Encrypt library
import JSEncrypt from "jsencrypt";
//idioma
import { FormattedMessage } from "react-intl";
import { putDataToken, getDataToken } from "../../../api/dataToken";
import moment from "moment";
import getDate from "../../Registro/Function/obtenerFecha";
import getHours from "../../Registro/Function/obtenerHora";
import { formatofecha } from "../../../api/getUmbrales";
import validarRFC from "../../Login/Elements/validateRFC";

//Solo aparecerá la alerta con dos botones
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogDownloadInfo(props) {
  const arrayInfoData = props.infoDataRow;
  console.log(arrayInfoData);
  //Obtener usuario.
  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");
  const [rfcA, setrfcA] = React.useState("");

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");
  console.log(moment(fechaEmpAn).format(formatofecha));
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();
  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;
  console.log("fechaActSIN" + fechaActSIN);

  const getData = async () => {
    try {
      var resultAnalista = await getDataToken(`login/get_correo/${email}/`);
      console.log(resultAnalista);
      setnombreAnalista(
        resultAnalista.SNYTLOG_username +
          " " +
          resultAnalista.SNYTLOG_surnamedad +
          " " +
          resultAnalista.SNYTLOG_surnamemom
      );
      setrfcA(resultAnalista.SNYTLOG_RFC);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  const handleKeyStop = (event) => {
    const maxLength = 13;
    const value = event.target.value;
    if (value.length >= maxLength) {
      event.preventDefault();
    }
  };

  //Abre el diálogo de uploadDownloadInfo
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  //Esta contante servirá para cambiar el mensaje segun los datos que se ingresen para autorizar la descarga
  const [validateData, setValidateData] = React.useState(
    "Ingresa llave privada"
  );

  //Esta contante servirá para el formulario de llave privada y rfc, si esta true mostrará los de la llave privada, y viceversa para RFC.
  const [showFormPK, setShowFormPK] = React.useState(true);

  const [disableFirtsButton, setDisableFirtsButton] = React.useState(true);
  const publicKey = `-----BEGIN PUBLIC KEY-----
  MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgG+plk5f3u1NHRVUDp+cGwODfxM+
  VlcBybZGN5THoAxnylRxW5JODsVTXcYO37sum0/thhhjzlpDpcUs1F/3FMQLhvMc
  sZaqXYYchokasJpMdCcYXlR9qj/RNdBvcFB46q0eAy+fjUa7xdM4hR6k7gMxtnIU
  Tug4374U7K4wWfgVAgMBAAE=
  -----END PUBLIC KEY-----`;
  const [privateKey, setPrivateKey] = React.useState("");
  // Las variables de acontinuación tienen como objetivo mostrar la fecha actual
  var day = new Date().getDate();
  var month = new Date().getMonth() + 1;
  var year = new Date().getFullYear();
  var formatDate = day + "/" + month + "/" + year; //format: dd-mm-yyyy;

  //La siguiente constante ayudará a validar RFC con el formato del SAT
  const [rfc, setRfc] = React.useState("");
  // patron del RFC, persona moral
  const _rfc_pattern_pm =
    "^(([A-ZÑ&]{3})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{3})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{3})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{3})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
  // patron del RFC, persona fisica
  const _rfc_pattern_pf =
    "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
    "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setValidateData("Ingresa llave privada.");
    setDisableFirtsButton(true);
    setPrivateKey("");
    setShowFormPK(true);
    setOpen(false);
  };
  const handleClose2 = () => {
    setValidateData("Ingresa llave privada.");
    setDisableFirtsButton(true);
    setPrivateKey("");
    setShowFormPK(true);
    setOpen(false);
    setOpen2(false);
  };
  const handleChangePrivKey = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.addEventListener("load", function () {
      setPrivateKey(reader.result);
    });
    reader.readAsText(file);
  };
  React.useEffect(() => {
    if (privateKey != "") {
      // Encrypt with the public key...
      var encrypt = new JSEncrypt();
      encrypt.setPublicKey(publicKey);
      // Generate a random alphanumerical string of length 11 ( change substring parameter for other length)
      var aux = Math.random().toString(36).substring(2);
      var encrypted = encrypt.encrypt(aux);

      // Decrypt with the private key...
      var decrypt = new JSEncrypt();
      decrypt.setPrivateKey(privateKey);
      var uncrypted = decrypt.decrypt(encrypted);

      // Now a simple check to see if the round-trip worked.
      if (uncrypted == aux) {
        setDisableFirtsButton(false);
        setValidateData(
          "La llave privada coincide. Da clic en Aceptar para continuar."
        );
      } else {
        setValidateData(
          "La llave seleccionada no coincide. Inténtelo de nuevo."
        );
        setDisableFirtsButton(true);
      }
    }
  }, [privateKey]);

  const handleChangedForm = () => {
    setShowFormPK(false);
    setValidateData("Ingresa tu R.F.C");
  };

  const validateRFC = (rfc) => {
    let validRfc = rfc;
    if (validRfc === rfcA) {
      setOpen2(true);
    } else {
      setValidateData(
        "El R.F.C. ingresado no es correcto. Verifica e inténtalo de nuevo."
      );
      return false;
    }
  };

  //Funcion para la descarga de la carpeta del siniestro seleccionado
  const [btnDescarga, setBtnDescarga] = React.useState({
    estatus: false,
    label: "Descarga de archivos",
  });
  const descargarArchivos = async () => {
    setBtnDescarga({ ...btnDescarga, label: "Descargando...", estatus: true });
    handleClose();
    handleClose2();

    var response = await getFiles(arrayInfoData?.num_folio);
    if (!response) {
      //Si la respuesta es falsa es porque no existe una carpeta con ese folio
      alert("No existe carpeta con ese folio");
      setBtnDescarga({
        ...btnDescarga,
        label: "Descargar archivos",
        estatus: false,
      });
    } else {
      //En caso contrario, revisará que la carpeta tenga archivos para descargarlos, si no, mandar alerta, por ejemplo.
      console.log(response);
      if (response.length > 0) {
        let arrayArchivos = [];
        response.map((e) => {
          arrayArchivos.push({ blob: e.fileBlob, filename: e.fileName });
        });
        downloadBlobsAsZip(arrayArchivos, arrayInfoData?.num_folio);
        setBtnDescarga({
          ...btnDescarga,
          label: "Descargar archivos",
          estatus: false,
        });
        putDescargado();
        console.log("Id investigacion", arrayInfoData?.inv_id);
      } else {
        alert("La carpeta está vacía.");
        setBtnDescarga({
          ...btnDescarga,
          label: "Descargar archivos",
          estatus: false,
        });
      }
    }

    async function putDescargado() {
      try {
        let idInvestigador = {
          INV_descargado: true,
          INV_fechadescargado: fechaActSIN,
          INV_actualizofecha: fechaActSIN,
          INV_actualizousuario: nombreAnalista,
        };
        var result = await putDataToken(
          `investigacion/get_id/` + arrayInfoData?.inv_id + "/",
          idInvestigador
        );
        console.log("Registro exitoso", result);
      } catch (error) {
        console.log(error);
        throw error;
      }
    }
  };

  const handleClick = () => {
    handleClose();
  };

  return (
    <>
      <div>
        {/*BOTON QUE HACE EL LLAMADO AL DIALOGO*/}
        {/*Este boton aparecera el js en donde se necesite de la alerta*/}
        <ButtonVino
          onClick={handleClickOpen}
          label={props.mainButton}
          estatus={props.disabledbtn}
        />
        {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
        <BootstrapDialog
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
          open={open}
        >
          <form>
            {/*CUERPO DEL DIALOGO*/}
            {/*TITILO*/}
            <BootstrapDialogTitle
              id="dialog"
              onClose={handleClose}
              style={{ color: "#761f2a" }}
            >
              <h1-dialog-title>{props.title}</h1-dialog-title>
            </BootstrapDialogTitle>
            {/*CUERPO DEL DIALOGO*/}
            {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
            <DialogContent dividers style={{ minWidth: 500 }}>
              {" "}
              {/* <--------------------------------  */}
              {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
               se necesita colocar entre un typogreaphy las veces que des salto de linea 
               o con un br*/}
              <Typography component={"div"}>
                <div>
                  <h2-dialog-description>
                    {props.description}
                  </h2-dialog-description>
                  <h2-dialog-description>
                    <p>
                      <label>
                        {
                          <FormattedMessage
                            id="globNoFoli"
                            defaultMessage="Número de folio"
                          />
                        }
                        : {arrayInfoData?.num_folio}
                      </label>
                    </p>
                  </h2-dialog-description>
                  <h2-dialog-description>
                    <p>
                      <label>
                        {
                          <FormattedMessage
                            id="msgInvFeDe"
                            defaultMessage="Fecha de descarga"
                          />
                        }
                        : {formatDate}
                      </label>
                    </p>
                  </h2-dialog-description>
                  <h2-dialog-description>
                    <p style={{ textAlign: "center" }}>
                      <label>{validateData}</label>
                    </p>
                  </h2-dialog-description>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      {showFormPK ? (
                        <BTUpload
                          accept=".txt"
                          onChange={(e) => handleChangePrivKey(e)}
                        />
                      ) : (
                        <TXTField
                          onChange={(e) => setRfc(e.target.value)}
                          onKeyPress={handleKeyStop}
                          onPaste={(e) => e.preventDefault()}
                        />
                      )}
                    </Grid>
                  </Grid>
                  {/*RFCErr ? null: <p style={{textAlign: "center"}}><label>Error, verifica R.F.C.</label></p>*/}
                </div>
              </Typography>
            </DialogContent>
            {/*es la tercer sección que engloba los botones */}
            <DialogActions>
              {showFormPK ? (
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item xs={"auto"}>
                    <ButtonVino
                      label={props.firstButton}
                      onClick={handleChangedForm}
                      estatus={disableFirtsButton}
                    />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <ButtonVino
                      label={props.secondButton}
                      onClick={handleClose}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item xs={"auto"}>
                    <ButtonVino
                      label={props.firstButton}
                      onClick={() => validateRFC(rfc)}
                      estatus={disableFirtsButton}
                    />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <ButtonVino
                      label={props.secondButton}
                      onClick={handleClose}
                    />
                  </Grid>
                </Grid>
              )}
            </DialogActions>
          </form>
        </BootstrapDialog>

        {/* CHILDREN DIALOG */}
        <div>
          {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
          <BootstrapDialog
            onClose={(_, reason) => {
              if (reason !== "backdropClick") {
                handleClose();
              }
            }}
            aria-labelledby="dialog"
            open={open2}
          >
            {/*CUERPO DEL DIALOGO*/}
            {/*TITULO*/}
            <BootstrapDialogTitle
              id="dialog"
              onClose={handleClose2}
              style={{ color: "#761f2a" }}
            >
              <h1-dialog-title>¡Atención!</h1-dialog-title>
            </BootstrapDialogTitle>

            {/*CUERPO DEL DIALOGO*/}
            {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
            <DialogContent dividers style={{ minWidth: 500 }}>
              {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
                   se necesita colocar entre un typogreaphy las veces que des salto de linea 
                   o con un br*/}
              <h2-dialog-description>
                Una vez descargada la información no se podrá volver a descargar
              </h2-dialog-description>
            </DialogContent>
            {/*es la tercer sección que engloba los botones */}
            <DialogActions>
              <Grid item xs={"auto"}>
                <a href={props.href} onClick={props.onClick1}>
                  <ButtonVino
                    onClick={() => descargarArchivos()}
                    label={btnDescarga.label}
                    estatus={btnDescarga.estatus}
                    onClose={btnDescarga}
                  />
                </a>
              </Grid>
            </DialogActions>
          </BootstrapDialog>
        </div>
      </div>
    </>
  );
}
