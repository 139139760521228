export function LimitarTextoSinCondiciones(input, limite) {
  if (input.value.length > limite) {
    // Si el valor excede el límite, se trunca
    input.value = input.value.substring(0, limite);
  }
}

export function LimitarTextoCodigo(input, limite) {
  const inputValue = input.value;
  const alphanumericValue = inputValue.replace(/[^a-zA-Z0-9-_]/g, ""); // Elimina caracteres no alfanuméricos
  const truncatedValue = alphanumericValue.substring(0, limite); // Trunca el valor al límite especificado
  input.value = truncatedValue; // Actualiza el valor del input
}
