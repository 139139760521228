import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../../Tools/Table/StyledTableCell";
import { FormattedMessage } from "react-intl";

export default function TablaCobertura(props) {
  const datos = props.data;
  console.log(datos);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const filteredData = datos.slice(startIndex, endIndex);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }} style={{ position: "sticky" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Cobertura</StyledTableCell>
                <StyledTableCell align="center">Suma asegurada</StyledTableCell>
                <StyledTableCell align="center">Moneda</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row) => (
                <TableRow key={datos?.POL_idpoliza?.POL_idpoliza}>
                  <StyledTableCell align="center">
                    {row?.COB_idcobertura?.COB_cobertura}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.PCP_coberturasumaasegurada?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.MON_idmonedacobertura?.MON_abreviatura}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          component="div"
          count={datos.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={
            <FormattedMessage
              id="globRegPa"
              defaultMessage="Registros por página:"
            />
          }
        />
      </Paper>
      <br />
    </div>
  );
}
