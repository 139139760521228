import { Comunicacion } from "../../pages/Client/ModComunicacion/Comunicacion";
import { Info } from "../../pages/Client/ModComunicacion/Info";
import { ClientLayaout } from '../../layouts/Client/ClientLayaout';
import { consul } from "../../pages/Client/ModComunicacion/Consul";
import { comunicacioncon } from "../../pages/Client/ModComunicacion/ComunicacionConsu";

const routesClient = [

    {
        path: "/comunicacion",
        layout: ClientLayaout,
        component: Comunicacion,
        exact: true,
    },
    {
        path: "/comunicacion/informacion",
        layout: ClientLayaout,
        component: Info,
        exact: true,
    },
    {
        path: "/comunicacion/consultas",
        layout: ClientLayaout,
        component: consul,
        exact: true,
    },

    {
        path: "/comunicacionconsul",
        layout: ClientLayaout,
        component: comunicacioncon,
        exact: true,
    },



];

export default routesClient;