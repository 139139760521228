import React from "react";
import CompGenerarCarta from "../../../components/Dictamen/CompGenerarCarta";



export function DictamenGenCarta() {
    return (
        <div>
           
            <CompGenerarCarta />
        
        </div>
    );
}