/* Brandon Hernandez Rocha 11/2022 */
import React, { useEffect } from "react";
import CustomSeparatorDinamic from "../Tools/BreadCrumbs/RecorridoDinamico";
import "../../css/estilo.css";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CardDetalleFolio } from "./Elements/Cards/CardDetalleFolio";
import DatePicker2 from "../Tools/DatePicker/DatePicker2";
import TXTField from "../Tools/TextField/TextField";
import ButtonVino from "../Tools/Bottones/ButtonVino";

//idioma
import { FormattedMessage } from "react-intl";
import TableFiniquito from "./Elements/Tables/TablaFiniquito";
import { PERR_ASEGURADO, PERR_BENEFICIARIO } from "../../utils/constanstoken";
import { getDataToken } from "../../api/dataToken";
import RadioDinamico from "../Tools/RadioButton/RadioDinamico";

const CompRealizarPagos = () => {
    const location = useLocation();
    const infoFolio = location.state.infoFolio;
    const idPoliza = location.state.idPoliza;
    const row = location.state.row;
    const arrayBreadcrumbs = [
        {
            key: 1,
            name: <FormattedMessage id="globInicio" defaultMessage="Inicio" />,
            to: "/menu",
        },
        {
            key: 2,
            name: <FormattedMessage id="menuDictam" defaultMessage="Dictamen" />,
            to: "/dictamen",
        },
        {
            key: 3,
            name: (
                <FormattedMessage id="dictaDetalleF" defaultMessage="Detalle folio" />
            ),
            to: "/dictamen/detalle_folio",
            state: { row: infoFolio },
        },
    ];
    /* CONSTANTES PARA CAMBIAR TABLA ASEGURADO / BENEFICIARIOS */
    const [dataApiPolxFolioxSin, setDataApiPolxFolioxSin] = React.useState("")
    const [coberPagadas, setCoberPagadas] = React.useState([])
    const [coberPagadasFiltro, setCoberPagadasFiltro] = React.useState([])


    /* CONSTANTES PARA CAMBIAR TABLA ASEGURADO / BENEFICIARIOS */
    const [listRB, setListRB] = React.useState([
        { id: PERR_ASEGURADO.id, value: PERR_ASEGURADO.id, label: PERR_ASEGURADO.descripcion },
        { id: PERR_BENEFICIARIO.id, value: PERR_BENEFICIARIO.id, label: PERR_BENEFICIARIO.descripcion }
    ])
    const [showTabla, setShowTabla] = React.useState(0)
    const handleRb = (value) => {
        let valor = value.target.value
        let coberturas = [...coberPagadas];
        let coberPagadasFiltro = []
        setShowTabla(parseInt(valor));
        if (valor == PERR_BENEFICIARIO.id) {
            coberPagadasFiltro = coberturas.filter(obj => obj.PER_idpersona.PERR_idrol.PERR_idrol == PERR_BENEFICIARIO.id)
        } else if (valor == PERR_ASEGURADO.id) {
            coberPagadasFiltro = coberturas.filter(obj => obj.PER_idpersona.PERR_idrol.PERR_idrol == PERR_ASEGURADO.id)
        }
        
        console.log("coberuras",coberturas)
        console.log("coberPagFiltro",coberPagadasFiltro)
        setCoberPagadasFiltro(coberPagadasFiltro)

    }

    const getDataApiPolizaxFolioxSin = async () => {
        try {
            let result = await getDataToken("polizasfolioxsiniestro/get_idpoliza/" + idPoliza + "/");
            // let result = await getDataToken("pagos/get_idfolioxpxs/" + idPoliza + "/");
            if (result) {
                setDataApiPolxFolioxSin(result[0]);
                console.log(result);
                let response2 = await getDataToken("pagos/get_idfolioxpxs/" + result[0]?.PXFS_idfoliopolizaxsiniestro + "/")
                if (response2) {
                    console.log(response2);
                    setCoberPagadas(response2);
                }
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
    useEffect(() => {
        getDataApiPolizaxFolioxSin();
    }, [])
    return (
        <div className="div-parent">
            <div className="div-breadcrumbs">
                <CustomSeparatorDinamic
                    breadcrumbs={arrayBreadcrumbs}
                    name={`Finiquito`}
                />
            </div>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
            >
                <Link
                    style={{ textDecoration: "none" }}
                    to="/dictamen/detalle_folio"
                    state={{ row: infoFolio }}
                >
                    <ReturnPage />
                </Link>
            </Grid>
            <div className="div-container">
                {dataApiPolxFolioxSin === "" ? null :
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item >
                            <h3-subtitle-card>Generar finiquito a </h3-subtitle-card>
                        </Grid>
                        <Grid item >
                            <RadioDinamico array={listRB}
                                row
                                onChange={handleRb}
                            />
                        </Grid>
                        {showTabla === 0 ? null : <Grid item xs={12}><TableFiniquito tipoTabla={showTabla} coberPagadas={coberPagadasFiltro} dataApiPolxFolioxSin={dataApiPolxFolioxSin}/></Grid>}
                    </Grid>}

            </div>
        </div>
    );
};
export default CompRealizarPagos;
