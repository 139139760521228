import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { Grid } from "@mui/material";
import TXTField from "../../Tools/TextField/TextField";
//paginación de la tabla
import TablePagination from "@mui/material/TablePagination";
//estilos de la tabla
import StyledTableCell from "../../Tools/Table/StyledTableCell";
import "../Contable.css"
import DialogPdNacional from "../Dialogs/DialogPdNacional";
import DialogMenuPd from "../Dialogs/DialogMenuPd";
//Idioma
import { FormattedMessage } from "react-intl";
import ButtonVino from "../../Tools/Bottones/ButtonVino";

export default function TableNationalCurrency() {

 /* Función para validar datos tabla,  */
 function createData(codigo, moneda, fecha, cuenta, póliza, asegrado, importe, naturaleza) {    
    return { codigo, moneda, fecha, cuenta, póliza, asegrado, importe, naturaleza };
  }
  const rows = [
    createData("3Y", "01", "03/05/2021", "6401 01011208 000", "N2Y20D6746", "Javier Gomez", "100,000", "01"),
    createData("3Y", "01", "03/05/2021", "6401 01011208 000", "N2Y20D6746", "Javier Gomez", "40,000", "02"),
    createData("3Y", "01", "03/05/2021", "6401 01011208 000", "N2Y20D6746", "Javier Gomez", "40,000", "01"),
    createData("3Y", "01", "03/05/2021", "6401 01011208 000", "N2Y20D6746", "Javier Gomez", "100,000", "02"),
  ];

  /* CONSTANTES DE ESTADOS*/
  const [data, setData] = useState(rows); // esta constante guarda un arreglo en donde se almacenará los datos de la API
  
  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };   
  
  // Variables para acumular la suma de los importes
  let sumaNaturaleza01 = 0;
  let sumaNaturaleza02 = 0;

  // Recorrer el array de rows y acumular la suma de los importes según la naturaleza
  rows.forEach((row) => {
    if (row.naturaleza === "01") {
      sumaNaturaleza01 += parseFloat(row.importe.replace(",", ""));
    } else if (row.naturaleza === "02") {
      sumaNaturaleza02 += parseFloat(row.importe.replace(",", ""));
    }
  });

  //Status de Botones
  const [extraccion,setExtraccion] = useState(false)
  const [enviar,setEnviar] = useState(false)
  //constante para el folio de la póliza
  const [poliza, setPoliza] = useState(false)
//Metodo para el boton de extraccion
  const handleClickExtraccion = () => {
    setExtraccion(false)
    setEnviar(true)
    setPoliza(true)
  }
//Metodo para el boton de enviar
  const handleClickEnviar = () => {
    setExtraccion(false)
    setEnviar(false)
  }

  return (
    <div>
      <Grid container justifyContent="center">
      <Grid item xs={12}>
          <h3-header-card>
            <FormattedMessage id="estconCrNa" defaultMessage="Creación de PD moneda nacional" />
          </h3-header-card>
        </Grid>
        <Grid item xs={12}>
          {/* <DialogMenuPd/> */}
        </Grid>
        
        {!poliza? null:
        <Grid item xs={12}>
          <br/>
          No. SY102532
        </Grid>
        }
      </Grid>
      <br/>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center"><FormattedMessage id="estconCodi" defaultMessage="Código" /></StyledTableCell>
                <StyledTableCell align="center"><FormattedMessage id="globMoneda" defaultMessage="Moneda" /></StyledTableCell>
                <StyledTableCell align="center"><FormattedMessage id="globFecha" defaultMessage="Fecha" /></StyledTableCell>
                <StyledTableCell align="center"><FormattedMessage id="loginCuent" defaultMessage="Cuenta" /></StyledTableCell>
                <StyledTableCell align="center"><FormattedMessage id="globPoliza" defaultMessage="Póliza" /></StyledTableCell>
                <StyledTableCell align="center"><FormattedMessage id="globAsegud" defaultMessage="Asegurado" /></StyledTableCell>
                <StyledTableCell align="center"><FormattedMessage id="globImport" defaultMessage="Importe" /></StyledTableCell>
                <StyledTableCell align="center"><FormattedMessage id="estconNatu" defaultMessage="Naturaleza" /> </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {data                
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.codigo}>
                    <StyledTableCell align="center"> {row.codigo} </StyledTableCell>
                    <StyledTableCell align="center"> {row.moneda} </StyledTableCell>
                    <StyledTableCell align="center"> {row.fecha} </StyledTableCell>
                    <StyledTableCell align="center"> {row.cuenta} </StyledTableCell>
                    <StyledTableCell align="center"> {row.póliza} </StyledTableCell>
                    <StyledTableCell align="center"> {row.asegrado} </StyledTableCell>
                    <StyledTableCell align="center"> {row.importe} </StyledTableCell>
                    <StyledTableCell align="center"> {row.naturaleza} </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelRowsPerPage="Filas por página"
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <br />

      <Grid 
       container
       direction="row"
       justifyContent="flex-end"
       alignItems="flex-start"
       spacing={1}>

        <Grid item>
        <TXTField label={<FormattedMessage id="estcToCa" defaultMessage="Total de cargos" />} 
        value={sumaNaturaleza01.toLocaleString()} // Mostrar el total de cargos 
        />
        </Grid>

        <Grid item>
        <TXTField label={<FormattedMessage id="globconTotaDCreditos" defaultMessage="Total de créditos" />} 
        value={sumaNaturaleza02.toLocaleString()} // Mostrar el total de créditos
        />
        </Grid>
        </Grid>
        
        <br/>
  
        <Grid 
       container
       direction="row"
       justifyContent="flex-end"
       alignItems="flex-start"
       spacing={1}>
        <Grid item>
        {/* <DialogPdNacional
           mainButton="Extracción"
           estatus={extraccion}
           title="Extracción"
           description="Póliza de diario MN SY102532 creada correctamente"           
           firstButton={<FormattedMessage id="globAceptr" defaultMessage="Aceptar" />}
           onClick1={handleClickExtraccion}
          secondButton="Cancelar"/> */}
          <ButtonVino
           label="Extraccion"
           onClick={handleClickExtraccion}/>
          <br />
        </Grid>
        <Grid item>
          {/* <DialogPdNacional
          mainButton={<FormattedMessage id="globEnviar" defaultMessage="Enviar" />}
          estatus={enviar}
          title={<FormattedMessage id="globEnviar" defaultMessage="Enviar" />}
          description="Póliza de diario MN SY102532 enviada exitosamente"          
          firstButton={<FormattedMessage id="globAceptr" defaultMessage="Aceptar" />}
          onClick1={handleClickEnviar}
          secondButton="Cancelar"/> */}
          <ButtonVino
           label="Enviar"
           onClick={handleClickEnviar}/>
        {/* <DialogPdNacional
           
           />  */}
        </Grid>
      </Grid>
    </div>
  )
}
