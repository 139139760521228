import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
//Idioma
import { FormattedMessage } from "react-intl";
import { getDataToken } from "../../../../api/dataToken";
import { formatofecha } from "../../../../api/getUmbrales";
import moment from "moment";

export default function Header(props) {
  //Constantes para poder pasar la información
  const infoDataRow = props.infoRow; //Por esta variable entran los valores que se estan enviando
  console.log("Estoy pasando el valor header", infoDataRow);
  //Creación de las apis
  //Creación de apis para el encabezado

  const [IDFolio, setIDFolio] = React.useState(
    infoDataRow?.RECT_idfolio?.RECT_idfolio
  );
  const [data, setdata] = useState("");

  const getCPXT = async () => {
    try {
      var result = await getDataToken(
        `polizascoberturasporproductos/get_folio/${IDFolio}/`
      );
      console.log("Resultado de polizascoberturasporproductos =>", result);
      setdata(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getCPXT();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container>
        <Grid xs={12}>
          <h3-header-card>
            <FormattedMessage
              id="datosDaAse"
              defaultMessage="Datos del asegurado"
            />
          </h3-header-card>
        </Grid>
        <Grid xs={12}></Grid>
        <br />
        <br />
        <Grid xs={2.4}>
          <h3-title-card>
            <FormattedMessage id="datosNombs" defaultMessage="Nombre(s)" />
          </h3-title-card>
          <br />
          {/*Se colocaran los id a cada etiqueta para que esta nos sirva para colocar la infromación que 
          nos regresará la api*/}
          <h3-subtitle-card>
            {infoDataRow?.PER_idpersona?.PER_nombre.toUpperCase()}
          </h3-subtitle-card>
        </Grid>
        <Grid xs={2.4}>
          <h3-title-card>
            <FormattedMessage
              id="datosApPat"
              defaultMessage="Apellido Paterno"
            />
          </h3-title-card>
          <br />
          <h3-subtitle-card>
            {infoDataRow?.PER_idpersona?.PER_apellidopaterno.toUpperCase()}
          </h3-subtitle-card>
        </Grid>
        <Grid xs={2.4}>
          <h3-title-card>
            <FormattedMessage
              id="datosApMat"
              defaultMessage="Apellido Materno"
            />
          </h3-title-card>
          <br />
          <h3-subtitle-card>
            {infoDataRow?.PER_idpersona?.PER_apellidomaterno.toUpperCase()}
          </h3-subtitle-card>
        </Grid>
        <Grid xs={2.4}>
          <h3-title-card>
            <FormattedMessage
              id="globFechNa"
              defaultMessage="Fecha de nacimiento"
            />
          </h3-title-card>
          <br />
          <h3-subtitle-card>
            {moment(infoDataRow?.PER_idpersona?.PER_fechanacimiento).format(
              formatofecha
            )}
          </h3-subtitle-card>
        </Grid>

        <Grid xs={12}></Grid>
        <br />
        <br />

        <Grid xs={2.4}>
          <h3-title-card>
            <FormattedMessage id="globRfcp" defaultMessage="RFC" />
          </h3-title-card>
          <br />
          <h3-subtitle-card>
            {infoDataRow?.PER_idpersona?.PER_RFC}
          </h3-subtitle-card>
        </Grid>
        <Grid xs={2.4}>
          <h3-title-card>
            <FormattedMessage id="globCurp" defaultMessage="CURP" />
          </h3-title-card>
          <br />
          <h3-subtitle-card>
            {infoDataRow?.PER_idpersona?.PER_CURP}
          </h3-subtitle-card>
        </Grid>
        <Grid xs={2.4}>
          <h3-title-card>
            <FormattedMessage
              id="regisFeRec"
              defaultMessage="Fecha de recepción"
            />
          </h3-title-card>
          <br />
          <h3-subtitle-card>
            {moment(infoDataRow?.RECT_idfolio?.RECT_fecharecepcion).format(
              formatofecha
            )}
          </h3-subtitle-card>
        </Grid>

        <Grid xs={2.4}>
          <h3-title-card>
            <FormattedMessage
              id="errorTiRec"
              defaultMessage="Tipo de reclamación"
            />
          </h3-title-card>
          <br />
          <h3-subtitle-card>
            {
              infoDataRow?.RECT_idfolio?.SINC_idcomplejidadsiniestro
                ?.TRA_destipotramitesiniestro
            }
          </h3-subtitle-card>
        </Grid>

        <Grid xs={2.4}>
          <h3-title-card>
            <FormattedMessage id="globCober" defaultMessage="Cobertura" />
          </h3-title-card>
          <br />
          <h3-subtitle-card>
            {data ? data[0].COB_idcobertura?.COB_cobertura : ""}
          </h3-subtitle-card>
        </Grid>
      </Grid>
    </Box>
  );
}
