/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import Paper from "@mui/material/Paper";


import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TXTField from "../../../Tools/TextField/TextField";
//idioma
import { FormattedMessage } from "react-intl";
import { getDataToken, putDataToken } from "../../../../api/dataToken";
import { formatNumberDecimalComa } from "../../../../utils/functions";
import { ESTATUS_GCRP, ESTATUS_GESTION_COMP } from "../../../../utils/constanstoken";
import { useEffect } from "react";
import DialogConfirm from "../../../Tools/Dialog/DialogConfirm";
import DialogFeedback from "../../../Tools/Dialog/DialogFeedback";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import DialogGeneral from "../../../Tools/Dialog/DialogGeneral";


export default function TablaCharolaPendientes() {


    //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
    const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
    const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10
    /****** Código Usuario que actualizó *******/
    const [user, setUser] = useState("");
    const today = new Date();

    //Estado para mostrar diálogos de confirmación 
    const initialDialogResponse = {
        title: '',
        description: '',
        button: '',
        firstButton: '',
        secondButton: '',
    }
    const [openDialogFeedb, setOpenDialogFeedb] = useState(false);
    const [dialogType, setDialogType] = useState(false);
    const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
    const [dialogResponse, setDialogResponse] = useState(initialDialogResponse);
    const [dataPut, setDataPut] = useState({
        "data": {},
        "tipoMov": {},
    });
    const [openDialog, setOpenDialog] = useState(false);
    //
    const [dataEstatusGesion, setDataEstatusGestion] = useState([]);
    //GET A API 
    const getDataUser = async () => {
        //Resultados de la api documentacion documentosxtiposiniestrostramite
        let result = await getDataToken("login/get_correo/" + localStorage.getItem("SNYTLOG_email") + "/");
        let result2 = await getDataToken("estatusgestion/get_listado/");
        setUser(`${result.SNYTLOG_username} ${result.SNYTLOG_surnamedad} ${result.SNYTLOG_surnamemom}`);
        if (result2) {
            console.log("Estatus", result2);
            let filterData = result2.filter(obj => {
                if (obj.ESTG_idestatusgestion != ESTATUS_GESTION_COMP.FINALIZADO_ID && obj.ESTG_idestatusgestion != ESTATUS_GESTION_COMP.PENDIENTE_ID) {
                    obj.identificador = obj.ESTG_idestatusgestion;
                    obj.texto = obj.ESTG_descripcion;
                    return obj;
                }
            })
            console.log("filter", filterData)
            setDataEstatusGestion(filterData)
        }
        console.log("user: ", result);
        getDataGestion();

    };
    const getDataGestion = async () => {
        //Resultados de la api documentacion documentosxtiposiniestrostramite
        let result = await getDataToken("gestiondecompetencias/get_listado/");
        //Filtrar ppor id trabajador
        // setData(result.filter(obj => obj.IDTRABAJADOR.id === user.id));

        //Ver coberturas 

        let coberturas = await getDataToken("coberturas/get_listado/");
        console.log("coberturas: ", coberturas);
        let newArr = result.map(obj => {
            let cober = coberturas.find(cob => cob.COB_idcobertura == obj.RPC_idreservapolizaporcobertura.CPT_idcoberturapolizatramite.COB_idcobertura)
            if (cober) obj.COB_cobertura = cober || null
            return obj
        })
        let filter = newArr.filter(obj => obj.ESTG_idestatusgestion.ESTG_idestatusgestion != ESTATUS_GESTION_COMP.FINALIZADO_ID)
        setData(filter);
        console.log("result: ", filter);


    };
    useEffect(() => {
        getDataUser();
    }, []);
    //Constantes data 
    const [data, setData] = useState([]);



    const handlerActionButton = async (row, tipoMov) => {
        console.log("row", row);
        setDialogResponse({ ...dialogResponse, description: `Está apunto de ${tipoMov.GC_movreserva ? "reservar" : "pagar"} sobre la cobertura "${row.COB_cobertura.COB_cobertura}" con el folio ${row.PXFS_idfoliopolizaxsiniestro.RECT_idfolio.RECT_numerofolio}. ¿Desea continuar?`, firstButton: 'Aceptar', secondButton: "Cancelar" });
        setDataPut({ ...dataPut, data: row, tipoMov: tipoMov });

        setOpenDialogConfirm(true);
    }
    const handlerUpdate = async () => {

        setOpenDialogConfirm(false);
        const jsonGestion = {
            ESTG_idestatusgestion: ESTATUS_GESTION_COMP.FINALIZADO_ID,
            GC_actualizousuario: user,
        }
        try{
            console.log(dataPut);
            //Actualizar gestion y reserva 
        if (dataPut.tipoMov.GC_movreserva) {
            console.log("Actualizar reserva");
            //PUT RESERVA 
            //OBTENER EL IMPORTE ANTERIO PARA SUMARLO AL IMPORTE NUEVO
            let reserva = await getDataToken("reservasporpolizaporcoberturas/get_id/" + dataPut.data.PXFS_idfoliopolizaxsiniestro.PXFS_idfoliopolizaxsiniestro + "/");
            let importeReserva = 0
            if(reserva && !isNaN(parseFloat(reserva.RPC_importeReserva))){
                importeReserva = parseFloat(reserva.RPC_importeReserva)
            }
            //Aqui el codigo put para modificar la reserva
            let jsonReservasPolCober = {
                MON_idmoneda: dataPut.data.MON_idmoneda.MON_idmoneda,
                RPC_balance: importeReserva + parseFloat(dataPut.data.GC_importereservado),
                RPC_importeReserva: parseFloat(dataPut.data.GC_importereservado),
                RPC_actualizofecha: today,
                RPC_actualizousuario: user,

            }
            console.log(jsonReservasPolCober);

            let resp = await putDataToken("reservasporpolizaporcoberturas/get_id/" + dataPut.data.PXFS_idfoliopolizaxsiniestro.PXFS_idfoliopolizaxsiniestro + "/", jsonReservasPolCober);
            if (!resp) {
                setDialogResponse({ ...resp, description: "" });
                setOpenDialog(true);
                setDialogType(false);
                return false;
            }
            console.log(resp);

        } else if (dataPut.tipoMov.GC_movpago) {
            console.log("Actualizar pago");
        }
        //PUT GESTION
        let resp = await putDataToken(`gestiondecompetencias/get_id/${dataPut.data.GC_idgestiondecompentencia}/`, jsonGestion);
        if (resp) {
            setDialogResponse({ ...resp, description: "" });
            // setOpenDialogFeedb(true);
            setOpenDialog(true);
            setDialogType(true);
            getDataGestion();
            console.log(resp);

        } else {
            setDialogResponse({ ...resp, description: "" });
            // openDialogFeedb(true);
            setOpenDialog(true);
            setDialogType(false);
            console.log(resp);

        }

        }catch(e){
            console.log(e);
        }



        
        
    }



    return (
        <div className='div-parent'>
            <div className='div-container'>
                <Paper style={{ width: "100%" }}>
                    <TableContainer
                        sx={{ maxHeight: 440, showEmptyDataSourceMessage: true }}
                        style={{ position: "sticky" }}
                    >
                        <Table
                            stickyHeader
                            aria-label="sticky table"
                            sx={{
                                "& .MuiTableRow-root:hover": {
                                    backgroundColor: "#ededed",
                                },
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                ascendente o descendente, agregando un icono para su efecto visual */}
                                    <StyledTableCell>
                                        <Grid container alignItems="center">
                                            <Grid item xs={9}>
                                                <FormattedMessage
                                                    id="globNoDeFo"
                                                    defaultMessage="No. de folio"
                                                />
                                            </Grid>
                                        </Grid>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Grid container alignItems="center">
                                            <Grid item xs={9}>
                                                <FormattedMessage
                                                    id="globPol"
                                                    defaultMessage="Póliza"
                                                />
                                            </Grid>
                                        </Grid>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Grid container alignItems="center">
                                            <Grid item xs={10}>
                                                <FormattedMessage
                                                    id="globCob"
                                                    defaultMessage="Cobertura"
                                                />
                                            </Grid>

                                        </Grid>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Grid container alignItems="center">
                                            <Grid item xs={10}>
                                                <FormattedMessage
                                                    id="globMoneCob"
                                                    defaultMessage="Moneda cobertura"
                                                />
                                            </Grid>

                                        </Grid>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <FormattedMessage id="globSumaAsegurada" defaultMessage="Suma asegurada" />
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <FormattedMessage id="dictamReserPago" defaultMessage="Reserva / Pago" />
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <FormattedMessage id="globImporte" defaultMessage="Importe" />
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <FormattedMessage
                                            id="globMoneda"
                                            defaultMessage="Moneda"
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell > <FormattedMessage
                                        id="globAutopor"
                                        defaultMessage="Atendió"
                                    /></StyledTableCell>
                                    <StyledTableCell > <FormattedMessage
                                        id="globEstatus"
                                        defaultMessage="Estatus"
                                    /></StyledTableCell>
                                    <StyledTableCell > <FormattedMessage
                                        id="globAcciones"
                                        defaultMessage="Acciones"
                                    /></StyledTableCell>



                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
            retornará el valor original del arreglo, en caso contrario retornará todos los valores 
            que coincidan con las letras ingresadas, */}
                                {data
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        //Tipo de movimiento 
                                        let tipoMov = ESTATUS_GCRP.find(obj => obj.GC_movreserva.value === row.GC_movreserva || obj.GC_movreserva.value === row.GC_movreserva)

                                        let atendio = `${row.IDTRABAJADOR.SNYTLOG_username} ${row.IDTRABAJADOR.SNYTLOG_surnamedad} ${row.IDTRABAJADOR.SNYTLOG_surnamemom}`
                                        return (
                                            <TableRow key={row.GC_idgestiondecompentencia}>
                                                <StyledTableCell align="center">{row?.PXFS_idfoliopolizaxsiniestro.RECT_idfolio.RECT_numerofolio}</StyledTableCell>
                                                <StyledTableCell align="center">{row?.PXFS_idfoliopolizaxsiniestro.POL_idpoliza.POL_numpoliza}</StyledTableCell>
                                                <StyledTableCell align="center">{row?.COB_cobertura.COB_cobertura}</StyledTableCell>
                                                <StyledTableCell align="center">{row?.RPC_idreservapolizaporcobertura.MON_idmoneda.MON_abreviatura}</StyledTableCell>
                                                <StyledTableCell align="center">{formatNumberDecimalComa(row?.GC_sumaasegurada)}</StyledTableCell>
                                                <StyledTableCell align="center">{tipoMov.GC_movreserva ? tipoMov.GC_movreserva.descripcion : tipoMov.GC_movpago.descripcion}</StyledTableCell>
                                                <StyledTableCell align="center">{formatNumberDecimalComa(row?.GC_importereservado)}</StyledTableCell>
                                                <StyledTableCell align="center">{row?.MON_idmoneda.MON_abreviatura}</StyledTableCell>
                                                <StyledTableCell align="center">{atendio}</StyledTableCell>
                                                <StyledTableCell align="center">{row?.ESTG_idestatusgestion.ESTG_descripcion}</StyledTableCell>
                                                <StyledTableCell align="center">{row?.ESTG_idestatusgestion.ESTG_idestatusgestion != ESTATUS_GESTION_COMP.APROBADO_ID && row?.ESTG_idestatusgestion.ESTG_idestatusgestion != ESTATUS_GESTION_COMP.DENEGADO_ID ? null : <ButtonVino label={tipoMov.GC_movreserva ? "Reservar" : "Pagar"} onClick={() => handlerActionButton(row, tipoMov)} />}</StyledTableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                {!openDialog ? null : <DialogGeneral open={openDialog} type={dialogType} title={dialogResponse.title} description={dialogResponse.description} onClickAccept={() => setOpenDialog(false)} />}
                {!openDialogFeedb ? null : <DialogFeedback open={openDialog} title={dialogResponse.title} description={dialogResponse.description} clickButton={() => setOpenDialog(false)} button={dialogResponse.button} />}
                {!openDialogConfirm ? null : <DialogConfirm open={openDialogConfirm} description={dialogResponse.description} firstButton={dialogResponse.firstButton} secondButton={dialogResponse.secondButton} onClickAccept={() => setOpenDialogConfirm(false)} onClickAccept2={() => {
                    console.log("Diste click en aceptar"); // cambiar el estatus GC_movreserva  GC_movpago a false, al igual que ESTG_idestatusgestion a FINALIZDO , TAMBIEN REALIZAR EL POST O PUT DE LA RESERV o pago
                    handlerUpdate();
                }} />}
            </div>
        </div>
    )
}
