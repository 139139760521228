import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import IconButton from "@mui/material/IconButton";
import { Grid } from "@mui/material";
import RadioInput from "../../Tools/RadioButton/RadioInput";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import TXTField from "../../Tools/TextField/TextField";
import { Link } from "react-router-dom";
//Idioma
import { FormattedMessage } from "react-intl";
//estilos de la tabla
import StyledTableCell from "../../Tools/Table/StyledTableCell";
//importamos la libreria para el get
import { getDataToken } from "../../../api/dataToken";
import moment from "moment";
import getDate from "../Function/obtenerFecha";
import { ASEG } from "../../../utils/constanstoken";
import { umbralCancelado } from "../../../api/getUmbrales";

export default function TableRecord() {
  //Creción de constantes  para poder usar las apis
  const [registro, setRegistros] = useState([]); //Guarda toda la información que trae la api

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");

  //Método get para traer los datos de la charola registro
  const getCharola = async () => {
    try {
      var result = await getDataToken("charolaregistro/get_listado/");
      console.log("Resultado de la charola registro =>", result);
      setRegistros(
        result.filter(
          (charola) =>
            charola.PER_idpersona?.PERR_idrol?.PERR_rolpersona.toLowerCase() ===
            ASEG
        )
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getCharola();
  }, []);

  /* CONSTANTES DE ESTADOS*/
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("ASC");
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla
  const [InfoDataRow, setInfoDataRow] = useState(""); //Aqui es donde almacenará los datos de la fila seleccionadad
  const [btnDisable, setBtnDisable] = useState(true); //Boton de inicio registro el cual podra ser habilitado o deshabilitado
  const [IDCharola, setIDCharola] = useState(""); //se almacenaran nuestros datos para poder enviarlos
  const [habilitaLink, setHabilitaLink] = useState(false); //activacion y desactivacion del link

  /* FUNCIÓN PARA ORDENAR LA TABLA ASC/DSC (Ascendent/ Descendant) */
  const sorting = (col) => {
    if (sortOrder === "ASC") {
      const sorted = [...registro].sort((a, b) =>
        a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1
      );
      setSortColumn(col);
      setSortOrder("DSC");
      setRegistros(sorted);
    } else if (sortOrder === "DSC") {
      const sorted = [...registro].sort((a, b) =>
        a[col]?.toLowerCase() > b[col]?.toLowerCase() ? -1 : 1
      );
      setSortColumn(col);
      setSortOrder("ASC");
      setRegistros(sorted);
    }
  };

  /* FUNCIÓN CLICK EN LOS RADIO BUTTON, ARROJA INFORMACION DE LA FILA SELECCIONADA */
  const rbClick = (e) => {
    const inputFolio = parseInt(e.target.value);
    registro.map((row) => {
      if (row.RECT_idfolio?.RECT_idfolio == inputFolio) {
        console.log(row);
        setInfoDataRow(row);
        setBtnDisable(false);
        setIDCharola(row.CHREG_idcharolareg);
        setHabilitaLink(true);
      }
    });
  };
  console.log("primer tabla que va a pasar ", IDCharola);

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const filteredData = registro.slice(startIndex, endIndex);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Grid
        style={{ paddingBottom: "20px" }}
        container
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={4}>
          <h4-search>
            <FormattedMessage id="globBuscar" defaultMessage="Buscar" />:
          </h4-search>
          <TXTField
            showSearchIcon={true}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center"></StyledTableCell>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                <StyledTableCell
                  align="center"
                  onClick={() =>
                    sorting("registro.RECT_idfolio.RECT_numerofolio")
                  }
                >
                  <FormattedMessage
                    id="globNoDeFo"
                    defaultMessage="No. de folio"
                  />
                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    <UnfoldMoreIcon />
                  </IconButton>
                </StyledTableCell>

                <StyledTableCell align="center">
                  <FormattedMessage
                    id="globTiemTrans"
                    defaultMessage="Tiempo transcurrido"
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="globEstatu" defaultMessage="Estatus" />{" "}
                  trámite
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  onClick={() => sorting("registro.PER_idpersona.PER_nombre")}
                >
                  <FormattedMessage
                    id="recepNoAse"
                    defaultMessage="Nombre del asegurado / Razón social"
                  />
                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    <UnfoldMoreIcon />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                    val.RECT_idfolio?.RECT_numerofolio?.toLowerCase().includes(
                      searchTerm.toLowerCase()
                    ) ||
                    val.PER_idpersona?.PER_nombrerazonsocial?.toLowerCase().includes(
                      searchTerm.toLowerCase()
                    ) ||
                    val.PER_idpersona?.PER_nombre?.toLowerCase().includes(
                      searchTerm.toLowerCase()
                    ) ||
                    val.PER_idpersona?.PER_apellidopaterno?.toLowerCase().includes(
                      searchTerm.toLowerCase()
                    ) ||
                    val.PER_idpersona?.PER_apellidomaterno?.toLowerCase().includes(
                      searchTerm.toLowerCase()
                    ) ||
                    val.PER_idpersona?.PER_apellidomaterno?.toLowerCase().includes(
                      searchTerm.toLowerCase()
                    )
                  ) {
                    console.log(val);
                    return val;
                  }
                })
                .map((row) => (
                  <TableRow key={row.RECT_idfolio}>
                    <StyledTableCell component="th" scope="row" align="center">
                      <RadioInput
                        name="rb"
                        value={row.RECT_idfolio.RECT_idfolio}
                        onChange={rbClick}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {" "}
                      {row.RECT_idfolio?.RECT_numerofolio}{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "50px",
                        }}
                      >
                        <CircleRoundedIcon style={{ color: umbralCancelado }} />{" "}
                        <span style={{ marginLeft: "10px" }}>
                          {" "}
                          {moment(fechaEmpAn).diff(
                            moment(
                              row.RECT_idfolio?.RECT_fecharecepcion
                            ).format("YYYY-MM-DD"),
                            "days"
                          )}{" "}
                          días
                        </span>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {" "}
                      {
                        row.RECT_idfolio?.TEST_idestatustramite
                          ?.TEST_estatustramite
                      }{" "}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {/* Utilizamos el operador ternario para mostrar los datos si es razón social y si no existe un nombre*/}

                      {row.PER_idpersona?.PER_nombrerazonsocial === ""
                        ? row.PER_idpersona?.PER_nombre +
                          " " +
                          row.PER_idpersona?.PER_apellidopaterno +
                          " " +
                          row.PER_idpersona?.PER_apellidomaterno
                        : row.PER_idpersona?.PER_nombre +
                          " " +
                          row.PER_idpersona?.PER_apellidopaterno +
                          " " +
                          row.PER_idpersona?.PER_apellidomaterno +
                          " / " +
                          row.PER_idpersona?.PER_nombrerazonsocial}
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={registro.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={
            <FormattedMessage
              id="globRegPa"
              defaultMessage="Registros por página:"
            />
          }
        />
      </Paper>
      <br />
      <Grid container spacing={5}>
        <Grid item>
          {/* El uso de Link es para poder pasar valores a el link necesario por to
          El valor que estamos pasando es por medio de la varibale de Row */}
          <Link
            to="/registroasegurado"
            state={{ InfoDataRow }}
            style={{ textDecoration: "none" }}
            onClick={(e) => !habilitaLink && e.preventDefault()}
          >
            <ButtonVino
              variant="contained"
              color="primary"
              size="small"
              estatus={btnDisable}
              label={
                <FormattedMessage
                  id="submenuIni"
                  defaultMessage="Iniciar registro"
                />
              }
            />
          </Link>
          <br />
        </Grid>
        <br />
      </Grid>
      <br />
      <br />
    </div>
  );
}
