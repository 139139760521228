import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export function Select2(props) {
    // La propiedad props.array permitirá llenar el menu item segun su prop.id y prop.texto
    const array = props.array;

    const [selectedValue, setSelectedValue] = React.useState(props.value ? props.value : 0);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        props.onChange(event) //  servirá para revisar el cambio del valor del select desde el componente padre
    };
    React.useEffect(() => {
        setSelectedValue(props.value || 0);
    }, [props.value]);
    return (
        <div>
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth style={{
                    position: 'sticky', marginTop: "16px",
                    marginBottom: "8px",
                }}
                    //Manejo de errores
                    error={props.error}
                    size={props.size}

                >
                    {/*La propiedad props.label permitirá darle un nombre al input*/}
                    <InputLabel id={props.inputLabelId} style={{ color: 'gray' }}>{props.label}</InputLabel>
                    <Select
                        value={selectedValue}
                        onChange={handleChange}
                        labelId={props.labelId}
                        label={props.label}
                        color="gray"
                        id={props.id} //Esta propiedad permitiá identificar el select, permitiendo saber su valor, etc.
                        name={props.name}
                        defaultValue={props.defaultValueDisabled ? props.defaultValueDisabled : (props.defaultValue ? props.defaultValue : 0)}
                        sx={props.sx}
                        disabled={props.disabled}
                        required={props.required}

                    >

                         <MenuItem value={0} disabled>
                            Selecciona una opción
                        </MenuItem> 
                        {!props.defaultValueDisabled ? null :
                            <MenuItem value={props.defaultValueDisabled} disabled>
                                {props.defaultOptionDisabled}
                            </MenuItem>}
                        {/* Este array tiene objetos que entre sus propiedades estarán el identificador 
            y el texto que se quiera mostrar, 
            
            **IMPORTANTE** estas propiedades (identificador,texto) no estarán en un principio en 
            el JSON traido de la API, por lo que se debe manipular y agregar estas propiedades 
            a cada uno de los objetos para poder usar este componente */}
                        {array.map((element) => {
                            return (
                                <MenuItem
                                    value={element.identificador}
                                    key={element.identificador}
                                >
                                    {element.texto}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>
        </div>
    );
}