import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../Tools/Table/StyledTableCell";
import { getDataToken } from "../../api/dataToken";

export function CompInicio(props) {
  return (
    <div>
      <iframe
        src="https://www.scribd.com/embeds/696012358/content?start_page=1&view_mode=scroll&access_key=key-hOpBX2U6dZqam7jXVpFV"
        width="800"
        height="600"
        frameborder="0"
      ></iframe>
    </div>
  );
}
