import * as React from "react";
import PropTypes from "prop-types";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@material-ui/core/Grid";
import { principal } from "../../../api/getUmbrales";
import { Link } from "react-router-dom";
//Solo aparesera un alerta con un solo boton
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogColumna(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    //props.clickButton(true)
    setOpen(false);
  };
  const handleClickButton = () => {
    //props.clickButton(true)
    setOpen(false);
  };

  return (
    <div>
      {/*BOTON QUE HACE EL LLAMADO AL DIALOGO*/}
      {/*Este boton aparecera el js en donde se necesite de la alerta*/}
      <ButtonVino
        variant="contained"
        onClick={handleClickOpen}
        estatus={props.estatus}
        type={props.type}
        label={props.mainButton}
        onSubmit={props.onSubmit}
        
      ></ButtonVino>
      {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="dialog"
        open={open}
        onSubmit={props.onSubmit}
      >
        {/*CUERPO DEL DIALOGO*/}
        {/*TITILO*/}
        <BootstrapDialogTitle
          id="dialog"
          onClose={handleClose}
          style={{ color: principal }}
        >
          <h1-dialog-title>{props.title}</h1-dialog-title>
        </BootstrapDialogTitle>
        {/*CUERPO DEL DIALOGO*/}
        {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
        <DialogContent dividers style={{ minWidth: 500 }}>
          {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
          se necesita colocar entre un typogreaphy las veces que des salto de linea 
          o con un br*/}
          <h2-dialog-description>{props.description}</h2-dialog-description>
          <br />
          <h2-dialog-description>{props.description2}</h2-dialog-description>

          <h2-dialog-description>{props.description3}</h2-dialog-description>

          <h2-dialog-description>{props.description4}</h2-dialog-description>

          <h2-dialog-description>{props.description5}</h2-dialog-description>

          <h2-dialog-description>{props.description6}</h2-dialog-description>

          <h2-dialog-description>{props.description7}</h2-dialog-description>

          <h2-dialog-description>{props.description8}</h2-dialog-description>

          <h2-dialog-description>{props.description9}</h2-dialog-description>

          <h2-dialog-description>{props.description10}</h2-dialog-description>
          
          <h2-dialog-description>{props.description11}</h2-dialog-description>

          <h2-dialog-description>{props.description12}</h2-dialog-description>

          <h2-dialog-description>{props.description13}</h2-dialog-description>
          
          <h2-dialog-description>{props.description14}</h2-dialog-description>

          <h2-dialog-description>{props.description15}</h2-dialog-description>

          <h2-dialog-description>{props.description16}</h2-dialog-description>
          
          <h2-dialog-description>{props.description17}</h2-dialog-description>

          <h2-dialog-description>{props.description18}</h2-dialog-description>

          <h2-dialog-description>{props.description19}</h2-dialog-description>

          <h2-dialog-description>{props.description20}</h2-dialog-description>
          
          <h2-dialog-description>{props.description21}</h2-dialog-description>

          <h2-dialog-description>{props.description22}</h2-dialog-description>

          <h2-dialog-description>{props.description23}</h2-dialog-description>
          
          <h2-dialog-description>{props.description24}</h2-dialog-description>

          <h2-dialog-description>{props.description25}</h2-dialog-description>

          <h2-dialog-description>{props.description26}</h2-dialog-description>
          
          <h2-dialog-description>{props.description27}</h2-dialog-description>

          <h2-dialog-description>{props.description28}</h2-dialog-description>

          <h2-dialog-description>{props.description29}</h2-dialog-description>

          <h2-dialog-description>{props.description30}</h2-dialog-description>
          
          <h2-dialog-description>{props.description31}</h2-dialog-description>

          <h2-dialog-description>{props.description32}</h2-dialog-description>

          <h2-dialog-description>{props.description33}</h2-dialog-description>
          
          <h2-dialog-description>{props.description34}</h2-dialog-description>

          <h2-dialog-description>{props.description35}</h2-dialog-description>

          <h2-dialog-description>{props.description36}</h2-dialog-description>
          
          <h2-dialog-description>{props.description37}</h2-dialog-description>

          <h2-dialog-description>{props.description38}</h2-dialog-description>

          <h2-dialog-description>{props.description39}</h2-dialog-description>

          <h2-dialog-description>{props.description40}</h2-dialog-description>
          
          <h2-dialog-description>{props.description41}</h2-dialog-description>

          <h2-dialog-description>{props.description42}</h2-dialog-description>

          <h2-dialog-description>{props.description43}</h2-dialog-description>
          
          <h2-dialog-description>{props.description44}</h2-dialog-description>

          <h2-dialog-description>{props.description45}</h2-dialog-description>

          <h2-dialog-description>{props.description46}</h2-dialog-description>
          
          <h2-dialog-description>{props.description47}</h2-dialog-description>

          <h2-dialog-description>{props.description48}</h2-dialog-description>

          <h2-dialog-description>{props.description49}</h2-dialog-description>

          <h2-dialog-description>{props.description50}</h2-dialog-description>
          
          <h2-dialog-description>{props.description51}</h2-dialog-description>

          <h2-dialog-description>{props.description52}</h2-dialog-description>

          <h2-dialog-description>{props.description53}</h2-dialog-description>

        </DialogContent>
        {/*es la tercer sección que engloba los botones */}
        <DialogActions>
          <Grid container justifyContent="center">
            <Link
              to={props.to}
              state={props.state}
              style={{ textDecoration: "none" }}
              onClick={props.onClick}
            >
              <ButtonVino
                onClick={handleClose}
                enlace={props.link}
                variant="contained"
                label={props.button}
                onClick1={props.cons}
              />
            </Link>
          </Grid>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
