//  David Ruiz 10/2022

import React from 'react';
import IconButton from '@mui/material/IconButton';
import QueryStatsIcon from '@mui/icons-material/QueryStats';


const BtGlobal=(props)=>{
    return(
        <IconButton aria-label=" QueryStatsIcon" color="icons"  
        //aqui va el enlace
        href={props.enlace}
        onClick={props.onClick}
        onChange={props.onChange}
        id={props.id}
        //ojo aqui es disabled
        //true para desabilitar y false para habilitar
        disabled={props.estatus}>
        < QueryStatsIcon />
        </IconButton>
        );
    }

export default BtGlobal;