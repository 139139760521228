import * as React from "react";

//tablas
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//componentes de mui
import RadioInput from "../../../Tools/RadioButton/RadioTable";


//idioma
import { FormattedMessage } from "react-intl";
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import DialogConfirm from "../Dialog/DialogConfirm";

//useEffect


//importacion de los token para acceder al proyecto



export default function TablaDatosReclamacion(props) {
  const datos = props.data;
  return (
    <div>
      <Paper sx={{ width: "100%" }} style={{ marginTop: '50px' }}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead style={{ position: "sticky" }}>
              <TableRow>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                <StyledTableCell>
                  <FormattedMessage
                    id="globNoPoli"
                    defaultMessage="No. de póliza"
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="globProdut" defaultMessage="Producto" />
                </StyledTableCell>
                <StyledTableCell>
                  <FormattedMessage
                    id="globNomAse"
                    defaultMessage="Nombre del asegurado"
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="globRfcp" defaultMessage="RFC del asegurado" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="globCurp" defaultMessage="CURP del asegurado" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="recepContr"
                    defaultMessage="Contratante"
                  />
                </StyledTableCell>
                <StyledTableCell align="center" >
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody id="tbbody">
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {datos
                .map((row, index) => {

                  return (

                    <TableRow >
                      <StyledTableCell align="center">
                        {row.POL_numpoliza}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.PRO_descripcion}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.PER_nombre_ase}{" "}
                        {row.PER_apePat_ase}{" "}
                        {row.PER_apeMat_ase}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.PER_RFC_ase}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.PER_CURP_ase}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.PER_nombre_cont}{" "}
                        {row.PER_apePat_cont}{" "}
                        {row.PER_apeMat_cont}
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{padding:"0px"}}>
                        <DialogConfirm onClickAccept={(e)=>props.iconOnClick(e, index)} 
                        title="Eliminar fila"
                        description="¿Está seguro de eliminar este registro de la tabla?"/>
                      </StyledTableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
