import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { useState, useEffect} from "react";
import { Grid } from "@mui/material";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import TXTField from "../../Tools/TextField/TextField";
//estilos de la tabla
import StyledTableCell from "../../Tools/Table/StyledTableCell";
import TablePagination from "@mui/material/TablePagination";
//Idioma
import { FormattedMessage } from "react-intl";
//impportacion apis
import {getDataToken} from "../../../api/dataToken"

export default function TableAccountingEntries() { 

  //Creación de metodo get
  const [cuentas, setCuentas] = useState([]); 
  const getData = async () => {
    try {
      var result = await getDataToken("cuentasporasientoscontables/get_listado/");
      console.log("ojala salga", result);
      setCuentas(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

   useEffect(() => {
     getData();
   }, []); 

  /* CONSTANTES DE ESTADOS*/
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item>
          <h3-header-card>
            <FormattedMessage
              id="estconAsCo"
              defaultMessage="Consulta de Asientos contables"
            />
          </h3-header-card>
        </Grid>
      </Grid>

      <Grid
        style={{ paddingBottom: "20px" }}
        container
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={4}>
          <h4-search>
            <FormattedMessage id="globBuscar" defaultMessage="Buscar" />
          </h4-search>
          <TXTField
            showSearchIcon={true}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      
      <br />
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <FormattedMessage id="estconCont" defaultMessage="Concepto" />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <FormattedMessage

                    id="estCueMayo"
                    defaultMessage="Cuenta del mayor"
                  />
                </StyledTableCell>

                <StyledTableCell align="center">
                  <FormattedMessage
                    id="estcoSubCu"
                    defaultMessage="Subcuenta"
                  />
                </StyledTableCell>
                <StyledTableCell align="center">Subsubcuenta</StyledTableCell>
                <StyledTableCell align="center">   
                  <FormattedMessage id="loginCuent" defaultMessage="Cuenta"/>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="estconNatu"
                    defaultMessage="Naturaleza"
                  />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {cuentas
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else 
                  /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                    
                  {
                    return (
                      (val.CTA_idcuentas?.CTAM_idcuentamayor?.CTAM_numcuenta).toString().includes(searchTerm) ||
                      (val.row.CTA_idcuentas?.CTA_numcuenta).toString().includes(searchTerm) ||
                      val.ASTCONT_idasientocontable?.ASTCONT_asientocontable.toLowerCase().includes(searchTerm.toLowerCase())
                    );
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index, array) => (
                  // Se colocan dos veces cada variable a ocupar ya que esta tabla puede tener
                  //  más de dos registros dentro de otro registro
                  <>

                    { index === 0 || row.ASTCONT_idasientocontable?.ASTCONT_asientocontable !== array[index-1].ASTCONT_idasientocontable?.ASTCONT_asientocontable ? 
                    (
                      <TableRow key={row.cuenta}>
                        <StyledTableCell rowSpan={array.filter( (item) => item.ASTCONT_idasientocontable?.ASTCONT_asientocontable === row.ASTCONT_idasientocontable?.ASTCONT_asientocontable).length}>
                        {row.ASTCONT_idasientocontable?.ASTCONT_asientocontable}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                            <Grid item xs={6}>
                              {row.CTA_idcuentas?.CTAM_idcuentamayor?.CTAM_numcuenta}
                            </Grid>
                            {/* <Grid item xs={6}>
                              {row.mayor2}
                            </Grid> */}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Grid item xs={6}>
                            {row.CTA_idcuentas?.SCTA_idsubcuenta?.SCTA_numsubcuenta}
                          </Grid>
                          {/* <Grid item xs={6}>
                            {row.subcuenta2}
                          </Grid> */}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Grid item xs={6}>
                            {row.CTA_idcuentas?.SSCTA_idsubsubcuenta?.SSCTA_numsubsubcuenta}
                          </Grid>
                          {/* <Grid item xs={6}>
                            {row.subSubcuenta2}
                          </Grid>*/}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Grid item xs={6}>
                            {row.CTA_idcuentas?.CTA_numcuenta}
                          </Grid>
                          {/* <Grid item xs={6}>
                            {row.cuenta2}
                          </Grid>  */}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Grid item xs={6}>
                          {row.CTAASC_naturaleza === 1 ? 'Crédito' : 'Cargo'}
                          </Grid> 
                          {/* <Grid item xs={6}>
                          {row.naturaleza2}
                          </Grid> */}
                        </StyledTableCell>

                      </TableRow>
                    ):(
                      <TableRow>
                      <StyledTableCell align="center">
                            <Grid item xs={6}>
                              {row.CTA_idcuentas?.CTAM_idcuentamayor?.CTAM_numcuenta}
                            </Grid>
                            {/* <Grid item xs={6}>
                              {row.mayor2}
                            </Grid> */}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Grid item xs={6}>
                            {row.CTA_idcuentas?.SCTA_idsubcuenta?.SCTA_numsubcuenta}
                          </Grid>
                          {/* <Grid item xs={6}>
                            {row.subcuenta2}
                          </Grid> */}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Grid item xs={6}>
                            {row.CTA_idcuentas?.SSCTA_idsubsubcuenta?.SSCTA_numsubsubcuenta}
                          </Grid>
                          {/* <Grid item xs={6}>
                            {row.subSubcuenta2}
                          </Grid>*/}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Grid item xs={6}>
                            {row.CTA_idcuentas?.CTA_numcuenta}
                          </Grid>
                          {/* <Grid item xs={6}>
                            {row.cuenta2}
                          </Grid>  */}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Grid item xs={6}>
                          {row.CTAASC_naturaleza === 2 ? 'Cargo' : 'Crédito'}
                          </Grid> 
                          {/* <Grid item xs={6}>
                          {row.naturaleza2}
                          </Grid> */}
                        </StyledTableCell>
                      </TableRow>
                    )}

                  </>
                ))}
            </TableBody>
          </Table>

        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={cuentas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelRowsPerPage="Filas por página"
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
