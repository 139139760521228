import React from 'react'
import {FormattedMessage} from 'react-intl';
export function Info(){
return (
    <div>
    <h3-title-card>
        <FormattedMessage
            id="documPolizaOriginalE"
            defaultMessage="Póliza original. En caso de invalidez, con una copia de la póliza es suficiente."
        />
    <br/>
    </h3-title-card>
    <h3-subtitle-card>
    <br/>
        <FormattedMessage
            id="docuActDef"
            defaultMessage="Acta de defunción (Original o copia certificada)"
        />
    <br/>
        <FormattedMessage
            id="docuCerDef"
            defaultMessage="Certificado de defunción (Copia)"
        />
        <br/>
        <FormattedMessage
            id="docuIdeOfi"
            defaultMessage="Identificación oficial del asegurado (Original y copia)"
        />
    <br/>
    <FormattedMessage
            id="globHistorialClinico"
            defaultMessage="Historial Clínico, completo Que incluya diagnóstico y tratamiento al que fue sometido el Asegurado"
        />
    <br/>
    <FormattedMessage
            id="docuIdeBen"
            defaultMessage="Identificación del beneficiario (Original y copia)"
        />
    <br/>
    <FormattedMessage
            id="gloFormatoReclaCom"
            defaultMessage="Formato Reclamación Formato declaración de fallecimiento no. 1. Debidamente llenado y firmado por los Beneficiarios. Original"
        />
    <br/>
    <FormattedMessage
            id="globCarpetaDeInvesti"
            defaultMessage="Carpeta de Investigación (Solo en caso de existir) Copia Certificada por el M.P incluyendo los resultados de los estudios toxicológicos y de alcoholemia"
        />
    <br/>
    <FormattedMessage
            id="globDatosBancaDeLosB"
            defaultMessage="Datos bancarios de los beneficiarios copia del estado de cuenta y Formato de información para pago. Debidamente llenado y firmado por el asegurado o beneficiario(s)."
        />
    <br/>
    <FormattedMessage
            id="globFormatoDeRecla"
            defaultMessage="Formato de reclamación en original formato declaración de fallecimiento no. 2. Debidamente llenado y firmado por el médico tratante del asegurado."
        />
    <br/>
    <FormattedMessage
            id="globOriginalDeCompro"
            defaultMessage="Original de comprobante de domicilio, no mayor de 3 meses (recibo de luz, teléfono, predial, agua, cuenta bancaria, suministro de gas natural).**"
        />
    <br/>
    <FormattedMessage
            id="docuPerFisCoCeAcNaci"
            defaultMessage="Copia certificada de acta de nacimiento (Solo en caso de que el beneficiario sea menor de edad)"
        />
    <br/>
    <FormattedMessage
            id="docuPeFiCoCeActDefun"
            defaultMessage="Copia certificada de acta de defunción (Solo en caso de que alguno de los beneficiarios haya fallecido)."
        />
    <br/>
    </h3-subtitle-card>
    <h3-title-card>
    <br/>
    Si el beneficiario es una Persona Moral algunos de los documentos qué deberá de presentar son  los siguientes:
    <br/>
    </h3-title-card>
    <h3-subtitle-card>
    <br/>
    <FormattedMessage
            id="docuPerMoralActaCons"
            defaultMessage="Testimonio o copia legible del acta constitutiva inscrita en el registro público de comercio que acredite fehacientemente su legal existencia (Copia notariada)"
        />
    <br/>
    <FormattedMessage
            id="docuPerMoralRfcExped"
            defaultMessage="Original de la cédula de identificación fiscal (RFC) expendida por la secretaría de hacienda y crédito público."
        />
    <br/>
    <FormattedMessage
            id="docuPerFisicaComDomi"
            defaultMessage="Comprobante de domicilio no mayor a 3 meses (Deberá ser original de recibo de luz, teléfono, predial, agua, cuenta bancaria, suministro de gas natural)"
        />
    <br/>
    <FormattedMessage
            id="docuPerMoralTestCoLe"
            defaultMessage="Testimonio o copia legible del instrumento que contenga los poderes del representante legal expedido por fedatario público cuando no estén contenidos en la escritura constitutiva."
        />
    <br/>
    <FormattedMessage
            id="docuPerMoralIdentifi"
            defaultMessage="Original de la identificación oficial vigente del representante legal con firma y fotografía (INE, pasaporte, cartilla SMN, cédula profesional)."
        />
    <br/>
    <FormattedMessage
            id="msgPerMoralExtrajero"
            defaultMessage="En caso de ser extranjeros deberá de presentar copia debidamente legalizada o apostillada del documento que compruebe fehacientemente su legal existencia."
        />
        <br/>
    <FormattedMessage
            id="msgPerMoralExtranjer"
            defaultMessage="Así como del que acredite como su representante a la persona física que ostente como tal y en caso de ser este también extranjera deberá presentar los documentos señalados para personas físicas."
        />
    </h3-subtitle-card>
    </div>

    






);
}