/* Brandon Hernandez Rocha 04/2023 */
import JSZip from "jszip";
import { getDataToken } from "../api/dataToken";
import { pdf } from "@react-pdf/renderer";

let baseURL, username, password, path;
const getURLData = async () => {
  try {
    const result = await getDataToken("configuraciongeneral/get_id/1/");
    if (result) {
      baseURL = result.SCG_DOCURL;
      path = result.SCG_pathnextcloud;
      username = result.SCG_usernextcloud;
      password = result.SCG_passnextcloud;
    } else {
      console.log("Error al hacer la consulta");
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 *
 * @param {*} folio Folio de la póliza
 * @returns
 */
export const getFiles = async (folio) => {
  await getURLData();
  const carpeta = baseURL + path;
  const serverUrl = baseURL;
  try {
    const response = await fetch(carpeta + folio, {
      method: "PROPFIND",
      headers: {
        Authorization: `Basic ${customBtoa(username + ":" + password)}`,
      },
    });
    console.log("Response: ", response)
    const responseText = await response.text();
    console.log("responseText", responseText);
    const hrefTags = responseText.match(/<d:href>([^<]+)/g);
    if (hrefTags) {
      const fileList = await Promise.all(
        hrefTags.map(async (hrefTag) => {
          const fileUrl = serverUrl + hrefTag.replace(/<\/?d:href>/g, "");
          const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
          if (fileName === "") return null;
          const fileResponse = await fetch(fileUrl, {
            headers: {
              Authorization: `Basic ${customBtoa(username + ":" + password)}`,
            },
          });
          const fileBlob = await fileResponse.blob();
          const fileExt = fileName.substring(fileName.lastIndexOf(".") + 1);
          return { fileName, fileResponse, fileExt, fileBlob };
        })
      );
      return fileList.filter((file) => file !== null); // Filtra los archivos que no son null
    } else {
      return false; //Si retorna false es porque no existe carpeta con ese folio
    }
  } catch (error) {
    return false;
  }
};

// Ejemplo de geticonos

export const getFiles2 = async (folio) => {
  await getURLData();
  const carpeta =
    "http://files.sinay.com.mx/remote.php/dav/files/usrloader/SINAY/FOLIOS/REC352162";
  const serverUrl = "http://files.sinay.com.mx/";
  try {
    const response = await fetch(carpeta + folio, {
      method: "PROPFIND",
      headers: {
        Authorization: `Basic ${customBtoa(username + ":" + password)}`,
      },
    });
    const responseText = await response.text();
    console.log("responseText", responseText);
    const hrefTags = responseText.match(/<d:href>([^<]+)/g);
    if (hrefTags) {
      const fileList = await Promise.all(
        hrefTags.map(async (hrefTag) => {
          const fileUrl = serverUrl + hrefTag.replace(/<\/?d:href>/g, "");
          const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
          if (fileName === "") return null;
          const fileResponse = await fetch(fileUrl, {
            headers: {
              Authorization: `Basic ${customBtoa(username + ":" + password)}`,
            },
          });
          const fileBlob = await fileResponse.blob();
          const fileExt = fileName.substring(fileName.lastIndexOf(".") + 1);
          return { fileName, fileResponse, fileExt, fileBlob };
        })
      );
      return fileList.filter((file) => file !== null); // Filtra los archivos que no son null
    } else {
      return false; //Si retorna false es porque no existe carpeta con ese folio
    }
  } catch (error) {
    return false;
  }
};

/**
 *
 * @param {Blob} blob Recibe un valor de tipo blob, que será util para crear un objeto url para su posterior descarga.
 * @param {String} fileName Recibe un nombre para el archivo a descargar
 */
export function downloadBlob(blob, fileName) {
  if (fileName !== "") {
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
}

/**
 *
 * @param {*} blobFileArray
 * @param {*} zipName
 * Ejemplo de blobFile Array =====> const blobFileArray = [ { blob, filename }  ]
 */
export function downloadBlobsAsZip(blobFileArray, zipName) {
  // Creamos un objeto JSZip
  const zip = new JSZip();

  // Agregamos cada blob al zip con su nombre de archivo correspondiente
  blobFileArray.forEach(({ blob, filename }) => {
    zip.file(filename, blob);
  });

  // Generamos el archivo zip
  zip.generateAsync({ type: "blob" }).then((zipBlob) => {
    // Descargamos el archivo zip utilizando la función downloadBlob
    downloadBlob(zipBlob, zipName);
  });
}

/**
 *
 * @param {*} folio Recibe como parámetro folio (el nombre de la carpeta en nextcloud)
 * @returns
 */
export const createFolderFolio = async (folio) => {
  await getURLData();
  const remoteUrl = baseURL + path;
  // Validar si la carpeta ya existe
  const existFolder = await validaCarpeta(remoteUrl, folio);

  if (existFolder) {
    alert("Ya existe una carpeta con ese folio");
    throw new Error("Ya existe una carpeta con ese folio.");
  }

  // Crear la carpeta
  return fetch(remoteUrl + folio, {
    method: "MKCOL",
    headers: {
      Authorization: `Basic ${customBtoa(username + ":" + password)}`,
      "Content-Type": "application/xml",
    },
    body: '<?xml version="1.0" ?><d:mkcol xmlns:d="DAV:"><d:set><d:prop><d:resourcetype><d:collection/></d:resourcetype></d:prop></d:set></d:mkcol>',
  })
    .then((response) => {
      if (response.ok) {
        console.log("Carpeta creada exitosamente");
        return true;
      } else {
        throw new Error("Ocurrió un error al crear la carpeta.", response);
      }
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
};
/**
 *
 * @param {String} folio Folio asociado a la póliza
 * @returns
 */
export const createFolderPoliza = async (folio) => {
  await getURLData();
  const remoteUrl = baseURL + path;
  // Validar si la carpeta ya existe
  const existFolder = await validaCarpeta(remoteUrl, folio);

  if (existFolder) {
    alert("Ya existe una carpeta con ese folio");
    throw new Error("Ya existe una carpeta con ese folio.");
  }

  // Crear la carpeta
  return fetch(remoteUrl + folio, {
    method: "MKCOL",
    headers: {
      Authorization: `Basic ${customBtoa(username + ":" + password)}`,
      "Content-Type": "application/xml",
    },
    body: '<?xml version="1.0" ?><d:mkcol xmlns:d="DAV:"><d:set><d:prop><d:resourcetype><d:collection/></d:resourcetype></d:prop></d:set></d:mkcol>',
  })
    .then((response) => {
      if (response.ok) {
        console.log("Carpeta creada exitosamente");
        return true;
      } else {
        throw new Error("Ocurrió un error al crear la carpeta.", response);
      }
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
};

//Upload files
/**
 *
 * @param {String} folderPath Este parametro es el folio, pues cada carpeta tendrá como nombre el folio.
 * @param {Array} files Files es un arreglo de archivos.
 * @returns Retorna true si los archivos han sido cargados exitosamente y false si es viceversa.
 */
export async function uploadFilesToNextcloud(folio, files) {
  await getURLData();
  const remoteUrl = baseURL + path;
  const fileUploadPromises = [];

  // Validar si la carpeta ya existe
  const existFolder = await validaCarpeta(remoteUrl, folio);

  if (!existFolder) {
    alert("No existe una carpeta con ese folio");
    throw new Error("No existe una carpeta con ese folio.");
  }

  for (const file of files) {
    const filename = file.name;
    const remoteFilePath = remoteUrl + folio + "/" + filename;

    try {
      const response = await fetch(remoteFilePath, {
        method: "HEAD",
        headers: {
          Authorization: `Basic ${customBtoa(username + ":" + password)}`,
        },

      });

      if (response.ok) {
        // If file already exists, add a number to the filename
        let count = 1;
        let newFilename = `${filename.replace(
          /\.[^/.]+$/,
          ""
        )} (${count})${filename.substring(filename.lastIndexOf("."))}`;

        while (
          await checkRemoteFileExists(
            remoteUrl,
            folio,
            newFilename,
            username,
            password
          )
        ) {
          count++;
          newFilename = `${filename.replace(
            /\.[^/.]+$/,
            ""
          )} (${count})${filename.substring(filename.lastIndexOf("."))}`;
        }

        const newFile = new File([file], newFilename, { type: file.type });
        fileUploadPromises.push(
          uploadFile(remoteUrl, folio, newFile, username, password)
        );
      } else {
        fileUploadPromises.push(
          uploadFile(remoteUrl, folio, file, username, password)
        );
      }
    } catch (error) {
      // File does not exist, do nothing
    }
  }

  return Promise.all(fileUploadPromises);
}
/**
 *
 * @param {String} remoteUrl Url del folder
 * @param {String} folderPath Nombre del folder (el folio).
 * @param {file} file Archivo que se cargará a la carpeta
 * @param {String} username
 * @param {String} password
 * @returns {String} Retorna la url
 */
async function uploadFile(remoteUrl, folio, file, username, password) {
  const response = await fetch(remoteUrl + folio + "/" + file.name, {
    method: "PUT",
    headers: {
      Authorization: `Basic ${customBtoa(username + ":" + password)}`,
    },
    body: file,
  });

  if (!response.ok) {
    throw new Error(`Ocurrió un error al subir el archivo ${file.name}`);
  }

  // const uploadedFileUrl = remoteUrl + folio + "/" + file.name;
  // return uploadedFileUrl;
  // Obtener la URL del archivo subido
  let url = getURLDocument(folio, file.name)
  console.log("Archivo URL", url)
  return url
}

export async function getAuthorize() {
  await getURLData();
  console.log("Base url", baseURL);
  const remoteFilePath = `${baseURL}index.php/apps/oauth2/authorize?client_id=gs7IWFOtyyXLPIjiaDkT9YJLWg2lzL1Llco6GZ7Zcc9iDqf9ODFxDlVoxxYYICIc&state=Sinay%242023%21&response_type=code`;

  const url = 'https://files.sinay.com.mx//index.php/apps/webapppassword/api/v1/shares?path=%2FSINAY%2FFOLIOS%2F%2FREC4747875&shareType=3';

  const options = {
    method: 'POST',
    headers: {
      'OCS-APIRequest': true,
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain',
      Authorization: `Basic ${customBtoa(username + ":" + password)}`
    },
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    console.log(data);
  } catch (error) {
    console.error(error);
  }
}
export async function getURLDocument(folio, fileName) {
  await getURLData();
  console.log("Base url", baseURL);
  const remoteFilePath = `${baseURL}/index.php/apps/webapppassword/core/preview/${folio}/${fileName}`;

  try {
    const response = await fetch(remoteFilePath, {
      method: "GET",
      headers: {
        'Authorization': `Basic ${customBtoa(username + ":" + password)}`,
        'mode': 'no-cors'
      },
    });
    console.log("RESPONSE:", response)
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log("Response from Nextcloud API:", data);

    // Aquí puedes realizar operaciones con la respuesta, si es necesario.

  } catch (error) {
    console.error('Error:', error);
  }
}
export async function getURLToken(folio, fileName) {
  // await getURLData();
  // console.log("Base url", baseURL);
  // const remoteFilePath = `${baseURL}${path}REC4747875/sample.pdf`;

  // try {
  //   const response = await fetch(remoteFilePath, {
  //     method: "GET",
  //     headers: {
  //       'OCS-APIRequest': true,
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       'Accept': 'application/json, text/plain',
  //       'Authorization': `Basic ${customBtoa(username + ":" + password)}`,
  //       body: 'shareType=3',
  //     },
  //   });

  //   console.log("RESPONSE:", response)
  //   // const data = await response.json();
  //   // console.log("data:", data)

  //   // if (response.ok) {
  //   //   // Obtiene el token de compartición desde la respuesta
  //   //   const token = data.ocs.data.id;
  //   //   console.log('Token de compartición:', token);
  //   // } else {
  //   //   console.error('Error al generar el enlace de compartición:', data);
  //   // }
  // } catch (error) {
  //   console.error('Error de red:', error);
  // }
  const url = 'https://files.sinay.com.mx/index.php/apps/oauth2/api/v1/token?grant_type=authorization_code';
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain',
      Authorization: `Basic ${customBtoa(username + ":" + password)}`, 
    },
    body: undefined
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    console.log(data);
  } catch (error) {
    console.error(error);
  }
};


/**
 *
 * @param {*} remoteUrl Url del folder
 * @param {*} folderPath Nombre del folder (el folio).
 * @param {*} filename Nombre del archivo
 * @param {*} username
 * @param {*} password
 * @returns
 */
async function checkRemoteFileExists(
  remoteUrl,
  folio,
  filename,
  username,
  password
) {
  await getURLData();
  const remoteFilePath = remoteUrl + folio + "/" + filename;
  const response = await fetch(remoteFilePath, {
    method: "HEAD",
    headers: {
      Authorization: `Basic ${customBtoa(username + ":" + password)}`,
    },
  });

  if (response.status === 404) {
    return false; // El archivo no existe
  } else if (response.ok) {
    return true; // El archivo existe
  } else {
    throw new Error(
      `Ocurrió un error al comprobar la existencia del archivo ${filename}`
    );
  }
}

/**
 *
 * @param {*} remoteUrl Url del folder
 * @param {*} folderName Nombre del folder (el folio).
 * @returns
 */
async function validaCarpeta(remoteUrl, folderName) {
  await getURLData();
  // Validar si la carpeta ya existe
  const existFolder = await fetch(remoteUrl + folderName, {
    method: "PROPFIND",
    headers: {
      Authorization: `Basic ${customBtoa(username + ":" + password)}`,
      "Content-Type": "application/xml",
    },
  })
    .then((response) => response.ok)
    .catch((error) => {
      console.error(error);
      return false;
    });

  return existFolder; //Si si existe retorna true
}

//Upload files
/**
 * Ejemplo para arreglo del parámetro "files" ==> [{doc: Document, docName: POL_123}, {doc: Document, docName: POL_234}]
 *
 * @param {String} folderName Este parametro es el folio, pues cada carpeta tendrá como nombre el folio.
 * @param {Array} files Files es un arreglo de archivos.
 * @returns Retorna un arreglo que contiene la informacion de los documentos cargados.
 */
export async function uploadFilesToNextcloud2(folio, files) {
  await getURLData();
  const remoteUrl = baseURL + path;

  try {
    // Validar si la carpeta ya existe
    const existFolder = await validaCarpeta(remoteUrl, folio);

    if (!existFolder) {
      alert("No existe una carpeta con ese folio");
      throw new Error("No existe una carpeta con ese folio.");
    }

    // Esta variable almacenará las rutas de los archivos
    let uploadedFileURLs = [];

    for (const file of files) {
      const filename = file.docName;
      const remoteFilePath = remoteUrl + folio + "/" + filename;

      const response = await fetch(remoteFilePath, {
        method: "HEAD",
        headers: {
          Authorization: `Basic ${customBtoa(username + ":" + password)}`,
        },
      });

      if (response.ok) {
        // If file already exists, add a number to the filename
        let count = 1;
        let newFilename = `${filename.replace(
          /\.[^/.]+$/,
          ""
        )} (${count})${filename.substring(filename.lastIndexOf("."))}`;

        while (
          await checkRemoteFileExists(
            remoteUrl,
            folio,
            newFilename,
            username,
            password
          )
        ) {
          count++;
          newFilename = `${filename.replace(
            /\.[^/.]+$/,
            ""
          )} (${count})${filename.substring(filename.lastIndexOf("."))}`;
        }

        const newFile = new File([file.doc], newFilename, { type: file.type });
        const uploadedFile = uploadFile(
          remoteUrl,
          folio,
          newFile,
          username,
          password
        );
        uploadedFileURLs.push({
          filename: newFilename,
          url: uploadedFile,
        });
      } else {
        const newFile = new File([file.doc], filename, { type: file.type });
        const uploadedFile = uploadFile(
          remoteUrl,
          folio,
          newFile,
          username,
          password
        );
        uploadedFileURLs.push({
          filename,
          url: uploadedFile,
        });
      }
    }

    return uploadedFileURLs;
  } catch (error) {
    console.log("Ocurrió un error al subir los archivos:", error);
    throw error;
  }
}
//Upload CARTAS
/**
 * Ejemplo para arreglo del parámetro "files" ==> [{doc: Document, docName: POL_123}, {doc: Document, docName: POL_234}]
 *
 * @param {String} folderName Este parametro es el folio, pues cada carpeta tendrá como nombre el folio.
 * @param {Array} files Files es un arreglo de archivos.
 * @returns Retorna true si los archivos han sido cargados exitosamente y false si es viceversa.
 */
export async function uploadCartasToNextcloud(folio, files) {
  await getURLData();
  const remoteUrl = baseURL + path;

  try {
    // Validar si la carpeta ya existe
    const existFolder = await validaCarpeta(remoteUrl, folio);

    if (!existFolder) {
      alert("No existe una carpeta con ese folio");
      throw new Error("No existe una carpeta con ese folio.");
    }

    // Esta variable almacenará las rutas de los archivos
    let uploadedFileURLs = [];

    for (const file of files) {
      var docBlob = file.doc;
      const filename = file.docName + ".pdf";
      const remoteFilePath = remoteUrl + folio + "/" + filename;

      const response = await fetch(remoteFilePath, {
        method: "HEAD",
        headers: {
          Authorization: `Basic ${customBtoa(username + ":" + password)}`,
        },
      });

      if (response.ok) {
        // If file already exists, add a number to the filename
        let count = 1;
        let newFilename = `${filename.replace(
          /\.[^/.]+$/,
          ""
        )} (${count})${filename.substring(filename.lastIndexOf("."))}`;

        while (
          await checkRemoteFileExists(
            remoteUrl,
            folio,
            newFilename,
            username,
            password
          )
        ) {
          count++;
          newFilename = `${filename.replace(
            /\.[^/.]+$/,
            ""
          )} (${count})${filename.substring(filename.lastIndexOf("."))}`;
        }

        const newFile = new File([docBlob], newFilename, {
          type: "application/pdf",
        });
        const uploadedFile = uploadFile(
          remoteUrl,
          folio,
          newFile,
          username,
          password
        );
        uploadedFileURLs.push({
          filename: newFilename,
          url: uploadedFile,
        });
      } else {
        const newFile = new File([docBlob], filename, {
          type: "application/pdf",
        });
        const uploadedFile = uploadFile(
          remoteUrl,
          folio,
          newFile,
          username,
          password
        );
        uploadedFileURLs.push({
          filename,
          url: uploadedFile,
        });
      }
    }

    return uploadedFileURLs;
  } catch (error) {
    console.log("Ocurrió un error al subir los archivos:", error);
    throw error;
  }
}

export const searchFilesByName = async (folio, nombreABuscar) => {
  await getURLData();
  const carpeta = baseURL + path + folio;
  const serverUrl = baseURL;
  try {
    const response = await fetch(carpeta, {
      method: "PROPFIND",
      headers: {
        Authorization: `Basic ${customBtoa(username + ":" + password)}`,
      },
    });
    const responseText = await response.text();
    const hrefTags = responseText.match(/<d:href>([^<]+)/g);
    if (hrefTags) {
      const fileList = await Promise.all(
        hrefTags.map(async (hrefTag) => {
          const fileUrl = serverUrl + hrefTag.replace(/<\/?d:href>/g, "");
          const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
          if (fileName === "") return null;
          const baseName = nombreABuscar.replace(/\d+$/, ""); // Extrae el nombre base
          const nameRegex = new RegExp(`^${baseName}`, "i"); // Expresión regular insensible a mayúsculas y minúsculas
          if (nameRegex.test(fileName)) {
            const fileResponse = await fetch(fileUrl, {
              headers: {
                Authorization: `Basic ${customBtoa(username + ":" + password)}`,
              },
            });
            const fileBlob = await fileResponse.blob();
            const fileExt = fileName.substring(fileName.lastIndexOf(".") + 1);
            return { fileName, fileResponse, fileExt, fileBlob };
          } else {
            return null;
          }
        })
      );
      return fileList.filter((file) => file !== null);
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

/*ESTE APARTADO MOSTRARÁ UN FRAGMENTO DE CÓDIGO DE USO. LA IMPLEMENTACIÓN DEPENDARÁ SEGUN LO REQUERIDO PARA CADA 
MÓDULO

    //Estados para crear folder
    const [folderFolio, setFolderFolio] = React.useState("")
    //Estados para descargar archivos del folder
    const [folio, setFolio] = React.useState("")
    //Esatdos para cargar documentos a folder
    const [selectDocs, setSelectDocs] = React.useState([])
    const [cargarFolio, setCargarFolio] = React.useState("")


    // Esta funciún descargará el archivo, llamando a la función getFiles(folio) quien se asegurará de hacer la programación para realizar la petición fetch
    const descargarArchivos = async () => {
        var response = await getFiles(folio)
        if (!response) {//Si la respuesta es falsa es porque no existe una carpeta con ese folio
            alert("No existe carpeta con ese folio")
        } else {//En caso contrario, revisará que la carpeta tenga archivos para descargarlos, si no, mandar alerta, por ejemplo.
            console.log(response);
            if (response.length > 0) {
                response.map((e) => {
                    downloadBlob(e.fileBlob, e.fileName)
                })
            } else {
                alert("La carpeta está vacía.")
            }
        }
    }

    //Esta función permite crear una carpeta dentro de nextcloud
    const crearCarpetaFolio = async () => {
        if (folderFolio !== "") {
            var response = await createFolderFolio(folderFolio)//Se llama a la función createFolderFolio(folderFolio).
            if (response) {//Si la respuesta es true, manda mensaje se exito
                alert('Carpeta ' + folderFolio + ' creada exitosamente');
            } else {//Si no, manda mensaje informando el estado de la operación. Por ejemplo
                alert('Ocurrió un error al crear la carpeta');
                throw new Error('Ocurrió un error al crear la carpeta');
            }
        } else {
            alert("Ingresa un folio válido.")
        }
    }

    //Esta funcion permite cargar archivos a nextcloud
    const cargarArchivos = async () => {
        if (cargarFolio !== "" && selectDocs.length > 0) {
            var response = await uploadFilesToNextcloud(cargarFolio, selectDocs) //Se llama a la funcion uploadFilesToNextCloud(folio,docs)
            if (response) {//Si la respuesta es true, mandar mensaje de exito. 
                alert('Archivo cargado  exitosamente');
            } else {//Si no, manda mensaje informando el estado de la operación. Por ejemplo
                alert('Ocurrió un error al archivo');
                throw new Error('Ocurrió un error al archivo');
            }
        } else {
            alert("Ingresa un folio válido o selecciona archivos.")
        }
    }


    <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: '100px', textAlign: 'center' }} spacing={3}>
                    <Grid item xs={12}>NEXTCLOUD</Grid>
                    <Grid item  ><TXTField label="Número de folio" onChange={(e) => setFolio(e.target.value)} /><ButtonVino onClick={() => descargarArchivos()} label="descarga de archivos" /></Grid>
                    <Grid item  ><TXTField label="Número de folio" onChange={(e) => setFolderFolio(e.target.value)} /><ButtonVino onClick={() => crearCarpetaFolio()} label="Crear carpeta" /></Grid>
                    <Grid item xs={4} >
                        <TXTField label="Número de folio" onChange={(e) => setCargarFolio(e.target.value)} />

                        <BTUpload style={{ marginRight: '10px' }} accept='.pdf, .jpg, .MP4, .MOV, .AVI' id="bt-upload1" multiple={true} onChange={(e) => {
                            var files = e.target.files
                            for (let i = 0; i < files.length; i++) {
                                let file = files[i];
                                // Realiza la validación del archivo aquí
                                if (file.size > 2 * 1024 * 1024) {
                                    console.log("El tamaño máximo permitido es de 2 MB.");
                                    // Elimina el archivo del arreglo si no pasa la validación
                                    files = [].slice.call(files, 0, i).concat([].slice.call(files, i + 1));
                                    i--;
                                }
                            }
                            // Actualiza el arreglo de archivos sin agregar el archivo que sobrepasa el límite 
                            setSelectDocs(files)
                        }} />
                        <ButtonVino onClick={() => cargarArchivos()} label="Subir archivos" /></Grid>
                </Grid>
 */

function customBtoa(str) {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    })
  );
}
