import React from "react";
import CompDictamenTec from "../../../components/Dictamen/CompDictamenTec";



export function DictamenTecnico() {
    return (
        <div>
            
            <CompDictamenTec />
         
        </div>
    );
}