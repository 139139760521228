import React from "react";
import { CompLogin } from '../../../components/Login/CompLogin';
import  Nav  from '../../../components/Tools/Header/Nav';
import  Footer  from "../../../components/Tools/Footer/Footer";


export function Login() {
    return (
        <div>
            <Nav />
            <CompLogin />
            <Footer />
        </div>
    );
}