import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./css/tema";
import { Navigation } from "./routes/Navigation";
import "./css/Typography.css";
import { LangProvider } from "./Context/langContext";
import { AuthProvider } from "./Context";
import actualizarRegistros from "./components/Registro/Function/actualizarregistro";

export default function App() {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      const result = await theme();
      setData(result);
    }
    fetchData();
  }, []);
  if (data === null) {
    return <></>;
  }

  return (
    <AuthProvider>
      <LangProvider>
        <ThemeProvider theme={data}>
          <Navigation />
        </ThemeProvider>
      </LangProvider>
    </AuthProvider>
  );
}
