//  David Ruiz 10/2022


import React from 'react';
import IconButton from '@mui/material/IconButton';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { Tooltip } from '@mui/material';
const BTInv=(props)=>{
    return(
        <Tooltip title={props.tooltipTitle}>
        <IconButton aria-label="PersonSearchIcon" color="icons" 
        //aqui va el enlace
        href={props.enlace}
        onClick={props.onClick}
        onChange={props.onChange}
        id={props.id}
        //ojo aqui es disabled
        //true para desabilitar y false para habilitar
        disabled={props.estatus}>
        <PersonSearchIcon />
        </IconButton>
        </Tooltip>
         ); 
    }

export default BTInv ;