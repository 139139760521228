import { Contable } from "../../pages/Client/ModContable/Contable";
import { ClientLayaout } from '../../layouts/Client/ClientLayaout';
//import { LoginLayaout } from "../../layouts/Login/LoginLayout";


const routesClient = [

    {
        path: "/contable",
        layout: ClientLayaout,
        component: Contable,
        exact: true,
    },
    
];

export default routesClient;