//Este componente sirve para mostrar el tiempo modular de cada modulo del proyecto sinay
//se diseño a parte para evitar tener demasiado codigo
import React, { useState, useEffect } from "react";
//importacion del tema
import "../../../css/estilo.css";
//importaciones del idioma al componente
import { FormattedMessage } from "react-intl";
//importacion herramientas MUI y componentes estandarizados
import { Container } from "@mui/system";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import CircleProgressWithLabel from "../../Tools/Progress/CircleLoading";
import CircularProgress from "@mui/material";
import DialogButtonWF from "../../Tools/Dialog/DialogButtonWF";
import { BASE_API } from "../../../utils/constans";
import { getToken } from "../../../api/token";
import {getDataToken} from "../../../api/dataToken";

//importacion de circulos de progreso
import StatusRecepcion from "../Status/StatusRecepcion";
import StatusRegistro from "../Status/StatusRegistro";
import StatusDictamen from "..//Status/StatusDictamen";
import StatusComunicacion from "../Status/StatusComunicacion";
import StatusInvestigacion from "../Status/StatusInvestigacion";
import StatusMedico from "../Status/StatusMedico";
import { useLocation } from "react-router-dom";

export default function CirculosDeProgreso() {

  const locationcircul = useLocation();
  const circularinfo = locationcircul.state.folios;
  console.log("apidecirculos", circularinfo);
  const [datos, setDatos] = useState([]);
  const [ponderacionRegistro, setPonderacionRegistro] = useState([]);
  const [ponderacionDictamen, setPonderacionDictamen] = useState([]);
  const [ponderacionMedico, setPonderacionMedico] = useState([]);
  const [ponderacionRecepcion, setPonderacionRecepcion] = useState([]);
  const [ponderacionInvestigador, setPonderacionInvestigador] = useState([]);
  const [ponderacionComunicacion, setPonderacionComunicacion] = useState([]);
  
   
//  Funcion del api para obtener las taras por modulo 
const token = getToken();
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${BASE_API}wktramite/get_listado/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Error al obtener datos');
      }
      const data = await response.json();
      // console.log('Datos desde la API:', data.POL_idpoliza?.POL_idpoliza);

      // Verifica el valor de POL_idpoliza
      const idFolio = circularinfo.POL_idpoliza?.POL_idpoliza;
    //  console.log('ID Folio a filtrar:', circularinfo.POL_idpoliza?.POL_idpoliza);

      // Filtra los datos basados en POL_idpoliza
      const datosFiltrados = data.filter((dato) => dato.POL_idpoliza?.POL_idpoliza === idFolio);
      // console.log('Datos filtrados:', datosFiltrados);
{/* Recepcion*/}
const datosFiltradosrecepcion = data.filter((dato) => (
  dato.POL_idpoliza?.POL_idpoliza === idFolio &&
  dato.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_idwfmodulo === 1
));
// console.log('Datos filtrados:', datosFiltradosrecepcion);
// console.log("Datos ponderacion",  datosFiltradosrecepcion.map(item => item?.WTM_idwftareasxmodulos?.TARE_idtarea?.TARE_ponderación));
let datosrecpcion = datosFiltradosrecepcion.map(item => item?.WTM_idwftareasxmodulos?.TARE_idtarea?.TARE_ponderación);
setPonderacionRecepcion(datosrecpcion);
// console.log("Datos almacenados en el estado ponderacionMedico", ponderacionRegistro);

{/* Registro*/}
      const datosFiltradosregistro = data.filter((dato) => (
        dato.POL_idpoliza?.POL_idpoliza === idFolio &&
        dato.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_idwfmodulo === 2
      ));
      // console.log('Datos filtrados:', datosFiltradosregistro);
      // console.log("Datos ponderacion",  datosFiltradosregistro.map(item => item?.WTM_idwftareasxmodulos?.TARE_idtarea?.TARE_ponderación));
      let datosregistro = datosFiltradosregistro.map(item => item?.WTM_idwftareasxmodulos?.TARE_idtarea?.TARE_ponderación);
      setPonderacionRegistro(datosregistro);
      // console.log("Datos almacenados en el estado ponderacionMedico", ponderacionRegistro);
{/* Dictamen*/}
const datosFiltradosdictamen = data.filter((dato) => (
  dato.POL_idpoliza?.POL_idpoliza === idFolio &&
  dato.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_idwfmodulo === 3
));
// console.log('Datos filtrados:', datosFiltradosdictamen);
// console.log("Datos ponderacion", datosFiltradosdictamen.map(item => item?.WTM_idwftareasxmodulos?.TARE_idtarea?.TARE_ponderación));
let datosdictamen = datosFiltradosdictamen.map(item => item?.WTM_idwftareasxmodulos?.TARE_idtarea?.TARE_ponderación);
  setPonderacionDictamen(datosdictamen);
  // console.log("Datos almacenados en el estado ponderacionMedico", ponderacionMedico);

{/* Medico*/}
      const datosFiltradosmedico = data.filter((dato) => (
        dato.POL_idpoliza?.POL_idpoliza === idFolio &&
        dato.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_idwfmodulo === 4
      ));
      // console.log('Datos filtrados:', datosFiltradosmedico);
      // console.log("Datos ponderacion", datosFiltradosmedico.map(item => item?.WTM_idwftareasxmodulos?.TARE_idtarea?.TARE_ponderación));
      let datosmedicos = datosFiltradosmedico.map(item => item?.WTM_idwftareasxmodulos?.TARE_idtarea?.TARE_ponderación);
        setPonderacionMedico(datosmedicos);
        // console.log("Datos almacenados en el estado ponderacionMedico", ponderacionMedico);
{/* Investigador*/}
const datosFiltradosinvestigador = data.filter((dato) => (
  dato.POL_idpoliza?.POL_idpoliza === idFolio &&
  dato.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_idwfmodulo === 5
));
// console.log('Datos filtrados:', datosFiltradosinvestigador);
// console.log("Datos ponderacion", datosFiltradosinvestigador.map(item => item?.WTM_idwftareasxmodulos?.TARE_idtarea?.TARE_ponderación));
let datosinvestigador = datosFiltradosinvestigador.map(item => item?.WTM_idwftareasxmodulos?.TARE_idtarea?.TARE_ponderación);
  setPonderacionInvestigador(datosinvestigador);
  // console.log("Datos almacenados en el estado ponderacionMedico", ponderacionMedico);

{/* Comunicacion con el cliente */}
const datosFiltradoscomunicacion = data.filter((dato) => (
  dato.POL_idpoliza?.POL_idpoliza === idFolio &&
  dato.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_idwfmodulo === 6
));
// console.log('Datos filtrados:', datosFiltradoscomunicacion);
// console.log("Datos ponderacion", datosFiltradoscomunicacion.map(item => item?.WTM_idwftareasxmodulos?.TARE_idtarea?.TARE_ponderación));
let datoscomunicacion = datosFiltradoscomunicacion.map(item => item?.WTM_idwftareasxmodulos?.TARE_idtarea?.TARE_ponderación);
  setPonderacionComunicacion(datoscomunicacion);

// Fin de area de ponderacion

      setDatos(datosFiltrados, datosFiltradosrecepcion, datosFiltradosregistro, datosFiltradosmedico, datosFiltradosdictamen, datosFiltradosinvestigador, datosFiltradoscomunicacion);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  fetchData();
}, [token, circularinfo.POL_idpoliza]);
// console.log('Datos finales:', datos.map(item => item?.WTM_idwftareasxmodulos?.TARE_idtarea?.TARE_ponderación));

function sumaArray(arr) {
  let suma = 0;
  for (const elemento of arr) {
    suma += elemento;
  }
  return suma;
}
const resultadomedico = sumaArray(ponderacionMedico);
const resultadoregistro = sumaArray(ponderacionRegistro);
const resultadodictamen = sumaArray(ponderacionDictamen);
const resultadorecepcion = sumaArray(ponderacionRecepcion);
const resultadoinvestigador = sumaArray(ponderacionInvestigador);
const resultadocomunicacion = sumaArray(ponderacionComunicacion);
// console.log("sumamedico",resultado);
// console.log("sumaregistro",resultadoregistro);
  return (

    <div className="div-parent">
      {/**este div agrega un contenedor aqui colocaremos los componentes y elementos que necesitemos */}
      <div className="div-container">
        <Grid container>
          <br />
          {/*aqui agregaremos circulos de progreso de los modulos de sinay */}
          <Grid xs={2}>
          <CircleProgressWithLabel initialValue={resultadorecepcion} />
            <br />
            <DialogButtonWF
              label={
                <FormattedMessage id="globRecepc" defaultMessage="Recepción" />
              }
              description={
                <Container>
                  <Box sx={{ width: "100%" }}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid xs={12}>
                        <StatusRecepcion />
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              }
              button="Cerrar"
            />
          </Grid>
          {/**progres registro */}
          <Grid xs={2}>
          <CircleProgressWithLabel initialValue={resultadoregistro} />
            <br />
            <DialogButtonWF
              label={
                <FormattedMessage id="globRegist" defaultMessage="Registro" />
              }
              description={
                <Container>
                  <Box sx={{ width: "100%" }}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid xs={12}>
                        <StatusRegistro />
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              }
              button="Cerrar"
            />
          </Grid>
          {/**progreso disctamen */}
          <Grid xs={2}>
          <CircleProgressWithLabel initialValue={resultadodictamen} />
            <br />
            <DialogButtonWF
              label={
                <FormattedMessage id="menuDictam" defaultMessage="Dictamen" />
              }
              description={
                <Container>
                  <Box sx={{ width: "100%" }}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid xs={12}>
                        <StatusDictamen />
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              }
              button="Cerrar"
            />
          </Grid>

          {/**progreso medico */}
          <Grid xs={2}>
          <CircleProgressWithLabel initialValue={resultadomedico} />
            <br />
            <DialogButtonWF
              label={
                <FormattedMessage id="globMedico" defaultMessage="Médico" />
              }
              description={
                <Container>
                  <Box sx={{ width: "100%" }}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid xs={12}>
                        <StatusMedico />
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              }
              button="Cerrar"
            />
          </Grid>
          {/**progreso investigacion */}
          <Grid xs={2}>
          <CircleProgressWithLabel initialValue={resultadoinvestigador} />
            <br />
            <DialogButtonWF
              label={
                <FormattedMessage
                  id="menuInvest"
                  defaultMessage="Investigación"
                />
              }
              description={
                <Container>
                  <Box sx={{ width: "100%" }}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid xs={12}>
                        <StatusInvestigacion />
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              }
              button="Cerrar"
            />
          </Grid>
          {/**progreso comunicacion */}
          <Grid xs={2}>
          <CircleProgressWithLabel initialValue={resultadocomunicacion} />

            <br />
            <DialogButtonWF
              label={
                <FormattedMessage
                  id="globCoClie"
                  defaultMessage="Comunicación con el cliente"
                />
              }
              description={
                <Container>
                  <Box sx={{ width: "100%" }}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid xs={12}>
                        <StatusComunicacion />
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              }
              button="Cerrar"
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}