import React from "react";
import CompContable from '../../../components/Contable/CompContable';


export function Contable() {
    return (
        <div>
     
            <CompContable />
     
        </div>
    );
}