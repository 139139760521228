/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import Paper from "@mui/material/Paper";
import DialogEliminarBene from "../Dialogs/DialogEliminarBene";
import { Grid } from "@mui/material";
import moment from "moment/moment";

//Dialogs
import DialogEditarBene from "../Dialogs/DialogEditarBene";
import DialogAgregarBene from "../Dialogs/DialogAgregarBeneficiario";
//idioma
import { FormattedMessage } from "react-intl";
import { calculateAge } from "../../../../utils/functions";
import { getDataToken } from "../../../../api/dataToken";
import { useState } from "react";
import { PERR_BENEFICIARIO } from "../../../../utils/constanstoken";
import { formatofecha } from "../../../../api/getUmbrales";
import { useEffect } from "react";

export default function TableBeneficiarios(props) {
  // CONSTANTES / ESTADOS / VARIABLES
  const idFolio = props.idFolio;
  const idPoliza = props.idPoliza;
  const [dataBene, setDataBene] = useState(props.beneficiarios);
  //FECHA ACTUAL
  var today = new Date();

   /* Funcion para actualizar los datos de los beneficiarios en la tabla */
   const getDataPersonasXPoliza = async () => {
    try {
      let response = await getDataToken(`personasxpoliza/get_poliza/${idPoliza}/`)
      if (response) {
        /** Buscar los beneficiarios de la póliza */
        const beneficiarios = response.filter((persona) => persona?.PER_idpersona?.PERR_idrol?.PERR_idrol == PERR_BENEFICIARIO.id);

        if (beneficiarios.length > 0)
          setDataBene(beneficiarios)
        else
          setDataBene([])
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  useEffect(()=>{},[])
  //Cobertura por folio 
  const [cobertura, setCobertura] = React.useState([]);

  const getPolizaCoberturasXTProductos = async () => {
    try {
      let result = await getDataToken('polizascoberturasporproductos/get_listado/');
      if (result) {
        let coberturaBuscada = result.find(function (objeto) {
          return objeto?.RECT_idfolio.RECT_idfolio == idFolio
        });
        if (coberturaBuscada) {
          setCobertura(coberturaBuscada);
        }
      }
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }
   React.useEffect(() => {
    getPolizaCoberturasXTProductos();
   },[])
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Paper style={{ width: "100%" }}>
            <TableContainer style={{ position: "sticky" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                    <StyledTableCell>
                      <FormattedMessage
                        id="globNombre"
                        defaultMessage="Nombre"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage
                        id="globParent"
                        defaultMessage="Parentesco"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage id="globRfcp" defaultMessage="RFC" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage
                        id="globFechNa"
                        defaultMessage="Fecha de nacimiento"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="dictEdadActual"
                        defaultMessage="Edad actual"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="dictEdadTra"
                        defaultMessage="Edad al trámite"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="globRazSoc"
                        defaultMessage="Razón social"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="dictClabeInte"
                        defaultMessage="Clabe interbancaria"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="dictamCuentaBancaria"
                        defaultMessage="Cuenta bancaria"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="dictamBanc"
                        defaultMessage="Banco"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="dictamPorc"
                        defaultMessage="Porcentaje"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="globEditar"
                        defaultMessage="Editar"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="estconElim"
                        defaultMessage="Eliminar"
                      />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
                  {!dataBene? null: dataBene.map((bene) => {
                    return (
                      
                      <TableRow key={bene.PXP_idpersonaxpoliza}>
                        <StyledTableCell component="th" scope="row">
                          {`${bene?.PER_idpersona?.PER_nombre} ${bene?.PER_idpersona?.PER_apellidopaterno} ${bene.PER_idpersona?.PER_apellidomaterno}`}
                        </StyledTableCell>
                        <StyledTableCell>{bene?.PER_idpersona?.PAR_idparentesco?.PAR_parentesco}</StyledTableCell>
                        <StyledTableCell>{bene?.PER_idpersona?.PER_RFC}</StyledTableCell>
                        <StyledTableCell>{moment(bene?.PER_idpersona?.PER_fechanacimiento).format(formatofecha)}</StyledTableCell>
                        <StyledTableCell>{calculateAge(today.toISOString(), bene?.PER_idpersona?.PER_fechanacimiento)}</StyledTableCell>
                        <StyledTableCell>{bene?.PER_idpersona?.PER_edadtramite}</StyledTableCell>
                        <StyledTableCell>{""}</StyledTableCell>
                        <StyledTableCell>{bene?.PER_idpersona?.PER_clabe}</StyledTableCell>
                        <StyledTableCell>{bene?.PER_idpersona?.PER_numerocuentabancaria}</StyledTableCell>
                        <StyledTableCell>{""}</StyledTableCell>
                        <StyledTableCell>{bene?.PXP_porcentajebeneficiario}</StyledTableCell>
                        <StyledTableCell align="center">
                          <DialogEditarBene beneficiarios={dataBene} idBene={bene.PXP_idpersonaxpoliza} idFolio={idFolio} cobertura={cobertura} response={(e)=>getDataPersonasXPoliza()}/>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <DialogEliminarBene infoDataRow={bene} idBenePxP={bene.PXP_idpersonaxpoliza} idBene={bene?.PER_idpersona?.PER_idpersona} idPoliza={bene?.POL_idpoliza?.POL_idpoliza} response={(e)=>getDataPersonasXPoliza(e)}/>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item>
          <br />
          <DialogAgregarBene rows={dataBene} beneficiarios={dataBene} cobertura={cobertura} idPoliza={idPoliza} response={(e)=>getDataPersonasXPoliza()}/>
        </Grid>
      </Grid>
    </>
  );
}
