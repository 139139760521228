import React from "react";
import  { Headerlayout } from '../../components/Tools/Header/Headerlayout';


export function Barras(){
    return(
        <div>
                    <h1>NAV</h1>
                    <Headerlayout />
        </div>
    );
}
