import * as React from "react";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { putDataToken } from "../../../../api/dataToken";

export default function GestorArch() {
  const [username, setUsername] = useState('');
  const [usernameValid, setUsernameValid] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [puert, setPuert] = useState("");
  const [url, setUrl] = useState("");
  const [urlVal, setURLVal] = useState(false); //Este constante se ocupara para validar la url al ingresarla
  const [path, setPath] = useState('');
  const [pathVal, setPathVal] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const info = {
      SCG_DOCURL: url,
      SCG_DOCPuerto: puert,
      SCG_usernextcloud: username,
      SCG_passnextcloud: password,
      SCG_pathnextcloud: path,
    };
    putDataToken("configuraciongeneral/get_id/1/", info)
      .then((data) => {
        console.log("Respuesta del servidor:", data);
        // setOpenDialog(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  // Area para validar el puerto del sistema
  const handlePuertoChange = (event) => {
    let value = event.target.value;
    if (parseInt(value) >= 0 && value !== "") {
      setPuert(parseInt(value));
    } else if (value === "") {
      setPuert(0);
    }
  };
  // Area para validar la direccion URL
  const handleURLChange = (event) => {
    setUrl(event.target.value);
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    if (event.target.value === "") {
      setURLVal(false);
    } else {
      setURLVal(regex.test(event.target.value));
    }
  };
  // Area para validar el Usuario
  const handleUsernameChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z0-9_]{3,20}$/; // Expresión regular para validar el nombre de usuario
    setUsername(value);
    setUsernameValid(regex.test(value));
  };
  // Area para validar el password
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setIsValidPassword(validatePassword(newPassword));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (password) => {
    // Aquí implementa tu lógica de validación para la contraseña
    // Por ejemplo, verifica si cumple con ciertos requisitos
    return password.length >= 8; // Ejemplo: Mínimo 8 caracteres
  };

  // Area para el Path
  const handlePathChange = (event) => {
    setPath(event.target.value);
    const regex = /^(\/[^/]+)*$/;
    if (event.target.value === '') {
      setPathVal(false);
    } else {
      setPathVal(regex.test(event.target.value));
    }
  };
  return (
    <div className="div-container">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} xs={12}>
          {/* Area de cards para el url */}
          <Grid item xs={4}>
            <Card>
              <CardHeader
                title="URL Gestor de Archivos"
                subheader="Ingresa el URL de sistema de Archivos"
              />
              <CardContent xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="https://files.sinay.com.mx/"
                      type="text"
                      value={url}
                      onChange={handleURLChange}
                    />
                    {urlVal ? (
                      <p style={{ color: "green", fontSize: 10 }}>
                        La url es valida
                      </p>
                    ) : (
                      <p style={{ color: "red", fontSize: 10 }}>
                        La url es invalida
                      </p>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {/* Area de cards para el puerto*/}
          <Grid item xs={4}>
            <Card>
              <CardHeader title="Puerto" subheader="Ingresa el Puerto " />
              <CardContent xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="80"
                      type="text"
                      value={puert}
                      onChange={handlePuertoChange}
                    />
                    {puert ? (
                      <p style={{ color: "green", fontSize: 10 }}>
                        El puerto es valid
                      </p>
                    ) : (
                      <p style={{ color: "red", fontSize: 10 }}>
                        El puerto es invalido
                      </p>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {/* Area para el usuario */}
          <Grid item xs={4}>
            <Card>
              <CardHeader title="Usuario" subheader="Ingresa el Usuario " />
              <CardContent xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Usr_sinay@qait.com.mx"
                      type="text"
                      value={username}
                      onChange={ handleUsernameChange}
                    />
                    {usernameValid ? (
                      <p style={{ color: "green", fontSize: 10 }}>
                        El usuario es valido
                      </p>
                    ) : (
                      <p style={{ color: "red", fontSize: 10 }}>
                        El usuario es invalido
                      </p>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {/* Area para la nueva contraseña */}
          <Grid item xs={4}>
            <Card>
              <CardHeader
                title="Contraseña"
                subheader="Ingresa una nueva contraseña"
              />
              <CardContent xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Contraseña"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={handlePasswordChange}
                      InputProps={{
                        endAdornment: (
                          <IconButton onClick={togglePasswordVisibility}>
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        ),
                      }}
                    />
                    {isValidPassword ? (
                      <p style={{ color: "green", fontSize: 10 }}>
                        La contraseña es válida
                      </p>
                    ) : (
                      <p style={{ color: "red", fontSize: 10 }}>
                        La contraseña es inválida
                      </p>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
           {/* Area para el usuario */}
           <Grid item xs={4}>
            <Card>
              <CardHeader title="Path" subheader="Ingresa la direccion" />
              <CardContent xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="remote.php/dav/files/usrloader"
                      type="text"
                      value={path}
                      onChange={handlePathChange}
                    />
                    {pathVal ? (
                      <p style={{ color: "green", fontSize: 10 }}>
                        El usuario es valido
                      </p>
                    ) : (
                      <p style={{ color: "red", fontSize: 10 }}>
                        El usuario es invalido
                      </p>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          
          {/* Area para el boton de guardar la informacion */}
          <Grid item xs={9} container>
            <ButtonVino
              variant="contained"
              color="primary"
              size="medium"
              label="Guardar"
              type="submit"
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
