//Brandon Hernandez 01/2023
import React from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

/* props.tooltipTitle es para colocar el titulo de la ayuda al pasar por encima del ícono.
    props.to funciona igual que un href, solo que ésta enviará datos a la ruta destino por medio de otra prop (state)
    props.state recibira un arreglo de datos
*/
const IconEliminar=(props)=>{
    return(
      <Tooltip title={props.tooltipTitle}>
        {/*<Link to={props.to} state= {props.state}  >
          <IconButton  
            color="icons"
            href={props.href}
            disabled={props.disabled}
            onClick={props.onClick}
            onChange={props.onChange}
            id={props.id}
          >
            <PaymentIcon color="icons"/>
          </IconButton> 
        </Link>*/}
        <IconButton  
            color="icons"
            href={props.href}
            disabled={props.disabled}
            onClick={props.onClick}
            onChange={props.onChange}
            id={props.id}
          >
            <DeleteOutlineOutlinedIcon color="icons" style={{paddingRight:'5px'}}/>
            <h2-dialog-description>{props.iconTitle}</h2-dialog-description>
          </IconButton>
      </Tooltip>
        )
     ;
    }
export default IconEliminar;