/* Brandon Hernandez Rocha 11/2022 */
import React from "react";
import CustomSeparator from "../Tools/BreadCrumbs/Recorrido";
import "../../css/estilo.css";
import TableCharola from "./Elements/Tables/TablaCharola";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import { Badge, Grid } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
//idioma
import { FormattedMessage } from "react-intl";
import ButtonVino from "../Tools/Bottones/ButtonVino";
import { Link } from "react-router-dom";
const CompDictamen = () => {
  return (
    <div className="div-parent">
      {/* <Alert severity="warning">
        <AlertTitle>
          <strong>Módulo en construcción</strong>
        </AlertTitle>
      </Alert> */}
      <div className="div-breadcrumbs">
        <CustomSeparator
          enlace="/menu"
          name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
          name2={<FormattedMessage id="menuDictam" defaultMessage="Dictamen" />}
        ></CustomSeparator>
      </div>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <ReturnPage to="/menu" />
      </Grid>
      <div className="div-container">
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start" style={{ marginBottom: '20px' }} spacing={2}>
          <Grid item><Link style={{ textDecoration: 'none' }} to="/dictamen/tramites_autorizados/"><Badge color="warning" badgeContent={2} style={{  zIndex: 1 }} ><ButtonVino color="secondary" label={<FormattedMessage id="tramAuto" defaultMessage="Trámites autorizados" />} /></Badge></Link></Grid>
          {/* <Grid item><Link style={{ textDecoration: 'none' }} to="/dictamen/reactivar/"><Badge color="warning" badgeContent={2}><ButtonVino color="secondary" label={<FormattedMessage id="tramAuto" defaultMessage="Reactivar folio" />} /></Badge></Link></Grid> */}
        </Grid>
        <TableCharola />
      </div>
    </div>
  );
};
export default CompDictamen;
