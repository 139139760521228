/* Brandon Hernandez Rocha 11/2022 */
import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import { FormattedMessage } from "react-intl";
import "../../dictamen.css"
import { useState } from "react";
import { Select1 } from '../../../Tools/Selectss/Select1';
//Textfleild
import TXTField from '../../../Tools/TextField/TextField';
import { MONEDA_NACIONAL, PERR_ASEGURADO, TRAM_BENEFICIOSEV, TRAM_FALLECIMIENTO, TRAM_RESCATES, TRAM_RETIROS, TRAM_VENCIMIENTO } from '../../../../utils/constanstoken';
import { getDataToken } from '../../../../api/dataToken';
import { formatNumberComa } from '../../../../utils/functions';

export function CardDetallePago(props) {
    const polFolSin = props.polFolSin;
    console.log("PolFolSin", polFolSin)
    //
    const [dataAse, setDataAse] = React.useState(null);
    const [dataReserPolCob, setDataReserPolCob] = React.useState([]);

    React.useEffect(() => {
        if (props.dataCob) console.log("Data cob", props.dataCob)
    }, [props.dataCob])

    const getDataApiPolizaxFolioxSin = async () => {
        try {
            let result = await getDataToken("personasxpoliza/get_poliza/" + polFolSin.POL_idpoliza.POL_idpoliza + "/");
            let response2 = await getDataToken(`reservasporpolizaporcoberturas/get_listado/`)
            let response3 = await getDataToken(`polizascoberturasporproductos/get_folio/${polFolSin.RECT_idfolio.RECT_idfolio}/`)

            if (result) {
                const asegurado = result.filter((persona) => persona?.PER_idpersona?.PERR_idrol?.PERR_idrol == PERR_ASEGURADO.id);
                //Conservamos un arreglo original de la api
                setDataAse(asegurado);
            }
            if (response2) {
                //TO DO: RECORREC EL ARREGLO DE LA RESPUESTA DE LA API reservasporpolizaporcoberturas (CREAR UN FILTRO DESDE EL BACK?)

                let filtro = response2.find((obj) => obj?.CPT_idcoberturapolizatramite?.POL_idpoliza?.POL_idpoliza === polFolSin?.POL_idpoliza?.POL_idpoliza)
                setDataReserPolCob(filtro)
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
    //
    const [dataApiPolCobxProd, setDataApiPolCobxProd] = React.useState([])
    const getDataApiPolCobxProd = async () => {
        try {
            let resutl = await getDataToken("polizascoberturasporproductos/get_pol/" + polFolSin.POL_idpoliza.POL_idpoliza + "/");
            if (resutl) {
                //Conservamos un arreglo original de la api
                setDataApiPolCobxProd(resutl[0]);
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
    React.useEffect(() => {
        getDataApiPolizaxFolioxSin();
        getDataApiPolCobxProd();
    }, [])
    return (
        <div className="div-cards">
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
                <Grid item xs={2}>
                    <h3-title-card>
                        <FormattedMessage
                            id="glob.nodefolio"
                            defaultMessage="No. de folio"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{polFolSin?.RECT_idfolio?.RECT_numerofolio}</h3-subtitle-card>
                </Grid>
                <Grid item xs={2}>
                    <h3-title-card>
                        <FormattedMessage
                            id="glob.nopoliza"
                            defaultMessage="No. de póliza"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{polFolSin?.POL_idpoliza?.POL_numpoliza}</h3-subtitle-card>
                </Grid>
                <Grid item xs={3}>
                    <h3-title-card>
                        <FormattedMessage
                            id="glob.nomasegurado"
                            defaultMessage="Nombre del asegurado"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{!dataAse ? null : `${dataAse[0]?.PER_idpersona?.PER_nombre} ${dataAse[0]?.PER_idpersona?.PER_apellidopaterno}  ${dataAse[0]?.PER_idpersona?.PER_apellidomaterno}`}</h3-subtitle-card>
                </Grid>
                <Grid item xs={2}>
                    <h3-title-card>
                        <FormattedMessage
                            id="glob.nodefolio"
                            defaultMessage="No. de siniestro"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{polFolSin?.SIN_idsiniestro?.SIN_numerosiniestro}</h3-subtitle-card>
                </Grid>
                <Grid item xs={2}>
                    <h3-title-card>
                        <FormattedMessage
                            id="glob.reclamacion"
                            defaultMessage="Reclamación"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{polFolSin?.RECT_idfolio?.SINC_idcomplejidadsiniestro?.TRA_destipotramitesiniestro}</h3-subtitle-card>
                </Grid>
                <Grid item xs={2}>
                    <h3-title-card>
                        <FormattedMessage
                            id="glob.monedanacional"
                            defaultMessage="Moneda nacional"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{MONEDA_NACIONAL.abreviatura}</h3-subtitle-card>
                </Grid>
                <Grid item xs={3}>
                    <h3-title-card>
                        <FormattedMessage
                            id="glob.cobertura"
                            defaultMessage="Cobertura"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{!props.dataCob ? "" : props.dataCob.CPT_idcoberturapolizatramite?.COB_idcobertura?.COB_cobertura}</h3-subtitle-card>
                </Grid>
                <Grid item xs={3}>
                    <h3-title-card>
                        <FormattedMessage
                            id="glob.monedaCob"
                            defaultMessage="Moneda cobertura"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{!props.dataCob ? "" : props.dataCob.CPT_idcoberturapolizatramite?.MON_idmoneda?.MON_abreviatura}</h3-subtitle-card>
                </Grid>

                <Grid item xs={3}>
                    <h3-title-card>
                        <FormattedMessage
                            id="glob.importeReserva"
                            defaultMessage="Importe reserva"
                        />
                    </h3-title-card>
                    <h3-subtitle-card>{formatNumberComa(!props.dataCob ? "" : (props.dataCob?.RPC_importeReserva + props.dataCob?.RPC_importeReservaAjustes).toFixed(2))}</h3-subtitle-card>
                </Grid>


            </Grid>
        </div>
    )
}