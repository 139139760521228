import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

/* ESTILOS DE LA TABLA */
const StyledTableCell2 = styled(TableCell)(
  ({ theme, paddingRight, paddingLeft, minWidth }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#00203B",
      padding: "5px",
      paddingLeft: "5px",
      paddingRight: "5px",
      textAlign: "center",
      height: "30px",
      color: theme.palette.common.white,
      fontSize: "16px",
      borderColor: "#e2e2e2",
      border: "1px solid #e2e2e2",
      fontFamily: "Avenir-Light",
      minWidth: minWidth ? minWidth : "auto",
    },
    [`&.${tableCellClasses.body}`]: {
      height: "16px",
      padding: 0,
      paddingRight: paddingRight ? paddingRight : "30px",
      paddingLeft: paddingLeft ? paddingLeft : "30px",
      fontSize: "5px",
      borderColor: "#e2e2e2",
      border: "1px solid #e2e2e2",
      fontFamily: "Avenir-Light",
    },
  })
);

export default StyledTableCell2;
