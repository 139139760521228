//import { Inicio } from "../../pages/Client/ModInicio/Inicio";
//import { ClientLayaout } from '../../layouts/Client/ClientLayaout';

import { Inicio } from "../../pages/Client/ModInicio/Inicio";
import { ClientLayaout } from "../../layouts/Client/ClientLayaout";

const routesClient = [

    {
        path: "/menu",
        layout: ClientLayaout,
        component: Inicio,
        exact: true,
    },
];

export default routesClient;