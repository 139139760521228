import React, { useState,useEffect } from "react";
import { Grid } from "@mui/material";
import TXTField from "../../Tools/TextField/TextField";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import DialogCascadeTwo from "../../Tools/Dialog/DialogCascadeTwo";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
//importacion apis
import {getDataToken} from "../../../api/dataToken";
import "../Contable.css";
import Btverreport from "../../Tools/Bottones/BTAgregarDocumento"
import DialogIconDeleteConfirm from "../../Tools/Dialog/DialogIconDeleteConfirm";
//Idioma
import { FormattedMessage } from "react-intl";
//funciones de validaciones
import { LimitarTexto } from "../../Recepcion/Elements/Functions/Validaciones";
import { LimitarDigitos } from "../../Recepcion/Elements/Functions/Validaciones";

export default function FormsAccountingEntries() {
  //Declaración de variables que se usaran en el formulario y para los mensajes
  const [cuentaMayorDeber, setCuentaMayorDeber] = useState("");
  const [cuentaMayorHaber, setCuentaMayorHaber] = useState("");
  const [subcuentaDeber, setSubcuentaDeber] = useState("");
  const [subcuentaHaber, setSubcuentaHaber] = useState("");
  const [subSubCuentaDeber, setSubSubCuentaDeber] = useState("");
  const [subSubCuentaHaber, setSubSubCuentaHaber] = useState("");
  const [naturalezaDeber, setNaturalezaDeber] = useState("");
  const [naturalezaHaber, setNaturalezaHaber] = useState("");
  const [cuentaDeber, setCuentaDeber] = useState("");
  const [cuentaHaber, setCuentaHaber] = useState("");

  console.log(subSubCuentaDeber, 'subsubcuenta')

  const [datosCuentasMayor, setDatosCuentasMayor] = useState({}
  //   {
  //   cuentaMayorDeber: "",
  //   cuentaMayorHaber: "",
  //   subcuentaDeber: "",
  //   subcuentaHaber: "",
  //   subSubCuentaDeber: "",
  //   subSubCuentaHaber: "",
  //   naturalezaDeber: "",
  //   naturalezaHaber: "",
  //   cuentaDeber: "",
  //   cuentaHaber: "",
  // }
  );
  //Valida que el formulario este lleno para poder enviar la informacion
  const [formValid, setFormValid] = useState(false);

  const validateForm = (e) => {
    e.preventDefault();

    console.log(
      cuentaMayorDeber,
      subcuentaDeber,
      subSubCuentaDeber,
      cuentaDeber,
      naturalezaDeber
    );
    console.log(
      cuentaMayorHaber,
      subcuentaHaber,
      subSubCuentaHaber,
      cuentaHaber,
      naturalezaHaber
    );
  };

  const [datosCuentasMayor2, setDatosCuentasMayor2] = useState([]);

  //------TRAE información para cuentas cargo
  //Creación de metodo get
  const [porCuentas, setPorCuentas] = useState([]);
  const getDataCuentas = async () => {
    try {
      var result = await getDataToken("cuentas/get_listado/");
      setPorCuentas(result)
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  
  useEffect(() => {
    getDataCuentas();
    console.log('Entre al UseEffect')
  }, []);

  const handleSelectCuentas = async (event, id) => {
    try {
      const numeroCuentaSeleccionada = event.target.value;
      var result = await getDataToken("cuentas/get_listado/");
      let detallesCuentaSeleccionada = result.find(
        (item) => numeroCuentaSeleccionada === item.CTA_numcuenta
      );

      // Actualiza solo los datos del formulario actual
      setDatosCuentasMayor({
       
        [id]: {
          ...datosCuentasMayor[id],
          // Actualiza aquí los campos según tus necesidades

        },
      });
  
      if (detallesCuentaSeleccionada) {
        setDatosCuentasMayor(detallesCuentaSeleccionada);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const [entradas, setEntradas] = useState([
    { id: 1}
  ]);

  

  const agregarEntrada = () => {
    
    const valorNuevo = entradas.length > 0 ? entradas[entradas.length - 1].id + 1 : 1;
    console.log(porCuentas.SSCTA_idsubsubcuenta?.SSCTA_numsubsubcuenta, 'este es el valor subsubcuenta' )
    console.log(porCuentas.SCTA_idsubcuenta?.SCTA_numsubcuenta, 'este es el valor subcuenta' )
    console.log(porCuentas.CTAM_idcuentamayor?.CTAM_numcuenta, 'este es el valor numcuentamayor' )

    setEntradas([...entradas, { id: valorNuevo }]);
    var subSubCuentaDeberValue = document.getElementById('subSubCuentaDeber').value;
    subSubCuentaDeberValue = 999;
    setSubSubCuentaDeber(subSubCuentaDeberValue)
    console.log(subSubCuentaDeberValue, 'hola estoy en setSubSubCuentaDeber')

    const ultimoObjeto = entradas[entradas.length - 1];
    const idDelUltimoObjeto = ultimoObjeto.id + 1;

    console.log(idDelUltimoObjeto, valorNuevo , ' estoy antes del if')

     if( idDelUltimoObjeto === valorNuevo ){
      console.log('Entre al IF del nuevo react fragment ', valorNuevo)
      subSubCuentaDeberValue = 999;
       return;
     }

    
  };

  const eliminarEntrada = (id) => {
    const nuevasEntradas = entradas.filter((entrada) => entrada.id !== id);
    setEntradas(nuevasEntradas);

    // Elimina los datos relacionados con el formulario eliminado
    const { [id]: omit, ...nuevosDatos } = datosCuentasMayor;
    setDatosCuentasMayor(nuevosDatos);
  };

   //------TRAE informacion para cuentas credito
  //Creación de metodo get
  const [porCuentasCredito, setPorCuentasCredito] = useState([]);
  const getDataCuentasCredito = async () => {
    try {
      var result = await getDataToken("cuentas/get_listado/");
      console.log("Message: ", result);
      setPorCuentasCredito(result)
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  
  useEffect(() => {
    getDataCuentasCredito();
  }, []);

  const handleSelectCuentasCredito = async (event) => {
    try {
      const numeroCuentaSeleccionadaCredito = event.target.value;
      var result = await getDataToken("cuentas/get_listado/");
      let detallesCuentaSeleccionada = result.find(
        (item) => numeroCuentaSeleccionadaCredito === item.CTA_numcuenta
      );
  
      if (detallesCuentaSeleccionada) {
        setDatosCuentasMayor2(detallesCuentaSeleccionada);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const [entradasCredito, setEntradasCredito] = useState([{ id: 1 }]);
  const agregarEntradaCredito = () => {
  setEntradasCredito([...entradasCredito, { id: entradasCredito.length + 1 }]);
    

  };

  const eliminarEntradaCredito = (id) => {
    const nuevasEntradasCredito = entradasCredito.filter((entrada) => entrada.id !== id);
    setEntradasCredito(nuevasEntradasCredito);
  };
  return (
    // Con validateForm validamos que entren los valores
    <form onSubmit={validateForm}>
      <Grid container justifyContent="center">
        <Grid item>
          <h3-header-card>
            <FormattedMessage
              id="estconAlCo"
              defaultMessage="Alta de asientos contables"
            />
          </h3-header-card>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid xs={12}>
          <h3-subtitle-card>
            Introduzca los datos del asiento contable que será dada de alta:
          </h3-subtitle-card>
        </Grid>
        <Grid item xs={3}>
          <TXTField
            label={
              <FormattedMessage id="estconCont" defaultMessage="Concepto" />
            }
            size="small"
            id="concepto"
          />
        </Grid>
        <Grid item xs={9}></Grid>
        <Grid item xs={12}>
          <h3-subtitle-card>
            <br/>
            <FormattedMessage
              id="estconNaCa"
              defaultMessage="Con naturaleza de cargo"
            />
          </h3-subtitle-card>
        </Grid>
    {entradas.map((entrada) => (
    <React.Fragment key={entrada.id}>
        <Grid item xs={2.2}>
          <div class="espacio">
            <InputLabel id="demo-simple-select-label">
            <h3-subtitle-card>Cuenta</h3-subtitle-card>
            </InputLabel>
            <Select 
              labelId="demo-simple-select-label" 
              onChange={(event) => handleSelectCuentas(event, entrada.id)}
              style={{minWidth: 170}}
            >
              {
                porCuentas.map((item) => {
                  return(
                    <MenuItem 
                      key={item.CTA_numcuenta}
                      value={item.CTA_numcuenta}
                      id="demo-simple-select-label"
                    >
                      { item.CTA_numcuenta}
                    </MenuItem>
                  )
                })
              }
              
            </Select> 
          </div>
        </Grid>
        <Grid item xs={2.2}>
        <h3-subtitle-card>Subsubcuenta</h3-subtitle-card>
          <div class="espacio">
            <TXTField
              onInput={(e) => {
                setSubSubCuentaDeber(e.target.value);
              }}
              name="subSubCuentaDeber"
              id="subSubCuentaDeber"
              size="small"
              disabled={true}
              value={datosCuentasMayor.SSCTA_idsubsubcuenta?.SSCTA_numsubsubcuenta}
            />
          </div>
        </Grid>
        <Grid item xs={2.2}>
          <div class="espacio">
          <h3-subtitle-card><FormattedMessage id="estcoSubCu" defaultMessage="Subcuenta" /></h3-subtitle-card>
            <TXTField
              onInput={(e) => LimitarDigitos(e.target, 2)}
              name="subcuentaDeber"
              id="subcuentaDeber"
              size="small"
              disabled={true}
              value={datosCuentasMayor.SCTA_idsubcuenta?.SCTA_numsubcuenta}
            />
          </div>
        </Grid>
        <Grid item xs={2.2}>
          <div class="espacio">
          <h3-subtitle-card>Cuenta del mayor</h3-subtitle-card>
            <TXTField
              onInput={(e) => LimitarDigitos(e.target, 2)}
        
              name="subcuentaDeber"
              id="subcuentaDeber"
              size="small"
              disabled={true}
              value={datosCuentasMayor.CTAM_idcuentamayor?.CTAM_numcuenta}
            />
          </div>
        </Grid>
        <Grid item xs={2.2}>
          <div class="espacio">
          <h3-subtitle-card><FormattedMessage id="estconNatu" defaultMessage="Naturaleza" /></h3-subtitle-card>
            <TXTField
              disabled={true}
              onInput={(e) => LimitarTexto(e.target, 20)}
              onKeyPress={(e) => {
                const keyCode = e.keyCode || e.which;
                const keyValue = String.fromCharCode(keyCode);
                const regex = /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]+$/;
                if (!regex.test(keyValue)) {
                  e.preventDefault();
                }
              }}
        
              name="naturalezaDeber"
              id="naturalezaDeber"
              size="small"
              value="Cargo"
            /> 
          </div>
        </Grid>
        <Grid item xs={1}>
          <br/>
          <br/>
          <DialogIconDeleteConfirm title={"Eliminar Cargo"} tooltipTitle={"Eliminar Cargo"} description={`¿Estás seguro de eliminar el cargo`} 
          onClickAccept={() => eliminarEntrada(entrada.id)}
          />
          {/* <ButtonVino
           label="Eliminar Cargo"
           onClick={() => eliminarEntrada(entrada.id)}
          /> */}
        </Grid>
        </React.Fragment>
    ))}
        <Grid item xs={12}>
          <br/>
        </Grid>
        <Grid item xs={10.5}></Grid>
        <Grid item xs={1.5}>
          <Btverreport
           label="Agregar Cargo"
           onClick={agregarEntrada}
          />
        </Grid>
        
        
        <Grid item xs={12}>
          <h3-subtitle-card>
            <br/>
            <FormattedMessage
              id="estconNaCa"
              defaultMessage="Con naturaleza de crédito"
            />
          </h3-subtitle-card>
        </Grid>
        {entradasCredito.map((entradaCredito) => (
        <React.Fragment key={entradaCredito.id}>
        <Grid item xs={2.2}>
          <div class="espacio">
            <InputLabel id="demo-simple-select-label">
            <h3-subtitle-card>Cuenta</h3-subtitle-card>
            </InputLabel>
            <Select 
              labelId="demo-simple-select-label" 
              onChange={handleSelectCuentasCredito}
              style={{minWidth: 170}}
            >
              {
                porCuentas.map((item) => {
                  return(
                    <MenuItem 
                      key={item.CTA_numcuenta}
                      value={item.CTA_numcuenta}
                      id="demo-simple-select-label"
                    >
                      { item.CTA_numcuenta}
                    </MenuItem>
                  )
                })
              }
              
            </Select> 
          </div>
        </Grid>
        <Grid item xs={2.2}>
        <h3-subtitle-card>Subsubcuenta</h3-subtitle-card>
          <div class="espacio">
            <TXTField
              disabled={true}
              onInput={(e) => LimitarDigitos(e.target, 3)}
        
              name="subSubCuentaDeber"
              id="subSubCuentaDeber"
              size="small"
              value={datosCuentasMayor2.SSCTA_idsubsubcuenta?.SSCTA_numsubsubcuenta}
            />
          </div>
        </Grid>
        <Grid item xs={2.2}>
          <div class="espacio">
          <h3-subtitle-card><FormattedMessage id="estcoSubCu" defaultMessage="Subcuenta" /></h3-subtitle-card>
            <TXTField
              onInput={(e) => LimitarDigitos(e.target, 2)}
        
              name="subcuentaDeber"
              id="subcuentaDeber"
              size="small"
              disabled={true}
              value={datosCuentasMayor2.SCTA_idsubcuenta?.SCTA_numsubcuenta}
            />
          </div>
        </Grid>
        <Grid item xs={2.2}>
          <div class="espacio">
          <h3-subtitle-card>Cuenta del mayor</h3-subtitle-card>
            <TXTField
              onInput={(e) => LimitarDigitos(e.target, 2)}
        
              name="subcuentaDeber"
              id="subcuentaDeber"
              size="small"
              disabled={true}
              value={datosCuentasMayor2.CTAM_idcuentamayor?.CTAM_numcuenta}
            />
          </div>
        </Grid>
        <Grid item xs={2.2}>
          <div class="espacio">
          <h3-subtitle-card><FormattedMessage id="estconNatu" defaultMessage="Naturaleza" /></h3-subtitle-card>
            <TXTField
              disabled={true}
        
              name="naturalezaDeber"
              id="naturalezaDeber"
              size="small"
              value="Credito"
            /> 
          </div>
        </Grid>
        <Grid item xs={1}>
          <br/>
          <br/>
          <DialogIconDeleteConfirm title={"Eliminar Crédito"} tooltipTitle={"Eliminar Crédito"} description={`¿Estás seguro de eliminar el crédito`} 
           onClickAccept={() => eliminarEntradaCredito(entradaCredito.id)}
         />
          {/* <ButtonVino
           label="Eliminar Credito"
           onClick={() => eliminarEntradaCredito(entradaCredito.id)}
          /> */}
        </Grid>
        </React.Fragment>
        ))}
        <Grid item xs={12}>
          <br/>
        </Grid>
        <Grid item xs={10.5}></Grid>
        <Grid item xs={1.5}>
          <Btverreport
           label="Agregar Crédito"
           onClick={agregarEntradaCredito}
          />
        </Grid>
        
        
      </Grid>

      <Grid container justifyContent="flex-end" spacing={2.4}>
        <Grid item>
          <br />
          <DialogCascadeTwo
            mainButton={
              <FormattedMessage id="regAltaAlt" defaultMessage="Alta" />
            }
            disabledbtn={!formValid}
            type="submit"
            title={
              <FormattedMessage id="GlobEstCon" defaultMessage="Confirmación" />
            }
            description={`Esta apunto de dar de alta un asiento contable nuevo,  `}
            description2="¿está seguro de realizar el cambio?"
            childrenButton={
              <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
            }
            secondChildrenButton="Cancelar"
            childrenTitle={
              <FormattedMessage id="GlobEstCon" defaultMessage="Confirmación" />
            }
            childrenDescription={`El asiento contable se dio de alta exitosamente`}
            firstChildrenButton={
              <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
            }
          />
        </Grid>
      </Grid>
    </form>
  );
}
