/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import Paper from "@mui/material/Paper";
import { Grid, Modal } from "@mui/material";
import DatePicker2 from "../../../Tools/DatePicker/DatePicker2"

//idioma
import { FormattedMessage } from "react-intl";
import TXTField from "../../../Tools/TextField/TextField";
import { Select2 } from "../../../Tools/Selectss/Select2";


//Document
import { COBERTURAS_BASICAS, COBERTURA_FALLE_DEFAULT, ESTATUS_PAGOS, MONEDA_NACIONAL, PERR_ASEGURADO, PERR_BENEFICIARIO, TRAM_FALLECIMIENTO } from "../../../../utils/constanstoken";
import { useState, useEffect } from "react";
import { getCurrencyExchange, getCurrencyExchangeBanxico, getCurrencyExchangeHistorical, getDataToken, postDataToken } from "../../../../api/dataToken";
import { calcularImporteBeneficiario, calcularPorcentajeBeneficiario, formatNumber, formatNumberComa, formatNumberDecimalComa, formatedNumberAndDecimalAndComa, validateNumberAndDecimal } from "../../../../utils/functions";
import { Select1 } from "../../../Tools/Selectss/Select1";
import IconPagos2 from "../../../Tools/Icons/IconPagos2";
import DialogButtons from "../../../Tools/Dialog/DialogButtons";
import DialogConfirm from "../../../Tools/Dialog/DialogConfirm";
import { format } from "date-fns";
import RadioInput from "../../../Tools/RadioButton/RadioTable";

export default function TablaRealizarPagos(props) {
  // CONSTANTES / ESTADOS / VARIABLES
  const tipoTabla = props.tipoTabla;
  const polFolSin = props.polFolSin;
  const row = props.infoFolio;
  const folio = row[0].RECT_idfolio;
  console.log("row: ", row)
  console.log("POLFolSin: ", polFolSin)

  const arrayFormaPago = [
    { identificador: 1, value: 1, texto: "TRANFERENCIA" },
    { identificador: 2, value: 2, texto: "CHEQUE" },
    { identificador: 3, value: 3, texto: "EFECTIVO" }
  ];
  const [porcentaje, setPorcentaje] = useState(100)
  const [importe, setImporte] = useState(0)
  const [importeOriginal, setImporteOriginal] = useState(0)
  const [importeAPagar, setImporteAPagar] = useState(0)
  const [dataBene, setDataBene] = useState([])
  const [dataAse, setDataAse] = useState([])
  const [errorMessage, setErrorMessage] = useState("")
  const [errorPorcent, setErrorPorcent] = useState(false);
  const [errorMessageImp, setErrorMessageImp] = useState("")
  const [errorImp, setErrorImp] = useState(false);
  const [dataRecCob, setDataRecCob] = useState(false);



  const [dataReserPolCob, setDataReserPolCob] = useState([]);

  const [validaciones, setValidaciones] = useState({
    importeAPagar: null,
    importeTipoCambio: null,
    FPAG_idformadepago: null,
    PAG_importe: null,
    PAG_porcentaje: null,
    PAG_cuentaclabe: null,
    PAG_fechatipocambio: null,
    PAG_importetipocambio: null,
  })



  let today = new Date();
  let todayIsoFormat = (today.toISOString()).slice(0, 10)

  /****** Código Usuario que actualizó *******/
  const [user, setUser] = useState("");
  //GET A API DOCUMENTACION 
  const getDataUser = async () => {
    //Resultados de la api documentacion documentosxtiposiniestrostramite
    var result = await getDataToken("login/get_correo/" + localStorage.getItem("SNYTLOG_email") + "/");
    setUser(result?.SNYTLOG_username + " " + result?.SNYTLOG_surnamedad + " " + result?.SNYTLOG_surnamemom);
  };
  useEffect(() => {
    getDataUser();
  }, []);
  /****** Código fromas de pago *******/
  const [formasPago, setFormasPago] = useState("");
  //GET A API DOCUMENTACION 
  const getDataFormaPago = async () => {
    //Resultados de la api documentacion documentosxtiposiniestrostramite
    var result = await getDataToken("tipoformaspago/get_listado/");
    if (result && result.length > 0) {
      let copiaApi = result.map((obj) => {
        return { ...obj, identificador: obj.TFPAG_idtipoformapago, texto: obj.TFPAG_tipoformadepago }
      })
      setFormasPago(copiaApi)
    }
  };
  useEffect(() => {
    getDataFormaPago();
  }, []);




  /* Funcion para actualizar los datos de los beneficiarios en la tabla */
  const getDataPersonasXPoliza = async () => {
    try {
      console.log("polfolsin", polFolSin)
      let response = await getDataToken(`personasxpoliza/get_poliza/${polFolSin?.POL_idpoliza?.POL_idpoliza}/`)
      // let response2 = await getDataToken(`reservasporpolizaporcoberturas/get_listado/`)
      // let response2 = await getDataToken("polizascoberturasporproductos/get_pol/" + polFolSin?.POL_idpoliza?.POL_idpoliza + "/");
      let response2 = await getDataToken("coberturaspolizasportramite/get_pol/" + polFolSin?.POL_idpoliza?.POL_idpoliza + "/");

      let response3 = await getDataToken(`pagos/get_idfolioxpxs/${polFolSin.PXFS_idfoliopolizaxsiniestro}/`);//Buscar si hay pagos 
      console.log("response 1",response);
      console.log("response 2",response2);
      console.log("response 3",response3);
      
      if (response && response2) {
        // Buscar la cobertura y su moneda 
        let monedaCob = response2.find(obj => obj.MON_idmoneda.MON_idmoneda == MONEDA_NACIONAL.id)
        let dataCob = response2.find(obj => obj.COB_idcobertura.COB_idcobertura == COBERTURA_FALLE_DEFAULT.id)
        /** Buscar los beneficiarios de la póliza */
        const beneficiarios = response.filter((persona) => persona?.PER_idpersona?.PERR_idrol?.PERR_idrol == PERR_BENEFICIARIO.id);
        /** Buscar al asegurado de la póliza */
        const asegurado = response.filter((persona) => persona?.PER_idpersona?.PERR_idrol?.PERR_idrol == PERR_ASEGURADO.id);

        console.log("BENEEEEEEE",beneficiarios)
        if (beneficiarios.length > 0) {
          //Validar si hay pagos a este beneficiario con esta cobertura 
          if (response3 && response3.length > 0) {
            let nuevoArreglo = beneficiarios.map((beneficiario) => {
              console.log("Beneficiario:", beneficiario)
              console.log("cobertura::", dataCob)
              let findPay = response3.find((obj) => obj.PER_idpersona.PER_idpersona == beneficiario.PER_idpersona.PER_idpersona && dataCob.COB_idcobertura.COB_idcobertura == obj.COB_idcobertura.COB_idcobertura)

              if (findPay) {
                console.log("Encontre coincidencia!", findPay)

                return {
                  ...beneficiario,
                  pagado: true, // asignación de valor para pagado
                  ESTPAG_idestatuspago: findPay.ESTPAG_idestatuspago, // asignación de valor para ESTPAG_idestatuspago
                  TFPAG_idtipoformapago: findPay?.TFPAG_idtipoformapago ? findPay?.TFPAG_idtipoformapago : null  // asignación de valor para ESTPAG_idestatuspago
                };
              } else {
                return beneficiario; // Si no se encuentra el beneficiario en response3, se devuelve sin modificar
              }
            });
            console.log("Nuevo arreglo", nuevoArreglo);

            setDataBene(nuevoArreglo); // Actualizamos el estado con los nuevos beneficiarios

          } else {
            setDataBene(beneficiarios)
          }
          if (monedaCob && monedaCob.MON_idmoneda?.MON_idmoneda != MONEDA_NACIONAL.id) {
            let data = await getCurrencyExchangeBanxico();
            console.log("data", data)
            let valorCambio = ""
            let series = data.bmx.series
            console.log("series", series)
            if (data) {
              let today = new Date();
              //Se carga una tabla con los registros obtenidos
              for (let serie in data.bmx.series) {
                console.log("Entre al for de series:", serie);

                for (let serie of series) {
                  for (let dato of serie.datos) {
                    console.log("Entre al for de datos:", dato);
                    if ((dato.fecha).split('/').reverse().join('-') === (today.toISOString()).slice(0, 10)) {
                      valorCambio = parseFloat(dato.dato).toFixed(2);
                    }
                  }
                }
              }
            }
            const nuevoArreglo = beneficiarios.map((objeto) => {
              return { ...objeto, fechaTC: todayIsoFormat, importeTC: parseFloat(valorCambio) };
            });
            setDataBene(nuevoArreglo);
          }
         }
         else {
          setDataBene([])
          setDialogConfirmBene(true)
        }
        if (asegurado.length > 0) {
          //Validar si hay pagos a este asegurado con esta cobertura 
          if (response3 && response3.length > 0) {
            let nuevoArreglo = asegurado.map((asegurado) => {
              let findPay = response3.find((obj) => obj.PER_idpersona.PER_idpersona == asegurado.PER_idpersona.PER_idpersona && dataCob.COB_idcobertura.COB_idcobertura == obj.COB_idcobertura.COB_idcobertura)

              if (findPay) {
                return {
                  ...asegurado,
                  pagado: true, // Ejemplo de asignación de valor para pagado
                  ESTPAG_idestatuspago: findPay.ESTPAG_idestatuspago // Ejemplo de asignación de valor para ESTPAG_idestatuspago
                };
              } else {
                return asegurado; // Si no se encuentra el asegurado en response3, se devuelve sin modificar
              }
            });
            setDataAse(nuevoArreglo); // Actualizamos el estado los nuevos datos del asegurado

          }

          if (monedaCob && monedaCob.MON_idmonedacobertura?.MON_idmoneda != MONEDA_NACIONAL.id) {
            console.log("MONEDAAAAAAAAAAAAAA COB", monedaCob)
            // let monedaFrom = asegurado[0].POL_idpoliza.PRO_idproductos.MON_idmonedas.MON_abreviatura
            // let respuesta = await getCurrencyExchangeHistorical(todayIsoFormat, monedaFrom, MONEDA_NACIONAL.abreviatura);
            // let valorCambio = ""
            // if (respuesta.success) {
            //   const filtroMoneda = Object.keys(respuesta.quotes).find(key => key.includes(`${monedaFrom}${MONEDA_NACIONAL.abreviatura}`));
            //   if (filtroMoneda) {
            //     valorCambio = respuesta.quotes[filtroMoneda];
            //   }
            // }
            let data = await getCurrencyExchangeBanxico();
            console.log("data", data)
            let valorCambio = ""
            let series = data.bmx.series
            console.log("series", series)
            if (data) {
              let today = new Date();
              //Se carga una tabla con los registros obtenidos
              for (let serie in data.bmx.series) {
                console.log("Entre al for de series:", serie);

                for (let serie of series) {
                  for (let dato of serie.datos) {
                    console.log("Entre al for de datos:", dato);
                    if ((dato.fecha).split('/').reverse().join('-') === (today.toISOString()).slice(0, 10)) {
                      valorCambio = parseFloat(dato.dato).toFixed(2);
                    }
                  }
                }
              }
            }
            const nuevoArreglo = asegurado.map((objeto) => {
              return { ...objeto, fechaTC: todayIsoFormat, importeTC: valorCambio };
            });
            setDataAse(nuevoArreglo);
          } else {
            setDataAse(asegurado);
          }
        }
        else
          setDataAse([])
      }
      if (response2) {
        try {
          let arrAux = [];
        
          for (let pcp of response2) {
            console.log("PCPPPPPPPPPPPPPPP",pcp)
            await getDataToken("reservasporpolizaporcoberturas/get_IDPCP/" + pcp.CPT_idcoberturapolizatramite + "/").then(
              (data) => {
                if (data.length > 1) {
                  alert("Esta poliza tiene más de un registro con la misma cobertura. Comuniquese con encargado de TI");
                } else {
                  arrAux.push(data[0]);
                  console.log("dataaaaaaaa:", data)
                }
              }
            );
          }

          setDataReserPolCob(arrAux)

        } catch (error) {
          console.log(error);
          throw error;
        }
        //TO DO: RECORREC EL ARREGLO DE LA RESPUESTA DE LA API reservasporpolizaporcoberturas (CREAR UN FILTRO DESDE EL BACK?)
        // let filtro = response2.find((obj) => obj?.PCP_idpolizacoberturaporproducto?.POL_idpoliza?.POL_idpoliza === polFolSin?.POL_idpoliza?.POL_idpoliza)
        // setDataReserPolCob(filtro)
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  const getDataApiCurrency = async () => {
    try {
      let response = await getCurrencyExchangeHistorical();
      if (response) {
        console.log(response)
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    getDataPersonasXPoliza();
  }, [])
  const handleChangePercentAse = (porcentaje) => {
    if (parseFloat(porcentaje) > 100) {
      setErrorMessage("La cantidad ingresada es superior al límite (100%)")
      setErrorPorcent(true)
      setPorcentaje(porcentaje)
    } else if (porcentaje <= 0) {
      setImporteAPagar(0.0)
    } else {
      setImporteAPagar(formatNumber(parseFloat(calcularImporteBeneficiario((dataRecCob.RPC_importeReserva + dataRecCob.RPC_importeReservaAjustes), porcentaje))))
      setImporte(formatNumberComa(parseFloat(calcularImporteBeneficiario((dataRecCob.RPC_importeReserva + dataRecCob.RPC_importeReservaAjustes), porcentaje)).toFixed(2)));
      setErrorMessage("");
      setPorcentaje(porcentaje)
      setErrorPorcent(false)
    }
    if (!porcentaje || porcentaje === "" || porcentaje <= 0) {
      setPorcentaje(0)
      setImporte(0.00)
      setImporteOriginal(0.0)
    }
  }


  const handleChangeImportAse = (e) => {
    let input = e
    let value = formatNumber(e.target.value)
    if (parseFloat(value) > (dataRecCob.RPC_importeReserva + dataRecCob.RPC_importeReservaAjustes)) {
      setImporte(formatedNumberAndDecimalAndComa(input))
      setErrorMessageImp(`La cantidad ingresada es superior al límite (${formatNumberDecimalComa(dataRecCob.RPC_importeReserva + dataRecCob.RPC_importeReservaAjustes)})`)
      setErrorImp(true)
    } else {
      setImporte(formatedNumberAndDecimalAndComa(input))
      setPorcentaje(parseFloat(calcularPorcentajeBeneficiario((dataRecCob.RPC_importeReserva + dataRecCob.RPC_importeReservaAjustes), value)))
      setImporteAPagar(parseFloat(value))
      setErrorMessageImp(``)
      setErrorImp(false)
    }
    if (!value || value === "") {
      setPorcentaje(0)
      setImporte(0.00)
      setImporteOriginal(0.0)
      setImporteAPagar(0.0)
    }
  }

  useEffect(() => {

    setImporteOriginal(formatNumberDecimalComa(calcularImporteBeneficiario((dataReserPolCob.RPC_importeReserva + dataReserPolCob.RPC_importeReservaAjustes), porcentaje)))
    setErrorMessage("")
    setErrorPorcent(false)
    setPorcentaje("")
    setImporte("")
  }, [props.tipoTabla])

  // Variables, estados y funcionalidad para diálogos
  const [dialogConfirmAse, setDialogConfirmAse] = useState(false)
  const [dialogConfirmBene, setDialogConfirmBene] = useState(false)
  const [dialogPropAse, setDialogPropAse] = useState({
    title: "",
    description: "",
    firstButton: "",
    secondButon: "",
  })
  const [dialogPropBene, setDialogPropBene] = useState({
    title: "",
    description: "",
    firstButton: "",
    secondButton: "",
    toFirstButton: null,
    stateFirstbutton: null,
  })

  const showDialogConfirmAse = (pxp) => {
    setDialogPropAse((prevData) => ({
      ...prevData,
      title: ` `,
      description: `Está a punto de registrar el pago de la póliza ${pxp?.POL_idpoliza?.POL_numpoliza} por la cantidad de ${importeOriginal}. Si se requiere realizar otro pago a la misma cobertura, deberá generar previamente el finiquito. ¿Desea confirmar?`,
      firstButton: "Aceptar",
      secondButon: "Cancelar",
    }));
    setDialogConfirmAse(true)
  }
  const showDialogConfirmBene = (pxp) => {
    setDialogPropBene((prevData) => ({
      ...prevData,
      title: ` `,
      description: `Está a punto de registrar el pago de la póliza ${pxp?.POL_idpoliza?.POL_numpoliza} por la cantidad de ${formatNumberComa(calcularImporteBeneficiario(dataRecCob.RPC_importeReserva + dataRecCob.RPC_importeReservaAjustes, pxp?.PXP_porcentajebeneficiario))}. Si se requiere realizar otro pago al mismo beneficiario, deberá generar previamente el finiquito.  ¿Desea confirmar?`,
      firstButton: "Aceptar",
      secondButon: "Cancelar",
    }));
    setDialogConfirmBene(true)
  }
  const dateChange = async (e, rolPersona, idPersona) => {
    let today = new Date();
    let today2 = new Date(e.$d);
    const formatedDate = format(today2, 'yyyy-MM-dd');
    const formatedHour = format(today, 'HH:mm:ss');
    let date = `${formatedDate}T${formatedHour}`;
    let startDate = new Date(new Date(date).getTime() - 24 * 60 * 60 * 1000);
    startDate = startDate.toISOString().slice(0, 10);
    let endDate = formatedDate;

    if (rolPersona === PERR_ASEGURADO.id) {
      const newData = [...dataAse]; // Crear una copia nueva del array de objetos
      for (const element of newData) {
        if (element.PER_idpersona.PER_idpersona == idPersona) {
          // let respuesta = await getCurrencyExchangeHistorical(date, monedaFrom, monedaTo);
          let data = await getCurrencyExchangeBanxico(endDate, endDate);
          console.log("data", data)
          let importeTC = ""
          let series = data.bmx.series
          console.log("series", series)
          if (data) {
            let today = new Date();
            //Se carga una tabla con los registros obtenidos
            for (let serie in data.bmx.series) {
              console.log("Entre al for de series:", serie);

              for (let serie of series) {
                for (let dato of serie.datos) {
                  console.log("Entre al for de datos:", dato);
                  console.log("end date:", endDate)
                  console.log("dato format: ", (dato.fecha).split('/').reverse().join('-'))
                  importeTC = parseFloat(dato.dato).toFixed(2);

                }
              }
            }
          }
          element.importeTC = importeTC;
          element.fechaTC = endDate;
        }
      }
      setDataAse(newData);
    } else if (rolPersona === PERR_BENEFICIARIO.id) {
      const newData = [...dataBene]; // Crear una copia nueva del array de objetos
      for (const element of newData) {
        if (element.PER_idpersona.PER_idpersona == idPersona) {
          let data = await getCurrencyExchangeBanxico(startDate, endDate);
          console.log("data", data)
          let importeTC = ""
          let series = data.bmx.series
          console.log("series", series)
          if (data) {
            let today = new Date();
            //Se carga una tabla con los registros obtenidos
            for (let serie in data.bmx.series) {
              console.log("Entre al for de series:", serie);

              for (let serie of series) {
                for (let dato of serie.datos) {
                  console.log("Entre al for de datos:", dato);
                  if ((dato.fecha).split('/').reverse().join('-') === endDate) {
                    importeTC = parseFloat(dato.dato).toFixed(2);
                  }
                }
              }
            }
          }
          element.importeTC = importeTC;
          element.fechaTC = endDate;

        }
      }

      setDataBene(newData);
    }
  }

  //Verificar si el texto es porcentaje o importe, si es porcentaje validar que no sea menor que o mayor de 100
  const [valueImporte, setValueImporte] = React.useState("");

  const [estadoId, setEstadoId] = useState("");
  /* FUNCIÓN CLICK EN LOS RADIO BUTTON, Habilita la funcionalidad de esa fila */
  const rbClick = (row) => {
    const id = parseInt(row.id);
    setEstadoId(id);
    setImporte("");
    setImporteAPagar("");
    setPorcentaje("");
    props.dataCob(row)
  };

  // Este useEffect escucha algún cambio en el estado P
  useEffect(() => {
    if (estadoId !== "")
      document.getElementById(`iValuePercent_${estadoId}`).focus();
  }, [estadoId]);



  // Este useEffect escucha algún cambio en el estado P
  useEffect(() => {
    if (dataReserPolCob && tipoTabla === PERR_BENEFICIARIO.id) {
      let coberBasica = dataReserPolCob.find(obj => obj?.CPT_idcoberturapolizatramite.COB_idcobertura.COB_idcobertura == COBERTURA_FALLE_DEFAULT.id)
      if (coberBasica) {
        props.dataCob(coberBasica);
        setDataRecCob(coberBasica)
      }
    }

  }, [props.tipoTabla]);

  const validateForm = async (data) => {
    try {
      let matchMoneda = dataReserPolCob.find(obj => obj?.PCP_idpolizacoberturaporproducto?.MON_idmonedacobertura?.MON_idmoneda != MONEDA_NACIONAL.id)
      let formaPago = document.getElementsByName('selectTFormaPag_' + data.PER_idpersona.PER_idpersona)[0].value;
      console.log(formaPago)
      console.log(dataRecCob)
      let jsonPagos = {}
      console.log(data);
      if (data.cuentaBancaria && data.cuentaBancaria != ""
        && formaPago != "" && formaPago != 0
        && data.porcentaje && data.porcentaje != "") {
        // Validar los importes 
        if (data.fechaTC && data.importeTC && matchMoneda) { // La moneda es diferente y el tipo cambio tiene un valor 
          jsonPagos = {
            PAG_importe: data.importe,
            PAG_porcentaje: data.porcentaje,
            PAG_cuentaclabe: data.cuentaBancaria,
            PAG_fechatipocambio: data.fechaTC,
            PAG_importetipocambio: data.importeTC,
            TFPAG_idtipoformapago: formaPago,
            PXFS_idfoliopolizaxsiniestro: polFolSin.PXFS_idfoliopolizaxsiniestro,
            PER_idpersona: data.PER_idpersona.PER_idpersona,
            COB_idcobertura: dataRecCob.CPT_idcoberturapolizatramite.COB_idcobertura.COB_idcobertura,
            MON_idmonedas: dataRecCob.CPT_idcoberturapolizatramite.MON_idmoneda.MON_idmoneda,
            PAG_actualizousuario: user,
            ESTPAG_idestatuspago: ESTATUS_PAGOS.PAGADO.id,
          }
          let respPago = await postDataToken("pagos/get_listado/", jsonPagos);
          console.log("json pago", jsonPagos)

          if (respPago) {
            alert("Se ha realizado el pago")
            getDataPersonasXPoliza();
          }


        } else {
          jsonPagos = {
            PAG_importe: data.importe,
            PAG_porcentaje: data.porcentaje,
            PAG_cuentaclabe: data.cuentaBancaria,
            PAG_fechatipocambio: data.fechaTC,
            PAG_importetipocambio: data.importeTC,
            TFPAG_idtipoformapago: formaPago,
            PXFS_idfoliopolizaxsiniestro: polFolSin.PXFS_idfoliopolizaxsiniestro,
            PER_idpersona: data.PER_idpersona.PER_idpersona,
            COB_idcobertura: dataRecCob.CPT_idcoberturapolizatramite.COB_idcobertura.COB_idcobertura,
            MON_idmonedas: dataRecCob.CPT_idcoberturapolizatramite.MON_idmoneda.MON_idmoneda,
            PAG_actualizousuario: user,
            ESTPAG_idestatuspago: ESTATUS_PAGOS.PAGADO.id,
          }
          let respPago = await postDataToken("pagos/get_listado/", jsonPagos);
          console.log("json pago", jsonPagos)

          if (respPago) {
            alert("Se ha realizado el pago")
            getDataPersonasXPoliza();
          }
        }
      } else {
        if (!data.cuentaBancaria || data.cuentaBancaria == "") { // Validar cuenta clabe
          setDialogPropBene((prevData) => ({
            ...prevData,
            title: ` `,
            toFirstButton: "/dictamen/detalle_folio/folio",
            stateFirstbutton: { row, folio },
            description: `Cuenta bancaria no  registrada. Favor de registrarla en Dictamen de las pólizas. `,
            firstButton: "Ir ahora",
            secondButton: "Aceptar",
          }));
          setDialogConfirmBene(true)
        } else {
          setDialogPropBene((prevData) => ({
            ...prevData,
            title: ` `,
            toFirstButton: null,
            stateFirstbutton: null,
            description: `Faltan datos. Revisar formulario.`,
            firstButton: null,
            secondButton: "Aceptar",
          }));
          setDialogConfirmBene(true)
        }
      }
    } catch (err) {
      console.error(err)
    }

  }

  useEffect(() => {

    if (tipoTabla == PERR_BENEFICIARIO.id && dataBene.length <= 0) { // Validar beneficiarios
      setDialogPropBene((prevData) => ({
        ...prevData,
        title: `No hay beneficiarios registrados`,
        toFirstButton: "/dictamen/detalle_folio/folio",
        stateFirstbutton: { row, folio },
        description: `No hay beneficiarios asociados a esta póliza. Favor de registrar beneficiarios en Dictamen de las pólizas. `,
        firstButton: "Ir ahora",
        secondButton: "Aceptar",
      }));
    }

  }, [tipoTabla, dataBene])




  return (
    !tipoTabla ? null : tipoTabla === PERR_ASEGURADO.id ?
      <React.Fragment>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Paper style={{ width: "100%" }}>
              <TableContainer style={{ position: "sticky" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                      <StyledTableCell style={{ padding: '0px' }}></StyledTableCell>
                      <StyledTableCell>
                        <FormattedMessage
                          id="globSelecc"
                          defaultMessage="Cobertura"
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <FormattedMessage
                          id="globPorcentaje"
                          defaultMessage="Porcentaje"
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <FormattedMessage
                          id="globImporte"
                          defaultMessage="Importe"
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <FormattedMessage id="globImporteAPagar" defaultMessage="Importe a pagar" />
                      </StyledTableCell>
                      {dataReserPolCob.find(obj => obj?.PCP_idpolizacoberturaporproducto?.MON_idmonedacobertura?.MON_idmoneda != MONEDA_NACIONAL.id) ?

                        <>
                          <StyledTableCell>
                            <FormattedMessage id="globDiaTC" defaultMessage="Día TC" />
                          </StyledTableCell>
                          <StyledTableCell>
                            <FormattedMessage
                              id="globaTC"
                              defaultMessage="TC"
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <FormattedMessage
                              id="globaImporteMn"
                              defaultMessage="Importe MN"
                            />
                          </StyledTableCell>
                        </> : <></>
                      }


                      <StyledTableCell>
                        <FormattedMessage
                          id="globTipoPago"
                          defaultMessage="Tipo de pago"
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <FormattedMessage
                          id="globCuentaBanca"
                          defaultMessage="Cuenta bancaria"
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <FormattedMessage id="globPagar" defaultMessage="Pagar" />
                      </StyledTableCell>
                      <StyledTableCell>
                        <FormattedMessage
                          id="globEstatus"
                          defaultMessage="Estatus"
                        />
                      </StyledTableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}

                  </TableBody>
                  <TableBody>
                    {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
                    {dataReserPolCob.length <= 0 ? null : dataReserPolCob.map((row, index) => {
                      // console.log(row)
                      // let monedaFrom = row.POL_idpoliza?.PRO_idproductos?.MON_idmonedas?.MON_abreviatura;
                      // let monedaTo = MONEDA_NACIONAL.abreviatura;
                      // console.log("importe", formatNumber(importe))

                      // let importeMN = isNaN(parseFloat(row.valorCambio) * parseFloat(formatNumberComa(importe))) ? 0.0 : parseFloat(row.valorCambio) * parseFloat(formatNumber(importe))
                      return (
                        <TableRow key={index + 1}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            <RadioInput
                              name="rb"
                              value={row.id}
                              onChange={() => {
                                rbClick(row);
                                setDataRecCob(row)
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {row.PCP_idpolizacoberturaporproducto.COB_idcobertura.COB_cobertura}
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            style={{
                              cursor: row.id !== estadoId ? "cursor" : null,
                            }}
                          >

                            {estadoId !== row.id ? null : (
                              <TXTField id={`iValuePercent_${row.id}`} textAlign="right" error={errorPorcent} helperText={errorMessage} value={porcentaje} onChange={(e) =>
                                handleChangePercentAse(validateNumberAndDecimal(e.target.value, 3))
                              } size="small" />)}
                          </StyledTableCell>

                          <StyledTableCell style={{
                            cursor: row.id !== estadoId ? "cursor" : null,

                          }}>

                            {estadoId !== row.id ? null : (
                              <TXTField textAlign="right" id={`iValueImporte_${row.id}`} value={importe} error={errorImp} helperText={errorMessageImp} onChange={(e) =>
                                handleChangeImportAse(e)
                              } size="small" />
                            )}

                            {/* <TXTField value={importe} error={errorImp} helperText={errorMessageImp} onChange={(e) =>
                              handleChangeImportAse(e)
                            } size="small" /> */}
                          </StyledTableCell>
                          <StyledTableCell>{row.id !== estadoId ? "" : (importeAPagar === "" || !importeAPagar ? "" : formatNumberDecimalComa(importeAPagar))}</StyledTableCell>
                          {row?.PCP_idpolizacoberturaporproducto?.MON_idmonedacobertura?.MON_idmoneda === MONEDA_NACIONAL.id ?
                            <></> : (row.id !== estadoId ? <><StyledTableCell></StyledTableCell><StyledTableCell></StyledTableCell><StyledTableCell></StyledTableCell></> : <>
                              <StyledTableCell style={{
                                cursor: row.id !== estadoId ? "cursor" : null,

                              }}><DatePicker2 maxDate={today} onDataChange={(e) => {
                                dateChange(e, PERR_ASEGURADO.id, row?.PER_idpersona?.PER_idpersona)
                              }} /></StyledTableCell>
                              <StyledTableCell>{row.valorCambio}</StyledTableCell>
                              <StyledTableCell>{ }</StyledTableCell></>
                            )}
                          <StyledTableCell ><Select1 disabled={row.id !== estadoId ? true : false} array={formasPago} /></StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell><IconPagos2 disabled={row.id !== estadoId ? true : false} tooltipTitle="Pagar" onClick={() => showDialogConfirmAse(row)} /></StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
        {/* Diaálogos */}
        {!dialogConfirmAse ? null :
          <DialogConfirm
            open={dialogConfirmAse}
            description={dialogPropAse.description}
            firstButton={dialogPropAse.firstButton}
            secondButton={dialogPropAse.secondButon}
            onClickAccept={(bool) => { setDialogConfirmAse(false) }}
          />}
      </React.Fragment>
      // Apartado para pago a beneficiarios
      :
      <React.Fragment>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Paper style={{ width: "100%" }}>
              <TableContainer style={{ position: "sticky" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                  ascendente o descendente, agregando un icono para su efecto visual */}
                      <StyledTableCell>
                        <FormattedMessage
                          id="globSelecc"
                          defaultMessage="Nombre"
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <FormattedMessage
                          id="globPorcentaje"
                          defaultMessage="Porcentaje"
                        />
                      </StyledTableCell>

                      {dataReserPolCob.find(obj => obj?.CPT_idcoberturapolizatramite?.MON_idmoneda?.MON_idmoneda != MONEDA_NACIONAL.id) ?
                        <>
                          <StyledTableCell>
                            <FormattedMessage id="globDiaTC" defaultMessage="Día TC" />
                          </StyledTableCell>
                          <StyledTableCell>
                            <FormattedMessage
                              id="globaTC"
                              defaultMessage="TC"
                            />
                          </StyledTableCell>
                        </> : <></>}

                      <StyledTableCell>
                        <FormattedMessage
                          id="globaImporteAPagar"
                          defaultMessage="Importe a pagar"
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <FormattedMessage
                          id="globFormaPago"
                          defaultMessage="Tipo de pago"
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <FormattedMessage
                          id="globCuentaBanca"
                          defaultMessage="Cuenta bancaria"
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <FormattedMessage id="globPagar" defaultMessage="Pagar" />
                      </StyledTableCell>
                      <StyledTableCell>
                        <FormattedMessage
                          id="globEstatus"
                          defaultMessage="Estatus"
                        />
                      </StyledTableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
              retornará el valor original del arreglo, en caso contrario retornará todos los valores 
              que coincidan con las letras ingresadas, */}
                    {dataBene.map((row, index) => {
                      console.log("rowwwwwwwwww", row);
                      if (!row.importeTC) {
                        row.fechaTC = null;
                        row.importeTC = null;
                      }
                      row.porcentaje = row?.PXP_porcentajebeneficiario;
                      row.importe = parseFloat(calcularImporteBeneficiario(dataRecCob.RPC_importeReserva + dataRecCob.RPC_importeReservaAjustes, row?.PXP_porcentajebeneficiario))

                      row.cuentaBancaria = row?.PER_idpersona?.PER_numerocuentabancaria;
                      // let monedaFrom = row.POL_idpoliza?.PRO_idproductos?.MON_idmonedas?.MON_abreviatura;
                      // let monedaTo = MONEDA_NACIONAL.abreviatura;
                      return (
                        <TableRow key={index + 1}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {`${row?.PER_idpersona?.PER_nombre} ${row?.PER_idpersona?.PER_apellidopaterno} ${row?.PER_idpersona?.PER_apellidomaterno}`}
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {/* <EditableCell error={errorPorcent} helperText={errorMessage} value={porcentaje} onValueChange={(e)=> {
                            setPorcentaje(validateNumberAndDecimal(e.target.value,3))
                            }} />  */}
                            {row?.PXP_porcentajebeneficiario}
                          </StyledTableCell>
                          <StyledTableCell align="center">{formatNumberComa(calcularImporteBeneficiario(dataRecCob.RPC_importeReserva + dataRecCob.RPC_importeReservaAjustes, row?.PXP_porcentajebeneficiario))}</StyledTableCell>
                          {row?.CPT_idcoberturapolizatramite?.MON_idmoneda?.MON_idmoneda != MONEDA_NACIONAL.id ? <></> : <>
                            <StyledTableCell><DatePicker2 maxDate={today} onDataChange={(e) => { dateChange(e, PERR_BENEFICIARIO.id, row?.PER_idpersona?.PER_idpersona) }} /></StyledTableCell>
                            <StyledTableCell>{row.valorCambio}</StyledTableCell>
                            <StyledTableCell>{0.0}</StyledTableCell>
                          </>}
                          {row?.TFPAG_idtipoformapago ? <StyledTableCell align="center">{row?.TFPAG_idtipoformapago.TFPAG_tipoformadepago}</StyledTableCell> :

                            <StyledTableCell><Select2 array={arrayFormaPago} onChange={() => { }} disabled={row?.TFPAG_idtipoformapago ? true : false} id={`selectTFormaPag_${row?.PER_idpersona?.PER_idpersona}`} name={`selectTFormaPag_${row?.PER_idpersona?.PER_idpersona}`} /></StyledTableCell>
                          }
                          <StyledTableCell align="center">{row?.PER_idpersona?.PER_numerocuentabancaria}</StyledTableCell>
                          {/* <StyledTableCell><IconPagos2 tooltipTitle="Pagar" onClick={() => showDialogConfirmBene(row)} /></StyledTableCell> */}
                          <StyledTableCell><IconPagos2 tooltipTitle="Pagar" onClick={() => validateForm(row)} disabled={row?.ESTPAG_idestatuspago ? true : false} /></StyledTableCell>
                          <StyledTableCell align="center">{row?.ESTPAG_idestatuspago?.ESTPAG_idestatuspago ? row?.ESTPAG_idestatuspago?.ESTPAG_descripcion : ""}</StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
        {/* Diálogos */}
        {!dialogConfirmBene ? null :
          <DialogConfirm
            open={dialogConfirmBene}
            description={dialogPropBene.description}
            firstButton={dialogPropBene.firstButton}
            toFirstButton={dialogPropBene.toFirstButton}
            stateFirstButton={dialogPropBene.stateFirstbutton}
            secondButton={dialogPropBene.secondButton}
            onClickAccept={(bool) => {
              getDataApiCurrency();
              setDialogConfirmBene(false)
            }}
          />}
      </React.Fragment>
  );
}
