import React from "react";
import CompDictamenJur from "../../../components/Dictamen/CompDictamenJur";



export function DictamenJuridico() {
    return (
        <div>
          
            <CompDictamenJur />
            
        </div>
    );
}