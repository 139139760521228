/* Brandon Hernandez Rocha 11/2022 */
import React, { useState, useEffect } from "react";
import CustomSeparatorDinamic from "../Tools/BreadCrumbs/RecorridoDinamico";
import "../../css/estilo.css";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
//import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Grid } from "@mui/material";
import { getDataToken } from "../../api/dataToken";
import { TabPM } from "../Registro/Elements/Tabs/TabPM";
import { TabPMSinay } from "../Registro/Elements/Tabs/TabPMSinay";
import { DATA_PM } from "../../utils/constansPM";

const CompDictamenPolOri = () => {
  const location = useLocation();
  const infoRow = location.state.row;
  console.log(infoRow);
  const row = infoRow?.infoFolio;
  const arrayBreadcrumbs = [
    {
      key: 1,
      name: <FormattedMessage id="globInicio" defaultMessage="Inicio" />,
      to: "/menu",
    },
    {
      key: 2,
      name: <FormattedMessage id="menuDictam" defaultMessage="Dictamen" />,
      to: "/dictamen",
    },
    {
      key: 3,
      name: (
        <FormattedMessage id="dictaDetalleF" defaultMessage="Detalle folio" />
      ),
      to: "/dictamen/detalle_folio",
      state: { row },
    },
  ];

  //--------------------------INFO DE LA POLIZA EN SINAY----------------------------------

  const [datosActualizados, setDatosActualizados] = useState([]);
  const [datosActualizadosSinay, setDatosActualizadosSinay] = useState([]);

  const getDatosPoliza = async () => {
    try {
      // Hacemos la consulta al PM
      getDataToken(
        `conexionpm/poliza_apiPM/?poliza=${infoRow?.POL_idpoliza?.POL_numpoliza}`
      ).then((result) => {
        setDatosActualizados(result[0]);
      });

      //Hacemos la segunda consulta a Sinay
      const fetchData = async () => {
        const result = await getDataToken(
          `polizas/get_numpoliza/${infoRow?.POL_idpoliza?.POL_numpoliza}/`
        );
        setDatosActualizadosSinay(result);
      };

      fetchData();
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getDatosPoliza();
  }, [infoRow]);

  ////-------------------------------------------FIN DATOS SINAY-------------------------------

  return (
    <div className="div-parent">
      <div className="div-breadcrumbs">
        <CustomSeparatorDinamic
          breadcrumbs={arrayBreadcrumbs}
          name={`Póliza origen`}
        />
      </div>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Link style={{ textDecoration: "none" }} to="/dictamen" state={{ row }}>
          <ReturnPage />
        </Link>
      </Grid>
      {/*-----------------------------------TABLA DE INFROMACION SINAY------------------------------------------*/}
      {datosActualizados?.length > 0 ? (
        <>
          <br />
          <br />
          <TabPM
            numPoliza={datosActualizados?.[DATA_PM?.POLIZA]}
            infoData={infoRow}
          />
          <br />
        </>
      ) : (
        <>
          <br />
          <br />
          <TabPMSinay
            idPoliza={datosActualizadosSinay?.POL_idpoliza}
            numPoliza={datosActualizadosSinay?.POL_numpoliza}
          />
          <br />
        </>
      )}
      <br />
    </div>
  );
};
export default CompDictamenPolOri;
