import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import {Select1} from "../../Tools/Selectss/Select1"
//importacion de la tabla
import TablaGenerateMovimiento from '../Elements/TablaGenerateMovimiento'

const ReservasActuariales = ()=>{

  //Constante de las tablas 
const [incremento, setIncremento ] = useState(false)
const [decremento, setDecremento] = useState(false)

//--Aquí apareceran el primer menú
const [reservas, setReservas] = useState([]);
const reservasAct = [
  { identificador: "1", texto: "Ajuste a reserva pólizas exentas por invalidez" },
  { identificador: "2", texto: "Ajuste pólizas exentas por fallecimiento" },
  { identificador: "3", texto: "Ajuste pólizas exentas por desempleo" },
  { identificador: "4", texto: "Reservas de siniestros ocurridos no reportados IBNR" },
];
useEffect(() => {
  setReservas(reservasAct);
}, []);
//subSubSubMenu de Reportes y estadisticas 
const [actuariales, setActuariales] = useState([]);
const [actuarial, setActuarial] = useState(false);
const segundaReserva = [
  { id: 1, reservasId: 1, identificador: "1", texto: "Incremento a la reserva", },
  { id: 2, reservasId: 1, identificador: "2", texto: "Decremento a la reserva", },
  { id: 3, reservasId: 2, identificador: "3", texto: "Incremento a la reserva", },
  { id: 4, reservasId: 2, identificador: "4", texto: "Decremento a la reserva", },
  { id: 5, reservasId: 3, identificador: "5", texto: "Incremento a la reserva", },
  { id: 6, reservasId: 3, identificador: "6", texto: "Decremento a la reserva", },
];

const handleOption4 = (id) => {
  const dt = segundaReserva.filter((x) => x.reservasId == id);
  console.log(dt);
  setActuariales(dt);
  setActuarial(true)

  if(id==1|| id==3|| id==5){
    setIncremento(true)
    setDecremento(false)
  }if(id==2|| id==4|| id==6){
    setDecremento(true)
    setIncremento(false)
  }
};



    return(
     <>
      <Grid container>
        <Grid xs={4}>
          {/*En este contenido llamaremos a los select necesarios para realizar nuestro menu
          Array=pondremos el arreglo que queremos mostrar 
          onChange= pondremos el handle que deseamos que necesitamos en esa opción      
        */}
            <Select1 sx={{ minWidth: 310, maxWidth: 300, background: "white", }} h3-contable='Buscar por:' 
            id="reserva" array={reservasAct} onChange={(e)=> handleOption4(e.target.value)}/>
        </Grid>
        <Grid xs={4}>
        { actuarial?(<Select1 sx={{ minWidth: 310, maxWidth: 300, background: "white", }} h3-contable='Buscar por' 
        id='actuarial' array={actuariales}  />): null }
        </Grid>
        <Grid xs={4}></Grid>
        <Grid xs={12}>
          {/*Pondremos todas las opciones porque estas seran seleccionadas dependiendo del menu 
        que desea ver*/} 
          {incremento? <label>Incremento</label>:null}
          {incremento? <TablaGenerateMovimiento/>:null}
          {decremento? <label>Decremento</label>:null}
          {decremento? <TablaGenerateMovimiento/>:null}
        </Grid>
     </Grid>
     </>
    )
}
export default ReservasActuariales;