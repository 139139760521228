import * as React from "react";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { BASE_API } from "../../../../utils/constans";
import { getToken } from "../../../../api/token";
import Button from "@material-ui/core/Button";
import { getDataToken } from "../../../../api/dataToken";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
//* ColorPicker
import { useColor } from "react-color-palette";
import { SketchPicker } from "react-color";
// import { getDataToken } from "../../../api/dataToken";
// import { postDataToken } from "../../../api/dataToken";
import { putDataToken } from "../../../../api/dataToken";




export default function SeccionTheme() {

  const token= getToken();

    //TODO: Umbrales
  const [colorUmbral1, setColorUmbral1] = useState("#c20000");
  const [colorUmbral2, setColorUmbral2] = useState("#FF2919");
  const [colorUmbral3, setColorUmbral3] = useState("#FF8000");
  const [colorUmbral4, setColorUmbral4] = useState("#FFE400");
  const [colorUmbral5, setColorUmbral5] = useState("#58E308");
  const [colorUmbral6, setColorUmbral6] = useState("#12B3EF");

   const enviarColores = (event) => {
    event.preventDefault();
    const datosUmbrales = {
      SCG_umbralColor1: colorUmbral1,
      SCG_umbralColor2: colorUmbral2,
      SCG_umbralColor3: colorUmbral3,
      SCG_umbralColor4: colorUmbral4,
      SCG_umbralColor5: colorUmbral5,
      SCG_umbralColor6: colorUmbral6,
    };
    console.log(datosUmbrales);
    putDataToken("configuraciongeneral/get_id/1/", datosUmbrales)
      .then((data) => {
        setOpenDialog(true);
      })
      .catch((error) => {
        console.error("Error al enviar los datos:", error);
      });
  };


  //!Dialogo
  const [openDialog, setOpenDialog] = useState(false);



  //TODO: Variable Image
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageLogo, setImageLogo] = useState("");
  const [archivos, setArchivos] = useState("");


  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const subirarchivos=e=>{
    setArchivos(e);
  }

  const [tableData, setTableData] = useState([])

  const getPermisos = async () => {
    //Resultados de la api investigacion
    var resultID = await getDataToken("configuraciongeneral/get_id/1/");    
    setTableData(resultID);
    
  }

  useEffect(() => {
    getPermisos()  
  }, [])


  const handleSubmitImage = (event) => {
    event.preventDefault();
    //var img = imageLogo.split("blob:");
    //let imagen = { SCG_imgPathSing:selectedImage};
    //putDataToken("configuraciongeneral/get_id/1/", imagen)

    const formData = new FormData();
    formData.append('SCG_idconfiguraciongeneral', 1);
    formData.append('SCG_imgPathSing', selectedImage);


    // await axios.put(`${BASE_API}configuraciongeneral/get_id/1/`,f)

    if (tableData.SCG_imgPathSing.lenght < 0) {

    fetch(`${BASE_API}configuraciongeneral/get_img/1/`,
    {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          
      }, 
        body: formData})
        .then((data) => {
          setOpenDialog(true);
          
        })
        .catch((error) => {
          console.error("Error al enviar los datos:", error);
        });

      }
    else {
      fetch(`${BASE_API}configuraciongeneral/get_img/1/`,
    {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          
      }, 
        body: formData})
        .then((data) => {
          setOpenDialog(true);
          
        })
        .catch((error) => {
          console.error("Error al enviar los datos:", error);
        });

    }
    
  };
  



  //TODO: ChangeColor
  const [colorPrimary, setColorPrimary] = useColor("hex", "#771f2a");
  const [colorSecundary, setColorSecundary] = useColor("hex", "#00203b");
  const [colorNeutral, setColorNeutral] = useColor("hex", "#FAFAFA");

  function guardarColores() {
    console.log(colorPrimary.hex, colorSecundary.hex, colorNeutral.hex);
    let aux = {
      SCG_primaryColor: colorPrimary.hex,
      SCG_secundaryColor: colorSecundary.hex,
      SCG_neutralColor: colorNeutral.hex,
    };
    try {
      putDataToken("configuraciongeneral/get_id/1/", aux);
      //obtener los datos guardados y enviar lsod a un hook antes de abrir el dialogo

      //despues abrir el dialogo
      setOpenDialog(true);

    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  //TODO: Cerrar Dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // const imageUrls = URL.createObjectURL(selectedImage);

  return (
    
    <div className="div-container">
      {/* <img src={URL.createObjectURL(selectedImage)} /> */}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Respuesta del servidor</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Los datos se han actualizado correctamente.
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonVino
            variant="contained"
            color="primary"
            size="medium"
            label="Aceptar"
            type="submit"
            autoFocus
            onClick={handleCloseDialog}
          />
        </DialogActions>
      </Dialog>
      <Grid container spacing={2} xs={12}>
        {/* //TODO: Fist grid */}
        <Grid item xs={4}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title="Carga de imagen de logotipo"
                subheader={
                  <label style={{ color: "red" }}>
                    No subir archivos mayores de 3 MB
                    Resolucion Min 1080x1080
                  </label>
                }
              />
              <CardContent>
                <form onSubmit={handleSubmitImage}>
                  <center>
                    {imageUrl && selectedImage ? (
                      <>
                        <CardContent>
                          <Card sx={{}}>
                            <CardMedia
                              component="img"
                              sx={{ height: 275, width: 275 }}
                              image={imageUrl}
                              title={selectedImage.name}
                              alt={selectedImage.name}
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                              >
                                Vista previa
                              </Typography>
                              {/* //TODO: Table Image */}
                              <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                  <TableBody>
                                    <TableRow>
                                      <StyledTableCell>
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          Nombre:
                                        </Typography>
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {selectedImage.name}
                                      </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                      <StyledTableCell>
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          Tipo:
                                        </Typography>
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {selectedImage.type}
                                      </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                      <StyledTableCell>
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          Tamaño:
                                        </Typography>
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {selectedImage.size}
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </CardContent>
                          </Card>
                        </CardContent>
                      </>
                    ) : (
                      <>
                        <Box
                          style={{
                            border: "1px dashed grey",
                            height: 475,
                          }}
                        >
                          <div
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              marginTop: 100,
                            }}
                          >
                            <br />
                            <CloudUploadIcon
                              style={{
                                fontSize: 200,
                                color: "lightgrey",
                              }}
                            />
                          </div>
                          <div>
                            <Typography>Cargar imagen...</Typography>
                          </div>
                        </Box>
                      </>
                    )}
                  </center>
                  {/* //TODO: Button Upload Image */}

                  <center>
                    <input
                      accept="image/*"
                      type="file"
                      id="select-image"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setSelectedImage(e.target.files[0]);
                        // setImageLogo(e.target.files[0]);
                      }}
                    />
                    <label htmlFor="select-image">
                      <Button
                        variant="contained"
                        color="secundary"
                        component="span"
                        startIcon={<FileUploadIcon />}
                        //onClick={handleSubmitImage}
                      >
                        Cargar imagen
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {selectedImage && imageUrl ? ( //si tiene
                        <>
                          <ButtonVino
                            variant="contained"
                            color="primary"
                            size="small"
                            label="Guardar imagen"
                            type="submit"
                            onClick={() => {
                              setImageLogo(imageUrl);
                            }}
                          />
                        </>
                      ) : (
                        //si no
                        <></>
                      )}
                    </label>
                  </center>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* //TODO: Second Grid */}
        <br />
        <p></p>
        <Grid container spacing={1} xs={8}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title="Tema"
                subheader="Selecciona los colores para tu interfaz."
              />
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                >
                  <Grid item xs={4}>
                    <Typography style={{ fontWeight: "bold" }}>
                      Primario:
                    </Typography>
                    <SketchPicker
                      width={170}
                      color={colorPrimary}
                      onChange={setColorPrimary}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={{ fontWeight: "bold" }}>
                      Secundario:
                    </Typography>
                    <SketchPicker
                      width={170}
                      color={colorSecundary}
                      onChange={setColorSecundary}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={{ fontWeight: "bold" }}>
                      Neutral:
                    </Typography>
                    <SketchPicker
                      width={170}
                      color={colorNeutral}
                      onChange={setColorNeutral}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid
                  xs={12}
                  container
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <ButtonVino
                      variant="contained"
                      color="primary"
                      size="small"
                      label="Guardar valores"
                      onClick={() => {
                        guardarColores();
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={1} xs={12}>
          <Grid item xs={12}>
          <Card>
          
            <CardHeader
              title="Colores para los umbrales"
              subheader="Selecciona el color para umbrales (semaforo)."
            />
            <CardContent>
              <form onSubmit={enviarColores}>
                <Grid container spacing={1} xs={12}>
                  <Grid item xs={4}>
                    <Typography>
                      <input
                        type="color"
                        style={{ border: 0 }}
                        value={colorUmbral1}
                        onChange={(e) => setColorUmbral1(e.target.value)}
                      />
                      &nbsp;&nbsp;Terminado
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <input
                        type="color"
                        style={{ border: 0 }}
                        value={colorUmbral2}
                        onChange={(e) => setColorUmbral2(e.target.value)}
                      />
                      &nbsp;&nbsp;Cancelado
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <input
                        type="color"
                        style={{ border: 0 }}
                        value={colorUmbral3}
                        onChange={(e) => setColorUmbral3(e.target.value)}
                      />
                      &nbsp;&nbsp;Por vencer
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography>
                      <input
                        type="color"
                        style={{ border: 0 }}
                        value={colorUmbral4}
                        onChange={(e) => setColorUmbral4(e.target.value)}
                      />
                      &nbsp;&nbsp;En proceso
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <input
                        type="color"
                        style={{ border: 0 }}
                        value={colorUmbral5}
                        onChange={(e) => setColorUmbral5(e.target.value)}
                      />
                      &nbsp;&nbsp;A tiempo
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <input
                        type="color"
                        style={{ border: 0 }}
                        value={colorUmbral6}
                        onChange={(e) => setColorUmbral6(e.target.value)}
                      />
                      &nbsp;&nbsp;Nuevo
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1} xs={12}>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <br />
                    <br />
                    <br />
                    <br />
                    <ButtonVino
                      variant="contained"
                      color="primary"
                      size="medium"
                      label="Guardar"
                      type="submit"
                    />
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
        </Grid>


      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¡Actualizado satisfactoriamente!"}
        </DialogTitle>
        <DialogActions>
          <ButtonVino
            variant="contained"
            color="primary"
            size="medium"
            label="Aceptar"
            onClick={handleClose}
            autoFocus
          />
        </DialogActions>
      </Dialog> */}
    </div>
  );
}
