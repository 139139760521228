import * as React from "react";
import Button from "@mui/material/Button";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

export default function BTUpload(props) {
  const [title, setTitle] = React.useState("");
  return (
    <Button
      // Diseño del botón
      variant="outlined"
      component="label"
      // Tamaño del botón
      size={props.size}
      onClick={props.onClick}
      onChange={props.onChange}
      // En que posición se va a colocar el icono
      endIcon={<DriveFolderUploadIcon />}
      // Tamaño del botón y fondo
      sx={{
        width: 200,
        height: 40,
        background: "white",
      }}
      style={props.style}
      
    >
      <btn-upload>
        {title.length === 0 ? null : title.substring(0, 13) + "..."}
      </btn-upload>
      <input
      disabled={props.disabled}
        id={props.id}
        style={{ zIndex: '-1', width: '1px' }}
        value={props.value}
        type="file"
        // La propiedad accept nos permitirá cambiar el formato que deseemos
        // Por ejemplo: accept=".jpg, .jpeg, .png, .pdf"
        accept={props.accept}
        onChange={(event) => {
          var docs = event.target.files
          if (docs.length > 1) {
            setTitle(`${docs[0].name},...`)
          }
          else {
            setTitle(docs[0].name)
          }
        }
        }
        name={props.name}
        required={props.required}
        multiple={props.multiple}
      />

    </Button>
  );
}
