/* 
David Ruiz
Proyecto SINAY 
05/09/2022
*/


import  { Admin } from "../pages/Admin/ModAdmin";
import { AdminLayaout } from '../layouts/Admin/Admin';

const routesAdmin = [
    {
        path:"/admin",
        layout:AdminLayaout,
        component:Admin,
        exact:true,
    },
  
];

export default routesAdmin;