import { putDataLogin } from "../components/Login/apiLogin/getUsers";
import JSEncrypt from "jsencrypt";


/**
 * 
 * @returns Private key
 */
export function createPairKeys() {
    //Get keys
    var crypt = new JSEncrypt();
    crypt.getKey();
    var publicKey = crypt.getPublicKey();
    console.log("llave publica:", publicKey )
    var privateKey = crypt.getPrivateKey();
    var llavePublica = publicKey.substring(26, publicKey.length - 24)
    var valApi = llavePublica.split("\n").join("")
    console.log("llave publica sin saltos:", valApi )
    var email = localStorage.getItem("SNYTLOG_email")
    console.log("Correo:", email)


    // Encrypt with the public key...
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    // Generate a random alphanumerical string of length 11 ( change substring parameter for other length)
    var aux = Math.random().toString(36).substring(2);
    var encrypted = encrypt.encrypt(aux);

    // Decrypt with the private key...
    var decrypt = new JSEncrypt();
    decrypt.setPrivateKey(privateKey);  
    var uncrypted = decrypt.decrypt(encrypted);

    // Now a simple check to see if the round-trip worked.
    if (uncrypted === aux) {
        alert('Llaves generadas exitosamente!!!');
        download(privateKey,'private')
        //download(publicKey,'public')
        updateLlaves(valApi,email)
    }
    else {
        alert('Ocurrio un error....');
    }


    return publicKey;
}

function download(data, filename) {
    const blob = new Blob([data], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}


//PUT Para cambiar el valor de la llave privada y publica
async function updateLlaves(aux,email) {
    try {
        let valorLLave = {
            SNYTLOG_email: email,
            SNYTLOG_llavepublica: aux,
            SNYTLOG_generollave: true
          }

      var result = await putDataLogin(`login/putall/${email}/`, valorLLave);
      console.log("Actualizado correctamente", result);
    } catch (error) {
      console.log(error);
      //setvalid(false);
      //console.log(valid);
      throw error;
    }
  }

