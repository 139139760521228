/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import CheckGroup from "../../../Tools/CheckBox/CheckGroup";
//icons
import IconImprimir from "../../../Tools/Icons/IconImprimir";
//Dialogs
import DialogAgregarOtroCarta from "../Dialogs/DialogAgregarOtroCarta";
import TablePagination from "@mui/material/TablePagination";
//idioma
import { FormattedMessage } from "react-intl";
import { IDCARTARESCI } from "../../../../utils/constanstoken";
import { getDataToken } from "../../../../api/dataToken";

export default function TableCartaRescision(props) {
  //Funcion checkbox para seleccionar beneficiarios
  const [listaDest, setListaDest] = React.useState([]);
  //funcion para añadir destinatario a la lista
  const updateDestToList = (e, dest) => {
    if (e.target.checked) {
      setListaDest((prevState) => [...prevState, dest]);
    } else {
      //remove from array
      setListaDest((current) => current.filter((e) => e.id !== dest.id));
    }
  };

  React.useEffect(() => {
    //console.log('documentos seleccionados', listaDest)
    props.onDataChange(listaDest.length === 0);
    props.cartas(listaDest);
    props.tipoCarta(IDCARTARESCI);
  }, [listaDest]);

  /** Funcionalidad y estados para el get ***/
  const [cartasRescision, setcartasRescision] = React.useState([]);

  const getDataCartas = async () => {
    try {
      let causasFiltradas;
      var result = await getDataToken(`cartascausa/get_listado/`);
      causasFiltradas = result.filter(
        (causas) => causas?.FK_Tipo_Carta?.LTR_idcarta === IDCARTARESCI
      );
      setcartasRescision(causasFiltradas);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  React.useEffect(() => {
    getDataCartas();
  }, []);

  const funcionActualizar = () => {
    getDataCartas();
  };

  console.log(cartasRescision);

  const [page, setPage] = React.useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = React.useState(5); //El tamaño inicial de las filas será de 10
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const filteredData = cartasRescision.slice(startIndex, endIndex);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Paper style={{ width: "100%" }}>
            <TableContainer style={{ position: "sticky" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                    <StyledTableCell>
                      <FormattedMessage
                        id="globSelecc"
                        defaultMessage="Seleccionar"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage
                        id="dictaCausas"
                        defaultMessage="Causas"
                      />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
                  {filteredData.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <CheckGroup
                            onChange={(e) => updateDestToList(e, row)}
                          />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.FK_IDCausa?.CAU_descripcion}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20, 25]}
              component="div"
              count={cartasRescision.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Registros por página:"
            />
          </Paper>
        </Grid>
        <Grid item>
          <br />
          <DialogAgregarOtroCarta
            tipoCarta={IDCARTARESCI}
            ejecutarConsulta={funcionActualizar}
          />
        </Grid>
      </Grid>
    </>
  );
}
