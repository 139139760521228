import { TOKEN } from "../utils/constans";

// Guardar el token en la memoria local del navegador para que este lo consulte

export function setToken(token) {
  localStorage.setItem(TOKEN, token);   
}

export function getToken() {
  return localStorage.getItem(TOKEN);
}

export function removeToken() {
  localStorage.removeItem(TOKEN);        
}
