import React from "react";
import { useState, useEffect } from "react";
import {
  getDataToken,
  postDataToken,
  putDataToken,
} from "../../../api/dataToken";
import {
  formatofecha,
  diasestandarizados,
  diasespecial,
  diascomplejo,
  diasMaxTramite,
} from "../../../api/getUmbrales";
import { Select1 } from "../../Tools/Selectss/Select1";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import DialogCascadeTwoButtons from "./Dialog/DialogCascadeTwoButtons";
import moment from "moment";
import getDate from "../Function/obtenerFecha";
import getHours from "../Function/obtenerHora";
import SumaAsegurada from "../Function/sumaAsegurada";
import obtenerPoliza from "../Function/obtenerPoliza";
import obtenerDias from "../Function/obtenerDias";

//Idioma
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import {
  PREFIJO,
  SUFIJO,
  PLAESTA,
  PLACOMP,
  PLAESPC,
  UNMILL,
  DIEZMILL,
  IDESTATUSFALTINF,
  IDCOMESPC,
  COBMUJER,
  ESTRAPRC,
  IDMODREGISTRO,
  IDCAPDASINI,
  IDGENESINI,
  IDENVDIC,
} from "../../../utils/constanstoken";
import GetTareas from "../../../api/getTareas";

export default function CompReclamacion(props) {
  const divStyles = {
    marginTop: "28px",
  };

  // Traemos los datos que nos estan pasando de la pantalla anterior
  const location = useLocation();
  const idRow = location.state;
  console.log(idRow);
  const [FolioRec, setFolioRec] = useState(idRow?.RECT_idfolio?.RECT_idfolio);

  //Obtenemos las tareas para nuestro módulo
  const objetosCoincidentes = GetTareas(IDMODREGISTRO);
  console.log(objetosCoincidentes);

  //Estado para guardar los datos.
  const [IdPersonaApi, setIdPersonaApi] = useState(
    idRow?.PER_idpersona?.PER_idpersona
  );
  const [Datos, setDatos] = React.useState(idRow);
  console.log(Datos);
  console.log(IdPersonaApi);

  //Estado para guardar los datos.
  const [DatosComunicacion, setDatosComunicacion] = React.useState([]);
  //Guardamos el id de las pólizas
  const [IDPoliza, setIDPoliza] = useState([]);
  const [polizastra, setpolizastra] = useState([]);
  //Obtener usuario.
  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");

  //Obtener datos del asegurado y su id
  const getData = async () => {
    try {
      var result = await getDataToken(
        `charolacomunicacion/get_idFolio/${FolioRec}/`
      );
      setDatosComunicacion(result);
      var resultPolizas = await getDataToken(
        `polizaportramites/get_folio/${FolioRec}/`
      );
      setpolizastra(resultPolizas);
      setIDPoliza(
        resultPolizas.map((persona) => persona.POL_idpoliza?.POL_idpoliza)
      );
      var resultAnalista = await getDataToken(`login/get_correo/${email}/`);
      setnombreAnalista(
        resultAnalista.SNYTLOG_username +
          " " +
          resultAnalista.SNYTLOG_surnamedad +
          " " +
          resultAnalista.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  console.log(DatosComunicacion);
  console.log(nombreAnalista);
  console.log(polizastra);

  //Validamos si existe

  var contenidoPolizas = "";
  for (var key in polizastra) {
    if (polizastra[key]?.POL_idpoliza?.POL_numpoliza) {
      contenidoPolizas += polizastra[key].POL_idpoliza.POL_numpoliza + "\n"; // Agregar un salto de línea
    }
  }

  //Obtenemos la fecha de recepción del trámite
  const fechaRecepcion = moment(Datos.RECT_idfolio?.RECT_fecharecepcion).format(
    "YYYY-MM-DD"
  );
  console.log("Fecha Recepcion: " + fechaRecepcion);

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");
  console.log(moment(fechaEmpAn).format(formatofecha));
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();
  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;
  console.log("fechaActSIN" + fechaActSIN);

  let personasFiltradas;

  //Estados para guardar datos del dictaminador
  const [IdPersonaDic, setIdPersonaDic] = useState("");
  const [NomDic, setNomDic] = useState("");

  //Buscar el dictaminador al azar
  const getDictaminador = async () => {
    try {
      var result = await getDataToken(`login/get_listado/`);
      personasFiltradas = result.filter(
        (persona) => persona.SNYTLOG_dictamen === true
      );
      personasFiltradas.forEach((persona) => {
        console.log(persona.id);
      });

      // Elegir una persona aleatoria
      const indiceAleatorio = Math.floor(
        Math.random() * personasFiltradas.length
      );
      const personaAleatoria = personasFiltradas[indiceAleatorio];
      setIdPersonaDic(personaAleatoria.id);
      setNomDic(
        personaAleatoria.SNYTLOG_username +
          " " +
          personaAleatoria.SNYTLOG_surnamedad +
          " " +
          personaAleatoria.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getDictaminador();
  }, []);

  console.log("Dictaminador" + IdPersonaDic);

  //Estados para guardar los datos de clasificación
  const [clasificacion, setClasificacion] = useState([]);
  const [idclasificacion, setIdclasificacion] = useState("");

  // Traemos los datos de clasificación para llenar el combo
  const getClasificacion = async () => {
    try {
      var result = await getDataToken(`complejidadsiniestros/get_listado/`);
      let array = result.map((element) => {
        return {
          ...element,
          identificador: element.SINC_idcomplejidadsiniestro,
          texto: element.SINC_complejidad,
        };
      });
      setClasificacion(array);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getClasificacion();
  }, []);

  //Estado donde se guardara el dato
  const [plataforma, setPlataforma] = useState("");

  const handleChange = (e) => {
    const dataClasificacion = parseInt(e.target.value);
    clasificacion.forEach((element) => {
      if (dataClasificacion === element.SINC_idcomplejidadsiniestro) {
        setIdclasificacion(element.SINC_idcomplejidadsiniestro);
        setPlataforma(element.SINC_complejidad.toLowerCase());
      }
    });
  };

  console.log("Id clasificación " + idclasificacion);
  console.log("Plataforma " + plataforma);

  //Guarda todo los datos del arreglo
  const [tipificacionSiniestro, settipificacionSiniestro] = useState([]);
  //Guardar el id y datos del siniestro
  const [IdSiniestroREC, setIdSiniestroREC] = useState([]);
  const [NoSiniestroAPI, setNoSiniestroAPI] = useState([]);
  const [fechaSin, setfechaSin] = useState([]);
  const [IdSiniestro, setIdSiniestro] = useState("");

  //Obtenemos el ultimo folio para la función de generar folio

  const getUltimoFolio = async () => {
    try {
      var result = await getDataToken(`siniestros/get_listado/`);
      const siniestrosEncontrados = result.filter(
        (persona) => persona.RECT_idfolio?.RECT_idfolio === FolioRec
      );
      settipificacionSiniestro(siniestrosEncontrados);
      setIdSiniestroREC(
        siniestrosEncontrados.map((persona) => persona.SIN_idsiniestro)
      );
      setNoSiniestroAPI(
        siniestrosEncontrados.map((persona) => persona.SIN_numerosiniestro)
      );
      setfechaSin(
        siniestrosEncontrados.map((persona) => persona.SIN_actualizofecha)
      );
      const ultimoRegistro = Math.max(
        ...result.map((registro) => registro.SIN_idsiniestro)
      );
      console.log("Resultado de último Siniestros=>", ultimoRegistro);
      setIdSiniestro(ultimoRegistro + 1);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getUltimoFolio();
  }, []);

  if (IdSiniestroREC) {
    console.log("ID del siniestro" + IdSiniestroREC);
    console.log("nO.Siniestro del siniestro" + NoSiniestroAPI);
    console.log("Fecha del siniestro" + fechaSin);
    console.log(moment(fechaSin[0]).format(formatofecha));
    console.log(tipificacionSiniestro);
    console.log(
      tipificacionSiniestro[0]?.CSIN_idclasificacionsiniestros?.CSIN_clasificacionsiniestros?.toLowerCase()
    );
  } else {
    console.log("No se encontró ningún registro que coincida con la búsqueda.");
  }

  //Generar un folio al azar
  let ejecutado = false;

  function generarFolio(IdSiniestrorecepcion) {
    const numero = Math.floor(Math.random() * 1000000);
    const numeroFolio = ("000000" + numero).slice(-6);
    return PREFIJO + numeroFolio + IdSiniestrorecepcion + IdSiniestro + SUFIJO;
  }

  const [NoSiniestro, setNoSiniestro] = useState({}); // Declarar NoSiniestro como estado

  useEffect(() => {
    if (!ejecutado) {
      const nuevosSiniestros = {};
      for (let i = 0; i < IdSiniestroREC.length; i++) {
        const folio = generarFolio(IdSiniestroREC[i]);
        nuevosSiniestros[i] = folio;
      }
      setNoSiniestro(nuevosSiniestros); // Actualizar el estado usando setNoSiniestro
      ejecutado = true;
    }
  }, [IdSiniestroREC, ejecutado]);

  // Ejemplo de uso
  console.log("Número de siniestro", NoSiniestro); // No uses el "+" para concatenar strings, utiliza una coma ","

  //Variables que se ocupan para poder almacenar el valor seleccionado

  const [Title, setTitle] = useState("");
  const [description1, setdescription1] = useState("");
  const [description2, setdescription2] = useState("");
  const [description3, setdescription3] = useState("");
  const [description4, setdescription4] = useState("");
  const [description5, setdescription5] = useState("");
  const [description6, setdescription6] = useState("");
  const [description7, setdescription7] = useState("");
  const [description8, setdescription8] = useState("");
  const [postMedico, setpostmedico] = useState(false);
  const [visibleboton, setvisibleboton] = useState("");

  //Estado donde se guardara el resultado
  const [coberturasSA, setCoberturasSA] = useState([]);

  //Obtener todas las coberturas
  const getCPXT = async () => {
    try {
      var result = await getDataToken(
        `coberturaspolizasportramite/get_folio/${FolioRec}/`
      );
      setCoberturasSA(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  React.useEffect(() => {
    getCPXT();
  }, []);

  console.log(coberturasSA);

  //Validar cobertura mujer
  const coberturaMujer = coberturasSA?.some(
    (item) => item.COB_idcobertura.COB_cobertura.toLowerCase() === COBMUJER
  );

  console.log(coberturaMujer);

  // Arreglo para almacenar las fechas de emisión
  const fechaEmision = [];

  // Objeto auxiliar para realizar la verificación
  const polizasRegistradas = {};

  // Iterar sobre cada registro
  coberturasSA?.forEach((registro) => {
    const idPoliza = registro?.POL_idpoliza?.POL_idpoliza;
    const fechasEmision = registro?.POL_idpoliza?.POL_polizafecemisión;

    // Verificar si ya se ha registrado una fecha para la póliza actual
    if (!polizasRegistradas[idPoliza]) {
      // Guardar la fecha de emisión en el arreglo
      const polizaData = {
        idPoliza: idPoliza,
        fechaEmision: fechasEmision,
      };
      fechaEmision.push(polizaData);

      // Marcar la póliza como registrada en el objeto auxiliar
      polizasRegistradas[idPoliza] = true;
    }
  });

  // Imprimir las fechas de emisión en la consola
  console.log(fechaEmision);

  // Variable para almacenar la suma de CPT_sumaasegurada
  var total = coberturasSA?.reduce(function (acc, poliza) {
    var idPoliza = poliza?.POL_idpoliza?.POL_idpoliza;

    if (!acc[idPoliza]) {
      acc[idPoliza] = 0;
    }

    acc[idPoliza] += poliza.CPT_sumaasegurada;
    return acc;
  }, {});

  console.log(total);

  // Validar SA
  // Extraer los valores de cantidad del objeto total
  const cantidades = Object.values(total);
  console.log(cantidades);

  // Pasar las cantidades como argumento a la función SumaAsegurada
  let resultadoSA;
  cantidades.forEach(function (cantidad, index) {
    resultadoSA = SumaAsegurada(cantidad);

    console.log("Resultado para cantidad", index + 1, ":", resultadoSA);
  });

  // Validar póliza disputable
  const resultadoPoliza = {};
  fechaEmision.forEach((emision, index) => {
    // Acceder directamente a las propiedades idPoliza y fechaEmision de la póliza
    const { idPoliza, fechaEmision } = emision;
    let resultadoFinal = obtenerPoliza(fechaEmision, fechaRecepcion);
    const objResultado = {
      estatusdisputable: resultadoFinal,
      idPoliza: idPoliza,
    };
    resultadoPoliza[index] = objResultado;
  });

  console.log("Resultado póliza", resultadoPoliza);

  // Para las validaciones juntaremos los resultados en un mismo arreglo
  const combinacionArr1 = tipificacionSiniestro.map((item, index) => {
    // Combinar el elemento del arreglo tipificacionSiniestro con el elemento del arreglo resultadoPoliza en la misma posición
    const combinedItem = {
      ...item,
      ...resultadoPoliza[index],
    };

    return combinedItem;
  });

  console.log(combinacionArr1);

  //Arreglo 2 para juntar los datos

  const combinacionArr2 = combinacionArr1.map((obj) => {
    const { idPoliza } = obj;
    return {
      ...obj,
      ["total"]: total[idPoliza],
    };
  });

  console.log(combinacionArr2);

  // Función para combinar los arreglos por POL_idpoliza
  function combinarArreglosPorIdPoliza(arreglo1, arreglo2) {
    // Creamos un objeto para mapear los elementos del arreglo1 por POL_idpoliza
    const mapaArreglo1 = arreglo1.reduce((mapa, elemento) => {
      mapa[elemento.POL_idpoliza.POL_idpoliza] = elemento;
      return mapa;
    }, {});

    // Combinamos los arreglos por POL_idpoliza
    const resultado = arreglo2.map((elemento) => {
      const idPoliza = elemento.idPoliza;
      const elementoArreglo1 = mapaArreglo1[idPoliza];
      return {
        ...elementoArreglo1,
        ...elemento,
      };
    });

    return resultado;
  }

  // Llamamos a la función para combinar los arreglos
  const arrCombinado = combinarArreglosPorIdPoliza(polizastra, combinacionArr2);

  // Mostramos el resultado combinado
  console.log(arrCombinado);

  const arreglo1Convertido = Object.values(NoSiniestro);

  const resultadoCombinado = arreglo1Convertido.map((elemento, index) => {
    return {
      ...arrCombinado[index], // Combina los elementos del arreglo2 usando la posición del arreglo1
      ["NumSinigenerado"]: elemento, // Agrega el elemento del arreglo1 usando el índice como clave
    };
  });

  console.log(resultadoCombinado);

  // Comprobamos si alguno de los objetos tiene la propiedad TEST_estatustramite con el valor "falta informacion"
  const existeFaltaInformacion = coberturasSA.some(
    (objeto) =>
      objeto.RECT_idfolio?.TEST_idestatustramite?.TEST_idestatustramite ===
      IDESTATUSFALTINF
  );

  // 'existeFaltaInformacion' será true si algún objeto cumple la condición, y false si ninguno la cumple.
  console.log("Existe falta de información:", existeFaltaInformacion);

  let bandera = false;

  if (!bandera && existeFaltaInformacion) {
    resultadoCombinado.forEach((siniestros) => {
      let siniestro = {
        SINC_idcomplejidadsiniestro: IDCOMESPC,
        RECT_idfolio: FolioRec,
        SIN_actualizofecha: fechaActSIN,
        SIN_actualizousuario: nombreAnalista,
      };
      putDataToken(
        `siniestros/get_id/${siniestros.SIN_idsiniestro}/`,
        siniestro
      ).then((data) => {
        console.log(`Registro ${siniestros.SIN_idsiniestro} actualizado`);
      });
    });
    bandera = true;
  }

  let dias, diasMax;

  //Asignar los tiempos de respuesta
  function obtenerTiempos(plataforma) {
    console.log("entro x2");
    dias = diasMaxTramite;

    resultadoCombinado.map((siniestro) => {
      if (
        (siniestro?.estatusdisputable === false &&
          plataforma === PLAESPC &&
          siniestro?.total < UNMILL &&
          siniestro?.CSIN_idclasificacionsiniestros
            ?.CSIN_idclasificacionsiniestros !== null) ||
        (siniestro?.estatusdisputable === false &&
          plataforma === PLAESPC &&
          siniestro?.total > UNMILL &&
          siniestro?.total < DIEZMILL &&
          siniestro?.CSIN_idclasificacionsiniestros
            ?.CSIN_idclasificacionsiniestros !== null) ||
        (siniestro?.estatusdisputable === false &&
          plataforma === PLAESPC &&
          siniestro?.total > DIEZMILL &&
          siniestro?.CSIN_idclasificacionsiniestros
            ?.CSIN_idclasificacionsiniestros !== null)
      ) {
        diasMax = diasespecial;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion1");
      } else if (plataforma === PLAESTA) {
        diasMax = diasestandarizados;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion2");
      } else if (plataforma === PLACOMP) {
        diasMax = diascomplejo;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion3");
      } else if (plataforma === PLAESPC) {
        diasMax = diasespecial;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion4");
      } else if (coberturaMujer && plataforma === PLACOMP) {
        diasMax = diascomplejo;
        //Obtener los dias que sobran para el trámite
        dias = obtenerDias(fechaRecepcion, fechaEmpAn, diasMax);
        console.log(dias);
        console.log("entro condicion5");
      }
    });

    console.log("dias" + dias);
  }

  if (plataforma.length > 0) {
    obtenerTiempos(idclasificacion);
    console.log("entro");
  }

  //Variable para ver si se mandan todos los registro antes del put
  let data = [],
    itemData;

  console.log(data);

  let dataSiniestros = [],
    siniestro;

  console.log(dataSiniestros);

  const handleClickMedico = () => {
    window.location.href = "registro/inicio";
  };

  async function putCharolaRegistro(aux) {
    try {
      var result = await putDataToken(
        `charolaregistro/get_id/${Datos.CHREG_idcharolareg}/`,
        aux
      );
      console.log("Registro exitoso", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async function postFoliosiniestro(aux) {
    try {
      var result = await postDataToken(
        `polizasfolioxsiniestro/get_listado/`,
        aux
      );
      console.log("Registro exitoso", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  //Estado donde se guardara el resultado
  const [estatusTramite, setestatusTramite] = useState([]);

  //Obtener todos los estatus del trámite
  const getestatusTramite = async () => {
    try {
      var result = await getDataToken(`estatustramite/get_id/${ESTRAPRC}/`);
      setestatusTramite(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  React.useEffect(() => {
    getestatusTramite();
  }, []);

  console.log(estatusTramite?.TEST_idestatustramite);

  async function putRecepcion(aux) {
    try {
      var result = await putDataToken(`recepcion/get_id/${FolioRec}/`, aux);
      console.log("Actualización exitosa", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  // Construir la descripción combinada con los datos de resultadoCombinado
  const generateDescription = () => {
    let description = "";
    resultadoCombinado.forEach((elemento, index) => {
      const numeroPosicion = index + 1;
      description += `Número de Siniestro ${numeroPosicion}: ${elemento?.NumSinigenerado}`;
    });
    return description;
  };

  const generateDescription2 = () => {
    let description = "";
    NoSiniestroAPI.forEach((numeroSiniestro, index) => {
      const numeroPosicion = index + 1;
      description += `Número de Siniestro ${numeroPosicion}: ${numeroSiniestro}\n`;
    });
    return description;
  };

  //Validar si existe un número de siniestro en la variable
  const noContieneNuloOEmptyString = NoSiniestroAPI.every(
    (valor) => valor !== null && valor !== ""
  );

  //Estados donde se guarda el mensaje de los datos del formulario
  const [msg16, setmsg16] = useState("");

  //Validamos si estan nulos los datos
  useEffect(() => {
    if (idclasificacion === "") {
      setmsg16(
        <FormattedMessage
          id="regisObliS"
          defaultMessage="/ Este campo es obligatorio"
        />
      );
    } else {
      setmsg16("");
    }
  }, [idclasificacion]);

  //Conocer los datos de las variables
  console.log(NoSiniestro);
  console.log(NoSiniestroAPI);
  console.log(Datos.PER_idpersona?.PER_idpersona);
  console.log(NoSiniestro);
  console.log(idclasificacion);
  console.log(FolioRec);
  console.log(fechaActSIN);
  console.log(dias);

  //Metodo para que las variables puedan ser leidas
  const validateForm = (e) => {
    e.preventDefault();
    let charola = {
      CHREG_entregado: true,
      CHREG_fechaentregado: fechaActSIN,
      RECT_idfolio: FolioRec,
      PER_idpersona: IdPersonaApi,
      CHREG_actualizofecha: fechaActSIN,
      CHREG_actualizousuario: nombreAnalista,
    };

    let recepcion = {
      SINC_idcomplejidadsiniestro:
        Datos?.RECT_idfolio?.SINC_idcomplejidadsiniestro
          ?.TRA_idtipotramitesiniestro,
      TEST_idestatustramite: estatusTramite?.TEST_idestatustramite,
      RECT_actualizofecha: fechaActSIN,
      RECT_actualizousuario: nombreAnalista,
    };

    if (
      Object.keys(NoSiniestro).length > 0 &&
      idclasificacion != "" &&
      FolioRec != "" &&
      fechaActSIN != ""
    ) {
      if (!noContieneNuloOEmptyString) {
        //ID a buscar
        const IDABuscar = [IDCAPDASINI, IDGENESINI, IDENVDIC];
        objetosCoincidentes.forEach((objeto) => {
          if (IDABuscar.includes(objeto.TARE_idtarea.TARE_idtarea)) {
            objeto.estado = true;
          }
        });
        //Primer put a la tabla siniestros
        resultadoCombinado.forEach((siniestros) => {
          siniestro = {
            SIN_numerosiniestro: siniestros.NumSinigenerado,
            SINC_idcomplejidadsiniestro: idclasificacion,
            RECT_idfolio: FolioRec,
            SIN_actualizofecha: fechaActSIN,
            SIN_actualizousuario: nombreAnalista,
          };
          putDataToken(
            `siniestros/get_id/${siniestros.SIN_idsiniestro}/`,
            siniestro
          ).then((data) => {
            console.log(`Registro ${siniestros.SIN_idsiniestro} actualizado`);
          });
          dataSiniestros.push(siniestro);
        });
        console.log(dataSiniestros);

        //Quinto put a la charola registro
        putCharolaRegistro(charola);

        //Sexto put
        putRecepcion(recepcion);

        //Septimo post
        for (const item of resultadoCombinado) {
          let foliosiniestro = {
            RECT_idfolio: FolioRec,
            SIN_idsiniestro: item?.SIN_idsiniestro,
            POL_idpoliza: item?.POL_idpoliza?.POL_idpoliza,
            PER_idpersona: IdPersonaApi,
            IDTRABAJADOR: IdPersonaDic,
            PXFS_actualizofecha: fechaActSIN,
            PXFS_actualizousuario: nombreAnalista,
          };
          postFoliosiniestro(foliosiniestro);
        }

        //Septimo put
        DatosComunicacion.forEach((Comunicacions) => {
          let Comunicacion = {
            RECT_idfolio: Comunicacions.RECT_idfolio.RECT_idfolio,
            PER_idpersona: Comunicacions.PER_idpersona.PER_idpersona,
            POL_idpoliza: Comunicacions.POL_idpoliza.POL_idpoliza,
            Usuario_Login: Comunicacions.Usuario_Login.id,
            CHCOM_fechaentregado: Comunicacions.CHCOM_fechaentregado,
            CHCOM_tiemporespuesta: dias,
            CHCOM_actualizofecha: fechaActSIN,
            CHCOM_actualizousuario: nombreAnalista,
          };
          putDataToken(
            `charolacomunicacion/get_id/${Comunicacions.CHCOM_idcharolacom}/`,
            Comunicacion
          ).then((data) => {
            console.log(
              `Registro ${Comunicacions.CHCOM_idcharolacom} actualizado`
            );
          });
        });

        // Filtrar el arreglo para obtener solo los registros con el campo 'estado'.
        const registrosConEstado = objetosCoincidentes.filter(
          (registro) => registro.estado
        );
        console.log(registrosConEstado);

        let bandera = false;

        for (const itempo of resultadoCombinado) {
          for (const itemTra of registrosConEstado) {
            for (const siniestro of tipificacionSiniestro) {
              let registro = {
                RECT_idfolio: FolioRec,
                POL_idpoliza: itempo.POL_idpoliza.POL_idpoliza,
                WTM_idwftareasxmodulos: itemTra.WTM_idwftareasxmodulos,
                PER_idpersona: IdPersonaApi,
                SIN_idsiniestro: siniestro?.SIN_idsiniestro,
                WKT_fechawktramite: fechaActSIN,
                WKT_seejecuto: itemTra.estado,
                WKT_actualizofecha: fechaActSIN,
                WKT_actualizousuario: nombreAnalista,
              };
              postDataToken(`wktramite/get_listado/`, registro).then((data) => {
                console.log(`Registro exitoso wktramite`, data);
              });
              bandera = true;
            }
          }
        }
        if (bandera) {
          registrosConEstado.forEach((objeto) => {
            delete objeto.estado;
          });
        }

        resultadoCombinado.forEach((polizas) => {
          const poliza = {
            POL_polizaindisputable: polizas.estatusdisputable,
            POL_actualizofecha: fechaActSIN,
            POL_actualizousuario: nombreAnalista,
          };
          putDataToken(
            `polizas/get_id/${polizas.POL_idpoliza.POL_idpoliza}/`,
            poliza
          ).then((data) => {
            console.log(
              `Registro de poliza ${polizas.POL_idpoliza.POL_idpoliza} actualizado`,
              data
            );
          });
        });

        //Datos que se mostraran en el dialog
        setTitle(
          <FormattedMessage
            id="regisAsgSi"
            defaultMessage="Asignación de número de siniestro"
          />
        );
        setdescription1(
          <FormattedMessage
            id="recepFolio"
            defaultMessage="Folio: {folio}"
            values={{ folio: Datos.RECT_idfolio?.RECT_numerofolio }}
          />
        );
        setdescription2(
          <FormattedMessage
            id="recepNomAse"
            defaultMessage="Nombre del Asegurado: {nomAse}"
            values={{
              nomAse:
                Datos.PER_idpersona?.PER_nombre +
                " " +
                Datos.PER_idpersona?.PER_apellidopaterno +
                " " +
                Datos.PER_idpersona?.PER_apellidomaterno,
            }}
          />
        );
        setdescription3(
          <FormattedMessage
            id="recepFeCre"
            defaultMessage="Fecha de creación: {fecha}"
            values={{ fecha: moment(fechaEmpAn).format(formatofecha) }}
          />
        );
        setdescription4(
          <FormattedMessage
            id="regisHoraCrea"
            defaultMessage="Hora de creación: {hora}"
            values={{ hora: horaActual }}
          />
        );
        setdescription5(generateDescription());
        setdescription6(
          <FormattedMessage
            id="regisAnalista"
            defaultMessage="Analista: {analista}"
            values={{ analista: nombreAnalista }}
          />
        );
        setdescription7(
          <FormattedMessage
            id="regisDic"
            defaultMessage="Dictaminador: {dictaminador}"
            values={{ dictaminador: NomDic }}
          />
        );
      } else {
        alert("Valor duplicado" + NoSiniestroAPI);
        setvisibleboton("true");
        setTitle(
          <FormattedMessage
            id="regisReExist"
            defaultMessage="Ya existe un registro de siniestro con estos datos"
          />
        );
        setdescription1(
          <FormattedMessage
            id="recepFolio"
            defaultMessage="Folio: {folio}"
            values={{ folio: Datos.RECT_idfolio?.RECT_numerofolio }}
          />
        );
        setdescription2(
          <FormattedMessage
            id="recepNomAse"
            defaultMessage="Nombre del Asegurado: {nomAse}"
            values={{
              nomAse:
                Datos.PER_idpersona?.PER_nombre +
                " " +
                Datos.PER_idpersona?.PER_apellidopaterno +
                " " +
                Datos.PER_idpersona?.PER_apellidomaterno,
            }}
          />
        );
        setdescription3(
          <FormattedMessage
            id="recepFeCre"
            defaultMessage="Fecha de creación: {fecha}"
            values={{ fecha: moment(fechaSin[0]).format(formatofecha) }}
          />
        );
        setdescription4(
          <FormattedMessage
            id="regisHoraCrea"
            defaultMessage="Hora de creación: {hora}"
            values={{ hora: moment(fechaSin[0]).format("HH:mm:ss") }}
          />
        );
        setdescription5(generateDescription2());

        setTimeout(() => {
          window.location.href = "/registro/inicio";
        }, 5000);
      }
    } else {
      setvisibleboton("true");
      setTitle(
        <FormattedMessage
          id="regisOcuError"
          defaultMessage="Ha ocurrido un error"
        />
      );
      setdescription1(
        <FormattedMessage
          id="regisIntTar"
          defaultMessage="Intentelo más tarde."
        />
      );
      setdescription2(
        <FormattedMessage
          id="regisRedTarde"
          defaultMessage="En un momento se redireccionara al apartado de inicio."
        />
      );
      setTimeout(() => {
        window.location.href = "/registro/inicio";
      }, 5000);
    }
  };
  console.log("Valor postMedico" + postMedico);

  return (
    <form onSubmit={validateForm}>
      <Box sx={{ m: 1, minWidth: 25 }}>
        <Grid container>
          <Grid xs={12}>
            <h3-header-card>
              <FormattedMessage
                id="datosDaAse"
                defaultMessage="Datos del asegurado"
              />
            </h3-header-card>
          </Grid>
          <Grid xs={12}></Grid>
          <br />
          <br />
          <Grid xs={3.5}>
            <h3-title-card>
              <FormattedMessage
                id="datosNombs"
                defaultMessage="Nombre del asegurado"
              />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {Datos?.PER_idpersona?.PER_nombre?.toUpperCase() +
                " " +
                Datos?.PER_idpersona?.PER_apellidopaterno?.toUpperCase() +
                " " +
                Datos?.PER_idpersona?.PER_apellidomaterno?.toUpperCase()}
            </h3-subtitle-card>
            <br />
          </Grid>
          <Grid xs={3.5}>
            <h3-title-card>
              <FormattedMessage
                id="datosTramite"
                defaultMessage="Tipo trámite"
              />
            </h3-title-card>
            <br />
            <h3-subtitle-card>
              {Datos?.RECT_idfolio?.SINC_idcomplejidadsiniestro?.TRA_destipotramitesiniestro.toUpperCase()}
            </h3-subtitle-card>
          </Grid>
          <Grid xs={3.5}>
            <h3-title-card>
              <FormattedMessage id="dictamPoli" defaultMessage="Pólizas" />
            </h3-title-card>
            <br />
            <h3-subtitle-card>{contenidoPolizas}</h3-subtitle-card>
          </Grid>
        </Grid>
        <hr />
        <br />
        <Grid container>
          <Grid xs={3}>
            <br />
            <Select1
              sx={{
                minWidth: 290,
                maxWidth: 290,
                height: 40,
                background: "white",
              }}
              label={
                <FormattedMessage
                  id="dictamClasV"
                  defaultMessage={`Clasificación {clasi}`}
                  values={{ clasi: msg16 }}
                />
              }
              array={clasificacion}
              onChange={handleChange}
            />
          </Grid>
          <Grid xs={1}></Grid>
          <Grid xs={1.7}>
            <div style={divStyles}>
              <ButtonVino
                estatus={false}
                label={
                  <FormattedMessage id="globCance" defaultMessage="Cancelar" />
                }
                enlace="/registro/inicio"
              />
            </div>
          </Grid>
          <Grid xs={2}>
            <div style={divStyles}>
              <DialogCascadeTwoButtons
                mainButton={
                  <FormattedMessage
                    id="regisGenSi"
                    defaultMessage="Generar Siniestro"
                  />
                }
                type="submit"
                title={Title}
                description1={description1}
                description2={description2}
                description3={description3}
                description4={description4}
                horaActual
                description5={description5}
                description6={description6}
                description7={description7}
                description8={description8}
                visible={visibleboton}
                button={
                  <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
                }
                estatus={idclasificacion > 0 ? false : true}
                onClickAceptar={handleClickMedico}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}
