import * as React from "react";

//tablas
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//componentes de mui
import TXTField from "../../../Tools/TextField/TextField";
import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";


//idioma
import { FormattedMessage } from "react-intl";
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import TablePagination from "@mui/material/TablePagination";
//useEffect
import { useEffect } from "react";

//importacion de los token para acceder al proyecto
import { getDataToken } from "../../../../api/dataToken";
import { PERR_AGENTE, PERR_ASEGURADO, PERR_BENEFICIARIO, PERR_CONTRATANTE } from "../../../../utils/constanstoken";
import RadioDinamico from "../../../Tools/RadioButton/RadioDinamico";
import DialogFoundAse from "../Dialog/DialogFoundAse";
import Switch from "@mui/material/Switch";
import DialogConfirmAviso from "../Dialog/DialogConfirmarAviso";
import DialogFolio from "../Dialog/DialogFolio";

export default function TableAgregarDocumento() {

  const [datosApiJoinRec, setDatosApiJoinRec] = useState([]);

  //TODO: GET
  const getDataJoinRec = async () => {
    try {
      await getDataToken("polizaportramites/get_listado/").then(
        (data) => {
          console.log("API PolxTram:", data)
          setDatosApiJoinRec(data)

        }
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getDataJoinRec();
  }, []);





  /* CONSTANTES DE ESTADOS*/

  const [BtnSiguiente, setBtnSiguiente] = useState(true); //Este estado validara el boton siguiente
  const [foundFolio, setFoundFolio] = useState(false); //Este estado validara el boton siguiente
  const [habilitaLink, setHabilitaLink] = useState(false); //activacion y desactivacion del link

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const [numPoliza, setNumPoliza] = useState("");
  const [dialogFoundFolio, setDialogFoundFolio] = useState(false);
  const [dialogFoundAse, setDialogFoundAse] = useState(false);
  const [numFolio, setNumFolio] = useState("");
  const [reclamacion, setReclamacion] = useState("");
  const [datosAse, setDatosAse] = useState({
    nombre: "",
    apePat: "",
    apeMat: ""
  })

  async function buscarFolio(numFolio) {
    if (numFolio !== "") {
      //BUSCAR LA PÓLIZA EN LA API JOIN 
      // Filtrar la estructura para obtener el objeto completo con sus objetos hijos
      let resultados = [];
      console.log("Data join", datosApiJoinRec);
      // Utilizar la función find para buscar el objeto
      for (const objetosHijos of datosApiJoinRec) {
        // let contieneValorBuscado = objetosHijos.some(function (obj) {
        //   return obj["POL_numpoliza"] === numPoliza;
        // });

        // if (contieneValorBuscado) {
        //   resultados.push(objetosHijos);
        // }
        if(objetosHijos.RECT_idfolio.RECT_numerofolio==numFolio) {
          resultados.push(objetosHijos);
        }
      };
      console.log("Resultados api join", resultados)
      if (resultados.length === 0) {
        // Si NO hay coincidencias en sinay mostrar diálogo. 
        setFoundFolio(false)
        setDialogFoundFolio(true)
      } else {

        console.log("Objeto sinay:", resultados);
        let asegurado = {};
        let contratante = {};
        //Recuperamos el numero de folio y tipo de reclamación de la api recepción 
        // let objectRecep = await getDataToken(`recepcion/get_id/${resultados[0][0]?.RECT_idfolio}/`);
        let objectRecep = await getDataToken(`recepcion/get_id/${resultados[0]?.RECT_idfolio}/`);
        setNumFolio(objectRecep?.RECT_numerofolio)
        setReclamacion(objectRecep?.SINC_idcomplejidadsiniestro?.TRA_destipotramitesiniestro)
        
        let personasxPoliza = await getDataToken("personasxpoliza/get_poliza/"+resultados[0].POL_idpoliza.POL_idpoliza+"/")
        console.log("personasxPoliza", personasxPoliza)
        personasxPoliza.forEach(poliza => {
          if (poliza.PER_idpersona.PERR_idrol.PERR_idrol == PERR_ASEGURADO.id) {
            asegurado = poliza
            console.log("Encontre al asegurado!!!", poliza)
          } else if (poliza.PER_idpersona.PERR_idrol.PERR_idrol == PERR_CONTRATANTE.id) {
            contratante = poliza
            console.log("Encontre al contratante!!!", poliza)

          }
        })
        if (Object.keys(asegurado).length === 0) {
          console.log("El objeto asegurado está vacío.");
          return;
        }
        let jsonPM = [
          {//CONTRATANTE
            "POL_idpoliza": contratante?.POL_idpoliza?.POL_idpoliza,
            "POL_numpoliza": contratante?.POL_idpoliza?.POL_numpoliza,
            "PRO_cveproducto": contratante?.POL_idpoliza?.PRO_idproductos?.PRO_cveproducto, //Pendiente, no hay dato similar en el PM (crear constante?)
            "PRO_descripcion": contratante?.POL_idpoliza?.PRO_idproductos?.PRO_descripcion,
            "EST_descripcion": contratante?.POL_idpoliza?.EST_idestatuspoliza?.EST_descripcion,
            "PER_nombre": contratante?.PER_idpersona?.PER_nombre,
            "PER_apellidopaterno": contratante?.PER_idpersona?.PER_apellidopaterno,
            "PER_apellidomaterno": contratante?.PER_idpersona?.PER_apellidomaterno,
            "PER_RFC": contratante?.PER_idpersona?.PER_RFC,
            "PER_CURP": contratante?.PER_idpersona?.PER_CURP,
            "PERR_idrol": PERR_CONTRATANTE.id,
            "PERT_idtipopersona": 1, //crear constante
            "POL_idpoliza": contratante?.POL_idpoliza?.POL_idpoliza,
            "RECT_idfolio": resultados[0]?.RECT_idfolio,
            "PER_idpersona": contratante?.PER_idpersona?.PER_idpersona,

          },
          {//ASEGURADO
            "POL_idpoliza": asegurado?.POL_idpoliza?.POL_idpoliza,
            "POL_numpoliza": asegurado?.POL_idpoliza?.POL_numpoliza,
            "PRO_cveproducto": asegurado?.POL_idpoliza?.PRO_idproductos?.PRO_cveproducto, //Pendiente, no hay dato similar en el PM (crear constante?)
            "PRO_descripcion": asegurado?.POL_idpoliza?.PRO_idproductos?.PRO_descripcion,
            "EST_descripcion": asegurado?.POL_idpoliza?.EST_idestatuspoliza?.EST_descripcion,
            "EST_idestatuspoliza": asegurado?.POL_idpoliza?.EST_idestatuspoliza?.EST_idestatuspoliza, //Crear constantes de estatus poliza, ya que no está especificado en el PM
            "POL_polizafecemisión": asegurado?.POL_idpoliza?.POL_polizafecemisión,
            "POL_polizafinvigencia": asegurado?.POL_idpoliza?.POL_polizafinvigencia,
            "PRO_idproductos": asegurado?.POL_idpoliza?.PRO_idproductos?.PRO_idproducto, //Crear constantes de productos, ya que no está especificado en el PM
            "PER_nombre": asegurado?.PER_idpersona?.PER_nombre,
            "PER_apellidopaterno": asegurado?.PER_idpersona?.PER_apellidopaterno,
            "PER_apellidomaterno": asegurado?.PER_idpersona?.PER_apellidomaterno,
            "PER_RFC": asegurado?.PER_idpersona?.PER_RFC,
            "PER_CURP": asegurado?.PER_idpersona?.PER_CURP,
            "PERR_idrol": PERR_ASEGURADO.id,
            "PERT_idtipopersona": asegurado?.PER_idpersona?.PERT_idtipopersona?.PERT_idtipopersona, //crear constante
            "PER_fechanacimiento": asegurado?.PER_idpersona?.PER_fechanacimiento,
            "RECT_numerofolio": numFolio,
            "RECT_idfolio": resultados[0]?.RECT_idfolio,

          },
        ];
        // Busco si hay coincidencias en el arreglo "arrPolizas"
        // Utilizar la función find para buscar el objeto
        var objetoEncontrado = arrPolizas.flat().find(function (obj) {
          return obj["RECT_numerofolio"] === numFolio;
        });

        if (objetoEncontrado) {
          console.log("Objeto encontrado en la tabla:", objetoEncontrado);
          setFoundFolio(true)
          setDialogFoundFolio(true)
        } else {
          console.log("Objeto no encontrado en la tabla.");
          setArrPolizas([...arrPolizas, jsonPM])
          setFoundFolio(true)
          setDialogFoundFolio(true)
        }
      }
    } else { // Si no se ingresó ningún carácter poner foco al texfield. 
      document.getElementById("txtNumFolio").focus();
    }
  }
  async function buscarPorNombreAse(nombre, apePat, apeMat) {
    if (nombre !== "") {
      setNumFolio(numFolio);
      //BUSCAR EL FOLIO EN LA API JOIN 
      // Filtrar la estructura para obtener el objeto completo con sus objetos hijos
      let resultados = [];
      // Utilizar la función find para buscar el objeto
      for (const objetosHijos of datosApiJoinRec) {
        let contieneValorBuscado = objetosHijos.some(function (obj) {
          return obj["PER_nombre"].toLowerCase() === nombre.trim().toLowerCase()
            && obj["PER_apellidopaterno"].toLowerCase() === apePat.trim().toLowerCase()
            && obj["PER_apellidomaterno"].toLowerCase() === apeMat.trim().toLowerCase()
            ;
        });

        if (contieneValorBuscado) {
          resultados.push(objetosHijos);
        }
      };
      console.log("Resultados api join", resultados)
      if (resultados.length === 0) {
        // Si NO hay coincidencias en sinay que muestre diálogo. 
        setFoundFolio(false)
        setDialogFoundFolio(true)
      } else {

        console.log("Objeto sinay:", resultados);
        let asegurado = {};
        let contratante = {};
        //Recuperamos el numero de folio y tipo de reclamación de la api recepción 
        let objectRecep = await getDataToken(`recepcion/get_id/${resultados[0][0]?.RECT_idfolio}/`);
        setNumFolio(objectRecep?.RECT_numerofolio)
        setReclamacion(objectRecep?.SINC_idcomplejidadsiniestro?.TRA_destipotramitesiniestro)
        resultados[0].forEach(poliza => {
          if (poliza?.PERR_idrol == PERR_ASEGURADO.id) {
            asegurado = poliza
          } else if (poliza?.PERR_idrol == PERR_CONTRATANTE.id) {
            contratante = poliza
          }
        })
        if (Object.keys(asegurado).length === 0) {
          console.log("El objeto asegurado está vacío.");
          return;
        }
        let jsonPM = [
          {//CONTRATANTE
            "POL_numpoliza": contratante?.POL_numpoliza,
            "PRO_cveproducto": contratante?.PRO_cveproducto, //Pendiente, no hay dato similar en el PM (crear constante?)
            "PRO_descripcion": contratante?.PRO_descripcion,
            "EST_descripcion": contratante?.EST_descripcion,
            "PER_nombre": contratante?.PER_nombre,
            "PER_apellidopaterno": contratante?.PER_apellidopaterno,
            "PER_apellidomaterno": contratante?.PER_apellidomaterno,
            "PER_RFC": contratante?.PER_RFC,
            "PER_CURP": contratante?.PER_CURP,
            "PERR_idrol": PERR_CONTRATANTE.id,
            "PERT_idtipopersona": 1, //crear constante
            "PCP_coberturasumaasegurada": contratante?.PCP_coberturasumaasegurada ? contratante?.PCP_coberturasumaasegurada : 0,
            "POL_idpoliza": contratante?.POL_idpoliza,
            "POL_idpoliza": contratante?.POL_idpoliza,
            "PER_idpersona": contratante?.PER_idpersona,

          },
          {//ASEGURADO
            "POL_numpoliza": asegurado?.POL_numpoliza,
            "PRO_cveproducto": asegurado?.PRO_cveproducto, //Pendiente, no hay dato similar en el PM (crear constante?)
            "PRO_descripcion": asegurado?.PRO_descripcion,
            "EST_descripcion": asegurado?.EST_descripcion,
            "EST_idestatuspoliza": 1, //Crear constantes de estatus poliza, ya que no está especificado en el PM
            "POL_polizafecemisión": asegurado?.POL_polizafecemisión,
            "POL_polizafinvigencia": asegurado?.POL_polizafinvigencia,
            "PRO_idproductos": "9", //Crear constantes de productos, ya que no está especificado en el PM
            "PER_nombre": asegurado?.PER_nombre,
            "PER_apellidopaterno": asegurado?.PER_apellidopaterno,
            "PER_apellidomaterno": asegurado?.PER_apellidomaterno,
            "PER_RFC": asegurado?.PER_RFC,
            "PER_CURP": asegurado?.PER_CURP,
            "PERR_idrol": PERR_ASEGURADO.id,
            "PERT_idtipopersona": 1, //crear constante
            "PER_fechanacimiento": asegurado?.PER_fechanacimiento,
            "PCP_coberturasumaasegurada": asegurado?.PCP_coberturasumaasegurada ? asegurado?.PCP_coberturasumaasegurada : 0,
            "RECT_numerofolio": asegurado?.RECT_numerofolio,

          },
        ];
        // Busco si hay coincidencias en el arreglo "arrPolizas"
        // Utilizar la función find para buscar el objeto
        var objetoEncontrado = arrPolizas.flat().find(function (obj) {
          return obj["POL_numpoliza"] === numPoliza;
        });

        if (objetoEncontrado) {
          console.log("Objeto encontrado en la tabla:", objetoEncontrado);
          setDialogFoundFolio(true)
          setFoundFolio(true)
        } else {
          console.log("Objeto no encontrado en la tabla.");
          setArrPolizas([...arrPolizas, jsonPM])
          setDialogFoundFolio(true)
          setFoundFolio(true)
        }
      }
    } else { // Si no se ingresó ningún carácter poner foco al texfield. 
      document.getElementById("txtNombre").focus();
    }
  }
  //Funciones para apartado "Buscar por:"
  const [buscarPor, setBuscarPor] = useState(1)

  //funcion para seleccionar polizas
  const [listaPol, setListaPol] = useState([]);
  const [dialogAviso, setDialogAviso] = useState(false);
  const [idRecepcion, setIdRecepcion] = useState("");
  const [idPoliza, setidPoliza] = useState("");
  const [polizaAse, setPolizaAse] = useState("");

  const updatePolToList = (e, row, pol) => {
    if (e.target.checked) {
      // Validar si el objeto cumple con ciertas condiciones
      if (listaPol.length > 0) {
        if (pol?.RECT_numerofolio !== listaPol[0][1].RECT_numerofolio) {
          // Se seleccionó un asegurado diferente, mostrar aviso.    
          setDialogAviso(true);
        } else {
          setIdRecepcion(pol?.RECT_idfolio);
          setidPoliza(pol?.POL_idpoliza);
          setPolizaAse(pol);
          setListaPol((prevState) => [...prevState, row]);
        }
      } else {
        setIdRecepcion(pol?.RECT_idfolio);
        setidPoliza(pol?.POL_idpoliza);
        setPolizaAse(pol);
        setListaPol((prevState) => [...prevState, row]);
      }
    } else if (!e.target.checked && listaPol.length <= 0) {
      setIdRecepcion("");
      setidPoliza("");
      setPolizaAse("");
      //Remover el objeto de la lista de polizas seleccionadas
      setListaPol((current) => current.filter((e) => e[0].POL_numpoliza != pol?.POL_numpoliza));
    } else {
      //Remover el objeto de la lista de polizas seleccionadas
      setListaPol((current) => current.filter((e) => e[0].POL_numpoliza != pol?.POL_numpoliza));
    }
  };

  useEffect(() => {
    if (listaPol.length > 0) {
      console.log("Polizas seleccionadas:", listaPol);
      setBtnSiguiente(false);
      setHabilitaLink(true);
    } else {
      setBtnSiguiente(true);
      setHabilitaLink(false);
    }
  }, [listaPol])


  const [arrPolizas, setArrPolizas] = useState([])
  const agregarPoliza = async (arrPolizasNuevas) => {
    const nuevoArrPolizas = [...arrPolizas];// Crear una copia del arreglo original
    arrPolizasNuevas.forEach(polizaNueva => {
      // Verificar si el nombre de la poliza nueva ya existe en el nuevo arreglo
      const existe = nuevoArrPolizas.some(polizaExistente => polizaExistente[0].POL_numpoliza === polizaNueva[0].POL_numpoliza);

      if (existe) {
        // Si el objeto ya existe, mostrar una mesaje
        console.log(`La póliza "${polizaNueva[0].POL_numpoliza}" ya existe en la tabla.`);
      } else {
        // Si el objeto no existe, agregarlo al nuevo arreglo
        nuevoArrPolizas.push(polizaNueva);
      }
    });
    //Actualizar el estado original por el nuevo.
    setArrPolizas(nuevoArrPolizas);
  }

  return (
    <div>
      <Grid
        style={{ paddingBottom: "20px" }}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <h4-search>
            <FormattedMessage id="globBuscar" defaultMessage="Buscar por" />:
          </h4-search>
        </Grid>
        <Grid item>
          <RadioDinamico  value={buscarPor} array={[{ "id": 1, "value": 1, "label": "Número de folio" }, { "id": 2, "value": 2, "label": "Nombre del asegurado" }]} onChange={(e) => {
            setBuscarPor(parseInt(e.target.value))
            setDatosAse({
              nombre: "",
              apePat: "",
              apeMat: ""
            })
            setNumPoliza("")
          }} />
        </Grid>
        {buscarPor === 1 ?
          <Grid item>
            <TXTField
              label="Número de folio"
              id="txtNumFolio"
              showSearchIcon={true}
              iconOnClick={() => buscarFolio(numFolio)}
              onChange={(e) => {
                setNumFolio(e.target.value)
              }}
            />
          </Grid> :
          <Grid item>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <TXTField
                  label="Nombre"
                  id="txtNombre"
                  onChange={(e) => { setDatosAse(prevState => ({ ...prevState, nombre: e.target.value })) }}
                />
              </Grid>
              <Grid item>
                <TXTField
                  label="Apellido paterno"
                  id="txtApePat"
                  onChange={(e) => { setDatosAse(prevState => ({ ...prevState, apePat: e.target.value })) }}
                /></Grid>
              <Grid item>
                <TXTField
                  label="Apellido Materno"
                  id="txtApeMat"
                  onChange={(e) => { setDatosAse(prevState => ({ ...prevState, apeMat: e.target.value })) }}
                />
              </Grid>
              <Grid item>
                <ButtonVino label="Buscar" onClick={() => buscarPorNombreAse(datosAse.nombre, datosAse.apePat, datosAse.apeMat)} />
              </Grid>
            </Grid>
          </Grid>
        }


      </Grid>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead style={{ position: "sticky" }}>
              <TableRow>
                <StyledTableCell>Seleccionar</StyledTableCell>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                <StyledTableCell align="center">
                  <FormattedMessage id="folio" defaultMessage="Número folio" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="globProdut" defaultMessage="Producto" />
                </StyledTableCell>
                <StyledTableCell align="center">Estatus</StyledTableCell>
                <StyledTableCell>
                  <FormattedMessage
                    id="globNomAse"
                    defaultMessage="Nombre del asegurado"
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="recepContr"
                    defaultMessage="Contratante"
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="globRfcp" defaultMessage="RFC" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="globCurp" defaultMessage="CURP" />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody id="tbbody">
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {arrPolizas
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  let asegurado;
                  let contratante;
                  row.forEach(poliza => {
                    if (poliza?.PERR_idrol === PERR_ASEGURADO.id) {
                      asegurado = poliza
                    } else if (poliza?.PERR_idrol === PERR_CONTRATANTE.id) {
                      contratante = poliza
                    }
                  })
                  return (

                    <TableRow >
                      <StyledTableCell component="th" scope="row" align="center">
                        <Switch onChange={(e) => updatePolToList(e, row, asegurado)}
                          disabled={listaPol.length > 0 && asegurado?.RECT_numerofolio !== listaPol[0][1].RECT_numerofolio ? true : false}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {asegurado?.RECT_numerofolio}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {asegurado?.PRO_descripcion}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {asegurado?.EST_descripcion}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {`${asegurado?.PER_nombre} ${asegurado?.PER_apellidopaterno} ${asegurado?.PER_apellidomaterno}`}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {`${contratante?.PER_nombre} ${contratante?.PER_apellidopaterno} ${contratante?.PER_apellidomaterno}`}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {`${asegurado?.PER_RFC}`}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {`${asegurado?.PER_CURP}`}
                      </StyledTableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={arrPolizas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Registros por página:"
        />
      </Paper>
      <br />
      <Box sx={{ minWidth: 25 }}>
        <Grid container>
          <Grid xs={2}>
            <Link
              to="/editarTramite"
              state={{ idRecepcion: idRecepcion, idPoliza : idPoliza, polizaAse : polizaAse }}
              style={{ textDecoration: "none" }}
              onClick={(e) => !habilitaLink && e.preventDefault()}
            >
              <ButtonVino
                variant="contained"
                color="primary"
                size="small"
                estatus={BtnSiguiente}
                label={
                  <FormattedMessage
                    id="glob.siguiente"
                    defaultMessage="Siguiente"
                  />
                }
              />
            </Link>
            <br />
          </Grid>
        </Grid>
      </Box>
      <br></br>

      {/* Este dialogo es cuando se encontró póliza dentro de sinay */}
      {!dialogFoundFolio ? null : <DialogFolio open={true} aceptar={() => {
        setDialogFoundFolio(false);
        setFoundFolio(false);
      }}
        folio={numFolio}
        foundFolio={foundFolio}
      />}
      {/* Este dialogo es cuando se encontró al aseguado dentro de sinay */}
      {!dialogFoundAse ? null : <DialogFoundAse open={true} aceptar={() => setDialogFoundAse(false)} />}
      {/* Este dialogo es cuando se encontró al aseguado dentro de sinay */}
      {!dialogAviso ? null : <DialogConfirmAviso open={true} aceptar={() => setDialogAviso(false)} />}
    </div>
  );
}
