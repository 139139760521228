/* 
David Ruiz
Proyecto SINAY 
11/10/2022
*/
import * as React from 'react';
//tabla
import Tabla1 from './Elements/TablaAdmin';
//breadcrumbs
import Recorrido from '../Tools/BreadCrumbs/Recorrido';
//idioma
import {FormattedMessage} from 'react-intl';
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import Grid from '@material-ui/core/Grid'; 

import "../../css/estilo.css";

export function CompAdmin() {

return (

  <div className="div-parent">
  <div className="div-breadcrumbs"> {/* Navegacion*/}
  <Recorrido name1={<FormattedMessage id="globInicio" defaultMessage="Inicio"/>}   
  enlace="/menuadmin" name2={<FormattedMessage id="adminAdmin" defaultMessage="Administración"/>} />  
  </div>
  <div className="div-container">{/* tabla */}
  <Tabla1/>
</div>
</div>
    
  );
}
