import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { number } from "yup/lib/locale";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 1,
          left: 0,
          bottom: 1,
          right: 0,
          position: "absolute",
          display: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function CircleLoading({ initialValue }) {
  const [progress, setProgress] = React.useState(initialValue);

  React.useEffect(() => {
    // Actualizar el estado si initialValue cambia
    setProgress(initialValue);
  }, [initialValue]);

  return <CircularProgressWithLabel value={progress} />;
}