/* Brandon Hernandez 01/2023 */
import * as React from "react";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const CheckGroup = (props) => {
  return (
    <FormControl component="fieldset">
      <FormGroup aria-label="position" row={props.row}>
        <FormControlLabel
          control={
          <Checkbox color="primary" disabled={props.disabled} />}
          label={props.label}
          labelPlacement={props.position}
          onChange={props.onChange}
          checked={props.checked} 
        />
      </FormGroup>
    </FormControl>
  );
};

export default CheckGroup;
