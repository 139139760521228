import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

const CustomSeparator = (props) => {
  const breadcrumbs = [
    <Link underline="hover" key="1" href={props.enlace}>
      <h2-breadcrumbs-primary>{props.name1}</h2-breadcrumbs-primary>
    </Link>,
    <h3-breadcrumbs-secundary key="2">{props.name2}</h3-breadcrumbs-secundary>,
  ];

  return (
    <Stack spacing={2}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};

export default CustomSeparator;
