import * as React from "react";
import PropTypes from "prop-types";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
//Solo aparesera un alerta con un solo boton
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogGuardarRegSinPol(props) {
  const [open, setOpen] = React.useState(props.open);
  const dataForm = props.dataForm
  console.log("dataform dialog:", dataForm)
  const handleClose = () => {
    //props.clickButton(true)
    setOpen(false);
  };

  return (
    <div>
      {/*BOTON QUE HACE EL LLAMADO AL DIALOGO*/}

      {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="dialog"
        open={open}
        onSubmit={props.onSubmit}
      >
        {/*CUERPO DEL DIALOGO*/}
        {/*TITILO*/}
        <BootstrapDialogTitle
          id="dialog"
          onClose={handleClose}
          style={{ color: "#761f2a" }}
        >
          <h1-dialog-title>{props.title}</h1-dialog-title>
        </BootstrapDialogTitle>
        {/*CUERPO DEL DIALOGO*/}
        {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
        <DialogContent dividers style={{ minWidth: 500 }}>
          {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
          se necesita colocar entre un typogreaphy las veces que des salto de linea 
          o con un br*/}
          <h2-dialog-description>{props.description}</h2-dialog-description>
        </DialogContent>
        {/*es la tercer sección que engloba los botones */}
        <DialogActions>
          <Grid container justifyContent="center">
            {dataForm ?
              <Link
                to={"/NuevoTramite"}
                state={{ dataForm }}
                style={{ textDecoration: "none" }}
              >


                <ButtonVino
                  onClick={handleClose}
                  variant="contained"
                  label={props.button}
                />
              </Link> :
              <ButtonVino
                onClick={handleClose}
                variant="contained"
                label={props.button}
              />
            }
          </Grid>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
