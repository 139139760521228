import { conf } from "../../pages/Admin/ModConfGeneral/ConfGen";

import { logo } from "../../pages/Admin/ModConfGeneral/General/ConfGeneral";
import { inputmail } from "../../pages/Admin/ModConfGeneral/Mail/MailInput";
import { exitmail } from "../../pages/Admin/ModConfGeneral/Mail/Mailexit";
import { userss } from "../../pages/Admin/ModConfGeneral/AdminUsers/Altausers";
import { nego } from "../../pages/Admin/ModConfGeneral/NegocioConf/ConfNego";
import { secu } from "../../pages/Admin/ModConfGeneral/Security/ConfSecurity";
import { cobe } from "../../pages/Admin/ModConfGeneral/Catalogo/ConfCobertura";
import { ClientLayaout } from "../../layouts/Client/Confsinaylayout";
import { plan } from "../../pages/Admin/ModConfGeneral/Catalogo/ConfPlanes";
import { adminMenu } from "../../pages/Admin/ModAdminMenu";
import { conta } from "../../pages/Admin/ModConfGeneral/Accounting/Contabilidad";
import { nego1 } from "../../pages/Admin/ModConfGeneral/NegocioConf/ConNego1";



const routesClient = [
  {
    path: "/menuadmin",
    layout: ClientLayaout,
    component: adminMenu,
    exact: true,
  },
  {
    path: "/negocioconfi",
    layout: ClientLayaout,
    component:  nego1,
    exact: true,
  },
  {
    path: "/confadmin",
    layout: ClientLayaout,
    //layout: ClientLayaout,
    component: conf,
    exact: true,
  },
  {
    path: "/confgeneral",
    layout: ClientLayaout,
    component: logo,
    exact: true,
  },

  {
    path: "/mail-output",
    layout: ClientLayaout,
    component: exitmail,
    exact: true,
  },
  {
    path: "/mail-input",
    layout: ClientLayaout,
    component: inputmail,
    exact: true,
  },
  {
    path: "/rolespermisos",
    layout: ClientLayaout,
    component: userss,
    exact: true,
  },
  {
    path: "/ConfNegocio",
    layout: ClientLayaout,
    component: nego,
    exact: true,
  },

  {
    path: "/ConfSecurity",
    layout: ClientLayaout,
    component: secu,
    exact: true,
  },

  {
    path: "/CataCobertura",
    layout: ClientLayaout,
    component: cobe,
    exact: true,
  },

  {
    path: "/CataPlan",
    layout: ClientLayaout,
    component: plan,
    exact: true,
  },

  {
    path: "/Conta",
    layout: ClientLayaout,
    component: conta,
    exact: true,
  },

];

export default routesClient;
