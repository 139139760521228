//Brandon Hernandez 12/2022
import ReplyIcon from '@mui/icons-material/Reply';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';

const ReturnPage = (props) => {
    return (
        <>
            {/* props.to es la pagina a donde se va a regresar. Ejemplo: <ReturnPage to='/inicio'/> */}
            {props.to ?
                <Link style={{ textDecoration: 'none' }} underline="hover" key={props.key} to={props.to} state={props.state}><IconButton color="icons" href={props.href}><ReplyIcon /><h2-breadcrumbs-primary>Regresar</h2-breadcrumbs-primary></IconButton></Link> :
                <IconButton color="icons" href={props.href}><ReplyIcon /><h2-breadcrumbs-primary>Regresar</h2-breadcrumbs-primary></IconButton>}
        </>
    )
}

export default ReturnPage