import React from "react";
import { Contabilidad } from "../../../../components/GeneralConf/Accounting/Contabilidad";


export function conta() {
    return (
        <div>

            <Contabilidad/>

        </div>
    );
}