import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { FormattedMessage } from "react-intl";
import { useState, useEffect } from "react";
import { getDataToken } from "../../../../api/dataToken";
//Select
import { Select1 } from "../../../Tools/Selectss/Select1";
//Textfleild
import TXTField from "../../../Tools/TextField/TextField";

//importacion de las imagenes para seleccionar tipo siniestro
import fallecimientoOn from "../../../../img/Recepcion/fallecimientoOn.svg";
import fallecimientoOff from "../../../../img/Recepcion/fallecimientoOff.svg";
import beneficioOn from "../../../../img/Recepcion/beneficioOn.svg";
import beneficioOff from "../../../../img/Recepcion/beneficioOff.svg";
import retiro_on from "../../../../img/Recepcion/retiro_on.png";
import retiro_off from "../../../../img/Recepcion/retiro_off.png";
import rescates_on from "../../../../img/Recepcion/rescates_on.svg";
import rescates_off from "../../../../img/Recepcion/rescates_off.svg";
import vencimiento_off from "../../../../img/Recepcion/vencimiento_off.svg";
import vencimiento_on from "../../../../img/Recepcion/vencimiento_on.svg";

//importar constantes 
import { TRAM_FALLECIMIENTO, TRAM_BENEFICIOSEV, TRAM_RESCATES, TRAM_RETIROS, TRAM_VENCIMIENTO, COBERTURA_VENC_DEFAULT, COBERTURAS_BASICAS, TRAM_PM } from "../../../../utils/constanstoken";
import { DATA_PM } from "../../../../utils/constansPM";



export function FormTipoTramiteSiniestro(props) {
  //Estados para los íconos
  const [images, setImages] = useState({
    imgFalle: false,
    imgBene: false,
    imgVenci: false,
    imgRetir: false,
    imgResc: false,
  });
  let tipoTramitePM = props.tipoTramitePM;
  let numPoliza = props.numPoliza;

  //Funcionalidad para llenar la lista de coberturas según el producto

  const [listaCoberturas, setListaCoberturas] = useState([]);
  const [listaCober, setListaCober] = useState([]);
  const [arrCoberturas, setArrCoberturas] = useState([]);
  const [arrCoberTram, setArrCoberTram] = useState([]);

  useEffect(()=>{ 
    setListaCoberturas(props.listaCoberturas)
  },[props.listaCoberturas])



  useEffect(() => {
    const fetchData = async () => {
      // if (props.idProducto === 0) {
      //   let coberturas = COBERTURAS_BASICAS.map(objeto => {
      //     return { ...objeto, identificador: objeto.id, texto: objeto.description };
      //   });
      //   setListaCober(coberturas);
      // } else {
      //   try {
      //     let result = await getDataCoberturaxProducto();
      //     console.log("result:", result);
      //     if (result && result.length > 0) {
      //       let filtro = result.filter(objeto => objeto.PXC_idcobertura.TRA_idtipotramitesiniestro.TRA_idtipotramitesiniestro == props.tipoTramite);
      //       let copiaApi = filtro.map(objeto => {
      //         return { ...objeto, identificador: objeto.id, texto: objeto.PXC_idcobertura.COB_cobertura };
      //       });
      //       console.log("coberturas del producto por tramite:", copiaApi);
      //       setListaCober(copiaApi);
      //     }
      //   } catch (error) {
      //     console.error("Error al obtener datos:", error);
      //   }
      // }
      let result = await getDataToken(`configuraciongeneral/poliza_apiPM/?poliza=${numPoliza}`);
      let object = result[0]
      props.dataPM(object)
      let objeto = [
        {//COBERTURA 1 
          identificador: object[DATA_PM.ID_COBERTURA_1], texto: object[DATA_PM.COB_DESCRIPCION_1]
        },
        {//COBERTURA 2 
          identificador: object[DATA_PM.ID_COBERTURA_2], texto: object[DATA_PM.COB_DESCRIPCION_2]
        },
        {//COBERTURA 3 
          identificador: object[DATA_PM.ID_COBERTURA_3], texto: object[DATA_PM.COB_DESCRIPCION_3]
        },
        {//COBERTURA 4 
          identificador: object[DATA_PM.ID_COBERTURA_4], texto: object[DATA_PM.COB_DESCRIPCION_4]
        },
        {//COBERTURA 5 
          identificador: object[DATA_PM.ID_COBERTURA_5], texto: object[DATA_PM.COB_DESCRIPCION_5]
        },
        {//COBERTURA 6 
          identificador: object[DATA_PM.ID_COBERTURA_6], texto: object[DATA_PM.COB_DESCRIPCION_6]
        },
        {//COBERTURA 7 
          identificador: object[DATA_PM.ID_COBERTURA_7], texto: object[DATA_PM.COB_DESCRIPCION_7]
        },
        {//COBERTURA 8 
          identificador: object[DATA_PM.ID_COBERTURA_8], texto: object[DATA_PM.COB_DESCRIPCION_8]
        },
        {//COBERTURA 9 
          identificador: object[DATA_PM.ID_COBERTURA_9], texto: object[DATA_PM.COB_DESCRIPCION_9]
        },
        {//COBERTURA 10 
          identificador: object[DATA_PM.ID_COBERTURA_10], texto: object[DATA_PM.COB_DESCRIPCION_10]
        },
      ];
      setArrCoberturas(objeto);
    };


    fetchData();
  }, []);

  //TODO: SE NECESITA UN FILTRO DE COBERTURAS POR TRAMITE, YA QUE LAS COBERTURAS DEL POLICY MASTER NO TIENEN UNA RELACION CON UN TIPO TRAMITE
  useEffect(() => {
    const fetchData = async () => {

      if (arrCoberturas.length > 0) { //Filtrar las coberturas por el tramite actual
        // Agregar un nuevo elemento al estado sin modificar el original
        // let filtro = arrCoberturas.filter(function (objeto) {
        //   return objeto.identificador == props.tipoTramite;
        // })
        let tipoTramite = props.tipoTramite;
        let coberturas = []; 
        console.log("Arra:" , listaCoberturas)

        for (let cobertura of listaCoberturas) {
          let dataCober = await getDataToken("coberturas/get_id/"+cobertura.COB_idcobertura+"/");
          if(dataCober && dataCober.TRA_idtipotramitesiniestro.TRA_idtipotramitesiniestro == tipoTramite){
            coberturas.push(
              {identificador: cobertura.COB_idcobertura, texto: dataCober.COB_cobertura
            });
          }

        }
        console.log(`Coberturas del tipo tramite ${tipoTramite}`, coberturas)
        setArrCoberTram(coberturas);
      }
    }
    fetchData();
  }, [props.idProducto, props.tipoTramite, arrCoberturas])


  useEffect(() => {
    if (listaCober.length > 0 && !props.coberAdicional) {
      actualizaLista();
    }
  }, [listaCober]);




  React.useEffect(() => {
    if (props.tipoTramite) {
      // Lógica para cambiar la lista de coberturas
      actualizaLista();
      let tipoTramite = props.tipoTramite;
      if (tipoTramite == TRAM_BENEFICIOSEV.id) {
        setImages((prevState) => ({
          ...prevState,
          imgBene: true,
          imgFalle: false,
          imgVenci: false,
          imgRetir: false,
          imgResc: false,
        }));
      } else if (tipoTramite == TRAM_FALLECIMIENTO.id) {
        setImages((prevState) => ({
          ...prevState,
          imgBene: false,
          imgFalle: true,
          imgVenci: false,
          imgRetir: false,
          imgResc: false,
        }));
      } else if (tipoTramite == TRAM_VENCIMIENTO.id) {
        setImages((prevState) => ({
          ...prevState,
          imgBene: false,
          imgFalle: false,
          imgVenci: true,
          imgRetir: false,
          imgResc: false,
        }));
      } else if (tipoTramite == TRAM_RETIROS.id) {
        setImages((prevState) => ({
          ...prevState,
          imgBene: false,
          imgFalle: false,
          imgVenci: false,
          imgRetir: true,
          imgResc: false,
        }));
      } else if (tipoTramite == TRAM_RESCATES.id) {
        setImages((prevState) => ({
          ...prevState,
          imgBene: false,
          imgFalle: false,
          imgVenci: false,
          imgRetir: false,
          imgResc: true,
        }));
      }
    }
    props.coberAdicChange(false);//Mandar respuesta al componente padre
    props.idCobertura(0);//Reestablecer el valor tras cambiar el formulario
  }, [props.tipoTramite]);

  const actualizaLista = () => {
    //Una vez la API  esta debera filtrar
    if (listaCober.length > 0) {
      if (props.idProducto == 0) {
        // Agregar un nuevo elemento al estado sin modificar el original
        let filtro = COBERTURAS_BASICAS.filter(function (objeto) {
          return objeto.tipoTramite == props.tipoTramite;
        })
        let copiaApi = filtro.map(objeto => {
          return { ...objeto, identificador: objeto.id, texto: objeto.description };
        });
        setArrCoberturas(copiaApi);
      } else {
        console.log("PRoducto id: " + props.idProducto)
        console.log("Lista cober : ", listaCober)
        // Agregar un nuevo elemento al estado sin modificar el original
        let filtro = listaCober.filter(function (objeto) {
          return objeto.PXC_idcobertura?.TRA_idtipotramitesiniestro?.TRA_idtipotramitesiniestro == props.tipoTramite;
        })

        let copiaApi = filtro.map(objeto => {
          return { ...objeto, identificador: objeto.PXC_idcobertura.COB_idcobertura, texto: objeto.PXC_idcobertura.COB_cobertura };
        });
        setArrCoberturas(copiaApi);
      }
    }
  }
  // Funcionalidad para controlar el cambio del switch cobertura adicional 

  useEffect(() => {
    if (props.coberAdicional) {// si es true, desactivar las coberturas principales y las imagenes
      console.log(props.coberAdicional)
      setArrCoberturas([])
    } else {
      actualizaLista();
    }
    props.idCobertura(0); //Reestablecer el valor tras cambiar el formulario
  }, [props.coberAdicional])



  // Respuesta al componente padre, tipo de tramite seleccionado 
  const [tramite, setTramite] = useState(0)

  useEffect(() => {
    if (!images.imgBene
      && !images.imgResc
      && !images.imgRetir
      && !images.imgVenci) {
      props.tipoSiniestro(TRAM_FALLECIMIENTO.id);

    } else {
      if(tramite != 0)
        props.tipoSiniestro(tramite)
    }
  }, [images])

  return (
    <>
      {/* GRID ÍCONO BENEFICIOS EN VIDA */}
      <Grid item xs={2} >
        {!images.imgBene ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                style={{ cursor: "pointer" }}
                onClick={() =>
                  images.imgBene
                    ? setImages((prevState) => ({
                      ...prevState,
                      imgBene: false
                    }))
                    :
                    (setImages((prevState) => ({
                      ...prevState,
                      imgBene: true,
                      imgFalle: false,
                      imgVenci: false,
                      imgRetir: false,
                      imgResc: false,
                    })),
                      setTramite(TRAM_BENEFICIOSEV.id))
                }
                src={beneficioOff}
                alt=""
                className="img-iconos-comp"
              />
            </Grid>
            <Grid item>
              <h3-contable>
                <FormattedMessage
                  id="recepBenVi"
                  defaultMessage="Beneficios en vida"
                />
              </h3-contable>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          // spacing={2}
          >
            <Grid item>
              <img
                style={{ cursor: "pointer" }}
                onClick={() =>
                  images.imgBene
                    ? setImages((prevState) => ({
                      ...prevState,
                      imgBene: false
                    }))
                    : (setImages((prevState) => ({
                      ...prevState,
                      imgBene: true,
                      imgFalle: false,
                      imgVenci: false,
                      imgRetir: false,
                      imgResc: false,
                    })),
                      setTramite(TRAM_BENEFICIOSEV.id))
                }
                src={beneficioOn}
                alt=""
                className="img-iconos-comp"
              />
            </Grid>
            <Grid item>
              <h3-contable style={{ color: "#771f2a" }}>
                <FormattedMessage
                  id="recepBenVi"
                  defaultMessage="Beneficios en vida"
                />
              </h3-contable>
            </Grid>
            {/* {arrCoberturas.length <= 0 ? null :
              <Grid item xs={12} style={{ width: "100%" }}>
                <Select1
                  array={arrCoberturas}
                  // label="Tipo"
                  onChange={(e) => { props.idCobertura(e.target.value) }}
                />
              </Grid>} */}
            {tipoTramitePM == TRAM_PM ?
              <Grid item xs={12} style={{ width: "100%" }}>
                <Select1
                  array={arrCoberTram}
                  // label="Tipo"
                  onChange={(e) => { props.idCobertura(e.target.value) }}
                />
              </Grid> : null}
          </Grid>
        )}
      </Grid>
      {/* GRID ÍCONO FALLECIMIENTO */}
      <Grid item xs={2}>
        {!images.imgFalle ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"

          >
            <Grid item>
              <img
                src={fallecimientoOff}
                alt=""
                style={{ cursor: "pointer" }}
                className="img-iconos-comp"
                onClick={() =>
                  images.imgFalle
                    ? setImages((prevState) => ({
                      ...prevState,
                      imgFalle: false
                    }))
                    :

                    (setImages((prevState) => ({
                      ...prevState,
                      imgBene: false,
                      imgFalle: true,
                      imgVenci: false,
                      imgRetir: false,
                      imgResc: false,
                    })),
                      setTramite(TRAM_FALLECIMIENTO.id))
                }
              />
            </Grid>
            <Grid item>
              <h3-contable>
                <FormattedMessage
                  id="globFallec"
                  defaultMessage="Fallecimiento"
                />
              </h3-contable>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          // spacing={2}
          >
            <Grid item>
              <img
                src={fallecimientoOn}
                alt=""
                className="img-iconos-comp"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  images.imgFalle
                    ? setImages((prevState) => ({
                      ...prevState,
                      imgFalle: false
                    }))
                    :
                    (setImages((prevState) => ({
                      ...prevState,
                      imgBene: false,
                      imgFalle: true,
                      imgVenci: false,
                      imgRetir: false,
                      imgResc: false,
                    })),
                      setTramite(TRAM_FALLECIMIENTO.id))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <h3-contable style={{ color: "#771f2a" }}>
                <FormattedMessage
                  id="globFallec"
                  defaultMessage="Fallecimiento"
                />
              </h3-contable>
            </Grid>
            {/* {arrCoberturas.length <= 0 ? null :
              <Grid item xs={12} style={{ width: "100%" }}>
                <Select1
                  array={arrCoberturas}
                  // label="Tipo"
                  onChange={(e) => { props.idCobertura(e.target.value) }}
                />
              </Grid>} */}
            {tipoTramitePM == TRAM_PM ?
              <Grid item xs={12} style={{ width: "100%" }}>
                <Select1
                  array={arrCoberTram}
                  // label="Tipo"
                  onChange={(e) => { props.idCobertura(e.target.value) }}
                />
              </Grid> : null}
          </Grid>
        )}
      </Grid>

      {/* GRID ÍCONO VENCIMIENTO */}
      <Grid item xs={2}>
        {!images.imgVenci ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                style={{ cursor: "pointer" }}
                onClick={() =>
                  images.imgVenci
                    ? setImages((prevState) => ({
                      ...prevState,
                      imgVenci: false
                    }))
                    :
                    (setImages((prevState) => ({
                      ...prevState,
                      imgBene: false,
                      imgFalle: false,
                      imgVenci: true,
                      imgRetir: false,
                      imgResc: false,
                    })),
                      setTramite(TRAM_VENCIMIENTO.id))
                }
                src={vencimiento_off}
                alt=""
                className="img-iconos-comp"
              />
            </Grid>
            <Grid item>
              <h3-contable>
                <FormattedMessage
                  id="recepVenci"
                  defaultMessage="Vencimiento"
                />
              </h3-contable>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                style={{ cursor: "pointer" }}
                onClick={() =>
                  images.imgVenci
                    ? setImages((prevState) => ({
                      ...prevState,
                      imgVenci: false
                    }))
                    :
                    (setImages((prevState) => ({
                      ...prevState,
                      imgBene: false,
                      imgFalle: false,
                      imgVenci: true,
                      imgRetir: false,
                      imgResc: false,
                    })),
                      setTramite(TRAM_VENCIMIENTO.id)
                    )
                }
                src={vencimiento_on}
                alt=""
                className="img-iconos-comp"
              />
            </Grid>
            <Grid item>
              <h3-contable style={{ color: "#771f2a" }}>
                <FormattedMessage
                  id="recepVenci"
                  defaultMessage="Vencimiento"
                />
              </h3-contable>
            </Grid>
            {/* {arrCoberturas.length <= 0 ? null :
              <Grid item xs={12} style={{ width: "100%" }}>
                <Select1
                  array={arrCoberturas}
                  // label="Tipo"
                  onChange={(e) => { props.idCobertura(e.target.value) }}
                />
              </Grid>} */}
          </Grid>
        )}
      </Grid>
      {/* GRID ÍCONO RETIROS */}
      <Grid item xs={2}>
        {!images.imgRetir ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                style={{ cursor: "pointer" }}
                onClick={() =>
                  images.imgRetir
                    ? setImages((prevState) => ({
                      ...prevState,
                      imgRetir: false
                    }))
                    :
                    (setImages((prevState) => ({
                      ...prevState,
                      imgBene: false,
                      imgFalle: false,
                      imgVenci: false,
                      imgRetir: true,
                      imgResc: false,
                    })),
                      setTramite(TRAM_RETIROS.id))
                }
                src={retiro_off}
                alt=""
                className="img-iconos-comp"
              />
            </Grid>
            <Grid item>
              <h3-contable>
                <FormattedMessage id="recepRetrs" defaultMessage="Retiros" />
              </h3-contable>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                style={{ cursor: "pointer" }}
                onClick={() =>
                  images.imgRetir
                    ? setImages((prevState) => ({
                      ...prevState,
                      imgRetir: false
                    }))
                    :
                    (setImages((prevState) => ({
                      ...prevState,
                      imgBene: false,
                      imgFalle: false,
                      imgVenci: false,
                      imgRetir: true,
                      imgResc: false,
                    })),
                      setTramite(TRAM_RETIROS.id))
                }
                src={retiro_on}
                alt=""
                className="img-iconos-comp"
              />
            </Grid>
            <Grid item>
              <h3-contable style={{ color: "#771f2a" }}>
                <FormattedMessage id="recepRetrs" defaultMessage="Retiros" />
              </h3-contable>
            </Grid>
            {/* {arrCoberturas.length <= 0 ? null :
              <Grid item xs={12} style={{ width: "100%" }}>
                <Select1
                  array={arrCoberturas}
                  // label="Tipo"
                  onChange={(e) => { props.idCobertura(e.target.value) }}
                />
              </Grid>} */}
          </Grid>
        )}
      </Grid>
      {/* GRID ÍCONO RESCATES */}
      <Grid item xs={2}>
        {!images.imgResc ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                style={{ cursor: "pointer" }}
                onClick={() =>
                  images.imgResc
                    ? setImages((prevState) => ({
                      ...prevState,
                      imgResc: false
                    }))
                    :
                    (setImages((prevState) => ({
                      ...prevState,
                      imgBene: false,
                      imgFalle: false,
                      imgVenci: false,
                      imgRetir: false,
                      imgResc: true,
                    })),
                      setTramite(TRAM_RESCATES.id))
                }
                src={rescates_off}
                alt=""
                className="img-iconos-comp"
              />
            </Grid>
            <Grid item>
              <h3-contable>
                <FormattedMessage id="recepResct" defaultMessage="Rescates" />
              </h3-contable>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                style={{ cursor: "pointer" }}
                onClick={() =>
                  images.imgResc
                    ? setImages((prevState) => ({
                      ...prevState,
                      imgResc: false
                    }))
                    :
                    (setImages((prevState) => ({
                      ...prevState,
                      imgBene: false,
                      imgFalle: false,
                      imgVenci: false,
                      imgRetir: false,
                      imgResc: true,
                    })),
                      setTramite(TRAM_RESCATES.id))
                }
                src={rescates_on}
                alt=""
                className="img-iconos-comp"
              />
            </Grid>
            <Grid item>
              <h3-contable style={{ color: "#771f2a" }}>
                <FormattedMessage id="recepResct" defaultMessage="Rescates" />
              </h3-contable>
            </Grid>
            {/* {arrCoberturas.length <= 0 ? null :
              <Grid item xs={12} style={{ width: "100%" }}>
                <Select1
                  array={arrCoberturas}
                  // label="Tipo"
                  onChange={(e) => { props.idCobertura(e.target.value) }}
                />
              </Grid>} */}
          </Grid>
        )}
      </Grid>
    </>
  );
}
