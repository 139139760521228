import * as React from "react";
import TextField from "@mui/material/TextField";
import { makeStyles, InputAdornment, IconButton } from "@material-ui/core";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

export default function PasswordTXTField(props) {
  //Estilo del helpertext
  const useHelperTextStyles = makeStyles(() => ({
    root: {
      background: "#fff",
      margin: "0px !important",
      paddingLeft: "10px",
    },
  }));

  const helperTextStyles = useHelperTextStyles();
  //Show-Hide password
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  return (
    <TextField
      defaultValue={props.defaultValue}
      style={{position: "sticky"}}
      //Tamaño del textfield
      fullWidth = { props.fullWidth ? false : true }
      // Identificar del texto
      onBlur={props.onBlur}
      value={props.value}
      // Identificador
      id={props.id}
      // Texto que mostrara el componente
      label={props.label}
      // Tipo de entrada
      type={values.showPassword ? "text" : "password"}
      // Texto que se muestra debajo del componente como indicaciones
      helperText={props.helperText}
      // Tamaño del componente
      size={props.size}
      // Si queremos que el componente sea requerido colocamos: required={true}
      required={props.required}
      // Si queremos que el componente sea multiline colocamos: multiline={true}
      multiline={props.multiline}
      // Si queremos que el componente sea multiline y tenga filas por default colocamos: rows={5}
      rows={props.rows}
      // El evento onchange ocurre cuando el valor de un elemento ha cambiado.
      onChange={props.onChange}
      // El onclickevento ocurre cuando el usuario hace clic en un elemento.
      onClick={props.onClick}
      // Estos valores no cambiaran ya que son los que se definieron para estandarizar, no se pide valor
      variant="outlined"
      // El color tampoco cambia es el definido por la empresa, no se pide valor
      color="gray"
      // Son los margenes que dan en los 4 lados, no se pide valor
      margin="normal"
      // Esta propiedad nos brinda darle diseño al componente, nosotros solo utilizaremos el
      // background para que lo muestre en color blanco, no se pide valor
      sx={{
        background: "white",
      }}
      FormHelperTextProps={{
        classes: {
          root: helperTextStyles.root,
        },
      }}
      //Placeholder, texto que ayuda al usuario a saber que colocar en el text field
      placeholder={props.placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              color="gray"
            >
              {values.showPassword ? (
                <VisibilityOutlinedIcon />
              ) : (
                <VisibilityOffOutlinedIcon />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
