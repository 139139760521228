import React from "react";
import { CompRecepcion } from "../../../components/Recepcion/CompRecepcion";


//import  { BotonesLayout } from '../../components/Bottones/BotonesLayout';

export function Recepcion() {
  return (
    <div>
  
      <CompRecepcion />

    </div>
  );
}
