//Brandon Hernandez 12/2022
import GradingIcon from '@mui/icons-material/Grading';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

/* props.tooltipTitle es para colocar el titulo de la ayuda al pasar por encima del ícono.
    props.to funciona igual que un href, solo que ésta enviará datos a la ruta destino por medio de otra prop (state)
    props.state recibira un arreglo de datos
*/
const IconDetalleDictamen=(props)=>{
    return(
        <Tooltip title={props.tooltipTitle}>
          <Link to={props.to} state= {props.state}  color="icons">
              <IconButton  
                color="icons"
                disabled={props.disabled}
                onClick={props.onClick}
                onChange={props.onChange}
                id={props.id}
              >
                  <GradingIcon color="icons"/>
              </IconButton> 
          </Link>
        </Tooltip>
        )
     ;
    }
export default IconDetalleDictamen;