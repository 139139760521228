import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { getDataToken } from "../../../api/dataToken";

import ButtonVino from "../../Tools/Bottones/ButtonVino";

//Idioma
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import TableDataCoverage from "./TableDataCoverage";

export default function TableCoverage(props) {
  //Constantes para poder pasar la información
  const infoDataRow = props.sharedState;
  const idFolio = infoDataRow?.RECT_idfolio?.RECT_idfolio;

  //Estados para el manejo de los botones con props
  const [habilitaLink, setHabilitaLink] = useState(false); //activacion y desactivacion del link
  const [btnDisable, setBtnDisable] = useState(true); //Boton de inicio registro el cual podra ser habilitado o deshabilitado

  //Obtener props del componente de las coberturas
  const obtenerValorDesdeHijo = (valor) => {
    // Aquí puedes usar el valor recibido del componente hijo como desees
    console.log("Valor recibido link:", valor);
    setHabilitaLink(valor);
  };

  const obtenerValorDesdeHijo2 = (valor2) => {
    // Aquí puedes usar el valor recibido del componente hijo como desees
    console.log("Valor recibido botn:", valor2);
    setBtnDisable(valor2);
  };

  //Traemos los datos y vamos a validar cual es el tipo de trámite.

  const [registro, setRegistros] = useState([]); //Guarda toda la información que trae la api

  const getTramite = async () => {
    try {
      var result = await getDataToken(
        `polizaportramites/get_folio/${idFolio}/`
      );
      console.log("Resultado de pólizas por trámite =>", result);
      setRegistros(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getTramite();
  }, []);

  //Datos para pasar props al componente
  const numerosPoliza = registro.map((dato) => ({
    id: dato.POL_idpoliza.POL_idpoliza,
    poliza: dato.POL_idpoliza.POL_numpoliza,
    info: infoDataRow,
  }));

  console.log(numerosPoliza);

  return (
    <div>
      <br />
      <Grid container spacing={2}>
        {numerosPoliza.map((item, index) => (
          <Grid item xs={6} key={index}>
            <TableDataCoverage
              id={item.id}
              poliza={item.poliza}
              info={item.info}
              botondisable={obtenerValorDesdeHijo2}
              botonlink={obtenerValorDesdeHijo}
            />
          </Grid>
        ))}
      </Grid>

      <br />
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={"auto"}>
          {" "}
          <Link
            to="/datosdelsiniestro"
            state={infoDataRow}
            style={{ textDecoration: "none" }}
            onClick={(e) => !habilitaLink && e.preventDefault()}
          >
            <ButtonVino
              label={
                <FormattedMessage id="globSiguie" defaultMessage="Siguiente" />
              }
              estatus={btnDisable}
            />
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
