import React from "react";
import { CompNuevoTramite } from "../../../components/Recepcion/CompNuevoTramite";


//import  { BotonesLayout } from '../../components/Bottones/BotonesLayout';

export function NuevoTramite() {
  return (
    <div>
     
      <CompNuevoTramite />
     
    </div>
  );
}
