import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import ButtonVino from '../../Tools/Bottones/ButtonVino';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
//import ModalesAlta from '../Elements/Modal/ModalAlta';
import { FormattedMessage } from 'react-intl';
import TablePagination from '@mui/material/TablePagination';
import { getToken } from '../../../api/token';
import { BASE_API } from '../../../utils/constans';
import { umbralTerminado, umbralATiempo, secundario } from '../../../api/getUmbrales';
import { getDataToken } from '../../../api/dataToken';
import DialogButtons from '../../Tools/Dialog/DialogButtons';
import SearchTXTField from "../../Tools/TextField/SearchTextField";
import ModalUser from './ModalEditUser';
import { Checkbox } from '@mui/material';
import CircleChecked from '@mui/icons-material/CheckCircleOutline';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import IconButton from "@material-ui/core/IconButton"


//MODAL
const arrayEstatus = [
  {'id': '1', 'value': 'true' , 'label':'Activo'},
  {'id': '2', 'value': 'false', 'label':'Inactivo'},
]

//Estilos para la tabla de usuarios
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: secundario,
    color: theme.palette.common.white,
    fontSize: "20px",
    fontFamily: "Avenir-Light",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "16px",
    fontFamily: "Avenir-Light",
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    '&:last-child': {
      borderRight: 'none',
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

//Estilos del checkbox 
const CircularCheckbox = styled(Checkbox)(({ theme }) => ({
  '& .MuiIconButton-root': {
    borderRadius: '50%',
    width: 24,
    height: 24,
    padding: 0,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 27,
  },
}));

const UsersTable = () => {
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("ASC");
  const [searchTerm, setSearchTerm] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);


  const sorting = (col) => {
    const sorted = [...tableData].sort((a, b) => {
      const valA = a[col].toLowerCase();
      const valB = b[col].toLowerCase();
      return order === "ASC" ? (valA > valB ? 1 : -1) : (valA < valB ? 1 : -1);
    });
    setOrder(order === "ASC" ? "DESC" : "ASC");
    setTableData(sorted);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    getPermisos();
  }, []);

  const getPermisos = async () => {
    try {
      const resultID = await getDataToken("login/get_listado_permiso/");
      setTableData(resultID);
    } catch (error) {
      console.error("Error al obtener los datos de permisos:", error);
    }
  };

  const handleOpenDialog = (user) => {
    setEditingUser(user);
    setOpenDialog(true);
  };


  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [successOpen, setSuccessOpen] = React.useState(false);
 
  const handleClickOpen = () => {
    //console.log("Abriendo modal");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const isPassword = (password) =>
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/.test(password);

  const token = getToken();

  const onClickAccept = () => {
    eliminars();
  };

  const eliminars = () => {
    fetch(`${BASE_API}login/putall/` + selectdata.SNYTLOG_email+ "/", {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        SNYTLOG_email: selectdata.SNYTLOG_email,
        SNYTLOG_delete: false, 
        SNYTLOG_admin: valordef.isviewadmin
      })
    })
    .then(response => response.json())
    .then(data => {
      //console.log('Estado cambiado:', data);
    });

    refreshPage();
  };

  /* CONSTANTES DE ESTADOS*/
  const [btnDisbale, setbtnDisbale] = useState(true)//estado del boton
  const [selectdata,setselectdata] = useState("")

  const [selected, setSelected] = React.useState(null);

  const handleCheckboxChange = (event, row) => {
    if (selected === row.id) {
      setSelected(null);
      setIsEditing(false); // Desactivar la edición al deseleccionar el checkbox
    } else {
      setSelected(row.id);
      setselectdata(row);
      setIsEditing(true); // Activar la edición al seleccionar el checkbox
    }
  };

  useEffect(() => {
    if (selected !== "") {
      setbtnDisbale(false);
    }
    console.log("Clic en el radio button:", selected);
  }, [selected, setbtnDisbale]);
  
  tableData.map(row=> { 
    if(row.SNYTLOG_estatus === false){
      row.SNYTLOG_estatus = false
      // <label className='lbl-estatus-tabla'><CircleRoundedIcon style={{color: '#97000E'}}/>
      // <FormattedMessage id="globInacti" defaultMessage="Inactivo"/>
      // </label>
    }else if(row.SNYTLOG_estatus === true){
      row.SNYTLOG_estatus = true
      // <label className='lbl-estatus-tabla'><CircleRoundedIcon style={{color: '#008000'}}/>
      //   <FormattedMessage id="globActivo" defaultMessage="Activo"/>
      //   </label> 
    }
  })

  const resetState = () => {
    
    setValues({email:"",password:"",username:"",lastName:"",maternalsurname:"",estatusss:""});
    setvalordef({
      ismedview: false,
      ismedadd: false,
      ismedchan: false,
      isviewreg: false,
      isaddreg: false,
      ischangereg: false,
      isaddrec: false,
      isviewrec: false,
      ischangerec: false,
      isviewdict: false,
      isadddict: false,
      ischangedict: false,
      isviewinve: false,
      isaddinve: false,
      ischangeinve: false,
      isviewcomcli: false,
      isaddcomcli: false,
      ischangecomcli: false,
      isviewestcon: false,
      isaddestcon: false,
      ischangeestcon: false,
      isviewwork: false,
      isaddwork: false,
      ischangework: false,
      isviewadmin: false,
      isaddadmin: false,
      ischangeadmin: false,
      isviewsuper: false,
      isaddsuper: false,
      ischangesuper: false,
      isviewgestor: false,
      isaddgestor: false,
      ischangestor: false,
    });
    //console.log("Valores reseteados:", valordef);
  };

  
  function refreshPage() {
    //window.location.reload(false);
      
    setSearchTerm("") // si es "" mostrará todos los datos de la tabla
    setbtnDisbale(true) 
    setselectdata("")
    setTableData([])
    resetState()
    getPermisos() 
  
    // setValues.estatusss("")
    
    
  }

  const [values, setValues] = React.useState({
    email: "",
    password: "",
    username: "",
    lastName: "",
    maternalsurname: "",
    estatusss:"",
    inicial:""
  });


  const [valordef, setvalordef  ] = React.useState({
    ismedview: false,
    ismedadd: false,
    ismedchan: false,

    isviewreg: false,
    isaddreg: false,
    ischangereg :false,

    isaddrec: false,
    isviewrec: false,
    ischangerec: false,

    isviewdict: false,
    isadddict: false,
    ischangedict: false,

    isviewinve: false,
    isaddinve: false,
    ischangeinve: false,

    isviewcomcli:false,
    isaddcomcli: false,
    ischangecomcli: false,

    isviewestcon: false,
    isaddestcon: false,
    ischangeestcon: false,

    isviewwork: false,
    isaddwork: false,
    ischangework: false,


    isviewadmin: false,
    isaddadmin: false,
    ischangeadmin: false,

    isviewsuper: false,
    isaddsuper: false,
    ischangesuper: false,

    isviewgestor: false,
    isaddgestor: false,
    ischangegestor: false,


  });

  return (
    <div>
      <Grid item xs={4}>
        <SearchTXTField
          label={<FormattedMessage id="globBusqued" defaultMessage="Búsqueda" />}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Grid>
      <TableContainer sx={{ maxHeight: 1050 }}style={{position:'sticky'}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell><FormattedMessage id="globSelecc" defaultMessage="Seleccionar" /></StyledTableCell>
              <StyledTableCell align="center" onClick={() => sorting("SNYTLOG_register")}>Fecha de alta<IconButton className='icon-button' style={{color: 'white'}}>{ order==='DSC' ? <UnfoldMoreIcon/>:<UnfoldMoreIcon/>}</IconButton></StyledTableCell>
              <StyledTableCell align="center" onClick={() => sorting("SNYTLOG_username")}>Nombre Completo<IconButton className='icon-button' style={{color: 'white'}}>{ order==='DSC' ? <UnfoldMoreIcon/>:<UnfoldMoreIcon/>}</IconButton></StyledTableCell>
              <StyledTableCell align="center">Correo electrónico</StyledTableCell>
              <StyledTableCell align="center" onClick={() => sorting("SNYTLOG_register")}>Perfil</StyledTableCell>
              <StyledTableCell align="center">Estatus</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData 
              .filter(row => searchTerm === ""  || row.SNYTLOG_username.toLowerCase().includes(searchTerm.toLowerCase()) ||  row.SNYTLOG_email.toLowerCase().includes(searchTerm.toLowerCase()) )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                let color;
                if (row.SNYTLOG_estatus === false) { 
                  color = (
                    <label className='lbl-estatus-tabla'>
                      <CircleRoundedIcon style={{ color: umbralTerminado }} />
                      <br />
                      <FormattedMessage id="globInacti" defaultMessage="Inactivo" />
                    </label>
                  );
                } else if (row.SNYTLOG_estatus === true) {
                  color = (
                    <label className='lbl-estatus-tabla'>
                      <CircleRoundedIcon style={{ color: umbralATiempo }} />
                      <br />
                      <FormattedMessage id="globActi" defaultMessage="Activo" />
                    </label>
                  );
                }
                let separar = (row.groups);
                var namemed = " ";
                var namecomcli = " ";
                var nameinv = " ";
                var namerec = " ";
                var namereg = " ";
                var nameadmin, namesuper = " ";

                if (separar.includes(2) || separar.includes(3) || separar.includes(4)) {
                  namemed = (<label key={separar}><FormattedMessage id="globMedico" defaultMessage=" Médico" /> </label>);
                }

                if (separar.includes(5) || separar.includes(6) || separar.includes(7)) {
                  namerec = (<label key={separar}><FormattedMessage id="globRecepc" defaultMessage=" Recepción" /></label>);
                }

                if (separar.includes(8) || separar.includes(9) || separar.includes(10)) {
                  namereg = (<label key={separar}><FormattedMessage id="globRegist" defaultMessage=" Registro" /></label>);
                }

                if (separar.includes(11) || separar.includes(12) || separar.includes(13)) {
                  nameinv = (<label key={separar}><FormattedMessage id="menuInvest" defaultMessage=" Investigación" /></label>);
                }

                if (separar.includes(14) || separar.includes(15) || separar.includes(16)) {
                  namecomcli = (<label key={separar}><FormattedMessage id="globCoClie" defaultMessage=" Comunicación con el Cliente" /></label>);
                }

                if (separar.includes(17) || separar.includes(18) || separar.includes(19)) {
                  nameadmin = (<label key={separar}><FormattedMessage id="adminAdmin" defaultMessage="Administrador" /></label>);
                }

                if (separar.includes(30) || separar.includes(31) || separar.includes(32)) {
                  namesuper = (<label key={separar}><FormattedMessage id="globCoCdslie" defaultMessage="Supervisor" /></label>);
                }

                if (separar.includes(33) || separar.includes(34) || separar.includes(35)) {
                  namesuper = (<label key={separar}><FormattedMessage id="globCoCdslie" defaultMessage="Gestión de competencias" /></label>);
                }
                return (
                  <StyledTableRow key={row.SNYTLOG_email}>
                    <StyledTableCell align="center" component="th" scope="row">
                    
                    <CircularCheckbox
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleChecked />}
                      checked={selected === row.id}
                      onChange={(event) => handleCheckboxChange(event, row)}
                    />
            
                    </StyledTableCell>
                    <StyledTableCell>{moment(row.SNYTLOG_register).format("DD/MMM/YYYY")}</StyledTableCell>
                    <StyledTableCell>{row.SNYTLOG_username + " " + row.SNYTLOG_surnamedad + " " + row.SNYTLOG_surnamemom}</StyledTableCell>
                    <StyledTableCell>{row.SNYTLOG_email}</StyledTableCell>
                    <StyledTableCell align="center">{[namereg, namerec, namemed, nameinv, namecomcli, nameadmin, namesuper]}</StyledTableCell>
                    <StyledTableCell>{color}</StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Registros por página"
      />
      
      <Grid container spacing={5}>
      <Grid item>
        <ButtonVino
        onClick={() => {
        resetState();
        handleClickOpen();
        setIsEditing(false); 
        }}
        label={<FormattedMessage id="adminEditr" defaultMessage="Agregar Usuario" />}
        >
        </ButtonVino>
      </Grid>
      <Grid item>
        <ButtonVino
        estatus={btnDisbale}
        onClick={() => {
        handleClickOpen();
        setIsEditing(true); 
        }}
        label={<FormattedMessage id="adminEditr" defaultMessage="Editar Usuario" />}
        >
        </ButtonVino>
        <ModalUser
          open={open}
          onClose={handleClose}
          selectdata={selectdata}
          setselectdata={setselectdata}
          setTableData={setTableData}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          disableEnforceFocus= {true}
        />

    </Grid>
    <Grid item > 
        {/*Eliminar usuario btn */}
      <DialogButtons
            onClickAccept={onClickAccept} 
            mainButton="Eliminar Usuario"
            title="Eliminar Usuario"
            description="¿Deseas eliminar este usuario?"
            firstButton="Cancelar"
            secondButton="Aceptar"
            estatus={btnDisbale}
        />
        </Grid>
      </Grid>
    </div>
  );
};

export default UsersTable;
