import React from "react";
import { useAuth } from "../../hooks/useAuth";
import {Login} from "../../pages/Login/ModLogin/Login";
import NavAdmin from "../../components/Tools/Header/NavAdmin"
import Footer from "../../components/Tools/Footer/Footer";


export function ClientLayaout(props){

    const { children } = props;
    const { auth } = useAuth();

    if (!auth) return <Login/>;
    return(
        <div>
            <NavAdmin/>
            {children}
            <Footer/>
        </div>
    );
}