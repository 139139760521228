import React, { useState, useEffect } from "react";
import "../../css/estilo.css";
import Recorrido from "../Tools/BreadCrumbs/Recorrido3";
import DialogButton from "../Tools/Dialog/DialogButton";
import DialogButtons from "../Tools/Dialog/DialogButtons";
import { Grid } from "@mui/material";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import TablaDocumentacion from "../Recepcion/Elements/Tables/TableDocumentacion";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

//importaciones para el paso de variables
import { useLocation } from "react-router-dom";

//Multilenguaje

import { FormattedMessage } from "react-intl";

import FormTramiteAsegurado from "../Recepcion/Elements/Forms/FormTramiteAsegurado";
import { getDataToken, postDataToken, putDataToken } from "../../api/dataToken";
import { PERR_ASEGURADO, TRAM_FALLECIMIENTO } from "../../utils/constanstoken";
import moment from "moment/moment";
import { formatofecha } from "../../api/getUmbrales";
import ButtonVino from "../Tools/Bottones/ButtonVino";
import { uploadFilesToNextcloud2 } from "../../nextcloud/functions";
import DialogDatosGuardados from "./Elements/Dialog/DialogDatosGuardados";
import DialogGenFolio from "./Elements/Dialog/DialogGenerarFolio";
import { AcuseRecepcion } from "../Dictamen/Elements/Cartas/CartasDictamen";
import { pdf } from "@react-pdf/renderer";


export function CompEditarTramite() {
  //declaramos las siguientes variables que toman los datos que se recibieron en la tabla agregar documentos
  const location = useLocation();
  const idFolio = location.state.idRecepcion;
  const idPoliza = location.state.idPoliza;
  const polizaAse = location.state.polizaAse;
  console.log("Poliza ase:", polizaAse)
  let today = new Date();

  /****** Código Usuario que actualizó *******/
  const [user, setUser] = useState("");
  //GET A API DOCUMENTACION 
  const getDataUser = async () => {
    //Resultados de la api documentacion documentosxtiposiniestrostramite
    var result = await getDataToken("login/get_correo/" + localStorage.getItem("SNYTLOG_email") + "/");
    setUser(result?.SNYTLOG_username + " " + result?.SNYTLOG_surnamedad + " " + result?.SNYTLOG_surnamemom);
  };

  /****** AQUI TERMINA Código Usuario que actualizó *******/


  /****** Código Usuario que realizó tramite (contacto) *******/
  const [contacto, setContacto] = useState("");
  //GET A API PERSONAS X POLIZA

  /****** AQUI TERMINA Código Usuario que actualizó *******/

  useEffect(() => {
    getDataUser();
  }, []);

  /********** Código DOCUMENTACION *************/
  const [tipoTramite, setTipoTramite] = useState(TRAM_FALLECIMIENTO.id);
  const [tipoTramiteSin, setTipoTramiteSin] = useState(TRAM_FALLECIMIENTO.id);
  const [dataApiDoc, setDataApiDoc] = useState([]);
  const [docsTramite, setDocsTramite] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [documentosExpTram, setDocumentosExpTram] = useState([]);
  const [btnGuardar, setBtnGuardar] = useState(true);
  const [btnGenerarFolio, setBtnGenerarFolio] = useState(false);
  const [mostrarDialogo, setMostrarDialog] = useState(false)

  /** funcionalidad para imprimir acuse **/
  const [acuseChecked, setAcuseChecked] = useState(false);

  //const para el estado Acuse
  const [dataAcuse, setDataAcuse] = useState({

    dataAsegurado: {
      nombre: "",
      apePat: "",
      apeMat: "",
      fechaNac: "",
      correo: "",
      rfc: "",
      curp: "",
    },
    dataContacto: {
      nombre: "",
      apePat: "",
      apeMat: "",
      fechaNac: "",
      correo: "",
    },
    datosTramite: {
      noFolio: "",
      tipoTramite: "",
      // polizas: [
      //     {
      //         numPoliza: "NL123",
      //         coberturas: [{ nombCober: "Cobertura por fallecimiento", SA: "100000", moneda: "MXN" }]
      //     },
      polizas: []
    },
    // documentos: [{
    //     nombre: "Poliza",
    //     original: true,
    //     copia: false,
    // }]
    documentos: []
  })
  //GET A API DOCUMENTACION 
  const getDataDocumentacion = async () => {
    //Resultados de la api documentacion documentosxtiposiniestrostramite
    var resultInves = await getDataToken("documentosxtiposiniestrostramite/get_listado/");
    setDataApiDoc(resultInves);
  };
  useEffect(() => {
    getDataDocumentacion();
  }, []);
  useEffect(() => {
    console.log("Documentos:", documentos)
    if (documentos.length > 0) {
      if (documentos[0].doc === "") {
        setBtnGuardar(true)
      } else {
        setBtnGuardar(false)
      }
    }
  }, [documentos]);

  useEffect(() => {
    if (dataApiDoc.length > 0)
      actualizaListaDocs();
  }, [dataApiDoc]);

  useEffect(() => {
    actualizaListaDocs();
  }, [tipoTramite]);

  const actualizaListaDocs = () => {
    //Una vez la API de documentos haya cambiado el estado dataApiDoc, esta debera filtrar 
    //la respuesta segun el tipo de trámite actual
    if (dataApiDoc.length > 0) {
      // Agregar un nuevo elemento al estado sin modificar el original
      let filtro = dataApiDoc.filter(function (objeto) {
        return objeto.TRA_idtipotramitesiniestro.TRA_idtipotramitesiniestro === tipoTramite;
      })

      let copiaApiDoc = filtro.map(objeto => {
        return { ...objeto, identificador: objeto.id, texto: objeto.DOC_iddocumento.DOC_documento };
      });
      setDocsTramite(copiaApiDoc);
    }
  }
  //Limpiar la tabla de documentos al tener un cambio en el siniestro a tramitar
  const [limpiarTabla, setLimpiarTabla] = useState(false);


  const handleEvento = () => {
    setLimpiarTabla(true);
  };
  const limpiarTablaCompleto = (e) => {
    setLimpiarTabla(false);
    setBtnGuardar(true);
    setDocumentos([]);
    console.log('Tabla limpia')
  };


  /***CÓDIGO PARA ACTUALIZAR DATOS CONTACTO */
  const [newDataContacto, setNewDataContacto] = useState("");
  //Esta función permite crear una carpeta dentro de nextcloud
  const cargarArchivosFolio = async (idFolio, numFolio) => {
    if (numFolio !== "") {
      if (documentos.length > 0) {
        setBtnGenerarFolio(false)
        try {
          let respuestaDocs = await uploadFilesToNextcloud2(numFolio, documentos)
          let docs = [];
          const newData = [];

          let result3 = await getDataToken("tipostramitesiniestro/get_id/" + tipoTramite + "/");
          console.log("resp 3 consulta tramite", result3)


          let dataFolio = await getDataToken("polizaportramites/get_folio/" + idFolio + "/");
          if (dataFolio && result3) {
            for (let folio of dataFolio) {
              newData.push({ poliza: folio?.POL_idpoliza?.POL_numpoliza, coberturas: [] });
            }
            setDataAcuse(prevState => ({
              ...prevState,
              datosTramite: {
                ...prevState.datosTramite,
                noFolio: datosCards.numFolio,
                tipoTramite: result3?.TRA_destipotramitesiniestro,
                polizas: newData,
              }
            }))
          }

          for (let index = 0; index < documentosExpTram.length; index++) {
            const element = documentosExpTram[index];
            let auxJsonBody = {
              RECT_idfolio: polizaAse.RECT_idfolio.RECT_idfolio,
              EXPT_original: element.original,
              EXPT_copia: element.copia,
              EXPT_notas: element.notas,
              EXPT_nombrearchivo: respuestaDocs[index] ? respuestaDocs[index]?.filename : "",
              // EXPT_rutaarchivo: respuestaDocs[index] ? respuestaDocs[index]?.url : "",
              EXPT_rutaarchivo:  "",
              //EXPT_fechaentregadocumento: today,
              EXPT_activo: true,
              EXPT_actualizofecha: today,
              EXPT_actualizousuario: user,
              TRA_idtipotramitesiniestro: tipoTramite,
            }
            let nombreArchivo = await getDataToken("documentos/get_id/" + element.tipoDoc + "/")
            docs.push(
              {
                nombre: nombreArchivo ? nombreArchivo.DOC_documento : "",
                original: element.original,
                copia: element.copia,
              });
            const result = await postDataToken('expedientetramite/get_listado/', auxJsonBody);


            setMostrarDialog(true)
          }
          setDataAcuse(prevState => ({
            ...prevState,
            documentos: docs

          }))
          if (newDataContacto !== "") {
            //Si hay datos nuevos, crear la estructura json para actualizar el contacto
            let jsonContacto = {
              "PER_correo": newDataContacto.txtCorreo ? newDataContacto.txtCorreo : "",
              "PER_calle": newDataContacto.txtCalle ? newDataContacto.txtCalle : "",
              "PER_numeroexterior": newDataContacto.txtExt ? newDataContacto.txtExt : "",
              "PER_numerointerior": newDataContacto.txtInt ? newDataContacto.txtInt : "",
              "PER_telefono": newDataContacto.txtTelFijo ? newDataContacto.txtTelFijo : "",
              "PER_celular": newDataContacto.txtTelCel ? newDataContacto.txtTelCel : "",
              "PER_actualizofecha": today,
              "PER_actualizousuario": user,
              "SEP_idsepomex": newDataContacto.txtSep_id
            }
            let respuesta = await putDataToken(`personas/get_id/${newDataContacto.idPersona}/`, jsonContacto);

          }
        } catch (e) {
          console.log('Ocurrió un error al subir archivos al expediente: ' + e);
        }
      }
    }
  }


  /********** AQUI TERMINA CÓDIGO DE DOCUMENTACION *************/

  /*************Programación para llenar las cards******/

  //Estados para llenar las cards 
  const [datosCards, setDatosCards] = useState({
    numFolio: "",
    tipoSin: "",
    numPoliza: "",
    fechaSin: "",
    nombre: "",
    apePat: "",
    apeMat: "",
    fechaNac: "",
    rfc: "",
    curp: "",

  })
  const [numFolio, setNumFolio] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {

        let datosPersonaXPoliza = await getDataToken("personasxpoliza/get_poliza/" + polizaAse?.POL_idpoliza + "/");
        let asegurado = null;
        let contacto = null;

        console.log(datosPersonaXPoliza)

        if (datosPersonaXPoliza.length > 0) {
          for (let persona of datosPersonaXPoliza) {
            if (persona.PER_idpersona.PERR_idrol.PERR_idrol == PERR_ASEGURADO.id) {
              asegurado = persona;
              console.log("persona asegurado: ", asegurado)
              setDataAcuse(prevState => ({
                ...prevState,
                dataAsegurado: {
                  ...prevState.dataAsegurado,
                  nombre: asegurado?.PER_idpersona?.PER_nombre,
                  apePat: asegurado?.PER_idpersona?.PER_apellidopaterno,
                  apeMat: asegurado?.PER_idpersona?.PER_apellidomaterno,
                  fechaNac: asegurado?.PER_idpersona?.PER_fechanacimiento ? moment(asegurado.PER_idpersona?.PER_fechanacimientoo).format(formatofecha) : "",
                  rfc: asegurado?.PER_idpersona?.PER_RFC,
                  curp: asegurado?.PER_idpersona?.PER_CURP,
                }
              }))
            }
            if (persona.PXP_iscontacto) {
              contacto = persona;
              setDataAcuse(prevState => ({
                ...prevState,
                dataContacto: {
                  ...prevState.dataContacto,
                  nombre: contacto?.PER_idpersona?.PER_nombre,
                  apePat: contacto?.PER_idpersona?.PER_apellidopaterno,
                  apeMat: contacto?.PER_idpersona?.PER_apellidomaterno,
                  correo: contacto?.PER_idpersona?.PER_correo,
                }
              }))
            }
          }
          setContacto(contacto);
        }

        let datosSiniestros = await getDataToken("polizaportramites/get_folio/"+polizaAse.RECT_idfolio.RECT_idfolio+"/");
        let datoSiniestro = "";
        console.log(datosSiniestros);
        if (datosSiniestros) {
          const objetoEncontrado = datosSiniestros.find(objeto => objeto.RECT_idfolio?.RECT_idfolio == polizaAse?.RECT_idfolio?.RECT_idfolio);

          if (objetoEncontrado) {
            // Se encontró un objeto que cumple con la condición
            datoSiniestro = objetoEncontrado;
            setTipoTramite(objetoEncontrado?.RECT_idfolio?.SINC_idcomplejidadsiniestro?.TRA_idtipotramitesiniestro)
            setTipoTramiteSin(objetoEncontrado?.RECT_idfolio?.SINC_idcomplejidadsiniestro?.TRA_destipotramitesiniestro)
          } else {
            console.log("No se encontró")
          }
        }
        setNumFolio(polizaAse?.RECT_numerofolio)
        setDatosCards(prevState => ({
          ...prevState,
          numFolio: polizaAse?.RECT_numerofolio,
          numPoliza: polizaAse?.POL_numpoliza,
          nombre: asegurado?.PER_idpersona?.PER_nombre,
          apePat: asegurado?.PER_idpersona?.PER_apellidopaterno,
          apeMat: asegurado?.PER_idpersona?.PER_apellidomaterno,
          fechaNac: asegurado?.PER_idpersona?.PER_fechanacimiento ? moment(asegurado.PER_idpersona?.PER_fechanacimientoo).format(formatofecha) : "",
          rfc: asegurado?.PER_idpersona?.PER_RFC,
          curp: asegurado?.PER_idpersona?.PER_CURP,
          tipoSin: datoSiniestro === "" ? "" : datoSiniestro?.RECT_idfolio?.SINC_idcomplejidadsiniestro?.TRA_destipotramitesiniestro,
          fechaSin: datoSiniestro === "" ? "" : moment(datoSiniestro?.SIN_fechasiniestro).format(formatofecha),
        }))


      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, []);



  /*************** AQUÍ TERMINA CÓDIGO CARDS ********************/

  async function redireccionar() {
    setMostrarDialog(false);
    if (acuseChecked) {
      let acuse = AcuseRecepcion(dataAcuse, true);

      var blob = await pdf(acuse).toBlob();
      const pdfUrl = window.URL.createObjectURL(blob);
      var printWindow = window.open(pdfUrl, '_blank');
      printWindow.print();
      URL.revokeObjectURL(pdfUrl);

    }
    window.location.href = '/agregarDocumento'
  }





  return (
    <div className="div-parent">
      {/*Enlaces de inicio */}
      <div className="div-breadcrumbs">
        {/*navegador*/}
        <Recorrido
          name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
          enlace="/menu"
          name2={
            <FormattedMessage id="globRecepc" defaultMessage="Recepción" />
          }
          enlace2="/agregarDocumento"
          name3={
            <FormattedMessage id="wtEditartr" defaultMessage="Editar trámite" />
          }
          enlace3="/editarTramite"
        />
      </div>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <ReturnPage href="/agregarDocumento" />
      </Grid>
      <div className="div-container">
        <form onSubmit={async (e) => {
          try {
            e.preventDefault();
            setBtnGuardar(true);
            cargarArchivosFolio(polizaAse.RECT_idfolio.RECT_idfolio, numFolio);

          } catch (e) {
            console.log(e)
          }
        }}>
          <Grid container>
            <Grid xs={12}>
              <h3-header-card>
                <FormattedMessage
                  id="dictamDaDelaReclamac"
                  defaultMessage="Datos de la reclamación"
                />
              </h3-header-card>
              <br />
            </Grid>

            {/*informacion de datos de reclamacion */}
            <Grid xs={3}>
              <h3-title-card>
                <FormattedMessage
                  id="glob.nodefolio"
                  defaultMessage="No. de folio"
                />
              </h3-title-card>
              <br></br>
              {/*De esta manera imprimimos los valores de la tabla anterior*/}
              <h3-subtitle-card>
                {datosCards.numFolio}
              </h3-subtitle-card>
            </Grid>
            <Grid xs={3}>
              <h3-title-card>
                <FormattedMessage
                  id="globNumPol"
                  defaultMessage="Número de póliza"
                />
              </h3-title-card>
              <br></br>
              <h3-subtitle-card>{datosCards.numPoliza}</h3-subtitle-card>
            </Grid>
            <Grid xs={3}>
              <h3-title-card>
                <FormattedMessage
                  id="globTiDeSi"
                  defaultMessage="Tipo de siniestro"
                />
              </h3-title-card>
              <br></br>
              <h3-subtitle-card>{datosCards.tipoSin}</h3-subtitle-card>
            </Grid>
            <Grid xs={3}>
              <h3-title-card>
                <FormattedMessage
                  id="dictFeDeSi"
                  defaultMessage="Fecha de siniestro"
                />
              </h3-title-card>
              <br></br>
              <h3-subtitle-card>{datosCards.fechaSin}</h3-subtitle-card>
            </Grid>
            <br />
            <Grid xs={12}></Grid>
            <br />
            <hr></hr>
            <Grid xs={12}>
              <br></br>

              <h3-header-card>
                <FormattedMessage
                  id="datosDaAse"
                  defaultMessage="Datos del asegurado"
                />
              </h3-header-card>
              <br></br>
            </Grid>
            {/*informacion de datos del asegurado */}
            <Grid xs={3}>
              <h3-title-card>
                <FormattedMessage
                  id="globNomCom"
                  defaultMessage="Nombre completo"
                />
              </h3-title-card>
              <br></br>
              <h3-subtitle-card>{`${datosCards.nombre} ${datosCards.apePat} ${datosCards.apeMat} `}</h3-subtitle-card>
            </Grid>
            <Grid xs={3}>
              <h3-title-card>
                <FormattedMessage
                  id="globFechNa"
                  defaultMessage="Fecha de nacimiento"
                />
              </h3-title-card>
              <br></br>
              <h3-subtitle-card>{datosCards.fechaNac}</h3-subtitle-card>
            </Grid>
            <Grid xs={3}>
              <h3-title-card>
                <FormattedMessage id="globRfcp" defaultMessage="RFC" />
              </h3-title-card>
              <br></br>
              <h3-subtitle-card>{datosCards.rfc}</h3-subtitle-card>
            </Grid>
            <Grid xs={3}>
              <h3-title-card>
                <FormattedMessage id="globCurp" defaultMessage="CURP" />
              </h3-title-card>
              <br></br>
              <h3-subtitle-card>{datosCards.curp}</h3-subtitle-card>
            </Grid>

            {/*Datos del contacto */}
            <Grid xs={12}>
              <br></br>
              <hr></hr>
              <br></br>
              <h3-header-card>
                <FormattedMessage
                  id="recepDatCo"
                  defaultMessage="Datos del contacto"
                />
              </h3-header-card>
              <br></br>
            </Grid>

            {/* campos correo y telefonos*/}
            <Grid xs={12}>
              {contacto === "" ? null : <FormTramiteAsegurado dataContacto={contacto} newDataContacto={(e) => setNewDataContacto(e)} />}
            </Grid>
            {/*Encabezado documentación */}
            <Grid xs={12}>
              <h3-header-card>Documentación</h3-header-card>
            </Grid>
            {/**tabla para sunir documentos */}
            <Grid xs={12}>
              <br />
              {/* <TablaDocumentacion /> */}
              <TablaDocumentacion limpiarTabla={limpiarTabla} limpiarTablaCompleto={limpiarTablaCompleto}
                arrayDocs={docsTramite}
                documentos={(e) => setDocumentos(e)} documentosExpTram={(e) => setDocumentosExpTram(e)} />
            </Grid>

            {/*botones */}
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={"auto"}>
                <DialogButtons
                  mainButton={
                    <FormattedMessage id="globCance" defaultMessage="Cancelar" />
                  }
                  title={
                    <FormattedMessage
                      id="recepCanRe"
                      defaultMessage="Cancelar recepción"
                    />
                  }
                  description={
                    <FormattedMessage
                      id="msgRecepGu"
                      defaultMessage="Si cancela la recepción se perderán los datos guardados, ¿Desea continuar?"
                    />
                  }
                  firstButton={
                    <FormattedMessage id="glob.acep" defaultMessage="Aceptar" />
                  }
                  link1="/agregarDocumento"
                  secondButton={
                    <FormattedMessage id="globAceptr" defaultMessage="Cancelar" />
                  }
                />
              </Grid>
              <Grid item xs={"auto"}>
                <ButtonVino
                  label={!btnGenerarFolio ? "Guardar cambios" : "Guardando..."}
                  type="submit"
                  estatus={btnGuardar}
                />
              </Grid>
              {!mostrarDialogo ? null :
                <DialogDatosGuardados
                  open={mostrarDialogo}
                  user={user}
                  nombreAse={`${polizaAse?.PER_nombre} ${polizaAse?.PER_apellidopaterno} ${polizaAse?.PER_apellidomaterno
                    }`}
                  folio={numFolio}
                  aceptar={(e) => { /*redireccionar()*/ }}
                />
              }
              {/* <Grid item xs={"auto"}>
              <DialogButton
                mainButton="Guardar cambios"
                title={
                  <FormattedMessage
                    id="msgVeDaCor"
                    defaultMessage="Generación de folio exitoso"
                  />
                }
                button={
                  <FormattedMessage id="glob.acep" defaultMessage="Aceptar" />
                }
                description="Folio:GN6702202"
                description2="Nombre del asegurado: Francisco López López"
                description3="Fecha de creación: 10/Dic/2020"
                description4="Fecha de aviso: 13:43:03 hrs"
                description5="Atendido por: Fernanda Zavala Zavala"
                description6={
                  <Grid container>
                    <Grid xs={5}>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Enviar por correo"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid xs={5}>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Imprimir"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                }
              />
            </Grid> */}
              {!mostrarDialogo ? null :
                <DialogGenFolio
                  type={2}
                  open={mostrarDialogo}
                  dataAcuse={dataAcuse}
                  AcuseChecked={(e) => setAcuseChecked(e)}
                  NoAleatorio={datosCards.numFolio}
                  user={user}
                  nombreAse={`${dataAcuse.dataAsegurado.nombre} ${dataAcuse.dataAsegurado.apePat} ${dataAcuse.dataAsegurado.apeMat}`}
                  aceptar={(e) => { redireccionar(dataAcuse) }}
                />
              }
            </Grid>
          </Grid>
        </form>

      </div>
    </div>
  );
}
