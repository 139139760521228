import React from "react";
import img_recepcion from "../../../../img/menu/Recepcion.svg";
import img_recepcion_on from "../../../../img/menu/Recepcion_on.svg";
import ReturnPage from "../../../Tools/Bottones/ButtonReturn";
import img_registro from "../../../../img/menu/Registro.svg";
import img_registro_on from "../../../../img/menu/Registro_on.svg";
import img_dictamen from "../../../../img/menu/Dictamen.svg";
import img_dictamen_on from "../../../../img/menu/Dictamen_on.svg";
import img_medico from "../../../../img/menu/Medico.svg";
import img_medico_on from "../../../../img/menu/Medico_on.svg";
import img_investigador from "../../../../img/menu/Investigador.svg";
import img_investigador_on from "../../../../img/menu/Investigador_on.svg";
import img_comunicacion from "../../../../img/menu/ComunicacionCliente.svg";
import img_comunicacion_on from "../../../../img/menu/ComunicacionCliente_on.svg";
import "./stilachos.css";
import { useState } from "react";
import DialogButton from "../../../Tools/Dialog/DialogButton";
import Grid from "@material-ui/core/Grid";
import {diascug,dias_medico_urgente,dias_recepcion_urgente,dias_registro_urgente,dias_dictamen_urgente,dias_investigador_urgente,dias_comcliente_urgente} from '../../../../api/getUmbrales';
import { postDataToken,putDataToken } from "../../../../api/dataToken";
//idioma
import { FormattedMessage } from "react-intl";
import TextField from '@mui/material/TextField';

export function Diasurgen() {

    const [Registro, setRegistro] = useState(false);
    const [Recepcion, setRecepcion] = useState(false);
    const [ComClien, setComClien] = useState(false);
    const [Dictamen, setDictamen] = useState(false);
    const [Medico, setMedico] = useState(false);
    const [Invest, setInvest] = useState(false);

  //Cambiar imagenes on a off y viceversa
  const [imgRegistro, setImgRegistro] = useState(false);
  const [imgRecepcion, setImgRecepcion] = useState(false);
  const [imgComClien, setImgComClien] = useState(false);
  const [imgDictamen, setImgDictamen] = useState(false);
  const [imgMedico, setImgMedico] = useState(false);
  const [imgInvest, setImgInvest] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [errorsExits, setErrorsExits] = React.useState({});
  const [errorsTitle, setErrorsTitle] = React.useState({});
  const [numero, setNumero] = React.useState({
    dias_medico : 0 ,
    dias_recepcion  : 0 ,
    dias_registro : 0 ,
    dias_dictamen : 0 ,
    dias_investigador  : 0 ,
    dias_comcliente  : 0 ,

  });



  //funciones para  el componente de los dias no pase de lo que se establezca
  function dictamen(e) {
    if (Number(e.target.value) > 30) {
      setDictamen(30);
      
    }else{
      setDictamen(e.target.value);
      numero.dias_dictamen(e.target.value)
    }
   
  }

  function medico(e) {
    if (Number(e.target.value) > 30) {
      setMedico(30);
    }else{
      setMedico(e.target.value);
    }
  }

  function recepcion(e) {
    if (Number(e.target.value) > 30) {
      setRecepcion(30);
    }else{
      setRecepcion(e.target.value);
    }
  }

  function registro(e) {
    if (Number(e.target.value) > 30) {
      setRegistro(30);
    }else{
      setRegistro(e.target.value);
    }
  }

  function investigador(e) {
    if (Number(e.target.value) > 30) {
      setInvest(30);
    }else{
      setInvest(e.target.value);
    }
  }
  

  function comcliente(e) {
    if (Number(e.target.value) > 30) {
      setComClien(30);
    }else{
      setComClien(e.target.value);
    }
  }

  numero.dias_registro = parseInt(Registro)
  numero.dias_recepcion  = parseInt(Recepcion) 
  numero.dias_dictamen = parseInt(Dictamen) 
  numero.dias_investigador  = parseInt(Invest) 
  numero.dias_comcliente  = parseInt(ComClien)  
  numero.dias_medico = parseInt(Medico) 

  const suma  = numero.dias_registro + numero.dias_investigador + numero.dias_dictamen + numero.dias_comcliente + numero.dias_recepcion +numero.dias_medico
  console.log(dias_medico_urgente)


  //POSTyPUT para el botton guardar 
const validateAndSubmitForm = (e) => {
  e.preventDefault();

  const errorsExits = {};
  const errorsTitle = {};

  errorsTitle.email = (
    <FormattedMessage id="fueServ" defaultMessage="Fuera de servicio" />
  );
  errorsExits.email = (
    <FormattedMessage
      id="peIntTar"
      defaultMessage="No es posible realizar su petición en este momento, intente más tarde."
    />
  );

  let aux = {
    SCG_dias_medico_urgente :  numero.dias_medico ,
    SCG_dias_recepcion_urgente : numero.dias_recepcion  ,
    SCG_dias_registro_urgente : numero.dias_registro ,
    SCG_dias_dictamen_urgente : numero.dias_dictamen ,
    SCG_dias_investigador_urgente :  numero.dias_investigador  ,
    SCG_dias_comcliente_urgente : numero.dias_comcliente  ,
  }

  if (suma > diascug) {
    errorsTitle.email = (
      <FormattedMessage id="globCorfdreo" defaultMessage="Diferencia de dias" />
    );
    errorsExits.email = (
      <FormattedMessage
        id="globElfdCorr"
        defaultMessage="La diferencia de dias no coinciden con los seleccionados."
      />
    );
  } else if (suma <= diascug && dias_medico_urgente === undefined)
  {
    errorsTitle.email = (
      <FormattedMessage
        id="globUsreadoExi"
        defaultMessage="Registrado exitosamente"
      />
    );
    errorsExits.email = (
      <FormattedMessage
        id="globElfdCorr"
        defaultMessage="Datos correctos"
      />
    );
      try {
        var result = postDataToken(`configuraciongeneral/get_id/1/`, aux);
        console.log("Registro exitoso", result);
        
      } catch (error) {
        console.log(error);
        throw error;
      }
     
      
  }else if (suma <= diascug && dias_medico_urgente >  0)
  {
      try {
        var result = putDataToken(`configuraciongeneral/get_id/1/`, aux);
        console.log("Registro exitoso", result);
       
        
      } catch (error) {
        console.log(error);
        throw error;
      }
      errorsTitle.email = (
        <FormattedMessage
          id="globUsreadoExi"
          defaultMessage="Actualizado  exitosamente"
        />
      );
      errorsExits.email = (
        <FormattedMessage
          id="globElfdCorr"
          defaultMessage="Datos Correctos"
        />
      );
  }

  numero.dias_medico =  0 ;
  numero.dias_recepcion  =  0 ;
  numero.dias_registro =  0 ;
  numero.dias_dictamen =  0;
  numero.dias_investigador  =  0; 
  numero.dias_comcliente  =  0 ;

  setErrorsExits(errorsExits);
  setErrorsTitle(errorsTitle);
}

  return (
    <>
      <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            style={{
              paddingRight: "15%",
              paddingTop: "1%",
              position: "absolute",
            }}
      >
        <form onClick={validateAndSubmitForm}>
        <DialogButton
                          
                          mainButton={
                            <FormattedMessage
                              id="loginRegt"
                              defaultMessage="Guardar"
                            />
                          }
                        
                          title={errorsTitle.email}
                          description={errorsExits.email}
                          button={
                            <FormattedMessage
                              id="globAceptr"
                              defaultMessage="Aceptar"
                            />
                          }
                          type="submit"
                          // to={link.email}
                          estatus={
                            numero.dias_registro > 0 &&
                            numero.dias_recepcion  > 0 &&
                            numero.dias_dictamen > 0 &&
                            numero.dias_investigador  > 0 && 
                            numero.dias_comcliente > 0 &&
                            numero.dias_medico > 0 
                              ? false
                              : true
                          }
                        />
        </form>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        id="div-parent"
      >
       

 
        {/* DIV MÓDULO  RECEPCIÓN */}
        
        <Grid
          item
          xs={3}
          className="div-children-menu"
          onMouseEnter={() => {
            setImgRecepcion(true);
            setImgRegistro(false);
            setImgInvest(false);
            setImgDictamen(false);
            setImgMedico(false);
          }}
          onMouseLeave={() => {
        
            setImgRecepcion(false);
          }}
          style={{ pointerEvents: enabled === true ? "auto" : "none" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <center>
                <Grid xs={12}>
                  {!imgRecepcion ? (
                    <img
                      className="img-iconos"
                      src={img_recepcion}
                      alt="Recepción"
                    />
                  ) : (
                    <img
                      className="img-iconos"
                      src={img_recepcion_on}
                      alt="Recepción"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label>
                    <FormattedMessage
                      id="globRecepc"
                      defaultMessage="Recepción"
                    />
                  </label>
                </Grid>
              </center>
            </Grid>
            <Grid item xs={12}>
            <TextField style={{ position: "sticky" }}
                id="Dias1"
                label="Max 30 dias"
                type="number"
                InputProps={{ inputProps: { min: "1", max: "30", step: "1" } }}
                onChange={recepcion}
                value={Recepcion}
                fullWidth
                placeholder={dias_recepcion_urgente}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                />
            </Grid>
          </Grid>
        </Grid>
        {/* DIV MÓDULO  REGISTRO */}
        <Grid
          item
          xs={3}
          className="div-children-menu"
          onMouseEnter={() => {
            setImgDictamen(false);
            setImgMedico(false);
            setImgInvest(false);
            setImgRegistro(true);
            setImgRecepcion(false);
            
          }}
          onMouseLeave={() => {
          
            setImgRegistro(false);
          }}
          style={{ pointerEvents: enabled === true ? "auto" : "none" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <center>
                <Grid xs={12}>
                  {!imgRegistro ? (
                    <img
                      className="img-iconos"
                      src={img_registro}
                      alt="Registro"
                    />
                  ) : (
                    <img
                      className="img-iconos"
                      src={img_registro_on}
                      alt="Registro"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label>
                    <FormattedMessage
                      id="globRegist"
                      defaultMessage="Registro"
                    />
                  </label>
                </Grid>
              </center>
            </Grid>
            <Grid item xs={12}>
            <TextField style={{ position: "sticky" }}
                id="Dias1"
                label="Max 30 dias"
                type="number"
                InputProps={{ inputProps: { min: "1", max: "30", step: "1" } }}
                onChange={registro}
                value={Registro}
                fullWidth
                placeholder={dias_registro_urgente}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                />
            </Grid>
          </Grid>
        </Grid>
        {/* DIV MÓDULO  DICTÁMEN*/}
        <Grid
          item
          xs={3}
          className="div-children-menu"

          onMouseEnter={() => {
            setImgDictamen(true);
            setImgInvest(false);
            setImgMedico(false);
            setImgRegistro(false);
            setImgRecepcion(false);
          }}
          onMouseLeave={() => {
            setImgDictamen(false);
          }}
          style={{ pointerEvents: enabled === true ? "auto" : "none" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <center>
                <Grid xs={12}>
                  {!imgDictamen ? (
                    <img
                      className="img-iconos"
                      src={img_dictamen}
                      alt="Dictamen"
                    />
                  ) : (
                    <img
                      className="img-iconos"
                      src={img_dictamen_on}
                      alt="Dictamen"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label>
                    <FormattedMessage
                      id="menuDictam"
                      defaultMessage="Dictamen"
                    />
                  </label>
                </Grid>
              </center>
              <Grid item xs={12}>
              <TextField style={{ position: "sticky" }}
                id="Dias1"
                label="Max 30 dias"
                type="number"
                InputProps={{ inputProps: { min: "1", max: "30", step: "1" } }}
                onChange={dictamen}
                value={Dictamen}
                fullWidth
                placeholder={dias_dictamen_urgente}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                />
            </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* DIV MÓDULO MÉDICO */}
        <Grid
          item
          xs={3}
          className="div-children-menu"

          onMouseEnter={() => {
            setImgMedico(true);
            setImgRecepcion(false);
            setImgRegistro(false);
            setImgInvest(false);
            setImgDictamen(false);
          }}
          onMouseLeave={() => {
            setImgMedico(false);
          }}
          style={{ pointerEvents: enabled === true ? "auto" : "none" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <center>
                <Grid xs={12}>
                  {!imgMedico ? (
                    <img className="img-iconos" src={img_medico} alt="Medico" />
                  ) : (
                    <img
                      className="img-iconos"
                      src={img_medico_on}
                      alt="Medico"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label>
                    <FormattedMessage id="globMedico" defaultMessage="Médico" />
                  </label>
                </Grid>
              </center>
              <Grid item xs={12}>
              <TextField style={{ position: "sticky" }}
                id="Dias1"
                label="Max 30 dias"
                type="number"
                InputProps={{ inputProps: { min: "1", max: "30", step: "1" } }}
                onChange={medico}
                value={Medico}
                fullWidth
                placeholder={dias_medico_urgente}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                />
            </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* DIV MÓDULO INVESTIGADOR */}
        <Grid
          item
          xs={3}
          className="div-children-menu"

          onMouseEnter={() => {
            setImgInvest(true);
            setImgDictamen(false);
            setImgRegistro(false);
            setImgRecepcion(false);
            setImgMedico(false);
          }}
          onMouseLeave={() => {
            setImgInvest(false);
          }}
          style={{ pointerEvents: enabled === true ? "auto" : "none" }}
        >
        
          <Grid container>
            <Grid item xs={12}>
              <center>
                <Grid xs={12}>
                  {!imgInvest ? (
                    <img
                      className="img-iconos"
                      src={img_investigador}
                      alt="Investigador"
                    />
                  ) : (
                    <img
                      className="img-iconos"
                      src={img_investigador_on}
                      alt="Investigador"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                    <br />
                  <label>
                    <FormattedMessage
                      id="globInvest"
                      defaultMessage="Investigador"
                    />
                  </label>
                </Grid>
              </center>
              <Grid item xs={12}>
              <TextField style={{ position: "sticky" }}
                id="Dias1"
                label="Max 30 dias"
                type="number"
                InputProps={{ inputProps: { min: "1", max: "30", step: "1" } }}
                onChange={investigador}
                value={Invest}
                fullWidth
                placeholder={dias_investigador_urgente}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                />
            </Grid>
            </Grid>
          </Grid>
        </Grid>
         {/* DIV MÓDULO COMUNICACIÓN CON EL CLIENTE*/}
         <Grid
          item
          xs={3}
          className="div-children-menu"
          onMouseEnter={() => {
            setImgComClien(true);
            setImgRecepcion(false);
            setImgDictamen(false);
            setImgMedico(false);
            setImgInvest(false);
            setImgRegistro(false);
          }}
          onMouseLeave={() => {
            setImgComClien(false);
          }}
          style={{ pointerEvents: enabled === true ? "auto" : "none" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <center>
                <Grid xs={12}>
                  {!imgComClien ? (
                    <img
                      className="img-iconos"
                      src={img_comunicacion}
                      alt="Comunicación"
                    />
                  ) : (
                    <img
                      className="img-iconos"
                      src={img_comunicacion_on}
                      alt="Comunicación"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label>
                    <FormattedMessage
                      id="globCoClie"
                      defaultMessage="Comunicación con el Cliente"
                    />
                  </label>
                </Grid>
              </center>
              <Grid item xs={12}>
              <TextField style={{ position: "sticky" }}
                id="Dias1"
                label="Max 30 dias"
                type="number"
                InputProps={{ inputProps: { min: "1", max: "30", step: "1" } }}
                onChange={comcliente}
                value={ComClien}
                fullWidth
                placeholder={dias_comcliente_urgente}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                />
            </Grid>
            </Grid>
         
          </Grid>
        </Grid>

        {/* FIN DE DIV MÓDULOS */}
      </Grid>
    </>
  );
}