import React from "react";
import { Planes } from '../../../../components/GeneralConf/Catalogo/Planes/Planes';


export function plan() {
    return (
        <div>

            <Planes />

        </div>
    );
}