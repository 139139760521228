/* Brandon Hernandez Rocha 11/2022 */
import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import { FormattedMessage } from "react-intl";
import "../../dictamen.css"
import { useState } from "react";
import { Select1 } from '../../../Tools/Selectss/Select1';
//Textfleild
import TXTField from '../../../Tools/TextField/TextField';
import moment from 'moment';
import { formatofecha } from '../../../../api/getUmbrales';
import { PERR_ASEGURADO, TRAM_FALLECIMIENTO } from '../../../../utils/constanstoken';
import { calculateAge } from '../../../../utils/functions';
import { getDataToken } from '../../../../api/dataToken';


export function CardDetalleFolio(props) {
  const infoDataRow = props.infoRow
  console.log(infoDataRow)
  let tiposiniestro = true
  //Estas constantes son propias de las reclamaciones de vida individual 
  let tipoSiniestro2 = null
  let tipo = null
  let subTipo = null
  //let subSubTipo = infoDataRow.subSubTipoSinies
  //Array coberturas fallecimiento
  const arrayFalleTipo = [{ "identificador": 1, "texto": "Muerte violenta" }, { "identificador": 2, "texto": "Enfermedad" }]
  const arrayFalleSubtipo = [{ "identificador": 1, "texto": "Homicidio" }, { "identificador": 2, "texto": "Accidente" }, { "identificador": 3, "texto": "Suicidio" }]
  const arrayFalleSubSubtipo = [
    { "identificador": 1, "texto": "Crimen pasional" },
    { "identificador": 2, "texto": "Asalto vía pública" },
    { "identificador": 3, "texto": "Asalto residencia" },
    { "identificador": 4, "texto": "Ejecución" },
    { "identificador": 5, "texto": "Secuestro previo" },
    { "identificador": 6, "texto": "Riña" },
    { "identificador": 7, "texto": "Otro" },
  ]
  const arrayFalleSubSubtipo2 = [
    { "identificador": 1, "texto": "Transporte terrestre privado" },
    { "identificador": 2, "texto": "Transporte terrestre público" },
    { "identificador": 3, "texto": "Transporte aéreo privado" },
    { "identificador": 4, "texto": "Transporte aéreo público" },
    { "identificador": 5, "texto": "Secuestro previo" },
    { "identificador": 6, "texto": "Moto" },
    { "identificador": 7, "texto": "Bicicleta" },
    { "identificador": 8, "texto": "Atropellamiento vía pública" },
    { "identificador": 9, "texto": "En casa" },
    { "identificador": 10, "texto": "Otro" },
  ];
  //Estados de selects Fallecimiento
  const [selectFalleTipo, setSelectFalleTipo] = useState("");
  const [selectFalleSubtipo, setSelectFalleSubtipo] = useState("");
  const [selectFalleSubsubtipo, setSelectFalleSubsubtipo] = useState("");

  //Eventos Fallecimiento

  const handleChangeFalletipo = (e) => {
    setSelectFalleTipo(e.target.value)
    setSelectFalleSubtipo("")
    setSelectFalleSubsubtipo("")
  }
  const handleChangeFalleSubtipo = (e) => {
    setSelectFalleSubtipo(e.target.value)
    setSelectFalleSubsubtipo("")
  }
  const handleChangeFalleSubsubtipo = (e) => {
    setSelectFalleSubsubtipo(e.target.value)
  }
  /***************** APARTADO PARA COBERTURA POR BENEFICIOS EN VIDA *********************/

  //Array coberturas fallecimiento
  const arrayBeneTipo = [{ "identificador": 1, "texto": "Invalidez" }, { "identificador": 2, "texto": "Enfermedad terminal" }, { "identificador": 3, "texto": "Exención por desempleo" }]
  const arrayBeneSubtipo = [{ "identificador": 1, "texto": "Accidente" }, { "identificador": 2, "texto": "Enfermedad" }]


  //Estados de selects Fallecimiento
  const [selectBeneTipo, setSelectBeneTipo] = useState("");
  const [selectBeneSubtipo, setSelectBeneSubtipo] = useState("");

  //Eventos Fallecimiento

  const handleChangeBenetipo = (e) => {
    setSelectBeneTipo(e.target.value)
    setSelectBeneSubtipo("")
  }
  const handleChangeBeneSubtipo = (e) => {
    setSelectFalleSubtipo(e.target.value)
  }


  let tipoReclamacion = ""
  if (infoDataRow?.tipoReclamacion == 1) {
    tipoReclamacion = "Fallecimiento"
    tipoSiniestro2 = "Tipo de fallecimiento"

    if (infoDataRow.tipoSinies == 1) {
      tipo = "Muerte violenta"
      if (infoDataRow?.subTipoSinies == 1) {
        subTipo = "Homicidio"
      } else if (infoDataRow?.subTipoSinies == 2) {
        subTipo = "Accidente"
      } else if (infoDataRow?.subTipoSinies == 3) {
        subTipo = "Suicidio"
      }
    }
    else if (infoDataRow?.tipoSinies == 2) {
      tipo = "Enfermedad"
    }

  }

  else if (infoDataRow?.tipoReclamacion == 2) {
    tipoReclamacion = "Beneficios en vida"
    tipoSiniestro2 = "Tipo de beneficio en vida"
    tiposiniestro = false

    if (infoDataRow?.tipoSinies == 1) {
      tipo = "Invalidez"
      if (infoDataRow?.subTipoSinies == 1) {
        subTipo = "Accidente"
      } else if (infoDataRow?.subTipoSinies == 2) {
        subTipo = "Enfermedad"
      }
    } else if (infoDataRow?.tipoSinies == 2) {
      tipo = "Enfermedad terminal"
    } else if (infoDataRow?.tipoSinies == 3) {
      tipo = "Extensión de primas por desempleo"
    }
  }


  /** DATOS DEL ASEGURADO **/
  const [asegurado, setAsegurado] = useState(null);
  const [edadAse, setEdadAse] = useState(null);
  const [dataSin, setdataSin] = useState(null);

  const getDataAsegurado = async (poliza) => {
    try {
      let response = await getDataToken(`personasxpoliza/get_poliza/${poliza}/`)
      let response2 = await getDataToken(`polizasfolioxsiniestro/get_idfolio/${infoDataRow?.[0]?.RECT_idfolio?.RECT_idfolio}/`)
      if (response) {
        /** Buscar el asegurado **/
        let objetoAux = [];

        if (response2) {
          objetoAux.push(response2);
        }
        // Aplanar el arreglo para identificar el 
        const arregloAplanado = objetoAux.flat(Infinity);
        // Buscar la propiedad "SIN_idsiniestro"
        const objetoAsegradoSiniestro = arregloAplanado.find(objeto => objeto.SIN_idsiniestro);

        if (objetoAsegradoSiniestro) {
          setdataSin(objetoAsegradoSiniestro);
        }

        setAsegurado(objetoAsegradoSiniestro)
        /*** APARTADO PARA CALCULAR LA EDAD DEL ASEGURADO ****/
        let tipoSiniestro = infoDataRow[0]?.RECT_idfolio?.SINC_idcomplejidadsiniestro?.TRA_idtipotramitesiniestro;
        let fechaNacAse = objetoAsegradoSiniestro?.PER_idpersona?.PER_fechanacimiento;
        let fechaSin = objetoAsegradoSiniestro?.SIN_idsiniestro?.SIN_fechasiniestro ?
          moment(objetoAsegradoSiniestro?.SIN_idsiniestro?.SIN_fechasiniestro).toISOString() : null;

          console.log("fecha nac",fechaNacAse);
          console.log("fecha sin",fechaSin);
        if (tipoSiniestro == TRAM_FALLECIMIENTO.id && fechaSin) {
          console.log(calculateAge(fechaSin, fechaNacAse))
          setEdadAse(calculateAge(fechaSin, fechaNacAse))
        } else {
          let today = new Date();
          let todayISO = today.toISOString();
          setEdadAse(calculateAge(todayISO, fechaNacAse))
        }

      }

    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  React.useEffect(() => {
    getDataAsegurado(infoDataRow?.[0]?.POL_idpoliza?.POL_idpoliza)
  }, [])


  return (
    <div className="div-cards">
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>

        <Grid item xs={2}>
          <h3-title-card>
            <FormattedMessage
              id="glob.nodefolio"
              defaultMessage="No. de folio"
            />
          </h3-title-card>
          <h3-subtitle-card>{infoDataRow?.[0]?.RECT_idfolio?.RECT_numerofolio}</h3-subtitle-card>
        </Grid>
        <Grid item xs={2}>
          <h3-title-card>
            <FormattedMessage
              id="glob.nodepoliza"
              defaultMessage="No. de póliza"
            />
          </h3-title-card>
          <h3-subtitle-card>{infoDataRow?.[0]?.POL_idpoliza?.POL_numpoliza}</h3-subtitle-card>
        </Grid>
        <Grid item xs={3}>
          <h3-title-card>
            <FormattedMessage
              id="glob.nomasegurado"
              defaultMessage="Nombre del asegurado"
            />
          </h3-title-card>
          <h3-subtitle-card>{!asegurado ? null : `${asegurado?.PER_idpersona?.PER_nombre} ${asegurado?.PER_idpersona?.PER_apellidopaterno} ${asegurado?.PER_idpersona?.PER_apellidomaterno}`}</h3-subtitle-card>
        </Grid>
        <Grid item xs={2}>
          <h3-title-card>
            <FormattedMessage
              id="glob.sexo"
              defaultMessage="Sexo"
            />
          </h3-title-card>
          <h3-subtitle-card>{!asegurado ? null :`${asegurado?.PER_idpersona?.PER_sexo}`}</h3-subtitle-card>
        </Grid>
        <Grid item xs={2}>
          <h3-title-card>
            <FormattedMessage
              id="glob.edad"
              defaultMessage="Edad"
            />
          </h3-title-card>
          <h3-subtitle-card>{!edadAse ? null : edadAse}</h3-subtitle-card>
        </Grid>
        <Grid item xs={2}>
          <h3-title-card>
            <FormattedMessage
              id="glob.datos.fechanacimiento"
              defaultMessage="Fecha de nacimiento"
            />
          </h3-title-card>
          <h3-subtitle-card>{!asegurado ? null : moment(asegurado?.PER_idpersona?.PER_fechanacimiento).format(formatofecha)}</h3-subtitle-card>
        </Grid>
        <Grid item xs={2}>
          <h3-title-card>
            <FormattedMessage
              id="glob.fechasinies"
              defaultMessage="Fecha de siniestro"
            />
          </h3-title-card>
          <h3-subtitle-card>{!dataSin ? "" : moment(dataSin.SIN_idsiniestro?.SIN_fechasiniestro).format(formatofecha)}</h3-subtitle-card>
        </Grid>
        <Grid item xs={3}>
          <h3-title-card>
            <FormattedMessage
              id="glob.fechasinies"
              defaultMessage="Fecha de aviso"
            />
          </h3-title-card>
          <h3-subtitle-card>{moment(infoDataRow?.[0]?.RECT_idfolio?.RECT_fechaaviso).format(formatofecha)}</h3-subtitle-card>
        </Grid>
        <Grid item xs={3}>
          <h3-title-card>
            <FormattedMessage
              id="recep.edit.tiposinies"
              defaultMessage="Tipo de siniestro"
            />
          </h3-title-card>
          <h3-subtitle-card>{infoDataRow?.[0]?.RECT_idfolio?.SINC_idcomplejidadsiniestro?.TRA_destipotramitesiniestro}</h3-subtitle-card>
        </Grid>
        {/* SINIESTROS POR FALLECIMIENTO */}
        {/* {infoDataRow.tipoReclamacion!=1?  null :   
                <Grid item xs={3}>
                    <h3-title-card>
                      <FormattedMessage
                        id="recep.tipofallecimiento"
                        defaultMessage="Tipo de fallecimiento"
                      />
                    </h3-title-card>
                    <h3-subtitle-card>{tipo}</h3-subtitle-card>
                </Grid>
                }
                {infoDataRow.tipoSinies==1 && subTipo!=null && infoDataRow.tipoReclamacion==1?     
                <Grid item xs={3}>
                    <h3-title-card>
                      <FormattedMessage
                        id="recep.tipomuerteviolenta"
                        defaultMessage="Tipo de muerte violenta"
                      />
                    </h3-title-card>
                    <h3-subtitle-card>{subTipo}</h3-subtitle-card>
                </Grid> : null
                }
                {infoDataRow.subTipoSinies==1 && subTipo!=null && infoDataRow.tipoReclamacion==1?     
                <Grid item xs={3}>
                    <h3-title-card>
                      <FormattedMessage
                        id="recep.causadehomicidio"
                        defaultMessage="Causa de homicidio"
                      />
                    </h3-title-card>
                    <h3-subtitle-card>{subSubTipo}</h3-subtitle-card>
                </Grid> : null
                }
                {infoDataRow.subTipoSinies==2 && subTipo!=null && infoDataRow.tipoReclamacion==1?     
                <Grid item xs={3}>
                    <h3-title-card>
                      <FormattedMessage
                        id="recep.causadeaccidente"
                        defaultMessage="Causa de accidente"
                      />
                    </h3-title-card>
                    <h3-subtitle-card>{subSubTipo}</h3-subtitle-card>
                </Grid> : null
                } */}
        {infoDataRow?.tipoReclamacion != 1 ? null :
          <Grid item xs={3}>
            <h3-title-card>
              <FormattedMessage
                id="recep.tipofallecimiento"
                defaultMessage="Tipo de fallecimiento"
              />
            </h3-title-card>
            <Select1 array={arrayFalleTipo} label="Tipo" onChange={handleChangeFalletipo} />
          </Grid>
        }
        {selectFalleTipo === "" ? null : (selectFalleTipo === 1 ? <Grid item xs={3}>
          <h3-title-card>
            <FormattedMessage
              id="recep.tipomuerteviolenta"
              defaultMessage="Tipo de muerte violenta"
            />
          </h3-title-card>
          <Select1 array={arrayFalleSubtipo} label="Subtipo" onChange={handleChangeFalleSubtipo} /></Grid> : null)}
        {selectFalleSubtipo === "" ? null : (selectFalleSubtipo === 1 ? <Grid item xs={3} >
          <h3-title-card>
            <FormattedMessage
              id="recep.tipomuerteviolenta"
              defaultMessage="Tipo de homicidio"
            />
          </h3-title-card>
          <Select1 array={arrayFalleSubSubtipo} label="Subsubtipo" id="subtipo1" onChange={handleChangeFalleSubsubtipo} /> </Grid> :
          null)}
        {selectFalleSubtipo === "" ? null : (selectFalleSubtipo === 2 ? <Grid item xs={3}>
          <h3-title-card>
            <FormattedMessage
              id="recep.tipomuerteviolenta"
              defaultMessage="Tipo de accidente"
            />
          </h3-title-card>
          <Select1 array={arrayFalleSubSubtipo2} label="Subsubtipo" id="subtipo2" onChange={handleChangeFalleSubsubtipo} /> </Grid> :
          null)}

        {/* Grid por si seleccionan OTRO en muertes violenta>homicidio>otro*/}
        {selectFalleSubsubtipo === "" ? null :
          (selectFalleSubsubtipo === 7 && selectFalleSubtipo === 1 ? <Grid item xs={3}><h3-title-card>
            <FormattedMessage
              id="recep.tipomuerteviolenta"
              defaultMessage="Otro"
            />
          </h3-title-card><TXTField label="Otro" required /></Grid> :
            null)}
        {/* Grid por si seleccionan OTRO en muertes violenta>accidente>otro*/}

        {selectFalleSubsubtipo === "" ? null :
          (selectFalleSubsubtipo === 10 && selectFalleSubtipo === 2 ? <Grid item xs={3}><h3-title-card>
            <FormattedMessage
              id="recep.tipomuerteviolenta"
              defaultMessage="Otro"
            />
          </h3-title-card><TXTField label="Otro" required /></Grid> :
            null)}

        {/* BENEFICIOS EN VIDA */}
        {/* {infoDataRow.tipoReclamacion != 2 ? null :
          <Grid item xs={3}>
            <h3-title-card>
              <FormattedMessage
                id="recep.tipobeneficionenvida"
                defaultMessage="Tipo de beneficio en vida"
              />
            </h3-title-card>
            <h3-subtitle-card>{tipo}</h3-subtitle-card>
          </Grid>
        }
        {infoDataRow.tipoReclamacion == 2 && infoDataRow.tipoSinies == 1 ?
          <Grid item xs={3}>
            <h3-title-card>
              <FormattedMessage
                id="recep.causadesiniestro"
                defaultMessage="Causa de siniestro"
              />
            </h3-title-card>
            <h3-subtitle-card>{subTipo}</h3-subtitle-card>
          </Grid>
          : null
        } */}
        {infoDataRow?.tipoReclamacion != 2 ? null :
          <Grid item xs={3}>
            <h3-title-card>
              <FormattedMessage
                id="recep.tipobeneficionenvida"
                defaultMessage="Tipo de beneficio en vida"
              />
            </h3-title-card>
            <Select1 array={arrayBeneTipo} label="Tipo" onChange={handleChangeBenetipo} />
          </Grid>
        }

        {selectBeneTipo === "" ? null :
          (selectBeneTipo === 1 ? <Grid item xs={3} ><h3-title-card>
            <FormattedMessage
              id="recep.tipobeneficionenvida"
              defaultMessage="Causa de siniestro"
            />
          </h3-title-card>
            <Select1 array={arrayBeneSubtipo} label="Subtipo" /> </Grid>
            : null)
        }
      </Grid>
    </div>
  )
}
