import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import {Select2} from "../../Tools/Selectss/Select2"
//importaciones de la tabla
import StatusTable from "../Reportesyestadisticas/StatusTable"//tabla de administración por estatus
import CasualtyBookTable from "../Reportesyestadisticas/CasualtyBookTable"//Tabla de administración por libro de reporte siniestro
import DeathClaimTable from "../Reportesyestadisticas/DeathClaimTable"//Tabla  de administración siniestro por fallecimiento
import HomicideTable from "../Reportesyestadisticas/HomicideTable" //Tabla de administración de homicidio
import InvestigationReportTable from "../Reportesyestadisticas/InvestigationReportTable"//tabla de administración reporte de investigacion
import WithdrawalsTable from "../Reportesyestadisticas/WithdrawalsTable" //tabla de administración retiros y rescate
import ExpirationTable from '../Reportesyestadisticas/ExpirationTable'//tabla de administracion vencimientos
import AccountingReportsTable from '../Reportesyestadisticas/AccountingReportsTable'//Contable tabla reporte por cuenta
import ReportFolioTable from '../Reportesyestadisticas/ReportFolioTable'//Contable y es por folio 
import ReportPolicyTable from '../Reportesyestadisticas/ReportPolicyTable'//Contable por póliza
//Idioma
import { FormattedMessage } from "react-intl";
import ButtonVino from "../../Tools/Bottones/ButtonVino";

const CompEstadisticas = ()=>{

  //Constantes para que puedan aparecer y desaparecer las tablas del menu
  const[showEstatus, setShowEstatus]= useState (false)
  const[showLibro, setShowLibro]= useState (false)
  const[showFallecimiento, setShowFallecimiento]= useState (false)
  const[showHomicidio, setShowHomicidio]= useState (false)
  const[showInvestigacion, setShowInvestigacion]= useState (false)
  const[showRetiros, setShowRetiros]= useState (false)
  const[showVencimientos, setShowVencimientos]= useState (false)
  const[showFolio, setShowFolio]= useState (false)
  const[showPoliza, setShowPoliza]= useState (false)
  const[showContablesR, setShowContablesR]= useState (false)
  const [valorSelectLista, setValorSelectLista] = useState(0) 
  const [valorSelectMenu, setValorSelectMenu] = useState(0) 
  //Para el botón de regresar
  const[showBtnRegresarLista, setShowBtnRegresarLista]= useState (false)
  const[showBtnRegresarTabla, setShowBtnRegresarTabla]= useState (false)  
  const [valorSelectUno, setValorSelectUno] = useState(0)
  const [valorSelectDos, setValorSelectDos] = useState(0)
  const [habilitado, setHabilitado] = useState(true);

//--Aquí apareceran el primer menú
const [reporte, setReporte] = useState([]);
const reporteEstadistica = [
  { identificador: "1", texto: "Reportes Administrativos" },
  { identificador: "2", texto: "Reportes Contables" },
];
useEffect(() => {
  setReporte(reporteEstadistica);
}, []);

//subSubSubMenu de Reportes y estadisticas 
const [administrativo, setAdministrativo] = useState([]);
const [contables, setContables] = useState(false);

const AdministrativosContables = [
  { id: 1, reporteId: 1, identificador: "1", texto: "Reporte de siniestro según su estatus", },
  { id: 2, reporteId: 1, identificador: "2", texto: "Reporte libro de siniestro ocurridos", },
  { id: 3, reporteId: 1, identificador: "3", texto: "Reporte de siniestros por fallecimiento", },
  { id: 4, reporteId: 1, identificador: "4", texto: "Reporte de fallecimiento por homicidio", },
  { id: 5, reporteId: 1, identificador: "5", texto: "Reporte de investigaciones", },
  { id: 6, reporteId: 1, identificador: "6", texto: "Reporte de retiros y rescates", },
  { id: 7, reporteId: 1, identificador: "7", texto: "Reporte de vencimientos", },
  { id: 8, reporteId: 2, identificador: "8", texto: "Reporte por cuenta", },
  { id: 9, reporteId: 2, identificador: "9", texto: "Reporte por folio", },
  { id: 10, reporteId: 2, identificador: "10", texto: "Reporte por número de póliza", },
];

const handleOptionLista = (id) => {
  const dt = AdministrativosContables.filter((x) => x.reporteId == id);
  console.log(dt);
  setAdministrativo(dt);
  setContables(true);
  setShowBtnRegresarLista(true);
};

const handleOptionTablas = (ide) => {
  const dt = AdministrativosContables.filter((x) => x.id == ide);
  if(ide==1){
    setShowEstatus(true)
    setShowLibro(false)
    setShowFallecimiento(false)
    setShowHomicidio(false)
    setShowInvestigacion(false)
    setShowRetiros(false)
    setShowVencimientos(false)
    setShowFolio(false)
    setShowPoliza(false)
    setShowContablesR(false)
    setShowBtnRegresarTabla(true)
    setShowBtnRegresarLista(false)
  }if(ide==2){
    setShowEstatus(false)
    setShowLibro(true)
    setShowFallecimiento(false)
    setShowHomicidio(false)
    setShowInvestigacion(false)
    setShowRetiros(false)
    setShowVencimientos(false)
    setShowFolio(false)
    setShowPoliza(false)
    setShowContablesR(false)
    setShowBtnRegresarTabla(true)
    setShowBtnRegresarLista(false)
  }if(ide==3){
    setShowEstatus(false)
    setShowLibro(false)
    setShowFallecimiento(true)
    setShowHomicidio(false)
    setShowInvestigacion(false)
    setShowRetiros(false)
    setShowVencimientos(false)
    setShowFolio(false)
    setShowPoliza(false)
    setShowContablesR(false)
    setShowBtnRegresarTabla(true)
    setShowBtnRegresarLista(false)
  }if(ide==4){
    setShowEstatus(false)
    setShowLibro(false)
    setShowFallecimiento(false)
    setShowHomicidio(true)
    setShowInvestigacion(false)
    setShowRetiros(false)
    setShowVencimientos(false)
    setShowFolio(false)
    setShowPoliza(false)
    setShowContablesR(false)
    setShowBtnRegresarTabla(true)
    setShowBtnRegresarLista(false)
  }if(ide==5){
    setShowEstatus(false)
    setShowLibro(false)
    setShowFallecimiento(false)
    setShowHomicidio(false)
    setShowInvestigacion(true)
    setShowRetiros(false)
    setShowVencimientos(false)
    setShowFolio(false)
    setShowPoliza(false)
    setShowContablesR(false)
    setShowBtnRegresarTabla(true)
    setShowBtnRegresarLista(false)
  }if(ide==6){
    setShowEstatus(false)
    setShowLibro(false)
    setShowFallecimiento(false)
    setShowHomicidio(false)
    setShowInvestigacion(false)
    setShowRetiros(true)
    setShowVencimientos(false)
    setShowFolio(false)
    setShowPoliza(false)
    setShowContablesR(false)
    setShowBtnRegresarTabla(true)
    setShowBtnRegresarLista(false)
  }if (ide==7){
    setShowEstatus(false)
    setShowLibro(false)
    setShowFallecimiento(false)
    setShowHomicidio(false)
    setShowInvestigacion(false)
    setShowRetiros(false)
    setShowVencimientos(true)
    setShowFolio(false)
    setShowPoliza(false)
    setShowContablesR(false)
    setShowBtnRegresarTabla(true)
    setShowBtnRegresarLista(false)
  }if (ide==8){
    setShowEstatus(false)
    setShowLibro(false)
    setShowFallecimiento(false)
    setShowHomicidio(false)
    setShowInvestigacion(false)
    setShowRetiros(false)
    setShowVencimientos(false)
    setShowFolio(false)
    setShowPoliza(false)
    setShowContablesR(true)
    setShowBtnRegresarTabla(true)
    setShowBtnRegresarLista(false)
  }if(ide==9){
    setShowEstatus(false)
    setShowLibro(false)
    setShowFallecimiento(false)
    setShowHomicidio(false)
    setShowInvestigacion(false)
    setShowRetiros(false)
    setShowVencimientos(false)
    setShowFolio(true)
    setShowPoliza(false)
    setShowContablesR(false)
    setShowBtnRegresarTabla(true)
    setShowBtnRegresarLista(false)
  }if(ide==10){
    setShowEstatus(false)
    setShowLibro(false)
    setShowFallecimiento(false)
    setShowHomicidio(false)
    setShowInvestigacion(false)
    setShowRetiros(false)
    setShowVencimientos(false)
    setShowFolio(false)
    setShowPoliza(true)
    setShowContablesR(false)
    setShowBtnRegresarTabla(true)
    setShowBtnRegresarLista(false)
  }
}
const handleClickRegresarLista = (e) => {
  setContables(false);
  setShowBtnRegresarLista(false);
  setValorSelectUno(0) 
  setHabilitado(true); // Habilitar el select2
  console.log("Primer regresar")
};

const handleClickRegresarTabla = (e) => {
  setContables(true)
  setShowBtnRegresarLista(true)
  setShowBtnRegresarTabla(false)
  setShowEstatus(false)
  setShowLibro(false)
  setShowFallecimiento(false)
  setShowHomicidio(false)
  setShowInvestigacion(false)
  setShowRetiros(false)
  setShowVencimientos(false)
  setShowFolio(false)
  setShowPoliza(false)
  setShowContablesR(false)
  console.log("Segundo Regresar")
  setValorSelectDos(0)
  setHabilitado(true); // Habilitar el select2
};

const handleChangeLista = (e) => {
  handleOptionLista(e.target.value);
  setValorSelectLista(e.target.value);  
  setHabilitado(true);
  setValorSelectUno(e.target.value);
  console.log("Entre primero al onclick")
};

const handleChangeMenu = (e) => {
  handleOptionTablas(e.target.value);
  setValorSelectMenu(e.target.value);
  setValorSelectDos(e.target.value);
};

    return(
     <>
     <Grid container>
      {/*En este contenido llamaremos a los select necesarios para realizar nuestro menu
            Array=pondremos el arreglo que queremos mostrar 
            onChange= pondremos el handle que deseamos que necesitamos en esa opción      
            */}
        <Grid xs={4}>
            <h4-search>Reportes</h4-search>
            <Select2 sx={{ minWidth: 310, maxWidth: 300, background: "white", }} h3-contable='Buscar por:' 
            id="primerSelect" 
            array={reporteEstadistica} 
            value={valorSelectUno}
            onChange={handleChangeLista}
            disable={!habilitado}
            />
        </Grid>
        
        <Grid xs={4}>
          { contables?<h4-search>Tipo de reportes</h4-search>:null}
          { contables?<Select2 sx={{ minWidth: 310, maxWidth: 300, background: "white", }} h3-contable='Buscar por' 
          id='segundoSelect' 
          array={administrativo} 
          value={valorSelectDos}
          onChange={handleChangeMenu} />: null }
        </Grid>
        <Grid xs={12}>
          {/*Pondremos todas las opciones porque estas seran seleccionadas dependiendo del menu 
        que desea ver*/}
          <br />
          {showEstatus?(<StatusTable/>):null}
          {showLibro?(<CasualtyBookTable/>):null}
          {showFallecimiento?(<DeathClaimTable/>):null}
          {showHomicidio?(<HomicideTable/>):null}
          {showInvestigacion?(<InvestigationReportTable/>):null}
          {showRetiros?(<WithdrawalsTable/>):null}
          {showVencimientos?(<ExpirationTable/>):null}
          {showFolio?(<ReportFolioTable/>):null}
          {showPoliza?(<ReportPolicyTable/>):null}
          {showContablesR?(<AccountingReportsTable/>):null}
        </Grid>
        
        {/* Vista del boton donde limpia los datos del select 2 y solo muestra el 1 */}
        {showBtnRegresarLista? <Grid container justifyContent="flex-end">
        <Grid item>
         <ButtonVino
            onClick = {handleClickRegresarLista}
            label={<FormattedMessage id="globRegres" defaultMessage="Regresar"/>}
          />
        </Grid>
      </Grid>:null}

        {/* Vista del Boton para limpiar las tablas */}
      {showBtnRegresarTabla? <Grid container justifyContent="flex-end">
        <Grid item>
         <ButtonVino
            onClick = {handleClickRegresarTabla}
            label={<FormattedMessage id="globRegres" defaultMessage="Regresar"/>}
          />
        </Grid>
      </Grid>:null}
     </Grid>
     </>
    );
}
export default CompEstadisticas;