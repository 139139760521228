//  David Ruiz 10/2022

import React from 'react';
import Link from '@mui/material/Link';

const Enlace=(props)=>{
    return(
        <Link href={props.enlace} 
        //ojo aqui es disabled
        //true para desabilitar y false para habilitar
        disabled={props.estatus}>
          onClick={props.onClick}
      onChange={props.onChange}
      id={props.id}
        {props.label//texto del enlaec
        }</Link>
        );
    }

export default Enlace;