/* 
Pajita
Proyecto SINAY
2023
*/
import React from "react";
//breadcrumb

import Recorrido5 from "./Elements/Bread/Recorrido5";
import { Grid } from "@mui/material";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
import {
  TRAM_FALLECIMIENTO,
  TRAM_BENEFICIOSEV,
} from "../../utils/constanstoken";
//Alertas
import "../../css/estilo.css";
//select
import CompDefuncion from "./Elements/CompDefuncion";
import CompReclamacion from "./Elements/CompReclamacion";
import CompBeneficiosVida from "./Elements/CompBeneficiosVida";
//Idioma
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { getDataToken } from "../../api/dataToken";
import { Link } from "react-router-dom";

export function CompSiniestro() {
  //Variables para poder pasar información
  const location = useLocation();
  const InfoDataRow = location.state;
  const paso = location.state;
  console.log(InfoDataRow);
  const [visible, setVisible] = React.useState(
    InfoDataRow?.RECT_idfolio?.SINC_idcomplejidadsiniestro
      ?.TRA_idtipotramitesiniestro
  );
  console.log("Tipo tramite" + visible);
  const [row, setRow] = React.useState("");
  const idFolio = InfoDataRow?.RECT_idfolio?.RECT_idfolio;

  // //Traemos los datos de la api y de acuerdo al folio traemos su información.
  // //Dependiendo del trámite que hagamos es el formulario que se mostrara.

  const getIdPXT = async () => {
    try {
      var result = await getDataToken(
        `polizaportramites/get_folio/${idFolio}/`
      );
      setRow(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getIdPXT();
  }, []);

  console.log(row);

  return (
    <div className="div-parent">
      <div className="div-breadcrumbs">
        {/*navegador*/}
        <Recorrido5
          name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
          to="/menu"
          name2={<FormattedMessage id="globRegist" defaultMessage="Registro" />}
          to2="/registro/inicio"
          state3={{ InfoDataRow }}
          name3={
            <FormattedMessage
              id="submenuIni"
              defaultMessage="Iniciar registro"
            />
          }
          to3="/registroasegurado"
          name4={
            <FormattedMessage
              id="regisValCo"
              defaultMessage="Validar cobertura"
            />
          }
          enlace4="/cobertura"
          to4="/cobertura"
          state4={{ paso }}
          name5={
            <FormattedMessage
              id="recepDatSi"
              defaultMessage="Datos del siniestro"
            />
          }
        />
      </div>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Link
          to="/cobertura"
          state={{ paso }}
          style={{ textDecoration: "none" }}
        >
          <ReturnPage />
        </Link>
      </Grid>
      <div className="div-container">
        <Grid container>
          <Grid xs={12}>
            {/*Contenido*/}
            {visible === TRAM_FALLECIMIENTO.id ? (
              <CompDefuncion InfoDataRow={InfoDataRow} />
            ) : visible === TRAM_BENEFICIOSEV.id ? (
              <CompBeneficiosVida InfoDataRow={InfoDataRow} />
            ) : (
              <CompReclamacion InfoDataRow={InfoDataRow} />
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
