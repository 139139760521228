import React from "react";
import { Box, Grid, Container, IconButton } from "@mui/material";
import TXTField from "../Tools/TextField/TextField";
import DialogButton from "../Tools/Dialog/DialogButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import familia from "../../img/familia.png";
import "./Login.css";
import {
  BASE_API,
  URLPRODUCTION,
  DOMINIOEMPRESARIAL,
} from "../../utils/constans";
import { getUsers, postDataLogin, putDataLogin } from "./apiLogin/getUsers";
import validateEmail from "./Elements/validateEmail";
import { Buffer } from "buffer";

//idioma
import { FormattedMessage } from "react-intl";
import { LimitarTexto2 } from "../Recepcion/Elements/Functions/Validaciones";

// variables globales
let token = "";
let url = "";

export function CompRecuperarContra() {
  const [values, setValues] = React.useState({
    email: "",
  });

  const [emailExits, setEmailExits] = React.useState({});
  const [errorsExits, setErrorsExits] = React.useState({});
  const [errorsTitle, setErrorsTitle] = React.useState({});
  const [validPass, setvalidPass] = React.useState(true);
  const [validEmail, setvalidEmail] = React.useState(true);
  const [Successful, setSuccessful] = React.useState(true);
  const [link, setLink] = React.useState({});
  const [info, setinfo] = React.useState([]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  // Validamos en la api si existe un asuario con  el correo

  getUsers(`${BASE_API}login/get_correo/${values.email}/`)
    .then((json) => {
      setEmailExits(json.SNYTLOG_email);
    })
    .catch((error) => {
      console.error("There was an error:", error);
      setSuccessful(false);
    });

  const getNotificadorData = async () => {
    try {
      var result = await getUsers(
        `${BASE_API}notificador/conf-mail/get_listado/`
      );
      console.log("Resultado del notificador =>", result);
      setinfo(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getNotificadorData();
  }, []);

  console.log("Notificador" + info[0]);

  //Validar dominio

  var esValido = validateEmail(values.email, DOMINIOEMPRESARIAL);
  // console.log(esValido);

  // Generación del token

  function generarToken(email) {
    const data = {
      user: email,
      description: "Seguros I+D+I",
      developers: "QAIT developers",
      exp: Date.now() / 1000 + 60 * 10, // Expira en 10 minutos
    };
    // Convertir objeto a una cadena de texto JSON
    const json = JSON.stringify(data);
    // Codificar la cadena de texto JSON en Base64
    // const token = btoa(json);
    const token = Buffer.from(json, "binary").toString("base64");

    return token;
  }

  function generarEncoded(tokensimple) {
    const encodedToken = encodeURIComponent(tokensimple); // codificar el valor del token para su uso en una URL
    const currentUrl = `${URLPRODUCTION}Restablecer-Contra`; // obtener la URL actual

    // concatenar el parámetro del token codificado a la cadena de consulta de la URL actual
    const newUrl = currentUrl.includes("?")
      ? `${currentUrl}&token=${encodedToken}`
      : `${currentUrl}?token=${encodedToken}`;

    return newUrl;
  }

  // PUT actualizar contraseña

  async function updatePassword(aux) {
    try {
      var result = await putDataLogin(`login/putall/${emailExits}/`, aux);
      console.log("Actualizado correctamente", result);
    } catch (error) {
      console.log(error);
      setvalidPass(false);
      console.log(validPass);
      throw error;
    }
  }

  //POST Mandar email

  async function postEmail(aux) {
    try {
      var result = await postDataLogin(`notificador/mail`, aux);
      console.log("Registro exitoso", result);
      setvalidEmail(true);
    } catch (error) {
      console.log(error);
      setvalidEmail(false);
      console.log(validEmail);
      throw error;
    }
  }

  const validateRetrieveEmail = (e) => {
    e.preventDefault();

    const errorsExits = {};
    const errorsTitle = {};
    const link = {};

    errorsTitle.email = (
      <FormattedMessage id="fueServ" defaultMessage="Fuera de servicio" />
    );
    errorsExits.email = (
      <FormattedMessage
        id="peIntTar"
        defaultMessage="No es posible realizar su petición en este momento, intente más tarde."
      />
    );
    link.email = "/";

    if (esValido === false) {
      errorsTitle.email = (
        <FormattedMessage id="globCorreo" defaultMessage="Correo inválido" />
      );
      errorsExits.email = (
        <FormattedMessage
          id="globElCorr"
          defaultMessage="El correo no cumple con el formato empresarial."
        />
      );
      //errors.email = "El correo no cumple con el formato";
    } else if (emailExits === values.email) {
      token = generarToken(emailExits);
      url = generarEncoded(token);
      console.log(url);
      let aux = {
        SNYTLOG_email: emailExits,
        SNYTLOG_tokenurl: token,
      };
      updatePassword(aux);
      let auxpost = {
        EMLNT_email: emailExits,
        EMLNT_asunto: "Restablecimiento de contraseña",
        EMLNT_texto:
          "Ingrese a la siguiente liga para el restablecimiento de su contraseña",
        EMLNT_texto2: url,
        EMLNT_PORT: info[0].EMLNT_PORT,
        EMLNT_TLS: info[0].EMLNT_TLS,
        EMLNT_HOST: info[0].EMLNT_HOST,
        EMLNT_HOST_USER: info[0].EMLNT_HOST_USER,
        EMLNT_HOST_PASSWORD: info[0].EMLNT_HOST_PASSWORD,
      };
      postEmail(auxpost);
      if (validPass === false && validEmail === false) {
        errorsTitle.email = (
          <FormattedMessage id="fueServ" defaultMessage="Fuera de servicio" />
        );
        errorsExits.email = (
          <FormattedMessage
            id="peIntTar"
            defaultMessage="No es posible realizar su petición en este momento, intente más tarde."
          />
        );
      } else if (validEmail === false) {
        errorsTitle.email = (
          <FormattedMessage id="fueServ" defaultMessage="Fuera de servicio" />
        );
        errorsExits.email = (
          <FormattedMessage
            id="peIntTar"
            defaultMessage="No es posible realizar su petición en este momento, intente más tarde."
          />
        );
      } else if (validEmail) {
        errorsTitle.email = (
          <FormattedMessage
            id="loginRecCo"
            defaultMessage="Recuperar contraseña"
          />
        );
        errorsExits.email = (
          <FormattedMessage
            id="loginReCo"
            defaultMessage="Revisa tu correo electrónico y sigue los pasos para restablecer tu contraseña."
          />
        );

        link.email = "/";
      }
    } else if (Successful === false) {
      errorsTitle.email = (
        <FormattedMessage id="globCorreo" defaultMessage="Correo inválido" />
      );
      errorsExits.email = (
        <FormattedMessage
          id="globCoNoReg"
          defaultMessage="Tu cuenta no esta registrada"
        />
      );
    } else {
      errorsTitle.email = (
        <FormattedMessage id="fueServ" defaultMessage="Fuera de servicio" />
      );
      errorsExits.email = (
        <FormattedMessage
          id="peIntTar"
          defaultMessage="No es posible realizar su petición en este momento, intente más tarde."
        />
      );
    }
    setErrorsExits(errorsExits);
    setErrorsTitle(errorsTitle);
    setLink(link);
  };

  // Definir el tiempo de inactividad deseado en milisegundos
  const INACTIVITY_TIMEOUT = 660000; // 11 minuto

  let timeoutId;

  // Función para reiniciar el temporizador
  function resetTimer() {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(logout, INACTIVITY_TIMEOUT);
  }

  // Función para registrar la inactividad del usuario
  function logout() {
    // Aquí puedes agregar código para realizar alguna acción cuando el usuario está inactivo durante un período de tiempo determinado, como cerrar sesión, mostrar un mensaje de advertencia, etc.
    console.log("Usuario inactivo durante demasiado tiempo");
    let auxpassword = {
      SNYTLOG_email: emailExits,
      SNYTLOG_tokenurl: "",
    };
    updatePassword(auxpassword);
  }

  // Agregar event listeners para detectar eventos de interacción del usuario
  document.addEventListener("click", resetTimer);
  document.addEventListener("keypress", resetTimer);

  // Iniciar el temporizador al cargar la página
  resetTimer();

  return (
    <form onSubmit={validateRetrieveEmail}>
      <div className="div-parent">
        <Box px={{ xs: 4, sm: 10 }} py={{ xs: 1, sm: 10 }}>
          <Container maxWidth="lg">
            <Grid container spacing={5}>
              <Grid item xs={12} sm={5}>
                <Box>
                  <IconButton
                    id="iconRegresar"
                    size="large"
                    color="primary"
                    href="/"
                  >
                    <ArrowBackIcon fontSize="inherit" />
                  </IconButton>
                  <br />
                  <br />
                  <h2-login-title>
                    <FormattedMessage
                      id="loginRecCo"
                      defaultMessage="Recuperar contraseña"
                    />
                  </h2-login-title>
                  <hr class="hr-line" align="left" />
                  <p align="justify">
                    <h3-login-subtitle>
                      <FormattedMessage
                        id="loginCaEle"
                        defaultMessage="Captura el correo electrónico empresarial con el que te registraste."
                      />
                    </h3-login-subtitle>
                  </p>
                  <TXTField
                    label={
                      <FormattedMessage
                        id="loginCorEm"
                        defaultMessage="Correo electrónico empresarial"
                      />
                    }
                    size="small"
                    id="lblCorreo"
                    value={values.email}
                    required={true}
                    onChange={handleChange("email")}
                    onInput={(e) => LimitarTexto2(e.target, 50)}
                  />
                  <br />
                  <br />
                  <DialogButton
                    id1="btnAceptar"
                    id2="btnActAcep"
                    mainButton={
                      <FormattedMessage
                        id="globEnviar"
                        defaultMessage="Enviar"
                      />
                    }
                    title={errorsTitle.email}
                    description={errorsExits.email}
                    button={
                      <FormattedMessage
                        id="globAceptr"
                        defaultMessage="Aceptar"
                      />
                    }
                    type="submit"
                    estatus={values.email.length > 0 ? false : true}
                    state={emailExits}
                    to={link.email}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Box>
                  <img
                    src={familia}
                    alt="Generando confianza en tus aplicaciones"
                    width="150%"
                    height="150%"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box></Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
    </form>
  );
}
