import React, { useState, useEffect } from "react";

//importaciones del estilo
import "../../../../css/estilo.css";
import "../Forms/Header.css";

//importacion de api para el uso de los codigos postales
import { getDataToken } from "../../../../api/dataToken";
import { putDataToken } from "../../../../api/dataToken";
//importaciones de mui y componentes estandarizados
import { Grid } from "@mui/material";
import TXTField from "../../../Tools/TextField/TextField";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { Select1 } from "../../../Tools/Selectss/Select1";
import { LimitarDigitos } from "../Functions/Validaciones";
import { LimitarTexto } from "../Functions/Validaciones";
import { useLocation } from "react-router-dom";

//importaciones del lenguaje
import { FormattedMessage } from "react-intl";
import { formatNumberString } from "../../../../utils/functions";

function TramiteContratante() {
  //declaramos las siguientes variables que toman los datos que se recibieron en formulario poliza sin registro
  const location = useLocation();
  const infoRow = location.state.prueba;

  // //Paso variables de tabla principal de recepcion para crear un tramite si se desea un registro de policy master
  const location2 = useLocation();
  const infoRow2 = location2.state.pasoVariables;

  // si existe (infoRow no es nulo ni undefined),
  // de lo contrario, le asigna el valor de infoRow2.
  const RegistroActual = infoRow || infoRow2;
  //Valida que el formulario este lleno para poder enviar la informacion
  const [formValid, setFormValid] = useState(false);


  /*funciones CP sepomex */
  const [SEPT_Id, setSEPT_Id] = useState("");
  const [SEPT_asenta, setSEPT_asenta] = useState("");
  const [SEPT_municipio, setSEPT_municipio] = useState("");
  const [SEPT_estado, setSEPT_estado] = useState("");
  const [SEPT_ciudad, setSEPT_ciudad] = useState("");
  const [codigopostal, setCodigoPostal] = useState("");

  const [helperCP, setHelperCP] = useState("");
  const [colonias, setColonias] = useState([]);
  const [dataSelectColonias, setDataSelectColonias] = useState([]);

  //Traer los datos de CP
  const [dataContacto ,setDataContacto] = useState({
    nombre : "",
    apeMat: "",
    apePat: "",
  })

  const getCodP = async (cp) => {
    try {
      var result = await getDataToken(`sepomex/get_listado_cp/${cp}`);
      console.log("Resultado del cp =>", result);
      setColonias(result);
      if (result <= 0) {
        setHelperCP("No hay Información.")
        setDataSelectColonias([])
        setSEPT_estado("");
        setSEPT_municipio("");
        setSEPT_asenta("");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  //CP

  const handleSubmitone = () => {
    if (codigopostal === "") {
      document.getElementById("txtCP").focus();
    } else {
      getCodP(codigopostal);
      setHelperCP("")
    }

  };


  const getInfoasentamiento = (e) => {
    const dataColonia = parseInt(e.target.value);
    colonias.forEach((element) => {
      if (dataColonia === element.id) {
        setSEPT_Id(element.id);
        setSEPT_municipio(element.SEP_municipio);
        setSEPT_estado(element.SEP_estado);
        setSEPT_ciudad(element.SEP_ciudad);
        setSEPT_asenta(element.SEP_asenta);
      }
    });
  };

  //Esta función useEffect se activará cuando haya cambios en el estado 'colonias', activando a su ves otro estado
  useEffect(() => {
    console.log(colonias);
    if (colonias.length > 0) {
      let dataColonias = colonias
      dataColonias.map(
        (cp) => (
          (cp.texto = cp.SEP_asenta), // cp.texto es una propiedad que no existe dentro del JSON, por tanto, se crea
          (cp.identificador = cp.id)
        )
      ); //Se actualiza el valor del dataSelectColonias
      setDataSelectColonias(dataColonias)
    }
  }, [colonias]);

  return (
    <Grid container direction="row"
      justifyContent="flex-start"
      alignItems="center" spacing={1}>
      {/*campos a llenar para beneficiario */}

      <Grid item xs={3}>
        <TXTField
          label={
            <FormattedMessage id="datos.nom" defaultMessage="Nombre(s)" />
          }
          name="txtNombre"
          id="txtNombre"
          value={dataContacto.nombre}
          onChange={(e) => {
            setDataContacto(prevState => ({ ...prevState, nombre: formatNumberString(e.target.value, 18, true, 3) }));
          }}
          required
        />
      </Grid>
      <Grid item xs={3} >
        <TXTField
          label={
            <FormattedMessage
              id="datos.apellidopate"
              defaultMessage="Apellido paterno"
            />
          }
          name="txtApePat"
          id="txtApePat"
          value={dataContacto.apePat}
          onChange={(e) => {
            setDataContacto(prevState => ({ ...prevState, apePat: formatNumberString(e.target.value, 18, true, 3) }));
          }}
          required
        />
      </Grid>
      <Grid item xs={3}>
        <TXTField
          label={
            <FormattedMessage
              id="datos.apellidomate"
              defaultMessage="Apellido materno"
            />
          }
          name="txtApeMat"
          id="txtApeMat"
          value={dataContacto.apeMat}
          onChange={(e) => {
            setDataContacto(prevState => ({ ...prevState, apeMat: formatNumberString(e.target.value, 18, true, 3) }));
          }}
          required
        />
      </Grid>

      {/*campos a llenar para beneficiario  correo*/}

      <Grid item xs={3}>
        <TXTField
          label={
            <FormattedMessage
              id="comuCorreo"
              defaultMessage="Correo eléctronico"
            />
          }
          name="txtCorreo"
          id="txtCorreo"
          required
        />
      </Grid>
      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage
              id="recepTelfi"
              defaultMessage="Teléfono fijo"
            />
          }
          name="txtTelFijo"
          id="txtTelFijo"
          onInput={(e) => LimitarDigitos(e.target, 10)}
          required
        />
      </Grid>
      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage
              id="recepTelCe"
              defaultMessage="Teléfono celular"
            />
          }
          name="txtTelCel"
          id="txtTelCel"
          onInput={(e) => LimitarDigitos(e.target, 10)}
          required
        />
      </Grid>
      {/*campos de direccion */}
      {/* en este grid se escribira el codigo postal y posteriormente lo buscaremos en el boton buscar*/}
      <Grid item xs={3}>
        <TXTField
          helperText={helperCP}
          label={
            <FormattedMessage
              id="globCodPos"
              defaultMessage="Código postal"
            />
          }
          value={codigopostal}
          name="txtCP"
          id="txtCP"
          idIcon="iconCP"
          nameIcon="iconCP"
          onChange={(event) => {
            setCodigoPostal(event.target.value);
          }}
          onInput={(e) => LimitarDigitos(e.target, 6)}
          showSearchIcon={true}
          iconOnClick={handleSubmitone}
        />
      </Grid>

      <Grid item xs={3} >
        <Select1
          array={dataSelectColonias}
          onChange={getInfoasentamiento}
          id="SEP_idsepomex"
          name="SEP_idsepomex"
        />
      </Grid>
      {/*campos donde aparece la localidad cuando se busca por codigo postal*/}

      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage id="globEstado" defaultMessage="Estado" />
          }
          value={SEPT_estado}
          required
        />
      </Grid>
      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage
              id="globMunicipioAlcadia"
              defaultMessage="Municipio o Alcaldia"
            />
          }
          value={SEPT_municipio}
          style={{ position: "sticky" }}
          required
        />
      </Grid>

      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage id="globColoni" defaultMessage="Colonia" />
          }
          value={SEPT_asenta}
          required
        />
      </Grid>

      {/*datos de calle y numero */}
      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage id="globCalle" defaultMessage="Calle" />
          }
          name="txtCalle"
          id="txtCalle"
          required
        />
      </Grid>

      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage
              id="recepNuExt"
              defaultMessage="No. exterior"
            />
          }
          name="txtExt"
          id="txtExt"
          onInput={(e) => LimitarDigitos(e.target, 4)}
          required
        />
      </Grid>
      <Grid xs={3} item>
        <TXTField
          label={
            <FormattedMessage
              id="recepNuInt"
              defaultMessage="No. interior"
            />
          }
          onInput={(e) => LimitarDigitos(e.target, 4)}
          name="txtInt"
          id="txtInt"
          required
        />
      </Grid>
    </Grid>

  );
}

export default TramiteContratante;
