/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import BTUpload from "../../Tools/Bottones/BTUpload";
import { Grid } from "@mui/material";
import TXTField from "../../Tools/TextField/TextField";
import { uploadFilesToNextcloud } from "../../../nextcloud/functions";
//idioma
import { FormattedMessage } from "react-intl";
import DialogAviso from "../../Registro/Elements/Dialog/DialogAviso";
/* ----------------------------------------------------------------------------------------------------------------------------------------------- */
//Solo aparecerá la alerta con dos botones
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
/* ----------------------------------------------------------------------------------------------------------------------------------------------- */
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

/* ----------------------------------------------------------------------------------------------------------------------------------------------- */
/* CONSTANTES PARA EL GUARDADO DE DATOS */
export default function DialogUploadDoc(props) {
  const [open, setOpen] = React.useState(false);
  const [selectDocs, setSelectDocs] = React.useState([]);
  const [newFileName, setnewFileName] = React.useState([]);
  const [visible, setvisible] = React.useState(false);

  const arrayInfoData = props.infoDataRow;
  const [disabledBtn, setDisabledBtn] = React.useState(true);
  const arrayDocs = [{ id: 0 }, { id: 1 }];
  const [documents, setDocuments] = React.useState(arrayDocs);
  const [dialogMessage, setDialogMessage] = React.useState("");

  React.useEffect(() => {
    if (selectDocs.length > 0) {
      const FileName = "INV_InformeInvestigacion"; // reemplace 'newName.ext' con el nombre y la extensión que desee
      const newFile = new File([selectDocs], FileName, {
        type: selectDocs.type,
      });
      console.log("nuevo nombre", newFile);
      setnewFileName(newFile);
    }
    console.log("Archivo", selectDocs);
    console.log("NewArchivo", newFileName);
  }, [selectDocs]);

  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  //Esta funcion permite cargar archivos a nextcloud
  const [btnCarga, setBtnCarga] = React.useState({
    estatus: true,
    label: "Guardar",
  });

  const cargarArchivos = async () => {
    setBtnCarga({ ...btnCarga, label: "Subiendo...", estatus: true });
    if (arrayInfoData !== "" && selectDocs.length > 0) {
      var response = await uploadFilesToNextcloud(
        arrayInfoData.num_folio,
        selectDocs
      ); //Se llama a la funcion uploadFilesToNextCloud(folio,docs)
      if (response) {
        //Si la respuesta es true, mandar mensaje de exito.
        // alert("Archivo cargado  exitosamente");
        setvisible(true);
        setBtnCarga({ ...btnCarga, label: "Cargado", estatus: true });
      } else {
        //Si no, manda mensaje informando el estado de la operación. Por ejemplo
        setBtnCarga({ ...btnCarga, label: "Fallido", estatus: true });
        alert("Ocurrió un error al archivo");
        throw new Error("Ocurrió un error al archivo");
      }
    } else {
      alert("Selecciona archivos.");
    }
  };
  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  //METODOS PARA EL CAMBIO DE LOS ESTADOS DE LOS BOTONES
  const handleClickOpen = () => {
    setDocuments(arrayDocs);
    setOpen(true);
  };
  const handleClose = () => {
    setSelectDocs([]);
    setOpen(false);
  };
  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  //CHILDREN DIALOG
  const [open2, setOpen2] = React.useState(false);
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false); // Close the children dialog
    handleClose(); // Close the parent dialog
  };
  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  //AQUI DEBE HACER LA PETICIÓN A LA API PARA DESPUES MOSTRAR LA RESPUESTA EN EL DIALOGO HIJO
  React.useEffect(() => {
    setDialogMessage(
      <div>
        <h2-dialog-description>
          <label>
            {
              <FormattedMessage
                id="fsfs"
                defaultMessage="Documentos guardados."
              />
            }
          </label>
        </h2-dialog-description>
      </div>
    );
  }, []);

  const printDoc = () => {
    var files = selectDocs;
    var aux = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      aux.push(
        <>
          <dd>
            <h3-subtitle-card>- {file.name} </h3-subtitle-card>
          </dd>
          <br />
        </>
      );
    }
    return aux;
  };
  React.useEffect(() => {
    selectDocs.length > 0 ? setDisabledBtn(false) : setDisabledBtn(true);
  }, [selectDocs]);

  const handleClick = () => {
    // Actualizar el estado utilizando el hook useState
    setvisible(false);
    handleClose();
    setBtnCarga({ ...btnCarga, label: "Guardar", estatus: true });
  };

  return (
    <div>
      {visible && (
        <DialogAviso
          title="Mensaje informativo"
          firstButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description={"Archivo cargado  exitosamente"}
          onClick1={handleClick}
        />
      )}
      {/*BOTON QUE HACE EL LLAMADO AL DIALOGO*/}
      {/*Este boton aparecera el js en donde se necesite de la alerta*/}
      <ButtonVino
        onClick={handleClickOpen}
        label={props.mainButton}
        estatus={props.disabledbtn}
      />
      {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
      <BootstrapDialog
        maxWidth={"md"}
        fullWidth={true}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        open={open}
      >
        {/*CUERPO DEL DIALOGO*/}
        {/*TITILO*/}
        <BootstrapDialogTitle
          id="dialog"
          onClose={handleClose}
          style={{ color: "#761f2a" }}
        >
          <h1-dialog-title>{props.title}</h1-dialog-title>
        </BootstrapDialogTitle>
        {/*CUERPO DEL DIALOGO*/}
        {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleClickOpen2();
          }}
        >
          <DialogContent dividers style={{ minWidth: 500 }}>
            {" "}
            {/* <--------------------------------  */}
            {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
          se necesita colocar entre un typogreaphy las veces que des salto de linea 
          o con un br*/}
            <h2-dialog-description>{props.description}</h2-dialog-description>
            <p></p>
            <Grid container spacing={6}>
              <Grid item>
                <h2-dialog-description>
                  <label>
                    {
                      <FormattedMessage
                        id="msgInvSuAr"
                        defaultMessage="Subir archivos"
                      />
                    }
                  </label>
                </h2-dialog-description>
                <BTUpload
                  accept=".pdf, .jpg"
                  id="bt-upload1"
                  multiple={true}
                  onChange={(e) => {
                    var files = e.target.files;
                    const renamedFiles = [];
                    const FileName = "INV_InformeInvestigacion"; // Nuevo nombre para los archivos

                    for (let i = 0; i < files.length; i++) {
                      let file = files[i];
                      // Realiza la validación del archivo aquí
                      if (file.size > 2 * 1024 * 1024) {
                        console.log("El tamaño máximo permitido es de 2 MB.");
                        continue; // Salta este archivo si no pasa la validación
                      }

                      // Extrae la extensión del archivo
                      const extension = file.name.split(".").pop();
                      // Crea un nuevo nombre de archivo con la extensión
                      const newName = FileName + "_" + i + "." + extension;

                      // Crea un nuevo archivo con el nombre renombrado
                      const renamedFile = new File([file], newName, {
                        type: file.type,
                      });
                      renamedFiles.push(renamedFile);
                    }

                    // Actualiza el arreglo de archivos con los archivos renombrados
                    setSelectDocs(renamedFiles);
                    console.log(renamedFiles);
                    //uploadFilesToNextcloud(rowFolio, renamedFiles);
                  }}
                />
              </Grid>
              <Grid item></Grid>
              <Grid item>
                <h2-dialog-description>
                  <label>
                    {
                      <FormattedMessage
                        id="globAgrega"
                        defaultMessage="Archivos seleccionados"
                      />
                    }
                    :
                  </label>
                </h2-dialog-description>
                <br />
                {printDoc()}
              </Grid>
            </Grid>
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <h2-dialog-description>
                  <label>
                    {
                      <FormattedMessage
                        id="globComent"
                        defaultMessage="Comentarios"
                      />
                    }
                  </label>
                </h2-dialog-description>
                <TXTField type="text" size="small" multiline={true} required />
              </Grid>
            </Grid>
            {/*<TXTField ></TXTField>*/}
            {/*RFCErr ? null: <p style={{textAlign: "center"}}><label>Error, verifica R.F.C.</label></p>*/}
          </DialogContent>
          {/*es la tercer sección que engloba los botones */}
          <DialogActions>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item xs={"auto"}>
                <ButtonVino
                  onClick={() => cargarArchivos()}
                  label={btnCarga.label}
                  estatus={disabledBtn}
                />
              </Grid>

              <Grid item xs={"auto"}>
                <ButtonVino label={props.secondButton} onClick={handleClose} />
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </BootstrapDialog>
      {/* CHILDREN DIALOG */}
      <div>
        {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
        <BootstrapDialog
          onClose={handleClose2}
          aria-labelledby="dialog"
          open={open2}
        >
          {/*CUERPO DEL DIALOGO*/}
          {/*TITULO*/}
          <BootstrapDialogTitle
            id="dialog"
            onClose={handleClose2}
            style={{ color: "#761f2a" }}
          >
            <h1-dialog-title></h1-dialog-title>
          </BootstrapDialogTitle>

          {/*CUERPO DEL DIALOGO*/}
          {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
          <DialogContent dividers style={{ minWidth: 500 }}>
            {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
              se necesita colocar entre un typogreaphy las veces que des salto de linea 
              o con un br*/}
            <h2-dialog-description>{dialogMessage}</h2-dialog-description>
          </DialogContent>
          {/*es la tercer sección que engloba los botones */}
          <DialogActions>
            <Grid container justifyContent="center">
              <ButtonVino onClick={handleClose2} label={"Aceptar"} />
            </Grid>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </div>
  );
}
