import React from 'react'
import {FormattedMessage} from 'react-intl';
export function Infobene(){
return (
    <div>
    <h3-title-card>
    <FormattedMessage
            id="documElBeneficioDeIn"
            defaultMessage="EL BENEFICIO DE INVALIDEZ SÓLO SE PAGA AL ASEGURADO"
        />
    <br/>
    </h3-title-card>
    <h3-subtitle-card>
    <br/>
    <FormattedMessage
            id="documPolizaOriginalE"
            defaultMessage="Póliza original. En caso de invalidez, con una copia de la póliza es suficiente."
        />
    <br/>
    <FormattedMessage
            id="documHistoriaClinica"
            defaultMessage="Historia clínica completa (Original o copia certificada) Con pronóstico y estado actual de salud del asegurado."
        />
    <br/>
    <FormattedMessage
            id="documFormatodeInvali"
            defaultMessage="Formato de invalidez (Original o copia certificada) Formato de declaración para el trámite de reclamación de beneficios adicionales. Debidamente llenado y firmado por el asegurado &nbsp;&nbsp;&nbsp; y el médico tratante."
        />
    <br/>
    <FormattedMessage
            id="doculForInformMedico"
            defaultMessage="Formato informe médico de beneficios de indemnización por pérdidas orgánicas. Debidamente llenado y firmado por el Asegurado y el médico tratante. (Solo si cuenta con cobertura DIBA). Identificación Oficial Asegurado"
        />
    <br/>
    <FormattedMessage
            id="recepDatosBancarios"
            defaultMessage="Datos bancarios estado de cuenta del asegurado no mayor a 3 meses de antigüedad. formato reclamación formato de información para pago. Debidamente llenado y firmado por el asegurado"
        />
    <br/>
    <FormattedMessage
            id="dictamOtro"
            defaultMessage="Otros"
        />
    <br/>
    </h3-subtitle-card>
   
    </div>

    






);
}