import moment from "moment";

//Esta función es para validar si una poliza es disputable o no
export default function obtenerPoliza(fechaEmision, fechaRecepcion) {
  // Calcular la diferencia en días
  const diffDays = moment(fechaRecepcion).diff(fechaEmision, "days");

  if (diffDays > 730) {
    //Póliza indisputable
    return true;
  } else {
    //Póliza disputable
    return false;
  }
}
