import React from "react";
import  { BotonesLayout } from '../../components/Tools/Bottones/BotonesLayout';
import Nav  from '../../components/Tools/Header/Nav';
import  Footer  from '../../components/Tools/Footer/Footer';

export function Botones(){
    return(
        <div>
            <Nav/>
                    <h1>BOTONES</h1>
                    <BotonesLayout />
            <Footer/>
        </div>
    );
}