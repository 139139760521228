import { getToken, removeToken } from "./token";
import { getURL } from "./getURL";
import { TOKENLOGIN } from "../utils/constans";
/**
 *
 * @param {string} url Recibe como parámetro una URL de la API
 * @returns {Any} Retorna el resultado de la API
 */

/* 
TODO: Ejemplo 
const getData = async () => {
    try {
        var result = await getDataToken("monedas/get_id/1/");
        console.log("resultado get data SINAY EQUIPO =>", result.MON_nombre);
      } catch (error) {
        console.log(error);
        throw error;
      }
    }
    useEffect(() => {
      getData()
    }, [])
    */
export async function getDataToken(url) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("authorization", "Bearer " + getToken());

  try {
    const response = await fetch(`${await getURL()}${url}`, {
      method: "GET",
      headers: headers,
    });

    if(response.status === 403) {
      window.location.href = "/unauthorized";    
    }else if(response.status === 401) {
      window.location.href = "/InvalidToken";    
    }
    
    if (!response.ok) {
      throw new Error("Something went wrong. Status: " + response.status);
    }
    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

//console.log(TOKENLOGIN)

export async function getDataTokenconstante(url) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("authorization", "Bearer " + TOKENLOGIN);

  try {
    const response = await fetch(`${await getURL()}${url}`, {
      method: "GET",
      headers: headers,
    });

    if (!response.ok) {
      console.error("Something went wrong. Status: " + response.status);
    }
    if(response.status === 403) {
      window.location.href = "/unauthorized";    
    }else if(response.status === 401) {
      window.location.href = "/InvalidToken";    
    }
    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    console.log(error.message);
    return null;
  }
}
/**
 *
 * @param {string} url Recibe como parámetro una URL de la API
 * @param {json} JsonBody Recibe un JSON '{}' según lo requerido
 * @returns {Any} Retorna el resultado de la API
 */
export async function postDataToken(url, JsonBody) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("authorization", "Bearer " + getToken());
  try {
    const response = await fetch(`${await getURL()}${url}`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(JsonBody),
    });

    if (!response.ok) {
      throw new Error(
        "Something went wrong. Status: " + response.status + getURL() + url
      );
    }
    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

/**
 *
 * @param {string} url Recibe como parámetro una URL de la API
 * @param {json} JsonBody Recibe un JSON '{}' según lo requerido
 * @returns {Any} Retorna el resultado de la API
 */
export async function putDataToken(url, JsonBody) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("authorization", "Bearer " + getToken());
  try {
    const response = await fetch(`${await getURL()}${url}`, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(JsonBody),
    });

    if (!response.ok) {
      throw new Error(
        "Something went wrong. Status: " + response.status + getURL() + url
      );
    }
    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

/**
 * 
 * @param {*} date Recibe un parametro tipo string con formato fecha YYYY-MM-DD
 * @param {*} from Recibe el acrónimo o abreviatura de la moneda base, ejemplo: MXN
 * @param {*} to Recibe el acrónimo o abreviatura de la moneda a convertir, ejemplo: USD
 * @returns Retrona la respuesta de la api 
 */
export async function getCurrencyExchangeHistorical(date, from, to) {
  try {
    let response = await fetch("http://apilayer.net/api/historical"
      + "?access_key=4f185199f52bf4ad0b8803b2417fdf7a"
      + `&date=${date}`
      + `&source=${from}`
      + `&currencies=${to}`
      + "&format=1");
    if (response.success) {
      throw new Error(
        "Something went wrong. Error: " + response.error.info
      );
    }
    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    console.error(error.message);
  }
}
/**
 * 
 * @param {String} startDate Receives a string parameter with YYYY-MM-DD structure
 * @param {String} endDate Receives a string parameter with YYYY-MM-DD structure
 * @returns {JSON} Returns an object of type json
 */
export async function getCurrencyExchangeBanxico(startDate, endDate) {
  try {
    if (startDate && endDate) {
      const response = await fetch(`https://www.banxico.org.mx/SieAPIRest/service/v1/series/SF43718/datos/${startDate}/${endDate}?token=c8dd801765efc1a5a2e30c4465b07c79d34a3c1215771436129bc4a0cb614880`);
      let data = await response.json();
      console.log(data);
      if (!data.bmx.series[0].datos) {
        const response = await fetch("https://www.banxico.org.mx/SieAPIRest/service/v1/series/SF43718/datos/oportuno?token=c8dd801765efc1a5a2e30c4465b07c79d34a3c1215771436129bc4a0cb614880");
        let data = await response.json();
        console.log("datos actuales", data);
        return data;
      } else {
        return data;
      }
    } else {
      const response = await fetch("https://www.banxico.org.mx/SieAPIRest/service/v1/series/SF43718/datos/oportuno?token=c8dd801765efc1a5a2e30c4465b07c79d34a3c1215771436129bc4a0cb614880");
      let data = await response.json();
      console.log(data);
      return data;
    }

  } catch (e) {
    return null;
  }
}