import React from "react";
import { CompRestablecerContra } from "../../../components/Login/CompRestablecerContra";

export function RestablecerContra() {
  return (
    <div>
      <CompRestablecerContra time={540000} />
    </div>
  );
}
