//  David Ruiz 10/2022

import React from "react";
import IconButton from "@mui/material/IconButton";
import GavelIcon from "@mui/icons-material/Gavel";

import { FormattedMessage } from "react-intl";

const BtIndicacionDicta = (props) => {
  return (
    <div>
      <nav>
        <IconButton
          aria-label="GavelIcon"
          color="icons"
          //aqui va el enlace
          href={props.enlace}
          onClick={props.onClick}
          onChange={props.onChange}
          id={props.id}
          //ojo aqui es disabled
          //true para desabilitar y false para habilitar
          disabled={props.estatus}
        >
          <GavelIcon variant="dark"></GavelIcon>
          <h3-medic-icons>
            <FormattedMessage
              id="medicInInv"
              defaultMessage="Indicaciones de dictamen"
            />
          </h3-medic-icons>
        </IconButton>
      </nav>
    </div>
  );
};
export default BtIndicacionDicta;
