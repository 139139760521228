import React from "react";
import { CompCobertura } from "../../../components/Registro/CompCobertura";
import MyComponent from "../../../components/Tools/ButtonLoading/ButtonLoading";

export function Cobertura() {
  return (
    <div>
      <MyComponent componentToRender={CompCobertura} />
    </div>
  );
}
