import React from "react";
import { CompEditarTramite } from "../../../components/Recepcion/CompEditarTramite";


//import  { BotonesLayout } from '../../components/Bottones/BotonesLayout';

export function EditarTramite() {
  return (
    <div>
     
      <CompEditarTramite />
  
    </div>
  );
}
