//  David Ruiz 10/2022

import React from "react";
//icono
import IconButton from '@mui/material/IconButton';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import DialogImg from "../Dialog/DialogImg";



//idioma
import {FormattedMessage} from 'react-intl';

function App(props) {

  return (
    <div>
      <nav>
        <IconButton aria-label="FindInPageIcon" color="icons"  
        href={props.enlace}
        //ojo aqui es disabled
        //true para desabilitar y false para habilitar
        disabled={props.estatus}
        onClick={props.onClick}
        onChange={props.onChange}
        id={props.id}>
        
        <FindInPageIcon  variant="dark">
           </FindInPageIcon>
           <h3-medic-icons><FormattedMessage id="medicInInv" defaultMessage="Informe del Investigador"/></h3-medic-icons>  
      </IconButton>
    </nav>
    </div>
  );
}

export default App;