import * as React from "react";

import MailEnt from "./MailEnt";
import Layout from "./Elements/layout";
import Recorrido from "../../Tools/BreadCrumbs/Recorrido3";
import WhatsApp from "./Elements/whatsapp";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

//idioma
import { FormattedMessage } from "react-intl";
import { ImportContacts } from "@mui/icons-material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MailExit() {
  //! =======> Variables don't touch
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //TODO: Return
  return (
    <div className="div-parent">
      <div className="div-breadcrumbs">
        <Recorrido
          name1={<FormattedMessage id="globInicio" defaultMessage="Inicio" />}
          enlace="/confadmin"
          name2={
            <FormattedMessage
              id="conGConfiCo"
              defaultMessage="Configuración Comunicación "
            />
          }
        />
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
          >
            <Tab
              label={
                <FormattedMessage
                  id="conGConCorr"
                  defaultMessage="Configuración Correo"
                />
              }
              {...a11yProps(0)}
            />
            <Tab label="|" disabled />
            <Tab
              label={
                <FormattedMessage
                  id="conGLayoC"
                  defaultMessage="Layout Correo"
                />
              }
              {...a11yProps(1)}
            />
            <Tab label="|" disabled />
            <Tab
              label={
                <FormattedMessage
                  id="conGWhats"
                  defaultMessage="WhatsApp Api"
                />
              }
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div>
            <MailEnt />
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div>
            <Layout />
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div>
            <WhatsApp />
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}
