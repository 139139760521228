//  David Ruiz 10/2022

import React from 'react';
import IconButton from '@mui/material/IconButton';
import DvrIcon from '@mui/icons-material/Dvr';


const Btconslinea=(props)=>{

    return(
        <IconButton aria-label="DvrIcon" color="icons" 
        //aqui va el enlace
        href={props.enlace}
        onClick={props.onClick}
        onChange={props.onChange}
        id={props.id}
        //ojo aqui es disabled
        disabled={props.estatus} //true para desabilitar y false para habilitar
        >
        <DvrIcon/>
        </IconButton>
        );
    
}
export default Btconslinea;