//Póliza => índice en pm 
export const POLIZA_PM = {
    NUM_POLIZA : 0,
    NUM_POLIZA_PADRE : 1,
    MONEDA_POLIZA : 2,
    PAGADO_HASTA : 3,
    ESTATUS : 4,
    DURACION : 5,
    FONDO_INVERSION : 6,
    MONEDA_FONDO : 7,
    PRESTAMO : 8,
    MONEDA_PRESTAMO : 9,
    CANAL : 10,
    OFICINA_EMISION : 11,
    NUMERO_OFICINA : 12,
    ENTIDAD_EMISION : 13,
    RESERVA_MATEMATICA : 14,
    MONEDA_RESERVA_MAT : 15, 
    COBERTURA_BASICA : 16,
    SA_FALLECIMIENTO : 17,
    MONEDA_SA_FALLECIMIENTO : 18,
    COBERTURA_2 : 19,
    SA_COBERTURA_2 : 20,
    MONEDA_COBERTURA_2 : 21,
    COBERTURA_3 : 22,
    SA_COBERTURA_3 : 23,
    MONEDA_COBERTURA_3 : 24,
    COBERTURA_4: 25,
    SA_COBERTURA_4 : 26,
    MONEDA_COBERTURA_4 : 27,
    COBERTURA_5 : 28,
    SA_COBERTURA_5 : 29,
    MONEDA_COBERTURA_5 : 30,
    COBERTURA_6 : 31,
    SA_COBERTURA_6 : 32,
    MONEDA_COBERTURA_6 : 33,
    REASEGURO : 34,
    REASEGURADOR : 35,
    IMPORTE_REASEGURO : 36,
    GIRO : 37,
    PORCENTAJE_REASEGURO : 38,
    PRIMA : 39,
    MONEDA_PRIMA : 40,
    FORMA_PAGO_POLIZA : 41,
    FECHA_FIN_VIGENCIA : 42,
    FECHA_EMISION : 43,
    PRODUCTO_COMERCIAL : 44,
    TIPO_PRODUCTO_1 : 45,
    TIPO_PRODUCTO_2 : 46,
    
}
export const ASEGURADO = {
    NOMBRE : 47,
    APELLIDO_PAT : 48,
    APELLIDO_MAT : 49,
    CURP : 50,
    FECHA_NAC : 54,
    RFC : 55,
    EDAD_EMISION : 56,
}
export const CONTRATANTE = {
    NOMBRE : 51, 
    APELLIDO_PAT : 52,
    APELLIDO_MAT : 53,
}
export const AGENTE = {
    NOMBRE_COMPLETO : 57, 
    CLAVE : 58,
    DA : 59,
}

//Beneficiarios => índice en pm 
export const BENE_1 = {
    NOMBRE_COMPLETO : 60,
    FECHA_NAC : 61,
    PORCENTAJE : 62,
}
export const BENE_2 = {
    NOMBRE_COMPLETO : 63,
    FECHA_NAC : 64,
    PORCENTAJE : 65,
}
export const BENE_3 = {
    NOMBRE_COMPLETO : 66,
    FECHA_NAC : 67,
    PORCENTAJE : 68,
}
export const BENE_4 = {
    NOMBRE_COMPLETO : 69,
    FECHA_NAC : 70,
    PORCENTAJE : 71
}
export const BENE_5 = {
    NOMBRE_COMPLETO : 72,
    FECHA_NAC : 73,
    PORCENTAJE : 74,
}

export const DATA_PM = {
    ENTIDAD_LEGAL : 0, 
    TIPO_SEGURO: 1, 
    POLIZA: 2, 
    POLIZA_PADRE: 3, 
    ID_MONEDA: 4, 
    PAGADO_HASTA: 5, 
    ID_ESTATUS_POLIZA: 6, 
    DURACION_POLIZA: 7, 
    PATH_POLIZA: 8, 
    FONDO_INVERSION: 9, 
    PRESTAMO: 10, 
    ID_CANAL: 11, 
    ID_OFICINA: 12, 
    ID_ESTADO: 13, 
    RESERVA_MATEMATICA: 14,

    //COBERTURAS 
    ID_COBERTURA_1: 15, 
    COB_DESCRIPCION_1: 16, 
    SA_COBERTURA_1: 17,
    MONEDA_COBERTURA_1: 18, 
    
    ID_COBERTURA_2: 19, 
    COB_DESCRIPCION_2: 20, 
    SA_COBERTURA_2: 21,
    MONEDA_COBERTURA_2: 22, 

    ID_COBERTURA_3: 23, 
    COB_DESCRIPCION_3: 24, 
    SA_COBERTURA_3: 25,
    MONEDA_COBERTURA_3: 26, 

    ID_COBERTURA_4: 27, 
    COB_DESCRIPCION_4: 28, 
    SA_COBERTURA_4: 29,
    MONEDA_COBERTURA_4: 30, 

    ID_COBERTURA_5: 31, 
    COB_DESCRIPCION_5: 32, 
    SA_COBERTURA_5: 33,
    MONEDA_COBERTURA_5: 34, 

    ID_COBERTURA_6: 35, 
    COB_DESCRIPCION_6: 36, 
    SA_COBERTURA_6: 37,
    MONEDA_COBERTURA_6: 38, 

    ID_COBERTURA_7: 39, 
    COB_DESCRIPCION_7: 40, 
    SA_COBERTURA_7: 41,
    MONEDA_COBERTURA_7: 42, 

    ID_COBERTURA_8: 43, 
    COB_DESCRIPCION_8: 44, 
    SA_COBERTURA_8: 45,
    MONEDA_COBERTURA_8: 46, 

    ID_COBERTURA_9: 47, 
    COB_DESCRIPCION_9: 48, 
    SA_COBERTURA_9: 49,
    MONEDA_COBERTURA_9: 50, 

    ID_COBERTURA_10: 51, 
    COB_DESCRIPCION_10: 52, 
    SA_COBERTURA_10: 53,
    MONEDA_COBERTURA_10: 54,
    
    REASEGURO: 55, 
    
    ID_REASEGURADORA_1: 56, 
    PORCENTAJE_REASEGURO_1: 57,
    IMPORTE_REASEGURO_1: 58,
    ID_MONEDA_REASEGURO_1: 59,

    ID_REASEGURADORA_2: 60, 
    PORCENTAJE_REASEGURO_2: 61,
    IMPORTE_REASEGURO_2: 62,
    ID_MONEDA_REASEGURO_2: 63,

    ID_REASEGURADORA_3: 64, 
    PORCENTAJE_REASEGURO_3: 65,
    IMPORTE_REASEGURO_3: 66,
    ID_MONEDA_REASEGURO_3: 67,

    ID_REASEGURADORA_4: 68, 
    PORCENTAJE_REASEGURO_4: 69,
    IMPORTE_REASEGURO_4: 70,
    ID_MONEDA_REASEGURO_4: 71,

    ID_REASEGURADORA_5: 72, 
    PORCENTAJE_REASEGURO_5: 73,
    IMPORTE_REASEGURO_5: 74,
    ID_MONEDA_REASEGURO_5: 75,

    PRIMA: 76,
    ID_FORMA_PAGO: 77,
    FECHA_FIN_VEGENCIA : 78,
    FECHA_EMISION_POLIZA: 79,

    PRODUCTO_COMERCIAL: 80,
    ID_TIPO_PRODUCTO: 81,
    ID_PLATAFORMA: 82,

    NOMBRE_ASEGURADO : 83,
    APELLIDO_PATERNO_ASE : 84,
    APELLIDO_MATERNO_ASE : 85,
    GENERO_SEXO: 86 ,
    CURP_ASEGURADO : 87,
    FECHA_NAC_ASEGURADO : 91,
    RFC_ASEGURADO : 92,

    NOMBRE_CONTRATANTE: 88,
    APELLIDO_PATERNO_CONT: 89,
    APELLIDO_MATERNO_CONT : 90,

    EDAD_EMISION: 93, 
    NOMBRE_AGENTE: 94,
    CLAVE_AGENTE: 95,
    DA_AGENTE: 96,

    NOMBRE_COMPLETO_BENEFICIARIO_1: 97,
    FECHA_NAC_BENEFICIARIO_1: 98,
    PORCENTAJE_BENEFICIARIO_1: 99,

    NOMBRE_COMPLETO_BENEFICIARIO_2: 100,
    FECHA_NAC_BENEFICIARIO_2: 101,
    PORCENTAJE_BENEFICIARIO_2: 102,

    NOMBRE_COMPLETO_BENEFICIARIO_3: 103,
    FECHA_NAC_BENEFICIARIO_3: 104,
    PORCENTAJE_BENEFICIARIO_3: 105,

    NOMBRE_COMPLETO_BENEFICIARIO_4: 106,
    FECHA_NAC_BENEFICIARIO_4: 107,
    PORCENTAJE_BENEFICIARIO_4: 108,

    NOMBRE_COMPLETO_BENEFICIARIO_5: 109,
    FECHA_NAC_BENEFICIARIO_5: 110,
    PORCENTAJE_BENEFICIARIO_5: 111,

    NOMBRE_COMPLETO_BENEFICIARIO_6: 112,
    FECHA_NAC_BENEFICIARIO_6: 113,
    PORCENTAJE_BENEFICIARIO_6: 114,

    NOMBRE_COMPLETO_BENEFICIARIO_7: 115,
    FECHA_NAC_BENEFICIARIO_7: 116,
    PORCENTAJE_BENEFICIARIO_7: 117,

    NOMBRE_COMPLETO_BENEFICIARIO_8: 118,
    FECHA_NAC_BENEFICIARIO_8: 119,
    PORCENTAJE_BENEFICIARIO_8: 120,

    NOMBRE_COMPLETO_BENEFICIARIO_9: 121,
    FECHA_NAC_BENEFICIARIO_9: 122,
    PORCENTAJE_BENEFICIARIO_9: 123,

    NOMBRE_COMPLETO_BENEFICIARIO_10: 124,
    FECHA_NAC_BENEFICIARIO_10: 125,
    PORCENTAJE_BENEFICIARIO_10: 126,

}

