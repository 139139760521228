import * as React from "react";
import PropTypes from "prop-types";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { SelectDictamen } from "../../../Tools/Selectss/SelectDictamen";
import { SelectDictamen2 } from "../../../Tools/Selectss/SelectDictamen2";
import Grid from "@material-ui/core/Grid";
import IconEditar from "../../../Tools/Icons/IconEditar";
import TXTField from "../../../Tools/TextField/TextField";
//idioma
import { FormattedMessage } from "react-intl";
import { getDataToken, putDataToken } from "../../../../api/dataToken";
import {
  LimitarDigitos,
  LimitarTexto,
  LimitarTexto2,
  LimitarTextoYNumero,
} from "../../../Recepcion/Elements/Functions/Validaciones";
import getDate from "../../../Registro/Function/obtenerFecha";
import getHours from "../../../Registro/Function/obtenerHora";
import moment from "moment";
import { formatofecha } from "../../../../api/getUmbrales";
import DialogValidaciones from "../../../Registro/Elements/Dialog/DialogValidaciones";
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";

//Solo aparesera un alerta con un solo boton
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogCartaEditarAgente(props) {
  const rows = props.rows;
  const idAgente = props.idAgente;

  const [open, setOpen] = React.useState(false);
  const [agente, setAgente] = React.useState("");
  const [asentamiento, setAsentamiento] = React.useState([]);
  const [agencia, setagencia] = React.useState([]);
  const [idOficina, setidOficina] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
    editarAgente();
  };
  const handleClose = () => {
    setOpen(false);
    setvisible(false);
    setAsentamiento([]);
    setagencia([]);
    setagencias([]);
    setColonias([]);
    setDataSelectColonias([]);
  };

  const editarAgente = () => {
    rows.forEach((agente, index) => {
      //Validamos que el beneficiario sea diferente al seleccionado, para no sumar su porcentaje ya asignado
      if (agente?.PXP_idpersonaxpoliza == idAgente) {
        getDataToken(`personasxpoliza/get_id/${idAgente}/`).then((data) => {
          setAgente(data);
          setidOficina(
            data?.PER_idpersona?.OFXAGEN_idoficinaxagencia?.OF_idoficina
              ?.OF_idoficina
          );
          let nuevoElemento = {
            identificador: data?.PER_idpersona?.SEP_idsepomex?.id,
            texto: data?.PER_idpersona?.SEP_idsepomex?.SEP_asenta,
          };
          setAsentamiento([...asentamiento, nuevoElemento]);
          let nuevoAgente = {
            identificador:
              data?.PER_idpersona?.OFXAGEN_idoficinaxagencia?.AGEN_idagencia
                ?.AGEN_idagencia,
            texto:
              data?.PER_idpersona?.OFXAGEN_idoficinaxagencia?.AGEN_idagencia
                ?.AGEN_direccion,
          };
          setagencia([...agencia, nuevoAgente]);
        });
      }
    });
  };
  console.log(agente);
  console.log(agencia);
  console.log(idOficina);

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");

  console.log(moment(fechaEmpAn).format(formatofecha));
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();

  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;

  //Obtener usuario.
  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");
  const getUsers = async () => {
    try {
      var result = await getDataToken(`login/get_correo/${email}/`);
      setnombreAnalista(
        result.SNYTLOG_username +
          " " +
          result.SNYTLOG_surnamedad +
          " " +
          result.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getUsers();
  }, []);

  //////////////////////////////
  //   Funciones para cod postal
  ////////////////////////////

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (/[^0-9]/.test(keyValue)) {
      event.preventDefault();
    }
  };

  //Estados utilizados para sepomex
  const [SEP_Id, setSEP_Id] = React.useState("");
  const [SEP_municipio, setSEP_municipio] = React.useState("");
  const [SEP_colonia, setSEP_colonia] = React.useState("");
  const [codpostal, setCodPostal] = React.useState("");
  const [colonias, setColonias] = React.useState([]);
  const [dataSelectColonias, setDataSelectColonias] = React.useState([]);

  //Traer los datos de CP para los dos formatos de cp

  const getCodP = async (cp) => {
    try {
      var result = await getDataToken(`sepomex/get_listado_cp/${cp}`);
      setColonias(result);
      if (result <= 0) {
        setDataSelectColonias([]);
        setSEP_municipio("");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  //Funcionalidad del formulario CP

  const handleSubmit = () => {
    if (codpostal === "") {
      document.getElementById("txtCP2").focus();
    } else {
      getCodP(codpostal);
    }
  };

  const getInfoasenta = (e) => {
    const dataColonia = parseInt(e.target.value);
    colonias.forEach((element) => {
      if (dataColonia === element.id) {
        setSEP_Id(element.id);
        setSEP_municipio(element.SEP_municipio);
        setSEP_colonia(element.SEP_asenta);
      }
    });
  };

  //Esta función useEffect se activará cuando haya cambios en el estado 'colonias', activando a su ves otro estado
  React.useEffect(() => {
    if (colonias.length > 0) {
      let dataColonias = colonias;
      dataColonias.map(
        (cp) => (
          (cp.texto = cp.SEP_asenta), // cp.texto es una propiedad que no existe dentro del JSON, por tanto, se crea
          (cp.identificador = cp.id)
        )
      ); //Se actualiza el valor del dataSelectColonias
      setDataSelectColonias(dataColonias);
    }
  }, [colonias]);

  console.log("Id sepomex " + SEP_Id);
  console.log(dataSelectColonias);

  ///Oficinas select///

  const [oficinas, setoficinas] = React.useState([]);
  const [idagencia, setidagencia] = React.useState("");
  const [agencias, setagencias] = React.useState([]);
  const [idoficinaxagencia, setidoficinaxagencia] = React.useState("");

  // Traemos los datos de clasificación para llenar el combo
  const getOficinas = async (oficinaId) => {
    try {
      var result = await getDataToken(`oficinasxagencias/get_listado/`);

      // Utilizar un conjunto para evitar duplicados
      const idOficinasSet = new Set();

      // Filtrar la oficina deseada y el resto
      const filteredOficinas = result.reduce((filteredArray, element) => {
        const idOficina = element?.OF_idoficina?.OF_idoficina;

        if (!idOficinasSet.has(idOficina)) {
          idOficinasSet.add(idOficina);

          if (idOficina === oficinaId) {
            // Colocar la oficina deseada en la posición 0
            filteredArray.unshift({
              ...element,
              identificador: 0,
              texto: element?.OF_idoficina?.OF_oficina,
            });
          } else {
            filteredArray.push({
              ...element,
              identificador: idOficina,
              texto: element?.OF_idoficina?.OF_oficina,
            });
          }
        }

        return filteredArray;
      }, []);

      setoficinas(filteredOficinas);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getOficinas(
      agente?.PER_idpersona?.OFXAGEN_idoficinaxagencia?.OF_idoficina
        ?.OF_idoficina
    );
  }, [agente]);

  const handleChangeOficinas = async (e) => {
    const dataOficinas = e.target.value;
    const nuevo = dataOficinas === 0 ? idOficina : dataOficinas;

    try {
      // Realizar la llamada asíncrona
      const result = await getDataToken(`oficinasxagencias/get_listado/`);
      const OficinasFiltradas = result.filter(
        (oficina) => oficina.OF_idoficina?.OF_idoficina === nuevo
      );
      console.log(OficinasFiltradas);

      const array = OficinasFiltradas.map((element) => {
        return {
          ...element,
          identificador: element?.AGEN_idagencia?.AGEN_idagencia,
          texto: element?.AGEN_idagencia?.AGEN_direccion,
        };
      });

      // Actualizar el estado del segundo select
      setagencias(array);
    } catch (error) {
      console.error("Error al obtener datos de agencias:", error);
    }
  };

  console.log(oficinas);
  console.log(agencias);

  const handleChangeAgencias = (e) => {
    const dataAgencias = parseInt(e.target.value);
    const selectedAgencia = agencias.find(
      (element) => dataAgencias === element?.AGEN_idagencia?.AGEN_idagencia
    );

    if (selectedAgencia) {
      setidagencia(selectedAgencia?.AGEN_idagencia?.AGEN_idagencia);
      setidoficinaxagencia(selectedAgencia?.OFXAGEN_idoficinaxagencia);
    }
  };

  console.log(idoficinaxagencia);

  ///////////////////////////////////////////////
  const [values, setValues] = React.useState({
    PER_nombre: "",
    PER_apellidopaterno: "",
    PER_apellidomaterno: "",
    PER_clave: "",
    PER_RFC: "",
    PER_CURP: "",
    PER_celular: "",
    PER_correo: "",
    PER_calle: "",
    PER_numeroexterior: "",
    PER_numerointerior: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  React.useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      PER_nombre: agente?.PER_idpersona?.PER_nombre,
      PER_apellidopaterno: agente?.PER_idpersona?.PER_apellidopaterno,
      PER_apellidomaterno: agente?.PER_idpersona?.PER_apellidomaterno,
      PER_clave: agente?.PER_idpersona?.PER_clave,
      PER_RFC: agente?.PER_idpersona?.PER_RFC,
      PER_CURP: agente?.PER_idpersona?.PER_CURP,
      PER_celular: agente?.PER_idpersona?.PER_celular,
      PER_correo: agente?.PER_idpersona?.PER_correo,
      PER_calle: agente?.PER_idpersona?.PER_calle,
      PER_numeroexterior: agente?.PER_idpersona?.PER_numeroexterior,
      PER_numerointerior: agente?.PER_idpersona?.PER_numerointerior,
    }));
    setCodPostal(agente?.PER_idpersona?.SEP_idsepomex?.SEP_codpostal);
    setSEP_municipio(agente?.PER_idpersona?.SEP_idsepomex?.SEP_municipio);
    setSEP_Id(agente?.PER_idpersona?.SEP_idsepomex?.id);
  }, [agente]);

  const validateUpdate = () => {
    setagencia([]);
    setagencias([]);
    setAsentamiento([]);
    setColonias([]);
    setDataSelectColonias([]);
    let actualizar = {
      PER_nombre: values.PER_nombre,
      PER_apellidopaterno: values.PER_apellidopaterno,
      PER_apellidomaterno: values.PER_apellidomaterno,
      PER_clave: values.PER_clave,
      PER_RFC: values.PER_RFC,
      PER_CURP: values.PER_CURP,
      PER_celular: values.PER_celular,
      PER_correo: values.PER_correo,
      PER_calle: values.PER_calle,
      PER_numeroexterior: values.PER_numeroexterior,
      PER_numerointerior: values.PER_numerointerior,
      SEP_idsepomex: SEP_Id,
      OFXAGEN_idoficinaxagencia: idoficinaxagencia,
      PER_actualizofecha: fechaActSIN,
      PER_actualizousuario: nombreAnalista,
    };
    putDataToken(
      `personas/get_id/${agente?.PER_idpersona?.PER_idpersona}/`,
      actualizar
    ).then((data) => {
      props.ejecutarConsulta();
      console.log(
        `Registro ${agente?.PER_idpersona?.PER_idpersona} actualizado`
      );
    });
  };

  const [visible, setvisible] = React.useState(false);

  const onclickVisible = () => {
    setvisible(true);
    validateUpdate();
  };

  console.log(agencias);

  return (
    <div>
      {visible && (
        <DialogValidaciones
          title="Actualización correcta"
          secondButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description="Su registro se actualizo correctamente."
          onClick={handleClose}
        />
      )}
      {/*BOTON QUE HACE EL LLAMADO AL DIALOGO*/}
      {/*Este boton aparecera el js en donde se necesite de la alerta*/}
      <IconEditar
        onClick={handleClickOpen}
        tooltipTitle={
          <FormattedMessage id="globEditar" defaultMessage="Editar" />
        }
      />

      {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
      <BootstrapDialog
        // onClose={handleClose}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="dialog"
        open={open}
      >
        {/*CUERPO DEL DIALOGO*/}
        {/*TITILO*/}
        <BootstrapDialogTitle
          id="dialog"
          onClose={handleClose}
          style={{ color: "primary" }}
        >
          <h1-dialog-title>Editar Agente</h1-dialog-title>
        </BootstrapDialogTitle>
        {/*CUERPO DEL DIALOGO*/}
        {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
        <form>
          <DialogContent dividers style={{ minWidth: 500 }}>
            {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
          se necesita colocar entre un typogreaphy las veces que des salto de linea 
          o con un br*/}

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item xs={12}>
                <TXTField
                  value={values?.PER_nombre}
                  label={"Nombre"}
                  onChange={handleChange("PER_nombre")}
                  InputLabelProps={{ shrink: true }}
                  onInput={(e) => LimitarTexto(e.target, 50)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  label={"Apellido paterno"}
                  onChange={handleChange("PER_apellidopaterno")}
                  InputLabelProps={{ shrink: true }}
                  value={values?.PER_apellidopaterno}
                  onInput={(e) => LimitarTexto(e.target, 50)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  label={"Apellido materno"}
                  onChange={handleChange("PER_apellidomaterno")}
                  InputLabelProps={{ shrink: true }}
                  value={values?.PER_apellidomaterno}
                  onInput={(e) => LimitarTexto(e.target, 50)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  label={"Clave de agente"}
                  onChange={handleChange("PER_clave")}
                  InputLabelProps={{ shrink: true }}
                  value={values?.PER_clave}
                  onInput={(e) => LimitarTextoYNumero(e.target, 15)}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectDictamen2
                  sx={{
                    minWidth: 550,
                    maxWidth: 550,
                    height: 50,
                    background: "white",
                  }}
                  label={
                    <FormattedMessage
                      id="globColoni"
                      defaultMessage={`Oficina`}
                    />
                  }
                  array={oficinas}
                  onChange={handleChangeOficinas}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectDictamen
                  sx={{
                    minWidth: 550,
                    maxWidth: 550,
                    height: 50,
                    background: "white",
                  }}
                  label={
                    <FormattedMessage
                      id="globColoni"
                      defaultMessage={`Agencia`}
                    />
                  }
                  array={agencias.length > 0 ? agencias : agencia}
                  onChange={handleChangeAgencias}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  label={"RFC"}
                  onChange={handleChange("PER_RFC")}
                  InputLabelProps={{ shrink: true }}
                  value={values?.PER_RFC}
                  onInput={(e) => LimitarTextoYNumero(e.target, 13)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  label={"CURP"}
                  onChange={handleChange("PER_CURP")}
                  InputLabelProps={{ shrink: true }}
                  value={values?.PER_CURP}
                  onInput={(e) => LimitarTextoYNumero(e.target, 18)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  label={"Teléfono celular"}
                  onChange={handleChange("PER_celular")}
                  InputLabelProps={{ shrink: true }}
                  value={values?.PER_celular}
                  onInput={(e) => LimitarDigitos(e.target, 10)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  label={"Correo Electrónico"}
                  onChange={handleChange("PER_correo")}
                  InputLabelProps={{ shrink: true }}
                  value={values?.PER_correo}
                  onInput={(e) => LimitarTexto2(e.target, 100)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  value={values?.PER_calle}
                  label={"Calle"}
                  onChange={handleChange("PER_calle")}
                  InputLabelProps={{ shrink: true }}
                  onInput={(e) => LimitarTextoYNumero(e.target, 100)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  value={values?.PER_numeroexterior}
                  label={"No. Ext."}
                  onChange={handleChange("PER_numeroexterior")}
                  InputLabelProps={{ shrink: true }}
                  onInput={(e) => LimitarDigitos(e.target, 5)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  value={values?.PER_numerointerior}
                  label={"No. Int."}
                  onChange={handleChange("PER_numerointerior")}
                  InputLabelProps={{ shrink: true }}
                  onInput={(e) => LimitarDigitos(e.target, 5)}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  label={
                    <FormattedMessage
                      id="globCodPos"
                      defaultMessage="Código postal"
                    />
                  }
                  value={codpostal}
                  name="txtCP2"
                  id="txtCP2"
                  onChange={(event) => setCodPostal(event.target.value)}
                  onKeyPress={handleKeyPress}
                  onPaste={(e) => e.preventDefault()}
                  onInput={(e) => LimitarDigitos(e.target, 6)}
                  showSearchIcon={true}
                  iconOnClick={handleSubmit}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectDictamen
                  sx={{
                    minWidth: 550,
                    maxWidth: 550,
                    height: 50,
                    background: "white",
                  }}
                  label={
                    <FormattedMessage
                      id="globColoni"
                      defaultMessage={`Colonia`}
                    />
                  }
                  array={
                    dataSelectColonias.length > 0
                      ? dataSelectColonias
                      : asentamiento
                  }
                  onChange={getInfoasenta}
                />
              </Grid>
              <Grid item xs={12}>
                <TXTField
                  label={
                    <FormattedMessage
                      id="globmunicipioAlcadia"
                      defaultMessage="municipio o Alcaldia"
                    />
                  }
                  value={SEP_municipio}
                  onChange={handleChange("SEP_municipio")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          {/*es la tercer sección que engloba los botones */}
          <DialogActions>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
              marginTop={1}
            >
              <Grid item>
                <ButtonVino
                  variant="contained"
                  label="Actualizar"
                  onClick={onclickVisible}
                />
              </Grid>
              <Grid item>
                <ButtonVino
                  variant="contained"
                  label="Cancelar"
                  onClick={handleClose}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}
