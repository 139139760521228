import * as React from "react";
import { useState, useEffect } from "react";


import Recorrido from "../../Tools/BreadCrumbs/Recorrido";
import Dateformat from "./Elements/dateformat";
import { Planes } from "./Elements/calendar";
import ControlledAccordions  from "./Elements/dayssmoduls";
import Dayservice from "./Elements/daysservice";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";



function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function Negociogeneral() {
 

  //! =======> Variables don't touch
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //! =======> Variables don't touch



  //TODO: Return
  return (
    <div className="div-parent">
     <div className="div-breadcrumbs">  
        <Recorrido
          name1="Inicio"
          enlace="/confadmin"
          name2="Negocio "
        />
      <div className="div-container">
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
          >
            <Tab label="Formato Fecha*" {...a11yProps(0)} />
            <Tab label="|" disabled />
            <Tab label="Calendario de Dias" {...a11yProps(1)} />
            <Tab label="|" disabled />
            <Tab label="Dias de Servicio" {...a11yProps(2)} />
            <Tab label="|" disabled />
            <Tab label="Dias por Módulo " {...a11yProps(3)} />
    
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div>
            <Dateformat/>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div>
          <Planes /> 
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div>
             <Dayservice/>
          </div>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <div>
             <ControlledAccordions /> 
          </div>
        </TabPanel>
      </Box>
      </div>
      </div>
    </div>
  );
}
