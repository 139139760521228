import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import SearchTXTField from "../../../../Tools/TextField/SearchTextField";
import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import { FormattedMessage } from 'react-intl';
import { secundario } from '../../../../../api/getUmbrales';
import { BASE_API } from "../../../../../utils/constans";
import { getToken } from "../../../../../api/token";
import { TOKENLOGIN } from "../../../../../utils/constans";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: secundario,
      color: theme.palette.common.white,
      fontSize: 18,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
    },
  }));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

export default function BancosTabla() {
    const [data, setData] = useState([]); // Estado para almacenar los datos de los bancos
    const [page, setPage] = useState(0); // Estado para el número de página actual
    const [rowsPerPage, setRowsPerPage] = useState(5); // Estado para el número de filas por página
    const token = getToken(); // Función para obtener el token de autenticación
    const [searchTerm, setSearchTerm] = useState("");
    const [editingId, setEditingId] = React.useState(null);
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogMsj, setOpenDialogMsj] = useState(false);
    const [editBankInfo, setEditBankInfo] = useState(null); 
    const [openNew, setOpenNew] = React.useState(false);
    const authorizationHeader = `Bearer ${TOKENLOGIN}`;
    const [openEliminar, setOpenEliminar] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openSnackbarEditar, setOpenSnackbarEditar] = useState(false);
    //const [reloadTable, setReloadTable] = useState(false);

    const [codigo, setCodigo] = useState('');
    const [siglas, setSiglas] = useState('');
    const [nombreBanco, setNombreBanco] = useState('');
   

    const handleClickOpenNew = () => {
        setOpenNew(true);
    };

    const handleCloseNew = () => {
        setOpenNew(false);
        //setReloadTable(true);
    };

    //Para abrir el dialogo que elimina
    const handleEliminarClick = (id) => {
        setOpenEliminar(true);
        setSelectedId(id);
    };

    //Para cerrar el dialogo que edita
    const handleCloseEdit = () => {
        setOpenDialog(false);
        
    };

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${token}`);

    // Método GET
    const fetchGet = () => {
        fetch(`${BASE_API}bancos/get_listado/`, {
            method: "GET",
            headers: headers,
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setData(data);
                console.log(data);
            });
    };

    useEffect(() => {
        fetchGet();
    }, []);

    // Cambiar página
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Cambiar filas por página
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredAndPaginatedData = data
    .filter((row) =>
        Object.values(row).some(
        (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    )
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

   
    //Para editar un banco
    const handleEdit = (id) => {
        console.log('Editar banco con ID:', id);
        fetch(`${BASE_API}bancos/get_id/${id}/`, {
          method: "GET",
          headers: headers,
        })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log('Datos del banco:', data);
          // Asegúrate de que los campos de texto sean editables
          setEditBankInfo({ ...data, editable: true });
          setOpenDialog(true); // Abrir el diálogo de edición
        });
      };
      

      const handleGuardar = () => {
        console.log('Datos del banco a guardar:', editBankInfo.BNC_idbanco);
        fetch(`${BASE_API}bancos/get_id/${editBankInfo.BNC_idbanco}/`, {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(editBankInfo),
        })
        .then((response) => {
            setOpenDialog(false); 
            setOpenSnackbarEditar(true);
        });
      };

      const handleCloseSnackbarEditar = () => {
        setOpenSnackbarEditar(false); // Cierra el Snackbar
      };
      
    
    
    
    const handleCloseDialog = () => {
        setOpen(false);
        setEditingId(null);
      };

    const handleCodigoChange = (event) => {
        setCodigo(event.target.value);
    };
    const handleSiglasChange = (event) => {
        setSiglas(event.target.value);
    };
    const handleNombreChange = (event) => {
        setNombreBanco(event.target.value);
    };

  const handleAddBank = () => {
    const currentDate = new Date().toISOString();
    const body = {
        BNC_codigo: codigo,
        BNC_nombrecorto: siglas,
        BNC_nombrelargo: nombreBanco,
        BNC_activo: true,
        BNC_actualizofecha: currentDate, 
        BNC_actualizousuario: "SINAY"
        
      };

      console.log('Datos a enviar:', body);

      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", authorizationHeader);
  
      fetch(`${BASE_API}bancos/get_listado/`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
      })
      .then(response => {
        console.log('Respuesta:', response);
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          return response.json();
      })
      .then(data => {
          console.log('New bank added:', data);
          setOpenDialogMsj(true);
          //setReloadTable(true);
      })
      .catch(error => {
          console.error('Error adding new bank:', error);
          // Aquí puedes manejar el error, por ejemplo, mostrando un mensaje al usuario
      });
  };

  const handleEliminarConfirmado = (id) => {
    let response = fetch(`${BASE_API}bancos/get_id/` + selectedId + "/", {
      method: "PUT",
      headers: headers,
      body: JSON.stringify({ BNC_activo: false }),
    });
    response.then((response) => {
      console.log(response);
      setOpenEliminar(false); 
      setOpenSnackbar(true);
    });
  
    console.log(selectedId);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false); // Cierra el Snackbar
  };
  
      
    return (
        <div>
            <Grid container alignItems="center" spacing={5}>
                <Grid item xs={3}>
                    <SearchTXTField
                        label={<FormattedMessage id="globBusqued" defaultMessage="Búsqueda" />}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button variant="contained" onClick={handleClickOpenNew}>Agregar nuevo banco</Button>
                </Grid>
                <Dialog
                    open={openNew}
                    onClose={handleCloseNew}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">Agregar Banco</DialogTitle>
                    <DialogContent>
                        <Box  component="form"
                            sx={{
                            '& .MuiTextField-root': { m: 1, margin: '0 auto', mt: 2 },
                            }}
                            noValidate
                            autoComplete="off">
                            <Grid container spacing={1}>
                                
                                <Grid item xs={6}>
                                <TextField
                                    id="idBanco"
                                    label="codigo"
                                    variant="outlined"
                                    value={codigo}
                                    onChange={handleCodigoChange}
                                    fullWidth
                                />
                                </Grid>
                                <Grid item xs={6}>
                                <TextField id="siglasBanco" label="siglas" variant="outlined"  value={siglas} onChange={handleSiglasChange}fullWidth />
                                </Grid>
                                
                            </Grid>
                            <Grid container spacing={2}>
                                
                                <Grid item xs={12}>
                                <TextField id="nombreBanco" label="nombre del banco" variant="outlined" value={nombreBanco} onChange={handleNombreChange}  fullWidth />
                                </Grid>
                                
                            </Grid>
                        </Box>
                            
                    </DialogContent>
                    <DialogActions>
                            <Button onClick={handleCloseNew}>Cancelar</Button>
                            <Button onClick={handleAddBank} autoFocus>
                                    Aceptar
                            </Button>
                            <Dialog open={openDialogMsj} onClose={handleCloseDialog}>
                                <DialogTitle>Banco Agregado</DialogTitle>
                                <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    El banco se agrego con éxito
                                </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseNew}>Cerrar</Button>
                                </DialogActions>
                            </Dialog>
                    </DialogActions>
                </Dialog>  
            </Grid>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell align="right">Código</StyledTableCell>
                            <StyledTableCell align="right">Sigla</StyledTableCell>
                            <StyledTableCell align="right">Nombre del banco</StyledTableCell>
                            <StyledTableCell align="right">Estatus</StyledTableCell>
                            <StyledTableCell align="right">Asignado a</StyledTableCell>
                            <StyledTableCell align="right">Fecha de ultima actualizacion</StyledTableCell>
                            <StyledTableCell align="right">Editar</StyledTableCell>
                            <StyledTableCell align="right">Eliminar</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredAndPaginatedData.map((row) => (
                            <StyledTableRow key={row.BNC_idbanco}>
                                <StyledTableCell component="th" scope="row">
                                    {row.BNC_idbanco}
                                </StyledTableCell>
                                <StyledTableCell align="right">{row.BNC_codigo}</StyledTableCell>
                                <StyledTableCell align="right">{row.BNC_nombrecorto}</StyledTableCell>
                                <StyledTableCell align="right">{row.BNC_nombrelargo}</StyledTableCell>
                                <StyledTableCell align="right">{row.BNC_activo ? 'Activo' : 'Inactivo'}</StyledTableCell>
                                <StyledTableCell align="right">{row.BNC_actualizousuario}</StyledTableCell>
                                <StyledTableCell align="right">
                                    {new Date(row.BNC_actualizofecha).toLocaleDateString('es-MX', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                    })}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <IconButton onClick={() => handleEdit(row.BNC_idbanco)}>
                                    <EditIcon />
                                    </IconButton>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <IconButton onClick={() => handleEliminarClick(row.BNC_idbanco)} sx={{ color: 'red' }}>
                                    <DeleteIcon />
                                    </IconButton>
                                </StyledTableCell>
                                <Dialog open={openEliminar} onClose={() => setOpenEliminar(false)}>
                                    <DialogTitle>¿Estás seguro que deseas eliminar este banco?</DialogTitle>
                                    <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Una vez eliminado no es posible recuperarlo
                                    </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                    <Button onClick={() => setOpenEliminar(false)}>Cancelar</Button>
                                    <Button onClick={() => handleEliminarConfirmado(row.BNC_idbanco)} autoFocus>Eliminar</Button>
                                    </DialogActions>
                                </Dialog>
                                <Snackbar
                                    open={openSnackbar}
                                    autoHideDuration={6000}
                                    onClose={handleCloseSnackbar}
                                    message="Banco eliminado con éxito"
                                    action={
                                    <Button color="inherit" onClick={handleCloseSnackbar}>
                                        Cerrar
                                    </Button>
                                    }
                                />
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </TableContainer>
                <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">Editar Banco</DialogTitle>
                <DialogContent>
                    <Box  component="form"
                        sx={{
                        '& .MuiTextField-root': { m: 1, margin: '0 auto', mt: 2 },
                        }}
                        noValidate
                        autoComplete="off">
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                            <TextField
                                label="Codigo"
                                value={editBankInfo?.BNC_codigo || ''}
                                onChange={(e) => setEditBankInfo({ ...editBankInfo, BNC_codigo: e.target.value })}
                                fullWidth
                                disabled={!editBankInfo?.editable} // Asegura que el campo sea editable
                            />
                            </Grid>
                            <Grid item xs={6}>
                            <TextField
                                id="outlined-basic2"
                                label="siglas"
                                variant="outlined"
                                value={editBankInfo ? editBankInfo.BNC_nombrecorto : ''}
                                fullWidth
                                onChange={(e) => setEditBankInfo({ ...editBankInfo, BNC_nombrecorto: e.target.value })}
                                disabled={!editBankInfo?.editable} // Asegura que el campo sea editable
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                            <TextField
                                id="outlined-basic1"
                                label="nombre del banco"
                                variant="outlined"
                                value={editBankInfo ? editBankInfo.BNC_nombrelargo : ''}
                                fullWidth
                                onChange={(e) => setEditBankInfo({ ...editBankInfo, BNC_nombrelargo: e.target.value })}
                                disabled={!editBankInfo?.editable} // Asegura que el campo sea editable
                                />
                            </Grid> 
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseEdit}>Cancelar</Button>
                <Button onClick={handleGuardar}>Guardar</Button>
                </DialogActions>
                </Dialog>
                <Snackbar
                    open={openSnackbarEditar}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbarEditar}
                    message="Banco actualizado con éxito"
                    action={
                    <Button color="inherit" onClick={handleCloseSnackbarEditar}>
                    Cerrar
                    </Button>
                    }
                    />
        </div>
    );
}



