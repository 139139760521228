import { InfoMedic } from "../../../components/Medic/InfoMedic";
import React from "react";
import MyComponent from "../../../components/Tools/ButtonLoading/ButtonLoading";

export function Info() {
  return (
    <div>
      <MyComponent componentToRender={InfoMedic} />
    </div>
  );
}
