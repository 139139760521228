import { Documentation } from "../../pages/Client/ModDocumentations/Documentation";
import { ClientLayaout } from "../../layouts/Client/ClientLayaout";

const routesClient = [
  {
    path: "/documentacion",
    layout: ClientLayaout,
    component: Documentation,
    exact: true,
  },
];

export default routesClient;
