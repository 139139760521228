/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import IconButton from "@material-ui/core/IconButton";
import "./Investigador.css";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import DialogAsignar from "../Elements/DialogAsignar";
import TablePagination from "@mui/material/TablePagination";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import SearchTXTField from "../../Tools/TextField/SearchTextField";
import StyledTableCell from "../../Tools/Table/StyledTableCell";
//idioma
import { FormattedMessage } from "react-intl";

//api
import { getDataToken } from "../../../api/dataToken";
//umbral
import {
  umbralCancelado,
  umbralEnProceso,
  umbralATiempo,
  diasoficiales,
  tipo_estandarizados,
  tipo_especial,
  tipo_complejo,
  tipo_cug,
  dias_investigador,
  dias_investigador_estandarizado,
  dias_investigador_urgente,
  dias_investigador_especial,
  dias_investigador_compleja,
  formatofecha,
} from "../../../api/getUmbrales";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";

export default function SupervisorTable() {
  const [datosApi, setDataApi] = React.useState([]);
  const [idData, setID] = useState([]);

  //GET PARA SABER CON EL CORREO SU ID Y CON ESO PINTAR LA TABLA

  const getPermisos = async () => {
    //Resultados de la api investigacion
    var resultID = await getDataToken(
      "login/get_correo/" + localStorage.getItem("SNYTLOG_email") + "/"
    );
    setID(resultID);
  };
  useEffect(() => {
    getPermisos();
  }, []);

  const getDataInvestigacion = async () => {
    //Resultados de la api investigacion
    var resultInves = await getDataToken("investigacion/get_listado2/");
    console.log("Resultados de la Api investigación", resultInves);
    setDataApi(resultInves);
  };

  useEffect(() => {
    getDataInvestigacion();
  }, [idData.id]);

  //Armar el objeto para la tabla investigador
  //Ocupa No.Folio,no. siniestro, fecha de llegada (fecha de envio), casos, estatus  nombre del investigador
  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  /* CONSTANTES DE ESTADOS*/
  const [order, setOrder] = useState("ASC"); // esta constante tiene como propósico guardar el estado del orden de la tabla
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla

  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  /* FUNCIÓN PARA ORDENAR LA TABLA ASC/DSC (Ascendent/ Descendant) */
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...datosApi].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setOrder("DSC");
      setDataApi(sorted);
    } else if (order === "DSC") {
      const sorted = [...datosApi].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? -1 : 1
      );
      setOrder("ASC");
      setDataApi(sorted);
    }
  };

  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  //dias se mapean y sy meten ala libreria especial
  const mapafecha = diasoficiales.map((elemento) => elemento);
  var arreglodias = [];

  var moment = require("moment-business-days");
  moment.updateLocale("us", {
    holidays: mapafecha,
    holidayFormat: "YYYY-MM-DD",
  });

  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  //CCONSTANTES DE ESTADOS PARA EL CONTADOR DE LAS CARPETAS DEL ENCABEZADO DE LA TABLA
  const [contador, setContador] = useState({
    urgente: 0,
    atiempo: 0,
    porterminar: 0,
  });

  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  //METODO DONDE SE HACE EL CONTEO DE LOS FOLIO SEGUN SEA EL SEMAFORO
  useEffect(() => {
    if (datosApi.length > 0) {
      let urgente = 0;
      let porterminar = 0;
      let atiempo = 0;
      for (let index = 0; index < datosApi.length; index++) {
        const row = datosApi[index];
        //console.log("Datos obtenidos de las APIS:", datosApi)
        //se saca la diferentencia entre los dias de asueto con la fecha actual
        var tiempos = moment().businessDiff(
          moment(row.fch_llegada, "YYYY-MM-DD")
        );

        if (
          tiempos > dias_investigador_compleja ||
          tiempos > dias_investigador_especial
        ) {
          urgente = urgente + 1;
        } else if (
          tiempos === dias_investigador_compleja ||
          tiempos === dias_investigador_especial
        ) {
          porterminar = porterminar + 1;
        } else if (
          tiempos < dias_investigador_compleja ||
          tiempos < dias_investigador_especial
        ) {
          atiempo = atiempo + 1;
        }
      }
      //prevState es donde guardamos los valores anteriores de nuestro contador u lo modificamos a lo nuevo
      setContador((prevState) => ({
        ...prevState,
        urgente: urgente,
        porterminar: porterminar,
        atiempo: atiempo,
      }));
    }
  }, [datosApi]);

  const funcionActualizar = () => {
    getDataInvestigacion();
  };

  return (
    <div>
      {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
      {/* SECCION DE CARPETAS IMPLEMENRANDO EL CONTADOR DE CASOS */}
      <Grid container>
        <Grid item xs={3}>
          <section className="sec-carpetas">
            <FolderOutlinedIcon style={{ color: "#F42B00" }} />
            <folder-titles>
              <FormattedMessage
                id="investCaUr"
                defaultMessage="Casos urgentes"
              />
            </folder-titles>
            <folder-titles-cases id="pCasosUrgentes">
              {contador.urgente}
            </folder-titles-cases>
          </section>
        </Grid>
        <Grid item xs={3}>
          <section className="sec-carpetas">
            <FolderOutlinedIcon style={{ color: "#FFE400" }} />
            <folder-titles>
              <FormattedMessage
                id="investCaTe"
                defaultMessage="Casos por terminar"
              />
            </folder-titles>
            <folder-titles-cases id="pCasosPorTerminar">
              {contador.porterminar}
            </folder-titles-cases>
          </section>
        </Grid>
        <Grid item xs={3}>
          <section className="sec-carpetas">
            <FolderOutlinedIcon style={{ color: "#58E308" }} />
            <folder-titles>
              <FormattedMessage
                id="investCaTi"
                defaultMessage="Casos a tiempo"
              />
            </folder-titles>
            <folder-titles-cases id="pCasosATiempo">
              {contador.atiempo}
            </folder-titles-cases>
          </section>
        </Grid>
      </Grid>
      {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
      {/* BARRA DE BUSQUEDA  */}
      <Grid
        style={{ paddingBottom: "20px" }}
        container
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={4}>
          <h4-search>
            <FormattedMessage id="globBuscar" defaultMessage="Buscar" />:
          </h4-search>
          <SearchTXTField
            showSearchIcon={true}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
      {/* TABLA INVESTIGADOR  */}
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }} style={{ position: "sticky" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* COLUMNA TABLA - NO FOLIO  */}
                <StyledTableCell>
                  <Grid container alignItems="center">
                    <Grid item xs={9}>
                      <FormattedMessage
                        id="globNoFoli"
                        defaultMessage="No. de folio."
                      />{" "}
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton
                        className="icon-button"
                        style={{ color: "white" }}
                        onClick={() => sorting("numFolio")}
                      >
                        <UnfoldMoreIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </StyledTableCell>

                {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* COLUMNA TABLA - NO SINIESTRO  */}
                <StyledTableCell>
                  <Grid container alignItems="center">
                    <Grid item xs={9}>
                      <FormattedMessage
                        id="globNSin"
                        defaultMessage="No. de siniestro."
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton
                        className="icon-button"
                        style={{ color: "white" }}
                        onClick={() => sorting("numSiniestro")}
                      >
                        <UnfoldMoreIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </StyledTableCell>

                {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* COLUMNA TABLA - CASO DEL SINIESTRO  */}
                <StyledTableCell>
                  <FormattedMessage
                    id="investClasf"
                    defaultMessage="Tipo siniestro."
                  />
                </StyledTableCell>

                {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* COLUMNA TABLA - CLASIFICACION DEL SINIESTRO  */}
                <StyledTableCell>
                  <FormattedMessage
                    id="investClasf"
                    defaultMessage="Clasificación."
                  />
                </StyledTableCell>

                {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* COLUMNA TABLA - FECHA DE LLEGADA  */}
                <StyledTableCell>
                  <Grid container alignItems="center">
                    <Grid item xs={9}>
                      <FormattedMessage
                        id="investFeLl"
                        defaultMessage="Fecha de llegada."
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton
                        className="icon-button"
                        style={{ color: "white" }}
                        onClick={() => sorting("fechaLlegada")}
                      >
                        <UnfoldMoreIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </StyledTableCell>

                {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* COLUMNA TABLA - ESTATUS  */}
                <StyledTableCell>
                  <FormattedMessage
                    id="investEstatus"
                    defaultMessage="Estatus."
                  />
                </StyledTableCell>

                {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* COLUMNA TABLA - NOMBRE DEL INVESTIGADOR  */}
                <StyledTableCell>
                  <FormattedMessage
                    id="globNomInv"
                    defaultMessage="Nombre del investigador."
                  />
                </StyledTableCell>

                {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                {/* COLUMNA TABLA - ASIGNACION DE INVESTIGADOR  */}
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="iinvestAsig"
                    defaultMessage="Asignar."
                  />
                  {/* Retorna el numero de folio de ese id que vallamos a asignar  */}
                </StyledTableCell>
              </TableRow>
            </TableHead>

            {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
            {/* FILTRADO DE BUSQUEDA  */}
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {datosApi
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    val.num_folio
                      .toString()
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    val.pxfs_data[0]?.num_siniestro
                      .toString()
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    val.tipo_tramite
                      .toString()
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    val.pxfs_data[0]?.pxfs_clasificacion
                      .toString()
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    val.investigador
                      .toString()
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
                /* MAPEO TABLA INVESTIGADOR */
                /*Estas validaciones sirven para editar la tabla en columna casos, agregando el icono de color según corresponda*/
                .map((row) => {
                  //Dependiendo el tipo de siniestro es true y cumple con su valor de su tabla sera tomado en dias habiles y si no seran naturales
                  //siempre se descontaran los dias de asueto
                  if (
                    (tipo_estandarizados === true &&
                      row.pxfs_data[0].pxfs_idclasificacion === 1) ||
                    (tipo_complejo === true &&
                      row.pxfs_data[0].pxfs_idclasificacion === 2) ||
                    (tipo_especial === true &&
                      row.pxfs_data[0].pxfs_idclasificacion === 3) ||
                    (tipo_cug === true &&
                      row.pxfs_data[0].pxfs_idclasificacion === 4)
                  ) {
                    arreglodias = [1, 2, 3, 4, 5];
                  } else {
                    arreglodias = [0, 1, 2, 3, 4, 5, 6];
                  }

                  moment.updateLocale("us", {
                    workingWeekdays: arreglodias,
                  });

                  //se saca la diferentencia entre los dias de asueto con la fecha actual
                  var fecha = moment(row.fch_llegada).format("LL");
                  var tiempos = moment().businessDiff(
                    moment(row.fch_llegada, "YYYY-MM-DD")
                  );

                  if (
                    tiempos > dias_investigador_compleja ||
                    tiempos > dias_investigador_especial
                  ) {
                    tiempos = (
                      <label className="INV_actualizofecha">
                        <CircleRoundedIcon style={{ color: umbralCancelado }} />{" "}
                        urgente{" "}
                      </label>
                    );
                  } else if (
                    tiempos === dias_investigador_compleja ||
                    tiempos === dias_investigador_especial
                  ) {
                    tiempos = (
                      <label className="INV_actualizofecha">
                        <CircleRoundedIcon style={{ color: umbralEnProceso }} />{" "}
                        Por terminar{" "}
                      </label>
                    );
                  } else if (
                    tiempos < dias_investigador_compleja ||
                    tiempos < dias_investigador_especial
                  ) {
                    tiempos = (
                      <label className="INV_actualizofecha">
                        <CircleRoundedIcon style={{ color: umbralATiempo }} /> A
                        tiempo{" "}
                      </label>
                    );
                  }

                  return (
                    <TableRow key={row.id}>
                      <StyledTableCell>{row.num_folio}</StyledTableCell>
                      <StyledTableCell>
                        {row.pxfs_data[0]?.num_siniestro}
                      </StyledTableCell>
                      <StyledTableCell>{row.tipo_tramite}</StyledTableCell>
                      <StyledTableCell>
                        {row.pxfs_data[0]?.pxfs_clasificacion}
                      </StyledTableCell>
                      <StyledTableCell>
                        {moment(fecha).format(formatofecha)}
                      </StyledTableCell>
                      <StyledTableCell>{tiempos}</StyledTableCell>
                      <StyledTableCell>{row.investigador}</StyledTableCell>
                      <StyledTableCell align="center">
                        {
                          <DialogAsignar
                            title={
                              <FormattedMessage
                                id="investAsIn"
                                defaultMessage="Asignar investigador"
                              />
                            }
                            mainButton={
                              <FormattedMessage
                                id="globAceptr"
                                defaultMessage="Aceptar"
                              />
                            }
                            secondButton={
                              <FormattedMessage
                                id="globCance"
                                defaultMessage="Cancelar"
                              />
                            }
                            rowFolio={row.num_folio}
                            idInvestigacion={row.inv_id}
                            ejecutarConsulta={funcionActualizar}
                          />
                        }
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
        {/* SECCION DEL PAGINACIÓN  */}
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={datosApi.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Registros por página"
        />
      </Paper>
      <br></br>
    </div>
  );
}
