import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RadioInput from "../../../Tools/RadioButton/RadioInput";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import IconButton from "@material-ui/core/IconButton";
import TXTField from "../../../Tools/TextField/TextField";
import Grid from "@material-ui/core/Grid";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import TablePagination from "@mui/material/TablePagination";
//botones
import DialogButton from "../../../Tools/Dialog/DialogButton";

//Estilo de la tabla
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
//TOKEN
import { getToken } from "../../../../api/token";
import { getDataToken } from "../../../../api/dataToken";

import { Link } from "react-router-dom";
//umbral
import {
  umbralCancelado,
  umbralEnProceso,
  umbralATiempo,
  diasoficiales,
  tipo_estandarizados,
  tipo_especial,
  tipo_complejo,
  tipo_cug,
} from "../../../../api/getUmbrales";

export default function InvestigadorTable() {
  /* Función para validar datos tabla,  */

  //APIS
  const token = getToken();
  const [idData, setID] = useState([]);
  const [tableData, setTableData] = useState([]);
  //GET PARA SABER CON EL CORREO SU ID Y CON ESO PINTAR LA TABLA
  const getPermisos = async () => {
    //Resultados de la api investigacion
    var resultID = await getDataToken(
      "login/get_correo/" + localStorage.getItem("SNYTLOG_email") + "/"
    );
    setID(resultID);
  };
  useEffect(() => {
    getPermisos();
  }, []);

  console.log(idData);

  //GET PARA PINTAR LA TABLA DE ACUERDO A SU ID
  const getDataPermisos = async () => {
    //Resultados de la api investigacion
    var resultPermisos = await getDataToken(
      `charolamedico/get_listadoNoEntregados/${idData.id}/`
    );
    setTableData(resultPermisos);
  };
  useEffect(() => {
    getDataPermisos();
  }, [idData.id]);

  console.log(tableData);

  const rows = [createData(tableData)];

  function createData(tableData) {
    return { tableData };
  }

  /* CONSTANTES DE ESTADOS*/

  const [order, setOrder] = useState("ASC"); // esta constante tiene como propósico guardar el estado del orden de la tabla
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla
  const [btnDisbale, setbtnDisbale] = useState(true); //estado del boton

  const mapafecha = diasoficiales.map((elemento) => elemento);
  var arreglodias = [];

  var moment = require("moment-business-days");
  moment.updateLocale("us", {
    holidays: mapafecha,
    holidayFormat: "YYYY-MM-DD",
  });

  /* FUNCIÓN PARA ORDENAR LA TABLA ASC/DSC (Ascendent/ Descendant) */
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...tableData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setOrder("DSC");
      setTableData(sorted);
    } else if (order === "DSC") {
      const sorted = [...tableData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? -1 : 1
      );
      setOrder("ASC");
      setTableData(sorted);
    }
  };

  const [selectdatamedic, setselectdatamedic] = useState("");

  /* FUNCIÓN CLICK EN LOS RADIO BUTTON, ARROJA INFORMACION DE LA FILA SELECCIONADA */
  const rbClick = (row) => {
    setselectdatamedic(row);
  };

  useEffect(() => {
    if (selectdatamedic !== "") setbtnDisbale(false);
  }, [selectdatamedic]);

  /* FUNCIÓN PARA DIRECCIONAR A OTRA VENTANA' */

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <Grid
        style={{ paddingBottom: "20px" }}
        container
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={4}>
          <h4-search>
            <FormattedMessage id="globBuscar" defaultMessage="Buscar" />:
          </h4-search>
          <TXTField
            showSearchIcon={true}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }} style={{ position: "sticky" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">
                  <FormattedMessage
                    id="globSelecc"
                    defaultMessage="Seleccionar"
                  />
                </StyledTableCell>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                <StyledTableCell
                  align="center"
                  onClick={() => sorting("RECT_idfolio")}
                >
                  <FormattedMessage
                    id="globNoDeFo"
                    defaultMessage="No. de folio"
                  />
                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    {order === "DSC" ? <UnfoldMoreIcon /> : <UnfoldMoreIcon />}
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  onClick={() => sorting("SIN_idsiniestro.SIN_numerosiniestro")}
                >
                  <FormattedMessage
                    id="globNSin"
                    defaultMessage="No. de siniestro"
                  />
                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    {order === "DSC" ? <UnfoldMoreIcon /> : <UnfoldMoreIcon />}
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  onClick={() => sorting("CHMED_tiemporespuesta")}
                >
                  <FormattedMessage
                    id="globTiemRe"
                    defaultMessage="Tiempo de respuesta"
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="globEstatu" defaultMessage="Estatus" />
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  onClick={() => sorting("nombre")}
                >
                  <FormattedMessage
                    id="globNomAse"
                    defaultMessage="Nombre del Asegurado"
                  />
                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    {order === "DSC" ? <UnfoldMoreIcon /> : <UnfoldMoreIcon />}
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {tableData
                ?.filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                    val.PER_idpersona.PER_nombre.toLowerCase().includes(
                      searchTerm.toLowerCase()
                    ) ||
                    val.PER_idpersona.PER_apellidopaterno.toLowerCase().includes(
                      searchTerm.toLowerCase()
                    ) ||
                    val.SIN_idsiniestro.SIN_numerosiniestro.toLowerCase().includes(
                      searchTerm.toLowerCase()
                    ) ||
                    val.PER_idpersona.PER_apellidomaterno.toLowerCase().includes(
                      searchTerm.toLowerCase()
                    ) ||
                    val.RECT_idfolio.RECT_numerofolio.toLowerCase().includes(
                      searchTerm.toLowerCase()
                    ) ||
                    val.SIN_idsiniestro.SIN_numerosiniestro.toLowerCase().includes(
                      searchTerm.toLowerCase()
                    )
                  ) {
                    return val;
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  if (
                    (tipo_estandarizados === true &&
                      row.SIN_idsiniestro?.SINC_idcomplejidadsiniestro
                        ?.SINC_complejidad === "Simple") ||
                    (tipo_complejo === true &&
                      row.SIN_idsiniestro?.SINC_idcomplejidadsiniestro
                        ?.SINC_complejidad === "Compleja") ||
                    (tipo_especial === true &&
                      row.SIN_idsiniestro?.SINC_idcomplejidadsiniestro
                        ?.SINC_complejidad === "Especial") ||
                    (tipo_cug === true &&
                      row.SIN_idsiniestro?.SINC_idcomplejidadsiniestro
                        ?.SINC_complejidad === "Urgente")
                  ) {
                    arreglodias = [1, 2, 3, 4, 5];
                  } else {
                    arreglodias = [0, 1, 2, 3, 4, 5, 6];
                  }

                  moment.updateLocale("us", {
                    workingWeekdays: arreglodias,
                  });
                  // Cálculo de días restantes
                  const fechaDeEnvio = moment(
                    row.CHMED_fechatzrespentre,
                    "YYYY-MM-DD"
                  );
                  const diasRespuesta = parseInt(row.CHMED_tiemporespuesta);
                  const fechaActual = moment();
                  const diaspasados = fechaActual.businessDiff(fechaDeEnvio);
                  const diasrestantes = diasRespuesta - diaspasados;
                  let tiempos;

                  if (diasrestantes >= 3) {
                    tiempos = (
                      <label className="CHMED_tiemporespuesta">
                        <CircleRoundedIcon style={{ color: umbralATiempo }} />
                      </label>
                    );
                  } else if (diasrestantes < 3 && diasrestantes > 0) {
                    tiempos = (
                      <label className="CHMED_tiemporespuesta">
                        <CircleRoundedIcon style={{ color: umbralEnProceso }} />
                      </label>
                    );
                  } else if (diasrestantes <= 0) {
                    tiempos = (
                      <label className="CHMED_tiemporespuesta">
                        <CircleRoundedIcon style={{ color: umbralCancelado }} />
                      </label>
                    );
                  }

                  return (
                    <TableRow key={row.RECT_idfolio}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        <RadioInput
                          name="rb"
                          value={row.RECT_idfolio}
                          onChange={() => rbClick(row)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.RECT_idfolio?.RECT_numerofolio}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.SIN_idsiniestro?.SIN_numerosiniestro}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "50px",
                          }}
                        >
                          {tiempos}
                          <span style={{ marginLeft: "10px" }}>
                            {diasrestantes + " días"}
                          </span>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {
                          row?.RECT_idfolio?.TEST_idestatustramite
                            ?.TEST_estatustramite
                        }
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {
                          //row.RECT_idfolio.TEST_idestatustramite.TEST_estatustramite
                          row?.PER_idpersona?.PER_nombre +
                            " " +
                            row?.PER_idpersona?.PER_apellidopaterno +
                            " " +
                            row?.PER_idpersona?.PER_apellidomaterno
                        }
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={tableData?.length > 0 ? tableData?.length : "0"}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelRowsPerPage="Registros por página"
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <br />

      <Grid container spacing={5}>
        <Grid item>
          <Link
            style={{ textDecoration: "none" }}
            to="/medico/infoasegurado"
            state={{ selectdatamedic }}
          ></Link>
          <DialogButton
            mainButton={
              <FormattedMessage id="globSiguie" defaultMessage="Siguiente" />
            }
            title="Nota"
            description="Para activar la opción de guardar y enviar al dictamen deberá seleccionar el Catálogo OII su, las fechas y algún cuadro de texto"
            button={
              <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
            }
            type="submit"
            to="/medico/infoasegurado"
            estatus={btnDisbale}
            state={{ selectdatamedic }}
          />

          {/* <ButtonVino color="primary" estatus={btnDisbale} label={<button-vino-small><FormattedMessage id="globSiguie" defaultMessage="Siguiente"/></button-vino-small> }/> */}
          <br />
        </Grid>
        <br />
      </Grid>
      <br />
    </div>
  );
}
