import { Grid } from "@material-ui/core";
import Reac, {useState} from "react"
//importaciones para la grafica
import { Bar } from 'react-chartjs-2';
//import para la tabla
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../../Tools/Table/StyledTableCell";


const GraficoCuenta = ()=>{
//Creación de las medidas para las estadisticas
//Le asignamos los valores necesarioa a la variable de chartData
    const [chartData, setChartData] = useState({
        labels: ['123456','234567', '345678', '456789'],//asignamos los valores por los que se clasificara la grafica
        datasets: [
          {
            label: 'Cuenta',//Este es el titulo que aparecera en la grafica
            data: [1,1,1,1],//asignamos los valores en un arreglo
            //Podemos darle diferentes colores a las tablas
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1,
          },
        ],
      });

//Creación de la tabla
function createData(cuenta, numero) {
    return { cuenta, numero };
  }
  const rows = [
    createData("123456", "1"),
    createData("234567", "1"),
    createData("345678", "1"),
    createData("456789", "1"),

  ];
  const [data, setData] = useState(rows);
  
return (
    <>
    <Grid container>
      {/* En esta parte aparecen los datos en una tabla */}
        <Grid xs={12} align="center">
        <Paper sx={{ width: "50%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Cuenta</StyledTableCell>
                <StyledTableCell align="center"> Cantidad </StyledTableCell>                
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => {
                return (
                  <TableRow>
                    <StyledTableCell align="center">{row.cuenta}</StyledTableCell>
                    <StyledTableCell align="center">{row.numero}</StyledTableCell>                    
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
        </Grid>
        <Grid xs={12}>
          {/* Aquí llamamos la funcion de la tabla y le pasamos el arreglo que 
          lleva la información que queremos plasmar en la grafica */}
        <Bar data={chartData} />            
        </Grid>

    </Grid>
    </>
)
}
export default GraficoCuenta;