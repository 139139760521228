import React, { useState, useEffect } from "react";
import FechaAviso from "../../Contable/MenuPrescripcion/FechaAviso";
import FechaVencimiento from "../../Contable/MenuPrescripcion/FechaVencimiento";
import FechaSiniestro from "../../Contable/MenuPrescripcion/FechaSiniestro";

import { Grid } from "@mui/material";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import {Select2} from "../../Tools/Selectss/Select2"
//Idioma
import { FormattedMessage } from "react-intl";

export default function FormPrescriptionReport() {

  const [Fechasiniestro, setFechasiniestro] = useState (false)
  const [Fechaaviso, setFechaaviso] = useState (false)
  const [Fechavencimiento, setFechavencimiento] = useState (false)
  const [valorSelect, setValorSelect] = useState(0) 
  const[showBtnRegresarTabla, setShowBtnRegresarTabla]= useState (false)

  const [linea, setLinea] = useState([]);

  const tipoFecha = [
    { identificador: "1", texto: "Por fecha de siniestro" },
    { identificador: "2", texto: "Por fecha de aviso" },
    { identificador: "3", texto: "Por fecha de vencimiento" },
  ];

  useEffect(() => {
    setLinea(tipoFecha);
 }, []);


 const handleMenu = (id) =>{
  const dt = tipoFecha.filter((x) => x.identificador == id);
  if (id == 1) {
    setFechasiniestro(true)
    setFechaaviso(false)
    setFechavencimiento(false)
    setShowBtnRegresarTabla(true)

  }if(id==2){
    setFechasiniestro(false)
    setFechaaviso(true)
    setFechavencimiento(false)
    setShowBtnRegresarTabla(true)

  }if(id==3){
    setFechasiniestro(false)
    setFechaaviso(false)
    setFechavencimiento(true)
    setShowBtnRegresarTabla(true)

  }}

  const handleClickRegresarTabla = () => {
    // Restablecer los estados 
    setFechasiniestro(false)
    setFechaaviso(false)
    setFechavencimiento(false)
    setShowBtnRegresarTabla(false);
    setValorSelect(0)
    
  };
  
    const handleChangeMenu = (e) => {
      handleMenu(e.target.value);
      setValorSelect(e.target.value);
      
    };
  
  return (
    <>
    <Grid container>
      {/*En este contenido llamaremos a los select necesarios para realizar nuestro menu
      Array=pondremos el arreglo que queremos mostrar 
      onChange= pondremos el handle que deseamos que necesitamos en esa opción      
    */}
      <Grid xs={4}>
        <Select2
        sx={{ minWidth: 310, maxWidth: 300, background: "white", }} h3-contable='Buscar por:'
        // label="SubconsultaLinea"
        id="subConsultaLinea" 
        array={tipoFecha}
        onChange={handleChangeMenu} 
        value={valorSelect}
         />
        {/* <Select1 sx={{ minWidth: 310, maxWidth: 300, background: "white", }} h3-contable='Buscar por:' id="subConsultaLinea" array={consultaLinea}
        onChange={(e) => {
          handleMenu(e.target.value);       
        }}        
        /> */}
      </Grid>

      <Grid xs={12}>
        {/*Pondremos todas las opciones porque estas seran seleccionadas dependiendo del menu 
      que desea ver*/} 
        {Fechaaviso? <FechaAviso/>:null}
        {Fechasiniestro? <FechaSiniestro/>:null}
        {Fechavencimiento? <FechaVencimiento/>:null}
        <br/>
      </Grid>
     
      {showBtnRegresarTabla? <Grid container justifyContent="flex-end">
      <Grid item>
      <ButtonVino
        onClick={handleClickRegresarTabla}
        variant="contained"
        color="primary"
        size="small"
        label={<FormattedMessage id="globRegres" defaultMessage="Regresar" />}
      />
      </Grid>
    </Grid>:null}
    </Grid>
   </>
  );
}
