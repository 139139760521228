import React from "react";
import { CompSiniestro } from "../../../components/Registro/CompSiniestro";
import MyComponent from "../../../components/Tools/ButtonLoading/ButtonLoading";

export function Siniestro() {
  return (
    <div>
      <MyComponent componentToRender={CompSiniestro} />
    </div>
  );
}
