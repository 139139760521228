import React, { useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import RadioInput from "../../Tools/RadioButton/RadioInput";
import { Link } from "react-router-dom";
import GetTareas from "../../../api/getTareas";

//Idioma
import { FormattedMessage } from "react-intl";
//estilos de la tabla
import StyledTableCell from "../../Tools/Table/StyledTableCell";
import TablePagination from "@mui/material/TablePagination";
import {
  getDataToken,
  putDataToken,
  postDataToken,
} from "../../../api/dataToken";
import DialogAviso from "./Dialog/DialogAviso";
import getDate from "../Function/obtenerFecha";
import getHours from "../Function/obtenerHora";
import moment from "moment";
import {
  COBERPERORG,
  IDVALCOB,
  IDMODREGISTRO,
} from "../../../utils/constanstoken";

export default function TablePerdida(props) {
  //Traemos el paso de variable
  const datospolizasarr = props.InfoDataRow;
  const infopersonal = props.infopersonal;

  console.log(datospolizasarr);
  console.log(infopersonal);

  //Obtenemos las tareas para nuestro módulo
  const objetosCoincidentes = GetTareas(IDMODREGISTRO);
  console.log(objetosCoincidentes);

  const datospoliza = datospolizasarr.filter(
    (registro) =>
      registro.COB_idcobertura?.COB_cobertura.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase() === COBERPERORG
  );

  console.log(datospoliza);

  const idFolio = datospoliza[0]?.RECT_idfolio?.RECT_idfolio;

  console.log(idFolio);

  //Checamos si en el arreglo existe la cobertura pérdida orgánica
  const existeCobertura = datospolizasarr.some(
    (registro) =>
      registro.COB_idcobertura?.COB_cobertura.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase() === COBERPERORG
  );

  console.log(existeCobertura); // true si existe la cobertura, false si no existe

  //Vamos a obtener solo registros que tengan pérdida orgánica
  const elementoEncontrado = datospolizasarr.find(
    (registro) =>
      registro.COB_idcobertura?.COB_cobertura.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase() === COBERPERORG
  );

  console.log(elementoEncontrado); // Elemento coincidente

  //Vamos a obtener solo registros que sean diferentes a pérdida orgánica
  const nuevoArreglo = datospolizasarr.filter(
    (registro) =>
      registro.COB_idcobertura?.COB_cobertura.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase() !== COBERPERORG
  );

  console.log(nuevoArreglo); // Nuevo arreglo sin el elemento coincidente

  const [arrperdida, setarrperdida] = useState([]);
  const [arrsinperdida, setarrsinperdida] = useState([]);

  useState(() => {
    setarrperdida(elementoEncontrado);
    setarrsinperdida(nuevoArreglo);
  }, []);

  console.log(arrperdida?.id);
  console.log(arrperdida);
  console.log(arrsinperdida);

  //Obtener fecha
  const fechaFormateada = getDate();
  const fechaEmpAn = moment(fechaFormateada, "DD/MM/YYYY").format("YYYY-MM-DD");
  console.log("Fecha emp por año" + fechaEmpAn);

  //Obtener la hora
  const horaActual = getHours();

  const fechaActSIN = `${fechaEmpAn}T${horaActual}`;

  console.log("fechaActSIN" + fechaActSIN);

  const email = localStorage.getItem("SNYTLOG_email");
  const [nombreAnalista, setnombreAnalista] = React.useState("");

  //Obtener usuario.
  const getUsers = async () => {
    try {
      var result = await getDataToken(`login/get_correo/${email}/`);
      setnombreAnalista(
        result.SNYTLOG_username +
          " " +
          result.SNYTLOG_surnamedad +
          " " +
          result.SNYTLOG_surnamemom
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getUsers();
  }, []);

  console.log(nombreAnalista);

  const [polizasxcoberturas, setpolizasxcoberturas] = useState([]); //Guarda toda la información que trae la api
  const [siniestros, setsiniestros] = React.useState([]);

  const getPolizaxproducto = async () => {
    try {
      var result = await getDataToken(
        `polizascoberturasporproductos/get_id/${datospoliza[0].id}/`
      );
      console.log("Resultado polizasxcoberturas=>", result);
      setpolizasxcoberturas(result);
      var resultSiniestros = await getDataToken(`siniestros/get_listado/`);
      const elementoSiniestros = resultSiniestros?.filter(
        (item) => item.RECT_idfolio?.RECT_idfolio === idFolio
      );
      setsiniestros(elementoSiniestros);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getPolizaxproducto();
  }, [idFolio]);

  console.log(siniestros);

  // console.log(polizasxcoberturas.PCP_coberturasumaasegurada);

  //Creción de constantes  para poder usar las apis
  const [registro, setRegistros] = useState([]); //Guarda toda la información que trae la api

  //Método get para traer los datos de la charola registro
  const getPerdidaOrg = async () => {
    try {
      var result = await getDataToken("perdidaorganica/get_listado/");
      console.log("Resultado de pérdida organica=>", result);
      setRegistros(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getPerdidaOrg();
  }, []);

  console.log(registro);

  async function putPerdida(aux) {
    try {
      var result = await putDataToken(
        `polizascoberturasporproductos/get_id/${datospoliza[0].id}/`,
        aux
      );
      console.log("Registro exitoso", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  /* CONSTANTES DE ESTADOS*/
  const [InfoDataRow, setInfoDataRow] = useState([]); //Aqui es donde almacenará los datos de la fila seleccionadad
  const [totalSA, settotalSA] = useState(0);
  const [visible, setvisible] = useState(false);
  const [visibleInf, setvisibleInf] = useState(false);

  /* FUNCIÓN CLICK EN LOS RADIO BUTTON, ARROJA INFORMACION DE LA FILA SELECCIONADA */
  const rbClick = (e) => {
    let porcentaje;
    const inputId = parseInt(e.target.value);
    console.log(inputId);
    registro.map((row) => {
      if (row.PRORG_id === inputId) {
        console.log(row);
        porcentaje = row.PRORG_porcentaje;
        setInfoDataRow(row);
        let total =
          (polizasxcoberturas.PCP_coberturasumaasegurada * porcentaje) / 100;
        console.log(total);
        //Actualizamos a true
        const indices = objetosCoincidentes.findIndex(
          (objeto) => objeto.TARE_idtarea.TARE_idtarea === IDVALCOB
        );
        //Actualizamos a true
        objetosCoincidentes[indices].estado = true;
        if (total > 0) {
          settotalSA(total);
          setvisible(true);
        } else {
          setvisibleInf(true);
        }
      }
    });
  };

  console.log(totalSA);

  // console.log(InfoDataRow.PRORG_porcentaje);
  // Aqui vamos a sacar el porcentaje dependiendo la SA

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const filteredData = registro.slice(startIndex, endIndex);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //POST DE LAS COBERTURAS
  async function postCoberturasxTramite(aux) {
    try {
      var result = await postDataToken(
        `coberturaspolizasportramite/get_listado/`,
        aux
      );
      console.log("Registro exitoso", result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  // Filtrar el arreglo para obtener solo los registros con el campo 'estado'.
  const registrosConEstado = objetosCoincidentes.filter(
    (registro) => registro.estado
  );
  console.log(registrosConEstado);

  //JSON para actualizar el registro

  let uppoliza = {
    PRORG_idorganica: InfoDataRow.PRORG_id,
    PCP_sumaaseguradaperdida: totalSA,
    PCP_actualizofecha: fechaActSIN,
    PCP_actualizousuario: nombreAnalista,
  };
  let dataperdida = {
    RECT_idfolio: idFolio,
    POL_idpoliza: arrperdida?.POL_idpoliza?.POL_idpoliza,
    COB_idcobertura: arrperdida?.COB_idcobertura?.COB_idcobertura,
    CPT_sumaasegurada: totalSA,
    CPT_actualizofecha: fechaActSIN,
    CPT_actualizousuario: nombreAnalista,
  };
  //Funcion para hacer el post
  const handleClick = () => {
    // Actualizar el estado utilizando el hook useState
    setvisible(false);
    putPerdida(uppoliza);
    postCoberturasxTramite(dataperdida);
    let datasinperdida;

    for (const item of arrsinperdida) {
      datasinperdida = {
        RECT_idfolio: idFolio,
        POL_idpoliza: item.POL_idpoliza.POL_idpoliza,
        COB_idcobertura: item.COB_idcobertura.COB_idcobertura,
        MON_idmoneda: item.MON_idmonedacobertura.MON_idmoneda,
        TRA_idtipotramitesiniestro:
          item.RECT_idfolio.SINC_idcomplejidadsiniestro
            .TRA_idtipotramitesiniestro,
        CPT_sumaasegurada: item.PCP_coberturasumaasegurada,
        CPT_actualizofecha: fechaActSIN,
        CPT_actualizousuario: nombreAnalista,
      };
      postCoberturasxTramite(datasinperdida);
    }

    for (const itemTra of registrosConEstado) {
      for (const siniestro of siniestros) {
        let registro = {
          RECT_idfolio: idFolio,
          POL_idpoliza: arrperdida?.POL_idpoliza?.POL_idpoliza,
          WTM_idwftareasxmodulos: itemTra.WTM_idwftareasxmodulos,
          PER_idpersona: infopersonal?.PER_idpersona?.PER_idpersona,
          SIN_idsiniestro: siniestro?.SIN_idsiniestro,
          WKT_fechawktramite: fechaActSIN,
          WKT_seejecuto: itemTra.estado,
          WKT_actualizofecha: fechaActSIN,
          WKT_actualizousuario: nombreAnalista,
        };
        postDataToken(`wktramite/get_listado/`, registro).then((data) => {
          console.log(`Registro exitoso wktramite`, data);
        });
      }
    }
    props.closewin(false);
  };

  const handleClose = () => {
    // Actualizar el estado utilizando el hook useState
    setvisibleInf(true);
  };

  return (
    <div>
      {visible && (
        <DialogAviso
          title={
            <FormattedMessage id="globSumAse" defaultMessage="Suma asegurada" />
          }
          firstButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description={
            <FormattedMessage
              id="totalSA"
              defaultMessage="El monto correspondiente de la SA es {total}"
              values={{
                total: totalSA.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
              }}
            />
          }
          onClick1={handleClick}
        />
      )}
      {visibleInf && (
        <DialogAviso
          title={
            <FormattedMessage
              id="regErrorUsu"
              defaultMessage="Error de usuario"
            />
          }
          firstButton={
            <FormattedMessage id="globAceptr" defaultMessage="Aceptar" />
          }
          description={
            <FormattedMessage
              id="regSelecCob"
              defaultMessage="Selecciona la cobertura correspondiente a pérdida orgánica."
            />
          }
          onClick1={handleClose}
        />
      )}
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                <StyledTableCell>
                  <FormattedMessage
                    id="globPerOrg"
                    defaultMessage="Pérdida orgánica"
                  />
                </StyledTableCell>
                <StyledTableCell> </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {filteredData.map((row) => {
                //Creacion de saber si es perdida organica nos mostrará un mensaje
                return (
                  <TableRow key={row.PRORG_id}>
                    <StyledTableCell component="th" scope="row">
                      <RadioInput
                        name="rb"
                        value={row.PRORG_id}
                        onChange={rbClick}
                      />
                    </StyledTableCell>
                    <StyledTableCell>{row.PRORG_descripcion}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.PRORG_porcentaje}%
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={registro.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={
            <FormattedMessage
              id="globRegPa"
              defaultMessage="Registros por página:"
            />
          }
        />
      </Paper>
    </div>
  );
}
