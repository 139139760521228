import * as React from 'react';
import logoSINAY from '../../../../img/logoSINAY.png'
import { Page, Text, View, Document, StyleSheet, Image, Font, BlobProvider, pdf } from '@react-pdf/renderer';
import { formatNumberDecimalComa } from '../../../../utils/functions';
import { MONEDA_NACIONAL, PERR_BENEFICIARIO } from '../../../../utils/constanstoken';

//Obtener el día de hoy con formato según el lenguaje del navegador 
var today = new Date();
var options = { year: 'numeric', month: 'long', day: 'numeric' };
var language = window.navigator.language;
var dateFormat = today.toLocaleDateString(language, options)


Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    body2: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 0,
    },
    date: {
        textAlign: 'right',
        fontSize: '8px',
        paddingRight: '10px',
        marginTop: 10,
        marginBottom: 10,
    },
    title: {
        fontSize: '8px',
        marginTop: 40,
        textAlign: 'center',
        fontFamily: 'Oswald'
    },
    author: {
        fontSize: '8px',
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: '8px',
        margin: 12,
        fontFamily: 'Oswald'
    },
    data: {
        alignSelf: 'flex-end',
        maxWidth: 250,
        textAlign: 'right',
        fontSize: '8px',
        paddingRight:'10px',
        fontFamily: 'Times-Roman'
    },
    address: {
        maxWidth: '200px',
        marginLeft: 12,
        marginRight: 12,
        fontSize: 10,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    text: {
        lineHeight: 1.5,
        margin: 12,
        fontSize: '8px',
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    reasons: {
        lineHeight: 1.5,
        marginLeft: 12,
        marginRight: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    recipient: {
        marginTop: 30,
        marginLeft: 12,
        marginBottom: '40px',
        fontSize: 12,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    dataRecipient: {
        marginTop: 10,
        marginLeft: 50,
        fontSize: '8px',
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    dataInfo: {
        marginTop: 20,
        marginBottom: 5,
        marginLeft: 12,
        fontSize: '8px',
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    signature: {
        letterSpacing: 2,
        marginLeft: 12,
        marginTop: 30,
        marginBottom: 40,
        fontSize: 12,
        fontFamily: 'Times-Roman'
    },
    sender: {
        marginLeft: 12,
        fontSize: 14,
        fontFamily: 'Times-Roman'
    },
    image: {
        opacity: 0.7,
        position: 'absolute',
        width: 100,
        marginVertical: 15,
        marginHorizontal: 50,
    },
    header: {
        marginTop: 10,
        fontSize: '8px',
        marginBottom: 10,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: '8px',
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    table: {
        display: 'table',
        width: '100%',
        borderStyle: 'solid',
        marginBottom: '20px'

    },
    table2: {
        display: 'table',
        width: '100%',
        borderStyle: 'solid',
        marginBottom: '20px',
        marginTop: '20px',

    },
    tableRow: {
        flexGrow: 1,
        flexDirection: 'row',
        marginBottom: '10px',
        paddingLeft: '10px',
        fontSize: '8px',
    },
    tableRow2: {
        flexGrow: 1,
        flexDirection: 'row',
        marginBottom: '0px',
        paddingLeft: '10px',
        fontSize: '8px',
    },
    tableCell: {
        margin: 'auto',
        // flexGrow: 1,
        marginTop: 5,
        fontSize: '8px',
        textAlign: 'center',
    },
    tableCell2: {
        margin: 'auto',
        // flexGrow: 1,
        marginTop: 0,
        fontSize: '8px',
        textAlign: 'center',
    },
    tableCell3: {
        margin: 'auto',
        // flexGrow: 1,
        marginTop: 10,
        fontSize: '8px',
        textAlign: 'center',
    },
    tableHeader: {
        backgroundColor: '#f2f2f2',
        marginBottom: '10px'
    },
    image: {
        width: 60, // Ancho de la imagen
        height: 60, // Altura de la imagen
        position: 'absolute', // Posición absoluta
        top: 20, // Distancia desde la parte superior
        left: 20, // Distancia desde la izquierda
    },
});

export function CartaRechazo(data) {

    let numPolizas = data.polizas.length;
    return data.destinatarios.map((item, index) => {
        return (
            <Document  >
                <Page size="A4" style={styles.body} scale={0.5}>
                    <Text style={styles.date}>{dateFormat}</Text>
                    <Text style={styles.address}>Sr. {`${item?.nombre} ${item?.apePat} ${item?.apeMat}`}</Text>
                    <Text style={styles.address}>Norte 25 no. 137</Text>
                    <Text style={styles.address}>Col. Moctezuma 2ª. Sección</Text>
                    <Text style={styles.address}>CP. 15530</Text>
                    <Text style={styles.address}>Alcaldía Venustiano Carranza</Text>
                    <Text style={styles.data}>Ref. Asegurado(a) {`${item?.nombre} ${item?.apePat} ${item?.apeMat}`}</Text>
                    <Text style={styles.data}>Pólizas: {
                        data.polizas.map((poliza, index) => {
                            return index + 1 != numPolizas ? poliza + ' ,' : poliza + '.'
                        })
                    } </Text>
                    <Text style={styles.data}>No. Reclamación 2023011456 </Text>

                    <Text style={styles.recipient}>
                        Apreciable Sr(a).  {`${item?.nombre} ${item?.apePat} ${item?.apeMat}`}:
                    </Text>
                    <Text style={styles.text}>
                        En Grupo Nacional Provincial nos preocupamos por atender sus reclamaciones de la
                        manera más rápida, atendiendo siempre a las condiciones del contrato y a las más
                        altas expectativas de atención y servicio. Con relación a la reclamación de referencia
                        que amablemente presentó a esta Aseguradora le comunicamos que no es posible cubrir la
                        reclamación del pago de la suma asegurada de la cobertura de:
                    </Text>
                    <View style={{ flexDirection: "row", marginLeft: 10 }}><Text style={styles.reasons}> - Invalidez Total y Permanente </Text></View>
                    <Text style={styles.text}>
                        Por el motivo descrito a continuación:
                    </Text>
                    {data.arrayDocs.map((poliza) => {
                        return <><View style={{ flexDirection: "row", marginLeft: 10 }}><Text style={styles.reasons}> - {poliza.causas}</Text></View></>
                    })
                    }
                    <Text style={styles.text}>
                        Agradeciendo su atención, quedamos a sus apreciables órdenes.
                    </Text>
                    <Text style={styles.signature}>
                        ATENTAMENTE
                    </Text>
                    <Text style={styles.address}>Lic. Cecilia Guerrero</Text>
                    <Text style={styles.address}>Gerente Reclamaciones Vida</Text>
                    <Text style={styles.address}>Grupo Nacional Provincial</Text>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            </Document>
        )
    })
}
export function CartaSolicitudDocs(data) {
    let numPolizas = data.polizas.length;
    return data.destinatarios.map((item, index) => {
        return (
            <Document  >
                <Page size="A4" style={styles.body} >

                    <Text style={styles.date}>{dateFormat}</Text>

                    <Text style={styles.address}>Sr(a). {`${item.nombre} ${item.apePat} ${item.apeMat}`}</Text>
                    <Text style={styles.address}>Oriente 166 no. 237</Text>
                    <Text style={styles.address}>Col. Moctezuma 2ª. Sección</Text>
                    <Text style={styles.address}>CP. 15530</Text>
                    <Text style={styles.address}>Alcaldía Venustiano Carranza</Text>


                    <Text style={styles.data}>Ref. Asegurado(a) {`${item?.nombre} ${item?.apePat} ${item?.apeMat}`}</Text>
                    <Text style={styles.data}>Pólizas: {
                        data.polizas.map((poliza, index) => {
                            return index + 1 != numPolizas ? poliza + ' ,' : poliza + '.'
                        })
                    }</Text>
                    <Text style={styles.data}>No. Reclamación 20230789</Text>



                    <Text style={styles.recipient}>
                        Apreciable Sr(a). {`${item.nombre} ${item.apePat} ${item.apeMat}`}:
                    </Text>
                    <Text style={styles.text}>
                        En Grupo Nacional Provincial nos preocupamos por atender sus reclamaciones de la manera más rápida atendiendo siempre a las condiciones del contrato
                        y a las más altas expectativas de atención y servicio, en relación a la reclamación de referencia que amablemente presentó a esta Aseguradora,
                        le comunicamos que para continuar con el trámite correspondiente, es necesario nos envíe lo siguiente:
                    </Text>
                    {data.arrayDocs.map((poliza) => {
                        return <><View style={{ flexDirection: "row", marginLeft: 10 }}><Text style={styles.reasons}> - {poliza.docName}</Text></View></>
                    })
                    }
                    <Text style={styles.text}>
                        En cuanto recibamos lo anterior descrito reanudaremos el trámite a su reclamación.
                    </Text>
                    <Text style={styles.text}>
                        Agradeciendo su atención, quedamos a sus apreciables órdenes.
                    </Text>
                    <Text style={styles.signature}>
                        A T E N T A M E N T E
                    </Text>
                    <Text style={styles.sender}>Lic. Cecilia Guerrero</Text>
                    <Text style={styles.sender}>Gerente Reclamaciones Vida</Text>
                    <Text style={styles.sender}>Grupo Nacional Provincial</Text>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            </Document>
        )
    })

}
export function CartaRescision(data) {
    let numPolizas = data.polizas.length;
    return data.destinatarios.map((item, index) => {
        return (
            <Document  >
                <Page size="A4" style={styles.body} >
                    <Text style={styles.date}>{dateFormat}</Text>

                    <Text style={styles.address}>Apreciable Sr(a). {`${item.nombre} ${item.apePat} ${item.apeMat}:`}</Text>
                    <Text style={styles.address}>Oriente 148 no. 239</Text>
                    <Text style={styles.address}>Col. Moctezuma 2ª. Sección</Text>
                    <Text style={styles.address}>CP. 15530</Text>
                    <Text style={styles.address}>Alcaldía Venustiano Carranza</Text>


                    <Text style={styles.data}>Ref. Asegurado(a) {`${item?.nombre} ${item?.apePat} ${item?.apeMat}`}</Text>
                    <Text style={styles.data}>Pólizas: {
                        data.polizas.map((poliza, index) => {
                            return index + 1 != numPolizas ? poliza + ', ' : poliza + '.'
                        })
                    }</Text>
                    <Text style={styles.data}>No. Reclamación 2023011456</Text>

                    <Text style={styles.recipient}>
                        Apreciable Sr(a). {`${item.nombre} ${item.apePat} ${item.apeMat}`}:
                    </Text>
                    <Text style={styles.text}>
                        En Grupo Nacional Provincial nos preocupamos por atender sus reclamaciones de la
                        manera más rápida atendiendo siempre a las condiciones del contrato y a las más altas
                        expectativas de atención y servicio, en relación a la reclamación de referencia que
                        presentó a esta Aseguradora le comunicamos que no es posible cubrir la reclamación
                        de referencia ya que en la solicitud de seguro que presentó a esta aseguradora
                        el Sr. Rodrigo Santiago Mares omitió informar datos que no permitieron la correcta
                        evaluación del riesgo, por lo que le comunicamos la rescisión del contrato de seguro
                        de acuerdo al artículo 47 de la Ley sobre el Contrato de Seguro.
                    </Text>

                    <Text style={styles.text}>
                        En caso de que desee conocer el monto de prima no devengada que pudiera estar a su favor,
                        le solicitamos se dirija al teléfono de atención a clientes 55 2876 9000
                    </Text>
                    <Text style={styles.text}>
                        Agradeciendo su atención, quedamos a sus apreciables órdenes.
                    </Text>
                    <Text style={styles.signature}>
                        A T E N T A M E N T E
                    </Text>
                    <Text style={styles.sender}>Lic. Cecilia Guerrero</Text>
                    <Text style={styles.sender}>Gerente Reclamaciones Vida</Text>
                    <Text style={styles.sender}>Grupo Nacional Provincial</Text>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            </Document>
        )
    })

}
export function AcuseRecepcion(dataArr, type) { //Type true cuando es un acuse por agregar documentación
    // let dataArr = {
    //     dataAsegurado: {
    //         nombre: "Roger",
    //         apePat: "Sanchez",
    //         apeMat: "Perez",
    //         fechaNac: "10-02-1999",
    //         correo: "",
    //     },
    //     dataContacto: {
    //         nombre: "Roger",
    //         apePat: "Sanchez",
    //         apeMat: "Perez",
    //         fechaNac: "10-02-1999",
    //         correo: "agente@gmail.com",
    //     },
    //     datosTramite: {
    //         noFolio: "REC002132",
    //         tipoTramite: "Fallecimiento",
    //         polizas: [
    //             {
    //                 numPoliza: "NL123",
    //                 coberturas: [{ nombCober: "Cobertura por fallecimiento", SA: "100000", moneda: "MXN" }]
    //             },
    //             {
    //                 numPoliza: "NL456",
    //                 coberturas: [{ nombCober: "Cobertura por fallecimiento 2", SA: "200000", moneda: "MXN" }]
    //             }]
    //     },
    //     documentos: [{
    //         nombre: "Poliza",
    //         original: true,
    //         copia: false,
    //     }]
    // }
    return (
        <Document  >
            <Page size="A4" style={styles.body} >
                <Image src={logoSINAY} style={styles.image} />

                <Text style={styles.date}>{dateFormat}</Text>


                <Text style={styles.data}>No. Reclamación {dataArr.datosTramite.noFolio}</Text>

                <Text style={styles.dataInfo}>
                    Nombre del asegurado: {`${dataArr.dataAsegurado.nombre} ${dataArr.dataAsegurado.apePat} ${dataArr.dataAsegurado.apeMat}`}
                </Text>
                <Text style={styles.dataInfo}>
                    Información de la persona que se contactará para darle seguimiento al trámite
                </Text>
                <Text style={styles.dataRecipient}>
                    Nombre del contacto: {`${dataArr.dataContacto.nombre} ${dataArr.dataContacto.apePat} ${dataArr.dataContacto.apeMat}`}
                </Text>
                <Text style={styles.dataRecipient}>
                    Correo electrónico: {`${dataArr.dataContacto.correo} `}
                </Text>
                <Text style={styles.dataInfo}>
                    Polizas reclamadas:
                </Text>
                {!type ? dataArr.datosTramite.polizas.map((poliza, index) => (
                    <View style={styles.table} key={index}>
                        {/* Encabezado de la tabla */}
                        <Text style={styles.header}>Póliza {poliza.poliza}</Text>
                        <View style={[styles.tableRow, styles.tableHeader]}>
                            <View style={styles.tableCell}>
                                <Text>Cobertura</Text>
                            </View>
                            <View style={styles.tableCell}>
                                <Text>Suma asegurada</Text>
                            </View>
                            <View style={styles.tableCell}>
                                <Text>Moneda</Text>
                            </View>
                        </View>

                        {/* Datos de la tabla */}
                        {poliza.coberturas.map((cobertura, index) => (
                            <View style={styles.tableRow}>
                                <View style={styles.tableRow}>
                                    <Text>{cobertura.nomCober}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text>{formatNumberDecimalComa(cobertura.SA)}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text>{cobertura.nomMoneda}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                )) : dataArr.datosTramite.polizas.map((poliza, index) => (
                    <Text style={styles.dataRecipient}>
                        {index + 1 === dataArr.datosTramite.polizas.length ? `${poliza.poliza}.` : `${poliza.poliza},`}
                    </Text>
                ))}
                <Text style={styles.dataInfo}>
                    Documentos recibidos:
                </Text>
                <View style={styles.table} >
                    {/* Encabezado de la tabla */}
                    <Text style={styles.header}></Text>
                    <View style={[styles.tableRow, styles.tableHeader]}>
                        <View style={styles.tableCell}>
                            <Text>Documento</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text>Original</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text>Copia</Text>
                        </View>
                    </View>

                    {/* Datos de la tabla */}
                    {dataArr.documentos.map((documento, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={styles.tableRow}>
                                <Text>{documento.nombre}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text>{documento.copia ? "X" : ""}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text>{documento.original ? "X" : ""}</Text>
                            </View>
                        </View>
                    ))}
                </View>



                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    );
}
export function AcuseFiniquito(json) { //Type true cuando es un acuse por agregar documentación
    // let jsonPost = {
    //     tipoPersona: type,
    //     nombreAseguradora: "",
    //     coberPagTotales: []
    //     nombreAsegurado: `${dataApiPolxFolioxSin.PER_idpersona?.PER_nombre} ${dataApiPolxFolioxSin.PER_idpersona?.PER_apellidopaterno} ${dataApiPolxFolioxSin.PER_idpersona?.PER_apellidomaterno}`,
    //     numFolio: dataApiPolxFolioxSin.RECT_idfolio.RECT_numerofolio,
    //     numSiniestro: dataApiPolxFolioxSin.SIN_idsiniestro.SIN_numerosiniestro,
    //     numPoliza: dataApiPolxFolioxSin.POL_idpoliza.POL_numpoliza,
    //     coberturasPagadas: cobPagadas,
    //     importesConceptosFavor: [],
    //     importesConceptosContra: [],
    //     leyendaPago: "",
    //     leyendaLegal: "",
    //   }
    let totalFavorNeto = 0;
    for (let cobPagada of json.coberturasPagadas) {
        totalFavorNeto += cobPagada.PAG_importe;
    }
    return (
        <Document  >
            <Page size="A4" style={styles.body2} >
                <Image src={logoSINAY} style={styles.image} />

                <Text style={styles.date}>{dateFormat}</Text>


                <Text style={styles.data}>No. Reclamación {json.numFolio}</Text>

                <Text style={styles.dataInfo}>
                    Nombre del asegurado: {json.nombreAsegurado}
                </Text>
                <Text style={styles.dataRecipient}>
                    Número de póliza: {json.numPoliza}
                </Text>
                <Text style={styles.dataRecipient}>
                    Número de siniestro: {json.numSiniestro}
                </Text>

                {json.tipoPersona == PERR_BENEFICIARIO.id ?
                    <>
                        <View style={styles.table2} >
                            {/* Encabezado de la tabla */}
                            <View style={[styles.tableRow2, styles.tableHeader]}>
                                <View style={[styles.tableCell2, { width: '30%' }]}>
                                    <Text></Text>
                                </View>
                                <View style={[styles.tableCell2, { width: '20%' }]}>
                                    <Text>Importe USD</Text>
                                </View>
                                <View style={[styles.tableCell2, { width: '20%' }]}>
                                    <Text>Tipo cambio</Text>
                                </View>
                                <View style={[styles.tableCell2, { width: '20%' }]}>
                                    <Text>Importe moneda nacional</Text>
                                </View>
                            </View>

                            {/* Datos de la tabla */}
                            {json.coberPagTotales.map((cobertura, index) => (
                                <View style={styles.tableRow2} key={index + 1}>
                                    <View style={[styles.tableCell2, { width: '30%',  textAlign:'left'}]}>
                                        <Text>{`${cobertura.COB_idcobertura.COB_idcobertura.COB_cobertura}`}</Text>
                                    </View>
                                    <View style={[styles.tableCell2, { width: '20%' }]}>
                                        <Text>{cobertura.COB_idcobertura.MON_idmonedas.MON_idmoneda == MONEDA_NACIONAL.id ? '-' : `${cobertura.PAG_importe}`}</Text>
                                    </View>
                                    <View style={[styles.tableCell2, { width: '20%' }]}>
                                        <Text>{!cobertura.COB_idcobertura.PAG_importetipocambio ? '-' : `${cobertura.COB_idcobertura.PAG_importetipocambio}`}</Text>
                                    </View>
                                    
                                    <View style={[styles.tableCell2, { width: '20%' }]}>
                                        <Text>{!cobertura.total ? '-' : formatNumberDecimalComa(cobertura.total)}</Text>
                                    </View>
                                </View>
                            ))}
                            {/* Datos de la tabla 
                            {json.coberturasPagadas.map((cobertura, index) => (
                                <View style={styles.tableRow2} key={index + 1}>
                                    <View style={[styles.tableCell2, { width: '30%',  textAlign:'left'}]}>
                                        <Text>{`${cobertura.COB_idcobertura.COB_cobertura}`}</Text>
                                    </View>
                                    <View style={[styles.tableCell2, { width: '20%' }]}>
                                        <Text>{!cobertura.PAG_importetipocambio ? '-' : `${cobertura.PAG_importetipocambio}`}</Text>
                                    </View>
                                    <View style={[styles.tableCell2, { width: '20%' }]}>
                                        <Text>{!cobertura.PAG_importetipocambio ? '-' : `${cobertura.PAG_importetipocambio}`}</Text>
                                    </View>
                                    <View style={[styles.tableCell2, { width: '20%' }]}>
                                        <Text>{!cobertura.PAG_importe ? '-' : formatNumberDecimalComa(cobertura.PAG_importe)}</Text>
                                    </View>
                                </View>
                            ))}*/}
                            {json.importesConceptosFavor.map((reservaAcc, index) => (
                                <View style={styles.tableRow2} key={index + 1}>
                                    <View style={[styles.tableCell2, { width: '30%', textAlign: "left"  }]}>
                                        <Text>{`${reservaAcc.CREVAJ0_idconceptoreservaajuste.CREVAJ0_conceptoreservaaj}`}</Text>
                                    </View>
                                    <View style={[styles.tableCell2, { width: '20%' }]}>
                                        <Text>{!reservaAcc.PAG_importetipocambio ? '-' : `${reservaAcc.PAG_importetipocambio}`}</Text>
                                    </View>
                                    <View style={[styles.tableCell2, { width: '20%' }]}>
                                        <Text>{!reservaAcc.PAG_importetipocambio ? '-' : `${reservaAcc.PAG_importetipocambio}`}</Text>
                                    </View>
                                    <View style={[styles.tableCell2, { width: '20%' }]}>
                                        <Text>{!reservaAcc.REAJ0_importe ? '-' : formatNumberDecimalComa(reservaAcc.REAJ0_importe)}</Text>
                                    </View>
                                </View>
                            ))}
                            {json.importesConceptosContra.map((reservaAcc, index) => (
                                <View style={styles.tableRow2} key={index + 1}>
                                    <View style={[styles.tableCell2, { width: '30%', textAlign: "left"  }]}>
                                        <Text>{`${reservaAcc.CREVAJ0_idconceptoreservaajuste.CREVAJ0_conceptoreservaaj}`}</Text>
                                    </View>
                                    <View style={[styles.tableCell2, { width: '20%' }]}>
                                        <Text>{!reservaAcc.PAG_importetipocambio ? '-' : `${reservaAcc.PAG_importetipocambio}`}</Text>
                                    </View>
                                    <View style={[styles.tableCell2, { width: '20%' }]}>
                                        <Text>{!reservaAcc.PAG_importetipocambio ? '-' : `${reservaAcc.PAG_importetipocambio}`}</Text>
                                    </View>
                                    <View style={[styles.tableCell2, { width: '20%' }]}>
                                        <Text>{!reservaAcc.REAJ0_importe ? '-' : formatNumberDecimalComa(reservaAcc.REAJ0_importe)}</Text>
                                    </View>
                                </View>
                            ))}
                            <View style={styles.tableRow2}>
                                <View style={[styles.tableCell2, { width: '30%',paddingTop:'10px', textAlign:'left' }]}>
                                    <Text>{`Total a favor neto`}</Text>
                                </View>
                                <View style={[styles.tableCell2, { width: '20%',paddingTop:'10px' }]}>
                                    <Text>{''}</Text>
                                </View>
                                <View style={[styles.tableCell2, { width: '20%', paddingTop:'10px' }]}>
                                    <Text>{''}</Text>
                                </View>

                                <View style={[styles.tableCell2, { width: '20%', paddingTop:'10px' }]}>
                                    <Text>{formatNumberDecimalComa(totalFavorNeto)}</Text>
                                </View>

                            </View>
                        </View>
                        <View style={styles.table2} >
                            {/* Encabezado de la tabla */}
                            <View style={[styles.tableRow, styles.tableHeader]}>
                                <View style={[styles.tableCell, { width: '20%' }]}>
                                    <Text>Nombre del beneficiario</Text>
                                </View>
                                <View style={[styles.tableCell, { width: '20%' }]}>
                                    <Text>RFC</Text>
                                </View>
                                <View style={[styles.tableCell, { width: '10%' }]}>
                                    <Text>% de pago</Text>
                                </View>
                                <View style={[styles.tableCell, { width: '20%' }]}>
                                    <Text>Importe pagado</Text>
                                </View>
                                <View style={[styles.tableCell, { width: '10%' }]}>
                                    <Text>Medio de pago</Text>
                                </View>
                                <View style={[styles.tableCell, { width: '10%' }]}>
                                    <Text>Firma</Text>
                                </View>
                            </View>

                            {/* Datos de la tabla */}
                            {json.coberturasPagadas.map((cobertura, index) => (
                                <View style={styles.tableRow} key={index + 1}>
                                    <View style={[styles.tableCell, { width: '20%', textAlign:'left',  }]}>
                                        <Text>{`${cobertura.nombreCompleto}`}</Text>
                                    </View>
                                    <View style={[styles.tableCell, { width: '20%' }]}>
                                        <Text>{!cobertura.PER_idpersona.PER_RFC ? '-' : `${cobertura.PER_idpersona.PER_RFC}`}</Text>
                                    </View>
                                    <View style={[styles.tableCell, { width: '10%' }]}>
                                        <Text>{cobertura.PAG_porcentaje}</Text>
                                    </View>
                                    <View style={[styles.tableCell, { width: '20%' }]}>
                                        <Text>{formatNumberDecimalComa(cobertura.PAG_importe)}</Text>
                                    </View>
                                    <View style={[styles.tableCell, { width: '10%' }]}>
                                        <Text>{cobertura.TFPAG_idtipoformapago.TFPAG_tipoformadepago}</Text>
                                    </View>
                                    <View style={[styles.tableCell, { width: '10%' }]}>
                                        <Text>{''}</Text>
                                    </View>
                                </View>

                            ))}
                            <View style={styles.tableRow}>
                                <View style={[styles.tableCell, { width: '20%', paddingTop:'10px'}]}>
                                    <Text>{``}</Text>
                                </View>
                                <View style={[styles.tableCell, { width: '20%' , paddingTop:'10px'}]}>
                                    <Text>{''}</Text>
                                </View>
                                <View style={[styles.tableCell, { width: '10%', paddingTop:'10px' }]}>
                                    <Text>{'Total'}</Text>
                                </View>

                                <View style={[styles.tableCell, { width: '20%', paddingTop:'10px' }]}>
                                    <Text>{formatNumberDecimalComa(totalFavorNeto)}</Text>
                                </View>
                                <View style={[styles.tableCell, { width: '10%', paddingTop:'10px' }]}>
                                    <Text>{''}</Text>
                                </View>
                                <View style={[styles.tableCell, { width: '10%', paddingTop:'10px' }]}>
                                    <Text>{''}</Text>
                                </View>

                            </View>
                        </View>
                    </>

                    : null}



                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    );
}

export async function imprimirCarta(carta, data) {

    var tipoCarta = carta;
    var carta = tipoCarta === 1 ? CartaRechazo(data) : (tipoCarta === 2 ? CartaRescision(data) : CartaSolicitudDocs(data))

    carta.map(async (item) => {

        var blob = await pdf(item).toBlob();
        const pdfUrl = window.URL.createObjectURL(blob);
        var printWindow = window.open(pdfUrl, '_blank');
        printWindow.print();

    })

}
export async function uploadFile2() {
    let data = {
        'arrayDocs': [{ causas: "No hay INE" }],
        'destinatarios': [{ nombre: "Brandon", apePat: "Hernandez", apeMat: "Rocha", docName: "CR_BHR" }], // Aquí cada destinatario deberá tener un nombre de archivo diferente
        'polizas': ["POL23123423"]
    };

    const blob = await pdf(CartaRechazo(data)).toBlob();
    const doc = new File([blob], data.destinatarios[0].docName, { type: 'application/pdf' });

    try {
        const response = await fetch("https://67.205.175.144/remote.php/dav/files/qait/SINAY/FOLIOS" + '/FOLIO12/' + doc.name + ".pdf", {
            method: 'PUT',
            headers: {
                'Authorization': `Basic ${customBtoa("qait" + ':' + "sinay2022")}`,
            },
            body: doc,
        });

        if (!response.ok) {
            throw new Error(`Ocurrió un error al subir el archivo ${doc.name}`);
        } else {
            console.log("¡Archivo subido con éxito!");
        }
    } catch (error) {
        console.error(error);
    }
}

function customBtoa(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(parseInt(p1, 16));
    }));
}