import React from "react";

import Footer from "../Footer/Footer";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import NavAppBar from "./Nav";
import NavUsuario from "./NavUsuario";
import NavCerrarSesion from "./NavCerrarSesion";
import NavMatrizRoles from "./NavMatrizRoles";
import NavDocumentacion from "./NavDocumentacion";
import familia from "../../../img/familia.png";

export function Headerlayout() {
  return (
    <>
      <NavAppBar></NavAppBar>
      <br></br>
      <br></br>
      <NavMatrizRoles></NavMatrizRoles>
      <br></br>
      <br></br>
      <NavCerrarSesion></NavCerrarSesion>
      <br></br>
      <br></br>
      <NavUsuario></NavUsuario>
      <br></br>
      <br></br>
      <NavDocumentacion></NavDocumentacion>
      <br></br>
      <br></br>

      <Box px={{ xs: 4, sm: 10 }} py={{ xs: 1, sm: 10 }}>
        <Container maxWidth="lg">
          <Grid container spacing={5}>
            <Grid item xs={20} sm={8}>
              <Box borderBottom={1}></Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box borderBottom={1}></Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box borderBottom={1}></Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box borderBottom={1}>
                <img
                  img
                  src={familia}
                  alt="familia"
                  width="150%"
                  height="150%"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Box borderBottom={1}></Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <br></br>
      <br></br>
      <Footer></Footer>
    </>
  );
}
