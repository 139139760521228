/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import Paper from "@mui/material/Paper";
import DialogEliminarBene from "../Dialogs/DialogEliminarBene";
import { Grid } from "@mui/material";
import moment from "moment/moment";

//Dialogs
import DialogEditarBene from "../Dialogs/DialogEditarBene";
//idioma
import { FormattedMessage } from "react-intl";
import { calculateAge } from "../../../../utils/functions";
import { getDataToken } from "../../../../api/dataToken";
import { useState } from "react";
import { PERR_ASEGURADO } from "../../../../utils/constanstoken";
import { formatofecha } from "../../../../api/getUmbrales";
import { useEffect } from "react";
import DialogEditarAse from "../Dialogs/DialogEditarAse";

export default function TableAsegurado(props) {
  // CONSTANTES / ESTADOS / VARIABLES
  const idFolio = props.idFolio;
  const idPoliza = props.idPoliza;
  const [dataAse, setDataAsegurado] = useState(props.asegurado);
  //FECHA ACTUAL
  let today = new Date();

   /* Funcion para actualizar los datos de los beneficiarios en la tabla */
   const getDataPersonasXPoliza = async () => {
    try {
      let response = await getDataToken(`personasxpoliza/get_poliza/${idPoliza}/`)
      if (response) {
        /** Buscar al asegurado de la póliza */
        const asegurado = response.filter((persona) => persona?.PER_idpersona?.PERR_idrol?.PERR_idrol == PERR_ASEGURADO.id);

        if (asegurado.length > 0)
          setDataAsegurado(asegurado)
        else
          setDataAsegurado([])
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  useEffect(()=>{},[])
  //Cobertura por folio 
  const [cobertura, setCobertura] = React.useState([]);

  const getPolizaCoberturasXTProductos = async () => {
    try {
      let result = await getDataToken('polizascoberturasporproductos/get_listado/');
      if (result) {
        let coberturaBuscada = result.find(function (objeto) {
          return objeto?.RECT_idfolio.RECT_idfolio == idFolio
        });
        if (coberturaBuscada) {
          setCobertura(coberturaBuscada);
        }
      }
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }
   React.useEffect(() => {
    getPolizaCoberturasXTProductos();
   },[])
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Paper style={{ width: "100%" }}>
            <TableContainer style={{ position: "sticky" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                    <StyledTableCell>
                      <FormattedMessage
                        id="globNombre"
                        defaultMessage="Nombre"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage
                        id="globParent"
                        defaultMessage="Parentesco"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage id="globRfcp" defaultMessage="RFC" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage
                        id="globFechNa"
                        defaultMessage="Fecha de nacimiento"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="dictEdadActual"
                        defaultMessage="Edad actual"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="dictEdadTra"
                        defaultMessage="Edad al trámite"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="globRazSoc"
                        defaultMessage="Razón social"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="dictClabeInte"
                        defaultMessage="Clabe interbancaria"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="dictamCuentaBancaria"
                        defaultMessage="Cuenta bancaria"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="dictamBanc"
                        defaultMessage="Banco"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="globEditar"
                        defaultMessage="Editar"
                      />
                    </StyledTableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
                  {!dataAse? null: dataAse.map((ase) => {
                    return (
                      
                      <TableRow key={ase.PXP_idpersonaxpoliza}>
                        <StyledTableCell component="th" scope="row">
                          {`${ase?.PER_idpersona?.PER_nombre} ${ase?.PER_idpersona?.PER_apellidopaterno} ${ase.PER_idpersona?.PER_apellidomaterno}`}
                        </StyledTableCell>
                        <StyledTableCell>{ase?.PER_idpersona?.PAR_idparentesco?.PAR_parentesco}</StyledTableCell>
                        <StyledTableCell>{ase?.PER_idpersona?.PER_RFC}</StyledTableCell>
                        <StyledTableCell>{moment(ase?.PER_idpersona?.PER_fechanacimiento).format(formatofecha)}</StyledTableCell>
                        <StyledTableCell>{calculateAge( today.toISOString(), ase?.PER_idpersona.PER_fechanacimiento)}</StyledTableCell>
                        <StyledTableCell>{ase?.PER_idpersona?.PER_edadtramite}</StyledTableCell>
                        <StyledTableCell>{""}</StyledTableCell>
                        <StyledTableCell>{ase?.PER_idpersona?.PER_clabe}</StyledTableCell>
                        <StyledTableCell>{ase?.PER_idpersona?.PER_numerocuentabancaria}</StyledTableCell>
                        <StyledTableCell>{""}</StyledTableCell>
                        <StyledTableCell align="center">
                          <DialogEditarAse asegurado={ase}  response={(e)=>getDataPersonasXPoliza()}/>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
