import React from 'react';
import "./Communication.css";
import Recorrido from "../Tools/BreadCrumbs/Recorrido";
import ReturnPage from "../Tools/Bottones/ButtonReturn"; 
import TablaComunicacion from "./Elements/TablaComunicacion";
import { Grid } from '@mui/material';


export function CompComunicacion() {

  return (
    
  <div className='div-parent'>
      <div className='div-breadcrumbs'> 
      <Recorrido name1="Inicio" enlace="/menu" name2="Comunicacion Cliente" />
      </div>
      <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                <ReturnPage href='/menu'/>
            </Grid>
      <div className='div-container' >  
      <TablaComunicacion/>

      </div>
    </div>

  );
}