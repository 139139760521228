import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { Grid } from "@mui/material";
import RadioInput from "../../Tools/RadioButton/RadioInput";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import TXTField from "../../Tools/TextField/TextField";
import Date from "../../Tools/DatePicker/DatePicker";
import { Select1 } from "../../Tools/Selectss/Select1";
//paginación de la tabla
import TablePagination from "@mui/material/TablePagination";
//estilos de la tabla
import StyledTableCell from "../../Tools/Table/StyledTableCell";
//Idioma
import { FormattedMessage } from "react-intl";

export default function TableConsultation() {
  /* Función para validar datos tabla,  */
  function createData(numControl, fechaGeneracion, moneda, importeCargos, importeCreditos, integracion, estatus) {
    return {numControl, fechaGeneracion, moneda, importeCargos, importeCreditos, integracion, estatus };
  }
  const rows = [
    createData("SY897626", "05/01/2021", "01", "140 000", "140 000", " ", "Creada"),
    createData("SY889176", "01/01/2021", "02", "155 000", "155 000", " ", "Creada"),
    createData("SY834171", "10/04/2021", "01", "1 525 000", "1 525 000", "", "Enviada"),
    createData("SY834172", "30/03/2021", "02", "2 187 000", "2 187 000", "76252130","Contabilizada"),
  ];

  /* CONSTANTES DE ESTADOS*/
  const [data, setData] = useState(rows); // esta constante guarda un arreglo en donde se almacenará los datos de la API
  const [order, setOrder] = useState("ASC"); // esta constante tiene como propósico guardar el estado del orden de la tabla
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla
  const [InfoDataRow, setInfoDataRow] = useState(""); //Aqui es donde almacenará los datos de la fila seleccionadad
  const [btnDownloadInfo, setBtnDownloadInfo] = useState(true);
  const [btnConsultar, setBtnConsultar] = useState(true);
  const [btnEnviar, setBtnEnviar] = useState(true);
  const [btnIntegrar, setBtnIntegrar] = useState(true);


  /* FUNCIÓN PARA ORDENAR LA TABLA ASC/DSC (Ascendent/ Descendant) */
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setOrder("DSC");
      setData(sorted);
    } else if (order === "DSC") {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? -1 : 1
      );
      setOrder("ASC");
      setData(sorted);
    }
  };

  /* FUNCIÓN CLICK EN LOS RADIO BUTTON, ARROJA INFORMACION DE LA FILA SELECCIONADA */
  const rbClick = (e) => {
    const inputControl = e.target.value;
    data.map((row) => {
      if (row.numControl === inputControl) {
        console.log(row);
        setInfoDataRow(row);
        setBtnDownloadInfo(false);
        setBtnIntegrar(false)

        // Activar los botones según el estado
        if (row.estatus === "Creada") {
          setBtnConsultar(false);
          setBtnEnviar(false);
          setBtnIntegrar(true);
        } else if (row.estatus === "Enviada") {
          setBtnConsultar(false);
          setBtnEnviar(true);
          setBtnIntegrar(false);
        } else if (row.estatus === "Contabilizada") {
          setBtnConsultar(false);
          setBtnEnviar(true);
          setBtnIntegrar(true);
        }
        console.log(btnEnviar, btnConsultar, btnIntegrar)
      }
    });
  };

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
   //Arreglo para el estatus
   const estatus = [
    { identificador: "1", texto: "Creada" },
    { identificador: "2", texto: "Enviada" },
    { identificador: "3", texto: "Contabilizada" },
    { identificador: "4", texto: "Todas las PD´S" },
  ];

  return (
    <div>
      <Grid container justifyContent="center">
      <Grid item>
          <h3-header-card>Consulta e Integración de PD´S</h3-header-card>
        </Grid>       
      </Grid>
      <Grid
        style={{ paddingBottom: "20px" }}
        container
        justifyContent="flex-end"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={3}>
        <h3-subtitle-card>
          <label>Fecha de aviso inicio</label>
        </h3-subtitle-card>
          <br />
          <Date></Date>
        </Grid>
        <Grid item xs={3}>
        <h3-subtitle-card>
          <label>Fecha de aviso fin</label>
        </h3-subtitle-card>
          <br />
          <Date></Date>
        </Grid>
        <Grid item xs={3}>
        <h3-subtitle-card>
          <label>Estatus</label>          
        </h3-subtitle-card>
          {/* Mediante el array pasamos las opciones que se mostrarán en la vista */}
          <br />
          <Select1 array={estatus}/>
        </Grid>
        <Grid item xs={3}>
          <br />
          <br />
          <br />
          <ButtonVino
          label="Buscar" />
        </Grid>
      </Grid>

      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}> 
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">
                </StyledTableCell>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                <StyledTableCell align="center">
                  <Grid container alignItems="center">
                    <Grid item xs={9}>
                      No. de Control
                    </Grid>
                    <Grid item xs={3}>
                    <IconButton
                        className="icon-button"
                        style={{ color: "white" }}
                        onClick={() => sorting("cuenta")}
                      >
                        <UnfoldMoreIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="center"> Fecha de Generación </StyledTableCell>
                <StyledTableCell align="center"><FormattedMessage id="globMoneda" defaultMessage="Moneda" /></StyledTableCell>
                <StyledTableCell align="center"> Importe total de cargo </StyledTableCell>
                <StyledTableCell align="center"> Importe total de crédito </StyledTableCell>
                <StyledTableCell align="center"> ID de integración </StyledTableCell>
                <StyledTableCell align="center"><FormattedMessage id="globEstatu" defaultMessage="Estatus" /> 
                <IconButton className="icon-button" style={{ color: "white" }}>
                    <UnfoldMoreIcon />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {data
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                    val.numControl.toLowerCase().includes(searchTerm.toLowerCase()) ||
                      val.estatus.toLowerCase().includes(searchTerm.toLowerCase())  
                  ) {
                    return val;
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.numControl}>
                    <StyledTableCell component="th" scope="row" align="center">
                      <RadioInput
                        name="rb"
                        value={row.numControl}
                        onChange={rbClick}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center"> {row.numControl} </StyledTableCell>
                    <StyledTableCell align="center"> {row.fechaGeneracion} </StyledTableCell>
                    <StyledTableCell align="center"> {row.moneda} </StyledTableCell>
                    <StyledTableCell align="center"> {row.importeCargos} </StyledTableCell>
                    <StyledTableCell align="center">{row.importeCreditos}</StyledTableCell>
                    <StyledTableCell align="center">{row.integracion}</StyledTableCell>
                    <StyledTableCell align="center">{row.estatus}</StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelRowsPerPage="Filas por página"
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <br />
      <Grid 
       container
       direction="row"
       justifyContent="flex-end"
       alignItems="flex-start"
       spacing={1}>
        <Grid item>
          <ButtonVino
            variant="contained"
            color="primary"
            size="small"
            estatus={btnConsultar}
            label={<FormattedMessage id="globConsul" defaultMessage="Consultar" /> }
          />
          <br />
        </Grid>
        <Grid item>
        <ButtonVino
            variant="contained"
            color="primary"
            size="small"
            estatus={btnEnviar}
            label={<FormattedMessage id="globEnviar" defaultMessage="Enviar" />}
            />
        </Grid>
        <Grid item>
        <ButtonVino
            variant="contained"
            color="primary"
            size="small"
            estatus={btnIntegrar}
            label={<FormattedMessage id="estconInte" defaultMessage="Integrar" />}
          />          
        </Grid>
        <br />
      </Grid>
      <br />
      <br />
    </div>
  );
}
