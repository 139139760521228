// import { getDataToken } from "../api/dataToken";
import { getUsers } from "../../src/components/Login/apiLogin/getUsers";
import { principal,secundario } from "../api/getUmbrales";
import { URLAPIS } from "../utils/constans";
/**
 *
 * @returns Retorna la resupuesta de la petición de la API
 */
export const getApiColor = async () => {
  var result = await getUsers(
    `${URLAPIS}configuraciongeneral/get_id/1/`
  );
  try {
    let paleta = {
      //Color de los botones deshabilitados
      action: {
        disabledBackground: "", // don't set the disable background color
        disabled: "#fff", // set the disable foreground color
      },
      primary: {
        main: result.SCG_primaryColor,
        dark: result.SCG_secundaryColor,
        contrastText: "#fff",
      },
      secondary: {
        main: result.SCG_secundaryColor,
        dark: result.SCG_secundaryColor,
        contrastText: "#fff",
      },
      neutral: {
        main: result.SCG_neutralColor,
      },
      three: {
        main: "#DFDADC",
        contrastText: secundario,
      },
      gray: {
        main: "#aaaaaa",
        contrastText: "#fff",
      },
      white: {
        main: "#ffffff",
        dark: "#304766",
        contrastText: "#fff",
      },

      icons: {
        main: result.SCG_secundaryColor,
        dark: result.SCG_primaryColor,
        contrastText: "#fdff",
        light: "#ffff",
        disabled: "black",
      },
    };
    return JSON.stringify(paleta);
  } catch (e) {
    let paleta = {
      action: {
        disabledBackground: "", // don't set the disable background color
        disabled: "#fff", // set the disable foreground color
      },
      primary: {
        main: principal,
        dark: "#a94d52",
        contrastText: "#fff",
      },
      secondary: {
        main: secundario,
        dark: "#304766",
        contrastText: "#fff",
      },
      neutral: {
        main: "#fff",
      },
      three: {
        main: "#DFDADC",
        contrastText: secundario,
      },
      gray: {
        main: "#aaaaaa",
        contrastText: "#fff",
      },
      white: {
        main: "#ffffff",
        dark: "#304766",
        contrastText: "#fff",
      },

      icons: {
        main: "#304766",
        dark: principal,
        contrastText: "#fdff",
        light: "#ffff",
        disabled: "black",
      },
    };
    return JSON.stringify(paleta);
  }
};
