import React, { useState, useEffect } from 'react'
import './Sty.css';
import Box from '@mui/material/Box';
//tabla
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

//modal ventstana
import Modal from '@mui/material/Modal';

//radio
import RadioDinamico from '../../../../Tools/RadioButton/RadioDinamico';

//checkbox
import Check2 from '../../../../Tools/CheckBox/Check2';

//texto
import PasswordTXTField from '../../../../Tools/TextField/TextFieldPass';
import TextField from '../../../../Tools/TextField/TextField';

//botones
import ButtonVino from '../../../../Tools/Bottones/ButtonVino';

//dialog
import DialogButton from '../../../../Tools/Dialog/DialogButton';

//idioma
import {FormattedMessage} from 'react-intl';

//
import { getDataToken } from '../../../../../api/dataToken';
import { BASE_API } from '../../../../../utils/constans';
import { getToken } from '../../../../../api/token';


const style = {//modal emergente
  position:'absolute',
  overflow:'scroll',
  overheight:'scroll',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  width:'98%' ,
  height:'90%',
 
  };


  const arrayEstatus = [
    {'id': '1', 'value': 'True' , 'label':'Activo'},
    {'id': '2', 'value': 'False', 'label':'Inactivo'},

]

const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

const isPassword = (password) =>
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/.test(password);


export function Modales(){
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    userName: "",
    lastName: "",
    maternalSurname: "",
    estatuss: "",
    confgeneral:"True"
  });

  const [emailExits, setEmailExits] = React.useState({});
  const [errorsExits, setErrorsExits] = React.useState({});
  const [errorsTitle, setErrorsTitle] = React.useState({});
  const [Successful, setSuccessful] = React.useState({});
  const [link, setLink] = React.useState({});

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  // Validamos en la api si existe un asuario con  el correo

  const token = getToken();


  // const [dataX, setDataX] = React.useState([])

  // const getDataPermisos = async () => {
  
  //   //Resultados de la api investigacion
  //   var resultPermisos = await getDataToken("login/get_correo/"+  localStorage.getItem("SNYTLOG_email") + "/");    
  //   setDataX(resultPermisos);
    
  // }
  
  // useEffect(() => {
  //   getDataPermisos()  
  // }, [])


  fetch(`${BASE_API}login/get_listado_admin/`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${token}`
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        return response.json();
      }
    })
    .then((data) => {
      setSuccessful(true);
     
    })
    .catch((error) => {
      console.error("There was an error:", error);
    });

 

  fetch(`${BASE_API}login/get_correo/${values.email}/`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${token}`
    },
  })
    .then((response) => response.json())
    .then((json) => {
      // console.log(json);
      setEmailExits(json.SNYTLOG_email);
    });

  

    //activacion de modal
const [open, setOpen] = React.useState(false);
const handleOpen = () => setOpen(true);
const handleClose = () =>{
  //window.location.reload(false);
  setOpen(false);}

 //chekeds

 const [valordef,setvalordef  ] = React.useState({
  isconfgenview: false,
  isconfgenadd: false,
  isconfgenchan: false,

  isviewadmin: false,
  isaddadmin: false,
  ischangeadmin :false,

  isviewemail: false,
  isaddemail: false,
  ischangeemail:false,

  isviewconta: false,
  isaddconta: false,
  ischangeconta:false,

  isviewcatalogo: false,
  isaddcatalogo: false,
  ischangecatalogo:false,

  isviewsecurity: false,
  isaddsecurity: false,
  ischangesecurity:false,

  isviewnegocio: false,
  isaddnegocio: false,
  ischangenegocio:false,




});



  // eventosdeloscheck

  var viewconf,addconf,chanconf,viewcorreo,addcorreo,chancorreo,viewadmin,addadmin,chanadmin = 0
  
//admin
  const handleviewadmin = (event) => {
  (valordef.isviewadmin) = (event.target.checked);
  
  };
  const handleaddadmin = (event) => {
    (valordef.isaddadmin) = (event.target.checked);
  };
  const handlechanadmin = (event) => {
    (valordef.ischangeadmin) = (event.target.checked);
  };
//confgeneral
const handleviewconf= (event) => {
  (valordef.isconfgenview) = (event.target.checked);
  console.log(valordef.isconfgenview)};
  
const handleaddconf = (event) => {
  (valordef.isconfgenadd) = (event.target.checked);
};
const handlechanconf = (event) => {
  (valordef.isconfgenchan) = (event.target.checked);
};
//email
const handleviewemail= (event) => {
  (valordef.isviewadmin) = (event.target.checked);
 };
 const handleaddemail = (event) => {
   (valordef.isaddemail) = (event.target.checked);
 };
 const handlechanemail = (event) => {
   (valordef.ischangeadmin) = (event.target.checked);
 };



  


//Validaciones de acuerdo al TCH100

console.log(valordef.isconfgenview)

const validateAndSubmitForm = (e) => {
  e.preventDefault();
  const errorsExits = {};
  const errorsTitle = {};
  const link = {};

  errorsTitle.email = "Fuera de servicio";
  errorsExits.email ="No es posible realizar su petición en este momento, intente más tarde.";

  //confgeneral
  if(valordef.isconfgenview=== true){viewconf = 21}else{viewconf =1 }
  if(valordef.isconfgenadd === true){addconf = 20}else{addconf = 1}
  if(valordef.isconfgenchan === true){chanconf = 22}else{chanconf = 1}
  //email
  if(valordef.isviewcorreo === true){viewcorreo = 23}else{viewcorreo = 1 }
  if(valordef.isaddcorreo === true){addcorreo = 24}else{addcorreo = 1}
  if(valordef.ischangerec === true){chancorreo = 25}else{chancorreo = 1}
  //admin
  if(valordef.isviewadmin === true){viewadmin = 26}else{viewadmin =1 }
  if(valordef.isaddadmin === true){addadmin = 27}else{addadmin = 1}
  if(valordef.ischangeadmin === true){chanadmin = 28}else{chanadmin = 1}

 


  if (!isEmail(values.email)) {
    errorsTitle.email = (
      <FormattedMessage id="globCorreo" defaultMessage="Correo inválido" />
    );
    errorsExits.email = (
      <FormattedMessage
        id="globElCorr"
        defaultMessage="El correo no cumple con el formato."
      />
    );
    //errors.email = "El correo no cumple con el formato";
  } else if (!isPassword(values.password)) {
    errorsTitle.email = (
      <FormattedMessage
        id="globContrasenaInorre"
        defaultMessage="Contraseña incorrecta"
      />
    );
    errorsExits.email = (
      <FormattedMessage
        id="globLaContrasenaNoCu"
        defaultMessage="La contraseña no cumple con el formato."
      />
    );
    //errorsPassword.password = "La contraseña no cumple con el formato";
  } else if (emailExits === values.email) {
    errorsTitle.email = (
      <FormattedMessage
        id="globUsuarioexistente"
        defaultMessage="Usuario existente"
      />
    );
    errorsExits.email = (
      <FormattedMessage
        id="globYaExisteunaCuent"
        defaultMessage="Ya existe una cuenta con los datos ingresados."
      />
    );
  } else if (emailExits !== values.email && Successful === true)

  {
    // POST request using fetch with error handling
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        SNYTLOG_email: values.email,
        password: values.password,
        SNYTLOG_username: values.userName,
        SNYTLOG_confgeneral: true,
        SNYTLOG_surnamedad: values.lastName,
        SNYTLOG_surnamemom: values.maternalSurname,
        SNYTLOG_estatus:values.estatuss,
        is_staff: values.estatuss,
        
        //groups:[viewconf,addconf,chanconf,viewcorreo,addcorreo,chancorreo,viewemail,addemail,chanemail]
      }),
    };
    fetch(`${BASE_API}login/register/`, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    errorsTitle.email = (
      <FormattedMessage
        id="globUsuarioCreadoExi"
        defaultMessage="Usuario creado exitosamente"
      />
    );
    errorsExits.email = (
      <>
        <FormattedMessage
          id="globSeRegi"
          defaultMessage="Se registraron los datos correctamente."
        />
        <br />
        <FormattedMessage
          id="globIniciaSesionPara"
          defaultMessage="Inicia sesión para continuar."
        />
      </>
    );

    fetch(`${BASE_API}login/putall/`+values.email+ "/", {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        SNYTLOG_email:values.email,
        groups:[viewconf,addconf,chanconf,viewcorreo,addcorreo,chancorreo,viewadmin,addadmin,chanadmin]
        })    
    })

    link.email = "/rolespermisos";

  }
  setErrorsExits(errorsExits);
  setErrorsTitle(errorsTitle);
  setLink(link);

};




    return (
      <form >
        <div>
      <ButtonVino onClick={handleOpen} label="Registro"> disabled={false}</ButtonVino>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}  >
        <div class="ventstanasa">
            <div class="vents1">{/*btbreadcrumbs*/}
            <h11-title-modal-admin>
            <FormattedMessage id="adminAlUsu" defaultMessage="Alta de usuario"/>
              </h11-title-modal-admin></div>
            <div class="vents2"> {/*txtfield*/}
            <TextField   label={<FormattedMessage id="datosNombs" defaultMessage="Nombre(s)"/> }     onChange={handleChange("userName")}  value={values.userName}  >   </TextField>
          
            </div>
            <div class="vents3"> {/*txtfield*/}
            <TextField  label={<FormattedMessage id="datosApPat" defaultMessage="Apellido paterno"/>}     onChange={handleChange("lastName")}  value={values.lastName} />
            </div>
            <div class="vents4"> {/*txtfield*/}
            <TextField id="mat" label={<FormattedMessage id="datosApMasst" defaultMessage="Apellido Materno"/>}     onChange={handleChange("maternalSurname")}  value={values.maternalSurname} />
            </div>
            <div class="vents5">{/*txtfield*/}
            <TextField  
            label={<FormattedMessage id="loginCorEm" defaultMessage="Correo electrónico empresarial"/> } id="email" variant="standard"    value={values.email}  onChange={handleChange("email")}/>
              </div>
            <div class="vents6"> {/*txtfield*/}
            <PasswordTXTField   label={<FormattedMessage id="globCurp" defaultMessage="Contraseña"/> }   onChange={handleChange("password")} value={values.password} variant="standard"  /> 
            
            </div>
            <div class="vents7"> {/*radio*/}
            <RadioDinamico id='rb-estatus' 
            row={true} 
            array={arrayEstatus} 
            defaultValue={values.estatus ? "1": "2"}
            formLabel={<FormattedMessage id="globEstatu" defaultMessage="Estatus"/>} 
            onChange={handleChange("estatuss")}
            />
            </div>
            <div class="vents8"> {/*titulo*/}
            <hr/>
            <br/>
            <h11-title-modal-admin>
            <FormattedMessage id="adminRoPer" defaultMessage="Roles y Permisos"/>
            </h11-title-modal-admin>
            </div>
            <div class="vents9"> {/*tabla*/}
            <br/>
            <Table aria-label="simple table" >
        <TableHead style={{color:'white',backgroundColor:'#00203B'}} >
          <TableRow >
            <TableCell style={{color:'white'}} ><FormattedMessage id="globConsul" defaultMessage="Consultar"/> <br/></TableCell>
            <TableCell style={{color:'white'}} align="center">
            <FormattedMessage
                id="Mod-ConfGen-Title1"
                defaultMessage="Administración de usuarios"
              /><br/>
            {/* viewadminusuarios */}
            <Check2
              defaultChecked={valordef.isviewadmin}
              onChange={(e) =>handleviewadmin(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
            <TableCell style={{color:'white'}} align="center">
            <FormattedMessage
                id="Mod-ConfGen-Title2"
                defaultMessage="Configuración general"
              />
            {/* view confgeneral*/}
            <Check2
              defaultChecked={valordef.isconfgenview}
              onChange={(e) =>handleviewconf(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
            </TableCell>
            <TableCell style={{color:'white'}} align="center">
            <FormattedMessage
                id="Mod-ConfGen-Title4"
                defaultMessage="Catálogo"
              />
            {/* view catalogo */}
            <Check2
              //defaultChecked={valordef.isviewdict}
              //onChange={(e) =>handleviewDictamen(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
            </TableCell>
            {/* viewcontabilidad */}
            <TableCell style={{color:'white'}} align="center">
            <FormattedMessage
            id="Mod-ConfGen-Title3-Subtitle2"
            defaultMessage="Contabilidad SINAY"
          />
            <Check2
              //checked={valordef.medview}
              //defaultChecked={valordef.isconfgenview}
              //onChange={(e) => handleviewMedico(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
            {/* seguridad */}
            <TableCell style={{color:'white'}} align="center">
            <FormattedMessage
                id="Mod-ConfGen-Title5"
                defaultMessage="Seguridad"
              />
            <Check2
             //checked={isviewinve}
              //defaultChecked={valordef.isviewinve}
              //onChange={(e) => handleviewInves(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
            <TableCell style={{color:'white'}} align="center">
            <FormattedMessage id="glob.correoelec" defaultMessage="Correo" />  
            {/* viewcorreo*/}
            <Check2
              defaultChecked={valordef.isviewemail}
              onChange={(e) => handleviewemail(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            />
            </TableCell>
            {/* viewnegocio */}
            <TableCell style={{color:'white'}} align="center">
            <FormattedMessage
                id="Mod-ConfGen-Title7"
                defaultMessage="Negocio"
              />
            <Check2
              //defaultChecked={valordef.isviewestcon}
              //onChange={(e) => handleviewEstCon(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow>
              <TableCell component="th" scope="row">
              <FormattedMessage id="aGRE" defaultMessage="Agregar"/>
              </TableCell>
              <TableCell align="center"> 
              {/* agregaradmin */}
              <Check2
              defaultChecked={valordef.isaddadmin}
              onChange={(e) => handleaddadmin(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center"> 
              {/* agregarconfgeneral */}
              <Check2
              defaultChecked={valordef.isconfgenadd}
              onChange={(e) => handleaddconf(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center"> 
              {/* agregarcatalogo */}
              <Check2
              //defaultChecked={valordef.isadddict}
              //onChange={(e) => handleaddDictamen(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
            {/* contabilidadagregar */}
              <TableCell align="center"> 
              <Check2
              
              //defaultChecked={valordef.isconfgenadd}
              //onChange={(e) => handleaddMedico(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center"> 
              {/* seguridad */}
              <Check2
              //defaultChecked={valordef.isaddinve}
              //onChange={(e) => handleaddInves(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center"> 
              {/* correoagregar */}
              <Check2
              defaultChecked={valordef.isaddemail}
              onChange={(e) =>handleaddemail(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center">
              {/* negocio*/}
              <Check2
             // defaultChecked={valordef.isaddnegocio}
             // onChange={(e) =>handleadd(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" >
              <FormattedMessage id="adminEdici" defaultMessage="Edición"/>
              </TableCell>
              <TableCell align="center"> 
              {/* edicionadminusuarios */}
              <Check2
              defaultChecked={valordef.ischangeadmin}
              onChange={(e) =>handlechanadmin(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center"> 
              {/* edicionconfgeneral*/}
              <Check2
              defaultChecked={valordef.isconfgenchan}
              onChange={(e) =>handlechanconf(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center"> 
              {/* edicioncatalogo */}
              <Check2
              //defaultChecked={valordef.ischangedict}
              //onChange={(e) =>handlechangeDictamen(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
            {/* editarcontabilidad */}
              <TableCell align="center"> 
              <Check2
              //defaultChecked={valordef.isconfgenchan}
              //onChange={(e) =>handlechangeMedico(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center"> 
              {/* edicionseguridad */}
              <Check2
              //defaultChecked={valordef.ischangeinve}
              //onChange={(e) =>handlechangeInves(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center"> 
              {/* edicioncorreo*/}
              <Check2
              defaultChecked={valordef.ischangeemail}
              onChange={(e) =>handlechanemail(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
              <TableCell align="center"> 
              {/* edicionnegocio */}
              <Check2
              //checked={ischangeestcon}
              //defaultChecked={valordef.ischangeestcon}
              //onChange={(e) =>handlechangeEstCon(e)}
              option1={<FormattedMessage id="globNo" defaultMessage="No" />}
              option2={<FormattedMessage id="globSi" defaultMessage="Si" />}
            /></TableCell>
            </TableRow>
        </TableBody>
      </Table>           
            </div>
            
            <div class="vents10">{/*btcancelar*/}
            <br></br>
            <ButtonVino onClick={handleClose} label={<FormattedMessage id="globCance" defaultMessage="Cancelar"/>}/>
            </div>
            <div class="vents11">{/*btaceptar*/}
            <br></br>
            <form onClick={validateAndSubmitForm}>
            <DialogButton
                          mainButton={
                            <FormattedMessage
                              id="loginRegte"
                              defaultMessage="Registrate"
                            />
                          }  
                          title={errorsTitle.email}
                          description={errorsExits.email}
                          button={
                            <FormattedMessage
                              id="globAceptr"
                              defaultMessage="Aceptar"
                            />
                          
                          }
                         
                          type="submit"
                          to={link.email}
                          estatus={
                            values.email.length > 0 &&
                            values.password.length > 0 &&
                            values.userName.length > 0 &&
                            values.lastName.length > 0 &&
                            values.estatuss.length > 0
                              ? false
                              : true
                          }
                          
                        /> 
                    </form>
            </div>
        </div>
        </Box>
      </Modal>

        </div>

        </form>
  );
};



export default Modales;