import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper"

import { getToken } from "../../../../api/token";
import { BASE_API } from "../../../../utils/constans";
import Grid from "@material-ui/core/Grid";


/* ESTILOS DE LA TABLA */
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00203b",

    padding: 0,
    paddingLeft: "5px",
    height: "65px",
    color: theme.palette.common.white,
    fontSize: "20px",
    borderColor: "#e2e2e2",
    border: "1px solid #e2e2e2",
    fontFamily: "Avenir-Light",
  },
  [`&.${tableCellClasses.body}`]: {
    height: "20px",
    padding: 0,
    fontSize: "16px",
    borderColor: "#e2e2e2",
    border: "1px solid #e2e2e2",
    fontFamily: "Avenir-Light",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    //backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    //border: 0,
  },
}));

export default function TableTotalInvestigador({ folios }) {
  // console.log("PAso de fechas x3", folios);
  const [inFolio, setFolios] = useState(folios);
  useEffect(() => {
    setFolios(folios);
  }, [folios]);

  const filteredFolios = folios.filter(
    (row) => row.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_idwfmodulo === 5
  );

  const uniqueinvestigador = [
    ...new Set(filteredFolios.map((row) => row.POL_idpoliza?.POL_numpoliza)),
  ];

  const metricaColor = folio => {
    const estaEnOtroModulo = folio.WTM_idwftareasxmodulos?.WFM_idwfmodulo?.WFM_idwfmodulo !== 5;
    return estaEnOtroModulo ? 'verde' : 'rojo';
  };

  return (
    <div>
      <Grid container>
        <h3-header-card>
          Investigador
        </h3-header-card>
      </Grid>

      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }} style={{ position: 'sticky' }} >
          <Table stickyHeader aria-label="sticky table" >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">
                  Polizas
                </StyledTableCell>
                <StyledTableCell align="center">
                  Estatus
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uniqueinvestigador.map((row) => (
                <StyledTableRow key={row}>
                  <StyledTableCell align="center">
                    {row}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div style={{ width: 20, height: 20, borderRadius: '50%', backgroundColor: metricaColor(row) === 'verde' ? 'green' : 'red' }}></div>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );



















}
