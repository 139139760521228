import { Grid } from "@material-ui/core";
import Reac, {useState} from "react"
//importaciones para la grafica
import { Bar } from 'react-chartjs-2';
//import para la tabla
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StyledTableCell from "../../Tools/Table/StyledTableCell";


const GraficoHomicidio = ()=>{
//Creación de las medidas para las estadisticas
//Le asignamos los valores necesarioa a la variable de chartData
    const [chartData, setChartData] = useState({
        labels: ['Pascional', 'Ejecución', 'Asalto residencial', 'Asalto vía pública', 'Secuestro Previo'],//asignamos los valores por los que se clasificara la grafica
        datasets: [
          {
            label: 'Fallecimientos por homicidios',//Este es el titulo que aparecera en la grafica
            data: [14, 21, 13, 12, 10],//asignamos los valores en un arreglo
            //Podemos darle diferentes colores a las tablas
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1,
          },
        ],
      });

//Creación de la tabla
function createData(homicidio, numero) {
    return { homicidio, numero };
  }
  const rows = [
    createData("Pascional", "14"),
    createData("Ejecución", "21"),
    createData("Asalto residencial", "13"),
    createData("Asalto vía pública", "12"),
    createData("Secuestro previo", "10")
  ];
  const [data, setData] = useState(rows);
  
return (
    <>
    <Grid container>
      {/* En esta parte aparecen los datos en una tabla */}
        <Grid xs={12} align="center">
        <Paper sx={{ width: "50%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Tipo de Homicidio</StyledTableCell>
                <StyledTableCell align="center"> Cantidad </StyledTableCell>                
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => {
                return (
                  <TableRow>
                    <StyledTableCell align="center">{row.homicidio}</StyledTableCell>
                    <StyledTableCell align="center">{row.numero}</StyledTableCell>                    
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
        </Grid>
        <Grid xs={12}>
          {/* Aquí llamamos la funcion de la tabla y le pasamos el arreglo que 
          lleva la información que queremos plasmar en la grafica */}
        <Bar data={chartData} />            
        </Grid>

    </Grid>
    </>
)
}
export default GraficoHomicidio;