import { getDataToken } from "./dataToken";
import { useState, useEffect } from "react";

function GetTareas(pIdModulo) {
  const [modulosFiltrados, setModulosFiltrados] = useState([]);

  const getTareasxModulo = async () => {
    try {
      const result = await getDataToken(`wftareaspormodulos/get_listado/`);
      setModulosFiltrados(
        result.filter(
          (modulo) => modulo.WFM_idwfmodulo.WFM_idwfmodulo === pIdModulo
        )
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getTareasxModulo();
  }, []);

  return modulosFiltrados;
}

export default GetTareas;
