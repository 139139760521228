import { ClientLayaout } from '../../layouts/Client/ClientLayaout';
// links de comp gestor
import { GestorComp } from '../../pages/Client/GestorComp/GestorComp'; 


const routesClient = [
    {
        path: "/gestion_competencia",
        layout: ClientLayaout,
        component: GestorComp,
        exact: true,
    },
];

export default routesClient;