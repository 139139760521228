import * as React from "react";
import { useState, useEffect } from "react";

import Recorrido from "../../Tools/BreadCrumbs/Recorrido";

import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TableCSV from "./Tables/tableCsv";
import SeccionTheme from "./Tables/colorPickers";
import General from "./Tables/generaltab";
import GestorArch from "./Elements/GestArch";
import { FormattedMessage } from "react-intl";
import DatePicker2 from "../../Tools/DatePicker/DatePicker2";
import { diasMaxTramite } from "../../../api/getUmbrales";
import {
  umbralUrgente,
  umbralATiempo,
  umbralPorTeminar,
  umbralNuevo,
} from "../../../api/getUmbrales"
import BdConfig from "./Elements/BdConfig"

// umbralTerminado: '#C20000'; //Rojo fuerte
// umbralCancelado: '#FF2919'; //Rojo claro
// umbralPorVencer: '#FF8000'; //Naranja
// umbralEnProceso: '#FFE400'; //Amarillo
// umbralATiempo:   '#58E308'; //verde
// umbralNuevo:     '#12B3EF'; //Azul

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function ConfGeneral() {
  // let poliza = {
  //   POL_numpoliza: "987654321",
  //   POL_polizapadre: "123456789",
  //   POL_polizapahasta: "2023-03-14",
  //   POL_polizaexprima: "1234",
  //   POL_polizafoinversión: "1234",
  //   POL_polizapréstamo: "1234",
  //   POL_polizarematemática: "1234",
  //   POL_polizafallecimiento: "1234",
  //   POL_polizatireaseguro: true,
  //   POL_polizareasegurador: "1234",
  //   POL_polizareaseguro: "1234",
  //   POL_polizaporreaseguro: 1234.0,
  //   POL_polizaprima: "1234",
  //   POL_polizafinvigencia: "2023-03-14",
  //   POL_polizafecemisión: "2023-03-14",
  //   POL_polizafecpago: "2023-03-14",
  //   POL_polizagrupo: true,
  //   POL_activo: true,
  //   POL_actualizofecha: "2023-03-14T19:53:08-06:00",
  //   POL_actualizousuario: "DANNY",

  //   PRA_idpoliza: 7,
  //   MON_idmonedas: 2,
  //   EST_idestatuspoliza: 1,
  //   CHN_idcanales: 9,
  //   OF_idoficinas: 2,
  //   FPAG_idformasdepago: 4,
  //   PRO_idproductos: "1",
  // };

  // const [anidado, setAnidado] = useState([]);

  // //TODO: GET
  // const getData = async () => {
  //   try {
  //     await getDataToken("polizas/get_id/29/").then((data) => setAnidado(data));
  // console.log(anidado.EST_idestatuspoliza?.EST_actualizousuario)
  //   console.log(anidado.OF_idoficinas?.OF_oficina)
  //   } catch (error) {
  //     console.log(error);
  //     throw error;
  //   }
  // };
  // useEffect(() => {
  //   getData();
  // }, []);

  //TODO: POST
  // async function guardarDatos(aux) {
  //   try {
  //     var result = await postDataToken(
  //       "configuraciongeneral/get_listado/",
  //       aux
  //     );
  //     console.log("Guardado satisfactoriamente", result);
  //   } catch (error) {
  //     console.log(error);
  //     throw error;
  //   }
  // }

  //TODO: PUT
  // async function actualizarDatos(aux) {
  //   try {
  //     var result = await putDataToken("configuraciongeneral/get_id/1/", aux);
  //     console.log("Actualizado satisfactoriamente", result);
  //   } catch (error) {
  //     console.log(error);
  //     throw error;
  //   }
  // }

  //! =======> Variables don't touch
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //! =======> Variables don't touch

  //TODO: Tipo de Cambio - Banco de Mexico
  const [date, setDate] = useState(new Date());
  const [dollarExchangeRate, setDollarExchangeRate] = useState(null);
  useEffect(() => {
    const options = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const locale = "es-MX";
    const formattedDate = new Date().toLocaleDateString(locale, options);
    setDate(formattedDate);
    fetch(
      "https://www.banxico.org.mx/SieAPIRest/service/v1/series/SF43718/datos/oportuno?token=79bd278e1e022a8ffd0649b604c0f6ee03f03f4c366b08302556c36df8f34979"
    )
      .then((response) => response.json())
      .then((data) => setDollarExchangeRate(data.bmx.series[0].datos[0].dato))
      .catch((error) => console.error(error));
  }, []);

  const [selectedDate, setSelectedDate] = useState("");

  //TODO: Return
  return (
    <div className="div-parent">
      <div
        style={{
          float: "right",
          textAlign: "right",
        }}
      >
        <Typography>
          <FormattedMessage
            id="conGDoPes"
            defaultMessage="Dolar/Peso Mexicano"
          />{" "}
          - {` ${date}`}
        </Typography>
        {dollarExchangeRate ? (
          <Typography
            style={{ fontWeight: "bold" }}
          >{`1 USD = ${dollarExchangeRate} MXN`}</Typography>
        ) : (
          <Typography>
            <FormattedMessage
              id="loginCarga"
              defaultMessage="Cargando........"
            />
          </Typography>
        )}
      </div>
      <br />
      <div className="div-breadcrumbs">
        <Recorrido
          name1="Inicio"
          enlace="/confadmin"
          name2={
            <FormattedMessage
              id="conGeneral"
              defaultMessage="Configuración General"
            />
          }
        />
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
          >
            <Tab
              label={
                <FormattedMessage
                  id="globConfi"
                  defaultMessage="Configuración*"
                />
              }
              {...a11yProps(0)}
            />
            <Tab label="|" disabled />
            <Tab
              label={<FormattedMessage id="globTema" defaultMessage="Tema" />}
              {...a11yProps(1)}
            />
            <Tab label="|" disabled />
            <Tab
              label={
                <FormattedMessage
                  id="conGCarD"
                  defaultMessage="Carga de datos"
                />
              }
              {...a11yProps(2)}
            />
            <Tab label="|" disabled />
            <Tab label="Gestor de Archivos" {...a11yProps(3)}/>
            <Tab label="|" disabled />
            <Tab label="BD Config" {...a11yProps(4)}/>
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div>
            <General />
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          {/* Item Two
          <h1>
            text:
            {anidado.EST_idestatuspoliza?.EST_actualizousuario}
          </h1> */}
          <div>
            <SeccionTheme />
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div>
             <TableCSV />
          </div>
        </TabPanel>
          <TabPanel value={value} index={6}>
            <div>
             <GestorArch/>
             </div>
          </TabPanel>
          <TabPanel value={value} index={8}>
            <div>
             <BdConfig/>
            </div>
          </TabPanel>
      </Box>
    </div>
  );
}
