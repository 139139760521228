/* 
David Ruiz
Proyecto SINAY 
11/10/2022
*/

import * as React from "react";
import dayjs from "dayjs";
import "dayjs/locale/es";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { formatofecha } from "../../../../api/getUmbrales";

const Date = (props) => {
  const [value, setValue] = React.useState(dayjs(props.fechaSiniestro));
  const [max] = React.useState(dayjs(props.fecha));
  dayjs.locale("es");

  //  const handleDateChange = (date) => {
  //   var fecha =date.toISOString(date).slice(0, 10);
  //   console.log(fecha);
  //     setValue(date);
  //   };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Stack
        style={{
          background: "white",
        }}
      >
        <DesktopDatePicker
          views={["day", "month", "year"]}
          inputFormat={formatofecha}
          maxDate={max}
          value={value}
          id="date"
          size="medium"
          label={props.titulo}
          backgroundColor="red"
          disabled={props.disabled}
          onChange={(newValue) => {
            setValue(newValue);
            props.onDataChange(newValue);
          }}
          // {handleDateChange}

          renderInput={(params) => (
            <TextField
              {...params}
              helperText={null}
              id={props.id}
              name={props.name}
              required={props.required}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
};
export default Date;
