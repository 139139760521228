import React from "react";
import { Confmenu } from '../../../components/GeneralConf/MenuConf/ConfMenu';

export function conf() {
    return (
        <div>
          
            <Confmenu />
            
        </div>
    );
}