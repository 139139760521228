import * as React from "react";
import Grid from "@mui/material/Grid";
import { DATA_PM } from "../../../../utils/constansPM";
import { FormattedMessage } from "react-intl";

export function TabDatosReservas(props) {
  const dataPoliza = props.dataPoliza;
  console.log(dataPoliza);

  return (
    <div style={{ padding: "5px", width: "900px" }}>
      {dataPoliza[0]?.POL_idpoliza?.POL_idpoliza ? (
        <table>
          <thead>
            <tr>
              <th align="center">
                <strong>
                  <FormattedMessage
                    id="regisIDRea"
                    defaultMessage="ID Reaseguradora"
                  />
                </strong>
              </th>
              <th align="center">
                <strong>
                  <FormattedMessage
                    id="regisPorRea"
                    defaultMessage="Porcentaje reaseguro"
                  />
                </strong>
              </th>
              <th align="center">
                <strong>
                  <FormattedMessage
                    id="regisImpRea"
                    defaultMessage="Importe reaseguro"
                  />
                </strong>
              </th>
              <th align="center">
                <strong>
                  <FormattedMessage
                    id="regisIDMoGi"
                    defaultMessage="ID Moneda Giro"
                  />
                </strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {dataPoliza?.map((row) => (
              <tr key={row?.POL_idpoliza?.POL_idpoliza}>
                <td
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    width: "250px",
                  }}
                >
                  {row?.REA_idreaseguradora?.REA_idreaseguradora}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    width: "250px",
                  }}
                >
                  {row?.PRA_porcentaje}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    width: "250px",
                  }}
                >
                  {row?.PRA_importe}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    width: "250px",
                  }}
                >
                  {row?.MON_idmonedagiroimporte?.MON_idmoneda}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div style={{ textAlign: "center" }}>
              <strong>
                <FormattedMessage
                  id="regisIDRea"
                  defaultMessage="ID Reaseguradora"
                />
              </strong>
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_REASEGURADORA_1]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_REASEGURADORA_2]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_REASEGURADORA_3]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_REASEGURADORA_4]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_REASEGURADORA_5]}
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ textAlign: "center" }}>
              <strong>
                <FormattedMessage
                  id="regisPorRea"
                  defaultMessage="Porcentaje reaseguro"
                />
              </strong>
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.PORCENTAJE_REASEGURO_1]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.PORCENTAJE_REASEGURO_2]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.PORCENTAJE_REASEGURO_3]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.PORCENTAJE_REASEGURO_4]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.PORCENTAJE_REASEGURO_5]}
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ textAlign: "center" }}>
              <strong>
                <FormattedMessage
                  id="regisImpRea"
                  defaultMessage="Importe reaseguro"
                />
              </strong>
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.IMPORTE_REASEGURO_1]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.IMPORTE_REASEGURO_2]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.IMPORTE_REASEGURO_3]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.IMPORTE_REASEGURO_4]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.IMPORTE_REASEGURO_5]}
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ textAlign: "center" }}>
              <strong>
                <FormattedMessage
                  id="regisIDMoGi"
                  defaultMessage="ID Moneda Giro"
                />
              </strong>
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_MONEDA_REASEGURO_1]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_MONEDA_REASEGURO_2]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_MONEDA_REASEGURO_3]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_MONEDA_REASEGURO_4]}
              <br />
              <br />
              {dataPoliza === "" || dataPoliza === undefined
                ? ""
                : dataPoliza[DATA_PM.ID_MONEDA_REASEGURO_5]}
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
