import React, { Component } from "react";
import  CompInvestigador  from '../../../components/Investigador/CompInvestigador';
import { FormattedMessage } from "react-intl";
import Box from "@mui/material/Box";

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   bgcolor: "background.paper",
};
class MyComponent extends Component {
   constructor(props) {
     super(props);
     this.state = {
       isComponentRendered: false,
    };
   }

   componentDidMount() {
     setTimeout(() => {
       this.setState({ isComponentRendered: true });
    }, 2000);
   }

   render() {
     const { isComponentRendered } = this.state;
     if (!isComponentRendered) {
       return (
         <Box sx={style}>
           <div>

            <h1-dialog-title>
                <FormattedMessage id="loginCarga" defaultMessage="Cargando........"/>
            </h1-dialog-title>

            <h2-dialog-description>
                <FormattedMessage id="espMom" defaultMessage="Recuerda revisar los nuevos casos."/>
            </h2-dialog-description>

           </div>
         </Box>
       );
    }
//     // Si el componente se renderiza dentro de los 2 segundos, se devuelve el contenido del componente.
    return (
       <div>
        <CompInvestigador/>
       </div>
        );
    }
}

export default MyComponent;
export function Investigador() {
   return (
    <div>
       <MyComponent/>
    </div>
   );
}
