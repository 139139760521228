import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function RadioDinamico(props) {
  /*Este radio group recibirá como props un id para identificar el radioGroup
    y un arreglo, que a su vez tendrá como atributos el valor del id, 
    value y label correspondiente a cada radio
    Ejemplo de array: 
    array=[{"id":1, "value": "1", "label":"Manzana"},{"id":2, "value": "2", "label": "Plátano"} ]
    */
  const array =props.array
  return (
    <FormControl >
      <FormLabel id="demo-radio-buttons-group-label" >{props.formLabel}</FormLabel>
      <RadioGroup
       /* Este prop recibirá un true si se quiere ver los rb horizontales
       su valor por default está en falso, por tanto, estará en vertical si no recibe la prop.
       */
       
        row={props.row}
        aria-labelledby="demo-radio-buttons-group-label"
        /* Se agrega como propiedad el name para en casos de que se requiera usar
        más de una vez por pantalla los Radios Groups, no interfieran cada uno con su ejecución*/
        name={props.name}
        color={props.color}   
        id={props.id}
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={props.onChange} 
      >
        {/* Este array map renderizará la cantidad de radios equivalemte al número de objetos dentro del 
        array que recibe como prop, otorgandole a cada uno su id, valor y label correspondiente*/}
        {array.map( rb => <FormControlLabel id={rb.id} key={rb.id} value={rb.value} control={<Radio />} label={ <h3-subtitle-card>{rb.label}</h3-subtitle-card>} style={{paddingLeft:'30px',paddingRight:'30px'}} labelPlacement={props.labelPlacement}/>)}
      </RadioGroup>
    </FormControl>
  );
}