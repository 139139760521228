import React from "react";
import { CompRecuperarContra } from "../../../components/Login/CompRecuperarContra";


export function RecuperarContra() {
  return (
    <div>
  
      <CompRecuperarContra />
  
    </div>
  );
}
