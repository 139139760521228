//Brandon Hernandez 12/2022
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

/* props.tooltipTitle es para colocar el titulo de la ayuda al pasar por encima del ícono.*/
const IconAsignarInvestigador = (props) => {
  return (
    <Tooltip title={props.tooltipTitle}>
      <Link to={props.to} state={props.state} color="icons">
        <IconButton
          color="icons"
          disabled={props.disabled}
          onClick={props.onClick}
          onChange={props.onChange}
          id={props.id}
        >
          <PersonSearchIcon color="icons" />
        </IconButton>
      </Link>
    </Tooltip>
  )
    ;
}
export default IconAsignarInvestigador;