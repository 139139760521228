import * as React from "react";
import { useState, useEffect } from "react";
import { BASE_API_SINSLSH } from "../../../utils/constans";
import { Box, Badge, Stack, Grid, Link } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import "../../../css/Sty.css";
import ModalesNoti from "../../Admin/Elements/Modal/ModalNoti";
//idioma
import { FormattedMessage } from "react-intl";
import { useAuth } from "../../../hooks/useAuth";

import { getDataToken } from "../../../api/dataToken";

export default function NavAppBar() {
  const [datos, setDatos] = useState([]);

  const getData = async () => {
    try {
      var result = await getDataToken("configuraciongeneral/get_id/1/");
      setDatos(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const { auth, logout } = useAuth();

  const remove = () => {
    localStorage.removeItem("SNYTLOG_email");
  };
  return (
    //imagen
    <div className="header">
      <Grid container style={{ height: 125 }}>
        <Grid item xs={1}>
          <img
            src={`${BASE_API_SINSLSH}${datos.SCG_imgPathSing}`}
            style={{ paddingLeft: 40 }}
            className="logo-qait"
            alt="logo"
            width="125px"
          ></img>
        </Grid>
        <Grid item xs={2}>
          {/* AQUI SE PUEDE AGREGAR UNA VARIABLE QUE RECIBA EL COLOR QUE SE DESEE, POR EL MOMENTO ES ESTATICO */}
          <div
            className="div-trapeze1"
            style={{ backgroundColor: datos.SCG_primaryColor }}
            // style={{ backgroundColor: "#771f2a" }}
          ></div>
        </Grid>
        <Grid item xs={1}>
          {/* EL COLOR DEL TRAPECIO2 Y LOS RECTANGULOS DEBEN SER LOS MISMOS */}
          <div
            className="div-trapeze2"
            style={{ backgroundColor: datos.SCG_secundaryColor }}
            // style={{ backgroundColor: "#00203b" }}
          ></div>
        </Grid>
        <Grid item xs={1}>
          <div
            className="div-rectangle1"
            style={{ backgroundColor: datos.SCG_secundaryColor }}

            // style={{ backgroundColor: "#00203b" }}
          ></div>
        </Grid>
        <Grid item xs={5}>
          <div
            className="div-rectangle2"
            style={{ backgroundColor: datos.SCG_secundaryColor }}

            // style={{ backgroundColor: "#00203b" }}
          >
            <p></p>
            <br></br>
            <br></br>
            <h1-header>SINAY</h1-header>
            <h1-header-card-polizas>
              Sistema Integral Administrador de Siniestros Vida
            </h1-header-card-polizas>
          </div>
        </Grid>
        <Grid item xs={2}>
          <div
            className="div-panel"
            style={{ backgroundColor: datos.SCG_secundaryColor }}
          >
            {/* <div className="div-panel" style={{ backgroundColor: "#00203b" }}> */}
            {/* AQUI EL PANEL CERRAR SESION, AJUSTES, ETC. */}
            <Box>
              <br />
              <br />

              <Badge>
                <PersonIcon color="white" />
                &nbsp;&nbsp;&nbsp;
                <h6-user-name>
                  {localStorage.getItem("SNYTLOG_email")}&nbsp;&nbsp;
                </h6-user-name>
              </Badge>
              <br></br>
              <br></br>
              <Stack spacing={1.38} direction="row">
                <ModalesNoti color="white" />

                <h6-user-name>
                  |
                  <Link color="inherit" underline="none">
                    <FormattedMessage
                      id="admMatdeRo"
                      defaultMessage=" Matriz de Roles"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </Link>
                  |
                </h6-user-name>

                <Link
                  color="inherit"
                  underline="none"
                  href="/"
                  onClick={logout}
                >
                  <h6-user-name>
                    <FormattedMessage
                      id="globCerSes"
                      defaultMessage="Cerrar sesión"
                    />
                  </h6-user-name>
                </Link>
              </Stack>
            </Box>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
