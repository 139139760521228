import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TXTField from "../../Tools/TextField/TextField";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import DialogCascadeTwo from "../../Tools/Dialog/DialogCascadeTwo";
import "../Contable.css";
//Idioma
import { FormattedMessage } from "react-intl";
//funciones de validaciones 
import { LimitarTexto } from "../../Recepcion/Elements/Functions/Validaciones"; 
import { LimitarDigitos} from "../../Recepcion/Elements/Functions/Validaciones"; 
import axios from "axios";
//impportacion apis
import {getDataToken, postDataToken} from "../../../api/dataToken"

export default function FormAccountRegistration() {

  const [cuentaMayor, setCuentaMayor] = useState("");
  const [subcuenta, setSubcuenta] = useState("");
  const [subSubCuenta, setSubSubCuenta] = useState("");
  const [cuenta, setCuenta] = useState("");
  const [nombre, setNombre] = useState("");

    //Creación de metodo get
    const [cuentas, setCuentas] = useState([]);   
    const [idCuentaMayor, setIdCuentaMayor] = useState ("");
    //const [datospoli, setDatospoli] = useState([]) 
    const getData = async (cuentaMayorParam) => {
      try {
        console.log(cuentaMayorParam)
        var result = await getDataToken("cuentamayor/get_listado/");
          for (let i = 0; i < result.length; i++) {
            if (result[i].CTAM_numcuenta === cuentaMayorParam) {
              alert("Ya existe la cuenta del mayor")  
              setCuentas(result[i]);
              let idCuentaMayor1= result?.CTAM_idcuentamayor;
              setIdCuentaMayor(idCuentaMayor1)

                console.log(cuentas);                
              break; // si encuentras el registro, det ienes el bucle
            }else {
              console.log("No es el mismo");
              var jsonCuentaMayorParam = {
                CTAM_numcuenta : cuentaMayorParam
              }
              var resultData = await postDataToken("cuentamayor/get_listado/", jsonCuentaMayorParam);
              console.log(resultData, "Se envio al POST");
              let idCuentaMayor1= resultData?.CTAM_idcuentamayor;
              setIdCuentaMayor(idCuentaMayor1)
              
              var jsonSubCuentaMayor = {
                SCTA_numsubcuenta: subcuenta?.toString(),
                CTAM_idcuentamayor: idCuentaMayor1?.toString(),
              }
              var resultSbCuenta = await postDataToken("subcuenta/get_listado/",jsonSubCuentaMayor);
              console.log(resultSbCuenta, "Se ingreso a SUBCUENTA");
              let idSubCuentaIdEnd = resultSbCuenta?.SCTA_idsubcuenta;

              var jsonSubSubCuenta = {
                SSCTA_numsubsubcuenta : subSubCuenta?.toString(),
                CTAM_idcuentamayor : idCuentaMayor1,
                SCTA_idsubcuenta : idSubCuentaIdEnd
              }

              var resultSubSubCuenta = await postDataToken("subsubcuenta/get_listado/",jsonSubSubCuenta);
              console.log(resultSubSubCuenta, "Se ingreso el ultimo POST")
              var idSubSubEnd = resultSubSubCuenta?.SSCTA_idsubsubcuenta;

              var jsonCuentaNombre = {
                CTA_numcuenta: cuenta.toString(),
                CTA_nombre: nombre.toString(),
                CTAM_idcuentamayor : idCuentaMayor1,
                SCTA_idsubcuenta : idSubCuentaIdEnd,
                SSCTA_idsubsubcuenta: idSubSubEnd
              }

              var resultCuentaNombre = await postDataToken("cuentas/get_listado/",jsonCuentaNombre);
              console.log(resultCuentaNombre, "Se ingreso el POST CuentaNombre")
            }
            return;
          }
      } catch (error) {
        console.log(error);
        throw error;
      }
    };
    
  const handleSubmit = async (e) => {
    e.preventDefault();
    getData(cuentaMayor);
  }
//MEtodo que funciona para realizar el onchange para cada uno de los textfield 
  const handleChange = (event) => {
    setCuentaMayor(event.target.value);
  };
 
  const handleChangeSubCuenta = (event) => {
    setSubcuenta(event.target.value);
  };
  const handleChangeSubSubCuenta = (event) => {
    setSubSubCuenta(event.target.value);
  };
  const handleChangeCuentaEnd = (event) => {
    setCuenta(event.target.value);
  };
  const handleChangeNombreEnd = (event) => {
    setNombre(event.target.value);
  };

//Constantes en donde guardaremos los estados de los textfield
  const [msg1, setmsg1] = useState("");
  const [msg2, setmsg2] = useState("");
  const [msg3, setmsg3] = useState("");
  const [msg4, setmsg4] = useState("");
  const [msg5, setmsg5] = useState("");

//Validacion para saber si estan nulos 
  useEffect(() => {
    if (cuentaMayor.trim() === "") {
      setmsg1("Este campo es obligatorio");
    } else {
      setmsg1("");
    }
    if (subcuenta.trim() === "") {
      setmsg2("Este campo es obligatorio");
    } else {
      setmsg2("");
    }
    if (subSubCuenta === "") {
      setmsg3("/ Este campo es obligatorio");
    } else {
      setmsg3("");
    }
    if (cuenta.trim() === "") {
      setmsg4("Este campo es obligatorio");
    } else {
      setmsg4("");
    }
    if (nombre.trim() === "") {
      setmsg5("Este campo es obligatorio");
    } else {
      setmsg5("");
    }
  },[
    cuentaMayor,
    subcuenta,
    subSubCuenta,
    cuenta,
    nombre
  ]);
  return (
    // Con validateForm validamos que entren los valores 
    <form onSubmit={handleSubmit}> 
      <div>
        <Grid container justifyContent="center">
          <Grid item>
            <br />
            <h3-header-card>
              <FormattedMessage id="estconAlCu" defaultMessage="Alta de cuentas"/>
            </h3-header-card>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid xs={12}>
            <h3-subtitle-card>
              <FormattedMessage id="estconIDaC" defaultMessage="Introduzca los datos de la nueva cuenta"/>
            </h3-subtitle-card>
            <br/>
          </Grid>
          <Grid xs={3}>
          <h3-subtitle-card>Cuenta del mayor</h3-subtitle-card>
            <div class="espacio">
              <TXTField 
              onInput={(e) => LimitarDigitos(
                e.target , 4)} 
              onChange={handleChange} 
              name="cuentaMayor"              
              id="cuentaMayor" 
              helperText={msg1}
              />
            </div>
          </Grid>
          <Grid xs={3}>
          <h3-subtitle-card>Subcuenta</h3-subtitle-card>
            <div class="espacio">              
              <TXTField 
              onInput={(e) => LimitarDigitos(
                e.target , 2)}
                onChange={handleChangeSubCuenta} 
                name="subcuenta" 
                id="subcuenta" 
                helperText={msg2}
                />
            </div>
          </Grid>
          <Grid xs={3}>
            <h3-subtitle-card>Subsubcuenta</h3-subtitle-card>
            <div class="espacio">
              <TXTField 
              onInput={(e) => LimitarDigitos(
                e.target , 3)}
              onChange={handleChangeSubSubCuenta}
              id="subSubCuenta" 
              name="subSubCuenta" 
              helperText={msg3}
              />
            </div>
          </Grid>
          <Grid xs={3}>
          <h3-subtitle-card>Cuenta</h3-subtitle-card>
            <div class="espacio">
              <TXTField onInput={(e) => LimitarDigitos(
                      e.target , 6)} 
                      onChange={handleChangeCuentaEnd} 
              id="cuenta" name="cuenta" 
              helperText={msg4}
              />
            </div>
          </Grid>
          <Grid xs={4}>
            <br/>
          <h3-subtitle-card>Nombre</h3-subtitle-card>
            <div class="espacio">
              <TXTField onInput={(e) => LimitarTexto(
                      e.target, 20)}
                onKeyPress={(e) => {
                  const keyCode = e.keyCode || e.which;
                  const keyValue = String.fromCharCode(keyCode);
                  const regex = /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]+$/;
                  if (!regex.test(keyValue)) {
                    e.preventDefault();
                  }
                }} 
              onChange={handleChangeNombreEnd} 
              id="nombre" name="nombre" 
              helperText={msg5} 
              />
            </div>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={2.4}>
          <Grid item>
            <br />
            <ButtonVino 
            label = "Guardar"
            type="submit"
            //onClick={buscarValores} 
            />
           {/* <DialogCascadeTwo 
              mainButton={<FormattedMessage id="regAltaAlt" defaultMessage="Alta"/>}
              disabledbtn={!formValid}
              type="submit"
              title={<FormattedMessage id="GlobEstCon" defaultMessage="Confirmación"/>}
              // Mandamos a imprimir los datos de los textFields en nuestro mensaje
              description= {`Esta apunto de dar de alta la cuenta  ${cuentaMayor} ${subcuenta} ${subSubCuenta} ${cuenta} ${nombre} ` }
              description2= {<FormattedMessage id="estconReCa" defaultMessage="¿Estás seguro de realizar los cambios?"/>}
              childrenButton={<FormattedMessage id="globAceptr" defaultMessage="Aceptar"/>}
              secondChildrenButton={<FormattedMessage id= "globCance" defaultMessage="Cancelar"/>}
              childrenTitle={<FormattedMessage id= "GlobEstCon" defaultMessage="Confirmación"/>}
              // Mandamos a imprimir los datos de los textFields en nuestro mensaje
              childrenDescription={`La cuenta  ${cuentaMayor} ${subcuenta} ${subSubCuenta} ${cuenta} ${nombre} se dio de alta exitosamente ` }
              firstChildrenButton={<FormattedMessage id="globAceptr" defaultMessage="Aceptar"/>}
            />  */}
          </Grid>
        </Grid>
      </div>
    </form>
  );
}