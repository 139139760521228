import React from "react";
import CompConsultasWF from "../../../components/Workflow/CompConsultasWF";


export function ConsultasWF() {
  return (
    <div>
     
      <CompConsultasWF />

    </div>
  );
}
