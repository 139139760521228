import React from "react";
import { CompRegistro } from "../../../components/Registro/ComRegistro";
import MyComponent from "../../../components/Tools/ButtonLoading/ButtonLoading";

export function RegistroInicio() {
  return (
    <div>
      <MyComponent componentToRender={CompRegistro} />
    </div>
  );
}
