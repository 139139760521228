import React from 'react'
import {FormattedMessage} from 'react-intl';
export function Infovenci(){
return (
    <div>
    <h3-title-card>
    <FormattedMessage
            id="recepPersonaOriginal"
            defaultMessage="Persona física:"
        />
    <br/>
    </h3-title-card>
    <h3-subtitle-card>
    <br/>
    <FormattedMessage
            id="recepPersonaOriginal"
            defaultMessage="Póliza original"
        />
    <br/>
    <FormattedMessage
            id="globIdenOfiAsegurado"
            defaultMessage="Identificación oficial del asegurado"
        />
    <br/>
    <FormattedMessage
            id="globDatBan"
            defaultMessage="Datos bancarios"
        />
    <br/>
    <FormattedMessage
            id="globFormatoDeinforma"
            defaultMessage="Formato de información para pago. Debidamente llenado y firmado por el asegurado o beneficiario(s). Estado de cuenta del asegurado o beneficiario(s). No mayor a 3 meses de antigüedad. Formato reclamación"
        />
    <br/>
    </h3-subtitle-card>
    <h3-title-card>
    <br/>
   <FormattedMessage
            id="recepPersonaOriginal"
            defaultMessage="Persona moral:"
        />
    <br/>
    </h3-title-card>
    <h3-subtitle-card>
    <br/>
    <FormattedMessage
            id="docuPerMoralActaCons"
            defaultMessage="Testimonio o copia legible del acta constitutiva inscrita en el registro público de comercio que acredite fehacientemente su legal existencia (Copia notariada)"
        />
    <br/>
    <FormattedMessage
            id="docuPerMoralRfcExped"
            defaultMessage="Original de la cédula de identificación fiscal (RFC) expendida por la secretaría de hacienda y crédito público."
        />
    <br/>
    <FormattedMessage
            id="docuPerFisicaComDomi"
            defaultMessage="Comprobante de domicilio no mayor a 3 meses (Deberá ser original de recibo de luz, teléfono, predial, agua, cuenta bancaria, suministro de gas natural)"
        />
    <br/>
    <FormattedMessage
            id="docuPerMoralTestCoLe"
            defaultMessage="Testimonio o copia legible del instrumento que contenga los poderes del representante legal expedido por fedatario público cuando no estén contenidos en la escritura constitutiva."
        />
    <br/>
    <FormattedMessage
            id="docuPerMoralIdentifi"
            defaultMessage="Original de la identificación oficial vigente del representante legal con firma y fotografía (INE, pasaporte, cartilla SMN, cédula profesional)."
        />
    <br/>
    <FormattedMessage
            id="msgPerMoralExtrajero"
            defaultMessage="En caso de ser extranjeros deberá de presentar copia debidamente legalizada o apostillada del documento que compruebe fehacientemente su legal existencia."
        />
    <br/>
    <FormattedMessage
            id="msgPerMoralExtranjer"
            defaultMessage="Así como del que acredite como su representante a la persona física que ostente como tal y en caso de ser este también extranjera deberá presentar los documentos señalados para personas físicas."
        />
    <br/>
    </h3-subtitle-card>
    </div>

    






);
}