import { getApiURL } from './dataurl';

export async function getURL() {
  try {
    var promise = getApiURL();
    return promise
      .then((result) => {
        return result;
      });
  } catch (e) {
    throw Error(e);
  }
}
