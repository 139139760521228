import * as React from "react";
import { useState, useEffect } from "react";

import { Box, Grid, Link } from "@mui/material";
import { BASE_API_SINSLSH } from "../../../utils/constans";
import IconVerPerfil from "../Icons/IconVerPerfil";
import "../../../css/Sty.css";
import { useAuth } from "../../../hooks/useAuth";
//idioma
import { FormattedMessage } from "react-intl";
// import { getToken } from "../../../api/token";

import { getDataToken } from "../../../api/dataToken";

export default function NavAppBar() {
  const [datos, setDatos] = useState([]);

  const getData = async () => {
    try {
      var result = await getDataToken("configuraciongeneral/get_id/1/");
      setDatos(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  useEffect(() => {
    getData();
  }, []);

  // Importacion del context logout
  const { auth, logout } = useAuth();

  const remove = () => {
    localStorage.removeItem("SNYTLOG_email");
    localStorage.removeItem("TOKEN");
    logout();
  };
  return (
    //imagen
    <div className="header">
      <Grid container style={{ height: 125 }}>
        <Grid item xs={1}>
          <img
            src={`${BASE_API_SINSLSH}${datos?.SCG_imgPathSing}`}
            style={{ paddingLeft: 40 }}
            className="logo-qait"
            alt="logo"
            width="125px"
          ></img>
        </Grid>
        <Grid item xs={2}>
          {/* AQUI SE PUEDE AGREGAR UNA VARIABLE QUE RECIBA EL COLOR QUE SE DESEE, POR EL MOMENTO ES ESTATICO */}
          <div
            className="div-trapeze1"
            // style={{ backgroundColor: "#771f2a" }}
            style={{ backgroundColor: datos?.SCG_primaryColor }}
          ></div>
        </Grid>
        <Grid item xs={1}>
          {/* EL COLOR DEL TRAPECIO2 Y LOS RECTANGULOS DEBEN SER LOS MISMOS */}
          <div
            className="div-trapeze2"
            // style={{ backgroundColor: "#00203b" }}
            style={{ backgroundColor: datos?.SCG_secundaryColor }}
          ></div>
        </Grid>
        <Grid item xs={1}>
          <div
            className="div-rectangle1"
            // style={{ backgroundColor: "#00203b" }}
            style={{ backgroundColor: datos?.SCG_secundaryColor }}
          ></div>
        </Grid>
        <Grid item xs={4}>
          <div
            className="div-rectangle2"
            // style={{ backgroundColor: "#00203b" }}
            style={{ backgroundColor: datos?.SCG_secundaryColor }}
          >
            <p></p>
            <br></br>
            <br></br>
            {/* <p></p> */}
            <h1-header>SINAY</h1-header>
            <h1-header-card-polizas>
              Sistema Integral Administrador de Siniestros Vida
            </h1-header-card-polizas>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div
            className="div-panel"
            style={{ backgroundColor: datos?.SCG_secundaryColor }}
          >
            {/* <div className="div-panel" style={{ backgroundColor: "#00203b" }}> */}
            {/* AQUI EL PANEL CERRAR SESION, AJUSTES, ETC. */}
            <Box>
              <br />
              <br />
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                style={{ marginBottom: "0px" }}
              >
                <IconVerPerfil to="/perfil" color="white" />
                &nbsp;&nbsp;&nbsp;
                <h6-user-name>
                  {localStorage.getItem("SNYTLOG_email")}&nbsp;&nbsp;
                </h6-user-name>
              </Grid>
              <br></br>
              <Grid container justifyContent="center" alignItems="flex-start">
                <Grid item>
                  <Link color="inherit" underline="none" href="/documentacion">
                    <h6-user-name>
                      <FormattedMessage
                        id="glob.docu"
                        defaultMessage="Documentación"
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </h6-user-name>
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    color="inherit"
                    underline="none"
                    href="/"
                    onClick={remove}
                  >
                    <h6-user-name>
                      <FormattedMessage
                        id="glob.cerrarsesion"
                        defaultMessage="Cerrar sesión"
                      />
                    </h6-user-name>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
