/* Brandon Hernandez Rocha 11/2022 */
import * as React from "react";
//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "../../../Tools/Table/StyledTableCell";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import moment from "moment/moment";
import CheckGroup from "../../../Tools/CheckBox/CheckGroup";
//idioma
import { FormattedMessage } from "react-intl";
import { getDataToken } from "../../../../api/dataToken";
import { PERR_ASEGURADO } from "../../../../utils/constanstoken";
import { useState } from "react";
import { useEffect } from "react";
import { formatofecha } from "../../../../api/getUmbrales";
import { calculateAge } from "../../../../utils/functions";
import TablePagination from "@mui/material/TablePagination";
export default function TableCartaAsegurados(props) {
  // CONSTANTES / ESTADOS / VARIABLES
  const idPoliza = props.idPoliza;
  console.log(idPoliza);
  //Funcion checkbox para seleccionar beneficiarios

  const [listaDest, setListaDest] = React.useState([]);

  //funcion para añadir destinatario a la lista
  const updateDestToList = (e, dest) => {
    if (e.target.checked) {
      setListaDest((prevState) => [...prevState, dest]);
    } else {
      //remove from array
      setListaDest((current) =>
        current.filter(
          (e) => e.PXP_idpersonaxpoliza !== dest.PXP_idpersonaxpoliza
        )
      );
    }
  };

  React.useEffect(() => {
    //console.log('destinatarios seleccionados', listaDest)
    props.onDataChange(listaDest.length === 0);
    props.destinatarios(listaDest);
  }, [listaDest]);

  console.log(listaDest);

  //FECHA ACTUAL
  var today = new Date();

  /** Funcionalidad y estados para asegurados segun la póliza ***/
  const [asegurados, setAsegurados] = useState([]);
  const getDataPersonasXPoliza = async () => {
    try {
      // Supongamos que idPoliza es un arreglo de IDs de pólizas
      const promises = idPoliza.map(async (id) => {
        const response = await getDataToken(
          `personasxpoliza/get_poliza/${id.idPoliza}/`
        );
        if (response) {
          return response.filter(
            (persona) =>
              persona?.PER_idpersona?.PERR_idrol?.PERR_idrol ==
              PERR_ASEGURADO.id
          );
        }
        return [];
      });

      const aseguradosArrays = await Promise.all(promises);
      const aseguradosConcatenados = aseguradosArrays.flat(); // Concatena los arreglos de asegurados

      console.log(aseguradosConcatenados);
      setAsegurados(aseguradosConcatenados);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  useEffect(() => {
    getDataPersonasXPoliza();
  }, []);

  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(5); //El tamaño inicial de las filas será de 10
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const filteredData = asegurados.slice(startIndex, endIndex);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Paper style={{ width: "100%" }}>
            <TableContainer style={{ position: "sticky" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                    <StyledTableCell>
                      <FormattedMessage
                        id="globSelecc"
                        defaultMessage="Seleccionar"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage
                        id="globNombre"
                        defaultMessage="Póliza"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage
                        id="globNombre"
                        defaultMessage="Nombre"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage
                        id="globFechNa"
                        defaultMessage="Fecha de nacimiento"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage id="globEdad" defaultMessage="Edad" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage id="globRfcp" defaultMessage="RFC" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage id="globCalle" defaultMessage="Calle" />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="globaNoExt"
                        defaultMessage="No. Ext."
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="globaNoInt"
                        defaultMessage="No. Int."
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="globColoni"
                        defaultMessage="Colonia"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage
                        id="dictamAlca"
                        defaultMessage="Alcaldía"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage id="globCP" defaultMessage="C.P." />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
                  {filteredData.map((row) => {
                    return (
                      <TableRow key={row.PXP_idpersonaxpoliza}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <CheckGroup
                            checked={listaDest.some(
                              (item) =>
                                item.PXP_idpersonaxpoliza ===
                                row.PXP_idpersonaxpoliza
                            )}
                            onChange={(e) => updateDestToList(e, row)}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          {`${row?.POL_idpoliza?.POL_numpoliza}`}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                        >{`${row?.PER_idpersona?.PER_nombre} ${row?.PER_idpersona?.PER_apellidopaterno} ${row?.PER_idpersona?.PER_apellidomaterno}`}</StyledTableCell>
                        <StyledTableCell>
                          {moment(
                            row?.PER_idpersona?.PER_fechanacimiento
                          ).format(formatofecha)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {calculateAge(
                            today.toISOString(),
                            moment(
                              row?.PER_idpersona?.PER_fechanacimiento
                            ).toISOString()
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.PER_idpersona?.PER_RFC}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.PER_idpersona?.PER_calle}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.PER_idpersona?.PER_numeroexterior}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.PER_idpersona?.PER_numerointerior}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.PER_idpersona?.SEP_idsepomex?.SEP_asenta}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.PER_idpersona?.SEP_idsepomex?.SEP_municipio}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.PER_idpersona?.SEP_idsepomex?.SEP_codpostal}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20, 25]}
              component="div"
              count={asegurados.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Registros por página:"
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
