import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RadioInput from "../../Tools/RadioButton/RadioInput";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import IconButton from "@material-ui/core/IconButton";
import TXTField from "../../Tools/TextField/TextField";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { FormattedMessage } from "react-intl";
import TablePagination from "@mui/material/TablePagination";
import ButtonVino from "../../Tools/Bottones/ButtonVino";
import StyledTableCell from "../../Tools/Table/StyledTableCell";
import {
  umbralTerminado,
  umbralATiempo,
  umbralCancelado,
  umbralEnProceso,
  umbralPorVencer,
  formatofecha,
} from "../../../api/getUmbrales";
import { Link } from "react-router-dom";
import moment from "moment";
import { getDataToken } from "../../../api/dataToken";

export default function TablaComunicacion() {
  // Metodo Get para las apis
  const [tableData, setTableData] = useState([]);

  const getData = async () => {
    try {
      var result = await getDataToken(`charolacomunicacion/get_listado/`);
      // console.log(result);
      setTableData(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  /* CONSTANTES DE ESTADOS*/
  const [order, setOrder] = useState("ASC"); // esta constante tiene como propósico guardar el estado del orden de la tabla
  const [searchTerm, setSearchTerm] = useState(""); // si es "" mostrará todos los datos de la tabla
  const [btnDisbale, setbtnDisbale] = useState(true); //estado del boton

  console.log(tableData);

  /* FUNCIÓN PARA ORDENAR LA TABLA ASC/DSC (Ascendent/ Descendant) */
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...tableData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setOrder("DSC");
      setTableData(sorted);
    } else if (order === "DSC") {
      const sorted = [...tableData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? -1 : 1
      );
      setOrder("ASC");
      setTableData(sorted);
    }
  };

  /* FUNCIÓN CLICK EN LOS RADIO BUTTON, ARROJA INFORMACION DE LA FILA SELECCIONADA */
  const [pasocomu, setPasocomu] = useState("");

  const rbClick = (row) => {
    // console.log(row);
    const { CHCOM_tiemporespuesta, ...rest } = row;
    // console.log(rest);
    setPasocomu(rest);
    setbtnDisbale(false);
  };

  tableData.map((row) => {
    if (row.CHCOM_tiemporespuesta <= -6) {
      row.CHCOM_tiemporespuesta = (
        <label className="CHCOM_tiemporespuesta">
          <CircleRoundedIcon style={{ color: umbralTerminado }} />
          -6 dias{" "}
        </label>
      );
    } else if (
      row.CHCOM_tiemporespuesta <= 5 &&
      row.CHCOM_tiemporespuesta >= -5
    ) {
      row.CHCOM_tiemporespuesta = (
        <label className="CHCOM_tiemporespuesta">
          <CircleRoundedIcon style={{ color: umbralCancelado }} />
          5/-5 dias{" "}
        </label>
      );
    } else if (
      row.CHCOM_tiemporespuesta <= 10 &&
      row.CHCOM_tiemporespuesta >= 6
    ) {
      row.CHCOM_tiemporespuesta = (
        <label className="CHCOM_tiemporespuesta">
          <CircleRoundedIcon style={{ color: umbralPorVencer }} />
          10 dias
        </label>
      );
    } else if (
      row.CHCOM_tiemporespuesta <= 20 &&
      row.CHCOM_tiemporespuesta >= 11
    ) {
      row.CHCOM_tiemporespuesta = (
        <label className="CHCOM_tiemporespuesta">
          <CircleRoundedIcon style={{ color: umbralEnProceso }} />
          20 dias{" "}
        </label>
      );
    } else if (
      row.CHCOM_tiemporespuesta <= 30 &&
      row.CHCOM_tiemporespuesta >= 21
    ) {
      row.CHCOM_tiemporespuesta = (
        <label className="CHCOM_tiemporespuesta">
          <CircleRoundedIcon style={{ color: umbralATiempo }} />
          30 dias
        </label>
      );
    }
  });

  /* FUNCIÓN PARA DIRECCIONAR A OTRA VENTANA' */

  //CONSTANTES PARA LA PAGINACIÓN DE LA TABLA
  const [page, setPage] = useState(0); //La pagina inicial de la tabla sera la 0 (Se mostrarán los primeros datos obtenidos de los datos)
  const [rowsPerPage, setRowsPerPage] = useState(10); //El tamaño inicial de las filas será de 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <Grid
        style={{ paddingBottom: "20px" }}
        container
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item xs={4}>
          <h4-search>
            <FormattedMessage id="glob.buscar" defaultMessage="Buscar" />:
          </h4-search>
          <TXTField
            showSearchIcon={true}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }} style={{ position: "sticky" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Seleccionar</StyledTableCell>
                {/* En los siguientes tres encabezados tienen como evento click para ordenar de manera 
                    ascendente o descendente, agregando un icono para su efecto visual */}
                <StyledTableCell
                  align="center"
                  style={{ width: "200px" }}
                  onClick={() => sorting("CHCOM_tiemporespuesta")}
                >
                  {
                    <FormattedMessage
                      id="globDiaFin"
                      defaultMessage="Días a Fin"
                    />
                  }
                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    {order === "DSC" ? <UnfoldMoreIcon /> : <UnfoldMoreIcon />}
                  </IconButton>
                </StyledTableCell>
                {/*  */}
                <StyledTableCell
                  align="center"
                  onClick={() => sorting("RECT_fecharecepcion")}
                >
                  {
                    <FormattedMessage
                      id="regisFeRec"
                      defaultMessage="Fecha de recepción"
                    />
                  }
                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    {order === "DSC" ? <UnfoldMoreIcon /> : <UnfoldMoreIcon />}
                  </IconButton>
                </StyledTableCell>
                {/*  */}
                <StyledTableCell
                  align="center"
                  onClick={() => sorting("RECT_idfolio")}
                >
                  {
                    <FormattedMessage
                      id="globNoFoli"
                      defaultMessage="Número de folio"
                    />
                  }
                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    {order === "DSC" ? <UnfoldMoreIcon /> : <UnfoldMoreIcon />}
                  </IconButton>
                </StyledTableCell>
                {/*  */}
                <StyledTableCell
                  align="center"
                  onClick={() => sorting("poliza")}
                >
                  {
                    <FormattedMessage
                      id="globNoPoli"
                      defaultMessage="No. de Póliza"
                    />
                  }
                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    {order === "DSC" ? <UnfoldMoreIcon /> : <UnfoldMoreIcon />}
                  </IconButton>
                </StyledTableCell>
                {/*  */}
                <StyledTableCell
                  align="center"
                  onClick={() => sorting("nombre")}
                >
                  {
                    <FormattedMessage
                      id="globNomAse"
                      defaultMessage="Nombre del asegurado"
                    />
                  }
                  <IconButton
                    className="icon-button"
                    style={{ color: "white" }}
                  >
                    {order === "DSC" ? <UnfoldMoreIcon /> : <UnfoldMoreIcon />}
                  </IconButton>
                </StyledTableCell>
                {/*  */}
                <StyledTableCell
                  align="center"
                  onClick={() => sorting("TEST_estatustramite")}
                >
                  {
                    <FormattedMessage
                      id="globEstatu"
                      defaultMessage="Estatus"
                    />
                  }
                </StyledTableCell>
                {/*  */}
                <StyledTableCell
                  align="center"
                  onClick={() => sorting("usuario")}
                >
                  {
                    <FormattedMessage
                      id="globUsuari"
                      defaultMessage="Usuario"
                    />
                  }
                </StyledTableCell>
                {/*  */}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* En esta parte del código se realiza el filtro de búsqueda, si el filtro está vacío 
                retornará el valor original del arreglo, en caso contrario retornará todos los valores 
                que coincidan con las letras ingresadas, */}
              {tableData
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    /* Es necesario agregar a las condicionales los filtros que quieran buscarse en la tabla */
                    val.PER_idpersona.PER_nombre.toLowerCase().includes(
                      searchTerm.toLowerCase()
                    )
                  ) {
                    console.log(val);
                    return val;
                  }
                })
                .map((row) => (
                  <TableRow key={row.RECT_idfolio}>
                    <StyledTableCell component="th" scope="row" align="center">
                      <RadioInput
                        name="rb"
                        value={row.CHCOM_idcharolacom}
                        onChange={() => rbClick(row)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.CHCOM_tiemporespuesta}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {moment(row.RECT_idfolio.RECT_fecharecepcion).format(
                        formatofecha
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.RECT_idfolio?.RECT_numerofolio}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.POL_idpoliza?.POL_numpoliza}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.PER_idpersona.PER_nombre +
                        " " +
                        row.PER_idpersona.PER_apellidopaterno +
                        " " +
                        row.PER_idpersona.PER_apellidomaterno}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {
                        row.RECT_idfolio?.TEST_idestatustramite
                          ?.TEST_estatustramite
                      }
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.Usuario_Login.SNYTLOG_username}
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelRowsPerPage="Registros por página"
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <br />
      <Grid container spacing={5}>
        <Grid item>
          <Link
            to="/comunicacion/informacion"
            style={{ textDecoration: "none" }}
            state={{ pasocomu }}
          >
            <ButtonVino
              variant="contained"
              color="primary"
              size="small"
              estatus={btnDisbale}
              label={
                <FormattedMessage
                  id="glob.siguiente"
                  defaultMessage="Siguiente"
                />
              }
            />
          </Link>
          <br />
        </Grid>
        <br />
      </Grid>
      <br />
    </div>
  );
}
