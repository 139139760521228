import React, { useState, useEffect, Fragment } from 'react'
import { Button, Grid } from '@mui/material'
import {Select1} from "../../Tools/Selectss/Select1"
import ButtonVino from '../../Tools/Bottones/ButtonVino'
import CheckGroup from "../../Tools/CheckBox/CheckGroup";
import TXTField from '../../Tools/TextField/TextField';
//Estilos para el menu
import "../Contable.css"
//importacion apis
import {getDataToken} from "../../../api/dataToken"
//Implementación de un select paa el menu
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
//Formularios o tablas que se necesitan
import ReservasActuariales from '../MenuMovimientoContable/ReservasActuariales'
import ReaseguroMenu from '../MenuMovimientoContable/ReaseguroMenu'
import SiniestrosMenu from '../MenuMovimientoContable/SiniestrosMenu'
import ConstitucionMoratorio from '../MenuMovimientoContable/ConstitucionMoratorio'
import CancelacionReserva from '../MenuMovimientoContable/CancelacionReserva'
//importacion de dialogos 
import DialogSiniestrosOrdenados from '../Dialogs/DialogSiniestrosOrdenados'
import DialogConstitucion from '../Dialogs/DialogConstitucion'
import DialogReservaPrescripcion from '../Dialogs/DialogReservaPrescripcion'
import DialogReservasActuariales from '../Dialogs/DialogReservasActuariales'
import DialogReaseguro from '../Dialogs/DialogReaseguro'
import GenerarMov from './GenerarMov'
//importacion del formulario
import TablaGenerateMovimiento from './TablaGenerateMovimiento'
//importación del nombre del boton de honorarios
import {HONORARIOS} from "../../../utils/constanstoken";


const AccountingMovements = ()=>{
    //Nombre de variables que necesitaremos para mostrar información
    //El nombre de cada variable corresponde a la palabra inicial del botón
    const [reservas, setReservas] = useState(false)
    const [reaseguro, setReaseguro] = useState(false)
    const [honorarios, setHonorarios] = useState(false)
    const [siniestros, setSiniestros] = useState(false)
    const [constitucion, setConstitucion] = useState(false)
    const [cancelacion, setCancelacion] = useState(false)

  //Metodo del menu para esta vista
  const sectionMenu =() => {
    return(
      <Fragment>

      {/* Grid solo del texto reporte libro de sinisestros ocurridos */}
      <br/>         
        {/* Columna de texto */}
          <Grid container spacing={5}>
          <Grid container justifyContent="center" item xs={4}>
          <DialogSiniestrosOrdenados onClick={() => {
            setReservas(false)
            setReaseguro(false)
            setHonorarios(false)
            setSiniestros(true)
            setConstitucion(false)
            setCancelacion(false)
          }}/>
          
          </Grid>

          <Grid container justifyContent="center" item xs={4}>
          <DialogConstitucion onClick={() => {
            setReservas(false)
            setReaseguro(false)
            setHonorarios(false)
            setSiniestros(false)
            setConstitucion(true)
            setCancelacion(false)
          }} />
          </Grid>

          <Grid container justifyContent="center" item xs={4}>
            <DialogReservaPrescripcion onClick={() => {
              setReservas(false)
              setReaseguro(false)
              setHonorarios(false)
              setSiniestros(false)
              setConstitucion(false)
              setCancelacion(true)
            }} />
          </Grid>
          </Grid>

        <Grid container spacing={5}>
          <Grid container justifyContent="center" item xs={4}>
          <DialogReservasActuariales onClick={() => {
            setReservas(true)
            setReaseguro(false)
            setHonorarios(false)
            setSiniestros(false)
            setConstitucion(false)
            setCancelacion(false)
          }}/>
          </Grid>

          <Grid container justifyContent="center" item xs={4}>
          <DialogReaseguro onClick={() => {
            setReservas(false)
            setReaseguro(true)
            setHonorarios(false)
            setSiniestros(false)
            setConstitucion(false)
            setCancelacion(false)
          }}/>
          </Grid>
      
        <Grid container justifyContent="center" item xs={4}>
        <ButtonVino label={HONORARIOS.descripcion}
        onClick={() => {
          setReservas(false)
          setReaseguro(false)
          setHonorarios(true)
          setSiniestros(false)
          setConstitucion(false)
          setCancelacion(false)
        }}></ButtonVino>
        </Grid>   
       </Grid>
        <Grid>
          <br></br>
          <br></br>
        {/* Es para que aparesca el contenido correspondiente a cada botón  */}
        <Grid container direction="row" alignItems="center">
          {!reservas ? null : (
            <Grid item xs={12}>
              <GenerarMov/>
              {/* <ReservasActuariales/> */}
            </Grid>
          )}
          {!reaseguro ? null : (
            <Grid item xs={12}>
              <GenerarMov/>
              {/* <ReaseguroMenu/> */}
            </Grid>
          )}
          {!honorarios? null : (
            <Grid item xs={12}>
               {/* <TablaGenerateMovimiento/> */}
               <GenerarMov/>
            </Grid>
          )}
          {!siniestros ? null : (
            <Grid item xs={12}>
              <GenerarMov/>
              {/* <SiniestrosMenu/> */}
            </Grid>
          )}
          {!constitucion ? null : (
            <Grid item xs={12}>
              <GenerarMov/>
              {/* <ConstitucionMoratorio/> */}
            </Grid>
          )}
          {!cancelacion ? null : (
            <Grid item xs={12}>
              <GenerarMov/>
              {/* <CancelacionReserva/> */}
            </Grid>
          )}
        </Grid>
        </Grid>
      </Fragment>
    )
  }
//Return de lo que va a mostrar nuestras vistas

  return( 
    <>
     <Grid xs={12}>
      {sectionMenu()}
     </Grid>
    </>
  )
}
export default AccountingMovements;