import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material';
import {Select2} from '../../Tools/Selectss/Select2'
//importaciones de tablas o formularios 
import TableAccounts from "../Elements/TableAccounts";//consulta al catálogo de cuentas
import FormAccountRegistration from "../Elements/FormAccountRegistration";//Alta de cuenta
import TableAccounts2 from "../Elements/TableAccounts2";//Baja de cuenta
import TableAccountingEntries from "../Elements/TableAccountingEntries";//Tabla de asientos contables Consulta
import TableDeregistrationAccountingEntries from "../Elements/TableDeregistrationAccountingEntries";//Baja de asientos contables
import FormsAccountingEntries  from "../Elements/FormsAccountingEntries"; //Alta de registro de asientos contables
//Idioma
import { FormattedMessage } from "react-intl";
//Boton
import ButtonVino from "../../Tools/Bottones/ButtonVino";

const CompCuentaAsiento = ()=>{

  //Variable para que puedan aparecer las tablas y formularios
  const [accounts, setAccounts] = useState(false);
  const [bajaContable, setBajaContable] = useState(false);
  const [altaCuenta, setAltaCuenta] = useState(false);
  const [consultaAsientos, setConsultaASientos] = useState(false);
  const [bajaAsientos, setBajaAsientos] = useState(false);
  const [altaAsientos, setAltaAsientos] = useState(false);
  //Para el botón de regresar
  const[showBtnRegresarLista, setShowBtnRegresarLista]= useState (false)
  const[showBtnRegresarTabla, setShowBtnRegresarTabla]= useState (false)  
  const [valorSelectUno, setValorSelectUno] = useState(0)
  const [valorSelectDos, setValorSelectDos] = useState(0)

//******CATALOGO DE CUENTAS Y ASIENTOS CONTABLES******/
//1er modulo catalogo de cuentas ya asientos contables 
//--Aquí apareceran el primer menú
const [cuentas, setCuentas] = useState([]);
const cuentasAsientos = [
  { id: 1, identificador: "1", texto: "Catálogo de cuentas" },
  { id: 2, identificador: "2", texto: "Asientos contables" },
];
useEffect(() => {
  console.log(cuentasAsientos);
  setCuentas(cuentasAsientos);
}, []);
//Subsubmenu estas son las opciones de submenu catalogo de cuentas y asientos contables
const [subCatalogo, setSubCatalogo] = useState([]); //recibira los el nuevo arreglo
const [subAltas, setSubAltas] = useState(false);
  const subCatalogoAltas = [
    { id: 1, cuentasId: 1, identificador: "1", texto: "Consulta al catálogo de cuentas",},
    { id: 2, cuentasId: 1, identificador: "2", texto: "Alta de cuentas", },
    { id: 3, cuentasId: 1, identificador: "3", texto: "Baja de cuentas", },
    { id: 4, cuentasId: 2, identificador: "4", texto: "Consulta" },
    { id: 5, cuentasId: 2, identificador: "5", texto: "Alta de asientos contables", },
    { id: 6, cuentasId: 2, identificador: "6", texto: "Baja de asientos contables", },
  ];
//Nesecitamos de este filtro para que dependiendo de la opción seleccionada el nos daras las opciones correspondientes
//con ese Id
  const handleOption = (id) => {
    const dt = subCatalogoAltas.filter((x) => x.cuentasId == id);
    console.log(dt);
    setSubCatalogo(dt);
    setShowBtnRegresarLista(true)
    setSubAltas(true); //para que aparezca el select cuando llamen este metodo
  };

  const handleOption1 = (id) => {
    //En el siguiente if anidado asiganamos el id del arreglo anterior para poder abrir el menu
   if (id==1) {
    setAccounts(true);//Consulta de catalogo de cuentas
    setConsultaASientos(false) 
    setAltaAsientos(false)
    setBajaAsientos(false)
    setAltaCuenta(false)
    setBajaContable(false)
    setShowBtnRegresarTabla(true)
    setShowBtnRegresarLista(false)
    } 
    if(id==2) {
      setAltaCuenta(true)//Alta de catalogo de cuentas
      setAccounts(false)
      setConsultaASientos(false) 
      setAltaAsientos(false)
      setBajaAsientos(false)
      setBajaContable(false)
      setShowBtnRegresarTabla(true)
      setShowBtnRegresarLista(false)
    }if(id==3) {
      setConsultaASientos(false)
      setAccounts(false)
      setAltaAsientos(false)
      setBajaAsientos(false)
      setAltaCuenta(false)
      setBajaContable(true)//Baja de cuenta contable
      setShowBtnRegresarTabla(true)
      setShowBtnRegresarLista(false)
    }if(id==4) {
      setConsultaASientos(true)//Consulta de asientos contables
      setAccounts(false) 
      setAltaAsientos(false)
      setBajaAsientos(false)
      setAltaCuenta(false)
      setBajaContable(false)
      setShowBtnRegresarTabla(true)
      setShowBtnRegresarLista(false)
    }if(id==5) {
      setAltaAsientos(true) // alta de asientos contables
      setAccounts(false)
      setConsultaASientos(false) 
      setBajaAsientos(false)
      setAltaCuenta(false)
      setBajaContable(false)
      setShowBtnRegresarTabla(true)
      setShowBtnRegresarLista(false)
    }if(id==6) {
      setBajaAsientos(true)//baja de asientos contables
      setAccounts(false)
      setConsultaASientos(false) 
      setAltaAsientos(false)
      setAltaCuenta(false)
      setBajaContable(false)
      setShowBtnRegresarTabla(true)
      setShowBtnRegresarLista(false)
    }
  };

  const handleClickRegresarLista = (e) => {
    setSubAltas(false);
    setShowBtnRegresarLista(false);
    setValorSelectUno(0) 
  };
  
  const handleClickRegresarTabla = (e) => {
    setShowBtnRegresarLista(true)
    setAltaCuenta(false)
    setBajaContable(false)
    setShowBtnRegresarTabla(false)
    setAccounts(false)
    //setConcepto(false)
    setConsultaASientos(false) 
    setAltaAsientos(false)
    setBajaAsientos(false)
    setValorSelectDos(0)//Asignamod el valor a cero para que el select regrese a su primera opción
  };

  const handleChangeMenu = (e) => {
    handleOption(e.target.value);
    setValorSelectUno(e.target.value);
  };

  const handleChangeMenuDos = (e) => {
    handleOption1(e.target.value);
    setValorSelectDos(e.target.value);
  };

    return(
     <>
     <Grid container>
      {/*En este contenido llamaremos a los select necesarios para realizar nuestro menu
        Array=pondremos el arreglo que queremos mostrar 
        onChange= pondremos el handle que deseamos que necesitamos en esa opción      
      */}
        <Grid xs={4}>
            <Select2 
            sx={{ minWidth: 310, maxWidth: 300, background: "white", }} h3-contable='Buscar por:'
            id="primeroSelect"
            array={cuentasAsientos} 
            onChange={handleChangeMenu}
            value={valorSelectUno}//Asignamos con el valor que va a empezar 
            />
        </Grid>
        <Grid xs={4}>
            { subAltas?(<Select2
            sx={{ minWidth: 310, maxWidth: 300, background: "white", }} h3-contable='Buscar por:'
            id="segundoSelect"
            array={subCatalogo} 
            onChange={handleChangeMenuDos}
            value={valorSelectDos}/>): null }
        </Grid> 
        <Grid xs={4}>           
        </Grid>
        <Grid xs={12}>
        {/*Pondremos todas las opciones porque estas seran seleccionadas dependiendo del menu 
        que desea ver*/} 
            {accounts?(<TableAccounts/>):null}
            {bajaContable?(<TableAccounts2/>):null}
            {altaCuenta?(<FormAccountRegistration/>):null}
            {consultaAsientos?<TableAccountingEntries/>:null}
            {bajaAsientos?<TableDeregistrationAccountingEntries/>:null}
            {altaAsientos?<FormsAccountingEntries/>:null}
            <br></br>
        </Grid>

        {/* Vista del boton donde limpia los datos del select 2 y solo muestra el 1 */}
        {showBtnRegresarLista? <Grid container justifyContent="flex-end">
        <Grid item>
         <ButtonVino
            onClick = {handleClickRegresarLista}
            label={<FormattedMessage id="globRegres" defaultMessage="Regresar"/>}
          />
        </Grid>
      </Grid>:null}

        {/* Vista del Boton para limpiar las tablas */}
      {showBtnRegresarTabla? <Grid container justifyContent="flex-end">
        <Grid item>
         <ButtonVino
            onClick = {handleClickRegresarTabla}
            label={<FormattedMessage id="globRegres" defaultMessage="Regresar"/>}
          />
        </Grid>
      </Grid>:null}

     </Grid>
     </>
    )
}
export default CompCuentaAsiento;