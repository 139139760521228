import * as React from "react";
import PropTypes from "prop-types";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from '@material-ui/core/Grid'
import TXTField from "../../../Tools/TextField/TextField";
import DatePicker2 from "../../../Tools/DatePicker/DatePicker2";
import { Select1 } from "../../../Tools/Selectss/Select1";
import { getDataToken } from "../../../../api/dataToken"

//Solo aparesera un alerta con un solo boton
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function DialogSolReservaConta(props) {
    //PARENT DIALOG
    const [open, setOpen] = React.useState(true)
    const handleClose = () => { 
        setOpen(false) 
        props.cerrarDialogo(false) 
    }
    //CHILDREN DIALOG
    const [open2, setOpen2] = React.useState(false)
    const handleClickOpen2 = () => { setOpen2(true) }

    const handleClose2 = () => {
        props.cerrarDialogo(false)
        setOpen2(false) // Close the children dialog
        handleClose() // Close the parent dialog
    }
    const [selectMoneda, setSelectMoneda] = React.useState([]);
    const infoRow = props.infoRow;
    var today = new Date();

    const getDataMoneda = async () => {
        try {
            var result = await getDataToken("monedas/get_listado/")
            let array = result.map((element) => {
                return {
                    ...element, identificador: element.MON_idmoneda,
                    texto: element.MON_abreviatura
                }
            }
            )
            setSelectMoneda(array)
        } catch (e) {
            console.error(e)
        }
    }
    React.useEffect(() => {
        getDataMoneda()
    }, [])

    return (
        <div>

            {/* PARENT DIALOG */}
            <div>
                {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="dialog"
                    open={open}
                >
                    {/*CUERPO DEL DIALOGO*/}
                    {/*TITILO*/}
                    <BootstrapDialogTitle id="dialog" onClose={handleClose} style={{ color: '#761f2a' }}>
                        <h1-dialog-title>{props.title}</h1-dialog-title>
                    </BootstrapDialogTitle>
                    {/*CUERPO DEL DIALOGO*/}
                    {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
                    <DialogContent dividers style={{ minWidth: 500 }}>
                        {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
            se necesita colocar entre un typogreaphy las veces que des salto de linea 
            o con un br*/}
                        <h2-dialog-description>{props.description}</h2-dialog-description>
                    </DialogContent>
                    {/*es la tercer sección que engloba los botones */}
                    <DialogActions>

                        {/*En esta parte se llena el segundo mensaje que debe de aparecer*/}
                        <Grid container spacing={1} direction="row" justifyContent="center" alignItems="flex-start">
                            <Grid item xs={'auto'}>
                                <ButtonVino onClick={handleClickOpen2} label={"Aceptar"} estatus={props.disabledBtn} />
                            </Grid>
                            <Grid item xs={'auto'}>
                                <ButtonVino onClick={handleClose} label={"Cancelar"} estatus={props.disabledBtn} />
                            </Grid>
                        </Grid>

                    </DialogActions>
                </BootstrapDialog>
            </div>
            {/*BOTON QUE HACE EL LLAMADO AL DIALOGO*/}

            {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
            <BootstrapDialog
                maxWidth={'md'}
                fullWidth={true}
                onClose={handleClose2}
                aria-labelledby="dialog"
                open={open2}
            >
                {/*CUERPO DEL DIALOGO*/}
                {/*TITILO*/}
                <BootstrapDialogTitle
                    id="dialog"
                    onClose={handleClose2}
                    style={{ color: "#761f2a" }}
                >
                    <h1-dialog-title>Solicitud de Reserva Contable por Siniestro</h1-dialog-title>
                </BootstrapDialogTitle>
                {/*CUERPO DEL DIALOGO*/}
                {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
                <DialogContent dividers style={{ minWidth: 500 }}>
                    {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
          se necesita colocar entre un typogreaphy las veces que des salto de linea 
          o con un br*/}
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                        <Grid item >
                            <h3-title-card>No. de póliza:</h3-title-card>
                        </Grid>
                        <Grid item  >
                            <h3-subtitle-card>{props.poliza}</h3-subtitle-card>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
                        <Grid item xs={4} >
                            <TXTField label="Destinatario" required />
                        </Grid>
                        <Grid item xs={4} >
                            <TXTField label="Correo electrónico" required />
                        </Grid>
                        <Grid item xs={4} >
                            <TXTField label="Número de folio" defaultValue={infoRow.infoFolio.numFolio} required />
                        </Grid>
                        <Grid item xs={4} >
                            <TXTField label="Número de siniestro" defaultValue={infoRow.noSiniestro} required />
                        </Grid>
                        <Grid item xs={4} >
                            <TXTField label="Nombre del asegurado" defaultValue={infoRow.infoFolio.nomAsegurado} required />
                        </Grid>
                        <Grid item xs={4} >
                            <DatePicker2 label="Fecha de nacimiento" maxDate={today} required />
                        </Grid>
                        <Grid item xs={4} >
                            <DatePicker2 label="Fecha de siniestro" maxDate={today} required />
                        </Grid>
                        <Grid item xs={4} >
                            <TXTField label="Cobertura" required />
                        </Grid>
                        <Grid item xs={4} >
                            <TXTField label="Importe en moneda origen" required />
                        </Grid>
                        <Grid item xs={4} >
                            <Select1 array={selectMoneda} label="Moneda" />
                        </Grid>
                        <Grid item xs={12} >
                            <TXTField label="Mensaje" multiline={true} required />
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                </DialogContent>
                {/*es la tercer sección que engloba los botones */}
                <DialogActions>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <ButtonVino
                                onClick={handleClose2}
                                variant="contained"
                                label={'Cancelar'}
                            />
                        </Grid>
                        <Grid item>
                            <ButtonVino
                                onClick={handleClose2}
                                variant="contained"
                                label={'Enviar'}
                            />
                        </Grid>


                    </Grid>
                </DialogActions>
            </BootstrapDialog>

        </div>
    );
}
