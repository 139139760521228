import * as React from "react";
import PropTypes from "prop-types";
import ButtonVino from "../Bottones/ButtonVino";
import { IconButton } from '@mui/material';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DialogImg(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/*BOTON QUE HACE EL LLAMADO AL DIALOGO*/}
      {/*Este boton aparecera el js en donde se necesite de la alerta*/}
      <a onClick={props.onClick1}>
      <ButtonVino variant="contained" onClick={handleClickOpen} label={props.mainButton}/>
      </a>
      {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
      <BootstrapDialog
        //onClose={handleClose}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="dialog"
        open={open}
        maxWidth={false}
        fullWidth ={true} 
        style={{
          width: "1250px",
          height: "auto",
          display: "block",
          margin: "auto",
        }} 
      >
        {/*CUERPO DEL DIALOGO*/}
        {/*TITILO*/}
        <BootstrapDialogTitle
          id="dialog"
          onClose={handleClose}
          style={{ color: "#761f2a" }}
        >
          {props.title}
        </BootstrapDialogTitle>

        {/*CUERPO DEL DIALOGO*/}
        {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
        <DialogContent dividers style={{ minWidth: 1000}}>
          {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
          se necesita colocar entre un typogreaphy las veces que des salto de linea 
          o con un br*/}
          <IconButton onClick={props.route}><RemoveRedEyeIcon/></IconButton>
          <br/>
          {/* <img
            style={{ width: "auto", height: "100%" }}
            src={props.img}
            
          /> */}
          <h2-dialog-description>{props.img}</h2-dialog-description> 
        </DialogContent>
        {/*es la tercer sección que engloba los botones */}
        <DialogActions>
          <ButtonVino onClick={handleClose} variant="contained" label={props.button} />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
