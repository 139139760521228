import React from "react";
import { CompInformacionWF } from "../../../components/Workflow/CompInformacionWF";


export function InformacionWF() {
  return (
    <div>
    
      <CompInformacionWF />
   
    </div>
  );
}
