import NotificationImportantSharpIcon from "@mui/icons-material/NotificationImportantSharp";
import Box from "@mui/material/Box";
//modal ventana
import Modal from "@mui/material/Modal";
//idioma
import {FormattedMessage} from 'react-intl';
import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import Badge from '@mui/material/Badge';
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import './Styles.css';
import CheckIcon from '@mui/icons-material/Check';
import { getToken } from "../../../../api/token";
import { Stack } from "@mui/system";
import { BASE_API } from "../../../../utils/constans";
import CloseIcon from '@mui/icons-material/Close';


const columns = [
  { field: 'SNYTLOG_email', headerName: 'Favor de Otorgar Permisos', width: 350}
]



function refreshPage() {
  window.location.reload(false);
}

const style = {
  //modal emergente
  position: "absolute",
  overflow: "scroll",
  overheight: "scroll",
  top: "40%",
  left: "65%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #304766",
  boxShadow: 35,
  width: "35%",
  height: "40%",
};

const ModalesNoti = () => {
  //activacion de modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //API FUNCTIONS
  
const token= getToken();
const [tableData, setTableData] = useState("")
const [SNYTLOG_leido] = useState(false);
const [selectionModel, setSelectionModel] = useState("");
const [SNYTLOG_email] = selectionModel

useEffect(() => {
  fetch(`${BASE_API}login/get_correo_noti/`, 
  {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
        
    }, 
    
  })
 
    .then((data) => data.json())
    .then((data) => setTableData(data))
  
}, [])





const handleChange = () => {


  fetch(`${BASE_API}login/putall/`+selectionModel+'/', 
      {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
            
        }, 
          body: JSON.stringify(
                  {
                    SNYTLOG_email:SNYTLOG_email,
                    SNYTLOG_leido : SNYTLOG_leido
                  })})
                }





//contador rows
// console.log(tableData.length)

  return (
    <div>
        <Badge color="warning" 
        badgeContent={tableData.length} 
        >
          <NotificationImportantSharpIcon
          color="white"
          onClick={handleOpen}
          fontSize="small"
          />
        </Badge>
    
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
        <DataGrid
      //checkboxSelection 
      sx={{
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
          display: "none"
        }
      }}
      // utilizar un identificador 
       getRowId={row => row.SNYTLOG_email} 
       pageSize={10}
       component="div"
        rows={tableData}
        autoHeight
        columns={columns}
        onStateChange={handleChange}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }
        
      }
  
        
      />

<Stack spacing={2} justifyContent="center" direction="row">
<ButtonVino onClick={refreshPage} startIcon={<CheckIcon/>}/>
<ButtonVino onClick={handleClose} startIcon={<CloseIcon/>}/>
</Stack>

        </Box>
       
      </Modal>


    </div>
  );
};
export default ModalesNoti;
