import * as React from "react";
import PropTypes from "prop-types";
import ButtonVino from "../../../Tools/Bottones/ButtonVino";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@material-ui/core/Grid";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { formatofecha } from "../../../../api/getUmbrales";
import moment from "moment";
//Tabla

//Solo aparesera un alerta con un solo boton
//estilos del dialogo
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));
//Funcionalidad que tiene el dialogo
function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function DialogFoundNumPol(props) {
    const [open, setOpen] = React.useState(props.open ? props.open : false);


    const handleClose = () => {
        setOpen(false);
        props.aceptar(true)
    };

    const showFolios = () => {
        let reactFrag = <React.Fragment></React.Fragment>;
        for (let index = 0; index < props.numeroFolio; index++) {
            const folio = props.numeroFolio[index];
            reactFrag =
                <React.Fragment style={{ marginBottom: "5px" }}>
                    <h2-dialog-description>{!folio ? "" : `Folio: ${folio}`} </h2-dialog-description>
                    <h2-dialog-description>{!props.reclamacion[index] ? "" : `Tipo de reclamación: ${props.reclamacion[index]}`}</h2-dialog-description>
                </React.Fragment>
        }
        return reactFrag;
    }

    return (
        <div>

            {/*FUNCIONALIDAD PARA LLAMAR EL DIALOGO*/}
            <BootstrapDialog

                fullWidth={true}
                maxWidth={'md'}
                onClose={handleClose}
                aria-labelledby="dialog"
                open={open}
            >
                {/*CUERPO DEL DIALOGO*/}
                {/*TITILO*/}
                <BootstrapDialogTitle
                    id="dialog"
                    onClose={handleClose}
                    style={{ color: "#761f2a" }}
                >
                    <h1-dialog-title>Existe coincidencia con el número de póliza {props.numPoliza} dentro de SINAY</h1-dialog-title>

                </BootstrapDialogTitle>
                {/*CUERPO DEL DIALOGO*/}
                {/*LA PALABRA DIVIDERS ES LA QUE COLOCA LAS DIVISIONES */}
                <DialogContent style={{ minWidth: 500 }}>
                    {/*para poner el cuerpo del dialogo no se puede dar un salto de linea 
          se necesita colocar entre un typogreaphy las veces que des salto de linea 
          o con un br*/}

                    {/* <h2-dialog-description>{!props.numeroFolio ? "" : `Folio: ${props.numeroFolio}`} </h2-dialog-description>
                    <h2-dialog-description>{!props.reclamacion ? "" : `Tipo de reclamación: ${props.reclamacion}`}</h2-dialog-description> */}

                    {showFolios()}
                </DialogContent>
                {/*es la tercer sección que engloba los botones */}
                <DialogActions>
                    <Grid container justifyContent="center" >
                        <ButtonVino
                            onClick={handleClose}
                            label={'Aceptar'}
                        />
                    </Grid>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
