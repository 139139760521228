import React from "react";
import IconButton from "@mui/material/IconButton";
import { FormattedMessage } from "react-intl";
import search_data from "../../../img/search_data.png";

function App(props) {
  return (
    <div>
      <nav>
        <IconButton
          aria-label="FindInPageIcon"
          color="icons"
          href={props.enlace}
          disabled={props.estatus}
          onClick={props.onClick}
          onChange={props.onChange}
          id={props.id}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={search_data} style={{ width: "150px", height: "150px" }} />{" "}
          <span style={{ marginTop: "5px" }}>
            <h3-medic-icons>Ver Reporte</h3-medic-icons>
          </span>
        </IconButton>
      </nav>
    </div>
  );
}

export default App;
