//Brandon Hernandez 12/2022
import React, {useState} from 'react'
import './Elements/Investigador.css'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import SupervisorTable from './Elements/TablaSupervisor'
import Grid from '@material-ui/core/Grid';
import CustomSeparator from '../Tools/BreadCrumbs/Recorrido'
import "../../css/estilo.css";
import ReturnPage from "../Tools/Bottones/ButtonReturn";
//idioma
import {FormattedMessage} from 'react-intl';

const CompSupervisor = () =>{
    return(
        
        <div className='div-parent'>
            <div className='div-breadcrumbs'>
                <CustomSeparator  name1={<FormattedMessage id="globInicio" defaultMessage="Inicio"/>} 
                enlace="/menu"
                name2={<FormattedMessage id="menuInvest" defaultMessage="Investigación"/>} 
                />
            </div>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                <ReturnPage href='/menu'/>
            </Grid>
            <div className='div-container'>
                <SupervisorTable/>
            </div>
        </div>
        
    );
}
export default CompSupervisor;